import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { Projection, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, MasterParameters, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { MasterWidgetConfig } from '../master-widget-config/master-widget-config-service';
import { XprojMasterWidgetTimeComponent } from '../master-widget-time/xproj-master-widget-time.component';

export class QueryData {
	projection : Projection;
	projectionGroups : Array<string[]> = [];
  queryableSelectedGroup : string[] = [];
  selectedGroup : string[] = [];
  time : MasterTimeSettings;
}

@Component({
  selector: 'xproj-master-widget',
  templateUrl: './xproj-master-widget.component.html',
  styleUrls: ['./xproj-master-widget.component.scss']
})
export class XprojMasterWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild("masterTime", { read: XprojMasterWidgetTimeComponent, static:false }) masterTime: XprojMasterWidgetTimeComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static:false }) groupSelect: XprojGroupSelectionComponent;

	widgetConfig: MasterWidgetConfig;

  projections : Projection[] = [];
  queryData : QueryData = new QueryData();

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {
      super(logger, xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as MasterWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0,10000);
    }

    setTimeout(async () => {
      this.queryData.projection = this.projections.find(p => p.projectionid == this.widgetConfig.ProjectionId);
      this.queryData.selectedGroup = this.widgetConfig.SelectedGroup;
      this.queryData.time = this.widgetConfig.Time;

      this.sendWidgetChange();
    }, 500);  //TODO: make sure masterwidgets are initilized last

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
  }

  async onRefresh() {

  }

  async onResized(component: GridsterItemComponentInterface) {

  }

  async onReset() {

  }


  async onUpdateQuery() {

  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {

  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {

  }

  async selectedProjectionChange(projection : Projection) {
    //this.logger.log('selectprojecton', projection);
	  if (projection) {
		  this.queryData.selectedGroup = null;
      this.queryData.queryableSelectedGroup.length = 0;
    }

    this.queryData.projection = projection;
    this.queryData.selectedGroup = undefined;
    //this.sendWidgetChange();
  }

  async selectedProjectionGroupChange(group : any) {
    this.logger.log('selectgroup', group);

    this.queryData.selectedGroup = group;
    //this.sendWidgetChange();
  }

  sendWidgetChange() {
    let params = new LinkedWidgetChangeParameters();
    params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
    params.path.push(this.widgetConfig.Id);
    let master = new MasterParameters();
    if (this.widgetConfig.ProjectionSelectEnabled) {
      master.projectionId = this.queryData.projection?.projectionid;
      master.group = this.queryData.selectedGroup;
    }
    master.time = this.queryData.time;
    params.master = master;

    this.widgetService.linkedWidgetChanged(params);
  }

  onTimeApply(timesettings : MasterTimeSettings) {
    this.queryData.time = timesettings;
    this.sendWidgetChange();
  }

  apply() {
    this.queryData.time = this.masterTime.getSettings();
    if (this.widgetConfig.ProjectionSelectEnabled) {
      this.queryData.selectedGroup = this.groupSelect.getSelectedGroup();
    }

    this.sendWidgetChange();
  }

}
