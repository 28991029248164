import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-editplcprogram-errorlogs',
  templateUrl: './editplcprogram-errorlogs.component.html',
  styleUrls: ['./editplcprogram-errorlogs.component.scss']
})
export class EditplcprogramErrorlogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
