<!-- <form autocomplete="off"> -->
  <clr-combobox-container>
    <label *ngIf="showHeader">{{header}}</label>
    <clr-combobox [disabled]="!enabled" [(ngModel)]="subgroupselections" name="groupselection" clrMulti="true"
      [clrLoading]="loading" (clrInputChange)="groupSelectionFilterChange($event)"
      (clrOpenChange)="$event ? querySubgroups() : null">
      <ng-container *clrOptionSelected="let selected">
        {{selected?.group[selected.group.length-1]}}
      </ng-container>
      <clr-options>
        <clr-option *clrOptionItems="let subgroup of subgroups; field:'group'" [clrValue]="subgroup">
          {{subgroup.group[subgroup.group.length-1]}}
        </clr-option>
      </clr-options>
    </clr-combobox>
    <clr-control-helper>{{getHelperText()}}</clr-control-helper>
    <clr-control-error>There was an error</clr-control-error>
  </clr-combobox-container>
<!-- </form> -->
