<div class="content-area">
  <div class="card" *ngIf="user">
    <div class="card-header">
      {{user.firstname}} {{user.surname}}
    </div>
    <div class="card-block">
      <form clrForm>
        <clr-input-container>
          <label>Firstname:</label>
          <input clrInput required placeholder="firstname" name="firstname" type="text" [(ngModel)]="user.firstname" />
        </clr-input-container>
        <clr-input-container>
          <label>Surname:</label>
          <input clrInput required placeholder="surname" name="surname" type="text" [(ngModel)]="user.surname" />
        </clr-input-container>
        <clr-input-container>
          <label>Email:</label>
          <input clrInput required placeholder="email" name="email" type="text" [(ngModel)]="user.email" />
        </clr-input-container>
        <!-- <clr-input-container>
          <label>Default dashboard:</label>
          <input clrInput required placeholder="" name="defaultDashboardId" type="text" [(ngModel)]="user.defaultDashboardId" />
        </clr-input-container> -->
      </form>
      <br>
      <button type="button" class="btn btn-outline" (click)="saveCustomerUser();">
        <clr-icon shape="floppy"></clr-icon>
        Save
      </button>

      <form clrForm>
        <clr-input-container>
          <label>Old password:</label>
          <input clrInput placeholder="oldpassword" name="oldpassword" type="password" [(ngModel)]="oldPassword" />
        </clr-input-container>
        <clr-input-container>
          <label>New password:</label>
          <input clrInput placeholder="newpassword" name="newpassword" type="password" [(ngModel)]="newPassword" />
        </clr-input-container>
        <br>
        <button type="button" class="btn btn-outline" (click)="setPassword();">
          <clr-icon shape="floppy"></clr-icon>
          Set password
        </button>
      </form>

      <div>
        <h3>Devices</h3>
        <clr-datagrid [(clrDgSingleSelected)]="selectedUserDevice">
          <clr-dg-action-bar>
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-secondary" (click)="addCustomerUserDevice()">
                <clr-icon shape="plus"></clr-icon> Add device
              </button>
              <button type="button" class="btn btn-sm btn-secondary" (click)="deleteCustomerUserDevice()"
                [disabled]="!selectedUserDevice">
                <clr-icon shape="trash"></clr-icon> Delete device...
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-secondary" (click)="viewCustomerUserDevice()"
                [disabled]="!selectedUserDevice">
                <clr-icon shape="eye"></clr-icon> View QR
              </button>
            </div>
          </clr-dg-action-bar>
          <clr-dg-column i18n='@@userprofile_name'>Name</clr-dg-column>
          <clr-dg-column i18n='@@userprofile_apikey'>Apikey</clr-dg-column>

          <clr-dg-row *ngFor="let userDevice of userDevices; let i = index" [clrDgItem]="userDevice">
            <clr-dg-cell>
              {{userDevice.name}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{userDevice.apiKey}}
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>      

    </div>
    <!-- <div class="card-footer">
      <div class="btn-group btn-primary">
        <button type="submit" class="btn btn-outline" (click)="saveCustomerUser();">
          <clr-icon shape="floppy"></clr-icon>
          Save
        </button>
      </div>
    </div> -->
  </div>

</div>
