import { verifyHostBindings } from '@angular/compiler';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupResult, GroupsQuery, Projection, XProjectorClient } from '../../XProjector/xprojector-client-service';

export enum GroupFilterSetting {
  STARTS_WITH = 0,
  CONTAINS = 1,
  ENDS_WITH = 2
}

@Component({
  selector: 'xproj-group-selection',
  templateUrl: './xproj-group-selection.component.html',
  styleUrls: ['./xproj-group-selection.component.scss']
})
export class XprojGroupSelectionComponent implements OnInit, AfterViewInit {

  private _projection: Projection;
  private _projectionId : string;

  get projection(): Projection {
    return this._projection;
  }

  @Input() set projection(value: Projection) {
    if (value && this._projection && value.projectionid != this._projection.projectionid) {
      this.subgroupselections = [];
    }
    this._projection = value;
    this._projectionId = value?.projectionid;
    this.checkEnabled();
  }

  get projectionId(): string {
    return this._projectionId;
  }

  @Input() set projectionId(value: string) {
    if (value && this._projection && value != this._projectionId) {
      this.subgroupselections = [];
    }
    this._projectionId = value;
    this.checkEnabled();
  }

  @Input() selected: string[] = [];
  @Input() filterSetting: GroupFilterSetting = GroupFilterSetting.STARTS_WITH;
  @Input() onlyOutputSelectedOnData : boolean = true;
  @Input() showHeader : boolean = true;
  @Input() fixedGroup: string[] = [];
  @Input() header: string = 'Group Selection';

  @Output() groupSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  private _subgroupselections: GroupResult[] = [];

  get subgroupselections(): GroupResult[] {
    return this._subgroupselections;
  }

  set subgroupselections(selection: GroupResult[]) {
    try {
      if (selection) {
        for (let i = this.fixedGroup.length; i < selection.length; i++) {
          if (selection[i].group.length != i + 1) {
            this._subgroupselections = selection.slice(0, i);
            return;
          }
        }
      }

      this._subgroupselections = selection;
    }
    finally {
      if (this._subgroupselections?.length > 0 && (!this.onlyOutputSelectedOnData || this._subgroupselections[this._subgroupselections.length - 1].hasdata)) {
        this.groupSelected?.next(this._subgroupselections[this._subgroupselections.length - 1].group);
      }
    }
  }

  subgroups: GroupResult[] = [];
  loading = false;
  enabled: boolean = true;

  constructor(private xprojClient : XProjectorClient) {

  }

  async ngOnInit() {
    this.subgroupselections = [];

    for (let i = this.fixedGroup.length; i < this.selected.length; i++) {
      let grp = new GroupResult();
      grp.group = this.selected.slice(0, i + 1);
      grp.haschildren = i < this.selected.length - 1;
      grp.hasdata = i == this.selected.length - 1;
      this.subgroupselections.push(grp);
    }
  }

  async ngAfterViewInit() {
  }

  setFixedGroup(fixedGroup : string[]) {
    this.fixedGroup = fixedGroup;
    if (this.fixedGroup.length != fixedGroup.length) {
      this.subgroupselections = [];
    }
  }

  getSelectedGroup(): string[] {
    if (this.subgroupselections?.length > 0) {
      return  this.subgroupselections[this.subgroupselections.length - 1].group;
    }
    else {
      return this.fixedGroup;
    }
  }

  async checkEnabled() {
    if (this.projection) {
      let groupsQuery = new GroupsQuery();
      groupsQuery.targetprojectionid = this._projectionId;
      groupsQuery.maxitems = 1;
      groupsQuery.seekoffset = 0;
      groupsQuery.groups.push('.*');

      let tgr = await this.xprojClient.RequestQueryGroups(groupsQuery);

      this.enabled = tgr.nrgroups > 0;
    }
    else {
      this.enabled = true;
    }
  }


  async groupSelectionFilterChange(startswith: string) {
    if (startswith?.length > 0) {
      await this.querySubgroups(startswith);
    }
    else {
      this.subgroups = [];
    }

  }

  pushFilter(filter: string, groups: string[]) {
    if (filter?.length > 0) {
      switch (this.filterSetting) {
        case GroupFilterSetting.STARTS_WITH:
          groups.push('(?i)' + filter + '.*');
          break;
        case GroupFilterSetting.CONTAINS:
          groups.push('(?i).*' + filter + '.*');
          break;
        case GroupFilterSetting.ENDS_WITH:
            //TODO: Doesn't work on last subgroup
          groups.push('(?i).*' + filter + ',.*');
          break;
      }
    }
    else {
      groups.push('.*');
    }
  }

  async querySubgroups(filter: string = ''){
    try {
      this.loading = true;
      let groupsQuery = new GroupsQuery();
      groupsQuery.targetprojectionid = this._projectionId;
      groupsQuery.maxitems = 50;
      groupsQuery.seekoffset = 0;

      this.fixedGroup.forEach((grp) => {
        this.pushFilter(grp, groupsQuery.groups);
      });

      if (!this.subgroupselections || this.subgroupselections.length == 0) {
        this.pushFilter(filter, groupsQuery.groups);
      }
      else {
        this.subgroupselections.forEach(g => groupsQuery.groups.push(g.group[g.group.length - 1]));
        this.pushFilter(filter, groupsQuery.groups);
      }

      let tgr = await this.xprojClient.RequestQueryGroups(groupsQuery);
      //console.log('subgroups', tgr);
      let i = this.subgroupselections ? this.subgroupselections.length : 0;
      let levelGroups: string[] = [];
      tgr.groups.forEach(g => levelGroups.push(g[i]));

      this.subgroups = tgr.groups;
    }
    finally {
      this.loading = false;
    }
  }

  getHelperText(): string {
    let result = '';
    if (this.subgroupselections?.length > 0) {
      if (this.subgroupselections[this.subgroupselections.length - 1].hasdata) {
        result += 'Group has data.';
      }
      if (this.subgroupselections[this.subgroupselections.length - 1].haschildren) {
        result += ' Group has children';
      }
    }
    else {
      result = this.enabled ? 'Select group' : "No groups available";
    }

    return result;
  }



}
