/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.sysadmin.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
import { GRPC_SYS_ADMIN_CLIENT_SETTINGS } from './xprojector.sysadmin.pbconf';
/**
 * Service client implementation for sysadmin.SysAdmin
 */
@Injectable({ providedIn: 'root' })
export class SysAdminClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /sysadmin.SysAdmin/AddNewCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.Customer>>
     */
    addNewCustomer: (
      requestData: xprojectorGrpcModels002.Customer,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.Customer>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/AddNewCustomer',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.Customer,
        responseClass: xprojectorGrpcModels002.Customer
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/ModifyCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    modifyCustomer: (
      requestData: xprojectorGrpcModels002.Customer,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/ModifyCustomer',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.Customer,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetCustomers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomersResponse>>
     */
    getCustomers: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetCustomers',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetCustomersResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/DeleteCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteCustomer: (
      requestData: thisProto.DeleteCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/DeleteCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteCustomerRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/AddUserToCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>>
     */
    addUserToCustomer: (
      requestData: xprojectorGrpcModels002.CustomerUser,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/AddUserToCustomer',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.CustomerUser,
        responseClass: xprojectorGrpcModels002.CustomerUser
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/AddUserToCustomerWithPassword
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>>
     */
    addUserToCustomerWithPassword: (
      requestData: thisProto.AddUserToCustomerWithPasswordRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/AddUserToCustomerWithPassword',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddUserToCustomerWithPasswordRequest,
        responseClass: xprojectorGrpcModels002.CustomerUser
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetCustomerUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SysAdminGetCustomerUsersResponse>>
     */
    getCustomerUsers: (
      requestData: thisProto.SysAdminGetCustomerUsersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SysAdminGetCustomerUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetCustomerUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.SysAdminGetCustomerUsersRequest,
        responseClass: thisProto.SysAdminGetCustomerUsersResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/DeleteUserFromCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteUserFromCustomer: (
      requestData: thisProto.DeleteUserFromCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/DeleteUserFromCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteUserFromCustomerRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/AddNewUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.User>>
     */
    addNewUser: (
      requestData: xprojectorGrpcModels002.User,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.User>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/AddNewUser',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.User,
        responseClass: xprojectorGrpcModels002.User
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/DeleteUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteUser: (
      requestData: thisProto.DeleteUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/DeleteUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteUserRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetUsersResponse>>
     */
    getUsers: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetUsers',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetUsersResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetActiveUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.User>>
     */
    getActiveUser: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.User>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetActiveUser',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: xprojectorGrpcModels002.User
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/ModifyUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    modifyUser: (
      requestData: xprojectorGrpcModels002.User,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/ModifyUser',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.User,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/ModifyCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    modifyCustomerUser: (
      requestData: xprojectorGrpcModels002.CustomerUser,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/ModifyCustomerUser',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.CustomerUser,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/SetPassword
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    setPassword: (
      requestData: thisProto.SetSysAdminPasswordRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/SetPassword',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetSysAdminPasswordRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/ResetPassword
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    resetPassword: (
      requestData: thisProto.ResetPasswordRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/ResetPassword',
        requestData,
        requestMetadata,
        requestClass: thisProto.ResetPasswordRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/LogoutAllLocations
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    logoutAllLocations: (
      requestData: thisProto.LogoutAllLocationsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/LogoutAllLocations',
        requestData,
        requestMetadata,
        requestClass: thisProto.LogoutAllLocationsRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GenerateApiKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GenerateApiKeyResponse>>
     */
    generateApiKey: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GenerateApiKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GenerateApiKey',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GenerateApiKeyResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/UpdateGeoLocations
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    updateGeoLocations: (
      requestData: thisProto.UpdateGeoLocationsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/UpdateGeoLocations',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateGeoLocationsRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetCustomersContent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomersContentResponse>>
     */
    getCustomersContent: (
      requestData: thisProto.GetCustomersContentRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomersContentResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetCustomersContent',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetCustomersContentRequest,
        responseClass: thisProto.GetCustomersContentResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetActiveUserDevices
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetActiveUserDevicesResponse>>
     */
    getActiveUserDevices: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetActiveUserDevicesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetActiveUserDevices',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetActiveUserDevicesResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/AddUserDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.UserDevice>>
     */
    addUserDevice: (
      requestData: thisProto.AddUserDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.UserDevice>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/AddUserDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddUserDeviceRequest,
        responseClass: xprojectorGrpcModels002.UserDevice
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/DeleteUserDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteUserDevice: (
      requestData: thisProto.DeleteUserDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/DeleteUserDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteUserDeviceRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/SetNodeLogging
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    setNodeLogging: (
      requestData: thisProto.SetNodeLoggingRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/SetNodeLogging',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetNodeLoggingRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /sysadmin.SysAdmin/GetNodeLogging
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetNodeLoggingResponse>>
     */
    getNodeLogging: (
      requestData: thisProto.GetNodeLoggingRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetNodeLoggingResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/sysadmin.SysAdmin/GetNodeLogging',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetNodeLoggingRequest,
        responseClass: thisProto.GetNodeLoggingResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_SYS_ADMIN_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('sysadmin.SysAdmin', settings);
  }

  /**
   * Unary call @/sysadmin.SysAdmin/AddNewCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.Customer>
   */
  addNewCustomer(
    requestData: xprojectorGrpcModels002.Customer,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.Customer> {
    return this.$raw
      .addNewCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/ModifyCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  modifyCustomer(
    requestData: xprojectorGrpcModels002.Customer,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .modifyCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetCustomers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomersResponse>
   */
  getCustomers(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomersResponse> {
    return this.$raw
      .getCustomers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/DeleteCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteCustomer(
    requestData: thisProto.DeleteCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/AddUserToCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.CustomerUser>
   */
  addUserToCustomer(
    requestData: xprojectorGrpcModels002.CustomerUser,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.CustomerUser> {
    return this.$raw
      .addUserToCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/AddUserToCustomerWithPassword
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.CustomerUser>
   */
  addUserToCustomerWithPassword(
    requestData: thisProto.AddUserToCustomerWithPasswordRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.CustomerUser> {
    return this.$raw
      .addUserToCustomerWithPassword(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetCustomerUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SysAdminGetCustomerUsersResponse>
   */
  getCustomerUsers(
    requestData: thisProto.SysAdminGetCustomerUsersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SysAdminGetCustomerUsersResponse> {
    return this.$raw
      .getCustomerUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/DeleteUserFromCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteUserFromCustomer(
    requestData: thisProto.DeleteUserFromCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteUserFromCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/AddNewUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.User>
   */
  addNewUser(
    requestData: xprojectorGrpcModels002.User,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.User> {
    return this.$raw
      .addNewUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/DeleteUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteUser(
    requestData: thisProto.DeleteUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetUsersResponse>
   */
  getUsers(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetUsersResponse> {
    return this.$raw
      .getUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetActiveUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.User>
   */
  getActiveUser(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.User> {
    return this.$raw
      .getActiveUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/ModifyUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  modifyUser(
    requestData: xprojectorGrpcModels002.User,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .modifyUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/ModifyCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  modifyCustomerUser(
    requestData: xprojectorGrpcModels002.CustomerUser,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .modifyCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/SetPassword
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  setPassword(
    requestData: thisProto.SetSysAdminPasswordRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .setPassword(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/ResetPassword
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  resetPassword(
    requestData: thisProto.ResetPasswordRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .resetPassword(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/LogoutAllLocations
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  logoutAllLocations(
    requestData: thisProto.LogoutAllLocationsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .logoutAllLocations(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GenerateApiKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GenerateApiKeyResponse>
   */
  generateApiKey(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GenerateApiKeyResponse> {
    return this.$raw
      .generateApiKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/UpdateGeoLocations
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  updateGeoLocations(
    requestData: thisProto.UpdateGeoLocationsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .updateGeoLocations(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetCustomersContent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomersContentResponse>
   */
  getCustomersContent(
    requestData: thisProto.GetCustomersContentRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomersContentResponse> {
    return this.$raw
      .getCustomersContent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetActiveUserDevices
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetActiveUserDevicesResponse>
   */
  getActiveUserDevices(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetActiveUserDevicesResponse> {
    return this.$raw
      .getActiveUserDevices(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/AddUserDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.UserDevice>
   */
  addUserDevice(
    requestData: thisProto.AddUserDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.UserDevice> {
    return this.$raw
      .addUserDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/DeleteUserDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteUserDevice(
    requestData: thisProto.DeleteUserDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteUserDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/SetNodeLogging
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  setNodeLogging(
    requestData: thisProto.SetNodeLoggingRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .setNodeLogging(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/sysadmin.SysAdmin/GetNodeLogging
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetNodeLoggingResponse>
   */
  getNodeLogging(
    requestData: thisProto.GetNodeLoggingRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetNodeLoggingResponse> {
    return this.$raw
      .getNodeLogging(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
