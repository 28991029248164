<form clrForm>
  <clr-select-container>
    <label>Data type</label>
    <select clrSelect name="coltype" [(ngModel)]="buttonConfig.DataType">
      <option [ngValue]="OutputDataType.Float32">Float32</option>
      <option [ngValue]="OutputDataType.Float64">Float64</option>
      <option [ngValue]="OutputDataType.UInt8">UInt8</option>
      <option [ngValue]="OutputDataType.Int32">Int32</option>
      <option [ngValue]="OutputDataType.Int64">Int64</option>
      <!-- <option value="5">Number</option> -->
      <option [ngValue]="OutputDataType.Timestamp">Timestamp</option>
      <option [ngValue]="OutputDataType.String">String</option>
    </select>
  </clr-select-container>

  <clr-datagrid [(clrDgSingleSelected)]="selectedButton">
    <clr-dg-column>Label</clr-dg-column>
    <clr-dg-column>Value</clr-dg-column>
    <clr-dg-column>Default</clr-dg-column>
    <clr-dg-column>Move</clr-dg-column>

    <clr-dg-row *ngFor="let button of buttonConfig.RadioButtons; let i = index" [clrDgItem]="button">
      <clr-dg-cell>
        <input class="clr-input" placeholder="" name="Label{{i}}" [(ngModel)]="button.Label" />
      </clr-dg-cell>
      <clr-dg-cell>
        <input *ngIf="buttonConfig.DataType < 6" class="clr-input" type="number" placeholder="" name="Value{{i}}" [(ngModel)]="button.ValueNumber" />
        <input *ngIf="buttonConfig.DataType == 6" class="clr-date" type="date" name="Value{{i}}" [(clrDate)]="button.ValueDate">
        <input *ngIf="buttonConfig.DataType == 7" class="clr-input" type="string" placeholder="" name="Value{{i}}" [(ngModel)]="button.ValueString" />
      </clr-dg-cell>
      <clr-dg-cell>
        <input class="clr-checkbox" type="checkbox" placeholder="" name="Default{{i}}" [(ngModel)]="button.Default" (change)="defaultClick(button)"/>
      </clr-dg-cell>
      <clr-dg-cell>
        <clr-icon (click)="moveButtonUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
        <clr-icon (click)="moveButtonDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
          style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>

  <button type="button" class="btn btn-link" (click)="addButton()">
    <clr-icon shape="plus-circle"></clr-icon>
    Add Button
  </button>
  <button *ngIf="selectedButton" type="button" class="btn btn-link" (click)="removeSelectedButton()">
    <clr-icon shape="minus-circle"></clr-icon>
    Remove selected button
  </button>
</form>
