<!-- <div class="customers-content-area">
   <button type="button" class="btn btn-primary">
    <clr-icon shape="export"></clr-icon> En knapp
  </button>
</div> -->

<div class="customers-content-area">
  <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false"
    [systemDashboard]="true" [dashboardId]="'bms_xedge'" [enableExport]="false"
    [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth"
    [dashboardOutputParameters]="meterParameters"
    (onWidgetValueSelected)="meterDashboardValueChanged($event)"></xproj-dashboard>
</div>
