<div *ngIf="widgetQuery">
  <form clrForm>

    <clr-input-container>
      <label>Name</label>
      <input clrInput style="width: 75%;" placeholder="" name="name_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.Name" />
    </clr-input-container>

    <clr-select-container>
      <label>Projection</label>
      <select clrSelect id="useprojectioninput_{{widgetQuery.Id}}" name="useprojectioninput_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.UseProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="!widgetQuery.UseProjectionInputParameter"
        name="projection_{{widgetQuery.Id}}" [(ngModel)]="projection"
        (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="widgetQuery.UseProjectionInputParameter" class="clr-select"
        name="UseProjectionInputParameter_{{widgetQuery.Id}}" [(ngModel)]="widgetQuery.ProjectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input / Group</label>
      <select clrSelect id="usegroupinput_{{widgetQuery.Id}}" name="usegroupinput_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.GroupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
      </select>

      <select clrSelect *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select"
        name="UseProjectionInputParameter_{{widgetQuery.Id}}" [(ngModel)]="widgetQuery.GroupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <xproj-group-selection #groupSelect *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP"
      [projection]="projection" [selected]="widgetQuery.Query?.targetgroup"
      (groupSelected)="selectedProjectionGroupChange($event)">
    </xproj-group-selection>

    <clr-combobox-container *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
      <label>Group input selections</label>
      <clr-combobox [(ngModel)]="widgetQuery.GroupInputParameterIds" name="multiSelect_{{widgetQuery.Id}}"
        clrMulti="true" required>
        <ng-container *clrOptionSelected="let selected">
          {{selected}}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
            [clrValue]="input.id">
            {{input.id}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
  </form>

  <form clrForm>
    <clr-select-container>
      <label>Time filter column</label>
      <select clrSelect id="timefiltercolumn_{{widgetQuery.Id}}" name="timefiltercolumn_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.timestampColumnName">
        <option *ngFor="let col of columns | xproj_filtertimestampcolumns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Grouping</label>

      <select clrSelect id="usegrouping_{{widgetQuery.Id}}" name="usegrouping_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.UseGrouping">
        <option [ngValue]="true"> Group </option>
        <option [ngValue]="false">No grouping</option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Max items</label>
      <input type="number" clrInput placeholder="" name="maxitems_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.MaxItems" />
    </clr-input-container>

    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox [(ngModel)]="widgetQuery.SortOnLabel"
          name="sortonlabel_{{widgetQuery.Id}}" />
        <label>Sort on labels</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>


    <clr-datagrid>
      <clr-dg-column> </clr-dg-column>
      <clr-dg-column>Column</clr-dg-column>
      <clr-dg-column *ngIf="widgetQuery.UseGrouping">Transform / Aggregation</clr-dg-column>
      <clr-dg-column>Label</clr-dg-column>
      <clr-dg-column>Unit</clr-dg-column>

      <clr-dg-row>
        <clr-dg-cell>
          Labels
        </clr-dg-cell>
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" name="labelselect_{{widgetQuery.Id}}" id="labelselect_{{widgetQuery.Id}}"
              [(ngModel)]="widgetQuery.LabelConfig.ColumnName">
              <option *ngFor="let col of columns" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of widgetQuery.Query.scriptedcolumnspostaggregation"
                [ngValue]="'script:' + col.columnoutname">
                script:{{col.columnoutname}}</option>
            </select>
          </div>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="widgetQuery.UseGrouping">
          <div class="clr-select-wrapper">
            <input *ngIf="widgetQuery.UseGrouping" class="clr-checkbox" type="checkbox" placeholder=""
              name="TransformInput" [(ngModel)]="widgetQuery.UseTransformInputParameter" />

            <select class="clr-select" style="width:15em;"
              *ngIf="widgetQuery.UseGrouping && !widgetQuery.UseTransformInputParameter"
              id="groupingtransform_{{widgetQuery.Id}}" name="groupingtransform_{{widgetQuery.Id}}"
              [(ngModel)]="widgetQuery.GroupingTransform">
              <option [ngValue]="Transformation.NONE"> None </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
              <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
            </select>

            <select *ngIf="widgetQuery.UseGrouping && widgetQuery.UseTransformInputParameter" class="clr-select"
              style="width:15em;" name="inputtansformparam" [(ngModel)]="widgetQuery.TransformInputParameterId">
              <option
                *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>

          </div>
        </clr-dg-cell>

        <clr-dg-cell>
        </clr-dg-cell>

        <clr-dg-cell>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-row>
        <clr-dg-cell>
          Data
        </clr-dg-cell>
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" name="tselectwidgetQuery.data_{{widgetQuery.Id}}"
              id="tselectcolumn_data_{{widgetQuery.Id}}" [(ngModel)]="widgetQuery.DataConfig.ColumnName"
              (ngModelChange)="onDataColumnSelect($event)">
              <option *ngFor="let col of columns" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of widgetQuery.Query.scriptedcolumnspostaggregation"
                [ngValue]="'script:' + col.columnoutname">
                script:{{col.columnoutname}}</option>
            </select>
          </div>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="widgetQuery.UseGrouping">
          <div class="clr-select-wrapper">
            <input class="clr-checkbox" type="checkbox" placeholder="" name="AggregationInput"
              [(ngModel)]="widgetQuery.DataConfig.UseAggregationInputParameter" />
            <select *ngIf="!widgetQuery.DataConfig.UseAggregationInputParameter" class="clr-select" style="width:13em;"
              name="dataConfigagg_{{widgetQuery.Id}}" [(ngModel)]="widgetQuery.DataConfig.Transform">
              <option [ngValue]="Aggregation.NONE"> None </option>
              <option [ngValue]="Aggregation.COUNT"> Count </option>
              <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

              <option [ngValue]="Aggregation.SUM"> Sum </option>

              <option [ngValue]="Aggregation.MAX"> Max </option>
              <option [ngValue]="Aggregation.MIN"> Min </option>

              <option [ngValue]="Aggregation.FIRST"> First </option>
              <option [ngValue]="Aggregation.LAST"> Last </option>

              <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

              <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
              <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
              <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

              <option [ngValue]="Aggregation.MEDIAN"> Median </option>

              <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
              </option>

              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                arithmetic mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
            </select>
            <select *ngIf="widgetQuery.DataConfig.UseAggregationInputParameter" class="clr-select" style="width:13em;"
              name="inputparam" [(ngModel)]="widgetQuery.DataConfig.AggregationInputParameterId">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="tlabel_data" [(ngModel)]="widgetQuery.DataConfig.Label" />
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="unit_data" [(ngModel)]="widgetQuery.DataConfig.Unit" />
        </clr-dg-cell>

      </clr-dg-row>
    </clr-datagrid>
  </form>

  <h5>Filters</h5>
  <xproj-projection-filter #projectionFilter id="projectionFilter_{{widgetQuery.Id}}"
    [projectionid]="projection?.projectionid" [columnDescriptions]="columns" [group]="selectedGroup"
    [datafilters]="widgetQuery.DataFilters" [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType"
    [inputParameters]="inputParameters" [widgets]="widgets">
  </xproj-projection-filter>
  <br />

  <clr-accordion>
    <clr-accordion-panel *ngFor="let scriptedcolumn of widgetQuery.Query.scriptedcolumnspostaggregation; index as i">
      <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn"
          (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button>

  <br />
  <button class="btn btn-outline" (click)="duplicateQuery()">Duplicate query</button>
  <button class="btn btn-danger-outline" (click)="removeQuery()">Remove query</button>
</div>
