<clr-modal [(clrModalOpen)]="showRemoveProjectionDialog">
  <h3 class="modal-title">Remove projection</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showRemoveProjectionDialog = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showRemoveProjectionDialog = false; doRemoveSelectedProjection()">Ok</button>
  </div>
</clr-modal>

<main class="content-area">
  <h1>Edit projections</h1>
  <p>Select a projection to continue to editing mode</p>


  <div class="limit-height limit-width">
    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedProjection" [clrDgRowSelection]="true"
      (clrDgSingleSelectedChange)="selectedProjectionChange($event)" [clrDgLoading]="loadingProjections">

      <clr-dg-column [clrDgField]="'projectionid'"> Projection ID </clr-dg-column>
      <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
      <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
      <clr-dg-column [clrDgField]="'lastmodifiedutc'"> Last Modified UTC </clr-dg-column>
      <clr-dg-column [clrDgField]="'lastprojectedutc'"> Last Projected UTC </clr-dg-column>

      <clr-dg-row *clrDgItems="let proj of projections" [clrDgItem]="proj">
        <clr-dg-cell class="cellhideoverflow"> {{proj.projectionid}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow"> {{proj.name}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow"> {{proj.description}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow"> {{proj.lastmodifiedutc | xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow"> {{proj.lastprojectedutc | xproj_pretty}} </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
          <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Projections per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Projections
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

  <div class="clr-row" *ngIf="selectedProjection">
    <div style="width: 600px;">
      <div class="card">
        <div class="card-header">
          {{selectedProjection.name}}
        </div>
        <div class="card-block">
          <div class="card-title">
            {{selectedProjection.description}}
          </div>
          <div class="card-text">

            <div class="clr-row" *ngFor="let item of selectedProjection | keyvalue">
              <div class="clr-col-4">
                <span>{{item.key}}</span>
              </div>
              <div class="clr-col-8">
                <xproj-dynclr-mini [title]="item.key" [data]="item.value"></xproj-dynclr-mini>
              </div>
            </div>

          </div>
        </div>
        <div class="card-footer">
          <div class="btn-group btn-primary">

            <button [routerLink]="[selectedProjection.projectionid]" class=" btn btn-outline ">Edit</button>
            <button class="btn btn-outline "
              (click)="setSelectedProjectionEnabled(!selectedProjection.enabled)">
              {{selectedProjection.enabled ? 'Disable' : 'Enable'}}</button>
            <button class="btn btn-danger-outline "
              (click)="showRemoveProjectionDialog = true">Remove</button>

          </div>
        </div>
      </div>
    </div>
  </div>

</main>
