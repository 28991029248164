<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid limit-width">
  <clr-dg-column>Id</clr-dg-column>
  <clr-dg-column>Displayname</clr-dg-column>

  <clr-dg-row *ngFor="let edgeType of edgeTypes; let i = index" [clrDgItem]="edgeType">
    <clr-dg-cell>
      {{edgeType.id}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{edgeType.displayableId}}
    </clr-dg-cell>

  </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addEdgeType()">
  <clr-icon shape="plus-circle"></clr-icon>
  Add edgetype
</button>
<button *ngIf="selected && !selected.systemDefined" type="button" class="btn btn-link" (click)="deleteSelected()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected edgetype
</button>

<div *ngIf="selected">
  <div class="card limit-width">
    <div class="card-header">
      Edit edgetype ({{selected.id}})
    </div>

    <div class="card-block">
      <form clrForm>
        <clr-input-container>
          <label>Id</label>
          <input clrInput placeholder="" [disabled]="selected.systemDefined" name="id" [(ngModel)]="selected.id" />
        </clr-input-container>

        <clr-input-container>
          <label>Displayname</label>
          <input clrInput placeholder="" [disabled]="selected.systemDefined" name="displayableId" [(ngModel)]="selected.displayableId" />
        </clr-input-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="selected.systemDefined" [(ngModel)]="selected.isReference" name="isreference" />
            <label>Is reference</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

      </form>

      <app-edit-type-properties [edgeType]="selected" [doubleProperties]="selected.doubleProperties"
        [stringProperties]="selected.stringProperties" [booleanProperties]="selected.booleanProperties"
        [dateTimeProperties]="selected.dateTimeProperties" [timeSpanProperties]="selected.timeSpanProperties"
        [stringOptionsProperties]="selected.stringOptionsProperties" [dashboardProperties]="selected.dashboardProperties"
        [stringArrayProperties]="selected.stringArrayProperties" >

      </app-edit-type-properties>
    </div>

    <button class="btn" [disabled]="selected.systemDefined" (click)="saveSelected()">Save</button>

  </div>
</div>
