import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GlobalWidgetSettings } from '../models/global-widget-settings';
import { WidgetUtils } from '../utils/widget-utils-service';
import { BaseQuery, BaseQueryInputColumnDescription, BaseQueryOutputColumnDescription, BaseQueryResult, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, ColumnGroupingDescription, DFTQuery, DownSampleQuery, MsgPackCloneObject, MultiseriesQuery, SPCQuery, XDataType, XProjectorClient } from '../XProjector/xprojector-client-service';
import { BaseQueryConfigInterface, GroupSelectionTypes, LegendAlignment, LegendPosition, OutputDataType, WidgetConfig, WidgetOutputParameter, WidgetPreQueryConfig } from './widget-config-service';
import { takeUntil } from 'rxjs/operators';
import { LinkedWidgetChangeParameters, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from './xproj-widget-service';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { ArrayUtils } from '../utils/array-utils-service';
import { DataFilter } from '../filters/data-filter/data-filter-service';
import { config } from 'process';
import { DateHelper } from '../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../logger/xproj-logger-service';


const { PromisePool } = require('@supercharge/promise-pool')

class PreQueryDataColumn {
  id: string;
  parameterId: string;
  columnname: string;
  columnOutName: string;
  datatype: XDataType;
  values: {
    paramValues: { outputParameter: WidgetOutputParameter, value: any }[],
    data: any[]
  }[];
  projectionid: string;
  group: string[];
  hidden: boolean = false;
  output: boolean = false;
  // editMode: EditMode = EditMode.String;
  // enumMembers: EnumMember[] = [];
}

class PreQueryData {
  columns: PreQueryDataColumn[] = [];
  configQuery: WidgetPreQueryConfig;
  count: number = 0;
  //paramValues : {outputParameter : WidgetOutputParameter, value : any}[] = [];
}

@Component({
  template: ''
})
export class WidgetBase implements OnInit, OnDestroy {

  @Input() config: WidgetConfig;
  @Input() widgetheight: number;
  @Input() widgetwidth: number;

  @Input() responsive: boolean = false;
  @Input() from: Date = null;
  @Input() to: Date = null;
  @Input() globalWidgetSettings: GlobalWidgetSettings;
  @Input() relativeTimestamp: ColumnFilteringRelativeTimestamp;
  @Input() zoom: boolean = false;

  @Output() onBeforeInit = new EventEmitter<WidgetConfig>();
  @Output() onAfterInit = new EventEmitter<WidgetConfig>();

  ngUnsubscribe = new Subject<void>();
  private inputParameterValues: Map<string, any> = new Map<string, any>();
  private preQueryParameterValues: Map<WidgetOutputParameter, any> = new Map<WidgetOutputParameter, any>();

  private preQueryDataByLevel: Map<number, PreQueryData[]> = undefined;
  private minLevel: number;
  private maxLevel: number;
  private requestCount: number = 0;
  private lastUpdate: number = 0;

  constructor(
    public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {

  }


  async ngOnInit() {
    this.widgetService.initDoneSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.onInitDone();
    });

    this.onBeforeInit?.next(this.config);

    setTimeout(async () => {
      this.config.InputParameters.forEach(input => {
        this.inputParameterValues.set(input.id, WidgetUtils.GetInputValue(input.initValue, input.datatype));
      });

      let outputParameters = this.widgetService.getDashboardParameters();
      if (outputParameters?.length > 0) {
        this.config.InputParameters.forEach(input => {
          outputParameters.forEach(e => {
            if (input.widgetId == e.widgetId && input.widgetOutputParameterId == e.outputParameterId) {
              this.inputParameterValues.set(input.id, WidgetUtils.GetInputValue(e.value, e.datatype, input.invertValue));
            }
          });
        });
      }

      this.updateDisplayTitle();

      this.widgetService.refreshSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (widgetId) => {
        if (widgetId == undefined || widgetId?.length == 0 || widgetId == this.config?.Id) {
          this.resetPreQuery();
          await this.onRefresh();
        }
      });

      this.widgetService.resizeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ item, itemComponent }) => {
        if (this.config == item) {
          await this.onResized(itemComponent);
        }
      });

      this.widgetService.resetSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (widgetId) => {
        if (widgetId == undefined || widgetId?.length == 0 || widgetId == this.config?.Id) {
          await this.onReset();
        }
      });


      this.widgetService.updateQuerySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ widgetId, config }) => {
        if (widgetId?.length == 0 || widgetId == this.config?.Id) {
          this.config = config;
          this.resetPreQuery();
          await this.onUpdateQuery();
        }
      });

      this.widgetService.linkedWidgetChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
        if ((event.widgetId.length == 0 || (event.widgetId == this.config?.LinkedInputWidget && event.widgetId != this.config.Id))
          && !event.path.includes(this.config.Id)) {
          let e = WidgetUtils.TransformLinkedWidgetChangeParameters(event, this.config.LinkedWidgetActions);

          await this.onLinkedWidgetChanged(e);

          e.widgetId = this.config.Id;
          e.path.push(this.config.Id);

          if (e.widgetId.length > 0) {
            this.widgetService.linkedWidgetChanged(e);
          }

        }
      });

      this.widgetService.widgetOutputChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (events) => {
        let refresh: boolean = false;
        await ArrayUtils.AsyncForEach(this.config.InputParameters, async (input) => {
          await ArrayUtils.AsyncForEach(events, async (e) => {
            if (input.widgetId == e.widgetId && input.widgetOutputParameterId == e.outputParameterId) {
              let value = e.value != undefined ? e.value : input.initValue;
              this.inputParameterValues.set(input.id, WidgetUtils.GetInputValue(value, e.datatype, input.invertValue));
              refresh = true;
              if (e.datatype == OutputDataType.Time) {
                await this.onWidgetOutputTimeChanged(this.getParameterValue(input.id, undefined).value);
              }
            }
          });
        })
        if (refresh) {
          //console.log('this.inputParameterValues', this.inputParameterValues);
          this.resetPreQuery();
          this.updateDisplayTitle();
          await this.onWidgetOutputChanged(events);
        }

      });

      this.widgetService.projectionDataChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (projectionIds) => {
        if (this.config.MinUpdateInterval >= 0) {
          let nowTime = new Date().valueOf();

          if (projectionIds.length == 0 || this.config.MinUpdateInterval == 0 || nowTime - this.lastUpdate > this.config.MinUpdateInterval) {
            await this.onProjectionDataChanged(projectionIds, projectionIds.length == 0);
            this.lastUpdate = nowTime;
          }
        }

      });

      this.widgetService.exportQueriesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ widgetId, callback }) => {
        if (widgetId == this.config?.Id) {
          let queries = this.getExportQueries();
          let excludeColumns = this.getExportExcludeColumns();
          callback(queries, excludeColumns);
        }
      });

      this.widgetService.exportImageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ widgetId, callback }) => {
        if (widgetId == this.config?.Id) {
          this.exportImage(callback);
        }
      });

      this.widgetService.exportDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ widgetId, callback }) => {
        if (widgetId == this.config?.Id) {
          this.exportData(callback);
        }
      });

      await this.onInit();

      this.onAfterInit?.next(this.config);
    });
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.onDestroy();
  }

  async onInit() { }

  onDestroy() { }

  async onInitDone() { }

  async onRefresh() { }

  async onResized(component: GridsterItemComponentInterface) { }

  async onReset() { }

  async onUpdateQuery() { }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) { }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) { }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) { }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) { }

  getExportQueries(): (BaseQuery | DownSampleQuery | DFTQuery | SPCQuery | MultiseriesQuery)[] {
    return [];
  }

  getExportExcludeColumns(): string[] {
    return [];
  }

  getHeight(component: any): any {
    let componentGridster = component as GridsterItemComponentInterface;
    if (componentGridster)
    {
      let row: any = component.el.firstChild;
      return component.height - row.offsetHeight;
    }
    return "100%";
  }

  exportImage(callback: (href: any) => void) {
    let href = this.getExportImage();
    callback(href);
  }


  getExportImage(): any {
    return '';
  }

  exportData(callback: (data: any[][]) => void) {
    let data = this.getExportData();
    callback(data);
  }

  getExportData(): any[][] {
    return null;
  }


  updateDisplayTitle() {
    let title = this.config.Title;
    if (title) {
      this.config.InputParameters.forEach(input => {
        title = title.replace('{{' + input.id + '}}', this.getParameterValue(input.id, input.initValue ?? ' ').value);
      });
    }

    this.config.DisplayTitle = title ?? '';
  }

  getParameterValue(inputParameterId: string, defaultValue: any): { parameterHasValue: boolean, value: any } {
    let result = { parameterHasValue: false, value: defaultValue };
    let parameter = this.config.InputParameters.find(p => p.id == inputParameterId);

    if (parameter) {
      if (this.inputParameterValues.has(inputParameterId)) {
        let resultValue = this.inputParameterValues.get(inputParameterId);
        if (resultValue != undefined && resultValue != null && !Number.isNaN(resultValue)) {
          result.value = resultValue;
          result.parameterHasValue = true;
        }
        else {
          result.parameterHasValue = parameter.mandatory;
        }
      }
      else {
        result.parameterHasValue = parameter.mandatory;
      }
    }
    else {
      this.preQueryParameterValues.forEach((value: any, key: WidgetOutputParameter) => {
        if (key.name == inputParameterId) {
          result.value = value;
        }
      });
      result.parameterHasValue = true;
    }

    return result;
  }

  inputParametersHasValue(mandatoryOnly: boolean = false): boolean {
    let result = true;

    this.config.InputParameters.forEach((input) => {
      let value = this.inputParameterValues.get(input.id);
      if (value == undefined || value == null || value == NaN) {
        if (!mandatoryOnly || (mandatoryOnly && input.mandatory)) {
          result = false;
        }
      }
    });

    return result;
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  getLegendPostion(): string {
    let result: string = 'left';
    switch (this.config.LegendPosition) {
      case LegendPosition.LEFT:
        result = 'left';
        break;

      case LegendPosition.TOP:
        result = 'top';
        break;

      case LegendPosition.RIGHT:
        result = 'right';
        break;

      case LegendPosition.BOTTOM:
        result = 'bottom';
        break;
    }

    return result;
  }

  getLegendAlignment(): string {
    let result: string = 'center';
    switch (this.config.LegendAlignment) {
      case LegendAlignment.START:
        result = 'start';
        break;

      case LegendAlignment.CENTER:
        result = 'center';
        break;

      case LegendAlignment.END:
        result = 'end';
        break;
    }

    return result;
  }

  resetPreQuery() {
    this.preQueryDataByLevel = undefined;
    this.preQueryParameterValues = new Map<WidgetOutputParameter, any>();
  }

  async requestBaseQueryResult(getBaseQuery: () => any, getBaseQueryResult: (query: any) => Promise<BaseQueryResult>, queryConfig: BaseQueryConfigInterface, datafilters: DataFilter[], forcereload: boolean = false, sender: string = '', senderId: string = ''): Promise<BaseQueryResult> {
    if (this.config.WidgetPreQueryConfigs?.length > 0) {
      let preQueryConfigByLevelTmp: Map<number, WidgetPreQueryConfig[]> = ArrayUtils.GroupBy(this.config.WidgetPreQueryConfigs.sort((a, b) => a > b ? -1 : 1), 'Level');
      let preQueryConfigByLevel = Object.entries(preQueryConfigByLevelTmp).map(([level, configs]) => ({ level, configs }));

      this.requestCount = 0;

      if (!this.preQueryDataByLevel) {
        this.preQueryDataByLevel = new Map<number, PreQueryData[]>();
        this.minLevel = -10000;
        this.maxLevel = -10000;
        let prevLevel: number = -10000;

        await ArrayUtils.AsyncForEach(preQueryConfigByLevel, async ({ level, configs }) => {
          level = parseInt(level);
          if (prevLevel > -10000) {
            let levelPreQueryDatas = this.preQueryDataByLevel.get(prevLevel);
            let preQueryDatas: Map<number, PreQueryData> = new Map<number, PreQueryData>();

            await this.executePreQuery(0, levelPreQueryDatas, preQueryDatas, configs, forcereload, sender, senderId);

            let datas: PreQueryData[] = [];
            preQueryDatas.forEach(item => datas.push(item));
            this.preQueryDataByLevel.set(level, datas);
          }
          else {
            let preQueryDatas: PreQueryData[] = [];
            this.minLevel = level;

            let preQueryInfos: { query: BaseQuery, config: WidgetPreQueryConfig, index: number, preQueryParameterValues: Map<WidgetOutputParameter, any> }[] = [];
            for (let i = 0; i < configs.length; i++) {
              let preQueryConfig = configs[i];
              let preQuery = this.getPreQueryQuery(preQueryConfig);
              let preQueryParameterValues = new Map<WidgetOutputParameter, any>();
              this.preQueryParameterValues.forEach((value: any, key: WidgetOutputParameter) => preQueryParameterValues.set(key, value));
              preQueryInfos.push({ query: preQuery, config: preQueryConfig, index: i, preQueryParameterValues: preQueryParameterValues });
            }

            let { results, errors } = await PromisePool
              .withConcurrency(this.config.maxConcurrentRequests)
              .for(preQueryInfos)
              .process(async (preQueryInfo, index, pool) => {
                try {
                  let preResult = await this.xprojClient.RequestQueryBaseQuery(preQueryInfo.query, true, sender, senderId);
                  let preQueryData = this.getPreQueryData(preResult, preQueryInfo.config, preQueryInfo.preQueryParameterValues);
                  preQueryDatas.push(preQueryData);
                }
                catch (err) {
                  this.logger.debug(err);
                }
              });

            this.preQueryDataByLevel.set(level, preQueryDatas);
          }
          prevLevel = level;
        });
        this.maxLevel = prevLevel;
      }

      let queriesInfos: { query: BaseQuery, preQueryParameterValues: { outputParameter: WidgetOutputParameter, value: any }[] }[] = [];
      await this.getExecuteQueries(this.maxLevel, 0, false, getBaseQuery, datafilters, queryConfig, queriesInfos);

      let { results, errors } = await PromisePool
        .withConcurrency(this.config.maxConcurrentRequests)
        .for(queriesInfos)
        .process(async (queriesInfo, index, pool): Promise<{ queryResult: BaseQueryResult, preQueryParameterValues: { outputParameter: WidgetOutputParameter, value: any }[] }> => {
          try {
            let queryResult = await getBaseQueryResult(queriesInfo.query);
            if (queryResult) {
              return { queryResult: queryResult, preQueryParameterValues: queriesInfo.preQueryParameterValues };
            }
          }
          catch (error) {
            this.logger.error('requestBaseQuery', error);
          }

          throw new Error("No query result");
        })

      this.logger.debug('requestBaseQuery', queriesInfos.length, results);
      return this.getQueryResult(results);
    }
    else {
      return getBaseQueryResult(await getBaseQuery());
    }
  }

  getQueryResult(queryResults: { queryResult: BaseQueryResult, preQueryParameterValues: { outputParameter: WidgetOutputParameter, value: any }[] }[]): BaseQueryResult {

    let result: BaseQueryResult;
    queryResults.forEach(queryResult => {
      queryResult.queryResult = MsgPackCloneObject(queryResult.queryResult) as BaseQueryResult;
      if (queryResult.preQueryParameterValues) {
        queryResult.preQueryParameterValues.forEach(paramValue => {
          let column = new BaseQueryOutputColumnDescription();
          column.columnname = paramValue.outputParameter.name;
          column.columnoutname = paramValue.outputParameter.name;
          column.datatype = Object.values(XDataType).indexOf(XDataType[paramValue.outputParameter.datatype]);
          switch (paramValue.outputParameter.datatype) {
            case OutputDataType.Float32:
            case OutputDataType.Float64:
            case OutputDataType.UInt8:
            case OutputDataType.Int32:
            case OutputDataType.Int64:
            case OutputDataType.Number:
              column.indexintypedvector = queryResult.queryResult.datanumbers.length;
              queryResult.queryResult.columns.push(column);
              let columnValues: number[] = [];
              let nrPointsNumber = this.xprojClient.GetNrOfPoints(queryResult.queryResult);
              for (let i = 0; i < nrPointsNumber; i++) {
                columnValues.push(paramValue.value);
              }
              queryResult.queryResult.datanumbers.push(columnValues);
              break;

            case OutputDataType.String:
              column.indexintypedvector = queryResult.queryResult.datastrings.length;
              queryResult.queryResult.columns.push(column);
              let stringColumnValues: string[] = [];
              let nrPointsString = this.xprojClient.GetNrOfPoints(queryResult.queryResult);
              for (let i = 0; i < nrPointsString; i++) {
                stringColumnValues.push(paramValue.value);
              }
              queryResult.queryResult.datastrings.push(stringColumnValues);
              break;

            case OutputDataType.Timestamp:
              column.indexintypedvector = queryResult.queryResult.datatimestamps.length;
              queryResult.queryResult.columns.push(column);
              let timestampColumnValues: Date[] = [];
              let nrPointsTimestamp = this.xprojClient.GetNrOfPoints(queryResult.queryResult);
              for (let i = 0; i < nrPointsTimestamp; i++) {
                timestampColumnValues.push(paramValue.value);
              }
              queryResult.queryResult.datatimestamps.push(timestampColumnValues);
              break;
          }
        });
      }

      if (!result) {
        result = queryResult.queryResult;
      }
      else {
        result.nrpoints += queryResult.queryResult.nrpoints;
        for (let i = 0; i < queryResult.queryResult.datanumbers.length; i++) {
          for (let ii = 0; ii < queryResult.queryResult.datanumbers[i].length; ii++) {
            result.datanumbers[i].push(queryResult.queryResult.datanumbers[i][ii]);
          }
        }
        for (let i = 0; i < queryResult.queryResult.datastrings.length; i++) {
          for (let ii = 0; ii < queryResult.queryResult.datastrings[i].length; ii++) {
            result.datastrings[i].push(queryResult.queryResult.datastrings[i][ii]);
          }
        }
        for (let i = 0; i < queryResult.queryResult.datatimestamps.length; i++) {
          for (let ii = 0; ii < queryResult.queryResult.datatimestamps[i].length; ii++) {
            result.datatimestamps[i].push(queryResult.queryResult.datatimestamps[i][ii]);
          }
        }
      }
    });

    if (!result) {
      result = new BaseQueryResult();
      result.columns = [];
      result.datanumbers = [];
      result.datastrings = [];
      result.datatimestamps = [];
      result.nroriginalpoints = 0;
      result.nrpoints = 0;
    }

    return result;
  }

  async executePreQuery(start: number, prevPreQueryDatas: PreQueryData[], result: Map<number, PreQueryData>, configs: WidgetPreQueryConfig[],
    forcereload: boolean = false, sender: string = '', senderId: string = '') {

    let preQueryInfos: { query: BaseQuery, config: WidgetPreQueryConfig, index: number, preQueryParameterValues: Map<WidgetOutputParameter, any> }[] = [];
    await this.getPreQueries(start, prevPreQueryDatas, preQueryInfos, configs);

    let { results, errors } = await PromisePool
      .withConcurrency(this.config.maxConcurrentRequests)
      .for(preQueryInfos)
      .process(async (preQueryInfo, index, pool) => {
        try {
          let preResult = await this.xprojClient.RequestQueryBaseQuery(preQueryInfo.query, true, sender, senderId);
          let preQueryData = this.getPreQueryData(preResult, preQueryInfo.config, preQueryInfo.preQueryParameterValues);

          if (!result.has(preQueryInfo.index)) {
            result.set(preQueryInfo.index, preQueryData);
          }
          else {
            let data = result.get(preQueryInfo.index);
            for (let i2 = 0; i2 < preQueryData.columns.length; i2++) {
              preQueryData.columns[i2].values.forEach(value => {
                data.columns[i2].values.push(value);
              });
            }
            data.count += preQueryData.count;
          }
        }
        catch (err) {
          this.logger.debug(err);
        }
      });
  }

  async getPreQueries(start: number, prevPreQueryDatas: PreQueryData[], result: { query: BaseQuery, config: WidgetPreQueryConfig, index: number, preQueryParameterValues: Map<WidgetOutputParameter, any> }[], configs: WidgetPreQueryConfig[]) {
    if (start < prevPreQueryDatas.length) {
      let prevPreQueryData = prevPreQueryDatas[start];

      for (let i = 0; i < prevPreQueryData.count; i++) {
        this.setInputParameterValues(prevPreQueryData, i);
        if (start < prevPreQueryDatas.length - 1) {
          await this.getPreQueries(start + 1, prevPreQueryDatas, result, configs);
        }
        else {
          for (let i = 0; i < configs.length; i++) {
            let preQueryConfig = configs[i];
            let preQuery = this.getPreQueryQuery(preQueryConfig);
            let preQueryParameterValues = new Map<WidgetOutputParameter, any>();
            this.preQueryParameterValues.forEach((value: any, key: WidgetOutputParameter) => preQueryParameterValues.set(key, value));
            result.push({ query: preQuery, config: preQueryConfig, index: i, preQueryParameterValues: preQueryParameterValues });
          }
        }
      }
    }
  }

  async getExecuteQueries(level: number, start: number, foundActive : boolean, getBaseQuery: () => any, datafilters: DataFilter[], queryConfig: BaseQueryConfigInterface,
    result: { query: BaseQuery, preQueryParameterValues: { outputParameter: WidgetOutputParameter, value: any }[] }[]) {
    let doQueryResult = true;
    if (this.preQueryDataByLevel.has(level)) {
      let preQueryDatas = this.preQueryDataByLevel.get(level);
      if (start < preQueryDatas.length) {
        let prevPreQueryData = preQueryDatas[start];
        for (let i = 0; i < prevPreQueryData.count; i++) {
          let active = false;
          for (let colIndex = 0; colIndex < prevPreQueryData.columns.length; colIndex++) {
            if (this.outputColumnActive(prevPreQueryData.columns[colIndex], datafilters, queryConfig)) {
              this.setInputParameterValues(prevPreQueryData, i);
              foundActive = true;
              active = true;
            }
          }
          if (active || !foundActive) {
            if (start < preQueryDatas.length - 1) {
              doQueryResult = false;
              await this.getExecuteQueries(level, start + 1, foundActive, getBaseQuery, datafilters, queryConfig, result);
            }
            else if (active) {
              doQueryResult = false;
              await this.getExecuteQueries(level - 1, 0, foundActive, getBaseQuery, datafilters, queryConfig, result);
            }
          }
        }
      }
    }

    if (level <= this.minLevel) {
      if (doQueryResult) {
        try {
          let query = getBaseQuery();
          if (query) {
            let paramValues: { outputParameter: WidgetOutputParameter, value: any }[] = [];
            this.preQueryParameterValues.forEach((value: any, key: WidgetOutputParameter) => {
              paramValues.push({ outputParameter: key, value: value });
            });
            result.push({ query: query, preQueryParameterValues: paramValues });
          }
        }
        catch { }
      }
    }
    else {
      if (doQueryResult) {
        await this.getExecuteQueries(level - 1, 0, false, getBaseQuery, datafilters, queryConfig, result);
      }
    }
  }

  outputColumnActive(column: PreQueryDataColumn, datafilters: DataFilter[], queryConfig: BaseQueryConfigInterface) {
    if (queryConfig.UseProjectionInputParameter && queryConfig.ProjectionInputParameterId == column.parameterId) {
      return true;
    }
    if (queryConfig.UseTransformInputParameter && queryConfig.TransformInputParameterId == column.parameterId) {
      return true;
    }

    if (queryConfig.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT && queryConfig.GroupInputParameterId == column.parameterId) {
      return true;
    }
    if (queryConfig.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS && queryConfig.GroupInputParameterIds.findIndex(g => g == column.parameterId) > -1) {
      return true;
    }

    let result = false;
    datafilters.forEach(filter => {
      if (filter.InputParameterId == column.parameterId || filter.InputParameterId2 == column.parameterId || filter.InputParameterId3 == column.parameterId) {
        result = true;
      }
    });

    return result;
  }

  getPreQueryQuery(queryConfig: WidgetPreQueryConfig): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = queryConfig.ProjectionId;
    query.maxitems = queryConfig.MaxItems;
    query.targetgroup = queryConfig.Group;

    //Transformation input parameters
    if (queryConfig.UseGrouping && queryConfig.UseTransformInputParameter) {
      query.grouping.columntransformation = this.getParameterValue(queryConfig.TransformInputParameterId, queryConfig.GroupingTransform).value;
    }

    //Projection input parameters
    if (queryConfig.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(queryConfig.ProjectionInputParameterId, query.targetprojectionid).value;
    }

    //Group input parameters
    if (queryConfig.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(queryConfig.GroupInputParameterId, query.targetgroup).value;
    }
    else if (queryConfig.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      queryConfig.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    WidgetUtils.SetQueryFilterValues(query, queryConfig.DataFilters, (i, d) => this.getParameterValue(i, d));

    let addedColumns = {};
    let colindex = 0;
    for (let column of queryConfig.ColumnConfigs) {
      if (addedColumns[column.ColumnName]) {
        continue;
      }
      addedColumns[column.ColumnName] = true;
      let col = new BaseQueryInputColumnDescription();
      col.columnname = column.ColumnName;
      if (column.Label?.length > 0) {
        col.columnoutname = column.ColumnOutName = column.Label;
      }
      else {
        col.columnoutname = column.ColumnOutName = queryConfig.UseGrouping ? "col_" + colindex.toString() : column.ColumnName;
      }

      if (colindex == 0) {
        if (!queryConfig.UseGrouping) {
          query.sorting.columnname = col.columnname;
          query.sorting.descending = false;
          query.grouping = new ColumnGroupingDescription();
          query.columns.push(col);
        }
        else {
          query.grouping.columnname = col.columnname;
          query.grouping.columntransformation = queryConfig.GroupingTransform;
          query.grouping.columnoutname = col.columnoutname;
        }
      }
      else {
        if (queryConfig.UseGrouping) {
          col.columnaggregation = column.Transform;
        }
        query.columns.push(col);
      }

      colindex++;
    }

    let scol = query.columns.find(c => c.columnname == queryConfig.DefaultSortColumnName);
    if (scol) {
      if (queryConfig.UseGrouping) {
        query.sorting.columnname = scol.columnoutname;
      }
      else {
        query.sorting.columnname = scol.columnname;
      }
    }

    query.sorting.descending = queryConfig.DefaultSortDescending;

    return query;
  }

  getPreQueryData(queryResult: BaseQueryResult, configQuery: WidgetPreQueryConfig, preQueryParameterValues: Map<WidgetOutputParameter, any>): PreQueryData {
    let result = new PreQueryData();
    result.configQuery = configQuery;

    let numericaldata = queryResult.datanumbers;
    let timestampdata = queryResult.datatimestamps;
    let stringdata = queryResult.datastrings;

    let paramValues: { outputParameter: WidgetOutputParameter, value: any }[] = [];
    preQueryParameterValues.forEach((value: any, key: WidgetOutputParameter) => {
      paramValues.push({ outputParameter: key, value: value });
    });

    for (let i = 0; i < queryResult.columns.length; i++) {
      let it = queryResult.columns[i];
      let typ = it.datatype;
      let data = [];
      let colname = it.columnoutname;
      let columnConfig = configQuery.ColumnConfigs.find(c => c.ColumnOutName == colname);

      if (typ == XDataType.Number) {
        if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
          data = numericaldata[it.indexintypedvector];
        }
        else {
          data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
        }
      }
      if (typ == XDataType.String) {
        data = stringdata[it.indexintypedvector];
      }
      if (typ == XDataType.Timestamp) {
        data = timestampdata[it.indexintypedvector];
      }

      if (i == 0) {
        result.count = data.length;
      }

      let colData = new PreQueryDataColumn();
      colData.columnname = colname;
      colData.columnOutName = it.columnoutname; //columnConfig?.Label?.length > 0 ? columnConfig.Label : colname;
      colData.values = [{ paramValues: paramValues, data: data }];
      colData.projectionid = configQuery.ProjectionId;
      colData.group = configQuery.Group;
      colData.datatype = it.datatype;
      colData.output = columnConfig?.Output;
      colData.id = columnConfig?.Id;
      colData.parameterId = (configQuery.Prefix?.length > 0 ? configQuery.Prefix : configQuery.Name) + ':' + columnConfig.ColumnOutName;
      // (columnConfig.Label?.length > 0 ? columnConfig.Label : columnConfig.ColumnName);
      // colData.editMode = columnConfig?.EditMode;
      // colData.enumMembers = columnConfig?.EnumMembers;

      result.columns.push(colData);
    }

    return result;
  }

  setInputParameterValues(preQueryData: PreQueryData, index: number) {
    preQueryData.configQuery.OutputParameters.forEach(outParam => {
      let preQueryColumnData = preQueryData.columns.find(c => c.id == outParam.id);
      if (preQueryColumnData) {
        let i = 0;
        preQueryColumnData.values.forEach(value => {
          value.data.forEach(data => {
            if (i == index) {
              this.preQueryParameterValues.set(outParam, data);
              value.paramValues.forEach(paramValue => {
                this.preQueryParameterValues.set(paramValue.outputParameter, paramValue.value);
              });
            }
            i++;
          });
        })
      }
      // if (preQueryColumnData && preQueryColumnData.data.length > index) {
      //   this.preQueryParameterValues.set(outParam, preQueryColumnData.data[index]);
      // }
    });
    // preQueryData.paramValues.forEach(paramValue => {
    //   this.preQueryParameterValues.set(paramValue.outputParameter, paramValue.value);
    // });

  }

  getDatasetIndex(str: string): number {
    let i, l,
      hval = 0x812c4dc5;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return hval >>> 0;
  }

}
