<h1>XAutomation Settings</h1>

<clr-accordion [clrAccordionMultiPanel]="true">

    <clr-accordion-panel >
      <clr-accordion-title>Backup &amp; Restore configuration</clr-accordion-title>
      <clr-accordion-content>
        <xproj-xdb-backup></xproj-xdb-backup>
      </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>
