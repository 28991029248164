import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GrpcDataSourceDefinition, GrpcDataSourceInstance, GrpcNodeType, SearchNodesRequest, SearchNodesResponse, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { EditTreenodeComponent } from '../edit-treenode/edit-treenode.component';
import { XprojAlertService } from 'xproj-lib';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { NGXLogger } from 'ngx-logger';

export class SearchForm {
  id: string;
  properties: SearchProperty[] = [];
}

@Component({
  selector: 'app-configuration-search',
  templateUrl: './configuration-search.component.html',
  styleUrls: ['./configuration-search.component.scss']
})
export class ConfigurationSearchComponent implements OnInit {
  @ViewChild("editTreeNode", { read: EditTreenodeComponent, static: false }) editTreeNode: EditTreenodeComponent;

  @Input() customerId: string = '';
  @Input() dataSourceInstanceId: string;
  @Input() dataSourceInstance: GrpcDataSourceInstance;

  @Output() viewTreeNode = new EventEmitter<{ nodeId : string, nodeTypeLabel : string }>();

  dataSourceDefinition: GrpcDataSourceDefinition;
  nodeTypes: GrpcNodeType[] = [];

  searchForm: SearchForm = new SearchForm();
  searchResult: SearchNodesResponse;
  searchableKeys : {key: string, name: string, type: string}[] = [];

  showEditModal: boolean = false;
  currentEditNode: GrpcNode;
  currentEditNodeId: string = '';

  selectedProperty: SearchProperty;

  searching: boolean = false;

  constructor(private xConfClient: XProjectorXConfClient,
    private logger: NGXLogger,
    private alertService: XprojAlertService,) { }

  async ngOnInit() {
    this.dataSourceDefinition = await this.xConfClient.getDataSourceDefinition(this.dataSourceInstance.dataSourceDefinitionId, true);
    let allNodeTypes = await this.xConfClient.getNodeTypes()

    this.nodeTypes = allNodeTypes.filter(nt => {
      let result = false;
      this.dataSourceDefinition.treeNodeDefinitions.forEach(t => {
        if (t.treeNodeReferenceDefinitions.findIndex(tref => tref.nodeTypeId == nt.id) > -1) {
          result = true;
        }
      })
      return result;
    });

    this.searchableKeys = [];
    this.nodeTypes.forEach(nt => {
      nt.stringProperties.forEach(p => this.searchableKeys.push({key: p.id, name: p.header, type: p.typeName}));
    });
  }

  clear() {
    this.searchForm = new SearchForm();
    this.searchResult = null;
  }

  async search() {
    try {
      this.searching = true;
      let request = new SearchNodesRequest();
      request.rootId = this.dataSourceInstance.rootNodeInstances[0].id;
      request.rootLabel = this.dataSourceInstance.rootNodeInstances[0].label;
      request.limit = 1000;
      request.maxHops = -1;
      request.skip = 0;

      if (this.searchForm.id?.length > 0) {
        let idSearchProperty = new SearchProperty();
        idSearchProperty.typeName = 'string';
        idSearchProperty.key = '_id';
        idSearchProperty.value = this.searchForm.id;
        idSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(idSearchProperty);
      }

      this.searchForm.properties.forEach(p => {
        let searchProperty = new SearchProperty();
        searchProperty.typeName = p.typeName;
        searchProperty.key = p.key;
        searchProperty.value = p.value;
        searchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(searchProperty);
      });

      if (request.properties.length > 0) {
        this.searchResult = await this.xConfClient.searchNodes(request)
        this.logger.info('searchResult', this.searchResult);
      }
    }
    finally {
      this.searching = false;
    }
  }

  editNode(node: GrpcNode) {
    this.currentEditNodeId = node.id;
    this.currentEditNode = node;
    this.showEditModal = true;
  }

  viewInTree(node: GrpcNode) {
    this.viewTreeNode?.next({
      nodeId: node.id,
      nodeTypeLabel : node.nodeTypeLabel
    });

  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xConfClient.updateNode(node, oldId, '', this.customerId);
    return result.result;
  }

  async saveEditNode() {
    if (this.editTreeNode) {
      this.editTreeNode.savePropertyValues();
      let result = await this.updateNode(this.currentEditNode, this.currentEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditModal = false;
  }

  closeEditNode() {
    this.showEditModal = false;
  }

  addSearchProperty() {
    let property = new SearchProperty();
    property.typeName = 'string';
    this.searchForm.properties.push(property);
  }

  removeSearchProperty() {
    this.searchForm.properties = this.searchForm.properties.filter(p => p != this.selectedProperty);
    this.selectedProperty = null;
  }
}
