import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalInteractService {

  eventsUpdateSubjec: Subject<{ pageId: string, count: number }> = new Subject();



  public EventsUpdate(pageId: string, count: number) {
    this.eventsUpdateSubjec.next({ pageId: pageId, count: count });
  }
}
