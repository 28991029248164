<main class="content-area">
    <router-outlet></router-outlet>
</main>

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">

    <clr-combobox-container style="margin-left: 2em; margin-bottom: 2em;" class="sensitive">
        <label>Select trustee:</label>
        <clr-combobox [(ngModel)]="selectedTrustee" name="trusteeselect" (ngModelChange)="currentTrusteeChanged($event)" (clrOpenChange)="trusteeSelectOpenChange($event)">
            <clr-options>
                <clr-option *clrOptionItems="let trustee of rossakerState.trustees; field:'name'" [clrValue]="trustee">
                    {{trustee.name}}
                </clr-option>
            </clr-options>
        </clr-combobox>
    </clr-combobox-container>



    <clr-vertical-nav-group-children>
        <!-- <a clrVerticalNavLink [routerLink]="['trusteeoverview']" routerLinkActive="active">
      Trustees overview
    </a> -->
        <a clrVerticalNavLink [routerLink]="['trusteeadmin']" routerLinkActive="active">
            <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
            Billing tenants
        </a>
    </clr-vertical-nav-group-children>

    <!-- 
  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Trustees

    <clr-vertical-nav-group-children>
      // <a clrVerticalNavLink [routerLink]="['trusteeoverview']" routerLinkActive="active">
        // Trustees overview
      // </a> 
      <a clrVerticalNavLink [routerLink]="['trusteeadmin']" routerLinkActive="active">
        Trustees view
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group> -->

</clr-vertical-nav>