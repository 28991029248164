import { Chart, Ticks } from 'chart.js';
import { ChartWidgetQuery } from '../widgets/chart/chart-widget-config/xproj-chart-widget-config-service';
import { SpcQuery } from '../widgets/spc/spc-widget-config/spc-config-service';
import { SpectrumAnalyzerQuery } from '../widgets/spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { Transformation } from '../XProjector/xprojector-client-service';

export class ChartUtils {

  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
  public static FormatLabel(that, value, index, ticks) {
    try {
      let absMax: number = 0;
      ticks.forEach(l => {
        if (Math.abs(l) > absMax) {
          absMax = Math.abs(l);
        }
      });
      if (absMax == 0) {
        return Ticks.formatters.numeric.apply(that, [value, index, ticks]);
      }
      else if (absMax >= 10000) {
        return new Intl.NumberFormat(undefined, {
          //@ts-ignore
          notation: "compact",
          //@ts-ignore
          compactDisplay: "short"
        }).format(value);
      }
      else {
        //return Ticks.formatters.numeric.apply(that, [value, index, ticks]);
        return new Intl.NumberFormat().format(value);
      }
    }
    catch {
      //return Ticks.formatters.numeric.apply(that, [value, index, ticks]);
      return value;
    }
  }

  public static GetBarsXAxisUnit(transform: Transformation): any {
    switch (transform) {
      case Transformation.TIMESTAMP_RESOLUTION_YEAR:
        return 'year'

      case Transformation.TIMESTAMP_DAYOFMONTH:
      case Transformation.TIMESTAMP_DAYOFWEEK:
      case Transformation.TIMESTAMP_DAYOFYEAR:
      case Transformation.TIMESTAMP_RESOLUTION_DAY:
        return 'day';

      case Transformation.TIMESTAMP_HOURS:
      case Transformation.TIMESTAMP_RESOLUTION_HOUR:
        return 'hour';

      case Transformation.TIMESTAMP_MINUTES:
      case Transformation.TIMESTAMP_MINUTES_OF_DAY:
      case Transformation.TIMESTAMP_RESOLUTION_MINUTE:
        return 'minute';

      case Transformation.TIMESTAMP_RESOLUTION_MONTH:
        return 'month';

      case Transformation.TIMESTAMP_RESOLUTION_SECOND:
      case Transformation.TIMESTAMP_SECONDS:
      case Transformation.TIMESTAMP_SECONDS_OF_DAY:
        return 'second';

      default:
        return false;
    }
  }

  public static GetBarsXAxisLabels(labels: any[], transform: Transformation): any {
    switch (transform) {
      case Transformation.TIMESTAMP_DAYOFWEEK:
        if (labels.length == 7) {
          //TODO : Settings for when week begins
          return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        }
        else {
          return labels;
        }

      default:
        return labels;
    }
  }

  public static GetXAxisTypeAndLabels(query: ChartWidgetQuery | SpectrumAnalyzerQuery | SpcQuery, data_x : number[] = []): { type: string, labels: string[] } {
    switch (query.XaxisTransform) {

      case Transformation.TIMESTAMP_DAYOFMONTH:
      case Transformation.TIMESTAMP_DAYOFWEEK :
      case Transformation.TIMESTAMP_DAYOFYEAR :
      case Transformation.TIMESTAMP_MINUTES_OF_DAY :
      case Transformation.TIMESTAMP_SECONDS_OF_DAY :
        return { type: 'category', labels: [] };

      case Transformation.TIMESTAMP_HOURS:
        let hourlabels : string[] = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
              '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

        if (data_x.length == 0 || data_x.length == 24) {
          return { type: 'category', labels: hourlabels };
        }
        else {
          let labels = [];
          for (let x of data_x) {
            if (x >= 0 && x < 24) {
              labels.push(hourlabels[x]);
            }
          }

          return { type: 'category', labels: labels };
        }

      // case Transformation.TIMESTAMP_MINUTES :
      // case Transformation.TIMESTAMP_MINUTES_OF_DAY :
      // case Transformation.TIMESTAMP_RESOLUTION_MINUTE:
      //   return 'minute';

      // case Transformation.TIMESTAMP_RESOLUTION_MONTH:
      //   return 'month';

      // case Transformation.TIMESTAMP_RESOLUTION_SECOND:
      // case Transformation.TIMESTAMP_SECONDS:
      // case Transformation.TIMESTAMP_SECONDS_OF_DAY:
      //   return 'second';

      default:
        return { type: 'linear', labels: [] };
    }
  }

  // public static GetXAxisTypeAndLabels(query: ChartWidgetQuery | SpectrumAnalyzerQuery): { type: string, labels: string[] } {
  //   switch (query.XaxisTransform) {

  //     case Transformation.TIMESTAMP_DAYOFMONTH:
  //       // return {
  //       //   type: 'category', labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
  //       //     '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
  //       // };
  //     case Transformation.TIMESTAMP_DAYOFWEEK :

  //     //   return {type: 'category', labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']};
  //     case Transformation.TIMESTAMP_DAYOFYEAR :
  //     //case Transformation.TIMESTAMP_RESOLUTION_DAY :
  //       return { type: 'category', labels: [] };

  //     case Transformation.TIMESTAMP_HOURS:
  //       //case Transformation.TIMESTAMP_RESOLUTION_HOUR:
  //       return {
  //         type: 'category', labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
  //           '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
  //       };

  //     // case Transformation.TIMESTAMP_MINUTES :
  //     // case Transformation.TIMESTAMP_MINUTES_OF_DAY :
  //     // case Transformation.TIMESTAMP_RESOLUTION_MINUTE:
  //     //   return 'minute';

  //     // case Transformation.TIMESTAMP_RESOLUTION_MONTH:
  //     //   return 'month';

  //     // case Transformation.TIMESTAMP_RESOLUTION_SECOND:
  //     // case Transformation.TIMESTAMP_SECONDS:
  //     // case Transformation.TIMESTAMP_SECONDS_OF_DAY:
  //     //   return 'second';

  //     default:
  //       return { type: 'linear', labels: [] };
  //   }
  // }
}
