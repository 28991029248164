import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { XprojModule } from 'xproj-lib';
import { ClarityModule } from '@clr/angular';
import { AngularSplitModule } from 'angular-split';
import { UserProfileComponent } from './components/user-profile/user-profile.component'
import { UserProfilesComponent } from './components/user-profiles/user-profiles.component'
import { CreateCredentialsComponent } from './components/create-credentials/create-credentials.component';
import { CustomerSettingsComponent } from './components/customer-settings/customer-settings.component';
import { GdprConsentComponent } from './components/gdpr-consent/gdpr-consent.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    UserProfileComponent,
    UserProfilesComponent,
    CreateCredentialsComponent,
    CustomerSettingsComponent,
    GdprConsentComponent,
    LoginComponent,
    ResetPasswordComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    XprojModule,
    ClarityModule,
    AngularSplitModule,
    RouterModule,
    QRCodeModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    XprojModule,
    AngularSplitModule,

    UserProfileComponent,
    UserProfilesComponent,
    CreateCredentialsComponent,
    CustomerSettingsComponent,
    GdprConsentComponent,
    LoginComponent,
    ResetPasswordComponent
  ]
})
export class SharedModule { }
