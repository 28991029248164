import { TypedJSON } from 'typedjson';
import { jsonMember, jsonObject } from 'typedjson';

export enum RossakerBmsDataExportValueStatus {
  None = 0,
  AutomaticInput = 1,
  ManualInput = 2,
  MissingStartValue = 4,
  MissingEndValue = 8,
  MissingTariff = 16,
  AutomaticEstimate = 32,
  ManualEstimate = 64,
  Warning = 128,
  Error = 256,
  Signed = 512,
  Invoiced = 1024,
  InvoicedConfirmed = 2048,
  TariffExchange = 4096,
  MeterExchange = 8192,
  ResidentExchange = 16384,
  NegativeConsumption = 32768,
  HighConsumption = 65536,
  Test = 131072,
  LowConsumption = 262144
}

@jsonObject
export class RossakerBmsDataExportValue {
  @jsonMember
  id : number;

  @jsonMember
  customerId : string;

  @jsonMember
  meterId : number;

  @jsonMember
  start : Date;

  @jsonMember
  end : Date;

  @jsonMember
  startValue : number;

  @jsonMember
  endValue : number;

  @jsonMember
  nodeId : number;

  @jsonMember
  nodeTypeId : string;

  @jsonMember
  meterType : string;

  @jsonMember
  unit : string;

  @jsonMember
  tariff : number;

  @jsonMember
  vatRate : number;

  @jsonMember
  includeVAT : boolean;

  @jsonMember
  prefix : string;

  @jsonMember
  externalId : string;

  @jsonMember
  status : RossakerBmsDataExportValueStatus = RossakerBmsDataExportValueStatus.None;

  @jsonMember
  svLantApartmentNo : number;

  @jsonMember
  createdAt : Date;

  @jsonMember
  modifiedAt : Date;

  @jsonMember
  deleted : boolean = false;

  @jsonMember
  deletedAt : Date = new Date(0);

  diff : number;
}
