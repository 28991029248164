import { Component } from '@angular/core';

@Component({
  selector: 'xproj-editintegrationservers-opcua',
  templateUrl: './editintegrationservers-opcua.component.html',
  styleUrls: ['./editintegrationservers-opcua.component.scss']
})
export class EditintegrationserversOpcuaComponent {

}
