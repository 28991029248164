export class LocationArea
{
  public position_x : number = 0;
  public position_y : number = 0;
  public size_x : number = 0;
  public size_y : number = 0;
}

export class Room
{
  public area : LocationArea = new LocationArea();
  public matchesSuffix : string = "";
}
export class Hallway
{
  public area : LocationArea = new LocationArea();
}

export class Floorplan
{
  public rooms : Room[] = [];
  public hallways: Hallway[] = [];
}
