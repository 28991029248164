<main class="content-area">
    <h1>External Connectors</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedConnector" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

            <clr-dg-column [clrDgField]="'Driver.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxautogroup'"> Default XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxgroup'"> Default XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} External Connectors
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addConnector()">
        <clr-icon shape="plus-circle "></clr-icon>
        New External connector
    </button>
    <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove connector
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedConnector">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Connector configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Driver ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.xautodriverid" name="xautodriverid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.name" name="drivername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.description" name="driverdescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxautogroup" name="driverdefaultxautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxgroup" name="driverdefaultxgroup" size="35" />
                    </clr-input-container>

                    <!-- <clr-input-container>
                        <label>Host</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.host" name="driverhost" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Port</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.MQTT.port" name="driverport" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Username</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.username" name="driverusername" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Password</label>
                        <input clrInput type="password" [(ngModel)]="selectedConnector.MQTT.password" name="driverpassword" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="drivervalidateservercertificate" [(ngModel)]="selectedConnector.MQTT.validateservercertificate" />
                            <label>Validate server certificate</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container> -->

                    <!--
                    <clr-select-container>
                        <label>Message Format</label>
                        <select clrSelect name="driverformat" [(ngModel)]="selectedConnector.MQTT.format">
                            <option [ngValue]="XAUTO_MQTTDataFormat.JSON">JSON</option>
                        </select>
                    </clr-select-container> -->


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="driverenabled" [(ngModel)]="selectedConnector.Driver.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>


                    <button class="btn" [clrLoading]="savingDriver" (click)="saveDriver()">Save</button>

                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Connector Variables</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedVariable" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

                        <clr-dg-column [clrDgField]="'enabled'"> Enabled </clr-dg-column>
                        <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
                        <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xautogroup'"> XAutoGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xgroup'"> XGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'defaultscriptname'"> Default scriptname </clr-dg-column>

                        <clr-dg-row *clrDgItems="let variable of selectedConnector.Variables" [clrDgItem]="variable">
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.enabled}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.name}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.description}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xautogroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xgroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.defaultscriptname}} </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[1]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Variables
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <button class="btn btn-link " (click)="addVariable()">
                    <clr-icon shape="plus-circle "></clr-icon>
                    New External variable
                </button>
                <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingVariable" (click)="removeVariable(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove variable
                </button>


                <form clrForm *ngIf="selectedVariable">
                    <clr-input-container>
                        <label>Variable ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautovariableid" name="variablexautovariableid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.name" name="variablename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.description" name="variabledescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautogroup" name="variablexautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xgroup" name="variablexgroup" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variablewritable" [(ngModel)]="selectedVariable.Variable.writable" />
                            <label>Writable</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-input-container>
                        <label>Sample interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.sampleintervalseconds" name="variablesampleinterval" size="35" />
                    </clr-input-container>



                    <!-- <clr-select-container>
                        <label>Modbus data type</label>
                        <select clrSelect name="drivermodbustype" [(ngModel)]="selectedVariable.Modbus.variabletype">
                            <option [ngValue]="XAUTO_ModbusVariableType.Bits">Bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int8">Signed Integer 8 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt8">Unsigned Integer 8 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int16">Signed Integer 16 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt16">Unsigned Integer 16 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int32">Signed Integer 32 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt32">Unsigned Integer 32 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int64">Signed Integer 64 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt64">Unsigned Integer 64 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Float32">Floating-Point 32 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.Float64">Floating-Point 64 bits</option>
                        </select>
                    </clr-select-container>

                    <clr-select-container>
                        <label>Register order</label>
                        <select clrSelect name="drivermodbustype" [(ngModel)]="selectedVariable.Modbus.variableregisterorder">
                            <option [ngValue]="XAUTO_ModbusVariableRegisterOrder.LEFT_TO_RIGHT">Left to right</option>
                            <option [ngValue]="XAUTO_ModbusVariableRegisterOrder.RIGHT_TO_LEFT">Right to left</option>
                        </select>
                    </clr-select-container>

                    <clr-select-container>
                        <label>Byte order (inside register)</label>
                        <select clrSelect name="drivermodbustype" [(ngModel)]="selectedVariable.Modbus.variablebyteorder">
                            <option [ngValue]="XAUTO_ModbusVariableByteOrder.LEFT_TO_RIGHT">Left to right</option>
                            <option [ngValue]="XAUTO_ModbusVariableByteOrder.RIGHT_TO_LEFT">Right to left</option>
                        </select>
                    </clr-select-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableinput" [(ngModel)]="selectedVariable.Modbus.input" />
                            <label>Input</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-input-container>
                        <label>Address</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Modbus.address" name="variableaddress" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Size</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Modbus.size" name="variablesize" size="35" />
                    </clr-input-container> -->


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableenabled" [(ngModel)]="selectedVariable.Variable.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form>

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main>
