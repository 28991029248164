<div *ngIf="widgetConfig.Layout == ContainerLayout.Cards">
  <div class="clr-row" [style.height.px]="widgetheight" style="overflow: scroll;">
    <div *ngFor="let container of containerWidgets" class="clr-col-lg-{{colSize}} clr-col-12">
      <div class="card">
        <div class="card-block">
          <xproj-container-widget-switch [config]="container.WidgetConfig" [typeId]="container.TypeId"
            [responsive]="responsive" [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from"
            [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight">
          </xproj-container-widget-switch>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="widgetConfig.Layout == ContainerLayout.Tabs" style="overflow: scroll;">
  <clr-tabs clrLayout="{{widgetConfig.TabsLayout == ContainerTabsLayout.Horizontal ? 'horizontal' : 'vertical'}}">
    <clr-tab *ngFor="let container of containerWidgets">
      <button clrTabLink>{{container.Name}}</button>
      <clr-tab-content *clrIfActive>
        <xproj-container-widget-switch [config]="container.WidgetConfig" [typeId]="container.TypeId"
          [responsive]="responsive" [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from"
          [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
        </xproj-container-widget-switch>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</div>

<div *ngIf="widgetConfig.Layout == ContainerLayout.Accordion" style="overflow: scroll;">
  <clr-accordion>
    <clr-accordion-panel *ngFor="let container of containerWidgets">
      <clr-accordion-title>{{container.Name}}
      </clr-accordion-title>
      <clr-accordion-content>
        <xproj-container-widget-switch [config]="container.WidgetConfig" [typeId]="container.TypeId"
          [responsive]="responsive" [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from"
          [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
        </xproj-container-widget-switch>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
</div>

<div *ngIf="widgetConfig.Layout == ContainerLayout.Carousel" class="carousel-container"
  [style.height.px]="widgetheight">
  <div>
    <clr-icon shape="angle" class="carousel-left" (click)="leftClick()"></clr-icon>
  </div>
  <div>
    <div class="clr-row" [style.height.px]="widgetheight" style="overflow: scroll;">
      <div *ngFor="let container of carouselWidgets" class="clr-col-lg-{{colSize}} clr-col-12">
        <div class="card">
          <div class="card-block">
            <xproj-container-widget-switch [config]="container.WidgetConfig" [typeId]="container.TypeId"
              [responsive]="responsive" [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from"
              [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight">
            </xproj-container-widget-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <clr-icon shape="angle" class="carousel-right" (click)="rightClick()"></clr-icon>
  </div>
</div>
