import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectionsWorkspaceComponent } from './components/projections-workspace/projections-workspace.component';
//import { FileLibraryComponent } from '@xprojectorfeatures/xconf/components/image-library/file-library.component';
import {  XProjListprojectionsComponent,
          XProjListdatasourcesComponent,
		  XProjEditdatasourceComponent,
		  XProjScriptinghelpComponent,
		  XProjEditScriptFilesComponent,
		  XProjEditprojectionComponent,
		  XProjWstestComponent,
      XProjLuadebuggerComponent} from 'xproj-lib';

const routes: Routes = [
  {
      path: '', component: ProjectionsWorkspaceComponent,

      children: [
        {
          path: 'databrowser',
          component: XProjWstestComponent
       },

        {
          path: 'projections',
          component: XProjListprojectionsComponent
        },
        {
          path: 'projections/:id',
          component: XProjEditprojectionComponent
        },
        {
          path: 'scripts',
          component: XProjEditScriptFilesComponent
        },
        {
          path:'scripthelp',
          component: XProjScriptinghelpComponent
        },
        {
          path: 'datasources',
          component: XProjListdatasourcesComponent
        },
        {
          path: 'datasources/:id',
          component: XProjEditdatasourceComponent
        },
        {
          path: 'debugger',
          component: XProjLuadebuggerComponent
        },
        {
          path: 'debugger/:id',
          component: XProjLuadebuggerComponent
        },        
        // {
        //   path: 'filelibrary',
        //   component: FileLibraryComponent
        // },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataConfigurationRoutingModule { }
