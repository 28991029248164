import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { XconfRoutingModule } from './xconf-routing.module';
import { EditNodeTypesComponent } from './components/edit-node-types/edit-node-types.component';
import { ConfigurationWorkspaceComponent } from './components/configuration-workspace/configuration-workspace.component';
import { EditTypePropertiesComponent } from './components/edit-type-properties/edit-type-properties.component';
import { EditEdgeTypesComponent } from './components/edit-edge-types/edit-edge-types.component';
import { EditDataSourceDefinitionsComponent } from './components/edit-datasource-definitions/edit-datasource-definitions.component';
import { EditDataSourceInstancesComponent } from './components/edit-datasource-instances/edit-datasource-instances.component';
import { ConfigurationDataSourceComponent } from './components/configuration-datasource/configuration-datasource.component';
import { ViewDataSourceInstanceComponent } from './components/view-datasource-instance/view-datasource-instance.component';
import { EditTreenodeComponent } from './components/edit-treenode/edit-treenode.component';
import { FileLibraryComponent } from './components/image-library/file-library.component';
import { ConfigurationSelectNodeComponent } from './components/configuration-select-node/configuration-select-node.component';
import { ConfigurationSearchComponent } from './components/configuration-search/configuration-search.component';
import { StringArrayInputComponent } from './components/string-array-input/string-array-input.component';
import { StringOptionInputComponent } from './components/string-option-input/string-option-input.component';
import { EditTreenodesComponent } from './components/edit-treenodes/edit-treenodes.component';
import { LogConfigComponent } from './components/log-config/log-config.component';
import { ReadoutTreenodeComponent } from './components/readout-treenode/readout-treenode.component';
import { ViewNodeLogsComponent } from './components/view-node-logs/view-node-logs.component';
import { GrpcEventtypePipe } from './components/view-node-logs/grpc-eventtype.pipe';

@NgModule({
  declarations: [
    EditNodeTypesComponent,
    ConfigurationWorkspaceComponent,
    EditTypePropertiesComponent,
    EditEdgeTypesComponent,
    EditDataSourceDefinitionsComponent,
    EditDataSourceInstancesComponent,
    ConfigurationDataSourceComponent,
    ViewDataSourceInstanceComponent,
    EditTreenodeComponent,
    FileLibraryComponent,
    ConfigurationSelectNodeComponent,
    ConfigurationSearchComponent,
    StringArrayInputComponent,
    StringOptionInputComponent,
    EditTreenodesComponent,
    LogConfigComponent,
    ReadoutTreenodeComponent,
    ViewNodeLogsComponent,
    GrpcEventtypePipe
  ],
  exports: [
    EditNodeTypesComponent,
    ConfigurationWorkspaceComponent,
    EditTypePropertiesComponent,
    EditEdgeTypesComponent,
    EditDataSourceDefinitionsComponent,
    EditDataSourceInstancesComponent,
    ConfigurationDataSourceComponent,
    ViewDataSourceInstanceComponent,
    EditTreenodeComponent,
    FileLibraryComponent,
    ConfigurationSelectNodeComponent,
    ConfigurationSearchComponent,
    StringOptionInputComponent,
    ViewNodeLogsComponent,
    GrpcEventtypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    XconfRoutingModule,
    SharedModule
  ]
})
export class XconfModule { }
