import { Component, Input, OnInit } from '@angular/core';
import { Transformation } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputTransformControllerConfig } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-transform-controller-config',
  templateUrl: './xproj-output-transform-controller-config.component.html',
  styleUrls: ['./xproj-output-transform-controller-config.component.scss']
})
export class XprojOutputTransformControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.transformConfig = value as XprojOutputTransformControllerConfig;
  }

  transformConfig : XprojOutputTransformControllerConfig;

  Transformation = Transformation

  constructor() { }

  ngOnInit(): void {
  }

}
