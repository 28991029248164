<clr-modal [(clrModalOpen)]="showEdit">
  <h3 class="modal-title">Edit</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Name:</label>
        <input clrInput required placeholder="name" name="name" type="text" [(ngModel)]="newname" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEdit = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showEdit = false; doUpdateTreeview()">Ok</button>
  </div>
</clr-modal>

<main class="content-area main-dashboard">
  <router-outlet></router-outlet>
</main>

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="navCollapsed">
  <clr-vertical-nav-group *ngFor="let tree of treeviews" routerLinkActive="false" (contextmenu)="onRightClick($event, tree)" (click)="selectItem(tree)">
    <clr-icon clrVerticalNavIcon shape="tree-view" *ngIf="collapsed"></clr-icon>

    <clr-dropdown [clrCloseMenuOnItemClick]="true" style="margin-right: 1em;">
      <!-- <button clrDropdownTrigger aria-label="Dropdown user button">
        <clr-icon shape="pencil" size="16"></clr-icon>
      </button> -->
      <clr-dropdown-menu *clrIfOpen="tree.openDropDown" clrPosition="bottom-left">
        <div aria-label="Dropdown header Action AddDashboard" clrDropdownItem (click)="addRoot(tree)">
          <clr-icon shape="plus" size="16"></clr-icon> Add dashboard
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="deleteTreeview(tree, true)">
          <clr-icon shape="trash" size="16"></clr-icon> Delete tree
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="renameTreeview(tree)">
          <clr-icon shape="pencil" size="16"></clr-icon> Rename tree
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
    {{tree.treeview.name}}
    <clr-vertical-nav-group-children *clrIfExpanded="tree.treeview.selectedNode != undefined">
      <div style="margin-left: 1em;">
        <app-admin-dashboard-treeview [treeview]="tree.treeview" [expanded]="false" [collapsed]="collapsed"></app-admin-dashboard-treeview>
      </div>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <div class="nav-divider"></div>

  <a clrVerticalNavLink routerLinkActive="active" (click)="addTreeview()" style="cursor: pointer;" >
    <clr-icon clrVerticalNavIcon shape="plus"></clr-icon>
    Add tree
  </a>

</clr-vertical-nav>
