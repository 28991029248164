import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, ColumnGroupingDescription, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, Transformation, XAUTO_XAutoProcessGraph, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { SvgColumnConfig, SvgWidgetConfig, SvgWidgetQuery, SvgThreshold, SvgTransform, SvgStyle } from '../svg-widget-config/xproj-svg-widget-config-service';
import { SVG } from '@svgdotjs/svg.js'
import { ArrayUtils } from '../../../utils/array-utils-service';
import { GroupSelectionTypes, OutputDataType, WidgetOutputParameter } from '../../widget-config-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { XAutoService } from '../../../xautomation/xauto.service';
import * as fengari from 'fengari-web';

export class SvgWidgetTransform {
  scaleY : number | string;
  scaleX : number | string;
  originY : number | string;
  originX : number | string;
  translateX : number | string;
  translateY : number | string;
  rotate : number | string;
  relative : boolean = false;
  active : boolean = false;
}

const isNumber = (val: any) => typeof val === "number" && val === val;

@Component({
  selector: 'xproj-svg-widget',
  templateUrl: './xproj-svg-widget.component.html',
  styleUrls: ['./xproj-svg-widget.component.scss']
})
export class XprojSvgWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  widgetConfig: SvgWidgetConfig;
  id: any;
  loading = false;
  private initiated : boolean = false;
  private updateSvgSize : boolean = true;
  private xautoGraph : XAUTO_XAutoProcessGraph = null;
  private xautoSvg = "";

  clicktimer: any;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    private xautoService : XAutoService,
    public widgetService: XprojWidgetService
  ) {
    super(logger, xprojClient, widgetService);
  }

  configureConfigFromXautoNonSplitted()
  {
    try
    {
      //this.widgetConfig = new SvgWidgetConfig();
      let query = new SvgWidgetQuery();

      query.ProjectionId = "XAUTO_VAL_NUM";
      query.MatchColumnName = "XAUTOVARID";
      query.UseColumnMatching = true;
      query.MaxItems = 10000;
      query.ValueColumnName = "VALUE";

      let parser = new DOMParser();
      let xml = parser.parseFromString(this.xautoGraph.svg, "text/xml");
      let elements = xml.querySelectorAll('[data-bindingtype]');
      let xgroups = new Set<string>();
      let xautogroups = new Set<string>();
      let xautovariableid = new Set<string>();

      for(let i = 0; i < elements.length; i++)
      {
        let el = elements[i];
        if( el.getAttribute("data-bindingtype") == "text" )
        {
          if(!el.hasAttribute("data-xautovariableid"))
            continue;

          let variableid = el.getAttribute("data-xautovariableid");

          // We should search for these..

          if(el.hasAttribute("data-xautogroup"))
          {
            xautogroups.add( el.getAttribute("data-xautogroup") );
          }
          if(el.hasAttribute("data-xgroup"))
          {
            xgroups.add( el.getAttribute("data-xgroups") );
          }

          xautovariableid.add(variableid);

          let svgID= "XAUTO-" + i.toString();
          el.setAttribute("id", svgID);

          let config = new SvgColumnConfig();
          config.Id = svgID;
          config.SvgID = svgID;
          config.MatchColumnValue = variableid;
          config.SetText = true;
          config.Clickable = true;
          //config.ClickableOutputColumnId = "XAUTOVARID";
          config.Thresholds = this.getThresholds(el);
          config.SvgTransform = this.getTransform(el);
          config.SvgStyle = this.getStyle(el)

          query.ColumnConfigs.push(config);
        }
      }

      for(let xvarid of xautovariableid)
      {
        let filter = new DataFilter();
        filter.Comparator = FilterComparator.Equals.toString();
        filter.Value = xvarid;
        filter.ColumnDescriptor.columnname = "XAUTOVARID";
        filter.ColumnDescriptor.projectionid = query.ProjectionId;
        filter.ColumnDescriptor.datatype = XDataType.String;

        query.DataFilters.push(filter);
      }
      query.FilterLogicalGroupType = FilterLogicalGroupType.OR;
      query.Query = this.getQuery(query);

      this.xautoSvg = new XMLSerializer().serializeToString(xml);
      this.widgetConfig.ConfigQueries.push(query);
    }
    catch
    {
      this.logger.error("Failed to configure from XAuto");
    }

  }

  getThresholds(el) : SvgThreshold[] {
    let result : SvgThreshold[] = [];

    try {
      if (el.hasAttribute('data-xautothresholds')) {
        let json = el.getAttribute('data-xautothresholds');
        let t = JSON.parse(json);
        t.forEach(th => {
          let thresholdData : SvgThreshold = new SvgThreshold();
          thresholdData.Value = th.value;
          thresholdData.Color = th.color;
          thresholdData.CssClass = th.cssclass;
          thresholdData.Text = th.text;
          thresholdData.Visible = th.visible;
          result.push(thresholdData);
        });
      }
    }
    catch {
      result = [];
    }
    return result;
  }

  getTransform(el) : SvgTransform {
    let result : SvgTransform = new SvgTransform();

    try {
      if (el.hasAttribute('data-xautotransform')) {
        let json = el.getAttribute('data-xautotransform');
        let t = JSON.parse(json);

        result.scaleY = t.scaley;
        result.scaleX = t.scalex;
        result.originY = t.originy;
        result.originX = t.originx;
        result.translateY = t.translatey;
        result.translateX = t.translatex;
        result.rotate = t.rotate;
        result.relative = t.relative ?? false;
      }
    }
    catch {

    }
    return result;
  }

  getStyle(el) : SvgStyle {
    let result : SvgStyle = new SvgStyle();
    result.Visible = el.getAttribute('data-xstyle-visible');

    try {
      if (el.hasAttribute('data-xautostyle')) {
        let json = el.getAttribute('data-xautostyle');
        let t = JSON.parse(json);

        result.Visible = t.visible;
      }
    }
    catch {

    }

    return result;
  }

  async configureConfigFromXauto()
  {
    this.widgetConfig.ConfigQueries.length = 0;
    let groups : Map<string, [Set<string>, SvgColumnConfig[]]> = new Map<string, [Set<string>, SvgColumnConfig[]]>();

    let svgIdIndex : number = 0;
    for(let i = 0; i < this.xautoGraph.numericalmapxautovariables.length; i++) {
      let xautovariable = this.xautoGraph.numericalmapxautovariables[i];
      let xvariableid = xautovariable.xautovariableid;
      let xautogroup = xautovariable.xautogroup;
      let xgroup = xautovariable.xgroup;
      let xname = xautovariable.xname;
      if(!xvariableid || xvariableid == "")
      {
        // Look it up
        if(!xname || xname == "")
        {
          continue;
        }

        xvariableid = await this.xautoService.getXAutoVariableID(xautogroup, xgroup, xname);
        this.logger.debug('xvariableid', xvariableid);
        if(!xvariableid)
          continue;
      }

      let mappings = await this.xautoService.getXAutoVariableMapping(xvariableid);
      if (mappings.length == 1) {
        xautogroup = mappings[0].xautogroup;
        xgroup = mappings[0].xgroup;
        let grpKey = [xautogroup, xgroup].join(',');
        if( !groups.has(grpKey) )
        {
          groups.set(grpKey, [new Set<string>(), []]);
        }

        let svgID= "XAUTO-" + (svgIdIndex++).toString();

        let config = new SvgColumnConfig();
        config.Id = svgID;
        config.SvgID = svgID;
        config.MatchColumnValue = xvariableid;
        config.Scriptname = xautovariable.scriptname;
        config.SetText = true;
        config.Clickable = true;

        // config.Thresholds = this.getThresholds(el);
        // config.SvgTransform = this.getTransform(el);
        // config.SvgStyle = this.getStyle(el)

        groups.get(grpKey)[0].add(xvariableid);
        groups.get(grpKey)[1].push(config);
      }
      else if (mappings.length == 0) {
        this.logger.debug("Could not match any variables for:", xvariableid);
      }
      else {
        this.logger.debug("Duplicate variables matching:", xvariableid);
      }
    }

    let parser = new DOMParser();
    let xml = parser.parseFromString(this.xautoGraph.svg, "text/xml");
    this.logger.debug('svgxml', xml);
    let elements = xml.querySelectorAll('[data-bindingtype]');

    for(let i = 0; i < elements.length; i++)
    {
      let xautogroup = "";
      let xgroup = "";
      let xvariableid = "";
      let xname = "";

      let el = elements[i];
      if( el.getAttribute("data-bindingtype") == "text" || el.getAttribute("data-bindingtype") == "shape")
      {
        let xvariable =  el.getAttribute("data-xvariable") ?? '';
        let xautovariable = this.xautoGraph.numericalmapxautovariables.find(x => x.scriptname == xvariable);
        if (xautovariable) {
          xgroup = xautovariable.xgroup;
          xautogroup = xautovariable.xautogroup;
          xvariableid = xautovariable.xautovariableid;
          xname = xautovariable.xname;
        }
        else {
            //backward comp, remove
          xgroup =  el.getAttribute("data-xgroup") ?? '';
          xautogroup = el.getAttribute("data-xautogroup") ?? '';
          xvariableid = el.getAttribute("data-xautovariableid") ?? '';
          xname =  el.getAttribute("data-xname") ?? '';
        }

        this.logger.debug('svg element data', xautogroup, xgroup, xvariableid, xname);

        if(!xvariableid || xvariableid == "")
        {
          // Look it up
          if(!xname || xname == "")
          {
            continue;
          }

          xvariableid = await this.xautoService.getXAutoVariableID(xautogroup, xgroup, xname);
          this.logger.debug('xvariableid', xvariableid);
          if(!xvariableid)
            continue;
        }

        let mappings = await this.xautoService.getXAutoVariableMapping(xvariableid);
        if (mappings.length == 1) {
          xautogroup = mappings[0].xautogroup;
          xgroup = mappings[0].xgroup;
          let grpKey = [xautogroup, xgroup].join(',');
          if( !groups.has(grpKey) )
          {
            groups.set(grpKey, [new Set<string>(), []]);
          }

          let svgID= "XAUTO-" + (svgIdIndex++).toString();
          el.setAttribute("id", svgID);

          let config = new SvgColumnConfig();
          config.Id = svgID;
          config.SvgID = svgID;
          config.MatchColumnValue = xvariableid;
          config.Scriptname = xvariable;
          config.SetText = true;
          config.Clickable = true;

          config.Thresholds = this.getThresholds(el);
          config.SvgTransform = this.getTransform(el);
          config.SvgStyle = this.getStyle(el)

          groups.get(grpKey)[0].add(xvariableid);
          groups.get(grpKey)[1].push(config);
        }
        else if (mappings.length == 0) {
          this.logger.debug("Could not match any variables for:", xvariableid);
        }
        else {
          this.logger.debug("Duplicate variables matching:", xvariableid);
        }
      }
    }

    for (let group of groups.keys())
    {
      let query = new SvgWidgetQuery();

      query.ProjectionId = "XAUTO_VAL_NUM";
      query.Group = group.split(',');
      query.MatchColumnName = "XAUTOVARID";
      query.UseColumnMatching = true;
      query.MaxItems = 10000;
      query.ValueColumnName = "VALUE";

      let variables = groups.get(group)[0];
      let svgconfigs = groups.get(group)[1];
      for(let variable of variables)
      {
        let xvarid = variable;
        let filter = new DataFilter();
        filter.Comparator = FilterComparator.Equals.toString();
        filter.Value = xvarid;
        filter.ColumnDescriptor.columnname = "XAUTOVARID";
        filter.ColumnDescriptor.projectionid = query.ProjectionId;
        filter.ColumnDescriptor.datatype = XDataType.String;

        query.DataFilters.push(filter);
      }
      for(let svgcol of svgconfigs)
      {
        query.ColumnConfigs.push(svgcol);
      }

      if(query.DataFilters.length > 0)
      {
        query.FilterLogicalGroupType = FilterLogicalGroupType.OR;
        query.Query = this.getQuery(query);
        this.widgetConfig.ConfigQueries.push(query);
      }
    }

    this.xautoSvg = new XMLSerializer().serializeToString(xml);
  }

  async ngAfterViewInit(): Promise<void> {
    if(this.widgetConfig.XAutoConfigured)
    {
      try{
        this.xautoGraph = await this.xprojClient.XAUTO_GetProcessGraph(this.widgetConfig.XAutoProcessGraphID);
      }
      catch(err)
      {
      }
      await this.configureConfigFromXauto();
    }

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      setTimeout(() => {
        this.initConfigDone = true;
        this.updateSvg().then(x => this.initiated = true);
      }, 500);
    }

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as SvgWidgetConfig;
    this.id = this.widgetConfig.Id;
    this.id = this.id.replaceAll('-', '');

    await super.ngOnInit();
  }

  async onInit() {


  }

  async onRefresh() {
    await this.updateSvg();
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetSize(this.getHeight(component), component?.width);

    if (this.initiated) {
      await this.updateSvg();
    }
  }

  async onReset() {

  }

  async onUpdateQuery() {
    this.initiated = false;
    await this.updateSvg();
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {
    await this.load();
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.load();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (force || i >= 0) {
      await this.load(true);
    }
  }

  private setWidgetSize(height: number, width?: number): void {

    if (height != this.widgetheight || width != this.widgetwidth) {
      this.updateSvgSize = true;
    }

    if (height) {
      this.widgetheight = height;
    }
    this.widgetwidth = width ?? this.widgetheight;
  }

  initConfigDone = false;

  private async updateSvg() {
    if(!this.initConfigDone)
    {
      return;
    }

    if (!this.widgetheight || !this.widgetwidth) {
      this.setWidgetSize(this.widgetConfig.Height, this.widgetConfig.Width);
    }

    let draw = SVG('div[id="' + this.id + '"]');
    draw.clear();

    if(this.widgetConfig.XAutoConfigured && this.xautoGraph == null)
      return;

    if(this.xautoGraph == null)
      draw.svg(this.widgetConfig.SvgData);
    else
      draw.svg(this.xautoSvg);

    let svgelem = SVG('div[id="' + this.id + '"] svg')
    if (svgelem && this.updateSvgSize) {
      if(this.widgetwidth == 0)
        svgelem.attr("width", "100%");
      else
        svgelem.attr("width", this.widgetwidth);

      if(this.widgetheight == 0)
        svgelem.attr("height", "100%");
      else
        svgelem.attr("height", this.widgetheight);
      svgelem.attr("preserveAspectRatio", 'xMidYMin');  //TODO : settings
      //svgelem.attr("viewBox", '0 0 ' + this.widgetwidth + ' '  + this.widgetheight);
      svgelem.writeDataToDom();

      this.updateSvgSize = false;
    }
    this.load();
  }

  getSvgThreshold(colorThredholds: SvgThreshold[], value: number): SvgThreshold {
    try {
      if (colorThredholds.length == 0) {
        return undefined;
      }

      let sorted = colorThredholds.sort((a, b) => {
        return a.Value - b.Value;
      });

      for (let i = 0; i < sorted.length; i++) {
        let ct = sorted[i];
        if (value < ct.Value) {
          return i == 0 ? undefined : sorted[i - 1];
        }

      }

      return sorted[sorted.length - 1];
    }
    catch {
      return undefined;
    }
  }

  emitOutputChangedParameters(dataConfig: SvgColumnConfig, dblClick: boolean) {
    let outputsChanged: WidgetOutputChangeParameters[] = [];

    let outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_matchcolumnvalue';
    outputChanged.datatype = OutputDataType.String;
    outputChanged.value = dataConfig.MatchColumnValue;
    outputsChanged.push(outputChanged);

    outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_svgid';
    outputChanged.datatype = OutputDataType.String;
    outputChanged.value = dataConfig.SvgID;
    outputsChanged.push(outputChanged);

    outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_columnname';
    outputChanged.datatype = OutputDataType.String;
    outputChanged.value = dataConfig.ColumnName;
    outputsChanged.push(outputChanged);

    outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_columnoutname';
    outputChanged.datatype = OutputDataType.String;
    outputChanged.value = dataConfig.ColumnOutName;
    outputsChanged.push(outputChanged);

    outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_unit';
    outputChanged.datatype = OutputDataType.String;
    outputChanged.value = dataConfig.Unit;
    outputsChanged.push(outputChanged);

    outputChanged = new WidgetOutputChangeParameters();
    outputChanged.widgetId = this.widgetConfig.Id;
    outputChanged.outputParameterId = this.widgetConfig.Id + '_dblclick';
    outputChanged.datatype = OutputDataType.UInt8;
    outputChanged.value = dblClick ? 1 : 0;
    outputsChanged.push(outputChanged);

    this.widgetService.outputParametersChanged(outputsChanged);
  }

  onClick($event) {
    if (this.clicktimer) {
      clearTimeout(this.clicktimer);
    }
    this.clicktimer = setTimeout(() => {
      let dataConfig = this.getDataConfig($event);
      if (dataConfig) {
        this.emitOutputChangedParameters(dataConfig, false);
      }
    }, 500);
  }

  onDblClick($event) {
    clearTimeout(this.clicktimer);
    let dataConfig = this.getDataConfig($event);
    console.log('onDblClick', dataConfig);
    if (dataConfig) {
      this.emitOutputChangedParameters(dataConfig, true);
    }
  }

  onMouseOver($event) {
    let dataConfig = this.getDataConfig($event);
    if (dataConfig) {
      $event.target.style.cursor = 'pointer';
    }
  }

  getDataConfig($event): SvgColumnConfig {
    let result = undefined;
    let svgId = $event.currentTarget?.id;
    if (svgId) {
      this.widgetConfig.ConfigQueries.forEach(configQuery => {
        let dataConfig = configQuery.ColumnConfigs.find(colConfig => colConfig.SvgID == svgId);
        if (dataConfig) {
          result = dataConfig;
        }
      });
    }

    return result;
  }

  getString(elem : any) : string
  {
    if(elem.type == "DIV")
    {
      return elem.node.innerText;
    }

    return elem.text();
  }

  setString(elem: any, value:string)
  {
    if(elem.type == "DIV")
    {
      elem.node.innerText = value;
    }

    if (elem.plain) {
      elem.plain(value);
    }
  }

  async load(forcereload: boolean = false) {
    if (this.loading) {
      return;
    }

    this.loading = true;

    let draw = SVG('div[id="' + this.id + '"]');

    draw?.off('click');
    draw?.on('click', this.onClick.bind(this));
    draw?.off('dblclick');
    draw?.on('dblclick', this.onDblClick.bind(this));
    draw?.off('mouseover');
    draw?.on('mouseover', this.onMouseOver.bind(this));

    let newValues = [];
    try {
      if (!this.inputParametersHasValue(true)) {
      }
      else {
        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: SvgWidgetQuery) => {

          //let queryZoom = WidgetUtils.GetQueryZoomDates(this.fromZoom, this.toZoom, configQuery.GroupingTransform);

          //let query = configQuery.Query.Clone();
          let query = this.getQuery(configQuery);

          // // Aggregation input parameters
          // let i = 0;
          // query.columns.forEach(col => {
          //   let dataConfig = configQuery.DataConfigs[i];
          //   if (dataConfig.UseAggregationInputParameter) {
          //     col.columnaggregation = this.getParameterValue(dataConfig.AggregationInputParameterId, col.columnaggregation);
          //   }
          //   i++;
          // });

          //Projection input parameters
          if (configQuery.UseProjectionInputParameter) {
            query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
          }

          //Group input parameters
          if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
            query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
          }
          else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
            query.targetgroup = [];
            configQuery.GroupInputParameterIds.forEach(id => {
              query.targetgroup.push(this.getParameterValue(id, '').value + '');
            });
          }


          WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
          WidgetUtils.AddQueryTimeframe(query,
            this.from,
            this.to,
            configQuery.timestampColumnName,
            // this.useRelativeTimestamp ? this.relativeTimestamp : null
            null
          );

          let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'svgwidget', this.config.Name);
          //this.logger.info('loading svg query data: data:');
          //this.logger.info(queryResult);

          let numericaldata = queryResult["datanumbers"];
          let timestampdata = queryResult["datatimestamps"];
          let stringdata = queryResult["datastrings"];

          //this.logger.info(queryResult);

          if (configQuery.UseColumnMatching) {

            let matchingValues = stringdata[0];
            let values = null;
            let dt = queryResult["columns"][1]["datatype"];
            if (dt == XDataType.String) {
              values = stringdata[1];
            }
            else if (dt == XDataType.Timestamp) {
              values = timestampdata[0];
            }
            else if (dt == XDataType.Number) {
              values = numericaldata[0];
            }

            // this.logger.info("got values for");
            // this.logger.info(matchingValues);

            let detectErrors = false;
            if (detectErrors) {
              for (let col of configQuery.ColumnConfigs) {
                let svgElem = draw.findOne("#" + CSS.escape(col.SvgID));
                if (svgElem) {
                  let svgElemText = svgElem as any;

                  try {
                    //svgElemText.plain("!!!BAD!!");
                    //this.logger.info("Set " + dataConfig.SvgID +" = " + data.toString());
                  }
                  catch
                  {
                    //this.logger.info("Set text failed " + dataConfig.SvgID +" = " + data.toString());
                    //this.logger.info(dataConfig);
                  }
                  try {
                    svgElem.attr('fill', "#FF00FF");
                    svgElem.attr('style', 'fill: \"#FF00FF;\"');
                  }
                  catch
                  {
                  }

                }
              }
            }

            let dataByScriptname : {scriptname : string, value : number }[] = [];

            for (let i = 0; i < matchingValues.length; i++) {
              let dataConfig = configQuery.ColumnConfigs.find(x => x.MatchColumnValue == matchingValues[i]);
              if (dataConfig && dataConfig.Scriptname?.length > 0) {
                dataByScriptname.push({scriptname : dataConfig.Scriptname, value : values[i]});
              }
            }

            this.pushScriptValues(dataByScriptname);

            for (let i = 0; i < matchingValues.length; i++) {
              //let dataConfig = configQuery.ColumnConfigs.find(c => c.MatchColumnValue == matchingValues[i]);
              for (let dataConfig of configQuery.ColumnConfigs) {
                if (dataConfig.MatchColumnValue != matchingValues[i])
                  continue;
                let data = values[i];

                if (dt == XDataType.Number) {
                  data = WidgetUtils.FormatNumber(data, this.globalWidgetSettings.Decimals);
                }

                if (dataConfig && dataConfig.SvgID && dataConfig.SvgID.length > 0) {
                  let svgElem = draw.findOne("#" + CSS.escape(dataConfig.SvgID));
                  if (!svgElem) {
                    this.logger.info("Could not find svgElement for " + matchingValues[i] + " svgid: " + dataConfig.SvgID + " escaped : " + CSS.escape(dataConfig.SvgID));
                  }
                  if (svgElem) {
                    let svgElemText = svgElem as any;

                    // plain does not add tspan (does not work for longer texts) but keeps positioning
                    try {
                      let strVal : string = undefined;

                      if (dataConfig.Thresholds?.length > 0) {
                        let val = data as number;
                        let threshold = this.getSvgThreshold(dataConfig.Thresholds, val);

                        if (threshold) {
                          if (threshold.Color) {
                            svgElem.attr('fill', threshold.Color);
                            svgElem.css('fill', threshold.Color);
                          }

                          dataConfig.Thresholds.filter(x => x != threshold).forEach(ct => {
                            if (ct.CssClass?.length > 0 && svgElem.hasClass(ct.CssClass)) {
                              svgElem.removeClass(ct.CssClass);
                            }
                          });

                          if (threshold.CssClass?.length > 0 && !svgElem.hasClass(threshold.CssClass)) {
                            svgElem.addClass(threshold.CssClass);
                          }

                          if (threshold.Text?.length > 0) {
                            strVal = threshold.Text
                            // this.setString(svgElem, threshold.Text);
                             if (threshold.Color) {
                              svgElem.attr('color', threshold.Color);
                              svgElem.css('color', threshold.Color);
                            }
                          }

                          if (threshold.Visible == undefined || threshold.Visible) {
                            svgElem.show();
                          }
                          else {
                            svgElem.hide();
                          }
                        }
                      }

                      let svgElemVisible = svgElem.visible();

                      if (svgElemVisible && dataConfig.SvgTransform) {
                        // @ts-ignore
                        if (svgElem.transform) {
                          let transformObject = this.getTransformObject(dataConfig.SvgTransform, data);

                          if (transformObject?.active) {

                            //let computedStyle = getComputedStyle(svgElem as any as Element);

                            // @ts-ignore
                            svgElem.transform(transformObject, transformObject.relative);
                          }
                        }
                      }

                      let visible = this.isVisible(dataConfig.SvgStyle, data);

                      if (visible != undefined) {
                        if (visible && ! svgElemVisible) {
                          svgElem.show();
                        }
                        else if (!visible && svgElemVisible) {
                          svgElem.hide();
                        }
                      }

                      if (dataConfig.SetText) {
                        let oldText = this.getString(svgElemText);
                        if (strVal == undefined) {
                          strVal = data.toString();

                          if (dt == XDataType.Number) {
                            strVal = WidgetUtils.FormatNumber(data, this.globalWidgetSettings.Decimals).toFixed(this.globalWidgetSettings.Decimals);
                          }
                          if (dataConfig.PrependText) {
                            strVal = dataConfig.PrependText + strVal;
                          }

                          if (dataConfig.Unit) {
                            strVal = strVal + " " + dataConfig.Unit;
                          }

                          if (dataConfig.AppendText) {
                            strVal = strVal + dataConfig.AppendText;
                          }
                        }
                        if (strVal != undefined) {
                          this.setString(svgElemText, strVal);
                        }

                        if (oldText != this.getString(svgElemText)) {
                          svgElemText.animate(400, 0, 'now').css("font-weight", "1000").animate(1000, 0, 'after').css("font-weight", "50");
                        }
                      }
                      // if (dataConfig.SetTextOnKeyKeys && dataConfig.SetTextOnKeyKeys.length > 0) {
                      //   // Todo loop and set value
                      //   let SetValues = false;
                      //   let val = data;
                      //   if (dt == XDataType.Number) {
                      //     val = WidgetUtils.FormatNumber(data, this.globalWidgetSettings.Decimals).toFixed(this.globalWidgetSettings.Decimals);
                      //   }

                      //   for (let cit = 0; cit < dataConfig.SetTextOnKeyKeys.length; cit++) {

                      //     if (val == dataConfig.SetTextOnKeyKeys[cit]) {
                      //       this.setString(svgElemText, dataConfig.SetTextOnKeyValues[cit] as string)
                      //       SetValues = true;

                      //       break;
                      //     }
                      //   }

                      //   if (!SetValues) {
                      //     this.setString(svgElem, "?");
                      //   }
                      // }

                      //this.logger.info("Set " + dataConfig.SvgID +" = " + data.toString());
                    }
                    catch
                    {
                      //this.logger.info("Set text failed " + dataConfig.SvgID +" = " + data.toString());
                      //this.logger.info(dataConfig);
                    }
                  }
                }
              }
            }
          }
          else { // Not matching by column name
            for (let i = 0; i < queryResult["columns"].length; i++) {
              let it = queryResult["columns"][i];
              let datatype = it["datatype"];
              let data: any;
              if (datatype == XDataType.Number) {
                let i: number = numericaldata[it["indexintypedvector"]][0];
                data = WidgetUtils.FormatNumber(i, this.globalWidgetSettings.Decimals);
              }
              else if (datatype == XDataType.String)
                data = stringdata[it["indexintypedvector"]][0];
              else if (datatype == XDataType.Timestamp)
                data = timestampdata[it["indexintypedvector"]][0];

              let colname = it["columnoutname"];

              let dataConfig = null;

              dataConfig = configQuery.ColumnConfigs.find(c => c.ColumnOutName == colname);


              //debugger;
              if (dataConfig && dataConfig.SvgID && dataConfig.SvgID.length > 0) {
                //this.logger.info("loading svg query data: got dataconfig");
                let svgElem = draw.findOne("#" + CSS.escape(dataConfig.SvgID));
                if (svgElem) {
                  //let svgElemText = svgElem as SVGTextElement;
                  //let svgElemAny = svgElem as SvgContainerComponent;
                  let svgElemText = svgElem as any;

                  // plain does not add tspan (does not work for longer texts) but keeps positioning
                  try {
                    svgElemText.plain(data.toString());
                    this.logger.info("Set " + dataConfig.SvgID + " = " + data.toString());
                  }
                  catch
                  {
                    this.logger.info("Set failed " + dataConfig.SvgID + " = " + data.toString());
                  }

                  /*
                  // example of overriding font color,
                  // this works but needs configuration for tresholds etc
                  let stylest = svgElemText.attr('style');
                  this.logger.info("styles:");
                  this.logger.info(stylest);
                  svgElemText.node.style.fill='#FF00FF';
                  */
                }
                else {
                  //this.logger.info("loading svg query data: could not find svg id: " + dataConfig.SvgID);
                }
              }
              else {
                //this.logger.info("loading svg query data: got no dataconfig");
              }


              // let dataConfig = configQuery.DataConfigs.find(c => c.ColumnOutName == colname);

              // if (dataConfig) {
              //   let value = new LabelValue();
              //   value.value = data;
              //   value.unit = dataConfig.Unit;
              //   value.fontSize = dataConfig.FontSize;
              //   value.label = dataConfig.Label;
              //   value.textColor = dataConfig.ColorProperty == ColorProperty.TEXT ? this.getColor(dataConfig.ColorThresholds, data) : undefined;
              //   value.backgroundColor = dataConfig.ColorProperty == ColorProperty.TEXT_BACKGROUND ? this.getColor(dataConfig.ColorThresholds, data) : undefined;

              //   newValues.push(value);

              //   if (!this.widgetBackgroud && dataConfig.ColorProperty == ColorProperty.WIDGET_BACKGROUND) {
              //     this.widgetBackgroud = this.getColor(dataConfig.ColorThresholds, data);
              //   }
              // }
            }
          }
        });
      }
    }
    finally {
      //this.values = newValues;
      this.loading = false;
    }

  }

  getQuery(queryConfig: SvgWidgetQuery): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = queryConfig.ProjectionId;
    query.maxitems = 2000;
    query.targetgroup = queryConfig.Group;

    let filterId = 0;
    queryConfig.DataFilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        columnFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(columnFiltering.queryfilterid);

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          query.numericalfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          query.stringfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          query.timestampfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          query.relativetimestampfilters.push(columnFiltering);
        }
      }
    });
    query.filter.type = queryConfig.FilterLogicalGroupType;

    if (queryConfig.UseColumnMatching) {
      let matchcol = new BaseQueryInputColumnDescription();
      matchcol.columnname = queryConfig.MatchColumnName;
      matchcol.columnoutname = "match";
      query.columns.push(matchcol);

      let valuecol = new BaseQueryInputColumnDescription();
      valuecol.columnname = queryConfig.ValueColumnName;
      valuecol.columnoutname = "value";
      query.columns.push(valuecol);
    }
    else {
      let addedColumns = {};
      let colindex = 0;
      for (let column of queryConfig.ColumnConfigs) {
        if (addedColumns[column.ColumnName]) {
          continue;
        }
        addedColumns[column.ColumnName] = true;
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = queryConfig.UseGrouping ? "col_" + colindex.toString() : column.ColumnName;
        }

        if (colindex == 0) {
          if (!queryConfig.UseGrouping) {
            query.sorting.columnname = col.columnname;
            query.sorting.descending = false;
            query.grouping = new ColumnGroupingDescription();
            query.columns.push(col);
          }
          else {
            query.grouping.columnname = col.columnname;
            query.grouping.columntransformation = queryConfig.GroupingTransform;
            query.grouping.columnoutname = col.columnoutname;
          }
        }
        else {
          if (queryConfig.UseGrouping) {
            col.columnaggregation = column.Transform;
          }
          query.columns.push(col);
        }

        colindex++;
      }
    }

    return query;

  }

  private luaResult : number | undefined;
  private luaResultBool : boolean | undefined;

  setLuaResult(value : number) {
    this.luaResult = value;
  }

  setLuaResultBool(value : boolean) {
    this.luaResultBool = value;
  }

  isVisible(svgStyle : SvgStyle, value : number) : boolean | undefined {
    let result : boolean = undefined;
    if (svgStyle.Visible?.length > 0) {
      result = this.getTransformValueBool(svgStyle.Visible, value);
    }

    return result;
  }

  getTransformObject(svgTransform : SvgTransform, value : number) : SvgWidgetTransform {
    let result : SvgWidgetTransform = new SvgWidgetTransform();
    result.active = false;
    result.relative = svgTransform.relative ?? false;

    let ok : boolean = false;
    if (svgTransform.scaleY?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.scaleY, value);
      result.scaleY = transformValue;
      if ((transformValue && isNumber(transformValue))) {
        result.active = result.scaleY > 0 || !result.relative;
      }

      ok = true;
    }
    if (svgTransform.originY?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.originY, value);
      result.originY = transformValue;
      ok = true;
    }
    if (svgTransform.scaleX?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.scaleX, value);
      result.scaleX = transformValue;
      if ((transformValue && isNumber(transformValue))) {
        result.active = result.scaleX > 0 || !result.relative;
      }

      result.active = true;
      ok = true;
    }
    if (svgTransform.originX?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.originX, value);
      result.originX = transformValue;
      ok = true;
    }
    if (svgTransform.translateY?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.translateY, value);
      result.translateY = transformValue;
      if ((transformValue && isNumber(transformValue))) {
        result.active = true;
      }
      ok = true;
    }
    if (svgTransform.translateX?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.translateX, value);
      result.translateX = transformValue;
      if ((transformValue && isNumber(transformValue))) {
        result.active = true;
      }
      ok = true;
    }
    if (svgTransform.rotate?.length > 0) {
      let transformValue = this.getTransformValue(svgTransform.rotate, value);
      result.rotate = transformValue;
      if ((transformValue != undefined && isNumber(transformValue))) {
        result.active = result.rotate > 0 || !result.relative;
      }
      ok = true;
    }

    return ok ? result : undefined;
  }

  pushScriptValues(dataByScriptname : {scriptname : string, value : number }[]) {
    dataByScriptname.forEach(x => {
      fengari.interop.push(fengari.L, x.value);
      fengari.lua.lua_setglobal(fengari.L, x.scriptname);
    });

    //console.log('dataByScriptname', dataByScriptname);
  }


  getTransformValue(script : string, value : number) : number | undefined{
    this.luaResult = undefined;
    fengari.interop.push(fengari.L, this);
    fengari.lua.lua_setglobal(fengari.L, "db");
    fengari.interop.push(fengari.L, value);
    fengari.lua.lua_setglobal(fengari.L, "value");

    fengari.interop.push(
      fengari.L,
      this.setLuaResult
    );
    fengari.lua.lua_setglobal(fengari.L, "setLuaResult");

    let innerLua =
      `
    function setLuaResult(val)
        return setLuaResult(db, val)
    end
    `;

    if (script.indexOf('return') > -1) {
      innerLua += "\n function evaluateScript()" + "\n  " + script + "\n end" +
      `
        local res = evaluateScript()
        db.setLuaResult(db, res)
        `;
    }
    else {
      innerLua += "\n local res = " + script + "\n" +
      `
        db.setLuaResult(db, res)
        `;
    }

  let luaCode = "coroutine.wrap(function() " + innerLua + ";\n end)()";

  fengari.load(luaCode)();

    return this.luaResult;
  }

  getTransformValueBool(script : string, value : number) : boolean | undefined{
    this.luaResult = undefined;
    fengari.interop.push(fengari.L, this);
    fengari.lua.lua_setglobal(fengari.L, "db");
    fengari.interop.push(fengari.L, value);
    fengari.lua.lua_setglobal(fengari.L, "value");

    fengari.interop.push(
      fengari.L,
      this.setLuaResultBool
    );
    fengari.lua.lua_setglobal(fengari.L, "setLuaResultBool");

    let innerLua =
      `
    function setLuaResultBool(val)
        return setLuaResultBool(db, val)
    end
    `;

    if (script.indexOf('return') > -1) {
      innerLua += "\n function evaluateScript()" + "\n  " + script + "\n end" +
      `
        local res = evaluateScript()
        db.setLuaResultBool(db, res)
        `;
    }
    else {
      innerLua += "\n local res = " + script + "\n" +
      `
        db.setLuaResultBool(db, res)
        `;
    }

  let luaCode = "coroutine.wrap(function() " + innerLua + ";\n end)()";

  fengari.load(luaCode)();

    return this.luaResultBool;
  }

}


