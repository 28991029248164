import { XprojCommonStrings } from './xproj-common-strings.interface';

export const xprojCommonStringsDefault: XprojCommonStrings = {

    //Chart
  rightY : 'right-y',

    //Weekdays
  mondayShort : 'Mon',
  tuesdayShort : 'Tue',
  wednesdayShort : 'Wed',
  thursShort : 'Thu',
  friShort : 'Fri',
  saturdayShort : 'Sat',
  sundayShort : 'Sun'


}
