import { Component, inject, OnInit } from '@angular/core';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { RossakerBmsLorawanMultiMeter } from '@core/models/rossaker-bms-lorawan-multimeter';
import { RossakerBmsAdminService } from '@app/core/services/rossaker-bms-admin-service';
import { RossakerLorawanDeviceInfo } from '@app/core/models/rossaker-lorawan-device-info';
import { DateHelper } from 'xproj-lib';

@Component({
  selector: 'app-rossaker-bms-lorawan-multi-meter-data-collector-view',
  templateUrl: './rossaker-bms-lorawan-multi-meter-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-lorawan-multi-meter-data-collector-view.component.scss']
})
export class RossakerBmsLorawanMultiMeterDataCollectorViewComponent implements OnInit {

  public static NodeTypeId : string = '_x_lorawan_multimeter';

  private rossakerAdminService : RossakerBmsAdminService = inject(RossakerBmsAdminService);
  public dateHelper: DateHelper = inject(DateHelper);

  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  lorawanMultiMeter : RossakerBmsLorawanMultiMeter;
  lorawanDeviceInfo : RossakerLorawanDeviceInfo;

  constructor(

  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.lorawanMultiMeter = this.rossakerAdminService.getLorawanMultiMeter(this.data.node);
    let lorawanDeviceInfos = await this.rossakerAdminService.getLoraWANDeviceInfos([this.lorawanMultiMeter?.devEui]);
    if (lorawanDeviceInfos?.length > 0) {
      this.lorawanDeviceInfo = lorawanDeviceInfos[0];
    }
  }

  setWidth(width: number) {

  }
}
