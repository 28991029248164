<form clrForm>
  <clr-input-container>
    <label>Label</label>
    <input clrInput placeholder="" name="label" type="string" [(ngModel)]="comboboxConfig.Label" />
  </clr-input-container>
  <clr-select-container *ngIf="!comboboxConfig.ProjectionData">
    <label>Data type</label>
    <select clrSelect name="coltype" [(ngModel)]="comboboxConfig.DataType">
      <option [ngValue]="OutputDataType.Float32">Float32</option>
      <option [ngValue]="OutputDataType.Float64">Float64</option>
      <option [ngValue]="OutputDataType.UInt8">UInt8</option>
      <option [ngValue]="OutputDataType.Int32">Int32</option>
      <option [ngValue]="OutputDataType.Int64">Int64</option>
      <!-- <option value="5">Number</option> -->
      <option [ngValue]="OutputDataType.Timestamp">Timestamp</option>
      <option [ngValue]="OutputDataType.String">String</option>
    </select>
  </clr-select-container>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="useprojectiondata" [(ngModel)]="comboboxConfig.ProjectionData" />
      <label>Use projection data</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-checkbox-container *ngIf="comboboxConfig.ProjectionData">
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="addemptycomboxmember" [(ngModel)]="comboboxConfig.ProjectionDataAddEmptyValue" />
      <label>Add default entry</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-input-container *ngIf="comboboxConfig.ProjectionData">
    <label>Default entry label</label>
    <input clrInput name="addemptycomboxmemberlabel" type="text" [(ngModel)]="comboboxConfig.ProjectionDataEmptyValueLabel" />
  </clr-input-container>
  <clr-checkbox-container *ngIf="comboboxConfig.ProjectionData">
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="searchablecomboxmembers" [(ngModel)]="comboboxConfig.ProjectionDataSearchable" />
      <label>Searchable members</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
</form>

<form clrForm *ngIf="!comboboxConfig.ProjectionData">
  <clr-datagrid [(clrDgSingleSelected)]="selectedMember">
    <clr-dg-column>Label</clr-dg-column>
    <clr-dg-column>Value</clr-dg-column>
    <clr-dg-column>Default</clr-dg-column>
    <clr-dg-column>Move</clr-dg-column>

    <clr-dg-row *ngFor="let member of comboboxConfig.Members; let i = index" [clrDgItem]="member">
      <clr-dg-cell>
        <input class="clr-input" placeholder="" name="Label{{i}}" [(ngModel)]="member.Label" />
      </clr-dg-cell>
      <clr-dg-cell>
        <input *ngIf="comboboxConfig.DataType < 6" class="clr-input" type="number" placeholder="" name="Value{{i}}"
          [(ngModel)]="member.ValueNumber" />
        <input *ngIf="comboboxConfig.DataType == 6" class="clr-date" type="date" name="Value{{i}}"
          [(clrDate)]="member.ValueDate">
        <input *ngIf="comboboxConfig.DataType == 7" class="clr-input" type="string" placeholder="" name="Value{{i}}"
          [(ngModel)]="member.ValueString" />
      </clr-dg-cell>
      <clr-dg-cell>
        <input class="clr-checkbox" type="checkbox" placeholder="" name="Default{{i}}" [(ngModel)]="member.Default"
          (change)="defaultClick(member)" />
      </clr-dg-cell>
      <clr-dg-cell>
        <clr-icon (click)="moveMemberUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
        <clr-icon (click)="moveMemberDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
          style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>

  <button type="button" class="btn btn-link" (click)="addMember()">
    <clr-icon shape="plus-circle"></clr-icon>
    Add Member
  </button>
  <button *ngIf="selectedMember" type="button" class="btn btn-link" (click)="removeSelectedMember()">
    <clr-icon shape="minus-circle"></clr-icon>
    Remove selected member
  </button>
</form>

<xproj-output-combobox-controller-query-config #queryConfig *ngIf="comboboxConfig.ProjectionData"
  [widgetQuery]="comboboxConfig.ConfigQuery" [widgets]="widgets" [inputParameters]="inputParameters">
</xproj-output-combobox-controller-query-config>

<button class="btn" (click)="saveOutputConfig()">Save</button>
