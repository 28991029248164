import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypedJSON } from 'typedjson';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { BaseQueryInputColumnDescription, ColumnGroupingDescription, Projection, ProjectionColumnDescription, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { GroupSelectionTypes, OutputDataType } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { ColumnConfig, EditMode, ProjectionDataEditorWidgetConfig } from './projection-dataeditor-widget-config-service';
import { ProjectionDataeditorWidgetUtils } from '../projection-dataeditor-utils-service';

@Component({
  selector: 'xproj-projection-dataeditor-widget-config',
  templateUrl: './xproj-projection-dataeditor-widget-config.component.html',
  styleUrls: ['./xproj-projection-dataeditor-widget-config.component.scss']
})
export class XprojProjectionDataEditorWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;
  @ViewChild("fixedGroupSelect", { read: XprojGroupSelectionComponent, static: false }) fixedGroupSelect: XprojGroupSelectionComponent;


  widgetConfig: ProjectionDataEditorWidgetConfig;

  editConfig: ProjectionDataEditorWidgetConfig;

  projection: Projection;
  projections: Projection[] = [];
  columns: ProjectionColumnDescription[] = [];
  queryableSelectedGroup: string[] = [];
  selectedColumnConfig: any = null;
  rowsPerPageOptionsString : string;

  GroupSelectionTypes = GroupSelectionTypes;
  OutputDataType = OutputDataType;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as ProjectionDataEditorWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();

    this.rowsPerPageOptionsString = this.widgetConfig.RowsPerPageOptions.join(',');
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.editConfig.ConfigQuery.ProjectionId);

    this.selectedProjectionGroupChange(this.editConfig.ConfigQuery.Query.targetgroup);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection.projectionid, null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection.projectionid, group);
      this.checkTimeFilterColumn();
    }
  }

  async fixedProjectionGroupChange(group: any) {
    this.editConfig.ConfigQuery.FixedGroup = group;
    this.groupSelect?.setFixedGroup(group);
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.editConfig.ConfigQuery.ColumnConfigs.find(col => col.ColumnName == this.editConfig.ConfigQuery.timestampColumnName)
      || (!this.editConfig.ConfigQuery.timestampColumnName || this.editConfig.ConfigQuery.timestampColumnName.length == 0)) {
        this.editConfig.ConfigQuery.timestampColumnName = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.editConfig.ConfigQuery.timestampColumnName || this.editConfig.ConfigQuery.timestampColumnName.length == 0) {
      this.editConfig.ConfigQuery.ColumnConfigs.forEach(col => {
        if (col.Datatype == XDataType.Timestamp &&
          (col.ColumnName.toLowerCase() == 'timestamp' || col.ColumnName.toLowerCase() == 'time')) {
          this.editConfig.ConfigQuery.timestampColumnName = col.ColumnName;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.editConfig.ConfigQuery.timestampColumnName || this.editConfig.ConfigQuery.timestampColumnName.length == 0)) {
      this.editConfig.ConfigQuery.ColumnConfigs.forEach(col => {
        if (col.Datatype == XDataType.Timestamp) {
          this.editConfig.ConfigQuery.timestampColumnName = col.ColumnName;
        }
      });
    }
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.editConfig.ConfigQuery.ColumnConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }

  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.editConfig.ConfigQuery.ColumnConfigs];
    this.editConfig.ConfigQuery.ColumnConfigs = [];
    this.cdr.detectChanges();
    this.editConfig.ConfigQuery.ColumnConfigs = copy;
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.editConfig.ConfigQuery.ColumnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;
  }

  onColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.editConfig.ConfigQuery.ColumnConfigs.length) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype = column.datatype;
      //this.editConfig.ConfigQuery.ColumnConfigs[index].Writeable = column.writable;
      this.editConfig.ConfigQuery.ColumnConfigs[index].PrimaryKey = column.primarykey;
      this.editConfig.ConfigQuery.ColumnConfigs[index].EditMode = ProjectionDataeditorWidgetUtils.getEditMode(this.editConfig.ConfigQuery.ColumnConfigs[index].Tags, this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype);
      this.editConfig.ConfigQuery.ColumnConfigs[index].EnumMembers = this.editConfig.ConfigQuery.ColumnConfigs[index].EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(this.editConfig.ConfigQuery.ColumnConfigs[index].Tags) : [];
    }
  }

  addAllColumns() {
    //this.queryData.columnConfigs.push(new ColumnConfig());

    for (let col of this.columns) {
      let t = new ColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      t.Writeable = true;//col.writable;
      t.PrimaryKey = col.primarykey;
      t.Tags = col.tags;
      t.EditMode = ProjectionDataeditorWidgetUtils.getEditMode(t.Tags, t.Datatype);
      t.EnumMembers = t.EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(t.Tags) : [];
      this.editConfig.ConfigQuery.ColumnConfigs.push(t);
    }

  }
  removeAllColumns() {
    this.editConfig.ConfigQuery.ColumnConfigs = [];
  }

  addColumn() {
    this.editConfig.ConfigQuery.ColumnConfigs.push(new ColumnConfig());
  }

  async onSaveConfig() {
    this.editConfig.ConfigQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.editConfig.ConfigQuery.Query = this.projectionFilter.GetQuery();
    this.editConfig.ConfigQuery.DataFilters = this.projectionFilter.datafilters;
    this.editConfig.ConfigQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.editConfig.ConfigQuery.Query.maxitems = 20;

    this.editConfig.ConfigQuery.ProjectionId = this.projection?.projectionid;
    this.editConfig.ConfigQuery.Group = this.editConfig.ConfigQuery.Query.targetgroup;
    this.editConfig.ConfigQuery.Query.targetprojectionid = this.editConfig.ConfigQuery.ProjectionId;

    if (this.editConfig.ConfigQuery.ColumnConfigs.length > 0) {
      let colindex = 0;
      for (let column of this.editConfig.ConfigQuery.ColumnConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = column.ColumnName;
        }

        if (colindex == 0) {
          this.editConfig.ConfigQuery.Query.sorting.columnname = col.columnname;
          this.editConfig.ConfigQuery.Query.sorting.descending = false;
          this.editConfig.ConfigQuery.Query.grouping = new ColumnGroupingDescription();
          this.editConfig.ConfigQuery.Query.columns.push(col);
        }
        else {
          this.editConfig.ConfigQuery.Query.columns.push(col);
        }

        colindex++;
      }

      if (this.fixedGroupSelect) {
        this.editConfig.ConfigQuery.FixedGroup = this.fixedGroupSelect.getSelectedGroup();
      }

      if (this.groupSelect) {
        this.editConfig.ConfigQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }

    try {
      this.widgetConfig.RowsPerPageOptions = [];
      this.rowsPerPageOptionsString.split(',').forEach(s => this.widgetConfig.RowsPerPageOptions.push(+s));
    }
    catch {
      this.widgetConfig.RowsPerPageOptions = [10,20,50,100];
    }

    this.widgetConfig.update(this.editConfig);
  }

}
