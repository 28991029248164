import { Injectable } from '@angular/core';
import { GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Guid } from '../../../utils/guid-service';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';

export enum ContainerLayout {
  Cards = 0,
  Tabs = 1,
  Accordion = 2,
  Carousel = 3
}

export enum ContainerTabsLayout {
  Horizontal = 0,
  Vertical = 1
}

@jsonObject
export class ContainerColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public Writeable : boolean = false;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

}

@jsonObject
export class ContainerWidgetQuery {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 10;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(ContainerColumnConfig)
  public ColumnConfigs : ContainerColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];
}

@jsonObject
export class ContainerWidget {
  @jsonMember
  public Id : string;

  @jsonMember
  public TypeId : string;

  @jsonMember
  public Json : string;

  public WidgetConfig : WidgetConfig;

  public Name : string;
}

@jsonObject
export class ContainerWidgetConfig extends WidgetConfig {

  @jsonMember
  public ConfigQuery : ContainerWidgetQuery = new ContainerWidgetQuery();

  //@jsonArrayMember(WidgetConfig)
  //public WidgetConfigs : WidgetConfig[] = [];

  @jsonArrayMember(ContainerWidget)
  public ContainerWidgets : ContainerWidget[] = [];

  @jsonMember
  public Layout : ContainerLayout = ContainerLayout.Cards;

  @jsonMember
  public LayoutColumns : number = 3;

  @jsonMember
  public TabsLayout : ContainerTabsLayout = ContainerTabsLayout.Horizontal;

  @jsonMember
  public TabsNameColumn : string;

  public Clone(): ContainerWidgetConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, ContainerWidgetConfig), ContainerWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    // this.ConfigQueries.forEach(c => {
    //   if (c.Query && c.Query.targetprojectionid?.length > 0) {
    //     result.push(c.Query.targetprojectionid)
    //   }
    // });
    return result;
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;

  update(config: ContainerWidgetConfig) {
    super.update(config)

    this.ConfigQuery = config.ConfigQuery;
    this.ContainerWidgets = config.ContainerWidgets;

    this.Layout = config.Layout;
    this.LayoutColumns = config.LayoutColumns;
    this.TabsLayout = config.TabsLayout;
    this.TabsNameColumn = config.TabsNameColumn;
  }
}
