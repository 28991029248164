import { Component, OnInit } from '@angular/core';
import {EditplcprogramService} from '../editplcprogram.service'

@Component({
  selector: 'xproj-editplcprogram-basic',
  templateUrl: './editplcprogram-basic.component.html',
  styleUrls: ['./editplcprogram-basic.component.scss']
})
export class EditplcprogramBasicComponent implements OnInit {

  constructor(public Project : EditplcprogramService) { }

  ngOnInit(): void {
  }

}
