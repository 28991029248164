import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rossakerbmscustomereventactivepipe'
})
export class RossakerBmsCustomerEventActivePipe implements PipeTransform {

  constructor() {}

  transform(active: boolean, ...args: unknown[]): string {

    return  active ? $localize `:@@customerevents_active:Active` : $localize `:@@customerevents_inactive:Inactive`;
  }

}
