Coming soon.

<!-- <main class="content-area">
    <h1>Script Directories</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedDirectory" [clrDgRowSelection]="true" [clrDgLoading]="loadingDirectories">

            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Directories per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Directories
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addScriptDirectory()">
        <clr-icon shape="plus-circle "></clr-icon>
        New Script Directory
    </button>
    <button *ngIf="selectedDirectory" type="button" class="btn btn-link" [clrLoading]="savingRemovingDirectory" (click)="removeDirectory(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove Script Directory
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedDirectory">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Directory configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Directory ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedDirectory.directory.id" name="directoryid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedDirectory.directory.name" name="directoryname" size="35" />
                    </clr-input-container>                    

                    <button class="btn" [clrLoading]="savingDirectory" (click)="saveDirectory()">Save</button>
                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Script Files</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedDirectory" [clrDgRowSelection]="true" [clrDgLoading]="loadingFiles">

                        <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>                       

                        <clr-dg-row *clrDgItems="let file of selectedDirectory.Files" [clrDgItem]="variable">                            
                            <clr-dg-cell class="cellhideoverflow"> {{file.script_name}} </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[1]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Script Files per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Script Files
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <button class="btn btn-link " (click)="addFile()">
                    <clr-icon shape="plus-circle "></clr-icon>
                    New Script File
                </button>
                <button *ngIf="selectedFile" type="button" class="btn btn-link" [clrLoading]="savingRemovingFile" (click)="removeFile(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove Script File
                </button>


                <form clrForm *ngIf="selectedFile">
                    <clr-input-container>
                        <label>Script File ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedFile.id" name="fileid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedFile.sciprt_name" name="filename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>LUA Script </label>
                        <input clrInput type="text" [(ngModel)]="selectedFile.lua_script" name="filescript" size="35" />
                    </clr-input-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form>

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main> -->