import { Pipe, PipeTransform } from '@angular/core';
import { isInstanceOf } from 'typedjson/lib/types/helpers';
import { prettyPrint } from '../../../misc-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { XDataType } from '../../../XProjector/xprojector-client-service';
import { EditMode, EnumMember } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { QueryDataColumn } from './xproj-table-widget.component';

@Pipe({
  name: 'tabledata'
})
export class XprojTableDataPipe implements PipeTransform {

  constructor(
    private dateHelper: DateHelper
  ) {

  }

  transform(column: unknown, dataValue: any, ...args: unknown[]): string {

    let columnData = column as QueryDataColumn;
    let value = dataValue[columnData.columnname];

    switch (columnData.editMode) {
      case EditMode.Boolean:
        return (value == 0 || value == '0' || value == false) ? 'False' : 'True';

      case EditMode.XDatatype:
        return XDataType[dataValue[columnData.columnname]];

      case EditMode.Timestamp:
        if(dataValue[columnData.columnname] instanceof Date)
        {
            return this.dateHelper.utils.formatByString(value as Date, columnData.dateFormat?.length > 0 ? columnData.dateFormat : 'yyyy-MM-dd HH:mm');
        }
        else {
          let t = new Date(value);
          return this.dateHelper.utils.formatByString(t,columnData.dateFormat?.length > 0 ? columnData.dateFormat : 'yyyy-MM-dd HH:mm');
        }

      case EditMode.Enum:
        let enumMember = columnData.enumMembers.find(e => e.value == +value);
        if (enumMember) {
          return enumMember.name;
        }
        else {
          return prettyPrint(value, this.dateHelper);
        }

      case EditMode.Flags:
        let result: string[] = [];
        if (isNaN(value)) {
          for (let i = 0; i < columnData.enumMembers.length; i++) {
            if (value[i]) {
              result.push(columnData.enumMembers[i].name);
            }
          }
        }
        else {
          columnData.enumMembers.forEach(e => {
            if ((value & e.value) > 0) {
              result.push(e.name);
            }
          });
        }

        return result.join(',');

      default:
        return prettyPrint(value, this.dateHelper);
    }

  }

}
