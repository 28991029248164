import { CustomerAdminClient } from './proto/xprojector.customeradmin.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { BasicResponse, Customer, CustomerUser } from './proto/xprojector.grpc.models.pb';
import { StateService } from '@xprojectorcore/services/state-service';
import { Empty } from '@ngx-grpc/well-known-types';
import { DeleteCustomerUserRequest, ModifyCustomerRequest } from './proto/xprojector.customeradmin.pb';
import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class XProjectorCustomerAdminClient implements OnInit, OnDestroy{

  constructor ( private customerAdminClient : CustomerAdminClient,
    private state : StateService,
    private logger: NGXLogger) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async getUsers() : Promise<CustomerUser[]> {
    try
    {
      let result = await this.customerAdminClient.getCustomerUsers(new Empty, this.state.metadata).toPromise();
      return result.users;
    }
    catch (ex){
      this.logger.error(ex);
    }

  }

  async addNewUser(request : CustomerUser) : Promise<CustomerUser> {
    return this.customerAdminClient.addNewCustomerUser(request, this.state.metadata).toPromise();
  }

  async deleteUser(customerUserId : string) : Promise<BasicResponse> {
    let request = new DeleteCustomerUserRequest({ customerUserID : customerUserId });
    return this.customerAdminClient.deleteCustomerUser(request, this.state.metadata).toPromise();
  }

  async modifyCustomer(request : ModifyCustomerRequest) : Promise<BasicResponse> {
    return this.customerAdminClient.modifyCustomer(request, this.state.metadata).toPromise();
  }
}
