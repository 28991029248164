import { Component, OnInit, inject } from '@angular/core';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { DashboardOutputChangeParameters, DateHelper, LinkedWidgetSelectParameters, OutputDataType, XPROJBIMSERVICE, XprojBimService, XprojAlertService, XprojModalService, BimFileInfo } from 'xproj-lib';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { ClrLoadingState } from '@clr/angular';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-rossaker-bms-building-data-collector-view',
  templateUrl: './rossaker-bms-building-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-building-data-collector-view.component.scss']
})
export class RossakerBmsBuildingDataCollectorViewComponent implements OnInit, RossakerBmsDataCollectorComponent {

  public static NodeTypeId: string = '_x_bms_building';

  private alertService: XprojAlertService = inject(XprojAlertService);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private xprojBimService: XprojBimService = inject(XPROJBIMSERVICE);


  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  filesActive : boolean = true;
  editorActive : boolean = false;

  responsiveWidth: number = 600;

  meterParameters: DashboardOutputChangeParameters[] = [];

  uploadFile: any;
  uploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  bimFileInfo: BimFileInfo;

  babylonUploadFile: any;
  babylonUploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  babylonFileInfo: BimFileInfo;

  constructor(

  ) { }

  ngOnInit(): void {
  }


  initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
    this.updateBIMFileInfo('bim');
    this.updateBIMFileInfo('babylon');
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }

  selectBIMFile(file) {
    if (file.files.length > 0) {
      this.uploadFile = file.files[0];
      if (!this.uploadFile.type || this.uploadFile.type.length == 0) {
        let name: string = file.name;
        let s = name.split('.');
        if (s.length > 1) {
          this.uploadFile.type= s[s.length - 1];
        }
        else {
          this.uploadFile.type = 'unknown';
        }
      }
    }
  }

  selectBabaylonFile(babylonfile) {
    if (babylonfile.files.length > 0) {
      this.babylonUploadFile = babylonfile.files[0];
      if (!this.babylonUploadFile.type || this.babylonUploadFile.type.length == 0) {
        let name: string = babylonfile.name;
        let s = name.split('.');
        if (s.length > 1) {
          this.babylonUploadFile.type= s[s.length - 1];
        }
        else {
          this.babylonUploadFile.type = 'unknown';
        }
      }
    }
  }

  async uploadBIMFile() {
    if (this.data && this.uploadFile) {
      try {
        this.uploadingState = ClrLoadingState.LOADING;

        let result = await this.xprojBimService.uploadBimFile(this.data.node.id, this.uploadFile.name, this.uploadFile.type, this.uploadFile, this.data.customer.id);

        if (result) {
          this.uploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateBIMFileInfo('bim');
        }
        else {

          this.uploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async uploadBabaylonFile() {
    if (this.data && this.babylonUploadFile) {
      try {
        this.babylonUploadingState = ClrLoadingState.LOADING;

        let result = await this.xprojBimService.uploadBabylonFile(this.data.node.id, this.babylonUploadFile.name, this.babylonUploadFile.type, this.babylonUploadFile, this.data.customer.id);

        if (result) {
          this.babylonUploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateBIMFileInfo('babylon');
        }
        else {

          this.babylonUploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async updateBIMFileInfo(fileType: string) {
    if (this.data) {
      if (fileType == 'bim') {
        this.bimFileInfo = await this.xprojBimService.getBimFileInfo(this.data.node.id, this.data.customer.id);
      }
      else if (fileType == 'babylon') {
        this.babylonFileInfo = await this.xprojBimService.getBabylonFileInfo(this.data.node.id, this.data.customer.id);
      }
    }

  }

  async downloadBIMFile() {
    if (this.data && this.bimFileInfo) {
      this.xprojBimService.getBimFile(this.data.node.id, this.data.customer.id)
        .then(blob => {
          saveAs(new TextDecoder().decode(blob.data), this.bimFileInfo.name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  async downloadBabylonFile() {
    if (this.data && this.babylonFileInfo) {
      this.xprojBimService.getBabylonFile(this.data.node.id, this.data.customer.id)
        .then(blob => {
          saveAs(new TextDecoder().decode(blob.data), this.babylonFileInfo.name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  async deleteBIMFile() {
    if (this.data && this.bimFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.xprojBimService.deleteBimFile(this.data.node.id, this.data.customer.id)
          .then(result => {
            this.bimFileInfo = undefined;
            this.alertService.success('File deleted');
          })
          .catch(error => {
            this.alertService.error(error);
          });
      }
    }
  }

  async deleteBabylonFile() {
    if (this.data && this.babylonFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.xprojBimService.deleteBabylonFile(this.data.node.id, this.data.customer.id)
          .then(result => {
            this.babylonFileInfo = undefined;
            this.alertService.success('File deleted');
          })
          .catch(error => {
            this.alertService.error(error);
          });
      }
    }
  }
}
