<div *ngIf="widgetConfig.LabelType == LabelWidgetType.LABEL" [style.background-color]="widgetBackgroud" [style.height.px]="widgetheight" style="height: 100%;" [style.margin]="widgetConfig.Margin" #labels>

    <table style="border-spacing: 0.5em;">
        <ng-template ngFor let-value [ngForOf]="values">
            <tr [style.margin]="value.margin">
                <td *ngIf="!value.hidden" class="one" [style.font-size]="value.fontSizeLabel" [style.font-family]="value.fontFamilyLabel" [style.text-align]="value.alignLabel" style="vertical-align: bottom;">
                    {{value.label}}
                </td>
                <td [style.font-size]="value.fontSizeValue" [style.font-family]="value.fontFamilyValue" [style.color]="value.textColor" [style.background-color]="value.backgroundColor">
                    {{value.value}} {{value.unit}}
                </td>
            </tr>
        </ng-template>
    </table>
</div>

<div *ngIf="widgetConfig.LabelType == LabelWidgetType.GAUGE_NEEDLE" id="gauge-canvas-holder" [style.height.px]="widgetheight" style="width:100%">
    <canvas #graphCanvas id="graphCanvas"></canvas>
</div>

<div class="gauge-container" *ngIf="widgetConfig.LabelType == LabelWidgetType.GAUGE" id="gauge2">

    <!-- <div class="head-line">
    <div class="title secondary-text text-bolder" [ngStyle]="{'color': 'grey'}">{{widgetConfig.GaugeSettings.Label}}</div>
    <div class="index">{{gaugeIndex || ''}}</div>
  </div> -->

    <ngx-gauge [size]="gaugeSize" [type]="GaugeType[this.widgetConfig.GaugeSettings.GaugeType].toLocaleLowerCase()" [min]="widgetConfig.GaugeSettings.Min" [label]="widgetConfig.GaugeSettings.Label" [max]="widgetConfig.GaugeSettings.Max" [value]="gaugeValue"
        [cap]="GaugeStyle[this.widgetConfig.GaugeSettings.GaugeStyle].toLocaleLowerCase()" [thick]="widgetConfig.GaugeSettings.Thick" [foregroundColor]="widgetConfig.GaugeSettings.ForegroundColor" [backgroundColor]="widgetConfig.GaugeSettings.BackgroundColor"
        [animate]="widgetConfig.GaugeSettings.Animate" [thresholds]="gaugeThresholdConfig">
        <ngx-gauge-prepend class="ngx-gauge-unit">{{widgetConfig.GaugeSettings.Prepend}}</ngx-gauge-prepend>
        <ngx-gauge-append class="ngx-gauge-unit">{{gaugeUnit}}{{widgetConfig.GaugeSettings.Append}}</ngx-gauge-append>
        <ngx-gauge-value class="ngx-gauge-value">{{gaugeValue ? (gaugeValue | number) : '--'}}</ngx-gauge-value>
    </ngx-gauge>
</div>