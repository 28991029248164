<clr-modal [(clrModalOpen)]="showRemoveCustomer">
  <h3 class="modal-title">Remove customer</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showRemoveCustomer = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showRemoveCustomer = false; doRemoveSelectedCustomer()">Ok</button>
  </div>
</clr-modal>

<div class="limit-height">
  <clr-datagrid [(clrDgSingleSelected)]="selectedCustomer" [clrDgRowSelection]="true">
    <clr-dg-column>ID</clr-dg-column>
    <clr-dg-column>Name</clr-dg-column>
    <clr-dg-column>Description</clr-dg-column>
    <clr-dg-column>Enabled</clr-dg-column>

    <clr-dg-row *ngFor="let customer of customers; let i = index" [clrDgItem]="customer">
      <clr-dg-cell>
        {{customer.username}}
      </clr-dg-cell>
      <clr-dg-cell>
        {{customer.name}}
      </clr-dg-cell>
      <clr-dg-cell>
        {{customer.description}}
      </clr-dg-cell>
      <clr-dg-cell>
        {{customer.isEnabled ? 'Enabled' : 'Disabled'}}
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>{{customers ? customers.length : 0}} Customers</clr-dg-footer>
  </clr-datagrid>
</div>

<div class="clr-row" *ngIf="selectedCustomer">
  <div style="width: 80%;">
    <div class="card">
      <div class="card-header">
        {{selectedCustomer.name}}
      </div>
      <div class="card-block">
        <div class="card-title">
          {{selectedCustomer.description}}
        </div>
        <div class="card-text">
          <clr-input-container>
            <label>Customer id:</label>
            <input clrInput required placeholder="customerid" name="customerid" type="text"
              [(ngModel)]="selectedCustomer.username" />
          </clr-input-container>
          <clr-input-container>
            <label>Customer name:</label>
            <input clrInput required placeholder="customername" name="customername" type="text"
              [(ngModel)]="selectedCustomer.name" />
          </clr-input-container>
          <clr-input-container>
            <label>Customer description:</label>
            <input clrInput required placeholder="customerdescription" name="customerdescription" type="text"
              [(ngModel)]="selectedCustomer.description" />
          </clr-input-container>
          <clr-input-container>
            <label>External id:</label>
            <input clrInput placeholder="" name="customerexternalid" type="text"
              [(ngModel)]="selectedCustomer.externalId" />
          </clr-input-container>
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomer.isEnabled" name="customerenabled" />
              <label>Enabled</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomer.sandboxEnabled" name="customersandboxEnabled" />
              <label>Sandbox Enabled</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="btn-group btn-primary">
          <button type="submit" class="btn btn-outline" (click)="saveSelectedCustomer();">
            <clr-icon shape="floppy"></clr-icon>
            Save
          </button>
          <button type="submit" class="btn btn-danger-outline" (click)="removeSelectedCustomer();">
            <clr-icon shape="trash"></clr-icon>
            Delete
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
