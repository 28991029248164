<div [style.height.px]="widgetheight">
    <div class="controller-container">
        <div class="controller" *ngFor="let controller of widgetConfig.Controllers">
            <xproj-output-range-controller *ngIf="(controller | typeof) == 'XprojOutputRangeControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-range-controller>
            <xproj-output-button-controller *ngIf="(controller | typeof) == 'XprojOutputButtonControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-button-controller>
            <xproj-output-combobox-controller *ngIf="(controller | typeof) == 'XprojOutputComboboxControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton" [widget]="this">
            </xproj-output-combobox-controller>
            <xproj-output-aggregation-controller *ngIf="(controller | typeof) == 'XprojOutputAggregationControllerConfig'" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-aggregation-controller>
            <xproj-output-transform-controller *ngIf="(controller | typeof) == 'XprojOutputTransformControllerConfig'" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-transform-controller>
            <xproj-output-projection-controller *ngIf="(controller | typeof) == 'XprojOutputProjectionControllerConfig'" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-projection-controller>
            <xproj-output-time-controller *ngIf="(controller | typeof) == 'XprojOutputTimeControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-time-controller>
            <xproj-output-toggle-controller *ngIf="(controller | typeof) == 'XprojOutputToggleControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-toggle-controller>
            <xproj-output-radiobutton-controller *ngIf="(controller | typeof) == 'XprojOutputRadioButtonControllerConfig'" [initDone]="initDone" [config]="controller" [widgetConfig]="widgetConfig" [useApplyButton]="widgetConfig.UseApplyButton">
            </xproj-output-radiobutton-controller>
        </div>
        <div class="controller-apply" *ngIf="widgetConfig.UseApplyButton">
            <button class="btn" (click)="apply()">{{widgetConfig.ApplyButtonText}}</button>
        </div>
    </div>
</div>