import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { CustomersComponent } from './components/customers/customers.component';
import { SysAdminUserProfilesComponent } from './components/sys-admin-user-profiles/sys-admin-user-profiles.component';
import { SysAdminWorkspaceComponent } from './components/sys-admin-workspace/sys-admin-workspace.component';

const routes: Routes = [
  {
      path: '', component: SysAdminWorkspaceComponent,
      children: [
          { path: 'customers', component: CustomersComponent },
          { path: 'addcustomer', component: AddCustomerComponent },
          { path: 'users', component: SysAdminUserProfilesComponent }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SysAdminRoutingModule { }
