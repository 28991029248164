import { Component, OnInit, Input, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { XDataType } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputRangeControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-range-controller',
  templateUrl: './xproj-output-range-controller.component.html',
  styleUrls: ['./xproj-output-range-controller.component.scss']
})
export class XprojOutputRangeControllerComponent extends XprojOutputControllerBase implements OnInit, AfterViewInit, OnDestroy {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      if (!this.useApplyButton) {
        this.rangeChange();
      }
    }
    this._initDone = value;
  }

  rangeConfig: XprojOutputRangeControllerConfig;

  value: number;
  play : boolean = false;

  private ngUnsubscribe = new Subject<void>();
  private playtimersource;


  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }

  ngAfterViewInit(): void {
  }


  ngOnInit(): void {
    this.rangeConfig = this.config as XprojOutputRangeControllerConfig;

    this.value = this.rangeConfig.Value;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  rangeChange() {
    //console.log('value', this.value);
    if (!this.useApplyButton) {
      this.widgetService.outputParameterChanged(this.getOutputChangeParameters()[0]);
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.rangeConfig.Id;
    output.datatype = OutputDataType.Number;
    output.value = this.value;

    return [output];
  }

  startPlay($event) {
    if (!this.play) {
      this.play = true;

      if (!this.playtimersource){
        this.playtimersource = timer(this.rangeConfig.PlaySecondsPerStep * 1000, this.rangeConfig.PlaySecondsPerStep * 1000);

        this.playtimersource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
          this.playStep();
        });
      }
    }
    $event.stopPropagation;
  }

  stopPlay($event) {
    this.play = false;
    $event.stopPropagation;
  }

  playStep() {
    if (this.play) {
      this.value += this.rangeConfig.Step;
      if (this.value > this.rangeConfig.Max) {
        this.value = this.rangeConfig.Min;
      }
      this.rangeChange();
    }
  }
}
