import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Guid } from '../../../utils/guid-service';
import { GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { BaseQuery, FilterLogicalGroupType, XDataType } from '../../../XProjector/xprojector-client-service';

export enum EditMode {
  Number = 0,
  String = 1,
  Timestamp = 2,
  Boolean = 3,
  XDatatype = 4,
  Enum = 5,
  Flags = 6
}

@jsonObject
export class EnumMember {
  @jsonMember
  public value : number;

  @jsonMember
  public name : string;
}


@jsonObject
export class ColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public Writeable : boolean = true;

  @jsonMember
  public PrimaryKey : boolean = false;

  @jsonArrayMember(String)
  public Tags : string[] = [];

  @jsonMember
  public EditMode : EditMode = EditMode.String;

  @jsonArrayMember(EnumMember)
  public EnumMembers : EnumMember[] = [];

  @jsonMember
  public UseFixedValue : boolean = false;

  @jsonMember
  public FixedValueInputParameterId: string = '';

  @jsonMember
  public FixedValue : string = '';

}

@jsonObject
export class ProjectionDataEditorWidgetQuery {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(ColumnConfig)
  public ColumnConfigs : ColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonMember
  public FixedGroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonArrayMember(String)
  public FixedGroup : string[] = [];

  @jsonMember
  public FixedGroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public FixedGroupInputParameterIds: string[] = [];

}


@jsonObject
export class ProjectionDataEditorWidgetConfig extends WidgetConfig{

  @jsonMember
  public ConfigQuery : ProjectionDataEditorWidgetQuery = new ProjectionDataEditorWidgetQuery();

  @jsonMember
  public ImportEnabled: boolean = true;

  @jsonMember
  public AddRemoveEnabled: boolean = true;

  @jsonMember
  public UseNativeGroupSetData : boolean =false;

  @jsonMember
  public GroupFilterEnabled: boolean = true;

  @jsonMember
  public SetColumnValueOnDelete: boolean = false;

  @jsonMember
  public OnDeleteColumnName: string = '';

  @jsonMember
  public OnDeletedAtColumnName: string = '';

  @jsonMember
  public OnCreatedAtColumnName: string = '';

  @jsonMember
  public OnModifiedAtColumnName: string = '';

  @jsonArrayMember(Number)
  public RowsPerPageOptions: number[] = [10, 20, 50, 100];

  @jsonMember
  public DefaultRowsPerPage: number = 20;

  @jsonMember
  public Compact: boolean = true;

  @jsonMember
  public EmptyTableText: string = 'No data';

  public Clone() : ProjectionDataEditorWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, ProjectionDataEditorWidgetConfig), ProjectionDataEditorWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    return [];
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = false;

  update(config: ProjectionDataEditorWidgetConfig) {
    super.update(config)

    this.ConfigQuery = config.ConfigQuery;

    this.ImportEnabled = config.ImportEnabled;
    this.AddRemoveEnabled = config.AddRemoveEnabled;
    this.UseNativeGroupSetData = config.UseNativeGroupSetData;
    this.GroupFilterEnabled = config.GroupFilterEnabled;
    this.SetColumnValueOnDelete = config.SetColumnValueOnDelete;
    this.OnDeleteColumnName = config.OnDeleteColumnName;
    this.OnDeletedAtColumnName = config.OnDeletedAtColumnName;
    this.OnCreatedAtColumnName = config.OnCreatedAtColumnName;
    this.OnModifiedAtColumnName = config.OnModifiedAtColumnName;
    this.RowsPerPageOptions = config.RowsPerPageOptions;
    this.DefaultRowsPerPage = config.DefaultRowsPerPage;
    this.Compact = config.Compact;
    this.EmptyTableText = config.EmptyTableText;
  }

}
