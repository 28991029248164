import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_OPCUANODEIDTYPE, XAUTO_XAutoDriver,  XAUTO_XAutoDriverCollectd,  XAUTO_XAutoDriverOpcua,  XAUTO_XAutoVariable,  XAUTO_XAutoVariableCollectd,  XAUTO_XAutoVariableOpcua,  XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public Opcua : XAUTO_XAutoVariableOpcua;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public Opcua : XAUTO_XAutoDriverOpcua;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}

@Component({
  selector: 'xproj-editconnectors-opcua',
  templateUrl: './editconnectors-opcua.component.html',
  styleUrls: ['./editconnectors-opcua.component.scss']
})
export class EditconnectorsOpcuaComponent implements OnInit {

  XAUTO_OPCUANODEIDTYPE = XAUTO_OPCUANODEIDTYPE;
  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
  public loadingConnectors = false;  
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;
 
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;
  public selectedVariable : ViewVariable = null;
  

  constructor(private xprojClient: XProjectorClient) {
  }


  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {    
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {  
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.Opcua = new XAUTO_XAutoVariableOpcua();
    this.selectedVariable.Opcua.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.Opcua.xautovariableid = this.selectedVariable.Variable.xautovariableid;
    this.selectedVariable.Opcua.nodeidtype = XAUTO_OPCUANODEIDTYPE.STRING;    
    this.selectedVariable.Opcua.nodeidbase64 = "";
    this.selectedVariable.Opcua.nodeidguid = "";
    this.selectedVariable.Opcua.nodeidint32 = 0;
    this.selectedVariable.Opcua.nodeidstring = "";

    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  async saveVariable()
  {
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable opcua");
      await this.xprojClient.XAUTO_SaveVariableOpcua( this.selectedVariable.Opcua );
      console.log("save variable opcua done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;    
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;

  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;    

    try
    {
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriverOpcua(this.selectedConnector.Opcua);
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();        
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New OPC UA";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.OPCUA;
    this.selectedConnector.Opcua = new XAUTO_XAutoDriverOpcua();    
    this.selectedConnector.Opcua.uri = "tcp://NNN:4840";
    this.selectedConnector.Opcua.xautodriverid = this.selectedConnector.Driver.xautodriverid;    

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {    
    console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,1000, driver.Driver.xautodriverid);
    for( let j = 0; j < variables.length; j++)
    {
      let variable = variables[j];
      console.log("loading variable", variable.xautovariableid);
      let opcuavar = await this.xprojClient.XAUTO_GetVariableOpcua(variable.xautovariableid);

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.Opcua = opcuavar;
      driver.Variables.push(newViewVar);
    }
    console.log("loading variables done");
  }

  async loadDrivers()
  {
    let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);    
    for(let i = 0; i < drivers.length; i++)
    {    
      let driver = drivers[i];
      if(driver.driver != XAUTO_DriverType.OPCUA)
        continue;

      let opcuadriver = await this.xprojClient.XAUTO_GetDriverOpcua(driver.xautodriverid);
      let viewdriver = new ViewDriver();
      viewdriver.Driver = driver;
      viewdriver.Opcua = opcuadriver;
      await this.loadVariables(viewdriver);
      this.connectors.push(viewdriver);
    }
  }

  async ngOnInit() {
    this.loadDrivers();
  }
}
