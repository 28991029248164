import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { BmsTrusteeAdminComponent } from '@features/bms/bms-admin/bms-trustee-admin/bms-trustee-admin.component';
//import { BmsTrusteeOverviewComponent } from '@features/bms/bms-admin/bms-trustee-overview/bms-trustee-overview.component';
import { RossakerBmsTrusteeAdminWorkspaceComponent } from './components/rossaker-bms-trustee-admin-workspace/rossaker-bms-trustee-admin-workspace.component';
import { RossakerBmsTrusteeAdminComponent } from './components/rossaker-bms-trustee-admin/rossaker-bms-trustee-admin.component';

const routes: Routes = [
  {
      path: '', component: RossakerBmsTrusteeAdminWorkspaceComponent,
      children: [
          //{ path: 'customeroverview', component: RossakerBmsOverviewComponent },
          //{ path: 'trusteeoverview', component: BmsTrusteeOverviewComponent },

          { path: 'trusteeadmin', component: RossakerBmsTrusteeAdminComponent },
          { path: 'trusteeadmin/:id', component: RossakerBmsTrusteeAdminComponent },
          { path: 'trusteeadmin/:id/:customerid', component: RossakerBmsTrusteeAdminComponent },

      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RossakerBmsTrusteeAdminRoutingModule { }
