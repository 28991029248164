<div>
    <xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"
        [gridSettings]="gridSettings" [showInputParametersAction]="true"></xproj-widget-config>
</div>
<br>

<!-- <clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>SVG</clr-accordion-title>
    <clr-accordion-content>
      <ngx-monaco-editor  class="svg-textarea" [options]="editorOptions" [(ngModel)]="svgData">
      </ngx-monaco-editor>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion> -->

<clr-accordion>

  <clr-accordion-panel >
    <clr-accordion-title>LUA: Init</clr-accordion-title>
      <ngx-monaco-editor style="height:40em" name="editinitluta" [options]="editorOptionsLua" [(ngModel)]="widgetConfig.InitLuaScript">
      </ngx-monaco-editor>
  </clr-accordion-panel>

  <clr-accordion-panel >
    <clr-accordion-title>LUA: Data update</clr-accordion-title>
      <ngx-monaco-editor style="height:40em" name="editdataupdatelua" [options]="editorOptionsLua" [(ngModel)]="widgetConfig.OnDataUpdateLuaScript">
      </ngx-monaco-editor>
  </clr-accordion-panel>

  <clr-accordion-panel >
    <clr-accordion-title>LUA: Before Render</clr-accordion-title>
      <ngx-monaco-editor style="height:40em" name="editbeforerenderlua" [options]="editorOptionsLua" [(ngModel)]="widgetConfig.BeforeRenderLuaScript">
      </ngx-monaco-editor>
  </clr-accordion-panel>

</clr-accordion>

<clr-accordion>
    <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
        <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
        <clr-accordion-content>
            <xproj-scripted3d-widget-query-config [widgetQuery]="configQuery" [projections]="projections" [inputParameters]="widgetConfig.InputParameters" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)">
            </xproj-scripted3d-widget-query-config>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addConfigQuery();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>
