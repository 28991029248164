<xproj-text-widget *ngIf="typeId == 'text'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-text-widget>
<xproj-label-widget *ngIf="typeId == 'label'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-label-widget>
<xproj-chart-widget *ngIf="typeId == 'chart'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-chart-widget>
<xproj-table-widget *ngIf="typeId == 'table'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-table-widget>
<xproj-map-widget *ngIf="typeId == 'map'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-map-widget>
<xproj-piechart-widget *ngIf="typeId == 'piechart'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-piechart-widget>
<xproj-spectrum-analyzer-widget *ngIf="typeId == 'spectrum-analyzer'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-spectrum-analyzer-widget>
<xproj-svg-widget *ngIf="typeId == 'svg'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-svg-widget>
<xproj-projection-dataeditor-widget *ngIf="typeId == 'projection-dataeditor'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-projection-dataeditor-widget>
<xproj-spc-widget *ngIf="typeId == 'spc'" [config]="config" [responsive]="responsive"
  [globalWidgetSettings]="globalWidgetSettings" [to]="to" [from]="from" [relativeTimestamp]="relativeTimestamp"
  [widgetheight]="widgetheight" [widgetwidth]="widgetwidth">
</xproj-spc-widget>
