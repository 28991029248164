import { Injectable } from '@angular/core';

import { BasicResponse, GrpcNode, GrpcNodeProperty } from './proto/xprojector.grpc.models.pb';
import { StateService } from '@xprojectorcore/services/state-service';
import { Empty } from '@ngx-grpc/well-known-types';
import { XConfServiceClient } from './proto/xprojector.xconf.pbsc';
import { AddDataSourceInstanceRootNodeRequest,
         CreateNodeResponse,
         CreateReferencedNodeRequest,
         CreateReferencedNodeResponse,
         CreateReferenceRequest,
         DeleteDataSourceDefinitionRequest,
         DeleteDataSourceInstanceRequest,
         DeleteEdgeTypeRequest,
         DeleteNodeRequest,
         DeleteNodeTypeRequest,
         DeleteReferenceRequest,
         GetDataSourceDefinitionRequest,
         GetDataSourceInstanceChildrenRequest,
         GetDataSourceInstanceRequest,
         GetDataSourceInstanceRootNodesRequest,
         GetDataSourceInstancesRequest,
         GetEdgeTypeRequest,
         GetNodeRequest,
         GetNodeTypeRequest,
         GetReferencedNodesRequest,
         GetReferingNodesRequest,
         GetShortestPathRequest,
         GetTreeRequest,
         GetTreeResponse,
         GrpcDataSourceDefinition,
         GrpcDataSourceInstance,
         GrpcEdgeType,
         GrpcNodeType,
         MoveNodeRequest,
         SearchNodesRequest,
         SearchNodesResponse,
         UpdateNodeRequest,
         ReadoutMomentaryValuesRequest,
         ReadoutValuesResponse,
         GetNodeCommandsRequest,
         GetNodeCommandsResponse,
         ExecuteNodeCommandResponse,
         ExecuteNodeCommandRequest,
         GetDataSourceInstanceTreeRequest,
         GetDataSourceInstanceTreeResponse,
         GetNodeLogsRequest,
         GetNodeLogsResponse,
         GrpcNodeLogObject} from './proto/xprojector.xconf.pb';
import { SourceCodeInfo } from './proto/google/protobuf/descriptor.pb';
import { CacheService } from 'xproj-lib';
import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class XProjectorXConfClient {

  private readonly nodeTypesCacheKey : string = 'nodetypes';
  private readonly edgeTypesCacheKey : string = 'edgetypes';

  constructor(
    private xConfClient: XConfServiceClient,
    private state: StateService,
    private logger: NGXLogger,
    private readonly cache: CacheService) {
  }

  async upsertNodeType(request: GrpcNodeType): Promise<BasicResponse> {
    this.cache.remove(this.nodeTypesCacheKey);
    return this.xConfClient.upsertNodeType(request, this.state.metadata).toPromise();
  }

  async getNodeType(nodeId: string): Promise<GrpcNodeType> {
    let request = new GetNodeTypeRequest({ id: nodeId });
    return this.xConfClient.getNodeType(request, this.state.metadata).toPromise();
  }

  async getNodeTypes(useCache : boolean = true): Promise<GrpcNodeType[]> {
    try {
      if (useCache && this.cache.has(this.nodeTypesCacheKey)) {
        return Promise.resolve(this.cache.get(this.nodeTypesCacheKey));
      }
      else {
        let result = await this.xConfClient.getNodeTypes(new Empty, this.state.metadata).toPromise();
        this.cache.set(this.nodeTypesCacheKey, result.nodeTypes);
        return result.nodeTypes;
      }
    }
    catch (ex) {
      this.logger.error(ex);
      throw ex;
    }
  }

  async deleteNodeType(nodeId: string): Promise<BasicResponse> {
    this.cache.remove(this.nodeTypesCacheKey);
    let request = new DeleteNodeTypeRequest({ id: nodeId });
    return this.xConfClient.deleteNodeType(request, this.state.metadata).toPromise();
  }


  async upsertEdgeType(request: GrpcEdgeType): Promise<BasicResponse> {
    this.cache.remove(this.edgeTypesCacheKey);
    return this.xConfClient.upsertEdgeType(request, this.state.metadata).toPromise();
  }

  async getEdgeType(edgeId: string): Promise<GrpcEdgeType> {
    let request = new GetEdgeTypeRequest({ id: edgeId });
    return this.xConfClient.getEdgeType(request, this.state.metadata).toPromise();
  }

  async deleteEdgeType(edegId: string): Promise<BasicResponse> {
    this.cache.remove(this.edgeTypesCacheKey);
    let request = new DeleteEdgeTypeRequest({ id: edegId });
    return this.xConfClient.deleteEdgeType(request, this.state.metadata).toPromise();
  }

  async getEdgeTypes(useCache : boolean = true): Promise<GrpcEdgeType[]> {
    try {
      if (useCache && this.cache.has(this.edgeTypesCacheKey)) {
        return Promise.resolve(this.cache.get(this.edgeTypesCacheKey));
      }
      else {
        let result = await this.xConfClient.getEdgeTypes(new Empty, this.state.metadata).toPromise();
        this.cache.set(this.edgeTypesCacheKey, result.edgeTypes);
        return result.edgeTypes;
      }
    }
    catch (ex) {
      this.logger.error(ex);
      throw ex;
    }
  }

  async upsertDataSourceDefinition(request: GrpcDataSourceDefinition): Promise<BasicResponse> {
    return this.xConfClient.upsertDataSourceDefinition(request, this.state.metadata).toPromise();
  }

  async getDataSourceDefinition(id: string, includeExtensions: boolean = false): Promise<GrpcDataSourceDefinition> {
    let request = new GetDataSourceDefinitionRequest({ id: id, includeExtensions: includeExtensions });
    return this.xConfClient.getDataSourceDefinition(request, this.state.metadata).toPromise();
  }

  async getDataSourceDefinitions(): Promise<GrpcDataSourceDefinition[]> {
    try {
      let result = await this.xConfClient.getDataSourceDefinitions(new Empty, this.state.metadata).toPromise();
      return result.dataSourceDefinitions;
    }
    catch (ex) {
      this.logger.error(ex);
      throw ex;
    }
  }

  async deleteDataSourceDefinition(nodeId: string): Promise<BasicResponse> {
    let request = new DeleteDataSourceInstanceRequest({ id: nodeId });
    return this.xConfClient.deleteDataSourceInstance(request, this.state.metadata).toPromise();
  }


  async upsertDataSourceInstance(request: GrpcDataSourceInstance): Promise<BasicResponse> {
    return this.xConfClient.upsertDataSourceInstance(request, this.state.metadata).toPromise();
  }

  async getDataSourceInstance(id: string): Promise<GrpcDataSourceInstance> {
    let request = new GetDataSourceInstanceRequest({ id: id });
    return this.xConfClient.getDataSourceInstance(request, this.state.metadata).toPromise();
  }

  async getDataSourceInstances(request : GetDataSourceInstancesRequest): Promise<GrpcDataSourceInstance[]> {
    try {
      let result = await this.xConfClient.getDataSourceInstances(request, this.state.metadata).toPromise();
      return result.dataSourceInstances;
    }
    catch (ex) {
      this.logger.error(ex);
      throw ex;
    }
  }

  async deleteDataSourceInstance(nodeId: string): Promise<BasicResponse> {
    let request = new DeleteDataSourceInstanceRequest({ id: nodeId });
    return this.xConfClient.deleteDataSourceInstance(request, this.state.metadata).toPromise();
  }

  async getDataSourceInstanceRootNodes(id : string): Promise<GrpcNode[]> {
    let request = new GetDataSourceInstanceRootNodesRequest({id : id});
    let result = await this.xConfClient.getDataSourceInstanceRootNodes(request, this.state.metadata).toPromise();

    return result.rootNodes;
  }

  async getDataSourceInstanceTree(dataSourceId : string, maxHops : number = 10): Promise<GetDataSourceInstanceTreeResponse> {
    let request = new GetDataSourceInstanceTreeRequest({
      id : dataSourceId,
      maxHops : maxHops });
    let result = await this.xConfClient.getDataSourceInstanceTree(request, this.state.metadata).toPromise();

    return result;
  }

  async getTree(rootId : string, label : string, maxHops : number = 5, isCustomerSpecific : boolean = false, customerId : string = ''): Promise<GetTreeResponse> {
    let request = new GetTreeRequest({
      id : rootId,
      label : label,
      isCustomerSpecific : isCustomerSpecific,
      customerId : customerId,
      maxHops : maxHops });
    let result = await this.xConfClient.getTree(request, this.state.metadata).toPromise();

    return result;
  }

  async getShortestPath(sourceId : string, sourcelabel : string, endId : string, endLabel : string,  maxHops : number = 5): Promise<string[]> {
    let request = new GetShortestPathRequest({
      sourceId : sourceId,
      sourceLabel : sourcelabel,
      endId : endId,
      endLabel : endLabel,
      maxHops : maxHops });

    let result = await this.xConfClient.getShortestPath(request, this.state.metadata).toPromise();

    return result.path;
  }

  async addDataSourceInstanceRootNode(id : string, rootNode : GrpcNode) : Promise<BasicResponse> {
    let request = new AddDataSourceInstanceRootNodeRequest({id : id, rootNode : rootNode});
    return await this.xConfClient.addDataSourceInstanceRootNode(request, this.state.metadata).toPromise();
  }

  async getDataSourceInstanceChildren(id: string): Promise<GrpcDataSourceInstance[]> {
    let request = new GetDataSourceInstanceChildrenRequest({ id: id });
    let response = await  this.xConfClient.getDataSourceInstanceChildren (request, this.state.metadata).toPromise();

    return response.dataSourceInstances;
  }

  //XConf Data

  async createNode(node: GrpcNode): Promise<CreateNodeResponse> {
    return this.xConfClient.createNode(node, this.state.metadata).toPromise();
  }

  async updateNode(node: GrpcNode, oldId : string, comment : string = '', customerId : string = ''): Promise<BasicResponse> {
    let request = new UpdateNodeRequest();
    request.node = node;
    request.oldId = oldId;
    request.comment = comment;
    request.customerId = customerId;
    return this.xConfClient.updateNode(request, this.state.metadata).toPromise();
  }

  async getNode(nodeId : string, label : string, isCustomerSpecific : boolean = false, customerId : string = ''): Promise<GrpcNode> {
    let request = new GetNodeRequest({
      id : nodeId,
      label : label,
      isCustomerSpecific : isCustomerSpecific,
      customerId : customerId
    });

    return this.xConfClient.getNode(request, this.state.metadata).toPromise();
  }

  async getReferencedNodes(nodeId : string, nodeLabel : string, edgeTypeIds : string[], referencedNodeTypeLabel : string = "", maxHops : number = 1, minHops : number = 1): Promise<GrpcNode[]> {
    let request = new GetReferencedNodesRequest({
      id : nodeId,
      label : nodeLabel,
      edgeTypeIds : edgeTypeIds,
      referencedNodeTypeLabel : referencedNodeTypeLabel,
      minHops: minHops,
      maxHops : maxHops
    });

    let result = await this.xConfClient.getReferencedNodes(request, this.state.metadata).toPromise();

    return result.nodes;
  }

  async getRefereringNodes(nodeId : string, nodeLabel : string, edgeTypeIds : string[], referingNodeLabels : string[], maxHops : number = 1, limit : number = 10000, skip : number = 0): Promise<GrpcNode[]> {
    let request = new GetReferingNodesRequest({
      id : nodeId,
      label : nodeLabel,
      edgeTypeIds : edgeTypeIds,
      referingNodeLabels : referingNodeLabels,
      maxHops : maxHops,
      limit : limit,
      skip : skip
    });

    let result = await this.xConfClient.getRefereringNodes(request, this.state.metadata).toPromise();

    return result.nodes;
  }

  async createReferencedNode(node : GrpcNode, referencedFromId: string, referencedFromLabel: string, edgeTypeId: string,
    edgeIsReference: boolean, edgeProperties?: GrpcNodeProperty[], comment : string = '', customerId : string = ''): Promise<CreateReferencedNodeResponse> {

    let request = new CreateReferencedNodeRequest({
      node : node,
      referencedFromId : referencedFromId,
      referencedFromLabel : referencedFromLabel,
      edgeTypeId : edgeTypeId,
      edgeIsreference: edgeIsReference,
      edgeProperties : edgeProperties,
      comment: comment,
      customerId: customerId
    });

    return this.xConfClient.createReferencedNode(request, this.state.metadata).toPromise();
  }

  async createReference(sourceId: string, sourceLabel: string, endId : string, endLabel : string, edgeTypeId: string,
      edgeIsReference: boolean, edgeProperties?: GrpcNodeProperty[], comment : string = '', customerId : string = ''): Promise<BasicResponse> {

    let request = new CreateReferenceRequest({
      edgeIsreference : edgeIsReference,
      edgeTypeId : edgeTypeId,
      edgeProperties : edgeProperties,
      sourceId : sourceId,
      sourceLabel : sourceLabel,
      endId : endId,
      endLabel : endLabel,
      comment: comment,
      customerId: customerId
    });

    return this.xConfClient.createReference(request, this.state.metadata).toPromise();
  }

  async deleteNode(nodeId: string, nodeLabel : string, comment : string = '', customerId : string = ''): Promise<BasicResponse> {
    let request = new DeleteNodeRequest({
      id : nodeId,
      label: nodeLabel,
      comment: comment,
      customerId: customerId
    });
    return this.xConfClient.deleteNode(request, this.state.metadata).toPromise();
  }

  async deleteReference(sourceId: string, sourceLabel: string, endId: string, endLabel : string, edgeTypeId: string, comment : string = '', customerId : string =''): Promise<BasicResponse> {

    let request = new DeleteReferenceRequest({
      sourceId : sourceId,
      sourceLabel : sourceLabel,
      endId : endId,
      endLabel : endLabel,
      edgeTypeId : edgeTypeId,
      comment: comment,
      customerId: customerId
    });

    return this.xConfClient.deleteReference(request, this.state.metadata).toPromise();
  }

  async searchNodes(request : SearchNodesRequest): Promise<SearchNodesResponse> {
    return this.xConfClient.searchNodes(request, this.state.metadata).toPromise();
  }

  async getReferencedNodesWithCache(cacheKey : string, nodeId : string, nodeLabel : string, edgeTypeIds : string[], referencedNodeTypeLabel : string = "", maxHops : number = 1): Promise<GrpcNode[]> {
    try {
      if (this.cache.has(cacheKey)) {
        return Promise.resolve(this.cache.get(cacheKey));
      }
      else {
        let result = await this.getReferencedNodes(nodeId, nodeLabel, edgeTypeIds, referencedNodeTypeLabel, maxHops);
        this.cache.set(cacheKey, result);
        return result;
      }
    }
    catch (ex) {
      this.logger.error(ex);
      throw ex;
    }
  }

  async moveNode(node : GrpcNode, oldReferencedFromId : string, oldReferencedFromLabel : string, newReferencedFromId : string,
    newReferencedFromLabel : string, edgeTypeId : string, edgeIsReference : boolean, edgeProperties?: GrpcNodeProperty[], comment : string = '', customerId : string = ''): Promise<BasicResponse> {
    let request = new MoveNodeRequest({
      node : node,
      oldReferencedFromId : oldReferencedFromId,
      oldReferencedFromLabel : oldReferencedFromLabel,
      newReferencedFromId : newReferencedFromId,
      newReferencedFromLabel : newReferencedFromLabel,
      edgeTypeId : edgeTypeId,
      edgeIsReference : edgeIsReference,
      edgeProperties : edgeProperties,
      comment: comment,
      customerId: customerId
    });

    return this.xConfClient.moveNode(request, this.state.metadata).toPromise();
  }

  async readoutMomentaryValues(nodeId: string, nodeLabel : string): Promise<ReadoutValuesResponse> {
    let request = new ReadoutMomentaryValuesRequest({ id: nodeId, label: nodeLabel });
    return this.xConfClient.readoutMomentaryValues(request, this.state.metadata).toPromise();
  }

  async getNodeCommands(nodeId: string, nodeLabel : string): Promise<GetNodeCommandsResponse> {
    let request = new GetNodeCommandsRequest({ id: nodeId, label: nodeLabel });
    return this.xConfClient.getNodeCommands(request, this.state.metadata).toPromise();
  }

  async executeNodeCommand(nodeId: string, nodeLabel : string, commandId : string): Promise<ExecuteNodeCommandResponse> {
    let request = new ExecuteNodeCommandRequest({ id: nodeId, label: nodeLabel, commandId : commandId });
    return this.xConfClient.executeNodeCommand(request, this.state.metadata).toPromise();
  }

  async getNodeLogs(nodeId: string, nodeLabel : string, readFromId : string): Promise<GrpcNodeLogObject[]> {
    let request = new GetNodeLogsRequest({ nodeId: nodeId, label: nodeLabel, readFromId : readFromId });
    let result = await this.xConfClient.getNodeLogs(request, this.state.metadata).toPromise();

    return result.logs;
  }
}
