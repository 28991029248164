import { Pipe, PipeTransform } from '@angular/core';
import { isInstanceOf } from 'typedjson/lib/types/helpers';
import { prettyPrint } from '../../../misc-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { XDataType } from '../../../XProjector/xprojector-client-service';
import { EditMode, EnumMember } from '../projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { QueryDataColumn } from './xproj-projection-dataeditor-widget.component';

@Pipe({
  name: 'projectiondataedit'
})
export class XprojProjectionDataEditPipe implements PipeTransform {

  constructor(
    private dateHelper: DateHelper
  ) {

  }

  transform(value: unknown, index: any, ...args: unknown[]): string {

    let columnData = value as QueryDataColumn;
    let dataIndex = index as number;

    switch (columnData.editMode) {
      case EditMode.Boolean:
        let b = columnData.data[index];
        return (b == 0 || b == '0' || b == false) ? 'False' : 'True';

      case EditMode.XDatatype:
        return XDataType[columnData.data[index]];

      case EditMode.Timestamp:
        if(columnData.data[index] instanceof Date)
        {
            return this.dateHelper.utils.formatByString(columnData.data[index] as Date, "yyyy-MM-dd HH:mm");
        }
        else {
          let t = new Date(columnData.data[index]);
          return this.dateHelper.utils.formatByString(t, "yyyy-MM-dd HH:mm");
        }

      case EditMode.Enum:
        let enumMember: EnumMember = columnData.enumMembers.find(e => e.value == +columnData.data[index]);
        if (enumMember) {
          return enumMember.name;
        }
        else {
          return prettyPrint(columnData.data[index], this.dateHelper);
        }

      case EditMode.Flags:
        let result: string[] = [];
        let value: any = columnData.data[index];
        if (isNaN(value)) {
          for (let i = 0; i < columnData.enumMembers.length; i++) {
            if (value[i]) {
              result.push(columnData.enumMembers[i].name);
            }
          }
        }
        else {
          columnData.enumMembers.forEach(e => {
            if ((value & e.value) > 0) {
              result.push(e.name);
            }
          });
        }

        return result.join(',');

      default:
        return prettyPrint(columnData.data[index], this.dateHelper);
    }

  }

}
