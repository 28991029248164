import { Component, OnInit } from '@angular/core';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { DashboardOutputChangeParameters, OutputDataType, LinkedWidgetSelectParameters } from 'xproj-lib';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';

@Component({
  selector: 'app-rossaker-bms-default-data-collector-view',
  templateUrl: './rossaker-bms-default-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-default-data-collector-view.component.scss']
})
export class RossakerBmsDefaultDataCollectorViewComponent implements OnInit, RossakerBmsDataCollectorComponent {

  public static NodeTypeId: string = '_x_bms_default';

  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth: number = 600;

  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardId: string;
  systemDashboard: boolean = false;

  constructor(

  ) { }

  ngOnInit(): void {
  }


  initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.dashboardId = '';
    let outputs: DashboardOutputChangeParameters[] = [];

    if (this.data?.node) {
      this.data?.node.propertyValues.forEach(p => {
        if (p.valueType == GrpcNodeProperty.ValueTypes.DASHBOARD) {
          this.dashboardId = p.value;
          // let prop = item.nodeType.dashboardProperties.find(prop => prop.id == p.key);
          // if (prop) {
          //   this.systemDashboard = prop.systemDashboard;
          // }
        } else {
          let out = new DashboardOutputChangeParameters();
          out.outputParameterName = p.key;
          out.value = p.value;
          outputs.push(out);
        }
      })

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = this.data?.node.id;
      outputs.push(out);
    }

    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      outputs.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      outputs.push(out);
    }

    this.dashboardOutputParameters = outputs;
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

}
