import { Component, Input, OnInit } from '@angular/core';
import { GlobalWidgetSettings } from '../../../models/global-widget-settings';
import { ColumnFilteringRelativeTimestamp } from '../../../XProjector/xprojector-client-service';
import { WidgetConfig } from '../../widget-config-service';

@Component({
  selector: 'xproj-container-widget-switch',
  templateUrl: './xproj-container-widget-switch.component.html',
  styleUrls: ['./xproj-container-widget-switch.component.scss']
})
export class XprojContainerWidgetSwitchComponent implements OnInit {

  @Input() config: WidgetConfig;
  @Input() typeId: string;
  @Input() widgetheight: number;
  @Input() widgetwidth: number;

  @Input() responsive: boolean = false;
  @Input() from: Date = null;
  @Input() to: Date = null;
  @Input() relativeTimestamp: ColumnFilteringRelativeTimestamp;
  @Input() globalWidgetSettings: GlobalWidgetSettings;

  constructor() { }

  ngOnInit(): void {
  }

}
