import { Component, OnDestroy, OnInit } from '@angular/core';
import { RossakerBmsTrustee } from '@core/models/rossaker-bms-trustee';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { Subscription } from 'rxjs';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';

@Component({
  selector: 'app-rossaker-bms-trustee-admin-workspace',
  templateUrl: './rossaker-bms-trustee-admin-workspace.component.html',
  styleUrls: ['./rossaker-bms-trustee-admin-workspace.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class RossakerBmsTrusteeAdminWorkspaceComponent implements OnInit, OnDestroy {

  collapsed = false;

  private trusteeSubscription: Subscription;

  selectedTrustee : RossakerBmsTrustee;
  oldSelectedTrustee : RossakerBmsTrustee;


  constructor(
    public rossakerState : RossakerStateService,
  ) {

  }

  ngOnDestroy(): void {
    this.trusteeSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.trusteeSubscription = this.rossakerState.trustee$.subscribe(async (e) => {
      this.selectedTrustee = e.trustee;
    });
    this.rossakerState.updateCustomers();
  }

  currentTrusteeChanged($event) {
    if (this.selectedTrustee) {
      this.rossakerState.setTrustee(this.selectedTrustee.id);
    }

  }

  trusteeSelectOpenChange(open: boolean) {
    if (open) {
      this.oldSelectedTrustee = this.selectedTrustee;
      this.selectedTrustee = null;
    }
    else if (!this.selectedTrustee) {
      this.selectedTrustee = this.oldSelectedTrustee;
    }

  }
}
