import { XDataType } from "../../XProjector/xprojector-client-service";
import { ColumnConfig, EditMode, EnumMember } from "./projection-dataeditor-widget-config/projection-dataeditor-widget-config-service";

export class ProjectionDataeditorWidgetUtils {

  static getEditMode(tags: string[], datatype: XDataType): EditMode {
    let result: EditMode;
    if (tags?.length > 0) {
      let tag = tags.find(tag => tag.startsWith('EDIT='));
      if (tag) {
        let s = tag.substr(5);
        switch (s) {
          case 'XDATATYPE':
            result = EditMode.XDatatype;
            break;

          case 'BOOLEAN':
            result = EditMode.Boolean;
            break;

          case 'ENUM':
            result = EditMode.Enum;
            break;

          case 'FLAGS':
            result = EditMode.Flags;
            break;
        }
      }
    }

    if (!result) {
      switch (datatype) {
        case XDataType.UInt8:
        case XDataType.Float32:
        case XDataType.Float64:
        case XDataType.Int32:
        case XDataType.Int64:
        case XDataType.Number:
          result = EditMode.Number;
          break;

        case XDataType.Timestamp:
          result = EditMode.Timestamp;
          break


        default:
          result = EditMode.String;
          break;
      }
    }

    return result;
  }

  static getEnumMembers(tags: string[], includeZero : boolean = true): EnumMember[] {
    let result: EnumMember[] = [];
    if (tags?.length > 0) {
      let tag = tags.find(tag => tag.startsWith('ENUMVALUES:'));
      if (tag) {
        let members = tag.substr(11).split(';');
        members.forEach(member => {
          let s = member.split('=');
          if (s.length == 2) {
            let member: EnumMember = new EnumMember();
            member.value = +s[0];
            member.name = s[1];
            if (includeZero || member.value > 0) {
              result.push(member);
            }
          }
        });
      }
    }

    return result;
  }
}
