<clr-select-container>
  <label class="clr-col-4">Type</label>
  <select clrSelect class="clr-col-8" name="outputtype" [(ngModel)]="timeConfig.Type">
    <option [ngValue]="OutputTimeType.Range">Time range</option>
    <option [ngValue]="OutputTimeType.Timestamp">Time</option>
    <option [ngValue]="OutputTimeType.Period">Periods</option>
  </select>
</clr-select-container>

<clr-select-container *ngIf="timeConfig.Type == OutputTimeType.Period" >
  <label class="clr-col-4">Period</label>
  <select clrSelect class="clr-col-8" name="period" [(ngModel)]="timeConfig.Period">
    <!-- <option [ngValue]="OutputPeriod.Hour">Hour </option> -->
    <option [ngValue]="OutputPeriod.Day">Day</option>
    <option [ngValue]="OutputPeriod.WeekSunday">Week (Sunday)</option>
    <option [ngValue]="OutputPeriod.WeekMonday">Week (Monday)</option>
    <option [ngValue]="OutputPeriod.Month">Month</option>
    <option [ngValue]="OutputPeriod.Quarter">Quarter</option>
    <option [ngValue]="OutputPeriod.Year">Year</option>
  </select>
</clr-select-container>

<clr-select-container *ngIf="timeConfig.Period == OutputPeriod.Quarter" >
  <label class="clr-col-4">Period</label>
  <select clrSelect class="clr-col-8" name="quarterstartmonth"
    [(ngModel)]="timeConfig.QuarterStartMonth">
    <option [ngValue]="MonthOfYear.January"> January </option>
    <option [ngValue]="MonthOfYear.February"> February </option>
    <option [ngValue]="MonthOfYear.March"> March </option>
    <option [ngValue]="MonthOfYear.April"> April </option>
    <option [ngValue]="MonthOfYear.May"> May </option>
    <option [ngValue]="MonthOfYear.June"> June </option>
    <option [ngValue]="MonthOfYear.July"> July </option>
    <option [ngValue]="MonthOfYear.August"> August </option>
    <option [ngValue]="MonthOfYear.September"> September </option>
    <option [ngValue]="MonthOfYear.October"> October </option>
    <option [ngValue]="MonthOfYear.November"> November </option>
    <option [ngValue]="MonthOfYear.December"> December </option>
  </select>
</clr-select-container>

<clr-input-container *ngIf="timeConfig.Type == OutputTimeType.Period">
  <label class="clr-col-4">Period count back</label>
  <input clrInput class="clr-col-8" type="number" min="1" max="100" [(ngModel)]="timeConfig.PeriodCountBackward"
    name="periodcountbackward" />
</clr-input-container>

<clr-input-container *ngIf="timeConfig.Type == OutputTimeType.Period">
  <label class="clr-col-4">Period count forward</label>
  <input clrInput class="clr-col-8" type="number" min="0" max="100" [(ngModel)]="timeConfig.PeriodCountForward"
    name="periodcountforward" />
</clr-input-container>

<clr-accordion style="margin-top: 1em;">
  <clr-accordion-panel>
    <clr-accordion-title>
      {{timeConfig.Type == OutputTimeType.Range ? 'From' : 'Time'}}
    </clr-accordion-title>
    <form clrForm *ngIf="timeConfig">
      <clr-checkbox-container>
        <label class="clr-col-4">Present year</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="fromYearCurrentTime"
            name="fromyearcurrenttime" (ngModelChange)="yearConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!fromYearCurrentTime">
        <label class="clr-col-4">Year</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.FromConfig.Year"
          name="fromconfigyear" />
      </clr-input-container>

      <clr-input-container *ngIf="fromYearCurrentTime">
        <label class="clr-col-4">Year offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1000" max="3000"
          [(ngModel)]="timeConfig.FromConfig.YearOffset" name="fromconfigyearoffset" />
      </clr-input-container>

      <clr-checkbox-container>
        <label class="clr-col-4">Present month</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="fromMonthCurrentTime"
            name="frommonthcurrenttime" (ngModelChange)="monthConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!fromMonthCurrentTime">
        <label class="clr-col-4">Month</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.FromConfig.Month"
          name="fromconfigmonth" />
      </clr-input-container>

      <clr-input-container *ngIf="fromMonthCurrentTime">
        <label class="clr-col-4">Month offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1000" max="3000"
          [(ngModel)]="timeConfig.FromConfig.MonthOffset" name="fromconfigmonthoffset" />
      </clr-input-container>

      <clr-checkbox-container>
        <label class="clr-col-4">Present day</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="fromDayCurrentTime"
            name="fromdaycurrenttime" (ngModelChange)="dayConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!fromDayCurrentTime">
        <label class="clr-col-4">Day</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.FromConfig.Day"
          name="fromconfigday" />
      </clr-input-container>

      <clr-input-container *ngIf="fromDayCurrentTime">
        <label class="clr-col-4">Day offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1000" max="3000"
          [(ngModel)]="timeConfig.FromConfig.DayOffset" name="fromconfigdayoffset" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Text</label>
        <input clrInput class="clr-col-8" type="text" size="20" [(ngModel)]="timeConfig.FromConfig.Text"
          name="fromconfigtext" />
      </clr-input-container>

      <clr-input-container *ngIf="timeConfig.Type == OutputTimeType.Period">
        <label class="clr-col-4">Format (empty = default)
          <clr-signpost>
            <clr-signpost-content *clrIfOpen  [clrPosition]="'top-right'">
              <h3>Date formats</h3>
              <p>fullDate,
                fullDateWithWeekday,
                normalDate,
                normalDateWithWeekday,
                shortDate,
                year,
                month,
                monthShort,
                monthAndYear,
                monthAndDate,
                weekday,
                weekdayShort,
                dayOfMonth,
                hours12h,
                hours24h,
                minutes,
                seconds,
                fullTime,
                fullTime12h,
                fullTime24h,
                fullDateTime,
                fullDateTime12h,
                fullDateTime24h,
                keyboardDate,
                keyboardDateTime,
                keyboardDateTime12h,
                keyboardDateTime24h</p>
            </clr-signpost-content>
          </clr-signpost>
        </label>
        <input clrInput class="clr-col-8" type="text" size="20" [(ngModel)]="timeConfig.DateFormat"
          name="fromconfigformat" />
      </clr-input-container>
    </form>
    <clr-accordion-content>
    </clr-accordion-content>
  </clr-accordion-panel>
  <clr-accordion-panel *ngIf="timeConfig.Type == OutputTimeType.Range" >
    <clr-accordion-title>
      To
    </clr-accordion-title>
    <form clrForm *ngIf="timeConfig">
      <clr-checkbox-container>
        <label class="clr-col-4">Present year</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="toYearCurrentTime" name="toyearcurrenttime"
            (ngModelChange)="yearConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!toYearCurrentTime">
        <label class="clr-col-4">Year</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.ToConfig.Year"
          name="toconfigyear" />
      </clr-input-container>

      <clr-input-container *ngIf="toYearCurrentTime">
        <label class="clr-col-4">Year offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.ToConfig.YearOffset"
          name="toconfigyearoffset" />
      </clr-input-container>

      <clr-checkbox-container>
        <label class="clr-col-4">Present month</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="toMonthCurrentTime"
            name="tomonthcurrenttime" (ngModelChange)="monthConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!toMonthCurrentTime">
        <label class="clr-col-4">Month</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.ToConfig.Month"
          name="toconfigmonth" />
      </clr-input-container>

      <clr-input-container *ngIf="toMonthCurrentTime">
        <label class="clr-col-4">Month offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000"
          [(ngModel)]="timeConfig.ToConfig.MonthOffset" name="toconfigmonthoffset" />
      </clr-input-container>

      <clr-checkbox-container>
        <label class="clr-col-4">Present day</label>
        <clr-checkbox-wrapper>
          <input clrCheckbox class="clr-col-8" type="checkbox" [(ngModel)]="toDayCurrentTime" name="todaycurrenttime"
            (ngModelChange)="dayConfigChanged()" />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="!toDayCurrentTime">
        <label class="clr-col-4">Day</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.ToConfig.Day"
          name="toconfigday" />
      </clr-input-container>

      <clr-input-container *ngIf="toDayCurrentTime">
        <label class="clr-col-4">Day offset</label>
        <input clrInput class="clr-col-8" type="number" min="-1" max="3000" [(ngModel)]="timeConfig.ToConfig.DayOffset"
          name="toconfigdayoffset" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Text</label>
        <input clrInput class="clr-col-8" type="text" size="20" [(ngModel)]="timeConfig.ToConfig.Text"
          name="toconfigtext" />
      </clr-input-container>

    </form>
    <clr-accordion-content>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
