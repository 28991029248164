import { Component, Inject, Input, OnInit } from '@angular/core';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { Aggregation } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputAggregationControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-aggregation-controller',
  templateUrl: './xproj-output-aggregation-controller.component.html',
  styleUrls: ['./xproj-output-aggregation-controller.component.scss']
})
export class XprojOutputAggregationControllerComponent extends XprojOutputControllerBase implements OnInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  aggregationConfig: XprojOutputAggregationControllerConfig;

  selectedAggregation: Aggregation;

  Aggregation = Aggregation;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }

  ngOnInit(): void {
    this.aggregationConfig = this.config as XprojOutputAggregationControllerConfig;

    this.selectedAggregation = this.aggregationConfig.DefaultAggregation;
  }

  ngAfterViewInit(): void {
    if (!this.useApplyButton) {
      setTimeout(() => {
        this.onSelect();
      }, 2000);
    }
  }


  onSelect() {
    if (!this.useApplyButton) {
      this.widgetService.outputParameterChanged(this.getOutputChangeParameters()[0]);
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.aggregationConfig.Id;
    output.datatype = OutputDataType.Aggregation;
    output.value = this.selectedAggregation;

    return [output];
  }

}
