import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Customer, CustomerUser, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { DashboardOutputChangeParameters, XprojAlertService, OutputDataType, XprojModalService, DateHelper, ArrayUtils, LinkedWidgetChangeParameters, LinkedWidgetSelectParameters } from 'xproj-lib';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { GrpcDataSourceInstance, GrpcNodeType, SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrLoadingState, ClrTabs } from '@clr/angular';
import { map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute } from '@angular/router';
import { SplitAreaDirective } from 'angular-split';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerBmsMeterData } from '@core/models/rossaker-bms-meter-data';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { StateService } from '@xprojectorcore/services/state-service';
import { DropDownItem } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RossakerXProjectorBmsExportClient } from '@core/xprojector_backend/rossaker-xprojector-bms-export-client';
import { RossakerBmsDataUtils } from '@core/utils/rossaker-bms-data-utils';
import { RossakerBmsMasterDataImportResult } from '@core/models/rossaker-bms-master-data-import-result';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

class ExternalIdComparator implements ClrDatagridComparatorInterface<RossakerBmsMeterData> {
  compare(a: RossakerBmsMeterData, b: RossakerBmsMeterData) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

@Component({
  selector: 'app-rossaker-bms-customers-admin',
  templateUrl: './rossaker-bms-customers-admin.component.html',
  styleUrls: ['./rossaker-bms-customers-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RossakerBmsCustomersAdminComponent implements OnInit, OnDestroy {

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  @ViewChild(ClrTabs) private readonly tabs: ClrTabs;

  ascSort = ClrDatagridSortOrder.ASC;
  externalIdSort = new ExternalIdComparator();

  ngUnsubscribe = new Subject<void>();

  customers: Customer[];
  bmsCustomers: RossakerBmsCustomer[];
  loadingCustomers: boolean = false;

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;

  inparamCustomerId: string;

  allNodeTypes: GrpcNodeType[] = [];

  rightPanelWidth: number = 300;
  bmsRightPanelWidth: number = 300;
  overviewResponsiveWidth: number;

  searchValue: string = '';

  bmsDataSourceInstance: GrpcDataSourceInstance;
  selectedBmsTreeNode: GrpcNode;

  importingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  importDatafile: any;
  importResult: RossakerBmsMasterDataImportResult;

  _overviewActive: boolean = false;
  get overviewActive(): boolean {
    return this._overviewActive;
  }
  set overviewActive(value: boolean) {
    this._overviewActive = value;
    if (this._overviewActive) {

    }
  }

  _bmsActive: boolean = true;
  get bmsActive(): boolean {
    return this._bmsActive;
  }
  set bmsActive(value: boolean) {
    if (value != this._bmsActive) {
      this.updateSelectedCustomerConfig();
      //this.updateSelectedCustomerRealestates();
      this._bmsActive = value;
    }
  }

  _customersListActive: boolean = true;
  get customersListActive(): boolean {
    return this._customersListActive;
  }
  set customersListActive(value: boolean) {
    this._customersListActive = value;
    if (this._customersListActive) {
      localStorage.setItem("xprojector-customers-admin-navigation", '0');
    }
  }

  _customersListHideAssociationId: boolean = true;
  get customersListHideAssociationId(): boolean {
    return this._customersListHideAssociationId;
  }
  set customersListHideAssociationId(value: boolean) {
    this._customersListHideAssociationId = value;
    localStorage.setItem("xprojector-customers-admin-customerslisthideassociationid", value ? '1' : '0');
  }

  dropDownItems: DropDownItem[] = [
    {
      name: 'Export Webport taglist',
      shape: 'export',
      nodeTypeLabel: '_x_bms_realestate',
      onClick: this.onExportTagList.bind(this)
    }
  ];

  constructor(
    private state: StateService,
    private rossakerState: RossakerStateService,
    private sysAdminClient: XProjectorSysAdminClient,
    private xconfClient: XProjectorXConfClient,
    private alertService: XprojAlertService,
    private modalService: XprojModalService,
    private cdr: ChangeDetectorRef,
    private adminService: RossakerBmsAdminService,
    private rossakerBmsExportClient : RossakerXProjectorBmsExportClient,
    private logger: NGXLogger,
    public dateHelper: DateHelper,
    private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
        this.logger.info('ngOnInit customerId', id);
        //this.inparamCustomerId = id;
      }
    });

    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.selectedCustomerData = new RossakerBmsCustomerData();
      if (this.selectedBmsCustomer) {
        this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
        this.updateSelectedCustomerConfig();
      }
      //await this.updateSelectedCustomerConfig();
    });

    await this.checkInparams();

    await this.updateWidth();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async checkInparams() {

  }

  async updateWidth() {
    //await this.onSplitDragEnd();
    this.overviewResponsiveWidth = window.innerWidth - 300;
  }

  async updateSelectedCustomerConfig() {
    if (this.allNodeTypes.length == 0) {
      this.allNodeTypes = await this.xconfClient.getNodeTypes();
    }

    if (this.selectedBmsCustomer && !this.selectedCustomerConfig) {
      this.selectedCustomerConfig = await this.adminService.getCustomerConfig(this.selectedBmsCustomer.customerId, this.allNodeTypes);
    }

    if (this.selectedBmsCustomer && !this.bmsDataSourceInstance) {
      this.bmsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_' + this.selectedBmsCustomer.customerId);
    }

  }

  async onOverviewDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {
    this.logger.debug(parameters);
    if (parameters.selected?.columnname == 'MeterId') {
      // this.searchValue = parameters.selected?.value;
      // this.search();
    }
  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.selectedBmsTreeNode = item.treeNode.node;
  }

  async search() {
  }

  async onExportTagList(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node.nodeTypeLabel == '_x_bms_realestate') {
      let pExternalId = treeNode.node.propertyValues.find(p => p.key == 'externalid');
      if (pExternalId) {
        let externalId = RossakerBmsDataUtils.getValue(pExternalId.valueType, pExternalId.value, pExternalId, this.dateHelper);
        let result = await this.rossakerBmsExportClient.createExportFile('webport', '', treeNode.id, treeNode.node.nodeTypeLabel,
                externalId + '_LORA.csv', this.selectedBmsCustomer?.customerId);
      }
    }
  }

  uploadDataFile(datafile) {
    if (datafile.files.length > 0) {
      this.importDatafile = datafile.files[0];
    }
  }

  async importDataFiles() {
    if (this.selectedBmsCustomer && this.importDatafile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        let count = await this.rossakerState.importDataFile(this.importDatafile, this.selectedBmsCustomer.customerId);

        this.modalService.ShowConfirmModal({
          header: 'Data import',
          description: ['Data imported ok.',
            ' DataPoints imported: ' + count]
          , showCancel: false
        }, (result) => { });
        this.importingState = ClrLoadingState.SUCCESS;
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
        this.importingState = ClrLoadingState.ERROR;
        this.importResult = new RossakerBmsMasterDataImportResult();
        this.importResult.ok = false;
        this.importResult.message = err.message;
      }
    }
  }
}
