import { Component, Inject, OnInit, inject } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { RossakerXProjectorWebClient, RossakerXProjectorWebContactInfo } from '@core/xprojector_backend/rossaker-xprojector-web-client';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  private webClient : RossakerXProjectorWebClient = inject(RossakerXProjectorWebClient)

  public notsaved = true;
  contactInfo : RossakerXProjectorWebContactInfo = new RossakerXProjectorWebContactInfo();
  savingPost : ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor() { }

  ngOnInit(): void {
  }

  async submit() {
    this.savingPost = ClrLoadingState.LOADING;
    try
    {
      await this.webClient.addContactInfo(this.contactInfo);
      this.savingPost = ClrLoadingState.SUCCESS;
    }
    catch(err)
    {
      this.savingPost = ClrLoadingState.ERROR;
    }
  }

  clear() {
    this.contactInfo = new RossakerXProjectorWebContactInfo();
  }
}
