import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcStringArrayProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { Guid } from 'xproj-lib';

@Component({
  selector: 'app-string-array-input',
  templateUrl: './string-array-input.component.html',
  styleUrls: ['./string-array-input.component.scss']
})
export class StringArrayInputComponent implements OnInit, AfterViewInit {

  @Input() stringArrayProperty : GrpcStringArrayProperty;
  @Input() value : string[];
  @Input() customerId: string;

  optionNodes : GrpcNode[] = [];

  id : string = Guid.newGuid();

  constructor(
    private xConfClient: XProjectorXConfClient
  ) { }


  async ngOnInit() {
    if (this.value?.length == 1 && this.value[0].length == 0) {
      this.value.pop();
    }
  }

  async ngAfterViewInit() {
    if (this.stringArrayProperty && this.stringArrayProperty.optionsRootNodeId?.length > 0 && this.optionNodes.length == 0) {
      let optionsRootNodeId : string = this.stringArrayProperty.optionsRootNodeId;
      if (this.stringArrayProperty.optionsRootIsCustomerSpecific && this.customerId) {
        optionsRootNodeId = optionsRootNodeId + '_' + this.customerId;
      }
      this.optionNodes = await this.xConfClient.getReferencedNodesWithCache(optionsRootNodeId, optionsRootNodeId, this.stringArrayProperty.optionsRootNodeLabel, [], '', 1);

      if (this.stringArrayProperty.optionsNodeAttribute?.length > 0) {
        this.optionNodes.forEach(node => {
          let property = node.propertyValues.find(p => p.key == this.stringArrayProperty.optionsNodeAttribute);
          if (property) {
            node.name = property.value;
          }

        });
      }
      this.optionNodes = this.optionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }
  }

}
