import { EventEmitter, Injectable, InjectionToken, Output } from '@angular/core';
import { Blob } from '../XProjector/xprojector-client-service';

export const XPROJBIMSERVICE = new InjectionToken<XprojBimService>('XPROJBIMSERVICE');

export class BimFileInfo {
  public id : string;
  public name : string;
  public lastModified : Date;
}

@Injectable({
  providedIn: 'root'
})
export abstract class XprojBimService {

  abstract uploadBimFile(id: string, name: string, fileFormat: string, blob : Blob, customerId : string) : Promise<boolean>;

  abstract uploadBabylonFile(id: string, name: string, fileFormat: string, blob : Blob, customerId : string) : Promise<boolean>;

  abstract uploadFloorplanFile(id: string, name: string, fileFormat: string, blob : Blob, customerId : string) : Promise<boolean>;

  abstract getBimFileInfo(id : string, customerId: string) : Promise<BimFileInfo>;

  abstract getBabylonFileInfo(id : string, customerId : string) : Promise<BimFileInfo>;

  abstract getFloorplanFileInfo(id : string, customerId : string) : Promise<BimFileInfo>;

  abstract getBimFile(id : string, customerId : string) : Promise<Blob>;

  abstract getBabylonFile(id : string, customerId : string) : Promise<Blob>;

  abstract getFloorplanFile(id : string, customerId : string) : Promise<Blob>;

  abstract deleteBimFile(id : string, customerId : string) : Promise<boolean>;

  abstract deleteBabylonFile(id : string, customerId : string) : Promise<boolean>;

  abstract deleteFloorplanFile(id : string, customerId : string) : Promise<boolean>;
}
