<as-split direction="horizontal" [unit]="'pixel'" class="dashsource-view" (dragEnd)="onSplitDragEnd($event)" [gutterSize]="7">
    <as-split-area [(size)]="datasourcePaneWidth" [order]="1">
        <app-configuration-datasource #bmsConfigurationDatasource [sensitiveNodes]="sensitiveNodes" [showNodeTypes]="showNodeTypes" *ngIf="_dataSourceInstance" [dataSourceInstance]="_dataSourceInstance" [dropDownItems]="dropDownItems" [rootNodes]="rootNodes"
            [sortByName]="sortByName" [sortPaddingZeros]="sortPaddingZeros" [highlightSelected]="highlightSelected" [lazy]="lazy" [treeMaxHops]="treeMaxHops" [moveEnabled]="moveEnabled" [moveEnabledNodeTypeIds]="moveEnabledNodeTypeIds" [expandRoot]="expandRoot"
            [selectedPath]="selectedPath" [singletonReferences]="singletonReferences" [deleteWtihChildrenEnabled]="deleteWtihChildrenEnabled" [eventLogdEnabled]="eventLogdEnabled" (onTreeNodeSelect)="onTreeNodeSelected($event)"></app-configuration-datasource>
    </as-split-area>

    <as-split-area *ngIf="showDetailPane || showSearchPane" [order]="2">
        <div class="detail-pane">
            <xproj-dashboard *ngIf="showDetailPane && dashboardId?.length > 0" #dashboard [editMode]="false" [showDashboardSettings]="false" [dashboardId]="dashboardId" [enableExport]="false" [dashboardOutputParameters]="dashboardOutputParameters" [responsiveWidth]="responsiveWidth"></xproj-dashboard>

            <app-configuration-search *ngIf="showSearchPane" [customerId]="customerId" [dataSourceInstanceId]="dataSourceInstanceId" (viewTreeNode)="onViewTreeNode($event)" [dataSourceInstance]="_dataSourceInstance"></app-configuration-search>
        </div>
    </as-split-area>
</as-split>
