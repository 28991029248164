<span *ngIf="isDate">{{Data| xproj_pretty}}</span>
<span *ngIf="isBoolean">{{Data| xproj_pretty}}</span>
<span *ngIf="isNumber">{{Data| xproj_pretty}}</span>
<span *ngIf="isNull"></span>
<span *ngIf="isArray && useLabelsForArrays">
    <!-- <input clrInput *ngIf="addableLabelsForArrays" type="text" [(ngModel)]="newTag" name="newtags" size="35"  /><br> -->

    <span class="label" style="margin-top: 4px;" *ngFor="let it of Data">
        {{it| xproj_pretty}}
        <clr-icon *ngIf="removeableLabelsForArrays" shape="window-close" style="cursor: pointer;margin-left:0.5em;" (click)="removelabel(it);"></clr-icon>
    </span>
</span>
<span *ngIf="isArray && !useLabelsForArrays">
        {{Data| xproj_pretty}}
</span>
<span *ngIf="isMap">
    <clr-signpost>
        <button class="btn btn-link btn-sm" clrSignpostTrigger>View</button>

        <clr-signpost-content *clrIfOpen>
            <h3 *ngIf="Title.length>0">{{title}}</h3>
            <h3 *ngIf="Title.length==0">Details</h3>
            <p *ngFor="let item of Data|keyvalue">
                {{item.key}}: <code class="clr-code">{{item.value}}</code>
            </p>

        </clr-signpost-content>
    </clr-signpost>


</span>
<span *ngIf="isOther">{{Data| xproj_pretty}}</span>
