/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for module.rossaker.web.AddContactInfoRequest
 */
export class AddContactInfoRequest implements GrpcMessage {
  static id = 'module.rossaker.web.AddContactInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddContactInfoRequest();
    AddContactInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddContactInfoRequest) {
    _instance.firstname = _instance.firstname || '';
    _instance.surname = _instance.surname || '';
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
    _instance.company = _instance.company || '';
    _instance.country = _instance.country || '';
    _instance.worktitle = _instance.worktitle || '';
    _instance.message = _instance.message || '';
    _instance.target = _instance.target || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddContactInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.firstname = _reader.readString();
          break;
        case 2:
          _instance.surname = _reader.readString();
          break;
        case 3:
          _instance.email = _reader.readString();
          break;
        case 4:
          _instance.phone = _reader.readString();
          break;
        case 5:
          _instance.company = _reader.readString();
          break;
        case 6:
          _instance.country = _reader.readString();
          break;
        case 7:
          _instance.worktitle = _reader.readString();
          break;
        case 8:
          _instance.message = _reader.readString();
          break;
        case 9:
          _instance.target = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddContactInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddContactInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.firstname) {
      _writer.writeString(1, _instance.firstname);
    }
    if (_instance.surname) {
      _writer.writeString(2, _instance.surname);
    }
    if (_instance.email) {
      _writer.writeString(3, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(4, _instance.phone);
    }
    if (_instance.company) {
      _writer.writeString(5, _instance.company);
    }
    if (_instance.country) {
      _writer.writeString(6, _instance.country);
    }
    if (_instance.worktitle) {
      _writer.writeString(7, _instance.worktitle);
    }
    if (_instance.message) {
      _writer.writeString(8, _instance.message);
    }
    if (_instance.target) {
      _writer.writeString(9, _instance.target);
    }
  }

  private _firstname?: string;
  private _surname?: string;
  private _email?: string;
  private _phone?: string;
  private _company?: string;
  private _country?: string;
  private _worktitle?: string;
  private _message?: string;
  private _target?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddContactInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddContactInfoRequest.AsObject>) {
    _value = _value || {};
    this.firstname = _value.firstname;
    this.surname = _value.surname;
    this.email = _value.email;
    this.phone = _value.phone;
    this.company = _value.company;
    this.country = _value.country;
    this.worktitle = _value.worktitle;
    this.message = _value.message;
    this.target = _value.target;
    AddContactInfoRequest.refineValues(this);
  }
  get firstname(): string | undefined {
    return this._firstname;
  }
  set firstname(value: string | undefined) {
    this._firstname = value;
  }
  get surname(): string | undefined {
    return this._surname;
  }
  set surname(value: string | undefined) {
    this._surname = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }
  get company(): string | undefined {
    return this._company;
  }
  set company(value: string | undefined) {
    this._company = value;
  }
  get country(): string | undefined {
    return this._country;
  }
  set country(value: string | undefined) {
    this._country = value;
  }
  get worktitle(): string | undefined {
    return this._worktitle;
  }
  set worktitle(value: string | undefined) {
    this._worktitle = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get target(): string | undefined {
    return this._target;
  }
  set target(value: string | undefined) {
    this._target = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddContactInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddContactInfoRequest.AsObject {
    return {
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      company: this.company,
      country: this.country,
      worktitle: this.worktitle,
      message: this.message,
      target: this.target
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddContactInfoRequest.AsProtobufJSON {
    return {
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      company: this.company,
      country: this.country,
      worktitle: this.worktitle,
      message: this.message,
      target: this.target
    };
  }
}
export module AddContactInfoRequest {
  /**
   * Standard JavaScript object representation for AddContactInfoRequest
   */
  export interface AsObject {
    firstname?: string;
    surname?: string;
    email?: string;
    phone?: string;
    company?: string;
    country?: string;
    worktitle?: string;
    message?: string;
    target?: string;
  }

  /**
   * Protobuf JSON representation for AddContactInfoRequest
   */
  export interface AsProtobufJSON {
    firstname?: string;
    surname?: string;
    email?: string;
    phone?: string;
    company?: string;
    country?: string;
    worktitle?: string;
    message?: string;
    target?: string;
  }
}

/**
 * Message implementation for module.rossaker.web.AddContactInfoResponse
 */
export class AddContactInfoResponse implements GrpcMessage {
  static id = 'module.rossaker.web.AddContactInfoResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddContactInfoResponse();
    AddContactInfoResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddContactInfoResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddContactInfoResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddContactInfoResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddContactInfoResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddContactInfoResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddContactInfoResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    AddContactInfoResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddContactInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddContactInfoResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddContactInfoResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module AddContactInfoResponse {
  /**
   * Standard JavaScript object representation for AddContactInfoResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for AddContactInfoResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}
