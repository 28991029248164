<main class="content-area">
  <router-outlet></router-outlet>
</main>

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="eye"></clr-icon>
    Administration

    <clr-vertical-nav-group-children>
      <!-- <a clrVerticalNavLink  *ngFor="let instance of dataSourceInstances"  [routerLink]="['viewdatasource/' + instance.id]" routerLinkActive="active">
        {{instance.id}}
      </a> -->
      <clr-tree [clrLazy]="true" *ngIf="initiated">
        <clr-tree-node *clrRecursiveFor="let item of dataSourceInstanceRootNodes; getChildren: getChildren">
          <!-- (click)="selectItem(item); item.expanded = !item.expanded"> -->
          <clr-icon [attr.shape]="'node'"></clr-icon>
          <a class="clr-treenode-link" [routerLink]="['viewdatasource/' + item.id]" routerLinkActive="active"
            (click)="selectItem(item)">
            {{item?.dataSourceInstance.name || item?.id}}
          </a>
        </clr-tree-node>
      </clr-tree>

    </clr-vertical-nav-group-children>

  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="image-gallery"></clr-icon>
    Files

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['filelibrary']" routerLinkActive="active">
        File library
      </a>
    </clr-vertical-nav-group-children>

  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="wrench"></clr-icon>
    Configurations

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['editnodetypes']" routerLinkActive="active">
        Edit NodeTypes
      </a>
      <a clrVerticalNavLink [routerLink]="['editedgetypes']" routerLinkActive="active">
        Edit EdgeTypes
      </a>
      <a clrVerticalNavLink [routerLink]="['editdatasourcedefinitions']" routerLinkActive="active">
        Edit DataSource def.
      </a>
      <a clrVerticalNavLink [routerLink]="['editdatasourceinstances']" routerLinkActive="active">
        Edit DataSource intances
      </a>
    </clr-vertical-nav-group-children>

  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="image-gallery"></clr-icon>
    Logging

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['logconfig']" routerLinkActive="active">
        Config
      </a>
    </clr-vertical-nav-group-children>

  </clr-vertical-nav-group>

</clr-vertical-nav>
