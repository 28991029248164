import { inject } from '@angular/core';
import { TreeviewItem } from '../../../core/models/treeview-item';
import { AdminDashboardsService } from './admin-dashboards.service';
import { XProjectorDashboardClient } from '../../../core/xprojector_backend/xprojector-dashboard-client';
import { Treeview } from '../../../core/models/treeview';
import { BaseQuery, DFTQuery, DashboardConfig, DownSampleQuery, MultiseriesQuery, WidgetExportParameters } from 'xproj-lib';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import * as XLSX from 'xlsx';

export class AdminDashboardsXConfService implements AdminDashboardsService {

  private dashboardClient : XProjectorDashboardClient = inject(XProjectorDashboardClient);
  private customerUserClient: XProjectorCustomerUsersClient = inject(XProjectorCustomerUsersClient);
  public clipboardTreeviewItem : TreeviewItem;


  async saveDashboardTree(treeview: Treeview): Promise<boolean> {
    return this.dashboardClient.saveDashboardTree(treeview);
  }

  async loadDashboard(dashboardId: string): Promise<DashboardConfig> {
    return this.dashboardClient.loadDashboard(dashboardId);
  }

  async saveDashboard(dashboard: DashboardConfig): Promise<boolean> {
    return this.dashboardClient.saveDashboard(dashboard);
  }

  async deleteDashboard(dashboardId: string): Promise<boolean> {
    return this.dashboardClient.deleteDashboardOld(dashboardId);
  }

  async getDashboardTrees(): Promise<Treeview[]> {
    return this.dashboardClient.getDashboardTrees();
  }

  async deleteDashboardTree(treeviewdId: string): Promise<boolean> {
    return this.dashboardClient.deleteDashboardTree(treeviewdId);
  }

  async widgetExport(parameters: WidgetExportParameters) : Promise<boolean>{
    return await this.customerUserClient.exportToExcel(parameters, 100000);
  }

}
