<div *ngIf="luaQueryColumn">
  <form clrForm autocomplete="off" style="width: auto;">

    <clr-datagrid [(clrDgSingleSelected)]="selectedLuaExportColumn">
      <clr-dg-column>Column name</clr-dg-column>
      <clr-dg-column>Script name</clr-dg-column>

      <clr-dg-row *ngFor="let inputcol of luaQueryColumn.inputcolumns; let i = index" [clrDgItem]="inputcol">
        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="inputcol_colname_{{i}}" id=inputcol_colname_{{i}}
            [(ngModel)]="inputcol.columnname" />
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="inputcol_scriptname_{{i}}" id=inputcol_scriptname_{{i}}
            [(ngModel)]="inputcol.scriptname" />
        </clr-dg-cell>

      </clr-dg-row>
    </clr-datagrid>

    <button type="button" class="btn btn-link" (click)="addLuaExportColumn()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add export column
    </button>
    <button *ngIf="selectedLuaExportColumn" type="button" class="btn btn-link" (click)="deleteSelectedLuaExportColumn()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected column
    </button>

    <clr-input-container>
      <label>Column Outname </label>
      <input clrInput type="text" [(ngModel)]="luaQueryColumn.columnoutname" name="columnoutname" />
    </clr-input-container>

    <clr-select-container>
      <label>Data type out</label>
      <select clrSelect name="coltype" [(ngModel)]="luaQueryColumn.datatypeout">
        <option [ngValue]="XDataType.Number">Number</option>
        <option [ngValue]="XDataType.Timestamp">Timestamp</option>
        <option [ngValue]="XDataType.String">String</option>
      </select>
    </clr-select-container>

    <clr-input-container *ngIf="unit != undefined && luaQueryColumn.datatypeout == XDataType.Number" >
      <label>Unit </label>
      <input clrInput type="text" [(ngModel)]="unit" name="unit" (change)="onUnitChanged($event)"/>
    </clr-input-container>

  </form>

  <b>Lua script</b>
  <ngx-monaco-editor class="script-textarea" [options]="editorOptions" [(ngModel)]="luaQueryColumn.luascript">
  </ngx-monaco-editor>

  <button class="btn btn-danger-outline" (click)="removeQueryColumn()">Remove scripted column</button>
</div>
