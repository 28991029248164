import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminDashboardWorkspaceComponent } from './components/admin-dashboard-workspace/admin-dashboard-workspace.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';


const routes: Routes = [
  {
      path: '', component: AdminDashboardWorkspaceComponent,
      children: [
        { path: ':id', component: AdminDashboardComponent },
        { path: ':id/:edit', component: AdminDashboardComponent }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminDashboardsRoutingModule { }
