<div *ngIf="widgetConfig.GroupQueriesByName" style="margin-bottom: 1em; text-align: center; height: 20px;">
  <div class="btn-group btn-sm">
    <button *ngFor="let group of chartGroups"  [class]="getClass(group)" (click)="setSelectedChartGroup(group)">{{group}}</button>
  </div>
</div>

<div *ngIf="widgetConfig.Streaming.Enabled && widgetConfig.Streaming.ShowPlayPauseButton" style="margin-bottom: 1em; text-align: center; height: 20px;">
  <div class="btn-group btn-sm">
    <button class="btn btn-secondary" (click)="onPause($event)"><clr-icon class="is-solid" [attr.shape]="playPauseButtonShape"></clr-icon></button>
  </div>
</div>

<div #chartWidget class="chart-widget"
  [style.height.px]="widgetConfig.GroupQueriesByName || (widgetConfig.Streaming.Enabled && widgetConfig.Streaming.ShowPlayPauseButton) ? widgetheight-30 : widgetheight" (dblclick)="resetZoom()"
	(keydown.shift)="onShiftDown($event)" (keyup.shift)="onShiftUp($event)" (keydown.alt)="onAltDown($event)"
	(keyup.alt)="onAltUp($event)" (keyup.p)="onPause($event)" (focusout)="focusOut()" tabindex="1">
  <canvas #graphCanvas id="graphCanvas" (click)="graphClick($event)">{{ graphCanvas }}</canvas>
</div>
