<div>
<h2>Big Data</h2>
Rossaker offer <em>XProjector</em>, a complete Big Data system with dynamic data models and real-time calculations designed to scale.<br>
This system may be white-labeled to run multi-tentant services or it may be used directly as a BI-tool.<br>
The commercial trade mark for XProjector when deployed as-is is called <em>Robotrisa</em>.

<h3>Deployment</h3>
Deployment of XProjector may be done in Rossaker Cloud, third-party Cloud or on-premise in various configurations.<br>
This enables XProjector to be deployed in existing in-house data centers, third-party located cloud services.
<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Deployment details </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
            <h4>Operating System</h4>
            XProjector may be deployed with Linux APT-based systems. FreeBSD support in progress.<br>
            No third party user-space software is required.<br>
            <em>Optional</em> dependencies in kernelspace; BRD.<br>
            <em>Optional</em> user-spce software: NGINX, Redis, MongoDB &amp; PostgreSQL.
            <br>
            
            <h4>Hardware requirement</h4>
            Lowest hardware requirements for binary compliance are 32bit Mips. This is however not relevant for a Big Data scenario.<br>
            Recommended hardware for performant Big-Data calculation nodes are impossible to specify since the hardware requirement will vary based on;
            <ul>
                <li>Data volume</li>
                <li>Velocity (speed of data going in and out of the system)</li>
                <li>Number of active / concurrent users</li>
                <li>Third party system integrations and their query needs</li>
            </ul>
            
            <br>
            In the Rossaker Cloud we prefer to use
            high performant servers with focus on;
            <ul>
                <li>
                    Reliable SSDs
                </li>
                <li>RAM size</li>
                <li>#Cores</li>
                <li>Core performance</li>
            </ul>
             
            However, there are many situations where smaller nodes in a cluster will perform better.
            <br>
            <em>Depending on the four V's metrics our hardware recommendations will vary.</em>
        </clr-accordion-content>
      </clr-accordion-panel>
</clr-accordion>

<h3>Fast calculations</h3>
XProjector supports built-in mathematical calculations in both time and frequency domain. XProjector automatically configures sorting trees.
The extremely fast and efficient calculations are key to perform real-time control, generate real-time insights and to support drill-downs.

<h3>Dynamic data models</h3>
In XProjector, the data models are modelled with dynamic models where both nodes and edges are customized.<br>
To support high velocity and advanced computations, the data is loosely connected with large computational data sets.

<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Dynamic Model: Nodes </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Configure data model via UI or via code.</p>
          <img class="screenshoot" src="/webimages/screens/xconf/1.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>Dynamic Model: Edges </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Configure data model via UI or via code.</p>
          <img class="screenshoot" src="/webimages/screens/xconf/2.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
</clr-accordion>

<h3>Load&amp;Transform vs Transform&amp;Load</h3>
XProjector supports both Load&amp;Transform and Transform&amp;Load patterns enabling for loading data from multiple data sources into one dataset.

<h3>Project data</h3>
No matter how the data is loaded, it can still be processed in several opt-in data modifier paths in the data pipeline.
The data may also be generated, or calculated from one or several other projections or even remote agents.
<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>ETL: Target data set / projection </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Configure the data set basic data types and names, units. Optionally tag or link data to external systems.</p>
          <img class="screenshoot" src="/webimages/screens/dataedit/1.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>ETL: ELT with calculations </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Re-Shape the data with internal scripting opt-in paths written in high performant LUA api.</p>
          <img class="screenshoot" src="/webimages/screens/dataedit/2.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>ETL: Scripting debugger </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Built-in debugger enables for rapid development directly in the browser. It is attachble to both PLC programs and LUA Projections.</p>
          <img class="screenshoot" src="/webimages/screens/debugger/1.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>ETL: Scripting debugger </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Built-in debugger enables for rapid development directly in the browser. It supports stepping, breaking and watches.</p>
          <img class="screenshoot" src="/webimages/screens/debugger/2.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
</clr-accordion>

      
<h3>Reporting</h3>
XProjector ships with a WYSIWYG (&quot;what you see is what you get&quot;) dashboard builder. <br>
In the builder the users can create reports, share the reports and automatically generate reports for different user roles.<br>
<br>
The builder supports both 2D and 3D visualization of data, drill downs, flow-charts, animations, etc.<br>
The reports built with the dashboard builder are interactive and support live-data automatically.

<img class="screenshoot" src="/webimages/screens/dashboardeditor/1.png"/>

<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Dashboard builder: Widgets </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The dashboard builder supports multiple widgets.</p>
          <img class="screenshoot" src="/webimages/screens/dashboardeditor/7.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title>Dashboard builder: configure query</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <p>The dashboard builder supports configuring queries individually per widget and series.
        Below is an example for a single series in a chart widget.</p>
        <img class="screenshoot" src="/webimages/screens/dashboardeditor/2.png"/>
      </clr-accordion-content>
    </clr-accordion-panel>
    <clr-accordion-panel>
        <clr-accordion-title>Dashboard builder: Link widgets </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The dashboard builder supports linking widgets for drill downs or high-lighting.</p>
          <img class="screenshoot" src="/webimages/screens/dashboardeditor/3.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>Dashboard builder: Parameters </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The dashboard builder supports linking widgets and the dynamic data model via parameters.</p>
          <img class="screenshoot" src="/webimages/screens/dashboardeditor/4.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>Dashboard builder: Pre queries</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The dashboard builder supports iteratively spanning input parameters via pre-queries.</p>
          <img class="screenshoot" src="/webimages/screens/dashboardeditor/5.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>Dashboard builder: Graph options </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The dashboard builder supports manipulating layout properties depending on widget. Below is an example with chart widget.</p>
          <img class="screenshoot" src="/webimages/screens/dashboardeditor/6.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>

  </clr-accordion>

<h3>Big Data - The four V&apos;s</h3>
Big data is often described in the terms of the four V&apos;s. They cover scalability and quality.

<clr-accordion>
    <clr-accordion-panel>
      <clr-accordion-title>Read more about XProjector &amp; The four V&apos;s
      </clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>

        <h4>Variety</h4>
        Variety of data sources, in XProjector this include;
        <ul>
            <li>Load data from existing databases.</li>
            <li>Write data from bots.</li>
            <li>Import data from files.</li>
            <li>Perform both ETL and ELT in advanced data pipelines.</li>
        </ul> 
        
        <h4>Velocity</h4>
        Velocity of data, in XProjector this has always been the core building block. <br>
        XProjector is designed to respond to real-time velocity and accomplishes this by combining internal technology with commonly used queue systems.
        
        <h4>Volume</h4>
        In order to accomplish real-time velocity the scale by volume is inverted by most traditional Big Data solutions. <br>
        In practice this mean that large data sets (Enterprise size) may be contained in a single configuration and larger data sets are
        flushed to distributed file systems that may be loaded for calculations on-demand.
        
        <h4>Veracity</h4>
        Veracity, or data quality, has to be adopted to each implementation and deployment. To support and gurantee high data quality XProjector include
        the best tools to process data in data pipelining, in combination with dynamic data models the data may be qualified with natural properties.
        

      </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>


</div>
