<div class="date-input" *ngIf="timeConfig.Type != OutputTimeType.Period">
    <clr-input-container>
        <label>{{this.timeConfig.FromConfig.Text}}</label>
        <input type="date" clrInput name="from" [(ngModel)]="selectedFromString" (change)="onTimeApply()">
    </clr-input-container>
</div>
<div class="date-input" *ngIf="timeConfig.Type == OutputTimeType.Range">
    <clr-input-container>
        <label>{{this.timeConfig.ToConfig.Text}}</label>
        <input type="date" clrInput name="to" [(ngModel)]="selectedToString" (change)="onTimeApply()">
    </clr-input-container>
</div>

<div class="date-input" *ngIf="timeConfig.Type == OutputTimeType.Period">
    <clr-select-container>
        <label>{{this.timeConfig.FromConfig.Text}}</label>
        <select clrSelect name="periodselect" [(ngModel)]="selectedPeriod" (ngModelChange)="onTimeApply()">
      <option *ngFor="let period of periods; let i = index" [ngValue]="i">
        {{formatPeriodString(period.start, period.end)}}</option>
    </select>
    </clr-select-container>
</div>