import { Component } from '@angular/core';

@Component({
  selector: 'xproj-editintegrationservers-external',
  templateUrl: './editintegrationservers-external.component.html',
  styleUrls: ['./editintegrationservers-external.component.scss']
})
export class EditintegrationserversExternalComponent {

}
