import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-editxautolinks',
  templateUrl: './editxautolinks.component.html',
  styleUrls: ['./editxautolinks.component.scss']
})
export class EditxautolinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
