import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { XProjectorClient, SetDataQuery, SetDataColumnDescription, XDataType, BaseQueryInputColumnDescription, Aggregation, Transformation, BaseQuery, ColumnFilteringNumerical, FilterComparator } from 'xproj-lib';
import { XbotExecutionQueueItem, XbotExecutionQueueItemResult, XbotExecutionResult, XbotExecutionStatus } from '../models/xbot-execution-queue-item';

import createFlakeID53 from "flakeid53";

const flakeId = createFlakeID53({
  epoch: +new Date(),
  workerId: 3,
});

@Injectable({
  providedIn: 'root'
})
export class XbotExecutionService {

  private setColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'userid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'botid',
      datatype: XDataType.String,
      indexintypedvector: 1
    },
    {
      columnname: 'timecreate',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'status',
      datatype: XDataType.UInt8,
      indexintypedvector: 1
    },
    {
      columnname: 'data',
      datatype: XDataType.String,
      indexintypedvector: 2
    },
    {
      columnname: 'priority',
      datatype: XDataType.UInt8,
      indexintypedvector: 2
    },
    {
      columnname: 'timestop',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'message',
      datatype: XDataType.String,
      indexintypedvector: 3
    },
    {
      columnname: 'timeupdate',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'caller',
      datatype: XDataType.String,
      indexintypedvector: 4
    },
    {
      columnname: 'result',
      datatype: XDataType.UInt8,
      indexintypedvector: 3
    },
    {
      columnname: 'timestart',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    }
  ];

  private getColumns: BaseQueryInputColumnDescription[] = [
    {
      columnname: 'id',
      columnoutname: 'id',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    // {
    //   columnname: 'userid',
    //   columnoutname: 'userid',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    // {
    //   columnname: 'botid',
    //   columnoutname: 'botid',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    // {
    //   columnname: 'timecreate',
    //   columnoutname: 'timecreate',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    {
      columnname: 'status',
      columnoutname: 'status',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    // {
    //   columnname: 'data',
    //   columnoutname: 'data',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    // {
    //   columnname: 'priority',
    //   columnoutname: 'priority',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    {
      columnname: 'timestop',
      columnoutname: 'timestop',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    {
      columnname: 'message',
      columnoutname: 'message',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    {
      columnname: 'timeupdate',
      columnoutname: 'timeupdate',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    // {
    //   columnname: 'caller',
    //   columnoutname: 'caller',
    //   columnaggregation: Aggregation.NONE,
    //   columntransformation: Transformation.NONE
    // },
    {
      columnname: 'result',
      columnoutname: 'result',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    },
    {
      columnname: 'timestart',
      columnoutname: 'timestart',
      columnaggregation: Aggregation.NONE,
      columntransformation: Transformation.NONE
    }
  ];

  public constructor(
    private xprojClient: XProjectorClient) {

  }

  executeBot(botId: string, data: string, timeoutSeconds: number, priority: number, caller: string, userId : string): Observable<{ result: XbotExecutionResult, message: string }> {

    return new Observable(observer => {
      flakeId.nextId().then(id => {
        let queueItem = new XbotExecutionQueueItem();
        queueItem.id = 0; //TODO
        queueItem.botId = botId;
        queueItem.userId = 'TODO';
        queueItem.timeCreate = new Date();
        queueItem.data = data;
        queueItem.priority = priority;
        queueItem.caller = caller;

        let query: SetDataQuery = new SetDataQuery();

        query.datastrings = [
          [userId],
          [botId],
          [data],
          [''],
          [caller]
        ];
        query.datanumbers = [[
          id],
          [0],
          [100],  //prio
          [0
        ]
        ];
        query.datatimestamps = [[
          new Date()],
          [new Date()],
          [new Date()],
          [new Date()
        ]
        ];

        query.projectionid = 'XBOT_EXECUTION_QUEUE';
        query.columns = this.setColumns;

        this.xprojClient.RequestSetData(query)
          .then(setResult => {
            if (!setResult) {
              observer.error({ result: XbotExecutionResult.ERROR, message: 'Error start bot.' });
            }
            else {
              let timerSource = timer(1000, 1000);
              let i = 0;
              let lastTimeUpdate : Date = new Date();
              let subscription = timerSource.subscribe(t => {
                i++;
                this.getQueueItem(id)
                  .then(result => {
                    if (result.status == XbotExecutionStatus.STATUS) {
                      if (lastTimeUpdate < result.timeUpdate) {
                        lastTimeUpdate = result.timeUpdate;
                        observer.next({ result: XbotExecutionResult.STATUS, message: result.message });
                      }

                    }
                    else if (result.status == XbotExecutionStatus.DONE || result.status == XbotExecutionStatus.CANCEL) {
                      subscription.unsubscribe();
                      observer.next({ result: result.result, message: result.message });
                      observer.complete();
                    }
                  })
                  .catch((err) => {
                    subscription.unsubscribe();
                    observer.error({ result: XbotExecutionResult.ERROR, message: err });
                    observer.complete();
                  });
                if (i > timeoutSeconds) {
                  subscription.unsubscribe();
                  observer.error({ result: XbotExecutionResult.ERROR, message: 'Timeout!' });
                  observer.complete();
                }
              });
            }
          }).catch((err) => {
            observer.error({ result: XbotExecutionResult.ERROR, message: err });
            observer.complete();
          });
      });
    });
  }

  private async getQueueItem(id: number): Promise<XbotExecutionQueueItemResult> {
    let query = new BaseQuery();
    query.targetprojectionid = 'XBOT_EXECUTION_QUEUE';
    query.columns = this.getColumns;

    let idFilter = new ColumnFilteringNumerical();
    idFilter.columnname = 'id';
    idFilter.comparator = FilterComparator.Equals;
    idFilter.queryfilterid = 1;
    idFilter.value = id;
    query.filter.filters.push(idFilter.queryfilterid);
    query.numericalfilters.push(idFilter);

    query.maxitems = 1;
    let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

    let result = new XbotExecutionQueueItemResult();

    let numericaldata = queryResult.datanumbers;
    let timestampdata = queryResult.datatimestamps;
    let stringdata = queryResult.datastrings;

    for (let i = 0; i < queryResult.columns.length; i++) {
      let it = queryResult.columns[i];
      let typ = it.datatype;
      let data = [];
      if (typ == XDataType.Number) {
        data = numericaldata[it.indexintypedvector];
      }
      else if (typ == XDataType.String) {
        data = stringdata[it.indexintypedvector];
      }
      else if (typ == XDataType.Timestamp) {
        data = timestampdata[it.indexintypedvector];
      }

      switch (it.columnoutname) {
        case 'id':
          result.id = data[0];
          break;
        // case 'userid':
        //   result.userId = data[0];
        //   break;
        // case 'botid':
        //   result.botId = data[0];
        //   break;
        // case 'timecreate':
        //   result.timeCreate = data[0];
        //   break;
        case 'status':
          result.status = data[0];
          break;
        // case 'data':
        //   result.data = data[0];
        //   break;
        // case 'priority':
        //   result.priority = data[0];
        //   break;
        case 'timestop':
          result.timeStop = data[0];
          break;
        case 'message':
          result.message = data[0];
          break;
        case 'timeupdate':
          result.timeUpdate = data[0];
          break;
        // case 'caller':
        //   result.caller = data[0];
        //   break;
        case 'result':
          result.result = data[0];
          break;
        case 'timestart':
          result.timeStart = data[0];
          break;
      }
    }

    return result;
  }

}
