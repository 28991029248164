import { Component, OnInit } from '@angular/core';
import { StringifyOptions } from 'querystring';
import { Customer, CustomerUser } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  newCustomer: Customer = new Customer();
  newCustomerAdmin: CustomerUser = new CustomerUser();

  constructor(
    private sysAdminClient: XProjectorSysAdminClient,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
  }

  async addCustomer() {
    try {
      if (this.newCustomerAdmin.email?.length > 0) {

        let customer = await this.sysAdminClient.addNewCustomer(this.newCustomer);
        if (customer) {
          if (this.newCustomerAdmin?.email.length > 0 && this.newCustomerAdmin?.email.indexOf('@') > 0) {
            this.newCustomerAdmin.id = this.newCustomerAdmin.email;
            this.newCustomerAdmin.customerId = customer.id;
            this.newCustomerAdmin.isAdmin = true;
            let customerAdmin = await this.sysAdminClient.addUserToCustomer(this.newCustomerAdmin);
          }

          this.alertService.success('New customer added.');
          this.clear();
        }
        else {
          this.alertService.error('Error add new customer!');
        }
      }
      else {
        this.alertService.error('Please fill in customer admin email', { appLevel: false });
      }
    }
    catch (err) {
      this.alertService.error('Error add new customer: ' + err);
    }

  }

  clear() {
    this.newCustomer = new Customer();
    this.newCustomerAdmin = new CustomerUser();
  }
}
