<div>

    <p style="font-size: larger; padding-top: 2em; padding-bottom: 2em;">
        Our systems meet tomorrow's needs on big data, automation and communications. <br>
        Click on respective categories to find out more.<br>        
        You can find our software-hardware bundled offerings under <a routerLink="/products">Products</a>.
    </p>

    <div class="clr-row">
        <div class="clr-col-lg-4 clr-col-12">
            
            <div class="card">
                <div class="card-block">
                    <h3 class="card-title">Big Data</h3>
                    <div class="card-text">
                        Explore our domain agnostic Big Data systems
                        <ul>
                            <li>Flexible deployment</li>
                            <li>Fast calculations</li>
                            <li>Dynamic data models</li>
                            <li>Reporting</li>
                            <li>Data visualization</li>
                            <li>Low-cost entry point</li>
                            <li>Scalable</li>
                            <li>Multi-tentant &amp; Multi-role</li>
                            <li>WYSIWYG dashboard editor</li>
                            <li>Business Intelligence for all verticals</li>
                            <li>Legal compliant</li>
                            <li>Extensible</li>
                            <li>Granular ACL/Rights</li>
                            <li>Microsoft Office 365 Integration</li>
                            <li>Google Apps Integration</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <a [routerLink]="[{ outlets: {systemsoutlet: ['big-data']} }]" routerLinkActive="active" class="btn btn-sm btn-link"> Read More </a>
                </div>
            </div>                
        </div>

        <div class="clr-col-lg-4 clr-col-12">
            <div class="card">
                <div class="card-block">
                    <h3 class="card-title">Automation</h3>
                    <div class="card-text">
                        Explore our Automation systems which can be deployed independantly or in combination with our Big Data systems
                        <ul>
                            <li>Industrial IoT</li>
                            <li>Big Data/Smart PLC</li>
                            <li>PLC</li>
                            <li>SCADA</li>
                            <li>De-Centralized Automation/Control</li>
                            <li>Centralized Automation/Control</li>                            
                            <li>Smart metering</li>
                            <li>Field-bus communications</li>
                            <li>Big Data support</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <a [routerLink]="[{ outlets: {systemsoutlet: ['automation']} }]" routerLinkActive="active" class="btn btn-sm btn-link"> Read More </a>
                </div>
            </div> 
        </div>

        <div class="clr-col-lg-4 clr-col-12">

            <div class="card">
                <div class="card-block">
                    <h3 class="card-title">Communications</h3>
                    <div class="card-text">
                        Explore our Communications systems that integrate open and proprietary communication methods for industrial scale
                        <ul>
                            <li>LoRaWAN</li>
                            <li>3GPP</li>
                            <li>Industrial communications</li>
                            <li>Integration with Automation systems</li>
                            <li>Provisioning</li>
                            <li>Multi-tenant</li>
                            <li>Billing</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <a [routerLink]="[{ outlets: {systemsoutlet: ['communications']} }]" routerLinkActive="active" class="btn btn-sm btn-link"> Read More </a>
                </div>
            </div> 

        </div>            
    </div>               
