import { Injectable, InjectionToken } from '@angular/core';

export const XPROJECTORCLIENTSETTINGS = new InjectionToken<XProjectClientSettings>('XPROJECTORCLIENTSETTINGS');

export interface XProjectClientSettings {
  host : string;
  cacheTTLSeconds? : number;
  cacheEnabled? : boolean;
  keepAlive? : boolean;
  keepAliveInterval? : number;
  defaultToken? : string;
}
