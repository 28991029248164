import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { Projection, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { WidgetConfig } from '../../widget-config-service';
import { MasterTimeSettings, XprojWidgetService } from '../../xproj-widget-service';
import { XprojMasterWidgetTimeComponent } from '../master-widget-time/xproj-master-widget-time.component';
import { MasterWidgetConfig } from './master-widget-config-service';

@Component({
  selector: 'xproj-master-widget-config',
  templateUrl: './xproj-master-widget-config.component.html',
  styleUrls: ['./xproj-master-widget-config.component.scss']
})
export class XprojMasterWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("masterTime", { read: XprojMasterWidgetTimeComponent, static:false }) masterTime: XprojMasterWidgetTimeComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static:false }) groupSelect: XprojGroupSelectionComponent;

	widgetConfig : MasterWidgetConfig;

  projections : Projection[] = [];
  projection : Projection;
  projectionGroups : Array<string[]> = [];

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0,10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.widgetConfig.ProjectionId);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as MasterWidgetConfig;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async onSaveConfig() {
	this.widgetConfig.Time = this.masterTime.getSettings();
	this.widgetConfig.SelectedGroup = this.groupSelect.getSelectedGroup();
    this.widgetConfig.ProjectionId = this.projection?.projectionid;
    //this.onConfigChanged?.emit(this.config);
  }

  onTimeApply(timesettings : MasterTimeSettings) {
    this.widgetConfig.Time = timesettings;
  }

  async selectedProjectionChange(projection : Projection)
	{
    this.projection = projection;
  }
}
