import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { XprojModule } from 'xproj-lib';
import { ClarityModule } from '@clr/angular';
import { AngularSplitModule } from 'angular-split';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    XprojModule,
    ClarityModule,
    AngularSplitModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    XprojModule,
    AngularSplitModule,

    DashboardComponent
  ]
})
export class CoreSharedModule { }
