export class RossakerBmsCustomer {
  id : number;
  customerId : string;
  organisationNumber : string;
  customerName : string;
  associationId : string;
  customerIsTrustee : boolean;
  extrapolateEnabled : boolean;
  extrapolateCoefficient : number;
  tariffDecimalCount : number;
  reportDataPointDecimalCount : number;
  disabled : boolean;
  billingEnabled : boolean;
}
