<div>
    <form clrForm>
        <clr-select-container>
            <label>Projection</label>
            <select clrSelect id="useprojectioninput" name="useprojectioninput" [(ngModel)]="queryData.useProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

            <select clrSelect style="margin-left: 1em;" *ngIf="!queryData.useProjectionInputParameter" name="projection" [(ngModel)]="queryData.projection" (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

            <select clrSelect style="margin-left: 1em;" *ngIf="queryData.useProjectionInputParameter" class="clr-select" name="UseProjectionInputParameter" [(ngModel)]="queryData.projectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
        </clr-select-container>

        <clr-select-container>
            <label>Input / Group</label>
            <select clrSelect id="usegroupinput" name="usegroupinput" [(ngModel)]="queryData.groupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
      </select>

            <select clrSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select" name="UseProjectionInputParameter" [(ngModel)]="queryData.groupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
        </clr-select-container>

        <xproj-group-selection #groupSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP" [projection]="queryData.projection" [selected]="widgetQuery.Query?.targetgroup" (groupSelected)="selectedProjectionGroupChange($event)">
        </xproj-group-selection>

        <clr-combobox-container *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
            <label>Group input selections</label>
            <clr-combobox [(ngModel)]="queryData.groupInputParameterIds" name="multiSelect" clrMulti="true" required>
                <ng-container *clrOptionSelected="let selected">
                    {{selected}}
                </ng-container>
                <clr-options>
                    <clr-option *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'" [clrValue]="input.id">
                        {{input.id}}
                    </clr-option>
                </clr-options>
            </clr-combobox>
        </clr-combobox-container>
    </form>

    <h5>Data</h5>
    <form clrForm>
        <clr-select-container>
            <label>X Axis</label>
            <select clrSelect name="xaxis" [(ngModel)]="queryData.xaxis">
        <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : true " [(ngValue)]="col.columnname">
          {{col.columnname}}</option>
      </select>

            <select style="margin-left: 1em;" clrSelect name="usegrouping" [(ngModel)]="queryData.usegrouping">
        <option [ngValue]="true"> Group </option>
        <option [ngValue]="false">No grouping</option>
      </select>

            <select *ngIf="queryData.usegrouping" style="margin-left: 1em;" clrSelect id="usetransforminput" name="usetransforminput" [(ngModel)]="queryData.useTransformInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Aggregation </option>
      </select>


            <select style="margin-left: 1em;" *ngIf="queryData.usegrouping && !queryData.useTransformInputParameter" clrSelect name="xaxisgrouptransform" id="xaxisgrouptransform" [(ngModel)]="queryData.xaxistransform">
        <option [ngValue]="Transformation.NONE"> None </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
        <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
      </select>

            <select clrSelect style="margin-left: 1em;" *ngIf="queryData.usegrouping && queryData.useTransformInputParameter" class="clr-select" name="UseTransformInputParameter" [(ngModel)]="queryData.transformInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
        </clr-select-container>

        Selected in model: {{queryData.xaxis}} <br>

        <clr-datagrid [(clrDgSingleSelected)]="selectedYAxis" (clrDgSingleSelectedChange)="selectedYAxisChanged($event)">
            <clr-dg-column>Column</clr-dg-column>
            <!-- *ngIf="queryData.usegrouping" -->
            <clr-dg-column>Aggregation</clr-dg-column>
            <clr-dg-column>Label</clr-dg-column>
            <clr-dg-column>Unit</clr-dg-column>
            <clr-dg-column>Axis</clr-dg-column>
            <clr-dg-column>Type</clr-dg-column>
            <clr-dg-column>Move</clr-dg-column>

            <clr-dg-row *ngFor="let column of queryData.yaxises; let i = index" [clrDgItem]="column">
                <clr-dg-cell>
                    <div class="clr-select-wrapper">
                        <select class="clr-select" name="queryData.xaxis{{i}}" id="yaxis_{{i}}" [(ngModel)]="queryData.yaxises[i]" (ngModelChange)="onYAxisSelect($event, i)">
              <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : false"
                [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of queryData.preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
            </select>
                    </div>
                </clr-dg-cell>
                <clr-dg-cell>
                    <div class="clr-select-wrapper">
                        <input class="clr-checkbox" type="checkbox" placeholder="" name="AggregationInput{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].UseAggregationInputParameter" [disabled]="!queryData.usegrouping" />
                        <select *ngIf="!queryData.yAxesConfigs[i].UseAggregationInputParameter" class="clr-select" style="width:13em;" name="queryData.yaxisagg{{i}}" [disabled]="!queryData.usegrouping" [(ngModel)]="queryData.yaxisestransforms[i]">
              <option [ngValue]="Aggregation.NONE"> None </option>
              <option [ngValue]="Aggregation.COUNT"> Count </option>
              <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

              <option [ngValue]="Aggregation.SUM"> Sum </option>

              <option [ngValue]="Aggregation.MAX"> Max </option>
              <option [ngValue]="Aggregation.MIN"> Min </option>

              <option [ngValue]="Aggregation.FIRST"> First </option>
              <option [ngValue]="Aggregation.LAST"> Last </option>

              <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

              <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
              <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
              <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

              <option [ngValue]="Aggregation.MEDIAN"> Median </option>

              <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
              </option>

              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                arithmetic mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
            </select>
                        <select *ngIf="queryData.yAxesConfigs[i].UseAggregationInputParameter" class="clr-select" style="width:13em;" name="inputparam" [(ngModel)]="queryData.yAxesConfigs[i].AggregationInputParameterId" [disabled]="!queryData.usegrouping">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
                    </div>
                </clr-dg-cell>

                <clr-dg-cell>
                    <input class="clr-input" placeholder="" name="tlabel{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].Label" />
                </clr-dg-cell>

                <clr-dg-cell>
                    <input class="clr-input" placeholder="" name="unit{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].Unit" />
                </clr-dg-cell>

                <clr-dg-cell>
                    <div class="clr-select-wrapper">
                        <select class="clr-select" style="width:5em;" name="yaxisid{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].AxisId">
              <option [ngValue]="YAxisId.LEFT"> Left </option>
              <option [ngValue]="YAxisId.RIGHT"> Right </option>
            </select>
                    </div>
                </clr-dg-cell>

                <clr-dg-cell>
                    <div class="clr-select-wrapper">
                        <select class="clr-select" style="width:5em;" name="charttype" [(ngModel)]="queryData.yAxesConfigs[i].Type">
              <option [ngValue]="'line'"> Line </option>
              <option [ngValue]="'bar'"> Bar </option>
            </select>
                    </div>
                </clr-dg-cell>

                <clr-dg-cell>
                    <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow"></clr-icon>
                    <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow" style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
                </clr-dg-cell>
            </clr-dg-row>

        </clr-datagrid>
        <button type="button" class="btn btn-link" (click)="addYaxis()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add y-axis
    </button>
        <button *ngIf="selectedYAxis" type="button" class="btn btn-link" (click)="removeSelectedYAxis()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected y-axis
    </button>

        <div *ngIf="selectedYAxesConfig" style="max-width: 600px;">
            <div class="card">
                <div class="card-header">
                    Editing column
                </div>

                <div class="card-block">
                    <div class="card-title">
                        {{selectedYAxesConfig.ColumnName}} ({{selectedYAxesConfig.Label}})
                    </div>
                    <form clrForm>
                        <clr-input-container>
                            <label>Label Real </label>
                            <input clrInput type="text" [(ngModel)]="selectedYAxesConfig.LabelReal" name="labelreal" size="25" />
                        </clr-input-container>

                        <clr-select-container>
                            <label>Axis Real</label>
                            <select clrSelect name="yaxisidreal" [(ngModel)]="selectedYAxesConfig.AxisIdReal">
                <option [ngValue]="YAxisId.LEFT"> Left </option>
                <option [ngValue]="YAxisId.RIGHT"> Right </option>
              </select>
                        </clr-select-container>

                        <clr-input-container>
                            <label>Label Power </label>
                            <input clrInput type="text" [(ngModel)]="selectedYAxesConfig.LabelPower" name="labelpower" size="25" />
                        </clr-input-container>

                        <clr-select-container>
                            <label>Axis Power</label>
                            <select clrSelect name="yaxisidpower" [(ngModel)]="selectedYAxesConfig.AxisIdPower">
                <option [ngValue]="YAxisId.LEFT"> Left </option>
                <option [ngValue]="YAxisId.RIGHT"> Right </option>
              </select>
                        </clr-select-container>
                    </form>
                </div>
            </div>
        </div>
    </form>

    <h5>Filters</h5>
    <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="queryData.projection?.projectionid" [columnDescriptions]="queryData.columns" [group]="queryData.selectedGroup" [datafilters]="widgetQuery.DataFilters" [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType"
        [inputParameters]="inputParameters" [widgets]="widgets">
    </xproj-projection-filter>

    <br />

    <clr-accordion>
        <clr-accordion-panel *ngFor="let scriptedcolumn of queryData.scriptedcolumnspostaggregation; index as i">
            <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
            <clr-accordion-content>
                <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn" (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
    <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button>

    <br />
    <button class="btn btn-danger-outline" (click)="removeQuery()">Remove query</button>
</div>
