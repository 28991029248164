import { SysAdminClient } from './proto/xprojector.sysadmin.pbsc'
import { HelloClient } from './proto/hello.pbsc'
import { Injectable } from '@angular/core';

import { BasicResponse, Customer, CustomerUser, User, UserDevice } from './proto/xprojector.grpc.models.pb';
import { StateService } from '@xprojectorcore/services/state-service';
import { Empty } from '@ngx-grpc/well-known-types';
import { AddUserToCustomerWithPasswordRequest, DeleteCustomerRequest, DeleteUserFromCustomerRequest, DeleteUserRequest, GetCustomersContentRequest, GetCustomersContentResponse, GetNodeLoggingRequest, GetNodeLoggingResponse, ResetPasswordRequest, SetNodeLoggingRequest, SetSysAdminPasswordRequest, SysAdminGetCustomerUsersRequest, UpdateGeoLocationsRequest } from './proto/xprojector.sysadmin.pb';
import { NGXLogger } from 'ngx-logger';
import { retryWhen } from 'rxjs/operators';
import { CacheService } from 'xproj-lib';

const SYSADMIN_CUSTOMERSCACHEKEY: string = 'sysadmin-customers';

@Injectable({
  providedIn: 'root'
})
export class XProjectorSysAdminClient {

  constructor ( private sysAdminClient : SysAdminClient,
    private state : StateService,
    private readonly cache: CacheService,
    private logger: NGXLogger) {
  }

  async addNewCustomer(request : Customer) : Promise<Customer> {
    this.cache.remove(SYSADMIN_CUSTOMERSCACHEKEY);
    return this.sysAdminClient.addNewCustomer(request, this.state.metadata).toPromise();
  }

  async addUserToCustomer(customerUser : CustomerUser, password : string = '') : Promise<CustomerUser> {
    if (password?.length > 0) {
      let request = new AddUserToCustomerWithPasswordRequest();
      request.customerUser = customerUser;
      request.password = password;

      return this.sysAdminClient.addUserToCustomerWithPassword(request, this.state.metadata).toPromise();
    }
    else {
      return this.sysAdminClient.addUserToCustomer(customerUser, this.state.metadata).toPromise();
    }
  }

  async getCustomerUsers(customerId? : string, admins? : boolean,
      firstnameFilter? : string, surnameFilter? : string, emailFilter? : string) : Promise<CustomerUser[]> {
    let request = new SysAdminGetCustomerUsersRequest( {
      customerId : customerId,
      admins : admins,
      firstnameFilter : firstnameFilter,
      surnameFilter : surnameFilter,
      emailFilter : emailFilter
    });

    let result =  await this.sysAdminClient.getCustomerUsers(request, this.state.metadata).toPromise();
    return result.customerUsers;
  }

  async deleteCustomerUser(customerId : string, userId : string) : Promise<BasicResponse> {
    let request = new DeleteUserFromCustomerRequest( {
      customerId : customerId,
      userId : userId
    });

    return await this.sysAdminClient.deleteUserFromCustomer(request, this.state.metadata).toPromise();
  }

  async getCustomers(forceReload : boolean = true) : Promise<Customer[]> {
    try
    {
      if (!forceReload && this.cache.has(SYSADMIN_CUSTOMERSCACHEKEY)) {
        return this.cache.get(SYSADMIN_CUSTOMERSCACHEKEY);
      }
      else {
        let result = await this.sysAdminClient.getCustomers(new Empty, this.state.metadata).toPromise();
        this.cache.set(SYSADMIN_CUSTOMERSCACHEKEY, result.customers);
        return result.customers;
      }
    }
    catch (ex){
      this.logger.error(ex);
    }
  }

  async modifyCustomer(request : Customer) : Promise<BasicResponse> {
    this.cache.remove(SYSADMIN_CUSTOMERSCACHEKEY);
    return this.sysAdminClient.modifyCustomer(request, this.state.metadata).toPromise();
  }


  async deleteCustomer(customerId : string) : Promise<BasicResponse> {
    this.cache.remove(SYSADMIN_CUSTOMERSCACHEKEY);
    let request = new DeleteCustomerRequest({ customerId : customerId });
    return this.sysAdminClient.deleteCustomer(request, this.state.metadata).toPromise();
  }

  async getUsers() : Promise<User[]> {
    try
    {
      let result = await this.sysAdminClient.getUsers(new Empty, this.state.metadata).toPromise();
      return result.users;
    }
    catch (ex){
      this.logger.error(ex);
    }
  }

  async deleteUser(userId : string) : Promise<BasicResponse> {
    let request = new DeleteUserRequest({ userId : userId });
    return this.sysAdminClient.deleteUser(request, this.state.metadata).toPromise();
  }

  async addNewUser(request : User) : Promise<User> {
    return this.sysAdminClient.addNewUser(request, this.state.metadata).toPromise();
  }

  async getActiveUser() : Promise<User> {
    return this.sysAdminClient.getActiveUser(new Empty(), this.state.metadata).toPromise();
  }

  async modifyUser(request : User) : Promise<BasicResponse> {
    return this.sysAdminClient.modifyUser(request, this.state.metadata).toPromise();
  }

  async resetPassword(user : CustomerUser) : Promise<BasicResponse> {
    let request : ResetPasswordRequest = new ResetPasswordRequest();
    request.customerId = user.customerId;
    request.userId = user.id;

    return this.sysAdminClient.resetPassword(request, this.state.metadata).toPromise();
  }

  async modifyCustomerUser(request : CustomerUser) : Promise<BasicResponse> {
    return this.sysAdminClient.modifyCustomerUser(request, this.state.metadata).toPromise();
  }

  async setPassword(oldPassword : string, newPassword : string) : Promise<BasicResponse> {
    let request : SetSysAdminPasswordRequest = new SetSysAdminPasswordRequest();
    request.oldPassword = oldPassword;
    request.newPassword = newPassword;

    return this.sysAdminClient.setPassword(request, this.state.metadata).toPromise();
  }

  async generateApiKey() : Promise<string> {
    let result = await this.sysAdminClient.generateApiKey(new Empty(), this.state.metadata).toPromise();
    return result.apiKey;
  }

  async getCustomersContent(customerId : string) : Promise<GetCustomersContentResponse> {
    let request : GetCustomersContentRequest = new GetCustomersContentRequest();
    request.customerId = customerId;
    let result = await this.sysAdminClient.getCustomersContent(request, this.state.metadata).toPromise();
    return result;
  }

  async updateGeoLocations(customerId : string, overwrite : boolean = false) : Promise<BasicResponse> {
    let request : UpdateGeoLocationsRequest = new UpdateGeoLocationsRequest();
    request.customerId = customerId;
    request.overwrite = overwrite
    return this.sysAdminClient.updateGeoLocations(request, this.state.metadata).toPromise();
  }


  async getUserDevices() : Promise<UserDevice[]> {
    throw Error("not implemented");
    //let result = await this.sysAdminClient.getActiveCustomerUserDevices(new Empty(), this.state.metadata).toPromise();
    //return result.userDevices;
  }

  async addUserDevice(name : string) : Promise<UserDevice> {
    throw Error("not implemented");
    // let request = new AddCustomerUserDeviceRequest();
    // request.name = name;
    // let result = await this.customerUsersClient.addCustomerUserDevice(request, this.state.metadata).toPromise();
    // return result;
  }

  async deleteUserDevice(id : string) : Promise<BasicResponse> {
    throw Error("not implemented");
    // let request = new DeleteCustomerUserDeviceRequest();
    // request.id = id;
    // return this.customerUsersClient.deleteCustomerUserDevice(request, this.state.metadata).toPromise();
  }

  async getNodeLogging(id : string) : Promise<GetNodeLoggingResponse> {
    let request : GetNodeLoggingRequest = new GetNodeLoggingRequest();
    request.id = id;

    return await this.sysAdminClient.getNodeLogging(request, this.state.metadata).toPromise();
  }

  async setNodeLogging(id : string, enabled : boolean, ttlSeconds : number = 0) : Promise<boolean> {
    let request : SetNodeLoggingRequest = new SetNodeLoggingRequest();
    request.id = id;
    request.enabled = enabled;
    request.ttl = ttlSeconds;

    await this.sysAdminClient.setNodeLogging(request, this.state.metadata).toPromise();

    return true;
  }

}
