import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'xproj-dynclr-mini',
  templateUrl: './xproj-dynclr-mini.component.html',
  styleUrls: ['./xproj-dynclr-mini.component.scss']
})
export class XProjDynclrMiniComponent implements OnInit {

  constructor(/*private ref: ChangeDetectorRef*/) {

   }

  public Data: any;
  isDate : boolean = false;
  isNumber : boolean = false;
  isArray : boolean = false;
  isMap: boolean = false;
  isNull :boolean = false;
  isBoolean : boolean = false;
  isOther : boolean = false;
  public Title = "";

  newTag = "";

  public useLabelsForArrays : boolean = true;
  public removeableLabelsForArrays : boolean = false;
  public addableLabelsForArrays : boolean = true;



  removelabel(label: string)
  {
    for(let i = 0; i < this.Data.length; i++)
    {
      let item = this.Data[i];
      if(item == label)
        this.Data.splice(i,1);
    }
    //this.ref.markForCheck();
    this.dataChange.emit(this.Data);
  }

  @Input()
  set title(title : string)
  {
    this.Title = title;
    //this.ref.markForCheck();
  }

  @Input()
  set uselabels(uselabels : boolean)
  {
    this.useLabelsForArrays = uselabels;
    //this.ref.markForCheck();
  }

  @Input()
  set removeablelabels(removeablelabels : boolean)
  {
    this.removeableLabelsForArrays = true;
    //this.ref.markForCheck();
  }

  @Input()
  set addablelabels(addablelables : boolean)
  {
    this.addableLabelsForArrays = addablelables;
    //this.ref.markForCheck();
  }

  @Input()
  set data(data: Object)
  {

    if(data == null || data === undefined)
    {
      this.Data = null;
      this.isNull = true;
      return;
    };

    if(data instanceof Date)
    {
        this.Data = data;
        this.isDate = true;
        return;
    }

    if(data instanceof Boolean)
    {
        /*let x = data as Boolean;
        if(x)
            return "True";
        return "False";*/
        this.Data = data as Boolean;
        this.isBoolean = true;
        return;
    }

    if( Array.isArray(data) )
    {
      this.Data = data as Array<any>;
      this.isArray = true;
      return;
    }

    if( data instanceof String || typeof data =='string')
    {
      this.Data = data.toString();
      this.isOther = true;
      return;
    }

    if( Object.keys(data).length > 0  )
    {
      this.Data = data as Object;
      this.isMap = true;
      return;
    }

    this.Data = data.toString();
    this.isOther = true;
  }

  @Output() dataChange = new EventEmitter<Object>();


  ngOnInit(): void {
  }

}
