import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Scripted3dWidgetConfig, Scripted3dWidgetQuery } from './xproj-scripted3d-widget-config-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widget-config-service';
import { Subscription } from 'rxjs';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Aggregation, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { XprojScripted3dWidgetQueryConfigComponent } from '../scripted3d-widget-query-config/xproj-scripted3d-widget-query-config.component';
import { WidgetConfigBase } from '../../widget-config-base';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'xproj-scripted3d-widget-config',
  templateUrl: './xproj-scripted3d-widget-config.component.html',
  styleUrls: ['./xproj-scripted3d-widget-config.component.scss']
})
export class XprojScripted3dWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  widgetConfig: Scripted3dWidgetConfig;

  editorOptionsLua = {
    theme: environment.editortheme,
    language: 'lua',
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap: { enabled: true }
  };


  @ViewChildren(XprojScripted3dWidgetQueryConfigComponent, { read: XprojScripted3dWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojScripted3dWidgetQueryConfigComponent>;

  projections: Projection[] = [];

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as Scripted3dWidgetConfig;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.widgetConfig.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
  }

  addConfigQuery(): void {
    let query = new Scripted3dWidgetQuery();
    query.Name = 'Query ' + (this.widgetConfig.ConfigQueries.length + 1);
    this.widgetConfig.ConfigQueries.push(query);
  }

  onQueryRemoved(query: Scripted3dWidgetQuery) {
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
  }

  async onSaveConfig() {

    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    //this.widgetConfig.OutputParameters = [];
  }
}
