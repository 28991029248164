import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrLoading, ClrLoadingState } from '@clr/angular';
import { Subscription } from 'rxjs';
import { EditplcprogramService } from '../editplcprogram.service';

@Component({
  selector: 'xproj-editplcprogram',
  templateUrl: './editplcprogram.component.html',
  styleUrls: ['./editplcprogram.component.scss']
})
export class EditplcprogramComponent implements OnInit {  

  public savingProgram : ClrLoadingState = ClrLoadingState.DEFAULT;
  public deployingProgram : ClrLoadingState = ClrLoadingState.DEFAULT;
  public runningProgram : ClrLoadingState = ClrLoadingState.DEFAULT;
  
  public showImportDialog = false;
  public showExportDialog = false;
  public programId = "";

 
  public subscription : Subscription;
  constructor(private route: ActivatedRoute, public Project : EditplcprogramService) { }

  async Save()
  {
    this.savingProgram = ClrLoadingState.LOADING;
    try
    {
      this.Project.SaveProgram();
      this.savingProgram = ClrLoadingState.SUCCESS;
    }
    catch
    {    
      this.savingProgram = ClrLoadingState.ERROR;
    }
  }

  async Deploy()
  {  
    this.deployingProgram = ClrLoadingState.LOADING;
    try
    {
      this.Project.DeployProgram();
      this.deployingProgram = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.deployingProgram = ClrLoadingState.ERROR;
    }
  }

  async Run()
  {
    this.runningProgram = ClrLoadingState.LOADING;
    try
    {
      let rval = await this.Project.TestRunProgram();
      this.runningProgram = ClrLoadingState.SUCCESS;
      console.log(rval);
    }
    catch(err)
    {
      console.log(err);
      this.runningProgram = ClrLoadingState.ERROR;
    }

  }

  ngOnInit() : void {
    
    this.subscription = this.route.params.subscribe(params => {
      this.programId = params['id'];
      console.log("Loading program", this.programId);
      this.Project.LoadProgram(this.programId);
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
