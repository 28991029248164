import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { DateHelper, XprojAlertService } from 'xproj-lib';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { ClrDatagridStateInterface } from '@clr/angular';
import { saveAs } from 'file-saver';
import { stat } from 'fs';
import { ca } from 'date-fns/locale';

@Component({
  selector: 'app-file-library',
  templateUrl: './file-library.component.html',
  styleUrls: ['./file-library.component.scss']
})
export class FileLibraryComponent implements OnInit {

  fileInfo: FileInfo;
  file: any;

  fileInfos: FileInfo[] = [];
  selected: FileInfo;

  newtag: string = '';
  loadingFiles: boolean = false;
  sizeOptions = [10, 20, 50, 100];
  tagsFilter: string[] = [];
  fileCount: number = 0;

  constructor(
    public state: StateService,
    private filesClient: XProjectorFilesClient,
    private dateHelper : DateHelper,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
    this.updateFileInfos(this.tagsFilter, this.sizeOptions[0], 0);
  }

  async upsertFile() {
    try {
      let result = await this.state.upsertFile(this.fileInfo.id, this.fileInfo.name, this.fileInfo.fileFormat, this.fileInfo.tags, false, this.file);
      if (result) {
        this.fileInfos.push(result);
        this.fileInfo = null;
      }
    }
    catch (err) {
      this.alertService.error('Error upsert file:', err);
    }

  }

  async updateFile() {
    try {
      let result = await this.state.upsertFile(this.selected.id, this.selected.name, this.selected.fileFormat, this.selected.tags, false, this.file);
      if (result) {
        this.selected.size = result.size;
        this.selected.lastModified = new Date(result.lastModified);
      }
    }
    catch (err) {
      this.alertService.error('Error update file.', err);
    }

  }

  uploadFile(fileInfo : FileInfo, files) {
    if (files.length > 0) {
      this.file = files[0];
      if (this.file.type?.length > 0) {
        fileInfo.fileFormat = this.file.type;
      }
      else {
        let name : string = this.file.name;
        let s = name.split('.');
        if (s.length > 1) {
          fileInfo.fileFormat = s[s.length-1];
        }
        else {
          fileInfo.fileFormat = 'unknown';
        }
      }


    }
  }

  addFile() {
    this.fileInfo = new FileInfo();
    this.selected = null;
  }

  async deleteFile(id: string) {
    let result = await this.filesClient.deleteFileInfo(id);
    if (result.result) {
      this.fileInfos = this.fileInfos.filter(f => f.id != id);
    }
    else {
      this.alertService.error('Delete file error: ' + result.message);
    }
  }

  async downloadFile(fileInfo : FileInfo) {
    this.filesClient.getFile(fileInfo.id)
    .then(blob => {
      saveAs(blob,this.getFilename(fileInfo));
    })
    .catch(error => {
      this.alertService.error(error);
    });

  }

  async updateFileInfos(tags?: string[], limit: number = 10000, skip: number = 0) {
    try {
      this.loadingFiles = true;
      let result = await this.filesClient.getFileInfos(tags, limit, skip)
      this.fileInfos = result.files;
      this.fileCount = result.fileCount;
    }
    finally {
      this.loadingFiles = false;
    }

  }

  async refreshFiles(state?: ClrDatagridStateInterface) {
    if (state) {
      await this.updateFileInfos(this.tagsFilter, state.page.size, state.page.size * (state.page.current - 1));
    }
    else {
      this.updateFileInfos(this.tagsFilter, this.sizeOptions[0], 0);
    }
  }

  addTag(fileInfo : FileInfo) {
    if (fileInfo) {
      if (fileInfo.tags.findIndex(t => t == this.newtag) < 0) {
        fileInfo?.tags.push(this.newtag);
        this.newtag = '';
      }
    }
  }

  removeTag(fileInfo : FileInfo, tag: string) {
    if (fileInfo) {
      fileInfo.tags = fileInfo.tags.filter(t => t != tag);
    }

  }

  formatDate(date : Date): string {
    try {
      return this.dateHelper.utils.formatByString(date, "yyyy-MM-dd HH:mm:ss");
    }
    catch {
      // let d = this.dateHelper.utils.parse(date);
      // return this.dateHelper.utils.formatByString(date, "yyyy-MM-dd HH:mm:ss");
      return date + '';
    }
  }

  getFilename(fileInfo : FileInfo) : string {
    let result = fileInfo.name;
    switch (fileInfo.fileFormat) {
      case 'application/pdf':
        result += '.pdf';
        break;

      case 'image/png':
        result += '.png';
        break;

      case 'text/plain':
        result += '.txt';
        break;

      case 'text/csv':
        result += '.csv';
        break;

      default:
        result += '.' + fileInfo.fileFormat;
        break;

    }

    return result;
  }
}
