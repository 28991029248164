import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grpc_eventtype'
})
export class GrpcEventtypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0: return 'Debug';
      case 1: return 'Info';
      case 2: return 'Warning';
      case 3: return 'Error';
    }

    return 'value';
  }

}
