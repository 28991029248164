<clr-modal #modal [(clrModalOpen)]="showConfirmModal" [clrModalSize]="data.size" style="z-index: 2147483647; position: relative;">
  <h3 class="modal-title">{{data.header}}</h3>
  <div class="modal-body">
      <p *ngFor="let description of data.descriptions" >{{description}}</p>
  </div>
  <div class="modal-footer">
    <button *ngIf="data.showCancel == undefined || data.showCancel"  type="button" class="btn btn-outline" (click)="cancel()">{{data.cancel}}</button>
    <button type="button" class="btn btn-primary" (click)="ok()">{{data.ok}}</button>
  </div>
</clr-modal>

<clr-modal #modal [(clrModalOpen)]="showInputModal" [clrModalSize]="data.size" style="z-index: 2147483647; position: relative;">
  <h3 class="modal-title">{{data.header}}</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-textarea-container>
        <label class="clr-col-12">{{data.description}}</label>
        <textarea clrTextarea style="width: 90%;" class="clr-col-12" type="text" [(ngModel)]="this.data.value" name="value"></textarea>
      </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="data.showCancel == undefined || data.showCancel" type="button" class="btn btn-outline" (click)="cancelInput()">{{data.cancel}}</button>
    <button type="button" class="btn btn-primary" (click)="okInput()">{{data.ok}}</button>
  </div>
</clr-modal>
