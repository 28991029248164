import { EventEmitter, Injectable, InjectionToken, Output } from '@angular/core';
import { TreeviewItem } from '../../../core/models/treeview-item';
import { Treeview } from '@xprojectorcore/models/treeview';
import { DashboardConfig, WidgetExportParameters } from 'xproj-lib';

export const ADMINDASHBOARDSSERVICE = new InjectionToken<AdminDashboardsService>('ADMINDASHBOARDSSERVICE');

@Injectable({
  providedIn: 'root'
})
export abstract class AdminDashboardsService {

  abstract clipboardTreeviewItem : TreeviewItem;

  abstract saveDashboardTree(treeview: Treeview): Promise<boolean>;

  abstract deleteDashboard(dashboardId: string): Promise<boolean>;

  abstract getDashboardTrees(): Promise<Treeview[]>;

  abstract deleteDashboardTree(treeviewdId: string): Promise<boolean>;

  abstract loadDashboard(dashboardId: string): Promise<DashboardConfig>;

  abstract saveDashboard(dashboard: DashboardConfig): Promise<boolean>;

  abstract widgetExport(parameters: WidgetExportParameters): Promise<boolean>;
}
