import { Component, Inject, Input, OnInit, inject } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_XAutoProgramScheduling, XAUTO_XAutoProgramSchedulingInterval, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

export class ViewScheduleEntry{
  public ProgramSchedule : XAUTO_XAutoProgramScheduling;
  public Interval : XAUTO_XAutoProgramSchedulingInterval;
}


@Component({
  selector: 'xproj-editplcprogram-scheduling',
  templateUrl: './editplcprogram-scheduling.component.html',
  styleUrls: ['./editplcprogram-scheduling.component.scss']
})
export class EditplcprogramSchedulingComponent implements OnInit {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  @Input() xautoProgramID : string = '';

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;
  public loadingEntries = false;
  savingEntry : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingEntry : ClrLoadingState = ClrLoadingState.DEFAULT;


  public schedule = [];
  public selectedEntry : ViewScheduleEntry = null;

  constructor(
    private xprojClient: XProjectorClient,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.parent?.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
          this.xautoProgramID = id;
          this.logger.debug('set xautoProgramID', this.xautoProgramID);
      }
    });

    this.LoadEntries();
  }

  async addEntry()
  {
    this.selectedEntry = new ViewScheduleEntry();
    this.selectedEntry.ProgramSchedule = new XAUTO_XAutoProgramScheduling();
    this.selectedEntry.Interval = new XAUTO_XAutoProgramSchedulingInterval();
    this.selectedEntry.ProgramSchedule.xautoprogramschedulingid = uuid.v4();
    this.selectedEntry.Interval.xautoprogramschedulingid = this.selectedEntry.ProgramSchedule.xautoprogramschedulingid;
    this.selectedEntry.Interval.hertz = 10;
    this.selectedEntry.ProgramSchedule.xautoprogramid = this.xautoProgramID;
    this.schedule.push(this.selectedEntry);
  }

  async removeEntry()
  {
    await this.xprojClient.XAUTO_RemoveProgramsScheduling(this.selectedEntry.ProgramSchedule.xautoprogramschedulingid);
    this.schedule.length = 0;
    await this.LoadEntries();
  }

  async LoadEntries()
  {
    this.loadingEntries = true;
    let entries = await this.xprojClient.XAUTO_GetProgramsSchedulings(0,1000);

    if (this.xautoProgramID?.length > 0) {
      entries = entries.filter(x => x.xautoprogramid == this.xautoProgramID);
    }

    for(let e of entries)
    {
      this.logger.debug("loading interval for", e);
      let t= new ViewScheduleEntry();
      t.ProgramSchedule = e;
      t.Interval = await this.xprojClient.XAUTO_GetProgramsSchedulingInterval(t.ProgramSchedule.xautoprogramschedulingid);
      this.schedule.push(t);
    }
    this.loadingEntries = false;
  }

  async saveEntry()
  {
    this.savingEntry = ClrLoadingState.LOADING;
    try
    {
      this.logger.debug("saving", this.selectedEntry);
      this.logger.debug("saving schedule");
      await this.xprojClient.XAUTO_SaveProgramsScheduling(this.selectedEntry.ProgramSchedule);
      this.logger.debug("saving interval");
      await this.xprojClient.XAUTO_SaveProgramsSchedulingInterval(this.selectedEntry.Interval);
      this.logger.debug("saving done");
      this.savingEntry = ClrLoadingState.SUCCESS;
    }
    catch(error)
    {
      console.error(error);
      this.savingEntry = ClrLoadingState.ERROR;
    }
  }

}
