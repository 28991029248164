
export class RossakerBmsBuildingAddress {
  id : number;
  buildingId : number;
  realestateId : number;

  street : string;
  housenumber : string;
  postalcode : string;
  city : string;
  district : string;
  country : string;
  description : string;
  latitude : number;
  longitude : number;

  createdAt : Date;
  modifiedAt : Date;
}
