import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { BaseQuery, ColumnFilteringRelativeTimestamp, DownSampleQuery, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { HiglightParameters, LinkedWidgetChangeParameters, LinkedWidgetSelectParameters, LinkedWidgetSelectValue, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService, ZoomParameters } from '../../xproj-widget-service';
import { PieChartType, PiechartWidgetConfig, PiechartWidgetQuery } from '../piechart-widget-config/piechart-widget-config-service';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import autocolors from 'chartjs-plugin-autocolors';
import { ChartUtils } from '../../../utils/chart-utils-service';
import { Guid } from '../../../utils/guid-service';
import { WidgetBase } from '../../widget-base';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { GroupSelectionTypes } from '../../widget-config-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { ColorHelper } from '../../../helpers/color-helper-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-piechart-widget',
  templateUrl: './xproj-piechart-widget.component.html',
  styleUrls: ['./xproj-piechart-widget.component.scss']
})
export class XprojPiechartWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("graphCanvas", { read: ElementRef, static: false }) graphCanvas: ElementRef;
  @ViewChild("piechartWidget", { read: ElementRef, static: false }) piechartWidget: ElementRef;

  widgetConfig: PiechartWidgetConfig

  projections: Projection[] = [];
  graphChart: any;
  loading: boolean = false;
  private lastQueries: BaseQuery[] = [];

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private altDown: Boolean = false;
  private cursor: string;
  private useRelativeTimestamp: boolean = true;

  private hasdatelabels: boolean[] = [];
  private chartlabels = [];
  private chartdata = [];
  private datatype: XDataType;
  private labelsByIndex: Map<number, string> = new Map<number, string>();


  piedata = {
    datasets: [{
      label: '',
      index: 0,
      data: []
    }],

    labels: [
    ]
  };

  pieoptions = {
    responsive: true,
    maintainAspectRatio: false,
    canvas: {
      height: 300
    },
    plugins: {
      autocolors: {
        enabled: true,
        mode: 'data'
      },
      //https://nagix.github.io/chartjs-plugin-colorschemes/
      colorschemes: {
        scheme: 'tableau.Classic20'
      },
      legend: {
        display: true,
        position: 'left',
        align: 'center'
      },
      animation: {
        duration: 1000
      }
    }
  }

  bardata = {
    datasets: []
  };

  baroptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000
    },
    canvas: {
      height: 300
    },
    scales: {
      x: {
        type: 'time',
        labels: [],
        time: {
          displayFormats: {
            hour: 'MMM D hA'
          },
          unit: false
        }
      },
      left_y: {
        type: 'linear',
        position: 'left',
        display: true,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      },
      right_y: {
        type: 'linear',
        position: 'right',
        display: false,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      }
    },
    plugins: {
      autocolors: {
        enabled: true,
        mode: 'dataset'
      },
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center'
      },
      //https://nagix.github.io/chartjs-plugin-colorschemes/
      colorschemes: {
        scheme: 'tableau.Classic20'
      },
      zoom: {
        // Container for pan options
        pan: {
          // Boolean to enable panning
          enabled: true,

          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          // A function that is called as the user is panning and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: 'xy',

          rangeMin: {
            // Format of min pan range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max pan range depends on scale type
            x: null,
            y: null
          },

          // On category scale, factor of pan velocity
          speed: 20,

          // Minimal pan distance required before actually applying pan
          threshold: 10,

          // Function called while the user is panning
          //onPan: function ({ chart }) { console.log(`I'm panning!!!`); },
          // Function called once panning is completed
          //onPanComplete: function ({ chart }) { console.log(`I was panned!!!`); }
        },

        // Container for zoom options
        zoom: {
          // Container for zoom options
          zoom: {
            drag: {
              // Boolean to enable zooming
              enabled: true,

              // 	 borderColor: 'rgba(225,225,225,0.3)'
              // 	 borderWidth: 5,
              // 	 backgroundColor: 'rgb(225,225,225)',
            },

            // Zooming directions. Remove the appropriate direction to disable
            // Eg. 'y' would only allow zooming in the y direction
            // A function that is called as the user is zooming and returns the
            // available directions can also be used:
            //   mode: function({ chart }) {
            //     return 'xy';
            //   },
            mode: 'x',

            rangeMin: {
              // Format of min zoom range depends on scale type
              x: null,
              y: null
            },
            rangeMax: {
              // Format of max zoom range depends on scale type
              x: null,
              y: null
            },

            // Speed of zoom via mouse wheel
            // (percentage of zoom on a wheel event)
            speed: 0.1,

            // Minimal zoom distance required before actually applying zoom
            threshold: 2,

            // On category scale, minimal zoom level before actually applying zoom
            sensitivity: 3,

            // Function called while the user is zooming
            //onZoom: function ({ chart }) { console.log(`I'm zooming!!!`); },
            // Function called once zooming is completed
            //onZoomComplete: function ({ chart }) { console.log(`I was zoomed!!!`, chart); }
          }
        }
      },
      //https://github.com/chartjs/chartjs-plugin-annotation
      annotation: {
        // Defines when the annotations are drawn.
        // This allows positioning of the annotation relative to the other
        // elements of the graph.
        //
        // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
        // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
        drawTime: 'afterDatasetsDraw', // (default)

        // Mouse events to enable on each annotation.
        // Should be an array of one or more browser-supported mouse events
        // See https://developer.mozilla.org/en-US/docs/Web/Events
        events: ['click'],

        // Double-click speed in ms used to distinguish single-clicks from
        // double-clicks whenever you need to capture both. When listening for
        // both click and dblclick, click events will be delayed by this
        // amount.
        dblClickSpeed: 350, // ms (default)

        // Array of annotation configuration objects
        // See below for detailed descriptions of the annotation options
        annotations: []
      }
    }
  }

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private colorHelper: ColorHelper,
    private cdr: ChangeDetectorRef) {
    super(logger, xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as PiechartWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.setChartCursor('auto');

    if (this.widgetConfig.ConfigQueries.length == 0) {
      this.widgetConfig.ConfigQueries.push(this.widgetConfig.ConfigQuery)
      this.widgetConfig.ConfigQuery = null;
    }
  }

  ngOnDestroy(): void {
    this.graphChart?.destroy();
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.graphChart)
      return;

    if (!this.graphCanvas || !this.graphCanvas.nativeElement) {
      this.logger.log("Hmm no canvas.. Returning");
      return;
    }
    // if (this.widgetConfig.ChartType != PieChartType.BARCHART) {
    //   let canvas = this.graphCanvas.nativeElement as HTMLCanvasElement;
    //   if (!canvas)
    //     return;

    //   this.pieoptions.plugins.colorschemes.scheme = this.widgetConfig.ColorScheme?.length > 0 ? this.widgetConfig.ColorScheme : this.globalWidgetSettings.ColorScheme;
    //   this.baroptions.plugins.colorschemes.scheme = this.widgetConfig.ColorScheme?.length > 0 ? this.widgetConfig.ColorScheme : this.globalWidgetSettings.ColorScheme;

    //   this.graphChart = new Chart(canvas,
    //     {
    //       type: this.getChartType(this.widgetConfig.ChartType),
    //       data: this.piedata,
    //       options: this.pieoptions
    //     }
    //   )
    // }

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      this.loadDelayed();
    }

    //console.log('widgetheight', this.widgetheight);
  }

  async onRefresh() {
    await this.loadDelayed(true);
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    await this.loadDelayed();
  }

  async onUpdateQuery() {
    await this.loadDelayed();
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let load: boolean = false;

    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        if (e.zoom.to == null || e.zoom.from == null) {
          await this.resetChartZoom();
        }

        load = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQuery.Query.targetprojectionid = e.master.projectionId;
        this.widgetConfig.ConfigQuery.Query.targetgroup = e.master.group;
        load = true;
      }
      //debugger;
      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          load = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          load = true;
        }
      }
    }

    if (load) {
      await this.loadDelayed();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.loadDelayed();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    if (force || projectionIds.findIndex(p => p == this.widgetConfig.ConfigQuery.Query.targetprojectionid) >= 0) {
      await this.load(true, false);
    }
  }


  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  async resetZoom() {
    if (this.zoom) {
      this.fromZoom = null;
      this.toZoom = null;
      this.useRelativeTimestamp = true;

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
      params.path.push(this.widgetConfig.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.widgetService.linkedWidgetChanged(params);

      await this.loadDelayed();
      await this.resetChartZoom();
    }
  }

  async resetChartZoom() {
    let oldZoom = this.zoom;
    this.zoom = false;
    await this.graphChart.resetZoom();
    this.zoom = oldZoom;
  }

  setChartCursor(cursor?: string, save: boolean = true) {
    if (cursor && save) {
      this.cursor = cursor;
    }

    if (this.piechartWidget?.nativeElement) {
      this.piechartWidget.nativeElement.style.cursor = cursor ? cursor : this.cursor;
    }
  }

  onShiftDown(event) {
    //this.logger.log('keydown', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.enabled = false;
      this.graphChart.options.plugins.zoom.pan.enabled = true;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = this.onPanComplete.bind(this);
      this.setChartCursor('grab');
    }

  }

  onShiftUp(event) {
    //this.logger.log('keyup', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.enabled = true;
      this.graphChart.options.plugins.zoom.pan.enabled = false;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = undefined;
      this.setChartCursor('crosshair');
    }
  }

  onAltDown(event) {
    this.altDown = true;
  }

  onAltUp(event) {
    this.altDown = false;
  }

  focusOut() {
    this.altDown = false;
    this.onShiftUp(null);
  }

  mouseMove($event) {

    if (this.graphChart) {
      let elem = this.graphChart.getElementsAtEventForMode($event, 'index', { intersect: true }, false);

      if (elem?.length > 0) {
        this.setChartCursor('pointer', false);
      }
      else {
        this.setChartCursor();
      }
    }
  }

  async onZoomComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);
      this.useRelativeTimestamp = false;

      this.graphChart.options.animation.duration = 0;
      //this.graphChart.resetZoom();
      this.setHighlightedTimeArea();

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
      params.path.push(this.widgetConfig.Id);

      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      await this.loadDelayed();

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  onPanComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
      params.path.push(this.widgetConfig.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.loadDelayed();

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  graphClick($event) {
    try {
      let elem = this.graphChart.getElementsAtEventForMode($event, 'nearest', { intersect: true }, false);
      //console.log('chart', this.graphChart);
      //console.log('elem', elem);

      if (elem[0]) {
        let index = elem[0]?.index;
        let datsetIndex = elem[0]?.datasetIndex;
        //console.log(index)
        if (index != undefined && index >= 0 && datsetIndex != undefined && datsetIndex >= 0) {
          // elem[0]._model.borderColor = 'black';
          // elem[0]._model.borderWidth = 10;
          // elem[0]._view.borderColor = 'black';
          if (this.hasdatelabels[datsetIndex]) {

            let from: Date = new Date(elem[0].element.$context?.raw.x);
            let unit = this.graphChart.scales.x._unit;
            let to: Date = new Date(elem[0].element.$context?.raw.x);
            switch (unit) {
              case 'year': {
                to.setFullYear(to.getFullYear() + 1);
                break;
              }
              case 'quarter': {
                to.setMonth(to.getMonth() + 3);
                break;
              }
              case 'month': {
                to.setMonth(to.getMonth() + 1);
                break;
              }
              case 'week': {
                to.setDate(to.getDate() + 7);
                break;
              }
              case 'day': {
                to.setDate(to.getDate() + 1);
                break;
              }
              case 'hour': {
                to.setHours(to.getHours() + 1);
                break;
              }
              case 'minute': {
                to.setMinutes(to.getMinutes() + 1);
                break;
              }
              default: {
                to.setMinutes(to.getMinutes() + 1);
                break;
              }
            }
            //console.log('to', to);

            if (from && to) {
              let params = new LinkedWidgetChangeParameters();
              params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
              params.path.push(this.widgetConfig.Id);

              if (this.altDown) {
                let zoom = new ZoomParameters();
                zoom.from = from;
                zoom.to = to;
                zoom.unit = unit;
                params.zoom = zoom;
              }
              else {
                let highlight = new HiglightParameters();
                highlight.from = from;
                highlight.to = to;
                highlight.unit = unit;
                params.highlight = highlight;

                let zoom = new ZoomParameters();
                zoom.from = this.fromZoom;
                zoom.to = this.toZoom;
                zoom.unit = unit;
                params.zoom = zoom;
              }

              //console.log('elem2', elem);
              this.widgetService.linkedWidgetChanged(params);
              //this.graphChart?.update();
            }
          }
          else {
            if (datsetIndex < this.chartlabels.length && index < this.chartlabels[datsetIndex].length) {
              let label = this.chartlabels[datsetIndex][index];
              //console.log('label', label);

              let e = new LinkedWidgetSelectParameters();
              e.widgetId = this.widgetConfig.Id;
              e.path.push(this.widgetConfig.Id);

              let labelColInfo = new LinkedWidgetSelectValue();
              let col = this.widgetConfig.ConfigQuery.LabelConfig;
              labelColInfo.columnname = col.ColumnName;
              labelColInfo.label = col.Label;
              labelColInfo.projectionId = this.widgetConfig.ConfigQuery.ProjectionId;
              labelColInfo.value = label;
              labelColInfo.group = this.widgetConfig.ConfigQuery.Group;
              labelColInfo.datatype = XDataType.String;

              e.values.push(labelColInfo);

              let dataColInfo = new LinkedWidgetSelectValue();
              col = this.widgetConfig.ConfigQuery.LabelConfig;
              dataColInfo.columnname = col.ColumnName;
              dataColInfo.label = col.Label;
              dataColInfo.projectionId = this.widgetConfig.ConfigQuery.ProjectionId;
              dataColInfo.value = this.chartdata[index] + '';
              dataColInfo.group = this.widgetConfig.ConfigQuery.Group;
              dataColInfo.datatype = this.datatype;

              e.values.push(dataColInfo);

              e.selected = labelColInfo;

              this.widgetService.linkedWidgetSelected(e);

              if (this.widgetConfig.OutputParameters.length > 0) {
                let outputChanged = new WidgetOutputChangeParameters();
                outputChanged.widgetId = this.widgetConfig.Id;
                outputChanged.outputParameterId = this.widgetConfig.OutputParameters[0].id;
                outputChanged.datatype = this.widgetConfig.OutputParameters[0].datatype;
                outputChanged.value = e.selected.value;

                this.widgetService.outputParameterChanged(outputChanged);
              }
            }
          }
        }
      }
    }
    catch { }
  }

  setHighlightedTimeArea(from?: Date, to?: Date) {

    this.graphChart.options.annotation.annotations = [];
    if (from && to) {
      let area = {
        type: 'box',
        drawTime: 'beforeDatasetsDraw',
        id: Guid.newGuid(),
        xScaleID: 'x',
        xMin: from,
        xMax: to,
        borderColor: 'lightgray',
        borderWidth: 0,
        backgroundColor: 'lightgray',
      }

      this.graphChart.options.annotation.annotations.push(area);
    }
  }

  getExportQueries(): BaseQuery[] {
    return this.lastQueries;
  }

  getExportImage(): any {
    let url_base64jp = this.graphCanvas.nativeElement.toDataURL("image/jpg");
    return url_base64jp;
  }

  getChartType(chartType: PieChartType) {
    let result = "pie";
    switch (chartType) {
      case PieChartType.PIE:
        result = 'pie';
        break;

      case PieChartType.DOUGHNUT:
        result = 'doughnut';
        break;

      case PieChartType.RADAR:
        result = 'radar';
        break;

      case PieChartType.BARCHART:
        result = 'bar';
        break;

      case PieChartType.POLARAREA:
        result = 'polarArea';
        break;
    }

    return result;
  }

  loadTimer: any;
  async loadDelayed(forcereload: boolean = false, animation: boolean = true) {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(() => {
        this.loadTimer = undefined;
        this.load(forcereload, animation);
      }, 200);
    }
  }

  customizeColorBar(context): { background: string, border: string } {
    return this.colorHelper.GetColor(this.graphChart.options.plugins.colorschemes.scheme, context.datasetIndex, context.colors);
  }

  customizeColorPie(context): { background: string, border: string } {
    let dataIndex = context.dataIndex;
    let key = context.datasetIndex*1000 + dataIndex;
    if (this.widgetConfig.ColorByLabel && this.labelsByIndex.has(key)) {
      dataIndex = this.getDatasetIndex(this.labelsByIndex.get(key));
    }
    return this.colorHelper.GetColor(this.graphChart.options.plugins.colorschemes.scheme, dataIndex, context.colors);
  }

  async load(forcereload: boolean = false, animation: boolean = true) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.lastQueries = [];
    this.cdr.detach();
    try {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }

      if (!this.inputParametersHasValue(true)) {
        this.piedata.labels = [];
        this.piedata.datasets = [];
        this.bardata.datasets = [];
        if (this.graphChart) {
          this.graphChart.options = this.pieoptions;
        }
      }
      else {
        if (!this.graphChart) {
          let canvas = this.graphCanvas.nativeElement as HTMLCanvasElement;
          if (!canvas)
            return;

          this.graphChart = new Chart(canvas,
            {
              type: this.getChartType(this.widgetConfig.ChartType) as any,
              data: this.piedata,
              options: this.pieoptions as any,
              plugins: [zoomPlugin, autocolors]
            }
          )
        }

        let colorschemeString = this.widgetConfig.ColorScheme?.length > 0 ? this.widgetConfig.ColorScheme : this.globalWidgetSettings.ColorScheme;
        if (colorschemeString.includes(',')) {
          let colors: any = colorschemeString.split(',');
          this.pieoptions.plugins.colorschemes.scheme = colors;
          this.baroptions.plugins.colorschemes.scheme = colors;
        }
        else {
          this.pieoptions.plugins.colorschemes.scheme = colorschemeString;
          this.baroptions.plugins.colorschemes.scheme = colorschemeString;
        }

        //@ts-ignore
        this.pieoptions.plugins.autocolors.customize = this.customizeColorPie.bind(this);
        //@ts-ignore
        this.baroptions.plugins.autocolors.customize = this.customizeColorBar.bind(this);

        this.chartlabels = [];
        this.chartdata = [];

        this.piedata.datasets = [];
        this.bardata.datasets = [];

        let datasetIndex = 0;

        this.baroptions.plugins.legend.display = this.pieoptions.plugins.legend.display = this.widgetConfig.LegendShow;
        this.baroptions.plugins.legend.position = this.pieoptions.plugins.legend.position = this.getLegendPostion();
        this.baroptions.plugins.legend.align = this.pieoptions.plugins.legend.align = this.getLegendAlignment();

        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: PiechartWidgetQuery) => {
          this.hasdatelabels[datasetIndex] = false;

          let query: BaseQuery;
          let queryResult = await this.requestBaseQueryResult(() => this.getQuery(configQuery),
            (query) => {
              return this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'piechartwidget', this.config.Name);
            }, configQuery, configQuery.DataFilters, forcereload, 'piechartwidget', this.config.Name);

          this.lastQueries.push(query);

          let numericaldata = queryResult.datanumbers;
          let timestampdata = queryResult.datatimestamps;
          let stringdata = queryResult.datastrings;

          //console.log(queryResult);

          let labels = [];
          let datas = [];

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let datatype = it.datatype;
            let data = [];
            if (datatype <= XDataType.Number)
              data = numericaldata[it.indexintypedvector];
            else if (datatype == XDataType.String)
              data = stringdata[it.indexintypedvector];
            else if (datatype == XDataType.Timestamp)
              data = timestampdata[it.indexintypedvector];

            let colname = it.columnoutname;
            if (colname == configQuery.LabelConfig.ColumnOutName || 'script:' + colname == configQuery.LabelConfig.ColumnOutName) {
              labels = data;
              this.hasdatelabels[datasetIndex] = datatype == XDataType.Timestamp;
            }
            else {
              datas = data;
              this.datatype = datatype;
            }
          }

          if (this.widgetConfig.ChartType != PieChartType.BARCHART) {

            this.piedata.labels = labels;

            if (this.widgetConfig.ColorByLabel) {
              for (let i = 0; i < this.piedata.labels.length; i++) {
                  this.labelsByIndex.set(datasetIndex*1000 + i, this.piedata.labels[i].toString());
              }
            }
            //this.piedata.datasets = [];
            this.piedata.datasets.push({
              data: datas,
              index: datasetIndex,
              label: this.widgetConfig.ChartType == PieChartType.RADAR ? configQuery.Name : ''
            });
            this.graphChart.options = this.pieoptions;
          }
          else {
            let seriesdata = [];
            if (this.hasdatelabels[datasetIndex]) {
              for (let i = 0; i < datas.length; i++) {
                let point = { x: labels[i], y: datas[i] };
                seriesdata.push(point);
              }
            }

            this.bardata.datasets.push(
              {
                label: configQuery.DataConfig.Label,
                index: datasetIndex,
                data: this.hasdatelabels[datasetIndex] ? seriesdata : datas,
                yAxisID: 'left_y',
              }
            );

            if (this.hasdatelabels[datasetIndex]) {
              this.baroptions.scales.x.type = "time";
              this.baroptions.scales.x.time.unit = ChartUtils.GetBarsXAxisUnit(configQuery.GroupingTransform);
            }
            else {
              this.baroptions.scales.x.type = 'category';
              labels = ChartUtils.GetBarsXAxisLabels(labels, configQuery.GroupingTransform);
              this.baroptions.scales.x.labels = labels;
            }

            this.piedata.labels = labels;
            //this.piedata.datasets = [];
            this.piedata.datasets.push(this.bardata.datasets[datasetIndex]);
            this.graphChart.options = this.baroptions;
          }

          this.graphChart.config.type = this.getChartType(this.widgetConfig.ChartType);
          this.graphChart.options.responsive = this.responsive;
          this.graphChart.options.maintainAspectRatio = !this.responsive;
          this.graphChart.options.animation.duration = animation ? this.widgetConfig.AnimationsMs : 0;
          this.graphChart.update();
          //console.log('piechart updated', this.widgetConfig.Name);

          this.chartdata.push(datas);
          this.chartlabels.push(labels);

          datasetIndex++;
        });
      }
    }
    finally {
      this.loading = false;
      this.cdr.reattach();
    }

  }

  getQuery(configQuery: PiechartWidgetQuery): BaseQuery {
    let query = configQuery.Query.Clone();

    query.maxitems = configQuery.MaxItems;
    query.columns = query.columns.filter(col => col.columnname.indexOf(':') < 0);

    // Aggregation input parameters
    query.columns.forEach(col => {
      if (col.columnname == configQuery.DataConfig.ColumnName) {
        if (configQuery.DataConfig.UseAggregationInputParameter) {
          col.columnaggregation = this.getParameterValue(configQuery.DataConfig.AggregationInputParameterId, col.columnaggregation).value;
        }
      }
    });

    //Transformation input parameters
    if (configQuery.UseGrouping && configQuery.UseTransformInputParameter) {
      query.grouping.columntransformation = this.getParameterValue(configQuery.TransformInputParameterId, configQuery.GroupingTransform).value;
    }

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    let queryZoom = WidgetUtils.GetQueryZoomDates(this.fromZoom, this.toZoom, configQuery.GroupingTransform);
    WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(query,
      queryZoom.from ?? this.from,
      queryZoom.to ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    return query;
  }
}
