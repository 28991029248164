import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { BaseControllerConfig, ButtonConfig, ButtonType, XprojOutputButtonControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-button-controller',
  templateUrl: './xproj-output-button-controller.component.html',
  styleUrls: ['./xproj-output-button-controller.component.scss']
})
export class XprojOutputButtonControllerComponent extends XprojOutputControllerBase implements OnInit, AfterViewInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      if (!this.useApplyButton) {
          this.buttonClicked(this.selectedButton);
      }
    }
    this._initDone = value;
  }

  buttonConfig: XprojOutputButtonControllerConfig;

  selectedButton: ButtonConfig = null;
  value: any;
  value2: any;
  value3: any;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    // if (!this.useApplyButton) {
    //   //setTimeout(() => {
    //     this.buttonClicked(this.selectedButton);
    //   //}, 2000);
    // }
  }

  ngOnInit(): void {
    this.buttonConfig = this.config as XprojOutputButtonControllerConfig;

    this.buttonConfig.Buttons.forEach(button => {
      if (button.Default) {
        this.setValue(button);
      }
    });

  }

  getClass(button: ButtonConfig): string {
    let result = 'btn ';
    switch (button.ButtonType) {
      case ButtonType.REGULAR:
        result += button == this.selectedButton ? 'btn-primary' : 'btn-outline';
        break;
      case ButtonType.DANGER:
        result += button == this.selectedButton ? 'btn-danger' : 'btn-danger-outline';
        break;
      case ButtonType.INFO:
        result += button == this.selectedButton ? 'btn-info' : 'btn-info-outline';
        break;
      case ButtonType.SUCCESS:
        result += button == this.selectedButton ? 'btn-success' : 'btn-success-outline';
        break;
      case ButtonType.WARNING:
        result += button == this.selectedButton ? 'btn-warning' : 'btn-warning-outline';
        break;
    }

    return result;
  }

  setValue(button: ButtonConfig): any {
    if (button) {
      switch (this.buttonConfig.DataType) {
        case OutputDataType.String:
          this.value = button.ValueString;
          break;
        case OutputDataType.Timestamp:
          this.value = button.ValueDate;
          break;
        case OutputDataType.RelativeTimestamp:
          this.value = button.ValueNumber;
          this.value2 = button.ValueNumber2;
          this.value3 = button.ValueNumber3;
          break;
        default:
          this.value = button.ValueNumber;
          break;
      }
      //if (this.useApplyButton) {
      this.selectedButton = button;
      //}
    }
  }

  buttonClicked(button: ButtonConfig) {
    if(!button)
      return;

    if(button.EmitsOutputParameter)
    {
      this.setValue(button);
      this.logger.debug('buttonClicked', this.value, this.value2, this.value3);
    }
    if(button.ScriptedAction)
    {
      this.widgetService.lua.RunScript(button.ScriptedActionLuaScript);
    }

    if (!this.useApplyButton) {
      this.widgetService.outputParametersChanged(this.getOutputChangeParameters());
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.buttonConfig.Id;
    output.datatype = this.buttonConfig.DataType;
    output.value = this.value;

    let result = [output];

    if (this.value2) {
      let output2 = new WidgetOutputChangeParameters();
      output2.widgetId = this.widgetConfig.Id;
      output2.outputParameterId = this.buttonConfig.Id + '_2';
      output2.datatype = this.buttonConfig.DataType2;
      output2.value = this.value2;

      result.push(output2);
    }

    if (this.value3) {
      let output3 = new WidgetOutputChangeParameters();
      output3.widgetId = this.widgetConfig.Id;
      output3.outputParameterId = this.buttonConfig.Id + '_3';
      output3.datatype = this.buttonConfig.DataType3;
      output3.value = this.value3;

      result.push(output3);
    }

    return result;
  }

}
