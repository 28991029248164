<div class="card">
  <div class="card-header">
    Log config
  </div>
  <div class="card-block">
    <form clrForm>
      <clr-select-container>
        <label class="clr-col-12">Console Log level</label>
        <select clrSelect class="clr-col-12" name="billingperiod"
          [(ngModel)]="state.currentLogLevel" (ngModelChange)="currentLogLevelChanged($event)">
          <option [ngValue]="NgxLoggerLevel.TRACE"> TRACE </option>
          <option [ngValue]="NgxLoggerLevel.DEBUG"> DEBUG </option>
          <option [ngValue]="NgxLoggerLevel.INFO"> INFO </option>
          <option [ngValue]="NgxLoggerLevel.LOG"> LOG </option>
          <option [ngValue]="NgxLoggerLevel.WARN"> WARN </option>
          <option [ngValue]="NgxLoggerLevel.ERROR"> ERROR </option>
          <option [ngValue]="NgxLoggerLevel.FATAL"> FATAL </option>
          <option [ngValue]="NgxLoggerLevel.OFF"> OFF </option>
        </select>
      </clr-select-container>
    </form>

  </div>
</div>
