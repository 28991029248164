import { BaseQuery, FilterLogicalGroupType, Transformation, Aggregation } from '../../../XProjector/xprojector-client-service';
import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Guid } from '../../../utils/guid-service';

export enum YAxisId
{
	LEFT         = 0,
	RIGHT        = 1
}

@jsonObject
export class SpectrumAnalyzerYAxesConfig {
  @jsonMember
	public ColumnName : string = '';

	@jsonMember
	public ColumnOutName : string = '';

	@jsonMember
	public Label : string = '';

	@jsonMember
	public AxisId : YAxisId = YAxisId.LEFT;

	@jsonMember
	public Type : string = 'line';

	@jsonMember
  public Unit : string = '';

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
	public LabelReal : string = '';

  @jsonMember
	public TypeReal : string = 'line';

	@jsonMember
	public AxisIdReal : YAxisId = YAxisId.LEFT;

  @jsonMember
	public LabelPower : string = '';

  @jsonMember
	public TypePower : string = 'line';

	@jsonMember
	public AxisIdPower : YAxisId = YAxisId.RIGHT;

}

@jsonObject
export class SpectrumAnalyzerQuery implements BaseQueryConfigInterface {
  public Id : string = Guid.newGuid();

	@jsonMember
	public Query : BaseQuery = new BaseQuery();

	@jsonMember
	public Xaxis : string = '';

	@jsonMember
	UseGrouping : boolean = false;

	@jsonMember
	XaxisTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

	@jsonArrayMember(String)
	public Yaxises : string[] = [];

	@jsonArrayMember(Number)
	YaxisesTransforms: Aggregation[] = [];

	@jsonArrayMember(SpectrumAnalyzerYAxesConfig)
	public YAxesConfigs : SpectrumAnalyzerYAxesConfig[] = [];

	@jsonArrayMember(DataFilter)
	public DataFilters : DataFilter[] = [];

	@jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];
}

@jsonObject
export class SpectrumAnalyzerConfig extends WidgetConfig{
  @jsonMember
	public Type : string = 'line';

  @jsonMember
  public DisableZoom : boolean = false;

  @jsonMember
	public AnimationsMs : number = 1000;

	@jsonArrayMember(SpectrumAnalyzerQuery)
	public ConfigQueries : SpectrumAnalyzerQuery[] = [];

   @jsonMember
  public ColorScheme : string = '';

  @jsonMember
  public InteractionMode : string = 'nearest';

  @jsonMember
  public InteractionIntersect : boolean = false;

  @jsonMember
  public InteractionAxis : string = '';

	public constructor() {
		super();
		this.Type = 'line';
	}

	public Clone() : SpectrumAnalyzerConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, SpectrumAnalyzerConfig), SpectrumAnalyzerConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = true;
}
