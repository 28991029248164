<form clrForm clrLayout="vertical">
    <clr-select-container>
        <label>{{aggregationConfig.Label ? aggregationConfig.Label : 'Select aggregation' }}</label>
        <select clrSelect name="selectedAggregation" [(ngModel)]="selectedAggregation" (change)="onSelect()">
      <option *ngIf="aggregationConfig.NONE" [ngValue]="Aggregation.NONE"> None </option>
      <option *ngIf="aggregationConfig.COUNT" [ngValue]="Aggregation.COUNT"> Count </option>
      <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

      <option *ngIf="aggregationConfig.SUM" [ngValue]="Aggregation.SUM"> Sum </option>

      <option *ngIf="aggregationConfig.MAX" [ngValue]="Aggregation.MAX"> Max </option>
      <option *ngIf="aggregationConfig.MIN" [ngValue]="Aggregation.MIN"> Min </option>

      <option *ngIf="aggregationConfig.FIRST" [ngValue]="Aggregation.FIRST"> First </option>
      <option *ngIf="aggregationConfig.LAST" [ngValue]="Aggregation.LAST"> Last </option>

      <option *ngIf="aggregationConfig.FORWARD_DIFF" [ngValue]="Aggregation.FORWARD_DIFF"> Last </option>

      <option *ngIf="aggregationConfig.MEAN_ARITHMETIC" [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic
      </option>
      <option *ngIf="aggregationConfig.MEAN_GEOMETRIC" [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric
      </option>
      <option *ngIf="aggregationConfig.MEAN_HARMONIC" [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

      <option *ngIf="aggregationConfig.MEDIAN" [ngValue]="Aggregation.MEDIAN"> Median </option>

      <option *ngIf="aggregationConfig.SD_SAMPLED_UNCORRECTED" [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard
        deviation - uncorrected </option>
      <option *ngIf="aggregationConfig.SD_SAMPLED_CORRECTED" [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard
        deviation - corrected </option>
      <option *ngIf="aggregationConfig.SD_SAMPLED_UNBIASED_APPROX_ND"
        [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
      </option>

      <option *ngIf="aggregationConfig.MAD_ORIGO_MEAN_ARITHMETIC" [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC">
        Mean absolute deviation - around
        arithmetic mean </option>
      <option *ngIf="aggregationConfig.MAD_ORIGO_MEAN_GEOMETRIC" [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean
        absolute deviation - around geometric
        mean </option>
      <option *ngIf="aggregationConfig.MAD_ORIGO_MEAN_HARMONIC" [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean
        absolute deviation - around harmonic
        mean </option>
      <option *ngIf="aggregationConfig.MAD_ORIGO_MEDIAN" [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute
        deviation - around median </option>
    </select>
    </clr-select-container>
</form>