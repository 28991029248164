<xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets"
  [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>
<br />
<div *ngIf="editConfig">
  <form clrForm>
    <clr-select-container>
      <label>Graph type</label>
      <select clrSelect name="piecharttype" [(ngModel)]="editConfig.ChartType">
        <option [ngValue]="PieChartType.PIE"> Pie </option>
        <option [ngValue]="PieChartType.DOUGHNUT"> Doughnut </option>
        <option [ngValue]="PieChartType.RADAR"> Radar </option>
        <option [ngValue]="PieChartType.BARCHART"> Bars </option>
        <option [ngValue]="PieChartType.POLARAREA"> Polar Area </option>
      </select>
    </clr-select-container>

    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox [(ngModel)]="editConfig.LegendShow" name="legendshow" />
        <label>Show legend</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <clr-select-container>
      <label>Legend position</label>
      <select clrSelect name="legendposition" [(ngModel)]="editConfig.LegendPosition">
        <option [ngValue]="LegendPosition.LEFT"> Left </option>
        <option [ngValue]="LegendPosition.TOP"> Top </option>
        <option [ngValue]="LegendPosition.RIGHT"> Right </option>
        <option [ngValue]="LegendPosition.BOTTOM"> Bottom </option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Legend alignment</label>
      <select clrSelect name="legendalignment" [(ngModel)]="editConfig.LegendAlignment">
        <option [ngValue]="LegendAlignment.START"> Start </option>
        <option [ngValue]="LegendAlignment.CENTER"> Center </option>
        <option [ngValue]="LegendAlignment.END"> End </option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Animations (ms)</label>
      <input clrInput placeholder="" type="number" name="animationsms" min="0" max="100000"
        [(ngModel)]="editConfig.AnimationsMs" />
    </clr-input-container>

    <clr-input-container>
      <label>Colorscheme</label>
      <input clrInput placeholder="" type="text" size="60" name="colorscheme" [(ngModel)]="editConfig.ColorScheme" />
      <clr-control-helper>Colorscheme name or ',' seperated colors.</clr-control-helper>
    </clr-input-container>

    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox [(ngModel)]="editConfig.ColorByLabel" name="colorbylabel" />
        <label>Color by label</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>
  </form>

  <clr-accordion style="margin-top: 1em;">
    <clr-accordion-panel *ngFor="let configQuery of editConfig.ConfigQueries; index as i">
      <clr-accordion-title>{{configQuery.Name}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-piechart-widget-query-config [widgetQuery]="configQuery" [projections]="projections" [widgetConfig]="widgetConfig"
          [inputParameters]="editConfig.InputParameters" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)"
          (onDuplicateQuery)="onDuplicateQuery($event)">
        </xproj-piechart-widget-query-config>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addConfigQuery();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add query
  </button>
</div>
