<xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"  [gridSettings]="gridSettings" [showInputParameters]="false" ></xproj-widget-config>
<br />
<clr-tabs>
	<clr-tab>
		<button clrTabLink>Time</button>
		<clr-tab-content>
			<xproj-master-widget-time #masterTime (onApply)="onTimeApply($event)" [settings]="widgetConfig.Time"></xproj-master-widget-time>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
		<button clrTabLink>Projection</button>
		<clr-tab-content>
			<form clrForm>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.ProjectionSelectEnabled" name="projectionSelectEnabled" />
            <label>Select projection and group enabled</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

				<clr-select-container>
					<label>Projection</label>
					<select clrSelect name="projection" [(ngModel)]="projection"
						(ngModelChange)="selectedProjectionChange($event)">
						<option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
					</select>
				</clr-select-container>

				<xproj-group-selection #groupSelect [projection]="projection" [selected]="widgetConfig.SelectedGroup"></xproj-group-selection>
			</form>
		</clr-tab-content>
	</clr-tab>
</clr-tabs>
