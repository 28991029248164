import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CacheSettings, CACHESETTINGS } from './models/cache-settings';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CacheModule {
  constructor(@Optional() @SkipSelf() parentModule?: CacheModule) {
    if (parentModule) {
      throw new Error(
        'XProjectorCacheModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: CacheSettings): ModuleWithProviders<CacheModule> {
    return {
      ngModule: CacheModule,
      providers: [
        {provide: CACHESETTINGS, useValue: config }
      ]
    };
  }
}
