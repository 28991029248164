export class RossakerCustomerErrors {
  customerId : string;
  customerName : string;
  errorMessage : string;
  stackTrace : string;
}

export class RossakerBmsMasterDataImportResult {
  ok : boolean;
  message : string;

  realestateCount : number;
  realestatesAdded : number;
  realestatesDeleted : number;

  buildingAddressCount : number;
  buildingAddressesAdded : number;
  buildingAddressesDeleted : number;

  apartmentCount : number;
  apartmentsAdded : number;
  apartmentsDeleted : number;
  apartmentsUpdated : number;
  apartmentsMoved : number;

  facilityCount : number;
  facilityAdded : number;
  facilityDeleted : number;
  facilityUpdated : number;
  facilityMoved : number;

  meterCount : number;
  metersAdded : number;
  metersUpdated : number;
  metersDeleted : number;
  metersMoved : number;
  metersReferencedChanged : number;

  gatewayCount : number;
  gatewaysAdded : number;
  gatewaysDeleted : number;

  customerErrors : RossakerCustomerErrors[];
}
