/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.dashboard.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as xprojectorGrpcModels001 from './xprojector.grpc.models.pb';
import * as googleProtobuf002 from './google/protobuf/empty.pb';
import { GRPC_DASHBOARD_CLIENT_SETTINGS } from './xprojector.dashboard.pbconf';
/**
 * Service client implementation for dashboard.Dashboard
 */
@Injectable({ providedIn: 'root' })
export class DashboardClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /dashboard.Dashboard/SaveDashboard
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    saveDashboard: (
      requestData: thisProto.DashboardConfig,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/SaveDashboard',
        requestData,
        requestMetadata,
        requestClass: thisProto.DashboardConfig,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/LoadDashboard
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DashboardConfig>>
     */
    loadDashboard: (
      requestData: thisProto.LoadDashboardRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DashboardConfig>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/LoadDashboard',
        requestData,
        requestMetadata,
        requestClass: thisProto.LoadDashboardRequest,
        responseClass: thisProto.DashboardConfig
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/DeleteDashboard
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    deleteDashboard: (
      requestData: thisProto.DeleteDashboardRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/DeleteDashboard',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteDashboardRequest,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/SaveDashboardTree
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    saveDashboardTree: (
      requestData: thisProto.DashboardTree,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/SaveDashboardTree',
        requestData,
        requestMetadata,
        requestClass: thisProto.DashboardTree,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/LoadDashboardTree
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DashboardTree>>
     */
    loadDashboardTree: (
      requestData: thisProto.LoadDashboardTreeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DashboardTree>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/LoadDashboardTree',
        requestData,
        requestMetadata,
        requestClass: thisProto.LoadDashboardTreeRequest,
        responseClass: thisProto.DashboardTree
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/LoadDashboardTrees
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.LoadDashboardTreesResponse>>
     */
    loadDashboardTrees: (
      requestData: googleProtobuf002.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.LoadDashboardTreesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/LoadDashboardTrees',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf002.Empty,
        responseClass: thisProto.LoadDashboardTreesResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/DeleteDashboardTree
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    deleteDashboardTree: (
      requestData: thisProto.DeleteDashboardTreeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/DeleteDashboardTree',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteDashboardTreeRequest,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/SaveDashboardXConf
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    saveDashboardXConf: (
      requestData: thisProto.DashboardConfig,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/SaveDashboardXConf',
        requestData,
        requestMetadata,
        requestClass: thisProto.DashboardConfig,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/LoadDashboardXConf
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DashboardConfig>>
     */
    loadDashboardXConf: (
      requestData: thisProto.LoadDashboardRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DashboardConfig>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/LoadDashboardXConf',
        requestData,
        requestMetadata,
        requestClass: thisProto.LoadDashboardRequest,
        responseClass: thisProto.DashboardConfig
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/LoadDashboardsXConf
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.LoadDashboardsResponse>>
     */
    loadDashboardsXConf: (
      requestData: thisProto.LoadDashboardsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.LoadDashboardsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/LoadDashboardsXConf',
        requestData,
        requestMetadata,
        requestClass: thisProto.LoadDashboardsRequest,
        responseClass: thisProto.LoadDashboardsResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/DeleteDashboardXConf
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    deleteDashboardXConf: (
      requestData: thisProto.DeleteDashboardRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/DeleteDashboardXConf',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteDashboardRequest,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /dashboard.Dashboard/UpdateDashboardIdXConf
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    updateDashboardIdXConf: (
      requestData: thisProto.UpdateDashboardIdRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/dashboard.Dashboard/UpdateDashboardIdXConf',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateDashboardIdRequest,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_DASHBOARD_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('dashboard.Dashboard', settings);
  }

  /**
   * Unary call @/dashboard.Dashboard/SaveDashboard
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  saveDashboard(
    requestData: thisProto.DashboardConfig,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .saveDashboard(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/LoadDashboard
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DashboardConfig>
   */
  loadDashboard(
    requestData: thisProto.LoadDashboardRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DashboardConfig> {
    return this.$raw
      .loadDashboard(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/DeleteDashboard
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  deleteDashboard(
    requestData: thisProto.DeleteDashboardRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .deleteDashboard(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/SaveDashboardTree
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  saveDashboardTree(
    requestData: thisProto.DashboardTree,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .saveDashboardTree(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/LoadDashboardTree
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DashboardTree>
   */
  loadDashboardTree(
    requestData: thisProto.LoadDashboardTreeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DashboardTree> {
    return this.$raw
      .loadDashboardTree(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/LoadDashboardTrees
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.LoadDashboardTreesResponse>
   */
  loadDashboardTrees(
    requestData: googleProtobuf002.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.LoadDashboardTreesResponse> {
    return this.$raw
      .loadDashboardTrees(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/DeleteDashboardTree
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  deleteDashboardTree(
    requestData: thisProto.DeleteDashboardTreeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .deleteDashboardTree(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/SaveDashboardXConf
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  saveDashboardXConf(
    requestData: thisProto.DashboardConfig,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .saveDashboardXConf(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/LoadDashboardXConf
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DashboardConfig>
   */
  loadDashboardXConf(
    requestData: thisProto.LoadDashboardRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DashboardConfig> {
    return this.$raw
      .loadDashboardXConf(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/LoadDashboardsXConf
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.LoadDashboardsResponse>
   */
  loadDashboardsXConf(
    requestData: thisProto.LoadDashboardsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.LoadDashboardsResponse> {
    return this.$raw
      .loadDashboardsXConf(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/DeleteDashboardXConf
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  deleteDashboardXConf(
    requestData: thisProto.DeleteDashboardRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .deleteDashboardXConf(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/dashboard.Dashboard/UpdateDashboardIdXConf
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  updateDashboardIdXConf(
    requestData: thisProto.UpdateDashboardIdRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .updateDashboardIdXConf(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
