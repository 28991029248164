import { Component, OnInit } from '@angular/core';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XprojAlertService } from 'xproj-lib';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers : Customer[];

  showRemoveCustomer : boolean = false;

  selectedCustomer : Customer;

  constructor(
    private sysAdminClient : XProjectorSysAdminClient,
    private alertService : XprojAlertService ) { }

  async ngOnInit() {
    this.getCustomers();
  }

  async getCustomers() {
    this.customers = await this.sysAdminClient.getCustomers();
  }

  async removeSelectedCustomer() {
    if (this.selectedCustomer) {
      this.showRemoveCustomer = true;
    }
  }

  async doRemoveSelectedCustomer() {
    if (this.selectedCustomer) {
      let result = await this.sysAdminClient.deleteCustomer(this.selectedCustomer.id);
      if (result.result) {
        this.customers = this.customers.filter(c => c != this.selectedCustomer );
        this.selectedCustomer = null;
        this.alertService.success('Customer deleted.');
      } else {
        this.alertService.error(result.message);
      }
    }
  }

  async saveSelectedCustomer() {
    let result = await this.sysAdminClient.modifyCustomer(this.selectedCustomer);
    if (result.result) {
      this.alertService.success('Customer updated.');
    } else {
      this.alertService.error(result.message);
    }

  }

}
