import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { Subscription } from 'rxjs';
import { Customer, CustomerUser, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';

@Component({
  selector: 'app-rossaker-bms-admin-workspace',
  templateUrl: './rossaker-bms-admin-workspace.component.html',
  styleUrls: ['./rossaker-bms-admin-workspace.component.scss'],
  host: {
    class: 'content-container'
  },
  encapsulation: ViewEncapsulation.None
})
export class RossakerBmsAdminWorkspaceComponent implements OnInit, OnDestroy {

  collapsed = false;

  private customerSubscription: Subscription;

  selectedCustomer : Customer;
  oldSelectedCustomer : Customer;

  constructor(
    public rossakerState : RossakerStateService,
    private sysAdminClient: XProjectorSysAdminClient,
    private adminService: RossakerBmsAdminService) {

  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.customerSubscription = this.rossakerState.customer$.subscribe(async (e) => {
      this.selectedCustomer = e.customer;
    });
    this.rossakerState.updateCustomers();
  }

  currentCustomerChanged($event) {
    if (this.selectedCustomer) {
      this.rossakerState.setCustomer(this.selectedCustomer.id);
    }

  }

  customerSelectOpenChange(open: boolean) {    
    if (open) {
      this.oldSelectedCustomer = this.selectedCustomer;
      this.selectedCustomer = null;
    }
    else if (!this.selectedCustomer) {
      this.selectedCustomer = this.oldSelectedCustomer;
    }

  }
}
