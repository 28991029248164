import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-data',
  templateUrl: './big-data.component.html',
  styleUrls: ['./big-data.component.scss']
})
export class SystemsBigDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
