import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import Chart from 'chart.js/auto';
import xprojGaugeController from './xproj-gauge-controller.js';
import { BaseQuery, BaseQueryOutputColumnDescription, ColumnFilteringRelativeTimestamp, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { GroupSelectionTypes } from '../../widget-config-service';
import { LinkedWidgetChangeParameters, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { ColorProperty, ColorThreshold, GaugeStyle, GaugeType, LabelConfig, LabelWidgetConfig, LabelWidgetQuery, LabelWidgetType } from '../label-widget-config/label-widget-config-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

Chart.register(xprojGaugeController);

export class LabelValue {
  value: string = '---';
  unit: string = '';
  fontSizeValue: string = '1em';
  fontSizeLabel: string = '1em';
  fontFamilyLabel : string = '';
  fontFamilyValue : string = '';
  label: string = '';
  textColor: string;
  backgroundColor: string;
  hidden: boolean = false;
  margin: string = '0 0 0 0';
  alignLabel: string = 'right';
  alignValue: string = 'left';
}

@Component({
  selector: 'xproj-label-widget',
  templateUrl: './xproj-label-widget.component.html',
  styleUrls: ['./xproj-label-widget.component.scss']
})
export class XprojLabelWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("graphCanvas", { read: ElementRef, static: false }) graphCanvas: ElementRef;

  widgetConfig: LabelWidgetConfig;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private useRelativeTimestamp: boolean = true;

  projections: Projection[] = [];

  loading: boolean = false;
  values: LabelValue[] = [];
  widgetBackgroud: string;

  LabelWidgetType = LabelWidgetType;

  graphChart: any;

  formatter(x: any) {
    if (!x)
      return "";

    return Math.round(x.dataset.value) + (this.gaugeUnit.length > 0 ? (" " + this.gaugeUnit) : "");
  }



  private formatValue(x: any, dateformat = null) {
    if (x == undefined || x == null)
      return "";

    if (x instanceof Date) {
      let date = x as Date;
      return this.dateHelper.formatDate(date, dateformat);
    }

    if (typeof x === "string") {
      return x;
    }

    return x.toString();
  }

  graphConfig = {
    type: 'xproj-gauge',
    data: {
      labels: [],
      datasets: [{
        data: [],
        value: 0,
        backgroundColor: [],
        borderWidth: 2,
        borderColor: '#fff'
      }]
    },
    options: {
      responsive: true,
      title: {
        display: false,
        text: ''
      },
      layout: {
        padding: {
          bottom: 30
        }
      },
      needle: {
        // Needle circle radius as the percentage of the chart area width
        radiusPercentage: 2,
        // Needle width as the percentage of the chart area width
        widthPercentage: 3.2,
        // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
        lengthPercentage: 80,
        // The color of the needle
        color: 'rgba(0, 0, 0, 1)'
      },
      valueLabel: {
        formatter: this.formatter.bind(this)
      },
      plugins: {
        datalabels: {
          display: true,
          formatter: function (value, context) {
            return '< ' + Math.round(value);
          },
          color: function (context) {
            return context.dataset.backgroundColor;
          },
          //color: 'rgba(255, 255, 255, 1.0)',
          backgroundColor: 'rgba(0, 0, 0, 1.0)',
          borderWidth: 0,
          borderRadius: 5,
          font: {
            weight: 'bold'
          }
        }
      }
    }
  };

  gaugeValue: number = 0;
  gaugeUnit: string = '';
  gaugeIndex: number = 0;
  gaugeSize: number = 200;
  gaugeThresholdConfig: Map<number, { color: string }> = new Map<number, { color: string }>();

  GaugeType = GaugeType;
  GaugeStyle = GaugeStyle;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private dateHelper: DateHelper) {
    super(logger, xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as LabelWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
  }

  ngOnDestroy(): void {
    this.graphChart?.destroy();
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {

    if (this.widgetConfig.LabelType == LabelWidgetType.GAUGE_NEEDLE) {
      let canvas = this.graphCanvas.nativeElement as HTMLCanvasElement;
      if (!canvas)
        return;

      this.updateGaugeNeedle(0);

      this.graphChart = new Chart(canvas, this.graphConfig as any);
    }

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      setTimeout(() => {
        this.load();
      }, 500);
    }
  }

  async onRefresh() {
    await this.load(true);
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetSize(this.getHeight(component), component?.width);
  }

  async onReset() {

  }


  async onUpdateQuery() {
    await this.load();
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let load: boolean = false;

    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        load = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQueries.forEach(query => {
          query.Query.targetprojectionid = e.master.projectionId;
          query.Query.targetgroup = e.master.group;
        });
        load = true;
      }
      //debugger;
      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          load = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          load = true;
        }
      }
    }

    if (load) {
      await this.load();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.load();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (force || i >= 0) {
      await this.load(true);
    }
  }

  private setWidgetSize(height: number, width?: number): void {
    if (height) {
      this.widgetheight = height;
    }
    this.widgetwidth = width ?? this.widgetheight;

    this.gaugeSize = this.widgetheight < this.widgetwidth ? this.widgetheight : this.widgetwidth;
  }


  getColor(colorThredholds: ColorThreshold[], value: number): string {
    try {
      if (colorThredholds.length == 0) {
        return undefined;
      }

      let sorted = colorThredholds.sort((a, b) => {
        return a.Value - b.Value;
      });
      //console.log('sorted', sorted);

      for (let i = 0; i < sorted.length; i++) {
        let ct = sorted[i];
        if (value < ct.Value) {
          return i == 0 ? undefined : sorted[i - 1].Color;
        }

      }

      return sorted[sorted.length - 1].Color;
    }
    catch {
      return undefined;
    }
  }

  updateGauge() {
    this.gaugeThresholdConfig.clear();
    this.widgetConfig.GaugeSettings.Thresholds.forEach(t => {
      this.gaugeThresholdConfig[t.Value] = { color: t.Color };
    });
  }

  updateGaugeNeedle(value: number) {
    let data = [];
    let backgroundColor = [];
    let labels = [];

    this.widgetConfig.GaugeColorThresholds.forEach(ct => {
      data.push(ct.Value);
      backgroundColor.push(ct.Color);
      labels.push(ct.Label);
    });

    this.graphConfig.data.labels = labels;
    this.graphConfig.data.datasets[0].backgroundColor = backgroundColor;
    this.graphConfig.data.datasets[0].data = data;

    this.graphConfig.data.datasets[0].value = value;

    this.graphConfig.options.title.display = this.widgetConfig.GaugeText.length > 0;
    this.graphConfig.options.title.text = this.widgetConfig.GaugeText;

    this.graphChart?.update();
  }

  async load(forcereload: boolean = false) {
    if (this.loading) {
      return;
    }

    if (!this.widgetheight || !this.widgetwidth) {
      this.setWidgetSize(this.widgetConfig.Height, this.widgetConfig.Width);
    }

    this.loading = true;
    let newValues = [];
    this.widgetBackgroud = undefined;
    try {
      if (!this.inputParametersHasValue(true)) {
        //console.log('not inputParametersHasValue');
      }
      else {
        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: LabelWidgetQuery) => {
          let queryResult = await this.requestBaseQueryResult(() => this.getQuery(configQuery),
            (query) => {
              return this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'labelwidget', this.config.Name);
            }, configQuery, configQuery.DataFilters, forcereload, 'labelwidget', this.config.Name);

          let numericaldata = queryResult.datanumbers;
          let timestampdata = queryResult.datatimestamps;
          let stringdata = queryResult.datastrings;

          configQuery.DataConfigs.forEach(dataConfig => {
            let queryColumn: BaseQueryOutputColumnDescription;
            if (dataConfig.ColumnName.indexOf(':') < 0) {
              queryColumn = queryResult.columns.find(col => col.columnoutname == dataConfig.ColumnOutName);
            }
            else {
              if (dataConfig.ColumnName.startsWith('script')) {
                queryColumn = queryResult.columns.find(col => 'script:' + col.columnoutname == dataConfig.ColumnName);
              }
              else {
                queryColumn = queryResult.columns.find(col => col.columnoutname == dataConfig.ColumnName);
              }
            }

            if (queryColumn) {
              let data: any;

              if (queryColumn.datatype <= XDataType.Number) {
                let i: number = numericaldata[queryColumn.indexintypedvector][0];
                if (i == undefined || Number.isNaN(i)) {
                  data = 0;
                }
                else {
                  data = WidgetUtils.FormatNumber(i, this.globalWidgetSettings.Decimals);
                }
              }
              else if (queryColumn.datatype == XDataType.String) {
                data = stringdata[queryColumn.indexintypedvector][0];
              }
              else if (queryColumn.datatype == XDataType.Timestamp) {
                data = timestampdata[queryColumn.indexintypedvector][0];
              }

              let value = new LabelValue();

              value.value = this.formatValue(data, dataConfig.DateFormatting);

              if (dataConfig.UseUnitInputParameter) {
                value.unit = this.getParameterValue(dataConfig.UnitInputParameterId, dataConfig.Unit).value;
              }
              else {
                value.unit = dataConfig.Unit;
              }

              value.fontFamilyLabel = dataConfig.FontFamilyLabel;
              value.fontFamilyValue = dataConfig.FontFamilyValue;
              value.fontSizeValue = dataConfig.FontSizeValue;
              value.fontSizeLabel = dataConfig.FontSizeLabel;
              value.alignLabel = dataConfig.AlignLabel;
              value.alignValue = dataConfig.AlignValue;
              value.label = dataConfig.Label;
              value.textColor = dataConfig.ColorProperty == ColorProperty.TEXT ? this.getColor(dataConfig.ColorThresholds, data) : undefined;
              value.backgroundColor = dataConfig.ColorProperty == ColorProperty.TEXT_BACKGROUND ? this.getColor(dataConfig.ColorThresholds, data) : undefined;
              value.hidden = dataConfig.Hidden;
              value.margin = dataConfig.Margin;

              newValues.push(value);

              if (this.widgetConfig.LabelType == LabelWidgetType.GAUGE || this.widgetConfig.LabelType == LabelWidgetType.GAUGE_NEEDLE) {
                this.gaugeValue = data;
                this.gaugeUnit = dataConfig.Unit;
              }

              if (!this.widgetBackgroud && dataConfig.ColorProperty == ColorProperty.WIDGET_BACKGROUND) {
                this.widgetBackgroud = this.getColor(dataConfig.ColorThresholds, data);
              }
            }
          });
        });
      }
    }
    finally {
      this.values = newValues;

      if (this.widgetConfig.LabelType == LabelWidgetType.GAUGE_NEEDLE && this.values.length > 0) {
        let value: any = this.values[0].value;
        this.updateGaugeNeedle(value);
      }
      else if (this.widgetConfig.LabelType == LabelWidgetType.GAUGE) {
        this.updateGauge();
      }
      this.loading = false;
    }

  }

  getQuery(configQuery: LabelWidgetQuery): BaseQuery {
    let query = configQuery.Query.Clone();

    query.columns = query.columns.filter(col => col.columnname.indexOf(':') < 0);

    // Aggregation input parameters
    let i = 0;
    query.columns.forEach(col => {
      let dataConfig = configQuery.DataConfigs[i];
      if (dataConfig.UseAggregationInputParameter) {
        col.columnaggregation = this.getParameterValue(dataConfig.AggregationInputParameterId, col.columnaggregation).value;
      }
      i++;
    });

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    let scol = configQuery.DataConfigs.find(c => c.ColumnName == configQuery.defaultSortColumnName);
    if (scol) {
      if (scol.ColumnName.indexOf(':') > -1) {
        let scol = query.columns.find(c => c.columnname.indexOf(':') < 0);
        query.sorting.columnname = scol?.columnname;
      }
      else {
        query.sorting.columnname = scol.ColumnName;
      }
      query.sorting.descending = configQuery.defaultSortDescending;
    }

    WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(query,
      this.fromZoom ?? this.from,
      this.toZoom ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    return query;
  }
}
