import { inject } from '@angular/core';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XprojBimService, BimFileInfo, Blob } from 'xproj-lib';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { StateService } from '@xprojectorcore/services/state-service';

export class XprojBimXConfService implements XprojBimService {

  private customerUserClient: XProjectorCustomerUsersClient = inject(XProjectorCustomerUsersClient);
  private filesClient: XProjectorFilesClient = inject(XProjectorFilesClient);
  private state: StateService = inject(StateService);


  async uploadBimFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let b = new globalThis.Blob([blob.data], { type: 'application/json' });
    let file = new File([b], name, { type: 'application/json' });

    let result = await this.state.upsertFile('bmsbim_bim_' + id, name, fileFormat, ['bmsbim'], false, file, customerId);
    return result != null;
  }

  async uploadBabylonFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let b = new globalThis.Blob([blob.data], { type: 'application/json' });
    let file = new File([b], name, { type: 'application/json' });

    let result = await this.state.upsertFile('bmsbim_babylon_' + id, name, fileFormat, ['bmsbim'], false, file, customerId);
    return result != null;
  }

  async uploadFloorplanFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let b = new globalThis.Blob([blob.data], { type: 'application/json' });
    let file = new File([b], name, { type: 'application/json' });

    let result = await this.state.upsertFile('bmsbim_floorplan_' + id, name, fileFormat, ['bmsbim'], false, file, customerId);
    return result != null;
  }

  async getBimFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    let result = new BimFileInfo();
    let bimFileInfo = await this.filesClient.getFileInfo('bmsbim_bim_' + id, customerId);

    result.id = bimFileInfo.id;
    result.lastModified = bimFileInfo.lastModified;
    result.name = bimFileInfo.name;

    return result;
  }

  async getBabylonFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    let result = new BimFileInfo();
    let bimFileInfo = await this.filesClient.getFileInfo('bmsbim_babylon_' + id, customerId);

    result.id = bimFileInfo.id;
    result.lastModified = bimFileInfo.lastModified;
    result.name = bimFileInfo.name;

    return result;
  }

  async getFloorplanFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    let result = new BimFileInfo();
    let bimFileInfo = await this.filesClient.getFileInfo('bmsbim_floorplan_' + id, customerId);

    result.id = bimFileInfo.id;
    result.lastModified = bimFileInfo.lastModified;
    result.name = bimFileInfo.name;

    return result;
  }

  async getBimFile(id: string, customerId: string): Promise<Blob> {
    let fileBlob = await this.filesClient.getFile('bmsbim_bim_' + id, customerId);
    return await this.fileToBlob(id, fileBlob);
  }

  async getBabylonFile(id: string, customerId: string): Promise<Blob> {
    let fileBlob = await this.filesClient.getFile('bmsbim_babylon_' + id, customerId);
    return await this.fileToBlob(id, fileBlob);
  }

  async getFloorplanFile(id: string, customerId: string): Promise<Blob> {
    let fileBlob = await this.filesClient.getFile('bmsbim_floorplan_' + id, customerId);
    return await this.fileToBlob(id, fileBlob);
  }

  async deleteBimFile(id : string, customerId : string) : Promise<boolean> {
    let result = await this.filesClient.deleteFileInfo('bmsbim_bim_' + id, customerId)
    return result.result;
  }

  async deleteBabylonFile(id : string, customerId : string) : Promise<boolean> {
    let result = await this.filesClient.deleteFileInfo('bmsbim_babylon_' + id, customerId)
    return result.result;
  }

  async deleteFloorplanFile(id : string, customerId : string) : Promise<boolean> {
    let result = await this.filesClient.deleteFileInfo('bmsbim_floorplan_' + id, customerId)
    return result.result;
  }

  private async fileToBlob(id: string, fileBlob : globalThis.Blob) : Promise<Blob> {
    let result = new Blob();
    result.data = (await fileBlob.stream().getReader().read()).value;
    result.id = id;

    return result;
  }

}
