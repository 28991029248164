import { DateHelper } from './helpers/date-helper-service';

export const cacheTTLSeconds = 30;
export const cacheEnabled = true;

export function prettyPrint(obj : Object, dateHelper : DateHelper) : string
{
    if(obj == null || obj === undefined)
        return "";

    /*if( Array.isArray(obj) )
    {
        let x = obj as Array<any>;
        let rVal = "";
        for(let it in x)
        {
            rVal+= "<span class='label'>"+ it + "</span>";
        }

        return sanitizer.bypassSecurityTrustHtml(rVal);
    }*/



    if(obj instanceof Date)
    {
        return dateHelper.utils.formatByString(obj as Date, "yyyy-MM-dd HH:mm");
    }
    if(obj instanceof Boolean)
    {
        let x = obj as Boolean;
        if(x)
            return "True";
        return "False";

    }

    if( obj instanceof String || typeof obj =='string')
    {
      return obj.toString();
    }

    if( Array.isArray(obj) )
    {
      return obj.toString();
    }

    if( Object.keys(obj).length > 0  )
    {
        let nrKeys = Object.keys(obj).length;
        if(nrKeys == 1)
        {
            return obj[ Object.keys(obj)[0] ];
        }

        return nrKeys.toString() + " keys";
    }


    return obj.toString();
}

