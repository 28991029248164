import { Component, OnInit, Input, Output, EventEmitter   } from '@angular/core';
import { XAUTO_PLCVariableNumericalMapXAutoVariable } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj-editplcvariable',
  templateUrl: './editplcvariable.component.html',
  styleUrls: ['./editplcvariable.component.scss']
})
export class EditplcvariableComponent implements OnInit {

  Variable : XAUTO_PLCVariableNumericalMapXAutoVariable = null;

  @Input() set variable (Variable : XAUTO_PLCVariableNumericalMapXAutoVariable)
  {
    this.Variable = Variable;
  }
  @Output() variableChange =new EventEmitter<XAUTO_PLCVariableNumericalMapXAutoVariable>();

  constructor() { }

  ngOnInit(): void {
  }

}
