import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';

import { XProjectorClient } from './xprojector-client-service';
import { XProjectClientSettings, XPROJECTORCLIENTSETTINGS } from './xprojector-client-settings-service';


@NgModule({
  imports:      [ CommonModule ]
})
export class XProjectorClientModule {
  constructor(@Optional() @SkipSelf() parentModule?: XProjectorClientModule) {
    if (parentModule) {
      throw new Error(
        'XProjectorClientModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: XProjectClientSettings): ModuleWithProviders<XProjectorClientModule> {
    return {
      ngModule: XProjectorClientModule,
      providers: [
        {provide: XPROJECTORCLIENTSETTINGS, useValue: config }
      ]
    };
  }
}
