export class RossakerBmsLatestValue {
  meterId : number;
  customerId : string;
  apartmentId : number;
  facilityId : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;
  gatewayId : number;

  meterType : string;
  meterSubtype : string;

  modifiedAt : Date;

  timestamp : Date;
  value : number;
  unit : string;
}
