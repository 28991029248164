<clr-datagrid #grid class="meter-list" [(clrDgSelected)]="selectedXAutoVars"
  [clrDgRowSelection]="true" [clrDgLoading]="loadingXAutoVars" (clrDgSelectedChange)="selectedXAutoVarsChanged($event)">

  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary" (click)="selectAllXAutoVars()">
        <clr-icon shape="plus"></clr-icon> Select all
      </button>
      <button type="button" class="btn btn-sm btn-danger-outline" (click)="unselectAllXAutoVars()"
        [disabled]="selectedXAutoVars.length == 0">
        <clr-icon shape="trash"></clr-icon> Unselect all
      </button>
    </div>
  </clr-dg-action-bar>

  <clr-dg-column [clrDgField]="'xAutoVarId'" [clrDgSortOrder]="ascSort">
    <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'name'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let xautovar of xedgeXAutoVars" [clrDgItem]="xautovar">
    <clr-dg-cell> {{xautovar.xAutoVarId}}</clr-dg-cell>
    <clr-dg-cell> {{xautovar.name}}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="20">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Files per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} files
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>
