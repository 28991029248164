import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { SpcConfig, SpcQuery, SpcWidgetQueryDistributionConfig, SpcYAxesConfig } from '../spc-widget-config/spc-config-service';
import { Projection, ProjectionColumnDescription, FilterLogicalGroupType, BaseQuery, BaseQueryInputColumnDescription, Transformation, Aggregation, ColumnGroupingDescription, XProjectorClient, LuaQueryColumn, SPCChartType, SPCQueryDistributionConfig } from '../../../XProjector/xprojector-client-service';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { YAxisId } from '../spc-widget-config/spc-config-service';
import { TypedJSON } from 'typedjson';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

export class QueryProjectionData {
  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  xaxis: string = "";
  xaxistransform: Transformation = Transformation.NONE;
  transformInputParameterId : string = '';
  useTransformInputParameter : boolean = false;
  yAxesConfig: SpcYAxesConfig;
  useProjectionInputParameter : boolean;
  projectionInputParameterId : string = '';
  groupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;
  groupInputParameterId: string = '';
  groupInputParameterIds: string[] = [];
  scriptedcolumnspostaggregation : LuaQueryColumn[] = [];
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];
  chartType : SPCChartType;
  stableDistribution : SpcWidgetQueryDistributionConfig;
  nelsonsignals : boolean[];
  colorValue : string;
  colorLimit : string;
  colorNelson : string;
}


@Component({
  selector: 'xproj-spc-widget-query-config',
  templateUrl: './xproj-spc-widget-query-config.component.html',
  styleUrls: ['./xproj-spc-widget-query-config.component.scss']
})
export class XprojSpcWidgetQueryConfigComponent implements OnInit, AfterViewInit {
  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  @Input() widgetQuery: SpcQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgets: WidgetConfig[];
  @Input() widgetConfig: SpcConfig;

  @Output() onQueryRemoved = new EventEmitter<SpcQuery>();

  queryData: QueryProjectionData = new QueryProjectionData();
  selectedYAxis: any = null;
  selectedYAxesConfig : SpcYAxesConfig = null;

  Transformation = Transformation;
  Aggregation = Aggregation;
  YAxisId = YAxisId;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  SPCChartType = SPCChartType;

  constructor(@Inject(LOGGERSERVICE) private logger: XprojLoggerService,
  private xprojClient: XProjectorClient,
  private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.queryData.selectedGroup = this.widgetQuery.Query.targetgroup
    this.queryData.xaxis = this.widgetQuery.Xaxis;
    this.queryData.yAxesConfig = this.widgetQuery.YAxesConfig;

    this.queryData.xaxistransform = this.widgetQuery.XaxisTransform;
    this.queryData.useTransformInputParameter = this.widgetQuery.UseTransformInputParameter;
    this.queryData.transformInputParameterId = this.widgetQuery.TransformInputParameterId;
    this.queryData.useProjectionInputParameter = this.widgetQuery.UseProjectionInputParameter;
    this.queryData.projectionInputParameterId = this.widgetQuery.ProjectionInputParameterId;
    this.queryData.groupSelectionType = this.widgetQuery.GroupSelectionType;
    this.queryData.groupInputParameterId = this.widgetQuery.GroupInputParameterId;
    this.queryData.groupInputParameterIds = this.widgetQuery.GroupInputParameterIds;
    this.queryData.chartType = this.widgetQuery.ChartType;
    this.queryData.stableDistribution = this.widgetQuery.StableDistribution;
    this.queryData.nelsonsignals = this.widgetQuery.Nelsonsignals;
    this.queryData.colorValue = this.widgetQuery.ColorValue;
    this.queryData.colorLimit = this.widgetQuery.ColorLimit;
    this.queryData.colorNelson = this.widgetQuery.ColorNelson;

    this.widgetQuery.Query.scriptedcolumnspostaggregation.forEach(scriptcol => {
      this.queryData.scriptedcolumnspostaggregation.push(TypedJSON.parse(JSON.stringify(scriptcol), LuaQueryColumn));
    })

  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.queryData.projection = this.projections.find(p => p.projectionid == this.widgetQuery.Query.targetprojectionid);

    await this.selectedProjectionGroupChange(this.queryData.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.queryData.selectedGroup = null;
      this.queryData.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.queryData.projection["projectionid"], null);
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.queryData.projection) {
      this.queryData.selectedGroup = group;
      this.queryData.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryData.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.queryData.projection["projectionid"], group);
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group)
      groupstr = group.join(",");
    this.queryData.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);

    this.queryData.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.queryData.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });

    //this.loadingProjectionColumns = false;
  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  addScriptedColumnsPostAggregation() {
    this.queryData.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.queryData.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  updateInputParameters(inputs: WidgetInputParameter[]) {
    this.inputParameters = inputs;
    if (this.projectionFilter) {
      this.projectionFilter.inputParameters = this.inputParameters;
    }
  }

  async SaveQuery() {
    if (this.queryData.xaxis == "") {
      return;
    }

    //convert from literal object to class object
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = 1000;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation;

    let xcol = new BaseQueryInputColumnDescription();
    xcol.columnname = this.queryData.xaxis;
    xcol.columnoutname = "x";

    this.widgetQuery.Query.grouping.columnname = xcol.columnname;
    this.widgetQuery.Query.grouping.columntransformation = this.queryData.xaxistransform;
    this.widgetQuery.Query.grouping.columnoutname = xcol.columnoutname;

    this.widgetQuery.Query.targetprojectionid = this.queryData.projection.projectionid;

    if (this.queryData.yAxesConfig?.ColumnName.length == 0) {
      return;
    }

    let ycol = new BaseQueryInputColumnDescription();
    ycol.columnname = this.queryData.yAxesConfig.ColumnName;

    if (this.queryData.yAxesConfig) {
      if (this.queryData.yAxesConfig.Label?.length > 0) {
        ycol.columnoutname = this.queryData.yAxesConfig.ColumnOutName = this.queryData.yAxesConfig.Label;
      }
      else {
        ycol.columnoutname = this.queryData.yAxesConfig.ColumnOutName = "y0";
      }
    }
    else {
      ycol.columnoutname = "y0";
    }

    ycol.columnaggregation = Aggregation.COUNT; //Dummy

    this.widgetQuery.Query.columns.push(ycol);

    this.widgetQuery.Xaxis = this.queryData.xaxis;
    this.widgetQuery.YAxesConfig = this.queryData.yAxesConfig;

    this.widgetQuery.UseGrouping = true;
    this.widgetQuery.XaxisTransform = this.queryData.xaxistransform;
    this.widgetQuery.UseTransformInputParameter = this.queryData.useTransformInputParameter;
    this.widgetQuery.TransformInputParameterId = this.queryData.transformInputParameterId;
    this.widgetQuery.UseProjectionInputParameter = this.queryData.useProjectionInputParameter;
    this.widgetQuery.ProjectionInputParameterId = this.queryData.projectionInputParameterId;
    this.widgetQuery.GroupSelectionType = this.queryData.groupSelectionType;
    this.widgetQuery.GroupInputParameterId = this.queryData.groupInputParameterId;
    this.widgetQuery.GroupInputParameterIds = this.queryData.groupInputParameterIds;
    this.widgetQuery.ChartType = this.queryData.chartType;
    this.widgetQuery.StableDistribution = this.queryData.stableDistribution;
    this.widgetQuery.Nelsonsignals = this.queryData.nelsonsignals;
    this.widgetQuery.ColorValue = this.queryData.colorValue;
    this.widgetQuery.ColorLimit = this.queryData.colorLimit;
    this.widgetQuery.ColorNelson = this.queryData.colorNelson;

    if (this.groupSelect) {
      this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
    }
  }
}

