<div>
	<div style="display: inline;">
		<clr-dropdown>
			<button class="btn btn-outline-primary" clrDropdownTrigger>
				{{logicalGroupTypeKeys[filterLogicalGroupType].title}}
			</button>
			<clr-dropdown-menu>
				<div clrDropdownItem (click)="filterLogicalGroupType = 0">AND</div>
				<div clrDropdownItem (click)="filterLogicalGroupType = 1">OR</div>
			</clr-dropdown-menu>
		</clr-dropdown>
	</div>
	<div *ngFor="let datafilter of datafilters" style="display: inline;">
		<xproj-data-filter class="data-filter" [datafilter]="datafilter" [columnDescriptions]="this.columnDescriptions"
			[editMode]="false" [group]="group" [inputParameters]="inputParameters" [widgets]="widgets" (onDelete)="onDataFilterDelete($event)"></xproj-data-filter>
	</div>
	<button type="button" class="data-filter btn btn-link" (click)="newDataFilter()">
		<clr-icon shape="plus-circle"></clr-icon>
		Add filter
	</button>
</div>
