import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UploadResult } from 'ngx-markdown-editor';
import { Subscription } from 'rxjs';
import { TypedJSON } from 'typedjson';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { ProjectionDataeditorWidgetUtils } from '../../projection-dataeditor/projection-dataeditor-utils-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';
import { XprojWidgetService } from '../../xproj-widget-service';
import { TextColumnConfig, TextWidgetConfig } from './text-widget-config-service';

@Component({
  selector: 'xproj-text-widget-config',
  templateUrl: './xproj-text-widget-config.component.html',
  styleUrls: ['./xproj-text-widget-config.component.scss']
})
export class XprojTextWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: TextWidgetConfig;

  editConfig: TextWidgetConfig;

  projection: Projection;
  projections: Projection[] = [];
  columns: ProjectionColumnDescription[] = [];
  queryableSelectedGroup: string[] = [];
  selectedColumnConfig: any = null;
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];
  inputParameters: WidgetInputParameter[] = [];

  options = {
    showPreviewPanel: false,
    showBorder: true,          // Show editor component's border. Default is true
    hideIcons: ['FullScreen'],     // ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen']. Default is empty
    usingFontAwesome5: false,   // Using font awesome with version 5, Default is false
    scrollPastEnd: 0,        // The option for ace editor. Default is 0
    enablePreviewContentClick: false,  // Allow user fire the click event on the preview panel, like href etc. Default is false
    resizable: true,           // Allow resize the editor
    //markedjsOpt?: MarkedjsOption  // The markedjs option, see https://marked.js.org/#/USING_ADVANCED.md#options
    // customRender?: {              // Custom markedjs render
    //   image?: Function     // Image Render
    //   table?: Function     // Table Render
    //   code?: Function      // Code Render
    //   listitem?: Function  // Listitem Render
    // }
  }

  mode = 'editor';


  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  XDataType = XDataType;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
    this.postRenderFunc = this.postRenderFunc.bind(this);
    this.preRenderFunc = this.preRenderFunc.bind(this);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as TextWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.editConfig.ConfigQuery.ProjectionId);
    this.selectedProjectionGroupChange(this.editConfig.ConfigQuery.Query.targetgroup);

    this.updateInputParameters();
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection.projectionid, null);
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection.projectionid, group);
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);

    this.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.editConfig.ConfigQuery.ColumnConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }

  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.editConfig.ConfigQuery.ColumnConfigs];
    this.editConfig.ConfigQuery.ColumnConfigs = [];
    this.cdr.detectChanges();
    this.editConfig.ConfigQuery.ColumnConfigs = copy;
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.editConfig.ConfigQuery.ColumnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;

  }

  onColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.editConfig.ConfigQuery.ColumnConfigs.length) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype = column.datatype;
      this.editConfig.ConfigQuery.ColumnConfigs[index].Writeable = column.writable;
    }
    else {
      let column = this.preQueryColumnConfigs.find(col => col.columnname == $event);
      if (column) {
        this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype = column.columnConfig.Datatype;
      }
      else {
        let column = this.editConfig.ConfigQuery.Scriptedcolumnspostaggregation.find(col => 'script:' + col.columnoutname == $event);
        if (column) {
          this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype = column.datatypeout;
          if (this.editConfig.ConfigQuery.ColumnConfigs[index].Label.length == 0) {
            this.editConfig.ConfigQuery.ColumnConfigs[index].Label = column.columnoutname;
          }
        }
      }
    }

  }

  addAllColumns() {
    //this.queryData.columnConfigs.push(new ColumnConfig());

    for (let col of this.columns) {
      let t = new TextColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      t.Writeable = col.writable;

      this.editConfig.ConfigQuery.ColumnConfigs.push(t);
    }

  }
  removeAllColumns() {
    this.editConfig.ConfigQuery.ColumnConfigs = [];
  }

  addColumn() {
    this.editConfig.ConfigQuery.ColumnConfigs.push(new TextColumnConfig());
  }

  addScriptedColumnsPostAggregation() {
    this.editConfig.ConfigQuery.Scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.editConfig.ConfigQuery.Scriptedcolumnspostaggregation = this.editConfig.ConfigQuery.Scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  async onSaveConfig() {

    this.editConfig.ConfigQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.editConfig.ConfigQuery.Query = this.projectionFilter.GetQuery();
    this.editConfig.ConfigQuery.DataFilters = this.projectionFilter.datafilters;
    this.editConfig.ConfigQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.editConfig.ConfigQuery.Query.maxitems = 1;
    this.editConfig.ConfigQuery.Query.scriptedcolumnspostaggregation = this.editConfig.ConfigQuery.Scriptedcolumnspostaggregation;

    this.editConfig.ConfigQuery.ProjectionId = this.projection?.projectionid;
    this.editConfig.ConfigQuery.Group = this.editConfig.ConfigQuery.Query.targetgroup;
    this.editConfig.ConfigQuery.Query.targetprojectionid = this.editConfig.ConfigQuery.ProjectionId;

    if (this.editConfig.ConfigQuery.ColumnConfigs.length > 0) {
      let colindex = 0;
      for (let column of this.editConfig.ConfigQuery.ColumnConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = column.ColumnName;
        }

        if (colindex == 0) {
          if (!this.editConfig.ConfigQuery.UseGrouping) {
            this.editConfig.ConfigQuery.Query.sorting.columnname = col.columnname;
            this.editConfig.ConfigQuery.Query.sorting.descending = false;
            this.editConfig.ConfigQuery.Query.grouping = new ColumnGroupingDescription();
            this.editConfig.ConfigQuery.Query.columns.push(col);
          }
          else {
            this.editConfig.ConfigQuery.Query.grouping.columnname = col.columnname;
            this.editConfig.ConfigQuery.Query.grouping.columntransformation = this.editConfig.ConfigQuery.GroupingTransform;
            this.editConfig.ConfigQuery.Query.grouping.columnoutname = col.columnoutname;
          }
        }
        else {
          if (this.editConfig.ConfigQuery.UseGrouping) {
            col.columnaggregation = column.Transform;
          }
          this.editConfig.ConfigQuery.Query.columns.push(col);
        }

        colindex++;
      }

      if (this.groupSelect) {
        this.editConfig.ConfigQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }
    this.widgetConfig.update(this.editConfig);
  }

  doUpload(files: Array<File>): Promise<Array<UploadResult>> {
    //console.log(files);
    let that = this;
    return new Promise((resolve, reject) => {
        let result: Array<UploadResult> = [];
        for (let file of files) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            //console.log(reader.result);
            result.push({
              name: file.name,
              url: reader.result as string,
              isImg: file.type.indexOf('image') !== -1
            })
            resolve(result);
          };
          reader.onerror = function (error) {
            that.logger.error('Error: ', error);
          };

        }
    });
  }

  preRenderFunc(content: string) {
    //console.log('preRenderFunc');
    return content;
  }

  postRenderFunc(content: string) {
    //console.log('postRenderFunc', content);
    this.editConfig.Html = content;
    return content;
  }

  onPreviewDomChanged($event) {
    //console.log('onPreviewDomChanged');
  }

  onEditorLoaded($event) {
    //console.log('onEditorLoaded');
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  updateInputParameters() {
    if (this.inputParameters) {
      let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
      this.inputParameters.length = 0;
      inputParameters.forEach(inputParam => this.inputParameters.push(inputParam));
    }
    else {
      this.inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    }

  }
}
