<div class="dashboard-area">


    <!-- <a id="download-image" download="ChartImage.jpg"></a> -->


    <!-- style=" display: flex;; border: 3px solid !important;" -->
    <div *ngFor="let widgetConfig of gridWidgetConfigs">
        <!-- <clr-signpost *ngIf="widgetConfig?.Tooltip">
            <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                {{ widgetConfig.Tooltip }}
            </clr-signpost-content>
        </clr-signpost> -->

        <!-- <span [class]="((widgetConfig.IsSensitive||widgetConfig.TitleIsSensitive)?'sensitive ': ' ') + ((widgetConfig.ShowTitle || widgetConfig == markedWidgetConfig) ? 'title-widget-show' : editMode ? 'title-widget-edit-hide' : 'title-widget-hide')">
            {{widgetConfig.DisplayTitle.length
            > 0 ? widgetConfig.DisplayTitle : ' '}}</span> -->

        <!-- [style.width.px]="get_width(widgetConfig.cols)" [style.minimum-height.px]="get_minimum_height(widgetConfig.rows)" -->
        <div [style.width]="get_width(widgetConfig.cols)">
            <!-- [widgetwidth]="get_width(widgetConfig.cols)"  -->
            <xproj-widget [widgetheight]="get_minimum_height(widgetConfig.rows)" [config]="widgetConfig" style="height:100% !important" [responsive]="true" [zoom]="true" [dashboardConfig]="dashboardConfig" (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
            </xproj-widget>
        </div>

    </div>
</div>