import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeLogObject } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subscription, interval, map, switchMap } from 'rxjs';
import { DateHelper } from 'xproj-lib';
import * as XLSX from 'xlsx';

export class NodeLog {
  eventType : number;
  timestamp : Date;
  message : string;

  constructor(eventType : number, timestamp : Date, message : string) {
    this.eventType = eventType;
    this.timestamp = timestamp;
    this.message = message;
  }
}


@Component({
  selector: 'app-view-node-logs',
  templateUrl: './view-node-logs.component.html',
  styleUrls: ['./view-node-logs.component.scss']
})
export class ViewNodeLogsComponent implements OnInit, OnDestroy {

  _node: GrpcNode;
  @Input()
  get node(): GrpcNode {
    return this._node;
  }
  set node(node: GrpcNode) {
    this._node = node;
  }

  loading : boolean = false;
  lastReadId : string = '';

  nodeLogs : NodeLog[] = [];

  readLogsTimerSource$: BehaviorSubject<number>;
  readLogsTimerSubscription : Subscription;

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;

  constructor(
    private xConfClient: XProjectorXConfClient,
    private dateHelper: DateHelper,
    private logger: NGXLogger
) { }

  ngOnInit() {
    this.readNodeLogs();

    if (!this.readLogsTimerSource$) {
      this.readLogsTimerSource$ = new BehaviorSubject(5000);

      this.readLogsTimerSubscription = this.readLogsTimerSource$.pipe(switchMap(val => interval(val).pipe(map((x) => {
        this.readNodeLogs();
      })))).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.readLogsTimerSource$?.unsubscribe();
    this.readLogsTimerSubscription?.unsubscribe();
  }

  async readNodeLogs() {
    if (this.node) {
      try {
        this.loading =true;
        let logs = await this.xConfClient.getNodeLogs(this.node.id, this.node.nodeTypeLabel, this.lastReadId);
        if (logs.length > 0) {
          this.lastReadId = logs[logs.length-1].id;
          logs.forEach(x => {
            this.nodeLogs.push(new NodeLog(x.eventType, x.timestamp.toDate(), x.message));
          });
        }
      }
      finally {
        this.loading = false;
      }
    }
  }

  formatDate(date: Date, displayUTC: boolean = false): string {
    return this.dateHelper.utils.formatByString(displayUTC ? this.dateHelper.utils.addMinutes(date, -date.getTimezoneOffset()) : date, "yyyy-MM-dd HH:mm:ss");
  }

  export() {
    var headers: string[] = ['eventType', 'timestamp', 'message'];
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.nodeLogs, { header: headers });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    let filename : string = (this.node.name?.length > 0 ? this.node.name : this.node.id) + '_logs.xlsx';

  XLSX.writeFile(wb, filename);
  }
}
