import 'reflect-metadata';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { OffsetType, ProjectionColumnDescription, RelativeTimestampOrigo } from '../../XProjector/xprojector-client-service';


@jsonObject
export class DataFilter {
  @jsonMember
  public CustomDisplayname: string;

  @jsonMember
  public ColumnDescriptor: ProjectionColumnDescription = new ProjectionColumnDescription();

  @jsonMember
  public Comparator: string = '0';

  @jsonMember
  public InputParameterId: string = '';

  @jsonMember
  public InputParameterId2: string = '';

  @jsonMember
  public InputParameterId3: string = '';

  @jsonMember
  public Value: string = '';

  @jsonMember
  public NumericValue: number;

  @jsonMember
  public DateValue: Date;

  @jsonMember
  public DateTypeRelative : boolean = false;

  @jsonMember
  public DateRelativeOrigo : RelativeTimestampOrigo = RelativeTimestampOrigo.NOW;

  @jsonMember
  public DateRelativeOffet : number = 0;

  @jsonMember
  public RelativeOffsettype : OffsetType = OffsetType.DAY;
}
