export enum XbotExecutionStatus {
  WAITING = 0,
  CANCEL = 1,
  INPROGRESS = 2,
  STATUS = 3,
  DONE = 4
}

export enum XbotExecutionResult {
  PENDING = 0,
  OK = 1,
  CANCELED = 2,
  ERROR = 3,
  STATUS = 4
}

export class XbotExecutionQueueItem {
  id : number;
  userId : string;
  botId : string;
  timeCreate : Date;
  status : XbotExecutionStatus = XbotExecutionStatus.WAITING;
  data : string;
  priority : number;
  timeStop : Date;
  message : string;
  timeUpdate : Date;
  caller : string;
  result : XbotExecutionResult = XbotExecutionResult.PENDING;
  timeStart : Date;
}

export class XbotExecutionQueueItemResult {
  id : number;
  status : XbotExecutionStatus = XbotExecutionStatus.WAITING;
  timeStop : Date;
  message : string;
  timeUpdate : Date;
  result : XbotExecutionResult = XbotExecutionResult.PENDING;
  timeStart : Date;
}
