import { Injectable } from '@angular/core';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, FilterComparator, FilterLogicalGroupType, Transformation, XProjectorClient } from '../XProjector/xprojector-client-service';

@Injectable({
  providedIn: 'root'
})
export class XAutoService {


  constructor(private xprojClient: XProjectorClient) {
  }

  async GetProcessGraphsList(seekoffset: number, maxitems: number): Promise<{ id: string, name: string }[]> {
    let result = [];

    let graphs = await this.xprojClient.XAUTO_GetProcessGraphs(seekoffset, maxitems);

    graphs.forEach(x => {
      result.push({ id: x.xautoprocessgraphid, name: x.name });
    });

    return result;
  }

  async getXAutoGroups(): Promise<string[]> {
    let sQuery = new BaseQuery();
    let cols = ["XAUTOGROUP"];
    sQuery.targetprojectionid = "XAUTO_VARS";
    sQuery.maxitems = 1000000;
    for (let col of cols) {
      let sCol = new BaseQueryInputColumnDescription();
      sCol.columnaggregation = Aggregation.NONE;
      sCol.columnname = col;
      sCol.columnoutname = col;
      sCol.columntransformation = Transformation.NONE;
      sQuery.columns.push(sCol);
    }

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 0;
    deletedFilter.value = 0;
    sQuery.filter.filters.push(deletedFilter.queryfilterid);
    sQuery.numericalfilters.push(deletedFilter);

    let result: string[] = [];
    let allXAutoGroups = await this.xprojClient.RequestQueryBaseQuery(sQuery, false, "editprocessgraphs", "nah");

    result = allXAutoGroups.datastrings[0];

    return result.filter((n, i) => result.indexOf(n) === i);
  }

  async getXGroups(xautogroup: string): Promise<string[]> {
    let sQuery = new BaseQuery();
    let cols = ["XGROUP"];
    sQuery.targetprojectionid = "XAUTO_VARS";
    sQuery.maxitems = 1000000;
    for (let col of cols) {
      let sCol = new BaseQueryInputColumnDescription();
      sCol.columnaggregation = Aggregation.NONE;
      sCol.columnname = col;
      sCol.columnoutname = col;
      sCol.columntransformation = Transformation.NONE;
      sQuery.columns.push(sCol);
    }

    sQuery.filter.type = FilterLogicalGroupType.AND;

    let xuatogroupFilter = new ColumnFilteringString();
    xuatogroupFilter.columnname = "XAUTOGROUP";
    xuatogroupFilter.comparator = FilterComparator.Equals;
    xuatogroupFilter.queryfilterid = 0;
    xuatogroupFilter.value = xautogroup;
    sQuery.filter.filters.push(xuatogroupFilter.queryfilterid);
    sQuery.stringfilters.push(xuatogroupFilter);

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 1;
    deletedFilter.value = 0;
    sQuery.filter.filters.push(deletedFilter.queryfilterid);
    sQuery.numericalfilters.push(deletedFilter);

    let result: string[] = [];
    let allXGroups = await this.xprojClient.RequestQueryBaseQuery(sQuery, false, "editprocessgraphs", "nah");

    result = allXGroups.datastrings[0];

    return result.filter((n, i) => result.indexOf(n) === i);
  }

  async getXAutoVariableIds(xautogroup: string, xgroup: string): Promise<{ id: string, name: string }[]> {
    let sQuery = new BaseQuery();
    let cols = ["XAUTOVARID", "NAME"];
    sQuery.targetprojectionid = "XAUTO_VARS";
    sQuery.maxitems = 1000000;
    for (let col of cols) {
      let sCol = new BaseQueryInputColumnDescription();
      sCol.columnaggregation = Aggregation.NONE;
      sCol.columnname = col;
      sCol.columnoutname = col;
      sCol.columntransformation = Transformation.NONE;
      sQuery.columns.push(sCol);
    }

    sQuery.filter.type = FilterLogicalGroupType.AND;

    let xuatogroupFilter = new ColumnFilteringString();
    xuatogroupFilter.columnname = "XAUTOGROUP";
    xuatogroupFilter.comparator = FilterComparator.Equals;
    xuatogroupFilter.queryfilterid = 0;
    xuatogroupFilter.value = xautogroup;
    sQuery.filter.filters.push(xuatogroupFilter.queryfilterid);
    sQuery.stringfilters.push(xuatogroupFilter);

    let xgroupFilter = new ColumnFilteringString();
    xgroupFilter.columnname = "XGROUP";
    xgroupFilter.comparator = FilterComparator.Equals;
    xgroupFilter.queryfilterid = 1;
    xgroupFilter.value = xgroup;
    sQuery.filter.filters.push(xgroupFilter.queryfilterid);
    sQuery.stringfilters.push(xgroupFilter);

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 2;
    deletedFilter.value = 0;
    sQuery.filter.filters.push(deletedFilter.queryfilterid);
    sQuery.numericalfilters.push(deletedFilter);

    let result = [];
    let allXVariableIds = await this.xprojClient.RequestQueryBaseQuery(sQuery, false, "editprocessgraphs", "nah");

    result = [];

    for (var i = 0; i < allXVariableIds.datastrings[0].length; i++) {
      result.push({ id: allXVariableIds.datastrings[0][i], name: allXVariableIds.datastrings[1][i] });
    }

    return result;
  }

  async getXNames(): Promise<string[]> {
    let sQuery = new BaseQuery();
    let cols = ["NAME"];
    sQuery.targetprojectionid = "XAUTO_VARS";
    sQuery.maxitems = 1000000;
    for (let col of cols) {
      let sCol = new BaseQueryInputColumnDescription();
      sCol.columnaggregation = Aggregation.NONE;
      sCol.columnname = col;
      sCol.columnoutname = col;
      sCol.columntransformation = Transformation.NONE;
      sQuery.columns.push(sCol);
    }

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 0;
    deletedFilter.value = 0;
    sQuery.filter.filters.push(deletedFilter.queryfilterid);
    sQuery.numericalfilters.push(deletedFilter);

    let result: string[] = [];
    let allXNames = await this.xprojClient.RequestQueryBaseQuery(sQuery, false, "editprocessgraphs", "nah");

    result = allXNames.datastrings[0];

    return result.filter((n, i) => result.indexOf(n) === i);
  }

  async getXAutoVariableID(xautogroup: string, xgroup: string, xname: string): Promise<string> {
    let query = new BaseQuery();
    query.maxitems = 2;
    query.targetprojectionid = "XAUTO_VARS";
    let col = new BaseQueryInputColumnDescription();
    col.columnname = "XAUTOVARID";
    col.columnoutname = "XAUTOVARID";
    col.columnaggregation = Aggregation.NONE;
    col.columntransformation = Transformation.NONE;
    query.columns.push(col);
    query.filter.type = FilterLogicalGroupType.AND;
    if (xautogroup?.length > 0) {
      let filter = new ColumnFilteringString();
      filter.comparator = FilterComparator.Equals;
      filter.value = xautogroup;
      filter.queryfilterid = 1;
      filter.columnname = "XAUTOGROUP";
      query.stringfilters.push(filter);
      query.filter.filters.push(filter.queryfilterid);
    }
    if (xgroup?.length > 0) {
      let filter = new ColumnFilteringString();
      filter.comparator = FilterComparator.Equals;
      filter.value = xgroup;
      filter.queryfilterid = 2;
      filter.columnname = "XGROUP";
      query.stringfilters.push(filter);
      query.filter.filters.push(filter.queryfilterid);
    }
    if (xname?.length > 0) {
      let filter = new ColumnFilteringString();
      filter.comparator = FilterComparator.Equals;
      filter.value = xname;
      filter.queryfilterid = 3;
      filter.columnname = "NAME";
      query.stringfilters.push(filter);
      query.filter.filters.push(filter.queryfilterid);
    }

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 4;
    deletedFilter.value = 0;
    query.filter.filters.push(deletedFilter.queryfilterid);
    query.numericalfilters.push(deletedFilter);

    let vars = await this.xprojClient.RequestQueryBaseQuery(query);
    if (vars.nrpoints == 0) {
      console.log("Could not match any variables for", xautogroup, xgroup, xname);
      return null;
    }
    if (vars.nrpoints > 1) {
      console.log("Duplicate variables matching", xautogroup, xgroup, xname);
      return null;
    }
    return vars.datastrings[0][0];
  }


  async getXAutoVariableMapping(xautovarid: string): Promise<{ xautogroup: string, xgroup: string }[]> {
    let query = new BaseQuery();
    query.maxitems = 2;
    query.targetprojectionid = "XAUTO_VARS";

    let colxautogroup = new BaseQueryInputColumnDescription();
    colxautogroup.columnname = "XAUTOGROUP";
    colxautogroup.columnaggregation = Aggregation.NONE;
    colxautogroup.columntransformation = Transformation.NONE;
    query.columns.push(colxautogroup);
    let colxgroup = new BaseQueryInputColumnDescription();
    colxgroup.columnname = "XGROUP";
    colxgroup.columnoutname = "XGROUP";
    colxgroup.columnaggregation = Aggregation.NONE;
    colxgroup.columntransformation = Transformation.NONE;
    query.columns.push(colxgroup);

    query.filter.type = FilterLogicalGroupType.AND;

    let filter = new ColumnFilteringString();
    filter.comparator = FilterComparator.Equals;
    filter.value = xautovarid;
    filter.queryfilterid = 1;
    filter.columnname = "XAUTOVARID";
    query.stringfilters.push(filter);
    query.filter.filters.push(filter.queryfilterid);

    let deletedFilter = new ColumnFilteringNumerical();
    deletedFilter.columnname = "DELETED";
    deletedFilter.comparator = FilterComparator.Equals;
    deletedFilter.queryfilterid = 2;
    deletedFilter.value = 0;
    query.filter.filters.push(deletedFilter.queryfilterid);
    query.numericalfilters.push(deletedFilter);

    let vars = await this.xprojClient.RequestQueryBaseQuery(query);

    let result = [];
    for (let i = 0; i < vars.nrpoints; i++) {
      let xautogroup = "";
      let xgroup = "";
      for (let col of vars.columns) {
        if (col.columnname == "XAUTOGROUP") {
          xautogroup = vars.datastrings[col.indexintypedvector][0];
          continue;
        }
        if (col.columnname == "XGROUP") {
          xgroup = vars.datastrings[col.indexintypedvector][0];
          continue;
        }
      }
      result.push({ xautogroup: xautogroup, xgroup: xgroup });
    }

    return result;
  }

}

