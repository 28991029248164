import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { config } from 'rxjs';
import { DashboardConfig, GridSettings } from '../../dashboard/xproj-dashboard-service';
import { ColumnFilteringRelativeTimestamp } from '../../XProjector/xprojector-client-service';
import { WidgetConfig } from '../widget-config-service';

@Component({
  selector: 'xproj-widget',
  templateUrl: './xproj-widget.component.html',
  styleUrls: ['./xproj-widget.component.scss']
})
export class XprojWidgetComponent implements OnInit {
  @Input() config: WidgetConfig;
  @Input() widgetheight: number;
  @Input() widgetwidth: number;

  @Input() responsive: boolean = false;
  @Input() from: Date = null;
  @Input() to: Date = null;
  @Input() relativeTimestamp: ColumnFilteringRelativeTimestamp;
  @Input() zoom: boolean = false;
  @Input() dashboardConfig: DashboardConfig;

  @Output() onConfigChanged = new EventEmitter<WidgetConfig>();
  @Output() onBeforeInit = new EventEmitter<WidgetConfig>();
  @Output() onAfterInit = new EventEmitter<WidgetConfig>();


  @Input() showConfig: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onBeforeWidgetInit(config) {
    this.onBeforeInit?.next(config);
  }

  onAfterWidgetInit(config) {
    this.onAfterInit?.next(config);
  }

}
