import { Injectable } from '@angular/core';

import { xprojCommonStringsDefault } from './xproj-common-strings.default';
import { XprojCommonStrings } from './xproj-common-strings.interface';

@Injectable({
  providedIn: 'root',
})
export class XprojCommonStringsService {
  private strings = xprojCommonStringsDefault;

  localize(overrides: Partial<XprojCommonStrings>) {
    this.strings = { ...this.strings, ...overrides };
  }

  get keys(): Readonly<XprojCommonStrings> {
    return this.strings;
  }

  parse(source: string, tokens: { [key: string]: string } = {}) {
    const names = Object.keys(tokens);
    let output = source;
    if (names.length) {
      names.forEach(name => {
        output = output.replace(`{${name}}`, tokens[name]);
      });
    }
    return output;
  }
}
