import { BaseQuery, FilterLogicalGroupType, Transformation, Aggregation } from '../../../XProjector/xprojector-client-service';
import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { GroupSelectionTypes, WidgetConfig, WidgetPreQueryConfig } from '../../widget-config-service';
import * as uuid from 'uuid';
import { Guid } from '../../../utils/guid-service';

export enum YAxisId
{
	LEFT         = 0,
	RIGHT        = 1
}

@jsonObject
export class LineConfig {
  @jsonMember
  public Name : string;

  @jsonMember
  public Value : number;

  @jsonMember
	public UseValueInputParameter : boolean = false;

  @jsonMember
  public ValueInputParameterId: string = '';

  @jsonMember
  public Horizontal : boolean = true;

  @jsonMember
	public Infront : boolean = true;

	@jsonMember
	public AxisId : YAxisId = YAxisId.LEFT;

  @jsonMember
  public Color : string = 'red';

  @jsonMember
  public Width : number = 2;

  @jsonArrayMember(Number)
  public LineDash : number[] = [];

  @jsonMember
  public ShowLabel : boolean = false;

  @jsonMember
  public LabelBackgroufColor : string = 'red';

  @jsonMember
  public LabelPosition : string = 'center'; //top, bottom, left, right, center

}


@jsonObject
export class QueriedLineConfig {
  @jsonMember
  public Name : string;

  @jsonMember
  public Horizontal : boolean = true;

  @jsonMember
	public Infront : boolean = true;

	@jsonMember
	public AxisId : YAxisId = YAxisId.LEFT;

  @jsonMember
  public Color : string = 'red';

  @jsonMember
  public Width : number = 2;

  @jsonArrayMember(Number)
  public LineDash : number[] = [];

  @jsonMember
  public ShowLabel : boolean = false;

  @jsonMember
  public LabelBackgroufColor : string = 'red';

  @jsonMember
  public LabelPosition : string = 'center'; //top, bottom, left, right, center

  @jsonMember
  public Query : WidgetPreQueryConfig = new WidgetPreQueryConfig();
}


@jsonObject
export class YAxesConfig {
  @jsonMember
	public ColumnName : string = '';

	@jsonMember
	public ColumnOutName : string = '';

	@jsonMember
	public Label : string = '';

	@jsonMember
	public AxisId : YAxisId = YAxisId.LEFT;

	@jsonMember
	public Type : string = 'line';

	@jsonMember
  public Unit : string = '';

  @jsonMember
	public UseUnitInputParameter : boolean = false;

  @jsonMember
  public UnitInputParameterId: string = '';

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
	public Fill : boolean = false;

  @jsonMember
	public BorderWidth : number = 2;

  @jsonMember
	public BorderRadius : number = 0;

  @jsonArrayMember(Number)
	public BorderDash : number[] = [];

  @jsonMember
  public UseColorSchema : boolean = true;

  @jsonMember
  public BorderColor : string = '';

  @jsonMember
  public BackgroundColor : string = '';

  @jsonMember
  public PointBorderColor : string = '';

  @jsonMember
  public PointBackgroundColor : string = '';

  @jsonMember
	public PointRadius : number = 3;

  @jsonMember
	public Tension : number = 0;

  @jsonMember
  public HideIfEmpty : boolean = false;

  @jsonMember
	public Transformation : Transformation = Transformation.NONE;

  @jsonMember
  public Stepped : boolean = false;

  @jsonMember
  public SteppedMode : string = 'before';
}

@jsonObject
export class ChartWidgetQuery {
  public Id : string = Guid.newGuid();

  @jsonMember
  public Name : string = '';

	@jsonMember
	public Query : BaseQuery = new BaseQuery();

	@jsonMember
	public Xaxis : string = '';

  @jsonMember
  public SplitBy : string = null;

  @jsonMember
  public SampleData : boolean = false;

  @jsonMember
  public SampleLength : number = 1000;

  @jsonMember
  public StackLeft : boolean = false;

  @jsonMember
  public StackRight : boolean = false;

	@jsonMember
	UseGrouping : boolean = false;

  @jsonMember
  public MaxItems : number = 1000;

	@jsonMember
  XaxisTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

	@jsonArrayMember(String)
	public Yaxises : string[] = [];

	@jsonArrayMember(Number)
	YaxisesTransforms: Aggregation[] = [];

	@jsonArrayMember(YAxesConfig)
	public YAxesConfigs : YAxesConfig[] = [];

	@jsonArrayMember(DataFilter)
	public DataFilters : DataFilter[] = [];

	@jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  // Stuff not to save
  public sampledSeries = {};

}


@jsonObject
export class ChartWidgetMinMaxSettings {
  @jsonMember
  public UseMinMaxLeft : boolean = false;

  @jsonMember
  public SuggestedMinMaxLeft : boolean = true;

  @jsonMember
	public MinLeft : number = 0;

  @jsonMember
	public MaxLeft : number = 100;

  @jsonMember
  public UseMinMaxRight : boolean = false;

  @jsonMember
  public SuggestedMinMaxRight : boolean = true;

  @jsonMember
	public MinRight : number = 0;

  @jsonMember
	public MaxRight : number = 100;
}

@jsonObject
export class ChartWidgetStreaming {
  @jsonMember
	public Enabled : boolean = false;

  @jsonMember
	public Duration : number = 30000;

  @jsonMember
	public Delay : number = 1000;

  @jsonMember
	public Refresh : number = 1000;

  @jsonMember
	public FrameRate : number = 20;

  @jsonMember
	public Ttl : number = undefined;

  @jsonMember
	public ShowPlayPauseButton : boolean = false;

  // @jsonMember
	// public AddOnlyNewValues : boolean = true;

}

@jsonObject
export class ChartWidgetConfig extends WidgetConfig{

	@jsonMember
	public Type : string = 'line';

  @jsonMember
	public DisableZoom : boolean = false;

  @jsonMember
	public AnimationsMs : number = 0;

  @jsonMember
	public AnimationsEasing : string = 'linear';

	@jsonArrayMember(ChartWidgetQuery)
	public ConfigQueries : ChartWidgetQuery[] = [];

	@jsonArrayMember(LineConfig)
	public LineConfigs : LineConfig[] = [];

  @jsonArrayMember(QueriedLineConfig)
	public QueriedLineConfigs : QueriedLineConfig[] = [];

  @jsonMember
	public GroupQueriesByName : boolean = false;

  @jsonMember
  public ColorScheme : string = '';

  @jsonMember
  public LeftYAxisType : string = 'linear';

  @jsonMember
  public RightYAxisType : string = 'linear';

  @jsonMember
	public Streaming : ChartWidgetStreaming = new ChartWidgetStreaming();

  @jsonMember
  public InteractionMode : string = 'nearest';

  @jsonMember
  public InteractionIntersect : boolean = false;

  @jsonMember
  public InteractionAxis : string = '';

  @jsonMember
  public MinMaxSettings : ChartWidgetMinMaxSettings = new ChartWidgetMinMaxSettings();

	public constructor() {
		super();
		this.Type = 'line';
	}

	public Clone() : ChartWidgetConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, ChartWidgetConfig), ChartWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = true;
}
