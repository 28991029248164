<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid limit-width">
    <clr-dg-column>Id</clr-dg-column>
    <!-- <clr-dg-column>Labels</clr-dg-column> -->

    <clr-dg-row *ngFor="let dataSourceDefinition of dataSourceDefinitions; let i = index" [clrDgItem]="dataSourceDefinition">
        <clr-dg-cell>
            <!-- <input class="clr-input" placeholder="" name="nodeType_id_{{i}}" id=nodetype_id{{i}}
        [(ngModel)]="nodeType.id" /> -->
            {{dataSourceDefinition.id}}
        </clr-dg-cell>

        <!-- <clr-dg-cell>
      {{nodeType.labels.join(", ")}}
    </clr-dg-cell> -->

    </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addDataSourceDefinition()">
  <clr-icon shape="plus-circle"></clr-icon>
  Add data source definition
</button>
<button *ngIf="selected && !selected.systemDefined" type="button" class="btn btn-link" (click)="deleteSelected()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected data source
</button>
<button *ngIf="selected && !selected.systemDefined" type="button" class="btn btn-link" (click)="visualize()">
  <clr-icon shape="animation"></clr-icon>
  Visualize selected data source
</button>

<div *ngIf="selected">
    <div class="card limit-width">
        <div class="card-header">
            Edit data source definition ({{selected.id}})
        </div>

        <div class="card-block">
            <form clrForm>
                <clr-input-container>
                    <label>Id</label>
                    <input clrInput placeholder="" name="id" [(ngModel)]="selected.id" />
                </clr-input-container>

                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox [disabled]="selected.systemDefined" [(ngModel)]="selected.customerInstance" name="customerInstance" />
                        <label>Create customer instance</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>

                <clr-combobox-container>
                    <label>Root nodetypes</label>
                    <clr-combobox [(ngModel)]="selected.rootNodeTypeIds" name="rootNodetypesSelect" clrMulti="true" required>
                        <ng-container *clrOptionSelected="let selectedNode">
                            <clr-icon shape="node" role="img" aria-label="root node"></clr-icon> {{selectedNode}}
                        </ng-container>
                        <clr-options>
                            <clr-option *clrOptionItems="let nodetype of nodeTypes; field:'id'" [clrValue]="nodetype.id">
                                <clr-icon shape="node" role="img" aria-label="node type"></clr-icon> {{nodetype.displayableId}}
                            </clr-option>
                        </clr-options>
                    </clr-combobox>
                </clr-combobox-container>

            </form>

            <clr-datagrid [(clrDgSingleSelected)]="selectedTreenode" class="grid">
                <clr-dg-column>Id</clr-dg-column>

                <clr-dg-row *ngFor="let treenode of selected.treeNodeDefinitions; let i = index" [clrDgItem]="treenode">
                    <clr-dg-cell>
                        {{treenode.nodeTypeId}}
                    </clr-dg-cell>

                </clr-dg-row>
            </clr-datagrid>

            <button type="button" class="btn btn-link" (click)="addTreenode()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add treenode
      </button>
            <button *ngIf="selected" type="button" class="btn btn-link" (click)="deleteSelectedTreenode()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected treenode
      </button>

            <div *ngIf="selectedTreenode">
                <div class="card">
                    <div class="card-header">
                        Edit treeonde definition ({{selectedTreenode.nodeTypeId}})
                    </div>

                    <div class="card-block">
                        <form clrForm>
                            <clr-select-container>
                                <label>NodeType Id</label>
                                <select clrSelect name="treenode_nodetype" [(ngModel)]="selectedTreenode.nodeTypeId">
                  <option *ngFor="let nodetype of nodeTypes" [ngValue]="nodetype.id">
                    {{nodetype.displayableId}}
                  </option>
                </select>
                            </clr-select-container>
                        </form>

                        <clr-datagrid class="grid">
                            <clr-dg-column>EdgeType</clr-dg-column>
                            <clr-dg-column>NodeType</clr-dg-column>
                            <clr-dg-column>Ref Root Id</clr-dg-column>
                            <clr-dg-column>Ref Root Label</clr-dg-column>
                            <!-- <clr-dg-column>Is Customer Ref</clr-dg-column> -->

                            <clr-dg-row *ngFor="let treenoderef of selectedTreenode.treeNodeReferenceDefinitions; let i = index" [clrDgItem]="treenoderef">

                                <clr-dg-cell>
                                    <select class="clr-select" style="width:13em;" name="treenoderef_edgetype" [(ngModel)]="treenoderef.edgeTypeId">
                    <option *ngFor="let edgetype of edgeTypes" [ngValue]="edgetype.id">
                      {{edgetype.displayableId}}</option>
                  </select>
                                </clr-dg-cell>

                                <clr-dg-cell>
                                    <select class="clr-select" style="width:13em;" name="treenoderef_nodetype" [(ngModel)]="treenoderef.nodeTypeId">
                    <option *ngFor="let nodetype of nodeTypes" [ngValue]="nodetype.id">
                      {{nodetype.displayableId}}</option>
                  </select>

                                </clr-dg-cell>

                                <clr-dg-cell>
                                    <input class="clr-input" placeholder="" style="width:13em;" name="treenoderef_rootid_{{i}}" [(ngModel)]="treenoderef.referenceRootId" />
                                </clr-dg-cell>

                                <clr-dg-cell>
                                    <input class="clr-input" placeholder="" style="width:13em;" name="treenoderef_rootlabel_{{i}}" [(ngModel)]="treenoderef.referenceRootLabel" />
                                </clr-dg-cell>

                            </clr-dg-row>
                        </clr-datagrid>

                        <button type="button" class="btn btn-link" (click)="addTreenodeReference()">
              <clr-icon shape="plus-circle"></clr-icon>
              Add reference
            </button>

                    </div>
                </div>
            </div>
        </div>

        <button class="btn" (click)="saveSelected()">Save</button>

    </div>
</div>