import { Component, Directive, Input } from '@angular/core';
import { WidgetOutputChangeParameters } from '../../xproj-widget-service';

@Directive({
  selector: '.outputController'
})
export class XprojOutputControllerBase {

  @Input() useApplyButton : boolean = false;

  constructor() {}

  getOutputChangeParameters() : WidgetOutputChangeParameters[] {
    return [];
  };

  async onUpdateQuery() {
  }
}
