<div class="master-widget">
	<div class="clr-row">
		<div class="clr-col-sm-8 clr-col-12" *ngIf="widgetConfig.ProjectionSelectEnabled" >
			<form clrForm>
				<clr-select-container>
					<label>Projection</label>
					<select clrSelect name="projection" [(ngModel)]="queryData.projection"
						(ngModelChange)="selectedProjectionChange($event)">
						<option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name | xproj_pretty}}</option>
					</select>
				</clr-select-container>

				<xproj-group-selection #groupSelect [projection]="queryData.projection"
					[selected]="widgetConfig.SelectedGroup"></xproj-group-selection>
			</form>
		</div>
		<div [class]="widgetConfig.ProjectionSelectEnabled ? 'clr-col-sm-4 clr-col-12' : 'clr-col-12'">
			<xproj-master-widget-time #masterTime (onApply)="onTimeApply($event)" [settings]="widgetConfig.Time">
			</xproj-master-widget-time>
		</div>
	</div>
	<button class="btn" style="margin-top: 1em; margin-left: 1em;" (click)="apply()">Apply</button>
</div>
