import { Injectable, OnDestroy } from '@angular/core';
import { XEdgeToken } from '../models/xedge-token';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements OnDestroy {
  constructor() {
    window.addEventListener('storage', this.handleStorageEvent, false);

    this.requestSyncSessionStorage();
  }

  handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === 'requestSyncSessionStorage') {
      //console.log('handleStorageEvent - requestSyncSessionStorage', event);
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'sessionStorage') {
      //console.log('handleStorageEvent - sessionStorage', event);
      let sessionStorage = JSON.parse(event.newValue || '{}');
      for (const key in sessionStorage) {
        window.sessionStorage.setItem(key, sessionStorage[key]);
      }
    }
  };

  requestSyncSessionStorage(): void {
    //console.log('requestSyncSessionStorage - sessionStorage', sessionStorage);
    if (!sessionStorage.length) {
      let current = new Date().toLocaleTimeString();
      //console.log('requestSyncSessionStorage - request', current);
      localStorage.setItem(
        'requestSyncSessionStorage',
        'request session storage' + current
      );
    }
  }

  getXEdgeToken(): XEdgeToken {
    let tokenStr = window.sessionStorage.getItem('xedgetoken') || 'null';
    let token : XEdgeToken = JSON.parse(tokenStr);
    //console.log('getToken', token);
    return token;
  }

  setXEdgeToken(xedgeToken : XEdgeToken): void {
    xedgeToken.timestamp = new Date().toLocaleTimeString();
    //console.log('setToken', xedgeToken);
    window.sessionStorage.setItem('xedgetoken', JSON.stringify(xedgeToken));
  }

  removeToken(): void {
    window.sessionStorage.removeItem('xedgetoken');
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent, false);
  }
}
