export class RossakerLorawanDeviceInfo {
  id : string;
  deveui :string;
  batteryLevel : number;
  frequency : number;
  gatewayCount : number;
  gatewayIdentifier : string;
  gatewayIdentifiers : string;
  latitude : number;
  longitude : number;
  rssi : number;
  snr : number;
  spreadingfactor : number;
  modifiedAt : Date;
}
