import { Component } from '@angular/core';
import { XAUTO_IntegrationServerMqtt, XAUTO_IntegrationServerMqttVariableMapping, XAUTO_XAutoIntegrationServer } from '../../XProjector/xprojector-client-service';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';

export class ViewServer{
  public Server : XAUTO_XAutoIntegrationServer;
  public Mqtt : XAUTO_IntegrationServerMqtt;
  public Variables: Array<XAUTO_IntegrationServerMqttVariableMapping> = new Array<XAUTO_IntegrationServerMqttVariableMapping>();
}

@Component({
  selector: 'xproj-editintegrationservers-mqtt',
  templateUrl: './editintegrationservers-mqtt.component.html',
  styleUrls: ['./editintegrationservers-mqtt.component.scss']
})

export class EditintegrationserversMqttComponent {
  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
 
  
  public selectedServer : ViewServer;
  public savingServer : ClrLoadingState = ClrLoadingState.DEFAULT;
  public loadingServers : ClrLoadingState = ClrLoadingState.DEFAULT;
  public savingRemoveServer : ClrLoadingState = ClrLoadingState.DEFAULT;
  

  public servers =[];
  

  addServer()
  {    
  }
  
  saveServer()
  {    
  }

  removeDriver()
  {

  }
}
