import { Component, OnInit } from '@angular/core';
import { GrpcEdgeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';

@Component({
  selector: 'app-edit-edge-types',
  templateUrl: './edit-edge-types.component.html',
  styleUrls: ['./edit-edge-types.component.scss']
})
export class EditEdgeTypesComponent implements OnInit {

  edgeTypes : GrpcEdgeType[] = [];

  selected : GrpcEdgeType = null;

  constructor(private xConfClient: XProjectorXConfClient) {

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.edgeTypes = await this.xConfClient.getEdgeTypes();
  }

  async saveEdgeType(edgeType : GrpcEdgeType) : Promise<boolean>{
    let result = await this.xConfClient.upsertEdgeType(edgeType);
    return result.result;
  }

  addEdgeType() {
    let edgeType : GrpcEdgeType = new GrpcEdgeType();
    this.edgeTypes.push(edgeType);
    this.selected = null;
  }

  async deleteSelected() {
    if (this.selected) {
      let result = await this.xConfClient.deleteEdgeType(this.selected.id);
      if (result.result) {
        this.edgeTypes = this.edgeTypes.filter(n => n.id != this.selected.id);
        this.selected = null;
      }
    }
  }

  async saveSelected() {
    if (this.selected) {
        //TODO: id changed??
      await this.saveEdgeType(this.selected);

      this.selected = null;
    }

  }
}
