import { Injectable } from '@angular/core';
import { LuaDebuggerCommandSetBreakpoints, LuaDebuggerState, XProjectorClient } from '../../XProjector/xprojector-client-service';

@Injectable({
  providedIn: 'root'
})
export class LuadebuggerService {

  public selectedInstanceID : string = "";//temp-debug-x1";
  public breakpoints : number [] = [];
  public activeline : number = 3;
  public watches : string [] = [];
  public watchesValues: any[] = [];

  loadingPotentialSIDs = false;
  PotentialSIDs = [];
  
  IsAttached = false;

  public async attach(sid: string)
  {
    this.source = "";
    this.sourcehash = 0;
    this.breakpoints.length = 0;

    if(this.selectedInstanceID != "")
    {
      await this.xprojClient.LuaDebuggerRelease(this.selectedInstanceID);
    }
    try
    {
      this.stateMessage = "";
      console.log("attaching to sid", sid)
      await this.xprojClient.LuaDebuggerAttach(sid);
      this.selectedInstanceID = sid;    
      console.log("this.selectedInstanceId", this.selectedInstanceID);
      localStorage.setItem("luadebugger-attached-sid", this.selectedInstanceID);
      if(this.breakpoints.length > 0)
      {
        await this.setBreakpoints(null);
      }
    }
    catch(err)
    {
      this.IsAttached = false;
    }
  }

  public async release()
  {
    this.stateMessage = "";
    console.log("releasing sid", this.selectedInstanceID)
    await this.xprojClient.LuaDebuggerRelease(this.selectedInstanceID);
    this.selectedInstanceID = "";
    localStorage.setItem("luadebugger-attached-sid", this.selectedInstanceID);
  }

  public async run()
  {
    this.stateMessage = "Running";
    console.log("Letting sid run", this.selectedInstanceID);
    await this.xprojClient.LuaDebuggerRun(this.selectedInstanceID);        
  }

  public async getVariables() 
  {
    try
    {
      let t = await this.xprojClient.LuaDebuggerGetValues(this.selectedInstanceID, this.watches);
      console.log("watches..", t);

      //debugger;
      this.watchesValues.length = 0;
      for(let i in t.values)
      {
        let obj = { exp: i, val: t.values[i] };      
        this.watchesValues.push( obj );
      } 
    }
    catch(err)
    {

    }
    for(let i of this.watches)
    {
      let found = false;
      for(let j of this.watchesValues)
      {
        if(i == j.exp)
        {
          found = true;
          break;
        }
      }
      if(!found)
      {
        let obj = { exp: i, val: null };
        this.watchesValues.push(obj);
      }
    }
  }

  public async setWatches( exps: string[] | null)
  {
    if(exps)
    {
      this.watches.length = 0;
      for(let i of exps)
      {
        this.watches.push(i);
      }
    }
    await this.getVariables();
    localStorage.setItem("luadebugger-" + this.selectedInstanceID + "-watches", JSON.stringify(this.watches));
  }

  public async setBreakpoints(lines: number[] | null)
  {
    if(lines)
    {
      this.breakpoints.length = 0;
      for(let i of lines)
      {
        this.breakpoints.push(i);
      }
    }    
    await this.xprojClient.LuaDebuggerSetBreakpoints(this.selectedInstanceID, this.breakpoints);
    localStorage.setItem("luadebugger-" + this.selectedInstanceID + "-breakpoints", JSON.stringify(this.breakpoints));
  }

  public async stepInto()
  {
    await this.xprojClient.LuaDebuggerStepInto(this.selectedInstanceID);
  }

  public async stepOver()
  {
    await this.xprojClient.LuaDebuggerStepOver(this.selectedInstanceID);    
  }  

  public async ReloadPotentialSIDs()
  {
    this.loadingPotentialSIDs = true;    
    try{
      let newSIDS = await this.xprojClient.LuaDebuggerListPotentialIdentifiers(0,1000);
      console.log("newsids:", newSIDS);
      this.PotentialSIDs.length = 0;
      for(let s of newSIDS)
      {
        this.PotentialSIDs.push(s);
      }
      console.log("PotentialSIDs:", this.PotentialSIDs);
    }
    catch(err)
    {
    }
    this.loadingPotentialSIDs = false;  
  }

  sourcehash : number = 0;
  source : string = "";
  state : LuaDebuggerState;

  public async VerifySource( force = false) : Promise<boolean>
  {
    console.log("Verify source id = ", this.selectedInstanceID, "force = ", force,)
    let t = await this.xprojClient.LuaDebuggerGetSource(this.selectedInstanceID);    
    console.log("t:", t);
    this.source = t.source;
    console.log("source: ", t.source);
    this.sourcehash = t.sourcehash;

    if(this.sourcehash == 0)
    {
      console.log("returning false");
      return false;
    }

    console.log("returning true");
    return true;
  }

  public stateMessage = "";
  public async VerifyStatus()
  {
    let stat = await this.xprojClient.LuaDebuggerGetStatus(this.selectedInstanceID);
    this.state = stat.state;
    if(stat.pausedatsourcehash == this.sourcehash)
      this.activeline = stat.pausedatline;
    //else
//      this.activeline = null;

      // TODO: Angular pipe
    switch(this.state)
    {
      case LuaDebuggerState.Aborted:
        this.stateMessage = "Aborted";
        break;
      case LuaDebuggerState.NotRunning:
        this.stateMessage = "Not Running";
        break;
      case LuaDebuggerState.Paused:
        this.stateMessage = "Paused at " + stat.pausedatline;// this.activeline;
        break;
      case LuaDebuggerState.Running:
        this.stateMessage = "Running";
        break;
    
    }
  }

  loadSid(sid: string)
  {
    localStorage.setItem("luadebugger-attached-sid", sid);
    this.selectedInstanceID = sid;
    this.breakpoints = JSON.parse( localStorage.getItem("luadebugger-" + this.selectedInstanceID + "-breakpoints" ) ) || [];
    this.watches = JSON.parse( localStorage.getItem("luadebugger-" + this.selectedInstanceID + "-watches" ) ) || []; 
    this.setBreakpoints(null);
    this.setWatches(null);    
  }


  constructor(private xprojClient: XProjectorClient)
  {
    let sid = localStorage.getItem("luadebugger-attached-sid");
    if(sid)
    {
      this.loadSid(sid);
    }
  }
}
