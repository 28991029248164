<main class="content-area">
    <h1>Edit datasources</h1>
    <p>Select a datasource to continue to editing mode</p>


    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid [(clrDgSingleSelected)]="selectedDataSource" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectedProjectionChange($event)" [clrDgLoading]="loadingDatasources">


            <clr-dg-column> Datasource ID </clr-dg-column>
            <clr-dg-column> Name </clr-dg-column>
            <clr-dg-column> Driver </clr-dg-column>
            <clr-dg-column> Description </clr-dg-column>
            <clr-dg-column> Last Modified UTC </clr-dg-column>
            <clr-dg-column> Last Projected UTC </clr-dg-column>

            <clr-dg-row *ngFor="let ds of Datasources" [clrDgItem]="ds">
                <clr-dg-cell class="cellhideoverflow"> {{ds.datasourceid}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{ds.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{ds.driver | xproj_prettydsdriver}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{ds.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{ds.lastmodifiedutc | xproj_pretty}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{ds.lastprojectedutc | xproj_pretty}} </clr-dg-cell>

            </clr-dg-row>

            <clr-dg-footer>{{Datasources.length}} Datasources</clr-dg-footer>

        </clr-datagrid>
    </div>

    <div class="clr-row" *ngIf="selectedDataSource">
        <div style="width: 600px;">
            <div class="card">
                <div class="card-header">
                    {{selectedDataSource.name}}
                </div>
                <div class="card-block">
                    <div class="card-title">
                        {{selectedDataSource.description}}
                    </div>
                    <div class="card-text">

                        <div class="clr-row" *ngFor="let item of selectedDataSource | keyvalue">
                            <div class="clr-col-4">
                                <span>{{item.key}}</span>
                            </div>
                            <div class="clr-col-8">
                                <xproj-dynclr-mini [title]="item.key" [data]="item.value"></xproj-dynclr-mini>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-group btn-primary">

                        <button [routerLink]="[selectedDataSource.datasourceid]" class=" btn btn-outline ">Edit</button>
                        <button class="btn btn-outline ">Disable</button>
                        <button class="btn btn-danger-outline ">Remove</button>

                    </div>
                </div>
            </div>
        </div>
    </div>

</main>
