/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for modulerossakerbms.export.GetExtrapolatedValueRequest
 */
export class GetExtrapolatedValueRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.GetExtrapolatedValueRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetExtrapolatedValueRequest();
    GetExtrapolatedValueRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetExtrapolatedValueRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.meterId = _instance.meterId || '0';
    _instance.periodStart = _instance.periodStart || undefined;
    _instance.periodEnd = _instance.periodEnd || undefined;
    _instance.extrapolateFrom = _instance.extrapolateFrom || undefined;
    _instance.extrapolateTo = _instance.extrapolateTo || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetExtrapolatedValueRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.meterId = _reader.readInt64String();
          break;
        case 3:
          _instance.periodStart = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.periodStart,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.periodEnd = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.periodEnd,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.extrapolateFrom = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.extrapolateFrom,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.extrapolateTo = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.extrapolateTo,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetExtrapolatedValueRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetExtrapolatedValueRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.meterId) {
      _writer.writeInt64String(2, _instance.meterId);
    }
    if (_instance.periodStart) {
      _writer.writeMessage(
        3,
        _instance.periodStart as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.periodEnd) {
      _writer.writeMessage(
        4,
        _instance.periodEnd as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.extrapolateFrom) {
      _writer.writeMessage(
        5,
        _instance.extrapolateFrom as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.extrapolateTo) {
      _writer.writeMessage(
        6,
        _instance.extrapolateTo as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _meterId?: string;
  private _periodStart?: googleProtobuf000.Timestamp;
  private _periodEnd?: googleProtobuf000.Timestamp;
  private _extrapolateFrom?: googleProtobuf000.Timestamp;
  private _extrapolateTo?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetExtrapolatedValueRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetExtrapolatedValueRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.meterId = _value.meterId;
    this.periodStart = _value.periodStart
      ? new googleProtobuf000.Timestamp(_value.periodStart)
      : undefined;
    this.periodEnd = _value.periodEnd
      ? new googleProtobuf000.Timestamp(_value.periodEnd)
      : undefined;
    this.extrapolateFrom = _value.extrapolateFrom
      ? new googleProtobuf000.Timestamp(_value.extrapolateFrom)
      : undefined;
    this.extrapolateTo = _value.extrapolateTo
      ? new googleProtobuf000.Timestamp(_value.extrapolateTo)
      : undefined;
    GetExtrapolatedValueRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterId(): string | undefined {
    return this._meterId;
  }
  set meterId(value: string | undefined) {
    this._meterId = value;
  }
  get periodStart(): googleProtobuf000.Timestamp | undefined {
    return this._periodStart;
  }
  set periodStart(value: googleProtobuf000.Timestamp | undefined) {
    this._periodStart = value;
  }
  get periodEnd(): googleProtobuf000.Timestamp | undefined {
    return this._periodEnd;
  }
  set periodEnd(value: googleProtobuf000.Timestamp | undefined) {
    this._periodEnd = value;
  }
  get extrapolateFrom(): googleProtobuf000.Timestamp | undefined {
    return this._extrapolateFrom;
  }
  set extrapolateFrom(value: googleProtobuf000.Timestamp | undefined) {
    this._extrapolateFrom = value;
  }
  get extrapolateTo(): googleProtobuf000.Timestamp | undefined {
    return this._extrapolateTo;
  }
  set extrapolateTo(value: googleProtobuf000.Timestamp | undefined) {
    this._extrapolateTo = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetExtrapolatedValueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetExtrapolatedValueRequest.AsObject {
    return {
      customerId: this.customerId,
      meterId: this.meterId,
      periodStart: this.periodStart ? this.periodStart.toObject() : undefined,
      periodEnd: this.periodEnd ? this.periodEnd.toObject() : undefined,
      extrapolateFrom: this.extrapolateFrom
        ? this.extrapolateFrom.toObject()
        : undefined,
      extrapolateTo: this.extrapolateTo
        ? this.extrapolateTo.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetExtrapolatedValueRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      meterId: this.meterId,
      periodStart: this.periodStart
        ? this.periodStart.toProtobufJSON(options)
        : null,
      periodEnd: this.periodEnd ? this.periodEnd.toProtobufJSON(options) : null,
      extrapolateFrom: this.extrapolateFrom
        ? this.extrapolateFrom.toProtobufJSON(options)
        : null,
      extrapolateTo: this.extrapolateTo
        ? this.extrapolateTo.toProtobufJSON(options)
        : null
    };
  }
}
export module GetExtrapolatedValueRequest {
  /**
   * Standard JavaScript object representation for GetExtrapolatedValueRequest
   */
  export interface AsObject {
    customerId?: string;
    meterId?: string;
    periodStart?: googleProtobuf000.Timestamp.AsObject;
    periodEnd?: googleProtobuf000.Timestamp.AsObject;
    extrapolateFrom?: googleProtobuf000.Timestamp.AsObject;
    extrapolateTo?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetExtrapolatedValueRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    meterId?: string;
    periodStart?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    periodEnd?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    extrapolateFrom?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    extrapolateTo?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulerossakerbms.export.GetExtrapolatedValueResponse
 */
export class GetExtrapolatedValueResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.GetExtrapolatedValueResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetExtrapolatedValueResponse();
    GetExtrapolatedValueResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetExtrapolatedValueResponse) {
    _instance.ok = _instance.ok || false;
    _instance.value = _instance.value || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetExtrapolatedValueResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.value = _reader.readDouble();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetExtrapolatedValueResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetExtrapolatedValueResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.value) {
      _writer.writeDouble(2, _instance.value);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
  }

  private _ok?: boolean;
  private _value?: number;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetExtrapolatedValueResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetExtrapolatedValueResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.value = _value.value;
    this.message = _value.message;
    GetExtrapolatedValueResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetExtrapolatedValueResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetExtrapolatedValueResponse.AsObject {
    return {
      ok: this.ok,
      value: this.value,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetExtrapolatedValueResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      value: this.value,
      message: this.message
    };
  }
}
export module GetExtrapolatedValueResponse {
  /**
   * Standard JavaScript object representation for GetExtrapolatedValueResponse
   */
  export interface AsObject {
    ok?: boolean;
    value?: number;
    message?: string;
  }

  /**
   * Protobuf JSON representation for GetExtrapolatedValueResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    value?: number;
    message?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.GetExportInfoRequest
 */
export class GetExportInfoRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.GetExportInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetExportInfoRequest();
    GetExportInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetExportInfoRequest) {
    _instance.billingPeriod = _instance.billingPeriod || 0;
    _instance.periodStart = _instance.periodStart || undefined;
    _instance.periodEnd = _instance.periodEnd || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetExportInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.billingPeriod = _reader.readEnum();
          break;
        case 2:
          _instance.periodStart = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.periodStart,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.periodEnd = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.periodEnd,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetExportInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetExportInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.billingPeriod) {
      _writer.writeEnum(1, _instance.billingPeriod);
    }
    if (_instance.periodStart) {
      _writer.writeMessage(
        2,
        _instance.periodStart as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.periodEnd) {
      _writer.writeMessage(
        3,
        _instance.periodEnd as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _billingPeriod?: GetExportInfoRequest.BillingPeriods;
  private _periodStart?: googleProtobuf000.Timestamp;
  private _periodEnd?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetExportInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetExportInfoRequest.AsObject>) {
    _value = _value || {};
    this.billingPeriod = _value.billingPeriod;
    this.periodStart = _value.periodStart
      ? new googleProtobuf000.Timestamp(_value.periodStart)
      : undefined;
    this.periodEnd = _value.periodEnd
      ? new googleProtobuf000.Timestamp(_value.periodEnd)
      : undefined;
    GetExportInfoRequest.refineValues(this);
  }
  get billingPeriod(): GetExportInfoRequest.BillingPeriods | undefined {
    return this._billingPeriod;
  }
  set billingPeriod(value: GetExportInfoRequest.BillingPeriods | undefined) {
    this._billingPeriod = value;
  }
  get periodStart(): googleProtobuf000.Timestamp | undefined {
    return this._periodStart;
  }
  set periodStart(value: googleProtobuf000.Timestamp | undefined) {
    this._periodStart = value;
  }
  get periodEnd(): googleProtobuf000.Timestamp | undefined {
    return this._periodEnd;
  }
  set periodEnd(value: googleProtobuf000.Timestamp | undefined) {
    this._periodEnd = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetExportInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetExportInfoRequest.AsObject {
    return {
      billingPeriod: this.billingPeriod,
      periodStart: this.periodStart ? this.periodStart.toObject() : undefined,
      periodEnd: this.periodEnd ? this.periodEnd.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetExportInfoRequest.AsProtobufJSON {
    return {
      billingPeriod:
        GetExportInfoRequest.BillingPeriods[
          this.billingPeriod === null || this.billingPeriod === undefined
            ? 0
            : this.billingPeriod
        ],
      periodStart: this.periodStart
        ? this.periodStart.toProtobufJSON(options)
        : null,
      periodEnd: this.periodEnd ? this.periodEnd.toProtobufJSON(options) : null
    };
  }
}
export module GetExportInfoRequest {
  /**
   * Standard JavaScript object representation for GetExportInfoRequest
   */
  export interface AsObject {
    billingPeriod?: GetExportInfoRequest.BillingPeriods;
    periodStart?: googleProtobuf000.Timestamp.AsObject;
    periodEnd?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetExportInfoRequest
   */
  export interface AsProtobufJSON {
    billingPeriod?: string;
    periodStart?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    periodEnd?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
  export enum BillingPeriods {
    WEEK = 0,
    MONTH = 1,
    QUARTER = 2,
    YEAR = 3
  }
}

/**
 * Message implementation for modulerossakerbms.export.GetExportInfoResponse
 */
export class GetExportInfoResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.GetExportInfoResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetExportInfoResponse();
    GetExportInfoResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetExportInfoResponse) {
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.start = _instance.start || undefined;
    _instance.end = _instance.end || undefined;
    _instance.totalCount = _instance.totalCount || 0;
    _instance.signedCount = _instance.signedCount || 0;
    _instance.invoicedCount = _instance.invoicedCount || 0;
    _instance.trustees = _instance.trustees || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetExportInfoResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.start = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.start,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.end = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.end,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.totalCount = _reader.readInt32();
          break;
        case 5:
          _instance.signedCount = _reader.readInt32();
          break;
        case 6:
          _instance.invoicedCount = _reader.readInt32();
          break;
        case 7:
          const messageInitializer7 = new GrpcTrusteeExportInfo();
          _reader.readMessage(
            messageInitializer7,
            GrpcTrusteeExportInfo.deserializeBinaryFromReader
          );
          (_instance.trustees = _instance.trustees || []).push(
            messageInitializer7
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetExportInfoResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetExportInfoResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.timestamp) {
      _writer.writeMessage(
        1,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.start) {
      _writer.writeMessage(
        2,
        _instance.start as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.end) {
      _writer.writeMessage(
        3,
        _instance.end as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.totalCount) {
      _writer.writeInt32(4, _instance.totalCount);
    }
    if (_instance.signedCount) {
      _writer.writeInt32(5, _instance.signedCount);
    }
    if (_instance.invoicedCount) {
      _writer.writeInt32(6, _instance.invoicedCount);
    }
    if (_instance.trustees && _instance.trustees.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.trustees as any,
        GrpcTrusteeExportInfo.serializeBinaryToWriter
      );
    }
  }

  private _timestamp?: googleProtobuf000.Timestamp;
  private _start?: googleProtobuf000.Timestamp;
  private _end?: googleProtobuf000.Timestamp;
  private _totalCount?: number;
  private _signedCount?: number;
  private _invoicedCount?: number;
  private _trustees?: GrpcTrusteeExportInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetExportInfoResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetExportInfoResponse.AsObject>) {
    _value = _value || {};
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    this.start = _value.start
      ? new googleProtobuf000.Timestamp(_value.start)
      : undefined;
    this.end = _value.end
      ? new googleProtobuf000.Timestamp(_value.end)
      : undefined;
    this.totalCount = _value.totalCount;
    this.signedCount = _value.signedCount;
    this.invoicedCount = _value.invoicedCount;
    this.trustees = (_value.trustees || []).map(
      m => new GrpcTrusteeExportInfo(m)
    );
    GetExportInfoResponse.refineValues(this);
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }
  get start(): googleProtobuf000.Timestamp | undefined {
    return this._start;
  }
  set start(value: googleProtobuf000.Timestamp | undefined) {
    this._start = value;
  }
  get end(): googleProtobuf000.Timestamp | undefined {
    return this._end;
  }
  set end(value: googleProtobuf000.Timestamp | undefined) {
    this._end = value;
  }
  get totalCount(): number | undefined {
    return this._totalCount;
  }
  set totalCount(value: number | undefined) {
    this._totalCount = value;
  }
  get signedCount(): number | undefined {
    return this._signedCount;
  }
  set signedCount(value: number | undefined) {
    this._signedCount = value;
  }
  get invoicedCount(): number | undefined {
    return this._invoicedCount;
  }
  set invoicedCount(value: number | undefined) {
    this._invoicedCount = value;
  }
  get trustees(): GrpcTrusteeExportInfo[] | undefined {
    return this._trustees;
  }
  set trustees(value: GrpcTrusteeExportInfo[] | undefined) {
    this._trustees = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetExportInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetExportInfoResponse.AsObject {
    return {
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      start: this.start ? this.start.toObject() : undefined,
      end: this.end ? this.end.toObject() : undefined,
      totalCount: this.totalCount,
      signedCount: this.signedCount,
      invoicedCount: this.invoicedCount,
      trustees: (this.trustees || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetExportInfoResponse.AsProtobufJSON {
    return {
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      start: this.start ? this.start.toProtobufJSON(options) : null,
      end: this.end ? this.end.toProtobufJSON(options) : null,
      totalCount: this.totalCount,
      signedCount: this.signedCount,
      invoicedCount: this.invoicedCount,
      trustees: (this.trustees || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetExportInfoResponse {
  /**
   * Standard JavaScript object representation for GetExportInfoResponse
   */
  export interface AsObject {
    timestamp?: googleProtobuf000.Timestamp.AsObject;
    start?: googleProtobuf000.Timestamp.AsObject;
    end?: googleProtobuf000.Timestamp.AsObject;
    totalCount?: number;
    signedCount?: number;
    invoicedCount?: number;
    trustees?: GrpcTrusteeExportInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetExportInfoResponse
   */
  export interface AsProtobufJSON {
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    start?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    end?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    totalCount?: number;
    signedCount?: number;
    invoicedCount?: number;
    trustees?: GrpcTrusteeExportInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.export.GrpcTrusteeExportInfo
 */
export class GrpcTrusteeExportInfo implements GrpcMessage {
  static id = 'modulerossakerbms.export.GrpcTrusteeExportInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcTrusteeExportInfo();
    GrpcTrusteeExportInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcTrusteeExportInfo) {
    _instance.name = _instance.name || '';
    _instance.customerId = _instance.customerId || '';
    _instance.customerXdbId = _instance.customerXdbId || '0';
    _instance.totalCount = _instance.totalCount || 0;
    _instance.signedCount = _instance.signedCount || 0;
    _instance.invoicedCount = _instance.invoicedCount || 0;
    _instance.customers = _instance.customers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcTrusteeExportInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.customerXdbId = _reader.readInt64String();
          break;
        case 4:
          _instance.totalCount = _reader.readInt32();
          break;
        case 5:
          _instance.signedCount = _reader.readInt32();
          break;
        case 6:
          _instance.invoicedCount = _reader.readInt32();
          break;
        case 7:
          const messageInitializer7 = new GrpcCustomerExportInfo();
          _reader.readMessage(
            messageInitializer7,
            GrpcCustomerExportInfo.deserializeBinaryFromReader
          );
          (_instance.customers = _instance.customers || []).push(
            messageInitializer7
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcTrusteeExportInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcTrusteeExportInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.customerXdbId) {
      _writer.writeInt64String(3, _instance.customerXdbId);
    }
    if (_instance.totalCount) {
      _writer.writeInt32(4, _instance.totalCount);
    }
    if (_instance.signedCount) {
      _writer.writeInt32(5, _instance.signedCount);
    }
    if (_instance.invoicedCount) {
      _writer.writeInt32(6, _instance.invoicedCount);
    }
    if (_instance.customers && _instance.customers.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.customers as any,
        GrpcCustomerExportInfo.serializeBinaryToWriter
      );
    }
  }

  private _name?: string;
  private _customerId?: string;
  private _customerXdbId?: string;
  private _totalCount?: number;
  private _signedCount?: number;
  private _invoicedCount?: number;
  private _customers?: GrpcCustomerExportInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcTrusteeExportInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcTrusteeExportInfo.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.customerId = _value.customerId;
    this.customerXdbId = _value.customerXdbId;
    this.totalCount = _value.totalCount;
    this.signedCount = _value.signedCount;
    this.invoicedCount = _value.invoicedCount;
    this.customers = (_value.customers || []).map(
      m => new GrpcCustomerExportInfo(m)
    );
    GrpcTrusteeExportInfo.refineValues(this);
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerXdbId(): string | undefined {
    return this._customerXdbId;
  }
  set customerXdbId(value: string | undefined) {
    this._customerXdbId = value;
  }
  get totalCount(): number | undefined {
    return this._totalCount;
  }
  set totalCount(value: number | undefined) {
    this._totalCount = value;
  }
  get signedCount(): number | undefined {
    return this._signedCount;
  }
  set signedCount(value: number | undefined) {
    this._signedCount = value;
  }
  get invoicedCount(): number | undefined {
    return this._invoicedCount;
  }
  set invoicedCount(value: number | undefined) {
    this._invoicedCount = value;
  }
  get customers(): GrpcCustomerExportInfo[] | undefined {
    return this._customers;
  }
  set customers(value: GrpcCustomerExportInfo[] | undefined) {
    this._customers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcTrusteeExportInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcTrusteeExportInfo.AsObject {
    return {
      name: this.name,
      customerId: this.customerId,
      customerXdbId: this.customerXdbId,
      totalCount: this.totalCount,
      signedCount: this.signedCount,
      invoicedCount: this.invoicedCount,
      customers: (this.customers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcTrusteeExportInfo.AsProtobufJSON {
    return {
      name: this.name,
      customerId: this.customerId,
      customerXdbId: this.customerXdbId,
      totalCount: this.totalCount,
      signedCount: this.signedCount,
      invoicedCount: this.invoicedCount,
      customers: (this.customers || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GrpcTrusteeExportInfo {
  /**
   * Standard JavaScript object representation for GrpcTrusteeExportInfo
   */
  export interface AsObject {
    name?: string;
    customerId?: string;
    customerXdbId?: string;
    totalCount?: number;
    signedCount?: number;
    invoicedCount?: number;
    customers?: GrpcCustomerExportInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GrpcTrusteeExportInfo
   */
  export interface AsProtobufJSON {
    name?: string;
    customerId?: string;
    customerXdbId?: string;
    totalCount?: number;
    signedCount?: number;
    invoicedCount?: number;
    customers?: GrpcCustomerExportInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.export.GrpcCustomerExportInfo
 */
export class GrpcCustomerExportInfo implements GrpcMessage {
  static id = 'modulerossakerbms.export.GrpcCustomerExportInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcCustomerExportInfo();
    GrpcCustomerExportInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcCustomerExportInfo) {
    _instance.name = _instance.name || '';
    _instance.customerId = _instance.customerId || '';
    _instance.customerXdbId = _instance.customerXdbId || '0';
    _instance.signed = _instance.signed || false;
    _instance.invoiced = _instance.invoiced || false;
    _instance.meterCount = _instance.meterCount || 0;
    _instance.meterErrorCount = _instance.meterErrorCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcCustomerExportInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.customerXdbId = _reader.readInt64String();
          break;
        case 4:
          _instance.signed = _reader.readBool();
          break;
        case 5:
          _instance.invoiced = _reader.readBool();
          break;
        case 6:
          _instance.meterCount = _reader.readInt32();
          break;
        case 7:
          _instance.meterErrorCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcCustomerExportInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcCustomerExportInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.customerXdbId) {
      _writer.writeInt64String(3, _instance.customerXdbId);
    }
    if (_instance.signed) {
      _writer.writeBool(4, _instance.signed);
    }
    if (_instance.invoiced) {
      _writer.writeBool(5, _instance.invoiced);
    }
    if (_instance.meterCount) {
      _writer.writeInt32(6, _instance.meterCount);
    }
    if (_instance.meterErrorCount) {
      _writer.writeInt32(7, _instance.meterErrorCount);
    }
  }

  private _name?: string;
  private _customerId?: string;
  private _customerXdbId?: string;
  private _signed?: boolean;
  private _invoiced?: boolean;
  private _meterCount?: number;
  private _meterErrorCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcCustomerExportInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcCustomerExportInfo.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.customerId = _value.customerId;
    this.customerXdbId = _value.customerXdbId;
    this.signed = _value.signed;
    this.invoiced = _value.invoiced;
    this.meterCount = _value.meterCount;
    this.meterErrorCount = _value.meterErrorCount;
    GrpcCustomerExportInfo.refineValues(this);
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerXdbId(): string | undefined {
    return this._customerXdbId;
  }
  set customerXdbId(value: string | undefined) {
    this._customerXdbId = value;
  }
  get signed(): boolean | undefined {
    return this._signed;
  }
  set signed(value: boolean | undefined) {
    this._signed = value;
  }
  get invoiced(): boolean | undefined {
    return this._invoiced;
  }
  set invoiced(value: boolean | undefined) {
    this._invoiced = value;
  }
  get meterCount(): number | undefined {
    return this._meterCount;
  }
  set meterCount(value: number | undefined) {
    this._meterCount = value;
  }
  get meterErrorCount(): number | undefined {
    return this._meterErrorCount;
  }
  set meterErrorCount(value: number | undefined) {
    this._meterErrorCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcCustomerExportInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcCustomerExportInfo.AsObject {
    return {
      name: this.name,
      customerId: this.customerId,
      customerXdbId: this.customerXdbId,
      signed: this.signed,
      invoiced: this.invoiced,
      meterCount: this.meterCount,
      meterErrorCount: this.meterErrorCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcCustomerExportInfo.AsProtobufJSON {
    return {
      name: this.name,
      customerId: this.customerId,
      customerXdbId: this.customerXdbId,
      signed: this.signed,
      invoiced: this.invoiced,
      meterCount: this.meterCount,
      meterErrorCount: this.meterErrorCount
    };
  }
}
export module GrpcCustomerExportInfo {
  /**
   * Standard JavaScript object representation for GrpcCustomerExportInfo
   */
  export interface AsObject {
    name?: string;
    customerId?: string;
    customerXdbId?: string;
    signed?: boolean;
    invoiced?: boolean;
    meterCount?: number;
    meterErrorCount?: number;
  }

  /**
   * Protobuf JSON representation for GrpcCustomerExportInfo
   */
  export interface AsProtobufJSON {
    name?: string;
    customerId?: string;
    customerXdbId?: string;
    signed?: boolean;
    invoiced?: boolean;
    meterCount?: number;
    meterErrorCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.DataChunk
 */
export class DataChunk implements GrpcMessage {
  static id = 'modulerossakerbms.export.DataChunk';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DataChunk();
    DataChunk.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DataChunk) {
    _instance.data = _instance.data || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DataChunk,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.data = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    DataChunk.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DataChunk, _writer: BinaryWriter) {
    if (_instance.data && _instance.data.length) {
      _writer.writeBytes(1, _instance.data);
    }
  }

  private _data?: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DataChunk to deeply clone from
   */
  constructor(_value?: RecursivePartial<DataChunk.AsObject>) {
    _value = _value || {};
    this.data = _value.data;
    DataChunk.refineValues(this);
  }
  get data(): Uint8Array | undefined {
    return this._data;
  }
  set data(value: Uint8Array | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DataChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DataChunk.AsObject {
    return {
      data: this.data ? this.data.subarray(0) : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DataChunk.AsProtobufJSON {
    return {
      data: this.data ? uint8ArrayToBase64(this.data) : ''
    };
  }
}
export module DataChunk {
  /**
   * Standard JavaScript object representation for DataChunk
   */
  export interface AsObject {
    data?: Uint8Array;
  }

  /**
   * Protobuf JSON representation for DataChunk
   */
  export interface AsProtobufJSON {
    data?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.CreateMeterExportFileRequest
 */
export class CreateMeterExportFileRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.CreateMeterExportFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateMeterExportFileRequest();
    CreateMeterExportFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateMeterExportFileRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.chunkSize = _instance.chunkSize || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateMeterExportFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.chunkSize = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateMeterExportFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateMeterExportFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(2, _instance.chunkSize);
    }
  }

  private _customerId?: string;
  private _chunkSize?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateMeterExportFileRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateMeterExportFileRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.chunkSize = _value.chunkSize;
    CreateMeterExportFileRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateMeterExportFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateMeterExportFileRequest.AsObject {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateMeterExportFileRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }
}
export module CreateMeterExportFileRequest {
  /**
   * Standard JavaScript object representation for CreateMeterExportFileRequest
   */
  export interface AsObject {
    customerId?: string;
    chunkSize?: number;
  }

  /**
   * Protobuf JSON representation for CreateMeterExportFileRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    chunkSize?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.CreateApartmentAndFacilityExportFileRequest
 */
export class CreateApartmentAndFacilityExportFileRequest
  implements GrpcMessage {
  static id =
    'modulerossakerbms.export.CreateApartmentAndFacilityExportFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateApartmentAndFacilityExportFileRequest();
    CreateApartmentAndFacilityExportFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateApartmentAndFacilityExportFileRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.chunkSize = _instance.chunkSize || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateApartmentAndFacilityExportFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.chunkSize = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateApartmentAndFacilityExportFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateApartmentAndFacilityExportFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(2, _instance.chunkSize);
    }
  }

  private _customerId?: string;
  private _chunkSize?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateApartmentAndFacilityExportFileRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      CreateApartmentAndFacilityExportFileRequest.AsObject
    >
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.chunkSize = _value.chunkSize;
    CreateApartmentAndFacilityExportFileRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateApartmentAndFacilityExportFileRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateApartmentAndFacilityExportFileRequest.AsObject {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateApartmentAndFacilityExportFileRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }
}
export module CreateApartmentAndFacilityExportFileRequest {
  /**
   * Standard JavaScript object representation for CreateApartmentAndFacilityExportFileRequest
   */
  export interface AsObject {
    customerId?: string;
    chunkSize?: number;
  }

  /**
   * Protobuf JSON representation for CreateApartmentAndFacilityExportFileRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    chunkSize?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.GetMeteringApiFileRequest
 */
export class GetMeteringApiFileRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.GetMeteringApiFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeteringApiFileRequest();
    GetMeteringApiFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeteringApiFileRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.chunkSize = _instance.chunkSize || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeteringApiFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.chunkSize = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeteringApiFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeteringApiFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(2, _instance.chunkSize);
    }
  }

  private _customerId?: string;
  private _chunkSize?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeteringApiFileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeteringApiFileRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.chunkSize = _value.chunkSize;
    GetMeteringApiFileRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeteringApiFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeteringApiFileRequest.AsObject {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeteringApiFileRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize
    };
  }
}
export module GetMeteringApiFileRequest {
  /**
   * Standard JavaScript object representation for GetMeteringApiFileRequest
   */
  export interface AsObject {
    customerId?: string;
    chunkSize?: number;
  }

  /**
   * Protobuf JSON representation for GetMeteringApiFileRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    chunkSize?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.TouchMetersRequest
 */
export class TouchMetersRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.TouchMetersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TouchMetersRequest();
    TouchMetersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TouchMetersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.meterType = _instance.meterType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TouchMetersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.meterType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TouchMetersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TouchMetersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.meterType) {
      _writer.writeString(2, _instance.meterType);
    }
  }

  private _customerId?: string;
  private _meterType?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TouchMetersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<TouchMetersRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.meterType = _value.meterType;
    TouchMetersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TouchMetersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TouchMetersRequest.AsObject {
    return {
      customerId: this.customerId,
      meterType: this.meterType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TouchMetersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      meterType: this.meterType
    };
  }
}
export module TouchMetersRequest {
  /**
   * Standard JavaScript object representation for TouchMetersRequest
   */
  export interface AsObject {
    customerId?: string;
    meterType?: string;
  }

  /**
   * Protobuf JSON representation for TouchMetersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    meterType?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.TouchMetersResponse
 */
export class TouchMetersResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.TouchMetersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TouchMetersResponse();
    TouchMetersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TouchMetersResponse) {
    _instance.ok = _instance.ok || false;
    _instance.meterCount = _instance.meterCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TouchMetersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.meterCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    TouchMetersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TouchMetersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.meterCount) {
      _writer.writeInt32(2, _instance.meterCount);
    }
  }

  private _ok?: boolean;
  private _meterCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TouchMetersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<TouchMetersResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.meterCount = _value.meterCount;
    TouchMetersResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get meterCount(): number | undefined {
    return this._meterCount;
  }
  set meterCount(value: number | undefined) {
    this._meterCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TouchMetersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TouchMetersResponse.AsObject {
    return {
      ok: this.ok,
      meterCount: this.meterCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TouchMetersResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      meterCount: this.meterCount
    };
  }
}
export module TouchMetersResponse {
  /**
   * Standard JavaScript object representation for TouchMetersResponse
   */
  export interface AsObject {
    ok?: boolean;
    meterCount?: number;
  }

  /**
   * Protobuf JSON representation for TouchMetersResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    meterCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.TouchApartmentsAndFacilitiesRequest
 */
export class TouchApartmentsAndFacilitiesRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.TouchApartmentsAndFacilitiesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TouchApartmentsAndFacilitiesRequest();
    TouchApartmentsAndFacilitiesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TouchApartmentsAndFacilitiesRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TouchApartmentsAndFacilitiesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TouchApartmentsAndFacilitiesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TouchApartmentsAndFacilitiesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TouchApartmentsAndFacilitiesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<TouchApartmentsAndFacilitiesRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    TouchApartmentsAndFacilitiesRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TouchApartmentsAndFacilitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TouchApartmentsAndFacilitiesRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TouchApartmentsAndFacilitiesRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module TouchApartmentsAndFacilitiesRequest {
  /**
   * Standard JavaScript object representation for TouchApartmentsAndFacilitiesRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for TouchApartmentsAndFacilitiesRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.TouchApartmentsAndFacilitiesResponse
 */
export class TouchApartmentsAndFacilitiesResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.TouchApartmentsAndFacilitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TouchApartmentsAndFacilitiesResponse();
    TouchApartmentsAndFacilitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TouchApartmentsAndFacilitiesResponse) {
    _instance.ok = _instance.ok || false;
    _instance.nodeCount = _instance.nodeCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TouchApartmentsAndFacilitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.nodeCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    TouchApartmentsAndFacilitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TouchApartmentsAndFacilitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.nodeCount) {
      _writer.writeInt32(2, _instance.nodeCount);
    }
  }

  private _ok?: boolean;
  private _nodeCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TouchApartmentsAndFacilitiesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<TouchApartmentsAndFacilitiesResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.nodeCount = _value.nodeCount;
    TouchApartmentsAndFacilitiesResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get nodeCount(): number | undefined {
    return this._nodeCount;
  }
  set nodeCount(value: number | undefined) {
    this._nodeCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TouchApartmentsAndFacilitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TouchApartmentsAndFacilitiesResponse.AsObject {
    return {
      ok: this.ok,
      nodeCount: this.nodeCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TouchApartmentsAndFacilitiesResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      nodeCount: this.nodeCount
    };
  }
}
export module TouchApartmentsAndFacilitiesResponse {
  /**
   * Standard JavaScript object representation for TouchApartmentsAndFacilitiesResponse
   */
  export interface AsObject {
    ok?: boolean;
    nodeCount?: number;
  }

  /**
   * Protobuf JSON representation for TouchApartmentsAndFacilitiesResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    nodeCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.AssertCountyAndDistrictRequest
 */
export class AssertCountyAndDistrictRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.AssertCountyAndDistrictRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AssertCountyAndDistrictRequest();
    AssertCountyAndDistrictRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AssertCountyAndDistrictRequest) {
    _instance.customerName = _instance.customerName || '';
    _instance.customerId = _instance.customerId || '';
    _instance.county = _instance.county || '';
    _instance.district = _instance.district || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AssertCountyAndDistrictRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerName = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.county = _reader.readString();
          break;
        case 4:
          _instance.district = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AssertCountyAndDistrictRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AssertCountyAndDistrictRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerName) {
      _writer.writeString(1, _instance.customerName);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.county) {
      _writer.writeString(3, _instance.county);
    }
    if (_instance.district) {
      _writer.writeString(4, _instance.district);
    }
  }

  private _customerName?: string;
  private _customerId?: string;
  private _county?: string;
  private _district?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AssertCountyAndDistrictRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AssertCountyAndDistrictRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerName = _value.customerName;
    this.customerId = _value.customerId;
    this.county = _value.county;
    this.district = _value.district;
    AssertCountyAndDistrictRequest.refineValues(this);
  }
  get customerName(): string | undefined {
    return this._customerName;
  }
  set customerName(value: string | undefined) {
    this._customerName = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get county(): string | undefined {
    return this._county;
  }
  set county(value: string | undefined) {
    this._county = value;
  }
  get district(): string | undefined {
    return this._district;
  }
  set district(value: string | undefined) {
    this._district = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AssertCountyAndDistrictRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AssertCountyAndDistrictRequest.AsObject {
    return {
      customerName: this.customerName,
      customerId: this.customerId,
      county: this.county,
      district: this.district
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AssertCountyAndDistrictRequest.AsProtobufJSON {
    return {
      customerName: this.customerName,
      customerId: this.customerId,
      county: this.county,
      district: this.district
    };
  }
}
export module AssertCountyAndDistrictRequest {
  /**
   * Standard JavaScript object representation for AssertCountyAndDistrictRequest
   */
  export interface AsObject {
    customerName?: string;
    customerId?: string;
    county?: string;
    district?: string;
  }

  /**
   * Protobuf JSON representation for AssertCountyAndDistrictRequest
   */
  export interface AsProtobufJSON {
    customerName?: string;
    customerId?: string;
    county?: string;
    district?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.AssertCountyAndDistrictResponse
 */
export class AssertCountyAndDistrictResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.AssertCountyAndDistrictResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AssertCountyAndDistrictResponse();
    AssertCountyAndDistrictResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AssertCountyAndDistrictResponse) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AssertCountyAndDistrictResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    AssertCountyAndDistrictResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AssertCountyAndDistrictResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AssertCountyAndDistrictResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AssertCountyAndDistrictResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    AssertCountyAndDistrictResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AssertCountyAndDistrictResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AssertCountyAndDistrictResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AssertCountyAndDistrictResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module AssertCountyAndDistrictResponse {
  /**
   * Standard JavaScript object representation for AssertCountyAndDistrictResponse
   */
  export interface AsObject {
    ok?: boolean;
  }

  /**
   * Protobuf JSON representation for AssertCountyAndDistrictResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
  }
}

/**
 * Message implementation for modulerossakerbms.export.AddDatapointValueRequest
 */
export class AddDatapointValueRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.AddDatapointValueRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDatapointValueRequest();
    AddDatapointValueRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDatapointValueRequest) {
    _instance.meterId = _instance.meterId || '0';
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.value = _instance.value || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDatapointValueRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.meterId = _reader.readInt64String();
          break;
        case 2:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.value = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    AddDatapointValueRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDatapointValueRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.meterId) {
      _writer.writeInt64String(1, _instance.meterId);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        2,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeDouble(3, _instance.value);
    }
  }

  private _meterId?: string;
  private _timestamp?: googleProtobuf000.Timestamp;
  private _value?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDatapointValueRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddDatapointValueRequest.AsObject>) {
    _value = _value || {};
    this.meterId = _value.meterId;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    this.value = _value.value;
    AddDatapointValueRequest.refineValues(this);
  }
  get meterId(): string | undefined {
    return this._meterId;
  }
  set meterId(value: string | undefined) {
    this._meterId = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDatapointValueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDatapointValueRequest.AsObject {
    return {
      meterId: this.meterId,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDatapointValueRequest.AsProtobufJSON {
    return {
      meterId: this.meterId,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      value: this.value
    };
  }
}
export module AddDatapointValueRequest {
  /**
   * Standard JavaScript object representation for AddDatapointValueRequest
   */
  export interface AsObject {
    meterId?: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
    value?: number;
  }

  /**
   * Protobuf JSON representation for AddDatapointValueRequest
   */
  export interface AsProtobufJSON {
    meterId?: string;
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    value?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.export.AddDatapointValueResponse
 */
export class AddDatapointValueResponse implements GrpcMessage {
  static id = 'modulerossakerbms.export.AddDatapointValueResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDatapointValueResponse();
    AddDatapointValueResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDatapointValueResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDatapointValueResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddDatapointValueResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDatapointValueResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDatapointValueResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddDatapointValueResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    AddDatapointValueResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDatapointValueResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDatapointValueResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDatapointValueResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module AddDatapointValueResponse {
  /**
   * Standard JavaScript object representation for AddDatapointValueResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for AddDatapointValueResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.export.ExportFileRequest
 */
export class ExportFileRequest implements GrpcMessage {
  static id = 'modulerossakerbms.export.ExportFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExportFileRequest();
    ExportFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExportFileRequest) {
    _instance.exportId = _instance.exportId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeLabel = _instance.nodeLabel || '';
    _instance.chunkSize = _instance.chunkSize || 0;
    _instance.exportTypeId = _instance.exportTypeId || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExportFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.exportId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeLabel = _reader.readString();
          break;
        case 4:
          _instance.chunkSize = _reader.readInt32();
          break;
        case 5:
          _instance.exportTypeId = _reader.readString();
          break;
        case 6:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ExportFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExportFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.exportId) {
      _writer.writeString(1, _instance.exportId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeLabel) {
      _writer.writeString(3, _instance.nodeLabel);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(4, _instance.chunkSize);
    }
    if (_instance.exportTypeId) {
      _writer.writeString(5, _instance.exportTypeId);
    }
    if (_instance.customerId) {
      _writer.writeString(6, _instance.customerId);
    }
  }

  private _exportId?: string;
  private _nodeId?: string;
  private _nodeLabel?: string;
  private _chunkSize?: number;
  private _exportTypeId?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExportFileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExportFileRequest.AsObject>) {
    _value = _value || {};
    this.exportId = _value.exportId;
    this.nodeId = _value.nodeId;
    this.nodeLabel = _value.nodeLabel;
    this.chunkSize = _value.chunkSize;
    this.exportTypeId = _value.exportTypeId;
    this.customerId = _value.customerId;
    ExportFileRequest.refineValues(this);
  }
  get exportId(): string | undefined {
    return this._exportId;
  }
  set exportId(value: string | undefined) {
    this._exportId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeLabel(): string | undefined {
    return this._nodeLabel;
  }
  set nodeLabel(value: string | undefined) {
    this._nodeLabel = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }
  get exportTypeId(): string | undefined {
    return this._exportTypeId;
  }
  set exportTypeId(value: string | undefined) {
    this._exportTypeId = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExportFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExportFileRequest.AsObject {
    return {
      exportId: this.exportId,
      nodeId: this.nodeId,
      nodeLabel: this.nodeLabel,
      chunkSize: this.chunkSize,
      exportTypeId: this.exportTypeId,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExportFileRequest.AsProtobufJSON {
    return {
      exportId: this.exportId,
      nodeId: this.nodeId,
      nodeLabel: this.nodeLabel,
      chunkSize: this.chunkSize,
      exportTypeId: this.exportTypeId,
      customerId: this.customerId
    };
  }
}
export module ExportFileRequest {
  /**
   * Standard JavaScript object representation for ExportFileRequest
   */
  export interface AsObject {
    exportId?: string;
    nodeId?: string;
    nodeLabel?: string;
    chunkSize?: number;
    exportTypeId?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for ExportFileRequest
   */
  export interface AsProtobufJSON {
    exportId?: string;
    nodeId?: string;
    nodeLabel?: string;
    chunkSize?: number;
    exportTypeId?: string;
    customerId?: string;
  }
}
