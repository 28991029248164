import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { XprojDashboardComponent, WidgetExportParameters, BaseQuery, DownSampleQuery,
  DFTQuery, DashboardOutputChangeParameters, XprojAlertService, MultiseriesQuery, XprojMediaService } from 'xproj-lib';

var AsyncLock = require('async-lock');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
  // ,
  // host: {
  //   class: 'content-container'
  // }

})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;

  @Input() editMode: boolean = true;
  @Input() showDashboardSettings: boolean = false;
  @Input() dashboardId: string = '';
  @Input() enableExport: boolean = true;
  @Input() dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  @Input() responsiveWidth: number = 1000;

  currentUserRights: UserRights;

  editModeRequested: boolean = false;
  editMobileRequested: boolean = false;
  fullScreenRequested: boolean = false;

  lock = new AsyncLock({ timeout: 5000 });
  lockkey: string = 'initkey';
  isMobile: boolean = false;


  mediaService = new XprojMediaService();
  mediaSubscription: any;

  constructor(
    private state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private route: ActivatedRoute,
    private alertService: XprojAlertService,
    private router: Router,
    private logger: NGXLogger
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.currentUserRights = this.state.userRightsValue;
    this.editMobileRequested = this.editMode;

    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
        this.lock.acquire(this.lockkey, async () => {

          this.dashboardId = id
          await this.xprojDashboard?.save();

          if (this.currentUserRights) {
            if (this.dashboardOutputParameters.length == 0) {
              this.dashboardOutputParameters = await this.getSystemOutputParameters();
            }
            await this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', false, this.dashboardOutputParameters);
          }

        }).then(() => {
          // lock released
        });
      }
      //await this.xprojDashboard?.setDashboardId(this.dashboardId);
    });
    this.route.params.pipe(map(p => p.edit)).subscribe(async (edit) => {
      if (edit) {
        this.editModeRequested = edit == "edit" || edit == "edit-mobile";
        this.editMobileRequested = edit == "edit-mobile";
        this.fullScreenRequested = edit == "fullscreen";
      }
    });
    this.route.queryParamMap.subscribe(async (params) => {
      if (params.keys.length > 0) {
        this.lock.acquire(this.lockkey, async () => {
          let outputs: DashboardOutputChangeParameters[] = await this.getSystemOutputParameters();
          params.keys.forEach((key) => {
            let out = new DashboardOutputChangeParameters();
            out.outputParameterName = key;
            out.value = params.get(key);

            outputs.push(out);
          });

          this.dashboardOutputParameters = outputs;
          await this.xprojDashboard?.setDashboardOutputParameters(this.dashboardOutputParameters);
        }).then(() => {
          // lock released
        });
      }
    }
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.fullScreenRequested = false;
}

  applyNewWidth(width) {
    this.logger.info("applying width: ", width);
    if (width > 850) {
      this.responsiveWidth = width - 350;
      return;
    }
    this.responsiveWidth = width - 50;
  }

  ngAfterViewInit(): void {
    //console.log("ngAfterViewInit window.innerWidth:", window.innerWidth);
    this.applyNewWidth(window.innerWidth);
  }

  ngOnDestroy(): void {
    this.mediaSubscription?.unsubscribe();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.applyNewWidth(event.target.innerWidth);
  }

  async getSystemOutputParameters(): Promise<DashboardOutputChangeParameters[]> {
    let result = [];
    if (this.currentUserRights) {
      let outCustomerId = new DashboardOutputChangeParameters();
      outCustomerId.outputParameterName = 'customerid';
      //outCustomerId.value = this.currentUserRights.session.customerId;
      if (this.state.customerName.length == 0 || this.state.customerId.length == 0) {
        let customer = await this.customerUserClient.getActiveCustomer();
        if (customer) {
          this.state.customerName = customer.name;
          this.state.customerId = customer.id;
        }
      }
      outCustomerId.value = this.state.customerId;
      result.push(outCustomerId);
    }

    return result;
  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters, 100000);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }
}
