import { Component, Input, OnInit, inject } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { Floorplan, Hallway, Room } from '../../models/bim-floor-plan';
import { XPROJBIMSERVICE, XprojBimService, Blob } from 'xproj-lib';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';

@Component({
  selector: 'app-bms-floorplan-editor',
  templateUrl: './bms-floorplan-editor.component.html',
  styleUrls: ['./bms-floorplan-editor.component.scss']
})
export class FloorplanEditorComponent implements OnInit{

  private xprojBimService: XprojBimService = inject(XPROJBIMSERVICE);

  @Input() nodeId : string;
  @Input() nodeTypeId : string;
  @Input() customer : Customer;
  @Input() saveEnabled : boolean = false;

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;

  nrRooms = 0;
  includeHallway = false;

  loadingFloorPlan : ClrLoadingState = ClrLoadingState.DEFAULT;
  showSaved : boolean = false;

  plan : Floorplan;

  constructor()
  {
    this.initFloorplan();
  }


  ngOnInit(): void {
    this.updateFloorplan();
  }

  initFloorplan() {
    this.plan = new Floorplan();
    this.nrRooms = 4;
    this.plan.rooms= [
      {
        area: {
          position_x: 0,
          position_y: 0,
          size_x: 10,
          size_y: 10
        },
        matchesSuffix: "01"
      },
      {
        area: {
          position_x: 0,
          position_y: 10,
          size_x: 10,
          size_y: 10
        },
        matchesSuffix: "02"
      },
      {
        area: {
          position_x: 13,
          position_y: 10,
          size_x: 10,
          size_y: 10
        },
        matchesSuffix: "03"
      },
      {
        area: {
          position_x: 13,
          position_y: 0,
          size_x: 10,
          size_y: 10
        },
        matchesSuffix: "04"
      }
    ];
  }

  async updateFloorplan() {
    try {
      this.loadingFloorPlan = ClrLoadingState.LOADING;
      let blob = await this.xprojBimService.getFloorplanFile(this.nodeId, this.customer.id);
      let json = new TextDecoder().decode(blob.data);
      this.plan = JSON.parse(json);
      this.nrRooms = this.plan.rooms.length;
    }
    catch {
      this.initFloorplan();
    }
    finally {
      this.loadingFloorPlan = ClrLoadingState.DEFAULT;
    }
  }

  async saveFloorplan() : Promise<boolean> {
    if (this.plan) {
      let json = JSON.stringify(this.plan);
      let byteString = json;// window.atob(json);
      let arrayBuffer = new ArrayBuffer(byteString.length);
      let int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      let blob = new Blob();
      blob.data = int8Array;

      let result = await this.xprojBimService.uploadFloorplanFile(this.nodeId, this.nodeId + '_floorplan', 'json',
          blob, this.customer.id);
      if (result) {
        this.showSaved = true;
        setTimeout(() => {
          this.showSaved = false;
        }, 2000);
      }

      return result;
    }

    return false;
  }

  calcBoundingBoxMinX()
  {
    let min = 10000;
    for(let room of this.plan.rooms)
    {
      let lmin = Math.min( room.area.position_x, room.area.position_x+room.area.size_x );
      min = Math.min(lmin, min);
    }
    return min;
  }

  calcBoundingBoxMinY()
  {
    let min = 10000;
    for(let room of this.plan.rooms)
    {
      let lmin = Math.min( -room.area.position_y, -room.area.position_y-room.area.size_y );
      min = Math.min(lmin, min);
    }
    return min;
  }

  calcBoundingBoxMaxX()
  {
    let max = -10000;
    for(let room of this.plan.rooms)
    {
      let lmax = Math.max( room.area.position_x, room.area.position_x+room.area.size_x );
      max = Math.max(lmax, max);
    }
    return max;
  }

  calcBoundingBoxMaxY()
  {
    let max = -10000;
    for(let room of this.plan.rooms)
    {
      let lmax = Math.max( -room.area.position_y, -room.area.position_y-room.area.size_y );
      max = Math.max(lmax, max);
    }
    return max;
  }


  updatePlan()
  {
    let oldNrRooms = this.plan.rooms.length;
    let oldNrHalways = this.plan.hallways.length;

    this.plan.rooms.length = this.nrRooms;
    this.plan.hallways.length = this.includeHallway ? 1 : 0;

    for(let i = oldNrRooms; i < this.plan.rooms.length; i++)
    {
      this.plan.rooms[i] = new Room();
      this.plan.rooms[i].matchesSuffix = (i+1).toString().padStart(2, "0");
    }
    for(let i = oldNrHalways; i < this.plan.hallways.length; i++)
    {
      this.plan.hallways[i] = new Hallway();
    }
  }

}
