import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_XAutoDriver, XAUTO_XAutoDriverGpio, XAUTO_XAutoVariable, XAUTO_XAutoVariableGpio, XAUTO_XAutoVariableGpioMode, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public Physical : XAUTO_XAutoVariableGpio;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public Physical : XAUTO_XAutoDriverGpio;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}


@Component({
  selector: 'xproj-editconnectors-physical',
  templateUrl: './editconnectors-physical.component.html',
  styleUrls: ['./editconnectors-physical.component.scss']
})

export class EditconnectorsPhysicalComponent implements OnInit {

  XAUTO_XAutoVariableGpioMode = XAUTO_XAutoVariableGpioMode;

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;
  public loadingConnectors = false;
  public loadingVariables = false;
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;


  XDataType = XDataType;
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;  
  public selectedVariable : ViewVariable = null;

  constructor(private xprojClient: XProjectorClient) {
  }

  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.Physical = new XAUTO_XAutoVariableGpio();
    this.selectedVariable.Physical.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.Physical.xautovariableid = this.selectedVariable.Variable.xautovariableid;

    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  async saveVariable()
  {
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable physical");
      await this.xprojClient.XAUTO_SaveVariableGpio( this.selectedVariable.Physical );
      console.log("save variable gpio done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;

  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;

    try
    {
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriverGpio(this.selectedConnector.Physical);
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New gpio";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.GPIO;
    this.selectedConnector.Physical = new XAUTO_XAutoDriverGpio();
    this.selectedConnector.Physical.xautodriverid = this.selectedConnector.Driver.xautodriverid;

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {
    this.loadingVariables= true;
    //console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,1000, driver.Driver.xautodriverid);
    for( let j = 0; j < variables.length; j++)
    {
      let variable = variables[j];
      //console.log("loading variable", variable.xautovariableid);
      let Physicalvar = await this.xprojClient.XAUTO_GetVariableGpio(variable.xautovariableid);

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.Physical = Physicalvar;
      driver.Variables.push(newViewVar);
    }
    //console.log("loading variables done: ", driver.Variables);
    this.loadingVariables = false;
  }

  async loadDrivers()
  {
    let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);
    for(let i = 0; i < drivers.length; i++)
    {
      let driver = drivers[i];
      if(!driver || driver.driver != XAUTO_DriverType.GPIO)
        continue;

      let Physicaldriver = await this.xprojClient.XAUTO_GetDriverGpio(driver.xautodriverid);
      let viewdriver = new ViewDriver();
      viewdriver.Driver = driver;
      viewdriver.Physical = Physicaldriver;
      await this.loadVariables(viewdriver);
      this.connectors.push(viewdriver);
    }
  }

  async ngOnInit() {
    await this.loadDrivers();
  }

}
