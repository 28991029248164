import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'xproj_filter_typeof',
  pure:false 
})

@Injectable()
export class XprojFilterFilterTypeofpipePipe implements PipeTransform {
  transform(items: any[], typeName : string): any[] {
    if (!items) return [];
    return items.filter(it => it.constructor.name == typeName);
  }
}
