import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'xproj_filter'
})

@Injectable()
export class XprojFilterpipePipe implements PipeTransform {
  transform(items: any[], field : string, value : string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it =>
    it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
  }
}
