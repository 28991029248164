<clr-alerts>
  <clr-alert *ngFor="let alert of alerts" [clrAlertType]="getAlertType(alert)" [clrAlertAppLevel]="alert.appLevel">
    <clr-alert-item>
      <span class="alert-text">
        {{alert.message}}
      </span>
    </clr-alert-item>
  </clr-alert>

</clr-alerts>
