<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid limit-width" (clrDgRefresh)="refreshFiles($event)"
  [clrDgLoading]="loadingFiles">

  <clr-dg-column [clrDgField]="'id'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'name'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'size'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Size</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'fileFormat'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'tags'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Tags</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'lastModified'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Last modified</ng-container>
  </clr-dg-column>
  <clr-dg-column></clr-dg-column>


  <clr-dg-row *clrDgItems="let fileInfo of fileInfos; let i = index" [clrDgItem]="fileInfo">
    <clr-dg-cell>
      {{fileInfo.id}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{fileInfo.name}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{fileInfo.size > 999 ? (fileInfo.size / 1000).toFixed(0) + ' KB' : fileInfo.size + ' B'}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{fileInfo.fileFormat}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{fileInfo.tags?.join(',')}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{formatDate(fileInfo.lastModified)}}
    </clr-dg-cell>

    <clr-dg-cell>
      <clr-icon (click)=deleteFile(fileInfo.id) style="margin-left: 1em;" shape="trash" [style.cursor]="'pointer'">
      </clr-icon>
      <clr-icon (click)=downloadFile(fileInfo) style="margin-left: 1em;" shape="download" [style.cursor]="'pointer'">
      </clr-icon>
    </clr-dg-cell>

  </clr-dg-row>

  <clr-dg-detail *clrIfDetail="let detail">
    <clr-dg-detail-header>{{detail.name}}</clr-dg-detail-header>
    <img src="{{state.host}}/api/v1/file/nocache/{{detail.id}}">
  </clr-dg-detail>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]" [clrDgTotalItems]="fileCount">
      <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Files per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{fileCount}} Files
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addFile()">
  <clr-icon shape="plus-circle"></clr-icon>
  New file
</button>


<div class="card limit-width" *ngIf="selected">
  <div class="card-header">
    Update file
  </div>

  <div class="card-block">
    <form class="clr-form clr-form-vertical">

      <div class="clr-form-control">
        <label class="clr-control-label">Name</label>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input clrInput placeholder="name" name="update_file_name" [(ngModel)]="selected.name" />
          </div>
        </div>
      </div>

      <div class="clr-form-control">
        <label class="clr-control-label">Tags</label>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input clrInput placeholder="id" name="update_file_id" [(ngModel)]="newtag" />
          </div>
          <button type="button" class="btn btn-link" [disabled]="newtag.length == 0" (click)="addTag(selected)">
            <clr-icon shape="plus-circle"></clr-icon>
            Add tag
          </button>

          <tr *ngFor="let tag of selected?.tags; let i = index">
            <td class="left">
              {{tag}}
            </td>
            <td class="left">
              <clr-icon (click)="removeTag(selected, tag)" style="margin-left: 1em;" shape="trash"
                [style.cursor]="'pointer'">
              </clr-icon>
            </td>
          </tr>

        </div>
      </div>

      <div class="clr-form-control">
        <label class="clr-control-label">File</label>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input clrInput name="file_file" #fileUpdate type="file"
              (change)="uploadFile(selected, fileUpdate.files)" />
          </div>
        </div>
      </div>
    </form>

    <button type="button" class="btn btn-primary" (click)="updateFile()">
      <clr-icon shape="plus-circle"></clr-icon>
      Update file
    </button>

  </div>
</div>

<div class="card limit-width" *ngIf="fileInfo && !selected">
  <div class="card-header">
    Add file
  </div>

  <div class="card-block">
    <form clrForm clrLayout="vertical" autocomplete="off">

      <clr-input-container>
        <label class="clr-col-12">Id</label>
        <input clrInput class="clr-col-12" placeholder="id" name="file_id" [(ngModel)]="fileInfo.id" />
        <clr-control-helper>Leave empty for random id.</clr-control-helper>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12">Name</label>
        <input clrInput class="clr-col-12" placeholder="name" name="file_name" [(ngModel)]="fileInfo.name" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12">Tags</label>
        <input clrInput class="clr-col-12" placeholder="id" name="file_id" [(ngModel)]="newtag" />

        <button type="button" class="btn btn-link" [disabled]="newtag.length == 0" (click)="addTag(fileInfo)">
          <clr-icon shape="plus-circle"></clr-icon>
          Add tag
        </button>

        <tr *ngFor="let tag of fileInfo?.tags; let i = index">
          <td class="left">
            {{tag}}
          </td>
          <td class="left">
            <clr-icon (click)="removeTag(fileInfo, tag)" style="margin-left: 1em;" shape="trash"
              [style.cursor]="'pointer'">
            </clr-icon>
          </td>
        </tr>

      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12">File</label>
        <input clrInput class="clr-col-12" name="file_file" #file type="file" (change)="uploadFile(fileInfo, file.files)" />
      </clr-input-container>

    </form>

    <button type="button" class="btn btn-primary" (click)="upsertFile()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add file
    </button>

  </div>
</div>
