import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, MasterParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { XprojOutputAggregationControllerComponent } from '../output-controller-widget-config/controllers/aggregation/xproj-output-aggregation-controller/xproj-output-aggregation-controller.component';
import { XprojOutputButtonControllerComponent } from '../output-controller-widget-config/controllers/button/xproj-output-button-controller/xproj-output-button-controller.component';
import { XprojOutputComboboxControllerComponent } from '../output-controller-widget-config/controllers/combobox/xproj-output-combobox-controller/xproj-output-combobox-controller.component';
import { XprojOutputProjectionControllerComponent } from '../output-controller-widget-config/controllers/projection/xproj-output-projection-controller/xproj-output-projection-controller.component';
import { XprojOutputRadiobuttonControllerComponent } from '../output-controller-widget-config/controllers/radiobutton/xproj-output-radio-button-controller/xproj-output-radiobutton-controller.component';
import { XprojOutputRangeControllerComponent } from '../output-controller-widget-config/controllers/range/xproj-output-range-controller/xproj-output-range-controller.component';
import { XprojOutputTimeControllerComponent } from '../output-controller-widget-config/controllers/time/xproj-output-time-controller/xproj-output-time-controller.component';
import { XprojOutputToggleControllerComponent } from '../output-controller-widget-config/controllers/toggle/xproj-output-toggle-controller/xproj-output-toggle-controller.component';
import { XprojOutputTransformControllerComponent } from '../output-controller-widget-config/controllers/transform/xproj-output-transform-controller/xproj-output-transform-controller.component';
import { XprojOutputControllerBase } from '../output-controller-widget-config/xproj-output-controller-base';
import { OutputControllerWidgetConfig } from '../output-controller-widget-config/xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-controller-widget',
  templateUrl: './xproj-output-controller-widget.component.html',
  styleUrls: ['./xproj-output-controller-widget.component.scss']
})
export class XprojOutputControllerWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

    //TODO : select all at once!?
  @ViewChildren(XprojOutputAggregationControllerComponent, { read: XprojOutputAggregationControllerComponent }) aggregationControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputTransformControllerComponent, { read: XprojOutputTransformControllerComponent }) transformControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputComboboxControllerComponent, { read: XprojOutputComboboxControllerComponent }) comboboxControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputProjectionControllerComponent, { read: XprojOutputProjectionControllerComponent }) projectionControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputRangeControllerComponent, { read: XprojOutputRangeControllerComponent }) rangeControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputTimeControllerComponent, { read: XprojOutputTimeControllerComponent }) timeControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputToggleControllerComponent, { read: XprojOutputToggleControllerComponent }) toggleControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputRadiobuttonControllerComponent, { read: XprojOutputRadiobuttonControllerComponent }) radioControllers: QueryList<XprojOutputControllerBase>;
  @ViewChildren(XprojOutputButtonControllerComponent, { read: XprojOutputButtonControllerComponent }) buttonsControllers: QueryList<XprojOutputControllerBase>;

  widgetConfig: OutputControllerWidgetConfig;
  id: any;
  initDone: boolean = false;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {
      super(logger, xprojClient, widgetService);
  }

  ngAfterViewInit(): void {

    if (this.widgetConfig.UseApplyButton) {
      setTimeout(() => {
        this.apply();
      }, 100);
    }

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as OutputControllerWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {

  }

  async onInitDone() {
    this.initDone = true;
  }

  async onRefresh() {
    await this.update();
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetSize(this.getHeight(component), component?.width);

    await this.update();
  }

  async onReset() {

  }

  async onUpdateQuery() {
    setTimeout(async () => {
      await ArrayUtils.AsyncForEach(this.aggregationControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.rangeControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.projectionControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.timeControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.comboboxControllers?.toArray(), async (controller) => await controller.onUpdateQuery());
      await ArrayUtils.AsyncForEach(this.toggleControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.radioControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.buttonsControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
      await ArrayUtils.AsyncForEach(this.aggregationControllers?.toArray(), async (controller) => await controller.onUpdateQuery() );
    });
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {

  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {

  }

  private setWidgetSize(height: number, width?: number): void {
    if (height) {
      this.widgetheight = height;
    }
    if (width) {
      this.widgetwidth = width;
    }

  }

  private async update() {

  }

  async apply() {
    let outputs: WidgetOutputChangeParameters[] = [];

    this.aggregationControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.transformControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.rangeControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.projectionControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.timeControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.comboboxControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.toggleControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.radioControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.buttonsControllers.forEach((controller) => {
      controller.getOutputChangeParameters().forEach(out => {
        outputs.push(out);
      });
    });

    this.widgetService.outputParametersChanged(outputs);

    this.sendMasterWidgetChange();
  }

  sendMasterWidgetChange() {
    let params = new LinkedWidgetChangeParameters();
    params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.widgetConfig.Id;
    params.path.push(this.widgetConfig.Id);
    let master = new MasterParameters();

    let projectionController = this.projectionControllers.first as XprojOutputProjectionControllerComponent;

    if (projectionController) {
      master.projectionId = projectionController.selectedProjection?.projectionid;
      master.group = projectionController.selectedGroup;
    }

    // let timeController = this.timeControllers.first as XprojOutputTimeControllerComponent;
    // if (timeController) {
    //   master.time = timeController.getSettings();
    // }

    // params.master = master;

    this.widgetService.linkedWidgetChanged(params);
  }
}
