import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { decodeStream } from '@msgpack/msgpack';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { XprojModalService } from '../xproj-modal-service.service';

@Component({
  selector: 'xproj-modal',
  templateUrl: './xproj-modal.component.html',
  styleUrls: ['./xproj-modal.component.scss']
})
export class XprojModalComponent implements OnInit, OnDestroy {

  data: {
    header: string;
    description: string;
    descriptions: string[];
    cancel: string;
    ok: string;
    size: string;  //'sm', undefined (medium), 'lg', 'xl'
    value: any,
    showCancel: boolean
  } = { header: '', description: '', descriptions: [], ok: 'Ok', cancel: 'Cancel', size: undefined, value: undefined, showCancel: true };

  showConfirmModal: boolean = false;
  showInputModal: boolean = false;
  callback: any;

  ngUnsubscribe = new Subject<void>();

  constructor(private modalService: XprojModalService) {
  }

  ngOnInit(): void {
    this.modalService.showConfirmModalSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ data, callback }) => {
      this.callback = callback;

      this.data.header = data.header;
      if (Array.isArray(data?.description)) {
        this.data.descriptions = data.description;
      }
      else {
        this.data.descriptions = [data.description ?? ''];
      }

      this.data.ok = data.ok ? data.ok : 'Ok';
      this.data.cancel = data.cancel ? data.cancel : 'Cancel';
      this.data.size = data.size ? data.size : undefined;
      this.data.showCancel = data.showCancel;
      this.showConfirmModal = true;
    });

    this.modalService.showInputModalSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ data, callback }) => {
      this.callback = callback;

      this.data.header = data.header;
      this.data.description = data.description;
      this.data.ok = data.ok ? data.ok : 'Ok';
      this.data.cancel = data.cancel ? data.cancel : 'Cancel';
      this.data.size = data.size ? data.size : undefined;
      this.data.value = data.value ? data.value : undefined;
      this.showInputModal = true;
    });

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async cancel() {
    this.showConfirmModal = false;
    this.callback(false);
  }

  async ok() {
    this.showConfirmModal = false;
    this.callback(true);
  }

  async cancelInput() {
    this.showInputModal = false;
    this.callback(false, this.data.value);
  }

  async okInput() {
    this.showInputModal = false;
    this.callback(true, this.data.value);
  }
}
