<div>
  <xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"
    [gridSettings]="gridSettings"></xproj-widget-config>
</div>
<br>

<clr-toggle-container>
  <clr-toggle-wrapper>
    <input type="checkbox" clrToggle value="true" name="xautoconfigured" [(ngModel)]="widgetConfig.XAutoConfigured" />
    <label>XAuto Configured</label>
  </clr-toggle-wrapper>
</clr-toggle-container>

<clr-select-container *ngIf="widgetConfig.XAutoConfigured">
  <label>XAutoProcessGraph</label>
  <select clrSelect id="xautoprocessgraph" name="xautoprocessgraph" [(ngModel)]="widgetConfig.XAutoProcessGraphID">
    <option *ngFor="let pg of processGraphs;" [ngValue]="pg.id">
      {{pg.name}}</option>
  </select>
</clr-select-container>

<br>
<clr-accordion *ngIf="!widgetConfig.XAutoConfigured">
  <clr-accordion-panel>
    <clr-accordion-title>SVG</clr-accordion-title>
    <clr-accordion-content>
      <ngx-monaco-editor class="svg-textarea" [options]="editorOptions" [(ngModel)]="svgData">
      </ngx-monaco-editor>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion *ngIf="!widgetConfig.XAutoConfigured||true">
  <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
    <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
    <clr-accordion-content>
      <xproj-svg-widget-query-config [widgetQuery]="configQuery" [projections]="projections"
        [inputParameters]="widgetConfig.InputParameters" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)">
      </xproj-svg-widget-query-config>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addConfigQuery();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>
