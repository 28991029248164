import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_PLCVariableNumericalMapXAutoVariable, XAUTO_XAutoVariable, XProjectorClient } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'app-edit-variables',
  templateUrl: './edit-variables.component.html',
  styleUrls: ['./edit-variables.component.scss']
})
export class EditVariablesComponent implements OnInit, AfterViewInit {

  @Input() numericalmapxautovariables : XAUTO_PLCVariableNumericalMapXAutoVariable[];
  @Output() numericalmapxautovariablesChange = new EventEmitter<XAUTO_PLCVariableNumericalMapXAutoVariable[]>();

  @Output() onAdd = new EventEmitter<XAUTO_PLCVariableNumericalMapXAutoVariable>();
  @Output() onUpdate = new EventEmitter<XAUTO_PLCVariableNumericalMapXAutoVariable>();
  @Output() onRemove = new EventEmitter<XAUTO_PLCVariableNumericalMapXAutoVariable>();

  loadingVariables = false;
  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;
  variables : XAUTO_XAutoVariable[] = [];
  newVariable : XAUTO_PLCVariableNumericalMapXAutoVariable= null;
  dummyselectedvar: any = null;

  selectedPLCVariable = null;

  IsAddingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  IsUpdatingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private xprojClient: XProjectorClient) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadVariables();
  }

  selectedXAutoVariableChanged(selectedXAutoVariable)
  {
    if(!selectedXAutoVariable)
    {
      this.newVariable = null;
      return;
    }
    this.newVariable = new XAUTO_PLCVariableNumericalMapXAutoVariable();
    this.newVariable.scriptname = selectedXAutoVariable.defaultscriptname;
    this.newVariable.xname = selectedXAutoVariable.name;
    this.newVariable.description = selectedXAutoVariable.description;
    this.newVariable.xautogroup = selectedXAutoVariable.xautogroup;
    this.newVariable.xautovariableid = selectedXAutoVariable.xautovariableid;
    this.newVariable.xgroup = selectedXAutoVariable.xgroup;
  }

  async addVariable()
  {
    this.IsAddingVariable = ClrLoadingState.LOADING;
    this.numericalmapxautovariables.push(this.newVariable);
    this.dummyselectedvar = null;
    this.IsAddingVariable = ClrLoadingState.SUCCESS;
    this.numericalmapxautovariablesChange?.emit(this.numericalmapxautovariables);
    this.onAdd?.next(this.newVariable);
    this.newVariable = null;
  }

  async updateVariable(variable : XAUTO_PLCVariableNumericalMapXAutoVariable)
  {
    this.IsUpdatingVariable = ClrLoadingState.LOADING;
    //this.plcvariables.push(this.newVariable);
    //this.newVariable = null;
    //this.dummyselectedvar = null;
    this.selectedPLCVariable = null;
    this.IsUpdatingVariable = ClrLoadingState.SUCCESS;
    this.onUpdate?.next(this.selectedPLCVariable);
  }

  async removeVariable(variable : XAUTO_PLCVariableNumericalMapXAutoVariable)
  {
    this.IsUpdatingVariable = ClrLoadingState.LOADING;
    this.numericalmapxautovariables = this.numericalmapxautovariables.filter(x => x != variable);
    this.selectedPLCVariable = null;
    this.IsUpdatingVariable = ClrLoadingState.SUCCESS;
    this.numericalmapxautovariablesChange?.emit(this.numericalmapxautovariables);
    this.onRemove?.next(this.selectedPLCVariable);
  }

  cancelUpdateVariable()
  {
    this.selectedPLCVariable = null;
  }

  cancelAddVariable()
  {
    this.newVariable = null;
    this.dummyselectedvar = null;
  }

  async loadVariables()
  {
    this.loadingVariables = true;
    try{
      let vars = await this.xprojClient.XAUTO_GetVariables(0, 100000);
      this.variables.length = 0;
      for(let i = 0; i < vars.length; i++)
      {
        this.variables.push(vars[i]);
      }
    }
    catch{
    }
    this.loadingVariables = false;

  }

}
