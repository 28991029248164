<clr-tabs>
    <clr-tab>
        <button clrTabLink (click)="dashboardTabActive()">Dashboard
            v{{selectedDashboardVersion?.Version}}{{selectedDashboardVersion?.Tag.length > 0 ? ('
            (' + selectedDashboardVersion.Tag + ')') : ''}}</button>
        <clr-tab-content>
            <xproj-dashboard #dashboard [editMode]="editEnabled" [showDashboardSettings]="false"
                [dashboardId]="dashboardId" [enableExport]="true" (onWidgetExport)="onWidgetExport($event)"
                [dashboardOutputParameters]="dashboardOutputParameters" [responsiveWidth]="responsiveWidth"
                [beforeSave]="beforeSave"></xproj-dashboard>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>Versions</button>
        <clr-tab-content>
            <h2>Versions</h2>
            <clr-datagrid class="version-grid" [(clrDgSingleSelected)]="selectedDashboardVersion">
                <clr-dg-column>Version</clr-dg-column>
                <clr-dg-column>Tag</clr-dg-column>

                <clr-dg-row *ngFor="let dashboard of dashboardVersions; let i = index" [clrDgItem]="dashboard">
                    <clr-dg-cell>
                        {{dashboard.Version}}
                    </clr-dg-cell>

                    <clr-dg-cell>
                        {{dashboard.Tag}}
                    </clr-dg-cell>

                </clr-dg-row>
            </clr-datagrid>

            <button *ngIf="!selectedDashboardVersion" type="button" class="btn btn-link" (click)="newVersion()">
                <clr-icon shape="plus-circle"></clr-icon>
                New version
            </button>

            <button *ngIf="selectedDashboardVersion" type="button" class="btn btn-link" (click)="newVersion()">
                <clr-icon shape="plus-circle"></clr-icon>
                New version from selected
            </button>

            <div *ngIf="selectedDashboardVersion" style="max-width: 600px;">
                <div class="card">
                    <!-- <div class="card-header">
              Edit
            </div> -->

                    <div class="card-block">
                        <div class="card-title">
                            Version {{selectedDashboardVersion.Version}}
                        </div>
                        <form clrForm>
                            <clr-input-container>
                                <label class="clr-col-12">Description</label>
                                <input clrInput style="width: 90%;" class="clr-col-12" placeholder=""
                                    name="edit_description" [(ngModel)]="selectedDashboardVersion.Description" />
                            </clr-input-container>
                            <clr-input-container>
                                <label class="clr-col-12">Tag</label>
                                <input clrInput class="clr-col-6" placeholder="" name="edit_tag"
                                    [(ngModel)]="selectedDashboardVersion.Tag" />
                            </clr-input-container>
                        </form>

                        <h2>Test values</h2>
                        <div class="testvalues-grid">
                            <clr-datagrid>
                                <clr-dg-column>Key</clr-dg-column>
                                <clr-dg-column>Value</clr-dg-column>

                                <clr-dg-row *ngFor="let testValue of selectedDashboardVersion.TestValues; let i = index"
                                    [clrDgItem]="testValue">
                                    <clr-dg-cell>
                                        {{testValue.Name}}
                                    </clr-dg-cell>

                                    <clr-dg-cell>
                                        <input class="clr-input" placeholder="" name="testvalue_value{{i}}"
                                            id=testvalue_value{{i}} [(ngModel)]="testValue.Value" />
                                    </clr-dg-cell>

                                </clr-dg-row>
                            </clr-datagrid>
                        </div>

                        <br>

                        <button class="btn btn-outline" (click)="saveSelectedDashboard()"
                            [clrLoading]="savingDashboard">Save</button>
                        <button class="btn btn-danger-outline" [disabled]="selectedDashboardVersion.Tag?.length > 0"
                            (click)="deleteSelectedDashboardVersion()" [clrLoading]="deletingDashboard">Delete
                            version</button>
                    </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>
