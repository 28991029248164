<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid limit-width">

  <clr-dg-column [clrDgField]="'nodeType.displayableId'" [clrDgSortOrder]="ascSort">
    <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'nodeType.id'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'nodeType.label'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Label</ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let nodeType of nodeTypes; let i = index" [clrDgItem]="nodeType">
    <clr-dg-cell>
      {{nodeType.nodeType.displayableId}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{nodeType.nodeType.id}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{nodeType.nodeType.label}}
    </clr-dg-cell>

  </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addNodeType()">
  <clr-icon shape="plus-circle"></clr-icon>
  Add nodetype
</button>
<button *ngIf="selected" type="button" class="btn btn-link" (click)="deleteSelected()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected nodetype
</button>

<div *ngIf="selected">
  <div class="card limit-width">
    <div class="card-header">
      Edit nodetype ({{selected.nodeType.id}})
    </div>

    <div class="card-block">
      <form clrForm>
        <clr-input-container>
          <label>Id</label>
          <input clrInput placeholder="" [disabled]="selected.nodeType.systemDefined" name="id" [(ngModel)]="selected.nodeType.id" />
        </clr-input-container>

        <clr-input-container>
          <label>Displayname</label>
          <input clrInput placeholder="" [disabled]="selected.nodeType.systemDefined" name="displayableId" [(ngModel)]="selected.nodeType.displayableId" />
        </clr-input-container>

        <clr-input-container>
          <label>Label</label>
          <input clrInput placeholder="" [disabled]="selected.nodeType.systemDefined" name="label" [(ngModel)]="selected.nodeType.label" />
        </clr-input-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="selected.nodeType.systemDefined" [(ngModel)]="selected.nodeType.autoGenerateId" name="autogenerategd" />
            <label>Auto Generate Id</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-input-container>
          <label>Name prefix</label>
          <input clrInput placeholder="" [disabled]="selected.nodeType.systemDefined" name="nameprefix" [(ngModel)]="selected.nodeType.namePrefix" />
        </clr-input-container>

        <clr-input-container>
          <label>Name property id</label>
          <input clrInput placeholder="" [disabled]="selected.nodeType.systemDefined" name="namepropertyid" [(ngModel)]="selected.nodeType.namePropertyId" />
        </clr-input-container>

      </form>

      <table class="table table-noborder categories-table">
        <thead>
          <tr>
            <th class="left"><b>Categories</b></th>
            <th *ngIf="!selected.nodeType.systemDefined" > </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let category of selected.fakeCategories; let i = index">
            <td class="left">
              <input class="clr-input" placeholder="" [disabled]="selected.nodeType.systemDefined" name="labeledit_{{i}}" [(ngModel)]="selected.nodeType.categories[i]" />
            </td>
            <td class="left" *ngIf="!selected.nodeType.systemDefined" >
              <clr-icon (click)="moveCategoryUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
              <clr-icon (click)="moveCategoryDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
                  style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
              <clr-icon (click)=removeCategory(i) style="margin-left: 1em;" shape="trash" [style.cursor]="'pointer'" >
              </clr-icon>
            </td>
          </tr>
        </tbody>
      </table>

      <button type="button" class="btn btn-link" (click)="addCategory()" [disabled]="selected.nodeType.systemDefined">
        <clr-icon shape="plus-circle"></clr-icon>
        Add category
      </button>

      <br>

      <label><b>Properties</b></label>
      <app-edit-type-properties [nodeType]="selected.nodeType" [doubleProperties]="selected.nodeType.doubleProperties"
        [stringProperties]="selected.nodeType.stringProperties" [booleanProperties]="selected.nodeType.booleanProperties"
        [dateTimeProperties]="selected.nodeType.dateTimeProperties" [timeSpanProperties]="selected.nodeType.timeSpanProperties"
        [stringOptionsProperties]="selected.nodeType.stringOptionsProperties" [dashboardProperties]="selected.nodeType.dashboardProperties"
        [stringArrayProperties]="selected.nodeType.stringArrayProperties" >
      </app-edit-type-properties>
    </div>

    <button class="btn" [disabled]="selected.nodeType.systemDefined" (click)="saveSelected()">Save</button>

  </div>
</div>
