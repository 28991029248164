import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_XAutoProgram, XAUTO_XAutoProgramLua, XAUTO_XAutoProgramType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';

export class ViewProgram{
  public Program : XAUTO_XAutoProgram;
  public IsSaved : boolean = false;
}


@Component({
  selector: 'xproj-listplcprograms',
  templateUrl: './listplcprograms.component.html',
  styleUrls: ['./listplcprograms.component.scss']
})
export class ListplcprogramsComponent implements OnInit {

  editorOptionsLua = { theme: environment.editortheme, language: 'lua', automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap: { enabled: false } };


  public XAUTO_XAutoProgramType = XAUTO_XAutoProgramType;

  programs = [];
  loadingPrograms = false;
  selectedProgram: any = null;

  collapsed = false;

  showRemoveProgramDialog: boolean = false;

  sizeOptions = [10, 20, 50, 100];

  ascSort = ClrDatagridSortOrder.ASC;

  savingRemovingProgram: ClrLoadingState = ClrLoadingState.DEFAULT;
  savingProgram: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private xprojClient: XProjectorClient) {

  }

  async addProgram()
  {
    let vp = new ViewProgram();

    let program = new XAUTO_XAutoProgram();
    program.name = "New program";
    program.xautoprogramid = uuid.v4();
    program.type = XAUTO_XAutoProgramType.LUA;

    vp.Program = program;

    this.programs.push(vp);
    this.selectedProgram = vp;
  }

  async saveSelectedProgram()
  {
    if(!this.selectedProgram)
    {
      this.savingProgram = ClrLoadingState.ERROR;
    }

    if(this.selectedProgram.IsSaved)
      return;

    this.savingProgram = ClrLoadingState.LOADING;

    try
    {
      await this.xprojClient.XAUTO_SaveProgram(this.selectedProgram.Program);

      if(this.selectedProgram.Program.type == XAUTO_XAutoProgramType.LUA)
      {
        let newLuaDummy = new XAUTO_XAutoProgramLua();
        newLuaDummy.xautoprogramid = this.selectedProgram.Program.xautoprogramid;
        await this.xprojClient.XAUTO_SaveProgramLua(newLuaDummy);
      }
      this.selectedProgram.IsSaved = true;
      this.savingProgram = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingProgram = ClrLoadingState.ERROR;
    }
  }

  async setSelectedProgramEnabled(enable: boolean) {
    if (this.selectedProgram) {
      let old = this.selectedProgram.enabled;
      this.selectedProgram.enabled = enable;
      /*let ok = await this.xprojClient.RequestSaveProjection(this.selectedProgram, false);
      if (!ok) {
        this.selectedProgram.enabled = old;
      }*/
    }
  }

  async doRemoveSelectedProgram() {
    if (this.selectedProgram) {
      /*let ok = await this.xprojClient.RequestRemoveConfigProjection(this.selectedProgram.projectionid);
      if (ok) {
        this.projections = this.projections.filter(p => p != this.selectedProgram);
        this.selectedProgram = null;
      }*/
    }
  }

  async loadPrograms() {
    this.loadingPrograms = true;
    let progies = await this.xprojClient.XAUTO_GetPrograms(0, 1000);
    for(let i = 0; i < progies.length; i++)
    {
      let program = progies[i];
      let vp = new ViewProgram();
      vp.IsSaved = true;
      vp.Program = program;
      this.programs.push(vp);
    }

    this.loadingPrograms = false;
  }

  async ngOnInit() {
    this.loadPrograms();
  }


}
