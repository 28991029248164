import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Projection } from '../../../../../../XProjector/xprojector-client-service';
import { ArrayUtils } from '../../../../../../utils/array-utils-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter } from '../../../../../widget-config-service';
import { BaseControllerConfig, ComboboxMemberConfig, XprojOutputComboboxControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputComboboxControllerQueryConfigComponent } from '../xproj-output-combobox-controller-query-config/xproj-output-combobox-controller-query-config.component';

@Component({
  selector: 'xproj-output-combobox-controller-config',
  templateUrl: './xproj-output-combobox-controller-config.component.html',
  styleUrls: ['./xproj-output-combobox-controller-config.component.scss']
})
export class XprojOutputComboboxControllerConfigComponent implements OnInit {

  @ViewChild("queryConfig", { read: XprojOutputComboboxControllerQueryConfigComponent, static:false }) queryConfig: XprojOutputComboboxControllerQueryConfigComponent;

  @Input() set config (value : BaseControllerConfig)
  {
    this.comboboxConfig = value as XprojOutputComboboxControllerConfig;
  }
  @Input() projections : Projection[] = [];
  @Input() inputParameters : WidgetInputParameter[] = [];
  @Input() widgets : WidgetConfig[];

  comboboxConfig: XprojOutputComboboxControllerConfig;

  selectedMember: ComboboxMemberConfig;

  OutputDataType = OutputDataType;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  addMember() {
    this.comboboxConfig.Members.push(new ComboboxMemberConfig());
  }

  removeSelectedMember() {
    let i = this.comboboxConfig.Members.findIndex(b => b == this.selectedMember);
    if (i > -1) {
      this.removeMember(i);
    }
  }

  removeMember(index: number) {
    ArrayUtils.RemoveItemAt(this.comboboxConfig.Members, index);
  }

  moveMemberUp(index: number) {
    ArrayUtils.MoveItemUp(this.comboboxConfig.Members, index);
    this.refreshMemberConfigs();
  }

  moveMemberDown(index: number) {
    ArrayUtils.MoveItemDown(this.comboboxConfig.Members, index);
    this.refreshMemberConfigs();
  }

  private refreshMemberConfigs() {
    let copy = [...this.comboboxConfig.Members];
    this.comboboxConfig.Members = [];
    this.cdr.detectChanges();
    this.comboboxConfig.Members = copy;
  }

  defaultClick(member : ComboboxMemberConfig) {
    //console.log("click", button, $event);
    if (member.Default) {
      this.comboboxConfig.Members.forEach(btn => {
        if (btn != member) {
          btn.Default = false;
        }
      });
    }
  }

  async saveOutputConfig() {
    this.queryConfig?.SaveQuery();
  }
}
