import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xproj-plcprogramlogs',
  templateUrl: './plcprogramlogs.component.html',
  styleUrls: ['./plcprogramlogs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlcprogramlogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
