<clr-modal [(clrModalOpen)]="showDashboardModal" [clrModalSize]="'xl'" *ngIf="initiated && showDashboardModal">
  <div class="modal-body view-dashboard">
    <xproj-dashboard #dashboard [editMode]="false" [showDashboardSettings]="false" [dashboardId]="dashboardId"
      [enableExport]="false" [dashboardOutputParameters]="dashboardOutputParameters"
      [responsiveWidth]="dashboardResponsiveWidth"></xproj-dashboard>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showMoveModal" *ngIf="initiated && showMoveModal">
  <h3 class="modal-title">Move node to... '{{moveToItem?.name}}'</h3>
  <div class="modal-body">
    <clr-tree *ngIf="treeview">
      <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren" [clrExpanded]="false">
        <a class="clr-treenode-link" routerLinkActive="active" [class.active]="item == moveToItem"
          (click)="moveToItem = item;">
          {{item.name}}
        </a>
      </clr-tree-node>
    </clr-tree>
  </div>
  <div class="modal-footer">
    <clr-spinner *ngIf="moveInProgress" [clrMedium]="true"></clr-spinner>
    <button type="button" class="btn btn-outline" (click)="showMoveModal = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="doMoveNode()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showReadoutModal" [clrModalSize]="'lg'" *ngIf="initiated && showReadoutModal">
  <div class="modal-body view-dashboard">
    <app-readout-treenode [node]="readoutNode"></app-readout-treenode>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showNodeLogsModal" [clrModalSize]="'lg'" *ngIf="initiated && showNodeLogsModal">
  <div class="modal-body view-dashboard">
    <app-view-node-logs [node]="readoutNode"></app-view-node-logs>
  </div>
</clr-modal>

<div *ngIf="this.treeview?.children.length == 0 && initiated && !hideRoot">
  <button *ngFor="let nodetype of rootNodeTypes" type="button" class="btn btn-link" (click)="addRootNode(nodetype)">
    <clr-icon shape="plus-circle"></clr-icon>
    Add {{nodetype.displayableId}}
  </button>
</div>

<clr-tree [clrLazy]="true" *ngIf="initiated" class="noselect">
  <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren;"
    [(clrExpanded)]="item.expanded" [id]="item.id">
    <!-- (click)="selectItem(item); item.expanded = !item.expanded"> -->
    <clr-icon [attr.shape]="item.shape"></clr-icon>
    <a class="clr-treenode-link" [class.active]="this.selectedItems.indexOf(item) >= 0 && highlightSelected"
      routerLinkActive="disable" (click)="selectItem(item, $event)" (dblclick)="dblClick(item, $event)"
      (contextmenu)="selectItem(item, $event); onRightClick($event)"
      [class]="(this.sensitiveNodes.indexOf(item.node.nodeTypeLabel) >= 0 || this.sensitiveNodes.indexOf('*') >= 0) ? 'sensitive':''">
      <span *ngIf="showNodeTypes">{{item.node.nodeTypeLabel}}</span>
      <clr-dropdown *ngIf="editEnabled" [clrCloseMenuOnItemClick]="true" style="margin-right: 1em;">
        <!-- <button clrDropdownTrigger aria-label="Dropdown user button" (click)="onOpenEditMenu(item)">
          <clr-icon shape="pencil"></clr-icon>6
        </button> -->
        <clr-dropdown-menu *clrIfOpen="item?.openDropDown" clrPosition="bottom-left">

          <div *ngIf="!item?.parent" aria-label="Dropdown header Action RefreshTree" clrDropdownItem
            (click)="refreshTreeView()">
            <clr-icon shape="refresh"></clr-icon> Refresh
          </div>

          <div aria-label="Dropdown header Action EditNode" clrDropdownItem (click)="editNode(item)">
            <clr-icon shape="wrench"></clr-icon> Edit node
          </div>

          <div aria-label="Dropdown header Action AddNode" clrDropdownItem (click)="addNode(item)"
            *ngIf="!item?.edgeType?.isReference">
            <clr-icon shape="plus"></clr-icon> Add node
          </div>

          <div aria-label="Dropdown header Action MoveDashboard" clrDropdownItem (click)="moveItem(item)"
            *ngIf="moveEnabled && item.moveEnabled">
            <clr-icon shape="cursor-move"></clr-icon> Move node{{selectedItems.length > 1 ? 's' : ''}}...
          </div>

          <div aria-label="Dropdown header Action DuplicateNode" clrDropdownItem (click)="duplicateNode(item)"
            *ngIf="duplicateEnabled && item.duplicateEnabled && !item?.edgeType?.isReference && item?.parent && selectedItems.length == 1">
            <clr-icon shape="copy"></clr-icon> Duplicate node
          </div>

          <div aria-label="Dropdown header Action DeleteNode" clrDropdownItem (click)="deleteNode(item)"
            *ngIf="item?.parent && selectedItems.length == 1">
            <clr-icon shape="trash"></clr-icon> {{item?.edgeType?.isReference ? 'Delete reference...' : 'Delete node...'
            }}
          </div>

          <div aria-label="Dropdown header Action DeleteNodes" clrDropdownItem (click)="deleteNodes(selectedItems)"
            *ngIf="item?.parent && selectedItems.length > 1">
            <clr-icon shape="trash"></clr-icon> {{item?.edgeType?.isReference ? 'Delete references...' : 'Delete
            nodes...' }}
          </div>

          <div *ngIf="dashboardId?.length > 0" class="dropdown-divider" role="separator" aria-hidden="true"></div>

          <div aria-label="Dropdown header Action ViewDashboard" clrDropdownItem (click)="showModalDashboard()"
            *ngIf="dashboardId?.length > 0">
            <clr-icon shape="line-chart"></clr-icon> View dashboard
          </div>

          <clr-dropdown *ngIf="currentNodeType?.supportsXDataValues">
            <button clrDropdownTrigger>
              <clr-icon shape="dashboard"></clr-icon> Readout
            </button>
            <clr-dropdown-menu>
              <div aria-label="Dropdown header Action Readout Momentary" clrDropdownItem
                (click)="readoutMomentaryValues()">
                Momentary values
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>

          <clr-dropdown *ngIf="currentNodeType?.supportsNodeCommands">
            <button clrDropdownTrigger>
              <clr-icon shape="terminal"></clr-icon> Commands
            </button>
            <clr-dropdown-menu>
              <div *ngFor="let command of currentItemCommands" aria-label="Dropdown header Action Command {command.id}"
                clrDropdownItem (click)="executeCommmand(command)">
                {{command.name}}
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>

          <div aria-label="Dropdown header Action ShowNodeLogs" clrDropdownItem (click)="showNodeLogs()"
            *ngIf="eventLogdEnabled && selectedItems.length == 1">
            <clr-icon shape="event"></clr-icon> View event logs
          </div>

          <div *ngIf="dropDownItems?.length > 0" class="dropdown-divider" role="separator" aria-hidden="true"></div>

          <ng-template ngFor let-dropDownItem [ngForOf]="dropDownItems" let-i="index">
            <div *ngIf="(!dropDownItem.nodeTypeLabel && !dropDownItem.nodeTypeId) || dropDownItem.nodeTypeLabel == item.node.nodeTypeLabel
                  || dropDownItem.nodeTypeId == item.node.nodeTypeId" aria-label="Dropdown header Action Node"
              clrDropdownItem (click)="item.openDropDown = false; dropDownItem.onClick(item)">
              <clr-icon [attr.shape]="dropDownItem.shape"></clr-icon> {{dropDownItem.name}}
            </div>
          </ng-template>
        </clr-dropdown-menu>
      </clr-dropdown>
      <i *ngIf="item?.edgeType?.isReference">{{item?.name}}</i>
      <span *ngIf="!item?.edgeType?.isReference">{{item?.name}}</span>
    </a>
  </clr-tree-node>
</clr-tree>

<clr-modal [(clrModalOpen)]="showAddModal">
  <h3 class="modal-title">Add node</h3>
  <div class="modal-body">
    <form class="clr-form clr-form-vertical" *ngIf="showAddModal">
      <div class="clr-form-control">
        <label>Select nodetype</label>
        <div class="clr-control-container">
          <div class="clr-select-wrapper">
            <select name="select_nodetype" [(ngModel)]="toAdd.node.nodeTypeId"
              (ngModelChange)="onNodeTypeSelect($event)">
              <option *ngFor="let nodetype of addNodeTypeChildNodeTypes" [ngValue]="nodetype.id">
                {{nodetype.displayableId}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <app-edit-treenode #addTreeNode *ngIf="toAdd?.node?.nodeTypeId?.length > 0 && !toAdd.edgeType.isReference"
      [node]="toAdd.node" [parent]="toAdd.parent" [nodeTypes]="nodeTypes" [customerId]="customerId"></app-edit-treenode>

    <div *ngIf="toAdd?.node?.nodeTypeId?.length > 0 && toAdd.edgeType.isReference">
      <h5>Select node</h5>
      <app-configuration-select-node [rootId]="toAdd.reference.referenceRootId"
        [rootLabel]="toAdd.reference.referenceRootLabel" [referenceNodeTypeId]="toAdd.reference.nodeTypeId"
        [rootIsCustomerSpecific]="toAdd.reference.isCustomerReference" [customerId]="customerId"
        [searchParameterIds]="searchParameterIds" [getNodeDisplayName]="getNodeDisplayNameCallback"
        (onNodeSelect)="onAddSelectReference($event)">
      </app-configuration-select-node>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button class="btn" (click)="saveAddedNode()" [disabled]="toAdd?.node?.id.length == 0">Save</button> -->
    <button class="btn btn-outline" (click)="closeAddNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveAddedNode()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditModal">
  <h3 class="modal-title">Edit node ({{currentNodeType?.displayableId}})</h3>
  <div class="modal-body">
    <app-edit-treenode #editTreeNode *ngIf="showEditModal && currentItem" [node]="currentItem.node"
      [customerId]="customerId" [parent]="currentItem.parent" [nodeTypes]="nodeTypes"></app-edit-treenode>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeEditNode()">Cancel</button>
    <button class="btn btn-primary" [clrLoading]="saveState" (click)="saveEditNode()">Ok</button>
  </div>
</clr-modal>
