<clr-modal [(clrModalOpen)]="showRemoveAllColumns">
    <h3 class="modal-title">Remove all columns</h3>
    <div class="modal-body">
      <p>Are you sure?</p>
      <p>This will remove also remove all data!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="showRemoveAllColumns = false">Cancel</button>
      <button type="button" class="btn btn-primary"
        (click)="showRemoveAllColumns = false; doRemoveAllColumns()">Ok</button>
    </div>
  </clr-modal>
  

<div *ngIf="Columns">
    <div class="limit-height">
        <clr-datagrid [(clrDgSingleSelected)]="selectedColumn">

            <clr-dg-column> Name </clr-dg-column>
            <clr-dg-column> Script name (Lua) </clr-dg-column>
            <clr-dg-column> Data type </clr-dg-column>
            <clr-dg-column> Transform (Lua) </clr-dg-column>
            <clr-dg-column> Scriptorder </clr-dg-column>
            <clr-dg-column> Unit </clr-dg-column>
            <clr-dg-column> Tags </clr-dg-column>

            <clr-dg-row *ngFor="let col of Columns" [clrDgItem]="col">
                <!-- <clr-dg-cell class="cellhideoverflow"> {{col.projectioncolumndescriptionid}} </clr-dg-cell> -->
                <clr-dg-cell>
                    {{col.columnname}}
                    <clr-icon *ngIf="col.primarykey" shape="key"></clr-icon>
                </clr-dg-cell>
                <clr-dg-cell> {{col.scriptname}} </clr-dg-cell>
                <clr-dg-cell> {{col.datatype | xproj_prettydatatype}} </clr-dg-cell>
                <!-- <clr-dg-cell class="cellhideoverflow"> {{col.luapostprocessingscript}} </clr-dg-cell> -->

                <clr-dg-cell>
                    <clr-input-container style="margin-top: 0px;">
                        <input clrInput name="luatransformnput{{col.projectioncolumndescriptionid}}" [(ngModel)]="col.luapostprocessingscript" disabled/>
                    </clr-input-container>
                </clr-dg-cell>

                <clr-dg-cell> {{col.luascriptorder}} </clr-dg-cell>
                <clr-dg-cell> {{col.unit}} </clr-dg-cell>
                <clr-dg-cell> {{col.tags}} </clr-dg-cell>

            </clr-dg-row>

            <clr-dg-footer>{{Columns.length}} Columns</clr-dg-footer>

        </clr-datagrid>
    </div>

    <clr-dropdown [clrCloseMenuOnItemClick]="true">
        <button clrDropdownTrigger aria-label="More actions">
          <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
          <!-- <clr-icon shape="caret down"></clr-icon> -->
        </button>
        <clr-dropdown-menu *clrIfOpen>
            <div aria-label="Generate LUA Write code" clrDropdownItem (click)="generateLuaWriteCode()">LUA: Copy write to clipboard</div>
            <div aria-label="Generate LUA Read code" clrDropdownItem (click)="generateLuaReadCode()">LUA: Copy read to clipboard</div>
            <div *ngIf="this.Columns.length==0" aria-label="Generate columns from Excel" clrDropdownItem (click)="generateFromExcel()">Import: Generate columns from Excel</div>
            <div aria-label="Save All" clrDropdownItem (click)="saveAll()">Save All Columns</div>
            <div aria-label="Remove All" clrDropdownItem (click)="removeAll()">Remove All Columns</div>
        </clr-dropdown-menu>
    </clr-dropdown>          


    <button type="button" class="btn btn-link" (click)="NewColumn()">
    <clr-icon shape="plus-circle"></clr-icon>
    Add column
  </button>
    <button *ngIf="selectedColumn" type="button" class="btn btn-link" [clrLoading]="savingRemoveProjectionColumn" (click)="RemoveColumn(selectedColumn); ">
    <clr-icon shape="minus-circle "></clr-icon>
    Remove column
  </button>

    <!-- hack for buttons :D -->
    <hr style="background-color: rgba(0,0, 0, 0);border:none;">

    <div class="dropzone" *ngIf="dropExcelEnabled">
        <ngx-file-drop dropZoneLabel="Drop Excel file here." (onFileDrop)="dropped($event)">
            Drop Excel file here.
        </ngx-file-drop>
        </div>
        <div *ngIf="ExcelImportError!=''">
            Error importing columns from excel file: {{ExcelImportError}}
        </div>
    

    <form clrForm *ngIf="selectedColumn">
        <clr-input-container>
            <label>Column ID </label>
            <input clrInput type="text" [(ngModel)]="selectedColumn.projectioncolumndescriptionid" name="colid" disabled size="35" />
        </clr-input-container>

        <clr-input-container>
            <label>Name </label>
            <input clrInput type="text" [(ngModel)]="selectedColumn.columnname" name="colname" size="35" />
        </clr-input-container>

        <clr-input-container>
            <label>Script name (Lua) </label>
            <input clrInput type="text" [(ngModel)]="selectedColumn.scriptname" name="colscriptname" size="35" />
        </clr-input-container>

        <clr-input-container>
            <label>Script order </label>
            <input clrInput type="number" [(ngModel)]="selectedColumn.luascriptorder" name="colluascriptorder" size="35" />
        </clr-input-container>


        <clr-select-container>
            <label>Data type</label>
            <select clrSelect name="coltype" [(ngModel)]="selectedColumn.datatype">
        <option [ngValue]="XDataType.Float32">Float32</option>
        <option [ngValue]="XDataType.Float64">Float64</option>
        <option [ngValue]="XDataType.UInt8">UInt8</option>
        <option [ngValue]="XDataType.Int32">Int32</option>
        <option [ngValue]="XDataType.Int64">Int64</option>
        <option [ngValue]="XDataType.UInt128">UInt128</option>
        <!-- <option value="5">Number</option> -->
        <option [ngValue]="XDataType.Timestamp">Timestamp</option>
        <option [ngValue]="XDataType.String">String</option>
      </select>
        </clr-select-container>

        <clr-checkbox-container>
            <label>Primary key </label>
            <clr-checkbox-wrapper>
                <input clrCheckbox type="checkbox" value="true" [(ngModel)]="selectedColumn.primarykey" name="colprimkey" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
            <label>Writable </label>
            <clr-checkbox-wrapper>
                <input clrCheckbox type="checkbox" value="true" [(ngModel)]="selectedColumn.writable" name="colwritable" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>


        <clr-input-container>
            <label>Unit </label>
            <input clrInput type="text" [(ngModel)]="selectedColumn.unit" name="colunit" size="35" />
        </clr-input-container>


        <div class="clr-form-control clr-row">
            <label for="colnewtag" class="clr-control-label clr-col-12 clr-col-md-2">Tags</label>
            <div class="clr-control-container clr-col-md-10 clr-col-12">
                <div class="clr-input-wrapper">
                    <input type="text" size="30" name="colnewtag" [(ngModel)]="newColTag" placeholder="New tag" class="clr-input">
                    <button class="btn btn-link btn-sm" (click)="selectedColumn.tags.push(newColTag);newColTag='';">Add</button>
                </div>
                <span class="clr-subtext">
          <xproj-dynclr-mini [addablelabels]="true" [uselabels]="true" [removeablelabels]="true"
            [(data)]="selectedColumn.tags"></xproj-dynclr-mini>
        </span>
            </div>
        </div>


        <div class="clr-form-control clr-row">
            <label for="luapostprocessingscript" class="clr-control-label clr-col-12 clr-col-md-2">Transform (Lua)</label>
            <div class="clr-control-container clr-col-md-10 clr-col-12">
                <!-- [style.height.px]="selectedColumn.luapostprocessingscript == '' ? 30 : 800" name="editcolluatransform"  -->
                <ngx-monaco-editor
                [style.height.px]="getEditorHeight(selectedColumn.luapostprocessingscript)"                                      
                 name="editcolluatransform"                  
                 [options]="editorOptionsLua" [(ngModel)]="selectedColumn.luapostprocessingscript" (onInit)="onEditorInit()">
                </ngx-monaco-editor>
            </div>
        </div>

    </form>

    <div class="btn-group btn-outline" style="margin-top: 2em;margin-bottom: 2em;" *ngIf="standalone && selectedColumn">
        <button class="btn" [clrLoading]="savingProjectionColumn" (click)="SaveColumnDescription(selectedColumn)">Save column</button>
        <!--<button class="btn">Revert</button>-->
    </div>
</div>