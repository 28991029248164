<h1 *ngIf="selectedDatasource">Editing Data source <i>{{selectedDatasource.name}}</i></h1>

<!-- https://www.npmjs.com/package/ngx-monaco-editor -->

<clr-accordion [clrAccordionMultiPanel]="true">

    <clr-accordion-panel [clrAccordionPanelOpen]="true">
        <clr-accordion-title>Basic configuration</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
            <form clrForm *ngIf="!loadingDatasource && selectedDatasource" >
                <clr-input-container>
                    <label>Datasource ID </label>
                    <input clrInput type="text" [(ngModel)]="selectedDatasource.datasourceid" name="projectionid" disabled size="35" />
                </clr-input-container>


                <clr-input-container>
                    <label>Name </label>
                    <input clrInput type="text" [(ngModel)]="selectedDatasource.name" name="name" size="35" />
                </clr-input-container>
                <clr-input-container>
                    <label>Description</label>
                    <input clrInput type="text" [(ngModel)]="selectedDatasource.description" name="description" size="35" />
                </clr-input-container>

                <clr-select-container>
                    <label>Driver</label>
                    <select clrSelect name="coltype" [(ngModel)]="selectedDatasource.driver" [disabled]="!creatingNew" (change)="recreateDriverObject()">
                      <option [ngValue]="DataSourceDriver.Broken">---</option>
                      <option [ngValue]="DataSourceDriver.MongoDB">MongoDB</option>
                      <option [ngValue]="DataSourceDriver.SQLServer">SQLServer</option>
                      <option [ngValue]="DataSourceDriver.Oracle">Oracle</option>
                      <option [ngValue]="DataSourceDriver.PostgreSQL">PostgreSQL</option>
                      <option [ngValue]="DataSourceDriver.MySQL">MySQL</option>
                      <option [ngValue]="DataSourceDriver.SQLite">SQLite</option>
                    </select>
                </clr-select-container>

                <!-- Todo: Refactor this mess -->
                <clr-input-container *ngIf="driverObject &&
                    (selectedDatasource.driver == DataSourceDriver.SQLServer ||
                    selectedDatasource.driver == DataSourceDriver.PostgreSQL ||
                    selectedDatasource.driver == DataSourceDriver.MongoDB ||
                    selectedDatasource.driver == DataSourceDriver.MySQL)">
                    <label>Hostname</label>
                    <input clrInput type="text" [(ngModel)]="driverObject.hostname" name="driverhostname" size="35" />
                </clr-input-container>

                <clr-input-container *ngIf="driverObject &&
                    (selectedDatasource.driver == DataSourceDriver.SQLServer ||
                    selectedDatasource.driver == DataSourceDriver.PostgreSQL ||
                    selectedDatasource.driver == DataSourceDriver.MongoDB ||
                    selectedDatasource.driver == DataSourceDriver.MySQL)">
                    <label>Port</label>
                    <input clrInput type="number" [(ngModel)]="driverObject.port" name="driverport" size="35" />
                </clr-input-container>

                <clr-input-container *ngIf="driverObject &&
                    (selectedDatasource.driver == DataSourceDriver.SQLServer ||
                    selectedDatasource.driver == DataSourceDriver.PostgreSQL ||
                    selectedDatasource.driver == DataSourceDriver.MySQL ||
                    selectedDatasource.driver == DataSourceDriver.MongoDB ||
                    selectedDatasource.driver == DataSourceDriver.SQLite)">
                    <label>Database name</label>
                    <input clrInput type="text" [(ngModel)]="driverObject.databasename" name="driverdbname" size="35" />
                </clr-input-container>

                <clr-input-container *ngIf="driverObject &&
                    (selectedDatasource.driver == DataSourceDriver.SQLServer ||
                    selectedDatasource.driver == DataSourceDriver.PostgreSQL ||
                    selectedDatasource.driver == DataSourceDriver.MongoDB ||
                    selectedDatasource.driver == DataSourceDriver.MySQL)">
                    <label>User name</label>
                    <input clrInput type="text" [(ngModel)]="driverObject.username" name="driveruid" autocomplete="off" size="35" />
                </clr-input-container>

                <clr-input-container *ngIf="driverObject &&
                    (selectedDatasource.driver == DataSourceDriver.SQLServer ||
                    selectedDatasource.driver == DataSourceDriver.PostgreSQL ||
                    selectedDatasource.driver == DataSourceDriver.MongoDB ||
                    selectedDatasource.driver == DataSourceDriver.MySQL)">
                    <label>Password</label>
                    <input clrInput type="password" [(ngModel)]="driverObject.password" name="driverpwd" autocomplete="new-password" size="35" />
                </clr-input-container>

                <clr-select-container *ngIf="driverObject && (selectedDatasource.driver == DataSourceDriver.SQLServer)">
                    <label>Server encoding</label>
                    <select clrSelect name="serverenc" [(ngModel)]="driverObject.serverencoding">
                      <option [ngValue]="encoding_default">Default</option>
                      <option [ngValue]="encoding_cp1252">CP1252</option>
                    </select>
                </clr-select-container>


                <div class="clr-form-control clr-row">
                    <label for="newtag" class="clr-control-label clr-col-12 clr-col-md-2">Tags</label>
                    <div class="clr-control-container clr-col-md-10 clr-col-12">
                        <div class="clr-input-wrapper">
                            <input type="text" size="30" name="newtag" [(ngModel)]="newTag" placeholder="New tag" class="clr-input">
                            <button class="btn btn-link btn-sm" (click)="selectedDatasource.tags.push(newTag);newTag='';">Add</button>
                        </div>
                        <span class="clr-subtext">
                            <xproj-dynclr-mini [addablelabels]="true" [uselabels]="true" [removeablelabels]="true" [(data)]="selectedDatasource.tags"></xproj-dynclr-mini>
                        </span>
                    </div>
                </div>

                <clr-toggle-container>
                    <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle value="true" name="disabled" [(ngModel)]="selectedDatasource.enabled" />
                        <label>Enabled</label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>
            </form>

            <div class="btn-group btn-outline" style="margin-top: 2em;margin-bottom: 2em;">
                <button class="btn" [clrLoading]="savingDatasource" (click)="Save()">Save</button>
                <!-- <button class="btn">Revert</button> -->
            </div>


        </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
        <clr-accordion-title>Query prober</clr-accordion-title>

        <clr-accordion-content>


            <xproj-datasourcequeryprober [codelanguage]="getCodeLanguage()" [datasourceid]="datasourceId"></xproj-datasourcequeryprober>

        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>
