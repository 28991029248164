import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { SvgWidgetConfig, SvgWidgetQuery } from './xproj-svg-widget-config-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widget-config-service';
import { Subscription } from 'rxjs';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Aggregation, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { XprojSvgWidgetQueryConfigComponent } from '../xproj-svg-widget-query-config/xproj-svg-widget-query-config.component';
import { WidgetConfigBase } from '../../widget-config-base';
import { environment } from 'src/environments/environment';
import { XAutoService } from '../../../xautomation/xauto.service';


@Component({
  selector: 'xproj-svg-widget-config',
  templateUrl: './xproj-svg-widget-config.component.html',
  styleUrls: ['./xproj-svg-widget-config.component.scss']
})
export class XprojSvgWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  widgetConfig: SvgWidgetConfig;

  editorOptions = {theme: environment.editortheme, language: 'svg',automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap:{enabled: false}};

  svgData : string = '';

  @ViewChildren(XprojSvgWidgetQueryConfigComponent, { read: XprojSvgWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojSvgWidgetQueryConfigComponent>;

  projections: Projection[] = [];

  processGraphs : { id : string, name : string }[] = [];

  constructor(
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private xautoService : XAutoService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as SvgWidgetConfig;
    if(!this.widgetConfig.XAutoConfigured)
      this.svgData = this.widgetConfig.SvgData;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    if (this.processGraphs.length == 0) {
      this.processGraphs = await this.xautoService.GetProcessGraphsList(0, 10000);
    }
    // if (this.widgetConfig.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
  }

  addConfigQuery(): void {
    this.widgetConfig.ConfigQueries.push(new SvgWidgetQuery());
  }

  onQueryRemoved(query: SvgWidgetQuery) {
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
  }

  async onSaveConfig() {
    if(!this.widgetConfig.XAutoConfigured)
    {
      this.widgetConfig.SvgData = this.svgData;
    }
    else
    {
      this.widgetQueryConfigs.toArray().forEach(queryConfig => {
        queryConfig.SaveQuery();
      });
    }

    this.widgetConfig.OutputParameters = [];

    let output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_matchcolumnvalue';
    output.name = 'MatchColumnValue';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_svgid';
    output.name = 'SvgId';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_columnname';
    output.name = 'ColumnName';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_columnoutname';
    output.name = 'ColumnOutName';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_unit';
    output.name = 'Unit';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_dblclick';
    output.name = 'DblClick';
    output.datatype = OutputDataType.UInt8;
    this.widgetConfig.OutputParameters.push(output);
  }

}
