/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.files.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
import { GRPC_FILES_CLIENT_SETTINGS } from './xprojector.files.pbconf';
/**
 * Service client implementation for files.Files
 */
@Injectable({ providedIn: 'root' })
export class FilesClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /files.Files/GetFileInfo
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GrpcFileInfo>>
     */
    getFileInfo: (
      requestData: thisProto.GetFileInfoRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GrpcFileInfo>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/files.Files/GetFileInfo',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFileInfoRequest,
        responseClass: thisProto.GrpcFileInfo
      });
    },
    /**
     * Unary call: /files.Files/GetFileInfos
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetFileInfosResponse>>
     */
    getFileInfos: (
      requestData: thisProto.GetFileInfosRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetFileInfosResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/files.Files/GetFileInfos',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFileInfosRequest,
        responseClass: thisProto.GetFileInfosResponse
      });
    },
    /**
     * Unary call: /files.Files/DeleteFileInfo
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteFileInfo: (
      requestData: thisProto.DeleteFileInfoRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/files.Files/DeleteFileInfo',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteFileInfoRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Server streaming: /files.Files/GetFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.DataChunk>>
     */
    getFile: (
      requestData: thisProto.GetFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/files.Files/GetFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFileRequest,
        responseClass: xprojectorGrpcModels002.DataChunk
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_FILES_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('files.Files', settings);
  }

  /**
   * Unary call @/files.Files/GetFileInfo
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GrpcFileInfo>
   */
  getFileInfo(
    requestData: thisProto.GetFileInfoRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GrpcFileInfo> {
    return this.$raw
      .getFileInfo(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/files.Files/GetFileInfos
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetFileInfosResponse>
   */
  getFileInfos(
    requestData: thisProto.GetFileInfosRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetFileInfosResponse> {
    return this.$raw
      .getFileInfos(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/files.Files/DeleteFileInfo
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteFileInfo(
    requestData: thisProto.DeleteFileInfoRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteFileInfo(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/files.Files/GetFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.DataChunk>
   */
  getFile(
    requestData: thisProto.GetFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.DataChunk> {
    return this.$raw
      .getFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
