import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RossakerBmsCustomerEventsHistoryComponent } from './rossaker-bms-customer-events-history/rossaker-bms-customer-events-history.component';
import { RossakerBmsCustomerEventsComponent } from './rossaker-bms-customer-events/rossaker-bms-customer-events.component';

const routes: Routes = [
  {
      path: '', component: RossakerBmsCustomerEventsComponent,
      children: [
           { path: 'events', component: RossakerBmsCustomerEventsComponent },
           { path: 'events/:customerid/:eventId', component: RossakerBmsCustomerEventsHistoryComponent }

      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RossakerBmsCustomerRoutingModule { }
