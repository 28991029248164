<!-- <h1 *ngIf="selectedProjection">Editing Program <i>{{selectedProjection.name}}</i></h1> -->


<div *ngIf="!Project.Program">
    <h1>ERROR</h1>
    <p>Program not loaded ( {{programId}} )</p>
</div>

<div class="dashboard-editmenu" *ngIf="Project.Program">
    <clr-tabs>
        <clr-tab>
            <button clrTabLink id="linkHome">Home</button>
            <clr-tab-content id="menuHome" *clrIfActive="true">
                <div style="padding-top:1em;">
                    <div class="btn-group  btn-outline">
                        <button class="btn btn-sm" (click)="Save()" [clrLoading]="savingProgram">
                        <clr-icon shape="floppy" title="Save"></clr-icon>
                        Save
                    </button>
                    </div>
                    <div class="btn-group  btn-outline">
                        <button class="btn btn-sm" (click)="showImportDialog = true;">
                        <clr-icon shape="import" title="import"></clr-icon>
                        Import
                    </button>
                        <button class="btn btn-sm" (click)="showExportDialog = true;">
                        <clr-icon shape="export" title="export"></clr-icon>
                        Export
                    </button>
                    </div>
                </div>

            </clr-tab-content>
        </clr-tab>
        <clr-tab>
            <button clrTabLink id="linkDeploy">Deployment</button>
            <clr-tab-content id="menuDeployment">
                <div style="padding-top:1em;">
                    <div class="btn-group  btn-outline">
                        <button class="btn btn-sm" (click)="Deploy()" [clrLoading]="deployingProgram">
                        <clr-icon shape="install" title="Install"></clr-icon>
                        Install
                    </button>
                    </div>
                    <div class="btn-group  btn-outline">
                        <button class="btn btn-sm" (click)="Run()" [clrLoading]="runningProgram">
                        <clr-icon shape="play" title="Run"></clr-icon>
                        Run
                    </button>
                    </div>

                </div>
            </clr-tab-content>
        </clr-tab>
    </clr-tabs>
</div>

<as-split direction="horizontal" [unit]="'pixel'" [gutterSize]="7" *ngIf="Project.Program">
    <as-split-area [order]="1">

        <as-split direction="vertical" [unit]="'pixel'" [gutterSize]="7" >
            <as-split-area [order]="1">
                <router-outlet name="plcworkspace"></router-outlet>
            </as-split-area>
            <as-split-area [order]="2" [size]="300">
                <pre style="height:80%;">
                    {{Project.LastRunOutput}}
                </pre>
            </as-split-area>
        </as-split>

    </as-split-area>
    <as-split-area [order]="2" [size]="400">

        <clr-tree>
            <clr-tree-node [clrExpanded]="true">
                Program ({{Project.Program.name}})
                <clr-tree-node>
                    <a [routerLink]="[{ outlets: {plcworkspace: ['basic']} }]" class="clr-treenode-link" routerLinkActive="active">Basic configuration</a>
                </clr-tree-node>
                <clr-tree-node>
                    <a [routerLink]="[{ outlets: {plcworkspace: ['io']} }]" class="clr-treenode-link" routerLinkActive="active">I/O</a>
                </clr-tree-node>
                <clr-tree-node>
                    Program
                    <clr-tree-node>
                        <a [routerLink]="[{ outlets: {plcworkspace: ['code', 'init']} }]" class="clr-treenode-link" routerLinkActive="active">Init</a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a [routerLink]="[{ outlets: {plcworkspace: ['code', 'loop']} }]" class="clr-treenode-link" routerLinkActive="active">Loop</a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a [routerLink]="[{ outlets: {plcworkspace: ['code', 'error']} }]" class="clr-treenode-link" routerLinkActive="active">Errors</a>
                    </clr-tree-node>
                </clr-tree-node>
                <clr-tree-node>
                    <a [routerLink]="[{ outlets: {plcworkspace: ['scheduling']} }]" class="clr-treenode-link" routerLinkActive="active">Scheduling</a>
                </clr-tree-node>
                <clr-tree-node>
                    Quality &amp; Debugging
                    <clr-tree-node>
                        <a [routerLink]="[{ outlets: {plcworkspace: ['errorlogs']} }]" class="clr-treenode-link" routerLinkActive="active">Error logs</a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a [routerLink]="[{ outlets: {plcworkspace: ['statistics']} }]" class="clr-treenode-link" routerLinkActive="active">Statistics</a>
                    </clr-tree-node>
                    <clr-tree-node>
                    Attach Debugger
                        <clr-tree-node>
                            <a [routerLink]="[{ outlets: {plcworkspace: ['debugger', 'xauto-plc-loop-' + Project.Program.xautoprogramid]} }]" class="clr-treenode-link" routerLinkActive="active">Attach Loop</a>
                        </clr-tree-node>
                        <clr-tree-node>
                            <a [routerLink]="[{ outlets: {plcworkspace: ['debugger', 'xauto-plc-oninit-' + Project.Program.xautoprogramid]} }]" class="clr-treenode-link" routerLinkActive="active">Attach Init</a>
                        </clr-tree-node>
                        <clr-tree-node>
                            <a [routerLink]="[{ outlets: {plcworkspace: ['debugger', 'xauto-plc-onerror-' + Project.Program.xautoprogramid]} }]" class="clr-treenode-link" routerLinkActive="active">Attach Error</a>
                        </clr-tree-node>
                    </clr-tree-node>

                </clr-tree-node>

            </clr-tree-node>
        </clr-tree>
    </as-split-area>

</as-split>