
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { XProjectBackendSettings, XPROJECTORBACKENDCLIENTSETTINGS } from '../xprojector_backend/xprojector-backend-settings-service';
import { UserRights } from '../models/user-rights';
import { LoginProvider } from '../models/login-provider';
import { Router } from '@angular/router';
import { FileInfo } from '../models/file-info';
import { PageItem} from '../models/page-item';
import { GrpcCustomerContentData } from '../xprojector_backend/proto/xprojector.grpc.models.pb';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { GrpcMetadata } from '@ngx-grpc/common';

@Injectable({
  providedIn: 'root'
})
export class StateService implements OnDestroy {

  public static DisregardVerifyLogin = false;

  private readonly checkLoginIntervalMinutes = 15;

  public metadata: GrpcMetadata;

  private userSubject: BehaviorSubject<UserRights>;
  public user$: Observable<UserRights>;

  public userId: string = '';
  public isAdminUser: boolean = false;
  public isCustomerAdmin: boolean = false;
  public sandboxEnabled: boolean = false;
  public username: string = '';
  public customerName: string = '';
  public customerId: string = '';

  public currentLogLevel: NgxLoggerLevel = NgxLoggerLevel.DEBUG;
  public currentLogLevelServer: NgxLoggerLevel = NgxLoggerLevel.OFF;

  private userFacilitySubject: BehaviorSubject<{ customerConentDatas: GrpcCustomerContentData[] }>;
  public userFacility$: Observable<{ customerConentDatas: GrpcCustomerContentData[] }>;
  public userCustomerContentDatas: GrpcCustomerContentData[] = [];
  public customerContentDatasByCustomer: Map<string, GrpcCustomerContentData[]>;

  private logintimersource;
  private logintimersubscription: Subscription;

  private pages : PageItem[] = [];

  public host: string;

  public constructor(
    public http: HttpClient,
    private router: Router,
    private logger: NGXLogger,
    @Inject(XPROJECTORBACKENDCLIENTSETTINGS) private settings: XProjectBackendSettings) {

    this.host = settings.host;
    this.userSubject = new BehaviorSubject<UserRights>(JSON.parse(localStorage.getItem('user')));
    this.user$ = this.userSubject.asObservable();

    this.userFacilitySubject = new BehaviorSubject<{ customerConentDatas: GrpcCustomerContentData[] }>({ customerConentDatas: this.userCustomerContentDatas });
    this.userFacility$ = this.userFacilitySubject.asObservable();

    this.setSession(this.userSubject.value);
    this.verifyLogin(true);
  }

  ngOnDestroy(): void {
    this.logintimersubscription?.unsubscribe();
  }

  public Exceptions = [];

  public get userRightsValue(): UserRights {
    return this.userSubject.value;
  }

  private HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
    withCredentials: true
  }

  async getLoginProviders(): Promise<LoginProvider[]> {
    let url = this.settings.host + '/api/v1/session/providers';

    return await this.http.get<LoginProvider[]>(url).toPromise();
  }

  async login(customer: string, userid: string, password: string, provider: string): Promise<UserRights> {

    let url = this.settings.host + '/api/v1/session/login';

    let params = {
      'customer': customer,
      'userid': userid,
      'password': password,
      'provider': provider
    }

    let options = {
      ...this.HttpOptions,
      params: params
    };

    try {
      let user = await this.http.post<UserRights>(url, '', options).toPromise();

      this.setSession(user);

      return user;
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  async loginAdmin(userid: string, password: string, provider: string): Promise<UserRights> {

    let url = this.settings.host + '/api/v1/session/login/admin';

    let params = {
      'userid': userid,
      'password': password,
      'provider': provider
    }

    let options = {
      ...this.HttpOptions,
      params: params
    };

    try {
      let user = await this.http.post<UserRights>(url, '', options).toPromise();

      this.setSession(user);

      return user;
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  async loginSocial(customer: string, idtoken: string, userid: string, provider: string): Promise<UserRights> {

    let url = this.settings.host + '/api/v1/session/login/social';

    let params = {
      'customer': customer,
      'idtoken': idtoken,
      'userid': userid,
      'provider': provider
    }

    let options = {
      ...this.HttpOptions,
      params: params
    };

    try {
      let user = await this.http.post<UserRights>(url, '', options).toPromise();

      this.setSession(user);

      return user;
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  async resetPassword(email: string): Promise<boolean> {

    let url = this.settings.host + '/api/v1/session/resetpassword';

    try {
      await this.http.post(url, {email: email}, this.HttpOptions).toPromise();
    }
    catch (err) {
      this.logger.error(err);
    }

    return true;
  }

  async logout(navigateToLogin : boolean = true) {
    let url = this.settings.host + '/api/v1/session';
    try {
      await this.http.delete(url, this.HttpOptions).toPromise();
    }
    catch { }

    localStorage.removeItem('user');
    this.userCustomerContentDatas = [];
    this.userSubject.next(null);
    if (navigateToLogin) {
      if (this.isAdminUser) {
        this.router.navigate(['/login'], { queryParams: { admin: 'true' } });
      }
      else {
        this.router.navigate(['/login']);
      }
    }
  }

  async getActiveSession(): Promise<UserRights> {
    let url = this.settings.host + '/api/v1/session';

    let x = this.http.get<UserRights>(url, this.HttpOptions);

    return x.toPromise();
  }

  async setSession(user: UserRights): Promise<void> {
    if (user) {
      //this.logger.error('set metadata', user.token, user);
      this.metadata = new GrpcMetadata({ 'Authorization': 'Bearer ' + user.token });

      try {
        localStorage.setItem('user', JSON.stringify(user));
      }
      catch {}

      this.userSubject.next(user);

      this.userId = user.session?.userId;
      this.isAdminUser = user.isAdminUser;
      this.isCustomerAdmin = user.isCustomerAdmin;
    }
    else {
      this.metadata = this.metadata = new GrpcMetadata({ 'Authorization': 'Bearer ' });

      localStorage.removeItem('user');
      this.userSubject.next(null);

      this.userId = '';
      this.isAdminUser = false;
      this.isCustomerAdmin = false;
    }

    if (!this.logintimersubscription) {

      this.logintimersource = timer(60 * 1000, this.checkLoginIntervalMinutes * 60 * 1000);
      this.logintimersubscription = this.logintimersource.subscribe(event => {
        this.verifyLogin(true);
      });

    }
  }

  private async verifyLogin(forceServerCheck: boolean = false): Promise<void> {
    if(StateService.DisregardVerifyLogin)
      return;
    if (forceServerCheck) {
      try {
        this.getActiveSession().then((user) =>
          this.setSession(user)
        )
          .catch(() => {
            if (!this.router.url.startsWith('/createcredentials') && !this.router.url.startsWith('/login') && !this.router.url.startsWith('/start-mobile-app')
                && !this.router.url.startsWith('/bmsevent')) {
              this.setSession(null);
              this.userSubject.next(null);
              this.router.navigate(['/login']);
            }
          }
          );
      }
      catch
      {
        if (this.userRightsValue) {
          this.userSubject.next(null);
        }
      }
    }
  }

  updateCustomerConentDatas(customerConentDatas: GrpcCustomerContentData[]) {
    this.userCustomerContentDatas = customerConentDatas;

    this.userFacilitySubject.next({ customerConentDatas: this.userCustomerContentDatas });
  }


  //File service
  async upsertFile(id: string, name: string, fileFormat: string, tags: string[], global: boolean, file, customerId : string = ''): Promise<FileInfo> {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('fileformat', fileFormat);
    formData.append('tags', tags.join(','));
    formData.append('global', global ? 'true' : 'false');
    formData.append('customerid', customerId);
    try {
      formData.append('file', file, 'dummy');
    }
    catch {
    }

    // this.http.post(this.settings.host + '/api/v1/file, formData, {reportProgress: true, observe: 'events'})
    // .subscribe(event => {
    //   if (event.type === HttpEventType.UploadProgress)
    //     this.progress = Math.round(100 * event.loaded / event.total);
    //   else if (event.type === HttpEventType.Response) {
    //     this.message = 'Upload success.';
    //     this.onUploadFinished.emit(event.body);
    //   }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.http.post<FileInfo>(this.settings.host + '/api/v1/file', formData, httpOptions).toPromise();
  }

  addPage(page : PageItem) {
    if (this.pages.findIndex(p => p.id == page.id)) {
      this.pages.push(page);
    }
  }

  getPages() : PageItem[] {
    return this.pages;
  }

}
