import { formatCurrency } from "@angular/common";
import { GrpcFileInfo } from "@xprojectorcore/xprojector_backend/proto/xprojector.files.pb";
import { RecursivePartial } from "@ngx-grpc/common";

export class FileInfo {
  public id : string;
  public name : string;
  public size : number = 0;
  public lastModified : Date;
  public fileFormat : string;
  public tags : string[] = [];

  constructor(_value?: RecursivePartial<GrpcFileInfo>) {
    _value = _value || { size : '0', tags : [] };

    this.id = _value.id;
    this.name = _value.name;
    this.size =  +_value.size;
    this.lastModified = _value.lastModified?.toDate();
    this.fileFormat = _value.fileFormat;
    this.tags = _value.tags;
  }
}
