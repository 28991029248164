import { Component } from '@angular/core';

@Component({
  selector: 'xproj-editintegrationservers-bacnet',
  templateUrl: './editintegrationservers-bacnet.component.html',
  styleUrls: ['./editintegrationservers-bacnet.component.scss']
})
export class EditintegrationserversBacnetComponent {

}
