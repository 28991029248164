/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
export enum GrpcXDataType {
  Float32 = 0,
  Float64 = 1,
  UInt8 = 2,
  Int32 = 3,
  Int64 = 4,
  Number = 5,
  Timestamp = 6,
  String = 7,
  UInt128 = 8
}
/**
 * Message implementation for module.xedge.ScanXEdgeRequest
 */
export class ScanXEdgeRequest implements GrpcMessage {
  static id = 'module.xedge.ScanXEdgeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ScanXEdgeRequest();
    ScanXEdgeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ScanXEdgeRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.remoteNodeId = _instance.remoteNodeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ScanXEdgeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.remoteNodeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ScanXEdgeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ScanXEdgeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.remoteNodeId) {
      _writer.writeString(2, _instance.remoteNodeId);
    }
  }

  private _customerId?: string;
  private _remoteNodeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ScanXEdgeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ScanXEdgeRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.remoteNodeId = _value.remoteNodeId;
    ScanXEdgeRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get remoteNodeId(): string | undefined {
    return this._remoteNodeId;
  }
  set remoteNodeId(value: string | undefined) {
    this._remoteNodeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ScanXEdgeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ScanXEdgeRequest.AsObject {
    return {
      customerId: this.customerId,
      remoteNodeId: this.remoteNodeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ScanXEdgeRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      remoteNodeId: this.remoteNodeId
    };
  }
}
export module ScanXEdgeRequest {
  /**
   * Standard JavaScript object representation for ScanXEdgeRequest
   */
  export interface AsObject {
    customerId?: string;
    remoteNodeId?: string;
  }

  /**
   * Protobuf JSON representation for ScanXEdgeRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    remoteNodeId?: string;
  }
}

/**
 * Message implementation for module.xedge.ScanXEdgeResponse
 */
export class ScanXEdgeResponse implements GrpcMessage {
  static id = 'module.xedge.ScanXEdgeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ScanXEdgeResponse();
    ScanXEdgeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ScanXEdgeResponse) {
    _instance.xedgeXAutoInfos = _instance.xedgeXAutoInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ScanXEdgeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new XEdgeXAutoVar();
          _reader.readMessage(
            messageInitializer1,
            XEdgeXAutoVar.deserializeBinaryFromReader
          );
          (_instance.xedgeXAutoInfos = _instance.xedgeXAutoInfos || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ScanXEdgeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ScanXEdgeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.xedgeXAutoInfos && _instance.xedgeXAutoInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.xedgeXAutoInfos as any,
        XEdgeXAutoVar.serializeBinaryToWriter
      );
    }
  }

  private _xedgeXAutoInfos?: XEdgeXAutoVar[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ScanXEdgeResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ScanXEdgeResponse.AsObject>) {
    _value = _value || {};
    this.xedgeXAutoInfos = (_value.xedgeXAutoInfos || []).map(
      m => new XEdgeXAutoVar(m)
    );
    ScanXEdgeResponse.refineValues(this);
  }
  get xedgeXAutoInfos(): XEdgeXAutoVar[] | undefined {
    return this._xedgeXAutoInfos;
  }
  set xedgeXAutoInfos(value: XEdgeXAutoVar[] | undefined) {
    this._xedgeXAutoInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ScanXEdgeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ScanXEdgeResponse.AsObject {
    return {
      xedgeXAutoInfos: (this.xedgeXAutoInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ScanXEdgeResponse.AsProtobufJSON {
    return {
      xedgeXAutoInfos: (this.xedgeXAutoInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module ScanXEdgeResponse {
  /**
   * Standard JavaScript object representation for ScanXEdgeResponse
   */
  export interface AsObject {
    xedgeXAutoInfos?: XEdgeXAutoVar.AsObject[];
  }

  /**
   * Protobuf JSON representation for ScanXEdgeResponse
   */
  export interface AsProtobufJSON {
    xedgeXAutoInfos?: XEdgeXAutoVar.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for module.xedge.XEdgeXAutoVar
 */
export class XEdgeXAutoVar implements GrpcMessage {
  static id = 'module.xedge.XEdgeXAutoVar';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new XEdgeXAutoVar();
    XEdgeXAutoVar.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: XEdgeXAutoVar) {
    _instance.xAutoVarId = _instance.xAutoVarId || '';
    _instance.xGroup = _instance.xGroup || '';
    _instance.xAutoGroup = _instance.xAutoGroup || '';
    _instance.dataType = _instance.dataType || 0;
    _instance.name = _instance.name || '';
    _instance.unit = _instance.unit || '';
    _instance.xAutoDriverId = _instance.xAutoDriverId || '';
    _instance.writeable = _instance.writeable || false;
    _instance.description = _instance.description || '';
    _instance.lastModified = _instance.lastModified || undefined;
    _instance.installed = _instance.installed || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: XEdgeXAutoVar,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.xAutoVarId = _reader.readString();
          break;
        case 2:
          _instance.xGroup = _reader.readString();
          break;
        case 3:
          _instance.xAutoGroup = _reader.readString();
          break;
        case 4:
          _instance.dataType = _reader.readEnum();
          break;
        case 5:
          _instance.name = _reader.readString();
          break;
        case 6:
          _instance.unit = _reader.readString();
          break;
        case 7:
          _instance.xAutoDriverId = _reader.readString();
          break;
        case 8:
          _instance.writeable = _reader.readBool();
          break;
        case 9:
          _instance.description = _reader.readString();
          break;
        case 10:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.installed = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    XEdgeXAutoVar.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: XEdgeXAutoVar,
    _writer: BinaryWriter
  ) {
    if (_instance.xAutoVarId) {
      _writer.writeString(1, _instance.xAutoVarId);
    }
    if (_instance.xGroup) {
      _writer.writeString(2, _instance.xGroup);
    }
    if (_instance.xAutoGroup) {
      _writer.writeString(3, _instance.xAutoGroup);
    }
    if (_instance.dataType) {
      _writer.writeEnum(4, _instance.dataType);
    }
    if (_instance.name) {
      _writer.writeString(5, _instance.name);
    }
    if (_instance.unit) {
      _writer.writeString(6, _instance.unit);
    }
    if (_instance.xAutoDriverId) {
      _writer.writeString(7, _instance.xAutoDriverId);
    }
    if (_instance.writeable) {
      _writer.writeBool(8, _instance.writeable);
    }
    if (_instance.description) {
      _writer.writeString(9, _instance.description);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        10,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.installed) {
      _writer.writeBool(11, _instance.installed);
    }
  }

  private _xAutoVarId?: string;
  private _xGroup?: string;
  private _xAutoGroup?: string;
  private _dataType?: GrpcXDataType;
  private _name?: string;
  private _unit?: string;
  private _xAutoDriverId?: string;
  private _writeable?: boolean;
  private _description?: string;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _installed?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of XEdgeXAutoVar to deeply clone from
   */
  constructor(_value?: RecursivePartial<XEdgeXAutoVar.AsObject>) {
    _value = _value || {};
    this.xAutoVarId = _value.xAutoVarId;
    this.xGroup = _value.xGroup;
    this.xAutoGroup = _value.xAutoGroup;
    this.dataType = _value.dataType;
    this.name = _value.name;
    this.unit = _value.unit;
    this.xAutoDriverId = _value.xAutoDriverId;
    this.writeable = _value.writeable;
    this.description = _value.description;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.installed = _value.installed;
    XEdgeXAutoVar.refineValues(this);
  }
  get xAutoVarId(): string | undefined {
    return this._xAutoVarId;
  }
  set xAutoVarId(value: string | undefined) {
    this._xAutoVarId = value;
  }
  get xGroup(): string | undefined {
    return this._xGroup;
  }
  set xGroup(value: string | undefined) {
    this._xGroup = value;
  }
  get xAutoGroup(): string | undefined {
    return this._xAutoGroup;
  }
  set xAutoGroup(value: string | undefined) {
    this._xAutoGroup = value;
  }
  get dataType(): GrpcXDataType | undefined {
    return this._dataType;
  }
  set dataType(value: GrpcXDataType | undefined) {
    this._dataType = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }
  get xAutoDriverId(): string | undefined {
    return this._xAutoDriverId;
  }
  set xAutoDriverId(value: string | undefined) {
    this._xAutoDriverId = value;
  }
  get writeable(): boolean | undefined {
    return this._writeable;
  }
  set writeable(value: boolean | undefined) {
    this._writeable = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get installed(): boolean | undefined {
    return this._installed;
  }
  set installed(value: boolean | undefined) {
    this._installed = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    XEdgeXAutoVar.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): XEdgeXAutoVar.AsObject {
    return {
      xAutoVarId: this.xAutoVarId,
      xGroup: this.xGroup,
      xAutoGroup: this.xAutoGroup,
      dataType: this.dataType,
      name: this.name,
      unit: this.unit,
      xAutoDriverId: this.xAutoDriverId,
      writeable: this.writeable,
      description: this.description,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      installed: this.installed
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): XEdgeXAutoVar.AsProtobufJSON {
    return {
      xAutoVarId: this.xAutoVarId,
      xGroup: this.xGroup,
      xAutoGroup: this.xAutoGroup,
      dataType:
        GrpcXDataType[
          this.dataType === null || this.dataType === undefined
            ? 0
            : this.dataType
        ],
      name: this.name,
      unit: this.unit,
      xAutoDriverId: this.xAutoDriverId,
      writeable: this.writeable,
      description: this.description,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      installed: this.installed
    };
  }
}
export module XEdgeXAutoVar {
  /**
   * Standard JavaScript object representation for XEdgeXAutoVar
   */
  export interface AsObject {
    xAutoVarId?: string;
    xGroup?: string;
    xAutoGroup?: string;
    dataType?: GrpcXDataType;
    name?: string;
    unit?: string;
    xAutoDriverId?: string;
    writeable?: boolean;
    description?: string;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    installed?: boolean;
  }

  /**
   * Protobuf JSON representation for XEdgeXAutoVar
   */
  export interface AsProtobufJSON {
    xAutoVarId?: string;
    xGroup?: string;
    xAutoGroup?: string;
    dataType?: string;
    name?: string;
    unit?: string;
    xAutoDriverId?: string;
    writeable?: boolean;
    description?: string;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    installed?: boolean;
  }
}

/**
 * Message implementation for module.xedge.AddXEdgeXAutoMetersRequest
 */
export class AddXEdgeXAutoMetersRequest implements GrpcMessage {
  static id = 'module.xedge.AddXEdgeXAutoMetersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddXEdgeXAutoMetersRequest();
    AddXEdgeXAutoMetersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddXEdgeXAutoMetersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.remoteNodeId = _instance.remoteNodeId || '';
    _instance.xedgeXAutoInfos = _instance.xedgeXAutoInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddXEdgeXAutoMetersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.remoteNodeId = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new XEdgeXAutoVar();
          _reader.readMessage(
            messageInitializer3,
            XEdgeXAutoVar.deserializeBinaryFromReader
          );
          (_instance.xedgeXAutoInfos = _instance.xedgeXAutoInfos || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddXEdgeXAutoMetersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddXEdgeXAutoMetersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.remoteNodeId) {
      _writer.writeString(2, _instance.remoteNodeId);
    }
    if (_instance.xedgeXAutoInfos && _instance.xedgeXAutoInfos.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.xedgeXAutoInfos as any,
        XEdgeXAutoVar.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _remoteNodeId?: string;
  private _xedgeXAutoInfos?: XEdgeXAutoVar[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddXEdgeXAutoMetersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddXEdgeXAutoMetersRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.remoteNodeId = _value.remoteNodeId;
    this.xedgeXAutoInfos = (_value.xedgeXAutoInfos || []).map(
      m => new XEdgeXAutoVar(m)
    );
    AddXEdgeXAutoMetersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get remoteNodeId(): string | undefined {
    return this._remoteNodeId;
  }
  set remoteNodeId(value: string | undefined) {
    this._remoteNodeId = value;
  }
  get xedgeXAutoInfos(): XEdgeXAutoVar[] | undefined {
    return this._xedgeXAutoInfos;
  }
  set xedgeXAutoInfos(value: XEdgeXAutoVar[] | undefined) {
    this._xedgeXAutoInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddXEdgeXAutoMetersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddXEdgeXAutoMetersRequest.AsObject {
    return {
      customerId: this.customerId,
      remoteNodeId: this.remoteNodeId,
      xedgeXAutoInfos: (this.xedgeXAutoInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddXEdgeXAutoMetersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      remoteNodeId: this.remoteNodeId,
      xedgeXAutoInfos: (this.xedgeXAutoInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module AddXEdgeXAutoMetersRequest {
  /**
   * Standard JavaScript object representation for AddXEdgeXAutoMetersRequest
   */
  export interface AsObject {
    customerId?: string;
    remoteNodeId?: string;
    xedgeXAutoInfos?: XEdgeXAutoVar.AsObject[];
  }

  /**
   * Protobuf JSON representation for AddXEdgeXAutoMetersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    remoteNodeId?: string;
    xedgeXAutoInfos?: XEdgeXAutoVar.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for module.xedge.AddXEdgeXAutoMetersResponse
 */
export class AddXEdgeXAutoMetersResponse implements GrpcMessage {
  static id = 'module.xedge.AddXEdgeXAutoMetersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddXEdgeXAutoMetersResponse();
    AddXEdgeXAutoMetersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddXEdgeXAutoMetersResponse) {
    _instance.ok = _instance.ok || false;
    _instance.addedCount = _instance.addedCount || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddXEdgeXAutoMetersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.addedCount = _reader.readInt32();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddXEdgeXAutoMetersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddXEdgeXAutoMetersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.addedCount) {
      _writer.writeInt32(2, _instance.addedCount);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
  }

  private _ok?: boolean;
  private _addedCount?: number;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddXEdgeXAutoMetersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddXEdgeXAutoMetersResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.addedCount = _value.addedCount;
    this.message = _value.message;
    AddXEdgeXAutoMetersResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get addedCount(): number | undefined {
    return this._addedCount;
  }
  set addedCount(value: number | undefined) {
    this._addedCount = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddXEdgeXAutoMetersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddXEdgeXAutoMetersResponse.AsObject {
    return {
      ok: this.ok,
      addedCount: this.addedCount,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddXEdgeXAutoMetersResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      addedCount: this.addedCount,
      message: this.message
    };
  }
}
export module AddXEdgeXAutoMetersResponse {
  /**
   * Standard JavaScript object representation for AddXEdgeXAutoMetersResponse
   */
  export interface AsObject {
    ok?: boolean;
    addedCount?: number;
    message?: string;
  }

  /**
   * Protobuf JSON representation for AddXEdgeXAutoMetersResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    addedCount?: number;
    message?: string;
  }
}
