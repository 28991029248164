import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  GrpcBooleanProperty,
  GrpcDateTimeProperty,
  GrpcDoubleProperty,
  GrpcEdgeType,
  GrpcNodeType,
  GrpcStringOptionsProperty,
  GrpcStringProperty,
  GrpcTimeSpanProperty,
  GrpcDashboardProperty,
  GrpcStringArrayProperty
} from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { GrpcDataEvent } from '@ngx-grpc/common';
import { ArrayUtils } from 'xproj-lib';

@Component({
  selector: 'app-edit-type-properties',
  templateUrl: './edit-type-properties.component.html',
  styleUrls: ['./edit-type-properties.component.scss']
})
export class EditTypePropertiesComponent implements OnInit, AfterViewInit {

  @Input() doubleProperties: GrpcDoubleProperty[] = [];
  @Input() stringProperties: GrpcStringProperty[] = [];
  @Input() stringArrayProperties: GrpcStringArrayProperty[] = [];
  @Input() booleanProperties: GrpcBooleanProperty[] = [];
  @Input() dateTimeProperties: GrpcDateTimeProperty[] = [];
  @Input() timeSpanProperties: GrpcTimeSpanProperty[] = [];
  @Input() stringOptionsProperties: GrpcStringOptionsProperty[] = [];
  @Input() dashboardProperties: GrpcDashboardProperty[] = [];

  @Input()
  get nodeType(): GrpcNodeType { return this._nodeType }
  set nodeType(nodeType: GrpcNodeType) {
    this._nodeType = nodeType;
    setTimeout(() => {
      this.sortProperties();
      this.updateCategories();
    });
  }

  @Input()
  get edgeType(): GrpcEdgeType { return this._edgeType }
  set edgeType(edgeType: GrpcEdgeType) {
    this._edgeType = edgeType;
    setTimeout(() => {
      this.sortProperties();
      this.updateCategories();
    });
  }

  private _nodeType: GrpcNodeType;
  private _edgeType: GrpcEdgeType;

  properties: any[] = [];
  categories: string[] = [];

  _selected: any = null;
  get selected(): any { return this._selected }
  set selected(selected: any) {
    this.selectedChanged(selected);

    this._selected = selected;
  }

  stringoptions: string;

  constructor() { }

  ngOnInit(): void {
    this.sortProperties();
  }

  ngAfterViewInit(): void {

  }

  sortProperties() {
    this.properties = [];
    this.doubleProperties?.forEach(p => this.properties.push(p));
    this.stringProperties?.forEach(p => this.properties.push(p));
    this.stringArrayProperties?.forEach(p => this.properties.push(p));
    this.booleanProperties?.forEach(p => this.properties.push(p));
    this.dateTimeProperties?.forEach(p => this.properties.push(p));
    this.timeSpanProperties?.forEach(p => this.properties.push(p));
    this.stringOptionsProperties?.forEach(p => this.properties.push(p));
    this.dashboardProperties?.forEach(p => this.properties.push(p));

    this.properties = this.properties.sort((a, b) => a.order > b.order ? a : b);
  }

  updateCategories() {
    if (this.nodeType) {
      this.categories = this.nodeType.categories;
    }
    else {
      this.categories = this.edgeType?.categories;
    }
  }

  addProperty(propertyType: string) {
    switch (propertyType) {
      case "string":
        this.stringProperties.push(new GrpcStringProperty({ typeName: 'string', order: this.properties.length }));
        break;
      case "stringarray":
        this.stringArrayProperties.push(new GrpcStringArrayProperty({ typeName: 'stringarray', order: this.properties.length }));
        break;
      case "double":
        this.doubleProperties.push(new GrpcDoubleProperty({ typeName: 'double', order: this.properties.length }));
        break;
      case "boolean":
        this.booleanProperties.push(new GrpcBooleanProperty({ typeName: 'boolean', order: this.properties.length }));
        break;
      case "datetime":
        this.dateTimeProperties.push(new GrpcDateTimeProperty({ typeName: 'datetime', order: this.properties.length }));
        break;
      case "datetime":
        this.stringOptionsProperties.push(new GrpcStringOptionsProperty({ typeName: 'stringoptions', order: this.properties.length }));
        break;
      case "stringoptions":
        this.stringOptionsProperties.push(new GrpcStringOptionsProperty({ typeName: 'stringoptions', order: this.properties.length }));
        break;
      case "dashboard":
        this.dashboardProperties.push(new GrpcDashboardProperty({ typeName: 'dashboard', order: this.properties.length }));
        break;

    }

    this.sortProperties();
  }

  deleteSelectedProperty() {
    if (this.selected) {
      switch (this.selected.typeName) {
        case "string":
          this.stringProperties = this.stringProperties.filter(p => p.id != this.selected.id);
          break;
        case "stringarray":
          this.stringArrayProperties = this.stringArrayProperties.filter(p => p.id != this.selected.id);
          break;
        case "double":
          this.doubleProperties = this.doubleProperties.filter(p => p.id != this.selected.id);
          break;
        case "boolean":
          this.booleanProperties = this.booleanProperties.filter(p => p.id != this.selected.id);
          break;
        case "datetime":
          this.dateTimeProperties = this.dateTimeProperties.filter(p => p.id != this.selected.id);
          break;
        case "stringoptions":
          this.stringOptionsProperties = this.stringOptionsProperties.filter(p => p.id != this.selected.id);
          break;
        case "dashboard":
          this.dashboardProperties = this.dashboardProperties.filter(p => p.id != this.selected.id);
          break;


      }

      this.sortProperties();
    }
  }

  selectedChanged(selected: any) {
    if (this.selected && this.selected.typeName == 'stringoptions') {
      this.selected.options = this.stringoptions.split('|');

      this.stringoptions = selected.options.join(',');
    }
  }

  movePropertyUp(index: number) {
    if (index < 1) {
      return;
    }

    let tmp = this.properties[index].order;
    this.properties[index].order = this.properties[index - 1].order;
    this.properties[index - 1].order = tmp;

    ArrayUtils.MoveItemUp(this.properties, index);
  }

  movePropertyDown(index: number) {
    if (index > this.properties.length - 2) {
      return;
    }

    let tmp = this.properties[index].order;
    this.properties[index].order = this.properties[index + 1].order;
    this.properties[index + 1].order = tmp;

    ArrayUtils.MoveItemDown(this.properties, index);
  }

}
