import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, OnDestroy, ViewChildren } from '@angular/core';
import { GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { SplitAreaDirective } from 'angular-split';
import { XprojAlertService, XProjectorClient, ArrayUtils, XprojModalService, DashboardOutputChangeParameters, XprojDashboardComponent, OutputDataType, DateHelper, LinkedWidgetChangeParameters } from 'xproj-lib';
import { RossakerBmsTrustee } from '@core/models/rossaker-bms-trustee';
import { RossakerBmsBillingPeriod, RossakerBmsExportBotConfig, BmsExportBotExecution, RossakerBmsMonthOfYear } from '@core/models/rossaker-bms-export-bot';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { XbotExecutionResult, XbotExecutionStatus } from '@xprojectorcore/models/xbot-execution-queue-item';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { saveAs } from 'file-saver';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrDatagridStringFilterInterface } from '@clr/angular';
import { StateService } from '@xprojectorcore/services/state-service';
import { RossakerBmsDataExportValue, RossakerBmsDataExportValueStatus } from '@core/models/rossaker-bms-data-export-value';
import { TypedJSON } from 'typedjson';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { UnitConversionsService } from '@xprojectorcore/services/unit-conversions.service';
import { RossakerXProjectorBmsExportClient } from '@core/xprojector_backend/rossaker-xprojector-bms-export-client';
import { RossakerBmsDataUtils } from '@core/utils/rossaker-bms-data-utils';
import { Subject } from 'rxjs';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsDataExportService } from '@core/services/rossaker-bms-data-export-service';


class ExternalIdComparator implements ClrDatagridComparatorInterface<RossakerBmsDataExportValue> {
  compare(a: RossakerBmsDataExportValue, b: RossakerBmsDataExportValue) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

class ExternalIdFilter implements ClrDatagridStringFilterInterface<RossakerBmsDataExportValue> {

  accepts(item: RossakerBmsDataExportValue, search: string) : boolean {
    let result = false;
    let externalId = item.externalId.toLowerCase();
    search.split(',').forEach(s => {
      result = result || externalId.indexOf(s) >= 0;
    });

    return result;
  }
}

class RossakerOverviewCustomerInfo {
  customerId: string;
  customerConfig: RossakerBmsCustomerConfig;
  customerName: string;
  dataExportValues: RossakerBmsDataExportValue[];
  errorCount: number;
  allSigned: boolean;
  allInvoiced: boolean;
  active: boolean;
  status: RossakerBmsDataExportValueStatus;
  exportPeriods: { start: Date, end: Date }[];
  initiated: boolean
}

class RossakerBillingPeriodConfig {
  billingPeriod: RossakerBmsBillingPeriod;
  startMonth: RossakerBmsMonthOfYear;
  exportPeriods: { start: Date, end: Date }[];
  overviewCustomerInfos: RossakerOverviewCustomerInfo[];
  selectedExecExportPeriods: number;
}

enum FlagStatus {
  All = 0,
  OnlyTrue = 1,
  OnlyFalse = 2
}

enum EditAllMode {
  StartValue = 0,
  Tariff = 1
}

const errorFlags: RossakerBmsDataExportValueStatus = RossakerBmsDataExportValueStatus.Error | RossakerBmsDataExportValueStatus.Warning | RossakerBmsDataExportValueStatus.MissingStartValue
  | RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.MissingTariff;

@Component({
  selector: 'app-rossaker-bms-trustee-admin',
  templateUrl: './rossaker-bms-trustee-admin.component.html',
  styleUrls: ['./rossaker-bms-trustee-admin.component.scss']
})
export class RossakerBmsTrusteeAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  trustees: RossakerBmsTrustee[] = [];

  loading: boolean = false;
  overviewLoading: boolean = false;
  selectedTrustee: RossakerBmsTrustee = null;
  selectedBmsCustomer : RossakerBmsCustomer = null;
  inparamTrusteeId: string;
  inparamCustomerId: string;
  selectedTrusteeConfig: RossakerBmsCustomerConfig;
  selectedTrusteeCustomers: GrpcNode[] = [];

  loadingCustomer: boolean = false;
  selectedCustomers = [];

  showAddCustomer: boolean = false;
  addNewCustomerId: string = '';
  customers: GrpcNode[] = [];
  filteredCustomers: GrpcNode[] = [];

  showImportData: boolean = false;
  importDatafile: any;
  importDatafileMetertype: string;
  importDatafileExportPeriod: number = 0;

  exportConfigs: GrpcNode[] = [];
  loadingExport: boolean = false;
  selectedExport: number = -1;
  selectedExportConfigNode: GrpcNode = null;
  selectedExportConfig: RossakerBmsExportBotConfig = null;

  billingPeriodConfigs: RossakerBillingPeriodConfig[] = [];

  execCreateFiles: boolean = false;

  showEditExportConfigs: boolean = false;
  botsDataSourceInstance: GrpcDataSourceInstance;
  dataReportsDataSourceInstance: GrpcDataSourceInstance;

  showEditExportProperties: boolean = false;
  botExecConfig = new BmsExportBotExecution();
  execExportPeriods: { start: Date, end: Date }[] = [];
  customeConfigsByCustomer: Map<string, RossakerBmsCustomerConfig>;
  selectedExecExportPeriods: number = 0;
  execStatusMessage: string = '';

  showExportedFiles: boolean = false;
  exportedFileInfos: FileInfo[] = [];
  selectedExportFileInfo: FileInfo;

  showEditAllExportDataValues: boolean = false;
  showEditAllExportTariffs: boolean = false;
  editAllData: {
    startValue: number,
    setToFirstPeriodValue: boolean,
    overridePreviousValues: boolean,
    tariff?: number
  } = { startValue: 0, setToFirstPeriodValue: true, overridePreviousValues: false };

  gridDetailState: any = null;
  dashboardGridDetailOutputParameters: DashboardOutputChangeParameters[] = [];

  showViewInvoiced: boolean = false;
  invoicedCustomers: string[] = [];

  _overviewActive: boolean = false;
  get overviewActive(): boolean {
    return this._overviewActive;
  }
  set overviewActive(value: boolean) {
    this._overviewActive = value;
    if (this._overviewActive) {
      this.updateCustomerInfos();
    }
  }

  configActive: boolean = false;
  dataReportBasisActive: boolean = false;

  dataBasisOverviewCustomerInfo: RossakerOverviewCustomerInfo;

  //dataBasisCustomerId: string;
  selectedDataBasisExportPeriod: number = 0;
  selectedExportDataValue: RossakerBmsDataExportValue;
  selectedExportDataValueCopy: RossakerBmsDataExportValue;
  selectedExportDataValueEdit: RossakerBmsDataExportValue;
  responsiveWidthEdit: number = 700;
  editExtrapolateStart: Date;
  editExtrapolateStartString: string = '1977-01-01';
  editExtrapolateEnd: Date;
  editExtrapolateEndString: string = '1977-01-01';
  selectedExportDataValueMeterType: string;
  loadingDataExportValues: boolean = false;
  dataExportValues: RossakerBmsDataExportValue[] = [];
  dataExportValuesByMeterType: Map<string, RossakerBmsDataExportValue[]> = new Map<string, RossakerBmsDataExportValue[]>();
  dataPointValueStep: string = '0.01';
  tariffValueStep: string = '0.01';
  dataExportValuesMeterTypes: string[] = [];
  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  responsiveWidth: number = 834;
  showEditExportDataValue: boolean = false;
  selectedExportSignedFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportMissingValueFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportErrorFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportWarningFlagStatus: FlagStatus = FlagStatus.All;
  meterTypeOptionNodes: GrpcNode[] = [];

  overviewCustomerInfos: RossakerOverviewCustomerInfo[] = [];
  // selectedOverviewExportPeriod: number = 0;

  extrapolateAllCurrent: number = 0;
  extrapolateAllTotal: number = 0;
  extrapolateAllPercent: number = 0;
  extrapolateAllRunning: boolean = false;

  deviceIdFilter: string = '';
  exportDataMeterId: number = -1;

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;
  externalIdSort = new ExternalIdComparator();
  externalIdFilter = new ExternalIdFilter();

  rightPanelWidth: number = 300;

  BmsBillingPeriod: RossakerBmsBillingPeriod;
  FlagStatus = FlagStatus;
  EditAllMode = EditAllMode;

  ngUnsubscribe = new Subject<void>();

  constructor(
    private state: StateService,
    private rossakerState: RossakerStateService,
    private router: Router,
    private alertService: XprojAlertService,
    private xConfClient: XProjectorXConfClient,
    private xbotExecutionService: XbotExecutionService,
    private xProjectorFilesClient: XProjectorFilesClient,
    private dataExportService: RossakerBmsDataExportService,
    private adminService: RossakerBmsAdminService,
    private modalService: XprojModalService,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private dateHelper: DateHelper,
    private unitConversions: UnitConversionsService,
    private bmsExportClient: RossakerXProjectorBmsExportClient
  ) { }


  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
        this.inparamTrusteeId = id;
      }
    });
    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerid) => {
      if (customerid) {
        this.inparamCustomerId = customerid;
      }
    });

    this.rossakerState.trustee$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedTrustee = e.trustee;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.selectedTrusteeChanged(null);
    });

    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-bmsrightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }

    if (!this.inparamTrusteeId) {
      let lastTrusteeId = localStorage.getItem("xprojector-trustee-admin-lasttrusteeid");
      if (lastTrusteeId) {
        this.inparamTrusteeId = lastTrusteeId;
      }
    }

    this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);

    //await this.updateTrustees();

    if (this.rossakerState.trustees.length > 0) {
      if (!this.selectedTrustee) {
        this.selectedTrustee = this.rossakerState.trustees[0];
      }

      this.updateTrusteeConfig();
      await this.updateSelectedCustomers();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    // if (this.trustees.length > 0) {
    //   this.selectedTrustee = this.trustees[0];
    //   this.updateTrusteeConfig();
    //   await this.updateSelectedCustomers();
    // }
  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-bmsrightPanelWidth", this.rightPanelWidth.toString());
    }

    let dashboardPaneArea = this.getPaneArea(1);
    if (dashboardPaneArea) {
      this.responsiveWidth = (dashboardPaneArea.elRef.nativeElement.clientWidth * 2) / 3;
    }
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async updateTrusteeConfig() {
    if (this.selectedTrustee) {
      this.selectedTrusteeConfig = await this.adminService.getCustomerConfig(this.selectedTrustee?.id);
      if (this.selectedTrusteeConfig) {
        switch (this.selectedTrusteeConfig.reportDataPointDecimalCount) {
          case 0:
            this.dataPointValueStep = '1';
            break;
          case 1:
            this.dataPointValueStep = '0.1';
            break;
          case 2:
            this.dataPointValueStep = '0.01';
            break;
          case 3:
            this.dataPointValueStep = '0.001';
            break;
          default:
            this.dataPointValueStep = '0.0001';
            break;
        }

        switch (this.selectedTrusteeConfig.tariffDecimalCount) {
          case 0:
            this.tariffValueStep = '1';
            break;
          case 1:
            this.tariffValueStep = '0.1';
            break;
          case 2:
            this.tariffValueStep = '0.01';
            break;
          case 3:
            this.tariffValueStep = '0.001';
            break;
          default:
            this.tariffValueStep = '0.0001';
            break;
        }
      }
    }
  }

  async updateTrustees() {
    this.loading = true;
    try {
      let request = new SearchNodesRequest();
      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_bms_customerconfig_root';
      let p = new SearchProperty();
      p.key = 'customeristrustee';
      p.value = 'true';
      p.typeName = 'boolean';

      request.properties = [p];
      request.propertiesOperatorAnd = true;
      request.limit = 500;
      request.maxHops = 5;
      request.skip = 0;

      let trusteeConfigNodes = await this.xConfClient.searchNodes(request);

      let customrIds: string[] = [];
      trusteeConfigNodes.nodes.forEach(node => {
        let i = node.id.lastIndexOf('_');
        customrIds.push(node.id.substring(i + 1));
      });

      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_datasource';
      request.properties = [];
      request.limit = 500;
      request.maxHops = 1;
      request.skip = 0;
      request.propertiesOperatorAnd = false;

      customrIds.forEach(id => {
        let p = new SearchProperty();
        p.key = '_id';
        p.value = id;
        p.typeName = 'string';
        request.properties.push(p);
      });

      let customerNodes = await this.xConfClient.searchNodes(request);

      this.trustees = [];
      customerNodes.nodes.forEach(node => {
        let trustee = new RossakerBmsTrustee();
        trustee.id = node.id;
        trustee.name = node.name;
        trustee.billingEnabled = RossakerBmsDataUtils.getParamterValue(trusteeConfigNodes.nodes.find(n => n.id.endsWith('_' + node.id)), 'billingenabled', null);
        if (trustee.enabled) {
          this.trustees.push(trustee);
        }
      });

      if (this.inparamTrusteeId?.length > 0) {
        this.selectedTrustee = this.trustees.find(trustee => trustee.id == this.inparamTrusteeId);
      }
    }
    finally {
      this.loading = false;
    }
  }

  async selectedTrusteeChanged($event) {
    this.selectedExport = null;
    this.selectedExport = -1;
    this.selectedExportConfigNode = null;
    this.selectedExportConfig = null;
    this.exportConfigs = [];
    this.selectedCustomers = [];
    this.selectedExportDataValue = null;
    this.selectedExportDataValueEdit = null;
    this.selectedExportDataValueCopy = null;
    this.botsDataSourceInstance = null;
    this.dataReportsDataSourceInstance = null;
    this.customeConfigsByCustomer = null;

    this.overviewActive = true;

    localStorage.setItem("xprojector-trustee-admin-lasttrusteeid", this.selectedTrustee?.id);

    this.updateTrusteeConfig();
    await this.updateExportConfigs();
    await this.updateSelectedCustomers();
  }

  selectedCustomerChanged($event) {
    this.logger.info($event, this.selectedCustomers);
  }

  async updateSelectedCustomers() {
    this.selectedTrusteeCustomers = [];
    if (this.selectedTrustee) {
      this.selectedTrusteeCustomers = await this.xConfClient.getReferencedNodes(
        '_x_bms_trustee_root_' + this.selectedTrustee.id,
        '_x_bms_trustee_root',
        [],
        '_x_datasource'
      );

      this.selectedTrusteeCustomers.sort((a, b) => a.name > b.name ? 1 : -1);
      this.initOverviewCustomerStatusInfo();
    }
  }

  async initOverviewCustomerStatusInfo() {
    if (this.selectedTrusteeCustomers) {
      this.overviewCustomerInfos = [];
      this.selectedTrusteeCustomers.forEach(customer => {
        this.overviewCustomerInfos.push({
          customerId: customer.id,
          customerConfig: undefined,
          customerName: customer.name,
          dataExportValues: [],
          status: RossakerBmsDataExportValueStatus.None,
          errorCount: 0,
          allSigned: false,
          allInvoiced: false,
          active: true,
          exportPeriods: [],
          initiated: false
        });
      });

      this.updateCustomerInfos();
    }
  }

  async updateCustomerInfos() {
    this.overviewLoading = true;
    try {
      await ArrayUtils.AsyncForEach(this.billingPeriodConfigs, async (billingPeriodConfig) => {
        billingPeriodConfig.overviewCustomerInfo = [];
        await this.updateOverviewCustomerInfos(billingPeriodConfig, true);
      });
    }
    finally {
      this.overviewLoading = false;
    }
  }

  async updateOverviewCustomerInfos(billingPeriodConfig: RossakerBillingPeriodConfig, force: boolean = false) {
    await ArrayUtils.AsyncForEach(this.overviewCustomerInfos, async (info: RossakerOverviewCustomerInfo) => {
      if (!info.customerConfig) {
        info.customerConfig = await this.adminService.getCustomerConfig(info.customerId);
      }
    });

    if (billingPeriodConfig.overviewCustomerInfos.length == 0) {
      this.overviewCustomerInfos.forEach((info) => {
        if (info.customerConfig) {
          if (info.customerConfig.billingPeriod == billingPeriodConfig.billingPeriod &&
            (billingPeriodConfig.billingPeriod != RossakerBmsBillingPeriod.Quarter || info.customerConfig.billingPeriodStartMonth == billingPeriodConfig.startMonth)) {
            billingPeriodConfig.overviewCustomerInfos.push(info);
            info.exportPeriods = billingPeriodConfig.exportPeriods;

            //Billing period changed?
            if (info.exportPeriods.length > 0 && info.customerConfig.billingPeriodChangedAt && info.customerConfig.billingPeriodChangedAt > info.exportPeriods[info.exportPeriods.length - 1].start) {
              if (info.customerConfig.billingPeriod == RossakerBmsBillingPeriod.Month) {
                let exportPeriods: { start: Date, end: Date }[] = [];
                let quarters = this.getExportPeriods(RossakerBmsBillingPeriod.Quarter, info.customerConfig.billingPeriodStartMonth);
                for (let i = quarters.length - 1; i >= 0; i--) {
                  if (quarters[i].end < info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(quarters[i]);
                  }
                  else {
                    break;
                  }
                }

                for (let i = info.exportPeriods.length - 1; i >= 0; i--) {
                  if (info.exportPeriods[i].end > info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(info.exportPeriods[i]);
                  }
                }

                info.exportPeriods = exportPeriods.sort((a, b) => a.start < b.start ? 1 : -1);
              }
            }
          }
          else {
            if (info.customerConfig && info.customerConfig.billingPeriod != billingPeriodConfig.billingPeriod
              && info.customerConfig.billingPeriodChangedAt > billingPeriodConfig.exportPeriods[billingPeriodConfig.exportPeriods.length - 1].start) {
              billingPeriodConfig.overviewCustomerInfos.push({
                customerId: info.customerId,
                customerConfig: info.customerConfig,
                customerName: info.customerName,
                dataExportValues: [],
                status: RossakerBmsDataExportValueStatus.None,
                errorCount: 0,
                allSigned: false,
                allInvoiced: false,
                active: true,
                exportPeriods: billingPeriodConfig.exportPeriods,
                initiated: false
              });
            }
          }
        }
      });
    }

    setTimeout(async () => {
      await ArrayUtils.AsyncForEach(billingPeriodConfig.overviewCustomerInfos, async (info: RossakerOverviewCustomerInfo) => {
        if (!info.initiated || force) {
          info.dataExportValues = await this.dataExportService.getDataExportValues({
            customerId: info.customerId,
            status: RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.MissingStartValue
              | RossakerBmsDataExportValueStatus.MissingTariff | RossakerBmsDataExportValueStatus.Error | RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced,
            periodStart: this.dateHelper.utils.addMinutes(info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start, -info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start.getTimezoneOffset()),
            periodEnd: this.dateHelper.utils.addMinutes(info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end, -info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end.getTimezoneOffset())
          });

          info.status = RossakerBmsDataExportValueStatus.None;
          info.errorCount = 0;
          info.allSigned = info.dataExportValues.length > 0;
          info.allInvoiced = info.dataExportValues.length > 0;
          info.dataExportValues.forEach(value => {
            info.status |= (value.status & (RossakerBmsDataExportValueStatus.Error | RossakerBmsDataExportValueStatus.MissingTariff | RossakerBmsDataExportValueStatus.MissingStartValue | RossakerBmsDataExportValueStatus.MissingEndValue));
            if ((value.status & (RossakerBmsDataExportValueStatus.Error | RossakerBmsDataExportValueStatus.MissingTariff | RossakerBmsDataExportValueStatus.MissingStartValue | RossakerBmsDataExportValueStatus.MissingEndValue)) != 0) {
              info.errorCount++;
            }
            info.allSigned &&= ((value.status & RossakerBmsDataExportValueStatus.Signed) > 0);
            info.allInvoiced &&= ((value.status & RossakerBmsDataExportValueStatus.Invoiced) > 0);
          });
          if (info.customerConfig) {
            if (info.customerConfig.billingPeriod == billingPeriodConfig.billingPeriod) {
              info.active = !info.customerConfig.billingPeriodChangedAt || info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start >= info.customerConfig.billingPeriodChangedAt;
            }
            else {
              info.active = !info.customerConfig.billingPeriodChangedAt || billingPeriodConfig.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end < info.customerConfig.billingPeriodChangedAt;
            }

          }
          info.initiated = true;
        }
        this.cdr.markForCheck();
      });
    });
  }

  async selectedOverviewPeriodChange(billingPeriodConfig: RossakerBillingPeriodConfig) {
    this.updateOverviewCustomerInfos(billingPeriodConfig, true);
  }

  async viewCustomerDataExportValues(billingPeriodConfig: RossakerBillingPeriodConfig, info: RossakerOverviewCustomerInfo) {
    if (info) {
      this.selectedDataBasisExportPeriod = billingPeriodConfig.selectedExecExportPeriods; //TODO
      this.dataBasisOverviewCustomerInfo = info;
      this.dataReportBasisActive = true;
      this.updateDataBasis();
      info.initiated = false;
    }
  }

  async updateExportConfigs() {
    if (this.selectedTrustee) {
      try {
        this.loadingExport = true;
        let nodes = await this.xConfClient.getReferencedNodes('_x_botservices_root_' + this.selectedTrustee.id,
          '_x_botservices_root',
          ['_x_botservices_hasbot'],
          '_x_botservices_bot',
          10);

        this.exportConfigs = nodes.filter(node => node.nodeTypeId == '_x_bms_node_exportconfig');

        this.exportConfigs.sort((a, b) => a.name > b.name ? 1 : -1);

        this.billingPeriodConfigs = [];
        this.exportConfigs.forEach(c => {
          let cPeriod = RossakerBmsBillingPeriod[c.propertyValues.find(p => p.key == 'bmsbillingperiod')?.value];
          let cStartMonth = RossakerBmsMonthOfYear[c.propertyValues.find(p => p.key == 'bmsbillingperiodstartmonth')?.value];
          let periodConfig = this.billingPeriodConfigs.find(p => p.billingPeriod == cPeriod && p.startMonth == cStartMonth);
          if (!periodConfig) {
            this.billingPeriodConfigs.push({
              billingPeriod: cPeriod,
              startMonth: cStartMonth,
              exportPeriods: this.getExportPeriods(cPeriod, cStartMonth),
              overviewCustomerInfos: [],
              selectedExecExportPeriods: 1
            });
          }
        });
        if (this.billingPeriodConfigs.length == 0) {
          this.billingPeriodConfigs.push({
            billingPeriod: RossakerBmsBillingPeriod.Month,
            startMonth: RossakerBmsMonthOfYear.March,
            exportPeriods: this.getExportPeriods(RossakerBmsBillingPeriod.Month, RossakerBmsMonthOfYear.March),
            overviewCustomerInfos: [],
            selectedExecExportPeriods: 1
          });
        }
        this.billingPeriodConfigs = this.billingPeriodConfigs.sort((a, b) => a.billingPeriod > b.billingPeriod ? 1 : -1);
      }
      finally {
        this.loadingExport = false;
      }
    }
  }

  selectedExportChanged($index) {
    this.logger.info($index, this.selectedExport);
    if ($index < this.exportConfigs.length) {
      this.selectedExportConfigNode = this.exportConfigs[$index];
      this.selectedExportConfig = new RossakerBmsExportBotConfig();
      this.selectedExportConfig.nodeId = this.selectedExportConfigNode.id;
      this.selectedExportConfig.name = this.selectedExportConfigNode.name;
      this.selectedExportConfigNode.propertyValues.forEach(p => {
        switch (p.key) {
          case 'enabled':
            this.selectedExportConfig.enabled = p.value.toLowerCase() == 'true';
            break;
          case 'createperioddatabasis':
            this.selectedExportConfig.createPeriodDataBasis = p.value.toLowerCase() == 'true';
            break;
          case 'createperioddatareport':
            this.selectedExportConfig.createPeriodDataReport = p.value.toLowerCase() == 'true';
            break;
          case 'schedule':
            this.selectedExportConfig.schedule = p.value;
            break;
          case 'metertypes':
            this.selectedExportConfig.meterTypes = p.value.split('|');
            break;
          case 'bmsbillingperiod':
            this.selectedExportConfig.billingPeriod = RossakerBmsBillingPeriod[p.value];
            break;
          case 'setinvoicedflag':
            this.selectedExportConfig.setInvoicedFlag = p.value.toLowerCase() == 'true';
            break;
          case 'outputonlysigned':
            this.selectedExportConfig.outputOnlySigned = p.value.toLowerCase() == 'true';
            break;
          case 'outputonlynotinvoiced':
            this.selectedExportConfig.outputOnlyNotInvoiced = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactivebilling':
            this.selectedExportConfig.includeStateActiveBilling = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactivenobilling':
            this.selectedExportConfig.includeStateActiveNoBilling = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactiveexport':
            this.selectedExportConfig.includeStateActiveExport = p.value.toLowerCase() == 'true';
            break;
          case 'signokdatavalues':
            this.selectedExportConfig.signOkDatavalues = p.value.toLowerCase() == 'true';
            break;
          case 'qaexportenabled':
            this.selectedExportConfig.qaExportEnabled = p.value.toLowerCase() == 'true';
            break;
          case 'includeStatetest':
            this.selectedExportConfig.includeStateTest = p.value.toLowerCase() == 'true';
            break;
          case 'bmsbillingperiod':
            this.selectedExportConfig.billingPeriod = +p.value;
            if (isNaN(this.selectedExportConfig.billingPeriod)) {
              this.selectedExportConfig.billingPeriod = RossakerBmsBillingPeriod[p.value as string];
            }
            break;
          case 'bmsbillingperiodstartmonth':
            this.selectedExportConfig.billingPeriodStartMonth = +p.value;
            if (isNaN(this.selectedExportConfig.billingPeriodStartMonth)) {
              this.selectedExportConfig.billingPeriodStartMonth = RossakerBmsMonthOfYear[p.value as string];
            }
            break;
          case 'extrapolatemaxdays':
            this.selectedExportConfig.extrapolateMaxDays = +p.value;
            break;
        }
      });
    }
  }

  async onEditExportConfigs() {
    if (!this.botsDataSourceInstance) {
      this.botsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_botservices_' + this.selectedTrustee.id);
    }
    if (!this.dataReportsDataSourceInstance) {
      this.dataReportsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_datareports_' + this.selectedTrustee.id);
    }
    this.showEditExportConfigs = true;
  }

  async onCloseEditExportConfigs() {
    this.showEditExportConfigs = false;
    await this.updateExportConfigs();
  }

  async onRemoveSelectedCustomers() {
    this.modalService.ShowConfirmModal({ header: 'Remove customer', description: 'Remove selected customers, are you sure?' }, async (result) => {
      if (result) {
        await ArrayUtils.AsyncForEach(this.selectedCustomers, async (i) => {
          await this.xConfClient.deleteReference('_x_bms_trustee_root_' + this.selectedTrustee.id, '_x_bms_trustee_root',
          this.selectedTrusteeCustomers[i].id, '_x_datasource', '_x_bms_trusteee_hascustomer', '', this.selectedTrusteeConfig?.customerId);
        });
        this.selectedCustomers = [];
        await this.updateSelectedCustomers();
      }
    });
  }

  async onShowAddCustomer() {
    if (this.customers.length == 0) {
      this.customers = await this.getCustomerNodes();
    }
    this.filteredCustomers = this.customers.filter(customer => this.selectedTrusteeCustomers.findIndex(tc => tc.id == customer.id) == -1);
    this.addNewCustomerId = '';
    this.showAddCustomer = true;
  }

  async onAddCustomer() {
    if (this.addNewCustomerId != '') {
      let result = await this.xConfClient.createReference('_x_bms_trustee_root_' + this.selectedTrustee.id, '_x_bms_trustee_root', this.addNewCustomerId,
          '_x_datasource', '_x_bms_trusteee_hascustomer', true, null, '', this.selectedTrusteeConfig?.customerId);
      if (result.result) {
        await this.updateSelectedCustomers();
        this.alertService.info('Customer added.');
      }
      else {
        this.alertService.error(result.message);
      }
    }
  }

  private async getCustomerNodes(): Promise<GrpcNode[]> {
    let request = new SearchNodesRequest();
    request.rootId = '_x_xconf_customers';
    request.rootLabel = '_x_datasource';
    request.label = '_x_bms_customerconfig_root';

    let p = new SearchProperty();
    p.key = 'customeristrustee';
    p.value = 'false';
    p.typeName = 'boolean';

    let p2 = new SearchProperty();
    p2.key = 'disabled';
    p2.value = 'false';
    p2.typeName = 'boolean';
    request.properties = [p, p2];
    request.propertiesOperatorAnd = true;
    let customerConfigNodes = await this.xConfClient.searchNodes(request);

    request.rootId = '_x_xconf_customers';
    request.rootLabel = '_x_datasource';
    request.label = '_x_datasource';
    request.properties = [];
    request.limit = 2000;
    request.maxHops = 1;
    request.skip = 0;

    let customerNodes = await this.xConfClient.searchNodes(request);

    return customerNodes.nodes.filter(customerNode => customerConfigNodes.nodes.findIndex(t => t.id == '_x_bms_customerconfig_root_' + customerNode.id) > -1)
      .sort((a, b) => a.name > b.name ? 1 : -1);
  }

  async manualExport() {
    this.botExecConfig = new BmsExportBotExecution();
    this.botExecConfig.configNodeId = this.selectedExportConfig?.nodeId;
    this.botExecConfig.meterTypes = [];
    this.selectedExportConfig?.meterTypes.forEach(m => this.botExecConfig.meterTypes.push(m));
    this.botExecConfig.createPeriodDataBasis = this.selectedExportConfig?.createPeriodDataBasis;
    this.botExecConfig.createPeriodDataReport = this.selectedExportConfig?.createPeriodDataReport;
    this.botExecConfig.setInvoicedFlag = this.selectedExportConfig?.setInvoicedFlag;
    this.botExecConfig.outputOnlySigned = this.selectedExportConfig?.outputOnlySigned;
    this.botExecConfig.outputOnlyNotInvoiced = this.selectedExportConfig?.outputOnlyNotInvoiced;
    this.botExecConfig.enableOutput = false;
    this.botExecConfig.testExportEnabled = false;
    this.botExecConfig.bmsBillingPeriod = this.selectedExportConfig.billingPeriod;
    this.botExecConfig.qaExportEnabled = this.selectedExportConfig.qaExportEnabled;
    this.botExecConfig.extrapolateMaxDays = this.selectedExportConfig.extrapolateMaxDays;
    this.botExecConfig.signOkDatavalues = this.selectedExportConfig.signOkDatavalues;
    this.botExecConfig.includeStateTest = this.selectedExportConfig.includeStateTest;
    this.botExecConfig.includeStateActiveBilling = this.selectedExportConfig.includeStateActiveBilling;
    this.botExecConfig.includeStateActiveNoBilling = this.selectedExportConfig.includeStateActiveNoBilling;
    this.botExecConfig.includeStateActiveExport = this.selectedExportConfig.includeStateActiveExport;
    this.botExecConfig.customerIds = [];
    this.selectedCustomers.forEach(i => {
      this.botExecConfig.customerIds.push(this.selectedTrusteeCustomers[i].id);
    });

    this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);
    this.selectedExecExportPeriods = 0;

    if (this.meterTypeOptionNodes.length == 0) {
      this.meterTypeOptionNodes = await this.xConfClient.getReferencedNodesWithCache('_x_bms_metertypes_root', '_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '', 1);
      this.meterTypeOptionNodes = this.meterTypeOptionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }

    this.showEditExportProperties = true;
  }

  getExportPeriods(billingPeriod: RossakerBmsBillingPeriod, startMonth: RossakerBmsMonthOfYear): { start: Date, end: Date }[] {
    let result = [];
    let now = new Date();

    switch (billingPeriod ?? RossakerBmsBillingPeriod.Month) {
      case RossakerBmsBillingPeriod.Week:
        let startOfWeek = this.dateHelper.utils.startOfWeek(now);
        let endOfWeek = this.dateHelper.utils.endOfWeek(now);
        for (let i = 0; i < 13; i++) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, -i), end: this.dateHelper.utils.addWeeks(endOfWeek, -i) });
        }
        break;

      case RossakerBmsBillingPeriod.Month:
        for (let i = 0; i < 25; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case RossakerBmsBillingPeriod.Quarter:
        let month = this.dateHelper.utils.getMonth(now);
        let offset = 0 + ((month + 4 - (1 + ((startMonth - 1) % 3))) % 3);

        for (let i = 0; i < 13; i++) {
          let monthStart = this.dateHelper.utils.addMonths(now, -((i * 3) + offset));
          let monthEnd = this.dateHelper.utils.addMonths(now, -((i * 3) + offset) + 2);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      default:
        for (let i = 1; i < 25; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;
    }

    return result;
  }

  formatByString(date: Date, format: string, displayUTC: boolean = false): string {
    let d = displayUTC ? this.dateHelper.utils.addMinutes(date, date.getTimezoneOffset()) : date;
    return this.dateHelper.utils.formatByString(d, format);
  }

  async onCloseEditExportProperties() {
    if (this.selectedExportConfig) {
      this.execCreateFiles = true;

      if (this.selectedExecExportPeriods < this.execExportPeriods.length) {
        this.botExecConfig.periodStart = this.dateHelper.utils.addMinutes(this.execExportPeriods[this.selectedExecExportPeriods].start, -this.execExportPeriods[this.selectedExecExportPeriods].start.getTimezoneOffset());
        this.botExecConfig.periodEnd = this.dateHelper.utils.addMinutes(this.execExportPeriods[this.selectedExecExportPeriods].end, -this.execExportPeriods[this.selectedExecExportPeriods].end.getTimezoneOffset());
      }

      this.botExecConfig.signOkDatavalues = this.selectedExecExportPeriods == 0 ? false : this.botExecConfig.signOkDatavalues;
      this.botExecConfig.storeOnClient = this.botExecConfig.storeOnClient && this.botExecConfig.createPeriodDataReport;

      this.xbotExecutionService.executeBot('bms_export', JSON.stringify(this.botExecConfig), 300, 100, 'trusteeadmin', this.state.userId).subscribe(
        result => {
          if (result.result == XbotExecutionResult.STATUS) {
            this.execStatusMessage = result.message;
          }
          else if (result.result == XbotExecutionResult.OK) {
            this.alertService.success('Executed ok: ' + result.message);
            if (this.botExecConfig.storeOnClient) {
              this.xProjectorFilesClient.getFile(this.selectedExportConfig.nodeId, this.selectedTrustee.id)
                .then(blob => {
                  saveAs(blob, this.selectedTrustee.name + ".zip");
                })
                .catch(error => {
                  this.alertService.error(error);
                }
                );
            }
            if (result.message?.length > 0) {
              this.invoicedCustomers = result.message.split(',');
              this.showViewInvoiced = true;
              //this.modalService.ShowConfirmModal({ header: 'Invoiced customers', description: 'Invoiced customers: ' + result.message, showCancel: false }, (result) => {});
            }
          }
          else if (result.result == XbotExecutionResult.CANCELED) {
            this.alertService.error('Execution canceled.');
          }
          else {
            this.alertService.error(result.message);
          }
        },
        error => {
          this.execCreateFiles = false;
          this.alertService.error(error.message);
        },
        () => {
          this.execStatusMessage = '';
          this.execCreateFiles = false;
        }
      )
    }
  }

  async viewExportedFiles() {
    if (this.selectedExportConfig) {
      this.selectedExportFileInfo = null;
      let fileInfos = await this.xProjectorFilesClient.getFileInfos(['bmsexport'], 100, 0, this.selectedTrustee.id);
      this.exportedFileInfos = fileInfos.files.filter(info => info.id.startsWith(this.selectedExportConfig.nodeId) && info.tags.includes('bmsexport'));
      this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);
      if (!this.customeConfigsByCustomer && this.billingPeriodConfigs) {
        this.customeConfigsByCustomer = new Map<string, RossakerBmsCustomerConfig>();
        this.billingPeriodConfigs.forEach(billingPeriodConfig => {
          billingPeriodConfig.overviewCustomerInfos.forEach(info => {
            this.customeConfigsByCustomer.set(info.customerId, info.customerConfig);
          });
        });
      }
      this.showExportedFiles = true;
    }
  }

  selectedExportFileInfoChanged(fileInfo: FileInfo) {

  }

  async omDownloadExportedFile() {
    if (this.selectedExportFileInfo) {
      this.xProjectorFilesClient.getFile(this.selectedExportFileInfo.id, this.selectedTrustee.id)
        .then(blob => {
          if (this.selectedExportFileInfo.name.startsWith('Export')) {
            saveAs(blob, this.selectedTrustee.name + ' ' + this.selectedExportFileInfo.name + ".zip");
          }
          else {
            saveAs(blob, this.selectedExportFileInfo.name);
          }
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  formatDate(date: Date, displayUTC: boolean = false): string {
    return this.dateHelper.utils.formatByString(displayUTC ? this.dateHelper.utils.addMinutes(date, -date.getTimezoneOffset()) : date, "yyyy-MM-dd HH:mm:ss");
  }

  async selectedDataBasisCustomerChange($event) {
    this.updateDataBasis();
  }

  async selectedDataBasisPeriodChange($event) {
    this.updateDataBasis();
  }

  onSelectTabDataReportBasis($event) {
    if (this.overviewCustomerInfos?.length > 0) {
      this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[0]
      this.updateDataBasis();
    }
  }

  async updateDataBasis() {
    if (this.dataBasisOverviewCustomerInfo) {
      try {
        this.deviceIdFilter = '';
        this.loadingDataExportValues = true;
        this.dataExportValues = [];

        let dataExportValues = await this.dataExportService.getDataExportValues({
          customerId: this.dataBasisOverviewCustomerInfo.customerId,
          periodStart: this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start.getTimezoneOffset()),
          periodEnd: this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end.getTimezoneOffset())
        });

        dataExportValues.forEach(dataExportValue => {
          if (dataExportValue.status & (RossakerBmsDataExportValueStatus.MissingStartValue | RossakerBmsDataExportValueStatus.MissingEndValue)) {
            dataExportValue.diff = -9999999999;
          }
          else {
            dataExportValue.diff = +(dataExportValue.endValue - dataExportValue.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
          }

          dataExportValue.startValue = +dataExportValue.startValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
          dataExportValue.endValue = +dataExportValue.endValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
          dataExportValue.tariff = +dataExportValue.tariff.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.tariffDecimalCount);
          dataExportValue.vatRate = +dataExportValue.vatRate.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.tariffDecimalCount);
        });

        this.dataExportValuesByMeterType = ArrayUtils.GroupBy(dataExportValues, 'meterType');
        this.dataExportValuesByMeterType['ALL METERTYPES'] = dataExportValues;
        this.dataExportValuesMeterTypes = [];
        let tmp = Object.entries(this.dataExportValuesByMeterType).map(([metertype, data]) => ({ metertype, data }));
        tmp.forEach(kvp => this.dataExportValuesMeterTypes.push(kvp.metertype));
        this.dataExportValuesMeterTypes.sort((a, b) => a > b ? 1 : -1);

        if (this.exportDataMeterId > 0) {
          this.dataExportValues = dataExportValues.filter(dataValue => dataValue.meterId == this.exportDataMeterId);
        }
        else if (this.dataExportValuesMeterTypes.length > 0) {
          if (this.dataExportValuesMeterTypes.findIndex(mt => mt == this.selectedExportDataValueMeterType) < 0) {
            this.selectedExportDataValueMeterType = this.dataExportValuesMeterTypes[0];
          }

          this.dataExportValues = this.dataExportValuesByMeterType[this.selectedExportDataValueMeterType];
          if (this.selectedExportSignedFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportSignedFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & RossakerBmsDataExportValueStatus.Signed) > 0)
              || (this.selectedExportSignedFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & RossakerBmsDataExportValueStatus.Signed) == 0)
            )
          }

          if (this.selectedExportMissingValueFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportMissingValueFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & (RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.MissingStartValue)) == 0)
              || (this.selectedExportMissingValueFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & (RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.MissingStartValue)) > 0)
            )
          }

          if (this.selectedExportErrorFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportErrorFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & errorFlags) > 0)
              || (this.selectedExportErrorFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & errorFlags) == 0)
            )
          }

          if (this.selectedExportWarningFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportWarningFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & RossakerBmsDataExportValueStatus.Warning) > 0)
              || (this.selectedExportWarningFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & RossakerBmsDataExportValueStatus.Warning) == 0)
            )
          }

        }
      }
      finally {
        this.loadingDataExportValues = false;
        this.exportDataMeterId = -1;
      }
    }
  }

  async selectedExportDataValueChanged($event) {
    try {
      if (this.selectedExportDataValue) {
        this.selectedExportDataValueCopy = TypedJSON.parse(TypedJSON.stringify(this.selectedExportDataValue, RossakerBmsDataExportValue), RossakerBmsDataExportValue);

        if (this.selectedExportDataValueCopy.startValue < 0) {
          this.selectedExportDataValueCopy.startValue = -1;
        }
        if (this.selectedExportDataValueCopy.endValue < 0) {
          this.selectedExportDataValueCopy.endValue = -1;
        }

        let outputs: DashboardOutputChangeParameters[] = [];
        let out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'MeterId';
        out.value = this.selectedExportDataValueCopy.meterId;
        outputs.push(out);

        let outStart = new DashboardOutputChangeParameters();
        outStart.outputParameterName = 'Start';
        outStart.value = this.dateHelper.utils.addDays(this.selectedExportDataValueCopy.start, -1);
        outputs.push(outStart);

        let outEnd = new DashboardOutputChangeParameters();
        outEnd.outputParameterName = 'End';
        outEnd.value = this.dateHelper.utils.addDays(this.selectedExportDataValueCopy.end, 1);
        outputs.push(outEnd);

        this.dashboardOutputParameters = outputs;

        this.cdr.detectChanges();
      }
    }
    catch { }
  }

  async onEditSelected() {
    this.selectedExportDataValueEdit = this.selectedExportDataValue;
    this.selectedExportDataValueCopy = TypedJSON.parse(TypedJSON.stringify(this.selectedExportDataValue, RossakerBmsDataExportValue), RossakerBmsDataExportValue);
    await this.onGridDetailChange(this.selectedExportDataValueCopy);
    this.showEditExportDataValue = true;
  }

  async selectedDataBasisMeterTypeChange($event) {
    this.dataExportValues = this.dataExportValuesByMeterType[this.selectedExportDataValueMeterType];
  }

  async saveSelectedDataExportvalue() {
    try {
      if (this.selectedExportDataValueCopy) {
        this.selectedExportDataValueCopy.modifiedAt = new Date();

        if ((this.selectedExportDataValueCopy.startValue >= 0 && this.selectedExportDataValueCopy.startValue != this.selectedExportDataValueEdit.startValue)
          || (this.selectedExportDataValueCopy.endValue >= 0 && this.selectedExportDataValueCopy.endValue != this.selectedExportDataValueEdit.endValue)) {
          this.selectedExportDataValueCopy.status &= ~RossakerBmsDataExportValueStatus.AutomaticEstimate;
          this.selectedExportDataValueCopy.status |= RossakerBmsDataExportValueStatus.ManualEstimate;
        }

        if (this.selectedExportDataValueCopy.startValue >= 0) {
          this.selectedExportDataValueCopy.status &= ~RossakerBmsDataExportValueStatus.MissingStartValue;
        }
        if (this.selectedExportDataValueCopy.endValue >= 0) {
          this.selectedExportDataValueCopy.status &= ~RossakerBmsDataExportValueStatus.MissingEndValue;
        }
        if (this.selectedExportDataValueCopy.tariff >= 0) {
          this.selectedExportDataValueCopy.status &= ~RossakerBmsDataExportValueStatus.MissingTariff;
        }

        let result = await this.dataExportService.setDataExportValues([this.selectedExportDataValueCopy]);
        if (result) {
          this.alertService.info('Date exportvalue updated ok.');
          this.selectedExportDataValueEdit.externalId = this.selectedExportDataValueCopy.externalId;
          this.selectedExportDataValueEdit.prefix = this.selectedExportDataValueCopy.prefix;
          this.selectedExportDataValueEdit.startValue = this.selectedExportDataValueCopy.startValue;
          this.selectedExportDataValueEdit.endValue = this.selectedExportDataValueCopy.endValue;
          this.selectedExportDataValueEdit.tariff = this.selectedExportDataValueCopy.tariff;
          this.selectedExportDataValueEdit.vatRate = this.selectedExportDataValueCopy.vatRate;
          this.selectedExportDataValueEdit.includeVAT = this.selectedExportDataValueCopy.includeVAT;
          this.selectedExportDataValueEdit.status = this.selectedExportDataValueCopy.status;
          if (this.selectedExportDataValueEdit.status & (RossakerBmsDataExportValueStatus.MissingStartValue | RossakerBmsDataExportValueStatus.MissingEndValue)) {
            this.selectedExportDataValueEdit.diff = -9999999999;
          }
          else {
            this.selectedExportDataValueEdit.diff = +(this.selectedExportDataValueEdit.endValue - this.selectedExportDataValueEdit.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
          }

          this.selectedExportDataValueEdit.modifiedAt = this.selectedExportDataValueCopy.modifiedAt;
        }
        else {
          this.alertService.error('Error update dataexport value!');
        }
      }
    }
    catch (err) {
      this.alertService.error('Error update data export value: ', err);
    }

    this.showEditExportDataValue = false;
    this.selectedExportDataValueCopy = null;
  }

  async onSaveEditAllExportDataValues(mode: EditAllMode = EditAllMode.StartValue) {
    try {
      let toSet: RossakerBmsDataExportValue[] = [];
      await ArrayUtils.AsyncForEach(this.dataExportValues, async (dataExportValue: RossakerBmsDataExportValue) => {
        if ((dataExportValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced)) == 0) {
          if (mode == EditAllMode.Tariff) {
            if (this.editAllData.tariff) {
              if (this.editAllData.tariff != dataExportValue.tariff) {
                dataExportValue.tariff = this.editAllData.tariff;
                toSet.push(dataExportValue);
              }
            }
          }
          else if (mode == EditAllMode.StartValue) {
            if (this.editAllData.overridePreviousValues || (dataExportValue.status & RossakerBmsDataExportValueStatus.MissingStartValue) > 0) {
              dataExportValue.modifiedAt = new Date();
              if (!this.editAllData.setToFirstPeriodValue) {
                dataExportValue.startValue = this.editAllData.startValue;
                dataExportValue.status |= RossakerBmsDataExportValueStatus.ManualEstimate;
                if (dataExportValue.startValue >= 0) {
                  dataExportValue.status &= ~RossakerBmsDataExportValueStatus.MissingStartValue;
                }
              }
              else {
                let datapointValue = await this.dataExportService.getDatapointValueAfter(dataExportValue.meterId, this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start);
                if (datapointValue) {
                  let startValue = await this.unitConversions.unitConversionFromDerived(datapointValue.value, dataExportValue.unit);
                  dataExportValue.startValue = +startValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
                  dataExportValue.status |= RossakerBmsDataExportValueStatus.ManualEstimate;
                  if (dataExportValue.startValue >= 0) {
                    dataExportValue.status &= ~RossakerBmsDataExportValueStatus.MissingStartValue;
                  }
                }
              }

              toSet.push(dataExportValue);
            }
          }
        }
      });
      if (toSet.length > 0) {
        let result = await this.dataExportService.setDataExportValues(toSet);
        if (result) {
          this.alertService.success('Data exportvalues updated ok. (' + toSet.length + ' values)');
        }
        else {
          this.alertService.error('Error update dataexport values!');
        }
      }
      else {
        this.alertService.info('No values updated.');
      }

    }
    catch (err) {
      this.alertService.error('Error update data export valuess: ', err);
    }
  }

  async deleteSelectedDataExportvalue() {
    if (this.selectedExportDataValueCopy) {
      this.showEditExportDataValue = false;
      this.cdr.detectChanges();
      this.modalService.ShowConfirmModal({ header: 'Delete value', description: 'Delete data export value, are you sure?' }, async (result) => {
        if (result) {
          let deleteresult = await this.dataExportService.deleteResidentExchange(this.selectedExportDataValueCopy);
          if (deleteresult) {
            this.alertService.info('Data exportvalue deleted ok.');
            this.dataExportValues = this.dataExportValues.filter(value => value.id != this.selectedExportDataValueCopy.id);
          }
          else {
            this.alertService.error('Error delete dataexport value!');
          }
          this.selectedExportDataValueCopy = null;
        }
      });
    }
  }

  getDataExportValueStatusText(status: RossakerBmsDataExportValueStatus, defaultResult: string = ''): string {
    let result: string[] = [];
    if (status & RossakerBmsDataExportValueStatus.AutomaticEstimate) {
      result.push('AUT_EST');
    }
    if (status & RossakerBmsDataExportValueStatus.ManualEstimate) {
      result.push('MAN_EST');
    }
    if (status & RossakerBmsDataExportValueStatus.MissingEndValue) {
      result.push('NO_END');
    }
    if (status & RossakerBmsDataExportValueStatus.MissingStartValue) {
      result.push('NO_START');
    }
    if (status & RossakerBmsDataExportValueStatus.MissingTariff) {
      result.push('NO_TAR');
    }
    if (status & RossakerBmsDataExportValueStatus.Error) {
      result.push('ERROR');
    }
    if (status & RossakerBmsDataExportValueStatus.Warning) {
      result.push('WARN');
    }
    if (status & RossakerBmsDataExportValueStatus.Signed) {
      result.push('SIGNED');
    }
    if (status & RossakerBmsDataExportValueStatus.Invoiced) {
      result.push('INVOICED');
    }
    if (status & RossakerBmsDataExportValueStatus.NegativeConsumption) {
      result.push('NEG');
    }
    if (status & RossakerBmsDataExportValueStatus.HighConsumption) {
      result.push('HIGH');
    }
    if (status & RossakerBmsDataExportValueStatus.LowConsumption) {
      result.push('LOW');
    }

    return result.length == 0 ? defaultResult : result.join(',');
  }

  async onSignAll() {
    if (this.dataExportValues) {
      let hasErrors: boolean = false;
      this.dataExportValues.forEach(dataExportValue => {
        if ((dataExportValue.status & errorFlags) > 0) {
          hasErrors = true;
        }
      });

      if (hasErrors) {
        this.modalService.ShowConfirmModal({
          header: 'Sign - Clear errors',
          description: 'The exportvalues has errors, do you want to clear error flags?',
          ok: 'Sign and clear flags',
          cancel: 'Only sign values'
        }, async (result) => {
          if (result) {
            this.dataExportValues.forEach(dataExportValue => {
              dataExportValue.status &= ~errorFlags;
            });
          }

          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status |= RossakerBmsDataExportValueStatus.Signed;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        });
      }
      else {
        this.dataExportValues.forEach(dataExportValue => {
          dataExportValue.status |= RossakerBmsDataExportValueStatus.Signed;
        });

        await this.dataExportService.setDataExportValues(this.dataExportValues);
      }
    }
  }

  async onUnsignAll() {
    if (this.dataExportValues) {
      this.dataExportValues.forEach(dataExportValue => {
        dataExportValue.status &= ~RossakerBmsDataExportValueStatus.Signed;
      });

      await this.dataExportService.setDataExportValues(this.dataExportValues);
    }
  }

  async onSignSelected() {
    if (this.selectedExportDataValue) {
      if ((this.selectedExportDataValue.status & errorFlags) > 0) {
        this.modalService.ShowConfirmModal({ header: 'Clear errors', description: 'The exportvalue has errors, do you want to clear error flags?' }, async (result) => {
          if (result) {
            this.selectedExportDataValue.status &= ~errorFlags;
          }

          this.selectedExportDataValue.status |= RossakerBmsDataExportValueStatus.Signed;
          await this.dataExportService.setDataExportValues([this.selectedExportDataValue]);
        });
      }
      else {
        this.selectedExportDataValue.status |= RossakerBmsDataExportValueStatus.Signed;
        await this.dataExportService.setDataExportValues([this.selectedExportDataValue]);
      }
    }
  }

  async onUnsignSelected() {
    if (this.selectedExportDataValue) {
      this.selectedExportDataValue.status &= ~RossakerBmsDataExportValueStatus.Signed;
    }
    await this.dataExportService.setDataExportValues([this.selectedExportDataValue]);
  }

  async onUnsetInvoicedAll() {
    this.modalService.ShowConfirmModal({ header: 'Unset invoiced', description: 'Unset all invoiced data export values, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status &= ~RossakerBmsDataExportValueStatus.Invoiced;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        }
      }
    });
  }

  async onSetInvoicedAll() {
    this.modalService.ShowConfirmModal({ header: 'Set invoiced', description: 'Set all data export values as invoiced, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status |= RossakerBmsDataExportValueStatus.Invoiced;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        }
      }
    });
  }

  async onDeleteAll() {
    this.selectedExportDataValue = null;
    this.cdr.detectChanges();
    this.modalService.ShowConfirmModal({ header: 'Delete values', description: 'Delete all unsigned data export values, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          let found = false;
          this.dataExportValues.forEach(dataExportValue => {
            if ((dataExportValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced)) == 0) {
              dataExportValue.deleted = true;
              found = true;
            }
          });

          if (found) {
            let deleteresult = await this.dataExportService.setDataExportValues(this.dataExportValues.filter(value => value.deleted));

            if (deleteresult) {
              this.alertService.info('Data exportvalues deleted ok.');
              this.dataExportValues = this.dataExportValues.filter(value => !value.deleted);
            }
            else {
              this.alertService.error('Error delete dataexport values!');
            }
          }
          else {
            this.alertService.warn('Found no unsigned or not invoiced values to delete.');
          }

        }
      }
    });
  }

  async onDeleteSelected() {
    if (this.selectedExportDataValue && (this.selectedExportDataValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced)) == 0) {
      this.modalService.ShowConfirmModal({ header: 'Delete value', description: 'Delete selected data export value, are you sure?' }, async (result) => {
        if (result) {
          this.selectedExportDataValue.deleted = true;
          let deleteresult = await this.dataExportService.setDataExportValues([this.selectedExportDataValue]);

          if (deleteresult) {
            this.alertService.info('Data exportvalue deleted ok.');
            this.dataExportValues = this.dataExportValues.filter(value => !value.deleted);
          }
          else {
            this.alertService.error('Error delete dataexport value!');
          }
        }
      });
    }
  }

  isSignedOrInvoiced(exportDataValue: RossakerBmsDataExportValue): boolean {
    return (exportDataValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced)) != 0;
  }

  isSigned(exportDataValue: RossakerBmsDataExportValue): boolean {
    return (exportDataValue.status & RossakerBmsDataExportValueStatus.Signed) != 0;
  }

  getBmsBillingPeriodString(period: RossakerBmsBillingPeriod) {
    return RossakerBmsBillingPeriod[period];
  }

  async onGridDetailChange(dataExportValue: RossakerBmsDataExportValue) {
    this.dashboardGridDetailOutputParameters = [];
    if (dataExportValue != null) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'Id';
      out.value = dataExportValue.meterId;
      out.datatype = OutputDataType.Int64;
      this.dashboardGridDetailOutputParameters.push(out);
    }
  }

  async onWidgetValueChangedEdit(parameters: LinkedWidgetChangeParameters) {
    this.logger.debug(parameters);
    this.editExtrapolateStart = parameters.zoom.from;
    this.editExtrapolateStartString = this.dateHelper.utils.formatByString(this.editExtrapolateStart, 'yyyy-MM-dd')
    this.editExtrapolateEnd = parameters.zoom.to;
    this.editExtrapolateEndString = this.dateHelper.utils.formatByString(this.editExtrapolateEnd, 'yyyy-MM-dd')
  }

  async editExtrapolateEndValue() {
    let extrapolateStart = this.dateHelper.utils.parse(this.editExtrapolateStartString, 'yyyy-MM-dd');
    let extrapolateEnd = this.dateHelper.utils.parse(this.editExtrapolateEndString, 'yyyy-MM-dd');

    let result = await this.bmsExportClient.getExtrapolatedValue(this.dataBasisOverviewCustomerInfo.customerId,
      this.selectedExportDataValueEdit.meterId, this.selectedExportDataValueEdit.start, this.selectedExportDataValueEdit.end,
      extrapolateStart, extrapolateEnd);

    this.logger.info('extrapolatedValue', result);

    if (result.ok) {
      this.selectedExportDataValueCopy.endValue = +result.value.toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
    }
  }

  async extrapolateAllEndValues() {
    if (this.selectedExportDataValueMeterType == 'ALL METERTYPES') {
      this.alertService.error('No Meter type selected!');
    }
    else {
      if (this.dataExportValues) {
        this.modalService.ShowConfirmModal({ header: 'Extrapolate all', description: 'Extrapolate all meters of same metertype, are you sure?' }, async (result) => {
          if (result) {
            try {
              this.extrapolateAllRunning = true;
              this.extrapolateAllCurrent = 0;
              this.extrapolateAllPercent = 0;
              this.extrapolateAllTotal = this.dataExportValues.filter(dataExportValue => (dataExportValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced | RossakerBmsDataExportValueStatus.MissingStartValue)) == 0
                && (dataExportValue.status & (RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.AutomaticEstimate)) > 0).length;
              let extrapolateStart = this.dateHelper.utils.parse(this.editExtrapolateStartString, 'yyyy-MM-dd');
              let extrapolateEnd = this.dateHelper.utils.parse(this.editExtrapolateEndString, 'yyyy-MM-dd');

              let toSet: RossakerBmsDataExportValue[] = [];
              await ArrayUtils.AsyncForEach(this.dataExportValues, async (dataExportValue: RossakerBmsDataExportValue) => {
                if ((dataExportValue.status & (RossakerBmsDataExportValueStatus.Signed | RossakerBmsDataExportValueStatus.Invoiced | RossakerBmsDataExportValueStatus.MissingStartValue)) == 0
                  && (dataExportValue.status & (RossakerBmsDataExportValueStatus.MissingEndValue | RossakerBmsDataExportValueStatus.AutomaticEstimate)) > 0) {
                  let result = await this.bmsExportClient.getExtrapolatedValue(this.dataBasisOverviewCustomerInfo.customerId,
                    dataExportValue.meterId, this.selectedExportDataValueEdit.start, this.selectedExportDataValueEdit.end,
                    extrapolateStart, extrapolateEnd);

                  this.logger.info('extrapolatedValue', result);

                  if (result.ok) {
                    dataExportValue.endValue = +result.value.toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
                    if (dataExportValue.endValue >= 0) {
                      dataExportValue.status &= ~RossakerBmsDataExportValueStatus.MissingEndValue;
                      dataExportValue.status |= RossakerBmsDataExportValueStatus.AutomaticEstimate;
                      if (dataExportValue.status & (RossakerBmsDataExportValueStatus.MissingStartValue | RossakerBmsDataExportValueStatus.MissingEndValue)) {
                        dataExportValue.diff = -9999999999;
                      }
                      else {
                        dataExportValue.diff = +(dataExportValue.endValue - dataExportValue.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
                      }
                    }
                    toSet.push(dataExportValue);
                  }
                  this.extrapolateAllCurrent++;
                  this.extrapolateAllPercent = +(this.extrapolateAllCurrent * 100 / this.extrapolateAllTotal).toFixed(0);
                }
              });

              await this.dataExportService.setDataExportValues(toSet);
            }
            finally {
              this.extrapolateAllRunning = false;
              this.showEditExportDataValue = false;
              this.alertService.success('Extrpolate all finished ok.');
            }
          }
        });
      }
    }
  }

  async onViewInBMS() {
    if (this.dataBasisOverviewCustomerInfo) {
      this.router.navigateByUrl('/bmsadmin/customers/' + this.dataBasisOverviewCustomerInfo.customerId + '/meters/' + this.selectedExportDataValue.meterId + '/' + '_x_bms_meter');
    }
  }

  async search() {
    this.exportDataMeterId = -1;
    let meter = await this.adminService.getMeterByIdentifier(this.deviceIdFilter);

    if (meter) {
      this.exportDataMeterId = meter.id;
    }

    this.updateDataBasis();
  }

  async showImportDatafile() {
    if (this.meterTypeOptionNodes.length == 0) {
      this.meterTypeOptionNodes = await this.xConfClient.getReferencedNodesWithCache('_x_bms_metertypes_root', '_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '', 1);
      this.meterTypeOptionNodes = this.meterTypeOptionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }
    this.importDatafileMetertype = this.selectedExportDataValueMeterType;
    this.importDatafileExportPeriod = this.selectedDataBasisExportPeriod;
    this.showImportData = true;
  }


  uploadDataFile(file) {
    if (file.files.length > 0) {
      this.importDatafile = file.files[0];
    }
  }

  async onImportDataFile() {
    try {
      //TODO

      // if (this.importDatafile) {
      //   if (this.importDatafileMetertype == 'ALL METERTYPES') {
      //     this.alertService.error('No Meter type selected!');
      //   }
      //   else {
      //     let customerId = this.dataBasisOverviewCustomerInfo.customerId;
      //     let periodStart = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].start, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start.getTimezoneOffset());
      //     let periodEnd = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].end, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end.getTimezoneOffset());

      //     let result = await this.state.importDataFile(this.importDatafile, customerId, this.importDatafileMetertype,
      //       this.formatByString(periodStart, 'yyyy-MM-dd HH:mm:ss', true), this.formatByString(periodEnd, 'yyyy-MM-dd HH:mm:ss', true));

      //     if (result) {
      //       this.alertService.info('Data exportvalues imported ok.');
      //       this.updateDataBasis();
      //     }
      //     else {
      //       this.alertService.error('Error import data exportvalues.');
      //     }
      //   }
      // }
    }
    finally {
      this.showImportData = false;
    }
  }
}

