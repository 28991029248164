import { Component, OnInit, Input } from '@angular/core';
import { BaseControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputRangeControllerConfig } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-range-controller-config',
  templateUrl: './xproj-output-range-controller-config.component.html',
  styleUrls: ['./xproj-output-range-controller-config.component.scss']
})
export class XprojOutputRangeControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.rangeConfig = value as XprojOutputRangeControllerConfig;
  }

  rangeConfig : XprojOutputRangeControllerConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
