<div class="clr-row">
    <div class="clr-col-8">
        <h3 *ngIf="selectedBmsCustomer" class="sensitive">{{selectedBmsCustomer.customerName}}
        </h3>
    </div>
    <div class="clr-col-4">

    </div>
</div>

<clr-tabs #tabs>
    <clr-tab>
        <button clrTabLink>Overview</button>
        <div class="tab-area" style="height: 100% !important;">
            <clr-tab-content style="height: 100% !important;">
                <div class="customers-content-area" style="height: 100% !important;">
                    <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false" [systemDashboard]="true" [dashboardId]="'bms_customers_overview'" [enableExport]="false" [dashboardTag]="'latest'" [responsiveWidth]="overviewResponsiveWidth" [dashboardOutputParameters]="dashboardOverviewOutputParameters"
                        (onWidgetValueSelected)="onOverviewDashboardValueChanged($event)"></xproj-dashboard>
                </div>
            </clr-tab-content>
        </div>
    </clr-tab>
</clr-tabs>