<h2>Automation</h2>
Our offering in automation includes data collection, PLC controllers and SCADA, all built with modern safe technology deployed in Linux or FreeBSD. <br>
All our systems are secure and supports updates via internet or local mirrors. <br>            

<h3>PLC</h3>
Our PLCs are comprised of our runtimes and they are always shipped with developer tools and our open source controller library.
Some of the features of our PLC's include:
<ul>
    <li>Programming studio with web interface</li>
    <li>Real-time debugger with web interface</li>
    <li>Individual program scheduling</li>
    <li>Templating with individual scheduling</li>
    <li>Process graphs directly bound to PLC program</li>
    <li>Lua as a powerful programming language</li>
</ul>
<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>PLC: I/O </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Lowlevel connectors to field bus protocls and local I/O are separated from the PLC program.</p>
          <img class="screenshoot" src="/webimages/screens/plc/3.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>PLC: I/O Mapping </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Map the connected signals directly into your PLC program.</p>
          <img class="screenshoot" src="/webimages/screens/plc/4.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>

    <clr-accordion-panel>
        <clr-accordion-title>PLC: Programming </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Built-in debugger enables for rapid development directly in the browser. It supports stepping, breaking and watches.</p>
          <img class="screenshoot" src="/webimages/screens/plc/1.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>PLC: Debugger </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>Built-in debugger enables for rapid development directly in the browser. It supports stepping, breaking and watches.</p>
          <img class="screenshoot" src="/webimages/screens/plc/2.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
</clr-accordion>

<h3>SCADA</h3>
Our SCADA is comprised of a WYSIWYG (&quot;What you see is what you get&quot;) editor with Piping and Instrumental symbols included.<br>
Signals are connected to the process graphs via signals and supports both visualization, animations and data manipulation.<br>
The process graphs may be directly inserted into our dashboard builder as a widget and thereby linked to other widgets for drilldowns and
real-time monitoring of process values.
<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>SCADA: WYSIWYG Editor </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The WYSIWYG is an adaption of an open source editor, directly integrated to XAutomation signal scheme.</p>
          <img class="screenshoot" src="/webimages/screens/scada/1.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>SCADA: Process Graphs </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The Process graphs drawn in the editor is easily manageable and signal linking does not require re-drawing the graph.</p>
          <img class="screenshoot" src="/webimages/screens/scada/2.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>SCADA: WYSIWYG Editor</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The editor supports colors, tresholds and even animations.</p>
          <img class="screenshoot" src="/webimages/screens/scada/3.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel>
        <clr-accordion-title>SCADA: Multi widget dashboards </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <p>The Process graphs are directly renderable in the dashboards and are eaasily configured with the dashboard builder, it even supports linking to other widgets! Combine it with live streaming widgets to create an oscilloscope.</p>
          <img class="screenshoot" src="/webimages/screens/scada/4.png"/>
        </clr-accordion-content>
      </clr-accordion-panel>

</clr-accordion>

<h3>High level integration servers</h3>
High level integration servers are use to quickly integrate with third party systems.<br>
Examples of high level integration servers include Modbus, Mqtt, OPC UA.

<h3>De-Centralized Automation/Control</h3>
With our powerful PLC systems it is possible to build smart on-premise control systems. <br>
The local units may fetch data from Rossaker systems or third party systems via WAN/Internet.<br>
De-centralized units may be configured to automatically connect to Rossaker uplinks for fleet management.

<h3>Centralized Automation/Control</h3>
Our PLC systems and SCADAs have native Cloud support and may be deployed in a centralized manner.<br>
This enables for centralized monitoring and control, remote management, integration with third party WAN-based services, etc.

<h3>Big Data</h3>
All our PLC's are directly compatible with our Big Data systems.
<ul>
    <li>Use Big Data queries in PLC sub-systems</li>
    <li>Publish PLC data directly to uplink Big Data systems</li>
    <li>Use machine intensive calculations in cloud fetch insights to edge/PLC</li>
    <li>Smart fallbacks when cloud connection is poor or down</li>
</ul>