<div>
    <!-- [style.height.px]="widgetheight" -->
    <div id="map" class="map" [style.height.px]="widgetheight"></div>

    <div #popup id="popup" class="ol-popup">
        <a #popupCloser href="#" id="popupCloser" class="ol-popup-closer"></a>
        <div #popupContent id="popupContent">
            <div *ngFor="let item of markerValues"><b>{{item.id}}</b>: <span (click)="outputClicked(item)" [style.cursor]="item.clickable ? 'pointer' : 'auto'">{{item.value}}</span></div>
        </div>
    </div>
</div>