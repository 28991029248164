<form clrForm clrLayout="vertical" autocomplete="off">

  <clr-input-container>
    <label class="clr-col-12">Customer config File</label>
    <input clrInput class="clr-col-12" name="file_file" #file type="file" multiple (change)="uploadFile(file.files)" />
  </clr-input-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="overwrite" name="overwrite" />
      <label>Overwrite</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
</form>

<button type="button" class="btn btn-primary" [clrLoading]="importingState" [disabled]="files.length == 0" (click)="importFiles()">
  <clr-icon shape="plus-circle"></clr-icon>
  Import file
</button>


<div *ngIf="importResult">
  <table class="table table-vertical importresult-table">
    <caption>
      Import result
    </caption>
    <tbody>
      <tr>
        <th class="left">Result</th>
        <td class="left">{{this.importingState == ClrLoadingState.LOADING ? 'Processing...' : (importResult.ok ? 'Success' : 'Error')}}</td>
      </tr>
      <tr *ngIf="!importResult.ok" >
        <th class="left">Message</th>
        <td class="left">{{importResult.message}}</td>
      </tr>

      <tr *ngIf="files" >
        <th class="left">Files processed</th>
        <td class="left">{{filesProcessed}} / {{files.length}}</td>
      </tr>
      <tr *ngIf="importResult.ok">
        <th class="left">Realestate count</th>
        <td class="left">{{importResult.realestateCount}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Realestate added</th>
        <td class="left">{{importResult.realestatesAdded}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Realestate deleted</th>
        <td class="left">{{importResult.realestatesDeleted}}</td>
      </tr>

      <tr *ngIf="importResult.ok" >
        <th class="left">Building address count</th>
        <td class="left">{{importResult.buildingAddressCount}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Building addresses added</th>
        <td class="left">{{importResult.buildingAddressesAdded}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Building addresses deleted</th>
        <td class="left">{{importResult.buildingAddressesDeleted}}</td>
      </tr>

      <tr *ngIf="importResult.ok" >
        <th class="left">Apartment count</th>
        <td class="left">{{importResult.apartmentCount}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Apartments added</th>
        <td class="left">{{importResult.apartmentsAdded}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Apartments deleted</th>
        <td class="left">{{importResult.apartmentsDeleted}}</td>
      </tr>

      <tr *ngIf="importResult.ok" >
        <th class="left">Meter count</th>
        <td class="left">{{importResult.meterCount}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Meters added</th>
        <td class="left">{{importResult.metersAdded}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Meters deleted</th>
        <td class="left">{{importResult.metersDeleted}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Meters moved</th>
        <td class="left">{{importResult.metersMoved}}</td>
      </tr>

      <tr *ngIf="importResult.ok" >
        <th class="left">Gateway count</th>
        <td class="left">{{importResult.gatewayCount}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Gateways added</th>
        <td class="left">{{importResult.gatewaysAdded}}</td>
      </tr>
      <tr *ngIf="importResult.ok" >
        <th class="left">Customer errors</th>
        <td class="left">{{importResult.customerErrors.length}}</td>
      </tr>
    </tbody>
  </table>
  <button type="button" class="btn btn-primary" (click)="downloadImportResult()">
    <clr-icon shape="download"></clr-icon>
    Download result
  </button>
</div>
