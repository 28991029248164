import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OutputDataType } from '../../../../../widget-config-service';
import { ArrayUtils } from '../../../../../../utils/array-utils-service';
import { BaseControllerConfig, RadioButtonConfig, XprojOutputRadioButtonControllerConfig } from '../../../xproj-output-controller-widget-config-service';


@Component({
  selector: 'xproj-output-radiobutton-controller-config',
  templateUrl: './xproj-output-radiobutton-controller-config.component.html',
  styleUrls: ['./xproj-output-radiobutton-controller-config.component.scss']
})
export class XprojOutputRadiobuttonControllerConfigComponent implements OnInit {
  @Input() set config (value : BaseControllerConfig)
  {
    this.buttonConfig = value as XprojOutputRadioButtonControllerConfig;
  }

  buttonConfig : XprojOutputRadioButtonControllerConfig;

  selectedButton: RadioButtonConfig;

  OutputDataType = OutputDataType;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  addButton() {
    this.buttonConfig.RadioButtons.push(new RadioButtonConfig());
  }

  removeSelectedButton() {
    let i = this.buttonConfig.RadioButtons.findIndex(b => b == this.selectedButton);
    if (i > -1) {
      this.removeButton(i);
    }
  }

  removeButton(index: number) {
    ArrayUtils.RemoveItemAt(this.buttonConfig.RadioButtons, index);
  }

  moveButtonUp(index: number) {
    ArrayUtils.MoveItemUp(this.buttonConfig.RadioButtons, index);
    this.refreshButtonConfigs();
  }

  moveButtonDown(index: number) {
    ArrayUtils.MoveItemDown(this.buttonConfig.RadioButtons, index);
    this.refreshButtonConfigs();
  }

  private refreshButtonConfigs() {
    let copy = [...this.buttonConfig.RadioButtons];
    this.buttonConfig.RadioButtons = [];
    this.cdr.detectChanges();
    this.buttonConfig.RadioButtons = copy;
  }

  defaultClick(button : RadioButtonConfig) {
    //console.log("click", button, $event);
    if (button.Default) {
      this.buttonConfig.RadioButtons.forEach(btn => {
        if (btn != button) {
          btn.Default = false;
        }
      });
    }
  }
}
