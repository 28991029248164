<div class="content-area">

    <form>
        <section class="form-block">

            <!-- <clr-input-container *ngIf="!xproj.Client.isConnected">
                <label>WS Server</label>
                <input clrInput placeholder="Hostname" name="wshostname" [(ngModel)]="xprojClient.wshostname" />
            </clr-input-container>
            <button class="btn btn-primary" *ngIf="!xprojClient.IsConnected()" (click)="connect()">Connect</button>
            <button class="btn btn-primary" *ngIf="xprojClient.IsConnected()" (click)="disconnect()">Disconnect</button> -->

        </section>
    </form>
    <!--
    <pre>
    isAuthenticated: {{xproj.Client.isAuthenticated}}
    {{xproj.Client.SocketState}}
</pre> -->
</div>
