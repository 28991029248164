/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.customeradmin.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
import { GRPC_CUSTOMER_ADMIN_CLIENT_SETTINGS } from './xprojector.customeradmin.pbconf';
/**
 * Service client implementation for customeradmin.CustomerAdmin
 */
@Injectable({ providedIn: 'root' })
export class CustomerAdminClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /customeradmin.CustomerAdmin/AddNewCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>>
     */
    addNewCustomerUser: (
      requestData: xprojectorGrpcModels002.CustomerUser,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customeradmin.CustomerAdmin/AddNewCustomerUser',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.CustomerUser,
        responseClass: xprojectorGrpcModels002.CustomerUser
      });
    },
    /**
     * Unary call: /customeradmin.CustomerAdmin/DeleteCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteCustomerUser: (
      requestData: thisProto.DeleteCustomerUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customeradmin.CustomerAdmin/DeleteCustomerUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteCustomerUserRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /customeradmin.CustomerAdmin/GetCustomerUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomerUsersResponse>>
     */
    getCustomerUsers: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomerUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customeradmin.CustomerAdmin/GetCustomerUsers',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetCustomerUsersResponse
      });
    },
    /**
     * Unary call: /customeradmin.CustomerAdmin/ModifyCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    modifyCustomer: (
      requestData: thisProto.ModifyCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customeradmin.CustomerAdmin/ModifyCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.ModifyCustomerRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_CUSTOMER_ADMIN_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'customeradmin.CustomerAdmin',
      settings
    );
  }

  /**
   * Unary call @/customeradmin.CustomerAdmin/AddNewCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.CustomerUser>
   */
  addNewCustomerUser(
    requestData: xprojectorGrpcModels002.CustomerUser,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.CustomerUser> {
    return this.$raw
      .addNewCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customeradmin.CustomerAdmin/DeleteCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteCustomerUser(
    requestData: thisProto.DeleteCustomerUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customeradmin.CustomerAdmin/GetCustomerUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomerUsersResponse>
   */
  getCustomerUsers(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomerUsersResponse> {
    return this.$raw
      .getCustomerUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customeradmin.CustomerAdmin/ModifyCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  modifyCustomer(
    requestData: thisProto.ModifyCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .modifyCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
