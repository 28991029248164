import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardOutputChangeParameters, LinkedWidgetSelectParameters, OutputDataType } from 'xproj-lib';

@Component({
  selector: 'app-rossaker-bms-overview',
  templateUrl: './rossaker-bms-overview.component.html',
  styleUrls: ['./rossaker-bms-overview.component.scss']
})
export class RossakerBmsOverviewComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;

  overviewResponsiveWidth: number = 834;
  dashboardOverviewOutputParameters: DashboardOutputChangeParameters[] = [];

  constructor(
    private rossakerState: RossakerStateService,
    private adminService: RossakerBmsAdminService,
    private xconfClient: XProjectorXConfClient
  ) { }

  ngOnInit(): void {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.selectedCustomerData = new RossakerBmsCustomerData();
      if (this.selectedBmsCustomer) {
        this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
        this.updateSelectedCustomerConfig();
      }
      //await this.updateSelectedCustomerConfig();
      this.updateSelectedCustomerOutputs();
    });

    this.updateWidth();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async updateWidth() {
    //await this.onSplitDragEnd();
    this.overviewResponsiveWidth = window.innerWidth - 300;
  }

  updateSelectedCustomerOutputs() {
    this.dashboardOverviewOutputParameters = [];
    if (this.selectedBmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.selectedBmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.selectedBmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customername';
      out.value = this.selectedBmsCustomer.customerName;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);
    }
  }

  async updateSelectedCustomerConfig() {
    if (this.selectedBmsCustomer && !this.selectedCustomerConfig) {
      this.selectedCustomerConfig = await this.adminService.getCustomerConfig(this.selectedBmsCustomer.customerId, await this.xconfClient.getNodeTypes());
    }
  }

  async onOverviewDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }
}
