<xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets"

  [gridSettings]="gridSettings"></xproj-widget-config>
<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Table options</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label class="clr-col-12">Empty table text</label>
          <input clrInput class="clr-col-12" style="width: 85%;" placeholder="" name="emptytabletext"
            [(ngModel)]="editConfig.EmptyTableText" />
        </clr-input-container>
        <clr-input-container>
          <label class="clr-col-12">Rows per page options</label>
          <input clrInput class="clr-col-12" style="width: 85%;" placeholder="" name="rowsperpageoptionsstring"
            [(ngModel)]="rowsPerPageOptionsString" />
        </clr-input-container>
        <clr-input-container>
          <label class="clr-col-12">Default rows per page</label>
          <input clrInput class="clr-col-12" type="number" min="1" max="1000" step="1" placeholder=""
            name="defaultrowsperpage" [(ngModel)]="editConfig.DefaultRowsPerPage" />
        </clr-input-container>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="compact" [(ngModel)]="editConfig.Compact" />
            <label>Compact table</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<form clrForm>
  <clr-select-container>
    <label>Projection</label>
    <select clrSelect id="useprojectioninput" name="useprojectioninput"
      [(ngModel)]="editConfig.ConfigQuery.UseProjectionInputParameter">
      <option [ngValue]="true"> Input </option>
      <option [ngValue]="false"> Projection </option>
    </select>

    <select clrSelect style="margin-left: 1em;" *ngIf="!editConfig.ConfigQuery.UseProjectionInputParameter"
      name="projection" [(ngModel)]="projection" (ngModelChange)="selectedProjectionChange($event)">
      <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
    </select>

    <select clrSelect style="margin-left: 1em;" *ngIf="editConfig.ConfigQuery.UseProjectionInputParameter"
      class="clr-select" id="useprojectioninputparameter" name="UseProjectionInputParameter"
      [(ngModel)]="editConfig.ConfigQuery.ProjectionInputParameterId">
      <option
        *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
        [ngValue]="input.id">
        {{input.id}}</option>
    </select>
  </clr-select-container>

  <clr-select-container>
    <label>Fixed Input / Group</label>
    <select clrSelect id="usefixedgroupinput" name="usefixedgroupinput"
      [(ngModel)]="editConfig.ConfigQuery.FixedGroupSelectionType">
      <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
      <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
      <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
    </select>
  </clr-select-container>

  <clr-select-container *ngIf="editConfig.ConfigQuery.FixedGroupSelectionType == GroupSelectionTypes.GROUP_INPUT">
    <label>Fixed group</label>
    <select clrSelect id="fixedgroupselectiontype" name="FixedGroupSelectionType"
      [(ngModel)]="editConfig.ConfigQuery.FixedGroupInputParameterId">
      <option *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
        [ngValue]="input.id">
        {{input.id}}</option>
    </select>
  </clr-select-container>

  <xproj-group-selection #fixedGroupSelect
    *ngIf="editConfig.ConfigQuery.FixedGroupSelectionType == GroupSelectionTypes.GROUP" [projection]="projection"
    [selected]="editConfig.ConfigQuery.FixedGroup" [header]="'Fixed group'" [onlyOutputSelectedOnData]="false"
    (groupSelected)="fixedProjectionGroupChange($event)">
  </xproj-group-selection>

  <clr-combobox-container
    *ngIf="editConfig.ConfigQuery.FixedGroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
    <label>Fixed group input selections</label>
    <clr-combobox [(ngModel)]="editConfig.ConfigQuery.FixedGroupInputParameterIds" name="fixedmultiSelect"
      clrMulti="true" required>
      <ng-container *clrOptionSelected="let fixedselected">
        {{fixedselected}}
      </ng-container>
      <clr-options>
        <clr-option
          *clrOptionItems="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
          [clrValue]="input.id">
          {{input.id}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>

  <clr-select-container>
    <label>Input / Group</label>
    <select clrSelect id="usegroupinput" name="usegroupinput" [(ngModel)]="editConfig.ConfigQuery.GroupSelectionType">
      <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
      <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
      <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
    </select>
  </clr-select-container>

  <clr-select-container *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT">
    <label>Group</label>
    <select clrSelect id="groupselectiontype" name="GroupSelectionType"
      [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterId">
      <option *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
        [ngValue]="input.id">
        {{input.id}}</option>
    </select>
  </clr-select-container>

  <xproj-group-selection #groupSelect *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP"
    [projection]="projection" [selected]="editConfig.ConfigQuery.Query?.targetgroup"
    (groupSelected)="selectedProjectionGroupChange($event)">
  </xproj-group-selection>

  <clr-combobox-container
    *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
    <label>Group input selections</label>
    <clr-combobox [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterIds" name="multiSelect" clrMulti="true"
      required>
      <ng-container *clrOptionSelected="let selected">
        {{selected}}
      </ng-container>
      <clr-options>
        <clr-option
          *clrOptionItems="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
          [clrValue]="input.id">
          {{input.id}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="GroupFilterEnabled" [(ngModel)]="editConfig.GroupFilterEnabled" />
      <label>Group filter enabled</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="ImportEnabled" [(ngModel)]="editConfig.ImportEnabled" />
      <label>Import enabled</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="AddRemoveEnabled" [(ngModel)]="editConfig.AddRemoveEnabled" />
      <label>Add/Remove enabled</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="UseNativeGroupSetData" [(ngModel)]="editConfig.UseNativeGroupSetData" />
      <label>Use native group set</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>


</form>

<h5>Data</h5>
<form clrForm *ngIf="columns">
  <clr-select-container>
    <label>Time filter column</label>
    <select clrSelect id="timefiltercolumn" name="timefiltercolumn"
      [(ngModel)]="editConfig.ConfigQuery.timestampColumnName">
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs | xproj_dataeditor_filtertimestampcolumns"
        [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-select-container>
    <label>Default sorting column</label>
    <select clrSelect id="sortingcolumn" name="sortingcolumn"
      [(ngModel)]="editConfig.ConfigQuery.defaultSortColumnName">
      <option [ngValue]=""> </option>
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-select-container>
    <label>Default sorting order</label>

    <select clrSelect id="sortingorder" name="sortingorder" [(ngModel)]="editConfig.ConfigQuery.defaultSortDescending">
      <option [ngValue]="false"> Ascending </option>
      <option [ngValue]="true"> Descending </option>
    </select>
  </clr-select-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="hidden" [(ngModel)]="editConfig.SetColumnValueOnDelete" />
      <label>Set column on delete</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-select-container *ngIf="editConfig.SetColumnValueOnDelete">
    <label>Column to set on delete</label>
    <select clrSelect id="ondeleteolumn" name="ondeleteolumn" [(ngModel)]="editConfig.OnDeleteColumnName">
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-select-container *ngIf="editConfig.SetColumnValueOnDelete">
    <label>Deleted At Column</label>
    <select clrSelect id="ondeletedatolumn" name="ondeletedatcolumn" [(ngModel)]="editConfig.OnDeletedAtColumnName">
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-select-container>
    <label>Modified At Column</label>
    <select clrSelect id="onmodifiedatolumn" name="onmodifiedatolumn" [(ngModel)]="editConfig.OnModifiedAtColumnName">
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-select-container>
    <label>Created At Column</label>
    <select clrSelect id="oncreatedatolumn" name="oncreatedatolumn" [(ngModel)]="editConfig.OnCreatedAtColumnName">
      <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
        {{col.ColumnName}}</option>
    </select>
  </clr-select-container>

  <clr-datagrid [(clrDgSingleSelected)]="selectedColumnConfig">
    <clr-dg-column>Column</clr-dg-column>
    <clr-dg-column>Label</clr-dg-column>
    <!-- <clr-dg-column>Hidden</clr-dg-column> -->
    <clr-dg-column>Move</clr-dg-column>

    <clr-dg-row *ngFor="let column of editConfig.ConfigQuery.ColumnConfigs; let i = index" [clrDgItem]="column">
      <clr-dg-cell>
        <div class="clr-select-wrapper">
          <select class="clr-select" name="tselectqueryData.column{{i}}" id="tselectcolumn_{{i}}"
            [(ngModel)]="column.ColumnName" (ngModelChange)="onColumnSelect($event, i)">
            <option *ngFor="let col of columns" [ngValue]="col.columnname">
              {{col.columnname}}</option>
          </select>
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" placeholder="" name="tlabel{{i}}"
          [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Label" />
      </clr-dg-cell>

      <!-- <clr-dg-cell>
        <input class="clr-input" clrCheckbox type="checkbox" placeholder="" name="hidden{{i}}"
          [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Hidden" />
      </clr-dg-cell> -->

      <clr-dg-cell>
        <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
        <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
          style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>


  <clr-dropdown [clrCloseMenuOnItemClick]="true">
    <button clrDropdownTrigger aria-label="More actions">
      <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
      <!-- <clr-icon shape="caret down"></clr-icon> -->
    </button>
    <clr-dropdown-menu *clrIfOpen>
      <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addAllColumns()">Add all columns</div>
      <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="removeAllColumns()">Remove all columns</div>
    </clr-dropdown-menu>
  </clr-dropdown>

  <button type="button" class="btn btn-link" (click)="addColumn()">
    <clr-icon shape="plus-circle"></clr-icon>
    Add column
  </button>
  <button *ngIf="selectedColumnConfig" type="button" class="btn btn-link" (click)="removeSelectedColumn()">
    <clr-icon shape="minus-circle"></clr-icon>
    Remove selected column
  </button>
</form>

<div *ngIf="selectedColumnConfig" style="max-width: 600px;">
  <div class="card">
    <div class="card-header">
      Editing column
    </div>

    <div class="card-block">
      <div class="card-title">
        {{selectedColumnConfig.ColumnName}} ({{selectedColumnConfig.Label}})
      </div>
      <form clrForm>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="detail_hidden" [(ngModel)]="selectedColumnConfig.Hidden" />
            <label>Hidden</label>
          </clr-checkbox-wrapper>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="detail_writeable" [(ngModel)]="selectedColumnConfig.Writeable" />
            <label>Writeable</label>
          </clr-checkbox-wrapper>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="detail_usefixedvalue"
              [(ngModel)]="selectedColumnConfig.UseFixedValue" />
            <label>Use Fixed Value</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-select-container *ngIf="selectedColumnConfig.UseFixedValue">
          <label>Fixed Value Input</label>
          <select clrSelect id="fixedvalueinput" name="fixedvalueinput"
            [(ngModel)]="selectedColumnConfig.FixedValueInputParameterId">
            <option value=""> </option>
            <option
              *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [selectedColumnConfig.Datatype]"
              [ngValue]="input.id">
              {{input.id}}</option>
          </select>
        </clr-select-container>
        <clr-input-container
          *ngIf="selectedColumnConfig.UseFixedValue && (selectedColumnConfig.FixedValueInputParameterId.length == 0 || editConfig.InputParameters.length == 0)">
          <label>Fixed Value</label>
          <input clrInput style="width: 75%;" placeholder="" name="fixedvalue"
            [(ngModel)]="selectedColumnConfig.FixedValue" />
        </clr-input-container>
      </form>
    </div>
  </div>
</div>

<h5>Filters</h5>
<xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="projection?.projectionid"
  [columnDescriptions]="columns" [group]="editConfig.ConfigQuery.Query.targetgroup"
  [datafilters]="editConfig.ConfigQuery.DataFilters"
  [filterLogicalGroupType]="editConfig.ConfigQuery.FilterLogicalGroupType"
  [inputParameters]="editConfig.InputParameters" [widgets]="widgets">
</xproj-projection-filter>
