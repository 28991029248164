<!-- <app-configuration-datasource *ngIf="dataSourceInstance" [editEnabled]="false" [dataSourceInstance]="dataSourceInstance" [duplicateEnabled]="false"
  [highlightSelected]="false" [hideRoot]="true" (onTreeNodeSelect)="onTreeNodeSelect($event)"></app-configuration-datasource> -->

  <clr-tabs>
    <clr-tab *ngFor="let dashboard of dashboards" >
      <button clrTabLink>{{dashboard.name}}</button>
      <ng-template [(clrIfActive)]="dashboard.active">
        <clr-tab-content>
          <app-rossaker-bms-admin-dashboard [dashboardId]="dashboard.id"></app-rossaker-bms-admin-dashboard>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
