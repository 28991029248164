import { DecimalPipe } from '@angular/common';
import { TypedJSON } from 'typedjson';
import { DataFilter } from '../filters/data-filter/data-filter-service';
import { LinkedWidgetActions, LinkedWidgetChangeCommand, OutputDataType } from '../widgets/widget-config-service';
import { HiglightParameters, LinkedWidgetChangeParameters, MasterTimeSettings, ZoomParameters } from '../widgets/xproj-widget-service';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, ColumnFiltering, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, OffsetType, RelativeTimestampOrigo, Transformation, XDataType } from '../XProjector/xprojector-client-service';

export class WidgetUtils {

  public static TransformLinkedWidgetChangeParameters(params: LinkedWidgetChangeParameters, actions: LinkedWidgetActions): LinkedWidgetChangeParameters {

    let e = params.Clone();

    if (params.zoom) {
      switch (actions.OnZoom) {
        case LinkedWidgetChangeCommand.Highlight: {
          if (!e.highlight) {
            e.highlight = new HiglightParameters();
            e.highlight.from = e.zoom.from;
            e.highlight.to = e.zoom.to;
            e.highlight.unit = e.zoom.unit;
          }
          else {
            if (!e.highlight.to) {
              e.highlight.to = e.zoom.to;
            }
            if (!e.highlight.from) {
              e.highlight.from = e.zoom.from;
            }
          }

          break;
        }
        case LinkedWidgetChangeCommand.None: {
          e.zoom = null;
          break;
        }
      }
    }

    if (params.highlight) {
      switch (actions.OnHighlight) {
        case LinkedWidgetChangeCommand.Zoom: {
          if (!e.zoom) {
            e.zoom = new ZoomParameters();
            e.zoom.from = e.highlight.from;
            e.zoom.to = e.highlight.to;
            e.zoom.unit = e.highlight.unit;
          }
          else {
            if (!e.zoom.to) {
              e.zoom.to = e.highlight.to;
            }
            if (!e.zoom.from) {
              e.zoom.from = e.highlight.from;
            }
          }
          break;
        }
        case LinkedWidgetChangeCommand.None: {
          e.highlight = null;
          break;
        }

      }
    }

    if (e.zoom && e.highlight) {

      if (e.zoom.from && (e.highlight.from <= e.zoom.from) || (e.zoom.to && e.highlight.to >= e.zoom.to)) {
        e.highlight = null;
      }
    }

    if (e.zoom && e.zoom.from && e.zoom.to) {
      switch (e.zoom.unit) {
        case 'year': {
          e.zoom.from.setMonth(e.zoom.from.getMonth() - 1);
          e.zoom.to.setMonth(e.zoom.to.getMonth() + 1);
          break;
        }
        case 'quarter': {
          e.zoom.from.setMonth(e.zoom.from.getMonth() - 1);
          e.zoom.to.setMonth(e.zoom.to.getMonth() + 1);
          break;
        }
        case 'month': {
          e.zoom.from.setDate(e.zoom.from.getDate() - 5);
          e.zoom.to.setDate(e.zoom.to.getDate() + 5);
          break;
        }
        case 'week': {
          e.zoom.from.setDate(e.zoom.from.getDate() - 2);
          e.zoom.to.setDate(e.zoom.to.getDate() + 2);
          break;
        }
        case 'day': {
          e.zoom.from.setHours(e.zoom.from.getHours() - 5);
          e.zoom.to.setHours(e.zoom.to.getHours() + 5);
          break;
        }
        case 'hour': {
          e.zoom.from.setMinutes(e.zoom.from.getMinutes() - 10);
          e.zoom.to.setMinutes(e.zoom.to.getMinutes() + 10);
          break;
        }
        case 'minute': {
          e.zoom.from.setMinutes(e.zoom.from.getMinutes() - 1);
          e.zoom.to.setMinutes(e.zoom.to.getMinutes() + 1);
          break;
        }
      }
    }

    return e;
  }

  public static GetInputValue(value: string, datatype: OutputDataType, invertValue: boolean = false): any {
    try {
      switch (datatype) {
        case OutputDataType.Number:
        case OutputDataType.Float32:
        case OutputDataType.Float64:
        case OutputDataType.UInt8:
        case OutputDataType.Int32:
        case OutputDataType.Int64: {
          if (invertValue) {
            return 0 - parseFloat(value);
          }
          else {
            return parseFloat(value);
          }
        }
        case OutputDataType.String: {
          return value;
          //return value?.length > 0 ? value : undefined;
        }
        case OutputDataType.Timestamp: {
          return new Date(value);
        }
        case OutputDataType.Aggregation: {
          return Aggregation[Aggregation[value]];
        }
        case OutputDataType.Transformation: {
          return Transformation[Transformation[value]];
        }
        case OutputDataType.Group: {
          return value.split('|');
        }
        case OutputDataType.Time: {
          return TypedJSON.parse(value, MasterTimeSettings);
        }
        case OutputDataType.RelativeTimestamp: {
          return parseFloat(value) as RelativeTimestampOrigo;
        }
        case OutputDataType.RelativeOffsettype: {
          return parseFloat(value) as OffsetType;
        }

        default:
          return value?.length > 0 ? value : undefined;
      }
    } catch {
      return undefined;
    }
  }

  public static GetColumnFiltering(datafilter: DataFilter): ColumnFiltering {
    if (datafilter.ColumnDescriptor) {
      if (datafilter.ColumnDescriptor.datatype == XDataType.String) {
        let result = new ColumnFilteringString();
        result.columnname = datafilter.ColumnDescriptor.columnname;
        result.comparator = parseInt(datafilter.Comparator.toString());
        result.value = datafilter.Value as string;
        return result;
      }
      else if (datafilter.ColumnDescriptor.datatype == XDataType.Timestamp) {
        if (!datafilter.DateTypeRelative) {
          let result = new ColumnFilteringTimestamp();
          result.columnname = datafilter.ColumnDescriptor.columnname;
          result.comparator = parseInt(datafilter.Comparator.toString());
          result.value = datafilter.DateValue;
          return result;
        }
        else {
          let result = new ColumnFilteringRelativeTimestamp();
          result.columnname = datafilter.ColumnDescriptor.columnname;
          result.comparator = parseInt(datafilter.Comparator.toString());
          result.origo = datafilter.DateRelativeOrigo;
          if (result.origo == RelativeTimestampOrigo.EXACT) {
            result.exactorigo = datafilter.DateValue;
          }

          switch (datafilter.RelativeOffsettype) {
            case OffsetType.YEAR: {
              result.offsetyears = datafilter.DateRelativeOffet;
              break;
            }
            case OffsetType.MONTH: {
              result.offsetmonths = datafilter.DateRelativeOffet;
              break;
            }
            case OffsetType.DAY: {
              result.offsetdays = datafilter.DateRelativeOffet;
              break;
            }
            case OffsetType.HOUR: {
              result.offsethours = datafilter.DateRelativeOffet;
              break;
            }
            case OffsetType.MINUTE: {
              result.offsetminutes = datafilter.DateRelativeOffet;
              break;
            }
            case OffsetType.SECOND: {
              result.offsetseconds = datafilter.DateRelativeOffet;
              break;
            }
          }
          return result;

        }
      }
      else {
        let result = new ColumnFilteringNumerical();
        result.columnname = datafilter.ColumnDescriptor.columnname;
        result.comparator = parseInt(datafilter.Comparator.toString());
        result.value = datafilter.NumericValue;
        return result;
      }
    }

    return null;
  }

  public static SetQueryFilterValues(query: BaseQuery, datafilters: DataFilter[], getFilterValue: (inputParameterId: string, defaultValue: any) => { parameterHasValue: boolean, value: any }) {
    let filterId = 0;
    query.stringfilters = [];
    query.numericalfilters = [];
    query.timestampfilters = [];
    query.relativetimestampfilters = [];
    query.filter.filters = [];

    datafilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        let addFilter: boolean = false;

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.value);
          if (filterValue.parameterHasValue) {
            columnFiltering.value = filterValue.value;
            query.numericalfilters.push(columnFiltering);
            addFilter = true;
          }
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.value);
          if (filterValue.parameterHasValue) {
            columnFiltering.value = filterValue.value;
            query.stringfilters.push(columnFiltering);
            addFilter = true;
          }
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.value);
          if (filterValue.parameterHasValue) {
            columnFiltering.value = filterValue.value;
            query.timestampfilters.push(columnFiltering);
            addFilter = true;
          }
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          let origoValue = getFilterValue(datafilter.InputParameterId2, columnFiltering.origo);
          if (origoValue.parameterHasValue) {
            columnFiltering.origo = origoValue.value;
            addFilter = true;
          }

          let relativeOffsettypeValue = getFilterValue(datafilter.InputParameterId3, datafilter.RelativeOffsettype);
          if (relativeOffsettypeValue.parameterHasValue) {
            datafilter.RelativeOffsettype = relativeOffsettypeValue.value;
            addFilter &&= true;
          }
          else {
            addFilter = false;
          }

          if (addFilter) {
            switch (datafilter.RelativeOffsettype) {
              case OffsetType.YEAR: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsetyears);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsetyears = filterValue.value;
                }
                else {
                  addFilter = false;
                }
                break;
              }
              case OffsetType.MONTH: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsetmonths);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsetmonths = filterValue.value;
                }
                else {
                  addFilter = false;
                }

                break;
              }
              case OffsetType.DAY: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsetdays);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsetdays = filterValue.value;
                }
                else {
                  addFilter = false;
                }
                break;
              }
              case OffsetType.HOUR: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsethours);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsethours = filterValue.value;
                }
                else {
                  addFilter = false;
                }
                break;
              }
              case OffsetType.MINUTE: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsetminutes);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsetminutes = filterValue.value;
                }
                else {
                  addFilter = false;
                }
                break;
              }
              case OffsetType.SECOND: {
                let filterValue = getFilterValue(datafilter.InputParameterId, columnFiltering.offsetseconds);
                if (filterValue.parameterHasValue) {
                  columnFiltering.offsetseconds = filterValue.value;
                }
                else {
                  addFilter = false;
                }
                break;
              }
            }
          }

          if (addFilter) {
            query.relativetimestampfilters.push(columnFiltering);
          }
        }

        if (addFilter) {
          columnFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(columnFiltering.queryfilterid);
        }
      }
    });
  }

  public static AddQueryTimeframe(query: BaseQuery, from: Date, to: Date, timestampColumnName: string, relativeTimestamp: ColumnFilteringRelativeTimestamp = null) {

    if (from || to || relativeTimestamp) {
      if (timestampColumnName?.length > 0) {
        query.subfiltergroups.push(query.filter);
        let filter = new FilterLogicalGroup();
        filter.type = FilterLogicalGroupType.AND;
        filter.queryfilterid = 99999;
        filter.filters.push(query.filter.queryfilterid);

        if (relativeTimestamp) {
          relativeTimestamp.queryfilterid = 999990;
          relativeTimestamp.columnname = timestampColumnName;
          relativeTimestamp.comparator = FilterComparator.GreatherThanOrEquals;
          filter.filters.push(relativeTimestamp.queryfilterid);
          query.relativetimestampfilters.push(relativeTimestamp);
        }
        else {
          if (from) {
            let fromFilter = new ColumnFilteringTimestamp();
            fromFilter.queryfilterid = 999990;
            fromFilter.columnname = timestampColumnName;
            fromFilter.comparator = FilterComparator.GreatherThanOrEquals;
            fromFilter.value = from;
            filter.filters.push(fromFilter.queryfilterid);
            query.timestampfilters.push(fromFilter);
          }

          if (to) {
            let toFilter = new ColumnFilteringTimestamp();
            toFilter.queryfilterid = 999991;
            toFilter.columnname = timestampColumnName;
            toFilter.comparator = FilterComparator.LessThanOrEquals;
            toFilter.value = to;
            filter.filters.push(toFilter.queryfilterid);
            query.timestampfilters.push(toFilter);
          }
        }


        query.filter = filter;
      }
    }
  }

  public static GetQueryZoomDates(from: Date, to: Date, transformation: Transformation): { from: Date, to: Date } {

    if (from && to && transformation) {
      let result = { from: new Date(from.getTime()), to: new Date(to.getTime()) }

      switch (transformation) {
        case Transformation.TIMESTAMP_RESOLUTION_YEAR:
          result.from.setFullYear(result.from.getFullYear() - 1);
          result.to.setFullYear(result.to.getFullYear() + 1);
          break;

        case Transformation.TIMESTAMP_RESOLUTION_MONTH:
          result.from.setMonth(result.from.getMonth() - 1);
          result.to.setMonth(result.to.getMonth() + 1);
          break;

        case Transformation.TIMESTAMP_DAYOFMONTH:
        case Transformation.TIMESTAMP_DAYOFWEEK:
        case Transformation.TIMESTAMP_DAYOFYEAR:
        case Transformation.TIMESTAMP_RESOLUTION_DAY:
          result.from.setDate(result.from.getDate() - 1);
          result.to.setDate(result.to.getDate() + 1);
          break;

        case Transformation.TIMESTAMP_HOURS:
        case Transformation.TIMESTAMP_RESOLUTION_HOUR:
          result.from.setHours(result.from.getHours() - 1);
          result.to.setHours(result.to.getHours() + 1);
          break;

        case Transformation.TIMESTAMP_MINUTES:
        case Transformation.TIMESTAMP_MINUTES_OF_DAY:
        case Transformation.TIMESTAMP_RESOLUTION_MINUTE:
          result.from.setMinutes(result.from.getMinutes() - 1);
          result.to.setMinutes(result.to.getMinutes() + 1);
          break;

        case Transformation.TIMESTAMP_RESOLUTION_SECOND:
        case Transformation.TIMESTAMP_SECONDS:
        case Transformation.TIMESTAMP_SECONDS_OF_DAY:
          result.from.setSeconds(result.from.getSeconds() - 1);
          result.to.setSeconds(result.to.getSeconds() + 1);
          break;
      }

      return result;
    }

    return { from: from, to: to };

  }

  public static FormatNumber(num: number, decimals: number): number {
    let x = 10 ** decimals;
    return Math.round((num + Number.EPSILON) * x) / x;
  }

  public static FormatNumbers(values: number[], decimals: number): number[] {
    let x = 10 ** decimals;

    return values.map(num => { return Math.round((num + Number.EPSILON) * x) / x; });
  }
}
