<div class="customers-content-area">
  <clr-tabs #tabs>
    <clr-tab>
      <button clrTabLink>Files</button>
      <ng-template [(clrIfActive)]="filesActive">
        <clr-tab-content>
          <div class="card">
            <div class="card-block">
              <div class="card-text">
                <table class="table table-compact table-vertical backup">
                  <caption style="margin-bottom: 1em;">
                    BIM file
                  </caption>
                  <tbody>
                    <tr>
                      <th class="left" style="width: 100px;">BIM file</th>
                      <td *ngIf="bimFileInfo" class="left">{{bimFileInfo.name}}
                        <!-- <clr-icon shape="download-cloud" style="cursor: pointer" (click)="downloadGatewayFile()"></clr-icon> -->
                      </td>
                      <td *ngIf="!bimFileInfo" class="left">---</td>
                      <td *ngIf="bimFileInfo" style="width: 5px;" class="right">
                        <clr-icon shape="download-cloud" style="cursor: pointer" (click)="downloadBIMFile()"></clr-icon>
                      </td>
                      <td *ngIf="bimFileInfo" style="width: 5px;" class="right">
                        <clr-icon shape="trash" style="cursor: pointer" (click)="deleteBIMFile()"></clr-icon>
                      </td>
                    </tr>
                    <tr>
                      <th class="left" style="width: 100px;">Time</th>
                      <td class="left">{{bimFileInfo ? dateHelper.utils.format(bimFileInfo.lastModified,
                        'keyboardDateTime')
                        :
                        '---'}}</td>
                      <td *ngIf="bimFileInfo" style="width: 5px;" class="right"></td>
                      <td *ngIf="bimFileInfo" style="width: 5px;" class="right"></td>
                    </tr>
                  </tbody>
                </table>

                <form clrForm clrLayout="vertical" autocomplete="off">
                  <clr-input-container>
                    <label class="clr-col-12">Upload BIM file, select or drop file.</label>
                    <input clrInput class="clr-col-12" name="bimfile_file" #file type="file"
                      (change)="selectBIMFile(file)" />
                  </clr-input-container>
                </form>

                <button type="button" class="btn btn-sm btn-outline" [clrLoading]="uploadingState" [disabled]="!uploadFile"
                  (click)="uploadBIMFile()">
                  <clr-icon shape="import"></clr-icon>
                  Upload BIM file
                </button>

                <table class="table table-compact table-vertical backup">
                  <caption style="margin-bottom: 1em;">
                    Babylon file
                  </caption>
                  <tbody>
                    <tr>
                      <th class="left" style="width: 100px;">Babylon file</th>
                      <td *ngIf="babylonFileInfo" class="left">{{babylonFileInfo.name}}
                        <!-- <clr-icon shape="download-cloud" style="cursor: pointer" (click)="downloadGatewayFile()"></clr-icon> -->
                      </td>
                      <td *ngIf="!babylonFileInfo" class="left">---</td>
                      <td *ngIf="babylonFileInfo" style="width: 5px;" class="right">
                        <clr-icon shape="download-cloud" style="cursor: pointer" (click)="downloadBabylonFile()"></clr-icon>
                      </td>
                      <td *ngIf="babylonFileInfo" style="width: 5px;" class="right">
                        <clr-icon shape="trash" style="cursor: pointer" (click)="deleteBabylonFile()"></clr-icon>
                      </td>
                    </tr>
                    <tr>
                      <th class="left" style="width: 100px;">Time</th>
                      <td class="left">{{babylonFileInfo ? dateHelper.utils.format(babylonFileInfo.lastModified,
                        'keyboardDateTime')
                        :
                        '---'}}</td>
                      <td *ngIf="babylonFileInfo" style="width: 5px;" class="right"></td>
                      <td *ngIf="babylonFileInfo" style="width: 5px;" class="right"></td>
                    </tr>
                  </tbody>
                </table>

                <form clrForm clrLayout="vertical" autocomplete="off">
                  <clr-input-container>
                    <label class="clr-col-12">Upload Babylon file, select or drop file.</label>
                    <input clrInput class="clr-col-12" name="babylonfile_file" #babylonfile type="file"
                      (change)="selectBabaylonFile(babylonfile)" />
                  </clr-input-container>
                </form>

                <button type="button" class="btn btn-sm btn-outline" [clrLoading]="babylonUploadingState" [disabled]="!babylonUploadFile"
                  (click)="uploadBabaylonFile()">
                  <clr-icon shape="import"></clr-icon>
                  Upload Babylon file
                </button>
              </div>
            </div>
          </div>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Editor</button>
      <ng-template [(clrIfActive)]="editorActive">
        <clr-tab-content>
          <app-bms-building-editor *ngIf="data.node" [nodeId]="data.node.id" [nodeTypeId]="data.node.nodeTypeId"></app-bms-building-editor>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>
