import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { SpcConfig, SpcQuery } from './spc-config-service';
import { QueryProjectionData, XprojSpcWidgetQueryConfigComponent } from '../spc-widget-query-config/xproj-spc-widget-query-config.component';
import { Projection, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { LegendAlignment, LegendPosition, WidgetConfig } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Subscription } from 'rxjs';
import { WidgetConfigBase } from '../../widget-config-base';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';

@Component({
  selector: 'xproj-spc-widget-config',
  templateUrl: './xproj-spc-widget-config.component.html',
  styleUrls: ['./xproj-spc-widget-config.component.scss']
})
export class XprojSpcWidgetConfigComponent extends WidgetConfigBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(XprojSpcWidgetQueryConfigComponent, { read: XprojSpcWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojSpcWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: SpcConfig;

  projections: Projection[] = [];

  LegendPosition = LegendPosition;
  LegendAlignment = LegendAlignment;

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
    this.updateInputParameters();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as SpcConfig;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.config.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
  }

  async onSaveConfig() {
    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }
}
