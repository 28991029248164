<form clrForm clrLayout="vertical">
  <h3>Search</h3>
  <clr-input-container>
    <label class="clr-col-12">Id</label>
    <input clrInput style="width: 90%;" class="clr-col-12" placeholder="" name="idsearch" [(ngModel)]="searchForm.id" />
  </clr-input-container>
</form>

<clr-datagrid class="searchPropertiesGrid" [(clrDgSingleSelected)]="selectedProperty">
  <clr-dg-column>Name</clr-dg-column>
  <clr-dg-column>Value</clr-dg-column>

  <clr-dg-row *ngFor="let property of searchForm.properties; let i = index" [clrDgItem]="property">
    <clr-dg-cell>
      <div class="clr-select-wrapper">
        <select class="clr-select" name="property_key_{{i}}" [(ngModel)]="property.key">
          <option *ngFor="let item of searchableKeys" [ngValue]="item.key"> {{item.name}} </option>
        </select>
      </div>
    </clr-dg-cell>
    <clr-dg-cell>
      <input class="clr-input" placeholder="" name="property_value_{{i}}" id=property_value_{{i}}
        [(ngModel)]="property.value" />
    </clr-dg-cell>

  </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addSearchProperty()">
  <clr-icon shape="plus-circle"></clr-icon>
  Add search property
</button>
<button *ngIf="selectedProperty" type="button" class="btn btn-link" (click)="removeSearchProperty()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected property
</button>

<br>
<button class="btn" (click)="search()" [clrLoading]="searching">Search</button>
<button class="btn" (click)="clear()">Clear</button>


<table *ngIf="searchResult" class="table">
  <caption>
    Search result
  </caption>
  <thead>
    <tr>
      <th class="left">Id</th>
      <th class="left">Name</th>
      <th class="left"></th>
      <th class="left"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let node of searchResult.nodes">
      <td class="left">{{node.id}}</td>
      <td class="left">{{node.name}}</td>
      <td class="left">
        <clr-icon shape="pencil" (click)="editNode(node)" style="cursor: pointer;"></clr-icon>
      </td>
      <td class="left">
        <span style="cursor: pointer;" (click)="viewInTree(node)">View</span>
        <clr-icon shape="arrow" (click)="viewInTree(node)" style="cursor: pointer; transform: rotate(90deg);"></clr-icon>
      </td>
    </tr>
  </tbody>
</table>

<clr-modal [(clrModalOpen)]="showEditModal">
  <h3 class="modal-title">Edit node</h3>
  <div class="modal-body">
    <app-edit-treenode #editTreeNode *ngIf="showEditModal && currentEditNode" [node]="currentEditNode" [customerId]="customerId"
      [nodeTypes]="nodeTypes"></app-edit-treenode>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeEditNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveEditNode()">Save</button>
  </div>
</clr-modal>
