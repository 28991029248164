<h3>New customer</h3>
<form clrForm (ngSubmit)="addCustomer()">
  <clr-input-container>
    <label>Customer username:</label>
    <input clrInput required placeholder="customer username" name="customerid" type="text" [(ngModel)]="newCustomer.username" />
  </clr-input-container>
  <clr-input-container>
    <label>Customer name:</label>
    <input clrInput required placeholder="customer name" name="customername" type="text" [(ngModel)]="newCustomer.name" />
  </clr-input-container>
  <clr-input-container>
    <label>Customer description:</label>
    <input clrInput required placeholder="customer description" name="customerdescription" type="text" [(ngModel)]="newCustomer.description" />
  </clr-input-container>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="newCustomer.isEnabled"
        name="customerenabled" />
      <label>Enabled</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-input-container>
    <label>Customer admin email:</label>
    <input clrInput required placeholder="customeradmin" name="customeradmin" type="text" [(ngModel)]="newCustomerAdmin.email" />
  </clr-input-container>

</form>
<button type="submit" class="btn btn-outline" (click)="addCustomer();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add customer
</button>
