<form clrForm>
    <clr-input-container>
        <label>Script name </label>
        <input clrInput type="text" [(ngModel)]="Variable.scriptname" name="varscriptname" size="35" required />
    </clr-input-container>
    <clr-input-container>
        <label>Description </label>
        <input clrInput type="text" [(ngModel)]="Variable.description" name="vardescription" size="35" />
    </clr-input-container>
    <clr-input-container>
        <label>XAutoVaribleID </label>
        <input clrInput type="text" [(ngModel)]="Variable.xautovariableid" name="varxautovariableid" size="35" />
    </clr-input-container>
    <clr-input-container>
        <label>XName </label>
        <input clrInput type="text" [(ngModel)]="Variable.xname" name="varxname" size="35" />
    </clr-input-container>
    <clr-input-container>
        <label>XAutoGroup </label>
        <input clrInput type="text" [(ngModel)]="Variable.xautogroup" name="varxautogroup" size="35" />
    </clr-input-container>
    <clr-input-container>
        <label>XGroup </label>
        <input clrInput type="text" [(ngModel)]="Variable.xgroup" name="varxgroup" size="35" />
    </clr-input-container>

    <clr-toggle-container>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="true" name="varflushiferror" [(ngModel)]="Variable.flushiferror" />
            <label>Flush if error</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>
    <clr-toggle-container>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="true" name="varflushfromblock" [(ngModel)]="Variable.flushfromblock" />
            <label>Flush directly from block</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>
</form>