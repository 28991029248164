import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-editplcprogram-simulation',
  templateUrl: './editplcprogram-simulation.component.html',
  styleUrls: ['./editplcprogram-simulation.component.scss']
})
export class EditplcprogramSimulationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
