import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Guid } from '../../../utils/guid-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';

export enum MapRenderType {
  MARKER = 0,
  WEBGL = 1,
}

@jsonObject
export class MapColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public WebGLId : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public Clickable : boolean = false;

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

}

@jsonObject
export class MapWidgetQuery   implements BaseQueryConfigInterface {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 1000;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(MapColumnConfig)
  public ColumnConfigs : MapColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public LatitudeColumnName : string = '';

  @jsonMember
  public LongitudeColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

}

@jsonObject
export class MapWidgetConfig extends WidgetConfig {

  @jsonArrayMember(MapWidgetQuery)
  public ConfigQueries : MapWidgetQuery[] = [];

  @jsonMember
  public CenterPointLatitude : number = -1;

  @jsonMember
  public CenterPointLongitude : number = -1;

  @jsonMember
  public DefaultZoom : number = 7;

  @jsonMember
  public CenterPointLatitudeNoPoints : number = 0;

  @jsonMember
  public CenterPointLongitudeNoPoints : number = 0;

  @jsonMember
  public DefaultZoomNoPoints : number = 4;

  @jsonMember
  public RenderType : MapRenderType = MapRenderType.MARKER;

  @jsonMember
  public MarkerURL : string = 'https://openlayers.org/en/v6.4.3/examples/data/icon.png';

  @jsonMember
  public GreyScale : boolean = false;

  @jsonMember
  public WebGLStyle : string = '{ "symbol": { } }';

  public webGLErrorMessage : string = '';

  public Clone(): MapWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, MapWidgetConfig), MapWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = true;

}
