import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sys-admin-workspace',
  templateUrl: './sys-admin-workspace.component.html',
  styleUrls: ['./sys-admin-workspace.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class SysAdminWorkspaceComponent implements OnInit {

  collapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
