<main class="content-area" style="height: 100% !important;">
    <h1>PLC Program Statistics</h1>

    <xproj-dashboard [assetDashboard]="true" dashboardId="xauto/programlogs.json" [editMode]="false" [enableExport]="false" [responsiveWidth]="1400"
    [dashboardOutputParameters]="outputParameters" >
    </xproj-dashboard>
</main>
<!--
<xproj-dashboard [assetDashboard]="true" dashboardId="xauto/programlogs.json" [editMode]="false" [enableExport]="false" [responsiveWidth]="1400">
</xproj-dashboard> -->
