import { DashboardClient } from './proto/xprojector.dashboard.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { FilesClient } from './proto/xprojector.files.pbsc'
import { FileInfo } from '@xprojectorcore/models/file-info';
import { DeleteFileInfoRequest, GetFileInfoRequest, GetFileInfosRequest, GetFileRequest } from './proto/xprojector.files.pb';
import { BasicResponse, DataChunk } from './proto/xprojector.grpc.models.pb';


@Injectable({
  providedIn: 'root'
})
export class XProjectorFilesClient implements OnInit, OnDestroy {

  constructor(
    private filesClient: FilesClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async getFileInfo(id: string, customerId: string = ''): Promise<FileInfo> {
    let request: GetFileInfoRequest = new GetFileInfoRequest({ id: id, customerId: customerId });

    let grpcInfo = await this.filesClient.getFileInfo(request, this.state.metadata).toPromise();

    return new FileInfo(grpcInfo);
  }


  async getFileInfos(tags?: string[], limit?: number, skip?: number, customerId: string = ''): Promise<{ fileCount: number, files: FileInfo[] }> {
    let request: GetFileInfosRequest = new GetFileInfosRequest({ tags: tags, limit: limit, skip: skip, customerId: customerId });

    let result = await this.filesClient.getFileInfos(request, this.state.metadata).toPromise();

    return { fileCount: result.fileCount, files: result.fileInfos.map(g => new FileInfo(g)) };
  }

  async deleteFileInfo(id: string, customerId: string = ''): Promise<BasicResponse> {
    let request: DeleteFileInfoRequest = new DeleteFileInfoRequest({ id: id, customerId: customerId });

    return await this.filesClient.deleteFileInfo(request, this.state.metadata).toPromise();
  }

  async getFile(id: string, customerId: string = ''): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let request: GetFileRequest = new GetFileRequest({ id: id, customerId: customerId });
      let dataChunks: Uint8Array[] = [];

      this.filesClient.getFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            resolve(blob);
          }
          else {
            reject();
          }
        })

      );
    });
  }

}
