/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.customerusers.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
import { GRPC_CUSTOMER_USERS_CLIENT_SETTINGS } from './xprojector.customerusers.pbconf';
/**
 * Service client implementation for customerusers.CustomerUsers
 */
@Injectable({ providedIn: 'root' })
export class CustomerUsersClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /customerusers.CustomerUsers/GetActiveCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>>
     */
    getActiveCustomerUser: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.CustomerUser>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/GetActiveCustomerUser',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: xprojectorGrpcModels002.CustomerUser
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/GetActiveCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.Customer>>
     */
    getActiveCustomer: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.Customer>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/GetActiveCustomer',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: xprojectorGrpcModels002.Customer
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/ModifyCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    modifyCustomerUser: (
      requestData: xprojectorGrpcModels002.CustomerUser,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/ModifyCustomerUser',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.CustomerUser,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/SetPassword
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    setPassword: (
      requestData: thisProto.SetPasswordRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/SetPassword',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetPasswordRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Server streaming: /customerusers.CustomerUsers/CreateExcelFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.DataChunk>>
     */
    createExcelFile: (
      requestData: thisProto.CreateExcelFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/customerusers.CustomerUsers/CreateExcelFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateExcelFileRequest,
        responseClass: xprojectorGrpcModels002.DataChunk
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/GetCustomerContent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomerContentResponse>>
     */
    getCustomerContent: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomerContentResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/GetCustomerContent',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetCustomerContentResponse
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/GetActiveCustomerUserDevices
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetActiveCustomerUserDevicesResponse>>
     */
    getActiveCustomerUserDevices: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetActiveCustomerUserDevicesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/GetActiveCustomerUserDevices',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetActiveCustomerUserDevicesResponse
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/AddCustomerUserDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.UserDevice>>
     */
    addCustomerUserDevice: (
      requestData: thisProto.AddCustomerUserDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.UserDevice>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/AddCustomerUserDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddCustomerUserDeviceRequest,
        responseClass: xprojectorGrpcModels002.UserDevice
      });
    },
    /**
     * Unary call: /customerusers.CustomerUsers/DeleteCustomerUserDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteCustomerUserDevice: (
      requestData: thisProto.DeleteCustomerUserDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/customerusers.CustomerUsers/DeleteCustomerUserDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteCustomerUserDeviceRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_CUSTOMER_USERS_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'customerusers.CustomerUsers',
      settings
    );
  }

  /**
   * Unary call @/customerusers.CustomerUsers/GetActiveCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.CustomerUser>
   */
  getActiveCustomerUser(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.CustomerUser> {
    return this.$raw
      .getActiveCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/GetActiveCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.Customer>
   */
  getActiveCustomer(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.Customer> {
    return this.$raw
      .getActiveCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/ModifyCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  modifyCustomerUser(
    requestData: xprojectorGrpcModels002.CustomerUser,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .modifyCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/SetPassword
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  setPassword(
    requestData: thisProto.SetPasswordRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .setPassword(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/customerusers.CustomerUsers/CreateExcelFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.DataChunk>
   */
  createExcelFile(
    requestData: thisProto.CreateExcelFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.DataChunk> {
    return this.$raw
      .createExcelFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/GetCustomerContent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomerContentResponse>
   */
  getCustomerContent(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomerContentResponse> {
    return this.$raw
      .getCustomerContent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/GetActiveCustomerUserDevices
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetActiveCustomerUserDevicesResponse>
   */
  getActiveCustomerUserDevices(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetActiveCustomerUserDevicesResponse> {
    return this.$raw
      .getActiveCustomerUserDevices(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/AddCustomerUserDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.UserDevice>
   */
  addCustomerUserDevice(
    requestData: thisProto.AddCustomerUserDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.UserDevice> {
    return this.$raw
      .addCustomerUserDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/customerusers.CustomerUsers/DeleteCustomerUserDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteCustomerUserDevice(
    requestData: thisProto.DeleteCustomerUserDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteCustomerUserDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
