<main class="content-area">
    <h1>PLC Scheduling</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedEntry" [clrDgRowSelection]="true" [clrDgLoading]="loadingEntries">

            <clr-dg-column [clrDgField]="'ProgramSchedule.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'ProgramSchedule.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'ProgramSchedule.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'ProgramSchedule.defaultxautogroup'"> XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'ProgramSchedule.defaultxgroup'"> XGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'ProgramSchedule.defaultxgroup'"> Program </clr-dg-column>

            <clr-dg-row *clrDgItems="let entry of schedule" [clrDgItem]="entry">
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.xautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.xgroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{entry.ProgramSchedule.xautoprogramid}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Entries per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Schedule entries
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addEntry()">
        <clr-icon shape="plus-circle "></clr-icon>
        New Schedule Entry
    </button>
    <button *ngIf="selectedEntry" type="button" class="btn btn-link" [clrLoading]="savingRemovingEntry" (click)="removeEntry(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove Entry
    </button>

    <div *ngIf="selectedEntry">
        <form clrForm>
            <clr-input-container>
                <label>Entry ID </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.xautoprogramschedulingid" name="entryid" disabled size="35" />
            </clr-input-container>

            <clr-input-container>
                <label>Name </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.name" name="entryname" size="35" />
            </clr-input-container>

            <clr-input-container>
                <label>Description </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.description" name="entrydescription" size="35" />
            </clr-input-container>

            <clr-input-container>
                <label>XAuto Group </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.xautogroup" name="entryxautogroup" size="35" />
            </clr-input-container>

            <clr-input-container>
                <label>XGroup </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.xgroup" name="entryxgroup" size="35" />
            </clr-input-container>

            <clr-input-container>
                <label>XAutoProgramID </label>
                <input clrInput type="text" [(ngModel)]="selectedEntry.ProgramSchedule.xautoprogramid" name="entryxautoprogramid" size="35"
                    [disabled]="xautoProgramID?.length > 0">
            </clr-input-container>


            <clr-input-container>
                <label>Hertz </label>
                <input clrInput type="number" [(ngModel)]="selectedEntry.Interval.hertz" name="entryhertz" size="35" />
            </clr-input-container>

            <clr-toggle-container>
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle value="true" name="entryenabled" [(ngModel)]="selectedEntry.ProgramSchedule.enabled" />
                    <label>Enabled</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>


            <button class="btn" [clrLoading]="savingEntry" (click)="saveEntry()">Save</button>

        </form>

    </div>
</main>
