<!-- <form clrForm clrLayout="vertical" *ngIf="!comboboxConfig.ProjectionData" style="padding-bottom: 0; background-color:pink;"> -->
<div *ngIf="!comboboxConfig.ProjectionData" style="margin-left:1em;">
    <clr-select-container *ngIf="comboboxConfig.DataType < 6">
        <label>{{comboboxConfig.Label}}</label>
        <select clrSelect name="comboNumber" [(ngModel)]="valueMember" (change)="comboboxClicked()">
      <option *ngFor="let member of comboboxConfig.Members"  [ngValue]="member">{{member.Label}}</option>
    </select>
    </clr-select-container>
    <clr-select-container *ngIf="comboboxConfig.DataType == 6">
        <label>{{comboboxConfig.Label}}</label>
        <select clrSelect name="comboDate" [(ngModel)]="valueMember" (change)="comboboxClicked()">
      <option *ngFor="let member of comboboxConfig.Members"  [ngValue]="member">{{member.Label}}</option>
    </select>
    </clr-select-container>
    <clr-select-container *ngIf="comboboxConfig.DataType == 7" (change)="comboboxClicked()">
        <label>{{comboboxConfig.Label}}</label>
        <select clrSelect name="comboString" [(ngModel)]="valueMember">
      <option *ngFor="let member of comboboxConfig.Members"  [ngValue]="member">{{member.Label}}</option>
    </select>
    </clr-select-container>
</div>
<!-- </form> -->

<!-- <form clrForm clrLayout="vertical" *ngIf="comboboxConfig.ProjectionData" style="margin: 0;background-color:pink;"> -->
<div *ngIf="comboboxConfig.ProjectionData" style="margin-left:1em;">
    <clr-select-container *ngIf="!comboboxConfig.ProjectionDataSearchable" (change)="comboboxClicked()">
        <label>{{comboboxConfig.Label}}</label>
        <select clrSelect name="comboProjectionData" [(ngModel)]="projectionIndex">
      <option *ngFor="let label of projectionLabels; let i = index"  [ngValue]="i">{{label}}</option>
    </select>
    </clr-select-container>

    <clr-combobox-container *ngIf="comboboxConfig.ProjectionDataSearchable">
        <label>{{comboboxConfig.Label}}</label>
        <clr-combobox [(ngModel)]="selectedProjectionItem" name="comboProjectionData" (ngModelChange)="comboboxChanged($event)" (clrOpenChange)="comboboxOpenChanged($event)">
            <clr-options>
                <clr-option *clrOptionItems="let item of projectionItems; field:'label'" [clrValue]="item">
                    {{item.label}}
                </clr-option>
            </clr-options>
        </clr-combobox>
    </clr-combobox-container>
</div>
<!-- </form> -->
