import { Component, Inject, Input, OnInit } from '@angular/core';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { Transformation } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputTransformControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-transform-controller',
  templateUrl: './xproj-output-transform-controller.component.html',
  styleUrls: ['./xproj-output-transform-controller.component.scss']
})
export class XprojOutputTransformControllerComponent extends XprojOutputControllerBase implements OnInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  transformationConfig: XprojOutputTransformControllerConfig;

  selectedTransformation: Transformation;

  Transformation = Transformation;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }

  ngOnInit(): void {
    this.transformationConfig = this.config as XprojOutputTransformControllerConfig;

    this.selectedTransformation = this.transformationConfig.DefaultTransform;
  }

  ngAfterViewInit(): void {
    if (!this.useApplyButton) {
      setTimeout(() => {
        this.onSelect();
      }, 2000);
    }
  }


  onSelect() {
    //console.log('value', this.value, member);
    if (!this.useApplyButton) {
      this.widgetService.outputParameterChanged(this.getOutputChangeParameters()[0]);
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.transformationConfig.Id;
    output.datatype = OutputDataType.Transformation;
    output.value = this.selectedTransformation;

    return [output];
  }
}
