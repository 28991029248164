<!-- <span class="spinner" *ngIf="loadingEditor"></span> -->



<clr-signpost>


    <clr-signpost-content *clrIfOpen>
        <h3>SQL Info</h3>
        <ul>
            <li>Do not use limiters (ie no <i>top</i> or <i>limit</i>)</li>
            <li>Make sure the primary key is mapped as such in column configuration</li>
            <li>Use <i>{{'{from}'}}</i> in <i>where</i> statement to reduce strain on data source. Example: <i>select id, x, y from dataset where modifiedat > {{'{from}'}}</i></li>
        </ul>
    </clr-signpost-content>
</clr-signpost>

<!-- [style.height.px]="getEditorHeight(Query)" -->
<ngx-monaco-editor 
[style.height.px]="getEditorHeight(Query)"
[options]="editorOptions" 
[(ngModel)]="Query" 
(onInit)="onEditorInit()" (ngModelChange)="changed()">
</ngx-monaco-editor>

<clr-accordion *ngIf="wrapInputinAccordion">
    <clr-accordion-panel>
        <clr-accordion-title>
            Test query
        </clr-accordion-title>

        <clr-accordion-content>
            <button class="btn" [clrLoading]="executingQuery" (click)="RunQuery(Query)">Execute</button>

            <div *ngIf="probeQueryResult" class="limit-height">

                <clr-datagrid [clrDgLoading]="loadingQuery">

                    <clr-dg-column *ngFor="let obj of queryData"> {{obj.name}}</clr-dg-column>

                    <clr-dg-row *ngFor="let obj of queryData[0].data ; index as i">
                        <clr-dg-cell *ngFor="let col of queryData"> {{col.data[i] | xproj_pretty}}</clr-dg-cell>
                    </clr-dg-row>

                    <clr-dg-footer>{{queryData.length}} Columns x {{queryData[0].data.length}} Rows</clr-dg-footer>
                </clr-datagrid>
            </div>

        </clr-accordion-content>

    </clr-accordion-panel>
</clr-accordion>


<button *ngIf="!wrapInputinAccordion" [clrLoading]="executingQuery" class="btn" (click)="RunQuery(Query)">Execute</button>
<div *ngIf="!wrapInputinAccordion && probeQueryResult" class="limit-height">

    <clr-datagrid [clrDgLoading]="loadingQuery">

        <clr-dg-column *ngFor="let obj of queryData"> {{obj.name}}</clr-dg-column>

        <clr-dg-row *ngFor="let obj of queryData[0].data ; index as i">
            <clr-dg-cell *ngFor="let col of queryData"> {{col.data[i] | xproj_pretty}}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>{{queryData.length}} Columns x {{queryData[0].data.length}} Rows</clr-dg-footer>
    </clr-datagrid>
</div>
