/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as xprojectorGrpcModels001 from './xprojector.grpc.models.pb';
import * as googleProtobuf002 from './google/protobuf/empty.pb';
/**
 * Message implementation for dashboard.LoadDashboardRequest
 */
export class LoadDashboardRequest implements GrpcMessage {
  static id = 'dashboard.LoadDashboardRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoadDashboardRequest();
    LoadDashboardRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoadDashboardRequest) {
    _instance.id = _instance.id || '';
    _instance.systemDashboard = _instance.systemDashboard || false;
    _instance.version = _instance.version || 0;
    _instance.tag = _instance.tag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoadDashboardRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.systemDashboard = _reader.readBool();
          break;
        case 3:
          _instance.version = _reader.readInt32();
          break;
        case 4:
          _instance.tag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    LoadDashboardRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoadDashboardRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.systemDashboard) {
      _writer.writeBool(2, _instance.systemDashboard);
    }
    if (_instance.version) {
      _writer.writeInt32(3, _instance.version);
    }
    if (_instance.tag) {
      _writer.writeString(4, _instance.tag);
    }
  }

  private _id?: string;
  private _systemDashboard?: boolean;
  private _version?: number;
  private _tag?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoadDashboardRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoadDashboardRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.systemDashboard = _value.systemDashboard;
    this.version = _value.version;
    this.tag = _value.tag;
    LoadDashboardRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get systemDashboard(): boolean | undefined {
    return this._systemDashboard;
  }
  set systemDashboard(value: boolean | undefined) {
    this._systemDashboard = value;
  }
  get version(): number | undefined {
    return this._version;
  }
  set version(value: number | undefined) {
    this._version = value;
  }
  get tag(): string | undefined {
    return this._tag;
  }
  set tag(value: string | undefined) {
    this._tag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoadDashboardRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoadDashboardRequest.AsObject {
    return {
      id: this.id,
      systemDashboard: this.systemDashboard,
      version: this.version,
      tag: this.tag
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoadDashboardRequest.AsProtobufJSON {
    return {
      id: this.id,
      systemDashboard: this.systemDashboard,
      version: this.version,
      tag: this.tag
    };
  }
}
export module LoadDashboardRequest {
  /**
   * Standard JavaScript object representation for LoadDashboardRequest
   */
  export interface AsObject {
    id?: string;
    systemDashboard?: boolean;
    version?: number;
    tag?: string;
  }

  /**
   * Protobuf JSON representation for LoadDashboardRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    systemDashboard?: boolean;
    version?: number;
    tag?: string;
  }
}

/**
 * Message implementation for dashboard.LoadDashboardsRequest
 */
export class LoadDashboardsRequest implements GrpcMessage {
  static id = 'dashboard.LoadDashboardsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoadDashboardsRequest();
    LoadDashboardsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoadDashboardsRequest) {
    _instance.id = _instance.id || '';
    _instance.systemDashboard = _instance.systemDashboard || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoadDashboardsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.systemDashboard = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    LoadDashboardsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoadDashboardsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.systemDashboard) {
      _writer.writeBool(2, _instance.systemDashboard);
    }
  }

  private _id?: string;
  private _systemDashboard?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoadDashboardsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoadDashboardsRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.systemDashboard = _value.systemDashboard;
    LoadDashboardsRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get systemDashboard(): boolean | undefined {
    return this._systemDashboard;
  }
  set systemDashboard(value: boolean | undefined) {
    this._systemDashboard = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoadDashboardsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoadDashboardsRequest.AsObject {
    return {
      id: this.id,
      systemDashboard: this.systemDashboard
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoadDashboardsRequest.AsProtobufJSON {
    return {
      id: this.id,
      systemDashboard: this.systemDashboard
    };
  }
}
export module LoadDashboardsRequest {
  /**
   * Standard JavaScript object representation for LoadDashboardsRequest
   */
  export interface AsObject {
    id?: string;
    systemDashboard?: boolean;
  }

  /**
   * Protobuf JSON representation for LoadDashboardsRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    systemDashboard?: boolean;
  }
}

/**
 * Message implementation for dashboard.DeleteDashboardRequest
 */
export class DeleteDashboardRequest implements GrpcMessage {
  static id = 'dashboard.DeleteDashboardRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteDashboardRequest();
    DeleteDashboardRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteDashboardRequest) {
    _instance.id = _instance.id || '';
    _instance.version = _instance.version || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteDashboardRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.version = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteDashboardRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteDashboardRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.version) {
      _writer.writeInt32(2, _instance.version);
    }
  }

  private _id?: string;
  private _version?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteDashboardRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteDashboardRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.version = _value.version;
    DeleteDashboardRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get version(): number | undefined {
    return this._version;
  }
  set version(value: number | undefined) {
    this._version = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteDashboardRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteDashboardRequest.AsObject {
    return {
      id: this.id,
      version: this.version
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteDashboardRequest.AsProtobufJSON {
    return {
      id: this.id,
      version: this.version
    };
  }
}
export module DeleteDashboardRequest {
  /**
   * Standard JavaScript object representation for DeleteDashboardRequest
   */
  export interface AsObject {
    id?: string;
    version?: number;
  }

  /**
   * Protobuf JSON representation for DeleteDashboardRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    version?: number;
  }
}

/**
 * Message implementation for dashboard.DashboardConfigTestValue
 */
export class DashboardConfigTestValue implements GrpcMessage {
  static id = 'dashboard.DashboardConfigTestValue';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DashboardConfigTestValue();
    DashboardConfigTestValue.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DashboardConfigTestValue) {
    _instance.key = _instance.key || '';
    _instance.value = _instance.value || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DashboardConfigTestValue,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.key = _reader.readString();
          break;
        case 2:
          _instance.value = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DashboardConfigTestValue.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DashboardConfigTestValue,
    _writer: BinaryWriter
  ) {
    if (_instance.key) {
      _writer.writeString(1, _instance.key);
    }
    if (_instance.value) {
      _writer.writeString(2, _instance.value);
    }
  }

  private _key?: string;
  private _value?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DashboardConfigTestValue to deeply clone from
   */
  constructor(_value?: RecursivePartial<DashboardConfigTestValue.AsObject>) {
    _value = _value || {};
    this.key = _value.key;
    this.value = _value.value;
    DashboardConfigTestValue.refineValues(this);
  }
  get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
  }
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DashboardConfigTestValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DashboardConfigTestValue.AsObject {
    return {
      key: this.key,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DashboardConfigTestValue.AsProtobufJSON {
    return {
      key: this.key,
      value: this.value
    };
  }
}
export module DashboardConfigTestValue {
  /**
   * Standard JavaScript object representation for DashboardConfigTestValue
   */
  export interface AsObject {
    key?: string;
    value?: string;
  }

  /**
   * Protobuf JSON representation for DashboardConfigTestValue
   */
  export interface AsProtobufJSON {
    key?: string;
    value?: string;
  }
}

/**
 * Message implementation for dashboard.DashboardConfig
 */
export class DashboardConfig implements GrpcMessage {
  static id = 'dashboard.DashboardConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DashboardConfig();
    DashboardConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DashboardConfig) {
    _instance.id = _instance.id || '';
    _instance.json = _instance.json || '';
    _instance.description = _instance.description || '';
    _instance.version = _instance.version || 0;
    _instance.tag = _instance.tag || '';
    _instance.lastModified = _instance.lastModified || undefined;
    _instance.testValues = _instance.testValues || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DashboardConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.json = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.version = _reader.readInt32();
          break;
        case 5:
          _instance.tag = _reader.readString();
          break;
        case 6:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          const messageInitializer7 = new DashboardConfigTestValue();
          _reader.readMessage(
            messageInitializer7,
            DashboardConfigTestValue.deserializeBinaryFromReader
          );
          (_instance.testValues = _instance.testValues || []).push(
            messageInitializer7
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DashboardConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DashboardConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.json) {
      _writer.writeString(2, _instance.json);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.version) {
      _writer.writeInt32(4, _instance.version);
    }
    if (_instance.tag) {
      _writer.writeString(5, _instance.tag);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        6,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.testValues && _instance.testValues.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.testValues as any,
        DashboardConfigTestValue.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _json?: string;
  private _description?: string;
  private _version?: number;
  private _tag?: string;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _testValues?: DashboardConfigTestValue[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DashboardConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<DashboardConfig.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.json = _value.json;
    this.description = _value.description;
    this.version = _value.version;
    this.tag = _value.tag;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.testValues = (_value.testValues || []).map(
      m => new DashboardConfigTestValue(m)
    );
    DashboardConfig.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get json(): string | undefined {
    return this._json;
  }
  set json(value: string | undefined) {
    this._json = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get version(): number | undefined {
    return this._version;
  }
  set version(value: number | undefined) {
    this._version = value;
  }
  get tag(): string | undefined {
    return this._tag;
  }
  set tag(value: string | undefined) {
    this._tag = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get testValues(): DashboardConfigTestValue[] | undefined {
    return this._testValues;
  }
  set testValues(value: DashboardConfigTestValue[] | undefined) {
    this._testValues = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DashboardConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DashboardConfig.AsObject {
    return {
      id: this.id,
      json: this.json,
      description: this.description,
      version: this.version,
      tag: this.tag,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      testValues: (this.testValues || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DashboardConfig.AsProtobufJSON {
    return {
      id: this.id,
      json: this.json,
      description: this.description,
      version: this.version,
      tag: this.tag,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      testValues: (this.testValues || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module DashboardConfig {
  /**
   * Standard JavaScript object representation for DashboardConfig
   */
  export interface AsObject {
    id?: string;
    json?: string;
    description?: string;
    version?: number;
    tag?: string;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    testValues?: DashboardConfigTestValue.AsObject[];
  }

  /**
   * Protobuf JSON representation for DashboardConfig
   */
  export interface AsProtobufJSON {
    id?: string;
    json?: string;
    description?: string;
    version?: number;
    tag?: string;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    testValues?: DashboardConfigTestValue.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for dashboard.LoadDashboardsResponse
 */
export class LoadDashboardsResponse implements GrpcMessage {
  static id = 'dashboard.LoadDashboardsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoadDashboardsResponse();
    LoadDashboardsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoadDashboardsResponse) {
    _instance.dashboardConfigs = _instance.dashboardConfigs || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoadDashboardsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new DashboardConfig();
          _reader.readMessage(
            messageInitializer1,
            DashboardConfig.deserializeBinaryFromReader
          );
          (_instance.dashboardConfigs = _instance.dashboardConfigs || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LoadDashboardsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoadDashboardsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.dashboardConfigs && _instance.dashboardConfigs.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dashboardConfigs as any,
        DashboardConfig.serializeBinaryToWriter
      );
    }
  }

  private _dashboardConfigs?: DashboardConfig[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoadDashboardsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoadDashboardsResponse.AsObject>) {
    _value = _value || {};
    this.dashboardConfigs = (_value.dashboardConfigs || []).map(
      m => new DashboardConfig(m)
    );
    LoadDashboardsResponse.refineValues(this);
  }
  get dashboardConfigs(): DashboardConfig[] | undefined {
    return this._dashboardConfigs;
  }
  set dashboardConfigs(value: DashboardConfig[] | undefined) {
    this._dashboardConfigs = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoadDashboardsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoadDashboardsResponse.AsObject {
    return {
      dashboardConfigs: (this.dashboardConfigs || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoadDashboardsResponse.AsProtobufJSON {
    return {
      dashboardConfigs: (this.dashboardConfigs || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module LoadDashboardsResponse {
  /**
   * Standard JavaScript object representation for LoadDashboardsResponse
   */
  export interface AsObject {
    dashboardConfigs?: DashboardConfig.AsObject[];
  }

  /**
   * Protobuf JSON representation for LoadDashboardsResponse
   */
  export interface AsProtobufJSON {
    dashboardConfigs?: DashboardConfig.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for dashboard.DashboardTree
 */
export class DashboardTree implements GrpcMessage {
  static id = 'dashboard.DashboardTree';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DashboardTree();
    DashboardTree.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DashboardTree) {
    _instance.id = _instance.id || '';
    _instance.json = _instance.json || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DashboardTree,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.json = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DashboardTree.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DashboardTree,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.json) {
      _writer.writeString(2, _instance.json);
    }
  }

  private _id?: string;
  private _json?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DashboardTree to deeply clone from
   */
  constructor(_value?: RecursivePartial<DashboardTree.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.json = _value.json;
    DashboardTree.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get json(): string | undefined {
    return this._json;
  }
  set json(value: string | undefined) {
    this._json = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DashboardTree.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DashboardTree.AsObject {
    return {
      id: this.id,
      json: this.json
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DashboardTree.AsProtobufJSON {
    return {
      id: this.id,
      json: this.json
    };
  }
}
export module DashboardTree {
  /**
   * Standard JavaScript object representation for DashboardTree
   */
  export interface AsObject {
    id?: string;
    json?: string;
  }

  /**
   * Protobuf JSON representation for DashboardTree
   */
  export interface AsProtobufJSON {
    id?: string;
    json?: string;
  }
}

/**
 * Message implementation for dashboard.LoadDashboardTreeRequest
 */
export class LoadDashboardTreeRequest implements GrpcMessage {
  static id = 'dashboard.LoadDashboardTreeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoadDashboardTreeRequest();
    LoadDashboardTreeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoadDashboardTreeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoadDashboardTreeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    LoadDashboardTreeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoadDashboardTreeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoadDashboardTreeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoadDashboardTreeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    LoadDashboardTreeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoadDashboardTreeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoadDashboardTreeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoadDashboardTreeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module LoadDashboardTreeRequest {
  /**
   * Standard JavaScript object representation for LoadDashboardTreeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for LoadDashboardTreeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for dashboard.LoadDashboardTreesResponse
 */
export class LoadDashboardTreesResponse implements GrpcMessage {
  static id = 'dashboard.LoadDashboardTreesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoadDashboardTreesResponse();
    LoadDashboardTreesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoadDashboardTreesResponse) {
    _instance.trees = _instance.trees || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoadDashboardTreesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new DashboardTree();
          _reader.readMessage(
            messageInitializer1,
            DashboardTree.deserializeBinaryFromReader
          );
          (_instance.trees = _instance.trees || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    LoadDashboardTreesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoadDashboardTreesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.trees && _instance.trees.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.trees as any,
        DashboardTree.serializeBinaryToWriter
      );
    }
  }

  private _trees?: DashboardTree[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoadDashboardTreesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoadDashboardTreesResponse.AsObject>) {
    _value = _value || {};
    this.trees = (_value.trees || []).map(m => new DashboardTree(m));
    LoadDashboardTreesResponse.refineValues(this);
  }
  get trees(): DashboardTree[] | undefined {
    return this._trees;
  }
  set trees(value: DashboardTree[] | undefined) {
    this._trees = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoadDashboardTreesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoadDashboardTreesResponse.AsObject {
    return {
      trees: (this.trees || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoadDashboardTreesResponse.AsProtobufJSON {
    return {
      trees: (this.trees || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module LoadDashboardTreesResponse {
  /**
   * Standard JavaScript object representation for LoadDashboardTreesResponse
   */
  export interface AsObject {
    trees?: DashboardTree.AsObject[];
  }

  /**
   * Protobuf JSON representation for LoadDashboardTreesResponse
   */
  export interface AsProtobufJSON {
    trees?: DashboardTree.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for dashboard.DeleteDashboardTreeRequest
 */
export class DeleteDashboardTreeRequest implements GrpcMessage {
  static id = 'dashboard.DeleteDashboardTreeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteDashboardTreeRequest();
    DeleteDashboardTreeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteDashboardTreeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteDashboardTreeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteDashboardTreeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteDashboardTreeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteDashboardTreeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteDashboardTreeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    DeleteDashboardTreeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteDashboardTreeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteDashboardTreeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteDashboardTreeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteDashboardTreeRequest {
  /**
   * Standard JavaScript object representation for DeleteDashboardTreeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteDashboardTreeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for dashboard.UpdateDashboardIdRequest
 */
export class UpdateDashboardIdRequest implements GrpcMessage {
  static id = 'dashboard.UpdateDashboardIdRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateDashboardIdRequest();
    UpdateDashboardIdRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateDashboardIdRequest) {
    _instance.oldId = _instance.oldId || '';
    _instance.newId = _instance.newId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateDashboardIdRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.oldId = _reader.readString();
          break;
        case 2:
          _instance.newId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateDashboardIdRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateDashboardIdRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.oldId) {
      _writer.writeString(1, _instance.oldId);
    }
    if (_instance.newId) {
      _writer.writeString(2, _instance.newId);
    }
  }

  private _oldId?: string;
  private _newId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateDashboardIdRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateDashboardIdRequest.AsObject>) {
    _value = _value || {};
    this.oldId = _value.oldId;
    this.newId = _value.newId;
    UpdateDashboardIdRequest.refineValues(this);
  }
  get oldId(): string | undefined {
    return this._oldId;
  }
  set oldId(value: string | undefined) {
    this._oldId = value;
  }
  get newId(): string | undefined {
    return this._newId;
  }
  set newId(value: string | undefined) {
    this._newId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateDashboardIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateDashboardIdRequest.AsObject {
    return {
      oldId: this.oldId,
      newId: this.newId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateDashboardIdRequest.AsProtobufJSON {
    return {
      oldId: this.oldId,
      newId: this.newId
    };
  }
}
export module UpdateDashboardIdRequest {
  /**
   * Standard JavaScript object representation for UpdateDashboardIdRequest
   */
  export interface AsObject {
    oldId?: string;
    newId?: string;
  }

  /**
   * Protobuf JSON representation for UpdateDashboardIdRequest
   */
  export interface AsProtobufJSON {
    oldId?: string;
    newId?: string;
  }
}
