import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';

@Component({
  selector: 'app-gdpr-consent',
  templateUrl: './gdpr-consent.component.html',
  styleUrls: ['./gdpr-consent.component.scss']
})
export class GdprConsentComponent implements OnInit {

  agreeEnabled : boolean = false;

  constructor(
    private router: Router,
    private state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient
  ) { }

  ngOnInit(): void {
  }

  async deny() {
    this.router.navigate(['/login']);
  }

  async agree() {
    let userRights = this.state.userRightsValue;

    let user = await this.customerUserClient.getActiveCustomerUser();

    if (user) {
      user.gdprConsent = true;
      await this.customerUserClient.modifyCustomerUser(user);
      this.state.userRightsValue.gdprConsent = true;

      this.router.navigate(['/customers']);
    }

  }

  @HostListener('scroll', ['$event'])
  onScroll($event) {
    if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
      this.agreeEnabled = true;
    }
  }
}
