import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Aggregation, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { OutputDataType, WidgetConfig, WidgetOutputParameter } from '../../widget-config-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';
import { XprojWidgetService } from '../../xproj-widget-service';
import { XprojMapWidgetQueryConfigComponent } from '../map-widget-query-config/xproj-map-widget-query-config.component';
import { MapRenderType, MapWidgetConfig, MapWidgetQuery } from './xproj-map-widget-config-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xproj-map-widget-config',
  templateUrl: './xproj-map-widget-config.component.html',
  styleUrls: ['./xproj-map-widget-config.component.scss']
})
export class XprojMapWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  @ViewChildren(XprojMapWidgetQueryConfigComponent, { read: XprojMapWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojMapWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;
  // @ViewChild("style-valid", { read: ElementRef, static: false }) spanValid: ElementRef;
  // @ViewChild("style-invalid", { read: ElementRef, static: false }) spanInvalid: ElementRef;
  // @ViewChild("style-editor", { read: ElementRef, static: false }) editor: ElementRef;

  editorOptions = {theme: environment.editortheme, language: 'json',automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap:{enabled: false}};

  widgetConfig: MapWidgetConfig;

  projections: Projection[] = [];

  webGLStyle : string = '';

  MapRenderType = MapRenderType;

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
    this.updateInputParameters();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as MapWidgetConfig;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.widgetConfig.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }

    this.webGLStyle = this.widgetConfig.WebGLStyle;
  }

  addConfigQuery(): void {
    this.widgetConfig.ConfigQueries.push(new MapWidgetQuery());
  }

  onQueryRemoved(query: MapWidgetQuery) {
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
  }

  async onSaveConfig() {
    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    this.widgetConfig.WebGLStyle = this.webGLStyle;

    this.widgetConfig.OutputParameters = [];
    this.widgetConfig.ConfigQueries.forEach((configQuery) => {
      configQuery.ColumnConfigs.forEach((col) => {
        if (col.Clickable) {
          let output = new WidgetOutputParameter();
          output.id = col.Id;
          output.datatype = col.Transform != Aggregation.NONE ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[col.Datatype]);
          output.name = col.Label?.length > 0 ? col.Label : col.ColumnName;
          this.widgetConfig.OutputParameters.push(output);
        }

      });
    });

    //Map bounderies
    let lat_min = new WidgetOutputParameter();
    lat_min.id = 'latitude_min';
    lat_min.datatype = OutputDataType.Number;
    lat_min.name = "Latitude min";
    this.widgetConfig.OutputParameters.push(lat_min);

    let lat_max = new WidgetOutputParameter();
    lat_max.id = 'latitude_max';
    lat_max.datatype = OutputDataType.Number;
    lat_max.name = "Latitude max";
    this.widgetConfig.OutputParameters.push(lat_max);

    let lon_min = new WidgetOutputParameter();
    lon_min.id = 'longitude_min';
    lon_min.datatype = OutputDataType.Number;
    lon_min.name = "Longitude min";
    this.widgetConfig.OutputParameters.push(lon_min);

    let lon_max = new WidgetOutputParameter();
    lon_max.id = 'longitude_max';
    lon_max.datatype = OutputDataType.Number;
    lon_max.name = "Longitude max";
    this.widgetConfig.OutputParameters.push(lon_max);

  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }

//   setStyleStatus(errorMsg) {
//     var isError = typeof errorMsg === 'string';
//     this.spanValid.nativeElement.style.display = errorMsg === null ? 'initial' : 'none';
//     this.spanInvalid.nativeElement.firstElementChild.innerText = isError ? errorMsg : '';
//     this.spanInvalid.nativeElement.style.display = isError ? 'initial' : 'none';
//   }

// onWebGLEditorInput($event) {
//   try {
//     var newLiteralStyle = JSON.parse(this.widgetConfig.WebGLStyle);
//     this.setStyleStatus(null);
//   } catch (e) {
//     this.setStyleStatus(e.message);
//   }
// }


}
