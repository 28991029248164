<div style="background-color: #1d2c33; text-align: center;">
    <img style="padding-top:10px;" src="/assets/robotrisa.svg">
    <h1>Log in via QR code</h1>

    <pre *ngIf="ScannedData != ''">
        {{ScannedData}}
    </pre>
    <button *ngIf="!scanning" class="btn btn-secondary" (click)="ScannedData='';scan()" [disabled]="disablescan">Re-Scan</button>
        
</div>


<!-- <button class="btn btn-secondary" (click)="scan()" [disabled]="disablescan">Scan</button> -->
<!-- Scan QR <br>
<pre>
{{ScannedData}}
</pre>

<button class="btn btn-secondary" (click)="scan()" [disabled]="disablescan">Scan</button> -->