import { Component, OnInit, Input, Inject } from '@angular/core';

import { Subject } from 'rxjs';

import {ClrDatagridFilterInterface, ClrDatagridFilter, ClrDatagridStringFilterInterface} from "@clr/angular";
import { TextSearchQueryResult, TextSearchQuery, XProjectorClient } from '../../XProjector/xprojector-client-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';

@Component({
    selector: "xproj-datagrid-string-filter",
    templateUrl: './xproj-datagrid-string-filter.component.html',
    styleUrls: ['./xproj-datagrid-string-filter.component.scss']
})
export class XprojDatagridstringfilterComponent implements OnInit, ClrDatagridStringFilterInterface<any>
{
    name = 'DatagridstringfilterComponent';
    custom = true;

    public ExistingStrings= [];
    public SelectedStrings = [];
    public searchfilter = "";

    disabled = false;
    loading = false;
    @Input() projectionid : string;
    @Input() columnname : string;
    @Input() group : string[] = [];
    @Input() startWithFilter : boolean = false;

    changes = new Subject<any>();

    constructor(private filterContainer: ClrDatagridFilter,
      private xprojClient: XProjectorClient,
      @Inject(LOGGERSERVICE) private logger: XprojLoggerService) {
        filterContainer.setFilter(this);
    }

    change(): void {
      this.changes.next(true);
    }


    remove(str: string)
    {
      for(let i = 0; i < this.SelectedStrings.length; i++)
      {
        if(this.SelectedStrings[i]!=str)
          continue;
        this.SelectedStrings.splice(i,1);
        i--;
      }
      this.change();
	  }

  async onSearchValueChange(searchValue: string)
	{
    if (this.loading || !searchValue || searchValue.length < 2) {
      return;
    }

    this.loading = true;
      try
      {
        this.ExistingStrings.length = 0;
        let query = new TextSearchQuery();
        query.columnname = this.columnname;
        query.targetprojectionid = this.projectionid;
        query.searchvalue = searchValue;
        if(this.group && this.group.length>0)
          query.targetgroup = this.group;
        else
          query.targetgroup = new Array<string>();
        let x = await this.xprojClient.RequestQueryTextsearch(query);
        this.logger.info(x);
        this.logger.info(x.strings);
        for(let o of x.strings)
        {
          this.logger.info(o);
          this.ExistingStrings.push(o);
        }

        this.logger.info("this.ExistingStrings:");
        this.logger.info(this.ExistingStrings);
      }
      catch(err)
      {
        this.logger.error(err);
      }
      this.loading = false;

      if (this.startWithFilter) {
        this.SelectedStrings = this.ExistingStrings;
      }
    }

    submit()
    {
      this.SelectedStrings.push(this.searchfilter);
      this.change();
    }

    isActive(): boolean {
     return this.SelectedStrings.length>0;
    }

    accepts(value: any) :boolean
    {
      return true;
    }

    ngOnInit(): void {
    }

}
