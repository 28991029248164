import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OutputDataType } from '../../../../../widget-config-service';
import { ArrayUtils } from '../../../../../../utils/array-utils-service';
import { BaseControllerConfig, ButtonConfig, ButtonType, XprojOutputButtonControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { OffsetType, RelativeTimestampOrigo } from '../../../../../../XProjector/xprojector-client-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xproj-output-button-controller-config',
  templateUrl: './xproj-output-button-controller-config.component.html',
  styleUrls: ['./xproj-output-button-controller-config.component.scss']
})
export class XprojOutputButtonControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.buttonConfig = value as XprojOutputButtonControllerConfig;
  }

  editorOptionsLua = {
    theme: environment.editortheme,
    language: 'lua',
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap: { enabled: true }
  };


  buttonConfig : XprojOutputButtonControllerConfig;

  selectedButton: ButtonConfig;

  OutputDataType = OutputDataType;
  ButtonType = ButtonType;
  RelativeTimestampOrigo = RelativeTimestampOrigo;
  OffsetType = OffsetType;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  addButton() {
    this.buttonConfig.Buttons.push(new ButtonConfig());
  }

  removeSelectedButton() {
    let i = this.buttonConfig.Buttons.findIndex(b => b == this.selectedButton);
    if (i > -1) {
      this.removeButton(i);
    }
  }

  removeButton(index: number) {
    ArrayUtils.RemoveItemAt(this.buttonConfig.Buttons, index);
  }

  moveButtonUp(index: number) {
    ArrayUtils.MoveItemUp(this.buttonConfig.Buttons, index);
    this.refreshColumnConfigs();
  }

  moveButtonDown(index: number) {
    ArrayUtils.MoveItemDown(this.buttonConfig.Buttons, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.buttonConfig.Buttons];
    this.buttonConfig.Buttons = [];
    this.cdr.detectChanges();
    this.buttonConfig.Buttons = copy;
  }

  defaultClick(button : ButtonConfig) {
    //console.log("click", button, $event);
    if (button.Default) {
      this.buttonConfig.Buttons.forEach(btn => {
        if (btn != button) {
          btn.Default = false;
        }
      });
    }
  }
}
