import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { SearchNodesRequest, SearchNodesResponse, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { threadId } from 'worker_threads';
import { ArrayUtils } from 'xproj-lib';
import { SearchForm } from '../configuration-search/configuration-search.component';

export class Treeview implements TreeNodeBase {
  children: TreeNode[] = [];
  expanded: boolean = false;
}

export class TreeNode implements TreeNodeBase {
  node: GrpcNode;
  id: string;
  children: TreeNode[] = null;
  expanded: boolean = false;
  name : string;
}

export abstract class TreeNodeBase {
  children: TreeNode[];
  expanded: boolean;
}

@Component({
  selector: 'app-configuration-select-node',
  templateUrl: './configuration-select-node.component.html',
  styleUrls: ['./configuration-select-node.component.scss']
})
export class ConfigurationSelectNodeComponent implements OnInit {

  private _rootId : string;
  @Input()
  get rootId(): string{
    return this._rootId;
  }
  set rootId(value : string) {
    if (this._rootId != value) {
      this.init();
    }
    this._rootId = value;
  }
  @Input() rootLabel: string;
  @Input() rootIsCustomerSpecific : boolean = false;
  @Input() customerId: string = '';
  @Input() referenceNodeTypeId: string;
  @Input() stopOnReferenceNodeType: boolean = true;
  @Input() getNodeDisplayName: Function;
  @Input() searchParameterIds: {typeName : string, key : string}[] = [];


  @Output() onNodeSelect = new EventEmitter<GrpcNode>();

  treeview: Treeview;
  selected : TreeNode;
  initiated : boolean = false;
  selectedIndex : number = -1;

  searchValue : string = '';
  searching : boolean = false;
  searchResultNodes : TreeNode[] = [];
  searchInitiated : boolean = false;


  constructor(
    private xConfClient: XProjectorXConfClient,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    //this.init();
  }

  async init() {
    this.treeview = new Treeview();
    this.treeview.children = [];
    this.initiated = false;

    setTimeout(async () => {
      let rootNode = await this.xConfClient.getNode(this.rootId, this.rootLabel, this.rootIsCustomerSpecific, this.customerId);
      if (rootNode) {
        let treenode = new TreeNode();
        treenode.node = rootNode;
        treenode.id = treenode.node.id;
        treenode.name = this.getNodeDisplayName ? await this.getNodeDisplayName(treenode.node) : (treenode.node.name || treenode.node.id);
        this.treeview.children.push(treenode);
      }
      this.initiated = true;
    });
  }

  getChildren = this.getChildNodes.bind(this);

  async getChildNodes(treenode: TreeNode): Promise<TreeNode[]> {
    try {
      if (treenode && treenode.node && (!this.stopOnReferenceNodeType || treenode.node.id == this.rootId || !(treenode.node.nodeTypeId == this.referenceNodeTypeId || treenode.node.nodeTypeLabel == this.referenceNodeTypeId) )) {
        if (!treenode.children) {
          treenode.children = [];
          let nodes = await this.xConfClient.getReferencedNodes(treenode.node.id, treenode.node.nodeTypeLabel, []);
          await ArrayUtils.AsyncForEach(nodes, async (n) => {
            let child = new TreeNode();
            child.node = n;
            child.id = n.id;
            child.name = this.getNodeDisplayName ? await this.getNodeDisplayName(n) : (n.name || n.id);
            treenode.children.push(child);
          })
        }

        return Promise.resolve(treenode.children);
      }
      else {
        return Promise.resolve([]);
      }
    }
    catch {
      return Promise.resolve([]);
    }
  };

  selectItem(item: TreeNode) {
    this.searchResultNodes = [];
    this.searchInitiated = false;
    if (item?.node?.nodeTypeId == this.referenceNodeTypeId || item?.node?.nodeTypeLabel == this.referenceNodeTypeId) {
      this.selected = item;
      this.onNodeSelect?.next(this.selected.node);
    }
    else {
      this.selected = null;
      this.onNodeSelect?.next(null);
    }
  }

  async search() {
    try {
      this.searchInitiated = true;
      this.searchResultNodes = [];
      let rootId = this.rootId;
      if (this.treeview.children.length > 0) {
        rootId = this.treeview.children[0].id;
      }
      this.searching = true;
      let request = new SearchNodesRequest();
      request.rootId = rootId;
      request.rootLabel = this.rootLabel;
      request.propertiesOperatorAnd = false;
      request.limit = 10;
      request.maxHops = -1;
      request.skip = 0;
      request.label = this.referenceNodeTypeId;

      let idSearchProperty = new SearchProperty();
      idSearchProperty.typeName = 'string';
      idSearchProperty.key = '_id';
      idSearchProperty.value = this.searchValue;
      idSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
      request.properties.push(idSearchProperty);

      this.searchParameterIds.forEach(p => {
        let searchProperty = new SearchProperty();
        searchProperty.typeName = p.typeName;
        searchProperty.key = p.key;
        searchProperty.value = this.searchValue;
        searchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(searchProperty);
      });

      let searchResult = await this.xConfClient.searchNodes(request)

      await ArrayUtils.AsyncForEach(searchResult.nodes, async (node : GrpcNode) => {
        let treeNode = new TreeNode();
        treeNode.node = node;
        treeNode.id = node.id;
        treeNode.name = this.getNodeDisplayName ? await this.getNodeDisplayName(node) : (node.name || node.id);
        this.searchResultNodes.push(treeNode);
      });

      if (searchResult.nodes.length > 0) {
        this.selectedIndex = 0;
        this.selected = null;
        this.onNodeSelect?.next(searchResult.nodes[0]);
      }
    }
    finally {
      this.searching = false;
    }
  }

  async onRowClick($event, i) {
    this.selectedIndex = i;
    this.selected = null;
    if (this.selectedIndex > 0 && this.selectedIndex < this.searchResultNodes.length) {
      this.onNodeSelect?.next(this.searchResultNodes[this.selectedIndex].node);
    }
  }
}
