export class RossakerBmsGateway {
  id : number;

  ipProtocol : string;
  endpointURL : string;
  serialnumber : string;
  vendor : string;
  model : string;
  placing : string;

  createdAt : Date;
  modifiedAt : Date;
}
