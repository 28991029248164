<form clrForm clrLayout="vertical">
  <clr-select-container>
    <label>Default projection</label>
    <select clrSelect name="projection" [(ngModel)]="selectedProjection" (ngModelChange)="selectedProjectionChange()">
      <option [ngValue]="null"> </option>
      <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name | xproj_pretty}}</option>
    </select>
  </clr-select-container>
  <xproj-group-selection #groupSelect [projection]="selectedProjection" [selected]="projectionConfig.DefaultGroup" (groupSelected)="groupSelected($event)">
  </xproj-group-selection>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="ShowProjection" [(ngModel)]="projectionConfig.ShowProjection" />
      <label>Show projection</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="ShowGroup" [(ngModel)]="projectionConfig.ShowGroup" />
      <label>Show group</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

</form>
