import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { BaseQuery, DownSampleQuery, BaseQueryResult, XDataType, ColumnFilteringRelativeTimestamp, XProjectorClient, BaseQueryInputColumnDescription, Aggregation, Transformation, ColumnFilteringNumerical, ColumnFilteringString, FilterComparator, ColumnGroupingDescription, FilterLogicalGroup } from '../../../XProjector/xprojector-client-service';
import { XprojWidgetService, LinkedWidgetChangeParameters, ZoomParameters, HiglightParameters, WidgetOutputChangeParameters, MasterTimeSettings } from '../../xproj-widget-service';
import { Subscription } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import Chart from 'chart.js/auto';
import { ChartWidgetConfig, ChartWidgetQuery, LineConfig, YAxisId } from '../chart-widget-config/xproj-chart-widget-config-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { ChartUtils } from '../../../utils/chart-utils-service';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import autocolors from 'chartjs-plugin-autocolors';
import chartStreaming from 'chartjs-plugin-streaming';
import { TypedJSON } from 'typedjson';
import { Guid } from '../../../utils/guid-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { ColorHelper } from '../../../helpers/color-helper-service';
import { WidgetBase } from '../../widget-base';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { GroupSelectionTypes, OutputDataType } from '../../widget-config-service';
import { XprojCommonStringsService } from '../../../i18n/xproj-common-strings.service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

Chart.register(zoomPlugin);
Chart.register(annotationPlugin);
Chart.register(autocolors);
Chart.register(chartStreaming);


export class AxisSettings {
  public date_x_axis: boolean = false;
  public show_left_yaxis: boolean = false;
  public show_right_yaxis: boolean = false;
  public left_yaxis_unit: string = '';
  public right_yaxis_unit: string = '';
  public xaxistimeunit = false;
  public stackLeft: boolean = false;
  public stackRight: boolean = false;

  public xaxisInfo: { type: string, labels: string[] } = { type: 'linear', labels: [] };
}

// Fix locale support for annotation

@Component({
  selector: 'xproj-chart-widget',
  templateUrl: './xproj-chart-widget.component.html',
  styleUrls: ['./xproj-chart-widget.component.scss']
})
export class XprojChartWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @Output() onLoadingStateChange = new EventEmitter<ClrLoadingState>();

  @ViewChild("graphCanvas", { read: ElementRef, static: false }) graphCanvas: ElementRef;
  @ViewChild("chartWidget", { read: ElementRef, static: false }) chartWidget: ElementRef;

  data = {
    labels: [],
    datasets:
      [{
        id: '',
        label: '',
        order: 10,
        index: 0,
        hidden: false,
        data: [
        ],
        fill: false,
        pointRadius: 3,
        lineTension: 0 /* disable splines etc */
      }]
  };

  //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
  options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
      easing: 'linear'
    },
    canvas: {
      height: 300
    },
    interaction: {
      intersect: false,
      mode: 'nearest'
    },
    scales: {
      x: {
        type: 'time',
        display: true,
        time: {
          displayFormats: {
            day: 'd MMM',
            hour: 'd MMM HH:mm',
            minute: 'HH:mm',
            second: 'HH:mm:ss',
            millisecond: 'HH:mm:ss.SSS'
          }
        },
        realtime: {
          duration: 20000,
          refresh: 0
        }
      },
      left_y: {
        type: 'linear',
        position: 'left',
        display: false,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      },
      right_y: {
        type: 'linear',
        position: 'right',
        display: false,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      }
    },
    plugins: {
      autocolors: {
        enabled: true,
        mode: 'dataset'
      },
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center'
      },
      //https://nagix.github.io/chartjs-plugin-colorschemes/
      colorschemes: {
        scheme: 'tableau.Classic20'
      },
      zoom: {
        // Container for pan options
        pan: {
          // Boolean to enable panning
          enabled: true,

          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          // A function that is called as the user is panning and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: 'xy',

          modifierKey: 'shift',

          rangeMin: {
            // Format of min pan range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max pan range depends on scale type
            x: null,
            y: null
          },

          // On category scale, factor of pan velocity
          speed: 20,

          // Minimal pan distance required before actually applying pan
          threshold: 10,

          // Function called while the user is panning
          //onPan: function ({ chart }) { console.log(`I'm panning!!!`); },
          // Function called once panning is completed
          //onPanComplete: function ({ chart }) { console.log(`I was panned!!!`); }
        },

        // Container for zoom options
        zoom: {
          drag: {
            // Boolean to enable zooming
            enabled: true,

            // 	 borderColor: 'rgba(225,225,225,0.3)'
            // 	 borderWidth: 5,
            // 	 backgroundColor: 'rgb(225,225,225)',
          },

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          // A function that is called as the user is zooming and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: 'x',

          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null
          },

          // Speed of zoom via mouse wheel
          // (percentage of zoom on a wheel event)
          speed: 0.1,

          // Minimal zoom distance required before actually applying zoom
          threshold: 2,

          // On category scale, minimal zoom level before actually applying zoom
          sensitivity: 3,

          // Function called while the user is zooming
          //onZoom: function ({ chart }) { console.log(`I'm zooming!!!`); },
          // Function called once zooming is completed
          //onZoomComplete: function ({ chart }) { console.log(`I was zoomed!!!`, chart); }
        }
      },
      //https://github.com/chartjs/chartjs-plugin-annotation
      tooltip:
      {
        //events: ['click']
      },
      annotation: {
        // Defines when the annotations are drawn.
        // This allows positioning of the annotation relative to the other
        // elements of the graph.
        //
        // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
        // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
        drawTime: 'afterDatasetsDraw', // (default)

        // Mouse events to enable on each annotation.
        // Should be an array of one or more browser-supported mouse events
        // See https://developer.mozilla.org/en-US/docs/Web/Events
        events: ['click'],

        // Double-click speed in ms used to distinguish single-clicks from
        // double-clicks whenever you need to capture both. When listening for
        // both click and dblclick, click events will be delayed by this
        // amount.
        dblClickSpeed: 350, // ms (default)

        // Array of annotation configuration objects
        // See below for detailed descriptions of the annotation options
        annotations: []
      }
    }
  }

  widgetConfig: ChartWidgetConfig;

  graphChart: any;
  loading: boolean = false;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private altDown: Boolean = false;
  private cursor: string;
  private useRelativeTimestamp: boolean = true;
  private lastQueries: DownSampleQuery[] = [];
  private highlightedTimeArea: any;
  private oldTargetGroup = [];

  playPauseButtonShape = 'pause';

  chartGroups: string[] = [];
  selectedChartGroup: string = '';

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    public xprojCommonStrings: XprojCommonStringsService,
    private colorHelper: ColorHelper,
    public cdr: ChangeDetectorRef) {
    super(logger, xprojClient, widgetService);
  }

  ngAfterViewInit(): void {
    this.initGraph();

    if (!this.config.ControlledByMaster || !this.responsive) {
      this.loadDelayed(false, this.zoom);
    }
  }

  private initGraph() {
    if (this.graphChart) {
      this.graphChart.options.scales.x.realtime = {
        duration: 20000,
        refresh: 0
      }
      this.graphChart.destroy();
    }

    if (!this.graphCanvas || !this.graphCanvas.nativeElement) {
      this.logger.log("Hmm no canvas.. Returning");
      return;
    }
    let canvas = this.graphCanvas.nativeElement as HTMLCanvasElement;
    if (!canvas)
      return;

    canvas.style.touchAction="none";

    this.options.plugins.colorschemes.scheme = this.widgetConfig.ColorScheme?.length > 0 ? this.widgetConfig.ColorScheme : this.globalWidgetSettings.ColorScheme;

    this.options.plugins.legend.display = this.widgetConfig.LegendShow;
    this.options.plugins.legend.position = this.getLegendPostion();
    this.options.plugins.legend.align = this.getLegendAlignment();

    this.graphChart = new Chart(canvas,
      {
        type: 'line',
        data: this.data,
        options: this.options as any,
        plugins: [zoomPlugin, autocolors, annotationPlugin, chartStreaming]
      }
    )

    this.graphChart.options.plugins.zoom.zoom.drag.enabled = this.zoom && !this.widgetConfig.DisableZoom;
    this.graphChart.options.plugins.zoom.zoom.onZoom = this.onZoom.bind(this);
    this.graphChart.options.plugins.zoom.zoom.onZoomComplete = this.onZoomComplete.bind(this);
    this.graphChart.options.plugins.zoom.pan.enabled = false;
    this.graphChart.options.plugins.zoom.pan.onPanComplete = undefined;

    this.graphChart.options.interaction.intersect = this.widgetConfig.InteractionIntersect;
    this.graphChart.options.interaction.mode = this.widgetConfig.InteractionMode;
    this.graphChart.options.interaction.axis = this.widgetConfig.InteractionAxis;

    this.graphChart.options.plugins.autocolors.customize = this.customizeColor.bind(this);
    this.graphChart.options.plugins.legend.onHover = this.onLegendHover.bind(this);
    this.graphChart.options.onHover = this.onHover.bind(this);

    //responsive
    this.graphChart.options.responsive = this.responsive;
    this.graphChart.options.maintainAspectRatio = !this.responsive;

    if (this.widgetConfig.Streaming.Enabled) {
      this.graphChart.options.scales.x.type = 'realtime';
      this.graphChart.options.scales.x.realtime = {
        duration: this.widgetConfig.Streaming.Duration,    // data in the past 20000 ms will be displayed
        refresh: this.widgetConfig.Streaming.Refresh,      // onRefresh callback will be called every 1000 ms
        delay: this.widgetConfig.Streaming.Delay,          // delay of 1000 ms, so upcoming values are known before plotting a line
        pause: false,                                      // chart is not paused
        ttl: this.widgetConfig.Streaming.Ttl,              // data will be automatically deleted as it disappears off the chart
        frameRate: this.widgetConfig.Streaming.FrameRate,  // data points are drawn 30 times every second

        onRefresh: this.onRealtimeRefresh.bind(this)
      };
    }
    else {
      this.graphChart.options.scales.x.type = 'time';
      this.graphChart.options.scales.x.realtime = {
        duration: 20000,
        refresh: 0
      }
    }
  }

  async ngOnInit() {
    this.widgetConfig = this.config as ChartWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {
    this.setChartCursor(this.widgetConfig.DisableZoom ? 'auto' : 'crosshair');
    this.data.datasets = [];
  }

  ngOnDestroy(): void {
    if (this.graphChart) {
      this.graphChart.options.scales.x.realtime = {
        duration: 20000,
        refresh: 0
      }
      this.graphChart.destroy();
    }

    super.ngOnDestroy();
  }

  async onRefresh() {
    await this.loadDelayed(true, this.zoom, true);
  }

  onRealtimeRefresh(chart) {
    this.load(false, false, true, false);
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    await this.loadDelayed(true, this.zoom);
  }

  async onReset() {
    if (this.zoom) {
      this.resetZoom();
    }
    else {
      await this.loadDelayed(true, this.zoom, true);
    }
  }

  async onUpdateQuery() {
    this.initGraph();
    this.initGraph();   //???should not be needed

    this.data.datasets = [];
    this.fromZoom = null;
    this.toZoom = null;
    this.useRelativeTimestamp = true;

    await this.loadDelayed(true, this.zoom, true);
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let load: boolean = false;
    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        if (e.zoom.to == null || e.zoom.from == null) {
          await this.resetChartZoom();
        }

        load = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQueries.forEach(query => {
          query.Query.targetprojectionid = e.master.projectionId;
          query.Query.targetgroup = e.master.group;
          load = true;
        })
      }

      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          load = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          load = true;
        }
      }
    }

    if (load) {
      this.data.datasets = [];
      await this.loadDelayed(true, this.zoom);
    }

    if (e.highlight) {
      this.setHighlightedTimeArea(e.highlight.from, e.highlight.to);
      this.graphChart.update();
    }
    else {
      this.setHighlightedTimeArea();
      this.graphChart.update();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    this.data.datasets = [];
    await this.loadDelayed(true, this.zoom);
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if ((force || i >= 0) && !(this.widgetConfig.Streaming.Enabled && this.widgetConfig.Streaming.Refresh > 0)) {
      let forceanimation = false; // with fix of ds not being new [] each update this should be tried to be true
      await this.load(forceanimation, this.zoom, true, false);
    }
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  async resetZoom() {
    if (this.zoom) {
      this.fromZoom = null;
      this.toZoom = null;
      this.useRelativeTimestamp = true;

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.widgetService.linkedWidgetChanged(params);

      this.data.datasets.forEach(ds => {
        ds.data = [];
      });

      await this.loadDelayed(true, this.zoom);
      await this.resetChartZoom();
    }
  }

  async resetChartZoom() {
    let oldZoom = this.zoom;
    this.zoom = false;
    await this.graphChart.resetZoom();
    this.zoom = oldZoom;
  }

  setChartCursor(cursor?: string, save: boolean = true) {
    if (cursor && save) {
      this.cursor = cursor;
    }

    if (this.chartWidget?.nativeElement) {
      this.chartWidget.nativeElement.style.cursor = cursor ? cursor : this.cursor;
    }
  }

  onShiftDown(event) {
    //this.logger.log('keydown', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.drag.enabled = false;
      this.graphChart.options.plugins.zoom.pan.enabled = true;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = this.onPanComplete.bind(this);
      this.setChartCursor('grab');
    }

  }

  onShiftUp(event) {
    //this.logger.log('keyup', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.drag.enabled = !this.widgetConfig.DisableZoom;
      this.graphChart.options.plugins.zoom.pan.enabled = false;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = undefined;
      this.setChartCursor(this.widgetConfig.DisableZoom ? 'auto' : 'crosshair');
    }
  }

  onAltDown(event) {
    this.altDown = true;
  }

  onAltUp(event) {
    this.altDown = false;
  }

  focusOut() {
    this.altDown = false;
    this.onShiftUp(null);
  }

  onPause(event) {
    if (this.widgetConfig.Streaming.Enabled) {
      this.graphChart.options.scales.x.realtime.pause = !this.graphChart.options.scales.x.realtime.pause;
      this.playPauseButtonShape = this.graphChart.options.scales.x.realtime.pause ? 'play' : 'pause';
    }
  }

  onHover($event, chartElement) {
    let elem = this.graphChart.getElementsAtEventForMode($event, 'index', { intersect: true }, false);
    if (elem?.length > 0) {
      this.setChartCursor('pointer', false);
    }
    else {
      this.setChartCursor();
    }
  }

  customizeColor(context): { background: string, border: string } {
    return this.colorHelper.GetColor(this.options.plugins.colorschemes.scheme, context.datasetIndex, context.colors);
  }

  onLegendHover(e, legendItem, legend) {
    this.setChartCursor('pointer', false);
  }

  async onZoom(chart) {
    this.graphChart.options.animation.duration = 0;
  }

  async onZoomComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);
      this.useRelativeTimestamp = false;

      this.graphChart.options.animation.duration = 0;
      //this.graphChart.resetZoom();
      this.setHighlightedTimeArea();

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);

      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.data.datasets.forEach(ds => {
        ds.data = [];
      });
      await this.loadDelayed(false, this.zoom);

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  onPanComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.loadDelayed(false, this.zoom);

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  graphClick($event) {
    try {
      let elem = this.graphChart.getElementsAtEventForMode($event, 'nearest', { intersect: true }, false);

      if (elem[0]) {
        let index = elem[0]?.index;
        //console.log(index)
        if (index) {
          let from: Date = new Date(elem[0].element.$context?.raw.x);
          let unit = this.graphChart.scales.x._unit;
          let to: Date = new Date(elem[0].element.$context?.raw.x);
          switch (unit) {
            case 'year': {
              to.setFullYear(to.getFullYear() + 1);
              break;
            }
            case 'quarter': {
              to.setMonth(to.getMonth() + 3);
              break;
            }
            case 'month': {
              to.setMonth(to.getMonth() + 1);
              break;
            }
            case 'week': {
              to.setDate(to.getDate() + 7);
              break;
            }
            case 'day': {
              to.setDate(to.getDate() + 1);
              break;
            }
            case 'hour': {
              to.setHours(to.getHours() + 1);
              break;
            }
            case 'minute': {
              to.setMinutes(to.getMinutes() + 1);
              break;
            }
            default: {
              to.setMinutes(to.getMinutes() + 1);
              break;
            }
          }
          //console.log('to', to);

          if (from && to) {
            let params = new LinkedWidgetChangeParameters();
            params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
            params.path.push(this.config.Id);

            if (this.altDown) {
              let zoom = new ZoomParameters();
              zoom.from = from;
              zoom.to = to;
              zoom.unit = unit;
              params.zoom = zoom;
            }
            else {
              let highlight = new HiglightParameters();
              highlight.from = from;
              highlight.to = to;
              highlight.unit = unit;
              params.highlight = highlight;

              let zoom = new ZoomParameters();
              zoom.from = this.fromZoom;
              zoom.to = this.toZoom;
              zoom.unit = unit;
              params.zoom = zoom;
            }

            //console.log('elem2', elem);
            this.widgetService.linkedWidgetChanged(params);
            //this.graphChart?.update();
          }
        }
      }
    }
    catch { }
  }

  clearAnnotations() {
    if (this.graphChart?.options?.plugins?.annotation) {
      this.graphChart.options.plugins.annotation.annotations = [];
    }

  }

  setHighlightedTimeArea(from?: Date, to?: Date) {

    if (this.highlightedTimeArea) {
      this.graphChart.options.plugins.annotation = this.graphChart.options.plugins.annotation.annotations.filter(a => a.id != this.highlightedTimeArea.id);
    }

    if (from && to) {
      this.highlightedTimeArea = {
        type: 'box',
        drawTime: 'beforeDatasetsDraw',
        id: Guid.newGuid(),
        xScaleID: 'x',
        xMin: from,
        xMax: to,
        borderColor: 'lightgray',
        borderWidth: 0,
        backgroundColor: 'lightgray',
      }

      this.graphChart.options.plugins.annotation.annotations.push(this.highlightedTimeArea);
    }
  }

  clearAnnotainLines() {
    this.graphChart.options.plugins.annotation.annotations = this.graphChart.options.plugins.annotation.annotations.filter(a => a.type != 'line');
  }

  addAnnotaionLine(lineconfig: LineConfig) {

    let line = {
      type: 'line',
      drawTime: lineconfig.Infront ? 'afterDatasetsDraw' : 'beforeDatasetsDraw',
      id: Guid.newGuid(),
      scaleID: lineconfig.Horizontal ? (lineconfig.AxisId == YAxisId.LEFT ? 'left_y' : 'right_y') : 'x',
      mode: lineconfig.Horizontal ? 'horizontal' : 'vertical',
      value: WidgetUtils.FormatNumber(lineconfig.UseValueInputParameter ? this.getParameterValue(lineconfig.ValueInputParameterId, lineconfig.Value).value : lineconfig.Value, this.globalWidgetSettings.Decimals),
      borderColor: lineconfig.Color,
      borderWidth: lineconfig.Width,
      borderDash: lineconfig.LineDash,
      label: {
        content: lineconfig.Name,
        position: lineconfig.LabelPosition,
        backgroundColor: lineconfig.LabelBackgroufColor,
        rotation: 'auto',
        enabled: lineconfig.ShowLabel
      }
    }

    this.graphChart.options.plugins.annotation.annotations.push(line);
  }


  getExportQueries(): DownSampleQuery[] {
    return this.lastQueries;
  }

  getExportImage(): any {
    let url_base64jp = this.graphCanvas.nativeElement.toDataURL("image/jpg");
    return url_base64jp;
  }


  updateChartGroups() {
    this.chartGroups = [];
    if (this.widgetConfig.GroupQueriesByName) {
      this.widgetConfig.ConfigQueries.forEach(config => {
        if (!this.chartGroups.find(g => g == config.Name)) {
          this.chartGroups.push(config.Name);
        }
      });

      if (this.selectedChartGroup?.length == 0 && this.chartGroups.length > 0) {
        this.selectedChartGroup = this.chartGroups[0];
      }
    }
    else {
      this.selectedChartGroup = '';
    }
  }

  async setSelectedChartGroup(group: string) {
    this.selectedChartGroup = group;
    this.data.datasets = [];
    await this.loadDelayed(true, this.zoom);
  }

  getClass(group: string): string {
    let result = 'btn';

    if (group == this.selectedChartGroup) {
      result += ' btn-primary';
    }

    return result;
  }

  loadTimer: any;
  async loadDelayed(animation: boolean, points: boolean, forcereload: boolean = false, showLoading: boolean = true) {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(() => {
        this.loadTimer = undefined;
        this.load(animation, points, forcereload, showLoading);
      }, 200);
    }
  }

  async GetUniqueColumnValues(chartquery: any, columnname: string, forcereload: boolean = false): Promise<BaseQueryResult> {
    let query = chartquery.query;
    let newquery = new BaseQuery();
    newquery.filter = query.filter;
    newquery.targetgroup = query.targetgroup;
    newquery.targetprojectionid = query.targetprojectionid;
    newquery.filter = query.filter;
    newquery.maxitems = 1000;//query.maxItems;
    newquery.numericalfilters = query.numericalfilters;
    newquery.relativetimestampfilters = query.relativetimestampfilters;
    newquery.stringfilters = query.stringfilters;
    newquery.timestampfilters = query.timestampfilters;
    newquery.subfiltergroups = query.subfiltergroups;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columnname;
    col.columnaggregation = Aggregation.NONE;
    col.columnoutname = col.columnoutname;
    col.columntransformation = Transformation.NONE;
    //newquery.columns.push(col);

    newquery.grouping = new ColumnGroupingDescription();
    newquery.grouping.columnname = col.columnname;
    newquery.grouping.columnoutname = col.columnoutname;
    newquery.grouping.columntransformation = Transformation.NONE;
    newquery.grouping.parameter = 0;

    return await this.xprojClient.RequestQueryBaseQuery(newquery, forcereload);
  }

  async loadQuery(rval: AxisSettings, configQuery: ChartWidgetQuery, points: boolean, forcereload: boolean, ds: any[], explicitGroupStr: ColumnFilteringString, explicitGroupNum: ColumnFilteringNumerical) {        
    let sampledSeries = configQuery.sampledSeries;
    let showPoints: boolean = points;
    let dsQuery: DownSampleQuery;
    let querydata: BaseQueryResult = null;

    rval.stackLeft = configQuery.StackLeft;
    rval.stackRight = configQuery.StackRight;
    let extra_id = "";

    let splitLabel = "";
    if (explicitGroupStr) {
      splitLabel = " (" + explicitGroupStr.value + ")";
      extra_id += "-" + explicitGroupStr.value;
    }
    if (explicitGroupNum) {
      splitLabel = " (" + explicitGroupNum.value.toString() + ")";
      extra_id += "-" + explicitGroupNum.value.toString();
    }

    try {
      let queryInfo: { query: DownSampleQuery, resetSampleSeries: boolean };
      querydata = await this.requestBaseQueryResult(() => { queryInfo = this.getQueryWithFilter(configQuery, explicitGroupStr, explicitGroupNum); return queryInfo.query; },
        (query) => {
          dsQuery = query;
          if (dsQuery.query.grouping.columnname.length == 0) {
            if (queryInfo.resetSampleSeries) {
              this.logger.log("reseting sampled data");
              configQuery.sampledSeries = {};
              sampledSeries = configQuery.sampledSeries;
            }
            return this.xprojClient.RequestQueryDownSampleQuery(dsQuery, forcereload || queryInfo.resetSampleSeries, 'chartwidget', this.config.Name);
          }
          else {
            if (queryInfo.resetSampleSeries) {
              this.logger.log("reseting sampled data");
              configQuery.sampledSeries = {};
              sampledSeries = configQuery.sampledSeries;
            }
            return this.xprojClient.RequestQueryBaseQuery(dsQuery.query, forcereload || queryInfo.resetSampleSeries, 'chartwidget', this.config.Name);
          }
        }, configQuery, configQuery.DataFilters, forcereload, 'chartwidget', this.config.Name);

      if (dsQuery.query.grouping.columnname.length == 0) {
        showPoints = showPoints && querydata.nrpoints < configQuery.Query.maxitems;
      }
    }
    catch (err) {
      this.logger.debug('Chartwidget error: ' + err);
      querydata = new BaseQueryResult();
      querydata.columns = [];
    }

    this.lastQueries.push(dsQuery);

    let data_x = [];
    let data_y = [];
    let sampling = [];

    let key = configQuery.Id;

    if (sampledSeries[key]) {
      sampling = sampledSeries[key];
    }
    //console.log(querydata);

    for (let columnOut of querydata.columns) {
      if (columnOut.columnoutname == configQuery.Xaxis) {
        if (columnOut.datatype == XDataType.Number) {
          data_x = querydata.datanumbers[columnOut.indexintypedvector];
          rval.date_x_axis = false;
          rval.xaxisInfo = ChartUtils.GetXAxisTypeAndLabels(configQuery, data_x);
        }
        if (columnOut.datatype == XDataType.Timestamp) {
          data_x = querydata.datatimestamps[columnOut.indexintypedvector];
          rval.date_x_axis = true;
          if (!configQuery.timestampColumnName || configQuery.timestampColumnName.length == 0) {
            configQuery.timestampColumnName = columnOut.columnname;
          }
        }
        continue;
      }

      let label = columnOut.columnoutname;
      let yaxisid: string;
      let hideifempty = false;

      let yAxisConfig = configQuery.YAxesConfigs?.find(yaxisconfig => yaxisconfig.ColumnOutName == columnOut.columnoutname);
      if (!yAxisConfig) {
        yAxisConfig = configQuery.YAxesConfigs?.find(yaxisconfig => yaxisconfig.ColumnName == columnOut.columnoutname);
      }
      if (yAxisConfig) {
        hideifempty = yAxisConfig.HideIfEmpty ?? false;
        label = yAxisConfig.Label;
        let unit = yAxisConfig.Unit;
        if (yAxisConfig.UseUnitInputParameter) {
          unit = this.getParameterValue(yAxisConfig.UnitInputParameterId, unit).value;
        }


        switch (yAxisConfig.AxisId) {
          case YAxisId.LEFT:
            yaxisid = 'left_y';
            rval.show_left_yaxis = true;
            if (rval.left_yaxis_unit.length == 0) {
              rval.left_yaxis_unit = unit;
            }
            break;
          case YAxisId.RIGHT:
            yaxisid = 'right_y';
            rval.show_right_yaxis = true;
            if (rval.right_yaxis_unit.length == 0) {
              rval.right_yaxis_unit = unit;
            }
            break;

        }

        if (!hideifempty || querydata.datanumbers[columnOut.indexintypedvector].length > 0) {
          data_y.push({
            id: configQuery.Id + columnOut.columnoutname + extra_id,
            data: this.globalWidgetSettings.Decimals >= 0 ? WidgetUtils.FormatNumbers(querydata.datanumbers[columnOut.indexintypedvector], this.globalWidgetSettings.Decimals) : querydata.datanumbers[columnOut.indexintypedvector],
            label: yAxisConfig?.Label + splitLabel + (yAxisConfig.AxisId == YAxisId.RIGHT ? ' (' + this.xprojCommonStrings.keys.rightY + ')' : ''),
            yaxisid: yaxisid,
            showPoints: showPoints,
            type: yAxisConfig?.Type,
            fill: yAxisConfig?.Fill,
            borderColor: yAxisConfig?.BorderColor,
            backgroundColor: yAxisConfig?.BackgroundColor,
            pointBorderColor: yAxisConfig?.PointBorderColor,
            pointBackgroundColor: yAxisConfig?.PointBackgroundColor,
            useColorSchema: yAxisConfig?.UseColorSchema,
            borderWidth: yAxisConfig?.BorderWidth,
            borderRadius: yAxisConfig?.BorderRadius,
            borderDash: yAxisConfig?.BorderDash,
            pointRadius: yAxisConfig?.PointRadius,
            tension: yAxisConfig?.Tension,
            cubicInterpolationMode: yAxisConfig?.Tension != 0 ? 'monotone' : '',
            stepped: yAxisConfig?.Stepped ? yAxisConfig?.SteppedMode : false
          });
        }
      }
    }

    for (let d of data_y) {
      let seriesdata = [];
      let nrDiscarded = 0;
      if (sampling.length > 0) {
        for (let i = 0; i < sampling.length; i++) {
          if (i > 0) {
            if (sampling[i]["y"] == seriesdata[i - 1]["y"]) {
              if (rval.date_x_axis) {
                if (sampling[i]["x"].getTime() == seriesdata[i - 1]["x"].getTime()) {
                  nrDiscarded++;
                  continue;
                }
              }
              else {
                if (sampling[i]["x"] == seriesdata[i - 1]["x"]) {
                  nrDiscarded++;
                  continue;
                }
              }
            }
          }
          seriesdata.push(sampling[i]);
        }
      }
      // console.log("nrDiscarded in sampling: " + nrDiscarded.toString());
      for (let i = 0; i < d.data.length; i++) {
        seriesdata.push({ x: data_x[i], y: d.data[i] });
      }
      if (sampling.length > 0 && seriesdata.length > configQuery.SampleLength) {
        seriesdata.splice(0, seriesdata.length - configQuery.SampleLength);
      }

      if (configQuery.SampleData) {
        sampledSeries[configQuery.Id] = seriesdata;
      }


      if (rval.xaxistimeunit == false && d.type == 'bar') {
        rval.xaxistimeunit = ChartUtils.GetBarsXAxisUnit(configQuery.XaxisTransform);
      }
      let order = 10;
      if (d.type && d.type == 'line')
        order = 0;
      else if (d.type && d.type == 'scatter')
        order = 1;

      let type = d.type;
      if (type == undefined)
        type = 'line';

      let pointradius = d.showPoints ? d.pointRadius : 0;
      if (type == 'scatter') {
        pointradius = d.pointRadius;
      }

      let excistingDs = ds.find(x => x.id == d.id);

      if (excistingDs) {
        if (this.widgetConfig.Streaming.Enabled && rval.date_x_axis) {
          let last = excistingDs.data.at(-1);
          if (last) {
            excistingDs.data.push(...seriesdata.filter(d => d.x > last.x));
            // if (this.widgetConfig.Streaming.AddOnlyNewValues) {
            //   excistingDs.data.push(...seriesdata.filter(d => d.x > last.x));
            // }
            // else {
            //   excistingDs.data.push(...seriesdata);
            // }
          }
          else {
            excistingDs.data = seriesdata;
          }
        }
        else {
          excistingDs.data = seriesdata;
        }
        excistingDs.pointRadius = pointradius;
      }
      else {
        ds.push(
          {
            id: d.id,
            label: d.label,
            hidden: false,
            data: seriesdata,
            order: order,
            index: ds.length,
            fill: d.fill,
            pointRadius: pointradius,
            tension: d.tension,
            yAxisID: d.yaxisid,
            type: type,
            borderDash: d.borderDash,
            borderColor: !d.useColorSchema ? d.borderColor : undefined,
            backgroundColor: !d.useColorSchema ? d.backgroundColor : undefined,
            pointBorderColor: !d.useColorSchema ? d.pointBorderColor : undefined,
            pointBackgroundColor: !d.useColorSchema ? d.pointBackgroundColor : undefined,
            borderWidth: d.borderWidth > 0 ? d.borderWidth : 1,
            borderRadius: d.borderRadius,
            cubicInterpolationMode: d.tension != 0 ? 'monotone' : '',
            stepped: d.stepped
          }
        );
      }
    }
  }

  async load(animation: boolean, points: boolean, forcereload: boolean = false, showLoading: boolean = true) {
    if (this.loading) {
      return;
    }
    this.cdr.detach();
    //Clear annotations
    this.clearAnnotations();

    if (!this.widgetheight) {
      this.setWidgetHeight(this.config.Height);
    }

    // this ds should not be a new object each frame,
    // maybe make a map of each series data with an id and make sure we synch that into ds?
    // if we continue to replace it new-values-from-right will be the same as full reload
    // and the animations are jumpy on Y instead of smooth on X
    //let ds: any[] = [];
    this.lastQueries = [];

    //let ds = this.data.datasets;

    try {
      if (!this.inputParametersHasValue(true)) {
        this.logger.debug('inputParameters misses value');
      }
      else {
        this.loading = true;
        this.onLoadingStateChange?.emit(ClrLoadingState.LOADING);

        this.updateChartGroups();

        this.options.plugins.legend.display = this.widgetConfig.LegendShow;
        this.options.plugins.legend.position = this.getLegendPostion();
        this.options.plugins.legend.align = this.getLegendAlignment();

        let settings = new AxisSettings();

        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries.filter(c => !this.widgetConfig.GroupQueriesByName || c.Name == this.selectedChartGroup)
          , async (configQuery: ChartWidgetQuery) => {
            try {
              if (configQuery.SplitBy) {
                let query = this.getQuery(configQuery).query;
                let splittedBy = await this.GetUniqueColumnValues(query, configQuery.SplitBy, forcereload);

                if (splittedBy.nrpoints != 0) {

                  let newMaxItems = Math.round(query.query.maxitems / splittedBy.nrpoints);
                  if (newMaxItems < 100)
                    newMaxItems = 100;

                  configQuery.Query.maxitems = newMaxItems;

                  let filterNum: ColumnFilteringNumerical = null;
                  let filterStr: ColumnFilteringString = null;

                  if (splittedBy.columns[0].datatype == XDataType.Number) {
                    filterNum = new ColumnFilteringNumerical();
                    filterNum.columnname = configQuery.SplitBy;
                    filterNum.comparator = FilterComparator.Equals;
                    filterNum.queryfilterid = 4389; // a good number i think ? :o

                    for (let i = 0; i < splittedBy.datanumbers[0].length; i++) {
                      filterNum.value = splittedBy.datanumbers[0][i];
                      await this.loadQuery(settings, configQuery, points, forcereload, this.data.datasets, null, filterNum);
                    }
                  }
                  else if (splittedBy.columns[0].datatype == XDataType.String) {
                    filterStr = new ColumnFilteringString();
                    filterStr.columnname = configQuery.SplitBy;
                    filterStr.comparator = FilterComparator.Equals;
                    filterStr.queryfilterid = 4389; // a good number i think ? :o

                    for (let i = 0; i < splittedBy.datastrings[0].length; i++) {
                      filterStr.value = splittedBy.datastrings[0][i];
                      await this.loadQuery(settings, configQuery, points, forcereload, this.data.datasets, filterStr, null);
                    }
                  }
                }
              }
              else {
                await this.loadQuery(settings, configQuery, points, forcereload, this.data.datasets, null, null);
              }
            }
            catch (error) {
              this.logger.log(error);
            }
          });

        if (settings.date_x_axis) {
          this.graphChart.options.scales.x.type = this.widgetConfig.Streaming.Enabled ? 'realtime' : 'time';
          this.graphChart.options.scales.x.time.unit = settings.xaxistimeunit;
          this.graphChart.options.scales.x.offset = false;
          this.graphChart.options.scales.x.labels = undefined;
        }
        else {
          this.graphChart.options.scales.x.type = settings.xaxisInfo.type;
          if (settings.xaxisInfo.type == 'category') {
            if (settings.xaxisInfo.labels?.length > 0) {
              this.graphChart.options.scales.x.labels = settings.xaxisInfo.labels;
            }
            else {
              this.graphChart.options.scales.x.labels = this.data.datasets[0].data.map(i => i.x);
            }
            this.graphChart.options.scales.x.offset = true;
          }
        }
        //this.graphChart.options

        this.graphChart.options.scales.left_y.type = this.widgetConfig.LeftYAxisType;
        this.graphChart.options.scales.left_y.display = settings.show_left_yaxis;
        this.graphChart.options.scales.x.stacked = settings.stackLeft || settings.stackRight;
        this.graphChart.options.scales.left_y.stacked = settings.stackLeft;

        this.graphChart.options.scales.left_y.scaleLabel.display = settings.left_yaxis_unit.length > 0;
        this.graphChart.options.scales.left_y.scaleLabel.labelString = settings.left_yaxis_unit;

        if (this.widgetConfig.MinMaxSettings.UseMinMaxLeft) {
          if (this.widgetConfig.MinMaxSettings.SuggestedMinMaxLeft) {
            this.graphChart.options.scales.left_y.suggestedMin = this.widgetConfig.MinMaxSettings.MinLeft;
            this.graphChart.options.scales.left_y.suggestedMax = this.widgetConfig.MinMaxSettings.MaxLeft;
          }
          else {
            this.graphChart.options.scales.left_y.min = this.widgetConfig.MinMaxSettings.MinLeft;
            this.graphChart.options.scales.left_y.max = this.widgetConfig.MinMaxSettings.MaxLeft;
          }
        }

        if (this.widgetConfig.MinMaxSettings.UseMinMaxRight) {
          if (this.widgetConfig.MinMaxSettings.SuggestedMinMaxRight) {
            this.graphChart.options.scales.right_y.suggestedMin = this.widgetConfig.MinMaxSettings.MinRight;
            this.graphChart.options.scales.right_y.suggestedMax = this.widgetConfig.MinMaxSettings.MaxRight;
          }
          else {
            this.graphChart.options.scales.right_y.min = this.widgetConfig.MinMaxSettings.MinRight;
            this.graphChart.options.scales.right_y.max = this.widgetConfig.MinMaxSettings.MaxRight;
          }
        }

        this.graphChart.options.scales.right_y.display = settings.show_right_yaxis;
        this.graphChart.options.scales.right_y.type = this.widgetConfig.RightYAxisType;
        this.graphChart.options.scales.right_y.stacked = settings.stackRight;
        this.graphChart.options.scales.right_y.scaleLabel.display = settings.right_yaxis_unit.length > 0;
        this.graphChart.options.scales.right_y.scaleLabel.labelString = settings.right_yaxis_unit;

        this.graphChart.options.animation.duration = animation ? this.widgetConfig.AnimationsMs : 0;
        this.graphChart.options.animation.easing = this.widgetConfig.AnimationsEasing;

        this.graphChart.options.type = '';

        this.clearAnnotainLines();
        this.widgetConfig.LineConfigs.forEach(line => {
          this.addAnnotaionLine(line);
        });

        if (this.widgetConfig.Streaming.Enabled) {
          this.graphChart.update('quite');
        }
        else {
          this.graphChart.update();
        }

        //console.log('chart updated', this.widgetheight);
        this.onLoadingStateChange?.emit(ClrLoadingState.SUCCESS);
      }
    }
    catch (error) {
      this.logger.error(error);
      this.onLoadingStateChange?.emit(ClrLoadingState.ERROR);
    }
    finally {
      this.loading = false;
      this.cdr.reattach();
      this.graphChart.options.animation.duration = this.widgetConfig.AnimationsMs;
    }
  }

  getQuery(configQuery: ChartWidgetQuery): { query: DownSampleQuery, resetSampleSeries: boolean } {
    let resetSampleSeries: boolean = false;
    let dsQuery = new DownSampleQuery();

    let queryZoom = WidgetUtils.GetQueryZoomDates(this.fromZoom, this.toZoom, configQuery.XaxisTransform);

    dsQuery.query = configQuery.Query.Clone();

    dsQuery.query.columns = dsQuery.query.columns.filter(col => col.columnname.indexOf(':') < 0);

    // Aggregation input parameters
    let i = 0;
    dsQuery.query.columns.forEach(col => {
      if (i > 0) {
        let yAxisConfig = configQuery.YAxesConfigs[i - 1];
        if (yAxisConfig.UseAggregationInputParameter) {
          col.columnaggregation = this.getParameterValue(yAxisConfig.AggregationInputParameterId, col.columnaggregation).value;
        }
      }
      i++;
    });

    //Transformation input parameters
    if (configQuery.UseGrouping && configQuery.UseTransformInputParameter) {
      dsQuery.query.grouping.columntransformation = this.getParameterValue(configQuery.TransformInputParameterId, configQuery.XaxisTransform).value;
    }

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      let projectionId = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
      resetSampleSeries = resetSampleSeries || dsQuery.query.targetprojectionid != projectionId;
      dsQuery.query.targetprojectionid = projectionId;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      let targetGroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
      resetSampleSeries = resetSampleSeries || dsQuery.query.targetgroup != targetGroup;
      dsQuery.query.targetgroup = targetGroup;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      let targetGroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        targetGroup.push(this.getParameterValue(id, '').value + '');
      });

      if (targetGroup.length != this.oldTargetGroup.length) {
        resetSampleSeries = true;
      }
      else {
        for (let i = 0; i < targetGroup.length; i++) {
          if (targetGroup[i] != this.oldTargetGroup[i]) {
            resetSampleSeries = true;
            break;
          }
        }
      }

      this.oldTargetGroup = targetGroup;
      dsQuery.query.targetgroup = targetGroup;
    }

    WidgetUtils.SetQueryFilterValues(dsQuery.query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(dsQuery.query,
      queryZoom.from ?? this.from,
      queryZoom.to ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    return { query: dsQuery, resetSampleSeries: resetSampleSeries };
  }

  getQueryWithFilter(configQuery: ChartWidgetQuery, filterStr: ColumnFilteringString, filterNum: ColumnFilteringNumerical): { query: DownSampleQuery, resetSampleSeries: boolean } {
    let q = this.getQuery(configQuery);

    if (filterStr) {
      q.query.query.stringfilters.push(filterStr);
      q.query.query.filter.filters.push(filterStr.queryfilterid);
    }
    if (filterNum) {
      q.query.query.numericalfilters.push(filterNum);
      q.query.query.filter.filters.push(filterNum.queryfilterid);
    }

    return q;
  }

}
