/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
/**
 * Message implementation for customerusers.SetPasswordRequest
 */
export class SetPasswordRequest implements GrpcMessage {
  static id = 'customerusers.SetPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetPasswordRequest();
    SetPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetPasswordRequest) {
    _instance.oldPassword = _instance.oldPassword || '';
    _instance.newPassword = _instance.newPassword || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.oldPassword = _reader.readString();
          break;
        case 2:
          _instance.newPassword = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.oldPassword) {
      _writer.writeString(1, _instance.oldPassword);
    }
    if (_instance.newPassword) {
      _writer.writeString(2, _instance.newPassword);
    }
  }

  private _oldPassword?: string;
  private _newPassword?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetPasswordRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetPasswordRequest.AsObject>) {
    _value = _value || {};
    this.oldPassword = _value.oldPassword;
    this.newPassword = _value.newPassword;
    SetPasswordRequest.refineValues(this);
  }
  get oldPassword(): string | undefined {
    return this._oldPassword;
  }
  set oldPassword(value: string | undefined) {
    this._oldPassword = value;
  }
  get newPassword(): string | undefined {
    return this._newPassword;
  }
  set newPassword(value: string | undefined) {
    this._newPassword = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetPasswordRequest.AsObject {
    return {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetPasswordRequest.AsProtobufJSON {
    return {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };
  }
}
export module SetPasswordRequest {
  /**
   * Standard JavaScript object representation for SetPasswordRequest
   */
  export interface AsObject {
    oldPassword?: string;
    newPassword?: string;
  }

  /**
   * Protobuf JSON representation for SetPasswordRequest
   */
  export interface AsProtobufJSON {
    oldPassword?: string;
    newPassword?: string;
  }
}

/**
 * Message implementation for customerusers.CreateExcelFileRequest
 */
export class CreateExcelFileRequest implements GrpcMessage {
  static id = 'customerusers.CreateExcelFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateExcelFileRequest();
    CreateExcelFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateExcelFileRequest) {
    _instance.query = _instance.query || new Uint8Array();
    _instance.queryType = _instance.queryType || 0;
    _instance.chunkSize = _instance.chunkSize || 0;
    _instance.decimals = _instance.decimals || 0;
    _instance.excludeColumns = _instance.excludeColumns || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateExcelFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.query = _reader.readBytes();
          break;
        case 2:
          _instance.queryType = _reader.readEnum();
          break;
        case 3:
          _instance.chunkSize = _reader.readInt32();
          break;
        case 4:
          _instance.decimals = _reader.readInt32();
          break;
        case 5:
          (_instance.excludeColumns = _instance.excludeColumns || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateExcelFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateExcelFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.query && _instance.query.length) {
      _writer.writeBytes(1, _instance.query);
    }
    if (_instance.queryType) {
      _writer.writeEnum(2, _instance.queryType);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(3, _instance.chunkSize);
    }
    if (_instance.decimals) {
      _writer.writeInt32(4, _instance.decimals);
    }
    if (_instance.excludeColumns && _instance.excludeColumns.length) {
      _writer.writeRepeatedString(5, _instance.excludeColumns);
    }
  }

  private _query?: Uint8Array;
  private _queryType?: CreateExcelFileRequest.QueryTypes;
  private _chunkSize?: number;
  private _decimals?: number;
  private _excludeColumns?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateExcelFileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateExcelFileRequest.AsObject>) {
    _value = _value || {};
    this.query = _value.query;
    this.queryType = _value.queryType;
    this.chunkSize = _value.chunkSize;
    this.decimals = _value.decimals;
    this.excludeColumns = (_value.excludeColumns || []).slice();
    CreateExcelFileRequest.refineValues(this);
  }
  get query(): Uint8Array | undefined {
    return this._query;
  }
  set query(value: Uint8Array | undefined) {
    this._query = value;
  }
  get queryType(): CreateExcelFileRequest.QueryTypes | undefined {
    return this._queryType;
  }
  set queryType(value: CreateExcelFileRequest.QueryTypes | undefined) {
    this._queryType = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }
  get decimals(): number | undefined {
    return this._decimals;
  }
  set decimals(value: number | undefined) {
    this._decimals = value;
  }
  get excludeColumns(): string[] | undefined {
    return this._excludeColumns;
  }
  set excludeColumns(value: string[] | undefined) {
    this._excludeColumns = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateExcelFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateExcelFileRequest.AsObject {
    return {
      query: this.query ? this.query.subarray(0) : new Uint8Array(),
      queryType: this.queryType,
      chunkSize: this.chunkSize,
      decimals: this.decimals,
      excludeColumns: (this.excludeColumns || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateExcelFileRequest.AsProtobufJSON {
    return {
      query: this.query ? uint8ArrayToBase64(this.query) : '',
      queryType:
        CreateExcelFileRequest.QueryTypes[
          this.queryType === null || this.queryType === undefined
            ? 0
            : this.queryType
        ],
      chunkSize: this.chunkSize,
      decimals: this.decimals,
      excludeColumns: (this.excludeColumns || []).slice()
    };
  }
}
export module CreateExcelFileRequest {
  /**
   * Standard JavaScript object representation for CreateExcelFileRequest
   */
  export interface AsObject {
    query?: Uint8Array;
    queryType?: CreateExcelFileRequest.QueryTypes;
    chunkSize?: number;
    decimals?: number;
    excludeColumns?: string[];
  }

  /**
   * Protobuf JSON representation for CreateExcelFileRequest
   */
  export interface AsProtobufJSON {
    query?: string;
    queryType?: string;
    chunkSize?: number;
    decimals?: number;
    excludeColumns?: string[];
  }
  export enum QueryTypes {
    BASEQUERY = 0,
    DOWNSAMPLEQUERY = 1,
    DFTQUERY = 2,
    MULTISERIESQUERY = 3
  }
}

/**
 * Message implementation for customerusers.GetCustomerContentResponse
 */
export class GetCustomerContentResponse implements GrpcMessage {
  static id = 'customerusers.GetCustomerContentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerContentResponse();
    GetCustomerContentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerContentResponse) {
    _instance.dashboardInfos = _instance.dashboardInfos || [];
    _instance.customeContentData = _instance.customeContentData || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerContentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcDashboardInfo();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcDashboardInfo
              .deserializeBinaryFromReader
          );
          (_instance.dashboardInfos = _instance.dashboardInfos || []).push(
            messageInitializer1
          );
          break;
        case 2:
          const messageInitializer2 = new xprojectorGrpcModels002.GrpcCustomerContentData();
          _reader.readMessage(
            messageInitializer2,
            xprojectorGrpcModels002.GrpcCustomerContentData
              .deserializeBinaryFromReader
          );
          (_instance.customeContentData =
            _instance.customeContentData || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerContentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerContentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.dashboardInfos && _instance.dashboardInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dashboardInfos as any,
        xprojectorGrpcModels002.GrpcDashboardInfo.serializeBinaryToWriter
      );
    }
    if (_instance.customeContentData && _instance.customeContentData.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.customeContentData as any,
        xprojectorGrpcModels002.GrpcCustomerContentData.serializeBinaryToWriter
      );
    }
  }

  private _dashboardInfos?: xprojectorGrpcModels002.GrpcDashboardInfo[];
  private _customeContentData?: xprojectorGrpcModels002.GrpcCustomerContentData[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerContentResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerContentResponse.AsObject>) {
    _value = _value || {};
    this.dashboardInfos = (_value.dashboardInfos || []).map(
      m => new xprojectorGrpcModels002.GrpcDashboardInfo(m)
    );
    this.customeContentData = (_value.customeContentData || []).map(
      m => new xprojectorGrpcModels002.GrpcCustomerContentData(m)
    );
    GetCustomerContentResponse.refineValues(this);
  }
  get dashboardInfos():
    | xprojectorGrpcModels002.GrpcDashboardInfo[]
    | undefined {
    return this._dashboardInfos;
  }
  set dashboardInfos(
    value: xprojectorGrpcModels002.GrpcDashboardInfo[] | undefined
  ) {
    this._dashboardInfos = value;
  }
  get customeContentData():
    | xprojectorGrpcModels002.GrpcCustomerContentData[]
    | undefined {
    return this._customeContentData;
  }
  set customeContentData(
    value: xprojectorGrpcModels002.GrpcCustomerContentData[] | undefined
  ) {
    this._customeContentData = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerContentResponse.AsObject {
    return {
      dashboardInfos: (this.dashboardInfos || []).map(m => m.toObject()),
      customeContentData: (this.customeContentData || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerContentResponse.AsProtobufJSON {
    return {
      dashboardInfos: (this.dashboardInfos || []).map(m =>
        m.toProtobufJSON(options)
      ),
      customeContentData: (this.customeContentData || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetCustomerContentResponse {
  /**
   * Standard JavaScript object representation for GetCustomerContentResponse
   */
  export interface AsObject {
    dashboardInfos?: xprojectorGrpcModels002.GrpcDashboardInfo.AsObject[];
    customeContentData?: xprojectorGrpcModels002.GrpcCustomerContentData.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetCustomerContentResponse
   */
  export interface AsProtobufJSON {
    dashboardInfos?:
      | xprojectorGrpcModels002.GrpcDashboardInfo.AsProtobufJSON[]
      | null;
    customeContentData?:
      | xprojectorGrpcModels002.GrpcCustomerContentData.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for customerusers.GetActiveCustomerUserDevicesResponse
 */
export class GetActiveCustomerUserDevicesResponse implements GrpcMessage {
  static id = 'customerusers.GetActiveCustomerUserDevicesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetActiveCustomerUserDevicesResponse();
    GetActiveCustomerUserDevicesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetActiveCustomerUserDevicesResponse) {
    _instance.userDevices = _instance.userDevices || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetActiveCustomerUserDevicesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.UserDevice();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.UserDevice.deserializeBinaryFromReader
          );
          (_instance.userDevices = _instance.userDevices || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetActiveCustomerUserDevicesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetActiveCustomerUserDevicesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.userDevices && _instance.userDevices.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.userDevices as any,
        xprojectorGrpcModels002.UserDevice.serializeBinaryToWriter
      );
    }
  }

  private _userDevices?: xprojectorGrpcModels002.UserDevice[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetActiveCustomerUserDevicesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetActiveCustomerUserDevicesResponse.AsObject>
  ) {
    _value = _value || {};
    this.userDevices = (_value.userDevices || []).map(
      m => new xprojectorGrpcModels002.UserDevice(m)
    );
    GetActiveCustomerUserDevicesResponse.refineValues(this);
  }
  get userDevices(): xprojectorGrpcModels002.UserDevice[] | undefined {
    return this._userDevices;
  }
  set userDevices(value: xprojectorGrpcModels002.UserDevice[] | undefined) {
    this._userDevices = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetActiveCustomerUserDevicesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetActiveCustomerUserDevicesResponse.AsObject {
    return {
      userDevices: (this.userDevices || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetActiveCustomerUserDevicesResponse.AsProtobufJSON {
    return {
      userDevices: (this.userDevices || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetActiveCustomerUserDevicesResponse {
  /**
   * Standard JavaScript object representation for GetActiveCustomerUserDevicesResponse
   */
  export interface AsObject {
    userDevices?: xprojectorGrpcModels002.UserDevice.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetActiveCustomerUserDevicesResponse
   */
  export interface AsProtobufJSON {
    userDevices?: xprojectorGrpcModels002.UserDevice.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for customerusers.AddCustomerUserDeviceRequest
 */
export class AddCustomerUserDeviceRequest implements GrpcMessage {
  static id = 'customerusers.AddCustomerUserDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomerUserDeviceRequest();
    AddCustomerUserDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomerUserDeviceRequest) {
    _instance.name = _instance.name || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomerUserDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomerUserDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomerUserDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
  }

  private _name?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomerUserDeviceRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddCustomerUserDeviceRequest.AsObject>
  ) {
    _value = _value || {};
    this.name = _value.name;
    AddCustomerUserDeviceRequest.refineValues(this);
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomerUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomerUserDeviceRequest.AsObject {
    return {
      name: this.name
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomerUserDeviceRequest.AsProtobufJSON {
    return {
      name: this.name
    };
  }
}
export module AddCustomerUserDeviceRequest {
  /**
   * Standard JavaScript object representation for AddCustomerUserDeviceRequest
   */
  export interface AsObject {
    name?: string;
  }

  /**
   * Protobuf JSON representation for AddCustomerUserDeviceRequest
   */
  export interface AsProtobufJSON {
    name?: string;
  }
}

/**
 * Message implementation for customerusers.DeleteCustomerUserDeviceRequest
 */
export class DeleteCustomerUserDeviceRequest implements GrpcMessage {
  static id = 'customerusers.DeleteCustomerUserDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteCustomerUserDeviceRequest();
    DeleteCustomerUserDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteCustomerUserDeviceRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteCustomerUserDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteCustomerUserDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteCustomerUserDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteCustomerUserDeviceRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteCustomerUserDeviceRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    DeleteCustomerUserDeviceRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteCustomerUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteCustomerUserDeviceRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteCustomerUserDeviceRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteCustomerUserDeviceRequest {
  /**
   * Standard JavaScript object representation for DeleteCustomerUserDeviceRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteCustomerUserDeviceRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}
