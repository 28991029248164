<clr-modal [(clrModalOpen)]="showXEdgeSelectMetersModal" [clrModalSize]="'xl'" *ngIf="showXEdgeSelectMetersModal" [clrModalClosable]="false">
  <h3 class="modal-title">Add XEdge meters</h3>
  <div class="modal-body xedge-select-meter-modal">
    <app-xedge-select-xauto-vars #selectXEdgeMeters [remoteNodeId]="xedgeRemoteNodeId"></app-xedge-select-xauto-vars>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="showXEdgeSelectMetersModal=false;">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="selectXEdgeMeters?.selectedXAutoVars?.length < 1" (click)="addSelectedXEdgeMeters()">Add selected meters ({{selectXEdgeMeters?.selectedXAutoVars?.length}})</button>
  </div>
</clr-modal>

<div class="clr-row">
  <div class="clr-col-8">
    <h3 *ngIf="selectedBmsCustomer" class="sensitive">{{selectedBmsCustomer.customerName}}
    </h3>
  </div>
  <div class="clr-col-4">

  </div>
</div>

<clr-tabs #tabs>
  <clr-tab>
    <button clrTabLink>Data collectors</button>
    <ng-template [(clrIfActive)]="dataColelctorsActive">
      <clr-tab-content>
        <div class="customers-content-area">
          <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd()" class="main-area"
            [gutterSize]="7">
            <as-split-area [order]="1">
              <app-configuration-datasource #dataCollectorsConfigurationDatasource [sensitiveNodes]="[]"
                *ngIf="dataCollectorsDataSourceInstance" [lazy]="false" [dropDownItems]="dropDownItems"
                [dataSourceInstance]="dataCollectorsDataSourceInstance" [expandRoot]="true"
                [customerId]="selectedBmsCustomer.customerId" [sortByName]="true" [sortPaddingZeros]="10"
                [highlightSelected]="true" (onTreeNodeSelect)="onTreeNodeSelect($event)" [moveEnabled]="true"
                [moveEnabledNodeTypeIds]="[]" [singletonReferences]="false" [dropDownItems]="dropDownItemsDataCollectors">
              </app-configuration-datasource>
            </as-split-area>
            <as-split-area [(size)]="rightPanelWidth" [order]="2">
              <app-rossaker-bms-data-collector-view #rossakerBmsDataCollectorView>
              </app-rossaker-bms-data-collector-view>
            </as-split-area>
          </as-split>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
