import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { XprojGroupSelectionComponent } from '../../../../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../../../../utils/array-utils-service';
import { ColorProperty } from '../../../../../../widgets/label/label-widget-config/label-widget-config-service';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter } from '../../../../../../widgets/widget-config-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { ComboboxColumnConfig, ComboboxControllerQuery } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-combobox-controller-query-config',
  templateUrl: './xproj-output-combobox-controller-query-config.component.html',
  styleUrls: ['./xproj-output-combobox-controller-query-config.component.scss']
})
export class XprojOutputComboboxControllerQueryConfigComponent implements OnInit, AfterViewInit {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;


  @Input() widgetQuery: ComboboxControllerQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgets: WidgetConfig[];

  @Output() onQueryRemoved = new EventEmitter<ComboboxControllerQuery>();

  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];

  Transformation = Transformation;
  Aggregation = Aggregation;
  ColorProperty = ColorProperty;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;

  selectedDataConfig: ComboboxColumnConfig = null;

  constructor(private xprojClient: XProjectorClient) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    //await this.selectedProjectionChange(this.projection);

    this.selectedProjectionGroupChange(this.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.widgetQuery.ProjectionId = projection.projectionid;
      this.selectedGroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection.projectionid, null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.selectedGroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection.projectionid, group);
      this.checkTimeFilterColumn();
    }
  }

  addColumn() {
    this.widgetQuery.DataConfigs.push(new ComboboxColumnConfig());
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.widgetQuery.DataConfigs, index);
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let dataConfig of this.widgetQuery.DataConfigs) {
      if (dataConfig == this.selectedDataConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedDataConfig = null;
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
    //this.loadingProjectionColumns = false;

  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.columns.find(col => col.columnname == this.widgetQuery.timestampColumnName)
      || (!this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.widgetQuery.timestampColumnName = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.widgetQuery.timestampColumnName = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.widgetQuery.timestampColumnName = col.columnname;
        }
      });
    }
  }

  onDataColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column) {
      this.widgetQuery.DataConfigs[index].Datatype = column.datatype;
    }
  }

  addScriptedColumnsPostAggregation() {
    this.widgetQuery.Query.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }


  public async SaveQuery() {
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation;
    this.widgetQuery.Query.maxitems = this.widgetQuery.MaxItems;

    if (this.projection) {
      this.widgetQuery.ProjectionId = this.projection.projectionid;
      this.widgetQuery.Group = this.selectedGroup;
      this.widgetQuery.Query.targetprojectionid = this.widgetQuery.ProjectionId;

      let colindex = 0;
      for (let column of this.widgetQuery.DataConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        col.columnoutname = column.ColumnOutName = "col_" + colindex.toString();
        col.columnaggregation = column.Transform;

        col.columntransformation = column.ColumnTransformation;

        if (colindex == 0) {
          if (!this.widgetQuery.UseGrouping) {
            this.widgetQuery.Query.sorting.columnname = col.columnname;
            this.widgetQuery.Query.sorting.descending = false;
            this.widgetQuery.Query.grouping = new ColumnGroupingDescription();
            this.widgetQuery.Query.columns.push(col);
          }
          else {
            this.widgetQuery.Query.grouping.columnname = col.columnname;
            this.widgetQuery.Query.grouping.columntransformation = this.widgetQuery.GroupingTransform;
            this.widgetQuery.Query.grouping.columnoutname = col.columnoutname;
          }
        }
        else {
          if (this.widgetQuery.UseGrouping) {
            col.columnaggregation = column.Transform;
          }
          this.widgetQuery.Query.columns.push(col);
        }
        colindex++;
      }

      if (this.groupSelect) {
        this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }

  }

}
