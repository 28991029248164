<div class="content-area">
  <div class="card" *ngIf="customer">
    <div class="card-header">
      {{customer.username}}
    </div>
    <div class="card-block">
      <form clrForm>
        <clr-input-container>
          <label>Default dashboard:</label>
          <input clrInput required placeholder="" name="defaultDashboardId" type="text"
            [(ngModel)]="customer.defaultDashboardId" />
        </clr-input-container>
      </form>
      <br>
      <button type="button" class="btn btn-outline" (click)="saveCustomer();">
        <clr-icon shape="floppy"></clr-icon>
        Save
      </button>

    </div>
  </div>
</div>
