<p *ngIf="codeObject=='init'">
    Init code
</p>
<ngx-monaco-editor *ngIf="codeObject=='init'" style="height:90%" name="editinitcode" [options]="editorOptionsLua" [(ngModel)]="Project.LuaProgram.luascript_oninit">
</ngx-monaco-editor>


<p *ngIf="codeObject=='error'">
    Error handling code
</p>
<ngx-monaco-editor *ngIf="codeObject=='error'" style="height:90%" name="editerrorcode" [options]="editorOptionsLua" [(ngModel)]="Project.LuaProgram.luascript_onerror">
</ngx-monaco-editor>


<p *ngIf="codeObject=='loop'">
    Loop code
</p>
<ngx-monaco-editor *ngIf="codeObject=='loop'" style="height:90%" name="editloopcode" [options]="editorOptionsLua" [(ngModel)]="Project.LuaProgram.luascript_loop">
</ngx-monaco-editor>