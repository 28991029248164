import { Pipe, PipeTransform } from '@angular/core';
import { XDataType, ProjectionColumnDescription } from '../XProjector/xprojector-client-service';

@Pipe({
  name: 'xproj_filternumericalcolumns'
})
export class XprojFilternumericalcolumnsPipe implements PipeTransform {

  transform(items:  Array<ProjectionColumnDescription>, includetimestamps : boolean): Array<ProjectionColumnDescription> {
    let rVal = new Array<ProjectionColumnDescription>()
    for(let item of items)
    {
      switch(item.datatype)
      {
        case XDataType.Float32:
        case XDataType.Float64:
        case XDataType.Int32:
        case XDataType.Int64:
        case XDataType.Number:
        case XDataType.UInt8:
          rVal.push(item);
          break;
        case XDataType.Timestamp:
          if(includetimestamps)
          rVal.push(item);
          break;
        default:
          break;
      }
    }
    return rVal;
  }

}
