import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { editableInPropertyPage } from 'babylonjs';
import { TimeScale } from 'chart.js';
import Drawflow from 'drawflow';
//import styleDrawflow from 'drawflow/dist/drawflow.min.css';
// https://github.com/jerosoler/Drawflow
// https://stackoverflow.com/questions/66267792/drawflow-library-on-typescript
@Component({
  selector: 'xproj-editplcprogram-blockcode',
  templateUrl: './editplcprogram-blockcode.component.html',
  styleUrls: ['./editplcprogram-blockcode.component.scss']
})
export class EditplcprogramBlockcodeComponent implements OnInit, AfterViewInit {

  Editor : Drawflow = null;
  @ViewChild('drawflow', {static: true})  drawflowContainer: HTMLElement;


  constructor() { }

  ngAfterViewInit(): void {
    //var id = document.getElementById("drawflow");    
    this.Editor = new Drawflow(this.drawflowContainer["nativeElement"]);
    this.Editor.reroute = true;
    this.Editor.reroute_fix_curvature = true;
    this.Editor.editor_mode = 'edit';
    this.Editor.curvature = 0.3;
    //this.Editor.

    
    this.Editor.start();

    // //var html = '<div><input type="text" df-name></div>';
    let data = { "name": "" };

    let editor = this.Editor;

    let signala = editor.addNode('foo', 0, 1, 100, 0, 'foo', "xautovarid-variablea", "Variable A", false);
    let signalb = editor.addNode('bar', 0, 1, 100, 100, 'bar', "xautovarid-variableb", "Variable B", false);


    let pidhtlm =  `<div style='float:left;font-size:0.5em;'>
                      <ul style='padding:0;margin: 0;list-style-type:none;'>
                        <li>Setpoint</li>
                        <li>Reference</li>
                      </ul>
                    </div>
                    <div style='float:left;margin-left:0.5em;'>
                      Controller<br>
                      PID
                    </div>`;

    let pid = editor.addNode('bar', 2, 1, 400, 300, 'bar', "call-pid", pidhtlm, false);
    

    editor.addConnection(signala, pid, "output_1", "input_1");
    editor.addConnection(signalb, pid, "output_1", "input_2");

    //this.Editor.import(dataToImport);
  }

  ngOnInit(): void {
  }

}
