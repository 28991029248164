import { Session } from "./session";

export class  UserRights {
  public isAdminUser : boolean;
  public isCustomerAdmin : boolean;
  public gdprConsent : boolean;
  public session : Session;
  public token : string;
  public sessionKey : string;
}
