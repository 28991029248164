import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { DashboardConfig, GridSettings } from '../../dashboard/xproj-dashboard-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { Projection, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import { LinkedWidgetChangeCommand, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetInputParameterAction, WidgetOutputParameter, WidgetPreQueryConfig, WidgetPrQueryColumnConfig } from '../widget-config-service';
import { XprojWidgetPrequeryConfigComponent } from '../widget-prequery-config/xproj-widget-prequery-config.component';

export class InputParameterConfig {
  inputParameter : WidgetInputParameter;
  outputparameters : WidgetOutputParameter[] = [];
}

@Component({
  selector: 'xproj-widget-config',
  templateUrl: './xproj-widget-config.component.html',
  styleUrls: ['./xproj-widget-config.component.scss']
})
export class XprojWidgetConfigComponent implements OnInit {

  @ViewChild("preQueryConfig", { read: XprojWidgetPrequeryConfigComponent, static: false }) preQueryConfig: XprojWidgetPrequeryConfigComponent;

  @Input() dashboardConfig : DashboardConfig;
  @Input() config : WidgetConfig;
  @Input() widgets : WidgetConfig[];
  @Input() showInputParameters : boolean = true;
  @Input() showPreQuery : boolean = false;
  @Input() gridSettings: GridSettings;
  @Input() projections: Projection[] = [];
  @Input() showInputParametersAction: boolean = false;

  @Output() save = new EventEmitter<WidgetConfig>();

  widgetConfigOpen : boolean = true;

  inputParameters : InputParameterConfig[] = [];
  selectedInputParameter : InputParameterConfig = null;
  selectedPreQueryInputParameters: InputParameterConfig[] = [];

  _selectedPreQueryConfig : WidgetPreQueryConfig = null;
  get selectedPreQueryConfig() : WidgetPreQueryConfig {
    return this._selectedPreQueryConfig;
  }
  set selectedPreQueryConfig(queryConfig : WidgetPreQueryConfig) {
    this._selectedPreQueryConfig = queryConfig;
    setTimeout(() => {
      this.preQueryConfig?.init();
    });
  }

  LinkedWidgetChangeCommand = LinkedWidgetChangeCommand;
  WidgetInputParameterAction = WidgetInputParameterAction;

  constructor(@Inject(LOGGERSERVICE) private logger: XprojLoggerService, private xprojClient: XProjectorClient) { }

  async ngOnInit() {
    this.getInputParameters = this.getInputParameters.bind(this);

    this.config.InputParameters.forEach(input => {
      let inputParam = new InputParameterConfig();
      inputParam.inputParameter = input;
      if (input.widgetId?.length > 0) {
        inputParam.outputparameters = this.getOutputParameters(input.widgetId);

        let output = inputParam.outputparameters.find(out => out.id == input.widgetOutputParameterId);
        if (output) {
          input.datatype = output.datatype;
        }
      }
      this.inputParameters.push(inputParam);
    });

    if (!this.projections || this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
  }

  getOutputParameters(widgetId : string, datatype? : OutputDataType) : WidgetOutputParameter[] {
    if (widgetId?.length > 0) {
      if (widgetId == '__dashboard__') {
        return this.dashboardConfig.OutputParameters.filter(out => !datatype || out.datatype == datatype);
      }
      else {
        let widget = this.widgets.find(w => w.Id == widgetId);
        if (widget) {
          return widget.OutputParameters.filter(out => !datatype || out.datatype == datatype);
        }
      }
    }

    return [];
  }

  onWidgetSelect(inputParam : InputParameterConfig, widgetId : string) {
    inputParam.outputparameters = this.getOutputParameters(inputParam.inputParameter.widgetId);
    //console.log('widgetId', widgetId);
    inputParam.inputParameter.widgetOutputParameterId = '';
  }

  onActionSelect(inputParam : InputParameterConfig, widgetId : string) {
  }


  addInputParameter() {
    let inputParam = new InputParameterConfig();
    inputParam.inputParameter = new WidgetInputParameter();
    this.config.InputParameters.push(inputParam.inputParameter);
    this.inputParameters.push(inputParam);
  }

  deleteSelectedParameter() {
    this.inputParameters = this.inputParameters.filter(i => i != this.selectedInputParameter);
    this.config.InputParameters = this.config.InputParameters.filter(i => i != this.selectedInputParameter.inputParameter);
  }

  onOutputSelect(input : InputParameterConfig, outputId : string) {
    let output = input.outputparameters.find(out => out.id == outputId);
    if (output) {
      //console.log('onOutputSelect', input, output);
      input.inputParameter.datatype = output.datatype;
    }
  }

  addPreQuery() {
    let preQueryConfig = new WidgetPreQueryConfig();
    this.config.WidgetPreQueryConfigs.forEach((config => {
      if (config.Level > preQueryConfig.Level) {
        preQueryConfig.Level = config.Level;
      }
    }));
    preQueryConfig.Level++;
    this.config.WidgetPreQueryConfigs.push(preQueryConfig);
  }

  removeSelectedPreQuery() {
    this.config.WidgetPreQueryConfigs = this.config.WidgetPreQueryConfigs.filter(c => c != this.selectedPreQueryConfig);
  }

  getInputParameters(level : number) : WidgetInputParameter[] {
    let result : WidgetInputParameter[] = [].concat(this.config.InputParameters);
    this.config.WidgetPreQueryConfigs.filter(c => level < 0 || c.Level < level).forEach(preQueryConfig => {
      preQueryConfig.OutputParameters.forEach(out => {
        let inputParameter = new WidgetInputParameter();
        inputParameter.id = out.name;
        inputParameter.widgetId = '';//this.config.Id;
        inputParameter.datatype = out.datatype;
        inputParameter.widgetOutputParameterId = out.id;

        result.push(inputParameter);
      });
    });

    return result;
  }

  onPreQueryConfigSaved(preQueryConfig : WidgetPreQueryConfig) {
    this.save?.emit(this.config);
  }

}
