import { CustomerUsersClient } from './proto/xprojector.customerusers.pbsc'
import { HelloClient } from './proto/hello.pbsc'
import { Injectable, OnDestroy, OnInit, ɵgetSanitizationBypassType } from '@angular/core';

import { BasicResponse, Customer, CustomerUser, DataChunk, UserDevice } from './proto/xprojector.grpc.models.pb';
import { StateService } from '@xprojectorcore/services/state-service';
import { Empty } from '@ngx-grpc/well-known-types';
import { Subscription } from 'rxjs';
import { SetPasswordRequest, CreateExcelFileRequest, GetCustomerContentResponse, AddCustomerUserDeviceRequest, DeleteCustomerUserDeviceRequest } from './proto/xprojector.customerusers.pb';
import { GrpcDataEvent, GrpcEvent } from '@ngx-grpc/common';
import { saveAs } from 'file-saver';
import { BaseQuery, DFTQuery, DownSampleQuery, MultiseriesQuery, WidgetExportParameters } from 'xproj-lib';
import { encode } from '@msgpack/msgpack';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})
export class XProjectorCustomerUsersClient implements OnInit, OnDestroy{

  constructor ( private customerUsersClient : CustomerUsersClient, private state : StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async getActiveCustomerUser() : Promise<CustomerUser> {
    return this.customerUsersClient.getActiveCustomerUser(new Empty(), this.state.metadata).toPromise();
  }

  async getActiveCustomer() : Promise<Customer> {
    return this.customerUsersClient.getActiveCustomer(new Empty(), this.state.metadata).toPromise();
  }

  async getCustomerContent() : Promise<GetCustomerContentResponse> {
    return this.customerUsersClient.getCustomerContent(new Empty(), this.state.metadata).toPromise();
  }

  async modifyCustomerUser(request : CustomerUser) : Promise<BasicResponse> {
    return this.customerUsersClient.modifyCustomerUser(request, this.state.metadata).toPromise();
  }

  async getCustomerUserDevices() : Promise<UserDevice[]> {
    let result = await this.customerUsersClient.getActiveCustomerUserDevices(new Empty(), this.state.metadata).toPromise();
    return result.userDevices;
  }

  async addCustomerUserDevice(name : string) : Promise<UserDevice> {
    let request = new AddCustomerUserDeviceRequest();
    request.name = name;
    let result = await this.customerUsersClient.addCustomerUserDevice(request, this.state.metadata).toPromise();
    return result;
  }

  async deleteCustomerUserDevice(id : string) : Promise<BasicResponse> {
    let request = new DeleteCustomerUserDeviceRequest();
    request.id = id;
    return this.customerUsersClient.deleteCustomerUserDevice(request, this.state.metadata).toPromise();
  }

  async setPassword(oldPassword : string, newPassword : string) : Promise<BasicResponse> {
    let request : SetPasswordRequest = new SetPasswordRequest();
    request.oldPassword = oldPassword;
    request.newPassword = newPassword;

    return this.customerUsersClient.setPassword(request, this.state.metadata).toPromise();
  }

  async exportBaseQueryToExcel(name : string, baseQuery : BaseQuery, decimals : number = 3, excludeColumns : string[] = [])  : Promise<boolean>{
    let query = encode(baseQuery);
    return this.exportExcel(name, CreateExcelFileRequest.QueryTypes.BASEQUERY, query, decimals, excludeColumns);
  }

  async exportDownSamlpQueryToExcel(name : string, downSampleQuery : DownSampleQuery, decimals : number = 3, excludeColumns : string[] = [])  : Promise<boolean>{
    let query = encode(downSampleQuery);
    return this.exportExcel(name, CreateExcelFileRequest.QueryTypes.DOWNSAMPLEQUERY, query, decimals, excludeColumns);
  }

  async exportDFTQueryToExcel(name : string, downSampleQuery : DFTQuery, decimals : number = 3, excludeColumns : string[] = [])  : Promise<boolean>{
    let query = encode(downSampleQuery);
    return this.exportExcel(name, CreateExcelFileRequest.QueryTypes.DFTQUERY, query, decimals, excludeColumns);
  }

  async exportMultiSeriesQueryToExcel(name : string, multiSeriesSampleQuery : MultiseriesQuery, decimals : number = 3, excludeColumns : string[] = [])  : Promise<boolean>{
    let query = encode(multiSeriesSampleQuery);
    return this.exportExcel(name, CreateExcelFileRequest.QueryTypes.MULTISERIESQUERY, query, decimals, excludeColumns);
  }

  private async exportExcel(name : string, querytype : CreateExcelFileRequest.QueryTypes, query : Uint8Array, decimals : number = 3, excludeColumns : string[] = []) : Promise<boolean>{
    return new Promise((resolve, reject) => {
      let request : CreateExcelFileRequest = new CreateExcelFileRequest();
      request.queryType = querytype;
      request.query = query;
      request.chunkSize = 1024 * 32;
      request.decimals = decimals;
      request.excludeColumns = excludeColumns;
      let dataChunks : Uint8Array[] = [];
      let i : number = 0;

      this.customerUsersClient.createExcelFile(request, this.state.metadata).subscribe(
        ((event : any) => {
          //console.log('event', event);

          let datachunk : Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, name + ".xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });

  }

  async exportToExcel(parameters: WidgetExportParameters, maxitems : number = 300000) : Promise<boolean> {
    let result = false;
    if (parameters.queries?.length > 0) {
      let query = parameters.queries[0];
      if (query instanceof BaseQuery) {
        query.maxitems = maxitems;
        query.seekoffset = 0;
        result = await this.exportBaseQueryToExcel(parameters.name, query, parameters.decimals, parameters.excludeColumns);
      }
      else if (query instanceof DownSampleQuery) {
        query.query.maxitems = maxitems;
        query.query.seekoffset = 0;
        result = await this.exportDownSamlpQueryToExcel(parameters.name, query, parameters.decimals, parameters.excludeColumns);
      }
      else if (query instanceof DFTQuery) {
        query.query.maxitems = maxitems;
        query.query.seekoffset = 0;
        result = await this.exportDFTQueryToExcel(parameters.name, query, parameters.decimals, parameters.excludeColumns);
      }
      else if (query instanceof MultiseriesQuery) {
        query.maxitems = maxitems;
        query.seekoffset = 0;
        result = await this.exportMultiSeriesQueryToExcel(parameters.name, query, parameters.decimals, parameters.excludeColumns);
      }
    }
    else if (parameters.data?.length > 0)
    {
      result = await this.exportDataToExcel(parameters);
    }

    return result;
  }

  private async exportDataToExcel(parameters: WidgetExportParameters) : Promise<boolean> {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(parameters.data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, parameters.name + '.xlsx');

    return true;
  }

}
