import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfigurationDataSourceComponent, DropDownItem } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerBmsRealestate } from '@core/models/rossaker-bms-realestate';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { RossakerBmsDataUtils } from '@core/utils/rossaker-bms-data-utils';
import { RossakerXProjectorBmsExportClient } from '@core/xprojector_backend/rossaker-xprojector-bms-export-client';
import { StateService } from '@xprojectorcore/services/state-service';
import { Customer, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrLoadingState, ClrTabs } from '@clr/angular';
import { SplitAreaDirective } from 'angular-split';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { rossakerBmsDistricts } from '@core/models/rossaker-bms-districts';
import { DashboardOutputChangeParameters, DateHelper, LinkedWidgetSelectParameters, OutputDataType, XprojAlertService, XprojModalService } from 'xproj-lib';
import { RossakerBmsDataCollectorViewComponent } from '../rossaker-bms-data-collector-view/rossaker-bms-data-collector-view.component';
import { RossakerBmsMeterData } from '@app/core/models/rossaker-bms-meter-data';
import { RossakerBmsMeterState } from '@app/core/models/rossaker-bms-meter';
import { EditTreenodeComponent } from '@xprojectorfeatures/xconf/components/edit-treenode/edit-treenode.component';
import { XEdgeToken } from '@xprojectorcore/models/xedge-token';
import { SessionStorageService } from '@xprojectorcore/services/session-storage.service';
import { XEdgeSelectXAutoVarsComponent } from '@xprojectorfeatures/xedge/components/xedge-select-xauto-vars/xedge-select-xauto-vars.component';
import { XProjectorXEdgeClient } from '@xprojectorcore/xprojector_backend/xprojector-xedge-client';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

declare var XPROJECTOR_HOST;
declare var XEDGE_APP_HOST;

class ExternalIdComparator implements ClrDatagridComparatorInterface<RossakerBmsMeterData> {
  compare(a: RossakerBmsMeterData, b: RossakerBmsMeterData) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

const TABINDEX_OVERVIEW = 0;
const TABINDEX_BMS = 1;
const TABINDEX_GRID = 2;
const TABINDEX_DATACOLLECTORS = 3;
const TABINDEX_SETTINGS = 4;

@Component({
  selector: 'app-rossaker-bms-realestate-view',
  templateUrl: './rossaker-bms-realestate-view.component.html',
  styleUrls: ['./rossaker-bms-realestate-view.component.scss']
})
export class RossakerBmsRealestateViewComponent implements OnInit, OnDestroy {
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  @ViewChild("rossakerBmsDataCollectorView", { read: RossakerBmsDataCollectorViewComponent, static: false }) rossakerBmsDataCollectorView: RossakerBmsDataCollectorViewComponent;
  @ViewChild("rossakerBmsBMSView", { read: RossakerBmsDataCollectorViewComponent, static: false }) rossakerBmsBMSView: RossakerBmsDataCollectorViewComponent;
  @ViewChild("editGridNode", { read: EditTreenodeComponent, static: false }) editGridNode: EditTreenodeComponent;
  @ViewChild("editTreeNode", { read: EditTreenodeComponent, static: false }) editTreeNode: EditTreenodeComponent;
  @ViewChild("selectXEdgeMeters", { read: XEdgeSelectXAutoVarsComponent, static: false }) selectXEdgeMeters: XEdgeSelectXAutoVarsComponent;
  @ViewChild("dataCollectorsDatasourceConfig", { read: ConfigurationDataSourceComponent, static: false }) dataCollectorsDatasourceConfig: ConfigurationDataSourceComponent;
  @ViewChild("bmsConfigurationDatasource", { read: ConfigurationDataSourceComponent, static: false }) bmsConfigurationDatasource: ConfigurationDataSourceComponent;
  @ViewChild(ClrTabs) private readonly tabs: ClrTabs;

  ascSort = ClrDatagridSortOrder.ASC;
  externalIdSort = new ExternalIdComparator();

  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;
  selectedCustomerTrustees: Customer[];
  selectedMeterDatas: RossakerBmsMeterData[] = [];
  loadingMeterData: boolean = false;
  inparamCustomerId: string;
  selectedCustomerRealestateNodes: GrpcNode[] = [];

  realestates: RossakerBmsRealestate[] = [];
  oldSelectedRealestate: RossakerBmsRealestate;
  selectedRealestate: RossakerBmsRealestate;
  selectedRealestateId: number = 0;

  showViewGatewayMeters: boolean = false;
  dataCollectorsDataSourceInstance: GrpcDataSourceInstance;
  selectedDataCollectorNode: GrpcNode;
  selectedDataCollectorNodeId: string;

  bmsSelectedPath: string[] = [];
  metersSelectedPath: string[] = [];

  gridResponsiveWidth: number = 834;
  overviewResponsiveWidth: number = 834;
  dashboardOverviewOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardGridDetailOutputParameters: DashboardOutputChangeParameters[] = [];

  allNodeTypes: GrpcNodeType[] = [];

  gridDetailState: any = null;
  showGridEditModal: boolean = false;
  currentGridEditNode: GrpcNode;
  currentGridEditNodeId: string = '';

  districts : string[] = rossakerBmsDistricts;
  showGridEditBuildingAddresses: boolean = false;
  setBuildingAddressesInfo: {
    postalCode : string,
    city : string,
    district : string
  }


  bmsDataSourceInstance: GrpcDataSourceInstance;
  selectedBmsTreeNode: GrpcNode;
  selectedRealestateNode: GrpcNode;
  showEditNodeModal: boolean = false;

  selectedRealestateDataCollectorsNode: GrpcNode;
  dataCollectorsRightPanelWidth: number = 600;
  bmsRightPanelWidth: number = 600;

  importingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  exportingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  importMeterfile: any;

  showXEdgeSelectMetersModal : boolean = false;
  xedgeRemoteNodeId : string;

  _overviewActive: boolean = false;
  get overviewActive(): boolean {
    return this._overviewActive;
  }
  set overviewActive(value: boolean) {
    this._overviewActive = value;
    if (this._overviewActive) {

    }
  }

  _bmsActive: boolean = false;
  get bmsActive(): boolean {
    return this._bmsActive;
  }
  set bmsActive(value: boolean) {
    if (value != this._bmsActive) {
      this.updateSelectedCustomerConfig();
      this._bmsActive = value;
    }
  }

  _gridActive: boolean = false;
  get gridActive(): boolean {
    return this._gridActive;
  }
  set gridActive(value: boolean) {
    if (value) {
      this.updateCustomerData();
    }
    this._gridActive = value;
  }

  _exportImportActive: boolean = false;
  get exportImportActive(): boolean {
    return this._exportImportActive;
  }
  set exportImportActive(value: boolean) {
    if (value && value != this._exportImportActive) {

    }
    this._exportImportActive = value;
  }

  dropDownItems: DropDownItem[] = [
    {
      name: 'Export Webport taglist',
      shape: 'export',
      nodeTypeLabel: '_x_bms_realestate',
      onClick: this.onExportTagList.bind(this)
    }
  ];

  dropDownItemsDataCollectors: DropDownItem[] = [
    {
      name: 'XEdge Config',
      shape: 'cog',
      nodeTypeId: '_x_xedge_server',
      onClick: this.viewXEdge.bind(this)
    },
    {
      name: 'Select meters...',
      shape: 'dashboard',
      nodeTypeId: '_x_xedge_server',
      onClick: this.xedgeSelectMeters.bind(this)
    }
  ];

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private rossakerState: RossakerStateService,
    private adminService: RossakerBmsAdminService,
    private xconfClient: XProjectorXConfClient,
    private alertService: XprojAlertService,
    private modalService: XprojModalService,
    private rossakerBmsExportClient: RossakerXProjectorBmsExportClient,
    private sessionStorageService : SessionStorageService,
    private xedgeClient : XProjectorXEdgeClient,
    public dateHelper: DateHelper,
    private cdr: ChangeDetectorRef
  ) {
    let lastRealestateId = localStorage.getItem("xprojector-rossaker-realestates-lastrealestateid");
    if (lastRealestateId) {
      this.logger.info('last realestateId', lastRealestateId);
      this.selectedRealestateId = +lastRealestateId;
    }

  }

  ngOnInit(): void {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.selectedCustomerData = new RossakerBmsCustomerData();
      if (this.selectedBmsCustomer) {
        this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
        this.updateSelectedCustomerConfig();
      }
      //await this.updateSelectedCustomerConfig();
      this.updateSelectedCustomerOutputs();
    });

    let lscontentPanelWidth = Number.parseInt(localStorage.getItem("xprojector-rossaker-realstate-datacollectors-rightPanelWidth") || this.dataCollectorsRightPanelWidth.toString());
    if (lscontentPanelWidth != this.dataCollectorsRightPanelWidth) {
      this.dataCollectorsRightPanelWidth = lscontentPanelWidth;
    }

    lscontentPanelWidth = Number.parseInt(localStorage.getItem("xprojector-rossaker-realstate-bms-rightPanelWidth") || this.bmsRightPanelWidth.toString());
    if (lscontentPanelWidth != this.bmsRightPanelWidth) {
      this.bmsRightPanelWidth = lscontentPanelWidth;
    }

    this.updateWidth();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async updateWidth() {
    //await this.onSplitDragEnd();
    this.overviewResponsiveWidth = window.innerWidth - 300;
  }

  updateSelectedCustomerOutputs() {
    this.dashboardOverviewOutputParameters = [];
    if (this.selectedBmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.selectedBmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.selectedBmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customername';
      out.value = this.selectedBmsCustomer.customerName;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'realestateid';
      out.value = this.selectedRealestate?.id;
      out.datatype = OutputDataType.Int64;
      this.dashboardOverviewOutputParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'realestatename';
      out.value = this.selectedRealestate?.name;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);
    }
  }

  async updateSelectedCustomerConfig() {
    if (this.selectedBmsCustomer && !this.selectedCustomerConfig) {
      this.selectedCustomerConfig = await this.adminService.getCustomerConfig(this.selectedBmsCustomer.customerId, await this.xconfClient.getNodeTypes());
    }

    if (!this.bmsDataSourceInstance) {
      this.bmsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_datasource');
    }

    if (!this.dataCollectorsDataSourceInstance) {
      this.dataCollectorsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_datacollectors_datasource');
    }

    this.realestates = await this.adminService.getRealestates(this.selectedCustomerConfig?.customerId);
    if (this.selectedRealestateId > 0) {
      this.selectedRealestate = this.realestates.find(r => r.id == this.selectedRealestateId);
      this.updateSelectedCustomerOutputs();

      this.selectedRealestateNode = await this.xconfClient.getNode(this.selectedRealestateId + '', '_x_bms_realestate', false, this.selectedCustomerConfig?.customerId);
      this.selectedRealestateDataCollectorsNode = await this.xconfClient.getNode(this.selectedRealestateId + '', '_x_datacollectors_group', false, this.selectedCustomerConfig?.customerId);
    }
  }

  async onRealestateDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

  async selectedRealestateChanged($event) {
    this.selectedRealestateId = this.selectedRealestate?.id;
    localStorage.setItem("xprojector-rossaker-realestates-lastrealestateid", this.selectedRealestate?.id + "");
    await this.updateSelectedCustomerConfig();
    this.updateSelectedCustomerOutputs();
    if (this.selectedCustomerData) {
      this.selectedCustomerData.realestates = [];
    }
    if (this.gridActive) {
      this.updateCustomerData(true);
    }
  }

  realestateSelectOpenChange(open: boolean) {
    if (open) {
      this.oldSelectedRealestate = this.selectedRealestate;
      this.selectedRealestate = null;
    }
    else if (!this.selectedRealestate) {
      this.selectedRealestate = this.oldSelectedRealestate;
    }

  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {

  }

  async onDataCollectorsTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.rossakerBmsDataCollectorView?.setDataNode(item.treeNode.node, this.dataCollectorsRightPanelWidth);
  }

  async onBMSTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.rossakerBmsBMSView?.setDataNode(item.treeNode.node, this.bmsRightPanelWidth);
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async onSplitDragEndDataCollectors() {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.dataCollectorsRightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-rossaker-realstate-datacollectors-rightPanelWidth", this.dataCollectorsRightPanelWidth.toString());
    }

    this.rossakerBmsDataCollectorView?.setWidth(this.dataCollectorsRightPanelWidth);
  }

  async onSplitDragEndBMS() {
    let treePaneArea = this.getPaneArea(4);

    if (treePaneArea) {
      this.bmsRightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-rossaker-realstate-bms-rightPanelWidth", this.bmsRightPanelWidth.toString());
    }

    this.rossakerBmsBMSView?.setWidth(this.bmsRightPanelWidth);
  }

  async exportTemplate() {
    let result = await this.rossakerBmsExportClient.createExportFile('higab', 'lorawantemplate', this.selectedRealestateId + '',
      '_x_bms_realestate', this.selectedRealestate?.name + '_LORA.xlsx', this.selectedBmsCustomer?.customerId);
  }

  async exportMeters() {
    let result = await this.rossakerBmsExportClient.createExportFile('higab', 'lorawanmeters', this.selectedRealestateId + '',
      '_x_bms_realestate', this.selectedRealestate?.name + '_LORA.xlsx', this.selectedBmsCustomer?.customerId);
  }


  async onExportTagList(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node.nodeTypeLabel == '_x_bms_realestate') {
      let pExternalId = treeNode.node.propertyValues.find(p => p.key == 'externalid');
      if (pExternalId) {
        let externalId = RossakerBmsDataUtils.getValue(pExternalId.valueType, pExternalId.value, pExternalId, this.dateHelper);
        let result = await this.rossakerBmsExportClient.createExportFile('webport', '', treeNode.id, treeNode.node.nodeTypeLabel,
          treeNode.node.name + '_IOT_LoRaWAN.csv', this.selectedBmsCustomer?.customerId);
      }
    }
  }

  uploadMeterFile(file) {
    if (file.files.length > 0) {
      this.importMeterfile = file.files[0];
    }
  }

  async importMeterFiles() {
    if (this.selectedBmsCustomer && this.importMeterfile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        let importResult = await this.rossakerState.importFile(this.importMeterfile, 'higab', 'lorawan',
          this.selectedRealestateId + '', '_x_bms_realestate', this.selectedBmsCustomer.customerId);

        if (importResult.success) {
          this.alertService.success('File import ok.');
          this.importingState = ClrLoadingState.SUCCESS;
        }
        else {
          this.alertService.error('Error import file:' + importResult.message);
          await this.modalService.ShowConfirmModalAsync({
             header: 'File import',
             description: importResult.message,
             showCancel: false
           });

          this.importingState = ClrLoadingState.ERROR;
        }

      }
      catch (err) {
        this.alertService.error('Error import file:' + err.message);
        this.importingState = ClrLoadingState.ERROR;
      }
      finally {
        this.importMeterfile = null;
      }
    }
  }

  async viewInBms(meterId: string) {
    //TODO
    //this.bmsSelectedPath = await this.xconfClient.getShortestPath('_x_bms_realestates_root_' + this.selectedBmsCustomer.customerId, '_x_bms_realestates_root', meterId, '_x_bms_meter', 10);
    this.bmsSelectedPath = await this.xconfClient.getShortestPath(this.selectedRealestate.id + '', '_x_bms_realestate', meterId, '_x_bms_meter', 10);
    // if (this.bmsSelectedPath.length > 1) {
    //   this.bmsSelectedPath = this.bmsSelectedPath.slice(1);
    // }

    this.bmsActive = true;
    this.tabs.tabLinkDirectives[TABINDEX_BMS].activate();
    this.cdr.detectChanges();
    if (this.bmsConfigurationDatasource) {
      this.bmsConfigurationDatasource.selectedPath = this.bmsSelectedPath;
      this.bmsConfigurationDatasource.initDataSourceInstance(false, false);
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xconfClient.updateNode(node, oldId, '', this.selectedBmsCustomer?.customerId);
    return result.result;
  }

  async saveEditNode() {
    if (this.editTreeNode) {
      this.editTreeNode.savePropertyValues();
      let result = await this.updateNode(this.selectedDataCollectorNode, this.selectedDataCollectorNodeId);
      if (result) {
        this.alertService.info('Node updated.');
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditNodeModal = false;
  }

  //Grid
  async updateCustomerData(forceReload: boolean = false) {
    if (this.selectedCustomerData?.customerId?.length > 0) {
      try {
        this.loadingMeterData = true;
        if (forceReload || this.selectedCustomerData.id <= 0) {
          let customer = await this.adminService.getCustomer(this.selectedCustomerData.customerId, forceReload);
          if (customer) {
            this.selectedCustomerData.id = customer.id;
          }
        }

        if (this.selectedCustomerData.id > 0 && (forceReload || this.selectedCustomerData.realestates.length == 0)) {
          this.selectedCustomerData.realestates = await this.adminService.getRealestates(this.selectedCustomerData.customerId, forceReload);
          this.selectedCustomerData.buildings = await this.adminService.getBuildings(this.selectedCustomerData.customerId, forceReload);
          this.selectedCustomerData.buildingAddresses = await this.adminService.getBuildingAddresses(this.selectedCustomerData.customerId, forceReload);
          this.selectedCustomerData.apartments = await this.adminService.getApartments(this.selectedCustomerData.customerId, forceReload);
          this.selectedCustomerData.facilities = await this.adminService.getFacilities(this.selectedCustomerData.customerId, forceReload);
          this.selectedCustomerData.gateways = await this.adminService.getGateways(this.selectedCustomerData.customerId);
          this.selectedCustomerData.meters = await this.adminService.getMeters(this.selectedCustomerData.customerId, this.selectedRealestateId, forceReload);
          this.selectedCustomerData.latestValues = await this.adminService.getLatestValues(this.selectedCustomerData.customerId, forceReload);

          this.selectedCustomerData.meterDatas = [];
          this.selectedCustomerData.meters.forEach(meter => {
            let meterData = new RossakerBmsMeterData();
            meterData.meterId = meter.id;
            meterData.realestateId = meter.realestateId;
            meterData.buildingId = meter.buildingId;
            meterData.buildingAddressId = meter.buildingAddressId;
            meterData.apartmentId = meter.apartmentId;
            meterData.facilityId = meter.facilityId;
            meterData.gatewayId = meter.gatewayId;
            meterData.floor = 0;

            let realestate = this.selectedCustomerData.realestates.find(r => r.id == meterData.realestateId);
            if (realestate) {
              meterData.svlantPropertyDesignation = realestate.svlantPropertyDesignation;
            }

            let building = this.selectedCustomerData.buildings.find(b => b.id == meterData.buildingId);
            if (building) {
              meterData.svLantBuildingNo = building.svLantBuildingNo;
            }

            let buildingAddress = this.selectedCustomerData.buildingAddresses.find(b => b.id == meterData.buildingAddressId);
            if (buildingAddress) {
              meterData.street = buildingAddress.street;
              meterData.housenumber = buildingAddress.housenumber;
              meterData.postalcode = buildingAddress.postalcode;
              meterData.city = buildingAddress.city;
              meterData.district = buildingAddress.district;
            }

            let apartment = this.selectedCustomerData.apartments.find(a => a.id == meterData.apartmentId);
            if (apartment) {
              meterData.svlantApartmentno = apartment.svlantApartmentno;
              meterData.externalId = apartment.externalId;
              meterData.prefix = apartment.prefix;
              meterData.area = apartment.area;
              meterData.size = apartment.size;
            }

            let facility = this.selectedCustomerData.facilities.find(f => f.id == meterData.facilityId);
            if (facility) {
              meterData.facilityType = facility.facilityType;
              meterData.externalId = facility.externalId;
              meterData.prefix = facility.prefix;
              meterData.area = facility.area;
              meterData.size = facility.size;
              meterData.floor = facility.floor;
            }

            let gateway = this.selectedCustomerData.gateways.find(g => g.id == meterData.gatewayId);
            if (gateway) {
              meterData.gw_serialnumber = gateway.serialnumber;
              meterData.gw_vendor = gateway.vendor;
            }

            let latestValue = this.selectedCustomerData.latestValues.find(l => l.meterId == meterData.meterId);
            if (latestValue) {
              meterData.value = latestValue.value;
              meterData.timestamp = latestValue.timestamp;
            }

            meterData.seconadaryAddress = meter.identifier;
            meterData.manufacturer = meter.manufacturer;
            meterData.unit = meter.unit;
            meterData.datapointValueUnit = meter.datapointValueUnit;
            meterData.variable = meter.variable;
            meterData.index = meter.index;
            meterData.state = RossakerBmsMeterState[meter.state];
            meterData.meterType = meter.meterType;
            meterData.meterSubtype = meter.meterSubtype;
            meterData.tag = meter.tag;

            this.selectedCustomerData.meterDatas.push(meterData);
          });
        }
      }
      finally {
        this.loadingMeterData = false;
      }
    }
  }

  async onGridRefresh() {
    this.updateCustomerData(true);
  }

  async onGridEditMeters() {
    console.log('onGridEditMeters', this.selectedMeterDatas);
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let meterNode = await this.xconfClient.getNode(meterData.meterId + '', '_x_bms_meter');
      if (meterNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = meterNode.id;
        this.currentGridEditNode = meterNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditViewInBMS() {
    let meterData = this.selectedMeterDatas[0];
    if (meterData) {
      await this.viewInBms(meterData.meterId + '');
    }
  }

  async onGridEditRealestates() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let realestateNode = await this.xconfClient.getNode(meterData.realestateId + '', '_x_bms_realestate');
      if (realestateNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = realestateNode.id;
        this.currentGridEditNode = realestateNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditBuildings() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let buildingNode = await this.xconfClient.getNode(meterData.buildingId + '', '_x_bms_building');
      if (buildingNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = buildingNode.id;
        this.currentGridEditNode = buildingNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditBuildingAddresses() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let buildingAddressNode = await this.xconfClient.getNode(meterData.buildingAddressId + '', '_x_bms_buildingaddress');
      if (buildingAddressNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = buildingAddressNode.id;
        this.currentGridEditNode = buildingAddressNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditApartmentsOrFacilities() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let node = await this.xconfClient.getNode(
        (meterData.apartmentId > 0 ? meterData.apartmentId : meterData.facilityId) + '',
        meterData.apartmentId > 0 ? '_x_bms_apartment' : '_x_bms_facility');
      if (node) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = node.id;
        this.currentGridEditNode = node;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridExport() {
    this.selectedCustomerData.meterDatas.sort((a: RossakerBmsMeterData, b: RossakerBmsMeterData) => {
      if (a && b && a.externalId && b.externalId) {
        return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
      }
      else {
        return a ? 1 : -1
      }
    });

    await this.adminService.exportToExcel(this.selectedCustomerData.meterDatas, this.selectedBmsCustomer.customerName + '.xlsx');
  }

  async saveGridEditNode() {
    if (this.editGridNode) {
      this.editGridNode.savePropertyValues();
      let result = await this.updateNode(this.currentGridEditNode, this.currentGridEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
        this.updateCustomerData(true);
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showGridEditModal = false;
  }

  async onGridDetailChange(meterData: RossakerBmsMeterData) {
    this.dashboardGridDetailOutputParameters = [];
    if (meterData != null) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'Id';
      out.value = meterData.meterId;
      out.datatype = OutputDataType.Int64;
      this.dashboardGridDetailOutputParameters.push(out);
    }
  }

  closeGridEditNode() {
    this.showGridEditModal = false;
  }

  showEditBuildingAddresses() {
    let city : string = '';
    let district : string = '';
    let postalCode : string = '';

    if (this.selectedCustomerData?.meterDatas?.length > 0) {
      let meterData = this.selectedCustomerData.meterDatas.find(m => m.city?.length > 0);
      if (meterData) {
        city = meterData.city;
        district = meterData.district;
        postalCode = meterData.postalcode;
      }
    }

    this.setBuildingAddressesInfo = {city : city, district : district, postalCode : postalCode};
    this.showGridEditBuildingAddresses = true;
  }

  async viewXEdge(treeNode: XconfTreeNode) {
    console.log("laucnhign viewedge for", treeNode);
    let token = new XEdgeToken();
    let session = await this.state.getActiveSession();
    token.token = session?.sessionKey ?? '';
    token.remoteNodeId = treeNode.id;
    token.host = XPROJECTOR_HOST;
    token.name = treeNode.name;
    console.log("setting token", token);
    this.sessionStorageService.setXEdgeToken(token);

    console.log("opening window", XEDGE_APP_HOST);
    window.open(XEDGE_APP_HOST);
  }

  async xedgeSelectMeters(treeNode: XconfTreeNode) {
    this.xedgeRemoteNodeId = treeNode.id;
    this.showXEdgeSelectMetersModal = true;
  }

  async addSelectedXEdgeMeters() {
    this.showXEdgeSelectMetersModal = false;
    let toAdd = this.selectXEdgeMeters?.selectedXAutoVars;

    if (toAdd) {
      let result = await this.xedgeClient.addXEdgeXAutoMeters(this.selectedCustomer?.id, this.xedgeRemoteNodeId, toAdd);
      if (result.ok) {
        this.alertService.success(result.addedCount + ' meters added.');
        this.dataCollectorsDatasourceConfig?.refreshTreeView();
      }
      else {
        this.alertService.error(result.message);
      }
    }
  }

  formatByString(date: Date, format: string, displayUTC: boolean = false): string {
    if (date) {
      let d = displayUTC ? this.dateHelper.utils.addMinutes(date, date.getTimezoneOffset()) : date;
      return this.dateHelper.utils.formatByString(d, format);
    }

    return '';
}

}
