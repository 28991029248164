<xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"
  [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Static Lines</clr-accordion-title>
    <clr-accordion-content>

      <clr-datagrid [(clrDgSingleSelected)]="selectedLine">
        <clr-dg-column>Name</clr-dg-column>
        <clr-dg-column>Input</clr-dg-column>
        <clr-dg-column>Value</clr-dg-column>
        <clr-dg-column>Direction</clr-dg-column>
        <clr-dg-column>Y Axis</clr-dg-column>

        <clr-dg-row *ngFor="let line of lineConfigs; let i = index" [clrDgItem]="line">
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="line_name_{{i}}" id="line_name_{{i}}" [(ngModel)]="line.Name" />
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <input class="clr-checkbox" type="checkbox" placeholder="" name="UseValueInputParameter{{i}}"
                [(ngModel)]="line.UseValueInputParameter" />

              <select [disabled]="!line.UseValueInputParameter" class="clr-select" style="width:13em;" name="inputparam"
                [(ngModel)]="line.ValueInputParameterId">
                <option
                  *ngFor="let input of widgetConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Number]"
                  [ngValue]="input.id">
                  {{input.id}}</option>
              </select>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>
            <input [disabled]="line.UseValueInputParameter" class="clr-input" type="number" name="value_{{i}}"
              id=value_{{i}} [(ngModel)]="line.Value" />
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select class="clr-select" name="inputparam" [(ngModel)]="line.Horizontal">
                <option [ngValue]="true"> Horizontal </option>
                <option [ngValue]="false">Vertical</option>
              </select>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select [disabled]="!line.Horizontal" class="clr-select" style="width:5em;" name="yaxisid{{i}}"
                [(ngModel)]="line.AxisId">
                <option [ngValue]="YAxisId.LEFT"> Left </option>
                <option [ngValue]="YAxisId.RIGHT"> Right </option>
              </select>
            </div>
          </clr-dg-cell>

        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addLine()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add line
      </button>
      <button *ngIf="selectedLine" type="button" class="btn btn-link" (click)="deleteSelectedLine()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected line
      </button>

      <div *ngIf="selectedLine" style="max-width: 600px;">
        <div class="card">
          <div class="card-header">
            Line properties
          </div>

          <div class="card-block">
            <form clrForm>
              <clr-select-container>
                <label>Position</label>
                <select clrSelect id="position" name="position" [(ngModel)]="selectedLine.Infront">
                  <option [ngValue]="true"> Front of </option>
                  <option [ngValue]="false">Behind</option>
                </select>
              </clr-select-container>

              <clr-input-container>
                <label>Line Color</label>
                <input clrInput placeholder="" name="linecolor" type="text" [(ngModel)]="selectedLine.Color" />
                <input clrInput placeholder="" name="linecolor2" type="color" [(ngModel)]="selectedLine.Color"
                  style="margin-left: 1em;" />
              </clr-input-container>

              <clr-input-container>
                <label>Line width</label>
                <input clrInput placeholder="" type="number" name="linewidth" min="1" max="1000"
                  [(ngModel)]="selectedLine.Width" />
              </clr-input-container>

              <clr-input-container>
                <label>Line dash (line / gap)/label</label>
                <input clrInput placeholder="" type="number" name="linedash1" min="0" max="1000"
                  [(ngModel)]="selectedLine.LineDash[0]" />
                <input clrInput placeholder="" type="number" name="linedash2" min="0" max="1000"
                  [(ngModel)]="selectedLine.LineDash[1]" />
              </clr-input-container>

              <clr-checkbox-container>
                <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox name="ShowLabel" [(ngModel)]="selectedLine.ShowLabel" />
                  <label>Show label</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>

              <clr-input-container>
                <label>Label Background Color</label>
                <input clrInput placeholder="" name="labelbackgroundcolor" type="text"
                  [(ngModel)]="selectedLine.LabelBackgroufColor" />
                <input clrInput placeholder="" name="labelbackgroundcolor2" type="color"
                  [(ngModel)]="selectedLine.LabelBackgroufColor" style="margin-left: 1em;" />
              </clr-input-container>

              <clr-select-container>
                <label>Label Position</label>
                <select clrSelect id="labelposition" name="labelposition" [(ngModel)]="selectedLine.LabelPosition">
                  <option [ngValue]="'center'"> Center </option>
                  <option [ngValue]="'left'"> Left </option>
                  <option [ngValue]="'right'"> Right </option>
                  <option [ngValue]="'bottom'"> Bottom </option>
                  <option [ngValue]="'top'"> Top </option>
                </select>
              </clr-select-container>

            </form>
          </div>
        </div>
      </div>
    </clr-accordion-content>
  </clr-accordion-panel>
  <!-- <clr-accordion-panel>
    <clr-accordion-title>Queried Lines</clr-accordion-title>
    <clr-accordion-content>

      <clr-datagrid [(clrDgSingleSelected)]="selectedQueryLine">
        <clr-dg-column>Name</clr-dg-column>
        <clr-dg-column>Direction</clr-dg-column>
        <clr-dg-column>Y Axis</clr-dg-column>

        <clr-dg-row *ngFor="let line of queriedLineConfigs; let i = index" [clrDgItem]="line">
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="queried_line_name_{{i}}" id="queried_line_name_{{i}}" [(ngModel)]="line.Name" />
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select class="clr-select" name="inputparam" [(ngModel)]="line.Horizontal">
                <option [ngValue]="true"> Horizontal </option>
                <option [ngValue]="false">Vertical</option>
              </select>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select [disabled]="!line.Horizontal" class="clr-select" style="width:5em;" name="yaxisid{{i}}"
                [(ngModel)]="line.AxisId">
                <option [ngValue]="YAxisId.LEFT"> Left </option>
                <option [ngValue]="YAxisId.RIGHT"> Right </option>
              </select>
            </div>
          </clr-dg-cell>

        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addQueriedLine()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add line
      </button>
      <button *ngIf="selectedLine" type="button" class="btn btn-link" (click)="deleteSelectedQueriedLine()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected line
      </button>

      <div *ngIf="selectedQueryLine" style="max-width: 600px;">
        <div class="card">
          <div class="card-header">
            Line properties
          </div>

          <div class="card-block">
            <form clrForm>
              <clr-select-container>
                <label>Position</label>
                <select clrSelect id="qposition" name="position" [(ngModel)]="selectedQueryLine.Infront">
                  <option [ngValue]="true"> Front of </option>
                  <option [ngValue]="false">Behind</option>
                </select>
              </clr-select-container>

              <clr-input-container>
                <label>Line Color</label>
                <input clrInput placeholder="" name="qlinecolor" type="text" [(ngModel)]="selectedQueryLine.Color" />
                <input clrInput placeholder="" name="qlinecolor2" type="color" [(ngModel)]="selectedQueryLine.Color"
                  style="margin-left: 1em;" />
              </clr-input-container>

              <clr-input-container>
                <label>Line width</label>
                <input clrInput placeholder="" type="number" name="qlinewidth" min="1" max="1000"
                  [(ngModel)]="selectedQueryLine.Width" />
              </clr-input-container>

              <clr-input-container>
                <label>Line dash (line / gap)/label</label>
                <input clrInput placeholder="" type="number" name="qlinedash1" min="0" max="1000"
                  [(ngModel)]="selectedQueryLine.LineDash[0]" />
                <input clrInput placeholder="" type="number" name="qlinedash2" min="0" max="1000"
                  [(ngModel)]="selectedQueryLine.LineDash[1]" />
              </clr-input-container>

              <clr-checkbox-container>
                <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox name="qShowLabel" [(ngModel)]="selectedQueryLine.ShowLabel" />
                  <label>Show label</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>

              <clr-input-container>
                <label>Label Background Color</label>
                <input clrInput placeholder="" name="qlabelbackgroundcolor" type="text"
                  [(ngModel)]="selectedQueryLine.LabelBackgroufColor" />
                <input clrInput placeholder="" name="qlabelbackgroundcolor2" type="color"
                  [(ngModel)]="selectedQueryLine.LabelBackgroufColor" style="margin-left: 1em;" />
              </clr-input-container>

              <clr-select-container>
                <label>Label Position</label>
                <select clrSelect id="qlabelposition" name="qlabelposition" [(ngModel)]="selectedQueryLine.LabelPosition">
                  <option [ngValue]="'center'"> Center </option>
                  <option [ngValue]="'left'"> Left </option>
                  <option [ngValue]="'right'"> Right </option>
                  <option [ngValue]="'bottom'"> Bottom </option>
                  <option [ngValue]="'top'"> Top </option>
                </select>
              </clr-select-container>

            </form>


            !-- (save)="onPreQueryConfigSaved($event)" --
            <xproj-widget-prequery-config
            [widgetQuery]="selectedQueryLine.Query" [projections]="projections" [widgets]="widgets">
          </xproj-widget-prequery-config>
          </div>
        </div>
      </div>
    </clr-accordion-content>
  </clr-accordion-panel>   -->
</clr-accordion>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Streaming</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.Streaming.Enabled" name="streamingenabled" />
            <label>Enabled</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.Streaming.ShowPlayPauseButton"
              name="streamingplaypausebutton" />
            <label>Play/Pause button</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-input-container>
          <label>Refresh (ms)</label>
          <input clrInput placeholder="" type="number" name="streamingrefresh" min="0" max="9999999999"
            [(ngModel)]="widgetConfig.Streaming.Refresh" />
        </clr-input-container>

        <clr-input-container>
          <label>Duration (ms)</label>
          <input clrInput placeholder="" type="number" name="streamingduration" min="10" max="9999999999"
            [(ngModel)]="widgetConfig.Streaming.Duration" />
        </clr-input-container>

        <clr-input-container>
          <label>Delay (ms)</label>
          <input clrInput placeholder="" type="number" name="streamingdelay" min="0" max="9999999999"
            [(ngModel)]="widgetConfig.Streaming.Delay" />
        </clr-input-container>

        <clr-input-container>
          <label>TTL (ms)</label>
          <input clrInput placeholder="" type="number" name="streamingttl" min="0" max="9999999999"
            [(ngModel)]="widgetConfig.Streaming.Ttl" />
        </clr-input-container>

        <clr-input-container>
          <label>Frame rate</label>
          <input clrInput placeholder="" type="number" name="streamingframerate" min="1" max="1000"
            [(ngModel)]="widgetConfig.Streaming.FrameRate" />
        </clr-input-container>

        <!-- <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.Streaming.AddOnlyNewValues" name="streamingeaddonlynewvalues" />
            <label>Add only new values</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container> -->
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Graph options</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.DisableZoom" name="disablezoom" />
            <label>Disable zoom</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.GroupQueriesByName"
              name="groupqueriesbyname" />
            <label>Group queries by name</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-input-container>
          <label>Colorscheme</label>
          <input clrInput placeholder="" type="text" name="colorscheme" [(ngModel)]="widgetConfig.ColorScheme" />
        </clr-input-container>

        <clr-select-container>
          <label>Left YAxis type/scale</label>
          <select clrSelect name="leftyaxistype" [(ngModel)]="widgetConfig.LeftYAxisType">
            <option value="linear"> Linear </option>
            <option value="logarithmic"> Logarithmic </option>
          </select>
        </clr-select-container>

        <clr-select-container>
          <label>Right YAxis type/scale</label>
          <select clrSelect name="rightyaxistype" [(ngModel)]="widgetConfig.RightYAxisType">
            <option value="linear"> Linear </option>
            <option value="logarithmic"> Logarithmic </option>
          </select>
        </clr-select-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.LegendShow" name="legendshow" />
            <label>Show legend</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-select-container>
          <label>Legend position</label>
          <select clrSelect name="legendposition" [(ngModel)]="widgetConfig.LegendPosition">
            <option [ngValue]="LegendPosition.LEFT"> Left </option>
            <option [ngValue]="LegendPosition.TOP"> Top </option>
            <option [ngValue]="LegendPosition.RIGHT"> Right </option>
            <option [ngValue]="LegendPosition.BOTTOM"> Bottom </option>
          </select>
        </clr-select-container>

        <clr-select-container>
          <label>Legend alignment</label>
          <select clrSelect name="legendalignment" [(ngModel)]="widgetConfig.LegendAlignment">
            <option [ngValue]="LegendAlignment.START"> Start </option>
            <option [ngValue]="LegendAlignment.CENTER"> Center </option>
            <option [ngValue]="LegendAlignment.END"> End </option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label>Animations (ms)</label>
          <input clrInput placeholder="" type="number" name="animationsms" min="0" max="100000"
            [(ngModel)]="widgetConfig.AnimationsMs" />
        </clr-input-container>

        <clr-select-container>
          <label>Animations easing function</label>
          <select clrSelect name="animationseeasing" [(ngModel)]="widgetConfig.AnimationsEasing">
            <option [ngValue]="'linear'"> linear </option>
            <option [ngValue]="'easeInQuad'"> easeInQuad </option>
            <option [ngValue]="'easeOutQuad'"> easeOutQuad </option>
            <option [ngValue]="'easeInOutQuad'"> easeInOutQuad </option>
            <option [ngValue]="'easeInCubic'"> easeInCubic </option>
            <option [ngValue]="'easeOutCubic'"> easeOutCubic </option>
            <option [ngValue]="'easeInOutCubic'"> easeInOutCubic </option>
            <option [ngValue]="'easeInQuart'"> easeInQuart </option>
            <option [ngValue]="'easeOutQuart'"> easeOutQuart </option>
            <option [ngValue]="'easeInOutQuart'"> easeInOutQuart </option>
            <option [ngValue]="'easeInQuint'"> easeInQuint </option>
            <option [ngValue]="'easeOutQuint'"> easeOutQuint </option>
            <option [ngValue]="'easeInOutQuint'"> easeInOutQuint </option>
            <option [ngValue]="'easeInSine'"> easeInSine </option>
            <option [ngValue]="'easeOutSine'"> easeOutSine </option>
            <option [ngValue]="'easeInOutSine'"> easeInOutSine </option>
            <option [ngValue]="'easeInExpo'"> easeInExpo </option>
            <option [ngValue]="'easeOutExpo'"> easeOutExpo </option>
            <option [ngValue]="'easeInOutExpo'"> easeInOutExpo </option>
            <option [ngValue]="'easeInCirc'"> easeInCirc </option>
            <option [ngValue]="'easeOutCirc'"> easeOutCirc </option>
            <option [ngValue]="'easeInOutCirc'"> easeInOutCirc </option>
            <option [ngValue]="'easeInElastic'"> easeInElastic </option>
            <option [ngValue]="'easeOutElastic'"> easeOutElastic </option>
            <option [ngValue]="'easeInOutElastic'"> easeInOutElastic </option>
            <option [ngValue]="'easeInBack'"> easeInBack </option>
            <option [ngValue]="'easeOutBack'"> easeOutBack </option>
            <option [ngValue]="'easeInOutBack'"> easeInOutBack </option>
            <option [ngValue]="'easeInBounce'"> easeInBounce </option>
            <option [ngValue]="'easeOutBounce'"> easeOutBounce </option>
            <option [ngValue]="'easeInOutBounce'"> easeInOutBounce </option>
          </select>
        </clr-select-container>

        <clr-select-container>
          <label>Interaction mode</label>
          <select clrSelect name="interactionmode" [(ngModel)]="widgetConfig.InteractionMode">
            <option [ngValue]="'point'"> Point </option>
            <option [ngValue]="'nearest'"> Nearest </option>
            <option [ngValue]="'index'"> Index </option>
            <option [ngValue]="'dataset'"> Dataset </option>
            <option [ngValue]="'X'"> X </option>
            <option [ngValue]="'y'"> Y </option>
          </select>
        </clr-select-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.InteractionIntersect"
              name="interactionintersect" />
            <label>Interaction Intersect</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-select-container>
          <label>Interaction axis</label>
          <select clrSelect name="interactionaxis" [(ngModel)]="widgetConfig.InteractionAxis">
            <option [ngValue]="''"> Default </option>
            <option [ngValue]="'X'"> X </option>
            <option [ngValue]="'Y'"> Y </option>
            <option [ngValue]="'xy'"> XY </option>
            <option [ngValue]="'r'"> R </option>
          </select>
        </clr-select-container>

        <clr-toggle-container>
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="useminmaxleft"
              [(ngModel)]="widgetConfig.MinMaxSettings.UseMinMaxLeft" />
            <label>Set Min/Max Left Y</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        <clr-input-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxLeft">
          <label>Min Left Y</label>
          <input clrInput placeholder="" type="number" name="minleft" min="-100000000" max="100000000"
            [(ngModel)]="widgetConfig.MinMaxSettings.MinLeft" />
        </clr-input-container>
        <clr-input-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxLeft">
          <label>Max Left Y</label>
          <input clrInput placeholder="" type="number" name="maxleft" min="-100000000" max="100000000"
            [(ngModel)]="widgetConfig.MinMaxSettings.MaxLeft" />
        </clr-input-container>
        <clr-checkbox-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxLeft">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="suggestedminmaxleft"
              [(ngModel)]="widgetConfig.MinMaxSettings.SuggestedMinMaxLeft" />
            <label>Suggested Min/Max Left Y</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-toggle-container>
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="useminmaxright"
              [(ngModel)]="widgetConfig.MinMaxSettings.UseMinMaxRight" />
            <label>Set Min/Max Right Y</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        <clr-input-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxRight">
          <label>Min Right Y</label>
          <input clrInput placeholder="" type="number" name="minright" min="-100000000" max="100000000"
            [(ngModel)]="widgetConfig.MinMaxSettings.MinRight" />
        </clr-input-container>
        <clr-input-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxRight">
          <label>Max Right Y</label>
          <input clrInput placeholder="" type="number" name="maxright" min="-100000000" max="100000000"
            [(ngModel)]="widgetConfig.MinMaxSettings.MaxRight" />
        </clr-input-container>
        <clr-checkbox-container *ngIf="widgetConfig.MinMaxSettings.UseMinMaxRight">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="suggestedminmaxright"
              [(ngModel)]="widgetConfig.MinMaxSettings.SuggestedMinMaxRight" />
            <label>Suggested Min/Max Right Y</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
  <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
    <clr-accordion-title>{{configQuery.Name}}</clr-accordion-title>
    <clr-accordion-content>
      <xproj-chart-widget-query-config [widgetQuery]="configQuery" [projections]="projections"
        [widgetConfig]="widgetConfig" [inputParameters]="widgetConfig.InputParameters" [widgets]="widgets"
        (onQueryRemoved)="onQueryRemoved($event)" (onDuplicateQuery)="onDuplicateQuery($event)">
      </xproj-chart-widget-query-config>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addConfigQuery();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>
