<div class="table-widget" tabindex="1" [style.height.px]="widgetheight" *ngIf="tableData.initilized">
  <clr-datagrid *ngIf="!widgetConfig?.ClientPagination"
    [class]="widgetConfig?.Compact ? 'table-datagrid datagrid-compact' : 'table-datagrid'"
    (clrDgRefresh)="refreshQueryTableSetState($event)" [clrDgLoading]="loading">

    <ng-template ngFor let-item [ngForOf]="tableData.columns">
      <clr-dg-column *ngIf="!item.excluded"  [clrDgField]="item.filterEnabled ? item.columnname : ''"
        [clrDgSortBy]="(!item.filterEnabled && item.sortEnabled) ? item.columnname : undefined">
        <ng-container *clrDgHideableColumn="{hidden: item.hidden}">
          {{item.label}}
        </ng-container>
        <clr-dg-filter *ngIf="item.datatype == 7 && item.filterEnabled">
          <xproj-datagrid-string-filter [group]="item.group" [projectionid]="item.projectionid"
            [columnname]="item.columnfiltername"></xproj-datagrid-string-filter>
        </clr-dg-filter>
      </clr-dg-column>
    </ng-template>

    <clr-dg-column *ngIf="widgetConfig.ButtonConfigs?.length > 0">
    </clr-dg-column>

    <clr-dg-placeholder>{{widgetConfig.EmptyTableText}}</clr-dg-placeholder>

    <clr-dg-row *ngFor="let dataValue of tableData.dataValues ; index as i" [clrDgItem]="dataValue"
      (click)="onCellClick($event, i)"  [ngClass]="{'table-selected' : selectedIndex == i}">
      <ng-template ngFor let-item [ngForOf]="tableData.columns">
        <clr-dg-cell *ngIf="!item.excluded" [ngClass]="{'sensitive': item.issensitive, 'monospaced': item.monospacedfont}"
          [ngStyle]="{'cursor': item.clickable ? 'pointer' : 'auto', 'background-color': item.colors[i], 'border-radius': item.colors[i].length > 0 ? '15px' : '0px', 'margin': item.colors[i].length > 0 ? '2px 2px 2px 2px' : '0 0 0 0'}">
          <span *ngIf="!item.url" [ngStyle]="{'cursor': item.clickable ? 'pointer' : 'auto'}">{{item | tabledata :
            dataValue}}</span>
          <a *ngIf="item.url" href="{{ item | tabledata : dataValue}}" target="_blank">{{ item | tabledata :
            dataValue}}</a>
        </clr-dg-cell>
      </ng-template>

      <clr-dg-cell [style.cursor]="'auto'" *ngIf="widgetConfig.ButtonConfigs?.length > 0">
        <button *ngFor="let btn of widgetConfig.ButtonConfigs" class="btn btn-sm" (click)="runButtonLuaScript(btn, i)">
          {{btn.Name}}</button>
        <!-- <clr-button-group class="btn btn-sm">
          <clr-button *ngFor="let btn of widgetConfig.ButtonConfigs" (click)="runButtonLuaScript(btn, i)">
            {{btn.Name}}</clr-button>
        </clr-button-group> -->
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination id="pagination" [clrDgPageSize]="pageSize">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions" size="xs">Rows per page</clr-dg-page-size>
        <div class="pagination-list" *ngIf="currentPage > 1 || queryNrRows >= pageSize">
          <button type="button" class="pagination-first" [disabled]="currentPage <= 1" (click)="stepFirst()">
            <cds-icon shape="step-forward-2" direction="down"></cds-icon>
          </button>
          <button type="button" class="pagination-previous" [disabled]="currentPage <= 1" (click)="stepPrevious()">
            <cds-icon shape="angle" direction="left"></cds-icon>
          </button>
          <input #currentPageInput type="text" class="pagination-current clr-input"
            [size]="currentPage.toString().length" [value]="currentPage" (keydown.enter)="updateCurrentPage($event)"
            (blur)="updateCurrentPage($event)" />
          <ng-template #readOnly>
            <span>{{ currentPage }}</span>
          </ng-template>

          <button type="button" class="pagination-next" [disabled]="currentPage > 1 && queryNrRows < pageSize"
            (click)="stepNext()">
            <cds-icon shape="angle" direction="right"></cds-icon>
          </button>
        </div>
      </clr-dg-pagination>
    </clr-dg-footer>

  </clr-datagrid>

  <clr-datagrid *ngIf="widgetConfig?.ClientPagination"
    [class]="widgetConfig?.Compact ? 'table-datagrid datagrid-compact' : 'table-datagrid'"
    (clrDgRefresh)="refreshQueryTableSetState($event)" [clrDgLoading]="loading">

    <ng-template ngFor let-item [ngForOf]="tableData.columns">
      <clr-dg-column *ngIf="!item.excluded" [clrDgField]="item.filterEnabled ? item.columnname : ''"
        [clrDgField]="item.columnname"
        [clrDgSortBy]="(!item.filterEnabled && item.sortEnabled) ? item.columnname : item.stringNumberIdSort"
        [clrDgSortOrder]="item.sortOrder">
        <ng-container *clrDgHideableColumn="{hidden: item.hidden}">
          {{item.label}}
        </ng-container>
      </clr-dg-column>
    </ng-template>

    <clr-dg-column *ngIf="widgetConfig.ButtonConfigs?.length > 0">
    </clr-dg-column>

    <clr-dg-placeholder>{{widgetConfig.EmptyTableText}}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let dataValue of tableData.dataValues; let i = index" [clrDgItem]="dataValue"
      (click)="onCellClick($event, i, dataValue)" [ngClass]="{'table-selected' : selectedIndex == i}">
      <ng-template ngFor let-item [ngForOf]="tableData.columns">
        <clr-dg-cell *ngIf="!item.excluded" [ngClass]="{'sensitive': item.issensitive, 'monospaced': item.monospacedfont}"
          [ngStyle]="{'cursor': item.clickable ? 'pointer' : 'auto', 'background-color': item.colors[i], 'border-radius': item.colors[i].length > 0 ? '15px' : '0px', 'margin': item.colors[i].length > 0 ? '2px 2px 2px 2px' : '0 0 0 0'}">
          <span *ngIf="!item.url" [ngStyle]="{'cursor': item.clickable ? 'pointer' : 'auto'}">{{item | tabledata :
            dataValue}}</span>
          <a *ngIf="item.url" href="{{ item | tabledata : dataValue}}" target="_blank">{{ item | tabledata :
            dataValue}}</a>
        </clr-dg-cell>
      </ng-template>
      <clr-dg-cell [style.cursor]="'auto'" *ngIf="widgetConfig.ButtonConfigs?.length > 0">
        <button *ngFor="let btn of widgetConfig.ButtonConfigs" class="btn btn-sm" (click)="runButtonLuaScript(btn, i)">
          {{btn.Name}}</button>
        <!-- <clr-button-group class="btn btn-sm">
            <clr-button *ngFor="let btn of widgetConfig.ButtonConfigs" (click)="runButtonLuaScript(btn, i)">
              {{btn.Name}}</clr-button>
          </clr-button-group> -->
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination id="pagination" [clrDgPageSize]="pageSize">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Rows per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
      </clr-dg-pagination>
    </clr-dg-footer>

  </clr-datagrid>
</div>

<div class="table-widget" tabindex="1" [style.height.px]="widgetheight" *ngIf="!tableData.initilized && tableDataEmpty">
  <clr-datagrid [class]="widgetConfig?.Compact ? 'table-datagrid datagrid-compact' : 'table-datagrid'">

    <ng-template ngFor let-label [ngForOf]="tableDataEmpty">
      <clr-dg-column>
        {{label}}
      </clr-dg-column>
    </ng-template>
    <clr-dg-placeholder>{{widgetConfig.EmptyTableText}}</clr-dg-placeholder>
  </clr-datagrid>
</div>
