import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface MediaQueryPayload {
  readonly matches: boolean;
  readonly media: string;
}

@Injectable()
export class XprojMediaService implements OnDestroy {

  private listenerCleanup: () => void;

  private matches = new ReplaySubject<boolean>(1);
  public match$ = this.matches.asObservable();

  ngOnDestroy() {
    this.cleanup();
  }

  protected cleanup() {
    if (this.listenerCleanup) {
      this.listenerCleanup();
    }
  }

  protected attachListener(query: string, listener: (event: MediaQueryPayload) => void): void {
    const mediaQueryList = window.matchMedia(query);
    this.addListener(mediaQueryList, listener);
    this.listenerCleanup = () => this.removeListener(mediaQueryList, listener);
    // trigger initial
    listener(mediaQueryList);
  }

  protected addListener(mql: MediaQueryList, listener: (event: MediaQueryPayload) => void): void {
    mql.addEventListener
      ? mql.addEventListener('change', listener)
      // add deprecated listeners for fallback
      : mql.addListener(listener);
  }

  protected removeListener(mql: MediaQueryList, listener: (event: MediaQueryPayload) => void): void {
    mql.removeEventListener
      ? mql.removeEventListener('change', listener)
      // add deprecated remove listeners for fallback
      : mql.removeListener(listener);
  }

  setQuery(query: string) {
    if (!query) {
      throw new Error('Media query string must be provided');
    }
    if (window) {
      const listener = (event: MediaQueryPayload) => this.matches.next(event.matches);
      this.attachListener(query, listener);
    } else {
      this.matches.complete();
    }
  }
}
