<clr-dropdown>
    <button class="btn btn-outline-primary" clrDropdownTrigger>
    {{Displayname()}}
    <clr-icon shape="times" (click)="removeFilter()"></clr-icon>
  </button>
    <clr-dropdown-menu>
        <div aria-hidden="true">
            <h4 class="dropdown-header">Edit data filter:</h4>
        </div>
        <form clrForm clrLayout="compact" style="margin-left: 1em; width:600px;">
            <clr-select-container>
                <label>Column</label>
                <select clrSelect name="column" [(ngModel)]="datafilter.ColumnDescriptor">
          <option *ngFor="let col of columnDescriptions" [ngValue]="col">{{col.columnname}}</option>
        </select>
            </clr-select-container>
            <clr-select-container>
                <label>Comparator</label>
                <select clrSelect name="comp" [(ngModel)]="datafilter.Comparator">
          <option *ngFor="let c of comparatorKeys" [value]="c.value">{{ c.title }}</option>
        </select>
            </clr-select-container>
            <div *ngIf="datafilter.ColumnDescriptor">

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6">
                    <label>Filter type</label>
                    <select clrSelect id="filtertype" name="filtertype" [(ngModel)]="datafilter.DateTypeRelative">
            <option [ngValue]="false"> Fixed </option>
            <option [ngValue]="true"> Relative </option>
          </select>
                </clr-select-container>

                <clr-select-container *ngIf="inputParameters.length > 0 && !datafilter.DateTypeRelative">
                    <label>Input parameter </label>
                    <select clrSelect name="inputparam" [(ngModel)]="datafilter.InputParameterId">
            <option value=""> </option>
            <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [datafilter.ColumnDescriptor.datatype]" [ngValue]="input.id">
              {{input.id}}</option>
          </select>
                </clr-select-container>

                <clr-datalist-container *ngIf="datafilter.ColumnDescriptor.datatype == 7 && (!datafilter.InputParameterId || datafilter.InputParameterId.length == 0)">
                    <label>Fixed value</label>
                    <input clrDatalistInput placeholder="" name="stringinput" [(ngModel)]="datafilter.Value" (ngModelChange)="onSearchValueChange($event)" />
                    <datalist>
            <option *ngFor="let item of existingStrings" [value]="item"></option>
          </datalist>
                </clr-datalist-container>
                <clr-date-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && !datafilter.DateTypeRelative && (!datafilter.InputParameterId || datafilter.InputParameterId.length == 0)">
                    <label>Value</label>
                    <input type="date" clrDate name="dateinput" [(clrDate)]="datafilter.DateValue">
                </clr-date-container>
                <clr-input-container *ngIf="datafilter.ColumnDescriptor.datatype < 6 && (!datafilter.InputParameterId || datafilter.InputParameterId.length == 0)">
                    <label>Value</label>
                    <input type="number" clrInput placeholder="" name="numberinput" [(ngModel)]="datafilter.NumericValue" />
                </clr-input-container>

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && inputParameters.length > 0 && datafilter.DateTypeRelative">
                    <label>Input origo</label>
                    <select clrSelect name="inputparamrelativeorigo" [(ngModel)]="datafilter.InputParameterId2">
            <option value=""> </option>
            <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [105]" [ngValue]="input.id">{{input.id}}</option>
          </select>
                </clr-select-container>

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && datafilter.DateTypeRelative && (!datafilter.InputParameterId2 || datafilter.InputParameterId2.length == 0)">
                    <label>Origo</label>
                    <select clrSelect name="origo" [(ngModel)]="datafilter.DateRelativeOrigo">
            <option [ngValue]="RelativeTimestampOrigo.NOW"> Now </option>
            <option [ngValue]="RelativeTimestampOrigo.NOWUTC"> Utc Now </option>
            <option [ngValue]="RelativeTimestampOrigo.EXACT"> Exact </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_YEAR"> Beginning of this year </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MONTH"> Beginning of this month </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_DAY"> Beginning of this day </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_HOUR"> Beginning of this hour </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MINUTE"> Beginning of this minute </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_YEARUTC"> UTC Beginning of this year </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MONTHUTC"> UTC Beginning of this month </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_DAYUTC"> UTC Beginning of this day </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_HOURUTC"> UTC Beginning of this hour </option>
            <option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MINUTEUTC"> UTC Beginning of this minute </option>
          </select>
                </clr-select-container>

                <clr-date-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && datafilter.DateTypeRelative && datafilter.DateRelativeOrigo == RelativeTimestampOrigo.EXACT">
                    <label>Exact origo</label>
                    <input type="date" clrDate name="exactorigo" [(ngModel)]="datafilter.DateValue">
                </clr-date-container>

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && inputParameters.length > 0 && datafilter.DateTypeRelative">
                    <label>Input offs.</label>
                    <select clrSelect name="inputparamrelative" [(ngModel)]="datafilter.InputParameterId">
            <option value=""> </option>
            <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [1]" [ngValue]="input.id">{{input.id}}</option>
          </select>
                </clr-select-container>

                <clr-input-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && datafilter.DateTypeRelative && (!datafilter.InputParameterId || datafilter.InputParameterId.length == 0)">
                    <label>Offset</label>
                    <input type="number" clrInput placeholder="" name="numberinputrelative" [(ngModel)]="datafilter.DateRelativeOffet" />
                </clr-input-container>

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && inputParameters.length > 0 && datafilter.DateTypeRelative">
                    <label>Input period</label>
                    <select clrSelect name="inputparamrelativetype" [(ngModel)]="datafilter.InputParameterId3">
            <option value=""> </option>
            <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [106]" [ngValue]="input.id">{{input.id}}</option>
          </select>
                </clr-select-container>

                <clr-select-container *ngIf="datafilter.ColumnDescriptor.datatype == 6 && datafilter.DateTypeRelative && (!datafilter.InputParameterId3 || datafilter.InputParameterId3.length == 0)">
                    <label>Period</label>
                    <select clrSelect name="offsettyperelative" [(ngModel)]="datafilter.RelativeOffsettype">
            <option [ngValue]="OffsetType.YEAR"> Year </option>
            <option [ngValue]="OffsetType.MONTH"> Month </option>
            <option [ngValue]="OffsetType.DAY"> Day </option>
            <option [ngValue]="OffsetType.HOUR"> Hour </option>
            <option [ngValue]="OffsetType.MINUTE"> Minute </option>
            <option [ngValue]="OffsetType.SECOND"> Second </option>
          </select>
                </clr-select-container>

            </div>
        </form>
    </clr-dropdown-menu>
</clr-dropdown>