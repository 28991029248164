import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { GetTicketInfoRequest, SetCredentialsFromTicketRequest, GetTicketInfoResponse } from './proto/xprojector.credentials.pb';
import { CredentialsClient } from './proto/xprojector.credentials.pbsc'
import { BasicResponse, DataChunk } from './proto/xprojector.grpc.models.pb';


@Injectable({
  providedIn: 'root'
})
export class XProjectorCredentialsClient implements OnInit, OnDestroy {

  constructor(
    private credentialsClient: CredentialsClient) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async setCredentialsFromTicketRequest(ticket : string, password : string, firstname : string, surname : string) : Promise<BasicResponse> {
    let request: SetCredentialsFromTicketRequest = new SetCredentialsFromTicketRequest();
    request.ticket = ticket;
    request.password = password;
    request.firstname = firstname;
    request.surname = surname;

    return this.credentialsClient.setCredentialsFromTicket(request).toPromise();
  }

  async getTicketInfo(ticket : string) : Promise<GetTicketInfoResponse> {
    let request: GetTicketInfoRequest = new GetTicketInfoRequest();
    request.ticket = ticket;

    return this.credentialsClient.getTicketInfo(request).toPromise();
  }

}
