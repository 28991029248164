import { TreeviewItem } from "./treeview-item";
import { TreeNode } from "./tree-node";
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';


@jsonObject
export class Treeview implements TreeNode {
  @jsonMember
  public id : string;

  @jsonMember
  public name : string;

  @jsonMember
  public descritpion : string;

  @jsonArrayMember(TreeviewItem)
  public children : TreeviewItem[] = [];

  public parent = null;

  public selectedNode : TreeNode;
}

@jsonObject
export class Treeviews {
  @jsonArrayMember(Treeview)
  public treeviews : Treeview[] = [];
}
