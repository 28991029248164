<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->

    <div class="splash-container">
        <div class="splash-inner">
            <h1>Digital infrastructure</h1>
            <p style="margin-top:1em;clear: both;color:white; max-width: 40em;">
                We are a dedicated engineering company delivering systems,
                products that form the digital infrastructure of tomorrow. <br> <br>
                Since our establishment in 2020, we have been delivering enterprise 
                and 2b2c systems and solutions to our customers that modernise 
                their data governance and accelerate their data-driven operations.
            </p>
            <a  routerLink="/about" class="btn btn-primary">More about us</a>
        </div>
    </div>

    <div class="content">
        <div>

            <p style="font-size: larger; padding-top: 2em; padding-bottom: 2em;">
                Discover Rossaker solutions, systems and products, build digital infrastructure with us for sustainable industry and society. 
            </p>

        <div class="clr-row">
            
            <div class="clr-col-lg-4 clr-col-12">
                
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Solutions</h3>
                        <div class="card-text">
                            We provide solutions for your sector, such as 
                            <ul>
                                <li>Sustainable building</li>
                                <li>Energy efficiency and cost saving</li>
                                <li>Smart manufacturing</li>
                                <li>Digital O&M and optimisation</li>
                            </ul>
                            Our solutions handle your challenges, such as
                            <ul>
                                <li>Metering</li>
                                <li>IoT & communication  </li>
                                <li>Data pipelining & data cleaning</li>
                                <li>Data visualization and analysis</li>
                                <li>Machine learning</li>
                                <li>Cost effective AI model deployment</li>
                            </ul>                            
                        </div>
                    </div>
                    <div class="card-footer">
                        <a routerLink="/solutions" class="btn btn-sm btn-link">More about our solutions</a>
                    </div>
                </div>                
            </div>

            <div class="clr-col-lg-4 clr-col-12">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Systems</h3>
                        <div class="card-text">
                            Explore our software systems that meet tomorrow's needs on big data, automation and communications. 
                            <ul >
                                <li>Big Data Analysis</li>
                                <li>Reporting</li>
                                <li>SCADA</li>
                                <li>De-coupled and de-centralized automation</li>
                                <li>Centralized automation</li>
                                <li>Industrial IoT</li>
                                <li>Communications</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-footer">
                        <a routerLink="/systems" class="btn btn-sm btn-link">More about our systems</a>
                    </div>
                </div> 
            </div>

            <div class="clr-col-lg-4 clr-col-12">

                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Products</h3>
                        <div class="card-text">
                            Explore our off-the-shelf software-hardware bundled solutions  

                            <ul>
                                <li>PLC Controller units</li>
                                <li>Big-Data edge units</li>
                                <li>Communication - edge and gateways</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-footer">
                        <a routerLink="/products" class="btn btn-sm btn-link">More about our products</a>
                    </div>
                </div> 

            </div>            
        </div>               
    </div>

</div>