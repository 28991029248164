import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ColorHelper {

  private classic20 = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];


  public GetColor(colorScheme : string, index : number, colors : {background : string, border : string}) : {background : string, border : string} {
    if (colorScheme == 'tableau.Classic20') {
      let length = this.classic20.length;
      let color = this.classic20[(index ?? 0) % length];

      return {background : color, border : color};
    }
    else {
      return colors;
    }
  }

  randomColor() : string {
    let randomize = Math.floor(Math.random() * 16777215).toString(16);
    return randomize;
  }

  // private *hueGen() {
  //   yield 0;
  //   for (let i = 1; i < 10; i++) {
  //     const d = 1 << i;
  //     for (let j = 1; j <= d; j += 2) {
  //       yield j / d;
  //     }
  //   }
  // }

  // private *colorGen() {
  //   let hue = this.hueGen();
  //   let h = hue.next();
  //   while (!h.done) {
  //     let rgb = hsv2rgb(Math.round(h.value * 360), 0.6, 0.8);
  //     yield {background: rgbString({r: rgb[0], g: rgb[1], b: rgb[2], a: 192}), border: rgbString({r: rgb[0], g: rgb[1], b: rgb[2], a: 144})};
  //     rgb = hsv2rgb(Math.round(h.value * 360), 0.6, 0.5);
  //     yield {background: rgbString({r: rgb[0], g: rgb[1], b: rgb[2], a: 192}), border: rgbString({r: rgb[0], g: rgb[1], b: rgb[2], a: 144})};
  //     h = hue.next();
  //   }
  // }

}
