import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { YAxisId } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { GroupSelectionTypes, LegendAlignment, LegendPosition, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { PiechartColumnConfig, PieChartType, PiechartWidgetConfig, PiechartWidgetQuery } from './piechart-widget-config-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { XprojPiechartWidgetQueryConfigComponent } from '../piechart-widget-query-config/xproj-piechart-widget-query-config.component';
import { Guid } from '../../../utils/guid-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';

@Component({
  selector: 'xproj-piechart-widget-config',
  templateUrl: './xproj-piechart-widget-config.component.html',
  styleUrls: ['./xproj-piechart-widget-config.component.scss']
})
export class XprojPiechartWidgetConfigComponent extends WidgetConfigBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(XprojPiechartWidgetQueryConfigComponent, { read: XprojPiechartWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojPiechartWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: PiechartWidgetConfig;

  editConfig: PiechartWidgetConfig;
  projections: Projection[] = [];

  Transformation = Transformation;
  Aggregation = Aggregation;
  PieChartType = PieChartType;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  LegendPosition = LegendPosition;
  LegendAlignment = LegendAlignment;

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as PiechartWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();

    if (this.editConfig.ConfigQueries.length == 0) {
      this.editConfig.ConfigQueries.push(this.editConfig.ConfigQuery)
      this.editConfig.ConfigQuery = null;
    }

    let i = 1;
    this.editConfig.ConfigQueries.forEach(q => {
      if (q.Name.length == 0) {
        q.Name = 'Query ' + i;
      }
      i++;
    });
  }

  async ngAfterViewInit() {
    this.updateInputParameters();
  }

  addConfigQuery(): void {
    this.editConfig.ConfigQueries.push(new PiechartWidgetQuery());
  }

  onQueryRemoved(query: PiechartWidgetQuery) {
    this.editConfig.ConfigQueries = this.editConfig.ConfigQueries.filter(q => q != query);
  }

  onDuplicateQuery(query: PiechartWidgetQuery) {
    let newQuery  = TypedJSON.parse(TypedJSON.stringify(query, PiechartWidgetQuery), PiechartWidgetQuery);
    newQuery.Id = Guid.newGuid();
    newQuery.Name = 'Query ' + (this.editConfig.ConfigQueries.length + 1);
    this.editConfig.ConfigQueries.push(newQuery);
  }

  async onSaveConfig() {
    await ArrayUtils.AsyncForEach(this.widgetQueryConfigs.toArray(), async (queryConfig) => {
      await queryConfig.SaveQuery();
    });

    if (this.editConfig.ConfigQueries.length > 0) {
      if (this.widgetConfig.OutputParameters.length == 0) {
        this.widgetConfig.OutputParameters.push(new WidgetOutputParameter());
      }

      let outputParam = this.widgetConfig.OutputParameters[0];
      outputParam.datatype = OutputDataType.String;
      outputParam.name = this.editConfig.ConfigQueries[0].LabelConfig.ColumnName;
    }

    this.widgetConfig.update(this.editConfig);
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }
}
