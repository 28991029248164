import { Pipe, PipeTransform } from '@angular/core';
import { EventHistory } from '@core/xprojector_backend/proto/xprojector.modulerossakerbms.events.pb';

@Pipe({
  name: 'rossakerbmscustomereventstatuspipe'
})
export class RossakerBmsCustomerEventStatusPipe implements PipeTransform {

  constructor() {}

  transform(e: EventHistory, ...args: unknown[]): string {

    return  e.muted ? $localize `:@@customerevents_muted:Muted` : e.active ? (e.acknowledged ? $localize `:@@customerevents_open:Open` : $localize `:@@customerevents_new:New`) : $localize `:@@customerevents_closed:Closed`;
  }

}
