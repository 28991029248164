import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export class XprojAlert {
  id: string;
  type: XprojAlertType = XprojAlertType.Info;
  message: string = '';
  autoClose: boolean = true;
  keepAfterRouteChange: boolean = false;
  appLevel : boolean = false;

  constructor(init?:Partial<XprojAlert>) {
      Object.assign(this, init);
  }
}

export enum XprojAlertType {
  Success,
  Error,
  Info,
  Warning
}


@Injectable({
  providedIn: 'root'
})
export class XprojAlertService {

  private subject = new Subject<XprojAlert>();
  private defaultId = 'default-alert';

  onAlert(id = this.defaultId): Observable<XprojAlert> {
      return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  success(message: string, options?: any) {
      this.alert(new XprojAlert({ ...options, type: XprojAlertType.Success, message }));
  }

  error(message: string, options?: any) {
      this.alert(new XprojAlert({ ...options, type: XprojAlertType.Error, message }));
  }

  info(message: string, options?: any) {
      this.alert(new XprojAlert({ ...options, type: XprojAlertType.Info, message }));
  }

  warn(message: string, options?: any) {
      this.alert(new XprojAlert({ ...options, type: XprojAlertType.Warning, message }));
  }

  alert(alert: XprojAlert) {
      alert.id = alert.id || this.defaultId;
      this.subject.next(alert);
  }

  clear(id = this.defaultId) {
      this.subject.next(new XprojAlert({ id }));
  }
}
