import { GrpcNode } from "@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb";
import { GrpcEdgeType } from "@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb";

export abstract class XconfTreeNodeBase {
  children: XconfTreeNode[];
  expanded: boolean;
}

export class XconfTreeview implements XconfTreeNodeBase {
  children: XconfTreeNode[] = [];
  expanded: boolean = false;
  //customerId : string = '';
}

export class XconfTreeNode implements XconfTreeNodeBase {
  node: GrpcNode;
  id: string;
  children: XconfTreeNode[] = null;
  expanded: boolean = false;
  parent: XconfTreeNodeBase;
  edgeType: GrpcEdgeType;
  name: string;
  shape: string = 'node';
  moveEnabled: boolean = false;
  duplicateEnabled: boolean = false;
}
