<xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets"
  [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>
<!-- <form clrForm>
  <clr-input-container>
    <label>Show decimals</label>
    <input clrInput placeholder="2" type="number" name="decimals" [(ngModel)]="config.Decimals" />
  </clr-input-container>
</form> -->
<form clrForm>
  <clr-select-container>
    <label>Label type:</label>
    <select clrSelect name="labelType" id="labelType" [(ngModel)]="editConfig.LabelType">
      <option [ngValue]="LabelWidgetType.LABEL"> Label </option>
      <option [ngValue]="LabelWidgetType.GAUGE"> Gauge </option>
      <option [ngValue]="LabelWidgetType.GAUGE_NEEDLE"> Gauge needle </option>
    </select>
  </clr-select-container>

  <clr-input-container>
    <label>Margin</label>
    <input clrInput type="text" [(ngModel)]="editConfig.Margin" name="margin"
      size="35" />
  </clr-input-container>

  <div *ngIf="editConfig.LabelType == LabelWidgetType.GAUGE_NEEDLE">
    <clr-input-container>
      <label>Text</label>
      <input clrInput style="width: 75%;" placeholder="" name="gaugetext" [(ngModel)]="editConfig.GaugeText" />
    </clr-input-container>

    <h5>Color thresholds</h5>
    <clr-datagrid [(clrDgSingleSelected)]="selectedGaugeThreshold">
      <clr-dg-column>Value</clr-dg-column>
      <clr-dg-column>Color</clr-dg-column>
      <clr-dg-column>Label</clr-dg-column>

      <clr-dg-row *ngFor="let colorthreshold of editConfig.GaugeColorThresholds; let i = index"
        [clrDgItem]="colorthreshold">
        <clr-dg-cell>
          <input class="clr-input" type="number" placeholder="0" name="gaugecolorthreshold_value{{i}}"
            [(ngModel)]="colorthreshold.Value" />
        </clr-dg-cell>
        <clr-dg-cell>
          <input class="clr-input" type="color" placeholder="" name="gaugecolorthreshold_color{{i}}"
            [(ngModel)]="colorthreshold.Color" />
        </clr-dg-cell>
        <clr-dg-cell>
          <input class="clr-input" type="text" placeholder="" name="gaugecolorthreshold_label{{i}}"
            [(ngModel)]="colorthreshold.Label" />
        </clr-dg-cell>
        <!-- <clr-dg-cell>
                <clr-icon (click)="moveThresholdUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
                <clr-icon (click)="moveThresholdDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
                  style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
              </clr-dg-cell> -->
      </clr-dg-row>
    </clr-datagrid>
    <button type="button" class="btn btn-link" (click)="addGaugeThreshold(true)">
      <clr-icon shape="plus-circle"></clr-icon>
      Add threshold
    </button>
    <button *ngIf="selectedGaugeThreshold" type="button" class="btn btn-link" (click)="removeGaugeSelectedThreshold()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected threshold
    </button>
  </div>
  <div *ngIf="editConfig.LabelType == LabelWidgetType.GAUGE">
    <clr-input-container>
      <label>Label</label>
      <input clrInput style="width: 75%;" placeholder="" name="gauge-label"
        [(ngModel)]="editConfig.GaugeSettings.Label" />
    </clr-input-container>

    <clr-select-container>
      <label>Gauge type:</label>
      <select clrSelect name="gauge-type" id="gauge-type" [(ngModel)]="editConfig.GaugeSettings.GaugeType">
        <option [ngValue]="GaugeType.FULL"> Full </option>
        <option [ngValue]="GaugeType.SEMI"> Semi </option>
        <option [ngValue]="GaugeType.ARCH"> Arch </option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Min</label>
      <input clrInput placeholder="" type="number" name="gauge-min" [(ngModel)]="editConfig.GaugeSettings.Min" />
    </clr-input-container>

    <clr-input-container>
      <label>Max</label>
      <input clrInput placeholder="" type="number" name="gauge-max" [(ngModel)]="editConfig.GaugeSettings.Max" />
    </clr-input-container>

    <clr-select-container>
      <label>Cap:</label>
      <select clrSelect name="gauge-cap" id="gauge-cap" [(ngModel)]="editConfig.GaugeSettings.GaugeStyle">
        <option [ngValue]="GaugeStyle.ROUND"> Round </option>
        <option [ngValue]="GaugeStyle.BUTT"> Butt </option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Thick</label>
      <input clrInput placeholder="" type="number" name="gauge-thick" [(ngModel)]="editConfig.GaugeSettings.Thick" />
    </clr-input-container>

    <clr-input-container>
      <label>Foreground color</label>
      <input clrInput placeholder="" name="gauge-foregroundcolor" type="text"
        [(ngModel)]="editConfig.GaugeSettings.ForegroundColor" />
      <input clrInput placeholder="" name="gauge-foregroundcolor2" type="color"
        [(ngModel)]="editConfig.GaugeSettings.ForegroundColor" style="margin-left: 1em;" />
    </clr-input-container>

    <clr-input-container>
      <label>Background color</label>
      <input clrInput placeholder="" name="gauge-backgroundcolor" type="text"
        [(ngModel)]="editConfig.GaugeSettings.BackgroundColor" />
      <input clrInput placeholder="" name="gauge-backgroundcolor2" type="color"
        [(ngModel)]="editConfig.GaugeSettings.BackgroundColor" style="margin-left: 1em;" />
    </clr-input-container>

    <clr-input-container>
      <label>Prepend</label>
      <input clrInput style="width: 75%;" placeholder="" name="gauge-preprend"
        [(ngModel)]="editConfig.GaugeSettings.Prepend" />
    </clr-input-container>

    <clr-input-container>
      <label>Append</label>
      <input clrInput style="width: 75%;" placeholder="" name="gauge-append"
        [(ngModel)]="editConfig.GaugeSettings.Append" />
    </clr-input-container>

    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="gauge-animate" [(ngModel)]="editConfig.GaugeSettings.Animate" />
        <label>Animate</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <h5>Color thresholds</h5>
    <clr-datagrid [(clrDgSingleSelected)]="selectedGaugeThreshold">
      <clr-dg-column>Value</clr-dg-column>
      <clr-dg-column>Color</clr-dg-column>
      <!-- <clr-dg-column>Move</clr-dg-column> -->

      <clr-dg-row *ngFor="let colorthreshold of editConfig.GaugeSettings.Thresholds; let i = index"
        [clrDgItem]="colorthreshold">
        <clr-dg-cell>
          <input class="clr-input" type="number" placeholder="0" name="gauge-colorthreshold_value{{i}}"
            [(ngModel)]="colorthreshold.Value" />
        </clr-dg-cell>
        <clr-dg-cell>
          <input class="clr-input" type="color" placeholder="" name="gauge-colorthreshold_color{{i}}"
            [(ngModel)]="colorthreshold.Color" />
        </clr-dg-cell>
        <!-- <clr-dg-cell>
                <clr-icon (click)="moveThresholdUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
                <clr-icon (click)="moveThresholdDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
                  style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
              </clr-dg-cell> -->
      </clr-dg-row>
    </clr-datagrid>
    <button type="button" class="btn btn-link" (click)="addGaugeThreshold(false)">
      <clr-icon shape="plus-circle"></clr-icon>
      Add threshold
    </button>
    <button *ngIf="selectedGaugeThreshold" type="button" class="btn btn-link" (click)="removeGaugeSelectedThreshold()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected threshold
    </button>
  </div>
</form>
<br />
<clr-accordion>
  <clr-accordion-panel *ngFor="let configQuery of editConfig.ConfigQueries; index as i">
    <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
    <clr-accordion-content>
      <xproj-label-widget-query-config [widgetQuery]="configQuery" [projections]="projections" [widgetConfig]="widgetConfig"
        [inputParameters]="editConfig.InputParameters" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)">
      </xproj-label-widget-query-config>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<button *ngIf="editConfig.LabelType != LabelWidgetType.GAUGE || editConfig.ConfigQueries.length == 0" type="button"
  class="btn btn-link" (click)="addConfigQuery();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>
