import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_MQTTDataFormat, XAUTO_MQTTPayloadEncoding, XAUTO_MQTTVariableEncoding, XAUTO_XAutoDriver, XAUTO_XAutoDriverMqtt, XAUTO_XAutoVariable,  XAUTO_XAutoVariableMqtt, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public MQTT : XAUTO_XAutoVariableMqtt;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public MQTT : XAUTO_XAutoDriverMqtt;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}


@Component({
  selector: 'xproj-editconnectors-mqtt',
  templateUrl: './editconnectors-mqtt.component.html',
  styleUrls: ['./editconnectors-mqtt.component.scss']
})
export class EditconnectorsMqttComponent implements OnInit {
  public IDENTIFIER = "{IDENTIFIER}";

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
  public loadingConnectors = false;  
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;
  
  XAUTO_MQTTDataFormat = XAUTO_MQTTDataFormat;
  XAUTO_MQTTPayloadEncoding = XAUTO_MQTTPayloadEncoding;
  XAUTO_MQTTVariableEncoding = XAUTO_MQTTVariableEncoding;


  XDataType = XDataType;
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;
  public selectedVariable : ViewVariable = null;
  

  constructor(private xprojClient: XProjectorClient) {
  }

  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {    
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {  
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.MQTT = new XAUTO_XAutoVariableMqtt();
    this.selectedVariable.MQTT.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.MQTT.xautovariableid = this.selectedVariable.Variable.xautovariableid;

    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  async saveVariable()
  {
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable mqtt");
      await this.xprojClient.XAUTO_SaveVariableMQTT( this.selectedVariable.MQTT );
      console.log("save variable mqtt done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;

  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;    

    try
    {
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriverMQTT(this.selectedConnector.MQTT);
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();        
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New MQTT";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.MQTT;
    this.selectedConnector.MQTT = new XAUTO_XAutoDriverMqtt();    
    this.selectedConnector.MQTT.xautodriverid = this.selectedConnector.Driver.xautodriverid;    

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {    
    console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,1000, driver.Driver.xautodriverid);
    for( let j = 0; j < variables.length; j++)
    {
      let variable = variables[j];
      console.log("loading variable", variable.xautovariableid);
      let mqttvar = await this.xprojClient.XAUTO_GetVariableMqtt(variable.xautovariableid);

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.MQTT = mqttvar;
      driver.Variables.push(newViewVar);
    }
    console.log("loading variables done");
  }

  async loadDrivers()
  {
    let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);    
    for(let i = 0; i < drivers.length; i++)
    {    
      let driver = drivers[i];
      if(driver.driver != XAUTO_DriverType.MQTT)
        continue;

      let mqttdriver = await this.xprojClient.XAUTO_GetDriverMqtt(driver.xautodriverid);
      let viewdriver = new ViewDriver();
      viewdriver.Driver = driver;
      viewdriver.MQTT = mqttdriver;
      await this.loadVariables(viewdriver);
      this.connectors.push(viewdriver);
    }
  }

  async ngOnInit() {
    this.loadDrivers();
  }

}
