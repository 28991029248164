import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ColumnFilteringRelativeTimestamp, OffsetType, RelativeTimestampOrigo } from '../../../XProjector/xprojector-client-service';
import { jsonMember, jsonObject } from 'typedjson';
import { MasterTimeSettings } from '../../xproj-widget-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-master-widget-time',
  templateUrl: './xproj-master-widget-time.component.html',
  styleUrls: ['./xproj-master-widget-time.component.scss']
})
export class XprojMasterWidgetTimeComponent implements OnInit {

  @Input() settings : MasterTimeSettings;
  @Output() onApply = new EventEmitter<MasterTimeSettings>();

  relativeTimestamp : boolean = true;
  from : Date;
  to : Date;
  origo : RelativeTimestampOrigo = RelativeTimestampOrigo.BEGINNING_OF_THIS_MONTH;
  exactorigo : Date = new Date();
  offset : number = 0;
  offsettype : OffsetType = OffsetType.DAY;
  offsetstring : string = '0 M';
  RelativeTimestampOrigo = RelativeTimestampOrigo;
  OffsetType = OffsetType;

  constructor(@Inject(LOGGERSERVICE) private logger: XprojLoggerService) { }

  ngOnInit(): void {
    if (this.settings) {
      this.offsetstring = this.encodeOffsetString(this.settings);
      this.origo = this.settings.relativeTimestamp?.origo;
      this.exactorigo = this.settings.relativeTimestamp?.exactorigo;
      this.to = this.settings.to;
      this.from = this.settings.from;
      this.relativeTimestamp = this.settings.relativeTimestamp != undefined;
    }
  }

  getSettings() : MasterTimeSettings{
    let settings : MasterTimeSettings = new MasterTimeSettings();
    if (this.relativeTimestamp) {
      settings.relativeTimestamp = new ColumnFilteringRelativeTimestamp();
      settings.relativeTimestamp.origo = this.origo;
      if (this.origo == RelativeTimestampOrigo.EXACT) {
        settings.relativeTimestamp.exactorigo = this.exactorigo;
      }

      let o = this.decodeOffset(this.offsetstring);

      switch (o.type) {
        case OffsetType.YEAR : {
          settings.relativeTimestamp.offsetyears = o.offset;
          break;
        }
        case OffsetType.MONTH : {
          settings.relativeTimestamp.offsetmonths = o.offset;
          break;
        }
        case OffsetType.DAY : {
          settings.relativeTimestamp.offsetdays = o.offset;
          break;
        }
        case OffsetType.HOUR : {
          settings.relativeTimestamp.offsethours = o.offset;
          break;
        }
        case OffsetType.MINUTE : {
          settings.relativeTimestamp.offsetminutes = o.offset;
          break;
        }
        case OffsetType.SECOND : {
          settings.relativeTimestamp.offsetseconds = o.offset;
          break;
        }
      }
    }
    else {
      try {
        settings.from = new Date(this.from);
        settings.to = new Date(this.to);
      }
      catch (err) {
        this.logger.error('master time error' , err);
      }

    }

    return settings;
  }

  private decodeOffset(s : string): {offset : number, type : OffsetType} {
    try {
      let parts = s.split(' ');

      if (parts.length > 1) {
        let offset = Number.parseInt(parts[0].trim());
        switch(parts[1].trim()) {
          case 'y' :
          case 'Y' :
          case 'year' :
          case 'years' : {
            return {offset : offset, type : OffsetType.YEAR };
          }

          case 'M' :
          case 'month' : {
            return {offset : offset, type : OffsetType.MONTH };
          }

          case 'd' :
          case 'D' :
          case 'day' :
          case 'days' :  {
            return {offset : offset, type : OffsetType.DAY };
          }

          case 'h' :
          case 'H' :
          case 'hour' :
          case 'hours' : {
            return {offset : offset, type : OffsetType.HOUR };
          }

          case 'm' :
          case 'minute' :
          case 'minutes' : {
            return {offset : offset, type : OffsetType.MINUTE };
          }

          case 's' :
          case 'S' :
          case 'second' :
          case 'seconds' : {
            return {offset : offset, type : OffsetType.YEAR };
          }

          default : {
            return {offset : offset, type : OffsetType.MONTH };
          }

        }
      }
      else if (parts.length > 0) {
        return {offset : Number.parseInt(parts[0].trim()) , type : OffsetType.MONTH };
      }
    }
    catch (err) {
      this.logger.error('error', err);
    }

    return {offset : 0, type : OffsetType.MONTH };
  }

  private encodeOffsetString(settings : MasterTimeSettings) : string {
    if (settings.relativeTimestamp) {
      if (settings.relativeTimestamp.offsetyears != 0) {
        return settings.relativeTimestamp.offsetyears + ' y';
      }
      if (settings.relativeTimestamp.offsetmonths != 0) {
        return settings.relativeTimestamp.offsetmonths + ' M';
      }
      if (settings.relativeTimestamp.offsetdays != 0) {
        return settings.relativeTimestamp.offsetdays + ' d';
      }
      if (settings.relativeTimestamp.offsethours != 0) {
        return settings.relativeTimestamp.offsethours + ' h';
      }
      if (settings.relativeTimestamp.offsetminutes != 0) {
        return settings.relativeTimestamp.offsetminutes + ' m';
      }
      if (settings.relativeTimestamp.offsetseconds != 0) {
        return settings.relativeTimestamp.offsetseconds + ' s';
      }
    }

    return '0 M';
  }

  onOffsetInputEnter() {
    this.onApply?.emit(this.getSettings());
  }
}
