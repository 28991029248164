// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  production: false,
  logoff : false,
  logo: '/assets/robotrisa.svg',
  favicon: 'favicon.ico',
  title: 'Robotrisa',
  maintitle: '',
  editortheme: 'vs-dark'
};*/

export const environment = {
  production: false,
  logoff : false,
  logo : '/assets/rossaker-logo-cgf-solid-white-20.png ',
  favicon: '/assets/rossaker-logo-cgf-solid-white-20.png',
  title: 'ROSSAKER',
  maintitle: '',
  editortheme: 'vs-dark'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
