import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { XprojGroupSelectionComponent } from '../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter, WidgetPreQueryConfig, WidgetPrQueryColumnConfig } from '../widget-config-service';
import { ProjectionDataeditorWidgetUtils } from '../projection-dataeditor/projection-dataeditor-utils-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-widget-prequery-config',
  templateUrl: './xproj-widget-prequery-config.component.html',
  styleUrls: ['./xproj-widget-prequery-config.component.scss']
})
export class XprojWidgetPrequeryConfigComponent implements OnInit, AfterViewInit {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  @Input() widgetQuery: WidgetPreQueryConfig;
  @Input() projections: Projection[] = [];

  @Input() widgets: WidgetConfig[];
  @Input() getInputParameters: Function;

  @Output() save = new EventEmitter<WidgetPreQueryConfig>();

  inputParameters: WidgetInputParameter[] = [];
  selectedColumnConfig: WidgetPrQueryColumnConfig = null;
  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];

  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  XDataType = XDataType;

  constructor(@Inject(LOGGERSERVICE) private logger: XprojLoggerService, private xprojClient: XProjectorClient) {

  }

  async ngOnInit() {

  }

  addAllColumns()
  {
    //this.widgetQuery.ColumnConfigs.push(new ColumnConfig());

    for(let col of this.columns)
    {
      let t = new WidgetPrQueryColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      t.Tags = col.tags;
      //t.EditMode = ProjectionDataeditorWidgetUtils.getEditMode(t.Tags, t.Datatype);
      //t.EnumMembers = t.EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(t.Tags) : [];
      this.widgetQuery.ColumnConfigs.push(t);
    }

  }
  removeAllColumns()
  {
    this.widgetQuery.ColumnConfigs.length = 0;
  }

  addColumn() {
    this.widgetQuery.ColumnConfigs.push(new WidgetPrQueryColumnConfig());
  }

  async ngAfterViewInit() {
    //await this.init();
  }

  async init() {
    this.inputParameters = this.getInputParameters(this.widgetQuery.Level);

    if (!this.projections || this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    //await this.selectedProjectionChange(this.queryData.projection);

    await this.selectedProjectionGroupChange(this.widgetQuery.Group);

    if (this.projectionFilter) {
      this.projectionFilter.columnDescriptions = this.columns;
      this.projectionFilter.inputParameters = this.inputParameters;
      this.projectionFilter.datafilters = this.widgetQuery.DataFilters;
      this.projectionFilter.projectionid = this.widgetQuery.ProjectionId;
    }
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.selectedGroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection.projectionid, null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.widgetQuery.Group = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection.projectionid, group);
      this.checkTimeFilterColumn();
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
    //this.loadingProjectionColumns = false;

  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.columns.find(col => col.columnname == this.widgetQuery.TimestampColumnName)
      || (!this.widgetQuery.TimestampColumnName || this.widgetQuery.TimestampColumnName.length == 0)) {
      this.widgetQuery.TimestampColumnName = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.widgetQuery.TimestampColumnName || this.widgetQuery.TimestampColumnName.length == 0) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.widgetQuery.TimestampColumnName = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.widgetQuery.TimestampColumnName || this.widgetQuery.TimestampColumnName.length == 0)) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.widgetQuery.TimestampColumnName = col.columnname;
        }
      });
    }
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.widgetQuery.ColumnConfigs, index);
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.widgetQuery.ColumnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;
  }

  onColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.widgetQuery.ColumnConfigs.length) {
      this.widgetQuery.ColumnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.widgetQuery.ColumnConfigs[index].Datatype = column.datatype;
      //this.widgetQuery.ColumnConfigs[index].EditMode = ProjectionDataeditorWidgetUtils.getEditMode(this.widgetQuery.ColumnConfigs[index].Tags, this.widgetQuery.ColumnConfigs[index].Datatype);
      //this.widgetQuery.ColumnConfigs[index].EnumMembers = this.widgetQuery.ColumnConfigs[index].EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(this.widgetQuery.ColumnConfigs[index].Tags) : [];

    }
  }

  addScriptedColumnsPostAggregation() {
    this.widgetQuery.ScriptedColumnsPostAggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.widgetQuery.ScriptedColumnsPostAggregation = this.widgetQuery.ScriptedColumnsPostAggregation.filter(q => q != queryColumn);
  }

  async SaveQuery() {
    //convert from literal object to class object
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;

    this.widgetQuery.ProjectionId = this.projection.projectionid;

    if (this.widgetQuery.ColumnConfigs.length == 0) {
      return;
    }

    let colindex = 0;
    this.widgetQuery.OutputParameters = [];
    for (let column of this.widgetQuery.ColumnConfigs) {
      let col = new BaseQueryInputColumnDescription();
      col.columnname = column.ColumnName;
      if (column.Label?.length > 0) {
        col.columnoutname = column.ColumnOutName = column.Label;
      }
      else {
        col.columnoutname = column.ColumnOutName = this.widgetQuery.UseGrouping ? "col_" + colindex.toString() : column.ColumnName;
      }

      col.columntransformation = column.ColumnTransformation;

      //if (column.Output) {
        let output = new WidgetOutputParameter();
        output.id = column.Id;
        output.datatype = column.Transform != Aggregation.NONE ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[column.Datatype]);
        output.name = (this.widgetQuery.Prefix?.length > 0 ? this.widgetQuery.Prefix : this.widgetQuery.Name) + ':' + column.ColumnOutName;
        // (column.Label?.length > 0 ? column.Label : column.ColumnName);

        this.widgetQuery.OutputParameters.push(output);
      //}

      colindex++;
    }

    this.save?.emit(this.widgetQuery);
  }
}
