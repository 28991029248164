import { XprojLoggerService } from "xproj-lib";
import { NGXLogger } from 'ngx-logger';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NgxloggerLoggerService implements XprojLoggerService {

  constructor(private logger: NGXLogger) {

  }

  trace(message: any, ...additional: any[]): void {
    this.logger.trace(message, additional);
  }

  debug(message: any, ...additional: any[]): void{
    this.logger.debug(message, additional);
  }

  info(message: any, ...additional: any[]): void {
    this.logger.info(message, additional);
  }

  log(message: any, ...additional: any[]): void {
    this.logger.log(message, additional);
  }

  warn(message: any, ...additional: any[]): void {
    this.logger.warn(message, additional);
  }

  error(message: any, ...additional: any[]): void {
    this.logger.error(message, additional);
  }

  fatal(message: any, ...additional: any[]): void {
    this.logger.error(message, additional);
  }

}
