export class RossakerBmsLorawanMultiMeter {
  id : string;
  name : string;
  multiMeterType : string;
  appKey : string;
  devEui : string;
  externalId : string;
  provisioned : boolean;
  createdAt : Date;
  modifiedAt : Date;
}
