import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { RossakerBmsAdminModule } from './rossaker-bms-admin/rossaker-bms-admin.module';
import { RossakerXProjectorBmsExportClient } from '@core/xprojector_backend/rossaker-xprojector-bms-export-client';
import { RossakerXProjectorBmsEventsClient } from '@core/xprojector_backend/rossaker-xprojector-bms-events-client';
import { RossakerBmsTrusteeAdminModule } from "./rossaker-bms-trustee-admin/rossaker-bms-trustee-admin.module";
import { RossakerBmsCustomerModule } from "./rossaker-bms-customer/rossaker-bms-customer.module";

@NgModule({
  declarations: [

  ],
  imports: [
    RossakerBmsAdminModule,
    RossakerBmsTrusteeAdminModule,
    RossakerBmsCustomerModule
  ],
  providers: [
    RossakerStateService,
    RossakerXProjectorBmsExportClient,
    RossakerXProjectorBmsEventsClient
  ]
})
export class RossakerBmsModule {
}
