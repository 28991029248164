<div>
    <xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets" [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>
</div>

<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Map settings</clr-accordion-title>
        <clr-accordion-content>
            <form clrForm>
                <clr-input-container>
                    <label>Centerpoint longitude</label>
                    <input clrInput placeholder="" type="number" name="CenterPointLongitude" min="-1" max="1000" [(ngModel)]="widgetConfig.CenterPointLongitude" />
                </clr-input-container>
                <clr-input-container>
                    <label>Centerpoint latitude</label>
                    <input clrInput placeholder="" type="number" name="CenterPointLatitude" min="-1" max="1000" [(ngModel)]="widgetConfig.CenterPointLatitude" />
                </clr-input-container>
                <clr-input-container>
                    <label>Default zoom</label>
                    <input clrInput placeholder="" type="number" name="DefaultZoom" min="1" max="20" [(ngModel)]="widgetConfig.DefaultZoom" />
                </clr-input-container>
                <clr-input-container>
                    <label>Centerpoint longitude when empty result</label>
                    <input clrInput placeholder="" type="number" name="CenterPointLongitudeNoPoints" min="-1" max="1000" [(ngModel)]="widgetConfig.CenterPointLongitudeNoPoints" />
                </clr-input-container>
                <clr-input-container>
                    <label>Centerpoint latitude when empty result</label>
                    <input clrInput placeholder="" type="number" name="CenterPointLatitudeNoPoints" min="-1" max="1000" [(ngModel)]="widgetConfig.CenterPointLatitudeNoPoints" />
                </clr-input-container>
                <clr-input-container>
                    <label>Default zoom when empty result</label>
                    <input clrInput placeholder="" type="number" name="DefaultZoomNoPoints" min="1" max="20" [(ngModel)]="widgetConfig.DefaultZoomNoPoints" />
                </clr-input-container>
                <clr-toggle-container>
                    <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle value="true" name="disabled" [(ngModel)]="widgetConfig.GreyScale" />
                        <label>Greyscale (requires reload)</label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>
                <clr-select-container>
                    <label>Rendering type</label>
                    <select clrSelect id="renderingtype" name="renderingtype" [(ngModel)]="widgetConfig.RenderType">
            <option [ngValue]="MapRenderType.MARKER"> Marker </option>
            <option [ngValue]="MapRenderType.WEBGL"> WebGL </option>
          </select>
                </clr-select-container>
                <clr-input-container *ngIf="widgetConfig.RenderType == MapRenderType.MARKER">
                    <label>MarkerURL</label>
                    <input clrInput style="width: 95%;" placeholder="" name="MarkerURL" [(ngModel)]="widgetConfig.MarkerURL" />
                </clr-input-container>
            </form>

            <div *ngIf="widgetConfig.RenderType == MapRenderType.WEBGL">
                <ngx-monaco-editor class="webgl-textarea" [options]="editorOptions" [(ngModel)]="webGLStyle">
                </ngx-monaco-editor>
                <p *ngIf="widgetConfig.webGLErrorMessage?.length > 0" class="webgl-error">{{widgetConfig.webGLErrorMessage}}</p>
            </div>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
    <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
        <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
        <clr-accordion-content>
            <xproj-map-widget-query-config [widgetQuery]="configQuery" [projections]="projections" [widgetConfig]="widgetConfig" [inputParameters]="widgetConfig.InputParameters" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)">
            </xproj-map-widget-query-config>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addConfigQuery();" *ngIf="widgetConfig.ConfigQueries.length == 0">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>