/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as xprojectorGrpcModels001 from './xprojector.grpc.models.pb';
/**
 * Message implementation for credentials.SetCredentialsFromTicketRequest
 */
export class SetCredentialsFromTicketRequest implements GrpcMessage {
  static id = 'credentials.SetCredentialsFromTicketRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetCredentialsFromTicketRequest();
    SetCredentialsFromTicketRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetCredentialsFromTicketRequest) {
    _instance.ticket = _instance.ticket || '';
    _instance.password = _instance.password || '';
    _instance.firstname = _instance.firstname || '';
    _instance.surname = _instance.surname || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetCredentialsFromTicketRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ticket = _reader.readString();
          break;
        case 2:
          _instance.password = _reader.readString();
          break;
        case 3:
          _instance.firstname = _reader.readString();
          break;
        case 4:
          _instance.surname = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetCredentialsFromTicketRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetCredentialsFromTicketRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.ticket) {
      _writer.writeString(1, _instance.ticket);
    }
    if (_instance.password) {
      _writer.writeString(2, _instance.password);
    }
    if (_instance.firstname) {
      _writer.writeString(3, _instance.firstname);
    }
    if (_instance.surname) {
      _writer.writeString(4, _instance.surname);
    }
  }

  private _ticket?: string;
  private _password?: string;
  private _firstname?: string;
  private _surname?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetCredentialsFromTicketRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SetCredentialsFromTicketRequest.AsObject>
  ) {
    _value = _value || {};
    this.ticket = _value.ticket;
    this.password = _value.password;
    this.firstname = _value.firstname;
    this.surname = _value.surname;
    SetCredentialsFromTicketRequest.refineValues(this);
  }
  get ticket(): string | undefined {
    return this._ticket;
  }
  set ticket(value: string | undefined) {
    this._ticket = value;
  }
  get password(): string | undefined {
    return this._password;
  }
  set password(value: string | undefined) {
    this._password = value;
  }
  get firstname(): string | undefined {
    return this._firstname;
  }
  set firstname(value: string | undefined) {
    this._firstname = value;
  }
  get surname(): string | undefined {
    return this._surname;
  }
  set surname(value: string | undefined) {
    this._surname = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetCredentialsFromTicketRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetCredentialsFromTicketRequest.AsObject {
    return {
      ticket: this.ticket,
      password: this.password,
      firstname: this.firstname,
      surname: this.surname
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetCredentialsFromTicketRequest.AsProtobufJSON {
    return {
      ticket: this.ticket,
      password: this.password,
      firstname: this.firstname,
      surname: this.surname
    };
  }
}
export module SetCredentialsFromTicketRequest {
  /**
   * Standard JavaScript object representation for SetCredentialsFromTicketRequest
   */
  export interface AsObject {
    ticket?: string;
    password?: string;
    firstname?: string;
    surname?: string;
  }

  /**
   * Protobuf JSON representation for SetCredentialsFromTicketRequest
   */
  export interface AsProtobufJSON {
    ticket?: string;
    password?: string;
    firstname?: string;
    surname?: string;
  }
}

/**
 * Message implementation for credentials.GetTicketInfoRequest
 */
export class GetTicketInfoRequest implements GrpcMessage {
  static id = 'credentials.GetTicketInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTicketInfoRequest();
    GetTicketInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTicketInfoRequest) {
    _instance.ticket = _instance.ticket || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTicketInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ticket = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetTicketInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTicketInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.ticket) {
      _writer.writeString(1, _instance.ticket);
    }
  }

  private _ticket?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTicketInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTicketInfoRequest.AsObject>) {
    _value = _value || {};
    this.ticket = _value.ticket;
    GetTicketInfoRequest.refineValues(this);
  }
  get ticket(): string | undefined {
    return this._ticket;
  }
  set ticket(value: string | undefined) {
    this._ticket = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTicketInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTicketInfoRequest.AsObject {
    return {
      ticket: this.ticket
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTicketInfoRequest.AsProtobufJSON {
    return {
      ticket: this.ticket
    };
  }
}
export module GetTicketInfoRequest {
  /**
   * Standard JavaScript object representation for GetTicketInfoRequest
   */
  export interface AsObject {
    ticket?: string;
  }

  /**
   * Protobuf JSON representation for GetTicketInfoRequest
   */
  export interface AsProtobufJSON {
    ticket?: string;
  }
}

/**
 * Message implementation for credentials.GetTicketInfoResponse
 */
export class GetTicketInfoResponse implements GrpcMessage {
  static id = 'credentials.GetTicketInfoResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTicketInfoResponse();
    GetTicketInfoResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTicketInfoResponse) {
    _instance.ok = _instance.ok || false;
    _instance.expired = _instance.expired || false;
    _instance.hasBeenUsed = _instance.hasBeenUsed || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTicketInfoResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.expired = _reader.readBool();
          break;
        case 3:
          _instance.hasBeenUsed = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetTicketInfoResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTicketInfoResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.expired) {
      _writer.writeBool(2, _instance.expired);
    }
    if (_instance.hasBeenUsed) {
      _writer.writeBool(3, _instance.hasBeenUsed);
    }
  }

  private _ok?: boolean;
  private _expired?: boolean;
  private _hasBeenUsed?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTicketInfoResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTicketInfoResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.expired = _value.expired;
    this.hasBeenUsed = _value.hasBeenUsed;
    GetTicketInfoResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get expired(): boolean | undefined {
    return this._expired;
  }
  set expired(value: boolean | undefined) {
    this._expired = value;
  }
  get hasBeenUsed(): boolean | undefined {
    return this._hasBeenUsed;
  }
  set hasBeenUsed(value: boolean | undefined) {
    this._hasBeenUsed = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTicketInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTicketInfoResponse.AsObject {
    return {
      ok: this.ok,
      expired: this.expired,
      hasBeenUsed: this.hasBeenUsed
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTicketInfoResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      expired: this.expired,
      hasBeenUsed: this.hasBeenUsed
    };
  }
}
export module GetTicketInfoResponse {
  /**
   * Standard JavaScript object representation for GetTicketInfoResponse
   */
  export interface AsObject {
    ok?: boolean;
    expired?: boolean;
    hasBeenUsed?: boolean;
  }

  /**
   * Protobuf JSON representation for GetTicketInfoResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    expired?: boolean;
    hasBeenUsed?: boolean;
  }
}
