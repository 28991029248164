import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrDatagridSortOrder } from '@clr/angular';
import { ArrayUtils } from 'xproj-lib';

@Component({
  selector: 'app-edit-node-types',
  templateUrl: './edit-node-types.component.html',
  styleUrls: ['./edit-node-types.component.scss']
})
export class EditNodeTypesComponent implements OnInit, AfterViewInit {

  nodeTypes : {nodeType : GrpcNodeType, fakeCategories : any[] }[] = [];

  selected : {nodeType : GrpcNodeType, fakeCategories : any[] } = null;

  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private xConfClient: XProjectorXConfClient) {

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    let nodeTypes = await this.xConfClient.getNodeTypes();
    nodeTypes.forEach(nt => {
      this.nodeTypes.push({
        nodeType : nt,
        fakeCategories : new Array(nt.categories.length
      )});
    });
  }

  async saveNodeType(nodeType : GrpcNodeType) : Promise<boolean>{
    let result = await this.xConfClient.upsertNodeType(nodeType);
    return result.result;
  }

  addNodeType() {
    let nodeType : GrpcNodeType = new GrpcNodeType();
    this.nodeTypes.push({
      nodeType : nodeType,
      fakeCategories : new Array(nodeType.categories.length)
    });
    this.selected = null;
  }

  async deleteSelected() {
    if (this.selected) {
      let result = await this.xConfClient.deleteNodeType(this.selected.nodeType.id);
      if (result.result) {
        this.nodeTypes = this.nodeTypes.filter(n => n.nodeType.id != this.selected.nodeType.id);
        this.selected = null;
      }
    }
  }

  async saveSelected() {
    if (this.selected) {
        //TODO: id changed??
      await this.saveNodeType(this.selected.nodeType);

      this.selected = null;
    }

  }

  addCategory() {
    if (this.selected) {
      if (!this.selected.fakeCategories) {
        this.selected.fakeCategories = [];
      }
      this.selected.fakeCategories.push('');
      this.selected.nodeType.categories.push('<new category>');
    }
  }

  removeCategory(index : number) {
    if (this.selected) {
      this.selected.fakeCategories.splice(index, 1);
      this.selected.nodeType.categories.splice(index, 1);
    }
  }

  moveCategoryUp(index: number) {
    if (this.selected.nodeType) {
      ArrayUtils.MoveItemUp(this.selected.nodeType.categories, index);
    }
  }

  moveCategoryDown(index: number) {
    if (this.selected.nodeType) {
      ArrayUtils.MoveItemDown(this.selected.nodeType.categories, index);
    }
  }
}
