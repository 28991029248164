import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeviewItem } from '@xprojectorcore/models/treeview-item';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorDashboardClient } from '@xprojectorcore/xprojector_backend/xprojector-dashboard-client';
import { NGXLogger } from 'ngx-logger';
import { XprojDashboardInteractService, XprojMediaService } from 'xproj-lib';

@Component({
  selector: 'app-xconf-dashboard-workspace',
  templateUrl: './xconf-dashboard-workspace.component.html',
  styleUrls: ['./xconf-dashboard-workspace.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class XConfDashboardWorkspaceComponent implements OnInit, OnDestroy {


  currentUserRights: UserRights;

  clipboardItem: TreeviewItem;

  collapsed = false;
  openDropDown = false;

  _navCollapsed: boolean = false;
  get navCollapsed(): boolean {
    return this._navCollapsed;
  }
  set navCollapsed(collapsed) {
    if (collapsed != this._navCollapsed) {
      this.xprojDashboardInteractService.RefreshDashboard();
    }
    this._navCollapsed = collapsed;
  }

  mediaService = new XprojMediaService();
  mediaSubscription: any;

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private dashboardClient: XProjectorDashboardClient,
    private xprojDashboardInteractService: XprojDashboardInteractService,
    private router: Router
  ) {

    // this.mediaService.setQuery('(max-width: 762px)');
    // this.mediaSubscription = this.mediaService.match$.subscribe(async (isMobile) => {

    // });

  }

  async ngOnInit() {
    this.currentUserRights = this.state.userRightsValue;

    let href = this.router.url;
    //this.logger.log('route', href);
    if (href?.length > 11) {
      let dashboardId = this.getDaskboardIdFromUrl(this.router.url);
      //this.logger.log('dashboardid', dashboardId);
      //this.initSelectedTreeNode(dashboardId);
    }

    this.router.events.subscribe(val => {
      //this.logger.log(val);
      //@ts-ignore
      if (val.navigationTrigger == 'imperative') {
        //@ts-ignore
        let dashboardId = this.getDaskboardIdFromUrl(val.url);
        //this.logger.log('dashboardid', dashboardId);
        //this.initSelectedTreeNode(dashboardId);
      }
    });
  }

  ngOnDestroy(): void {
    this.mediaSubscription?.unsubscribe();
  }

  getDaskboardIdFromUrl(url: string) {
    let dashboardId = decodeURIComponent(url.substr(11));
    let i = dashboardId.indexOf('?');
    if (i > 0) {
      dashboardId = dashboardId.substring(0, i);
    }

    return dashboardId;
  }

}
