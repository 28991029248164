<clr-radio-container clrInline style="padding-left: 6px;">
	<label>Time filter type</label>
	<clr-radio-wrapper>
		<input type="radio" clrRadio name="options" required [value]="true" [(ngModel)]="relativeTimestamp" />
		<label>Relative</label>
	</clr-radio-wrapper>
	<clr-radio-wrapper>
		<input type="radio" clrRadio name="options" required [value]="false" [(ngModel)]="relativeTimestamp" />
		<label>Fixed</label>
	</clr-radio-wrapper>
</clr-radio-container>
<form clrForm>
	<div *ngIf="!relativeTimestamp">
		<clr-date-container>
			<label>From</label>
			<input type="date" clrDate name="from" [(ngModel)]="from">
		</clr-date-container>
		<clr-date-container>
			<label>To</label>
			<input type="date" clrDate name="to" [(ngModel)]="to">
		</clr-date-container>
	</div>
	<div clrForm *ngIf="relativeTimestamp">
		<clr-select-container>
			<label>Origo</label>
			<select style="margin-left: 1em;" clrSelect name="origo" [(ngModel)]="origo">
				<option [ngValue]="RelativeTimestampOrigo.NOW"> Now </option>
				<option [ngValue]="RelativeTimestampOrigo.NOWUTC"> Utc Now </option>
				<option [ngValue]="RelativeTimestampOrigo.EXACT"> Exact </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_YEAR"> Beginning of this year </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MONTH"> Beginning of this month </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_DAY"> Beginning of this day </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_HOUR"> Beginning of this hour </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MINUTE"> Beginning of this minute </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_YEARUTC"> UTC Beginning of this year </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MONTHUTC"> UTC Beginning of this month </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_DAYUTC"> UTC Beginning of this day </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_HOURUTC"> UTC Beginning of this hour </option>
				<option [ngValue]="RelativeTimestampOrigo.BEGINNING_OF_THIS_MINUTEUTC"> UTC Beginning of this minute </option>	
			</select>
		</clr-select-container>

		<clr-date-container *ngIf="origo == RelativeTimestampOrigo.EXACT">
			<label>Exact origo</label>
			<input type="date" clrDate name="exactorigo" [(ngModel)]="exactorigo">
		</clr-date-container>

		<clr-input-container>
			<label>Offset</label>
			<input type="string" clrInput placeholder="" name="offset" [(ngModel)]="offsetstring" (keyup.enter)="onOffsetInputEnter()" />
		</clr-input-container>

	</div>
</form>
