<form clrForm clrLayout="vertical">
  <clr-range-container [clrRangeHasProgress]="false">
    <label *ngIf="rangeConfig.ShowPlay">
      (
      <clr-icon *ngIf="!play" class="play-controller" shape="play" size="16" (click)="startPlay($event)"></clr-icon>
      <clr-icon *ngIf="play" class="play-controller" shape="pause" size="16" (click)="stopPlay($event)"></clr-icon>
      ) {{rangeConfig.Label}}
    </label>
    <label *ngIf="!rangeConfig.ShowPlay">
      {{rangeConfig.Label}}
    </label>
    <input type="range" clrRange [(ngModel)]="value" name="range" [min]="rangeConfig.Min" [max]="rangeConfig.Max"
      [step]="rangeConfig.Step" [value]="rangeConfig.Value" (change)="rangeChange()" class="clr-col-12" />
    <clr-control-helper>Value: {{value}}</clr-control-helper>
  </clr-range-container>
</form>
