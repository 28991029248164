import { Component, OnInit, ViewChild } from '@angular/core';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { CustomerUser } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorCustomerAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-customeradmin-client';
import { ClrForm } from '@clr/angular';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-user-profiles',
  templateUrl: './user-profiles.component.html',
  styleUrls: ['./user-profiles.component.scss']
})
export class UserProfilesComponent implements OnInit {
  @ViewChild(ClrForm, {static: true}) clrForm : ClrForm;

  users: CustomerUser[];
  showRemoveUser: boolean = false;
  selectedUser: CustomerUser;
  currentUserRights: UserRights;

  newUser: CustomerUser = new CustomerUser();

  constructor(
    private state: StateService,
    private customerAdminClient: XProjectorCustomerAdminClient,
    private alertService: XprojAlertService) { }


  async ngOnInit() {
    this.currentUserRights = this.state.userRightsValue;
    this.getUsers();
  }

  async getUsers() {
    this.users = await this.customerAdminClient.getUsers();
  }

  async removeUser(user: CustomerUser) {
    if (user) {
      this.selectedUser = user;
      this.showRemoveUser = true;
    }
  }

  async doRemoveSelectedUser() {
    if (this.selectedUser) {
      let result = await this.customerAdminClient.deleteUser(this.selectedUser.id);
      if (result.result) {
        this.users = this.users.filter(c => c != this.selectedUser);
        this.selectedUser = null;
        this.alertService.success('User deleted.');
      } else {
        this.alertService.error(result.message);
      }
    }
  }

  async addCustomerUser() {
    try {
      if (this.isNewUserValid()) {
        this.newUser.id = this.newUser.email;
        let user = await this.customerAdminClient.addNewUser(this.newUser);
        if (user) {
          this.alertService.success($localize `:@@userprofiles_newuseradded:New user added.`);
          this.users.push(user);
          this.clear();
        }
        else {
          this.alertService.error($localize `:@@userprofile_erroraddnewuser:Error add new user!`);
        }
      }
      else {
        this.clrForm.markAsTouched();
      }
    }
    catch (err) {
      this.alertService.error($localize `:@@userprofile_erroraddnewuser2:Error add new user: ` + err);
    }
  }

  private isNewUserValid(): boolean {
    return this.newUser && this.newUser.firstname.length > 0 && this.newUser.surname.length > 0 && this.newUser.email.length > 0;
  }

  clear() {
    this.newUser = new CustomerUser();
  }
}
