import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '@xprojectorcore/services/state-service';
import { GetTicketInfoResponse } from '@xprojectorcore/xprojector_backend/proto/xprojector.credentials.pb';
import { XProjectorCredentialsClient } from '@xprojectorcore/xprojector_backend/xprojector-credentials-client';
import { map } from 'rxjs/operators';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-create-credentials',
  templateUrl: './create-credentials.component.html',
  styleUrls: ['./create-credentials.component.scss']
})
export class CreateCredentialsComponent implements OnInit {

  ticket: string = '';
  customerId: string = '';
  customerUserEmail: string = '';
  action: string = '';

  firstname: string = '';
  surname: string = '';
  password: string = '';
  password2: string = '';

  errorsubmit : boolean = false;
  errormessage: string = '';

  ticketInfo : GetTicketInfoResponse;

  constructor(private credentialsClient : XProjectorCredentialsClient,
    private state: StateService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: XprojAlertService) { }

  async ngOnInit() {
    await this.state.logout(false);

    this.route.params.pipe(map(p => p.action)).subscribe(async (action) => {
      this.action = action;
    });

    this.ticket = this.route.snapshot.queryParams['ticket'];
    this.customerId = this.route.snapshot.queryParams["customer"];
    this.customerUserEmail = this.route.snapshot.queryParams["email"];

    this.ticketInfo = await this.credentialsClient.getTicketInfo(this.ticket);
  }

  async submit() {
    this.errorsubmit = false;

    if (this.password == this.password2) {
      let result = await this.credentialsClient.setCredentialsFromTicketRequest(this.ticket, this.password, this.firstname, this.surname);
      if (result.result) {
        let user = await this.state.login('', this.customerUserEmail, this.password, '');
        if (user) {
          this.router.navigate(['/']);
        }
        else {
          this.router.navigate(['/login']);
        }
      }
      else {
        this.errorsubmit = true;
        this.errormessage = result.message;
        this.alertService.error('Error setup credentials: ' + result.message);
      }
    }
    else {
      this.errormessage = 'Password mismatch, make sure that the repeated password is the same as the first.';
      this.errorsubmit = true;
    }
  }

}
