<form clrForm clrLayout="vertical">
  <clr-select-container>
    <label>{{transformationConfig.Label ? transformationConfig.Label : 'Select transform' }}</label>

    <select clrSelect name="selecttransform"
      id="xaxisgrouptransform" [(ngModel)]="selectedTransformation" (change)="onSelect()">
      <option *ngIf="transformationConfig.NONE.Show" [ngValue]="Transformation.NONE"> {{transformationConfig.NONE.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_YEAR.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> {{transformationConfig.TIMESTAMP_RESOLUTION_YEAR.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_MONTH.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> {{transformationConfig.TIMESTAMP_RESOLUTION_MONTH.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_DAY.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> {{transformationConfig.TIMESTAMP_RESOLUTION_DAY.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_HOUR.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> {{transformationConfig.TIMESTAMP_RESOLUTION_HOUR.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_MINUTE.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> {{transformationConfig.TIMESTAMP_RESOLUTION_MINUTE.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_RESOLUTION_SECOND.Show" [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> {{transformationConfig.TIMESTAMP_RESOLUTION_SECOND.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_DAYOFYEAR.Show" [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> {{transformationConfig.TIMESTAMP_DAYOFYEAR.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_DAYOFMONTH.Show" [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> {{transformationConfig.TIMESTAMP_DAYOFMONTH.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_DAYOFWEEK.Show" [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> {{transformationConfig.TIMESTAMP_DAYOFWEEK.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_SECONDS_OF_DAY.Show" [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> {{transformationConfig.TIMESTAMP_SECONDS_OF_DAY.Label}}</option>
      <option *ngIf="transformationConfig.TIMESTAMP_MINUTES_OF_DAY.Show" [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> {{transformationConfig.TIMESTAMP_MINUTES_OF_DAY.Label}}</option>
      <option *ngIf="transformationConfig.TIMESTAMP_HOURS.Show" [ngValue]="Transformation.TIMESTAMP_HOURS"> {{transformationConfig.TIMESTAMP_HOURS.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_MINUTES.Show" [ngValue]="Transformation.TIMESTAMP_MINUTES"> {{transformationConfig.TIMESTAMP_MINUTES.Label}} </option>
      <option *ngIf="transformationConfig.TIMESTAMP_SECONDS.Show" [ngValue]="Transformation.TIMESTAMP_SECONDS"> {{transformationConfig.TIMESTAMP_SECONDS.Label}} </option>
      <option *ngIf="transformationConfig.FORWARD_DIFF.Show" [ngValue]="Transformation.FORWARD_DIFF"> {{transformationConfig.FORWARD_DIFF.Label}} </option>
    </select>
  </clr-select-container>
</form>
