import { Component, Input, OnInit } from '@angular/core';
import { Aggregation } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputAggregationControllerConfig } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-aggregation-controller-config',
  templateUrl: './xproj-output-aggregation-controller-config.component.html',
  styleUrls: ['./xproj-output-aggregation-controller-config.component.scss']
})
export class XprojOutputAggregationControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.aggregationConfig = this.config as XprojOutputAggregationControllerConfig;
  }


  aggregationConfig : XprojOutputAggregationControllerConfig;

  Aggregation = Aggregation;

  constructor() { }

  ngOnInit(): void {
  }

}
