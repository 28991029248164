<div class="content-area">

  <button class="btn btn-primary" *ngIf="!hasLoaded && xprojClient.IsConnected()" (click)="Start()">Reload</button>

  <div class="limit-height">
    <clr-datagrid [(clrDgSingleSelected)]="selectedProjection" [clrDgRowSelection]="true"
      (clrDgSingleSelectedChange)="selectedProjectionChange($event)" [clrDgLoading]="loadingProjections">

      <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
      <clr-dg-column [clrDgField]="'lastprojectedutc'"> Last Projected </clr-dg-column>
      <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
      <clr-dg-column [clrDgField]="'projectionid'"> ID </clr-dg-column>
      <clr-dg-column [clrDgField]="'tags'"> Tags </clr-dg-column>

      <clr-dg-row *clrDgItems="let n of Projections" [clrDgItem]="n">
        <clr-dg-cell> {{n.name| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.lastprojectedutc| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.description| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.projectionid| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.tags| xproj_pretty}} </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>{{Projections.length}} Projections</clr-dg-footer>

    </clr-datagrid>
  </div>

  <div class="limit-height" *ngIf="ProjectionGroups.length>0">
    <clr-datagrid [(clrDgSingleSelected)]="selectedGroup" [clrDgRowSelection]="true"
      (clrDgSingleSelectedChange)="selectedProjectionGroupChange($event)" [clrDgLoading]="loadingProjections">
      <clr-dg-column>Has data</clr-dg-column>
      <clr-dg-column>Group</clr-dg-column>
      <clr-dg-row *ngFor="let n of ProjectionGroups" [clrDgItem]="n">
        <clr-dg-cell> {{n.hasdata| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.groupstr| xproj_pretty}} </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>{{ProjectionGroups.length}} / {{NrProjectionGroups}} Filtered groups</clr-dg-footer>

    </clr-datagrid>

    <clr-datagrid *ngIf="selectedGroup && selectedGroup.haschildren" [(clrDgSingleSelected)]="selectedSubGroup"
      [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectedProjectionSubGroupChange($event)"
      [clrDgLoading]="loadingProjections">
      <clr-dg-column>Has data</clr-dg-column>
      <clr-dg-column>Group</clr-dg-column>
      <clr-dg-row *ngFor="let n of SubProjectionGroups" [clrDgItem]="n">
        <clr-dg-cell> {{n.hasdata| xproj_pretty}} </clr-dg-cell>
        <clr-dg-cell> {{n.groupstr| xproj_pretty}} </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>{{SubProjectionGroups.length}} / {{NrSubProjectionGroups}} Filtered groups</clr-dg-footer>

    </clr-datagrid>
  </div>

  <!-- <clr-tabs>
        <clr-tab> -->
  <!-- <button clrTabLink id="link1">Tabular</button>
            <clr-tab-content id="content1" *clrIfActive> -->


  <div class="limit-height">
    <clr-datagrid [(clrDgSelected)]="selectedColumns" (clrDgSelectedChange)="selectedColumnsChange($event)"
      [clrDgLoading]="loadingProjectionColumns">

      <clr-dg-column>Name</clr-dg-column>
      <clr-dg-column>PK</clr-dg-column>
      <clr-dg-column>Type</clr-dg-column>
      <clr-dg-column>Unit</clr-dg-column>
      <!--<clr-dg-column>Writable</clr-dg-column>-->
      <clr-dg-column>Tags</clr-dg-column>

      <clr-dg-row *ngFor="let n of Columns " [clrDgItem]="n ">
        <clr-dg-cell> {{ n.columnname | xproj_pretty}}</clr-dg-cell>
        <clr-dg-cell> {{ n.primarykey | xproj_pretty}}</clr-dg-cell>
        <clr-dg-cell> {{ n.datatype | xproj_prettydatatype}}</clr-dg-cell>
        <!--<clr-dg-cell> {{ n.writable | xproj_pretty}}</clr-dg-cell>-->
        <clr-dg-cell> {{ n.unit | xproj_pretty}}</clr-dg-cell>
        <clr-dg-cell> {{ n.tags | xproj_pretty}}</clr-dg-cell>

      </clr-dg-row>

      <clr-dg-footer>{{Columns.length}} Columns</clr-dg-footer>

    </clr-datagrid>
  </div>

  <div class="limit-heightx2" *ngIf="QueryIndexer">
    <clr-datagrid (clrDgRefresh)="refreshQueryTable($event)" [clrDgLoading]="loadingQuery">

      <clr-dg-action-bar>
        <div class="btn-group">
          <button class="btn btn-sm btn-secondary" (click)="refreshQueryTable()">
              <clr-icon shape="refresh" directions="left"></clr-icon> Refresh
          </button>
      </div>
    </clr-dg-action-bar>

      <clr-dg-column [clrDgField]="item.key" *ngFor="let item of queryData | keyvalue">
        {{item.key}}
        <clr-dg-filter *ngIf="queryData[item.key]['datatype'] == 7">
          <xproj-datagrid-string-filter [group]="queryableSelectedGroup"
            [projectionid]="selectedProjection.projectionid" [columnname]="item.key"></xproj-datagrid-string-filter>
        </clr-dg-filter>
      </clr-dg-column>


      <clr-dg-row *ngFor="let obj of QueryIndexer.data ; index as i">
        <clr-dg-cell *ngFor="let item of queryData | keyvalue"> {{ item.value.data[i] | xproj_pretty}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="QueryNrTotalRows">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{QueryNrTotalRows}} Rows
        </clr-dg-pagination>
      </clr-dg-footer>

    </clr-datagrid>
  </div>
  <!-- </clr-tab-content> -->

  <!-- </clr-tab> -->
  <!-- <clr-tab>
            <button clrTabLink>Graph</button>

            <clr-tab-content *clrIfActive>
				<chart [columndescriptors]="Columns" [group]="selectedGroup?.group" type="line" dynamicinput="true"></chart>
            </clr-tab-content>
        </clr-tab> -->
  <!-- </clr-tabs> -->
</div>
