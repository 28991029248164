import { RossakerBmsMeterState } from './rossaker-bms-meter';

export class RossakerBmsMeterData {

  //realestate
  svlantPropertyDesignation: string;

  //apartment / facility
  externalId: string;
  svlantApartmentno: number;
  facilityType : string;
  prefix : string;
  area : number;
  size : number;
  floor : number;

  //building
  svLantBuildingNo: number;

  //buildingaddress
  street: string;
  housenumber: string;
  postalcode : string;
  city : string;
  district : string;

  //gateway
  gw_serialnumber : string;
  gw_vendor : string;

  //meter
  seconadaryAddress : string;
  manufacturer : string;
  unit : string;
  datapointValueUnit : string;
  variable : string;
  meterType : string;
  meterSubtype : string;
  index : number;
  state : string; //BmsMeterState;
  value : number;
  timestamp : Date;
  tag : string;

  //ids
  meterId: number;
  apartmentId: number;
  facilityId: number;
  buildingAddressId: number;
  buildingId: number;
  realestateId: number;
  gatewayId: number;
}
