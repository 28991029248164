import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { Customer, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationDataSourceComponent, DropDownItem } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { SplitAreaDirective } from 'angular-split';
import { RossakerBmsDataCollectorViewComponent } from '../rossaker-bms-data-collector-view/rossaker-bms-data-collector-view.component';
import { SessionStorageService } from '@xprojectorcore/services/session-storage.service';
import { XEdgeToken } from '@xprojectorcore/models/xedge-token';
import { XProjectorXEdgeClient } from '@xprojectorcore/xprojector_backend/xprojector-xedge-client';
import { StateService } from '@xprojectorcore/services/state-service';
import { XprojAlertService } from 'xproj-lib';
import { XEdgeSelectXAutoVarsComponent } from '@xprojectorfeatures/xedge/components/xedge-select-xauto-vars/xedge-select-xauto-vars.component';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

declare var XPROJECTOR_HOST;
declare var XEDGE_APP_HOST;

@Component({
  selector: 'app-rossaker-bms-data-collectors',
  templateUrl: './rossaker-bms-data-collectors.component.html',
  styleUrls: ['./rossaker-bms-data-collectors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RossakerBmsDataCollectorsComponent implements OnInit, OnDestroy {

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  @ViewChild("rossakerBmsDataCollectorView", { read: RossakerBmsDataCollectorViewComponent, static: false }) rossakerBmsDataCollectorView: RossakerBmsDataCollectorViewComponent;
  @ViewChild("selectXEdgeMeters", { read: XEdgeSelectXAutoVarsComponent, static: false }) selectXEdgeMeters: XEdgeSelectXAutoVarsComponent;
  @ViewChild("dataCollectorsConfigurationDatasource", { read: ConfigurationDataSourceComponent, static: false }) dataCollectorsConfigurationDatasource: ConfigurationDataSourceComponent;

  rightPanelWidth: number = 600;

  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;

  dataCollectorsDataSourceInstance: GrpcDataSourceInstance;
  selectedDataCollectorsTreeNode: GrpcNode;

  dataColelctorsActive : boolean = true;

  showXEdgeSelectMetersModal : boolean = false;
  xedgeRemoteNodeId : string;

  dropDownItems: DropDownItem[] = [
    {
      name: 'XEdge Config',
      shape: 'cog',
      nodeTypeId: '_x_xedge_server',
      onClick: this.viewXEdge.bind(this)
    },
  ]

  dropDownItemsDataCollectors: DropDownItem[] = [
    {
      name: 'XEdge Config',
      shape: 'cog',
      nodeTypeId: '_x_xedge_server',
      onClick: this.viewXEdge.bind(this)
    },
    {
      name: 'Select meters...',
      shape: 'dashboard',
      nodeTypeId: '_x_xedge_server',
      onClick: this.xedgeSelectMeters.bind(this)
    }
  ];

  constructor(
    private state: StateService,
    private rossakerState : RossakerStateService,
    private xconfClient: XProjectorXConfClient,
    private sessionStorageService : SessionStorageService,
    private xedgeClient : XProjectorXEdgeClient,
    private alertService: XprojAlertService
  ) { }

  ngOnInit(): void {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      await this.updateCustomer();
    });

    let lscontentPanelWidth = Number.parseInt(localStorage.getItem("xprojector-rossaker-datacollectors-rightPanelWidth") || this.rightPanelWidth.toString());
    if (lscontentPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lscontentPanelWidth;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async updateCustomer() {
    if (this.selectedBmsCustomer) {
      this.dataCollectorsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_datacollectors_' + this.selectedBmsCustomer.customerId);
    }
  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.selectedDataCollectorsTreeNode = item.treeNode.node;
    this.rossakerBmsDataCollectorView?.setDataNode(this.selectedDataCollectorsTreeNode, this.rightPanelWidth);
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }


  async onSplitDragEnd() {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-rossaker-datacollectors-rightPanelWidth", this.rightPanelWidth.toString());
    }

    this.rossakerBmsDataCollectorView?.setWidth(this.rightPanelWidth);
  }

  async viewXEdge(treeNode: XconfTreeNode) {
    //console.log("laucnhign viewedge for", treeNode);
    let token = new XEdgeToken();
    let session = await this.state.getActiveSession();
    token.token = session?.sessionKey ?? '';
    token.remoteNodeId = treeNode.id;
    token.host = XPROJECTOR_HOST;
    token.name = treeNode.name;
    console.log("setting token", token);
    this.sessionStorageService.setXEdgeToken(token);

    setTimeout(() => {
      //console.log("opening window", XEDGE_APP_HOST);
      let wnd = window.open(XEDGE_APP_HOST);
      token.timestamp = new Date().toLocaleTimeString();
      wnd.sessionStorage.setItem('xedgetoken', JSON.stringify(token));
    }, 100);
  }

  async xedgeSelectMeters(treeNode: XconfTreeNode) {
    this.xedgeRemoteNodeId = treeNode.id;
    this.showXEdgeSelectMetersModal = true;
  }

  async addSelectedXEdgeMeters() {
    this.showXEdgeSelectMetersModal = false;
    let toAdd = this.selectXEdgeMeters?.selectedXAutoVars;

    if (toAdd) {
      let result = await this.xedgeClient.addXEdgeXAutoMeters(this.selectedCustomer?.id, this.xedgeRemoteNodeId, toAdd);
      if (result.ok) {
        this.alertService.success(result.addedCount + ' meters added.');
        this.dataCollectorsConfigurationDatasource?.refreshTreeView();
      }
      else {
        this.alertService.error(result.message);
      }
    }
  }

}
