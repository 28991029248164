<div>
  <form clrForm>
    <clr-input-container>
      <label>Name</label>
      <input clrInput style="width: 75%;" placeholder="" name="queryname_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.name" />
    </clr-input-container>

    <clr-select-container>
      <label>Projection</label>
      <select clrSelect id="useprojectioninput" name="useprojectioninput"
        [(ngModel)]="queryData.useProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="!queryData.useProjectionInputParameter" name="projection"
        [(ngModel)]="queryData.projection" (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="queryData.useProjectionInputParameter" class="clr-select"
        name="UseProjectionInputParameter" [(ngModel)]="queryData.projectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input / Group</label>
      <select clrSelect id="usegroupinput" name="usegroupinput" [(ngModel)]="queryData.groupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
      </select>

      <select clrSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select"
        name="UseProjectionInputParameter" [(ngModel)]="queryData.groupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <xproj-group-selection #groupSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP"
      [projection]="queryData.projection" [selected]="widgetQuery.Query?.targetgroup"
      (groupSelected)="selectedProjectionGroupChange($event)">
    </xproj-group-selection>

    <clr-combobox-container *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
      <label>Group input selections</label>
      <clr-combobox [(ngModel)]="queryData.groupInputParameterIds" name="multiSelect" clrMulti="true" required>
        <ng-container *clrOptionSelected="let selected">
          {{selected}}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
            [clrValue]="input.id">
            {{input.id}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
  </form>

  <h5>Data</h5>
  <form clrForm *ngIf="queryData.columns">
    <clr-select-container>
      <label>Time filter column</label>
      <select clrSelect id="timefiltercolumn" name="timefiltercolumn" [(ngModel)]="queryData.timefiltercolumn">
        <option *ngFor="let col of queryData.columns | xproj_filtertimestampcolumns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Default sorting column</label>
      <select clrSelect id="sortingcolumn" name="sortingcolumn" [(ngModel)]="queryData.defaultSortColumnName">
        <option [ngValue]=""> </option>
        <option *ngFor="let col of queryData.columnConfigs" [ngValue]="col.ColumnName">
          {{col.ColumnName}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Default sorting order</label>

      <select clrSelect id="sortingorder" name="sortingorder" [(ngModel)]="queryData.defaultSortDescending">
        <option [ngValue]="false"> Ascending </option>
        <option [ngValue]="true"> Descending </option>
      </select>

    </clr-select-container>

    <clr-select-container>
      <label>Grouping</label>

      <select clrSelect id="usegrouping" name="usegrouping" [(ngModel)]="queryData.usegrouping">
        <option [ngValue]="true"> Group </option>
        <option [ngValue]="false">No grouping</option>
      </select>

    </clr-select-container>

    <!-- <clr-toggle-container>
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle value="true" name="disabled" [(ngModel)]="widgetQuery.UseColumnMatching" />
                <label>Position rows with column values</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>

        <clr-select-container *ngIf="widgetQuery.UseColumnMatching">
            <label>Column used to position data</label>

            <select clrSelect id="matchingcolumn" name="matchingoclumn" [(ngModel)]="widgetQuery.MatchColumnName">
        <option *ngFor="let col of queryData.columns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>

        </clr-select-container>

        <clr-select-container *ngIf="widgetQuery.UseColumnMatching">
            <label>Column used for values</label>

            <select clrSelect id="valuescolumnname" name="valuescolumnname" [(ngModel)]="widgetQuery.ValueColumnName">
        <option *ngFor="let col of queryData.columns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>

        </clr-select-container> -->


    <clr-datagrid [(clrDgSingleSelected)]="selectedColumnConfig">
      <clr-dg-column>Column</clr-dg-column>
      <clr-dg-column *ngIf="queryData.usegrouping">Transform / Aggregation</clr-dg-column>
      <clr-dg-column>Unit</clr-dg-column>
      <!-- <clr-dg-column>Position</clr-dg-column> -->

      <clr-dg-row *ngFor="let column of queryData.columnConfigs; let i = index" [clrDgItem]="column">
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" name="tselectqueryData.column{{i}}" id="tselectcolumn_{{i}}"
              [(ngModel)]="column.ColumnName" (ngModelChange)="onColumnSelect($event, i)">
              <option *ngFor="let col of queryData.columns" [ngValue]="col.columnname">
                {{col.columnname}}</option>
            </select>
          </div>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="queryData.usegrouping">
          <div class="clr-select-wrapper">
            <input *ngIf="queryData.usegrouping && i == 0" class="clr-checkbox" type="checkbox" placeholder=""
              name="TransformInput{{i}}" [(ngModel)]="queryData.useTransformInputParameter" />

            <select class="clr-select" style="width:13em;"
              *ngIf="queryData.usegrouping && i == 0 && !queryData.useTransformInputParameter" id="xaxisgrouptransform"
              name="xaxisgrouptransform" [(ngModel)]="queryData.groupingtransform">
              <option [ngValue]="Transformation.NONE"> None </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
              <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
            </select>

            <select *ngIf="queryData.usegrouping && i == 0 && queryData.useTransformInputParameter" class="clr-select"
              style="width:13em;" name="inputtransformparam" [(ngModel)]="queryData.transformInputParameterId">
              <option
                *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>

            <input *ngIf="queryData.usegrouping && i > 0" class="clr-checkbox" type="checkbox" placeholder=""
              name="AggregationInput{{i}}" [(ngModel)]="queryData.columnConfigs[i].UseAggregationInputParameter" />

            <select *ngIf="queryData.usegrouping && i > 0 && !queryData.columnConfigs[i].UseAggregationInputParameter"
              class="clr-select" style="width:13em;" name="tselectqueryData.columnagg{{i}}"
              [(ngModel)]="queryData.columnConfigs[i].Transform">
              <option [ngValue]="Aggregation.NONE"> None </option>
              <option [ngValue]="Aggregation.COUNT"> Count </option>
              <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

              <option [ngValue]="Aggregation.SUM"> Sum </option>

              <option [ngValue]="Aggregation.MAX"> Max </option>
              <option [ngValue]="Aggregation.MIN"> Min </option>

              <option [ngValue]="Aggregation.FIRST"> First </option>
              <option [ngValue]="Aggregation.LAST"> Last </option>

              <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

              <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
              <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
              <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

              <option [ngValue]="Aggregation.MEDIAN"> Median </option>

              <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
              </option>

              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                arithmetic mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
            </select>

            <select *ngIf="queryData.usegrouping && i > 0 && queryData.columnConfigs[i].UseAggregationInputParameter"
              class="clr-select" style="width:13em;" name="inputparam"
              [(ngModel)]="queryData.columnConfigs[i].AggregationInputParameterId">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </div>
        </clr-dg-cell>

        <!-- <clr-dg-cell>
            <input class="clr-input" placeholder="" name="tlabel{{i}}" [(ngModel)]="queryData.columnConfigs[i].Label" />
          </clr-dg-cell> -->

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="unit{{i}}" [(ngModel)]="queryData.columnConfigs[i].Unit" />
        </clr-dg-cell>

        <!--
                <clr-dg-cell>
                    <input class="clr-input" placeholder="" name="position{{i}}" [(ngModel)]="queryData.columnConfigs[i].Position" />
                </clr-dg-cell> -->
      </clr-dg-row>
    </clr-datagrid>


    <clr-dropdown [clrCloseMenuOnItemClick]="true">
      <button clrDropdownTrigger aria-label="More actions">
        <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
        <!-- <clr-icon shape="caret down"></clr-icon> -->
      </button>
      <clr-dropdown-menu *clrIfOpen>
        <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addAllColumns()">Add all columns</div>
        <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="removeAllColumns()">Remove all columns</div>
      </clr-dropdown-menu>
    </clr-dropdown>

    <button type="button" class="btn btn-link" (click)="addColumn()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add column
    </button>
    <button *ngIf="selectedColumnConfig" type="button" class="btn btn-link" (click)="removeSelectedColumn()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected column
    </button>


    <div *ngIf="selectedColumnConfig" style="max-width: 600px;">
      <div class="card">
        <div class="card-header">
          Editing column
        </div>

        <div class="card-block">
          <div class="card-title">
            {{selectedColumnConfig.ColumnName}} ({{selectedColumnConfig.Label}})
          </div>
          <form clrForm>
            <clr-input-container>
              <label>Position</label>
              <input clrInput placeholder="positionid" type="text" name="positionid"
                [(ngModel)]="selectedColumnConfig.PositionD" size="35" />
            </clr-input-container>
            <button type="button" class="btn btn-outline" (click)="toggleBindText()">Bind to text</button>


            <clr-checkbox-container>
              <!-- <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox name="hidden" [(ngModel)]="selectedColumnConfig.Hidden" />
                  <label>Hidden</label>
                </clr-checkbox-wrapper> -->
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="clickable" [(ngModel)]="selectedColumnConfig.Clickable" />
                <label>Clickable</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-select-container *ngIf="selectedColumnConfig.Clickable">
              <label>Output column</label>
              <select clrSelect id="outputcolumn" name="outputcolumn"
                [(ngModel)]="selectedColumnConfig.ClickableOutputColumnId">
                <option [ngValue]=""> </option>
                <option *ngFor="let col of queryData.columnConfigs" [ngValue]="col.Id">
                  {{col.ColumnName}}</option>
              </select>
            </clr-select-container>
          </form>
        </div>
      </div>
    </div>
  </form>

  <h5>Filters</h5>
  <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="queryData.projection?.projectionid"
    [columnDescriptions]="queryData.columns" [group]="queryData.selectedGroup" [datafilters]="widgetQuery.DataFilters"
    [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType" [inputParameters]="inputParameters"
    [widgets]="widgets">
  </xproj-projection-filter>
  <br />

  <h5>Data Update Lua</h5>
  <ngx-monaco-editor style="height:40em" name="editqueryrendering" [options]="editorOptionsLua"
    [(ngModel)]="widgetQuery.OnDataUpdateLuaScript">
  </ngx-monaco-editor>

  <button class="btn btn-danger-outline" (click)="removeQuery()">Remove query</button>
</div>
