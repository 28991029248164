import { Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RossakerBmsDataCollectorView } from '@core/models/rossaker-bms-data-collector-view';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { RossakerBmsDataCollectorViewDirective } from '@features/rossaker-bms/rossaker-bms-admin/directives/rossaker-bms-data-collector-view.directive';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { Customer, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { takeUntil } from 'rxjs/operators';
import { RossakerBmsDefaultDataCollectorViewComponent } from '../rossaker-bms-data-collector-views/rossaker-bms-default-data-collector-view/rossaker-bms-default-data-collector-view.component';

@Component({
  selector: 'app-rossaker-bms-data-collector-view',
  templateUrl: './rossaker-bms-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-data-collector-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RossakerBmsDataCollectorViewComponent implements OnInit, OnDestroy {

  @ViewChild(RossakerBmsDataCollectorViewDirective, { static: true }) dataCollectorViewHost!: RossakerBmsDataCollectorViewDirective;

  // @Input() dataCollectorId: string = '';
  // @Input() nodeId: string = '';

  ngUnsubscribe = new Subject<void>();

  dataCollectorViews: RossakerBmsDataCollectorView[] = [];

  componentRef : ComponentRef<RossakerBmsDataCollectorComponent>;

  selectedNode: GrpcNode;
  areaWidth : number;

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;

  constructor(
    public rossakerState: RossakerStateService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;
    });

    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  async setDataNode(selectedDataCollectorsTreeNode: GrpcNode, width : number) {
    this.areaWidth = width;
    this.selectedNode = selectedDataCollectorsTreeNode;
    await this.loadComponent();
  }

  setWidth(width : number) {
    if (this.componentRef) {
      this.componentRef.instance.setWidth(width);
    }
  }

  async loadComponent() {
    if (this.dataCollectorViews.length == 0) {
      this.dataCollectorViews = this.rossakerState.getDataCollectoViews();
    }

    if (this.selectedNode) {
      let dataCollectorView = this.dataCollectorViews.find(p => p.id == this.selectedNode.nodeTypeId);

      let viewContainerRef = this.dataCollectorViewHost.viewContainerRef;
      viewContainerRef.clear();

      if (!dataCollectorView) {
        dataCollectorView = this.dataCollectorViews.find(p => p.id == this.selectedNode.nodeTypeLabel);
      }

      if (!dataCollectorView) {
        dataCollectorView = this.dataCollectorViews.find(p => p.id == RossakerBmsDefaultDataCollectorViewComponent.NodeTypeId);
      }

      if (dataCollectorView) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(dataCollectorView.component);

        this.componentRef = viewContainerRef.createComponent<RossakerBmsDataCollectorComponent>(componentFactory);
        let data = new RossakerBmsDataCollectorViewData();
        data.node = this.selectedNode;
        data.customer = this.selectedCustomer;
        data.bmsCustomer = this.selectedBmsCustomer;
        data.width = this.areaWidth;
        this.componentRef.instance.visible = true;
        this.componentRef.instance.data = data;

        await this.componentRef.instance.initDataCollectorView();
      }
    }
  }
}
