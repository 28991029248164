import { jsonObject, jsonMember, TypedJSON, jsonArrayMember, jsonMapMember } from 'typedjson';

import { Injectable } from '@angular/core';
import { MsgPackCloneObject, XAUTO_PLCVariableNumericalMapXAutoVariable, XAUTO_XAutoProgram, XAUTO_XAutoProgramLua, XAUTO_XAutoProgramType, XProjectorClient } from '../XProjector/xprojector-client-service';

@Injectable({
  providedIn: 'root'
})
export class EditplcprogramService {

  public Program: XAUTO_XAutoProgram = null;
  public LuaProgram : XAUTO_XAutoProgramLua = null;

  public LastRunOutput : string = "";

  constructor(private xprojClient: XProjectorClient)
  {
  }

  public async LoadProgram(programid : string, forceReload : boolean = false) : Promise<boolean>
  {
    if(!forceReload && this.Program)
    {
      if(this.Program.xautoprogramid == programid)
        return true;
    }

    let strProg = localStorage.getItem("xauto-program-" + programid);
    if(strProg && strProg.length > 0)
    {
      this.Program = TypedJSON.parse(strProg, XAUTO_XAutoProgram);
      if(this.Program.type == XAUTO_XAutoProgramType.LUA)
      {
        let strLuaProg = localStorage.getItem("xauto-program-lua-" + programid);
        this.LuaProgram = TypedJSON.parse(strLuaProg, XAUTO_XAutoProgramLua);
      }
    }

    if(this.Program && this.Program.xautoprogramid == programid)
      return true;

    return this.FetchProgram(programid);
  }

  public async FetchProgram(programid : string) : Promise<boolean>
  {
    try{
      this.Program = await this.xprojClient.XAUTO_GetProgram(programid);
      if(this.Program.type == XAUTO_XAutoProgramType.LUA)
      {
        this.LuaProgram = await this.xprojClient.XAUTO_GetProgramLua(programid);
      }

      return true;
    }
    catch
    {
      this.Program = new XAUTO_XAutoProgram();
      this.LuaProgram = null;
      return false;
    }

  }

  public async SaveProgram()
  {
    let strProg = TypedJSON.stringify(this.Program, XAUTO_XAutoProgram);
    localStorage.setItem( "xauto-program-" + this.Program.xautoprogramid, strProg );
    if(this.Program.type == XAUTO_XAutoProgramType.LUA)
    {
      let strLuaProg = TypedJSON.stringify(this.LuaProgram, XAUTO_XAutoProgramLua);
      localStorage.setItem( "xauto-program-lua-" + this.Program.xautoprogramid, strLuaProg );
    }
  }

  public async DeployProgram()
  {
    // First we disable the program, so we can save everything needed
    let isEnabled = this.Program.enabled;
    this.Program.enabled = false;
    await this.xprojClient.XAUTO_SaveProgram(this.Program);

    // Save everything else to xauto
    if(this.Program.type == XAUTO_XAutoProgramType.LUA)
    {
      await this.xprojClient.XAUTO_SaveProgramLua(this.LuaProgram);
    }

    // Lastly we enable it, if it was enabled before
    if(isEnabled)
    {
      this.Program.enabled = true;
      await this.xprojClient.XAUTO_SaveProgram(this.Program);
    }
  }

  public async TestRunProgram() : Promise<string>
  {
    //let dProgram = MsgPackCloneObject(this.Program) as XAUTO_XAutoProgram;
    //dProgram.numericalmapxautovariables = new Array<XAUTO_PLCVariableNumericalMapXAutoVariable>();
    //delete dProgram.type;
    //delete dProgram.xautoprogramid;
    //delete dProgram.xautogroup;
    //delete dProgram.xgroup;
    //delete dProgram.name;
    //delete dProgram.description;
    //delete dProgram.numericalmapxautovariables;
    //delete dProgram.lastmodifiedutc;
    // delete dProgram.laststartedutc;
    // delete dProgram.lastfinishedutc;
    // delete dProgram.lasterrorutc;
    // delete dProgram.lasterror;
    //delete dProgram.enabled;
    //console.log(dProgram);
    this.LastRunOutput = await this.xprojClient.XAUTO_ExecuteLuaProgram(this.Program, this.LuaProgram);
    return this.LastRunOutput;
    //return await this.xprojClient.XAUTO_ExecuteLuaProgram(this.Program, this.LuaProgram);
  }

}

