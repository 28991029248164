/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
/**
 * Message implementation for files.GetFileInfoRequest
 */
export class GetFileInfoRequest implements GrpcMessage {
  static id = 'files.GetFileInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFileInfoRequest();
    GetFileInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFileInfoRequest) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFileInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFileInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFileInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _id?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFileInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFileInfoRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    GetFileInfoRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFileInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFileInfoRequest.AsObject {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFileInfoRequest.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }
}
export module GetFileInfoRequest {
  /**
   * Standard JavaScript object representation for GetFileInfoRequest
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetFileInfoRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for files.DeleteFileInfoRequest
 */
export class DeleteFileInfoRequest implements GrpcMessage {
  static id = 'files.DeleteFileInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteFileInfoRequest();
    DeleteFileInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteFileInfoRequest) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteFileInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteFileInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteFileInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _id?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteFileInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteFileInfoRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    DeleteFileInfoRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteFileInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteFileInfoRequest.AsObject {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteFileInfoRequest.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }
}
export module DeleteFileInfoRequest {
  /**
   * Standard JavaScript object representation for DeleteFileInfoRequest
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteFileInfoRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for files.GetFileInfosRequest
 */
export class GetFileInfosRequest implements GrpcMessage {
  static id = 'files.GetFileInfosRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFileInfosRequest();
    GetFileInfosRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFileInfosRequest) {
    _instance.tags = _instance.tags || [];
    _instance.limit = _instance.limit || 0;
    _instance.skip = _instance.skip || 0;
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFileInfosRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.tags = _instance.tags || []).push(_reader.readString());
          break;
        case 2:
          _instance.limit = _reader.readInt32();
          break;
        case 3:
          _instance.skip = _reader.readInt32();
          break;
        case 4:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFileInfosRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFileInfosRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.tags && _instance.tags.length) {
      _writer.writeRepeatedString(1, _instance.tags);
    }
    if (_instance.limit) {
      _writer.writeInt32(2, _instance.limit);
    }
    if (_instance.skip) {
      _writer.writeInt32(3, _instance.skip);
    }
    if (_instance.customerId) {
      _writer.writeString(4, _instance.customerId);
    }
  }

  private _tags?: string[];
  private _limit?: number;
  private _skip?: number;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFileInfosRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFileInfosRequest.AsObject>) {
    _value = _value || {};
    this.tags = (_value.tags || []).slice();
    this.limit = _value.limit;
    this.skip = _value.skip;
    this.customerId = _value.customerId;
    GetFileInfosRequest.refineValues(this);
  }
  get tags(): string[] | undefined {
    return this._tags;
  }
  set tags(value: string[] | undefined) {
    this._tags = value;
  }
  get limit(): number | undefined {
    return this._limit;
  }
  set limit(value: number | undefined) {
    this._limit = value;
  }
  get skip(): number | undefined {
    return this._skip;
  }
  set skip(value: number | undefined) {
    this._skip = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFileInfosRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFileInfosRequest.AsObject {
    return {
      tags: (this.tags || []).slice(),
      limit: this.limit,
      skip: this.skip,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFileInfosRequest.AsProtobufJSON {
    return {
      tags: (this.tags || []).slice(),
      limit: this.limit,
      skip: this.skip,
      customerId: this.customerId
    };
  }
}
export module GetFileInfosRequest {
  /**
   * Standard JavaScript object representation for GetFileInfosRequest
   */
  export interface AsObject {
    tags?: string[];
    limit?: number;
    skip?: number;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetFileInfosRequest
   */
  export interface AsProtobufJSON {
    tags?: string[];
    limit?: number;
    skip?: number;
    customerId?: string;
  }
}

/**
 * Message implementation for files.GrpcFileInfo
 */
export class GrpcFileInfo implements GrpcMessage {
  static id = 'files.GrpcFileInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcFileInfo();
    GrpcFileInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcFileInfo) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.tags = _instance.tags || [];
    _instance.fileFormat = _instance.fileFormat || '';
    _instance.path = _instance.path || '';
    _instance.size = _instance.size || '0';
    _instance.lastModified = _instance.lastModified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcFileInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          (_instance.tags = _instance.tags || []).push(_reader.readString());
          break;
        case 4:
          _instance.fileFormat = _reader.readString();
          break;
        case 5:
          _instance.path = _reader.readString();
          break;
        case 6:
          _instance.size = _reader.readInt64String();
          break;
        case 7:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcFileInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcFileInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.tags && _instance.tags.length) {
      _writer.writeRepeatedString(3, _instance.tags);
    }
    if (_instance.fileFormat) {
      _writer.writeString(4, _instance.fileFormat);
    }
    if (_instance.path) {
      _writer.writeString(5, _instance.path);
    }
    if (_instance.size) {
      _writer.writeInt64String(6, _instance.size);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        7,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _name?: string;
  private _tags?: string[];
  private _fileFormat?: string;
  private _path?: string;
  private _size?: string;
  private _lastModified?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcFileInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcFileInfo.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.tags = (_value.tags || []).slice();
    this.fileFormat = _value.fileFormat;
    this.path = _value.path;
    this.size = _value.size;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    GrpcFileInfo.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get tags(): string[] | undefined {
    return this._tags;
  }
  set tags(value: string[] | undefined) {
    this._tags = value;
  }
  get fileFormat(): string | undefined {
    return this._fileFormat;
  }
  set fileFormat(value: string | undefined) {
    this._fileFormat = value;
  }
  get path(): string | undefined {
    return this._path;
  }
  set path(value: string | undefined) {
    this._path = value;
  }
  get size(): string | undefined {
    return this._size;
  }
  set size(value: string | undefined) {
    this._size = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcFileInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcFileInfo.AsObject {
    return {
      id: this.id,
      name: this.name,
      tags: (this.tags || []).slice(),
      fileFormat: this.fileFormat,
      path: this.path,
      size: this.size,
      lastModified: this.lastModified ? this.lastModified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcFileInfo.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      tags: (this.tags || []).slice(),
      fileFormat: this.fileFormat,
      path: this.path,
      size: this.size,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null
    };
  }
}
export module GrpcFileInfo {
  /**
   * Standard JavaScript object representation for GrpcFileInfo
   */
  export interface AsObject {
    id?: string;
    name?: string;
    tags?: string[];
    fileFormat?: string;
    path?: string;
    size?: string;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GrpcFileInfo
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    tags?: string[];
    fileFormat?: string;
    path?: string;
    size?: string;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for files.GetFileInfosResponse
 */
export class GetFileInfosResponse implements GrpcMessage {
  static id = 'files.GetFileInfosResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFileInfosResponse();
    GetFileInfosResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFileInfosResponse) {
    _instance.fileCount = _instance.fileCount || 0;
    _instance.fileInfos = _instance.fileInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFileInfosResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileCount = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new GrpcFileInfo();
          _reader.readMessage(
            messageInitializer2,
            GrpcFileInfo.deserializeBinaryFromReader
          );
          (_instance.fileInfos = _instance.fileInfos || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFileInfosResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFileInfosResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.fileCount) {
      _writer.writeInt32(1, _instance.fileCount);
    }
    if (_instance.fileInfos && _instance.fileInfos.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.fileInfos as any,
        GrpcFileInfo.serializeBinaryToWriter
      );
    }
  }

  private _fileCount?: number;
  private _fileInfos?: GrpcFileInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFileInfosResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFileInfosResponse.AsObject>) {
    _value = _value || {};
    this.fileCount = _value.fileCount;
    this.fileInfos = (_value.fileInfos || []).map(m => new GrpcFileInfo(m));
    GetFileInfosResponse.refineValues(this);
  }
  get fileCount(): number | undefined {
    return this._fileCount;
  }
  set fileCount(value: number | undefined) {
    this._fileCount = value;
  }
  get fileInfos(): GrpcFileInfo[] | undefined {
    return this._fileInfos;
  }
  set fileInfos(value: GrpcFileInfo[] | undefined) {
    this._fileInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFileInfosResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFileInfosResponse.AsObject {
    return {
      fileCount: this.fileCount,
      fileInfos: (this.fileInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFileInfosResponse.AsProtobufJSON {
    return {
      fileCount: this.fileCount,
      fileInfos: (this.fileInfos || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetFileInfosResponse {
  /**
   * Standard JavaScript object representation for GetFileInfosResponse
   */
  export interface AsObject {
    fileCount?: number;
    fileInfos?: GrpcFileInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetFileInfosResponse
   */
  export interface AsProtobufJSON {
    fileCount?: number;
    fileInfos?: GrpcFileInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for files.GetFileRequest
 */
export class GetFileRequest implements GrpcMessage {
  static id = 'files.GetFileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFileRequest();
    GetFileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFileRequest) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetFileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _id?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetFileRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    GetFileRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFileRequest.AsObject {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFileRequest.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId
    };
  }
}
export module GetFileRequest {
  /**
   * Standard JavaScript object representation for GetFileRequest
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetFileRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
  }
}
