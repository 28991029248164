

<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->

    <div class="splash-container">
        <div style="display: table;">
            <h1>Contact Us</h1>
        </div>
    </div>

    <!-- <nav class="subnav">
        <ul class="nav">
            <li class="nav-item">
                <a routerLink="#BigData" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'BigData' }" class="nav-link nav-text boldtext"> Big Data </a>
            </li>
            <li class="nav-item">
                <a routerLink="#Automation" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'BigData' }" class="nav-link nav-text boldtext"> Reporting </a>
            </li>
            <li class="nav-item">
                <a routerLink="#Optimizations" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'BigData' }" class="nav-link nav-text boldtext"> Automation </a>
            </li>
            <li class="nav-item">
                <a routerLink="#Communications" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'BigData' }" class="nav-link nav-text boldtext"> Scada </a>
            </li>
        </ul>
    </nav>     -->

    <div class="content">
        <!-- <div>
            <a id="Sales"><h2>Sales</h2></a>
            Contact our sale team for pricing &amp; demo request.
        </div>

        <div>
            <a id="Solutions"><h2>Solutions</h2> </a>
            Contact our solutions team for specific requests on specific solutions or services.
        </div>
        <div>
            <a id="Communications"><h2>After market</h2></a>
            Contact our after market team if you have questions concerning completed projects or deliveries.
        </div> -->

        <h2>Contact Us</h2>
        <p>Fill in the form below and we will get back to you as soon as possible!</p>

        <form clrForm *ngIf="notsaved">
            <clr-input-container>
                <label>First name </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.firstname" name="firstname" size="35" />
            </clr-input-container>
            <clr-input-container>
                <label>Last name </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.surname" name="surname" size="35" />
            </clr-input-container>
            <clr-input-container>
                <label>E-mail </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.email" name="email" size="35" />
            </clr-input-container>
            <clr-input-container>
                <label>Phone </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.phone" name="phone" size="35" />
            </clr-input-container>
            <clr-input-container>
                <label>Company </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.company" name="company" size="35" />
            </clr-input-container>
            <clr-input-container>
                <label>Work Title/Role </label>
                <input clrInput type="text" [(ngModel)]="contactInfo.worktitle" name="worktitle" size="35" />
            </clr-input-container>
            <clr-textarea-container>
                <label>Message </label>
                <textarea style="max-width: 100%; width: 40em;height: 8em;" clrTextarea [(ngModel)]="contactInfo.message" name="message" size="35" ></textarea>
            </clr-textarea-container>

            <button class="btn" [clrLoading]="savingPost" (click)="submit()">Save</button>

        </form>

        <div *ngIf="!notsaved">
            <h2>Thank you for your message, we will get to you as soon as possible.</h2>
        </div>
    </div>

</div>
