import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class XprojModalService {

  public showConfirmModalSubject: Subject<{data : any, callback: (result : boolean) => void}> = new Subject();
  public showInputModalSubject: Subject<{data : any, callback: (result : boolean, value : any) => void}> = new Subject();


  public ShowConfirmModal(data : any, callback: (result : boolean) => void) {
		this.showConfirmModalSubject.next({data: data, callback: callback});
	}

  public async ShowConfirmModalAsync(data : any) : Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.showConfirmModalSubject.next({data: data, callback: (result : boolean) => {
        resolve(result);
      }});
    }
    );
  }

  public ShowInputModal(data : any, callback: (result : boolean, value : any) => void) {
		this.showInputModalSubject.next({data: data, callback: callback});
	}

  public async ShowInputModalAsync(data : any) : Promise<{result : boolean, value : any}>{
    return new Promise<{result : boolean, value : any}>(resolve => {
      this.showInputModalSubject.next({data: data, callback: (result : boolean, value : any) => {
        resolve({result : result, value : value});
      }});
    }
    );
  }

}
