import { GrpcStringOptionsProperty } from "@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb";
import { RossakerBmsBillingPeriod, RossakerBmsMonthOfYear } from "./rossaker-bms-export-bot";

export class RossakerBmsCustomerConfig {
  customerId : string;
  organisationNumber : string;
  associationId : string;
  associationId2 : string;
  tariffDecimalCount : number;
  reportDataPointDecimalCount : number;
  trustee : string;
  customerIsTrustee : boolean;
  billingEnabled : boolean;
  billingPeriod : RossakerBmsBillingPeriod = RossakerBmsBillingPeriod.Month;
  billingPeriodChangedAt : Date;
  billingPeriodChangedAtString : string;
  billingPeriodStartMonth : RossakerBmsMonthOfYear = RossakerBmsMonthOfYear.March;
  extrapolateCoefficient : number;
  paddingExternalIdCount : number;
  paddingExternalIdOverrideTrustee: boolean;
  disabled : boolean;
  mergeChargingStationsWithEl : boolean;
  contractType : string;
  contractTypeStringOptionsProperty : GrpcStringOptionsProperty;
  simCardCount : number;
  eventsEnabled : boolean;
}
