import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { BaseControllerConfig, RadioButtonConfig, XprojOutputRadioButtonControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-radiobutton-controller',
  templateUrl: './xproj-output-radiobutton-controller.component.html',
  styleUrls: ['./xproj-output-radiobutton-controller.component.scss']
})
export class XprojOutputRadiobuttonControllerComponent extends XprojOutputControllerBase implements OnInit, AfterViewInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      if (!this.useApplyButton) {
        this.radioButtonChanged();
      }
    }
    this._initDone = value;
  }

  buttonConfig: XprojOutputRadioButtonControllerConfig;

  selectedValue: number = 0;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.buttonConfig = this.config as XprojOutputRadioButtonControllerConfig;

    let i = 0;
    this.buttonConfig.RadioButtons.forEach(button => {
      if (button.Default) {
        this.selectedValue = i;
      }
      i++;
    });

  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let button = this.buttonConfig.RadioButtons[this.selectedValue];
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.buttonConfig.Id;
    output.datatype = this.buttonConfig.DataType;
    switch (this.buttonConfig.DataType) {
      case OutputDataType.String:
        output.value = button.ValueString;
        break;
      case OutputDataType.Timestamp:
        output.value = button.ValueDate;
        break;
      default:
        output.value = button.ValueNumber;
        break;
    }

    return [output];
  }

  radioButtonChanged($event?) {
    if (!this.useApplyButton) {
      this.widgetService.outputParameterChanged(this.getOutputChangeParameters()[0]);
    }
  }

}

