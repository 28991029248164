<main class="content-area">
    <h1>Physical - GPIO Chipsets</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedConnector" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

            <clr-dg-column [clrDgField]="'Driver.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Physical.chipsetname'" [clrDgSortOrder]="ascSort"> Chipset </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxautogroup'"> Default XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxgroup'"> Default XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Physical.chipsetname}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Physical Connectors
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addConnector()">
        <clr-icon shape="plus-circle "></clr-icon>
        New GPIO Chipset
    </button>
    <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove connector
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedConnector">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Connector configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Driver ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.xautodriverid" name="xautodriverid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.name" name="drivername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.description" name="driverdescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxautogroup" name="driverdefaultxautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxgroup" name="driverdefaultxgroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Chipset name</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Physical.chipsetname" name="driverhost" size="35" />
                    </clr-input-container>


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="driverenabled" [(ngModel)]="selectedConnector.Driver.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>


                    <button class="btn" [clrLoading]="savingDriver" (click)="saveDriver()">Save</button>

                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Connector Variables</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedVariable" [clrDgRowSelection]="true" [clrDgLoading]="loadingVariables">

                        <clr-dg-column [clrDgField]="'Variable.enabled'"> Enabled </clr-dg-column>
                        <clr-dg-column [clrDgField]="'Variable.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
                        <clr-dg-column [clrDgField]="'Variable.description'"> Description </clr-dg-column>
                        <clr-dg-column [clrDgField]="'Variable.xautogroup'"> XAutoGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'Variable.xgroup'"> XGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'Variable.defaultscriptname'"> Default scriptname </clr-dg-column>

                        <clr-dg-row *clrDgItems="let variable of selectedConnector.Variables" [clrDgItem]="variable">
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.enabled}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.name}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.description}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xautogroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xgroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.defaultscriptname}} </clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[3]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Variables
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <button class="btn btn-link " (click)="addVariable()">
                    <clr-icon shape="plus-circle "></clr-icon>
                    New Gpio variable
                </button>
                <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingVariable" (click)="removeVariable(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove variable
                </button>


                <form clrForm *ngIf="selectedVariable">
                    <clr-input-container>
                        <label>Variable ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautovariableid" name="variablexautovariableid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.name" name="variablename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.description" name="variabledescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautogroup" name="variablexautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xgroup" name="variablexgroup" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variablewritable" [(ngModel)]="selectedVariable.Variable.writable" />
                            <label>Writable</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-input-container>
                        <label>Sample interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.sampleintervalseconds" name="variablesampleinterval" size="35" />
                    </clr-input-container>

                    <clr-select-container>
                        <label>Mode</label>
                        <select clrSelect name="drivergpiomode" [(ngModel)]="selectedVariable.Physical.mode">
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.AnalogueIn">INPUT: Analogue</option>
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.DigitalIn">INPUT: Digital</option>
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.PulseCount">INPUT: Pulse Count</option>
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.Frequency">INPUT: Frequency measurement</option>
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.AnalogueOut">OUTPUT: Analogue</option>
                            <option [ngValue]="XAUTO_XAutoVariableGpioMode.DigitalOut">OUTPUT: Digital</option>
                        </select>
                    </clr-select-container>

                    <clr-input-container>
                        <label>Line Offset</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Physical.lineoffset" name="variablelineoffset" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Voltage Low</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Physical.voltagelow" name="variablevoltagelow" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Voltage High</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Physical.voltagehigh" name="variablevoltagehigh" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Max Voltage</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Physical.maxvoltage" name="variablemaxvoltage" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Min Voltage</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Physical.minvoltage" name="variableminvoltage" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="varibalenormalized" [(ngModel)]="selectedVariable.Physical.normalized" />
                            <label>Normalized</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableenabled" [(ngModel)]="selectedVariable.Variable.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form>

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main>