import { Injectable } from '@angular/core';
import { XprojDashboardService, DashboardConfig } from 'xproj-lib';
import { XProjectorDashboardClient } from '../xprojector_backend/xprojector-dashboard-client';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends XprojDashboardService {

  constructor(private dashboardClient: XProjectorDashboardClient) {
    super();
  }

  loadDashboard(id: string, version : number = -1, tag : string = '', systemDashboard : boolean = false): Promise<DashboardConfig> {

    return new Promise((resolve) => {
      this.dashboardClient.loadDashboard(id, version, tag, systemDashboard).then(d => {
        resolve(d);
      })
      .catch(async (err) => {
        this.dashboardClient.getDashboard(id, systemDashboard).then(d => {
          resolve(d);
        })
        .catch((err) => {
          let d = new DashboardConfig();
          d.Id = id;
          resolve(d);
        });
      });
    });
    // } else {
    //   return this.dashboardClient.getDashboard(id, systemDashboard);
    // }
    // if (isMobile && !id.endsWith('-mobile')) {
    //   return new Promise((resolve) => {
    //     this.dashboardClient.getDashboard(id + '-mobile', systemDashboard).then(d => {
    //       resolve(d);
    //     })
    //     .catch(async (err) => {
    //       let d = await this.dashboardClient.getDashboard(id, systemDashboard);
    //       resolve(d);
    //     });
    //   });
    // } else {
    //   return this.dashboardClient.getDashboard(id, systemDashboard);
    // }
  }

  saveDashboard(dashboardConfig: DashboardConfig): Promise<boolean> {
    return this.dashboardClient.saveDashboard(dashboardConfig);
  }
}
