import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { CacheService } from 'xproj-lib';
import { UnitConversion } from '../models/unit-conversion';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, FilterComparator, FilterLogicalGroupType, XDataType, XProjectorClient } from 'xproj-lib';
import { NGXLogger } from 'ngx-logger';

const UNITCONVERSIONSCACHEKEY: string = 'unitconversions';
const UNITCONVERIONS_PROJECTIONID: string = 'unit-conversions';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionsService {

  unitConversionsColumns: BaseQueryInputColumnDescription[] = [];


  constructor(private xprojClient: XProjectorClient,
    private readonly cache: CacheService,
    private logger: NGXLogger) {

  }

  private async _getUnitConversions(): Promise<UnitConversion[]> {
    if (this.cache.has(UNITCONVERSIONSCACHEKEY)) {
      return this.cache.get(UNITCONVERSIONSCACHEKEY);
    }
    else {
      let result : UnitConversion[];
      if (this.unitConversionsColumns.length == 0) {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = inCol.columnoutname = 'id';
        this.unitConversionsColumns.push(inCol);

        inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = inCol.columnoutname = 'derivedfrom';
        this.unitConversionsColumns.push(inCol);

        inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = inCol.columnoutname = 'unit';
        this.unitConversionsColumns.push(inCol);

        inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = inCol.columnoutname = 'factorderived';
        this.unitConversionsColumns.push(inCol);
      }

      result = [];

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = UNITCONVERIONS_PROJECTIONID;
      query.maxitems = 1000;

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      query.columns = this.unitConversionsColumns;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let unitConversion = new UnitConversion();

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                unitConversion.id = data[row];
                break;
              case 'derivedfrom':
                unitConversion.derivedFrom = data[row];
                break;
              case 'unit':
                unitConversion.unit = data[row];
                break;
              case 'factorderived':
                unitConversion.factorDerived = data[row];
                break;
            }
          }
          result.push(unitConversion);
        }

        this.cache.set(UNITCONVERSIONSCACHEKEY, result);

      }
      catch (err) {
        this.logger.error(err);
      }
      return result;
    }
  }

  async unitConversionFromDerived(value: number, toUnit: string): Promise<number> {
    var unitConversions = await this._getUnitConversions();
    var unitConversion = unitConversions.find(uc => uc.unit == toUnit);

    if (unitConversion) {
      return value / unitConversion.factorDerived;
    }

    return value;
  }

  async unitConversionToDerived(value: number, fromUnit: string, toDerivedUnit: string): Promise<number> {
    var unitConversions = await this._getUnitConversions();
    var unitConversion = unitConversions.find(uc => uc.unit == fromUnit && uc.derivedFrom == toDerivedUnit);

    if (unitConversion) {
      return value * unitConversion.factorDerived;
    }

    return value;
  }
}
