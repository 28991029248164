import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { TreeNode } from './tree-node';

@jsonObject
export class TreeviewItem implements TreeNode {
  @jsonMember
  public id : string;

  @jsonMember
  public name : string;

  @jsonMember
  public descritpion : string;

  @jsonArrayMember(TreeviewItem)
  public children : TreeviewItem[] = [];

  @jsonMember
  expanded : boolean = false;

  parent : TreeNode;
}
