import { Pipe, PipeTransform } from '@angular/core';
import { XDataType } from '../../../XProjector/xprojector-client-service';
import { ColumnConfig } from './projection-dataeditor-widget-config-service';

@Pipe({
  name: 'xproj_dataeditor_filtertimestampcolumns'
})
export class XprojDataeditorFiltertimestampcolumnsPipe implements PipeTransform {

  transform(items:  Array<ColumnConfig>): Array<ColumnConfig> {
    let rVal = new Array<ColumnConfig>()
    for(let item of items)
    {
      switch(item.Datatype)
      {
        case XDataType.Timestamp:
          rVal.push(item);
          break;

        default:
          break;
      }
    }
    return rVal;
  }

}
