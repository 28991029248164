import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { Guid } from '../../../utils/guid-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation } from '../../../XProjector/xprojector-client-service';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';

export enum ColorProperty {
  TEXT              = 0,
  TEXT_BACKGROUND   = 1,
  WIDGET_BACKGROUND  = 2
}

export enum LabelWidgetType {
  LABEL          = 0,
  GAUGE_NEEDLE    = 1,
  GAUGE         = 2,
}

export enum GaugeType {
  FULL     = 0,
  SEMI     = 1,
  ARCH     = 2
}

export enum GaugeStyle {
  ROUND     = 0,
  BUTT     = 1
}

@jsonObject
export class ColorThreshold {
  @jsonMember
  public Value : number;

  @jsonMember
  public Color : string;

  @jsonMember
  public Label : string;
}

@jsonObject
export class LabelConfig {

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
	public UseUnitInputParameter : boolean = false;

  @jsonMember
  public UnitInputParameterId: string = '';

  @jsonMember
  public FontFamilyLabel : string = '';

  @jsonMember
  public FontFamilyValue : string = '';

  @jsonMember
  public FontSizeLabel : string = '2em';

  @jsonMember
  public FontSizeValue : string = '2em';

  @jsonMember
  public AlignLabel : string = "right";

  @jsonMember
  public AlignValue : string = "left";

  @jsonMember
  public Margin : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
  public DateFormatting : string = '';

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonArrayMember(ColorThreshold)
  public ColorThresholds : ColorThreshold[] = [];

  @jsonMember
  public ColorProperty : ColorProperty = ColorProperty.TEXT;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  public Clone(): LabelConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, LabelConfig), LabelConfig);
	}
}

@jsonObject
export class LabelWidgetQuery  implements BaseQueryConfigInterface {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(LabelConfig)
  public DataConfigs : LabelConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonMember
  UseTransformInputParameter : boolean = false;

  @jsonMember
  TransformInputParameterId : string = '';
}

@jsonObject
export class LabelWidgetGaugeSettings {
  @jsonMember
  public GaugeType: GaugeType = GaugeType.FULL;

  @jsonMember
  public Min: number = 0;

  @jsonMember
  public Max: number = 100;

  @jsonMember
  public GaugeStyle: GaugeStyle = GaugeStyle.BUTT;

  @jsonMember
  public Thick : number = 6;

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ForegroundColor : string;

  @jsonMember
  public BackgroundColor : string;

  @jsonMember
  public Append : string;

  @jsonMember
  public Prepend : string;

  @jsonArrayMember(ColorThreshold)
  public Thresholds : ColorThreshold[] = [];

  @jsonMember
  public Animate : boolean = true;
}


@jsonObject
export class LabelWidgetConfig extends WidgetConfig {

  @jsonArrayMember(LabelWidgetQuery)
  public ConfigQueries : LabelWidgetQuery[] = [];

  @jsonMember
  public LabelType : LabelWidgetType = LabelWidgetType.LABEL;

  @jsonArrayMember(ColorThreshold)
  public GaugeColorThresholds : ColorThreshold[] = [];

  @jsonMember
  public GaugeText : string = '';

  @jsonMember
  public GaugeSettings: LabelWidgetGaugeSettings = new LabelWidgetGaugeSettings();

  @jsonMember
  public Margin : string = '';

	public Clone(): LabelWidgetConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, LabelWidgetConfig), LabelWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;

  update(config: LabelWidgetConfig) {
    super.update(config)

    this.ConfigQueries = config.ConfigQueries;
    this.LabelType = config.LabelType;
    this.GaugeColorThresholds = config.GaugeColorThresholds;
    this.GaugeText = config.GaugeText;
    this.GaugeSettings = config.GaugeSettings;
    this.Margin = config.Margin;
  }
}
