import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RossakerBmsCustomerRoutingModule } from './rossaker-bms-customer-routing.module';
import { RossakerBmsCustomerEventsComponent } from './rossaker-bms-customer-events/rossaker-bms-customer-events.component';
import { StateService } from '@xprojectorcore/services/state-service';
import { PageItem } from '@xprojectorcore/models/page-item';
import { SharedModule } from '@shared/shared.module';
import { RossakerBmsCustomerEventsHistoryComponent } from './rossaker-bms-customer-events-history/rossaker-bms-customer-events-history.component';
import { RossakerBmsCustomerEventStatusPipe } from './rossaker-bms-customer-event-status.pipe';
import { RossakerBmsCustomerEventActivePipe } from './rossaker-bms-customer-event-active.pipe';



@NgModule({
  declarations: [
    RossakerBmsCustomerEventsComponent,
    RossakerBmsCustomerEventsHistoryComponent,
    RossakerBmsCustomerEventStatusPipe,
    RossakerBmsCustomerEventActivePipe],
  imports: [
    CommonModule,
    SharedModule,

    RossakerBmsCustomerRoutingModule
  ]
})
export class RossakerBmsCustomerModule {
  constructor(private state : StateService, @Optional() @SkipSelf() parentModule?: RossakerBmsCustomerModule) {
    state.addPage( new PageItem(
      RossakerBmsCustomerEventsComponent,
      'customerevents'
    ));
  }
}
