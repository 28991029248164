// npm install @msgpack/msgpack
// https://github.com/msgpack/msgpack-javascript

import { Component, OnInit } from '@angular/core';
import { XProjectorClient } from 'xproj-lib'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  host: {
    class:'content-container'
}
})
export class SettingsComponent implements OnInit {

  constructor(public xprojClient: XProjectorClient)
  {


  }

  ngOnInit(): void {
    //this.xprojClient.client.wshostname = localStorage.getItem("xprojector-wshostname") || "ws://localhost:8000";
  }

  async connect()
  {
    this.xprojClient.Connect();
    //localStorage.setItem("xprojector-wshostname", this.xprojClient.client.wshostname);
  }

  async disconnect()
  {
    this.xprojClient.Disconnect();
  }


}
