<xproj-chart-widget *ngIf="!showConfig && (config | typeof) ==  'ChartWidgetConfig'" [config]="config"
  [responsive]="responsive" [zoom]="true" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to"
  [from]="from" [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-chart-widget>
<xproj-table-widget *ngIf="!showConfig && (config | typeof) ==  'TableWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-table-widget>
<xproj-master-widget *ngIf="!showConfig && (config | typeof) ==  'MasterWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-master-widget>
<xproj-piechart-widget *ngIf="!showConfig && (config | typeof) ==  'PiechartWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-piechart-widget>
<xproj-label-widget *ngIf="!showConfig && (config | typeof) ==  'LabelWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp" [widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-label-widget>
<xproj-spectrum-analyzer-widget *ngIf="!showConfig && (config | typeof) ==  'SpectrumAnalyzerConfig'" [config]="config"
  [responsive]="responsive" [zoom]="true" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to"
  [from]="from" [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-spectrum-analyzer-widget>
<xproj-spc-widget *ngIf="!showConfig && (config | typeof) ==  'SpcConfig'" [config]="config"
  [responsive]="responsive" [zoom]="true" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to"
  [from]="from" [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-spc-widget>
<xproj-text-widget *ngIf="!showConfig && (config | typeof) ==  'TextWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-text-widget>
<xproj-map-widget *ngIf="!showConfig && (config | typeof) ==  'MapWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-map-widget>
<xproj-svg-widget *ngIf="!showConfig && (config | typeof) ==  'SvgWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-svg-widget>
<xproj-scripted3d-widget *ngIf="!showConfig && (config | typeof) ==  'Scripted3dWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-scripted3d-widget>
<xproj-output-controller-widget *ngIf="!showConfig && (config | typeof) ==  'OutputControllerWidgetConfig'"
  [config]="config" [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to"
  [from]="from" [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-output-controller-widget>
<xproj-projection-dataeditor-widget *ngIf="!showConfig && (config | typeof) ==  'ProjectionDataEditorWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-projection-dataeditor-widget>
<xproj-container-widget *ngIf="!showConfig && (config | typeof) ==  'ContainerWidgetConfig'" [config]="config"
  [responsive]="responsive" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings" [to]="to" [from]="from"
  [relativeTimestamp]="relativeTimestamp"[widgetheight]="widgetheight" [widgetwidth]="widgetwidth"
  (onBeforeInit)="onBeforeWidgetInit($event)" (onAfterInit)="onAfterWidgetInit($event)">
</xproj-container-widget>


<xproj-chart-widget-config *ngIf="showConfig && (config | typeof) ==  'ChartWidgetConfig'" #chartWidgetConfig
  id="chartWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-chart-widget-config>
<xproj-table-widget-config *ngIf="showConfig && (config | typeof) ==  'TableWidgetConfig'" #tableWidgetConfig
  id="tableWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-table-widget-config>
<xproj-master-widget-config *ngIf="showConfig && (config | typeof) ==  'MasterWidgetConfig'" #tableWidgetConfig
  id="tableWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-master-widget-config>
<xproj-piechart-widget-config *ngIf="showConfig && (config | typeof) ==  'PiechartWidgetConfig'" #piechartWidgetConfig
  id="piechartWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs"
  (onConfigChanged)="onConfigChanged" [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-piechart-widget-config>
<xproj-label-widget-config *ngIf="showConfig && (config | typeof) ==  'LabelWidgetConfig'" #labelWidgetConfig
  id="labelWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-label-widget-config>
<xproj-spectrum-analyzer-widget-config *ngIf="showConfig && (config | typeof) ==  'SpectrumAnalyzerConfig'"
  #chartWidgetConfig id="spectrumanalyzerWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs"
  (onConfigChanged)="onConfigChanged" [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-spectrum-analyzer-widget-config>
<xproj-spc-widget-config *ngIf="showConfig && (config | typeof) ==  'SpcConfig'"
  #chartWidgetConfig id="spectrumanalyzerWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs"
  (onConfigChanged)="onConfigChanged" [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-spc-widget-config>
<xproj-text-widget-config *ngIf="showConfig && (config | typeof) ==  'TextWidgetConfig'" #textWidgetConfig
  id="textWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-text-widget-config>
<xproj-map-widget-config *ngIf="showConfig && (config | typeof) ==  'MapWidgetConfig'" #mapWidgetConfig
  id="textWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-map-widget-config>
<xproj-svg-widget-config *ngIf="showConfig && (config | typeof) ==  'SvgWidgetConfig'" #svgWidgetConfig
  id="svgWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-svg-widget-config>
<xproj-scripted3d-widget-config *ngIf="showConfig && (config | typeof) ==  'Scripted3dWidgetConfig'" #scripted3dWidgetConfig
  id="scripted3dWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-scripted3d-widget-config>
<xproj-output-controller-widget-config *ngIf="showConfig && (config | typeof) ==  'OutputControllerWidgetConfig'"
  #outputControllerWidgetConfig id="outputControllerWidgetConfig" [config]="config"
  [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged" [gridSettings]="dashboardConfig.Grid"
  [dashboardConfig]="dashboardConfig">
</xproj-output-controller-widget-config>
<xproj-projection-dataeditor-widget-config *ngIf="showConfig && (config | typeof) ==  'ProjectionDataEditorWidgetConfig'" #projectionDataEditWidgetConfig
  id="projectionDateEditWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-projection-dataeditor-widget-config>
<xproj-container-widget-config *ngIf="showConfig && (config | typeof) ==  'ContainerWidgetConfig'" #containerWidgetConfig
  id="containerWidgetConfig" [config]="config" [widgets]="dashboardConfig.WidgetConfigs" (onConfigChanged)="onConfigChanged"
  [gridSettings]="dashboardConfig.Grid" [dashboardConfig]="dashboardConfig">
</xproj-container-widget-config>
