import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { DataSourceProbeQuery, ProjectionColumnDescription, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'xproj-datasourcequeryprober',
  templateUrl: './xproj-datasourcequeryprober.component.html',
  styleUrls: ['./xproj-datasourcequeryprober.component.scss']
})
export class XProjDatasourcequeryproberComponent implements OnInit {

  loadingEditor :boolean =true;
  queryData = [];
  public Query: string = "";

  public getEditorHeight(str:string): number
  {
    const lines = (str.match(/\n/g) || '').length + 1;
    return lines * 19 + 10;
  }

  loadingQuery :boolean = false;
  editorOptions = {theme: environment.editortheme, language: 'sql',automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap:{enabled: false}};

  probeQueryResult : any;

  executingQuery: ClrLoadingState = ClrLoadingState.DEFAULT;

  public async getColumns() : Promise<Array<ProjectionColumnDescription>>
  {

    if(!this.queryData || this.queryData.length == 0)
    {
      await this.RunQuery(this.Query);
    }

    let RVal = new Array<ProjectionColumnDescription>();
    for(let i = 0; i < this.queryData.length; i++)
    {
      let name = this.queryData[i].name;
      let datatype = this.queryData[i].datatype;

      let colDesc = new ProjectionColumnDescription();
      colDesc.columnname = name;
      colDesc.datatype = datatype;
      RVal.push(colDesc);
    }


    return RVal;
  }

  @Input()  
  set codelanguage(language:string)
  {
    this.editorOptions["language"] = language;
  }
  
  datasourceId : string = null;
  @Input()
  set datasourceid(dsid : string)
  {
    this.datasourceId = dsid;
  }

  wrapInputinAccordion : boolean = false;

  @Input()
  set wrapinaccordion(wrapinocc : boolean)
  {
    this.wrapInputinAccordion = wrapinocc;
  }

  @Input()
  set query(dataquery : string)
  {
    this.Query = dataquery;
  }

  @Output() queryChange = new EventEmitter<string>();

  changed()
  {
    this.queryChange.emit(this.Query);
  }

  constructor(private xprojClient: XProjectorClient) { }

  onEditorInit() {
    this.loadingEditor = false;
    // Manual triggering of change  detection is required to stop showing loading spinner
    //this.changeDetectorRef.detectChanges();
  }


  async RunQuery(query : string)
  {
    try
    {
      this.executingQuery = ClrLoadingState.LOADING;
      if(!this.datasourceId)
      {
        this.executingQuery = ClrLoadingState.ERROR;
        throw new Error("No datasource id..");
      }

      this.loadingQuery = true;
      let probeQuery = new DataSourceProbeQuery();
      probeQuery.targetdatasourceid = this.datasourceId;
      probeQuery.query = query;
      this.probeQueryResult = await this.xprojClient.RequestDevDatasourceProbeqQuery(probeQuery);
      this.loadingQuery = false;
      this.executingQuery = ClrLoadingState.SUCCESS;


      this.queryData.length = 0;

      let numericaldata = this.probeQueryResult["datanumbers"];
      let timestampdata = this.probeQueryResult["datatimestamps"];
      let textdatadata = this.probeQueryResult["datastrings"];

      for(let i = 0; i<  this.probeQueryResult["columns"].length; i++)
      {
        let it = this.probeQueryResult["columns"][i];
        let data = null;
        switch(it.datatype)
        {
          case XDataType.Float32:
          case XDataType.Float64:
          case XDataType.UInt8:
          case XDataType.Int32:
          case XDataType.Int64:
          case XDataType.Number:
            data = numericaldata[it["indexintypedvector"]];
            break;
          case XDataType.String:
          case XDataType.UInt128:
            data = textdatadata[it["indexintypedvector"]];
            break;
          case XDataType.Timestamp:
            data = timestampdata[it["indexintypedvector"]];
            break;
        }

        let name = it["columnname"];
        if(name.length == 0)
        {
          name = "Unknown";
        }
        let obj = {
          name: name,
          datatype: it["datatype"],
          data: data
        };

        this.queryData.push(obj);

      }
      this.loadingQuery = false;
    }
    catch
    {
      this.executingQuery = ClrLoadingState.ERROR;
    }
  }


  ngOnInit(): void {
  }

}
