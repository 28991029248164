import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-editconnectors-bacnet',
  templateUrl: './editconnectors-bacnet.component.html',
  styleUrls: ['./editconnectors-bacnet.component.scss']
})
export class EditconnectorsBacnetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
