import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XProjectorDashboardClient } from '@xprojectorcore/xprojector_backend/xprojector-dashboard-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { TypedJSON } from 'typedjson';
import {
  XprojDashboardComponent, WidgetExportParameters, DashboardOutputChangeParameters,
  XprojAlertService, XprojMediaService, DashboardConfig, WidgetUtils, DashboardTestValue
} from 'xproj-lib';

var AsyncLock = require('async-lock');

@Component({
  selector: 'app-xconf-dashboard',
  templateUrl: './xconf-dashboard.component.html',
  styleUrls: ['./xconf-dashboard.component.scss']
})
export class XConfDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;

  @Input() editMode: boolean = true;

  dashboardId: string;
  currentUserRights: UserRights;

  public responsiveWidth: number = 834;

  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];

  lock = new AsyncLock({ timeout: 5000 });
  lockkey: string = 'initkey';
  isMobile: boolean = false;

  dashboardVersions: DashboardConfig[] = [];
  selectedDashboardVersion: DashboardConfig
  setVersion: number = -1;
  editEnabled: boolean = false;
  savingDashboard: boolean = false;
  deletingDashboard: boolean = false;
  reloadDashboard : boolean = false;

  mediaService = new XprojMediaService();
  mediaSubscription: any;

  constructor(
    private state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private dashboardClient: XProjectorDashboardClient,
    private route: ActivatedRoute,
    private alertService: XprojAlertService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.currentUserRights = this.state.userRightsValue;

    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {

      this.lock.acquire(this.lockkey, async () => {
        this.dashboardId = id
        //await this.xprojDashboard?.save();

        if (this.currentUserRights) {
          this.dashboardVersions = [];
          await this.updateDashboardVersions();
          await this.updateXProjDashboard();
        }
      }).then(() => {
        // lock released
      });
    });
    this.route.queryParamMap.subscribe(async (params) => {
      if (params.keys.length > 0) {
        this.lock.acquire(this.lockkey, async () => {
          let outputs: DashboardOutputChangeParameters[] = [];
          params.keys.forEach((key) => {
            let out = new DashboardOutputChangeParameters();
            out.outputParameterName = key;
            out.value = params.get(key);

            outputs.push(out);
          });

          this.dashboardOutputParameters = outputs;
          await this.xprojDashboard?.setDashboardOutputParameters(this.dashboardOutputParameters);
        }).then(() => {
          // lock released
        });
      }
    }
    );
  }

  applyNewWidth(width) {
    this.logger.info("applying width: ", width);
    if (width > 850) {
      this.responsiveWidth = width - 300;
      return;
    }
    this.responsiveWidth = width - 50;
  }

  ngAfterViewInit(): void {
    this.applyNewWidth(window.innerWidth);
  }

  ngOnDestroy(): void {
    this.mediaSubscription?.unsubscribe();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.applyNewWidth(event.target.innerWidth);
  }

  onResized($event): void {
    this.xprojDashboard?.refresh();
  }

  beforeSave = (dashboardConfig: DashboardConfig) => {

    return dashboardConfig;
  }

  async updateXProjDashboard(force: boolean = true) {
    let selectedVersion = this.selectedDashboardVersion ? this.selectedDashboardVersion.Version : -1;

    if (force || selectedVersion != this.setVersion) {
      this.setVersion = selectedVersion;
      this.editEnabled = this.selectedDashboardVersion?.Tag.length > 0 ? false : true;

      let outputs = [];
      if (this.selectedDashboardVersion) {
        this.selectedDashboardVersion.TestValues.forEach(tv => {
          let out = new DashboardOutputChangeParameters();
          out.outputParameterName = tv.Name;
          out.datatype = tv.Datatype;
          out.value = WidgetUtils.GetInputValue(tv.Value, tv.Datatype, false);
          outputs.push(out);
        });
      }

      await this.xprojDashboard?.setDashboardId(this.dashboardId, this.setVersion, '', true, outputs);
      this.reloadDashboard = false;
    }
  }

  async dashboardTabActive() {
    this.updateXProjDashboard(this.reloadDashboard);
  }

  async updateDashboardVersions() {
    if (this.dashboardVersions?.length == 0) {
      this.dashboardVersions = await this.dashboardClient.loadAllDashboardVersions(this.dashboardId);
      this.dashboardVersions.sort((a, b) => { return b.Version - a.Version });
      let maxVersion = 0;

      this.dashboardVersions.forEach(d => {
        if (d.Version > maxVersion) {
          maxVersion = d.Version;
        }
        this.updateTestValues(d);
      });

      this.selectedDashboardVersion = this.dashboardVersions.find(d => d.Version = maxVersion);
    }
  }

  updateTestValues(config: DashboardConfig) {
    config.OutputParameters.forEach(out => {
      let testValue = config.TestValues.find(tv => tv.Name == out.name);
      if (!testValue) {
        testValue = new DashboardTestValue();
        testValue.Name = out.name;
        testValue.Value = '';
        testValue.Datatype = out.datatype;
        config.TestValues.push(testValue);
      }
    });

    config.TestValues = config.TestValues.filter(tv => config.OutputParameters.findIndex(out => out.name == tv.Name) > -1);
  }

  async saveSelectedDashboard() {
    if (this.selectedDashboardVersion) {
      try {
        this.savingDashboard = true;
        let dashboard = await this.dashboardClient.loadDashboard(this.selectedDashboardVersion.Id, this.selectedDashboardVersion.Version)
        dashboard.Description = this.selectedDashboardVersion.Description;
        dashboard.Tag = this.selectedDashboardVersion.Tag;
        dashboard.TestValues = this.selectedDashboardVersion.TestValues;

        let result = await this.dashboardClient.saveDashboard(dashboard);
        if (result) {
          if (this.selectedDashboardVersion.Tag?.length > 0) {
            this.dashboardVersions.forEach(d => {
              if (d.Version != this.selectedDashboardVersion.Version && d.Tag == this.selectedDashboardVersion.Tag) {
                d.Tag = '';
              }
            });
          }
          this.alertService.info('Dashboard version saved.');
          this.reloadDashboard = true;
        }
        else {
          this.alertService.error('Error save dashboard version!');
        }
      }
      finally {
        this.savingDashboard = false;
      }
    }
  }

  async deleteSelectedDashboardVersion() {
    if (this.selectedDashboardVersion) {
      try {
        this.deletingDashboard = true;
        if (this.selectedDashboardVersion.Tag?.length > 0) {
          this.alertService.error("Can't delete a taged version!");
        }
        else {
          let result = await this.dashboardClient.deleteDashboard(this.selectedDashboardVersion.Id, this.selectedDashboardVersion.Version);
          if (result) {
            this.alertService.info('Dashboard version deleted.');
            this.dashboardVersions = this.dashboardVersions.filter(d => d.Version != this.selectedDashboardVersion.Version);
            this.selectedDashboardVersion = undefined;
          }
          else {
            this.alertService.error('Error delete dashboard version!');
          }
        }
      }
      finally {
        this.deletingDashboard = false;
      }
    }
  }

  async newVersion() {
    let newVersion: DashboardConfig;
    let maxVersion = 0;

    this.dashboardVersions.forEach(d => {
      if (d.Version > maxVersion) {
        maxVersion = d.Version;
      }
    });

    if (this.selectedDashboardVersion) {
      newVersion = TypedJSON.parse(TypedJSON.stringify(this.selectedDashboardVersion, DashboardConfig), DashboardConfig);
    }
    else {
      let config = this.dashboardVersions.find(d => d.Version = maxVersion);
      if (config) {
        newVersion = TypedJSON.parse(TypedJSON.stringify(config, DashboardConfig), DashboardConfig);
      }
    }

    if (newVersion) {
      newVersion.Version = maxVersion + 1;
      newVersion.Tag = '';
      let result = await this.dashboardClient.saveDashboard(newVersion);
      if (result) {
        this.dashboardVersions.push(newVersion);
        this.dashboardVersions.sort((a, b) => { return b.Version - a.Version });
      }

    }
  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters, 100000);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }
}
