import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { start } from 'repl';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { RossakerBmsDataExportValue, RossakerBmsDataExportValueStatus } from '../models/rossaker-bms-data-export-value';
import { RossakerBmsDatapointValue, RossakerBmsDatapointValueStatus } from '../models/rossaker-bms-datapoint-value';
import { flakeId } from '@core/utils/rossaker-bms-data-utils';

export interface RossakerBmsDataExportValueParameters {
  customerId: string,
  periodStart: Date,
  periodEnd: Date,
  status?: RossakerBmsDataExportValueStatus;
  meterType?: string,
  maxItems?: number
}

const DATAPOINTVALUE_PROJECTIONID: string = 'ros-datapointvalue';
const DATAEXPORTVALUE_PROJECTIONID: string = 'ros-dataexportvalue';

@Injectable({
  providedIn: 'root'
})
export class RossakerBmsDataExportService {

  dataExportValueColumns: ProjectionColumnDescription[] = [];
  datapointValueColumns: BaseQueryInputColumnDescription[] = [];

  private setDataExportValueColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'customerid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'meterid',
      datatype: XDataType.Int64,
      indexintypedvector: 1
    },
    {
      columnname: 'start',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'end',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'startvalue',
      datatype: XDataType.Float32,
      indexintypedvector: 2
    },
    {
      columnname: 'endvalue',
      datatype: XDataType.Float32,
      indexintypedvector: 3
    },
    {
      columnname: 'nodeid',
      datatype: XDataType.Int64,
      indexintypedvector: 4
    },
    {
      columnname: 'nodetypeid',
      datatype: XDataType.String,
      indexintypedvector: 1
    },
    {
      columnname: 'metertype',
      datatype: XDataType.String,
      indexintypedvector: 2
    },
    {
      columnname: 'unit',
      datatype: XDataType.String,
      indexintypedvector: 3
    },
    {
      columnname: 'tariff',
      datatype: XDataType.Float32,
      indexintypedvector: 5
    },
    {
      columnname: 'vatrate',
      datatype: XDataType.Float32,
      indexintypedvector: 6
    },
    {
      columnname: 'includevat',
      datatype: XDataType.UInt8,
      indexintypedvector: 7
    },
    {
      columnname: 'prefix',
      datatype: XDataType.String,
      indexintypedvector: 4
    },
    {
      columnname: 'externalid',
      datatype: XDataType.String,
      indexintypedvector: 5
    },
    {
      columnname: 'status',
      datatype: XDataType.Int32,
      indexintypedvector: 8
    },
    {
      columnname: 'svlantapartmentno',
      datatype: XDataType.Int32,
      indexintypedvector: 9
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 4
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 10
    }
  ];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async getDataExportValues({
    customerId,
    periodStart,
    periodEnd,
    status = undefined,
    meterType = undefined,
    maxItems = 10000
  }: RossakerBmsDataExportValueParameters): Promise<RossakerBmsDataExportValue[]> {

    if (customerId) {
      if (this.dataExportValueColumns.length == 0) {
        this.dataExportValueColumns = await this.xprojClient.RequestListQueryableProjectionColumns(DATAEXPORTVALUE_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = DATAEXPORTVALUE_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (meterType) {
        let meterTypeFiltering = new ColumnFilteringString();
        meterTypeFiltering.columnname = 'metertype';
        meterTypeFiltering.comparator = FilterComparator.Equals;
        meterTypeFiltering.value = meterType;
        meterTypeFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(meterTypeFiltering.queryfilterid);
        query.stringfilters.push(meterTypeFiltering);
      }

      let startFiltering = new ColumnFilteringTimestamp();
      startFiltering.columnname = 'start';
      startFiltering.comparator = FilterComparator.GreatherThanOrEquals;
      startFiltering.value = periodStart;
      startFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(startFiltering.queryfilterid);
      query.timestampfilters.push(startFiltering);

      let endFiltering = new ColumnFilteringTimestamp();
      endFiltering.columnname = 'end';
      endFiltering.comparator = FilterComparator.LessThanOrEquals;
      endFiltering.value = periodEnd;
      endFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(endFiltering.queryfilterid);
      query.timestampfilters.push(endFiltering);

      if (status) {
        let statusFiltering = new ColumnFilteringNumerical();
        statusFiltering.columnname = 'status';
        statusFiltering.comparator = FilterComparator.BitAnd;
        statusFiltering.value = status;
        statusFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(statusFiltering.queryfilterid);
        query.numericalfilters.push(statusFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.dataExportValueColumns.filter(c => c.columnname != 'customerid').forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'externalid';
      query.sorting.descending = false;

      query.targetgroup = [customerId];

      return await this._getDataExportValues(customerId, query);
    }

    return [];
  }

  async getLastDataExportValue(customerId : string, meterId : number): Promise<RossakerBmsDataExportValue> {
    try {
      if (customerId) {
        if (this.dataExportValueColumns.length == 0) {
          this.dataExportValueColumns = await this.xprojClient.RequestListQueryableProjectionColumns(DATAEXPORTVALUE_PROJECTIONID, '', 0, 100);
        }

        let query: BaseQuery = new BaseQuery();
        query.targetprojectionid = DATAEXPORTVALUE_PROJECTIONID;
        query.maxitems = 1;
        query.targetgroup = [customerId];

        let filterId = 0;
        query.filter.type = FilterLogicalGroupType.AND;

        let meterFiltering = new ColumnFilteringNumerical();
        meterFiltering.columnname = 'meterid';
        meterFiltering.comparator = FilterComparator.Equals;
        meterFiltering.value = meterId;
        meterFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(meterFiltering.queryfilterid);
        query.numericalfilters.push(meterFiltering);

        let dateFiltering = new ColumnFilteringTimestamp();
        dateFiltering.columnname = 'end';
        dateFiltering.comparator = FilterComparator.LessThan;
        dateFiltering.value = new Date();
        dateFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(dateFiltering.queryfilterid);
        query.timestampfilters.push(dateFiltering);

        let deletedFiltering = new ColumnFilteringNumerical();
        deletedFiltering.columnname = 'deleted';
        deletedFiltering.comparator = FilterComparator.Equals;
        deletedFiltering.value = 0;
        deletedFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(deletedFiltering.queryfilterid);
        query.numericalfilters.push(deletedFiltering);

        this.dataExportValueColumns.filter(c => c.columnname != 'customerid').forEach(c => {
          let inCol = new BaseQueryInputColumnDescription();
          inCol.columnname = c.columnname;
          inCol.columnoutname = c.columnname;
          query.columns.push(inCol);
        });

        query.sorting.columnname = 'end';
        query.sorting.descending = true;

        let exportValues = await this._getDataExportValues(customerId, query);
        if (exportValues.length > 0) {
          return exportValues[0];
        }
      }
    }
    catch {

    }

    return null;
  }

  private async _getDataExportValues(customerId : string, query : BaseQuery) : Promise<RossakerBmsDataExportValue[]> {
    let result: RossakerBmsDataExportValue[] = [];
    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let dataExportValue = new RossakerBmsDataExportValue();
        dataExportValue.customerId = customerId;

        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              dataExportValue.id = data[row];
              break;
            case 'meterid':
              dataExportValue.meterId = data[row];
              break;
            case 'start':
              dataExportValue.start = data[row];
              break;
            case 'end':
              dataExportValue.end = data[row];
              break;
            case 'startvalue':
              dataExportValue.startValue = data[row];
              break;
            case 'endvalue':
              dataExportValue.endValue = data[row];
              break;
            case 'nodeid':
              dataExportValue.nodeId = data[row];
              break;
            case 'nodetypeid':
              dataExportValue.nodeTypeId = data[row];
              break;
            case 'metertype':
              dataExportValue.meterType = data[row];
              break;
            case 'unit':
              dataExportValue.unit = data[row];
              break;
            case 'tariff':
              dataExportValue.tariff = data[row];
              break;
            case 'vatrate':
              dataExportValue.vatRate = data[row];
              break;
            case 'includevat':
              dataExportValue.includeVAT = data[row] > 0 ? true : false;
              break;
            case 'prefix':
              dataExportValue.prefix = data[row];
              break;
            case 'externalid':
              dataExportValue.externalId = data[row];
              break;
            case 'svlantapartmentno':
              dataExportValue.svLantApartmentNo = data[row];
              break;
            case 'status':
              dataExportValue.status = data[row];
              break;
            case 'createdat':
              dataExportValue.createdAt = data[row];
              break;
            case 'modifiedat':
              dataExportValue.modifiedAt = data[row];
              break;
          }
        }

        result.push(dataExportValue);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async setDataExportValues(dataExportValues: RossakerBmsDataExportValue[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    let now = new Date();
    await ArrayUtils.AsyncForEach(dataExportValues, async (dataExportValue : RossakerBmsDataExportValue) => {
      if (!dataExportValue.id || dataExportValue.id == 0) {
        dataExportValue.id = await flakeId.nextId();
      }
      dataExportValue.modifiedAt = now;
    });

    query.datastrings = [
      dataExportValues.map(t => t.customerId),
      dataExportValues.map(t => t.nodeTypeId),
      dataExportValues.map(t => t.meterType),
      dataExportValues.map(t => t.unit),
      dataExportValues.map(t => t.prefix),
      dataExportValues.map(t => t.externalId),
    ];
    query.datanumbers = [
      dataExportValues.map(t => t.id),
      dataExportValues.map(t => t.meterId ?? 0),
      dataExportValues.map(t => t.startValue ?? 0),
      dataExportValues.map(t => t.endValue ?? 0),
      dataExportValues.map(t => t.nodeId ?? 0),
      dataExportValues.map(t => t.tariff ?? 0),
      dataExportValues.map(t => t.vatRate ?? 0),
      dataExportValues.map(t => t.includeVAT ? 1 : 0),
      dataExportValues.map(t => t.status ?? 0),
      dataExportValues.map(t => t.svLantApartmentNo ?? 0),
      dataExportValues.map(t => t.deleted ? 1 : 0),
    ];
    query.datatimestamps = [
      dataExportValues.map(t => t.start ?? new Date()),
      dataExportValues.map(t => t.end ?? new Date()),
      dataExportValues.map(t => t.createdAt ?? new Date()),
      dataExportValues.map(t => t.modifiedAt ?? new Date()),
      dataExportValues.map(t => t.deletedAt ?? new Date(0))
    ];

    query.projectionid = DATAEXPORTVALUE_PROJECTIONID;
    query.columns = this.setDataExportValueColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async deleteResidentExchange(dataExportValue : RossakerBmsDataExportValue) : Promise<boolean> {
    dataExportValue.deleted = true;
    dataExportValue.deletedAt = new Date();

    return this.setDataExportValues([dataExportValue]);
  }

  async getDatapointValueAfter(meterId : number, after : Date) : Promise<RossakerBmsDatapointValue> {
    if (this.datapointValueColumns.length == 0) {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = inCol.columnoutname = 'id';
      this.datapointValueColumns.push(inCol);

      inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = inCol.columnoutname = 'value';
      this.datapointValueColumns.push(inCol);

      inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = inCol.columnoutname = 'flags';
      this.datapointValueColumns.push(inCol);

      inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = inCol.columnoutname = 'timestamp';
      this.datapointValueColumns.push(inCol);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = DATAPOINTVALUE_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [meterId + ''];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let timestampFiltering = new ColumnFilteringTimestamp();
    timestampFiltering.columnname = 'timestamp';
    timestampFiltering.comparator = FilterComparator.GreaterThan;
    timestampFiltering.value = after;
    timestampFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(timestampFiltering.queryfilterid);
    query.timestampfilters.push(timestampFiltering);

    let flagsFiltering = new ColumnFilteringNumerical();
    flagsFiltering.columnname = 'flags';
    flagsFiltering.comparator = FilterComparator.LessThanOrEquals;
    flagsFiltering.value = RossakerBmsDatapointValueStatus.Interpolated;
    flagsFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(flagsFiltering.queryfilterid);
    query.numericalfilters.push(flagsFiltering);

    query.columns = this.datapointValueColumns;

    query.sorting.columnname = 'timestamp';
    query.sorting.descending = false;

    let result: RossakerBmsDatapointValue;
    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        result = new RossakerBmsDatapointValue();
        result.meterId = meterId;

        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              result.id = data[row];
              break;
            case 'timestamp':
              result.timestamp = data[row];
              break;
            case 'value':
              result.value = data[row];
              break;
            case 'flags':
              result.flags = data[row];
              break;
          }
        }
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }
}
