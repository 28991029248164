import { Pipe, PipeTransform } from '@angular/core';
import {prettyPrint} from '../misc-service'
import { DateHelper } from '../helpers/date-helper-service';
import { DataSourceDriver, XDataType, ProjectionExecutionEntry, ProjectionColumnDescription, ProjectionColumnGroupColumnDescription } from '../XProjector/xprojector-client-service';
//import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({
  name: 'xproj_pretty'
})
export class XprojPrettyPipe implements PipeTransform {

  constructor(
    private dateHelper : DateHelper
  ) {

  }

  transform(value: unknown, ...args: unknown[]): string {
    return prettyPrint(value, this.dateHelper);
  }
}

@Pipe({
  name: 'xproj_prettydsdriver'
})
export class XprojPrettyDSPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {

    let ds = value as DataSourceDriver;
    switch(ds)
    {
      case DataSourceDriver.Broken:
        return 'Broken';
      case DataSourceDriver.SQLite:
        return 'SQLite';
      case DataSourceDriver.MongoDB:
        return 'MongoDB';
      case DataSourceDriver.MySQL:
        return 'MySQL';
      case DataSourceDriver.Oracle:
        return 'Oracle';
      case DataSourceDriver.PostgreSQL:
        return 'PostgreSQL';
      case DataSourceDriver.SQLServer:
        return 'SQL Server';
      }
  }

}


@Pipe({
  name: 'xproj_prettydatatype'
})
export class XprojPrettyDataType implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {

    let ds = value as XDataType;
    switch(ds)
    {
      case XDataType.Number:
        return 'Number';
      case XDataType.Float32:
        return "Float32";
      case XDataType.Float64:
        return "Float64";
      case XDataType.UInt8:
        return "UInt8";
      case XDataType.Int32:
        return "Int32";
      case XDataType.Int64:
        return "Int64";
      case XDataType.String:
        return 'String';
      case XDataType.Timestamp:
        return 'Timestamp';
      }
  }
}



@Pipe({
  name: 'xproj_prettyprojectionexecutionentry'
})
export class XprojPrettyProjectionExecutionEntry implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {

    let entry = value as ProjectionExecutionEntry;
    switch(entry)
    {
      case ProjectionExecutionEntry.DataSourceQuery:
        return 'Data source query';
      case ProjectionExecutionEntry.LuaMainFunction:
        return "Lua main function";
      case ProjectionExecutionEntry.ProjectionCursor:
        return "Projection cursor";
      }
  }

}

@Pipe({
  name: 'xproj_prettyprojectioncolumndescription'
})
export class XprojPrettyProjectionColumnDescription implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {

    let entry = value as Array<ProjectionColumnDescription>;
    let joinAble = [];

    for(let i = 0; i < entry.length; i++)
    {
      joinAble.push ( entry[i].columnname );
    }

    return joinAble.join(", ");

  }

}

@Pipe({
  name: 'xproj_prettyprojectioncolumngroupcolumndescription'
})
export class XprojPrettyProjectionColumnGroupColumnDescription implements PipeTransform {

  transform(value: unknown, columns: any,  ...args: unknown[]): string
  {
    let valueCasted = value as Array<ProjectionColumnGroupColumnDescription>;
    let castedColumns = columns as Array<ProjectionColumnDescription>;
    let joinAble = [];

    for(let i = 0; i < valueCasted.length; i++)
    {
      for(let j = 0; j < castedColumns.length; j++)
      {
        if(castedColumns[j].projectioncolumndescriptionid != valueCasted[i].projectioncolumndescriptionid)
          continue;

        joinAble.push (castedColumns[j].columnname );
      }

    }

    return joinAble.join(", ");

  }
}

@Pipe({
  name: 'xproj_prettytruncatestring'
})
export class XprojPrettyTruncateString implements PipeTransform {

  transform(value: string, length : number, ...args: unknown[]): string {

    return value?.substring(0, Math.min(length, value.length)) ?? '';
  }

}
