/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
/**
 * Message implementation for customeradmin.DeleteCustomerUserRequest
 */
export class DeleteCustomerUserRequest implements GrpcMessage {
  static id = 'customeradmin.DeleteCustomerUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteCustomerUserRequest();
    DeleteCustomerUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteCustomerUserRequest) {
    _instance.customerUserID = _instance.customerUserID || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteCustomerUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerUserID = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteCustomerUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteCustomerUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerUserID) {
      _writer.writeString(1, _instance.customerUserID);
    }
  }

  private _customerUserID?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteCustomerUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteCustomerUserRequest.AsObject>) {
    _value = _value || {};
    this.customerUserID = _value.customerUserID;
    DeleteCustomerUserRequest.refineValues(this);
  }
  get customerUserID(): string | undefined {
    return this._customerUserID;
  }
  set customerUserID(value: string | undefined) {
    this._customerUserID = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteCustomerUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteCustomerUserRequest.AsObject {
    return {
      customerUserID: this.customerUserID
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteCustomerUserRequest.AsProtobufJSON {
    return {
      customerUserID: this.customerUserID
    };
  }
}
export module DeleteCustomerUserRequest {
  /**
   * Standard JavaScript object representation for DeleteCustomerUserRequest
   */
  export interface AsObject {
    customerUserID?: string;
  }

  /**
   * Protobuf JSON representation for DeleteCustomerUserRequest
   */
  export interface AsProtobufJSON {
    customerUserID?: string;
  }
}

/**
 * Message implementation for customeradmin.GetCustomerUsersResponse
 */
export class GetCustomerUsersResponse implements GrpcMessage {
  static id = 'customeradmin.GetCustomerUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerUsersResponse();
    GetCustomerUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerUsersResponse) {
    _instance.users = _instance.users || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.CustomerUser();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.CustomerUser.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.users as any,
        xprojectorGrpcModels002.CustomerUser.serializeBinaryToWriter
      );
    }
  }

  private _users?: xprojectorGrpcModels002.CustomerUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerUsersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerUsersResponse.AsObject>) {
    _value = _value || {};
    this.users = (_value.users || []).map(
      m => new xprojectorGrpcModels002.CustomerUser(m)
    );
    GetCustomerUsersResponse.refineValues(this);
  }
  get users(): xprojectorGrpcModels002.CustomerUser[] | undefined {
    return this._users;
  }
  set users(value: xprojectorGrpcModels002.CustomerUser[] | undefined) {
    this._users = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerUsersResponse.AsObject {
    return {
      users: (this.users || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerUsersResponse.AsProtobufJSON {
    return {
      users: (this.users || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetCustomerUsersResponse {
  /**
   * Standard JavaScript object representation for GetCustomerUsersResponse
   */
  export interface AsObject {
    users?: xprojectorGrpcModels002.CustomerUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetCustomerUsersResponse
   */
  export interface AsProtobufJSON {
    users?: xprojectorGrpcModels002.CustomerUser.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for customeradmin.ModifyCustomerRequest
 */
export class ModifyCustomerRequest implements GrpcMessage {
  static id = 'customeradmin.ModifyCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ModifyCustomerRequest();
    ModifyCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ModifyCustomerRequest) {
    _instance.defaultDashboardId = _instance.defaultDashboardId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ModifyCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.defaultDashboardId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ModifyCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ModifyCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.defaultDashboardId) {
      _writer.writeString(1, _instance.defaultDashboardId);
    }
  }

  private _defaultDashboardId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ModifyCustomerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ModifyCustomerRequest.AsObject>) {
    _value = _value || {};
    this.defaultDashboardId = _value.defaultDashboardId;
    ModifyCustomerRequest.refineValues(this);
  }
  get defaultDashboardId(): string | undefined {
    return this._defaultDashboardId;
  }
  set defaultDashboardId(value: string | undefined) {
    this._defaultDashboardId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ModifyCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ModifyCustomerRequest.AsObject {
    return {
      defaultDashboardId: this.defaultDashboardId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ModifyCustomerRequest.AsProtobufJSON {
    return {
      defaultDashboardId: this.defaultDashboardId
    };
  }
}
export module ModifyCustomerRequest {
  /**
   * Standard JavaScript object representation for ModifyCustomerRequest
   */
  export interface AsObject {
    defaultDashboardId?: string;
  }

  /**
   * Protobuf JSON representation for ModifyCustomerRequest
   */
  export interface AsProtobufJSON {
    defaultDashboardId?: string;
  }
}
