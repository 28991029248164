import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper } from 'xproj-lib';
import { ClrDatagridSortOrder } from '@clr/angular';

export class ReadoutDateValue {
  id : string;
  timestamp : Date;
  value : any;
}

@Component({
  selector: 'app-readout-treenode',
  templateUrl: './readout-treenode.component.html',
  styleUrls: ['./readout-treenode.component.scss']
})
export class ReadoutTreenodeComponent implements OnInit {

  _node: GrpcNode;
  @Input()
  get node(): GrpcNode {
    return this._node;
  }
  set node(node: GrpcNode) {
    this._node = node;
  }

  loading : boolean = false;
  datavalues : ReadoutDateValue[] = [];
  message : string = '';

  ascSort = ClrDatagridSortOrder.ASC;

  constructor(
    private xConfClient: XProjectorXConfClient,
    private dateHelper: DateHelper,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.readoutMomentary();
  }

  async readoutMomentary() {
    if (this.node) {
      try {
        this.loading =true;
        let result = await this.xConfClient.readoutMomentaryValues(this.node.id, this.node.nodeTypeLabel);
        this.logger.debug('readoutMomentary', result);
        if (result.ok) {
          this.message = '';
          this.datavalues = [];
          result.numericValues.forEach(v => {
            let dataValue = new ReadoutDateValue();
            dataValue.id = v.varId;
            dataValue.timestamp = v.timestamp.toDate();
            dataValue.value = v.value + ' ' + v.unit;
            this.datavalues.push(dataValue);
          });
        }
        else {
          this.message = result.message;
        }
      }
      finally {
        this.loading = false;
      }
    }
  }

  formatDate(date: Date, displayUTC: boolean = false): string {
    return this.dateHelper.utils.formatByString(displayUTC ? this.dateHelper.utils.addMinutes(date, -date.getTimezoneOffset()) : date, "yyyy-MM-dd HH:mm:ss");
  }

}
