import { XprojCommonStrings } from 'xproj-lib';

export const xprojCommonStrings: Partial<XprojCommonStrings> = {

  //Chart
  rightY: $localize `:@@xprojlib_righty:right-y`,

      //Weekdays
  mondayShort: $localize `:@@xprojlib_mondayshort:Mon`,
  tuesdayShort: $localize `:@@xprojlib_tuesdayshort:Tue`,
  wednesdayShort: $localize `:@@xprojlib_wednesdayshort:Wed`,
  thursShort: $localize `:@@xprojlib_thursdayshort:Thu`,
  friShort: $localize `:@@xprojlib_fridayshort:Fri`,
  saturdayShort: $localize `:@@xprojlib_saturdayshort:Sat`,
  sundayShort: $localize `:@@xprojlib_sundaayshort:Sun`,
};
