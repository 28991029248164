

<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->

        <div class="splash-container">
            <div class="splash-inner">
                <h1>Products</h1>
                Discover our software-hardware bundled solutions below. <br>
                Or read more about our software under <a routerLink="/systems">Systems</a>.
            </div>
        </div>


    <div class="content">
        <div>
            <a id="controllers"><h2>Automation controller units: R Series </h2></a>
            Our automation controller units are designed to be DIN-mounted in automation cabinets, <br>
            with excellent performance and various connectivity and protocol support. <br>
            <br>
            They are ideal building blocks for your distributed automation system and industrial edge computing system. 


            <h3>R-100 series</h3>
            The R-100 is our smallest controller. <br>
            This series include multiple bus and network communication interfaces in order to control third party systems.

            <clr-accordion>
                <clr-accordion-panel>
                    <clr-accordion-title>Technical overview of the R-100 family</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>            
                        Technical overview&apos; are subject to change and depending on selected configuration features and properties may vary.
                        <table class="table" >
                            <thead>
                                <tr>                        
                                    <th class="left">Function</th>
                                    <th class="left">Support</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="left">Web based</td>
                                    <td class="left">Yes, all configuration, programming and other tools are web based with no browser plugins required.</td>
                                </tr>                    
                                <tr>
                                    <td class="left">SCADA</td>
                                    <td class="left">Yes, Web-based Flow charts / P&ID, etc</td>
                                </tr>
                                <tr>
                                <td class="left">PLC</td>
                                <td class="left">Yes, LUA-programs with up to 20 kHz loop cycle</td>
                            </tr>
                            <tr>
                                <td class="left">PLC Debugger</td>
                                <td class="left">Yes</td>
                            </tr>
                            <tr>
                                <td class="left">Opensource</td>
                                <td class="left">Includes Rossaker Open Source Controller Library (LUA)</td>
                            </tr>
                            <tr>
                                <td class="left">Operation System</td>
                                <td class="left">Debian based Linux</td>
                            </tr>

                            <tr>
                                <td class="left">Firewall</td>                    
                                <td class="left">Yes</td>
                            </tr>                
                            <tr>
                                <td class="left">VPN Support</td>                    
                                <td class="left">Yes</td>
                            </tr>      
                            <tr>
                                <td class="left">Automatical updates<sup>1</sup></td>                    
                                <td class="left">Yes via APT</td>
                            </tr>                

                            <tr>
                                <td class="left">Protocols<sup>2</sup></td>
                                <td class="left">Collectd, Mbus, Modbus, Mqtt, Midi, Redis, Opc UA, XDB</td>
                            </tr>
                            <tr>
                                <td class="left">Uplink to Rossaker Big Data System</td>
                                <td class="left">Yes, TLS over WebSockets</td>
                            </tr>

                            <tr>
                                <td class="left">RS485</td>
                                <td class="left">1-2</td>

                            </tr>
                            <tr>
                                <td class="left">RS232</td>
                                <td class="left">1 (optional)</td>
                            </tr>
                            <tr>
                                <td class="left">CANBUS</td>
                                <td class="left">1 (optional)</td>
                            </tr>

                            <tr>
                                <td class="left">Digital I/O</td>                    
                                <td class="left">Optional 4x digital outputs + 4x digital inputs</td>
                            </tr>

                            <tr>
                                <td class="left">Analog I/O</td>                    
                                <td class="left">Up-to 2x analog 4-20mA input</td>
                            </tr>

                            <tr>
                                <td class="left">RAM</td>
                                <td class="left">1 - 4 GB</td>
                            </tr>
                            <tr>
                                <td class="left">Storage</td>
                                <td class="left">eMMC several size options</td>
                            </tr>
                            
                            <tr>
                                <td class="left">LTE</td>                    
                                <td class="left">Optional</td>
                            </tr>

                            <tr>
                                <td class="left">Ethernet</td>                    
                                <td class="left">2x100Mbps</td>
                            </tr>

                            <tr>
                                <td class="left">PoE</td>                    
                                <td class="left">Optional</td>
                            </tr>

                            <tr>
                                <td class="left">Wifi</td>                    
                                <td class="left">Optional</td>
                            </tr>

            
                            <tr>
                                <td class="left">Secure boot</td>                    
                                <td class="left">Yes</td>
                            </tr>

                            <tr>
                                <td class="left">TPM 2.0</td>                    
                                <td class="left">Yes</td>
                            </tr>

                            <tr>
                                <td class="left">Temperature range <sup>3</sup></td>
                                <td class="left">-40 to 80C</td>
                            </tr>

                            <tr>
                                <td class="left">DIN mounting/td>
                                <td class="left">Yes</td>
                            </tr>                

                            <tr>
                                <td class="left">Fanless/td>
                                <td class="left">Yes</td>
                            </tr>

                            <tr>
                                <td class="left">Power</td>                    
                                <td class="left">DC input: unregulated 8V to 36V
                                    Reverse voltage protection</td>
                            </tr>

                            <tr>
                                <td class="left">Power consumption</td>                    
                                <td class="left">2W - 7W, depending on system load and configuration
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="left">Dimensions</td>                    
                                <td class="left">112 x 84 x 25 mm</td>
                            </tr>       
                            
                            <tr>
                                <td class="left">Compliance</td>                    
                                <td class="left">CE, FCC, EN 55032/5, EN 61000-6-2, EN 61000-6-3, EN/UL/IEC 62368-1</td>
                            </tr>  

                            <tr>
                                <td class="left">MTTF</td>                    
                                <td class="left">&gt; 200,000 hours</td>
                            </tr>                  
                            <tr>
                                <td class="left">Limited hardware warranty</td>                    
                                <td class="left">5 years</td>
                            </tr>      

                        </tbody>

                        <tfoot>
                            <tr>
                                <td class="left" colspan="2">
                                    <sup>1</sup>Automatical updates are configurable; select manual updates, update security patches only or full automatical updates.<br>
                                    <sup>2</sup>Protocols listed are functionally supported, please verify protocol details with pre-market before ordering to verify compatibility.<br>
                                    <sup>3</sup>Temperature ranges may vary depending on selected options, there may also be several temperature range options.
                                </td>
                            </tr>
                        </tfoot>                                            
                    </table>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

        <h3>R-200 series</h3>
        The R-200 is a lightweight controller with built-in I/O.
        <clr-accordion>
            <clr-accordion-panel>
                <clr-accordion-title>Technical overview of the R-200 family</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>            
                    Technical overview&apos; are subject to change and depending on selected configuration features and properties may vary.

                    <table class="table" >
                        <thead>
                            <tr>                        
                                <th class="left">Function</th>
                                <th class="left">Support</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="left">Web based</td>
                                <td class="left">Yes, all configuration, programming and other tools are web based with no browser plugins required.</td>
                            </tr>                    
                            <tr>
                                <td class="left">SCADA</td>
                                <td class="left">Yes, Web-based Flow charts / P&ID, etc</td>
                            </tr>
                            <tr>
                            <td class="left">PLC</td>
                            <td class="left">Yes, LUA-programs with up to 20 kHz loop cycle</td>
                        </tr>
                        <tr>
                            <td class="left">PLC Debugger</td>
                            <td class="left">Yes</td>
                        </tr>
                        <tr>
                            <td class="left">Opensource</td>
                            <td class="left">Includes Rossaker Open Source Controller Library (LUA)</td>
                        </tr>
                        <tr>
                            <td class="left">Operation System</td>
                            <td class="left">Debian based Linux</td>
                        </tr>

                        <tr>
                            <td class="left">Firewall</td>                    
                            <td class="left">Yes</td>
                        </tr>                
                        <tr>
                            <td class="left">VPN Support</td>                    
                            <td class="left">Yes</td>
                        </tr>      
                        <tr>
                            <td class="left">Automatical updates<sup>1</sup></td>                    
                            <td class="left">Yes via APT</td>
                        </tr>                

                        <tr>
                            <td class="left">Protocols<sup>2</sup></td>
                            <td class="left">Collectd, Mbus, Modbus, Mqtt, Midi, Redis, Opc UA, XDB</td>
                        </tr>
                        <tr>
                            <td class="left">Uplink to Rossaker Big Data System</td>
                            <td class="left">Yes, TLS over WebSockets</td>
                        </tr>

                        <tr>
                            <td class="left">RS485</td>
                            <td class="left">Optional USB Accessory</td>

                        </tr>
                        <tr>
                            <td class="left">RS232</td>
                            <td class="left">No</td>
                        </tr>
                        <tr>
                            <td class="left">CANBUS</td>
                            <td class="left">No</td>
                        </tr>

                        <tr>
                            <td class="left">Digital I/O</td>                    
                            <td class="left">7 x configurable digital input/output pins, for potential-free or voltage inputs, TLL input/outputs and open collector outputs.<br>
                                4 x power relay outputs rated for 6A at 250V
                            </td>
                        </tr>

                        <tr>
                            <td class="left">Analog I/O</td>                    
                            <td class="left">2 x analog voltage inputs 0÷30V<br>
                                2 x analog voltage inputs 0÷3V on internal pin-headers
                            </td>
                        </tr>

                        <tr>
                            <td class="left">1-Wire and Wiegand support</td>
                            <td class="left">Yes</td>
                        </tr>

                        <tr>
                            <td class="left">RAM</td>
                            <td class="left">2 - 8 GB</td>
                        </tr>
                        <tr>
                            <td class="left">Storage</td>
                            <td class="left">SD-card</td>
                        </tr>
                        
                        <tr>
                            <td class="left">LTE</td>                    
                            <td class="left">Optional USB Accessory</td>
                        </tr>

                        <tr>
                            <td class="left">Ethernet</td>                    
                            <td class="left">1x1Gbps</td>
                        </tr>

                        <tr>
                            <td class="left">PoE</td>                    
                            <td class="left">No</td>
                        </tr>

                        <tr>
                            <td class="left">Wifi</td>                    
                            <td class="left">Yes</td>
                        </tr>

        
                        <tr>
                            <td class="left">Secure boot</td>                    
                            <td class="left">TBA</td>
                        </tr>

                        <tr>
                            <td class="left">TPM 2.0</td>                    
                            <td class="left">TBA</td>
                        </tr>

                        <tr>
                            <td class="left">Temperature range <sup>3</sup></td>
                            <td class="left">-20 to 50C</td>
                        </tr>

                        <tr>
                            <td class="left">DIN mounting/td>
                            <td class="left">Yes</td>
                        </tr>                

                        <tr>
                            <td class="left">Fanless/td>
                            <td class="left">Yes</td>
                        </tr>

                        <tr>
                            <td class="left">Power</td>                    
                            <td class="left">Input: 9÷28Vdc, with surge and reverse polarity protection, and 2.2A resettable fuse.
                            </td>
                        </tr>

                        <tr>
                            <td class="left">Power consumption</td>                    
                            <td class="left">2W - 7W, depending on system load and configuration
                            </td>
                        </tr>
                        
                        <tr>
                            <td class="left">Dimensions</td>                    
                            <td class="left">4 Module DIN</td>
                        </tr>       
                        
                        <tr>
                            <td class="left">Compliance</td>                    
                            <td class="left">EN 61000-4-2 (ESD)<br>
                                EN 61000-4-3 (Radiated RF Field)    <br>                             
                                EN 61000-4-4 (Burst/fast transient)<br>
                                EN 61000-4-5 (Surge)<br>
                                EN 61000-4-6 (Conducted)<br>
                                EN 61000-4-8 (Power frequency magnetic field)<br>
                                IP20
                            
                            </td>
                        </tr>  

                        <tr>
                            <td class="left">MTTF</td>                    
                            <td class="left">&gt; 200,000 hours</td>
                        </tr>                  
                        <tr>
                            <td class="left">Limited hardware warranty</td>                    
                            <td class="left">Per request</td>
                        </tr>      

                    </tbody>

                    <tfoot>
                        <tr>
                            <td class="left" colspan="2">
                                <sup>1</sup>Automatical updates are configurable; select manual updates, update security patches only or full automatical updates.<br>
                                <sup>2</sup>Protocols listed are functionally supported, please verify protocol details with pre-market before ordering to verify compatibility.<br>
                                <sup>3</sup>Temperature ranges may vary depending on selected options, there may also be several temperature range options.
                            </td>
                        </tr>
                    </tfoot>                                            
                </table>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>

        <h3>R-300 series</h3>
        R-300 is the largest control unit in the R series with large data persistent storage capacity, suitable for business logic implementation.
            <clr-accordion>
                <clr-accordion-panel>
                    <clr-accordion-title>Technical overview of the R-300 family</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>        
                        Technical overview&apos; are subject to change and depending on selected configuration features and properties may vary.
    
                        <table class="table" >
                            <thead>
                                <tr>                        
                                    <th class="left">Function</th>
                                    <th class="left">Support</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="left">Web based</td>
                                    <td class="left">Yes, all configuration, programming and other tools are web based with no browser plugins required.</td>
                                </tr>                    
                                <tr>
                                    <td class="left">SCADA</td>
                                    <td class="left">Yes, Web-based Flow charts / P&ID, etc</td>
                                </tr>
                                <tr>
                                <td class="left">PLC</td>
                                <td class="left">Yes, LUA-programs with up to 20 kHz loop cycle</td>
                            </tr>
                            <tr>
                                <td class="left">PLC Debugger</td>
                                <td class="left">Yes</td>
                            </tr>
                            <tr>
                                <td class="left">Opensource</td>
                                <td class="left">Includes Rossaker Open Source Controller Library (LUA)</td>
                            </tr>
                            <tr>
                                <td class="left">Operation System</td>
                                <td class="left">Debian based Linux</td>
                            </tr>

                            <tr>
                                <td class="left">Firewall</td>                    
                                <td class="left">Yes</td>
                            </tr>                
                            <tr>
                                <td class="left">VPN Support</td>                    
                                <td class="left">Yes</td>
                            </tr>      
                            <tr>
                                <td class="left">Automatical updates<sup>1</sup></td>                    
                                <td class="left">Yes via APT</td>
                            </tr>                

                            <tr>
                                <td class="left">Protocols<sup>2</sup></td>
                                <td class="left">Collectd, Mbus, Modbus, Mqtt, Midi, Redis, Opc UA, XDB</td>
                            </tr>
                            <tr>
                                <td class="left">Uplink to Rossaker Big Data System</td>
                                <td class="left">Yes, TLS over WebSockets</td>
                            </tr>

                            <tr>
                                <td class="left">RS485</td>
                                <td class="left">1 (Cannot be combined with RS232)</td>

                            </tr>
                            <tr>
                                <td class="left">RS232</td>
                                <td class="left">1 (Cannot be combined with RS485)</td>
                            </tr>
                            <tr>
                                <td class="left">CANBUS</td>
                                <td class="left">No</td>
                            </tr>

                            <tr>
                                <td class="left">Digital I/O</td>                    
                                <td class="left">-</td>
                            </tr>

                            <tr>
                                <td class="left">Analog I/O</td>                    
                                <td class="left">-</td>
                            </tr>

                            <tr>
                                <td class="left">RAM</td>
                                <td class="left">8 GB</td>
                            </tr>
                            <tr>
                                <td class="left">Storage</td>
                                <td class="left">eMMC several size options<br>NVME several size options.</td>
                            </tr>
                            
                            <tr>
                                <td class="left">LTE</td>                    
                                <td class="left">Optional</td>
                            </tr>

                            <tr>
                                <td class="left">Ethernet</td>                    
                                <td class="left">2xGbps</td>
                            </tr>

                            <tr>
                                <td class="left">PoE</td>                    
                                <td class="left">Optional</td>
                            </tr>

                            <tr>
                                <td class="left">Wifi</td>                    
                                <td class="left">Yes</td>
                            </tr>

            
                            <tr>
                                <td class="left">Secure boot</td>                    
                                <td class="left">TBA</td>
                            </tr>

                            <tr>
                                <td class="left">TPM 2.0</td>                    
                                <td class="left">Optional</td>
                            </tr>

                            <tr>
                                <td class="left">Temperature range <sup>3</sup></td>
                                <td class="left">-20 to 60C</td>
                            </tr>

                            <tr>
                                <td class="left">DIN mounting/td>
                                <td class="left">Yes</td>
                            </tr>                

                            <tr>
                                <td class="left">Fanless/td>
                                <td class="left">Yes</td>
                            </tr>

                            <tr>
                                <td class="left">Power</td>                    
                                <td class="left">DC input: 8~24 VDC</td>
                            </tr>

                            <tr>
                                <td class="left">Power consumption</td>                    
                                <td class="left">
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="left">Dimensions</td>                    
                                <td class="left">(WxHxD) 102.5 x 129 x 38 mm 4.04 x 5.08 x 1.50 in</td>
                            </tr>       
                            
                            <tr>
                                <td class="left">Compliance</td>                    
                                <td class="left">FCC 47 CFR Part 15<br>
                                    CE<br>
                                    UKCA<br>
                                    UL/ETL Listed configurations available<br>
                                    CB scheme<br>
                                    WEEE Directive (2002/96/EC)<br>
                                    ErP Directive (2009/125/EC)<br>
                                    Low Voltage Directive (2014/35/EU)<br>
                                    Electromagnetic Compatibility Directive (2014/30/EU)<br>
                                    Radio Equipment Directive (2014/53/EU) - For configurations with wireless transmitters<br>
                                    RoHS 2 (2011/65/EU)<br>
                                    RoHS 3 (2015/863/EU)<br>
                                    REACH<br>
                                    RCM<br>
                                    VCCI-CISPR-32<br>
                                    EN 301 489-1<br>
                                    EN 301 489-17<br>
                                    EN 55032<br>
                                    EN 55035<br>
                                    EN 62368-1<br>
                                    IEC 62368-1<br>
                                    UL 62368-1<br>
                                    EN 301 489-52<br>
                                    UL/IEC/EN 61010-1<br>
                                    UL/IEC/EN 61010-2-201<br>
                                    IEC 60601-1-2 (4th edition)<br>
                                    IEC 60945</td>
                            </tr>  

                            <tr>
                                <td class="left">MTTF</td>                    
                                <td class="left">TBA</td>
                            </tr>                  
                            <tr>
                                <td class="left">Limited hardware warranty</td>                    
                                <td class="left">2 Years</td>
                            </tr>      

                        </tbody>

                        <tfoot>
                            <tr>
                                <td class="left" colspan="2">
                                    <sup>1</sup>Automatical updates are configurable; select manual updates, update security patches only or full automatical updates.<br>
                                    <sup>2</sup>Protocols listed are functionally supported, please verify protocol details with pre-market before ordering to verify compatibility.<br>
                                    <sup>3</sup>Temperature ranges may vary depending on selected options, there may also be several temperature range options.
                                </td>
                            </tr>
                        </tfoot>                                            
                    </table>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>
        </div>   

        <div>
            <a id="Automation"><h2>Big Data Edges</h2> </a>
            Big Data Edges from us are designed based on your requests. <br>
            We offer deployment in any server format or size.<br>
            For industrial usage with DIN-mounting, we offer many options in the R-300 family.<br>
            <br>
            <a routerLink="/contact">Contact us for more information.</a>
        </div>   
        <div>
            <a id="CommunicationEdge"><h2>Communication Edge: R-300Connect</h2></a>
            Our Communication Edge provides communications services based on R-300, <br>
            shipped with a fully controllable firewall supporting several VPN services. <br>
            Communication services include: <br>

            <ul>
                <li>Local LoRaWAN</li>
                <li>W-MBus/MBus</li>
                <li>OPC UA server</li>
                <li>MQTT</li>
                <li>Fieldbus proxies</li>
            </ul>

            <a routerLink="/contact">Contact us for information on products and pricing. </a><br>
            Or, get inspired from our <a routerLink="/solutions">Solutions</a>.

        </div>   

    </div>

</div>