import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-start-mobile-app',
  templateUrl: './start-mobile-app.component.html',
  styleUrls: ['./start-mobile-app.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class StartMobileAppComponent implements OnInit, AfterViewInit {

  // todo design: https://github.com/capacitor-community/barcode-scanner/blob/main/examples/scan-area-layout/index.html
  private oldbackgr = '';
  constructor()
  {
  }

  public ScannedData : string = "";


  async didUserGrantPermission() :Promise<boolean>
  {
    // check if user already granted permission
    console.log("QRSCAN Verifying rights");
    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.granted) {
      console.log("QRSCAN user has granted rights!");
      // user granted permission
      return true;
    }

    if (status.denied) {
      console.log("QRSCAN Verifying rights - denied");
      // user denied permission
      return false;
    }

    if (status.asked) {
      console.log("QRSCAN asked");
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.neverAsked) {
      console.log("QRSCAN nver asked");
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = confirm('We need your permission to use your camera to be able to scan barcodes');
      if (!c) {
        return false;
      }
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      console.log("QRSCAN DENIED");
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }

    // user did not grant the permission, so he must have declined the request
    return false;
  };

  // document.getElementsByClassName('main-container')[0].style.backgroundColor="transparent !important"

  setTransparent()
  {
    //let maincontainer = document.getElementsByClassName('main-container');
    //maincontainer[0]["style"]["background-color"] = 'transparent !important';
    BarcodeScanner.hideBackground(); // make background of WebView transparent
  }
  setVisible()
  {
    //let maincontainer = document.getElementsByClassName('main-container');
    //maincontainer[0]["style"]["background-color"] = this.oldbackgr;
    BarcodeScanner.showBackground();
  }
  stopscan()
  {
    BarcodeScanner.stopScan();
    this.setVisible();
  }

  public disablescan = false;
  public scanning = false;

  async scan() : Promise<string>
  {
    this.scanning = true;
    console.log("Starting to scan.. Verifying rights");
    let granted = await this.didUserGrantPermission();
    if(!granted)
    {
      console.error("QRSCAN No permission to camera");
      return;
    }

    console.log("QRSCAN set transparent");
    this.setTransparent();

    console.log("QRSCAN start scan");
    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

    if (result.hasContent) {
      console.log("QRSCAN got content", result.content);
      this.ScannedData = result.content;
    }
    else
    {
      console.log("QRSCAN no content");
    }
    console.log("QRSCAN stopping scan");
    this.stopscan();
    this.scanning = false;
    return this.ScannedData;
  }

  async testApiKey(key : string) : Promise<boolean>
  {
    return false;
  }

  redirectToHome() : void
  {
    return;
  }

  async LoginViaQRCode() : Promise<void>
  {
    const xapikey = "xprojector-api-key";
    let savedAPIKey = await Preferences.get({ key: xapikey});
    if(savedAPIKey)
    {
      let okKey = await this.testApiKey(savedAPIKey.value);
      if(okKey)
      {
        return this.redirectToHome();
      }
    }

    // We haz bad key lets scan and d o it all again
    this.scan();
    Preferences.set({
      key: xapikey,
      value: this.ScannedData,
    });

  }

  async ngAfterViewInit(): Promise<void> {
    return await this.LoginViaQRCode();
    /*
    let maincontainer = document.getElementsByClassName('main-container');
    let t = maincontainer[0]["style"]["background-color"];
    if(t)
      this.oldbackgr = t;*/
  }
  async ngOnInit(): Promise<void> {

    //this.scan();
  }

}
