<clr-modal [(clrModalOpen)]="showRemoveUser">
  <h3 class="modal-title" i18n='@@userprofiles_removeuser'>Remove user</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button i18n='@@userprofiles_cancel' type="button" class="btn btn-outline" (click)="showRemoveUser = false">Cancel</button>
    <button i18n='@@userprofiles_ok' type="button" class="btn btn-primary" (click)="showRemoveUser = false; doRemoveSelectedUser()">Ok</button>
  </div>
</clr-modal>

<div class="content-area">
  <div class="card" *ngIf="users">
    <div class="card-header" i18n='@@userprofiles_users'>
      Users
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid>
          <clr-dg-column i18n='@@userprofiles_firstname'>Firstname</clr-dg-column>
          <clr-dg-column i18n='@@userprofiles_surname'>Surname</clr-dg-column>
          <clr-dg-column i18n='@@userprofiles_email'>Email</clr-dg-column>
          <clr-dg-column i18n='@@userprofiles_admin'>Admin</clr-dg-column>
          <clr-dg-column> </clr-dg-column>

          <clr-dg-row *ngFor="let user of users; let i = index" [clrDgItem]="user">
            <clr-dg-cell>
              {{user.firstname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.surname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.email}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.isAdmin ? 'True' : 'False'}}
            </clr-dg-cell>
            <clr-dg-cell>
              <clr-icon (click)="removeUser(user);" shape="trash" [style.cursor]="'pointer'"
                *ngIf="user.id != currentUserRights.session.userId"></clr-icon>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>{{users ? users.length : 0}} <ng-container i18n='@@userprofiles_users2'>Users</ng-container></clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>

  <div class="content-area">
    <div class="card">
      <div class="card-header" i18n='@@userprofiles_adduser'>
        Add user
      </div>
      <div class="card-block">
        <form clrForm (ngSubmit)="addCustomerUser()">
          <clr-input-container>
            <label i18n='@@userprofiles_firstname2'>Firstname:</label>
            <input clrInput required placeholder="firstname" name="firstname" type="text"
              [(ngModel)]="newUser.firstname" />
            <clr-control-error i18n='@@userprofiles_pleaseinputfirstname'>Please input firstname.</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label i18n='@@userprofiles_surname2'>Surname:</label>
            <input clrInput required placeholder="surname" name="surname" type="text" [(ngModel)]="newUser.surname" />
            <clr-control-error i18n='@@userprofiles_pleaseinputsurname'>Please input surname.</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label i18n='@@userprofiles_email2'>Email:</label>
            <input clrInput required placeholder="email" name="email" type="text" [(ngModel)]="newUser.email" />
            <clr-control-error i18n='@@userprofiles_pleaseinputemail'>Please input email.</clr-control-error>
          </clr-input-container>
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="newUser.isAdmin" name="isadmin" />
              <label i18n='@@userprofiles_admin'>Admin</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>

          <br>
          <button type="submit" class="btn btn-outline">
            <clr-icon shape="plus-circle"></clr-icon>
            <ng-container i18n='@@userprofiles_adduser2'>Add user</ng-container>
          </button>
        </form>

      </div>
    </div>
  </div>
