<div class="background">
    <div class="card centered" (scroll)="onScroll($event)">
        <div i18n='@@gdprconsent_header' class="card-header">
            Consent.
        </div>
        <div class="card-block" (scroll)="onScroll($event)">
            <div class="card-text" (scroll)="onScroll($event)">
                <p><b>By continuing, you agree to Homesolution’s Privacy Notice. The site uses cookies, see our Cookie Notice below for more information.</b></p>

                <h1>Privacy Notice</h1>

                <h2>Updated: 2021-12-15</h2>
                <p>This Privacy Notice describes how Homesolution collect and use your personal information in relation to websites, and applications. </p>

                <h2>Personal Information</h2>
                <p>We collect your personal information to provide our IMD and Smart Building services provided to you. The types of information we gather:
                </p>
                <ul style="list-style-type:circle">
                    <li>Information provided by you, which are needed for our service offering. </li>
                    <li>Automatic information, such as email addresses and phone numbers used to contact us and identifiers and information contained in cookies.</li>
                    <li>Information from other sources where access has been granted by you or our end customer. This can be public building records, utility company metering data, etc. </li>
                </ul>

                <h2>Cookies</h2>
                <p>Cookies are used to recognize your browser or device to deliver our service. Information about cookies are found in Cookie Notice.</p>

                <h2>Use of Personal Information</h2>
                <p>We use your personal information to operate, provide, and improve our Offerings, including:</p>
                <ul style="list-style-type:circle">

                    <li>The personal information is used to provide and deliver our Offerings and process transactions related to our Offerings, including registrations, subscriptions, and notifications.</li>
                    <li>Contact information for support, notification by phone, chat or email</li>
                    <li>Comply with legal obligations and regulations</li>
                    <li>Measurements of usage, performance and provide support. </li>
                    <li>Personalization of preferences and user experience</li>

                </ul>

                <h2>Sharing of Personal Information</h2>
                <p>Customer information is essential to provide our business offering, and Homesolutions do not sell our customers’ personal information to others. Personal information can be shared with third parties, in order to deliver an integrated solution
                    or when fetching data.</p>

                <h2>Anonymized data and aggregated data</h2>
                <p>Anonymized data and aggregated data are collected and used for data analysis, statistics and for comparison. No Personal Information cannot be identified in the anonymized data and aggregated data.</p>

                <h2>Location of Personal Information</h2>
                <p>The Personal Information is located in servers in Sweden and all data handling follows Swedish laws. </p>

                <h2>Information Security</h2>
                <p>Security is our highest priority. The system is designed with your security and privacy in mind.</p>

                <ul style="list-style-type:circle">
                    <li>The data is protected during transmission between different systems and services by encryption </li>
                    <li>The electronic, procedural and electronic security is protected during collection, storage, and disclosure of personal information. </li>
                </ul>


                <h2>Retention of Personal Information</h2>
                <p>The personal information is kept for as long as required to fulfill the relevant purposes described in this Privacy Notice, as may be required by law (including for tax and accounting purposes), or as otherwise communicated to you. </p>

                <h2>Contacts and Revisions</h2>
                <p>If you have any concern about our privacy notice please <a ahref="https://propeye.se">contact us</a>. This Privacy Notice may change over time, please revisit this website frequently to see changes. A dated change log track all revisions.
                </p>

                <h1>Cookie Notice</h1>
                <h2>Updated: 2021-12-15</h2>
                <p>Cookies, pixels, and similar technologies (hereafter, “cookies”) to recognize your browser or device, learn more about your interests, provide you with essential features and services, and for additional purposes, including:</p>
                <ul>
                    <li>Recognizing you when signing in to use our offerings. The cookies allows us to display personalized content, and provide other customized features and services.</li>
                    <li>Keeping track of your settings and specified preferences, such as your language and configuration preferences.</li>
                    <li>Conducting research and diagnostics to improve our offerings.</li>
                    <li>Preventing fraudulent activity.</li>
                    <li>Improving security.</li>
                    <li>Delivering content, </li>
                    <li>Measure and analyze usage, performance and relevance of our offerings. </li>

                </ul>

                <p>Session cookies are deleted at the end of your browsing session, while others persist between sessions. Essential cookies remain on your device for up to 365 days from your last visit to our site. Other cookies remain on your device for
                    up to 365 days from their last use.</p>


                <h2>Information collected through cookies</h2>
                <p>Examples of the information that is automatically collected through cookies may include:</p>

                <ul style="list-style-type:circle">
                    <li>Network and connection information, such as the Internet protocol (IP) address used to connect your computer or other device to the Internet and information about your Internet service provider</li>
                    <li>Computer and device information, such as device, time zone setting, application, or browser type and version, browser plug-in type and version, or operating system. </li>
                    <li>Authentication and security credential information</li>
                    <li>The location of your device or computer</li>
                    <li>Content interaction information, such as content downloads, streams, and playback details, including duration and number of simultaneous streams and downloads</li>
                    <li>The full Uniform Resource Locators (URL) clickstream to, through, and from our site (including date and time) and Offerings, content you viewed or searched for, page response times, download errors, and page interaction information
                        (such as scrolling, clicks, and mouse-overs)</li>
                </ul>


                <h2>Managing cookies</h2>
                <p>The cookies allows us to provide useful features, blocking some types of cookies may impact your experience of the site. The cookie preference can be changed in on your device or browser. Check the browser support site for available privacy
                    settings. Third party cookies Approved integrated third party applications, such as search engines, viewers or support tools may also set cookies when interacting with our service. The third parties’ cookies are used to deliver content
                    and to perform services on behalf of Homesolutions.</p>

                <table>
                    <tr>
                        <td>Adobe</td>
                        <td>adobe.com</td>
                    </tr>
                    <tr>
                        <td>Zammad</td>
                        <td>zammad.com</td>
                    </tr>
                    <tr>
                        <td>CloudFlare</td>
                        <td>cloudflare.com</td>
                    </tr>
                </table>

                <p>We also embed features from third parties on our sites, and those third parties may set cookies if you engage with those features. For example, we embed chat functionality from Salesforce and videos from Vidyard or our official YouTube
                    channel using YouTube’s privacy-enhanced mode. These experiences set cookies on your device once you click on a chat link or video player.
                </p>

            </div>
        </div>
        <div class="card-footer">
            <button i18n='@@gdprconsent_deny' class="btn btn-secondary" (click)="deny()">Deny</button>
            <button i18n='@@gdprconsent_agree' class="btn btn-primary" [disabled]="!agreeEnabled" (click)="agree()">Agree</button>
        </div>
    </div>
</div>
