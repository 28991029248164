<h3>Readout values</h3>
<clr-datagrid [clrDgLoading]="loading">

  <clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="ascSort">
    <ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'timestamp'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Time</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'value'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Value</ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let dataValue of datavalues; let i = index" [clrDgItem]="dataValue">
    <clr-dg-cell>{{dataValue.id}}</clr-dg-cell>
    <clr-dg-cell>{{formatDate(dataValue.timestamp)}}</clr-dg-cell>
    <clr-dg-cell>{{dataValue.value}}</clr-dg-cell>

  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>
<p *ngIf="message?.length > 0">Error: {{message}}</p>
