import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DashboardColorPaletteEntry, DashboardConfig, SideNavPosition } from '../xproj-dashboard-service';
import { ArrayUtils } from '../../utils/array-utils-service';
import { OutputDataType, WidgetConfig, WidgetOutputParameter } from '../../widgets/widget-config-service';
import { TypedJSON } from 'typedjson';
import { saveAs } from 'file-saver';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ClipboardService } from 'ngx-clipboard'
import { CompactType, GridType } from 'angular-gridster2';
import { DASHBOARDSERVICE, XprojDashboardService } from '../xproj-dashboard-service';
import { XprojModalService } from '../../modals/xproj-modal-service.service';
import { Guid } from '../../utils/guid-service';

@Component({
  selector: 'xproj-dashboard-config',
  templateUrl: './xproj-dashboard-config.component.html',
  styleUrls: ['./xproj-dashboard-config.component.scss']
})
export class XprojDashboardConfigComponent implements OnInit {

  @Input() config: DashboardConfig;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSelectWidget = new EventEmitter<WidgetConfig>();

  showExportDialog: boolean = false;
  exportFilename: string = 'dashboardexport.json';
  showCopiedToClipboardAlert = false;
  jsonActive: boolean = false;
  widgetsActive: boolean = false;

  selectedOutputParameter : WidgetOutputParameter;
  selectedPaletteColor : DashboardColorPaletteEntry;

  OutputDataType = OutputDataType;

  constructor(@Inject(DASHBOARDSERVICE) private dashboardService: XprojDashboardService,
    private clipboard: ClipboardService, private modalService: XprojModalService) { }

  ngOnInit(): void {
  }

  removeWidget(index: number) {
    this.modalService.ShowConfirmModal({ header: 'Remove widget', description: 'Remove widget, are you sure?' }, (result) => {
      if (result) {
        ArrayUtils.RemoveItemAt(this.config.WidgetConfigs, index);
      }
    });
  }

  async cloneWidget(widgetConfig: WidgetConfig) {
    let newConfig = widgetConfig.Clone();

    newConfig.Id = Guid.newGuid();
    newConfig.Name = widgetConfig.Name + ' (Copy)';
    newConfig.Title = widgetConfig.Title + ' (Copy)';

    this.config.WidgetConfigs.push(newConfig);
  }

  selectWidget(widgetConfig: WidgetConfig) {
    this.onSelectWidget?.next(widgetConfig);
  }

  clearConfig() {
    this.modalService.ShowConfirmModal({ header: 'Clear all', description: 'Clear all widgets, are you sure?' }, (result) => {
      if (result) {
        this.config.WidgetConfigs.length = 0;
      }
    });
  }

  close() {
    this.onClose?.next(true);
  }

  getJson(): string {
    return TypedJSON.stringify(this.config, DashboardConfig, { indent: 2 });
  }

  copyJsonToClipboard(): void {
    this.showCopiedToClipboardAlert = true;
    this.clipboard.copy(this.getJson());
    setTimeout(() => {
      this.showCopiedToClipboardAlert = false;
    }, 3000);
  }

  addPaletteColor()
  {
    this.config.ColorPalette.push(new DashboardColorPaletteEntry())
  }
  deleteSelectedPaletteColor()
  {
    this.config.ColorPalette = this.config.ColorPalette.filter(p => p != this.selectedPaletteColor);
  }

  addOutputParameter() {
    this.config.OutputParameters.push(new WidgetOutputParameter());
  }

  deleteSelectedParameter() {
    this.config.OutputParameters = this.config.OutputParameters.filter(p => p != this.selectedOutputParameter);
  }

}
