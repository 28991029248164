<h3>Logs '{{node?.name}}'</h3>
<clr-datagrid>

  <clr-dg-action-bar>
    <div class="btn-group" style="float: right;">
      <button class="btn btn-sm btn-secondary" (click)="export();"><clr-icon shape="export"
          directions="left"></clr-icon> Export</button>
    </div>
  </clr-dg-action-bar>


  <clr-dg-column [clrDgField]="'eventType'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'timestamp'" [clrDgSortOrder]="descSort">
    <ng-container *clrDgHideableColumn="{hidden: false}">Time</ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'message'">
    <ng-container *clrDgHideableColumn="{hidden: false}">Message</ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let log of nodeLogs; let i = index" [clrDgItem]="log">
    <clr-dg-cell>{{log.eventType | grpc_eventtype}}</clr-dg-cell>
    <clr-dg-cell>{{formatDate(log.timestamp)}}</clr-dg-cell>
    <clr-dg-cell>{{log.message}}</clr-dg-cell>

  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>
