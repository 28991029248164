<form clrForm clrLayout="vertical">
  <clr-select-container *ngIf="projectionConfig.ShowProjection">
    <label>{{projectionConfig.Label ? projectionConfig.Label : 'Projection'}}</label>
    <select clrSelect name="projection" [(ngModel)]="selectedProjection"
      (ngModelChange)="selectedProjectionChange()">
      <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name | xproj_pretty}}</option>
    </select>
  </clr-select-container>

  <xproj-group-selection #groupSelect *ngIf="projectionConfig.ShowGroup"  [projection]="selectedProjection" [selected]="projectionConfig.DefaultGroup" (groupSelected)="groupSelected($event)">
  </xproj-group-selection>
</form>
