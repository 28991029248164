<main class="content-area">
  <h1>Process Graphs</h1>

  <div class="limit-height" style="max-width:1200px;">
    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedGraph" [clrDgRowSelection]="true"
      [clrDgLoading]="loadingGraphs">

      <clr-dg-column [clrDgField]=" 'Graph.name' " [clrDgSortOrder]="ascSort "> Name </clr-dg-column>
      <clr-dg-column [clrDgField]=" 'Graph.description' "> Description </clr-dg-column>
      <clr-dg-column [clrDgField]=" 'Graph.xautogroup' "> AutoGroup </clr-dg-column>
      <clr-dg-column [clrDgField]=" 'Graph.xgroup' "> XGroup </clr-dg-column>

      <clr-dg-row *clrDgItems="let graph of graphs " [clrDgItem]="graph ">
        <clr-dg-cell class="cellhideoverflow "> {{graph.name}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow "> {{graph.description}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow "> {{graph.xautogroup}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow "> {{graph.xgroup}} </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0] ">
          <clr-dg-page-size [clrPageSizeOptions]="sizeOptions ">Graphs per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Process Graphs
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

  <button class="btn btn-link " (click)="addGraph()">
    <clr-icon shape="plus-circle "></clr-icon>
    New Process Graph
  </button>
  <button *ngIf="selectedGraph " type="button " class="btn btn-link " [clrLoading]="savingRemovingGraph "
    (click)="removeSelectedGraph(); ">
    <clr-icon shape="minus-circle "></clr-icon>
    Remove Process Graph
  </button>

  <div *ngIf="selectedGraph">

    <clr-tabs #tabs>
      <clr-tab>
        <button clrTabLink>Process graph</button>
        <ng-template [clrIfActive]="true">
          <clr-tab-content>
            <h2>Process Graph</h2>
            <div [innerHTML]="selectedGraph.svg|xproj_safe:'html'"></div>
            <form clrForm>
              <clr-input-container>
                <label>Process Graph ID </label>
                <input clrInput type="text " [(ngModel)]="selectedGraph.xautoprocessgraphid "
                  name="xautoprocessgraphid " disabled size="35 " />
              </clr-input-container>

              <clr-input-container>
                <label>Name </label>
                <input clrInput type="text " [(ngModel)]="selectedGraph.name " name="graphname " size="35 " />
              </clr-input-container>

              <clr-input-container>
                <label>Description </label>
                <input clrInput type="text " [(ngModel)]="selectedGraph.description " name="graphdescription "
                  size="35 " />
              </clr-input-container>

              <clr-input-container>
                <label>XAuto Group </label>
                <input clrInput type="text " [(ngModel)]="selectedGraph.xautogroup " name="graphxautogroup "
                  size="35 " />
              </clr-input-container>

              <clr-input-container>
                <label>XGroup </label>
                <input clrInput type="text " [(ngModel)]="selectedGraph.xgroup " name="graphxgroup " size="35 " />
              </clr-input-container>
            </form>

            <div class="graphButtons">
              <button class="btn " [clrLoading]="savingGraph" (click)="saveSelectedGraph() ">Save</button>
              <button class="btn " (click)="editGraph() " [disabled]="windowEditGraph!=null">Edit Graph (Visuals)</button>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Variables</button>
        <ng-template [clrIfActive]="false">
          <clr-tab-content>
            <app-edit-variables [(numericalmapxautovariables)]="selectedGraph.numericalmapxautovariables" (onAdd)="variableUpdated($event)"
            (onUpdate)="variableUpdated($event)" (onRemove)="variableUpdated($event)"></app-edit-variables>
            <div class="graphButtons">
              <button class="btn " [clrLoading]="savingGraph" (click)="saveSelectedGraph() ">Save</button>
              <button class="btn " (click)="editGraph() " [disabled]="windowEditGraph!=null">Edit Graph (Visuals)</button>
            </div>

          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </div>

</main>
