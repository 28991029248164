// npm install @msgpack/msgpack
// https://github.com/msgpack/msgpack-javascript

import { Component, Inject, OnInit } from '@angular/core';
import { encode,decode } from "@msgpack/msgpack";
import { timestampExtension } from '@msgpack/msgpack/dist/timestamp';
import { XProjectorClient, BaseQueryInputColumnDescription, BaseQuery, XDataType, FilterLogicalGroup, ProjectionColumnDescription, ColumnFilteringNumerical,
  FilterComparator, ColumnFilteringTimestamp, ColumnFilteringString, FilterLogicalGroupType, GroupsQuery} from '../../XProjector/xprojector-client-service';
import { ControlIdService } from '@clr/angular/forms/common/providers/control-id.service';

import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { XprojDatagridstringfilterComponent } from '../../filters/datagrid-string-filter/xproj-datagrid-string-filter.component';
import { query } from '@angular/animations';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-wstest',
  templateUrl: './xproj-wstest.component.html',
  styleUrls: ['./xproj-wstest.component.scss'],
  host: {
    class:'content-container'
}
})

export class XProjWstestComponent implements OnInit {
  MessagesReceived = [];
  Projections = [];
  ProjectionsMembers = [];
  loadingProjections  = false;
  selectedProjection : any = null;
  selectedGroup : any = null;
  selectedSubGroup : any = null;
  queryableSelectedGroup = Array<string>();

  loadingProjectionColumns = false;
  Columns = [];
  ColumnsMembers = [];

  selectedColumns= [];
  queryData : any = new Object();
  loadingQuery = false;

  ProjectionGroups =[];
  OffsetProjectionGroups = 0;
  NrProjectionGroups = 0;
  SubProjectionGroups =[];
  OffsetSubProjectionGroups = 0;
  NrSubProjectionGroups = 0;

  hasLoaded  = false;

  state: ClrDatagridStateInterface
  ascSort = ClrDatagridSortOrder.ASC;

  // graphData = [
  //   {x: new Date(2019,1,1), y : 10},
  //   {x: new Date(2019,1,2), y : 3},
  //   {x: new Date(2019,11,3), y : 1},
  //   {x: new Date(2019,12,1), y : 10},
  // ];



  constructor(public xprojClient: XProjectorClient,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService)
  {

  }


  async selectedProjectionGroupChange( event : any)
  {
    this.selectedSubGroup = null;
    this.SubProjectionGroups.length = 0;
    this.logger.info(this.selectedGroup);

    if( this.selectedGroup.haschildren && this.SubProjectionGroups.length == 0)
    {
      let groupsQuery = new GroupsQuery();
      groupsQuery.targetprojectionid = this.selectedProjection["projectionid"];
      groupsQuery.maxitems = 10000;
      groupsQuery.seekoffset = this.OffsetSubProjectionGroups;
      groupsQuery.joinedmatch = this.selectedGroup.groupstr + ",.*";
      let tgr = await this.xprojClient.RequestQueryGroups(groupsQuery);

      this.logger.info("groups:");
      this.logger.info(tgr);
      this.SubProjectionGroups.length = 0;
      this.NrSubProjectionGroups = tgr.nroriginalgroups;
      for(let gr of tgr.groups)
      {
        this.SubProjectionGroups.push(
          {
            group: gr.group,
            groupstr: gr.group.join(", "),
            haschildren: gr.haschildren,
            hasdata: gr.hasdata
          });
      }
      //this.SubProjectionGroups = selectedProjectionGroupChange
    }
    if(!this.selectedGroup.hasdata)
      return;

    let reallySelectedGroup = this.selectedGroup;

    //await this.basequery( this.selectedProjection );
    this.selectedColumns.length = null;
    this.queryData = new Object();
    this.QueryIndexer = null;
    this.QueryNrTotalRows = 0;
    this.QueryNrRows = 0;
    this.Query = new BaseQuery();
    this.queryableSelectedGroup.length = 0;
    for(let gr of reallySelectedGroup.group)
    {
      this.queryableSelectedGroup.push(gr);
    }
    await this.queryColumns( this.selectedProjection["projectionid"], reallySelectedGroup.group );
  }

  async selectedProjectionSubGroupChange(event : any)
  {
    let reallySelectedGroup = this.selectedGroup;
    if(this.selectedSubGroup)
      reallySelectedGroup = this.selectedSubGroup;

    //await this.basequery( this.selectedProjection );
    this.selectedColumns.length = null;
    this.queryData = new Object();
    this.QueryIndexer = null;
    this.QueryNrTotalRows = 0;
    this.QueryNrRows = 0;
    this.Query = new BaseQuery();
    this.queryableSelectedGroup.length = 0;
    for(let gr of reallySelectedGroup.group)
    {
      this.queryableSelectedGroup.push(gr);
    }
    await this.queryColumns( this.selectedProjection["projectionid"], reallySelectedGroup.group );
  }

  async selectedProjectionChange( event : any)
  {
    this.selectedGroup = null;
    this.selectedSubGroup = null;
    this.ProjectionGroups.length = 0;
    this.SubProjectionGroups.length = 0;
    //await this.basequery( this.selectedProjection );
    this.selectedColumns.length = null;
    this.selectedGroup = null;
    this.queryableSelectedGroup.length = 0;
    this.queryData = new Object();
    this.QueryIndexer = null;
    this.QueryNrTotalRows = 0;
    this.QueryNrRows = 0;
    this.Query = new BaseQuery();
    let groupsQuery = new GroupsQuery();
    groupsQuery.targetprojectionid = this.selectedProjection["projectionid"];
    groupsQuery.maxitems = 100;
    groupsQuery.seekoffset = 0;
    groupsQuery.groups.push('.*');
    let tgr = await this.xprojClient.RequestQueryGroups(groupsQuery);

    this.logger.info("groups:");
    this.logger.info(tgr);
    this.ProjectionGroups.length = 0;
    this.NrSubProjectionGroups  = 0;
    this.OffsetSubProjectionGroups = 0;
    this.NrProjectionGroups = tgr.nroriginalgroups;
    this.logger.info(tgr);
    for(let gr of tgr.groups)
    {
      this.ProjectionGroups.push(
        {
          group: gr.group,
          groupstr: gr.group.join(", "),
          haschildren: gr.haschildren,
          hasdata: gr.hasdata
        });
    }
    if(this.ProjectionGroups.length == 0)
      await this.queryColumns( this.selectedProjection["projectionid"], [] );
  }

  async selectedColumnsChange( event: any)
  {
    await this.basequery();
  }


  async OnLowlevelMessage(Data : Object)
  {
    this.MessagesReceived.push(Data);
  }

  async OnLowlevelAuthenticated()
  {
    this.SendListQueryableProjections()
  }

  async OnLowlevelDisconnected()
  {
    this.MessagesReceived.length = 0;
    this.Projections.length = 0;
    this.ProjectionsMembers.length = 0;
    this.Columns.length = 0;
    this.ColumnsMembers.length = 0;
    this.selectedColumns.length = 0;

  }



  async queryColumns(projectionId : string, group: Array<string>)
  {
    this.loadingProjectionColumns = true;
    let groupstr = "";
    if(group)
      groupstr = group.join(",");
    let columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
    this.loadingProjectionColumns = false;

    this.Columns.length = 0;
    for(let i = 0; i < columns.length; i++)
    {
      if(this.ColumnsMembers.length == 0)
      {
        Object.keys(columns[i]).forEach(
            key =>  this.ColumnsMembers.push(key) );
      }

      this.Columns.push(columns[i]);
    }

  }

  async SendDummyMessage()
  {
    let bigstringarray = [];
    for(let j = 0; j < 10000; j++)
    {
      bigstringarray.push("A VERY LONG TEXT A VERY LONG TEXT A VERY LONG TEXT A VERY LONG TEXT A VERY LONG TEXT ");
    }
    let msg= {
      request: "misc",
      foo: "bar",
      numberdata: 951709647681934134,
      annoyingstuff: bigstringarray
    };
    this.xprojClient.SendMessage(msg);
  }


  Query = new BaseQuery();
  QueryIndexer : any  = null;
  QueryNrRows : number = 0;
  QueryNrTotalRows : number = 0;


  async ReQuery()
  {
    this.logger.info(this.Query);
    //this.queryData.length = 0;
    let x = await this.xprojClient.RequestQueryBaseQuery(this.Query, true, 'wstest', 'wstest');

    let numericaldata = x["datanumbers"];
    let timestampdata = x["datatimestamps"];
    let stringdata = x["datastrings"];


    this.logger.info(x);
    for(let i = 0; i<  x["columns"].length; i++)
    {
      let it = x["columns"][i];
      let typ = it["datatype"];
      let data = [];
      if(typ == XDataType.Number)
        data= numericaldata[it["indexintypedvector"]];
      if(typ == XDataType.String)
        data= stringdata[it["indexintypedvector"]];
      if(typ == XDataType.Timestamp)
        data= timestampdata[it["indexintypedvector"]];


      let colname = it["columnoutname"];

      if(this.queryData[colname])
      {
        this.queryData[colname].datatype = it["datatype"];
        this.queryData[colname].data = data;
      }
      else
      {
        this.queryData[colname] = {
          name: it["columnoutname"],
          datatype: it["datatype"],
          data: data
        };
      }

      this.QueryIndexer = this.queryData[colname];
    }

    let keysToRemove = [];
    for(let key in this.queryData)
    {
      let found = false;
      for(let i = 0; i<  x["columns"].length; i++)
      {
        let it = x["columns"][i];
        let colname = it["columnoutname"];
        if(colname == key)
        {
          found = true;
          break;
        }
      }
      if(!found)
        keysToRemove.push(key);
    }
    for(let keytoremove in keysToRemove)
    {
      delete this.queryData[keytoremove];
    }

    this.QueryNrRows = x.nrpoints;
    this.QueryNrTotalRows = x.nroriginalpoints;
  }


  async basequery()
  {
    this.Query = new BaseQuery();
    this.Query.maxitems = 100;
    this.Query.targetprojectionid = this.selectedProjection["projectionid"];

    if(this.selectedGroup)
    {
      if(this.selectedSubGroup)
      {
        this.Query.targetgroup=this.selectedSubGroup["group"];
      }
      else
      {
        this.Query.targetgroup=this.selectedGroup["group"];
      }
    }
    else
      this.Query.targetgroup = new Array<string>();

    for(let i = 0; i < this.selectedColumns.length; i++)
    {
      let it = this.selectedColumns[i];
      let col = new BaseQueryInputColumnDescription();
      col.columnname = it["columnname"];
      col.columnoutname = it["columnname"];
      this.Query.columns.push(col);
    }

    this.loadingQuery = true;
    await this.ReQuery();
    this.loadingQuery = false;

  }

  async refreshQueryTable(state: ClrDatagridStateInterface = null) {
    if (state) {
      this.state = state;
    }
    this.loadingQuery = true;

    let OldQuery = JSON.stringify(this.Query);
    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting


    this.logger.info("State", this.state);

    this.Query.filter = new FilterLogicalGroup();
    this.Query.numericalfilters.length = 0;
    this.Query.stringfilters.length = 0;
    this.Query.timestampfilters.length = 0;
    this.Query.subfiltergroups.length = 0;

    let filterId = 0;
    if (this.state.filters)
    {
        for (let filter of this.state.filters)
        {

          if(filter instanceof XprojDatagridstringfilterComponent)
          {
            let dynfilter = filter as XprojDatagridstringfilterComponent;
            let col = dynfilter.columnname;
            let strings = dynfilter.SelectedStrings;
            let gr = new FilterLogicalGroup();
            gr.type = FilterLogicalGroupType.OR;
            gr.queryfilterid = filterId++;
            for(let str of strings)
            {
              let newFilter = new ColumnFilteringString();
              newFilter.queryfilterid = filterId++;
              newFilter.columnname = col;
              newFilter.comparator = FilterComparator.Equals;
              newFilter.value = str;
              gr.filters.push(newFilter.queryfilterid);
              this.Query.stringfilters.push(newFilter);
            }
            this.Query.filter.filters.push(gr.queryfilterid);
            this.Query.subfiltergroups.push(gr);
            continue;
          }

          let columname = filter.property;
          let value = filter.value;

          for(let col of this.Query.columns)
          {
            if(col.columnoutname != columname)
              continue;

            for(let scolit of this.Columns)
            {
              let scol = (scolit as any) as ProjectionColumnDescription;
              if(scol.columnname != col.columnname)
                continue;

              switch(scol.datatype)
              {
                case XDataType.Number:
                case XDataType.Float32:
                case XDataType.Float64:
                case XDataType.UInt8:
                case XDataType.Int32:
                case XDataType.Int64:
                {
                  let newFilter = new ColumnFilteringNumerical();
                  newFilter.columnname = scol.columnname;
                  newFilter.comparator = FilterComparator.Equals;
                  newFilter.queryfilterid = ++filterId;
                  newFilter.value = parseFloat(value);
                  this.Query.filter.filters.push(newFilter.queryfilterid);
                  this.Query.numericalfilters.push(newFilter);
                  break;
                }
                case XDataType.Timestamp:
                {
                  let newFilter = new ColumnFilteringTimestamp();
                  newFilter.columnname = scol.columnname;
                  newFilter.comparator = FilterComparator.Equals;
                  newFilter.queryfilterid = ++filterId;
                  newFilter.value = new Date(value);
                  this.Query.filter.filters.push(newFilter.queryfilterid);
                  this.Query.timestampfilters.push(newFilter);
                  break;
                }
                case XDataType.String:
                {
                  let newFilter = new ColumnFilteringString();
                  newFilter.columnname = scol.columnname;
                  newFilter.comparator = FilterComparator.Equals;
                  newFilter.queryfilterid = ++filterId;
                  newFilter.value = value;
                  this.Query.filter.filters.push(newFilter.queryfilterid);
                  this.Query.stringfilters.push(newFilter);
                  break;
                }
              }
            }
          }
        }
    }

    if(this.state.sort)
    {
      this.Query.sorting.columnname = this.state.sort.by as string;
      this.Query.sorting.descending = this.state.sort.reverse;
    }



    this.Query.seekoffset = this.state.page.size * (this.state.page.current - 1);
    this.Query.maxitems = this.state.page.size;

    /*
    this.inventory.filter(filters)
        .sort(<{by: string, reverse: boolean}>state.sort)
        .fetch(state.page.size * (state.page.current - 1), state.page.size)
        .then((result: FetchResult) => {
            this.users = result.users;
            this.total = result.length;
            this.loading = false;
        });*/

    if( JSON.stringify(this.Query)!= OldQuery || !state)
    {
      await this.ReQuery();
    }
    this.loadingQuery = false;

}

  async SendListQueryableProjectionColumns()
  {
    let msg= {
      request: "list-queryable-projection-columns",
      path: "/",
    };
    this.xprojClient.SendMessage(msg);
  }

  async SendListQueryableProjections()
  {
    this.loadingProjections = true;
    let Projs = await this.xprojClient.RequestListQueryableProjections(0,10000);
    this.loadingProjections = false;
    this.Projections.length = 0;
    for(let i = 0; i < Projs.length; i++)
    {
      if(this.ProjectionsMembers.length == 0)
      {
        Object.keys(Projs[i]).forEach(
            key =>  this.ProjectionsMembers.push(key) );
      }
      this.Projections.push(Projs[i]);
    }
  }

  async connect()
  {
    this.xprojClient.Connect();
  }

  async disconnect()
  {
    this.xprojClient.Disconnect();
  }

  async Start()
  {
    this.hasLoaded = true;
    this.SendListQueryableProjections();
  }

  ngOnInit() {
    /*this.xproj.OnAuthenticated  = this.OnLowlevelAuthenticated.bind(this);
    this.xproj.OnMessage = this.OnLowlevelMessage.bind(this);
    this.xproj.OnDisconnected = this.OnLowlevelDisconnected.bind(this);*/

    this.Start();

  }

}
