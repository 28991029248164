<div *ngIf="!startWithFilter" >
  <form clrForm style="width:100%" clrLayout="vertical" autocomplete="off">
    <clr-datalist-container>
      <label></label>
      <input minlength="1" size="50" style="width:100%;" name="searchfilter" required clrDatalistInput
        [(ngModel)]="searchfilter" (ngModelChange)="onSearchValueChange($event)">
      <datalist>
        <option *ngFor="let item of ExistingStrings" [value]="item"></option>
      </datalist>
      <clr-control-helper>Search for a string in the column</clr-control-helper>
    </clr-datalist-container>
  </form>

  <button class="btn btn-outline" type="submit" [disabled]="searchfilter==''" (click)="submit()">
    Add
  </button>

  <ul>
    <li *ngFor="let item of SelectedStrings">
      {{item}} <button class="btn btn-link" type="submit" [disabled]="searchfilter==''" (click)="remove(item)">
        <clr-icon shape="minus-circle "></clr-icon> Remove
      </button>
    </li>
  </ul>
</div>

<div *ngIf="startWithFilter" >
  <form clrForm style="width:100%" clrLayout="vertical" autocomplete="off">
    <clr-input-container>
      <label>Starts with:</label>
      <input clrInput placeholder="" name="startswith" type="text"
        [(ngModel)]="searchfilter" (ngModelChange)="onSearchValueChange($event)" />
    </clr-input-container>

  </form>
</div>
