import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcStringOptionsProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { Guid } from 'xproj-lib';

@Component({
  selector: 'app-string-option-input',
  templateUrl: './string-option-input.component.html',
  styleUrls: ['./string-option-input.component.scss']
})
export class StringOptionInputComponent implements OnInit {

  @Input() stringOptionsProperty: GrpcStringOptionsProperty;

  @Input() value: string;
  @Input() customerId: string;
  @Output() valueChange = new EventEmitter<string>();

  @Input() showHelp: boolean = true;

  optionNodes: GrpcNode[] = [];

  id: string = Guid.newGuid();

  constructor(
    private xConfClient: XProjectorXConfClient
  ) { }


  async ngOnInit() {
  }

  async ngAfterViewInit() {
    if (this.stringOptionsProperty && this.stringOptionsProperty.optionsRootNodeId?.length > 0 && this.optionNodes.length == 0) {
      let optionsRootNodeId : string = this.stringOptionsProperty.optionsRootNodeId;
      if (this.stringOptionsProperty.optionsRootIsCustomerSpecific && this.customerId) {
        optionsRootNodeId = optionsRootNodeId + '_' + this.customerId;
      }
      this.optionNodes = await this.xConfClient.getReferencedNodesWithCache(optionsRootNodeId, optionsRootNodeId, this.stringOptionsProperty.optionsRootNodeLabel, [], '', 1);

      if (this.stringOptionsProperty.optionsNodeAttribute?.length > 0) {
        this.optionNodes.forEach(node => {
          let property = node.propertyValues.find(p => p.key == this.stringOptionsProperty.optionsNodeAttribute);
          if (property) {
            node.name = property.value;
          }

        });
      }

      this.optionNodes = this.optionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }
  }

  async selectedValueChange($event) {
    this.valueChange.next(this.value);
  }

}
