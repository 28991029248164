<h1>Scripting help</h1>

<h2>Lua</h2>
For beginners tutorial click <a href="https://www.tutorialspoint.com/lua/index.htm" target="blank">here</a> (External link).
<br><br> The lua implementation runs on LuaJIT (Lua 5.1) and runs in sandboxed mode. <br> For more information what LuaJIT supports see their <a href="https://luajit.org" target="blank">homepage</a> (External link) or their <a href="https://luajit.org/faq.html"
    target="blank">FAQ</a>.


<h3>Lua in Projections</h3>
Lua may be used in projections in different stages;
<ul>
    <li>Calculate values / row with a datasource query</li>
    <li>Calculate group transforms in grouping/splitting</li>
    <li>Calculate values / row for a grouped/splitted row</li>
</ul>

<h4>Calculate values / row with a datasource query</h4>
Lua scripts are run after each row has been processed from the data source. A column may be re-calculated with Lua.<br> To access a specific column in Lua the column must be exported into Lua by adding a variable name in <i>Script name (Lua)</i> for the
column.
<br>
<br><i>Example - Setting a column value</i>
<pre>
-- This example requires that a column exported as a scriptvariable named 'unit'

if unit=="kWh" then    
    return value*1000.0
end

return value
</pre>

<h4>Calculate group transforms in grouping/splitting</h4>
This is used for naming the group itself and provisioning the column descriptors.
<br><i>Example - Naming a group and setting the unit of a column description</i>
<pre>
-- This example requires that a column exported as a scriptvariable named 'unit'

if column.name=="Value" then
    if unit=="kWh" then
        column.unit ="Wh"
    else
        column.unit = unit
    end
end
</pre>

<h4>Calculate values / row for a grouped/splitted row</h4>
This is similar to calculate values / row with a data source query. <br> The difference is that it is used inside a grouping/splitting.
<i>Example - Calculating a value in a grouped/splitted projection</i>
<pre>
-- This example requires that a column exported as a scriptvariable named 'unit'

if unit=="kWh" then    
    return value*1000.0
end

return value
</pre>

<h4>Data manipulations with input projections</h4>
When adding an input projection the projection execution will get a dependency on the projection. Lets assume there is a projection named <pre>BEPA</pre> used as an input projection then the following code may be used:
<pre>
    -- select id from bepa where magic = 'abrakadabra30829364'
    local q = bepa.query_create()
    q:add_filter_string( "magic", EQUALS, "abrakadabra30829364")
    q:add_col("id");
    local d = bepa.query_run(q:get_query())
    local var = d:get_scalar_numerical()
    return var
</pre>