import { Component, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { ClrLoadingState } from '@clr/angular';
import { XprojAlertService } from 'xproj-lib';
import { saveAs } from 'file-saver';
import { RossakerBmsMasterDataImportResult } from '@core/models/rossaker-bms-master-data-import-result';
import { RossakerStateService } from '@core/services/rossaker-state-service';

@Component({
  selector: 'app-rossaker-bms-customer-import',
  templateUrl: './rossaker-bms-customer-import.component.html',
  styleUrls: ['./rossaker-bms-customer-import.component.scss']
})
export class RossakerBmsCustomerImportComponent implements OnInit {

  files: any[] = [];
  overwrite : boolean = false;
  importingState : ClrLoadingState = ClrLoadingState.DEFAULT;
  importResult : RossakerBmsMasterDataImportResult;
  filesProcessed : number;

  orgNoFilter : string[] = [];
  orgNoFilterString : string = '';

  ClrLoadingState = ClrLoadingState;

  constructor(
    private state : StateService,
    private rossakerState : RossakerStateService,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
  }

  async importFiles() {
    try {
      this.importingState = ClrLoadingState.LOADING;
      this.importResult = null;
      this.filesProcessed = 0;

      let filesToImport : any[] = [];
      for (let i = 0; i < this.files.length; i++) {
        filesToImport.push(this.files[i]);
        if (filesToImport.length >= 1) {
          let result = await this.rossakerState.importMasterDataFiles(this.overwrite, filesToImport);
          this.filesProcessed += filesToImport.length;
          this.mergeResults(result);
          filesToImport = [];
        }
      }

      if (filesToImport.length > 0) {
        let result = await this.rossakerState.importMasterDataFiles(this.overwrite, filesToImport);
        this.mergeResults(result);
        this.filesProcessed += filesToImport.length;
      }

      if (this.importResult.ok) {
        this.alertService.info('Master data file imported ok.');
        this.importingState = ClrLoadingState.SUCCESS;
      }
      else {
        this.alertService.error('Error import file:', this.importResult.message);
        this.importingState = ClrLoadingState.ERROR;
      }
    }
    catch (err) {
      this.alertService.error('Error import file:', err);
      this.importingState = ClrLoadingState.ERROR;
      this.importResult = new RossakerBmsMasterDataImportResult();
      this.importResult.ok = false;
      this.importResult.message = err.message;
    }
  }

  private mergeResults(toMerge : RossakerBmsMasterDataImportResult) {
    if (!this.importResult) {
      this.importResult = toMerge;
    }
    else {
      this.importResult.ok &&= toMerge.ok;
      this.importResult.realestateCount += toMerge.realestateCount;
      this.importResult.realestatesAdded += toMerge.realestatesAdded;
      this.importResult.realestatesDeleted += toMerge.realestatesDeleted;

      this.importResult.buildingAddressCount += toMerge.buildingAddressCount;
      this.importResult.buildingAddressesAdded += toMerge.buildingAddressesAdded;
      this.importResult.buildingAddressesDeleted += toMerge.buildingAddressesDeleted;

      this.importResult.apartmentCount += toMerge.apartmentCount;
      this.importResult.apartmentsAdded += toMerge.apartmentsAdded;
      this.importResult.apartmentsDeleted += toMerge.apartmentsDeleted;

      this.importResult.facilityCount += toMerge.facilityCount;
      this.importResult.facilityAdded += toMerge.facilityAdded;
      this.importResult.facilityDeleted += toMerge.facilityDeleted;

      this.importResult.meterCount += toMerge.meterCount;
      this.importResult.metersAdded += toMerge.metersAdded;
      this.importResult.metersDeleted += toMerge.metersDeleted;

      this.importResult.gatewayCount += toMerge.gatewayCount;
      this.importResult.gatewaysAdded += toMerge.gatewaysAdded;
      this.importResult.gatewaysDeleted += toMerge.gatewaysDeleted;

      toMerge.customerErrors.forEach(err => this.importResult.customerErrors.push(err));
    }
  }

  uploadFile(files) {
    if (files.length > 0) {
      this.files = files;
    }
    else {
      this.files = [];
    }
  }

  async downloadImportResult() {
    if (this.importResult) {
      let json: string = JSON.stringify(this.importResult);
      var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
      saveAs(blob, 'MasterDataImportResult.json');
    }
  }

}
