import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { XConfDashboardWorkspaceComponent } from './components/xconf-dashboard-workspace/xconf-dashboard-workspace.component';
import { XConfDashboardComponent } from './components/xconf-dashboard/xconf-dashboard.component';


const routes: Routes = [
  {
      path: '', component: XConfDashboardWorkspaceComponent,
      children: [
        { path: ':id', component: XConfDashboardComponent },
        { path: ':id/:edit', component: XConfDashboardComponent }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class XConfDashboardRoutingModule { }
