import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { sleep } from '@cds/core/internal';
import { TypedJSON } from 'typedjson';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { XprojModalService } from '../../../modals/xproj-modal-service.service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { ChartWidgetConfig } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { LabelWidgetConfig } from '../../label/label-widget-config/label-widget-config-service';
import { MapWidgetConfig } from '../../map/map-widget-config/xproj-map-widget-config-service';
import { PiechartWidgetConfig } from '../../piechart/piechart-widget-config/piechart-widget-config-service';
import { ProjectionDataEditorWidgetConfig } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { SpcConfig } from '../../spc/spc-widget-config/spc-config-service';
import { SpectrumAnalyzerConfig } from '../../spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { SvgWidgetConfig } from '../../svg/svg-widget-config/xproj-svg-widget-config-service';
import { TableWidgetConfig } from '../../table/table-widget-config/table-widget-config-service';
import { TextWidgetConfig } from '../../text/text-widget-config/text-widget-config-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetOutputParameter } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { ContainerColumnConfig, ContainerLayout, ContainerTabsLayout, ContainerWidget, ContainerWidgetConfig } from './container-widget-config-service';

@Component({
  selector: 'xproj-container-widget-config',
  templateUrl: './xproj-container-widget-config.component.html',
  styleUrls: ['./xproj-container-widget-config.component.scss']
})
export class XprojContainerWidgetConfigComponent extends WidgetConfigBase implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  widgetConfig: ContainerWidgetConfig;

  editConfig: ContainerWidgetConfig;

  projection: Projection;
  projections: Projection[] = [];
  columns: ProjectionColumnDescription[] = [];
  queryableSelectedGroup: string[] = [];
  selectedColumnConfig: any = null;

  widgetTypeId: string = '';
  selectedWidget: ContainerWidget;

  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  XDataType = XDataType;
  ContainerLayout = ContainerLayout;
  ContainerTabsLayout = ContainerTabsLayout;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private modalService: XprojModalService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as ContainerWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();

    this.editConfig.ContainerWidgets.forEach(cw => {
      switch (cw.TypeId) {
        case "label":
          cw.WidgetConfig = TypedJSON.parse(cw.Json, LabelWidgetConfig);
          break;

        case "text":
          cw.WidgetConfig = TypedJSON.parse(cw.Json, TextWidgetConfig);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.editConfig.ConfigQuery.ProjectionId);

    this.selectedProjectionGroupChange(this.editConfig.ConfigQuery.Query.targetgroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection.projectionid, null);
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.editConfig.ConfigQuery.Query.targetgroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection.projectionid, group);
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.editConfig.ConfigQuery.ColumnConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }
  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.editConfig.ConfigQuery.ColumnConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.editConfig.ConfigQuery.ColumnConfigs];
    this.editConfig.ConfigQuery.ColumnConfigs = [];
    this.cdr.detectChanges();
    this.editConfig.ConfigQuery.ColumnConfigs = copy;
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.editConfig.ConfigQuery.ColumnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;

    this.updateOutputParameters();
  }

  onColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.editConfig.ConfigQuery.ColumnConfigs.length) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.editConfig.ConfigQuery.ColumnConfigs[index].Datatype = column.datatype;
      this.editConfig.ConfigQuery.ColumnConfigs[index].Writeable = column.writable;
    }

    this.updateOutputParameters();
  }

  addAllColumns() {
    //this.queryData.columnConfigs.push(new ColumnConfig());

    for (let col of this.columns) {
      let t = new ContainerColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      t.Writeable = col.writable;

      this.editConfig.ConfigQuery.ColumnConfigs.push(t);
    }

    this.updateOutputParameters();

  }
  removeAllColumns() {
    this.editConfig.ConfigQuery.ColumnConfigs = [];

    this.updateOutputParameters();
  }

  addColumn() {
    this.editConfig.ConfigQuery.ColumnConfigs.push(new ContainerColumnConfig());
  }


  async addWidget() {
    let newConfig: WidgetConfig;
    let containerWidget: ContainerWidget = new ContainerWidget();
    containerWidget.Id = 'Widget_' + this.editConfig.ContainerWidgets.length + 1;
    containerWidget.TypeId = this.widgetTypeId;

    switch (this.widgetTypeId) {

      case 'label':
        newConfig = new LabelWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, LabelWidgetConfig);
        break;
      case 'text':
        newConfig = new TextWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, TextWidgetConfig);
        break;
      case 'chart':
        newConfig = new ChartWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, ChartWidgetConfig);
        break;
      case 'table':
        newConfig = new TableWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, TableWidgetConfig);
        break;
      case 'map':
        newConfig = new MapWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, MapWidgetConfig);
        break;
      case 'piechart':
        newConfig = new PiechartWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, PiechartWidgetConfig);
        break;
      case 'spectrum-analyzer':
        newConfig = new SpectrumAnalyzerConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, SpectrumAnalyzerConfig);
        break;
      case 'spc':
        newConfig = new SpcConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, SpcConfig);
        break;
      case 'svg':
        newConfig = new SvgWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, SvgWidgetConfig);
        break;
      case 'projection-dataeditor':
        newConfig = new ProjectionDataEditorWidgetConfig();
        containerWidget.Json = TypedJSON.stringify(newConfig, ProjectionDataEditorWidgetConfig);
        break;

    }

    if (newConfig) {
      containerWidget.WidgetConfig = newConfig;
      this.editConfig.ContainerWidgets.push(containerWidget);
    }
  }

  removeWidget(index: number) {
    this.modalService.ShowConfirmModal({ header: 'Remove widget', description: 'Remove widget, are you sure?' }, (result) => {
      if (result) {
        ArrayUtils.RemoveItemAt(this.editConfig.ContainerWidgets, index);
      }
    });
  }

  saveWidget(widgetConfig: ContainerWidget) {
    if (widgetConfig) {
      this.widgetService.saveConfig(widgetConfig.WidgetConfig?.Id, () => { });
    }
  }

  private updateOutputParameters() {
    this.editConfig.OutputParameters = [];
    this.editConfig.ConfigQuery.ColumnConfigs.forEach((col) => {
      let output = new WidgetOutputParameter();
      output.id = col.Id;
      output.datatype = col.Transform != Aggregation.NONE ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[col.Datatype]);
      output.name = col.Label?.length > 0 ? col.Label : col.ColumnName;

      this.editConfig.OutputParameters.push(output);
    });
  }

  async onSaveConfig() {
    this.editConfig.ConfigQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.editConfig.ConfigQuery.Query = this.projectionFilter.GetQuery();
    this.editConfig.ConfigQuery.DataFilters = this.projectionFilter.datafilters;
    this.editConfig.ConfigQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.editConfig.ConfigQuery.Query.maxitems = 20;

    this.editConfig.ConfigQuery.ProjectionId = this.projection?.projectionid;
    this.editConfig.ConfigQuery.Group = this.editConfig.ConfigQuery.Query.targetgroup;
    this.editConfig.ConfigQuery.Query.targetprojectionid = this.editConfig.ConfigQuery.ProjectionId;

    if (this.editConfig.ConfigQuery.ColumnConfigs.length > 0) {
      let colindex = 0;
      for (let column of this.editConfig.ConfigQuery.ColumnConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = column.ColumnName;
        }

        if (colindex == 0) {
          this.editConfig.ConfigQuery.Query.sorting.columnname = col.columnname;
          this.editConfig.ConfigQuery.Query.sorting.descending = false;
          this.editConfig.ConfigQuery.Query.grouping = new ColumnGroupingDescription();
          this.editConfig.ConfigQuery.Query.columns.push(col);
        }
        else {
          this.editConfig.ConfigQuery.Query.columns.push(col);
        }

        colindex++;
      }

      if (this.groupSelect) {
        this.editConfig.ConfigQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }

    this.editConfig.ContainerWidgets.forEach(cw => {
      cw.Json = JSON.stringify(cw.WidgetConfig);
    });

    this.updateOutputParameters();


    if (this.selectedWidget) {
      this.widgetService.saveConfig(this.selectedWidget.WidgetConfig?.Id, () => {
      });
    }

    this.widgetConfig.update(this.editConfig);
  }
}
