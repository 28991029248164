import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { TypedJSON } from 'typedjson';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { BaseQuery, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { ChartWidgetConfig } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { LabelWidgetConfig } from '../../label/label-widget-config/label-widget-config-service';
import { MapWidgetConfig } from '../../map/map-widget-config/xproj-map-widget-config-service';
import { PiechartWidgetConfig } from '../../piechart/piechart-widget-config/piechart-widget-config-service';
import { ProjectionDataEditorWidgetConfig } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { SpcConfig } from '../../spc/spc-widget-config/spc-config-service';
import { SpectrumAnalyzerConfig } from '../../spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { TableWidgetConfig } from '../../table/table-widget-config/table-widget-config-service';
import { TextWidgetConfig } from '../../text/text-widget-config/text-widget-config-service';
import { WidgetBase } from '../../widget-base';
import { GroupSelectionTypes } from '../../widget-config-service';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { ContainerLayout, ContainerTabsLayout, ContainerWidget, ContainerWidgetConfig } from '../container-widget-config/container-widget-config-service';

export class QueryDataColumn {
  id: string;
  columnname: string;
  label: string;
  datatype: XDataType;
  projectionid: string;
  data: any[] = [];
}

@Component({
  selector: 'xproj-container-widget',
  templateUrl: './xproj-container-widget.component.html',
  styleUrls: ['./xproj-container-widget.component.scss']
})
export class XprojContainerWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  widgetConfig: ContainerWidgetConfig;

  loadingQuery: boolean = false;
  loading: boolean = true;
  private forcereload: boolean = false;
  lastQueryJson: string = '';
  colSize = 4;

  columns: QueryDataColumn[] = [];

  containerWidgets: ContainerWidget[] = [];
  carouselWidgets: ContainerWidget[] = [];

  startIndex: number = 0;
  lastIndex: number = 0;

  ContainerLayout = ContainerLayout;
  ContainerTabsLayout = ContainerTabsLayout;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {
      super(logger, xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as ContainerWidgetConfig;

    this.widgetConfig.ContainerWidgets.forEach(cw => {
      this.initWidget(cw);
    });

    this.initColumnSize();

    await super.ngOnInit();
  }

  async onInit() {

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      setTimeout(() => {
        this.load();
      }, 500);
    }
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    this.load();
  }

  async onReset() {

  }

  async onRefresh() {
    this.forcereload = true;
    this.load();
  }

  async onUpdateQuery() {
    this.forcereload = true;
    this.initColumnSize();
    this.load();
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {

  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    this.load();
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  initWidget(widget: ContainerWidget) {
    widget.Name = widget.Id;
    switch (widget.TypeId) {
      case "label":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, LabelWidgetConfig);
        break;

      case "text":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, TextWidgetConfig);
        break;

      case "chart":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, ChartWidgetConfig);
        break;

      case "table":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, TableWidgetConfig);
        break;

      case "map":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, MapWidgetConfig);
        break;

      case "piechart":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, PiechartWidgetConfig);
        break;

      case "spectrum-analyzer":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, SpectrumAnalyzerConfig);
        break;

      case "spc":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, SpcConfig);
        break;

      case "projection-dataeditor":
        widget.WidgetConfig = TypedJSON.parse(widget.Json, ProjectionDataEditorWidgetConfig);
        break;
    }
  }

  leftClick() {
    if (this.startIndex === 0) {
      this.startIndex = this.containerWidgets.length - 1
      this.lastIndex--;
      this.carouselWidgets.unshift(this.containerWidgets[this.carouselWidgets.length - 1]);
      this.carouselWidgets.pop();
    }
    else if (this.lastIndex === 0) {
      this.lastIndex = this.containerWidgets.length - 1;
      this.startIndex--;
      this.carouselWidgets.unshift(this.containerWidgets[this.startIndex]);
      this.carouselWidgets.pop();
    }
    else {
      this.startIndex--;
      this.lastIndex--;
      this.carouselWidgets.unshift(this.containerWidgets[this.startIndex])
      this.carouselWidgets.pop()
    }
    //console.log('start ', this.startIndex, 'last ', this.lastIndex)
  }

  rightClick() {
    if (this.lastIndex === this.containerWidgets.length - 1) {
      this.lastIndex = 0;
      this.startIndex++;
      this.carouselWidgets.shift();
      this.carouselWidgets.push(this.containerWidgets[0]);
    }
    else if (this.startIndex === this.containerWidgets.length - 1) {
      this.startIndex = 0;
      this.lastIndex++;
      this.carouselWidgets.shift();
      this.carouselWidgets.push(this.containerWidgets[this.lastIndex]);
    }
    else {
      this.startIndex++;
      this.lastIndex++;
      this.carouselWidgets.shift();
      this.carouselWidgets.push(this.containerWidgets[this.lastIndex]);
    }
    //console.log('start ', this.startIndex, 'last ', this.lastIndex)
  }

  private initColumnSize() {
    switch (this.widgetConfig.LayoutColumns) {
      case 1:
        this.colSize = 12;
        break;
      case 2:
        this.colSize = 6;
        break;
      case 3:
        this.colSize = 4;
        break;
      case 4:
        this.colSize = 3;
        break;
      case 5:
      case 6:
        this.colSize = 2;
        break;
      default:
        this.colSize = 1;
        break;
    }
  }

  checkIfNeedRefresh(query: BaseQuery): boolean {
    let result = true;
    let queryJson = JSON.stringify(query)
    result = this.lastQueryJson != queryJson;
    this.lastQueryJson = queryJson;

    return result;
  }

  async reQuery(query: BaseQuery, queryData: QueryDataColumn[]) {
    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true, 'containerwidget', this.config.Name);
      //let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, this.forcereload);

      this.forcereload = false;

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      //console.log(queryResult);
      for (let i = 0; i < queryResult.columns.length; i++) {
        let it = queryResult.columns[i];
        let typ = it.datatype;
        let data = [];
        let colname = it.columnoutname;
        let columnConfig = this.widgetConfig.ConfigQuery.ColumnConfigs.find(c => c.ColumnOutName == colname);

        if (typ == XDataType.Number) {
          if (columnConfig?.Datatype == XDataType.Float32 || columnConfig?.Datatype == XDataType.Float64) {
            data = WidgetUtils.FormatNumbers(numericaldata[it["indexintypedvector"]], this.globalWidgetSettings.Decimals);
          }
          else {
            data = numericaldata[it["indexintypedvector"]];
          }
        }
        if (typ == XDataType.String) {
          data = stringdata[it.indexintypedvector];
        }
        if (typ == XDataType.Timestamp) {
          data = timestampdata[it.indexintypedvector];
        }


        let colData = queryData.find(d => d.columnname == colname);

        if (colData) {
          colData.datatype = it.datatype;
          colData.data = data;
        }
        else {
          colData = new QueryDataColumn();
          colData.columnname = colname;
          colData.label = colname;
          colData.data = data;
          colData.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;
          colData.datatype = it.datatype;
          colData.id = columnConfig?.Id;

          queryData.push(colData);
        }
      }


    }
    catch (err) {

    }
  }

  // emitOutputParameters() {
  //   if (this.widgetConfig.ContainerWidgets.length > 0 && this.columns.length > 0 && this.columns[0].data.length > 0) {
  //     let i = 0;
  //     let outputs : WidgetOutputChangeParameters[] = [];
  //     this.columns[0].data.forEach(d => {
  //       this.columns.forEach(col => {
  //         let output = this.widgetConfig.OutputParameters.find(out => out.id == col.id);
  //         if (output) {
  //           let outputChanged = new WidgetOutputChangeParameters();
  //           outputChanged.widgetId = this.widgetConfig.Id + i;
  //           outputChanged.outputParameterId = output.id;
  //           outputChanged.value = col.data[i];
  //           outputChanged.datatype = output.datatype;

  //           outputs.push(outputChanged);
  //         }
  //       });

  //       this.widgetService.outputParametersChanged(outputs);
  //       i++;
  //     });
  //   };
  // }

  async load() {

    if (!this.loadingQuery) {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }
      if (!this.inputParametersHasValue(true)) {
        //TODO
      }
      else {
        //let queryData: QueryDataColumn[] = [];

        let newData: boolean = false;
        try {
          this.loadingQuery = true;
          this.loading = true;

          let sortingDefined: boolean = false;

          let query = this.widgetConfig.ConfigQuery.Query.Clone();

          //Projection input parameters
          if (this.widgetConfig.ConfigQuery.UseProjectionInputParameter) {
            query.targetprojectionid = this.getParameterValue(this.widgetConfig.ConfigQuery.ProjectionInputParameterId, this.widgetConfig.ConfigQuery.Query.targetprojectionid).value;
          }

          //Group input parameters

          if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
            query.targetgroup = this.getParameterValue(this.widgetConfig.ConfigQuery.GroupInputParameterId, this.widgetConfig.ConfigQuery.Query.targetgroup).value;
          }
          else if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
            query.targetgroup = [];
            this.widgetConfig.ConfigQuery.GroupInputParameterIds.forEach(id => {
              query.targetgroup.push(this.getParameterValue(id, '').value + '');
            });
          }

          WidgetUtils.SetQueryFilterValues(query, this.widgetConfig.ConfigQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
          // TODO
          // WidgetUtils.AddQueryTimeframe(query,
          //   this.fromZoom ?? this.from,
          //   this.toZoom ?? this.to,
          //   this.widgetConfig.ConfigQuery.TimestampColumnName,
          //   this.useRelativeTimestamp ? this.relativeTimestamp : null);

          query.seekoffset = 0;
          query.maxitems = this.widgetConfig.ConfigQuery.MaxItems;

          if (!sortingDefined) {
            let scol = query.columns.find(c => c.columnname == this.widgetConfig.ConfigQuery.defaultSortColumnName);
            if (scol) {
              if (this.widgetConfig.ConfigQuery.UseGrouping) {
                query.sorting.columnname = scol.columnoutname;
              }
              else {
                query.sorting.columnname = scol.columnname;
              }
              query.sorting.descending = this.widgetConfig.ConfigQuery.defaultSortDescending;
            }
          }

          if (this.forcereload || this.checkIfNeedRefresh(query)) {
            newData = true;
            await this.reQuery(query, this.columns);
          }

        }
        finally {
          this.containerWidgets = [];
          if (this.widgetConfig.ContainerWidgets.length > 0 && this.columns.length > 0 && this.columns[0].data.length > 0) {
            let i = 0;
            this.columns[0].data.forEach(d => {
              let widget = TypedJSON.parse(TypedJSON.stringify(this.widgetConfig.ContainerWidgets[0], ContainerWidget), ContainerWidget);
              this.initWidget(widget);
              widget.WidgetConfig.InputParameters.forEach(input => {
                input.widgetId += i;
                let col = this.columns.find(col => col.id == input.widgetOutputParameterId);
                if (col) {
                  input.initValue = col.data[i];
                }
              })

              if (this.widgetConfig.TabsNameColumn?.length > 0) {
                let col = this.columns.find(col => col.columnname == this.widgetConfig.TabsNameColumn);
                if (col) {
                  widget.Name = col.data[i];
                }
              }

              this.containerWidgets.push(widget);
              i++;
            });

            if (this.widgetConfig.Layout == ContainerLayout.Carousel) {
              if (this.containerWidgets.length > this.widgetConfig.LayoutColumns) {
                this.startIndex = 0;
                this.lastIndex = this.widgetConfig.LayoutColumns;
              }
              else {
                this.startIndex = 0;
                this.containerWidgets.length;
              }

              this.carouselWidgets = this.containerWidgets.slice(this.startIndex, this.lastIndex);
            }
          }

          this.loadingQuery = false;
        }
      }
    }

  }
}
