import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { StateService } from '@xprojectorcore/services/state-service';
import { XConfDashboardsService } from '@xprojectorfeatures/xconf-dashboards/services/xconf-dashboards.service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

@Component({
  selector: 'app-rossaker-bms-admin-dashboards',
  templateUrl: './rossaker-bms-admin-dashboards.component.html',
  styleUrls: ['./rossaker-bms-admin-dashboards.component.scss']
})
export class RossakerBmsAdminDashboardsComponent implements OnInit {
  currentUserRights: UserRights;
  currentDashboardId: string;

  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;

  private dataSourceInstanceId : string = '_x_bms_bmsadmindashboards';
  dataSourceInstance: GrpcDataSourceInstance;

  dashboards : {id : string, name : string, active : boolean}[] = [];

  constructor(
    private state: StateService,
    public rossakerState : RossakerStateService,
    public dashboardsService: XConfDashboardsService,
    private xConfClient: XProjectorXConfClient,
    private router: Router
  ) { }

  async ngOnInit() {
    this.currentUserRights = this.state.userRightsValue;

    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.dataSourceInstance = await this.xConfClient.getDataSourceInstance(this.dataSourceInstanceId + '_' + this.selectedCustomer.id);
      await this.getDashboards();
      //this.dataSourceInstance.rootNodeInstances[0].
    });

  }

  async getDashboards() {
    try {
      if (this.dataSourceInstance && this.dataSourceInstance.rootNodeInstances?.length > 0) {
        let nodes = await this.xConfClient.getReferencedNodes(this.dataSourceInstance.rootNodeInstances[0].id, this.dataSourceInstance.rootNodeInstances[0].label, []);

        let first : boolean = true;
        this.dashboards = [];
        nodes.forEach(node => {
          let dashboardIdProperty = node.propertyValues.find(v => v.key == 'dashboardid');
          if (dashboardIdProperty) {
            let dashboard : {id : string, name : string, active : boolean} = {id : dashboardIdProperty.value, name : node.name, active : first};
            this.dashboards.push(dashboard);
            first = false;
          }
        });
      }
    }
    catch {

    }
  };

  async onTreeNodeSelect(item: {treeNode : XconfTreeNode, nodeType : GrpcNodeType}) {
    let dashboardId = item.treeNode.node.propertyValues.find(v => v.key == 'dashboardid');
    if (dashboardId) {
      this.currentDashboardId = dashboardId.value;
      this.router.navigateByUrl('rossakerbmsadmin/insightdashboards/' + dashboardId.value );
    }
    else {
      //this.router.navigateByUrl('xconfdashboard');
    }
  }

}
