import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainNavComponent } from '@core/components/main-nav/main-nav.component';
import { SettingsComponent } from '@shared/components/settings/settings.component';
import { LoginComponent } from '@shared/components/login/login.component';
import { GdprConsentComponent } from '@shared/components/gdpr-consent/gdpr-consent.component';
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { SysAdminAuthGuard } from '@core/guards/sysadmin.auth.guard';
import { CustomerAdminAuthGuard } from '@core/guards/customeradmin.auth.guard';
import { SysAdminModule } from '@xprojectorfeatures/sysadmin/sys-admin.module';
import { UserProfileComponent } from '@shared/components/user-profile/user-profile.component';
import { UserProfilesComponent } from '@shared/components/user-profiles/user-profiles.component';
import { HomeComponent } from '@core/components/home/home.component';
import { CustomerSettingsComponent } from './shared/components/customer-settings/customer-settings.component';
import { SysAdminUserProfilesComponent } from '@xprojectorfeatures/sysadmin/components/sys-admin-user-profiles/sys-admin-user-profiles.component';
import { SysAdminUserProfileComponent } from '@xprojectorfeatures/sysadmin/components/sys-admin-user-profile/sys-admin-user-profile.component';
import { CreateCredentialsComponent } from './shared/components/create-credentials/create-credentials.component';
import { StartMobileAppComponent } from '@shared/components/start-mobile-app/start-mobile-app.component';

import { AdminDashboardsModule } from '@xprojectorfeatures/admin-dashboards/admin-dashboards.module';
import { XConfDashboardModule } from '@xprojectorfeatures/xconf-dashboards/xconf-dashboard.module';
import { AboutComponent } from './core/components/about/about.component';
import { SystemsComponent } from './core/components/systems/systems.component';
import { SolutionsComponent } from './core/components/solutions/solutions.component';
import { ProductsComponent } from './core/components/products/products.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { SupportComponent } from './core/components/support/support.component';
import { SystemsBigDataComponent } from './core/components/systems/big-data/big-data.component';
import { SystemsAutomationComponent } from './core/components/systems/automation/automation.component';
import { SystemsCommunicationsComponent } from './core/components/systems/communications/communications.component';
import { SystemsHomeComponent } from './core/components/systems/home/home.component';

const sysAdminModule = () => import('@xprojectorfeatures/sysadmin/sys-admin.module').then(x => x.SysAdminModule);
const dataConfigurationModule = () => import('@xprojectorfeatures/data-configuration/data-configuration.module').then(x => x.DataConfigurationModule);
//const dashboardModule = () => import('@xprojectorfeatures/admin-dashboards/admin-dashboards.module').then(x => x.AdminDashboardsModule);
//const xconfDashboardModule = () => import('@xprojectorfeatures/xconf-dashboards/xconf-dashboard.module').then(x => x.XConfDashboardModule);
const configurationModule = () => import('@xprojectorfeatures/xconf/xconf.module').then(x => x.XconfModule);
const portalModule = () => import('@xprojectorfeatures/portal/portal.module').then(x => x.PortalModule);
const automationnModule = () => import('@xprojectorfeatures/automation/automation.module').then(x => x.AutomationnModule);

const rossakerBmsAdminModule = () => import('@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-admin.module').then(x => x.RossakerBmsAdminModule);
const rossakerBmsTrusteeAdminModule = () => import('@features/rossaker-bms/rossaker-bms-trustee-admin/rossaker-bms-trustee-admin.module').then(x => x.RossakerBmsTrusteeAdminModule);

const plantOperatorModule = () => import('@xprojectorfeatures/plant-operator/plant-operator.module').then(x => x.PlantOperatorModule);
const plantConfiguratorModule = () => import('@xprojectorfeatures/plant-configurator/plant-configurator.module').then(x => x.PlantConfiguratorModule);

const routes: Routes = [
  {path: 'start-mobile-app', component: StartMobileAppComponent },
  {
    path: '',
    component: MainNavComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { 
        path: 'systems', 
        component: SystemsComponent,
        children: [      
          { path: '', pathMatch: 'full', outlet:'systemsoutlet', component: SystemsHomeComponent },
          { path: 'big-data', outlet:'systemsoutlet', component: SystemsBigDataComponent },
          { path: 'automation', outlet:'systemsoutlet', component: SystemsAutomationComponent },
          { path: 'communications', outlet:'systemsoutlet',component: SystemsCommunicationsComponent },
        ]
      },
      { path: 'solutions', component: SolutionsComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'support', component: SupportComponent },
      { path: 'login', component: LoginComponent },
      { path: 'gdprconsent', component: GdprConsentComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
      { path: 'customersettings', component: CustomerSettingsComponent, canActivate: [CustomerAdminAuthGuard] },
      { path: 'userprofile', component: UserProfileComponent, canActivate: [AuthGuard] },
      { path: 'adminuserprofile', component: SysAdminUserProfileComponent, canActivate: [SysAdminAuthGuard] },
      { path: 'userprofiles', component: UserProfilesComponent, canActivate: [CustomerAdminAuthGuard] },
      //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'sysadmin', loadChildren: sysAdminModule, canActivate: [SysAdminAuthGuard] },
      { path: 'xprojector', loadChildren: dataConfigurationModule, canActivate: [AuthGuard] },
      //{ path: 'dashboard', loadChildren: dashboardModule, canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => AdminDashboardsModule, canActivate: [AuthGuard] },
      //{ path: 'xconfdashboard', loadChildren: xconfDashboardModule, canActivate: [AuthGuard] },
      { path: 'xconfdashboard', loadChildren:() => XConfDashboardModule, canActivate: [AuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
      { path: 'configuration', loadChildren: configurationModule, canActivate: [SysAdminAuthGuard] },
      { path: 'customers', loadChildren: portalModule, canActivate: [AuthGuard] },
      { path: 'createcredentials/:action', component: CreateCredentialsComponent },

      { path: 'rossakerbmsadmin', loadChildren: rossakerBmsAdminModule, canActivate: [SysAdminAuthGuard] },
      { path: 'rossakerbmstrusteeadmin', loadChildren: rossakerBmsTrusteeAdminModule, canActivate: [SysAdminAuthGuard] },
      { path: 'xautomation', loadChildren: automationnModule, canActivate: [SysAdminAuthGuard] },
      { path: 'plant-operator', loadChildren:plantOperatorModule, canActivate: [AuthGuard]},
      { path: 'plant-configurator', loadChildren:plantConfiguratorModule, canActivate: [SysAdminAuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
