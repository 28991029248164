import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { ColorProperty, ColorThreshold, LabelConfig, LabelWidgetConfig, LabelWidgetQuery } from '../label-widget-config/label-widget-config-service';

@Component({
  selector: 'xproj-label-widget-query-config',
  templateUrl: './xproj-label-widget-query-config.component.html',
  styleUrls: ['./xproj-label-widget-query-config.component.scss']
})
export class XprojLabelWidgetQueryConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;


  @Input() widgetQuery: LabelWidgetQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgets: WidgetConfig[];
  @Input() widgetConfig: LabelWidgetConfig;

  @Output() onQueryRemoved = new EventEmitter<LabelWidgetQuery>();

  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  preQueryColumnConfigs: { columnname: string, columnConfig: WidgetPrQueryColumnConfig }[] = [];

  Transformation = Transformation;
  Aggregation = Aggregation;
  ColorProperty = ColorProperty;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;

  selectedDataConfig: LabelConfig = null;
  selectedThreshold: ColorThreshold = null;

  constructor(private xprojClient: XProjectorClient,
    private widgetService: XprojWidgetService,
    private cdr: ChangeDetectorRef) {

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.selectedGroup = this.widgetQuery.Query.targetgroup
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    //await this.selectedProjectionChange(this.projection);

    await this.selectedProjectionGroupChange(this.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.selectedGroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection["projectionid"], null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.selectedGroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection["projectionid"], group);
      this.checkTimeFilterColumn();
    }
  }

  addColumn() {
    this.widgetQuery.DataConfigs.push(new LabelConfig());
  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.widgetQuery.DataConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.widgetQuery.DataConfigs, index);
    this.refreshColumnConfigs();
  }

  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.widgetQuery.DataConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.widgetQuery.DataConfigs];
    this.widgetQuery.DataConfigs = [];
    this.cdr.detectChanges();
    this.widgetQuery.DataConfigs = copy;
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let dataConfig of this.widgetQuery.DataConfigs) {
      if (dataConfig == this.selectedDataConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedDataConfig = null;
  }

  addThreshold() {
    this.selectedDataConfig.ColorThresholds.push(new ColorThreshold());
  }

  removeThreshold(index: number) {
    ArrayUtils.RemoveItemAt(this.selectedDataConfig.ColorThresholds, index);
  }

  moveThresholdUp(index: number) {
    ArrayUtils.MoveItemUp(this.selectedDataConfig.ColorThresholds, index);
  }
  moveThresholdDown(index: number) {
    ArrayUtils.MoveItemDown(this.selectedDataConfig.ColorThresholds, index);
  }

  removeSelectedThreshold() {
    let counter = 0;
    for (let threshold of this.selectedDataConfig.ColorThresholds) {
      if (threshold == this.selectedThreshold)
        break;
      counter++;
    }
    this.removeThreshold(counter);
    this.selectedThreshold = null;
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
    //this.loadingProjectionColumns = false;
    this.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });

  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.columns.find(col => col.columnname == this.widgetQuery.timestampColumnName)
      || (!this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.widgetQuery.timestampColumnName = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.widgetQuery.timestampColumnName = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.widgetQuery.timestampColumnName = col.columnname;
        }
      });
    }
  }

  onDataColumnSelect($event, index) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column) {
      if (column.unit?.length > 0) {
        this.widgetQuery.DataConfigs[index].Unit = column.unit;
      }
    }
    else {
      let column = this.preQueryColumnConfigs.find(col => col.columnname == $event);
      if (column) {
        if (column.columnConfig.Unit?.length > 0) {
          this.widgetQuery.DataConfigs[index].Unit = column.columnConfig.Unit;
        }
      }
    }
  }

  addScriptedColumnsPostAggregation() {
    this.widgetQuery.Query.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  updateInputParameters(inputs: WidgetInputParameter[]) {
    this.inputParameters = inputs;
    if (this.projectionFilter) {
      this.projectionFilter.inputParameters = this.inputParameters;
    }
  }

  public async SaveQuery() {
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    let scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation;
    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = 1;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = scriptedcolumnspostaggregation;

    if (this.projection) {
      this.widgetQuery.ProjectionId = this.projection.projectionid;
      this.widgetQuery.Group = this.selectedGroup;
      this.widgetQuery.Query.targetprojectionid = this.widgetQuery.ProjectionId;

      let colindex = 0;
      for (let column of this.widgetQuery.DataConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;

        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = "col_" + colindex.toString();
        }

        col.columnaggregation = column.Transform;

        this.widgetQuery.Query.columns.push(col);
        colindex++;
      }

      if (this.groupSelect) {
        this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }

  }


}
