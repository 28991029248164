<h2>Program Variables</h2>
<div class="limit-height" style="max-width:1200px;">
  <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedPLCVariable" [clrDgRowSelection]="true">
    <clr-dg-column [clrDgField]="'scriptname'" [clrDgSortOrder]="ascSort"> Scriptname </clr-dg-column>
    <clr-dg-column [clrDgField]="'description'" [clrDgSortOrder]="ascSort"> Description </clr-dg-column>
    <clr-dg-column [clrDgField]="'xautovariableid'" [clrDgSortOrder]="ascSort"> XAutoVariableID </clr-dg-column>
    <clr-dg-column [clrDgField]="'xname'"> xname </clr-dg-column>
    <clr-dg-column [clrDgField]="'xautogroup'"> xautogroup </clr-dg-column>
    <clr-dg-column [clrDgField]="'xgroup'"> xgroup </clr-dg-column>
    <!-- <clr-dg-column [clrDgField]="'Variable.type'"> Type </clr-dg-column>
          <clr-dg-column [clrDgField]="'Variable.writable'"> Writable </clr-dg-column> -->

    <clr-dg-row *clrDgItems="let variable of numericalmapxautovariables" [clrDgItem]="variable">
      <clr-dg-cell class="cellhideoverflow"> {{variable.scriptname}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.description}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.xautovariableid}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.xname}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.xautogroup}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.xgroup}} </clr-dg-cell>
      <!-- <clr-dg-cell class="cellhideoverflow"> {{variable.type | xproj_prettydatatype}} </clr-dg-cell>
              <clr-dg-cell class="cellhideoverflow"> {{variable.writable}} </clr-dg-cell> -->
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} XAuto Variables
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
<div *ngIf="selectedPLCVariable">
  <h2>Edit {{selectedPLCVariable.xname}}</h2>
  <xproj-editplcvariable [(variable)]="selectedPLCVariable"></xproj-editplcvariable>
  <button class="btn" [clrLoading]="IsUpdatingVariable" (click)="updateVariable(selectedPLCVariable)">Save</button>
  <button class="btn" (click)="cancelUpdateVariable()">Cancel</button>
  <button class="btn btn-danger-outline" (click)="removeVariable(selectedPLCVariable)">Remove</button>
</div>


<h2>Add XAuto Variable</h2>
<div class="limit-height" style="max-width:1200px;">
  <clr-datagrid class="datagrid-compact" (clrDgSingleSelectedChange)="selectedXAutoVariableChanged($event)"
    [(clrDgSingleSelected)]="dummyselectedvar" [clrDgRowSelection]="true" [clrDgLoading]="loadingVariables">
    <clr-dg-column [clrDgField]="'xautogroup'" [clrDgSortOrder]="ascSort"> XAutoGroup </clr-dg-column>
    <clr-dg-column [clrDgField]="'xgroup'" [clrDgSortOrder]="ascSort"> XGroup </clr-dg-column>
    <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
    <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
    <clr-dg-column [clrDgField]="'defaultscriptname'"> Default Scriptname </clr-dg-column>
    <!-- <clr-dg-column [clrDgField]="'Variable.type'"> Type </clr-dg-column> -->
    <!-- <clr-dg-column [clrDgField]="'Variable.writable'"> Writable </clr-dg-column> -->

    <clr-dg-row *clrDgItems="let variable of variables" [clrDgItem]="variable">
      <clr-dg-cell class="cellhideoverflow"> {{variable.xautogroup}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.xgroup}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.name}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.description}} </clr-dg-cell>
      <clr-dg-cell class="cellhideoverflow"> {{variable.defaultscriptname}} </clr-dg-cell>
      <!-- <clr-dg-cell class="cellhideoverflow"> {{variable.type | xproj_prettydatatype}} </clr-dg-cell> -->
      <!-- <clr-dg-cell class="cellhideoverflow"> {{variable.writable}} </clr-dg-cell> -->
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} XAuto Variables
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<div *ngIf="newVariable">
  <h2>Add {{newVariable.xname}}</h2>
  <xproj-editplcvariable [(variable)]="newVariable"></xproj-editplcvariable>
  <button class="btn" [clrLoading]="IsAddingVariable" (click)="addVariable()">Add</button>
  <button class="btn" (click)="cancelAddVariable()">Cancel</button>
</div>
