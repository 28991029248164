import { Component, inject, Input, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { NGXLogger } from 'ngx-logger';
import { XEdgeXAutoVar } from '../../../../core/xprojector_backend/proto/xprojector.module.xedge.pb';
import { XProjectorXEdgeClient } from '../../../../core/xprojector_backend/xprojector-xedge-client';

@Component({
  selector: 'app-xedge-select-xauto-vars',
  templateUrl: './xedge-select-xauto-vars.component.html',
  styleUrls: ['./xedge-select-xauto-vars.component.scss']
})
export class XEdgeSelectXAutoVarsComponent implements OnInit {

  private xedgeClient : XProjectorXEdgeClient = inject(XProjectorXEdgeClient);
  private logger: NGXLogger = inject(NGXLogger);

  @Input() customerId : string = '';
  @Input() remoteNodeId : string = '';
  @Input() showInstalled : boolean = false;

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;

  loadingXAutoVars : boolean = false;
  xedgeXAutoVars : XEdgeXAutoVar[];
  selectedXAutoVars : XEdgeXAutoVar[] = [];

  constructor() { }

  ngOnInit(): void {
    this.loadingXAutoVars = true;
    this.xedgeClient.scanXEdge(this.customerId, this.remoteNodeId).then(xAutoVars => {
      if (this.showInstalled) {
        this.xedgeXAutoVars = xAutoVars;
      }
      else {
        this.xedgeXAutoVars = xAutoVars.filter(x => !x.installed);
      }
    }).finally(() => this.loadingXAutoVars = false);
  }

  selectedXAutoVarsChanged(xedgeXAutoVar: XEdgeXAutoVar) {

  }

  selectAllXAutoVars() {
    this.selectedXAutoVars = this.xedgeXAutoVars;
  }

  unselectAllXAutoVars() {
    this.selectedXAutoVars = [];
  }
}
