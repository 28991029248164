import { BaseQuery, FilterLogicalGroupType, Transformation, Aggregation, SPCChartType, SPCQueryDistributionConfig } from '../../../XProjector/xprojector-client-service';
import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Guid } from '../../../utils/guid-service';

export enum YAxisId
{
	LEFT         = 0,
	RIGHT        = 1
}

@jsonObject
export class SpcWidgetQueryDistributionConfig
{
  @jsonMember
    public Mean : number = 0.0;

    @jsonMember
    public StdDev : number = 0.0;
};


@jsonObject
export class SpcYAxesConfig {
  @jsonMember
	public ColumnName : string = '';

	@jsonMember
	public ColumnOutName : string = '';

	@jsonMember
	public Label : string = '';

	@jsonMember
	public AxisId : YAxisId = YAxisId.LEFT;

	@jsonMember
	public Type : string = 'line';

	@jsonMember
  public Unit : string = '';

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
	public LabelReal : string = '';

  @jsonMember
	public TypeReal : string = 'line';

	@jsonMember
	public AxisIdReal : YAxisId = YAxisId.LEFT;

  @jsonMember
	public LabelPower : string = '';

  @jsonMember
	public TypePower : string = 'line';

	@jsonMember
	public AxisIdPower : YAxisId = YAxisId.LEFT;

}

@jsonObject
export class SpcQuery implements BaseQueryConfigInterface {
  public Id : string = Guid.newGuid();

	@jsonMember
	public Query : BaseQuery = new BaseQuery();

	@jsonMember
	public Xaxis : string = '';

	@jsonMember
	UseGrouping : boolean = true;

	@jsonMember
	XaxisTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

	@jsonArrayMember(String)
	public Yaxises : string[] = [];

	@jsonArrayMember(Number)
	YaxisesTransforms: Aggregation[] = [];

	@jsonMember(SpcYAxesConfig)
	public YAxesConfig : SpcYAxesConfig = new SpcYAxesConfig();

	@jsonArrayMember(DataFilter)
	public DataFilters : DataFilter[] = [];

	@jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonMember
  public ChartType : SPCChartType = SPCChartType.XBAR;

  @jsonMember(SpcWidgetQueryDistributionConfig)
  public StableDistribution : SpcWidgetQueryDistributionConfig = new SpcWidgetQueryDistributionConfig();

  @jsonArrayMember(Boolean)
  public Nelsonsignals : boolean[] = [false, false, false, false, false, false, false, false];

  @jsonMember
  public ColorValue : string = undefined;

  @jsonMember
  public ColorLimit : string = 'black';

  @jsonMember
  public ColorNelson : string = 'red';

}

@jsonObject
export class SpcConfig extends WidgetConfig{
  @jsonMember
	public Type : string = 'line';

  @jsonMember
  public DisableZoom : boolean = false;

  @jsonMember
	public AnimationsMs : number = 1000;

	@jsonMember(SpcQuery)
	public ConfigQuery : SpcQuery = new SpcQuery();

   @jsonMember
  public ColorScheme : string = '';

  @jsonMember
  public InteractionMode : string = 'nearest';

  @jsonMember
  public InteractionIntersect : boolean = false;

  @jsonMember
  public InteractionAxis : string = '';

	public constructor() {
		super();
		this.Type = 'line';
	}

	public Clone() : SpcConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, SpcConfig), SpcConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    if (this.ConfigQuery.Query && this.ConfigQuery.Query.targetprojectionid?.length > 0) {
      result.push({projectionId : this.ConfigQuery.Query.targetprojectionid, group : this.ConfigQuery.Query.targetgroup})
    }
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = true;
}
