/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
/**
 * Message implementation for sysadmin.GetCustomersResponse
 */
export class GetCustomersResponse implements GrpcMessage {
  static id = 'sysadmin.GetCustomersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomersResponse();
    GetCustomersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomersResponse) {
    _instance.customers = _instance.customers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.Customer();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.Customer.deserializeBinaryFromReader
          );
          (_instance.customers = _instance.customers || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.customers && _instance.customers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.customers as any,
        xprojectorGrpcModels002.Customer.serializeBinaryToWriter
      );
    }
  }

  private _customers?: xprojectorGrpcModels002.Customer[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomersResponse.AsObject>) {
    _value = _value || {};
    this.customers = (_value.customers || []).map(
      m => new xprojectorGrpcModels002.Customer(m)
    );
    GetCustomersResponse.refineValues(this);
  }
  get customers(): xprojectorGrpcModels002.Customer[] | undefined {
    return this._customers;
  }
  set customers(value: xprojectorGrpcModels002.Customer[] | undefined) {
    this._customers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomersResponse.AsObject {
    return {
      customers: (this.customers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomersResponse.AsProtobufJSON {
    return {
      customers: (this.customers || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetCustomersResponse {
  /**
   * Standard JavaScript object representation for GetCustomersResponse
   */
  export interface AsObject {
    customers?: xprojectorGrpcModels002.Customer.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetCustomersResponse
   */
  export interface AsProtobufJSON {
    customers?: xprojectorGrpcModels002.Customer.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for sysadmin.DeleteCustomerRequest
 */
export class DeleteCustomerRequest implements GrpcMessage {
  static id = 'sysadmin.DeleteCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteCustomerRequest();
    DeleteCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteCustomerRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteCustomerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteCustomerRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    DeleteCustomerRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteCustomerRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteCustomerRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module DeleteCustomerRequest {
  /**
   * Standard JavaScript object representation for DeleteCustomerRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteCustomerRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for sysadmin.DeleteUserRequest
 */
export class DeleteUserRequest implements GrpcMessage {
  static id = 'sysadmin.DeleteUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteUserRequest();
    DeleteUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteUserRequest) {
    _instance.userId = _instance.userId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeString(1, _instance.userId);
    }
  }

  private _userId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteUserRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    DeleteUserRequest.refineValues(this);
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteUserRequest.AsObject {
    return {
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteUserRequest.AsProtobufJSON {
    return {
      userId: this.userId
    };
  }
}
export module DeleteUserRequest {
  /**
   * Standard JavaScript object representation for DeleteUserRequest
   */
  export interface AsObject {
    userId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteUserRequest
   */
  export interface AsProtobufJSON {
    userId?: string;
  }
}

/**
 * Message implementation for sysadmin.GetUsersResponse
 */
export class GetUsersResponse implements GrpcMessage {
  static id = 'sysadmin.GetUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUsersResponse();
    GetUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUsersResponse) {
    _instance.users = _instance.users || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.User();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.User.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.users as any,
        xprojectorGrpcModels002.User.serializeBinaryToWriter
      );
    }
  }

  private _users?: xprojectorGrpcModels002.User[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUsersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUsersResponse.AsObject>) {
    _value = _value || {};
    this.users = (_value.users || []).map(
      m => new xprojectorGrpcModels002.User(m)
    );
    GetUsersResponse.refineValues(this);
  }
  get users(): xprojectorGrpcModels002.User[] | undefined {
    return this._users;
  }
  set users(value: xprojectorGrpcModels002.User[] | undefined) {
    this._users = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUsersResponse.AsObject {
    return {
      users: (this.users || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUsersResponse.AsProtobufJSON {
    return {
      users: (this.users || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetUsersResponse {
  /**
   * Standard JavaScript object representation for GetUsersResponse
   */
  export interface AsObject {
    users?: xprojectorGrpcModels002.User.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetUsersResponse
   */
  export interface AsProtobufJSON {
    users?: xprojectorGrpcModels002.User.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for sysadmin.SetSysAdminPasswordRequest
 */
export class SetSysAdminPasswordRequest implements GrpcMessage {
  static id = 'sysadmin.SetSysAdminPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetSysAdminPasswordRequest();
    SetSysAdminPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetSysAdminPasswordRequest) {
    _instance.oldPassword = _instance.oldPassword || '';
    _instance.newPassword = _instance.newPassword || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetSysAdminPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.oldPassword = _reader.readString();
          break;
        case 2:
          _instance.newPassword = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetSysAdminPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetSysAdminPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.oldPassword) {
      _writer.writeString(1, _instance.oldPassword);
    }
    if (_instance.newPassword) {
      _writer.writeString(2, _instance.newPassword);
    }
  }

  private _oldPassword?: string;
  private _newPassword?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetSysAdminPasswordRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetSysAdminPasswordRequest.AsObject>) {
    _value = _value || {};
    this.oldPassword = _value.oldPassword;
    this.newPassword = _value.newPassword;
    SetSysAdminPasswordRequest.refineValues(this);
  }
  get oldPassword(): string | undefined {
    return this._oldPassword;
  }
  set oldPassword(value: string | undefined) {
    this._oldPassword = value;
  }
  get newPassword(): string | undefined {
    return this._newPassword;
  }
  set newPassword(value: string | undefined) {
    this._newPassword = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetSysAdminPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetSysAdminPasswordRequest.AsObject {
    return {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetSysAdminPasswordRequest.AsProtobufJSON {
    return {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };
  }
}
export module SetSysAdminPasswordRequest {
  /**
   * Standard JavaScript object representation for SetSysAdminPasswordRequest
   */
  export interface AsObject {
    oldPassword?: string;
    newPassword?: string;
  }

  /**
   * Protobuf JSON representation for SetSysAdminPasswordRequest
   */
  export interface AsProtobufJSON {
    oldPassword?: string;
    newPassword?: string;
  }
}

/**
 * Message implementation for sysadmin.SysAdminGetCustomerUsersRequest
 */
export class SysAdminGetCustomerUsersRequest implements GrpcMessage {
  static id = 'sysadmin.SysAdminGetCustomerUsersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SysAdminGetCustomerUsersRequest();
    SysAdminGetCustomerUsersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SysAdminGetCustomerUsersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.admins = _instance.admins || false;
    _instance.firstnameFilter = _instance.firstnameFilter || '';
    _instance.surnameFilter = _instance.surnameFilter || '';
    _instance.emailFilter = _instance.emailFilter || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SysAdminGetCustomerUsersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.admins = _reader.readBool();
          break;
        case 3:
          _instance.firstnameFilter = _reader.readString();
          break;
        case 4:
          _instance.surnameFilter = _reader.readString();
          break;
        case 5:
          _instance.emailFilter = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SysAdminGetCustomerUsersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SysAdminGetCustomerUsersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.admins) {
      _writer.writeBool(2, _instance.admins);
    }
    if (_instance.firstnameFilter) {
      _writer.writeString(3, _instance.firstnameFilter);
    }
    if (_instance.surnameFilter) {
      _writer.writeString(4, _instance.surnameFilter);
    }
    if (_instance.emailFilter) {
      _writer.writeString(5, _instance.emailFilter);
    }
  }

  private _customerId?: string;
  private _admins?: boolean;
  private _firstnameFilter?: string;
  private _surnameFilter?: string;
  private _emailFilter?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SysAdminGetCustomerUsersRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SysAdminGetCustomerUsersRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.admins = _value.admins;
    this.firstnameFilter = _value.firstnameFilter;
    this.surnameFilter = _value.surnameFilter;
    this.emailFilter = _value.emailFilter;
    SysAdminGetCustomerUsersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get admins(): boolean | undefined {
    return this._admins;
  }
  set admins(value: boolean | undefined) {
    this._admins = value;
  }
  get firstnameFilter(): string | undefined {
    return this._firstnameFilter;
  }
  set firstnameFilter(value: string | undefined) {
    this._firstnameFilter = value;
  }
  get surnameFilter(): string | undefined {
    return this._surnameFilter;
  }
  set surnameFilter(value: string | undefined) {
    this._surnameFilter = value;
  }
  get emailFilter(): string | undefined {
    return this._emailFilter;
  }
  set emailFilter(value: string | undefined) {
    this._emailFilter = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SysAdminGetCustomerUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SysAdminGetCustomerUsersRequest.AsObject {
    return {
      customerId: this.customerId,
      admins: this.admins,
      firstnameFilter: this.firstnameFilter,
      surnameFilter: this.surnameFilter,
      emailFilter: this.emailFilter
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SysAdminGetCustomerUsersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      admins: this.admins,
      firstnameFilter: this.firstnameFilter,
      surnameFilter: this.surnameFilter,
      emailFilter: this.emailFilter
    };
  }
}
export module SysAdminGetCustomerUsersRequest {
  /**
   * Standard JavaScript object representation for SysAdminGetCustomerUsersRequest
   */
  export interface AsObject {
    customerId?: string;
    admins?: boolean;
    firstnameFilter?: string;
    surnameFilter?: string;
    emailFilter?: string;
  }

  /**
   * Protobuf JSON representation for SysAdminGetCustomerUsersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    admins?: boolean;
    firstnameFilter?: string;
    surnameFilter?: string;
    emailFilter?: string;
  }
}

/**
 * Message implementation for sysadmin.SysAdminGetCustomerUsersResponse
 */
export class SysAdminGetCustomerUsersResponse implements GrpcMessage {
  static id = 'sysadmin.SysAdminGetCustomerUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SysAdminGetCustomerUsersResponse();
    SysAdminGetCustomerUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SysAdminGetCustomerUsersResponse) {
    _instance.customerUsers = _instance.customerUsers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SysAdminGetCustomerUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.CustomerUser();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.CustomerUser.deserializeBinaryFromReader
          );
          (_instance.customerUsers = _instance.customerUsers || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SysAdminGetCustomerUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SysAdminGetCustomerUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.customerUsers && _instance.customerUsers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.customerUsers as any,
        xprojectorGrpcModels002.CustomerUser.serializeBinaryToWriter
      );
    }
  }

  private _customerUsers?: xprojectorGrpcModels002.CustomerUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SysAdminGetCustomerUsersResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SysAdminGetCustomerUsersResponse.AsObject>
  ) {
    _value = _value || {};
    this.customerUsers = (_value.customerUsers || []).map(
      m => new xprojectorGrpcModels002.CustomerUser(m)
    );
    SysAdminGetCustomerUsersResponse.refineValues(this);
  }
  get customerUsers(): xprojectorGrpcModels002.CustomerUser[] | undefined {
    return this._customerUsers;
  }
  set customerUsers(value: xprojectorGrpcModels002.CustomerUser[] | undefined) {
    this._customerUsers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SysAdminGetCustomerUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SysAdminGetCustomerUsersResponse.AsObject {
    return {
      customerUsers: (this.customerUsers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SysAdminGetCustomerUsersResponse.AsProtobufJSON {
    return {
      customerUsers: (this.customerUsers || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module SysAdminGetCustomerUsersResponse {
  /**
   * Standard JavaScript object representation for SysAdminGetCustomerUsersResponse
   */
  export interface AsObject {
    customerUsers?: xprojectorGrpcModels002.CustomerUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for SysAdminGetCustomerUsersResponse
   */
  export interface AsProtobufJSON {
    customerUsers?:
      | xprojectorGrpcModels002.CustomerUser.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for sysadmin.DeleteUserFromCustomerRequest
 */
export class DeleteUserFromCustomerRequest implements GrpcMessage {
  static id = 'sysadmin.DeleteUserFromCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteUserFromCustomerRequest();
    DeleteUserFromCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteUserFromCustomerRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.userId = _instance.userId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteUserFromCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.userId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteUserFromCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteUserFromCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.userId) {
      _writer.writeString(2, _instance.userId);
    }
  }

  private _customerId?: string;
  private _userId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteUserFromCustomerRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteUserFromCustomerRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.userId = _value.userId;
    DeleteUserFromCustomerRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteUserFromCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteUserFromCustomerRequest.AsObject {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteUserFromCustomerRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }
}
export module DeleteUserFromCustomerRequest {
  /**
   * Standard JavaScript object representation for DeleteUserFromCustomerRequest
   */
  export interface AsObject {
    customerId?: string;
    userId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteUserFromCustomerRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    userId?: string;
  }
}

/**
 * Message implementation for sysadmin.GenerateApiKeyResponse
 */
export class GenerateApiKeyResponse implements GrpcMessage {
  static id = 'sysadmin.GenerateApiKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GenerateApiKeyResponse();
    GenerateApiKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GenerateApiKeyResponse) {
    _instance.apiKey = _instance.apiKey || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GenerateApiKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.apiKey = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GenerateApiKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GenerateApiKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.apiKey) {
      _writer.writeString(1, _instance.apiKey);
    }
  }

  private _apiKey?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GenerateApiKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GenerateApiKeyResponse.AsObject>) {
    _value = _value || {};
    this.apiKey = _value.apiKey;
    GenerateApiKeyResponse.refineValues(this);
  }
  get apiKey(): string | undefined {
    return this._apiKey;
  }
  set apiKey(value: string | undefined) {
    this._apiKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GenerateApiKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GenerateApiKeyResponse.AsObject {
    return {
      apiKey: this.apiKey
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GenerateApiKeyResponse.AsProtobufJSON {
    return {
      apiKey: this.apiKey
    };
  }
}
export module GenerateApiKeyResponse {
  /**
   * Standard JavaScript object representation for GenerateApiKeyResponse
   */
  export interface AsObject {
    apiKey?: string;
  }

  /**
   * Protobuf JSON representation for GenerateApiKeyResponse
   */
  export interface AsProtobufJSON {
    apiKey?: string;
  }
}

/**
 * Message implementation for sysadmin.ResetPasswordRequest
 */
export class ResetPasswordRequest implements GrpcMessage {
  static id = 'sysadmin.ResetPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResetPasswordRequest();
    ResetPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResetPasswordRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.userId = _instance.userId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResetPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.userId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ResetPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResetPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.userId) {
      _writer.writeString(2, _instance.userId);
    }
  }

  private _customerId?: string;
  private _userId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResetPasswordRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ResetPasswordRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.userId = _value.userId;
    ResetPasswordRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResetPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResetPasswordRequest.AsObject {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResetPasswordRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }
}
export module ResetPasswordRequest {
  /**
   * Standard JavaScript object representation for ResetPasswordRequest
   */
  export interface AsObject {
    customerId?: string;
    userId?: string;
  }

  /**
   * Protobuf JSON representation for ResetPasswordRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    userId?: string;
  }
}

/**
 * Message implementation for sysadmin.AddUserToCustomerWithPasswordRequest
 */
export class AddUserToCustomerWithPasswordRequest implements GrpcMessage {
  static id = 'sysadmin.AddUserToCustomerWithPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddUserToCustomerWithPasswordRequest();
    AddUserToCustomerWithPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddUserToCustomerWithPasswordRequest) {
    _instance.customerUser = _instance.customerUser || undefined;
    _instance.password = _instance.password || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddUserToCustomerWithPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerUser = new xprojectorGrpcModels002.CustomerUser();
          _reader.readMessage(
            _instance.customerUser,
            xprojectorGrpcModels002.CustomerUser.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.password = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddUserToCustomerWithPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddUserToCustomerWithPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerUser) {
      _writer.writeMessage(
        1,
        _instance.customerUser as any,
        xprojectorGrpcModels002.CustomerUser.serializeBinaryToWriter
      );
    }
    if (_instance.password) {
      _writer.writeString(2, _instance.password);
    }
  }

  private _customerUser?: xprojectorGrpcModels002.CustomerUser;
  private _password?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddUserToCustomerWithPasswordRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddUserToCustomerWithPasswordRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerUser = _value.customerUser
      ? new xprojectorGrpcModels002.CustomerUser(_value.customerUser)
      : undefined;
    this.password = _value.password;
    AddUserToCustomerWithPasswordRequest.refineValues(this);
  }
  get customerUser(): xprojectorGrpcModels002.CustomerUser | undefined {
    return this._customerUser;
  }
  set customerUser(value: xprojectorGrpcModels002.CustomerUser | undefined) {
    this._customerUser = value;
  }
  get password(): string | undefined {
    return this._password;
  }
  set password(value: string | undefined) {
    this._password = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddUserToCustomerWithPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddUserToCustomerWithPasswordRequest.AsObject {
    return {
      customerUser: this.customerUser
        ? this.customerUser.toObject()
        : undefined,
      password: this.password
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddUserToCustomerWithPasswordRequest.AsProtobufJSON {
    return {
      customerUser: this.customerUser
        ? this.customerUser.toProtobufJSON(options)
        : null,
      password: this.password
    };
  }
}
export module AddUserToCustomerWithPasswordRequest {
  /**
   * Standard JavaScript object representation for AddUserToCustomerWithPasswordRequest
   */
  export interface AsObject {
    customerUser?: xprojectorGrpcModels002.CustomerUser.AsObject;
    password?: string;
  }

  /**
   * Protobuf JSON representation for AddUserToCustomerWithPasswordRequest
   */
  export interface AsProtobufJSON {
    customerUser?: xprojectorGrpcModels002.CustomerUser.AsProtobufJSON | null;
    password?: string;
  }
}

/**
 * Message implementation for sysadmin.UpdateGeoLocationsRequest
 */
export class UpdateGeoLocationsRequest implements GrpcMessage {
  static id = 'sysadmin.UpdateGeoLocationsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateGeoLocationsRequest();
    UpdateGeoLocationsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateGeoLocationsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.overwrite = _instance.overwrite || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateGeoLocationsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.overwrite = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateGeoLocationsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateGeoLocationsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.overwrite) {
      _writer.writeBool(2, _instance.overwrite);
    }
  }

  private _customerId?: string;
  private _overwrite?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateGeoLocationsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateGeoLocationsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.overwrite = _value.overwrite;
    UpdateGeoLocationsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get overwrite(): boolean | undefined {
    return this._overwrite;
  }
  set overwrite(value: boolean | undefined) {
    this._overwrite = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateGeoLocationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateGeoLocationsRequest.AsObject {
    return {
      customerId: this.customerId,
      overwrite: this.overwrite
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateGeoLocationsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      overwrite: this.overwrite
    };
  }
}
export module UpdateGeoLocationsRequest {
  /**
   * Standard JavaScript object representation for UpdateGeoLocationsRequest
   */
  export interface AsObject {
    customerId?: string;
    overwrite?: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateGeoLocationsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    overwrite?: boolean;
  }
}

/**
 * Message implementation for sysadmin.GetCustomersContentRequest
 */
export class GetCustomersContentRequest implements GrpcMessage {
  static id = 'sysadmin.GetCustomersContentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomersContentRequest();
    GetCustomersContentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomersContentRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomersContentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomersContentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomersContentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomersContentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomersContentRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    GetCustomersContentRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomersContentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomersContentRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomersContentRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module GetCustomersContentRequest {
  /**
   * Standard JavaScript object representation for GetCustomersContentRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetCustomersContentRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for sysadmin.GetCustomersContentResponse
 */
export class GetCustomersContentResponse implements GrpcMessage {
  static id = 'sysadmin.GetCustomersContentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomersContentResponse();
    GetCustomersContentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomersContentResponse) {
    _instance.dashboardInfos = _instance.dashboardInfos || [];
    _instance.customeContentData = _instance.customeContentData || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomersContentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcDashboardInfo();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcDashboardInfo
              .deserializeBinaryFromReader
          );
          (_instance.dashboardInfos = _instance.dashboardInfos || []).push(
            messageInitializer1
          );
          break;
        case 2:
          const messageInitializer2 = new xprojectorGrpcModels002.GrpcCustomerContentData();
          _reader.readMessage(
            messageInitializer2,
            xprojectorGrpcModels002.GrpcCustomerContentData
              .deserializeBinaryFromReader
          );
          (_instance.customeContentData =
            _instance.customeContentData || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomersContentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomersContentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.dashboardInfos && _instance.dashboardInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dashboardInfos as any,
        xprojectorGrpcModels002.GrpcDashboardInfo.serializeBinaryToWriter
      );
    }
    if (_instance.customeContentData && _instance.customeContentData.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.customeContentData as any,
        xprojectorGrpcModels002.GrpcCustomerContentData.serializeBinaryToWriter
      );
    }
  }

  private _dashboardInfos?: xprojectorGrpcModels002.GrpcDashboardInfo[];
  private _customeContentData?: xprojectorGrpcModels002.GrpcCustomerContentData[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomersContentResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomersContentResponse.AsObject>) {
    _value = _value || {};
    this.dashboardInfos = (_value.dashboardInfos || []).map(
      m => new xprojectorGrpcModels002.GrpcDashboardInfo(m)
    );
    this.customeContentData = (_value.customeContentData || []).map(
      m => new xprojectorGrpcModels002.GrpcCustomerContentData(m)
    );
    GetCustomersContentResponse.refineValues(this);
  }
  get dashboardInfos():
    | xprojectorGrpcModels002.GrpcDashboardInfo[]
    | undefined {
    return this._dashboardInfos;
  }
  set dashboardInfos(
    value: xprojectorGrpcModels002.GrpcDashboardInfo[] | undefined
  ) {
    this._dashboardInfos = value;
  }
  get customeContentData():
    | xprojectorGrpcModels002.GrpcCustomerContentData[]
    | undefined {
    return this._customeContentData;
  }
  set customeContentData(
    value: xprojectorGrpcModels002.GrpcCustomerContentData[] | undefined
  ) {
    this._customeContentData = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomersContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomersContentResponse.AsObject {
    return {
      dashboardInfos: (this.dashboardInfos || []).map(m => m.toObject()),
      customeContentData: (this.customeContentData || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomersContentResponse.AsProtobufJSON {
    return {
      dashboardInfos: (this.dashboardInfos || []).map(m =>
        m.toProtobufJSON(options)
      ),
      customeContentData: (this.customeContentData || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetCustomersContentResponse {
  /**
   * Standard JavaScript object representation for GetCustomersContentResponse
   */
  export interface AsObject {
    dashboardInfos?: xprojectorGrpcModels002.GrpcDashboardInfo.AsObject[];
    customeContentData?: xprojectorGrpcModels002.GrpcCustomerContentData.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetCustomersContentResponse
   */
  export interface AsProtobufJSON {
    dashboardInfos?:
      | xprojectorGrpcModels002.GrpcDashboardInfo.AsProtobufJSON[]
      | null;
    customeContentData?:
      | xprojectorGrpcModels002.GrpcCustomerContentData.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for sysadmin.GetActiveUserDevicesResponse
 */
export class GetActiveUserDevicesResponse implements GrpcMessage {
  static id = 'sysadmin.GetActiveUserDevicesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetActiveUserDevicesResponse();
    GetActiveUserDevicesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetActiveUserDevicesResponse) {
    _instance.userDevices = _instance.userDevices || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetActiveUserDevicesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.UserDevice();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.UserDevice.deserializeBinaryFromReader
          );
          (_instance.userDevices = _instance.userDevices || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetActiveUserDevicesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetActiveUserDevicesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.userDevices && _instance.userDevices.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.userDevices as any,
        xprojectorGrpcModels002.UserDevice.serializeBinaryToWriter
      );
    }
  }

  private _userDevices?: xprojectorGrpcModels002.UserDevice[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetActiveUserDevicesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetActiveUserDevicesResponse.AsObject>
  ) {
    _value = _value || {};
    this.userDevices = (_value.userDevices || []).map(
      m => new xprojectorGrpcModels002.UserDevice(m)
    );
    GetActiveUserDevicesResponse.refineValues(this);
  }
  get userDevices(): xprojectorGrpcModels002.UserDevice[] | undefined {
    return this._userDevices;
  }
  set userDevices(value: xprojectorGrpcModels002.UserDevice[] | undefined) {
    this._userDevices = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetActiveUserDevicesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetActiveUserDevicesResponse.AsObject {
    return {
      userDevices: (this.userDevices || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetActiveUserDevicesResponse.AsProtobufJSON {
    return {
      userDevices: (this.userDevices || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetActiveUserDevicesResponse {
  /**
   * Standard JavaScript object representation for GetActiveUserDevicesResponse
   */
  export interface AsObject {
    userDevices?: xprojectorGrpcModels002.UserDevice.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetActiveUserDevicesResponse
   */
  export interface AsProtobufJSON {
    userDevices?: xprojectorGrpcModels002.UserDevice.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for sysadmin.AddUserDeviceRequest
 */
export class AddUserDeviceRequest implements GrpcMessage {
  static id = 'sysadmin.AddUserDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddUserDeviceRequest();
    AddUserDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddUserDeviceRequest) {
    _instance.name = _instance.name || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddUserDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddUserDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddUserDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
  }

  private _name?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddUserDeviceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddUserDeviceRequest.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    AddUserDeviceRequest.refineValues(this);
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddUserDeviceRequest.AsObject {
    return {
      name: this.name
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddUserDeviceRequest.AsProtobufJSON {
    return {
      name: this.name
    };
  }
}
export module AddUserDeviceRequest {
  /**
   * Standard JavaScript object representation for AddUserDeviceRequest
   */
  export interface AsObject {
    name?: string;
  }

  /**
   * Protobuf JSON representation for AddUserDeviceRequest
   */
  export interface AsProtobufJSON {
    name?: string;
  }
}

/**
 * Message implementation for sysadmin.DeleteUserDeviceRequest
 */
export class DeleteUserDeviceRequest implements GrpcMessage {
  static id = 'sysadmin.DeleteUserDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteUserDeviceRequest();
    DeleteUserDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteUserDeviceRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteUserDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteUserDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteUserDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteUserDeviceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteUserDeviceRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    DeleteUserDeviceRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteUserDeviceRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteUserDeviceRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteUserDeviceRequest {
  /**
   * Standard JavaScript object representation for DeleteUserDeviceRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteUserDeviceRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for sysadmin.LogoutAllLocationsRequest
 */
export class LogoutAllLocationsRequest implements GrpcMessage {
  static id = 'sysadmin.LogoutAllLocationsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogoutAllLocationsRequest();
    LogoutAllLocationsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogoutAllLocationsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.userId = _instance.userId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogoutAllLocationsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.userId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    LogoutAllLocationsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogoutAllLocationsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.userId) {
      _writer.writeString(2, _instance.userId);
    }
  }

  private _customerId?: string;
  private _userId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogoutAllLocationsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogoutAllLocationsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.userId = _value.userId;
    LogoutAllLocationsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogoutAllLocationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogoutAllLocationsRequest.AsObject {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogoutAllLocationsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      userId: this.userId
    };
  }
}
export module LogoutAllLocationsRequest {
  /**
   * Standard JavaScript object representation for LogoutAllLocationsRequest
   */
  export interface AsObject {
    customerId?: string;
    userId?: string;
  }

  /**
   * Protobuf JSON representation for LogoutAllLocationsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    userId?: string;
  }
}

/**
 * Message implementation for sysadmin.SetNodeLoggingRequest
 */
export class SetNodeLoggingRequest implements GrpcMessage {
  static id = 'sysadmin.SetNodeLoggingRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetNodeLoggingRequest();
    SetNodeLoggingRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetNodeLoggingRequest) {
    _instance.id = _instance.id || '';
    _instance.enabled = _instance.enabled || false;
    _instance.ttl = _instance.ttl || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetNodeLoggingRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.enabled = _reader.readBool();
          break;
        case 3:
          _instance.ttl = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    SetNodeLoggingRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetNodeLoggingRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.enabled) {
      _writer.writeBool(2, _instance.enabled);
    }
    if (_instance.ttl) {
      _writer.writeInt32(3, _instance.ttl);
    }
  }

  private _id?: string;
  private _enabled?: boolean;
  private _ttl?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetNodeLoggingRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetNodeLoggingRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.enabled = _value.enabled;
    this.ttl = _value.ttl;
    SetNodeLoggingRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get enabled(): boolean | undefined {
    return this._enabled;
  }
  set enabled(value: boolean | undefined) {
    this._enabled = value;
  }
  get ttl(): number | undefined {
    return this._ttl;
  }
  set ttl(value: number | undefined) {
    this._ttl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetNodeLoggingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetNodeLoggingRequest.AsObject {
    return {
      id: this.id,
      enabled: this.enabled,
      ttl: this.ttl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetNodeLoggingRequest.AsProtobufJSON {
    return {
      id: this.id,
      enabled: this.enabled,
      ttl: this.ttl
    };
  }
}
export module SetNodeLoggingRequest {
  /**
   * Standard JavaScript object representation for SetNodeLoggingRequest
   */
  export interface AsObject {
    id?: string;
    enabled?: boolean;
    ttl?: number;
  }

  /**
   * Protobuf JSON representation for SetNodeLoggingRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    enabled?: boolean;
    ttl?: number;
  }
}

/**
 * Message implementation for sysadmin.GetNodeLoggingRequest
 */
export class GetNodeLoggingRequest implements GrpcMessage {
  static id = 'sysadmin.GetNodeLoggingRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeLoggingRequest();
    GetNodeLoggingRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeLoggingRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeLoggingRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeLoggingRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeLoggingRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeLoggingRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeLoggingRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    GetNodeLoggingRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeLoggingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeLoggingRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeLoggingRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetNodeLoggingRequest {
  /**
   * Standard JavaScript object representation for GetNodeLoggingRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetNodeLoggingRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for sysadmin.GetNodeLoggingResponse
 */
export class GetNodeLoggingResponse implements GrpcMessage {
  static id = 'sysadmin.GetNodeLoggingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeLoggingResponse();
    GetNodeLoggingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeLoggingResponse) {
    _instance.id = _instance.id || '';
    _instance.enabled = _instance.enabled || false;
    _instance.expiry = _instance.expiry || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeLoggingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.enabled = _reader.readBool();
          break;
        case 3:
          _instance.expiry = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expiry,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeLoggingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeLoggingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.enabled) {
      _writer.writeBool(2, _instance.enabled);
    }
    if (_instance.expiry) {
      _writer.writeMessage(
        3,
        _instance.expiry as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _enabled?: boolean;
  private _expiry?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeLoggingResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeLoggingResponse.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.enabled = _value.enabled;
    this.expiry = _value.expiry
      ? new googleProtobuf000.Timestamp(_value.expiry)
      : undefined;
    GetNodeLoggingResponse.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get enabled(): boolean | undefined {
    return this._enabled;
  }
  set enabled(value: boolean | undefined) {
    this._enabled = value;
  }
  get expiry(): googleProtobuf000.Timestamp | undefined {
    return this._expiry;
  }
  set expiry(value: googleProtobuf000.Timestamp | undefined) {
    this._expiry = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeLoggingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeLoggingResponse.AsObject {
    return {
      id: this.id,
      enabled: this.enabled,
      expiry: this.expiry ? this.expiry.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeLoggingResponse.AsProtobufJSON {
    return {
      id: this.id,
      enabled: this.enabled,
      expiry: this.expiry ? this.expiry.toProtobufJSON(options) : null
    };
  }
}
export module GetNodeLoggingResponse {
  /**
   * Standard JavaScript object representation for GetNodeLoggingResponse
   */
  export interface AsObject {
    id?: string;
    enabled?: boolean;
    expiry?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetNodeLoggingResponse
   */
  export interface AsProtobufJSON {
    id?: string;
    enabled?: boolean;
    expiry?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
