import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projections-workspace',
  templateUrl: './projections-workspace.component.html',
  styleUrls: ['./projections-workspace.component.scss'],
  host: {
    class:'content-container'
}
})
export class ProjectionsWorkspaceComponent implements OnInit {

  collapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
