
 <div class="searchbar-container" *ngIf="searchParameterIds?.length > 0" >
  <div class="icons-search-wrapper">
    <label class="searchbar-label">
      <clr-icon shape="search" size="24"></clr-icon>
      <input type="text" class="searchbar-input" placeholder="Search..." (keyup.enter)="search()"
        [(ngModel)]="searchValue">
    </label>
  </div>
</div>
<div *ngIf="searchParameterIds?.length > 0 && searchResultNodes.length > 0" >
  <table class="table table-compact">
    <tbody>
      <tr *ngFor="let treeNode of searchResultNodes; index as i" (click)="onRowClick($event, i)">
        <td class="left" [style.backgroundColor]="selectedIndex == i ? 'hsl(201, 29%, 88%)' : ''" >{{treeNode.name}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="searchParameterIds?.length > 0 && searchInitiated && searchResultNodes.length == 0" >
  <table class="table table-compact">
    <tbody>
      <tr>
        <td class="left">No nodes found.</td>
      </tr>
    </tbody>
  </table>
</div>
<clr-tree [clrLazy]="true" *ngIf="initiated">
  <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren"
    [(clrExpanded)]="item.expanded">
    <clr-icon [attr.shape]="item?.edgeType?.isReference ? 'link' : 'node'"></clr-icon>
    <a class="clr-treenode-link" [class.active]="item == selected" routerLinkActive="disable" (click)="selectItem(item)">
      {{item?.name}}
    </a>
  </clr-tree-node>
</clr-tree>
