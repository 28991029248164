<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid">
  <clr-dg-column>Id</clr-dg-column>
  <clr-dg-column>Header</clr-dg-column>
  <clr-dg-column>Category</clr-dg-column>
  <clr-dg-column *ngIf="!nodeType?.systemDefined || edgeType?.systemDefined">Order</clr-dg-column>

  <clr-dg-row *ngFor="let property of properties; let i = index" [clrDgItem]="property">
    <clr-dg-cell>
      <!-- <input class="clr-input" placeholder="" name="nodeType_id_{{i}}" id=nodetype_id{{i}}
        [(ngModel)]="nodeType.id" /> -->
      {{property.id}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{property.header}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{property.category}}
    </clr-dg-cell>

    <clr-dg-cell *ngIf="!nodeType?.systemDefined || edgeType?.systemDefined" >
      <clr-icon (click)="movePropertyUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
      <clr-icon (click)="movePropertyDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
        style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
    </clr-dg-cell>

  </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addProperty('string')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add string
</button>
<button type="button" class="btn btn-link" (click)="addProperty('double')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add numeric
</button>
<button type="button" class="btn btn-link" (click)="addProperty('boolean')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add boolean
</button>
<button type="button" class="btn btn-link" (click)="addProperty('datetime')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add date
</button>
<button type="button" class="btn btn-link" (click)="addProperty('stringoptions')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add string options
</button>
<button type="button" class="btn btn-link" (click)="addProperty('dashboard')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add dashboard
</button>
<button type="button" class="btn btn-link" (click)="addProperty('stringarray')" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined">
  <clr-icon shape="plus-circle"></clr-icon>
  Add string array
</button>


<button *ngIf="selected && (!nodeType?.systemDefined || !edgeType?.systemDefined)" type="button" class="btn btn-link" (click)="deleteSelectedProperty()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected property
</button>

<div *ngIf="selected">
  <div class="card">
    <div class="card-header">
      Edit property ({{selected.id}})
    </div>

    <div class="card-block">
      <form clrForm>

        <!-- Common -->

        <clr-input-container>
          <label>Id</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="id" [(ngModel)]="selected.id" />
        </clr-input-container>

        <clr-input-container>
          <label>Header</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="header" [(ngModel)]="selected.header" />
        </clr-input-container>

        <clr-input-container>
          <label>Tooltip</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="tooltip" [(ngModel)]="selected.tooltip" />
        </clr-input-container>

        <clr-select-container>
          <label>Category</label>
          <select clrSelect name="category" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.category">
            <option *ngFor="let category of categories" [ngValue]="category">
              {{category}}</option>
          </select>
        </clr-select-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.hidden" name="hidden" />
            <label>Hidden</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.readOnly" name="readonly" />
            <label>Read Only</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <!-- String -->

        <clr-input-container *ngIf="selected.typeName == 'string'" >
          <label>Default value</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="defaultvalue_string" [(ngModel)]="selected.defaultValue" />
        </clr-input-container>

        <clr-checkbox-container *ngIf="selected.typeName == 'string'" >
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.projection" name="projection_string" />
            <label>Projection</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <!-- String Array -->

        <clr-input-container *ngIf="selected.typeName == 'stringarray'" >
          <label>Default value</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="defaultvalue_stringarray" [(ngModel)]="selected.defaultValue" />
        </clr-input-container>

        <clr-checkbox-container *ngIf="selected.typeName == 'stringarray'" >
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.projectionGroup" name="projectiongroup_stringarray" />
            <label>Projection group</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-input-container *ngIf="selected.typeName == 'stringarray'" >
          <label>Projection property id</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined || !selected.projectionGroup" name="projectionpropertyid_stringarray" [(ngModel)]="selected.projectionPropertyId" />
        </clr-input-container>

        <clr-checkbox-container *ngIf="selected.typeName == 'stringarray'" >
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined || !selected.projectionGroup" [(ngModel)]="selected.projectionPropertyParent" name="projectionpropertyparent_stringarray" />
            <label>Projection property parent</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <!-- Dashboard -->

        <clr-input-container *ngIf="selected.typeName == 'dashboard'" >
          <label>Default value</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="defaultvalue_dashboard" [(ngModel)]="selected.defaultValue" />
        </clr-input-container>

        <clr-checkbox-container *ngIf="selected.typeName == 'dashboard'" >
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.systemDashboard" name="systemDashboard_dashboard" />
            <label>System dashboard</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <!-- Double -->

        <clr-input-container *ngIf="selected.typeName == 'double'">
          <label>Default value</label>
          <input clrInput type="number" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined"
            placeholder="" name="defaultvalue_double" [(ngModel)]="selected.defaultValue" />
        </clr-input-container>

        <clr-input-container *ngIf="selected.typeName == 'double'">
          <label>Min</label>
          <input clrInput type="number" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined"
            placeholder="" name="minvalue_double" [(ngModel)]="selected.min" />
        </clr-input-container>

        <clr-input-container *ngIf="selected.typeName == 'double'">
          <label>Max</label>
          <input clrInput type="number" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined"
            placeholder="" name="maxvalue_double" [(ngModel)]="selected.max" />
        </clr-input-container>

        <!-- Boolean -->

        <clr-checkbox-container *ngIf="selected.typeName == 'boolean'">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" [(ngModel)]="selected.defaultValue" name="defaultvalue_bool" />
            <label>Default value</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <!-- String Options -->

        <clr-input-container *ngIf="selected.typeName == 'stringoptions'" >
          <label>Default value</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="defaultvalue_stringoptions" [(ngModel)]="selected.defaultValue" />
        </clr-input-container>

        <clr-input-container *ngIf="selected.typeName == 'stringoptions'" >
          <label>Options</label>
          <input clrInput placeholder="" [disabled]="nodeType?.systemDefined || edgeType?.systemDefined" name="options_stringoptions" [(ngModel)]="stringoptions" />
        </clr-input-container>


        <!-- <clr-date-container *ngIf="selected.typeName == 'datetime'">
          <label>Default value</label>
          <input type="date" [(clrDate)]="selected.defaultValue" name="defaultvalue_date" />
        </clr-date-container> -->

      </form>

  </div>
</div>
