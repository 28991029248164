import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { DashboardOutputChangeParameters } from '../../models/dashboard-output-change-parameters';
import { OutputDataType } from '../../widgets/widget-config-service';

@Component({
  selector: 'xproj-editplcprogram-statistics',
  templateUrl: './editplcprogram-statistics.component.html',
  styleUrls: ['./editplcprogram-statistics.component.scss']
})
export class EditplcprogramStatisticsComponent implements OnInit {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  @Input() xautoProgramID : string = '';

  outputParameters: DashboardOutputChangeParameters[] = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent?.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
          this.xautoProgramID = id;
          this.logger.debug('set ', this.xautoProgramID);

          let out = new DashboardOutputChangeParameters();
          out.outputParameterName = 'id';
          out.value = this.xautoProgramID;
          out.datatype = OutputDataType.String;
          this.outputParameters.push(out);
      }
    });
  }

}
