export enum RossakerBmsDatapointValueStatus {
  None  = 0,
  Manual = 1 << 1,
  Interpolated = 1 << 2,
  Extrapolated = 1 << 3,
  Suspicious = 1 << 4,
  Error = 1 << 5
}

export class RossakerBmsDatapointValue {
  id : number;
  meterId : number;
  timestamp : Date;
  value : number;
  flags : RossakerBmsDatapointValueStatus;
}
