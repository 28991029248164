import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { XEdgeSelectXAutoVarsComponent } from './components/xedge-select-xauto-vars/xedge-select-xauto-vars.component';

@NgModule({
  declarations: [
    XEdgeSelectXAutoVarsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreSharedModule
  ],
  exports: [
    XEdgeSelectXAutoVarsComponent
  ]
})
export class XEdgeModule { }
