import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { Projection } from '../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { WidgetConfig } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { XprojLabelWidgetQueryConfigComponent } from '../label-widget-query-config/xproj-label-widget-query-config.component';
import { ColorThreshold, GaugeStyle, GaugeType, LabelConfig, LabelWidgetConfig, LabelWidgetQuery, LabelWidgetType } from './label-widget-config-service';
import { XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';


@Component({
  selector: 'xproj-label-widget-config',
  templateUrl: './xproj-label-widget-config.component.html',
  styleUrls: ['./xproj-label-widget-config.component.scss']
})
export class XprojLabelWidgetConfigComponent extends WidgetConfigBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(XprojLabelWidgetQueryConfigComponent, { read: XprojLabelWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojLabelWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: LabelWidgetConfig;

  editConfig: LabelWidgetConfig;

  projections: Projection[] = [];

  selectedGaugeThreshold : ColorThreshold = null;

  LabelWidgetType = LabelWidgetType;
  GaugeType = GaugeType;
  GaugeStyle = GaugeStyle;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
    this.updateInputParameters();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as LabelWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.config.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
  }

  addConfigQuery(): void {
    this.editConfig.ConfigQueries.push(new LabelWidgetQuery());
  }

  onQueryRemoved(query: LabelWidgetQuery) {
    this.editConfig.ConfigQueries = this.editConfig.ConfigQueries.filter(q => q != query);
  }

  addGaugeThreshold(needleGauge : boolean) {
    if (needleGauge) {
      this.editConfig.GaugeColorThresholds.push(new ColorThreshold());
    }
    else {
      this.editConfig.GaugeSettings.Thresholds.push(new ColorThreshold());
    }

  }

  removeThreshold(index: number) {
    ArrayUtils.RemoveItemAt(this.editConfig.GaugeColorThresholds, index);
  }

  moveThresholdUp(index: number)
  {
    ArrayUtils.MoveItemUp(this.editConfig.GaugeColorThresholds, index);
    this.refreshColumnConfigs();
  }

  moveThresholdDown(index: number)
  {
    ArrayUtils.MoveItemDown(this.editConfig.GaugeColorThresholds, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.editConfig.GaugeColorThresholds];
    this.editConfig.GaugeColorThresholds = [];
    this.cdr.detectChanges();
    this.editConfig.GaugeColorThresholds = copy;
  }

  removeGaugeSelectedThreshold()
  {
    let counter = 0;
    for(let threshold of this.editConfig.GaugeColorThresholds)
    {
      if(threshold == this.selectedGaugeThreshold)
        break;
      counter++;
    }
    this.removeThreshold(counter);
    this.selectedGaugeThreshold = null;
  }

  public async onSaveConfig() {
    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    this.widgetConfig.update(this.editConfig);
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }
}
