import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XConfDashboardWorkspaceComponent } from './components/xconf-dashboard-workspace/xconf-dashboard-workspace.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { XConfDashboardRoutingModule } from './xconf-dashboard-routing.module';
import { XConfDashboardTreeviewComponent } from './components/xconf-dashboard-treeview/xconf-dashboard-treeview.component';
import { RouterModule } from '@angular/router';
import { XConfDashboardComponent } from './components/xconf-dashboard/xconf-dashboard.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { XconfModule } from '@xprojectorfeatures/xconf/xconf.module';

@NgModule({
  declarations: [
    XConfDashboardWorkspaceComponent,
    XConfDashboardTreeviewComponent,
    XConfDashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    XConfDashboardRoutingModule,
    AngularResizeEventModule,
    SharedModule,
    XconfModule
  ]
})
export class XConfDashboardModule { }
