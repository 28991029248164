import { RossakerExportClient } from './proto/xprojector.modulerossakerbms.export.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { GetMeteringApiFileRequest, GetExtrapolatedValueRequest, GetExtrapolatedValueResponse, GetExportInfoRequest, GetExportInfoResponse, DataChunk, CreateMeterExportFileRequest, CreateApartmentAndFacilityExportFileRequest, TouchMetersRequest, AssertCountyAndDistrictRequest, TouchApartmentsAndFacilitiesRequest, AddDatapointValueRequest, ExportFileRequest } from './proto/xprojector.modulerossakerbms.export.pb';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { saveAs } from 'file-saver';
import { RossakerBmsBillingPeriod } from '@core/models/rossaker-bms-export-bot';
import { CreateExcelFileRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.customerusers.pb';

@Injectable({
  providedIn: 'root'
})
export class RossakerXProjectorBmsExportClient implements OnInit, OnDestroy {

  constructor(
    private rossakerExportClient: RossakerExportClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async touchMeters(customerId: string, meterType: string = '') {
    let request: TouchMetersRequest = new TouchMetersRequest({
      customerId: customerId,
      meterType: meterType
    });

    return await this.rossakerExportClient.touchMeters(request, this.state.metadata).toPromise();
  }

  async touchApartmentsAndFacilities(customerId: string) {
    let request: TouchApartmentsAndFacilitiesRequest = new TouchApartmentsAndFacilitiesRequest({
      customerId: customerId
    });

    return await this.rossakerExportClient.touchApartmentsAndFacilities(request, this.state.metadata).toPromise();
  }


  async getExtrapolatedValue(customerId: string, meterId: number, periodStart: Date, periodEnd: Date, extrapolateFrom: Date, extrapolateTo: Date): Promise<GetExtrapolatedValueResponse> {
    let request: GetExtrapolatedValueRequest = new GetExtrapolatedValueRequest({
      customerId: customerId,
      meterId: meterId + '',
      periodStart: Timestamp.fromDate(periodStart),
      periodEnd: Timestamp.fromDate(periodEnd),
      extrapolateFrom: Timestamp.fromDate(extrapolateFrom),
      extrapolateTo: Timestamp.fromDate(extrapolateTo)
    });

    return await this.rossakerExportClient.getExtrapolatedValue(request, this.state.metadata).toPromise();
  }

  async getExportInfo(billingPeriod: RossakerBmsBillingPeriod, periodStart: Date, periodEnd: Date): Promise<GetExportInfoResponse> {
    let request: GetExportInfoRequest = new GetExportInfoRequest({
      billingPeriod: GetExportInfoRequest.BillingPeriods[GetExportInfoRequest.BillingPeriods[billingPeriod]],
      periodStart: Timestamp.fromDate(periodStart),
      periodEnd: Timestamp.fromDate(periodEnd)
    });

    return await this.rossakerExportClient.getExportInfo(request, this.state.metadata).toPromise();
  }

  async createMeterExportFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: CreateMeterExportFileRequest = new CreateMeterExportFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.rossakerExportClient.createMeterExportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_meters.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async createApartmentAndFacilityExportFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: CreateApartmentAndFacilityExportFileRequest = new CreateApartmentAndFacilityExportFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.rossakerExportClient.createApartmentAndFacilityExportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_apartments.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async assertCountyAndDistrict(customerId: string, customerName: string, county : string, district : string ) : Promise<boolean> {
    let request: AssertCountyAndDistrictRequest = new AssertCountyAndDistrictRequest({
      customerName : customerName,
      customerId: customerId,
      county: county,
      district : district
    });

    let result = await this.rossakerExportClient.assertCountyAndDistrict(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async getMeteringApiFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: GetMeteringApiFileRequest = new GetMeteringApiFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.rossakerExportClient.getMeteringApiFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_MeteringApiReport.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async addDatapointValue(meterId : string, timestamp : Date, value : number) {
    let request: AddDatapointValueRequest = new AddDatapointValueRequest({
      meterId: meterId,
      timestamp: Timestamp.fromISOString(timestamp.toISOString()),
      value : value
    });

    return await this.rossakerExportClient.addDatapointValue(request, this.state.metadata).toPromise();
  }

  async createExportFile(exportId: string, exportTypeId: string, nodeId: string, nodeLabel : string, filename : string, customerId : string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: ExportFileRequest = new ExportFileRequest({
        exportId: exportId,
        nodeId : nodeId,
        nodeLabel : nodeLabel,
        exportTypeId : exportTypeId,
        customerId : customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.rossakerExportClient.exportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, filename);

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }
}
