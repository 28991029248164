import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource,
        DataSourceSQLServer,
        DataSourceDriver,
        DataSourcePostgreSQL,
        DataSourceMySQL,
        DataSourceMongoDB,
        XProjectorClient,
        DataSourceSQLite} from '../../XProjector/xprojector-client-service';

import * as uuid from 'uuid';
import { ClrLoadingState } from '@clr/angular';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';


@Component({
  selector: 'xproj-editdatasource',
  templateUrl: './xproj-editdatasource.component.html',
  styleUrls: ['./xproj-editdatasource.component.scss']
})
export class XProjEditdatasourceComponent implements OnInit {

  DataSourceDriver = DataSourceDriver;

  selectedDatasource : any = null;
  loadingDatasource = false;
  driverObject : any;
  datasourceId : string = "";
  newTag : string ="";
  newColTag : string = "";
  creatingNew : boolean = false;
  savingDatasource : ClrLoadingState = ClrLoadingState.DEFAULT;

  encoding_cp1252 : string = "cp1252";
  encoding_default : string  ="";

  public getCodeLanguage(): string
  {
    if(this.selectedDatasource["driver"] == DataSourceDriver.MongoDB)
      return "json";

    return "sql;"
  }

  constructor(private xprojClient: XProjectorClient,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService) { }


  async selectedColumnChange( event : any)
  {
    //await this.basequery( this.selectedProjection );
    //await this.queryColumns( this.selectedProjection["projectionid"] );
  }


  recreateDriverObject()
  {
    switch(this.selectedDatasource.driver)
    {
      case DataSourceDriver.SQLServer:
        this.driverObject = new DataSourceSQLServer();
        this.driverObject.datasourcesqlserverid = uuid.v4();
        this.driverObject.datasourceid = this.datasourceId;
        break;
      case DataSourceDriver.PostgreSQL:
          this.driverObject = new DataSourcePostgreSQL();
          this.driverObject.datasourcepostgresqlid = uuid.v4();
          this.driverObject.datasourceid = this.datasourceId;
          break;
      case DataSourceDriver.MySQL:
          this.driverObject = new DataSourceMySQL();
          this.driverObject.datasourcemysqlid = uuid.v4();
          this.driverObject.datasourceid = this.datasourceId;
          break;
      case DataSourceDriver.SQLite:
          this.driverObject = new DataSourceSQLite();
          this.driverObject.datasourcesqliteid = uuid.v4();
          this.driverObject.datasourceid = this.datasourceId;
          break;
      case DataSourceDriver.MongoDB:
        this.driverObject = new DataSourceMongoDB();
        this.driverObject.datasourcemongodbid = uuid.v4();
        this.driverObject.datasourceid = this.datasourceId;
        break;            
      }
  }

  async Save()
  {
    try
    {
      //debugger;
      this.savingDatasource = ClrLoadingState.LOADING;
      this.logger.info(this.selectedDatasource);
      this.logger.info("saving datasource");
      this.selectedDatasource =  await this.xprojClient.RequestSaveDatasource(this.selectedDatasource);
      this.logger.info("saving driver");
      switch(this.selectedDatasource.driver)
      {
        case DataSourceDriver.SQLServer:
          this.driverObject = await this.xprojClient.RequestSaveDatasourceSQLServer(this.driverObject);
          break;
        case DataSourceDriver.PostgreSQL:
          this.driverObject = await this.xprojClient.RequestSaveDatasourcePostgreSQL(this.driverObject);
          break;
        case DataSourceDriver.MySQL:
          this.driverObject = await this.xprojClient.RequestSaveDatasourceMySQL(this.driverObject);
          break;
        case DataSourceDriver.SQLite:
            this.driverObject = await this.xprojClient.RequestSaveDatasourceSQLite(this.driverObject);
            break;
        case DataSourceDriver.MongoDB:
          this.driverObject = await this.xprojClient.RequestSaveDatasourceMongoDB(this.driverObject);
          break;
  
      }

      if(this.creatingNew)
      {
        this.creatingNew= false;
        this.router.navigateByUrl(this.router.url.replace("new", this.datasourceId), {skipLocationChange:false});
      }

      this.savingDatasource = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDatasource = ClrLoadingState.ERROR;
    }
  }


  async Start()
  {
    if(this.datasourceId == 'new')
    {
      this.logger.info("creating new");
      this.creatingNew = true;
      this.selectedDatasource = new DataSource();
      this.datasourceId = uuid.v4();
      this.selectedDatasource.datasourceid = this.datasourceId;
      this.recreateDriverObject();
      return;
    }

    this.loadingDatasource = true;
    this.selectedDatasource = await this.xprojClient.RequestGetConfigDatasource(this.datasourceId);
    switch(this.selectedDatasource.driver)
    {
      case DataSourceDriver.SQLServer:
        this.driverObject = await this.xprojClient.RequestGetConfigDatasourceSQLServer(this.datasourceId);
        break;
      case DataSourceDriver.PostgreSQL:
          this.driverObject = await this.xprojClient.RequestGetConfigDatasourcePostgreSQL(this.datasourceId);
          break;
      case DataSourceDriver.MySQL:
        this.driverObject = await this.xprojClient.RequestGetConfigDatasourceMySQL(this.datasourceId);
        break;
      case DataSourceDriver.SQLite:
          this.driverObject = await this.xprojClient.RequestGetConfigDatasourceSQLite(this.datasourceId);
          break;
      case DataSourceDriver.MongoDB:
          this.driverObject = await this.xprojClient.RequestGetConfigDatasourceMongoDB(this.datasourceId);
          break;
      }
    this.loadingDatasource = false;

  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.datasourceId = params['id'];
      this.Start();
  });

  }

}
