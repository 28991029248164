export class RossakerBmsFacility {
  id : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;

  facilityType : string;
  externalId : string;
  prefix : string;
  area : number;
  size : number;
  floor : number;

  createdAt : Date;
  modifiedAt : Date;
}
