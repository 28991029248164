<h1 *ngIf="selectedProjection">Editing Projection <i>{{selectedProjection.name}}</i></h1>

<div class="alert alert-warning" role="alert" *ngIf="Columns && nrPrimarykeys!=1 && Columns.length > 0">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
      </div>
      <span class="alert-text" *ngIf="nrPrimarykeys==0">
        Make sure to use a <i>Primary key</i> in the projection.<br>
        The primary key is used to upsert data and required for data query entry points.
      </span>
      <span class="alert-text" *ngIf="nrPrimarykeys>1">
        Use only one (1) <i>Primary key</i> in the projection.<br>
      </span>
    </div>
  </div>
</div>

<clr-accordion [clrAccordionMultiPanel]="true">

  <clr-accordion-panel [clrAccordionPanelOpen]="true" *ngIf="selectedProjection">
    <clr-accordion-title>Basic configuration</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label>Projection ID </label>
          <input clrInput type="text" [(ngModel)]="selectedProjection.projectionid" name="projectionid" [disabled]="!creatingNew"
            size="35" />
        </clr-input-container>


        <clr-input-container>
          <label>Name </label>
          <input clrInput type="text" [(ngModel)]="selectedProjection.name" name="name" size="35" />
        </clr-input-container>
        <clr-input-container>
          <label>Description</label>
          <input clrInput type="text" [(ngModel)]="selectedProjection.description" name="description" size="35" />
        </clr-input-container>

        <div class="clr-form-control clr-row">
          <label for="newtag" class="clr-control-label clr-col-12 clr-col-md-2">Tags</label>
          <div class="clr-control-container clr-col-md-10 clr-col-12">
            <div class="clr-input-wrapper">
              <input type="text" size="30" name="newtag" [(ngModel)]="newTag" placeholder="New tag" class="clr-input">
              <button class="btn btn-link btn-sm" (click)="selectedProjection.tags.push(newTag);newTag='';">Add</button>
            </div>
            <span class="clr-subtext">
              <xproj-dynclr-mini [addablelabels]="true" [uselabels]="true" [removeablelabels]="true"
                [(data)]="selectedProjection.tags"></xproj-dynclr-mini>
            </span>
          </div>
        </div>

        <clr-toggle-container>
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="true" name="disabled" [(ngModel)]="selectedProjection.enabled" />
            <label>Enabled</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
      </form>

      <div class="btn-group btn-outline" style="margin-top: 2em;margin-bottom: 2em;">
        <button class="btn" (click)="Save(false)" [clrLoading]="savingProjection">Save</button>
        <!-- <button class="btn">Revert</button> -->
      </div>


    </clr-accordion-content>
  </clr-accordion-panel>


  <clr-accordion-panel *ngIf="selectedProjection">
    <clr-accordion-title *ngIf="!Columns">Columns configuration (Loading)</clr-accordion-title>
    <clr-accordion-title *ngIf="Columns">Columns configuration ({{Columns.length}})</clr-accordion-title>

    <clr-accordion-content>
      <xproj-editcolumndescription [(columns)]="Columns" [(nrprimarykeys)]="nrPrimarykeys"
        [projectionid]="projectionId"></xproj-editcolumndescription>
    </clr-accordion-content>

  </clr-accordion-panel>

  <clr-accordion-panel>
    <clr-accordion-title>Input projections ({{selectedProjections.length}})</clr-accordion-title>
    <clr-accordion-content>
      <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid [(clrDgSelected)]="selectedProjections" [clrDgLoading]="loadingProjections">

          <clr-dg-column [clrDgField]="'projection.projectionid'">
            <ng-container *clrDgHideableColumn="{hidden: false}">ProjectionD</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'projection.name'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'projection.description'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'projection.tags'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Tags</ng-container>
          </clr-dg-column>
          <clr-dg-column>
            <ng-container *clrDgHideableColumn="{hidden: false}">Scriptname</ng-container>
          </clr-dg-column>


          <clr-dg-row *clrDgItems="let proj of Projections; let i = index" [clrDgItem]="proj">
            <clr-dg-cell class="cellhideoverflow"> {{proj.projection.projectionid}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{proj.projection.name}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{proj.projection.description}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{proj.projection.tags}} </clr-dg-cell>
            <clr-dg-cell>
              <input class="clr-input" placeholder="" name="projscriptname_{{i}}" [(ngModel)]="proj.scriptname" />
            </clr-dg-cell>

          </clr-dg-row>

          <clr-dg-footer> Selected {{selectedProjections.length}} / {{Projections.length}} Projections</clr-dg-footer>

        </clr-datagrid>
      </div>

      <button class="btn" [clrLoading]="savingProjection" (click)="SaveDependencyProjections()">Save</button>
      <!-- <button class="btn">Revert</button> -->
    </clr-accordion-content>
  </clr-accordion-panel>

  <clr-accordion-panel *ngIf="selectedProjection?.dependencies?.remotes">
    <clr-accordion-title>Input remotes ({{selectedProjection.dependencies.remotes.length}})</clr-accordion-title>
    <clr-accordion-content>
      <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid [(clrDgSelected)]="selectedRemotes">

          <clr-dg-column> RemoteNodeID </clr-dg-column>
          <clr-dg-column> Scriptname </clr-dg-column>

          <clr-dg-row *ngFor="let remote of selectedProjection.dependencies.remotes; let i = index"
            [clrDgItem]="remote">
            <clr-dg-cell class="cellhideoverflow"> {{remote.remotenodeid}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{remote.scriptname}} </clr-dg-cell>
            <clr-dg-cell>
              <input class="clr-input" placeholder="" name="remotescriptname_{{i}}" [(ngModel)]="remote.scriptname" />
            </clr-dg-cell>

          </clr-dg-row>

          <clr-dg-footer> Selected {{selectedProjection.dependencies.remotes.length}} Remote Nodes</clr-dg-footer>

        </clr-datagrid>
        <form clrForm>
          <clr-input-container>
            <label>Manual remote node dependency</label>
            <input clrInput type="text" [(ngModel)]="ManualRemoteNodeID" name="projectionid"
              size="35" />
          </clr-input-container>
          </form>
          <button class="btn" [clrLoading]="savingProjection" (click)="AddManualRemoteNodeID()">Add manual</button>


      </div>

      <!-- <button class="btn btn-link" (click)="addConnector()">
                <clr-icon shape="plus-circle"></clr-icon>
                New Remote Dependency
            </button>
            <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver();">
                <clr-icon shape="minus-circle"></clr-icon>
                Remove Remote Dependency
            </button> -->

      <button class="btn" [clrLoading]="savingProjection" (click)="SaveDependencyRemotes()">Save</button>
      <!-- <button class="btn">Revert</button> -->
    </clr-accordion-content>
  </clr-accordion-panel>

  <clr-accordion-panel>
    <clr-accordion-title>Input data sources ({{selectedDataSources.length}})</clr-accordion-title>
    <clr-accordion-content>

      <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid [(clrDgSelected)]="selectedDataSources" [clrDgLoading]="loadingDatasources">

          <clr-dg-column [clrDgField]="'datasourceid'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Datasource ID</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'name'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
          </clr-dg-column>
          <clr-dg-column>
            <ng-container *clrDgHideableColumn="{hidden: false}">Driver</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'description'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'tags'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Tags</ng-container>
          </clr-dg-column>


          <clr-dg-row *clrDgItems="let ds of Datasources; let i = index" [clrDgItem]="ds">
            <clr-dg-cell class="cellhideoverflow"> {{ds.datasourceid}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{ds.name}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{ds.driver | xproj_prettydsdriver}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{ds.description}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{ds.tags}} </clr-dg-cell>

          </clr-dg-row>

          <clr-dg-footer> Selected {{selectedDataSources.length}} / {{Datasources.length}} Data sources</clr-dg-footer>

        </clr-datagrid>
      </div>

      <button class="btn" [clrLoading]="savingProjection" (click)="SaveDependencyDatasources()">Save</button>
      <!-- <button class="btn">Revert</button> -->

    </clr-accordion-content>
  </clr-accordion-panel>

  <clr-accordion-panel>
    <clr-accordion-title>Global Lua scripts ( {{ selectedProjection ? ((selectedProjection.prescriptlua == ''?0:1) +
      (selectedProjection.postscriptlua == ''?0:1)) :0 }} ) </clr-accordion-title>
    <clr-accordion-content *ngIf="selectedProjection">
      <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Pre-script (LUA)</label>
      <div class="clr-control-container clr-col-md-10 clr-col-12">
        <!-- [style.height.px]="selectedProjection.prescriptlua == '' ? 30 : 800"  -->
        <ngx-monaco-editor
        [style.height.px]="getEditorHeight(selectedProjection.prescriptlua)"
        name="editprescriptlua"
          [options]="editorOptionsLua" [(ngModel)]="selectedProjection.prescriptlua">
        </ngx-monaco-editor>
      </div>
      <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Post-script (LUA)</label>
      <div class="clr-control-container clr-col-md-10 clr-col-12">
        <!-- [style.height.px]="selectedProjection.postscriptlua == '' ? 30 : 800" -->
        <ngx-monaco-editor
        [style.height.px]="getEditorHeight(selectedProjection.postscriptlua)"
          name="editpostscriptlua" [options]="editorOptionsLua" [(ngModel)]="selectedProjection.postscriptlua">
        </ngx-monaco-editor>
      </div>

      <!-- TODO: Lua library stuff -->
      <br />
      <div class="btn-group btn-outline" style="margin-top: 2em;margin-bottom: 2em;">
        <button class="btn" (click)="Save(false)" [clrLoading]="savingProjection">Save</button>
        <!-- <button class="btn">Revert</button> -->
      </div>
    </clr-accordion-content>
  </clr-accordion-panel>

  <clr-accordion-panel>
    <clr-accordion-title>Executions ({{Executions.length}})</clr-accordion-title>
    <clr-accordion-content>

      <h3>Executions</h3>
      <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid [(clrDgSingleSelected)]="selectedExecution" [clrDgLoading]="loadingExecutions">

          <clr-dg-column> ID </clr-dg-column>
          <clr-dg-column> Entrypoint </clr-dg-column>
          <clr-dg-column> Default </clr-dg-column>

          <clr-dg-row *ngFor="let exec of Executions" [clrDgItem]="exec">
            <clr-dg-cell class="cellhideoverflow"> {{exec.execution.projectionexecutionid}} </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{exec.execution.entrypoint | xproj_prettyprojectionexecutionentry}}
            </clr-dg-cell>
            <clr-dg-cell class="cellhideoverflow"> {{exec.execution.isdefaultexecution}} </clr-dg-cell>

          </clr-dg-row>

          <!-- <clr-dg-footer> Selected {{selectedExecution.length}} / {{Executions.length}} Executions</clr-dg-footer>-->
        </clr-datagrid>
      </div>
      <button type="button" class="btn btn-link" (click)="NewExecution()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add execution
      </button>
      <button *ngIf="selectedExecution" type="button" class="btn btn-link" [clrLoading]="savingExecutions"
        (click)="RemoveExecution(selectedExecution); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove execution
      </button>


      <div *ngIf="selectedExecution">
        <form clrForm>
          <clr-select-container>
            <label>Entrypoint</label>
            <select clrSelect name="entrypointtype" [(ngModel)]="selectedExecution.execution.entrypoint"
              (change)="recreateEntryPoint()">
              <option [ngValue]="ProjectionExecutionEntry.DataSourceQuery">Datasource query</option>
              <option [ngValue]="ProjectionExecutionEntry.ProjectionCursor">Projection cursor</option>
              <option [ngValue]="ProjectionExecutionEntry.LuaMainFunction">Lua main function</option>
            </select>
          </clr-select-container>

          <clr-checkbox-container>
            <label>Default execution</label>
            <clr-checkbox-wrapper>
              <input clrCheckbox type="checkbox" value="true"
                [(ngModel)]="selectedExecution.execution.isdefaultexecution" name="isdefaultexecution" />
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
        </form>

        <clr-accordion style="padding-top: 1em;">
          <clr-accordion-panel *ngIf="Columns && Columns.length> 0">
            <clr-accordion-title>Execution - Grouping/Splitting
              ({{selectedExecution.execution.grouping.groupcolumns.length}})</clr-accordion-title>
            <clr-accordion-content>
              <div *ngIf="selectedExecution.execution.grouping">
                <h4>Input columns</h4>
                <div class="limit-height">
                  <clr-datagrid [(clrDgSingleSelected)]="selectedGroupColumn">
                    <clr-dg-column> Column </clr-dg-column>
                    <clr-dg-column> Transformation (Lua) </clr-dg-column>

                    <clr-dg-row *ngFor="let col of selectedExecution.execution.grouping.groupcolumns" [clrDgItem]="col">
                      <clr-dg-cell class="cellhideoverflow">
                        <div class="clr-select-wrapper">
                          <select class="clr-select" name="groupcolumnid"
                            [(ngModel)]="col.projectioncolumndescriptionid">
                            <option *ngFor="let column of Columns" [ngValue]="column.projectioncolumndescriptionid">
                              {{column.columnname}}</option>
                          </select>
                        </div>
                      </clr-dg-cell>
                      <clr-dg-cell>
                        <clr-input-container style="margin-top: 0px;">
                          <input clrInput placeholder="Transformation (Lua)"
                            name="luatransformnput{{col.projectioncolumndescriptionid}}"
                            [(ngModel)]="col.transformluascript" />
                        </clr-input-container>
                      </clr-dg-cell>

                    </clr-dg-row>

                    <clr-dg-footer>{{selectedExecution.execution.grouping.groupcolumns.length}} Input
                      columns</clr-dg-footer>

                  </clr-datagrid>
                </div>
                <button type="button" class="btn btn-link" (click)="NewGroupingColumnInput()">
                  <clr-icon shape="plus-circle"></clr-icon>
                  Add Input Column
                </button>
                <button *ngIf="selectedGroupColumn" type="button" class="btn btn-link"
                  (click)="RemoveGroupColumnInput(); ">
                  <clr-icon shape="minus-circle "></clr-icon>
                  Remove Input Column
                </button>

                <div *ngIf="selectedGroupColumn">
                  <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Transform group
                    column (Lua)</label>
                  <div class="clr-control-container clr-col-md-10 clr-col-12">
                    <!-- [style.height.px]="selectedGroupColumn.transformluascript == '' ? 30 : 800" -->
                    <ngx-monaco-editor
                    [style.height.px]="getEditorHeight(selectedGroupColumn.transformluascript )"
                      name="editcolumngrouptransform" [options]="editorOptionsLua"
                      [(ngModel)]="selectedGroupColumn.transformluascript">
                    </ngx-monaco-editor>
                  </div>
                </div>

              </div>

              <div *ngIf="selectedExecution.execution.grouping">
                <h4>Output columns</h4>
                <xproj-editcolumndescription [standalone]="false"
                  [(columns)]="selectedExecution.execution.grouping.outputcolumns"
                  [(nrprimarykeys)]="selectedGroupnrPrimarykeys"
                  [projectionid]="projectionId"></xproj-editcolumndescription>
                <button *ngIf="selectedExecution.execution.grouping.outputcolumns.length==0" type="button"
                  class="btn btn-link" (click)="ImportOutputColumnsFromColumns();">
                  <clr-icon shape="import"></clr-icon>
                  Import output columns from main projection
                </button>
                <hr style="height:1px; background-color: #FAFAFA;border: none;">
              </div>

              <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Transform column
                descriptions (Lua)</label>
              <div class="clr-control-container clr-col-md-10 clr-col-12">
                <!-- [style.height.px]="selectedExecution.execution.grouping.transformcolumndescriptionluascript == '' ? 30 : 800" -->
                <ngx-monaco-editor
                  [style.height.px]="getEditorHeight(selectedExecution.execution.grouping.transformcolumndescriptionluascript  )"
                  name="editcolumngenerationlua" [options]="editorOptionsLua"
                  [(ngModel)]="selectedExecution.execution.grouping.transformcolumndescriptionluascript">
                </ngx-monaco-editor>
              </div>

              <br>
              <button class="btn" [clrLoading]="savingExecutions"
                (click)="SaveExecution(selectedExecution)">Save</button>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>

        <div *ngIf="selectedExecution.execution.entrypoint==ProjectionExecutionEntry.DataSourceQuery">

          <h4>Data source queries</h4>
          <div class="limit-height" style="max-width:1200px;">
            <clr-datagrid [(clrDgSingleSelected)]="selectedExecutionDS" [clrDgLoading]="loadingExecutions">

              <clr-dg-column> ID </clr-dg-column>
              <clr-dg-column> Datasource </clr-dg-column>
              <clr-dg-column> Query </clr-dg-column>

              <clr-dg-row *ngFor="let execDS of selectedExecution.executionDS" [clrDgItem]="execDS">
                <clr-dg-cell class="cellhideoverflow"> {{execDS.projectionexecutiondatasourcequeryid}} </clr-dg-cell>
                <clr-dg-cell>
                  <clr-select-container style="margin-top: 0px; ">
                    <select clrSelect name="entrypointdatasource " [(ngModel)]="execDS.entrypointdatasourceid ">
                      <option value=" 'null' ">Not set</option>
                      <option *ngFor="let ds of selectedDataSources " [value]="ds.datasourceid ">{{ds.name}}</option>
                    </select>
                  </clr-select-container>
                </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow "> {{execDS.entrypointdatasourcequery}} </clr-dg-cell>

              </clr-dg-row>
            </clr-datagrid>
          </div>
          <button type="button " class="btn btn-link " (click)="NewExecutionDS(selectedExecution) ">
            <clr-icon shape="plus-circle "></clr-icon>
            Add datasource query
          </button>
          <button *ngIf="selectedExecution " type="button " class="btn btn-link " [clrLoading]="savingExecutions "
            (click)="RemoveExecutionDS(selectedExecution, selectedExecutionDS); ">
            <clr-icon shape="minus-circle "></clr-icon>
            Remove datasource query
          </button>


          <form clrForm *ngIf="selectedExecutionDS ">
            <clr-select-container>
              <label>Data source</label>
              <select clrSelect name="entrypointdatasource " [(ngModel)]="selectedExecutionDS.entrypointdatasourceid ">
                <option value=" 'null' ">Not set</option>
                <option *ngFor="let ds of selectedDataSources " [value]="ds.datasourceid ">{{ds.name}}</option>
              </select>
            </clr-select-container>

            <div class="clr-form-control clr-row "
              *ngIf="selectedExecutionDS.entrypointdatasourceid && selectedExecutionDS.executionentrypointdatasourceid!='null' ">
              <label for="entrypointquery " class="clr-control-label clr-col-12 clr-col-md-2 ">Query</label>
              <div class="clr-control-container clr-col-md-10 clr-col-12 ">
                <xproj-datasourcequeryprober #queryProber [(query)]="selectedExecutionDS.entrypointdatasourcequery "
                  [wrapinaccordion]="true "
                  [datasourceid]="selectedExecutionDS.entrypointdatasourceid "></xproj-datasourcequeryprober>

                <button class="btn " [clrLoading]="importingProjectionColumnsFromQuery "
                  (click)="ImportColumnsFromDatasourceQuery(queryProber) ">
                  <clr-icon shape="import "></clr-icon>Import columns from query</button>
              </div>
            </div>
          </form>
        </div>


        <div *ngIf="selectedExecution.execution.entrypoint==ProjectionExecutionEntry.LuaMainFunction">

          <h4>LUA Main Functions</h4>

          <div class="limit-height" style="max-width:1200px;">
            <clr-datagrid [(clrDgSingleSelected)]="selectedExecutionLMF" [clrDgLoading]="loadingExecutions">

              <clr-dg-column> ID </clr-dg-column>
              <clr-dg-column> Script </clr-dg-column>

              <clr-dg-row *ngFor="let execLMF of selectedExecution.executionLMF" [clrDgItem]="execLMF">
                <clr-dg-cell class="cellhideoverflow"> {{execLMF.projectionexecutionluamainfunctionid}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow "> {{execLMF.luascript | xproj_prettytruncatestring : 50}}... </clr-dg-cell>

              </clr-dg-row>
            </clr-datagrid>
          </div>
          <button type="button " class="btn btn-link " (click)="NewExecutionLMF(selectedExecution) ">
            <clr-icon shape="plus-circle "></clr-icon>
            Add LUA Main function
          </button>
          <button *ngIf="selectedExecution " type="button " class="btn btn-link" [clrLoading]="savingExecutions "
            (click)="RemoveExecutionLMF(selectedExecution, selectedExecutionLMF); ">
            <clr-icon shape="minus-circle "></clr-icon>
            Remove LUA Main function
          </button>

          <!-- [style.height.px]="selectedExecutionLMF.luascript == '' ? 30 : 800"  -->
          <!-- [style.height.px]="EditorLMF?EditorLMF.getContentHeight():0"  -->
          <!-- (onInit)="onInitEditorLuaMain($event)" -->
          <ngx-monaco-editor *ngIf="selectedExecutionLMF"
            [style.height.px]="getEditorHeight(selectedExecutionLMF.luascript)"
            name="editluamainfunction"
            [options]="editorOptionsLua" [(ngModel)]="selectedExecutionLMF.luascript">
          </ngx-monaco-editor>


        </div>

        <br />
        <button class="btn " [clrLoading]="savingProjection " (click)="SaveExecution(selectedExecution) ">Save</button>
        <button class="btn" (click)="RunNowTriggeredFromLMFSection=true;RunProjection()" [clrLoading]="runningProjection"
        [disabled]="nrPrimarykeys!=1 || !selectedProjection.enabled">Run now</button>


        <div *ngIf="RunNowTriggeredFromLMFSection">
          <div class="clr-row" *ngFor="let item of projectionExecutionLog | keyvalue">
            <div class="clr-col-4">
              <span>{{item.key}}</span>
            </div>
            <div class="clr-col-8">
              <xproj-dynclr-mini [title]="item.key" [data]="item.value"></xproj-dynclr-mini>
            </div>
          </div>

          <button class="btn" (click)="RunNowTriggeredFromLMFSection=false ">Close log</button>
        </div>
      </div>
    </clr-accordion-content>


  </clr-accordion-panel>


  <clr-accordion-panel *ngIf="ShowImportFromFile">
    <clr-accordion-title>Import from file</clr-accordion-title>
    <clr-accordion-content>
      <h3>Import from file</h3>
      <p>Here you can import data from file. Start by selecting file.</p>

      <div *ngIf="!Columns || Columns.length==0">
        Error: No columns configured
      </div>

      <div class="dropzone" *ngIf="Columns&&Columns.length > 0">
        <ngx-file-drop dropZoneLabel="Drop Excel file here." (onFileDrop)="onImportDataExcel($event)">
            Drop Excel file here.
        </ngx-file-drop>
        </div>
        <div *ngIf="ExcelImportLog!=''">
          <pre>{{ExcelImportLog}}</pre>
      </div>
      <div *ngIf="ExcelImportError!=''">
            Error importing columns from excel file: {{ExcelImportError}}
        </div>

      <!-- <input id="fileimportexcel" type="file" class="btn btn-sm btn-secondary" [multiple]="false" accept="Excel/*,.xlsx"
        (change)="onImportDataExcel($event)" hidden />
      <label class="btn btn-sm btn-secondary" for="fileimportexcel">
        <clr-icon shape="import"></clr-icon> Import from Excel
      </label> -->

      <!-- <input id="fileimportcsv" type="file" class="btn btn-sm btn-secondary" [multiple]="false" accept="CSV/*,.csv"
        (change)="onImportDataCSV($event)" hidden />
      <label class="btn btn-sm btn-secondary" for="fileimportcsv">
        <clr-icon shape="import"></clr-icon> Import from CSV
      </label> -->

      <div>
        <h4>Configure columns</h4>
      </div>
    </clr-accordion-content>
  </clr-accordion-panel>
  <!-- <clr-accordion-panel *ngFor="let execution of Executions ">
        <clr-accordion-title>Execution - Entry point ({{execution.execution.entrypoint | xproj_prettyprojectionexecutionentry}})</clr-accordion-title>
        <clr-accordion-content>

            <form clrForm>
                <clr-select-container>
                    <label>Entrypoint</label>
                    <select clrSelect name="entrypointtype " [(ngModel)]="execution.execution.entrypoint ">
                     <option [ngValue]="ProjectionExecutionEntry.DataSourceQuery ">Datasource query</option>
                      <option [ngValue]="ProjectionExecutionEntry.ProjectionCursor ">Projection cursor</option>
                      <option [ngValue]="ProjectionExecutionEntry.LuaMainFunction ">Lua main function</option>
                    </select>
                </clr-select-container>

            </form>

            <form clrForm *ngFor="let execDS of execution.executionDS ">
                <clr-select-container>
                    <label>Data source</label>
                    <select clrSelect name="entrypointdatasource " [(ngModel)]="execDS.entrypointdatasourceid ">
                        <option value=" 'null' ">Not set</option>
                        <option *ngFor="let ds of selectedDataSources " [value]="ds.datasourceid ">{{ds.name}}</option>
                  </select>
                </clr-select-container>

                <div class="clr-form-control clr-row " *ngIf="execDS.entrypointdatasourceid && execDS.executionentrypointdatasourceid!='null' ">
                    <label for="entrypointquery " class="clr-control-label clr-col-12 clr-col-md-2 ">Query</label>
                    <div class="clr-control-container clr-col-md-10 clr-col-12 ">
                        <xproj-datasourcequeryprober #queryProber [(query)]="execDS.entrypointdatasourcequery " [wrapinaccordion]="true " [datasourceid]="execDS.entrypointdatasourceid "></xproj-datasourcequeryprober>

                        <button class="btn " [clrLoading]="importingProjectionColumnsFromQuery " (click)="ImportColumnsFromDatasourceQuery(queryProber) ">
                            <clr-icon shape="import "></clr-icon>Import columns from query</button>
                    </div>
                </div>

            </form>

            <button class="btn " [clrLoading]="savingProjection " (click)="SaveExecution(execution) ">Save</button>
        </clr-accordion-content>


    </clr-accordion-panel> -->

  <!--
    <clr-accordion-panel *ngIf="selectedProjection && Columns && Columns.length> 0">
                                <clr-accordion-title>Execution - Grouping/Splitting ({{selectedProjection.execution.grouping.groupcolumns.length}})</clr-accordion-title>
                                <clr-accordion-content>
                                    <div *ngIf="selectedProjection.execution.grouping">
                                        <h4>Input columns</h4>
                                        <div class="limit-height">
                                            <clr-datagrid [(clrDgSingleSelected)]="selectedGroupColumn">
                                                <clr-dg-column> Column </clr-dg-column>
                                                <clr-dg-column> Transformation (Lua) </clr-dg-column>

                                                <clr-dg-row *ngFor="let col of selectedProjection.execution.grouping.groupcolumns" [clrDgItem]="col">
                                                    <clr-dg-cell class="cellhideoverflow">
                                                        <div class="clr-select-wrapper">
                                                            <select class="clr-select" name="groupcolumnid" [(ngModel)]="col.projectioncolumndescriptionid">
                                        <option *ngFor="let column of Columns" [ngValue]="column.projectioncolumndescriptionid">{{column.columnname}}</option>
                                    </select>
                                                        </div>
                                                    </clr-dg-cell>
                                                    <clr-dg-cell>
                                                        <clr-input-container style="margin-top: 0px;">
                                                            <input clrInput placeholder="Transformation (Lua)" name="luatransformnput{{col.projectioncolumndescriptionid}}" [(ngModel)]="col.transformluascript" />
                                                        </clr-input-container>
                                                    </clr-dg-cell>

                                                </clr-dg-row>

                                                <clr-dg-footer>{{selectedProjection.execution.grouping.groupcolumns.length}} Input columns</clr-dg-footer>

                                            </clr-datagrid>
                                        </div>
                                        <button type="button" class="btn btn-link" (click)="NewGroupingColumnInput()">
                    <clr-icon shape="plus-circle"></clr-icon>
                    Add Input Column
                </button>
                                        <button *ngIf="selectedGroupColumn" type="button" class="btn btn-link" (click)="RemoveGroupColumnInput(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove Input Column
                </button>

                                        <div *ngIf="selectedGroupColumn">
                                            <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Transform group column (Lua)</label>
                                            <div class="clr-control-container clr-col-md-10 clr-col-12">
                                                <ngx-monaco-editor style="height: 400px;" name="editcolumngrouptransform" [options]="editorOptionsLua" [(ngModel)]="selectedGroupColumn.transformluascript">
                                                </ngx-monaco-editor>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="selectedProjection.execution.grouping">
                                        <h4>Output columns</h4>
                                        <xproj-editcolumndescription [standalone]="false" [(columns)]="selectedProjection.execution.grouping.outputcolumns" [(nrprimarykeys)]="selectedGroupnrPrimarykeys" [projectionid]="projectionId"></xproj-editcolumndescription>
                                        <button *ngIf="selectedProjection.execution.grouping.outputcolumns.length==0" type="button" class="btn btn-link" (click)="ImportOutputColumnsFromColumns();">
                    <clr-icon shape="import"></clr-icon>
                    Import output columns from main projection
                </button>
                                        <hr style="height:1px; background-color: #FAFAFA;border: none;">
                                    </div>

                                    <label for="editcolumngenerationlua" class="clr-control-label clr-col-12 clr-col-md-4">Transform column descriptions (Lua)</label>
                                    <div class="clr-control-container clr-col-md-10 clr-col-12">
                                        <ngx-monaco-editor style="height: 400px;" name="editcolumngenerationlua" [options]="editorOptionsLua" [(ngModel)]="selectedProjection.execution.grouping.transformcolumndescriptionluascript">
                                        </ngx-monaco-editor>
                                    </div>

                                    <br>
                                    <button class="btn" [clrLoading]="savingProjection" (click)="Save(false)">Save</button>
                                </clr-accordion-content>
    </clr-accordion-panel> -->

  <clr-accordion-panel>
    <clr-accordion-title>Scheduling</clr-accordion-title>
    <clr-accordion-content>

      <h4>Modify current scheduling</h4>

      <form clrForm *ngIf="selectedProjection">
        <clr-toggle-container>
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="true" name="schedulingautocollect"
              [(ngModel)]="selectedProjection.scheduling.autocollect" />
            <label>Auto collect</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>

        <clr-select-container>
          <label>Scheduling queue</label>
          <select clrSelect name="schedulingqueue" [(ngModel)]="selectedProjection.schedulingqueue">
            <option *ngFor="let queue of SchedulingQueues" [ngValue]="queue">{{queue}}</option>
          </select>
        </clr-select-container>

        <clr-select-container>
          <label>Data path</label>
          <select clrSelect name="datapath" [(ngModel)]="selectedProjection.projectiondatapath">
            <option *ngFor="let path of DataPaths" [ngValue]="path">{{path}}</option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label>Minimum interval betweel collection (Seconds) </label>
          <input clrInput type="number" [(ngModel)]="selectedProjection.scheduling.minintervalseconds"
            name="schedulingminintervalseconds" size="35" />
        </clr-input-container>

        <clr-input-container>
          <label>Approximation - Maximum rows (fifo) 0 = unlimited </label>
          <input clrInput type="number" [(ngModel)]="selectedProjection.scheduling.maxrows" name="schedulingmaxrows"
            size="35" />
        </clr-input-container>

        <clr-date-container>
          <label>Override last projected</label>
          <input type="date" clrDate name="lastprojectedutc" [(clrDate)]="selectedProjection.lastprojectedutc">
        </clr-date-container>

      </form>
      <button class="btn" [clrLoading]="savingProjection" (click)="Save(true)">Save</button>

      <h4>Manual execution</h4>
      <button class="btn" (click)="RunProjection()" [clrLoading]="runningProjection"
        [disabled]="nrPrimarykeys!=1 || !selectedProjection.enabled">Run now</button>

      <div class="clr-row" *ngFor="let item of projectionExecutionLog | keyvalue">
        <div class="clr-col-4">
          <span>{{item.key}}</span>
        </div>
        <div class="clr-col-8">
          <xproj-dynclr-mini [title]="item.key" [data]="item.value"></xproj-dynclr-mini>
        </div>
      </div>

    </clr-accordion-content>
  </clr-accordion-panel>
  <clr-accordion-panel>
    <clr-accordion-title>Reset data</clr-accordion-title>
    <clr-accordion-content>
      <h4>Reset projection data</h4>
      <button class="btn" (click)="ResetProjectionData()" [clrLoading]="runningResetProjectionData">Reset projection
        data (Clears all data)</button>

      <h4>Reset projection group data</h4>
      <form clrForm>
        <div class="clr-form-control clr-row">
          <div class="clr-control-container clr-col-md-10 clr-col-12">
            <div class="clr-input-wrapper">
              <xproj-group-selection #groupSelect *ngIf="selectedProjection" [projection]="selectedProjection"
                [selected]="resetProjectionDataGroups" (groupSelected)="selectedResetGroupChange($event)">
              </xproj-group-selection>
            </div>
            <!-- <span class="clr-subtext">
              <xproj-dynclr-mini [addablelabels]="false" [uselabels]="true" [removeablelabels]="false"
                [(data)]="resetProjectionDataGroups"></xproj-dynclr-mini>
            </span> -->
          </div>
        </div>

        <button class="btn" style="margin-top: 2em;" [disabled]="!resetProjectionDataGroups || resetProjectionDataGroups.length == 0"
          (click)="ResetProjectionGroupData()" [clrLoading]="runningResetProjectionData">Reset projection group data
          (Clears all group data)</button>

      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
