import { Component, OnInit, ViewChild } from '@angular/core';
import { EditTreenodeComponent } from '@xprojectorfeatures/xconf/components/edit-treenode/edit-treenode.component';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { RossakerBmsLorawanMultiMeter } from '@core/models/rossaker-bms-lorawan-multimeter';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType, SearchNodesRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, XprojAlertService } from 'xproj-lib';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { RossakerLorawanDeviceInfo } from '@app/core/models/rossaker-lorawan-device-info';
import * as XLSX from 'xlsx';

export class RossakerBmsLorawanMetersInfo {
  id : string;
  devEui : string;
  name : string;
  provisioned : boolean;
  lastActiveString : string;
  snr : string;
  spf : number;
  rssi : number;
  externalId : string;
  multiMeterType : string;
  appKey : string;
  createdAtString: string;
  modifiedAtString : string;
}

@Component({
  selector: 'app-rossaker-bms-lorawan-meters-group-data-collector-view',
  templateUrl: './rossaker-bms-lorawan-meters-group-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-lorawan-meters-group-data-collector-view.component.scss']
})
export class RossakerBmsLorawanMetersGroupDataCollectorViewComponent implements OnInit, RossakerBmsDataCollectorComponent {

  @ViewChild("editNode", { read: EditTreenodeComponent, static: false }) editNode: EditTreenodeComponent;

  public static NodeTypeId : string = '_x_lorawan_group';

  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  lorawanMultiMeters : RossakerBmsLorawanMultiMeter[] = [];
  lorawanDeviceInfos : RossakerLorawanDeviceInfo[] = [];
  loadingMeters : boolean = false;
  selectedMultiMeter : RossakerBmsLorawanMultiMeter;

  lorawanMultiMetersInfos : RossakerBmsLorawanMetersInfo[] = [];

  allNodeTypes: GrpcNodeType[] = [];
  currentEditNodeId : string;
  currentEditNode : GrpcNode;
  showEditModal : boolean = false;

  constructor(
    private xconfClient: XProjectorXConfClient,
    private rossakerAdminService : RossakerBmsAdminService,
    public dateHelper: DateHelper,
    private alertService: XprojAlertService
  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    await this.update();
  }

  setWidth(width: number) {

  }

  async update() {
    try {
      this.loadingMeters = true;
      this.lorawanMultiMeters  = await this.rossakerAdminService.getLorawanMultiMeters(this.data.node.id);
      this.lorawanDeviceInfos  = await this.rossakerAdminService.getLoraWANDeviceInfos(this.lorawanMultiMeters.map(x => x.devEui));

      this.lorawanMultiMetersInfos = [];
      this.lorawanMultiMeters.forEach(meter => {
        let deviceInfo = this.lorawanDeviceInfos.find(x => x.deveui == meter.devEui);
        let lorawanMultiMetersInfo : RossakerBmsLorawanMetersInfo = new RossakerBmsLorawanMetersInfo();
        lorawanMultiMetersInfo.id = meter.id;
        lorawanMultiMetersInfo.devEui = meter.devEui;
        lorawanMultiMetersInfo.name = meter.name;
        lorawanMultiMetersInfo.provisioned = meter.provisioned;
        lorawanMultiMetersInfo.lastActiveString = deviceInfo?.modifiedAt ? this.dateHelper.utils.formatByString(deviceInfo?.modifiedAt, 'yyyy-MM-dd HH:mm:ss') : '---';
        lorawanMultiMetersInfo.externalId = meter.externalId;
        lorawanMultiMetersInfo.multiMeterType = meter.multiMeterType;
        lorawanMultiMetersInfo.appKey = meter.appKey;
        lorawanMultiMetersInfo.createdAtString = this.dateHelper.utils.formatByString(meter.createdAt, 'yyyy-MM-dd HH:mm:ss');
        lorawanMultiMetersInfo.modifiedAtString = this.dateHelper.utils.formatByString(meter.modifiedAt, 'yyyy-MM-dd HH:mm:ss');
        lorawanMultiMetersInfo.spf = deviceInfo?.spreadingfactor ?? -1;
        lorawanMultiMetersInfo.snr = deviceInfo?.snr.toFixed(1) ?? '-1';
        lorawanMultiMetersInfo.rssi = deviceInfo?.rssi ?? -1;

        this.lorawanMultiMetersInfos.push(lorawanMultiMetersInfo);
      });
    }
    finally {
      this.loadingMeters = false;
    }
  }

  async editMultiMeter() {
    if (this.selectedMultiMeter) {
      let node = await this.xconfClient.getNode(
        this.selectedMultiMeter.id,
        '_x_lorawan_multimeter');
      if (node) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentEditNodeId = node.id;
        this.currentEditNode = node;
        this.showEditModal = true;
      }
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xconfClient.updateNode(node, oldId, '', this.data.customer.id);
    return result.result;
  }

  async saveEditNode() {
    if (this.editNode) {
      this.editNode.savePropertyValues();
      let result = await this.updateNode(this.currentEditNode, this.currentEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
        this.update();
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditModal = false;
  }

  closeEditNode() {
    this.showEditModal = false;
  }

  async addMultiMeter() {

  }

  async exportMultiMeters() {
    var headers: string[] = ['devEui', 'name', 'provisioned', 'lastActiveString', 'externalId', 'rssi', 'spf', 'snr', 'multiMeterType', 'appKey', 'createdAtString', 'modifiedAtString', 'id'];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lorawanMultiMetersInfos, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SensorInfo');

    XLSX.writeFile(wb, 'lorawansensors.xlsx');
  }

  async importMultiMeters() {

  }

  async refresh() {
    await this.update();
  }
}
