import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectionColumnDescription, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';

import * as uuid from 'uuid';
import { ClrLoadingState } from '@clr/angular';
import { XProjDatasourcequeryproberComponent } from '../datasourcequeryprober/xproj-datasourcequeryprober.component';
import { ClipboardService } from 'ngx-clipboard'
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { environment } from 'src/environments/environment';
import { NgxFileDropEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';


@Component({
  selector: 'xproj-editcolumndescription',
  templateUrl: './xproj-editcolumndescription.component.html',
  styleUrls: ['./xproj-editcolumndescription.component.scss']
})
export class XprojEditcolumndescriptionComponent implements OnInit
{
  
  editorOptionsLua = { theme: environment.editortheme, 
    language: 'lua',
     automaticLayout: true, 
     acceptSuggestionOnEnter: "smart", 
     minimap: { enabled: false },
     ariaLabel: "arne weise",
     scrollBeyondLastLine: false
    };

    getEditorHeight(str:string)
    {
      const lines = (str.match(/\n/g) || '').length + 1;
      return lines * 19 + 10;
    }

  XDataType = XDataType;
  projectionId = "";
  newColTag : string = "";
  @Input() set projectionid (id :string)
  {
    if(this.projectionId ==id)
      return;
    this.projectionId = id;
    this.LoadColumns();
  }
  loadingProjectionColumns : boolean = false;
  Columns : Array<ProjectionColumnDescription> = new Array<ProjectionColumnDescription>();
  @Input() set columns (Columns : Array<ProjectionColumnDescription>)
  {
    if(Columns)
    {
      this.Columns = Columns;
    }
  }
  @Output() columnsChange =new EventEmitter<Array<ProjectionColumnDescription> >();

  nrPrimarykeys :number = 0;
  @Input() set nrprimarykeys(ignore: number)
  {
    this.nrPrimarykeys = 0;
    for(let i = 0; i < this.Columns.length; i++)
    {
      if(this.Columns[i].primarykey)
        this.nrPrimarykeys++;
    }
    this.nrprimarykeysChange.emit(this.nrPrimarykeys);
  }
  @Output() nrprimarykeysChange = new EventEmitter<number>();


  savingRemoveProjectionColumn : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingProjectionColumn : ClrLoadingState = ClrLoadingState.DEFAULT;

  selectedColumn : any = null;

  @Input() standalone : boolean = true;

  onEditorInit() {
    //this.loadingEditor = false;
    // Manual triggering of change  detection is required to stop showing loading spinner
    //this.changeDetectorRef.detectChanges();
  }


  constructor(private xprojClient: XProjectorClient,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private clipboard: ClipboardService
    )
  {

  }

  public dropExcelEnabled = false;

  static  isDate(dateStr:string): boolean 
  {
    return !this.isNum(dateStr) && !isNaN(new Date(dateStr).getDate());
  }
  static  isNum(numStr:string): boolean 
  {
    return !isNaN(new Number(numStr) as number);
  }

  public showRemoveAllColumns = false;

  removeAll()
  {
    this.showRemoveAllColumns = true;
  }

  async doRemoveAllColumns()
  {
    console.log("removing all!!");    
    await this.xprojClient.RequestResetConfigProjectionData(this.projectionId);
    for(let i of this.Columns)
    {
      await this.xprojClient.RequestRemoveConfigProjectionColumn(i.projectioncolumndescriptionid);
    }
    this.Columns.length = 0;
  }

  public ExcelImportError:string = '';

  async dropped(files: NgxFileDropEntry[]) {

    if(this.Columns.length != 0)
    {
      this.ExcelImportError = "Cannot import columns if columns already exist";
      return;
    }

    this.ExcelImportError = '';
    if (files.length !== 1) {
      this.ExcelImportError = 'Cannot use multiple files';
      return;
    }
    const droppedFile = files[0];

    if (!droppedFile.fileEntry.isFile)
    {    
      this.ExcelImportError = "Not a file";
      return;
    }
    
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) =>
      {     
        let buff = await file.arrayBuffer();

        const wb: XLSX.WorkBook = XLSX.read(buff, { type: 'binary' });    

        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        
        const data = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}); // generate objects
        console.log("XLSX data", data);
        // Get column names
        if(data.length < 1)
        {
          this.ExcelImportError = "No Rows";
          return;
        }
        let columnnames = [];
        let columntypes = {};
        for(let col of (data as string[])[0])
        {
          columnnames.push(col);
        }

        console.log("Columns found", columnnames);

        // Find types
        for(let i = 0; i < columnnames.length; i++)
        {
          let m_number = true;
          let m_string = true;
          let m_timestamp = true;
          for(let j = 1; j < data.length; j++)
          {
            let d = (data[j] as any[]);

            let x = d[i];

            console.log(x + " is date: ", XprojEditcolumndescriptionComponent.isDate(x));
            console.log(x + " is num: ", XprojEditcolumndescriptionComponent.isNum(x));
            m_timestamp = m_timestamp && XprojEditcolumndescriptionComponent.isDate(x);
            m_number = m_number && XprojEditcolumndescriptionComponent.isNum(x);
                        
            console.log(columnnames[i] + "[" + j.toString() + "] = ", { 'number': m_number, 'string': m_string, 'timestamp': m_timestamp });
          }
          if(m_timestamp)
          {
            columntypes[columnnames[i]] = XDataType.Timestamp;
          }
          else if(m_number)
          {
            columntypes[columnnames[i]] = XDataType.Float64;
          }
          else
          {
            columntypes[columnnames[i]] = XDataType.String
          }
        }  
        console.log("columntypes", columntypes);          

        this.Columns.length =0;
        this.nrPrimarykeys = 0;

        for(let i of columnnames)
        {          
          let col = new ProjectionColumnDescription();
          col.projectioncolumndescriptionid = uuid.v4();
          col.projectionid = this.projectionId;
          col.columnname = i;

          col.datatype = columntypes[i]

          let cols = await this.xprojClient.RequestSaveProjectionColumnDescription(col);

          this.Columns.push(cols);
        }
        this.columnsChange.emit(this.Columns);
        this.nrprimarykeysChange.emit(this.nrPrimarykeys);
      });


    this.dropExcelEnabled = false;
  }


  generateFromExcel() : void
  {
    this.dropExcelEnabled = true;
  }

  saveAll() : void
  {
    for(let col of this.columns)
    {
      this.SaveColumnDescription(col);
    }
  }

  generateLuaReadCode() : void
  {
    let luaread = "";
    let luaread2 = "";

    for (let i = 0; i < this.Columns.length; i++)
    {
      let get_type = "";
      let column = this.Columns[i];
      if(i > 0)
      {
        luaread += "\n";
        luaread2 += "\n";
      }
      luaread += "q:add_col(\"" + column.columnname + "\",\"" + column.columnname+ "\")";

      switch(column.datatype)
      {
        case XDataType.Timestamp:
          get_type="get_timestamp";
          break;
        case XDataType.String:
          get_type="set_string";
          break;
        default:
          get_type="get_numerical";
          break;
      }
      
      luaread2 += "res:" + get_type+ "(row,\"" + column.columnname +"\")";
    }

    this.clipboard.copy(luaread + "\n\n" + luaread2);    
  }
  generateLuaWriteCode() : void
  {
    let luawrite = "";
    for (let i = 0; i < this.Columns.length; i++)
    {
      if(i > 0)
        luawrite += "\n";

      let column = this.Columns[i];
      let val = "";
      let set_type = "";
      switch(column.datatype)
      {
        case XDataType.Timestamp:
          val = "now()";
          set_type="set_timestamp";
          break;
        case XDataType.String:
          val = "\"val\"";
          set_type="set_string";
          break;
        default:
          val = "0";
          set_type="set_numerical";
          break;
      }
      luawrite += "output." + set_type +"(\"" + column.columnname + "\", " + val + " )";
    }
    luawrite += "\noutput.write()";
    this.clipboard.copy(luawrite);

  }

  async RemoveColumn(col : ProjectionColumnDescription)
  {
    try
    {
      this.savingRemoveProjectionColumn = ClrLoadingState.LOADING;
      if(this.standalone)
      {
        let resp = await this.xprojClient.RequestRemoveConfigProjectionColumn(col.projectioncolumndescriptionid);
      }
      this.savingRemoveProjectionColumn = ClrLoadingState.SUCCESS;
      for(let i = 0; i < this.Columns.length; i++)
      {
        if(this.Columns[i] == this.selectedColumn)
        {
          if(this.Columns[i].primarykey)
          {
            this.nrPrimarykeys--;
            if(this.nrPrimarykeys < 0)
              this.nrPrimarykeys = 0;
          }
          this.Columns.splice(i,1);
          break;
        }
      }
      this.selectedColumn =null;
    }
    catch
    {
      this.savingRemoveProjectionColumn = ClrLoadingState.ERROR;
    }

    this.columnsChange.emit(this.Columns);
    this.nrprimarykeysChange.emit(this.nrPrimarykeys);

  }

  NewColumn()
  {
    let newCol = new ProjectionColumnDescription();
    newCol.projectioncolumndescriptionid = uuid.v4();
    newCol.projectionid = this.projectionId;
    this.Columns.push(newCol);
  }

  async ImportColumnsFromQuery()
  {

  }
  async SaveColumnDescription( col : ProjectionColumnDescription )
  {
    this.logger.debug(col);
    try
    {
      this.nrPrimarykeys = 0;
      for(let i = 0; i < this.Columns.length; i++)
      {
        if(this.Columns[i].primarykey)
          this.nrPrimarykeys++;
      }
      if(this.standalone)
      {
        this.savingProjectionColumn = ClrLoadingState.LOADING;
        col = await this.xprojClient.RequestSaveProjectionColumnDescription(col);
        this.savingProjectionColumn = ClrLoadingState.SUCCESS;
      }
    }
    catch
    {
      this.savingProjectionColumn = ClrLoadingState.ERROR;
    }

    this.logger.info("emitting nrprimarykeyschange to: " + this.nrPrimarykeys.toString());
    this.columnsChange.emit(this.columns);
    this.nrprimarykeysChange.emit(this.nrPrimarykeys);

  }

  async ImportColumnsFromDatasourceQuery( prober : XProjDatasourcequeryproberComponent )
  {
      let createdColumns = await prober.getColumns();
      let s  = new Set<string>();
      for(let i = 0; i < createdColumns.length; i++)
      {
        s.add( createdColumns[i].columnname );
      }
      this.logger.debug(s);
      this.logger.debug("s size: " + s.size.toString());
      this.logger.debug("createdColumns.length: " + createdColumns.length.toString());
      if(s.size != createdColumns.length)
      {
        throw new Error("Non-unique column names");
      }

      for(let i = 0; i < this.Columns.length; i++)
      {
        await this.xprojClient.RequestRemoveConfigProjectionColumn(this.Columns[i].projectioncolumndescriptionid);
      }

      this.Columns.length =0;
      for(let i = 0; i < createdColumns.length; i++)
      {
        let col = createdColumns[i];
        col.projectioncolumndescriptionid = uuid.v4();
        col.projectionid = this.projectionId;
        createdColumns[i] = await this.xprojClient.RequestSaveProjectionColumnDescription(col);
        this.Columns.push(createdColumns[i]);
      }

      this.columnsChange.emit(this.Columns);
      this.nrprimarykeysChange.emit(this.nrPrimarykeys);
  }

  async LoadColumns()
  {
    if(!this.standalone)
      return;

    this.nrPrimarykeys = 0;
    this.loadingProjectionColumns = true;
    let columns = await this.xprojClient.RequestListConfigProjectionColumns(this.projectionId,0, 500);
    this.logger.debug('columns', columns);
    this.Columns.length = 0;
    for(let i = 0; i < columns.length; i++)
    {
      if(columns[i].primarykey)
        this.nrPrimarykeys++;
      this.Columns.push(columns[i]);
    }

    this.columnsChange.emit(this.Columns);
    this.nrprimarykeysChange.emit(this.nrPrimarykeys);

    this.loadingProjectionColumns = false;
  }

  ngOnInit(): void
  {
  }

}
