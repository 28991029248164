import { RossakerBmsRealestate } from './rossaker-bms-realestate';
import { RossakerBmsBuilding } from './rossaker-bms-building';
import { RossakerBmsBuildingAddress } from './rossaker-bms-buildingaddress';
import { RossakerBmsApartment } from './rossaker-bms-apartment';
import { RossakerBmsFacility } from './rossaker-bms-facility';
import { RossakerBmsMeter } from './rossaker-bms-meter';
import { RossakerBmsGateway } from './rossaker-bms-gateway';
import { RossakerBmsMeterData } from './rossaker-bms-meter-data';
import { RossakerBmsLatestValue } from './rossaker-bms-latestvalue';

export class RossakerBmsCustomerData {
  id : number = -1;
  customerId : string;

  realestates : RossakerBmsRealestate[] = [];
  buildings : RossakerBmsBuilding[] = [];
  buildingAddresses : RossakerBmsBuildingAddress[] = [];
  apartments : RossakerBmsApartment[] = [];
  facilities : RossakerBmsFacility[] = [];
  meters : RossakerBmsMeter[] = [];
  gateways : RossakerBmsGateway[] = [];
  latestValues : RossakerBmsLatestValue[] = [];

  meterDatas : RossakerBmsMeterData[] = [];
}
