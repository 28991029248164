import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { GroupSelectionTypes, OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { BaseControllerConfig, ComboboxMemberConfig, XprojOutputComboboxControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { WidgetUtils } from '../../../../../../utils/widget-utils-service';
import { Aggregation, XDataType, XProjectorClient } from '../../../../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../../../../../widgets/widget-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-combobox-controller',
  templateUrl: './xproj-output-combobox-controller.component.html',
  styleUrls: ['./xproj-output-combobox-controller.component.scss']
})
export class XprojOutputComboboxControllerComponent extends XprojOutputControllerBase implements OnInit, AfterViewInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;
  @Input() widget: WidgetBase;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      this.init();
    }
    this._initDone = value;
  }

  comboboxConfig: XprojOutputComboboxControllerConfig;

  value : any;
  _valueMember: ComboboxMemberConfig;
  get valueMember(): ComboboxMemberConfig {
    return this._valueMember;
  }
  set valueMember(memberConfig : ComboboxMemberConfig) {
    this._valueMember = memberConfig;
    switch (this.comboboxConfig.DataType) {
      case OutputDataType.String:
        this.value = this._valueMember.ValueString;
        break;
      case OutputDataType.Timestamp:
        this.value = this._valueMember.ValueDate;
        break;
      default:
        this.value = this._valueMember.ValueNumber;
        break;
    }
  }

  projectionVValues: { value: any, dateType: OutputDataType, id: string }[];

  loading: boolean = false;

  projectionIndex: number = -1;
  projectionLabels: string[] = [];
  projectionOutputs: { value: any, dataType: OutputDataType, id: string }[];

  projectionItems: { index: number, label: string }[] = [];
  selectedProjectionItem: { index: number, label: string };
  oldSelectedProjectionItem: { index: number, label: string };

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService,
    private xprojClient: XProjectorClient,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }


  ngOnInit(): void {
    this.comboboxConfig = this.config as XprojOutputComboboxControllerConfig;
  }

  ngAfterViewInit(): void {

  }

  async init() {
    if (!this.useApplyButton) {
      if (this.comboboxConfig.ProjectionData) {
        await this.load();
        if (this.comboboxConfig.ProjectionDataSearchable) {
          if (this.projectionItems.length > 0) {
            this.selectedProjectionItem = this.projectionItems[0];
            this.comboboxClicked();
          }
        }
        else {
          if (this.projectionLabels.length > 0) {
            this.projectionIndex = 0;
            this.comboboxClicked();
          }
        }
      }
      else {
        this.comboboxConfig.Members.forEach(member => {
          if (member.Default) {
            this._valueMember = member;
            switch (this.comboboxConfig.DataType) {
              case OutputDataType.String:
                this.value = member.ValueString;
                break;
              case OutputDataType.Timestamp:
                this.value = member.ValueDate;
                break;
              default:
                this.value = member.ValueNumber;
                break;
            }

            this.comboboxClicked();
          }
        });
      }
    }
  }

  async onUpdateQuery() {
    if (this.comboboxConfig.ProjectionData) {
      await this.load();
      this.cdr.detectChanges();
      if (this.comboboxConfig.ProjectionDataSearchable) {
        if (this.projectionItems.length > 0) {
          this.selectedProjectionItem = this.projectionItems[0];
          this.comboboxClicked();
        }
      }
      else {
        if (this.projectionLabels.length > 0) {
          this.projectionIndex = 0;
          this.comboboxClicked();
        }
      }
    }
  }

  comboboxClicked() {
    if (!this.useApplyButton) {
      this.widgetService.outputParametersChanged(this.getOutputChangeParameters());
    }
  }

  comboboxChanged($event) {
    if (this.selectedProjectionItem) {
      this.comboboxClicked();
    }
  }

  comboboxOpenChanged(open: boolean) {
    if (open) {
      this.oldSelectedProjectionItem = this.selectedProjectionItem;
      this.selectedProjectionItem = null;
    }
    else if (!this.selectedProjectionItem) {
      this.selectedProjectionItem = this.oldSelectedProjectionItem;
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let result = [];
    if (this.comboboxConfig.ProjectionData) {
      if (this.comboboxConfig.ProjectionDataSearchable) {
        this.projectionIndex = this.selectedProjectionItem?.index ?? -1;
      }

      if (this.projectionIndex > -1) {
        let defaultOutput = this.projectionIndex == 0 && this.comboboxConfig.ProjectionDataAddEmptyValue;
        let index = this.comboboxConfig.ProjectionDataAddEmptyValue ? this.projectionIndex - 1 : this.projectionIndex;
        this.projectionOutputs.forEach(outputParameters => {
          let output = new WidgetOutputChangeParameters();
          output.widgetId = this.widgetConfig.Id;
          output.outputParameterId = outputParameters.id;
          output.datatype = outputParameters.dataType;
          output.value = defaultOutput ? undefined : outputParameters.value[index];

          result.push(output);
        });
      }
    }
    else {
      let output = new WidgetOutputChangeParameters();
      output.widgetId = this.widgetConfig.Id;
      output.outputParameterId = this.comboboxConfig.Id;
      output.datatype = this.comboboxConfig.DataType;
      output.value = this.value;

      let outputDisplayName = new WidgetOutputChangeParameters();
      outputDisplayName.widgetId = this.widgetConfig.Id;
      outputDisplayName.outputParameterId = this.comboboxConfig.Id + '_label';
      outputDisplayName.datatype = OutputDataType.String;
      outputDisplayName.value = this.valueMember?.Label;

      result.push(output);
      result.push(outputDisplayName);
    }


    return result;
  }

  async load(forcereload: boolean = false) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.cdr.detach();
    let newValues = [];
    try {
      if (!this.widget?.inputParametersHasValue(true)) {
        //console.log('not inputParametersHasValue');
      }
      else {
        let query = this.comboboxConfig.ConfigQuery.Query.Clone();

        let i = 0;
        query.columns.forEach(col => {
          if (i > 0 || this.comboboxConfig.ConfigQuery.UseGrouping) {
            let columnConfig = this.comboboxConfig.ConfigQuery.DataConfigs[this.comboboxConfig.ConfigQuery.UseGrouping ? i + 1 : i];
            if (columnConfig.UseAggregationInputParameter) {
              col.columnaggregation = this.widget.getParameterValue(columnConfig.AggregationInputParameterId, col.columnaggregation).value;
            }
          }
          i++;
        });

        //Transformation input parameters
        if (this.comboboxConfig.ConfigQuery.UseGrouping && this.comboboxConfig.ConfigQuery.UseTransformInputParameter) {
          query.grouping.columntransformation = this.widget.getParameterValue(this.comboboxConfig.ConfigQuery.TransformInputParameterId, this.comboboxConfig.ConfigQuery.GroupingTransform).value;
        }

        // Aggregation input parameters
        i = 0;
        query.columns.forEach(col => {
          let dataConfig = this.comboboxConfig.ConfigQuery.DataConfigs[i];
          if (dataConfig.UseAggregationInputParameter) {
            col.columnaggregation = this.widget.getParameterValue(dataConfig.AggregationInputParameterId, col.columnaggregation).value;
          }
          i++;
        });

        //Projection input parameters
        if (this.comboboxConfig.ConfigQuery.UseProjectionInputParameter) {
          query.targetprojectionid = this.widget.getParameterValue(this.comboboxConfig.ConfigQuery.ProjectionInputParameterId, this.comboboxConfig.ConfigQuery.Query.targetprojectionid).value;
        }

        //Group input parameters
        if (this.comboboxConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
          query.targetgroup = this.widget.getParameterValue(this.comboboxConfig.ConfigQuery.GroupInputParameterId, this.comboboxConfig.ConfigQuery.Query.targetgroup).value;
        }
        else if (this.comboboxConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
          query.targetgroup = [];
          this.comboboxConfig.ConfigQuery.GroupInputParameterIds.forEach(id => {
            query.targetgroup.push(this.widget.getParameterValue(id, '').value + '');
          });
        }

        WidgetUtils.SetQueryFilterValues(query, this.comboboxConfig.ConfigQuery.DataFilters, (i, d) => this.widget.getParameterValue(i, d));
        // WidgetUtils.AddQueryTimeframe(query,
        //   this.widget.fromZoom ?? this.from,
        //   this.toZoom ?? this.to,
        //   this.comboboxConfig.ConfigQuery.timestampColumnName,
        //   this.useRelativeTimestamp ? this.relativeTimestamp : null);

        let scol = query.columns.find(c => c.columnname == this.comboboxConfig.ConfigQuery.DefaultSortColumnName);
        if (scol) {
          if (this.comboboxConfig.ConfigQuery.UseGrouping) {
            query.sorting.columnname = scol.columnoutname;
          }
          else {
            query.sorting.columnname = scol.columnname;
          }
          query.sorting.descending = this.comboboxConfig.ConfigQuery.DefaultSortDescending;
        }

        query.maxitems = this.comboboxConfig.ConfigQuery.MaxItems;

        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'comboboxoutput', this.config.Name);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        this.projectionLabels = [];
        this.projectionItems = [];
        this.projectionOutputs = [];
        if (this.comboboxConfig.ProjectionDataAddEmptyValue) {
          this.projectionLabels.push(this.comboboxConfig.ProjectionDataEmptyValueLabel);
        }

        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let datatype = it.datatype;
          let data: any;
          if (datatype == XDataType.Number) {
            data = numericaldata[it.indexintypedvector]
          }
          else if (datatype == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          else if (datatype == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          let colname = it.columnoutname;
          let dataConfig = this.comboboxConfig.ConfigQuery.DataConfigs.find(c => c.ColumnOutName == colname);

          if (dataConfig) {
            if (dataConfig.IncludeInOutput) {
              let output = {
                value: data, dataType: (dataConfig.Transform != Aggregation.NONE
                  && dataConfig.Transform != Aggregation.MIN
                  && dataConfig.Transform != Aggregation.MAX
                  && dataConfig.Transform != Aggregation.FIRST
                  && dataConfig.Transform != Aggregation.LAST) ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[dataConfig.Datatype]),
                id: dataConfig.Id
              };
              this.projectionOutputs.push(output);
            }

            if (dataConfig.ColumnName == this.comboboxConfig.ConfigQuery.ColumnNameLabel) {
              this.projectionLabels = this.projectionLabels.concat(data);

              if (this.comboboxConfig.ProjectionDataSearchable) {
                let i = 0;
                this.projectionLabels.forEach(label => this.projectionItems.push({ index: i++, label: label }));
              }
            }
          }
        }

      }
    }
    catch (err) {
      this.logger.debug('Combobox output error: ' + err);
    }
    finally {
      this.loading = false;
      this.cdr.reattach();
    }

  }

  // customerSelectOpenChange(open: boolean) {
  //   if (open) {
  //     this.oldSelectedCustomerItem = this.selectedCustomerItem;
  //     this.selectedCustomerItem = null;
  //   }
  //   else if (!this.selectedCustomerItem) {
  //     this.selectedCustomerItem = this.oldSelectedCustomerItem;
  //   }

  // }

}
