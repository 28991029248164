import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ChartWidgetConfig, ChartWidgetQuery, LineConfig, QueriedLineConfig, YAxisId } from './xproj-chart-widget-config-service';
import { XprojChartWidgetQueryConfigComponent } from '../chart-widget-query-config/xproj-chart-widget-query-config.component';
import { Projection, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { LegendAlignment, LegendPosition, OutputDataType, WidgetConfig, WidgetInputParameter } from '../../widget-config-service';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Subscription } from 'rxjs';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { TypedJSON } from 'typedjson';
import { Guid } from '../../../utils/guid-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';

@Component({
  selector: 'xproj-chart-widget-config',
  templateUrl: './xproj-chart-widget-config.component.html',
  styleUrls: ['./xproj-chart-widget-config.component.scss']
})
export class XprojChartWidgetConfigComponent extends WidgetConfigBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(XprojChartWidgetQueryConfigComponent, { read: XprojChartWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojChartWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: ChartWidgetConfig;

  projections: Projection[] = [];

  lineConfigs : LineConfig[] = [];
  selectedLine : LineConfig;

  queriedLineConfigs : QueriedLineConfig[] = [];
  selectedQueryLine : QueriedLineConfig;


  YAxisId = YAxisId;
  OutputDataType = OutputDataType;
  LegendPosition = LegendPosition;
  LegendAlignment = LegendAlignment;

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
    this.updateInputParameters();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as ChartWidgetConfig;

    let i = 1;
    this.widgetConfig.ConfigQueries.forEach(q => {
      if (q.Name.length == 0) {
        q.Name = 'Query ' + i;
      }
      i++;
    });

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.config.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
    this.widgetConfig.LineConfigs.forEach(line =>{
      if (line.LineDash.length == 0) {
        line.LineDash = [0, 0];
      }
      this.lineConfigs.push(TypedJSON.parse(TypedJSON.stringify(line, LineConfig), LineConfig));
    });
    this.widgetConfig.QueriedLineConfigs.forEach(line =>{
      if (line.LineDash.length == 0) {
        line.LineDash = [0, 0];
      }
      this.queriedLineConfigs.push(TypedJSON.parse(TypedJSON.stringify(line, QueriedLineConfig), QueriedLineConfig));
    });
  }

  addConfigQuery(): void {
    let query = new ChartWidgetQuery();
    query.Name = 'Query ' + (this.widgetConfig.ConfigQueries.length + 1);
    this.widgetConfig.ConfigQueries.push(query);
  }

  onDuplicateQuery(query: ChartWidgetQuery) {
    let newQuery  = TypedJSON.parse(TypedJSON.stringify(query, ChartWidgetQuery), ChartWidgetQuery);
    newQuery.Id = Guid.newGuid();
    newQuery.Name = 'Query ' + (this.widgetConfig.ConfigQueries.length + 1);
    this.widgetConfig.ConfigQueries.push(newQuery);
  }

  onQueryRemoved(query: ChartWidgetQuery) {
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
  }

  public async onSaveConfig() {
    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    await ArrayUtils.AsyncForEach(this.widgetQueryConfigs.toArray(), async (queryConfig : XprojChartWidgetQueryConfigComponent) => {
      await queryConfig.SaveQuery();
    });

    this.lineConfigs.forEach(line => {
      if (line.LineDash[0] == 0 || line.LineDash[1] == 0) {
        line.LineDash = [];
      }
    });
    this.queriedLineConfigs.forEach(line => {
      if (line.LineDash[0] == 0 || line.LineDash[1] == 0) {
        line.LineDash = [];
      }
    });


    this.widgetConfig.LineConfigs = this.lineConfigs;
    this.widgetConfig.QueriedLineConfigs = this.queriedLineConfigs;
  }

  addQueriedLine() {
    this.queriedLineConfigs.push(new QueriedLineConfig());
  }

  deleteSelectedQueriedLine() {
    this.queriedLineConfigs = this.queriedLineConfigs.filter(line => line != this.selectedQueryLine);
    this.selectedLine = null;
  }

  addLine() {
    this.lineConfigs.push(new LineConfig());
  }

  deleteSelectedLine() {
    this.lineConfigs = this.lineConfigs.filter(line => line != this.selectedLine);
    this.selectedLine = null;
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }
}
