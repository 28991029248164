import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-scriptinghelp',
  templateUrl: './xproj-scriptinghelp.component.html',
  styleUrls: ['./xproj-scriptinghelp.component.scss']
})
export class XProjScriptinghelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
