import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { BmsBuildingEditorComponent } from './components/bms-building-editor/bms-building-editor.component';
import { FloorplanEditorComponent } from './components/bms-floorplan-editor/bms-floorplan-editor.component';
import { FilterBuildingAddressPipe } from './pipes/filter-buildingaddress.pipe';

@NgModule({
  declarations: [
    BmsBuildingEditorComponent,
    FloorplanEditorComponent,
    FilterBuildingAddressPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreSharedModule
  ],
  exports: [
    BmsBuildingEditorComponent,
    FloorplanEditorComponent,
    FilterBuildingAddressPipe
  ]

})
export class BimModule { }
