import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';

@Component({
  selector: 'app-edit-treenodes',
  templateUrl: './edit-treenodes.component.html',
  styleUrls: ['./edit-treenodes.component.scss']
})
export class EditTreenodesComponent implements OnInit {

  _nodes: GrpcNode[];
  @Input()
  get nodes(): GrpcNode[] {
    return this._nodes;
  }
  set nodes(nodes: GrpcNode[]) {
    this._nodes = nodes;
    this.initProperties();
  }

  @Input() nodeTypes: GrpcNodeType[] = [];

  @Output() onSaveTreeNodes = new EventEmitter<GrpcNode[]>();

  nodeType: GrpcNodeType;
  editNode : GrpcNode;

  constructor() { }

  ngOnInit(): void {
  }

  async initProperties() {
    if (this.nodes?.length > 0) {
      this.nodeType = this.nodeTypes?.find(n => n.id == this.nodes[0].nodeTypeId);



    }
  }
}
