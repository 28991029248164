import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { max } from 'rxjs/operators';
import { Projection, XProjectorClient } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj-listprojections',
  templateUrl: './xproj-listprojections.component.html',
  styleUrls: ['./xproj-listprojections.component.scss'],

  host: {
    class: 'content-container'
  }
})

//encapsulation: ViewEncapsulation.None,

export class XProjListprojectionsComponent implements OnInit {

  projections = [];
  loadingProjections = false;
  selectedProjection: any = null;

  collapsed = false;

  showRemoveProjectionDialog: boolean = false;

  sizeOptions = [10, 20, 50, 100];

  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private xprojClient: XProjectorClient) {

  }

  async selectedProjectionChange(event: any) {
    //await this.basequery( this.selectedProjection );
    //await this.queryColumns( this.selectedProjection["projectionid"] );
  }

  async loadProjections() {
    this.loadingProjections = true;

    this.projections = await this.xprojClient.RequestListConfigProjections(0, 5000).catch((reason) =>
      this.projections = []
    );

    this.loadingProjections = false;
  }

  async ngOnInit() {
    this.loadProjections();
  }

  async setSelectedProjectionEnabled(enable: boolean) {
    if (this.selectedProjection) {
      let old = this.selectedProjection.enabled;
      this.selectedProjection.enabled = enable;
      let ok = await this.xprojClient.RequestSaveProjection(this.selectedProjection, false);
      if (!ok) {
        this.selectedProjection.enabled = old;
      }
    }
  }

  async doRemoveSelectedProjection() {
    if (this.selectedProjection) {
      let ok = await this.xprojClient.RequestRemoveConfigProjection(this.selectedProjection.projectionid);
      if (ok) {
        this.projections = this.projections.filter(p => p != this.selectedProjection);
        this.selectedProjection = null;
      }
    }
  }

}
