<clr-datagrid [(clrDgSingleSelected)]="selected" class="grid limit-width">
  <clr-dg-column>Id</clr-dg-column>
  <clr-dg-column>Name</clr-dg-column>

  <clr-dg-row *ngFor="let dataSourceInstance of dataSourceInstances; let i = index" [clrDgItem]="dataSourceInstance">
    <clr-dg-cell>
      {{dataSourceInstance.id}}
    </clr-dg-cell>

    <clr-dg-cell>
      {{dataSourceInstance.name}}
    </clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>

<button type="button" class="btn btn-link" (click)="addDataSourceInstance()">
  <clr-icon shape="plus-circle"></clr-icon>
  Add data source instance
</button>
<button *ngIf="selected" type="button" class="btn btn-link" (click)="deleteSelected()">
  <clr-icon shape="minus-circle"></clr-icon>
  Remove selected data source
</button>

<div *ngIf="selected">
  <div class="card limit-width">
    <div class="card-header">
      Edit data source instance ({{selected.name || selected.id}})
    </div>

    <div class="card-block">
      <form clrForm>
        <clr-input-container>
          <label>Id</label>
          <input clrInput placeholder="" name="id" [(ngModel)]="selected.id" />
        </clr-input-container>

        <clr-select-container>
          <label>Data source definition</label>
          <select clrSelect name="datasourcedefselect" [(ngModel)]="selected.dataSourceDefinitionId">
            <option *ngFor="let dataSourceDefinition of dataSourceDefinitions" [ngValue]="dataSourceDefinition.id">
              {{dataSourceDefinition.id}}</option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label>Name</label>
          <input clrInput placeholder="" name="name" [(ngModel)]="selected.name" />
        </clr-input-container>
      </form>

      <button class="btn" (click)="saveSelected()">Save</button>
    </div>
  </div>
</div>
