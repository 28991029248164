<clr-main-container>
    <app-alert></app-alert>

    
    <clr-header class="header-6">
        <div class="branding">
            <a *ngIf="state.isAdminUser" [routerLink]="['/']" class="nav-link">
                <img src="{{logo}}" class="logocls" />
                <span class="title" *ngIf="title != ''">{{title}}</span>
            </a>
            <a *ngIf="!state.isAdminUser" [routerLink]="['/']" class="nav-link">
                <img src="{{logo}}" class="logocls" />
            </a>
        </div>

        <!-- style="padding-left:36px;" -->
        <div class="header-nav" [clr-nav-level]="1" style="margin-left: 1em;"  >
            <!-- <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'top' }" class="nav-link nav-text boldtext"> Home </a> -->
            <a [routerLink]="['/solutions']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'top' }" class="nav-link nav-text boldtext"> Solutions </a>
            <a [routerLink]="['/systems']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'top' }" class="nav-link nav-text boldtext"> Systems </a>
            <a [routerLink]="['/products']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve', fragment: 'top' }" class="nav-link nav-text boldtext"> Products </a>
            <a [routerLink]="['/about']" routerLinkActive="active" class="nav-link nav-text boldtext"> About </a>
            <a [routerLink]="['/contact']" routerLinkActive="active" class="nav-link nav-text boldtext"> Contact </a>
            <a [routerLink]="['/support']" routerLinkActive="active" class="nav-link nav-text boldtext"> Support </a>
        </div>
    </clr-header>

    <router-outlet>
    </router-outlet>

 
    <div class="footer">        
        <div style="display: inline-block;">
            <p style="float:left; padding-left: 1em;">
                <b>© ROSSAKER 2023 </b><br>
                Org# 559288-6872<br>
                VAT: SE559288687201 <br>
                <a routerLink="/contact">Contact us</a>
            </p>            
            <div style="float: left; margin: 0; padding: 0;">
                <p style="float:left; padding-left: 1em;">
                    <b>Gothenburg</b><br>
                    Datavägen 14A<br>
                    436 32 Askim<br>
                    Sweden
                </p>            
                <p style="float:left; padding-left: 1em;">
                    <b>Stockholm</b><br>
                    Borgmästargatan 12<br>
                    116 29 Stockholm<br>
                    Sweden
                </p> 
            </div>
        </div> 
    </div>
</clr-main-container>