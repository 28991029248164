<clr-modal [(clrModalOpen)]="showEditModal">
    <h3 class="modal-title">Edit node</h3>
    <div class="modal-body">
        <app-edit-treenode #editNode *ngIf="showEditModal && currentEditNode" [node]="currentEditNode"
            [nodeTypes]="allNodeTypes"></app-edit-treenode>

    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" (click)="closeEditNode()">Cancel</button>
        <button class="btn btn-primary" (click)="saveEditNode()">Save</button>
    </div>
</clr-modal>

<clr-datagrid [(clrDgSingleSelected)]="selectedMultiMeter" [clrDgLoading]="loadingMeters"
    class="datagrid-compact meterGrid">
    <clr-dg-action-bar>
        <div class="btn-group">
            <button class="btn btn-sm btn-secondary" (click)="editMultiMeter()" [disabled]="!selectedMultiMeter">
                <clr-icon shape="pencil" directions="left"></clr-icon> Edit
            </button>
            <!-- <button class="btn btn-sm btn-secondary" (click)="addMultiMeter()">
        <clr-icon shape="plus" directions="left"></clr-icon> Add...
      </button> -->
        </div>

        <div class="btn-group">
            <button class="btn btn-sm btn-secondary" (click)="exportMultiMeters()">
                <clr-icon shape="export" directions="left"></clr-icon> Export
            </button>
            <!-- <button type="button" class="btn btn-sm btn-secondary" (click)="importMultiMeters()">
                <clr-icon shape="import"></clr-icon> Import...
            </button> -->
        </div>

        <div class="btn-group">
            <button class="btn btn-sm btn-secondary" (click)="refresh()">
                <clr-icon shape="refresh" directions="left"></clr-icon> Refresh
            </button>
        </div>
    </clr-dg-action-bar>

    <clr-dg-column [clrDgField]="'id'" [style.min-width.px]="200">
        <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'devEui'" [style.min-width.px]="150">
        <ng-container *clrDgHideableColumn="{hidden: false}">DevEui</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'externalId'" [style.min-width.px]="150">
      <ng-container *clrDgHideableColumn="{hidden: false}">External Id</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'provisioned'">
        <ng-container *clrDgHideableColumn="{hidden: false}">Provisioned</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'lastActiveString'" [style.min-width.px]="200">
        <ng-container *clrDgHideableColumn="{hidden: false}">Last active</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'rssi'">
      <ng-container *clrDgHideableColumn="{hidden: false}">RSSI</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'spf'">
      <ng-container *clrDgHideableColumn="{hidden: false}">SPF</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'snr'">
      <ng-container *clrDgHideableColumn="{hidden: false}">SNR</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'name'" [style.min-width.px]="150">
      <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'multiMeterType'" [style.min-width.px]="100">
        <ng-container *clrDgHideableColumn="{hidden: false}">MultiMeterType</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'appKey'" [style.min-width.px]="200">
        <ng-container *clrDgHideableColumn="{hidden: false}">AppKey</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'createdAtString'" [style.min-width.px]="150">
        <ng-container *clrDgHideableColumn="{hidden: false}">Created At</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'modifiedAtString'" [style.min-width.px]="150">
        <ng-container *clrDgHideableColumn="{hidden: false}">Modified At</ng-container>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let item of lorawanMultiMetersInfos; let i = index" [clrDgItem]="item">
        <clr-dg-cell class="left">{{item.id}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.devEui}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.externalId}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.provisioned}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.lastActiveString}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.rssi}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.spf}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.snr}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.name}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.multiMeterType}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.appKey}}</clr-dg-cell>
        <clr-dg-cell class="left">{{item.createdAtString}}
        </clr-dg-cell>
        <clr-dg-cell class="left">{{item.modifiedAtString}}
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="20">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                Meters</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
        </clr-dg-pagination>
    </clr-dg-footer>

</clr-datagrid>
