import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Aggregation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { XprojWidgetService } from '../../xproj-widget-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { BaseControllerConfig, OutputControllerWidgetConfig, OutputTimeType, XprojOutputAggregationControllerConfig, XprojOutputButtonControllerConfig, XprojOutputComboboxControllerConfig, XprojOutputProjectionControllerConfig, XprojOutputRadioButtonControllerConfig, XprojOutputTimeControllerConfig, XprojOutputToggleControllerConfig, XprojOutputTransformControllerConfig } from './xproj-output-controller-widget-config-service';
import { XprojOutputRangeControllerConfig } from './xproj-output-controller-widget-config-service';
import { OutputDataType, WidgetOutputParameter } from '../../widget-config-service';
import { timeStamp } from 'console';

@Component({
  selector: 'xproj-output-controller-widget-config',
  templateUrl: './xproj-output-controller-widget-config.component.html',
  styleUrls: ['./xproj-output-controller-widget-config.component.scss']
})
export class XprojOutputControllerWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  widgetConfig: OutputControllerWidgetConfig;

  workingCopy: OutputControllerWidgetConfig;
  selectedController: BaseControllerConfig;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {

  }

  async ngOnInit() {
    this.widgetConfig = this.config as OutputControllerWidgetConfig;

    this.workingCopy = this.widgetConfig.Clone();
  }

  addController(type: string) {
    switch (type) {
      case "Range":
        this.workingCopy.Controllers.push(new XprojOutputRangeControllerConfig());
        break;
      case "Buttons":
        this.workingCopy.Controllers.push(new XprojOutputButtonControllerConfig());
        break;
      case "Combobox":
        this.workingCopy.Controllers.push(new XprojOutputComboboxControllerConfig());
        break;
      case "Aggregation":
        this.workingCopy.Controllers.push(new XprojOutputAggregationControllerConfig());
        break;
      case "Transform":
        this.workingCopy.Controllers.push(new XprojOutputTransformControllerConfig());
        break;
      case "Projection":
        this.workingCopy.Controllers.push(new XprojOutputProjectionControllerConfig());
        break;
      case "Time":
        this.workingCopy.Controllers.push(new XprojOutputTimeControllerConfig());
        break;
      case "Toggle":
        this.workingCopy.Controllers.push(new XprojOutputToggleControllerConfig());
        break;
      case "RadioButtons":
        this.workingCopy.Controllers.push(new XprojOutputRadioButtonControllerConfig());
        break;

    }
  }

  removeSelectedController() {
    let i = this.workingCopy.Controllers.findIndex(c => c == this.selectedController);
    if (i > -1) {
      this.removeController(i);
    }

    this.selectedController = null;
  }

  removeController(index: number) {
    ArrayUtils.RemoveItemAt(this.workingCopy.Controllers, index);
  }


  moveControllerUp(index: number) {
    ArrayUtils.MoveItemUp(this.workingCopy.Controllers, index);
    this.refreshColumnConfigs();
  }

  moveControllerDown(index: number) {
    ArrayUtils.MoveItemDown(this.workingCopy.Controllers, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.workingCopy.Controllers];
    this.workingCopy.Controllers = [];
    this.cdr.detectChanges();
    this.workingCopy.Controllers = copy;
  }

  async onSaveConfig() {
    this.widgetConfig.Controllers = [];
    this.widgetConfig.OutputParameters = [];
    this.widgetConfig.UseApplyButton = this.workingCopy.UseApplyButton;
    this.widgetConfig.ApplyButtonText = this.workingCopy.ApplyButtonText;
    this.workingCopy.Controllers.forEach(c => {
      // if (c.TypeName == 'Time') {
      //   this.widgetConfig.UseApplyButton = true;
      // }
      this.widgetConfig.Controllers.push(c);
      this.addOutParameters(c);
    });
  }

  addOutParameters(config: BaseControllerConfig) {
    switch (config.TypeName) {
      case 'Range':
        let rangeConfig: XprojOutputRangeControllerConfig = config as XprojOutputRangeControllerConfig;
        let output = new WidgetOutputParameter();
        output.id = rangeConfig.Id;
        output.datatype = OutputDataType.Number;
        output.name = rangeConfig.Name;
        this.widgetConfig.OutputParameters.push(output);
        break;

      case 'Buttons':
        let buttonConfig: XprojOutputButtonControllerConfig = config as XprojOutputButtonControllerConfig;
        let buttonOutput = new WidgetOutputParameter();
        buttonOutput.id = buttonConfig.Id;
        buttonOutput.datatype = buttonConfig.DataType;
        buttonOutput.name = buttonConfig.Name;
        this.widgetConfig.OutputParameters.push(buttonOutput);

        if (buttonConfig.DataType == OutputDataType.RelativeTimestamp) {
          buttonConfig.Name2 = buttonConfig.Name + ' offset';
          buttonConfig.DataType2 = OutputDataType.Int32;

          let buttonOutput2 = new WidgetOutputParameter();
          buttonOutput2.id = buttonConfig.Id + '_2';
          buttonOutput2.datatype = buttonConfig.DataType2;
          buttonOutput2.name = buttonConfig.Name2;
          this.widgetConfig.OutputParameters.push(buttonOutput2);

          buttonConfig.Name3 = buttonConfig.Name + ' offsettype';
          buttonConfig.DataType3 = OutputDataType.RelativeOffsettype;

          let buttonOutput3 = new WidgetOutputParameter();
          buttonOutput3.id = buttonConfig.Id + '_3';
          buttonOutput3.datatype = buttonConfig.DataType3;
          buttonOutput3.name = buttonConfig.Name3;
          this.widgetConfig.OutputParameters.push(buttonOutput3);
        }
        break;

      case 'Combobox':
        let comboboxConfig: XprojOutputComboboxControllerConfig = config as XprojOutputComboboxControllerConfig;

        if (comboboxConfig.ProjectionData && comboboxConfig.ConfigQuery.DataConfigs?.length > 0) {
          comboboxConfig.ConfigQuery.DataConfigs.forEach(dataConfig => {
            let comboboxOutput = new WidgetOutputParameter();
            comboboxOutput.id = dataConfig.Id;
            comboboxOutput.datatype = (dataConfig.Transform != Aggregation.NONE
              && dataConfig.Transform != Aggregation.MIN
              && dataConfig.Transform != Aggregation.MAX
              && dataConfig.Transform != Aggregation.FIRST
              && dataConfig.Transform != Aggregation.LAST) ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[dataConfig.Datatype]);
            comboboxOutput.name = dataConfig.Label?.length > 0 ? dataConfig.Label : dataConfig.ColumnName;
            this.widgetConfig.OutputParameters.push(comboboxOutput);
          });
        }
        else if (comboboxConfig.Members?.length > 0) {
          let comboboxOutput = new WidgetOutputParameter();
          comboboxOutput.id = comboboxConfig.Id;
          comboboxOutput.datatype = comboboxConfig.DataType;
          comboboxOutput.name = comboboxConfig.Name;
          this.widgetConfig.OutputParameters.push(comboboxOutput);

          let comboboxOutputLabel = new WidgetOutputParameter();
          comboboxOutputLabel.id = comboboxConfig.Id + '_label';
          comboboxOutputLabel.datatype = OutputDataType.String;
          comboboxOutputLabel.name = comboboxConfig.Name + '-label';
          this.widgetConfig.OutputParameters.push(comboboxOutputLabel);
        }
        break;

      case 'Aggregation':
        let aggregationConfig: XprojOutputAggregationControllerConfig = config as XprojOutputAggregationControllerConfig;
        let aggregationOutput = new WidgetOutputParameter();
        aggregationOutput.id = aggregationConfig.Id;
        aggregationOutput.datatype = OutputDataType.Aggregation;
        aggregationOutput.name = aggregationConfig.Name;
        this.widgetConfig.OutputParameters.push(aggregationOutput);
        break;

      case 'Transform':
        let transformConfig: XprojOutputTransformControllerConfig = config as XprojOutputTransformControllerConfig;
        let transformOutput = new WidgetOutputParameter();
        transformOutput.id = transformConfig.Id;
        transformOutput.datatype = OutputDataType.Transformation;
        transformOutput.name = transformConfig.Name;
        this.widgetConfig.OutputParameters.push(transformOutput);
        break;

      case 'Projection':
        let projectionConfig: XprojOutputProjectionControllerConfig = config as XprojOutputProjectionControllerConfig;
        let projectionOutput = new WidgetOutputParameter();
        projectionOutput.id = projectionConfig.Id;
        projectionOutput.datatype = OutputDataType.Projection;
        projectionOutput.name = projectionConfig.Name;
        this.widgetConfig.OutputParameters.push(projectionOutput);

        let groupOutput = new WidgetOutputParameter();
        groupOutput.id = projectionConfig.Id + '_group';
        groupOutput.datatype = OutputDataType.Group;
        groupOutput.name = projectionConfig.Name + ' - Group';
        this.widgetConfig.OutputParameters.push(groupOutput);

        break;

      case 'Time':
        let timeConfig: XprojOutputTimeControllerConfig = config as XprojOutputTimeControllerConfig;
        let timeOutputFrom = new WidgetOutputParameter();
        timeOutputFrom.id = timeConfig.Id + '_from';
        timeOutputFrom.datatype = OutputDataType.Timestamp;
        timeOutputFrom.name = timeConfig.Name + (timeConfig.Type == OutputTimeType.Timestamp ? '' : ' from');
        this.widgetConfig.OutputParameters.push(timeOutputFrom);

        if (timeConfig.Type != OutputTimeType.Timestamp) {
          let timeOutputTo = new WidgetOutputParameter();
          timeOutputTo.id = timeConfig.Id + '_to';
          timeOutputTo.datatype = OutputDataType.Timestamp;
          timeOutputTo.name = timeConfig.Name + ' to';
          this.widgetConfig.OutputParameters.push(timeOutputTo);
        }

        let timeOutputLabel = new WidgetOutputParameter();
        timeOutputLabel.id = timeConfig.Id + '_label';
        timeOutputLabel.datatype = OutputDataType.String;
        timeOutputLabel.name = timeConfig.Name + '-label';
        this.widgetConfig.OutputParameters.push(timeOutputLabel);
        break;

      case 'Toggle':
        let toggleConfig: XprojOutputToggleControllerConfig = config as XprojOutputToggleControllerConfig;
        let toggleOutput = new WidgetOutputParameter();
        toggleOutput.id = toggleConfig.Id;
        toggleOutput.datatype = toggleConfig.DataType;
        toggleOutput.name = toggleConfig.Name;
        this.widgetConfig.OutputParameters.push(toggleOutput);
        break;

      case 'RadioButtons':
        let radioButtonConfig: XprojOutputRadioButtonControllerConfig = config as XprojOutputRadioButtonControllerConfig;
        let radioButtonOutput = new WidgetOutputParameter();
        radioButtonOutput.id = radioButtonConfig.Id;
        radioButtonOutput.datatype = radioButtonConfig.DataType;
        radioButtonOutput.name = radioButtonConfig.Name;
        this.widgetConfig.OutputParameters.push(radioButtonOutput);
        break;

    }
  }
}
