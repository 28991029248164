/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
/**
 * Message implementation for xconf.GetNodeTypeRequest
 */
export class GetNodeTypeRequest implements GrpcMessage {
  static id = 'xconf.GetNodeTypeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeTypeRequest();
    GetNodeTypeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeTypeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeTypeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeTypeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeTypeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeTypeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeTypeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    GetNodeTypeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeTypeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeTypeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetNodeTypeRequest {
  /**
   * Standard JavaScript object representation for GetNodeTypeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetNodeTypeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetNodeTypesResponse
 */
export class GetNodeTypesResponse implements GrpcMessage {
  static id = 'xconf.GetNodeTypesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeTypesResponse();
    GetNodeTypesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeTypesResponse) {
    _instance.nodeTypes = _instance.nodeTypes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeTypesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcNodeType();
          _reader.readMessage(
            messageInitializer1,
            GrpcNodeType.deserializeBinaryFromReader
          );
          (_instance.nodeTypes = _instance.nodeTypes || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeTypesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeTypesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeTypes && _instance.nodeTypes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.nodeTypes as any,
        GrpcNodeType.serializeBinaryToWriter
      );
    }
  }

  private _nodeTypes?: GrpcNodeType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeTypesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeTypesResponse.AsObject>) {
    _value = _value || {};
    this.nodeTypes = (_value.nodeTypes || []).map(m => new GrpcNodeType(m));
    GetNodeTypesResponse.refineValues(this);
  }
  get nodeTypes(): GrpcNodeType[] | undefined {
    return this._nodeTypes;
  }
  set nodeTypes(value: GrpcNodeType[] | undefined) {
    this._nodeTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeTypesResponse.AsObject {
    return {
      nodeTypes: (this.nodeTypes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeTypesResponse.AsProtobufJSON {
    return {
      nodeTypes: (this.nodeTypes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetNodeTypesResponse {
  /**
   * Standard JavaScript object representation for GetNodeTypesResponse
   */
  export interface AsObject {
    nodeTypes?: GrpcNodeType.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetNodeTypesResponse
   */
  export interface AsProtobufJSON {
    nodeTypes?: GrpcNodeType.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.DeleteNodeTypeRequest
 */
export class DeleteNodeTypeRequest implements GrpcMessage {
  static id = 'xconf.DeleteNodeTypeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteNodeTypeRequest();
    DeleteNodeTypeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteNodeTypeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteNodeTypeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteNodeTypeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteNodeTypeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteNodeTypeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteNodeTypeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    DeleteNodeTypeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteNodeTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteNodeTypeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteNodeTypeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteNodeTypeRequest {
  /**
   * Standard JavaScript object representation for DeleteNodeTypeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteNodeTypeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetEdgeTypeRequest
 */
export class GetEdgeTypeRequest implements GrpcMessage {
  static id = 'xconf.GetEdgeTypeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEdgeTypeRequest();
    GetEdgeTypeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEdgeTypeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEdgeTypeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEdgeTypeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEdgeTypeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEdgeTypeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEdgeTypeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    GetEdgeTypeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEdgeTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEdgeTypeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEdgeTypeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetEdgeTypeRequest {
  /**
   * Standard JavaScript object representation for GetEdgeTypeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetEdgeTypeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetEdgeTypesResponse
 */
export class GetEdgeTypesResponse implements GrpcMessage {
  static id = 'xconf.GetEdgeTypesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEdgeTypesResponse();
    GetEdgeTypesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEdgeTypesResponse) {
    _instance.edgeTypes = _instance.edgeTypes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEdgeTypesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcEdgeType();
          _reader.readMessage(
            messageInitializer1,
            GrpcEdgeType.deserializeBinaryFromReader
          );
          (_instance.edgeTypes = _instance.edgeTypes || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetEdgeTypesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEdgeTypesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.edgeTypes && _instance.edgeTypes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.edgeTypes as any,
        GrpcEdgeType.serializeBinaryToWriter
      );
    }
  }

  private _edgeTypes?: GrpcEdgeType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEdgeTypesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEdgeTypesResponse.AsObject>) {
    _value = _value || {};
    this.edgeTypes = (_value.edgeTypes || []).map(m => new GrpcEdgeType(m));
    GetEdgeTypesResponse.refineValues(this);
  }
  get edgeTypes(): GrpcEdgeType[] | undefined {
    return this._edgeTypes;
  }
  set edgeTypes(value: GrpcEdgeType[] | undefined) {
    this._edgeTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEdgeTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEdgeTypesResponse.AsObject {
    return {
      edgeTypes: (this.edgeTypes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEdgeTypesResponse.AsProtobufJSON {
    return {
      edgeTypes: (this.edgeTypes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetEdgeTypesResponse {
  /**
   * Standard JavaScript object representation for GetEdgeTypesResponse
   */
  export interface AsObject {
    edgeTypes?: GrpcEdgeType.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetEdgeTypesResponse
   */
  export interface AsProtobufJSON {
    edgeTypes?: GrpcEdgeType.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.DeleteEdgeTypeRequest
 */
export class DeleteEdgeTypeRequest implements GrpcMessage {
  static id = 'xconf.DeleteEdgeTypeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteEdgeTypeRequest();
    DeleteEdgeTypeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteEdgeTypeRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteEdgeTypeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteEdgeTypeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteEdgeTypeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteEdgeTypeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteEdgeTypeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    DeleteEdgeTypeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteEdgeTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteEdgeTypeRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteEdgeTypeRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteEdgeTypeRequest {
  /**
   * Standard JavaScript object representation for DeleteEdgeTypeRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteEdgeTypeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetDataSourceDefinitionRequest
 */
export class GetDataSourceDefinitionRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceDefinitionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceDefinitionRequest();
    GetDataSourceDefinitionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceDefinitionRequest) {
    _instance.id = _instance.id || '';
    _instance.includeExtensions = _instance.includeExtensions || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceDefinitionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.includeExtensions = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceDefinitionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceDefinitionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.includeExtensions) {
      _writer.writeBool(2, _instance.includeExtensions);
    }
  }

  private _id?: string;
  private _includeExtensions?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceDefinitionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceDefinitionRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.includeExtensions = _value.includeExtensions;
    GetDataSourceDefinitionRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get includeExtensions(): boolean | undefined {
    return this._includeExtensions;
  }
  set includeExtensions(value: boolean | undefined) {
    this._includeExtensions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceDefinitionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceDefinitionRequest.AsObject {
    return {
      id: this.id,
      includeExtensions: this.includeExtensions
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceDefinitionRequest.AsProtobufJSON {
    return {
      id: this.id,
      includeExtensions: this.includeExtensions
    };
  }
}
export module GetDataSourceDefinitionRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceDefinitionRequest
   */
  export interface AsObject {
    id?: string;
    includeExtensions?: boolean;
  }

  /**
   * Protobuf JSON representation for GetDataSourceDefinitionRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    includeExtensions?: boolean;
  }
}

/**
 * Message implementation for xconf.GetDataSourceDefinitionsResponse
 */
export class GetDataSourceDefinitionsResponse implements GrpcMessage {
  static id = 'xconf.GetDataSourceDefinitionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceDefinitionsResponse();
    GetDataSourceDefinitionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceDefinitionsResponse) {
    _instance.dataSourceDefinitions = _instance.dataSourceDefinitions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceDefinitionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcDataSourceDefinition();
          _reader.readMessage(
            messageInitializer1,
            GrpcDataSourceDefinition.deserializeBinaryFromReader
          );
          (_instance.dataSourceDefinitions =
            _instance.dataSourceDefinitions || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceDefinitionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceDefinitionsResponse,
    _writer: BinaryWriter
  ) {
    if (
      _instance.dataSourceDefinitions &&
      _instance.dataSourceDefinitions.length
    ) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dataSourceDefinitions as any,
        GrpcDataSourceDefinition.serializeBinaryToWriter
      );
    }
  }

  private _dataSourceDefinitions?: GrpcDataSourceDefinition[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceDefinitionsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceDefinitionsResponse.AsObject>
  ) {
    _value = _value || {};
    this.dataSourceDefinitions = (_value.dataSourceDefinitions || []).map(
      m => new GrpcDataSourceDefinition(m)
    );
    GetDataSourceDefinitionsResponse.refineValues(this);
  }
  get dataSourceDefinitions(): GrpcDataSourceDefinition[] | undefined {
    return this._dataSourceDefinitions;
  }
  set dataSourceDefinitions(value: GrpcDataSourceDefinition[] | undefined) {
    this._dataSourceDefinitions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceDefinitionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceDefinitionsResponse.AsObject {
    return {
      dataSourceDefinitions: (this.dataSourceDefinitions || []).map(m =>
        m.toObject()
      )
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceDefinitionsResponse.AsProtobufJSON {
    return {
      dataSourceDefinitions: (this.dataSourceDefinitions || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDataSourceDefinitionsResponse {
  /**
   * Standard JavaScript object representation for GetDataSourceDefinitionsResponse
   */
  export interface AsObject {
    dataSourceDefinitions?: GrpcDataSourceDefinition.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDataSourceDefinitionsResponse
   */
  export interface AsProtobufJSON {
    dataSourceDefinitions?: GrpcDataSourceDefinition.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.DeleteDataSourceDefinitionRequest
 */
export class DeleteDataSourceDefinitionRequest implements GrpcMessage {
  static id = 'xconf.DeleteDataSourceDefinitionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteDataSourceDefinitionRequest();
    DeleteDataSourceDefinitionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteDataSourceDefinitionRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteDataSourceDefinitionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteDataSourceDefinitionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteDataSourceDefinitionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteDataSourceDefinitionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteDataSourceDefinitionRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    DeleteDataSourceDefinitionRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteDataSourceDefinitionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteDataSourceDefinitionRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteDataSourceDefinitionRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteDataSourceDefinitionRequest {
  /**
   * Standard JavaScript object representation for DeleteDataSourceDefinitionRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteDataSourceDefinitionRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceRequest
 */
export class GetDataSourceInstanceRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceRequest();
    GetDataSourceInstanceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    GetDataSourceInstanceRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetDataSourceInstanceRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstancesRequest
 */
export class GetDataSourceInstancesRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstancesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstancesRequest();
    GetDataSourceInstancesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstancesRequest) {
    _instance.rootId = _instance.rootId || '';
    _instance.rootLabel = _instance.rootLabel || '';
    _instance.maxHops = _instance.maxHops || 0;
    _instance.limit = _instance.limit || 0;
    _instance.skip = _instance.skip || 0;
    _instance.includeCollections = _instance.includeCollections || false;
    _instance.includeSystemDefined = _instance.includeSystemDefined || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstancesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rootId = _reader.readString();
          break;
        case 2:
          _instance.rootLabel = _reader.readString();
          break;
        case 3:
          _instance.maxHops = _reader.readInt32();
          break;
        case 4:
          _instance.limit = _reader.readInt32();
          break;
        case 5:
          _instance.skip = _reader.readInt32();
          break;
        case 6:
          _instance.includeCollections = _reader.readBool();
          break;
        case 7:
          _instance.includeSystemDefined = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstancesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstancesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.rootId) {
      _writer.writeString(1, _instance.rootId);
    }
    if (_instance.rootLabel) {
      _writer.writeString(2, _instance.rootLabel);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(3, _instance.maxHops);
    }
    if (_instance.limit) {
      _writer.writeInt32(4, _instance.limit);
    }
    if (_instance.skip) {
      _writer.writeInt32(5, _instance.skip);
    }
    if (_instance.includeCollections) {
      _writer.writeBool(6, _instance.includeCollections);
    }
    if (_instance.includeSystemDefined) {
      _writer.writeBool(7, _instance.includeSystemDefined);
    }
  }

  private _rootId?: string;
  private _rootLabel?: string;
  private _maxHops?: number;
  private _limit?: number;
  private _skip?: number;
  private _includeCollections?: boolean;
  private _includeSystemDefined?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstancesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstancesRequest.AsObject>
  ) {
    _value = _value || {};
    this.rootId = _value.rootId;
    this.rootLabel = _value.rootLabel;
    this.maxHops = _value.maxHops;
    this.limit = _value.limit;
    this.skip = _value.skip;
    this.includeCollections = _value.includeCollections;
    this.includeSystemDefined = _value.includeSystemDefined;
    GetDataSourceInstancesRequest.refineValues(this);
  }
  get rootId(): string | undefined {
    return this._rootId;
  }
  set rootId(value: string | undefined) {
    this._rootId = value;
  }
  get rootLabel(): string | undefined {
    return this._rootLabel;
  }
  set rootLabel(value: string | undefined) {
    this._rootLabel = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }
  get limit(): number | undefined {
    return this._limit;
  }
  set limit(value: number | undefined) {
    this._limit = value;
  }
  get skip(): number | undefined {
    return this._skip;
  }
  set skip(value: number | undefined) {
    this._skip = value;
  }
  get includeCollections(): boolean | undefined {
    return this._includeCollections;
  }
  set includeCollections(value: boolean | undefined) {
    this._includeCollections = value;
  }
  get includeSystemDefined(): boolean | undefined {
    return this._includeSystemDefined;
  }
  set includeSystemDefined(value: boolean | undefined) {
    this._includeSystemDefined = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstancesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstancesRequest.AsObject {
    return {
      rootId: this.rootId,
      rootLabel: this.rootLabel,
      maxHops: this.maxHops,
      limit: this.limit,
      skip: this.skip,
      includeCollections: this.includeCollections,
      includeSystemDefined: this.includeSystemDefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstancesRequest.AsProtobufJSON {
    return {
      rootId: this.rootId,
      rootLabel: this.rootLabel,
      maxHops: this.maxHops,
      limit: this.limit,
      skip: this.skip,
      includeCollections: this.includeCollections,
      includeSystemDefined: this.includeSystemDefined
    };
  }
}
export module GetDataSourceInstancesRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceInstancesRequest
   */
  export interface AsObject {
    rootId?: string;
    rootLabel?: string;
    maxHops?: number;
    limit?: number;
    skip?: number;
    includeCollections?: boolean;
    includeSystemDefined?: boolean;
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstancesRequest
   */
  export interface AsProtobufJSON {
    rootId?: string;
    rootLabel?: string;
    maxHops?: number;
    limit?: number;
    skip?: number;
    includeCollections?: boolean;
    includeSystemDefined?: boolean;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceRootNodesRequest
 */
export class GetDataSourceInstanceRootNodesRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceRootNodesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceRootNodesRequest();
    GetDataSourceInstanceRootNodesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceRootNodesRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceRootNodesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceRootNodesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceRootNodesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceRootNodesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceRootNodesRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    GetDataSourceInstanceRootNodesRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceRootNodesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceRootNodesRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceRootNodesRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetDataSourceInstanceRootNodesRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceRootNodesRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceRootNodesRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceRootNodesResponse
 */
export class GetDataSourceInstanceRootNodesResponse implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceRootNodesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceRootNodesResponse();
    GetDataSourceInstanceRootNodesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceRootNodesResponse) {
    _instance.rootNodes = _instance.rootNodes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceRootNodesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          (_instance.rootNodes = _instance.rootNodes || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceRootNodesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceRootNodesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.rootNodes && _instance.rootNodes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.rootNodes as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _rootNodes?: xprojectorGrpcModels002.GrpcNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceRootNodesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceRootNodesResponse.AsObject>
  ) {
    _value = _value || {};
    this.rootNodes = (_value.rootNodes || []).map(
      m => new xprojectorGrpcModels002.GrpcNode(m)
    );
    GetDataSourceInstanceRootNodesResponse.refineValues(this);
  }
  get rootNodes(): xprojectorGrpcModels002.GrpcNode[] | undefined {
    return this._rootNodes;
  }
  set rootNodes(value: xprojectorGrpcModels002.GrpcNode[] | undefined) {
    this._rootNodes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceRootNodesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceRootNodesResponse.AsObject {
    return {
      rootNodes: (this.rootNodes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceRootNodesResponse.AsProtobufJSON {
    return {
      rootNodes: (this.rootNodes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetDataSourceInstanceRootNodesResponse {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceRootNodesResponse
   */
  export interface AsObject {
    rootNodes?: xprojectorGrpcModels002.GrpcNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceRootNodesResponse
   */
  export interface AsProtobufJSON {
    rootNodes?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.AddDataSourceInstanceRootNodeRequest
 */
export class AddDataSourceInstanceRootNodeRequest implements GrpcMessage {
  static id = 'xconf.AddDataSourceInstanceRootNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDataSourceInstanceRootNodeRequest();
    AddDataSourceInstanceRootNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDataSourceInstanceRootNodeRequest) {
    _instance.id = _instance.id || '';
    _instance.rootNode = _instance.rootNode || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDataSourceInstanceRootNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.rootNode = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.rootNode,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddDataSourceInstanceRootNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDataSourceInstanceRootNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.rootNode) {
      _writer.writeMessage(
        2,
        _instance.rootNode as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _rootNode?: xprojectorGrpcModels002.GrpcNode;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDataSourceInstanceRootNodeRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddDataSourceInstanceRootNodeRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.rootNode = _value.rootNode
      ? new xprojectorGrpcModels002.GrpcNode(_value.rootNode)
      : undefined;
    AddDataSourceInstanceRootNodeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get rootNode(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._rootNode;
  }
  set rootNode(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._rootNode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDataSourceInstanceRootNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDataSourceInstanceRootNodeRequest.AsObject {
    return {
      id: this.id,
      rootNode: this.rootNode ? this.rootNode.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDataSourceInstanceRootNodeRequest.AsProtobufJSON {
    return {
      id: this.id,
      rootNode: this.rootNode ? this.rootNode.toProtobufJSON(options) : null
    };
  }
}
export module AddDataSourceInstanceRootNodeRequest {
  /**
   * Standard JavaScript object representation for AddDataSourceInstanceRootNodeRequest
   */
  export interface AsObject {
    id?: string;
    rootNode?: xprojectorGrpcModels002.GrpcNode.AsObject;
  }

  /**
   * Protobuf JSON representation for AddDataSourceInstanceRootNodeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    rootNode?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceChildrenRequest
 */
export class GetDataSourceInstanceChildrenRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceChildrenRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceChildrenRequest();
    GetDataSourceInstanceChildrenRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceChildrenRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceChildrenRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceChildrenRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceChildrenRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceChildrenRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceChildrenRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    GetDataSourceInstanceChildrenRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceChildrenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceChildrenRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceChildrenRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetDataSourceInstanceChildrenRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceChildrenRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceChildrenRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceChildrenResponse
 */
export class GetDataSourceInstanceChildrenResponse implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceChildrenResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceChildrenResponse();
    GetDataSourceInstanceChildrenResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceChildrenResponse) {
    _instance.dataSourceInstances = _instance.dataSourceInstances || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceChildrenResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcDataSourceInstance();
          _reader.readMessage(
            messageInitializer1,
            GrpcDataSourceInstance.deserializeBinaryFromReader
          );
          (_instance.dataSourceInstances =
            _instance.dataSourceInstances || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceChildrenResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceChildrenResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.dataSourceInstances && _instance.dataSourceInstances.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dataSourceInstances as any,
        GrpcDataSourceInstance.serializeBinaryToWriter
      );
    }
  }

  private _dataSourceInstances?: GrpcDataSourceInstance[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceChildrenResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceChildrenResponse.AsObject>
  ) {
    _value = _value || {};
    this.dataSourceInstances = (_value.dataSourceInstances || []).map(
      m => new GrpcDataSourceInstance(m)
    );
    GetDataSourceInstanceChildrenResponse.refineValues(this);
  }
  get dataSourceInstances(): GrpcDataSourceInstance[] | undefined {
    return this._dataSourceInstances;
  }
  set dataSourceInstances(value: GrpcDataSourceInstance[] | undefined) {
    this._dataSourceInstances = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceChildrenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceChildrenResponse.AsObject {
    return {
      dataSourceInstances: (this.dataSourceInstances || []).map(m =>
        m.toObject()
      )
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceChildrenResponse.AsProtobufJSON {
    return {
      dataSourceInstances: (this.dataSourceInstances || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDataSourceInstanceChildrenResponse {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceChildrenResponse
   */
  export interface AsObject {
    dataSourceInstances?: GrpcDataSourceInstance.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceChildrenResponse
   */
  export interface AsProtobufJSON {
    dataSourceInstances?: GrpcDataSourceInstance.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstancesResponse
 */
export class GetDataSourceInstancesResponse implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstancesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstancesResponse();
    GetDataSourceInstancesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstancesResponse) {
    _instance.dataSourceInstances = _instance.dataSourceInstances || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstancesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcDataSourceInstance();
          _reader.readMessage(
            messageInitializer1,
            GrpcDataSourceInstance.deserializeBinaryFromReader
          );
          (_instance.dataSourceInstances =
            _instance.dataSourceInstances || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstancesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstancesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.dataSourceInstances && _instance.dataSourceInstances.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.dataSourceInstances as any,
        GrpcDataSourceInstance.serializeBinaryToWriter
      );
    }
  }

  private _dataSourceInstances?: GrpcDataSourceInstance[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstancesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstancesResponse.AsObject>
  ) {
    _value = _value || {};
    this.dataSourceInstances = (_value.dataSourceInstances || []).map(
      m => new GrpcDataSourceInstance(m)
    );
    GetDataSourceInstancesResponse.refineValues(this);
  }
  get dataSourceInstances(): GrpcDataSourceInstance[] | undefined {
    return this._dataSourceInstances;
  }
  set dataSourceInstances(value: GrpcDataSourceInstance[] | undefined) {
    this._dataSourceInstances = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstancesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstancesResponse.AsObject {
    return {
      dataSourceInstances: (this.dataSourceInstances || []).map(m =>
        m.toObject()
      )
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstancesResponse.AsProtobufJSON {
    return {
      dataSourceInstances: (this.dataSourceInstances || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDataSourceInstancesResponse {
  /**
   * Standard JavaScript object representation for GetDataSourceInstancesResponse
   */
  export interface AsObject {
    dataSourceInstances?: GrpcDataSourceInstance.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstancesResponse
   */
  export interface AsProtobufJSON {
    dataSourceInstances?: GrpcDataSourceInstance.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.DeleteDataSourceInstanceRequest
 */
export class DeleteDataSourceInstanceRequest implements GrpcMessage {
  static id = 'xconf.DeleteDataSourceInstanceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteDataSourceInstanceRequest();
    DeleteDataSourceInstanceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteDataSourceInstanceRequest) {
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteDataSourceInstanceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteDataSourceInstanceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteDataSourceInstanceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
  }

  private _id?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteDataSourceInstanceRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteDataSourceInstanceRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    DeleteDataSourceInstanceRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteDataSourceInstanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteDataSourceInstanceRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteDataSourceInstanceRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module DeleteDataSourceInstanceRequest {
  /**
   * Standard JavaScript object representation for DeleteDataSourceInstanceRequest
   */
  export interface AsObject {
    id?: string;
  }

  /**
   * Protobuf JSON representation for DeleteDataSourceInstanceRequest
   */
  export interface AsProtobufJSON {
    id?: string;
  }
}

/**
 * Message implementation for xconf.GetNodeRequest
 */
export class GetNodeRequest implements GrpcMessage {
  static id = 'xconf.GetNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeRequest();
    GetNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.isCustomerSpecific = _instance.isCustomerSpecific || false;
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.isCustomerSpecific = _reader.readBool();
          break;
        case 4:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.isCustomerSpecific) {
      _writer.writeBool(3, _instance.isCustomerSpecific);
    }
    if (_instance.customerId) {
      _writer.writeString(4, _instance.customerId);
    }
  }

  private _id?: string;
  private _label?: string;
  private _isCustomerSpecific?: boolean;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.isCustomerSpecific = _value.isCustomerSpecific;
    this.customerId = _value.customerId;
    GetNodeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get isCustomerSpecific(): boolean | undefined {
    return this._isCustomerSpecific;
  }
  set isCustomerSpecific(value: boolean | undefined) {
    this._isCustomerSpecific = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      isCustomerSpecific: this.isCustomerSpecific,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      isCustomerSpecific: this.isCustomerSpecific,
      customerId: this.customerId
    };
  }
}
export module GetNodeRequest {
  /**
   * Standard JavaScript object representation for GetNodeRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    isCustomerSpecific?: boolean;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetNodeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    isCustomerSpecific?: boolean;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.DeleteNodeRequest
 */
export class DeleteNodeRequest implements GrpcMessage {
  static id = 'xconf.DeleteNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteNodeRequest();
    DeleteNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteNodeRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(4, _instance.customerId);
    }
  }

  private _id?: string;
  private _label?: string;
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteNodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteNodeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    DeleteNodeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteNodeRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteNodeRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module DeleteNodeRequest {
  /**
   * Standard JavaScript object representation for DeleteNodeRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteNodeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.MoveNodeRequest
 */
export class MoveNodeRequest implements GrpcMessage {
  static id = 'xconf.MoveNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MoveNodeRequest();
    MoveNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MoveNodeRequest) {
    _instance.node = _instance.node || undefined;
    _instance.oldReferencedFromId = _instance.oldReferencedFromId || '';
    _instance.oldReferencedFromLabel = _instance.oldReferencedFromLabel || '';
    _instance.newReferencedFromId = _instance.newReferencedFromId || '';
    _instance.newReferencedFromLabel = _instance.newReferencedFromLabel || '';
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.edgeIsReference = _instance.edgeIsReference || false;
    _instance.edgeProperties = _instance.edgeProperties || [];
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MoveNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.oldReferencedFromId = _reader.readString();
          break;
        case 3:
          _instance.oldReferencedFromLabel = _reader.readString();
          break;
        case 4:
          _instance.newReferencedFromId = _reader.readString();
          break;
        case 5:
          _instance.newReferencedFromLabel = _reader.readString();
          break;
        case 6:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 7:
          _instance.edgeIsReference = _reader.readBool();
          break;
        case 8:
          const messageInitializer8 = new xprojectorGrpcModels002.GrpcNodeProperty();
          _reader.readMessage(
            messageInitializer8,
            xprojectorGrpcModels002.GrpcNodeProperty.deserializeBinaryFromReader
          );
          (_instance.edgeProperties = _instance.edgeProperties || []).push(
            messageInitializer8
          );
          break;
        case 9:
          _instance.comment = _reader.readString();
          break;
        case 10:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    MoveNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MoveNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.oldReferencedFromId) {
      _writer.writeString(2, _instance.oldReferencedFromId);
    }
    if (_instance.oldReferencedFromLabel) {
      _writer.writeString(3, _instance.oldReferencedFromLabel);
    }
    if (_instance.newReferencedFromId) {
      _writer.writeString(4, _instance.newReferencedFromId);
    }
    if (_instance.newReferencedFromLabel) {
      _writer.writeString(5, _instance.newReferencedFromLabel);
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(6, _instance.edgeTypeId);
    }
    if (_instance.edgeIsReference) {
      _writer.writeBool(7, _instance.edgeIsReference);
    }
    if (_instance.edgeProperties && _instance.edgeProperties.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.edgeProperties as any,
        xprojectorGrpcModels002.GrpcNodeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.comment) {
      _writer.writeString(9, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(10, _instance.customerId);
    }
  }

  private _node?: xprojectorGrpcModels002.GrpcNode;
  private _oldReferencedFromId?: string;
  private _oldReferencedFromLabel?: string;
  private _newReferencedFromId?: string;
  private _newReferencedFromLabel?: string;
  private _edgeTypeId?: string;
  private _edgeIsReference?: boolean;
  private _edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty[];
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MoveNodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<MoveNodeRequest.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    this.oldReferencedFromId = _value.oldReferencedFromId;
    this.oldReferencedFromLabel = _value.oldReferencedFromLabel;
    this.newReferencedFromId = _value.newReferencedFromId;
    this.newReferencedFromLabel = _value.newReferencedFromLabel;
    this.edgeTypeId = _value.edgeTypeId;
    this.edgeIsReference = _value.edgeIsReference;
    this.edgeProperties = (_value.edgeProperties || []).map(
      m => new xprojectorGrpcModels002.GrpcNodeProperty(m)
    );
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    MoveNodeRequest.refineValues(this);
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }
  get oldReferencedFromId(): string | undefined {
    return this._oldReferencedFromId;
  }
  set oldReferencedFromId(value: string | undefined) {
    this._oldReferencedFromId = value;
  }
  get oldReferencedFromLabel(): string | undefined {
    return this._oldReferencedFromLabel;
  }
  set oldReferencedFromLabel(value: string | undefined) {
    this._oldReferencedFromLabel = value;
  }
  get newReferencedFromId(): string | undefined {
    return this._newReferencedFromId;
  }
  set newReferencedFromId(value: string | undefined) {
    this._newReferencedFromId = value;
  }
  get newReferencedFromLabel(): string | undefined {
    return this._newReferencedFromLabel;
  }
  set newReferencedFromLabel(value: string | undefined) {
    this._newReferencedFromLabel = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get edgeIsReference(): boolean | undefined {
    return this._edgeIsReference;
  }
  set edgeIsReference(value: boolean | undefined) {
    this._edgeIsReference = value;
  }
  get edgeProperties(): xprojectorGrpcModels002.GrpcNodeProperty[] | undefined {
    return this._edgeProperties;
  }
  set edgeProperties(
    value: xprojectorGrpcModels002.GrpcNodeProperty[] | undefined
  ) {
    this._edgeProperties = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MoveNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MoveNodeRequest.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      oldReferencedFromId: this.oldReferencedFromId,
      oldReferencedFromLabel: this.oldReferencedFromLabel,
      newReferencedFromId: this.newReferencedFromId,
      newReferencedFromLabel: this.newReferencedFromLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsReference: this.edgeIsReference,
      edgeProperties: (this.edgeProperties || []).map(m => m.toObject()),
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MoveNodeRequest.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      oldReferencedFromId: this.oldReferencedFromId,
      oldReferencedFromLabel: this.oldReferencedFromLabel,
      newReferencedFromId: this.newReferencedFromId,
      newReferencedFromLabel: this.newReferencedFromLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsReference: this.edgeIsReference,
      edgeProperties: (this.edgeProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module MoveNodeRequest {
  /**
   * Standard JavaScript object representation for MoveNodeRequest
   */
  export interface AsObject {
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
    oldReferencedFromId?: string;
    oldReferencedFromLabel?: string;
    newReferencedFromId?: string;
    newReferencedFromLabel?: string;
    edgeTypeId?: string;
    edgeIsReference?: boolean;
    edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty.AsObject[];
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for MoveNodeRequest
   */
  export interface AsProtobufJSON {
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    oldReferencedFromId?: string;
    oldReferencedFromLabel?: string;
    newReferencedFromId?: string;
    newReferencedFromLabel?: string;
    edgeTypeId?: string;
    edgeIsReference?: boolean;
    edgeProperties?:
      | xprojectorGrpcModels002.GrpcNodeProperty.AsProtobufJSON[]
      | null;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.GetReferencedNodesRequest
 */
export class GetReferencedNodesRequest implements GrpcMessage {
  static id = 'xconf.GetReferencedNodesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetReferencedNodesRequest();
    GetReferencedNodesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetReferencedNodesRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.edgeTypeIds = _instance.edgeTypeIds || [];
    _instance.referencedNodeTypeLabel = _instance.referencedNodeTypeLabel || '';
    _instance.maxHops = _instance.maxHops || 0;
    _instance.minHops = _instance.minHops || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetReferencedNodesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          (_instance.edgeTypeIds = _instance.edgeTypeIds || []).push(
            _reader.readString()
          );
          break;
        case 4:
          _instance.referencedNodeTypeLabel = _reader.readString();
          break;
        case 5:
          _instance.maxHops = _reader.readInt32();
          break;
        case 6:
          _instance.minHops = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetReferencedNodesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetReferencedNodesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.edgeTypeIds && _instance.edgeTypeIds.length) {
      _writer.writeRepeatedString(3, _instance.edgeTypeIds);
    }
    if (_instance.referencedNodeTypeLabel) {
      _writer.writeString(4, _instance.referencedNodeTypeLabel);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(5, _instance.maxHops);
    }
    if (_instance.minHops) {
      _writer.writeInt32(6, _instance.minHops);
    }
  }

  private _id?: string;
  private _label?: string;
  private _edgeTypeIds?: string[];
  private _referencedNodeTypeLabel?: string;
  private _maxHops?: number;
  private _minHops?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetReferencedNodesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetReferencedNodesRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.edgeTypeIds = (_value.edgeTypeIds || []).slice();
    this.referencedNodeTypeLabel = _value.referencedNodeTypeLabel;
    this.maxHops = _value.maxHops;
    this.minHops = _value.minHops;
    GetReferencedNodesRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get edgeTypeIds(): string[] | undefined {
    return this._edgeTypeIds;
  }
  set edgeTypeIds(value: string[] | undefined) {
    this._edgeTypeIds = value;
  }
  get referencedNodeTypeLabel(): string | undefined {
    return this._referencedNodeTypeLabel;
  }
  set referencedNodeTypeLabel(value: string | undefined) {
    this._referencedNodeTypeLabel = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }
  get minHops(): number | undefined {
    return this._minHops;
  }
  set minHops(value: number | undefined) {
    this._minHops = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetReferencedNodesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetReferencedNodesRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      edgeTypeIds: (this.edgeTypeIds || []).slice(),
      referencedNodeTypeLabel: this.referencedNodeTypeLabel,
      maxHops: this.maxHops,
      minHops: this.minHops
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetReferencedNodesRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      edgeTypeIds: (this.edgeTypeIds || []).slice(),
      referencedNodeTypeLabel: this.referencedNodeTypeLabel,
      maxHops: this.maxHops,
      minHops: this.minHops
    };
  }
}
export module GetReferencedNodesRequest {
  /**
   * Standard JavaScript object representation for GetReferencedNodesRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    edgeTypeIds?: string[];
    referencedNodeTypeLabel?: string;
    maxHops?: number;
    minHops?: number;
  }

  /**
   * Protobuf JSON representation for GetReferencedNodesRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    edgeTypeIds?: string[];
    referencedNodeTypeLabel?: string;
    maxHops?: number;
    minHops?: number;
  }
}

/**
 * Message implementation for xconf.GetReferencedNodesResponse
 */
export class GetReferencedNodesResponse implements GrpcMessage {
  static id = 'xconf.GetReferencedNodesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetReferencedNodesResponse();
    GetReferencedNodesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetReferencedNodesResponse) {
    _instance.nodes = _instance.nodes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetReferencedNodesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          (_instance.nodes = _instance.nodes || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetReferencedNodesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetReferencedNodesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.nodes && _instance.nodes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.nodes as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _nodes?: xprojectorGrpcModels002.GrpcNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetReferencedNodesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetReferencedNodesResponse.AsObject>) {
    _value = _value || {};
    this.nodes = (_value.nodes || []).map(
      m => new xprojectorGrpcModels002.GrpcNode(m)
    );
    GetReferencedNodesResponse.refineValues(this);
  }
  get nodes(): xprojectorGrpcModels002.GrpcNode[] | undefined {
    return this._nodes;
  }
  set nodes(value: xprojectorGrpcModels002.GrpcNode[] | undefined) {
    this._nodes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetReferencedNodesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetReferencedNodesResponse.AsObject {
    return {
      nodes: (this.nodes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetReferencedNodesResponse.AsProtobufJSON {
    return {
      nodes: (this.nodes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetReferencedNodesResponse {
  /**
   * Standard JavaScript object representation for GetReferencedNodesResponse
   */
  export interface AsObject {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetReferencedNodesResponse
   */
  export interface AsProtobufJSON {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GetReferingNodesRequest
 */
export class GetReferingNodesRequest implements GrpcMessage {
  static id = 'xconf.GetReferingNodesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetReferingNodesRequest();
    GetReferingNodesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetReferingNodesRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.edgeTypeIds = _instance.edgeTypeIds || [];
    _instance.referingNodeLabels = _instance.referingNodeLabels || [];
    _instance.maxHops = _instance.maxHops || 0;
    _instance.limit = _instance.limit || 0;
    _instance.skip = _instance.skip || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetReferingNodesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          (_instance.edgeTypeIds = _instance.edgeTypeIds || []).push(
            _reader.readString()
          );
          break;
        case 4:
          (_instance.referingNodeLabels =
            _instance.referingNodeLabels || []).push(_reader.readString());
          break;
        case 5:
          _instance.maxHops = _reader.readInt32();
          break;
        case 6:
          _instance.limit = _reader.readInt32();
          break;
        case 7:
          _instance.skip = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetReferingNodesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetReferingNodesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.edgeTypeIds && _instance.edgeTypeIds.length) {
      _writer.writeRepeatedString(3, _instance.edgeTypeIds);
    }
    if (_instance.referingNodeLabels && _instance.referingNodeLabels.length) {
      _writer.writeRepeatedString(4, _instance.referingNodeLabels);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(5, _instance.maxHops);
    }
    if (_instance.limit) {
      _writer.writeInt32(6, _instance.limit);
    }
    if (_instance.skip) {
      _writer.writeInt32(7, _instance.skip);
    }
  }

  private _id?: string;
  private _label?: string;
  private _edgeTypeIds?: string[];
  private _referingNodeLabels?: string[];
  private _maxHops?: number;
  private _limit?: number;
  private _skip?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetReferingNodesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetReferingNodesRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.edgeTypeIds = (_value.edgeTypeIds || []).slice();
    this.referingNodeLabels = (_value.referingNodeLabels || []).slice();
    this.maxHops = _value.maxHops;
    this.limit = _value.limit;
    this.skip = _value.skip;
    GetReferingNodesRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get edgeTypeIds(): string[] | undefined {
    return this._edgeTypeIds;
  }
  set edgeTypeIds(value: string[] | undefined) {
    this._edgeTypeIds = value;
  }
  get referingNodeLabels(): string[] | undefined {
    return this._referingNodeLabels;
  }
  set referingNodeLabels(value: string[] | undefined) {
    this._referingNodeLabels = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }
  get limit(): number | undefined {
    return this._limit;
  }
  set limit(value: number | undefined) {
    this._limit = value;
  }
  get skip(): number | undefined {
    return this._skip;
  }
  set skip(value: number | undefined) {
    this._skip = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetReferingNodesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetReferingNodesRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      edgeTypeIds: (this.edgeTypeIds || []).slice(),
      referingNodeLabels: (this.referingNodeLabels || []).slice(),
      maxHops: this.maxHops,
      limit: this.limit,
      skip: this.skip
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetReferingNodesRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      edgeTypeIds: (this.edgeTypeIds || []).slice(),
      referingNodeLabels: (this.referingNodeLabels || []).slice(),
      maxHops: this.maxHops,
      limit: this.limit,
      skip: this.skip
    };
  }
}
export module GetReferingNodesRequest {
  /**
   * Standard JavaScript object representation for GetReferingNodesRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    edgeTypeIds?: string[];
    referingNodeLabels?: string[];
    maxHops?: number;
    limit?: number;
    skip?: number;
  }

  /**
   * Protobuf JSON representation for GetReferingNodesRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    edgeTypeIds?: string[];
    referingNodeLabels?: string[];
    maxHops?: number;
    limit?: number;
    skip?: number;
  }
}

/**
 * Message implementation for xconf.GetReferingNodesResponse
 */
export class GetReferingNodesResponse implements GrpcMessage {
  static id = 'xconf.GetReferingNodesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetReferingNodesResponse();
    GetReferingNodesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetReferingNodesResponse) {
    _instance.nodes = _instance.nodes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetReferingNodesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          (_instance.nodes = _instance.nodes || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetReferingNodesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetReferingNodesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.nodes && _instance.nodes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.nodes as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _nodes?: xprojectorGrpcModels002.GrpcNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetReferingNodesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetReferingNodesResponse.AsObject>) {
    _value = _value || {};
    this.nodes = (_value.nodes || []).map(
      m => new xprojectorGrpcModels002.GrpcNode(m)
    );
    GetReferingNodesResponse.refineValues(this);
  }
  get nodes(): xprojectorGrpcModels002.GrpcNode[] | undefined {
    return this._nodes;
  }
  set nodes(value: xprojectorGrpcModels002.GrpcNode[] | undefined) {
    this._nodes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetReferingNodesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetReferingNodesResponse.AsObject {
    return {
      nodes: (this.nodes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetReferingNodesResponse.AsProtobufJSON {
    return {
      nodes: (this.nodes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetReferingNodesResponse {
  /**
   * Standard JavaScript object representation for GetReferingNodesResponse
   */
  export interface AsObject {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetReferingNodesResponse
   */
  export interface AsProtobufJSON {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.CreateNodev2Request
 */
export class CreateNodev2Request implements GrpcMessage {
  static id = 'xconf.CreateNodev2Request';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateNodev2Request();
    CreateNodev2Request.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateNodev2Request) {
    _instance.node = _instance.node || undefined;
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateNodev2Request,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateNodev2Request.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateNodev2Request,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _node?: xprojectorGrpcModels002.GrpcNode;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateNodev2Request to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateNodev2Request.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    this.customerId = _value.customerId;
    CreateNodev2Request.refineValues(this);
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateNodev2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateNodev2Request.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateNodev2Request.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      customerId: this.customerId
    };
  }
}
export module CreateNodev2Request {
  /**
   * Standard JavaScript object representation for CreateNodev2Request
   */
  export interface AsObject {
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for CreateNodev2Request
   */
  export interface AsProtobufJSON {
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.CreateReferencedNodeRequest
 */
export class CreateReferencedNodeRequest implements GrpcMessage {
  static id = 'xconf.CreateReferencedNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateReferencedNodeRequest();
    CreateReferencedNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateReferencedNodeRequest) {
    _instance.node = _instance.node || undefined;
    _instance.referencedFromId = _instance.referencedFromId || '';
    _instance.referencedFromLabel = _instance.referencedFromLabel || '';
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.edgeIsreference = _instance.edgeIsreference || false;
    _instance.edgeProperties = _instance.edgeProperties || [];
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateReferencedNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.referencedFromId = _reader.readString();
          break;
        case 3:
          _instance.referencedFromLabel = _reader.readString();
          break;
        case 4:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 5:
          _instance.edgeIsreference = _reader.readBool();
          break;
        case 6:
          const messageInitializer6 = new xprojectorGrpcModels002.GrpcNodeProperty();
          _reader.readMessage(
            messageInitializer6,
            xprojectorGrpcModels002.GrpcNodeProperty.deserializeBinaryFromReader
          );
          (_instance.edgeProperties = _instance.edgeProperties || []).push(
            messageInitializer6
          );
          break;
        case 7:
          _instance.comment = _reader.readString();
          break;
        case 8:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateReferencedNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateReferencedNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.referencedFromId) {
      _writer.writeString(2, _instance.referencedFromId);
    }
    if (_instance.referencedFromLabel) {
      _writer.writeString(3, _instance.referencedFromLabel);
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(4, _instance.edgeTypeId);
    }
    if (_instance.edgeIsreference) {
      _writer.writeBool(5, _instance.edgeIsreference);
    }
    if (_instance.edgeProperties && _instance.edgeProperties.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.edgeProperties as any,
        xprojectorGrpcModels002.GrpcNodeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.comment) {
      _writer.writeString(7, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(8, _instance.customerId);
    }
  }

  private _node?: xprojectorGrpcModels002.GrpcNode;
  private _referencedFromId?: string;
  private _referencedFromLabel?: string;
  private _edgeTypeId?: string;
  private _edgeIsreference?: boolean;
  private _edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty[];
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateReferencedNodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateReferencedNodeRequest.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    this.referencedFromId = _value.referencedFromId;
    this.referencedFromLabel = _value.referencedFromLabel;
    this.edgeTypeId = _value.edgeTypeId;
    this.edgeIsreference = _value.edgeIsreference;
    this.edgeProperties = (_value.edgeProperties || []).map(
      m => new xprojectorGrpcModels002.GrpcNodeProperty(m)
    );
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    CreateReferencedNodeRequest.refineValues(this);
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }
  get referencedFromId(): string | undefined {
    return this._referencedFromId;
  }
  set referencedFromId(value: string | undefined) {
    this._referencedFromId = value;
  }
  get referencedFromLabel(): string | undefined {
    return this._referencedFromLabel;
  }
  set referencedFromLabel(value: string | undefined) {
    this._referencedFromLabel = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get edgeIsreference(): boolean | undefined {
    return this._edgeIsreference;
  }
  set edgeIsreference(value: boolean | undefined) {
    this._edgeIsreference = value;
  }
  get edgeProperties(): xprojectorGrpcModels002.GrpcNodeProperty[] | undefined {
    return this._edgeProperties;
  }
  set edgeProperties(
    value: xprojectorGrpcModels002.GrpcNodeProperty[] | undefined
  ) {
    this._edgeProperties = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateReferencedNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateReferencedNodeRequest.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      referencedFromId: this.referencedFromId,
      referencedFromLabel: this.referencedFromLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsreference: this.edgeIsreference,
      edgeProperties: (this.edgeProperties || []).map(m => m.toObject()),
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateReferencedNodeRequest.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      referencedFromId: this.referencedFromId,
      referencedFromLabel: this.referencedFromLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsreference: this.edgeIsreference,
      edgeProperties: (this.edgeProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module CreateReferencedNodeRequest {
  /**
   * Standard JavaScript object representation for CreateReferencedNodeRequest
   */
  export interface AsObject {
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
    referencedFromId?: string;
    referencedFromLabel?: string;
    edgeTypeId?: string;
    edgeIsreference?: boolean;
    edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty.AsObject[];
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for CreateReferencedNodeRequest
   */
  export interface AsProtobufJSON {
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    referencedFromId?: string;
    referencedFromLabel?: string;
    edgeTypeId?: string;
    edgeIsreference?: boolean;
    edgeProperties?:
      | xprojectorGrpcModels002.GrpcNodeProperty.AsProtobufJSON[]
      | null;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.CreateReferencedNodeResponse
 */
export class CreateReferencedNodeResponse implements GrpcMessage {
  static id = 'xconf.CreateReferencedNodeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateReferencedNodeResponse();
    CreateReferencedNodeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateReferencedNodeResponse) {
    _instance.result = _instance.result || false;
    _instance.message = _instance.message || '';
    _instance.node = _instance.node || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateReferencedNodeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.result = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateReferencedNodeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateReferencedNodeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.result) {
      _writer.writeBool(1, _instance.result);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.node) {
      _writer.writeMessage(
        3,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _result?: boolean;
  private _message?: string;
  private _node?: xprojectorGrpcModels002.GrpcNode;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateReferencedNodeResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateReferencedNodeResponse.AsObject>
  ) {
    _value = _value || {};
    this.result = _value.result;
    this.message = _value.message;
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    CreateReferencedNodeResponse.refineValues(this);
  }
  get result(): boolean | undefined {
    return this._result;
  }
  set result(value: boolean | undefined) {
    this._result = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateReferencedNodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateReferencedNodeResponse.AsObject {
    return {
      result: this.result,
      message: this.message,
      node: this.node ? this.node.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateReferencedNodeResponse.AsProtobufJSON {
    return {
      result: this.result,
      message: this.message,
      node: this.node ? this.node.toProtobufJSON(options) : null
    };
  }
}
export module CreateReferencedNodeResponse {
  /**
   * Standard JavaScript object representation for CreateReferencedNodeResponse
   */
  export interface AsObject {
    result?: boolean;
    message?: string;
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateReferencedNodeResponse
   */
  export interface AsProtobufJSON {
    result?: boolean;
    message?: string;
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for xconf.CreateNodeResponse
 */
export class CreateNodeResponse implements GrpcMessage {
  static id = 'xconf.CreateNodeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateNodeResponse();
    CreateNodeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateNodeResponse) {
    _instance.result = _instance.result || false;
    _instance.message = _instance.message || '';
    _instance.node = _instance.node || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateNodeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.result = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateNodeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateNodeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.result) {
      _writer.writeBool(1, _instance.result);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.node) {
      _writer.writeMessage(
        3,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _result?: boolean;
  private _message?: string;
  private _node?: xprojectorGrpcModels002.GrpcNode;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateNodeResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateNodeResponse.AsObject>) {
    _value = _value || {};
    this.result = _value.result;
    this.message = _value.message;
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    CreateNodeResponse.refineValues(this);
  }
  get result(): boolean | undefined {
    return this._result;
  }
  set result(value: boolean | undefined) {
    this._result = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateNodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateNodeResponse.AsObject {
    return {
      result: this.result,
      message: this.message,
      node: this.node ? this.node.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateNodeResponse.AsProtobufJSON {
    return {
      result: this.result,
      message: this.message,
      node: this.node ? this.node.toProtobufJSON(options) : null
    };
  }
}
export module CreateNodeResponse {
  /**
   * Standard JavaScript object representation for CreateNodeResponse
   */
  export interface AsObject {
    result?: boolean;
    message?: string;
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateNodeResponse
   */
  export interface AsProtobufJSON {
    result?: boolean;
    message?: string;
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for xconf.CreateReferenceRequest
 */
export class CreateReferenceRequest implements GrpcMessage {
  static id = 'xconf.CreateReferenceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateReferenceRequest();
    CreateReferenceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateReferenceRequest) {
    _instance.sourceId = _instance.sourceId || '';
    _instance.sourceLabel = _instance.sourceLabel || '';
    _instance.endId = _instance.endId || '';
    _instance.endLabel = _instance.endLabel || '';
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.edgeIsreference = _instance.edgeIsreference || false;
    _instance.edgeProperties = _instance.edgeProperties || [];
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateReferenceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sourceId = _reader.readString();
          break;
        case 2:
          _instance.sourceLabel = _reader.readString();
          break;
        case 3:
          _instance.endId = _reader.readString();
          break;
        case 4:
          _instance.endLabel = _reader.readString();
          break;
        case 5:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 6:
          _instance.edgeIsreference = _reader.readBool();
          break;
        case 7:
          const messageInitializer7 = new xprojectorGrpcModels002.GrpcNodeProperty();
          _reader.readMessage(
            messageInitializer7,
            xprojectorGrpcModels002.GrpcNodeProperty.deserializeBinaryFromReader
          );
          (_instance.edgeProperties = _instance.edgeProperties || []).push(
            messageInitializer7
          );
          break;
        case 8:
          _instance.comment = _reader.readString();
          break;
        case 9:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateReferenceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateReferenceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.sourceId) {
      _writer.writeString(1, _instance.sourceId);
    }
    if (_instance.sourceLabel) {
      _writer.writeString(2, _instance.sourceLabel);
    }
    if (_instance.endId) {
      _writer.writeString(3, _instance.endId);
    }
    if (_instance.endLabel) {
      _writer.writeString(4, _instance.endLabel);
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(5, _instance.edgeTypeId);
    }
    if (_instance.edgeIsreference) {
      _writer.writeBool(6, _instance.edgeIsreference);
    }
    if (_instance.edgeProperties && _instance.edgeProperties.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.edgeProperties as any,
        xprojectorGrpcModels002.GrpcNodeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.comment) {
      _writer.writeString(8, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(9, _instance.customerId);
    }
  }

  private _sourceId?: string;
  private _sourceLabel?: string;
  private _endId?: string;
  private _endLabel?: string;
  private _edgeTypeId?: string;
  private _edgeIsreference?: boolean;
  private _edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty[];
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateReferenceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateReferenceRequest.AsObject>) {
    _value = _value || {};
    this.sourceId = _value.sourceId;
    this.sourceLabel = _value.sourceLabel;
    this.endId = _value.endId;
    this.endLabel = _value.endLabel;
    this.edgeTypeId = _value.edgeTypeId;
    this.edgeIsreference = _value.edgeIsreference;
    this.edgeProperties = (_value.edgeProperties || []).map(
      m => new xprojectorGrpcModels002.GrpcNodeProperty(m)
    );
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    CreateReferenceRequest.refineValues(this);
  }
  get sourceId(): string | undefined {
    return this._sourceId;
  }
  set sourceId(value: string | undefined) {
    this._sourceId = value;
  }
  get sourceLabel(): string | undefined {
    return this._sourceLabel;
  }
  set sourceLabel(value: string | undefined) {
    this._sourceLabel = value;
  }
  get endId(): string | undefined {
    return this._endId;
  }
  set endId(value: string | undefined) {
    this._endId = value;
  }
  get endLabel(): string | undefined {
    return this._endLabel;
  }
  set endLabel(value: string | undefined) {
    this._endLabel = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get edgeIsreference(): boolean | undefined {
    return this._edgeIsreference;
  }
  set edgeIsreference(value: boolean | undefined) {
    this._edgeIsreference = value;
  }
  get edgeProperties(): xprojectorGrpcModels002.GrpcNodeProperty[] | undefined {
    return this._edgeProperties;
  }
  set edgeProperties(
    value: xprojectorGrpcModels002.GrpcNodeProperty[] | undefined
  ) {
    this._edgeProperties = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateReferenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateReferenceRequest.AsObject {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsreference: this.edgeIsreference,
      edgeProperties: (this.edgeProperties || []).map(m => m.toObject()),
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateReferenceRequest.AsProtobufJSON {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      edgeTypeId: this.edgeTypeId,
      edgeIsreference: this.edgeIsreference,
      edgeProperties: (this.edgeProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module CreateReferenceRequest {
  /**
   * Standard JavaScript object representation for CreateReferenceRequest
   */
  export interface AsObject {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    edgeTypeId?: string;
    edgeIsreference?: boolean;
    edgeProperties?: xprojectorGrpcModels002.GrpcNodeProperty.AsObject[];
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for CreateReferenceRequest
   */
  export interface AsProtobufJSON {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    edgeTypeId?: string;
    edgeIsreference?: boolean;
    edgeProperties?:
      | xprojectorGrpcModels002.GrpcNodeProperty.AsProtobufJSON[]
      | null;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.DeleteReferenceRequest
 */
export class DeleteReferenceRequest implements GrpcMessage {
  static id = 'xconf.DeleteReferenceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteReferenceRequest();
    DeleteReferenceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteReferenceRequest) {
    _instance.sourceId = _instance.sourceId || '';
    _instance.sourceLabel = _instance.sourceLabel || '';
    _instance.endId = _instance.endId || '';
    _instance.endLabel = _instance.endLabel || '';
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteReferenceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sourceId = _reader.readString();
          break;
        case 2:
          _instance.sourceLabel = _reader.readString();
          break;
        case 3:
          _instance.endId = _reader.readString();
          break;
        case 4:
          _instance.endLabel = _reader.readString();
          break;
        case 5:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 6:
          _instance.comment = _reader.readString();
          break;
        case 7:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteReferenceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteReferenceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.sourceId) {
      _writer.writeString(1, _instance.sourceId);
    }
    if (_instance.sourceLabel) {
      _writer.writeString(2, _instance.sourceLabel);
    }
    if (_instance.endId) {
      _writer.writeString(3, _instance.endId);
    }
    if (_instance.endLabel) {
      _writer.writeString(4, _instance.endLabel);
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(5, _instance.edgeTypeId);
    }
    if (_instance.comment) {
      _writer.writeString(6, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(7, _instance.customerId);
    }
  }

  private _sourceId?: string;
  private _sourceLabel?: string;
  private _endId?: string;
  private _endLabel?: string;
  private _edgeTypeId?: string;
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteReferenceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteReferenceRequest.AsObject>) {
    _value = _value || {};
    this.sourceId = _value.sourceId;
    this.sourceLabel = _value.sourceLabel;
    this.endId = _value.endId;
    this.endLabel = _value.endLabel;
    this.edgeTypeId = _value.edgeTypeId;
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    DeleteReferenceRequest.refineValues(this);
  }
  get sourceId(): string | undefined {
    return this._sourceId;
  }
  set sourceId(value: string | undefined) {
    this._sourceId = value;
  }
  get sourceLabel(): string | undefined {
    return this._sourceLabel;
  }
  set sourceLabel(value: string | undefined) {
    this._sourceLabel = value;
  }
  get endId(): string | undefined {
    return this._endId;
  }
  set endId(value: string | undefined) {
    this._endId = value;
  }
  get endLabel(): string | undefined {
    return this._endLabel;
  }
  set endLabel(value: string | undefined) {
    this._endLabel = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteReferenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteReferenceRequest.AsObject {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      edgeTypeId: this.edgeTypeId,
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteReferenceRequest.AsProtobufJSON {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      edgeTypeId: this.edgeTypeId,
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module DeleteReferenceRequest {
  /**
   * Standard JavaScript object representation for DeleteReferenceRequest
   */
  export interface AsObject {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    edgeTypeId?: string;
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteReferenceRequest
   */
  export interface AsProtobufJSON {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    edgeTypeId?: string;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.UpdateNodeRequest
 */
export class UpdateNodeRequest implements GrpcMessage {
  static id = 'xconf.UpdateNodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateNodeRequest();
    UpdateNodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateNodeRequest) {
    _instance.node = _instance.node || undefined;
    _instance.oldId = _instance.oldId || '';
    _instance.comment = _instance.comment || '';
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateNodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.oldId = _reader.readString();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateNodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateNodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.oldId) {
      _writer.writeString(2, _instance.oldId);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.customerId) {
      _writer.writeString(4, _instance.customerId);
    }
  }

  private _node?: xprojectorGrpcModels002.GrpcNode;
  private _oldId?: string;
  private _comment?: string;
  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateNodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateNodeRequest.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    this.oldId = _value.oldId;
    this.comment = _value.comment;
    this.customerId = _value.customerId;
    UpdateNodeRequest.refineValues(this);
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }
  get oldId(): string | undefined {
    return this._oldId;
  }
  set oldId(value: string | undefined) {
    this._oldId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateNodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateNodeRequest.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      oldId: this.oldId,
      comment: this.comment,
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateNodeRequest.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      oldId: this.oldId,
      comment: this.comment,
      customerId: this.customerId
    };
  }
}
export module UpdateNodeRequest {
  /**
   * Standard JavaScript object representation for UpdateNodeRequest
   */
  export interface AsObject {
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
    oldId?: string;
    comment?: string;
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for UpdateNodeRequest
   */
  export interface AsProtobufJSON {
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    oldId?: string;
    comment?: string;
    customerId?: string;
  }
}

/**
 * Message implementation for xconf.GrpcBooleanProperty
 */
export class GrpcBooleanProperty implements GrpcMessage {
  static id = 'xconf.GrpcBooleanProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcBooleanProperty();
    GrpcBooleanProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcBooleanProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcBooleanProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcBooleanProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcBooleanProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeBool(100, _instance.defaultValue);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcBooleanProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcBooleanProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    GrpcBooleanProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): boolean | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: boolean | undefined) {
    this._defaultValue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcBooleanProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcBooleanProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcBooleanProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
    };
  }
}
export module GrpcBooleanProperty {
  /**
   * Standard JavaScript object representation for GrpcBooleanProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcBooleanProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcDoubleProperty
 */
export class GrpcDoubleProperty implements GrpcMessage {
  static id = 'xconf.GrpcDoubleProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDoubleProperty();
    GrpcDoubleProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDoubleProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || 0;
    _instance.min = _instance.min || 0;
    _instance.max = _instance.max || 0;
    _instance.step = _instance.step || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDoubleProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readDouble();
          break;
        case 101:
          _instance.min = _reader.readDouble();
          break;
        case 102:
          _instance.max = _reader.readDouble();
          break;
        case 103:
          _instance.step = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDoubleProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDoubleProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeDouble(100, _instance.defaultValue);
    }
    if (_instance.min) {
      _writer.writeDouble(101, _instance.min);
    }
    if (_instance.max) {
      _writer.writeDouble(102, _instance.max);
    }
    if (_instance.step) {
      _writer.writeDouble(103, _instance.step);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: number;
  private _min?: number;
  private _max?: number;
  private _step?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDoubleProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDoubleProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    this.min = _value.min;
    this.max = _value.max;
    this.step = _value.step;
    GrpcDoubleProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): number | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: number | undefined) {
    this._defaultValue = value;
  }
  get min(): number | undefined {
    return this._min;
  }
  set min(value: number | undefined) {
    this._min = value;
  }
  get max(): number | undefined {
    return this._max;
  }
  set max(value: number | undefined) {
    this._max = value;
  }
  get step(): number | undefined {
    return this._step;
  }
  set step(value: number | undefined) {
    this._step = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDoubleProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDoubleProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      min: this.min,
      max: this.max,
      step: this.step
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDoubleProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      min: this.min,
      max: this.max,
      step: this.step
    };
  }
}
export module GrpcDoubleProperty {
  /**
   * Standard JavaScript object representation for GrpcDoubleProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: number;
    min?: number;
    max?: number;
    step?: number;
  }

  /**
   * Protobuf JSON representation for GrpcDoubleProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: number;
    min?: number;
    max?: number;
    step?: number;
  }
}

/**
 * Message implementation for xconf.GrpcDateTimeProperty
 */
export class GrpcDateTimeProperty implements GrpcMessage {
  static id = 'xconf.GrpcDateTimeProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDateTimeProperty();
    GrpcDateTimeProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDateTimeProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || undefined;
    _instance.dateOnly = _instance.dateOnly || false;
    _instance.timeOnly = _instance.timeOnly || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDateTimeProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.defaultValue,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 101:
          _instance.dateOnly = _reader.readBool();
          break;
        case 102:
          _instance.timeOnly = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDateTimeProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDateTimeProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeMessage(
        100,
        _instance.defaultValue as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.dateOnly) {
      _writer.writeBool(101, _instance.dateOnly);
    }
    if (_instance.timeOnly) {
      _writer.writeBool(102, _instance.timeOnly);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: googleProtobuf000.Timestamp;
  private _dateOnly?: boolean;
  private _timeOnly?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDateTimeProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDateTimeProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue
      ? new googleProtobuf000.Timestamp(_value.defaultValue)
      : undefined;
    this.dateOnly = _value.dateOnly;
    this.timeOnly = _value.timeOnly;
    GrpcDateTimeProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): googleProtobuf000.Timestamp | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: googleProtobuf000.Timestamp | undefined) {
    this._defaultValue = value;
  }
  get dateOnly(): boolean | undefined {
    return this._dateOnly;
  }
  set dateOnly(value: boolean | undefined) {
    this._dateOnly = value;
  }
  get timeOnly(): boolean | undefined {
    return this._timeOnly;
  }
  set timeOnly(value: boolean | undefined) {
    this._timeOnly = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDateTimeProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDateTimeProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
        ? this.defaultValue.toObject()
        : undefined,
      dateOnly: this.dateOnly,
      timeOnly: this.timeOnly
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDateTimeProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
        ? this.defaultValue.toProtobufJSON(options)
        : null,
      dateOnly: this.dateOnly,
      timeOnly: this.timeOnly
    };
  }
}
export module GrpcDateTimeProperty {
  /**
   * Standard JavaScript object representation for GrpcDateTimeProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: googleProtobuf000.Timestamp.AsObject;
    dateOnly?: boolean;
    timeOnly?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcDateTimeProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    dateOnly?: boolean;
    timeOnly?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcStringProperty
 */
export class GrpcStringProperty implements GrpcMessage {
  static id = 'xconf.GrpcStringProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcStringProperty();
    GrpcStringProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcStringProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || '';
    _instance.projection = _instance.projection || false;
    _instance.filter = _instance.filter || false;
    _instance.minLength = _instance.minLength || 0;
    _instance.maxLength = _instance.maxLength || 0;
    _instance.password = _instance.password || false;
    _instance.multiline = _instance.multiline || false;
    _instance.multilineRows = _instance.multilineRows || 0;
    _instance.multilineCols = _instance.multilineCols || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcStringProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readString();
          break;
        case 101:
          _instance.projection = _reader.readBool();
          break;
        case 102:
          _instance.filter = _reader.readBool();
          break;
        case 103:
          _instance.minLength = _reader.readInt32();
          break;
        case 104:
          _instance.maxLength = _reader.readInt32();
          break;
        case 105:
          _instance.password = _reader.readBool();
          break;
        case 106:
          _instance.multiline = _reader.readBool();
          break;
        case 107:
          _instance.multilineRows = _reader.readInt32();
          break;
        case 108:
          _instance.multilineCols = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcStringProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcStringProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeString(100, _instance.defaultValue);
    }
    if (_instance.projection) {
      _writer.writeBool(101, _instance.projection);
    }
    if (_instance.filter) {
      _writer.writeBool(102, _instance.filter);
    }
    if (_instance.minLength) {
      _writer.writeInt32(103, _instance.minLength);
    }
    if (_instance.maxLength) {
      _writer.writeInt32(104, _instance.maxLength);
    }
    if (_instance.password) {
      _writer.writeBool(105, _instance.password);
    }
    if (_instance.multiline) {
      _writer.writeBool(106, _instance.multiline);
    }
    if (_instance.multilineRows) {
      _writer.writeInt32(107, _instance.multilineRows);
    }
    if (_instance.multilineCols) {
      _writer.writeInt32(108, _instance.multilineCols);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: string;
  private _projection?: boolean;
  private _filter?: boolean;
  private _minLength?: number;
  private _maxLength?: number;
  private _password?: boolean;
  private _multiline?: boolean;
  private _multilineRows?: number;
  private _multilineCols?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcStringProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcStringProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    this.projection = _value.projection;
    this.filter = _value.filter;
    this.minLength = _value.minLength;
    this.maxLength = _value.maxLength;
    this.password = _value.password;
    this.multiline = _value.multiline;
    this.multilineRows = _value.multilineRows;
    this.multilineCols = _value.multilineCols;
    GrpcStringProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): string | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: string | undefined) {
    this._defaultValue = value;
  }
  get projection(): boolean | undefined {
    return this._projection;
  }
  set projection(value: boolean | undefined) {
    this._projection = value;
  }
  get filter(): boolean | undefined {
    return this._filter;
  }
  set filter(value: boolean | undefined) {
    this._filter = value;
  }
  get minLength(): number | undefined {
    return this._minLength;
  }
  set minLength(value: number | undefined) {
    this._minLength = value;
  }
  get maxLength(): number | undefined {
    return this._maxLength;
  }
  set maxLength(value: number | undefined) {
    this._maxLength = value;
  }
  get password(): boolean | undefined {
    return this._password;
  }
  set password(value: boolean | undefined) {
    this._password = value;
  }
  get multiline(): boolean | undefined {
    return this._multiline;
  }
  set multiline(value: boolean | undefined) {
    this._multiline = value;
  }
  get multilineRows(): number | undefined {
    return this._multilineRows;
  }
  set multilineRows(value: number | undefined) {
    this._multilineRows = value;
  }
  get multilineCols(): number | undefined {
    return this._multilineCols;
  }
  set multilineCols(value: number | undefined) {
    this._multilineCols = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcStringProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcStringProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      projection: this.projection,
      filter: this.filter,
      minLength: this.minLength,
      maxLength: this.maxLength,
      password: this.password,
      multiline: this.multiline,
      multilineRows: this.multilineRows,
      multilineCols: this.multilineCols
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcStringProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      projection: this.projection,
      filter: this.filter,
      minLength: this.minLength,
      maxLength: this.maxLength,
      password: this.password,
      multiline: this.multiline,
      multilineRows: this.multilineRows,
      multilineCols: this.multilineCols
    };
  }
}
export module GrpcStringProperty {
  /**
   * Standard JavaScript object representation for GrpcStringProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    projection?: boolean;
    filter?: boolean;
    minLength?: number;
    maxLength?: number;
    password?: boolean;
    multiline?: boolean;
    multilineRows?: number;
    multilineCols?: number;
  }

  /**
   * Protobuf JSON representation for GrpcStringProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    projection?: boolean;
    filter?: boolean;
    minLength?: number;
    maxLength?: number;
    password?: boolean;
    multiline?: boolean;
    multilineRows?: number;
    multilineCols?: number;
  }
}

/**
 * Message implementation for xconf.GrpcStringArrayProperty
 */
export class GrpcStringArrayProperty implements GrpcMessage {
  static id = 'xconf.GrpcStringArrayProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcStringArrayProperty();
    GrpcStringArrayProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcStringArrayProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || [];
    _instance.projectionGroup = _instance.projectionGroup || false;
    _instance.projectionPropertyId = _instance.projectionPropertyId || '';
    _instance.projectionPropertyParent =
      _instance.projectionPropertyParent || false;
    _instance.optionsRootNodeId = _instance.optionsRootNodeId || '';
    _instance.optionsRootNodeLabel = _instance.optionsRootNodeLabel || '';
    _instance.optionsRootIsCustomerSpecific =
      _instance.optionsRootIsCustomerSpecific || false;
    _instance.optionsNodeAttribute = _instance.optionsNodeAttribute || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcStringArrayProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          (_instance.defaultValue = _instance.defaultValue || []).push(
            _reader.readString()
          );
          break;
        case 101:
          _instance.projectionGroup = _reader.readBool();
          break;
        case 102:
          _instance.projectionPropertyId = _reader.readString();
          break;
        case 103:
          _instance.projectionPropertyParent = _reader.readBool();
          break;
        case 104:
          _instance.optionsRootNodeId = _reader.readString();
          break;
        case 105:
          _instance.optionsRootNodeLabel = _reader.readString();
          break;
        case 106:
          _instance.optionsRootIsCustomerSpecific = _reader.readBool();
          break;
        case 107:
          _instance.optionsNodeAttribute = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcStringArrayProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcStringArrayProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue && _instance.defaultValue.length) {
      _writer.writeRepeatedString(100, _instance.defaultValue);
    }
    if (_instance.projectionGroup) {
      _writer.writeBool(101, _instance.projectionGroup);
    }
    if (_instance.projectionPropertyId) {
      _writer.writeString(102, _instance.projectionPropertyId);
    }
    if (_instance.projectionPropertyParent) {
      _writer.writeBool(103, _instance.projectionPropertyParent);
    }
    if (_instance.optionsRootNodeId) {
      _writer.writeString(104, _instance.optionsRootNodeId);
    }
    if (_instance.optionsRootNodeLabel) {
      _writer.writeString(105, _instance.optionsRootNodeLabel);
    }
    if (_instance.optionsRootIsCustomerSpecific) {
      _writer.writeBool(106, _instance.optionsRootIsCustomerSpecific);
    }
    if (_instance.optionsNodeAttribute) {
      _writer.writeString(107, _instance.optionsNodeAttribute);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: string[];
  private _projectionGroup?: boolean;
  private _projectionPropertyId?: string;
  private _projectionPropertyParent?: boolean;
  private _optionsRootNodeId?: string;
  private _optionsRootNodeLabel?: string;
  private _optionsRootIsCustomerSpecific?: boolean;
  private _optionsNodeAttribute?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcStringArrayProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcStringArrayProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = (_value.defaultValue || []).slice();
    this.projectionGroup = _value.projectionGroup;
    this.projectionPropertyId = _value.projectionPropertyId;
    this.projectionPropertyParent = _value.projectionPropertyParent;
    this.optionsRootNodeId = _value.optionsRootNodeId;
    this.optionsRootNodeLabel = _value.optionsRootNodeLabel;
    this.optionsRootIsCustomerSpecific = _value.optionsRootIsCustomerSpecific;
    this.optionsNodeAttribute = _value.optionsNodeAttribute;
    GrpcStringArrayProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): string[] | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: string[] | undefined) {
    this._defaultValue = value;
  }
  get projectionGroup(): boolean | undefined {
    return this._projectionGroup;
  }
  set projectionGroup(value: boolean | undefined) {
    this._projectionGroup = value;
  }
  get projectionPropertyId(): string | undefined {
    return this._projectionPropertyId;
  }
  set projectionPropertyId(value: string | undefined) {
    this._projectionPropertyId = value;
  }
  get projectionPropertyParent(): boolean | undefined {
    return this._projectionPropertyParent;
  }
  set projectionPropertyParent(value: boolean | undefined) {
    this._projectionPropertyParent = value;
  }
  get optionsRootNodeId(): string | undefined {
    return this._optionsRootNodeId;
  }
  set optionsRootNodeId(value: string | undefined) {
    this._optionsRootNodeId = value;
  }
  get optionsRootNodeLabel(): string | undefined {
    return this._optionsRootNodeLabel;
  }
  set optionsRootNodeLabel(value: string | undefined) {
    this._optionsRootNodeLabel = value;
  }
  get optionsRootIsCustomerSpecific(): boolean | undefined {
    return this._optionsRootIsCustomerSpecific;
  }
  set optionsRootIsCustomerSpecific(value: boolean | undefined) {
    this._optionsRootIsCustomerSpecific = value;
  }
  get optionsNodeAttribute(): string | undefined {
    return this._optionsNodeAttribute;
  }
  set optionsNodeAttribute(value: string | undefined) {
    this._optionsNodeAttribute = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcStringArrayProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcStringArrayProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: (this.defaultValue || []).slice(),
      projectionGroup: this.projectionGroup,
      projectionPropertyId: this.projectionPropertyId,
      projectionPropertyParent: this.projectionPropertyParent,
      optionsRootNodeId: this.optionsRootNodeId,
      optionsRootNodeLabel: this.optionsRootNodeLabel,
      optionsRootIsCustomerSpecific: this.optionsRootIsCustomerSpecific,
      optionsNodeAttribute: this.optionsNodeAttribute
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcStringArrayProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: (this.defaultValue || []).slice(),
      projectionGroup: this.projectionGroup,
      projectionPropertyId: this.projectionPropertyId,
      projectionPropertyParent: this.projectionPropertyParent,
      optionsRootNodeId: this.optionsRootNodeId,
      optionsRootNodeLabel: this.optionsRootNodeLabel,
      optionsRootIsCustomerSpecific: this.optionsRootIsCustomerSpecific,
      optionsNodeAttribute: this.optionsNodeAttribute
    };
  }
}
export module GrpcStringArrayProperty {
  /**
   * Standard JavaScript object representation for GrpcStringArrayProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string[];
    projectionGroup?: boolean;
    projectionPropertyId?: string;
    projectionPropertyParent?: boolean;
    optionsRootNodeId?: string;
    optionsRootNodeLabel?: string;
    optionsRootIsCustomerSpecific?: boolean;
    optionsNodeAttribute?: string;
  }

  /**
   * Protobuf JSON representation for GrpcStringArrayProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string[];
    projectionGroup?: boolean;
    projectionPropertyId?: string;
    projectionPropertyParent?: boolean;
    optionsRootNodeId?: string;
    optionsRootNodeLabel?: string;
    optionsRootIsCustomerSpecific?: boolean;
    optionsNodeAttribute?: string;
  }
}

/**
 * Message implementation for xconf.GrpcDashboardProperty
 */
export class GrpcDashboardProperty implements GrpcMessage {
  static id = 'xconf.GrpcDashboardProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDashboardProperty();
    GrpcDashboardProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDashboardProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || '';
    _instance.systemDashboard = _instance.systemDashboard || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDashboardProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readString();
          break;
        case 101:
          _instance.systemDashboard = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDashboardProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDashboardProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeString(100, _instance.defaultValue);
    }
    if (_instance.systemDashboard) {
      _writer.writeBool(101, _instance.systemDashboard);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: string;
  private _systemDashboard?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDashboardProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDashboardProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    this.systemDashboard = _value.systemDashboard;
    GrpcDashboardProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): string | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: string | undefined) {
    this._defaultValue = value;
  }
  get systemDashboard(): boolean | undefined {
    return this._systemDashboard;
  }
  set systemDashboard(value: boolean | undefined) {
    this._systemDashboard = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDashboardProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDashboardProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      systemDashboard: this.systemDashboard
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDashboardProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      systemDashboard: this.systemDashboard
    };
  }
}
export module GrpcDashboardProperty {
  /**
   * Standard JavaScript object representation for GrpcDashboardProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    systemDashboard?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcDashboardProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    systemDashboard?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcStringOptionsProperty
 */
export class GrpcStringOptionsProperty implements GrpcMessage {
  static id = 'xconf.GrpcStringOptionsProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcStringOptionsProperty();
    GrpcStringOptionsProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcStringOptionsProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || '';
    _instance.options = _instance.options || [];
    _instance.optionsRootNodeId = _instance.optionsRootNodeId || '';
    _instance.optionsRootNodeLabel = _instance.optionsRootNodeLabel || '';
    _instance.optionsRootIsCustomerSpecific =
      _instance.optionsRootIsCustomerSpecific || false;
    _instance.optionsNodeAttribute = _instance.optionsNodeAttribute || '';
    _instance.freetext = _instance.freetext || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcStringOptionsProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readString();
          break;
        case 101:
          (_instance.options = _instance.options || []).push(
            _reader.readString()
          );
          break;
        case 102:
          _instance.optionsRootNodeId = _reader.readString();
          break;
        case 103:
          _instance.optionsRootNodeLabel = _reader.readString();
          break;
        case 104:
          _instance.optionsRootIsCustomerSpecific = _reader.readBool();
          break;
        case 105:
          _instance.optionsNodeAttribute = _reader.readString();
          break;
        case 106:
          _instance.freetext = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcStringOptionsProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcStringOptionsProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeString(100, _instance.defaultValue);
    }
    if (_instance.options && _instance.options.length) {
      _writer.writeRepeatedString(101, _instance.options);
    }
    if (_instance.optionsRootNodeId) {
      _writer.writeString(102, _instance.optionsRootNodeId);
    }
    if (_instance.optionsRootNodeLabel) {
      _writer.writeString(103, _instance.optionsRootNodeLabel);
    }
    if (_instance.optionsRootIsCustomerSpecific) {
      _writer.writeBool(104, _instance.optionsRootIsCustomerSpecific);
    }
    if (_instance.optionsNodeAttribute) {
      _writer.writeString(105, _instance.optionsNodeAttribute);
    }
    if (_instance.freetext) {
      _writer.writeBool(106, _instance.freetext);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: string;
  private _options?: string[];
  private _optionsRootNodeId?: string;
  private _optionsRootNodeLabel?: string;
  private _optionsRootIsCustomerSpecific?: boolean;
  private _optionsNodeAttribute?: string;
  private _freetext?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcStringOptionsProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcStringOptionsProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    this.options = (_value.options || []).slice();
    this.optionsRootNodeId = _value.optionsRootNodeId;
    this.optionsRootNodeLabel = _value.optionsRootNodeLabel;
    this.optionsRootIsCustomerSpecific = _value.optionsRootIsCustomerSpecific;
    this.optionsNodeAttribute = _value.optionsNodeAttribute;
    this.freetext = _value.freetext;
    GrpcStringOptionsProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): string | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: string | undefined) {
    this._defaultValue = value;
  }
  get options(): string[] | undefined {
    return this._options;
  }
  set options(value: string[] | undefined) {
    this._options = value;
  }
  get optionsRootNodeId(): string | undefined {
    return this._optionsRootNodeId;
  }
  set optionsRootNodeId(value: string | undefined) {
    this._optionsRootNodeId = value;
  }
  get optionsRootNodeLabel(): string | undefined {
    return this._optionsRootNodeLabel;
  }
  set optionsRootNodeLabel(value: string | undefined) {
    this._optionsRootNodeLabel = value;
  }
  get optionsRootIsCustomerSpecific(): boolean | undefined {
    return this._optionsRootIsCustomerSpecific;
  }
  set optionsRootIsCustomerSpecific(value: boolean | undefined) {
    this._optionsRootIsCustomerSpecific = value;
  }
  get optionsNodeAttribute(): string | undefined {
    return this._optionsNodeAttribute;
  }
  set optionsNodeAttribute(value: string | undefined) {
    this._optionsNodeAttribute = value;
  }
  get freetext(): boolean | undefined {
    return this._freetext;
  }
  set freetext(value: boolean | undefined) {
    this._freetext = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcStringOptionsProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcStringOptionsProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      options: (this.options || []).slice(),
      optionsRootNodeId: this.optionsRootNodeId,
      optionsRootNodeLabel: this.optionsRootNodeLabel,
      optionsRootIsCustomerSpecific: this.optionsRootIsCustomerSpecific,
      optionsNodeAttribute: this.optionsNodeAttribute,
      freetext: this.freetext
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcStringOptionsProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue,
      options: (this.options || []).slice(),
      optionsRootNodeId: this.optionsRootNodeId,
      optionsRootNodeLabel: this.optionsRootNodeLabel,
      optionsRootIsCustomerSpecific: this.optionsRootIsCustomerSpecific,
      optionsNodeAttribute: this.optionsNodeAttribute,
      freetext: this.freetext
    };
  }
}
export module GrpcStringOptionsProperty {
  /**
   * Standard JavaScript object representation for GrpcStringOptionsProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    options?: string[];
    optionsRootNodeId?: string;
    optionsRootNodeLabel?: string;
    optionsRootIsCustomerSpecific?: boolean;
    optionsNodeAttribute?: string;
    freetext?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcStringOptionsProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: string;
    options?: string[];
    optionsRootNodeId?: string;
    optionsRootNodeLabel?: string;
    optionsRootIsCustomerSpecific?: boolean;
    optionsNodeAttribute?: string;
    freetext?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcTimeSpanProperty
 */
export class GrpcTimeSpanProperty implements GrpcMessage {
  static id = 'xconf.GrpcTimeSpanProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcTimeSpanProperty();
    GrpcTimeSpanProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcTimeSpanProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.id = _instance.id || '';
    _instance.header = _instance.header || '';
    _instance.tooltip = _instance.tooltip || '';
    _instance.category = _instance.category || '';
    _instance.mandatory = _instance.mandatory || false;
    _instance.order = _instance.order || 0;
    _instance.readOnly = _instance.readOnly || false;
    _instance.hidden = _instance.hidden || false;
    _instance.defaultValue = _instance.defaultValue || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcTimeSpanProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.header = _reader.readString();
          break;
        case 4:
          _instance.tooltip = _reader.readString();
          break;
        case 5:
          _instance.category = _reader.readString();
          break;
        case 6:
          _instance.mandatory = _reader.readBool();
          break;
        case 7:
          _instance.order = _reader.readInt32();
          break;
        case 8:
          _instance.readOnly = _reader.readBool();
          break;
        case 9:
          _instance.hidden = _reader.readBool();
          break;
        case 100:
          _instance.defaultValue = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcTimeSpanProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcTimeSpanProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.header) {
      _writer.writeString(3, _instance.header);
    }
    if (_instance.tooltip) {
      _writer.writeString(4, _instance.tooltip);
    }
    if (_instance.category) {
      _writer.writeString(5, _instance.category);
    }
    if (_instance.mandatory) {
      _writer.writeBool(6, _instance.mandatory);
    }
    if (_instance.order) {
      _writer.writeInt32(7, _instance.order);
    }
    if (_instance.readOnly) {
      _writer.writeBool(8, _instance.readOnly);
    }
    if (_instance.hidden) {
      _writer.writeBool(9, _instance.hidden);
    }
    if (_instance.defaultValue) {
      _writer.writeDouble(100, _instance.defaultValue);
    }
  }

  private _typeName?: string;
  private _id?: string;
  private _header?: string;
  private _tooltip?: string;
  private _category?: string;
  private _mandatory?: boolean;
  private _order?: number;
  private _readOnly?: boolean;
  private _hidden?: boolean;
  private _defaultValue?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcTimeSpanProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcTimeSpanProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.id = _value.id;
    this.header = _value.header;
    this.tooltip = _value.tooltip;
    this.category = _value.category;
    this.mandatory = _value.mandatory;
    this.order = _value.order;
    this.readOnly = _value.readOnly;
    this.hidden = _value.hidden;
    this.defaultValue = _value.defaultValue;
    GrpcTimeSpanProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get header(): string | undefined {
    return this._header;
  }
  set header(value: string | undefined) {
    this._header = value;
  }
  get tooltip(): string | undefined {
    return this._tooltip;
  }
  set tooltip(value: string | undefined) {
    this._tooltip = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get mandatory(): boolean | undefined {
    return this._mandatory;
  }
  set mandatory(value: boolean | undefined) {
    this._mandatory = value;
  }
  get order(): number | undefined {
    return this._order;
  }
  set order(value: number | undefined) {
    this._order = value;
  }
  get readOnly(): boolean | undefined {
    return this._readOnly;
  }
  set readOnly(value: boolean | undefined) {
    this._readOnly = value;
  }
  get hidden(): boolean | undefined {
    return this._hidden;
  }
  set hidden(value: boolean | undefined) {
    this._hidden = value;
  }
  get defaultValue(): number | undefined {
    return this._defaultValue;
  }
  set defaultValue(value: number | undefined) {
    this._defaultValue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcTimeSpanProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcTimeSpanProperty.AsObject {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcTimeSpanProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      id: this.id,
      header: this.header,
      tooltip: this.tooltip,
      category: this.category,
      mandatory: this.mandatory,
      order: this.order,
      readOnly: this.readOnly,
      hidden: this.hidden,
      defaultValue: this.defaultValue
    };
  }
}
export module GrpcTimeSpanProperty {
  /**
   * Standard JavaScript object representation for GrpcTimeSpanProperty
   */
  export interface AsObject {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: number;
  }

  /**
   * Protobuf JSON representation for GrpcTimeSpanProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    id?: string;
    header?: string;
    tooltip?: string;
    category?: string;
    mandatory?: boolean;
    order?: number;
    readOnly?: boolean;
    hidden?: boolean;
    defaultValue?: number;
  }
}

/**
 * Message implementation for xconf.GrpcNodeType
 */
export class GrpcNodeType implements GrpcMessage {
  static id = 'xconf.GrpcNodeType';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcNodeType();
    GrpcNodeType.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcNodeType) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.displayableId = _instance.displayableId || '';
    _instance.icon = _instance.icon || '';
    _instance.categories = _instance.categories || [];
    _instance.booleanProperties = _instance.booleanProperties || [];
    _instance.stringProperties = _instance.stringProperties || [];
    _instance.doubleProperties = _instance.doubleProperties || [];
    _instance.dateTimeProperties = _instance.dateTimeProperties || [];
    _instance.timeSpanProperties = _instance.timeSpanProperties || [];
    _instance.stringOptionsProperties = _instance.stringOptionsProperties || [];
    _instance.dashboardProperties = _instance.dashboardProperties || [];
    _instance.stringArrayProperties = _instance.stringArrayProperties || [];
    _instance.systemDefined = _instance.systemDefined || false;
    _instance.autoGenerateId = _instance.autoGenerateId || false;
    _instance.namePropertyId = _instance.namePropertyId || '';
    _instance.namePrefix = _instance.namePrefix || '';
    _instance.shape = _instance.shape || '';
    _instance.alwaysShowName = _instance.alwaysShowName || false;
    _instance.nameHeader = _instance.nameHeader || '';
    _instance.supportsXDataValues = _instance.supportsXDataValues || false;
    _instance.supportsNodeCommands = _instance.supportsNodeCommands || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcNodeType,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.displayableId = _reader.readString();
          break;
        case 4:
          _instance.icon = _reader.readString();
          break;
        case 5:
          (_instance.categories = _instance.categories || []).push(
            _reader.readString()
          );
          break;
        case 6:
          const messageInitializer6 = new GrpcBooleanProperty();
          _reader.readMessage(
            messageInitializer6,
            GrpcBooleanProperty.deserializeBinaryFromReader
          );
          (_instance.booleanProperties =
            _instance.booleanProperties || []).push(messageInitializer6);
          break;
        case 7:
          const messageInitializer7 = new GrpcStringProperty();
          _reader.readMessage(
            messageInitializer7,
            GrpcStringProperty.deserializeBinaryFromReader
          );
          (_instance.stringProperties = _instance.stringProperties || []).push(
            messageInitializer7
          );
          break;
        case 8:
          const messageInitializer8 = new GrpcDoubleProperty();
          _reader.readMessage(
            messageInitializer8,
            GrpcDoubleProperty.deserializeBinaryFromReader
          );
          (_instance.doubleProperties = _instance.doubleProperties || []).push(
            messageInitializer8
          );
          break;
        case 9:
          const messageInitializer9 = new GrpcDateTimeProperty();
          _reader.readMessage(
            messageInitializer9,
            GrpcDateTimeProperty.deserializeBinaryFromReader
          );
          (_instance.dateTimeProperties =
            _instance.dateTimeProperties || []).push(messageInitializer9);
          break;
        case 10:
          const messageInitializer10 = new GrpcTimeSpanProperty();
          _reader.readMessage(
            messageInitializer10,
            GrpcTimeSpanProperty.deserializeBinaryFromReader
          );
          (_instance.timeSpanProperties =
            _instance.timeSpanProperties || []).push(messageInitializer10);
          break;
        case 11:
          const messageInitializer11 = new GrpcStringOptionsProperty();
          _reader.readMessage(
            messageInitializer11,
            GrpcStringOptionsProperty.deserializeBinaryFromReader
          );
          (_instance.stringOptionsProperties =
            _instance.stringOptionsProperties || []).push(messageInitializer11);
          break;
        case 12:
          const messageInitializer12 = new GrpcDashboardProperty();
          _reader.readMessage(
            messageInitializer12,
            GrpcDashboardProperty.deserializeBinaryFromReader
          );
          (_instance.dashboardProperties =
            _instance.dashboardProperties || []).push(messageInitializer12);
          break;
        case 13:
          const messageInitializer13 = new GrpcStringArrayProperty();
          _reader.readMessage(
            messageInitializer13,
            GrpcStringArrayProperty.deserializeBinaryFromReader
          );
          (_instance.stringArrayProperties =
            _instance.stringArrayProperties || []).push(messageInitializer13);
          break;
        case 14:
          _instance.systemDefined = _reader.readBool();
          break;
        case 15:
          _instance.autoGenerateId = _reader.readBool();
          break;
        case 16:
          _instance.namePropertyId = _reader.readString();
          break;
        case 17:
          _instance.namePrefix = _reader.readString();
          break;
        case 18:
          _instance.shape = _reader.readString();
          break;
        case 19:
          _instance.alwaysShowName = _reader.readBool();
          break;
        case 20:
          _instance.nameHeader = _reader.readString();
          break;
        case 21:
          _instance.supportsXDataValues = _reader.readBool();
          break;
        case 22:
          _instance.supportsNodeCommands = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcNodeType.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcNodeType,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.displayableId) {
      _writer.writeString(3, _instance.displayableId);
    }
    if (_instance.icon) {
      _writer.writeString(4, _instance.icon);
    }
    if (_instance.categories && _instance.categories.length) {
      _writer.writeRepeatedString(5, _instance.categories);
    }
    if (_instance.booleanProperties && _instance.booleanProperties.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.booleanProperties as any,
        GrpcBooleanProperty.serializeBinaryToWriter
      );
    }
    if (_instance.stringProperties && _instance.stringProperties.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.stringProperties as any,
        GrpcStringProperty.serializeBinaryToWriter
      );
    }
    if (_instance.doubleProperties && _instance.doubleProperties.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.doubleProperties as any,
        GrpcDoubleProperty.serializeBinaryToWriter
      );
    }
    if (_instance.dateTimeProperties && _instance.dateTimeProperties.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.dateTimeProperties as any,
        GrpcDateTimeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.timeSpanProperties && _instance.timeSpanProperties.length) {
      _writer.writeRepeatedMessage(
        10,
        _instance.timeSpanProperties as any,
        GrpcTimeSpanProperty.serializeBinaryToWriter
      );
    }
    if (
      _instance.stringOptionsProperties &&
      _instance.stringOptionsProperties.length
    ) {
      _writer.writeRepeatedMessage(
        11,
        _instance.stringOptionsProperties as any,
        GrpcStringOptionsProperty.serializeBinaryToWriter
      );
    }
    if (_instance.dashboardProperties && _instance.dashboardProperties.length) {
      _writer.writeRepeatedMessage(
        12,
        _instance.dashboardProperties as any,
        GrpcDashboardProperty.serializeBinaryToWriter
      );
    }
    if (
      _instance.stringArrayProperties &&
      _instance.stringArrayProperties.length
    ) {
      _writer.writeRepeatedMessage(
        13,
        _instance.stringArrayProperties as any,
        GrpcStringArrayProperty.serializeBinaryToWriter
      );
    }
    if (_instance.systemDefined) {
      _writer.writeBool(14, _instance.systemDefined);
    }
    if (_instance.autoGenerateId) {
      _writer.writeBool(15, _instance.autoGenerateId);
    }
    if (_instance.namePropertyId) {
      _writer.writeString(16, _instance.namePropertyId);
    }
    if (_instance.namePrefix) {
      _writer.writeString(17, _instance.namePrefix);
    }
    if (_instance.shape) {
      _writer.writeString(18, _instance.shape);
    }
    if (_instance.alwaysShowName) {
      _writer.writeBool(19, _instance.alwaysShowName);
    }
    if (_instance.nameHeader) {
      _writer.writeString(20, _instance.nameHeader);
    }
    if (_instance.supportsXDataValues) {
      _writer.writeBool(21, _instance.supportsXDataValues);
    }
    if (_instance.supportsNodeCommands) {
      _writer.writeBool(22, _instance.supportsNodeCommands);
    }
  }

  private _id?: string;
  private _label?: string;
  private _displayableId?: string;
  private _icon?: string;
  private _categories?: string[];
  private _booleanProperties?: GrpcBooleanProperty[];
  private _stringProperties?: GrpcStringProperty[];
  private _doubleProperties?: GrpcDoubleProperty[];
  private _dateTimeProperties?: GrpcDateTimeProperty[];
  private _timeSpanProperties?: GrpcTimeSpanProperty[];
  private _stringOptionsProperties?: GrpcStringOptionsProperty[];
  private _dashboardProperties?: GrpcDashboardProperty[];
  private _stringArrayProperties?: GrpcStringArrayProperty[];
  private _systemDefined?: boolean;
  private _autoGenerateId?: boolean;
  private _namePropertyId?: string;
  private _namePrefix?: string;
  private _shape?: string;
  private _alwaysShowName?: boolean;
  private _nameHeader?: string;
  private _supportsXDataValues?: boolean;
  private _supportsNodeCommands?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcNodeType to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcNodeType.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.displayableId = _value.displayableId;
    this.icon = _value.icon;
    this.categories = (_value.categories || []).slice();
    this.booleanProperties = (_value.booleanProperties || []).map(
      m => new GrpcBooleanProperty(m)
    );
    this.stringProperties = (_value.stringProperties || []).map(
      m => new GrpcStringProperty(m)
    );
    this.doubleProperties = (_value.doubleProperties || []).map(
      m => new GrpcDoubleProperty(m)
    );
    this.dateTimeProperties = (_value.dateTimeProperties || []).map(
      m => new GrpcDateTimeProperty(m)
    );
    this.timeSpanProperties = (_value.timeSpanProperties || []).map(
      m => new GrpcTimeSpanProperty(m)
    );
    this.stringOptionsProperties = (_value.stringOptionsProperties || []).map(
      m => new GrpcStringOptionsProperty(m)
    );
    this.dashboardProperties = (_value.dashboardProperties || []).map(
      m => new GrpcDashboardProperty(m)
    );
    this.stringArrayProperties = (_value.stringArrayProperties || []).map(
      m => new GrpcStringArrayProperty(m)
    );
    this.systemDefined = _value.systemDefined;
    this.autoGenerateId = _value.autoGenerateId;
    this.namePropertyId = _value.namePropertyId;
    this.namePrefix = _value.namePrefix;
    this.shape = _value.shape;
    this.alwaysShowName = _value.alwaysShowName;
    this.nameHeader = _value.nameHeader;
    this.supportsXDataValues = _value.supportsXDataValues;
    this.supportsNodeCommands = _value.supportsNodeCommands;
    GrpcNodeType.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get displayableId(): string | undefined {
    return this._displayableId;
  }
  set displayableId(value: string | undefined) {
    this._displayableId = value;
  }
  get icon(): string | undefined {
    return this._icon;
  }
  set icon(value: string | undefined) {
    this._icon = value;
  }
  get categories(): string[] | undefined {
    return this._categories;
  }
  set categories(value: string[] | undefined) {
    this._categories = value;
  }
  get booleanProperties(): GrpcBooleanProperty[] | undefined {
    return this._booleanProperties;
  }
  set booleanProperties(value: GrpcBooleanProperty[] | undefined) {
    this._booleanProperties = value;
  }
  get stringProperties(): GrpcStringProperty[] | undefined {
    return this._stringProperties;
  }
  set stringProperties(value: GrpcStringProperty[] | undefined) {
    this._stringProperties = value;
  }
  get doubleProperties(): GrpcDoubleProperty[] | undefined {
    return this._doubleProperties;
  }
  set doubleProperties(value: GrpcDoubleProperty[] | undefined) {
    this._doubleProperties = value;
  }
  get dateTimeProperties(): GrpcDateTimeProperty[] | undefined {
    return this._dateTimeProperties;
  }
  set dateTimeProperties(value: GrpcDateTimeProperty[] | undefined) {
    this._dateTimeProperties = value;
  }
  get timeSpanProperties(): GrpcTimeSpanProperty[] | undefined {
    return this._timeSpanProperties;
  }
  set timeSpanProperties(value: GrpcTimeSpanProperty[] | undefined) {
    this._timeSpanProperties = value;
  }
  get stringOptionsProperties(): GrpcStringOptionsProperty[] | undefined {
    return this._stringOptionsProperties;
  }
  set stringOptionsProperties(value: GrpcStringOptionsProperty[] | undefined) {
    this._stringOptionsProperties = value;
  }
  get dashboardProperties(): GrpcDashboardProperty[] | undefined {
    return this._dashboardProperties;
  }
  set dashboardProperties(value: GrpcDashboardProperty[] | undefined) {
    this._dashboardProperties = value;
  }
  get stringArrayProperties(): GrpcStringArrayProperty[] | undefined {
    return this._stringArrayProperties;
  }
  set stringArrayProperties(value: GrpcStringArrayProperty[] | undefined) {
    this._stringArrayProperties = value;
  }
  get systemDefined(): boolean | undefined {
    return this._systemDefined;
  }
  set systemDefined(value: boolean | undefined) {
    this._systemDefined = value;
  }
  get autoGenerateId(): boolean | undefined {
    return this._autoGenerateId;
  }
  set autoGenerateId(value: boolean | undefined) {
    this._autoGenerateId = value;
  }
  get namePropertyId(): string | undefined {
    return this._namePropertyId;
  }
  set namePropertyId(value: string | undefined) {
    this._namePropertyId = value;
  }
  get namePrefix(): string | undefined {
    return this._namePrefix;
  }
  set namePrefix(value: string | undefined) {
    this._namePrefix = value;
  }
  get shape(): string | undefined {
    return this._shape;
  }
  set shape(value: string | undefined) {
    this._shape = value;
  }
  get alwaysShowName(): boolean | undefined {
    return this._alwaysShowName;
  }
  set alwaysShowName(value: boolean | undefined) {
    this._alwaysShowName = value;
  }
  get nameHeader(): string | undefined {
    return this._nameHeader;
  }
  set nameHeader(value: string | undefined) {
    this._nameHeader = value;
  }
  get supportsXDataValues(): boolean | undefined {
    return this._supportsXDataValues;
  }
  set supportsXDataValues(value: boolean | undefined) {
    this._supportsXDataValues = value;
  }
  get supportsNodeCommands(): boolean | undefined {
    return this._supportsNodeCommands;
  }
  set supportsNodeCommands(value: boolean | undefined) {
    this._supportsNodeCommands = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcNodeType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcNodeType.AsObject {
    return {
      id: this.id,
      label: this.label,
      displayableId: this.displayableId,
      icon: this.icon,
      categories: (this.categories || []).slice(),
      booleanProperties: (this.booleanProperties || []).map(m => m.toObject()),
      stringProperties: (this.stringProperties || []).map(m => m.toObject()),
      doubleProperties: (this.doubleProperties || []).map(m => m.toObject()),
      dateTimeProperties: (this.dateTimeProperties || []).map(m =>
        m.toObject()
      ),
      timeSpanProperties: (this.timeSpanProperties || []).map(m =>
        m.toObject()
      ),
      stringOptionsProperties: (this.stringOptionsProperties || []).map(m =>
        m.toObject()
      ),
      dashboardProperties: (this.dashboardProperties || []).map(m =>
        m.toObject()
      ),
      stringArrayProperties: (this.stringArrayProperties || []).map(m =>
        m.toObject()
      ),
      systemDefined: this.systemDefined,
      autoGenerateId: this.autoGenerateId,
      namePropertyId: this.namePropertyId,
      namePrefix: this.namePrefix,
      shape: this.shape,
      alwaysShowName: this.alwaysShowName,
      nameHeader: this.nameHeader,
      supportsXDataValues: this.supportsXDataValues,
      supportsNodeCommands: this.supportsNodeCommands
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcNodeType.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      displayableId: this.displayableId,
      icon: this.icon,
      categories: (this.categories || []).slice(),
      booleanProperties: (this.booleanProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringProperties: (this.stringProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      doubleProperties: (this.doubleProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      dateTimeProperties: (this.dateTimeProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      timeSpanProperties: (this.timeSpanProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringOptionsProperties: (this.stringOptionsProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      dashboardProperties: (this.dashboardProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringArrayProperties: (this.stringArrayProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      systemDefined: this.systemDefined,
      autoGenerateId: this.autoGenerateId,
      namePropertyId: this.namePropertyId,
      namePrefix: this.namePrefix,
      shape: this.shape,
      alwaysShowName: this.alwaysShowName,
      nameHeader: this.nameHeader,
      supportsXDataValues: this.supportsXDataValues,
      supportsNodeCommands: this.supportsNodeCommands
    };
  }
}
export module GrpcNodeType {
  /**
   * Standard JavaScript object representation for GrpcNodeType
   */
  export interface AsObject {
    id?: string;
    label?: string;
    displayableId?: string;
    icon?: string;
    categories?: string[];
    booleanProperties?: GrpcBooleanProperty.AsObject[];
    stringProperties?: GrpcStringProperty.AsObject[];
    doubleProperties?: GrpcDoubleProperty.AsObject[];
    dateTimeProperties?: GrpcDateTimeProperty.AsObject[];
    timeSpanProperties?: GrpcTimeSpanProperty.AsObject[];
    stringOptionsProperties?: GrpcStringOptionsProperty.AsObject[];
    dashboardProperties?: GrpcDashboardProperty.AsObject[];
    stringArrayProperties?: GrpcStringArrayProperty.AsObject[];
    systemDefined?: boolean;
    autoGenerateId?: boolean;
    namePropertyId?: string;
    namePrefix?: string;
    shape?: string;
    alwaysShowName?: boolean;
    nameHeader?: string;
    supportsXDataValues?: boolean;
    supportsNodeCommands?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcNodeType
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    displayableId?: string;
    icon?: string;
    categories?: string[];
    booleanProperties?: GrpcBooleanProperty.AsProtobufJSON[] | null;
    stringProperties?: GrpcStringProperty.AsProtobufJSON[] | null;
    doubleProperties?: GrpcDoubleProperty.AsProtobufJSON[] | null;
    dateTimeProperties?: GrpcDateTimeProperty.AsProtobufJSON[] | null;
    timeSpanProperties?: GrpcTimeSpanProperty.AsProtobufJSON[] | null;
    stringOptionsProperties?: GrpcStringOptionsProperty.AsProtobufJSON[] | null;
    dashboardProperties?: GrpcDashboardProperty.AsProtobufJSON[] | null;
    stringArrayProperties?: GrpcStringArrayProperty.AsProtobufJSON[] | null;
    systemDefined?: boolean;
    autoGenerateId?: boolean;
    namePropertyId?: string;
    namePrefix?: string;
    shape?: string;
    alwaysShowName?: boolean;
    nameHeader?: string;
    supportsXDataValues?: boolean;
    supportsNodeCommands?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcEdgeType
 */
export class GrpcEdgeType implements GrpcMessage {
  static id = 'xconf.GrpcEdgeType';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcEdgeType();
    GrpcEdgeType.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcEdgeType) {
    _instance.id = _instance.id || '';
    _instance.displayableId = _instance.displayableId || '';
    _instance.categories = _instance.categories || [];
    _instance.isReference = _instance.isReference || false;
    _instance.booleanProperties = _instance.booleanProperties || [];
    _instance.stringProperties = _instance.stringProperties || [];
    _instance.doubleProperties = _instance.doubleProperties || [];
    _instance.dateTimeProperties = _instance.dateTimeProperties || [];
    _instance.timeSpanProperties = _instance.timeSpanProperties || [];
    _instance.stringOptionsProperties = _instance.stringOptionsProperties || [];
    _instance.dashboardProperties = _instance.dashboardProperties || [];
    _instance.stringArrayProperties = _instance.stringArrayProperties || [];
    _instance.systemDefined = _instance.systemDefined || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcEdgeType,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.displayableId = _reader.readString();
          break;
        case 3:
          (_instance.categories = _instance.categories || []).push(
            _reader.readString()
          );
          break;
        case 4:
          _instance.isReference = _reader.readBool();
          break;
        case 5:
          const messageInitializer5 = new GrpcBooleanProperty();
          _reader.readMessage(
            messageInitializer5,
            GrpcBooleanProperty.deserializeBinaryFromReader
          );
          (_instance.booleanProperties =
            _instance.booleanProperties || []).push(messageInitializer5);
          break;
        case 6:
          const messageInitializer6 = new GrpcStringProperty();
          _reader.readMessage(
            messageInitializer6,
            GrpcStringProperty.deserializeBinaryFromReader
          );
          (_instance.stringProperties = _instance.stringProperties || []).push(
            messageInitializer6
          );
          break;
        case 7:
          const messageInitializer7 = new GrpcDoubleProperty();
          _reader.readMessage(
            messageInitializer7,
            GrpcDoubleProperty.deserializeBinaryFromReader
          );
          (_instance.doubleProperties = _instance.doubleProperties || []).push(
            messageInitializer7
          );
          break;
        case 8:
          const messageInitializer8 = new GrpcDateTimeProperty();
          _reader.readMessage(
            messageInitializer8,
            GrpcDateTimeProperty.deserializeBinaryFromReader
          );
          (_instance.dateTimeProperties =
            _instance.dateTimeProperties || []).push(messageInitializer8);
          break;
        case 9:
          const messageInitializer9 = new GrpcTimeSpanProperty();
          _reader.readMessage(
            messageInitializer9,
            GrpcTimeSpanProperty.deserializeBinaryFromReader
          );
          (_instance.timeSpanProperties =
            _instance.timeSpanProperties || []).push(messageInitializer9);
          break;
        case 10:
          const messageInitializer10 = new GrpcStringOptionsProperty();
          _reader.readMessage(
            messageInitializer10,
            GrpcStringOptionsProperty.deserializeBinaryFromReader
          );
          (_instance.stringOptionsProperties =
            _instance.stringOptionsProperties || []).push(messageInitializer10);
          break;
        case 11:
          const messageInitializer11 = new GrpcDashboardProperty();
          _reader.readMessage(
            messageInitializer11,
            GrpcDashboardProperty.deserializeBinaryFromReader
          );
          (_instance.dashboardProperties =
            _instance.dashboardProperties || []).push(messageInitializer11);
          break;
        case 12:
          const messageInitializer12 = new GrpcStringArrayProperty();
          _reader.readMessage(
            messageInitializer12,
            GrpcStringArrayProperty.deserializeBinaryFromReader
          );
          (_instance.stringArrayProperties =
            _instance.stringArrayProperties || []).push(messageInitializer12);
          break;
        case 13:
          _instance.systemDefined = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcEdgeType.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcEdgeType,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.displayableId) {
      _writer.writeString(2, _instance.displayableId);
    }
    if (_instance.categories && _instance.categories.length) {
      _writer.writeRepeatedString(3, _instance.categories);
    }
    if (_instance.isReference) {
      _writer.writeBool(4, _instance.isReference);
    }
    if (_instance.booleanProperties && _instance.booleanProperties.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.booleanProperties as any,
        GrpcBooleanProperty.serializeBinaryToWriter
      );
    }
    if (_instance.stringProperties && _instance.stringProperties.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.stringProperties as any,
        GrpcStringProperty.serializeBinaryToWriter
      );
    }
    if (_instance.doubleProperties && _instance.doubleProperties.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.doubleProperties as any,
        GrpcDoubleProperty.serializeBinaryToWriter
      );
    }
    if (_instance.dateTimeProperties && _instance.dateTimeProperties.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.dateTimeProperties as any,
        GrpcDateTimeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.timeSpanProperties && _instance.timeSpanProperties.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.timeSpanProperties as any,
        GrpcTimeSpanProperty.serializeBinaryToWriter
      );
    }
    if (
      _instance.stringOptionsProperties &&
      _instance.stringOptionsProperties.length
    ) {
      _writer.writeRepeatedMessage(
        10,
        _instance.stringOptionsProperties as any,
        GrpcStringOptionsProperty.serializeBinaryToWriter
      );
    }
    if (_instance.dashboardProperties && _instance.dashboardProperties.length) {
      _writer.writeRepeatedMessage(
        11,
        _instance.dashboardProperties as any,
        GrpcDashboardProperty.serializeBinaryToWriter
      );
    }
    if (
      _instance.stringArrayProperties &&
      _instance.stringArrayProperties.length
    ) {
      _writer.writeRepeatedMessage(
        12,
        _instance.stringArrayProperties as any,
        GrpcStringArrayProperty.serializeBinaryToWriter
      );
    }
    if (_instance.systemDefined) {
      _writer.writeBool(13, _instance.systemDefined);
    }
  }

  private _id?: string;
  private _displayableId?: string;
  private _categories?: string[];
  private _isReference?: boolean;
  private _booleanProperties?: GrpcBooleanProperty[];
  private _stringProperties?: GrpcStringProperty[];
  private _doubleProperties?: GrpcDoubleProperty[];
  private _dateTimeProperties?: GrpcDateTimeProperty[];
  private _timeSpanProperties?: GrpcTimeSpanProperty[];
  private _stringOptionsProperties?: GrpcStringOptionsProperty[];
  private _dashboardProperties?: GrpcDashboardProperty[];
  private _stringArrayProperties?: GrpcStringArrayProperty[];
  private _systemDefined?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcEdgeType to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcEdgeType.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.displayableId = _value.displayableId;
    this.categories = (_value.categories || []).slice();
    this.isReference = _value.isReference;
    this.booleanProperties = (_value.booleanProperties || []).map(
      m => new GrpcBooleanProperty(m)
    );
    this.stringProperties = (_value.stringProperties || []).map(
      m => new GrpcStringProperty(m)
    );
    this.doubleProperties = (_value.doubleProperties || []).map(
      m => new GrpcDoubleProperty(m)
    );
    this.dateTimeProperties = (_value.dateTimeProperties || []).map(
      m => new GrpcDateTimeProperty(m)
    );
    this.timeSpanProperties = (_value.timeSpanProperties || []).map(
      m => new GrpcTimeSpanProperty(m)
    );
    this.stringOptionsProperties = (_value.stringOptionsProperties || []).map(
      m => new GrpcStringOptionsProperty(m)
    );
    this.dashboardProperties = (_value.dashboardProperties || []).map(
      m => new GrpcDashboardProperty(m)
    );
    this.stringArrayProperties = (_value.stringArrayProperties || []).map(
      m => new GrpcStringArrayProperty(m)
    );
    this.systemDefined = _value.systemDefined;
    GrpcEdgeType.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get displayableId(): string | undefined {
    return this._displayableId;
  }
  set displayableId(value: string | undefined) {
    this._displayableId = value;
  }
  get categories(): string[] | undefined {
    return this._categories;
  }
  set categories(value: string[] | undefined) {
    this._categories = value;
  }
  get isReference(): boolean | undefined {
    return this._isReference;
  }
  set isReference(value: boolean | undefined) {
    this._isReference = value;
  }
  get booleanProperties(): GrpcBooleanProperty[] | undefined {
    return this._booleanProperties;
  }
  set booleanProperties(value: GrpcBooleanProperty[] | undefined) {
    this._booleanProperties = value;
  }
  get stringProperties(): GrpcStringProperty[] | undefined {
    return this._stringProperties;
  }
  set stringProperties(value: GrpcStringProperty[] | undefined) {
    this._stringProperties = value;
  }
  get doubleProperties(): GrpcDoubleProperty[] | undefined {
    return this._doubleProperties;
  }
  set doubleProperties(value: GrpcDoubleProperty[] | undefined) {
    this._doubleProperties = value;
  }
  get dateTimeProperties(): GrpcDateTimeProperty[] | undefined {
    return this._dateTimeProperties;
  }
  set dateTimeProperties(value: GrpcDateTimeProperty[] | undefined) {
    this._dateTimeProperties = value;
  }
  get timeSpanProperties(): GrpcTimeSpanProperty[] | undefined {
    return this._timeSpanProperties;
  }
  set timeSpanProperties(value: GrpcTimeSpanProperty[] | undefined) {
    this._timeSpanProperties = value;
  }
  get stringOptionsProperties(): GrpcStringOptionsProperty[] | undefined {
    return this._stringOptionsProperties;
  }
  set stringOptionsProperties(value: GrpcStringOptionsProperty[] | undefined) {
    this._stringOptionsProperties = value;
  }
  get dashboardProperties(): GrpcDashboardProperty[] | undefined {
    return this._dashboardProperties;
  }
  set dashboardProperties(value: GrpcDashboardProperty[] | undefined) {
    this._dashboardProperties = value;
  }
  get stringArrayProperties(): GrpcStringArrayProperty[] | undefined {
    return this._stringArrayProperties;
  }
  set stringArrayProperties(value: GrpcStringArrayProperty[] | undefined) {
    this._stringArrayProperties = value;
  }
  get systemDefined(): boolean | undefined {
    return this._systemDefined;
  }
  set systemDefined(value: boolean | undefined) {
    this._systemDefined = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcEdgeType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcEdgeType.AsObject {
    return {
      id: this.id,
      displayableId: this.displayableId,
      categories: (this.categories || []).slice(),
      isReference: this.isReference,
      booleanProperties: (this.booleanProperties || []).map(m => m.toObject()),
      stringProperties: (this.stringProperties || []).map(m => m.toObject()),
      doubleProperties: (this.doubleProperties || []).map(m => m.toObject()),
      dateTimeProperties: (this.dateTimeProperties || []).map(m =>
        m.toObject()
      ),
      timeSpanProperties: (this.timeSpanProperties || []).map(m =>
        m.toObject()
      ),
      stringOptionsProperties: (this.stringOptionsProperties || []).map(m =>
        m.toObject()
      ),
      dashboardProperties: (this.dashboardProperties || []).map(m =>
        m.toObject()
      ),
      stringArrayProperties: (this.stringArrayProperties || []).map(m =>
        m.toObject()
      ),
      systemDefined: this.systemDefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcEdgeType.AsProtobufJSON {
    return {
      id: this.id,
      displayableId: this.displayableId,
      categories: (this.categories || []).slice(),
      isReference: this.isReference,
      booleanProperties: (this.booleanProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringProperties: (this.stringProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      doubleProperties: (this.doubleProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      dateTimeProperties: (this.dateTimeProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      timeSpanProperties: (this.timeSpanProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringOptionsProperties: (this.stringOptionsProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      dashboardProperties: (this.dashboardProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      stringArrayProperties: (this.stringArrayProperties || []).map(m =>
        m.toProtobufJSON(options)
      ),
      systemDefined: this.systemDefined
    };
  }
}
export module GrpcEdgeType {
  /**
   * Standard JavaScript object representation for GrpcEdgeType
   */
  export interface AsObject {
    id?: string;
    displayableId?: string;
    categories?: string[];
    isReference?: boolean;
    booleanProperties?: GrpcBooleanProperty.AsObject[];
    stringProperties?: GrpcStringProperty.AsObject[];
    doubleProperties?: GrpcDoubleProperty.AsObject[];
    dateTimeProperties?: GrpcDateTimeProperty.AsObject[];
    timeSpanProperties?: GrpcTimeSpanProperty.AsObject[];
    stringOptionsProperties?: GrpcStringOptionsProperty.AsObject[];
    dashboardProperties?: GrpcDashboardProperty.AsObject[];
    stringArrayProperties?: GrpcStringArrayProperty.AsObject[];
    systemDefined?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcEdgeType
   */
  export interface AsProtobufJSON {
    id?: string;
    displayableId?: string;
    categories?: string[];
    isReference?: boolean;
    booleanProperties?: GrpcBooleanProperty.AsProtobufJSON[] | null;
    stringProperties?: GrpcStringProperty.AsProtobufJSON[] | null;
    doubleProperties?: GrpcDoubleProperty.AsProtobufJSON[] | null;
    dateTimeProperties?: GrpcDateTimeProperty.AsProtobufJSON[] | null;
    timeSpanProperties?: GrpcTimeSpanProperty.AsProtobufJSON[] | null;
    stringOptionsProperties?: GrpcStringOptionsProperty.AsProtobufJSON[] | null;
    dashboardProperties?: GrpcDashboardProperty.AsProtobufJSON[] | null;
    stringArrayProperties?: GrpcStringArrayProperty.AsProtobufJSON[] | null;
    systemDefined?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcTreeNodeReferenceDefinition
 */
export class GrpcTreeNodeReferenceDefinition implements GrpcMessage {
  static id = 'xconf.GrpcTreeNodeReferenceDefinition';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcTreeNodeReferenceDefinition();
    GrpcTreeNodeReferenceDefinition.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcTreeNodeReferenceDefinition) {
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.edgesLimit = _instance.edgesLimit || 0;
    _instance.referenceRootId = _instance.referenceRootId || '';
    _instance.referenceRootLabel = _instance.referenceRootLabel || '';
    _instance.isCustomerReference = _instance.isCustomerReference || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcTreeNodeReferenceDefinition,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 2:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 3:
          _instance.edgesLimit = _reader.readInt32();
          break;
        case 4:
          _instance.referenceRootId = _reader.readString();
          break;
        case 5:
          _instance.referenceRootLabel = _reader.readString();
          break;
        case 6:
          _instance.isCustomerReference = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcTreeNodeReferenceDefinition.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcTreeNodeReferenceDefinition,
    _writer: BinaryWriter
  ) {
    if (_instance.edgeTypeId) {
      _writer.writeString(1, _instance.edgeTypeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(2, _instance.nodeTypeId);
    }
    if (_instance.edgesLimit) {
      _writer.writeInt32(3, _instance.edgesLimit);
    }
    if (_instance.referenceRootId) {
      _writer.writeString(4, _instance.referenceRootId);
    }
    if (_instance.referenceRootLabel) {
      _writer.writeString(5, _instance.referenceRootLabel);
    }
    if (_instance.isCustomerReference) {
      _writer.writeBool(6, _instance.isCustomerReference);
    }
  }

  private _edgeTypeId?: string;
  private _nodeTypeId?: string;
  private _edgesLimit?: number;
  private _referenceRootId?: string;
  private _referenceRootLabel?: string;
  private _isCustomerReference?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcTreeNodeReferenceDefinition to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GrpcTreeNodeReferenceDefinition.AsObject>
  ) {
    _value = _value || {};
    this.edgeTypeId = _value.edgeTypeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.edgesLimit = _value.edgesLimit;
    this.referenceRootId = _value.referenceRootId;
    this.referenceRootLabel = _value.referenceRootLabel;
    this.isCustomerReference = _value.isCustomerReference;
    GrpcTreeNodeReferenceDefinition.refineValues(this);
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get edgesLimit(): number | undefined {
    return this._edgesLimit;
  }
  set edgesLimit(value: number | undefined) {
    this._edgesLimit = value;
  }
  get referenceRootId(): string | undefined {
    return this._referenceRootId;
  }
  set referenceRootId(value: string | undefined) {
    this._referenceRootId = value;
  }
  get referenceRootLabel(): string | undefined {
    return this._referenceRootLabel;
  }
  set referenceRootLabel(value: string | undefined) {
    this._referenceRootLabel = value;
  }
  get isCustomerReference(): boolean | undefined {
    return this._isCustomerReference;
  }
  set isCustomerReference(value: boolean | undefined) {
    this._isCustomerReference = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcTreeNodeReferenceDefinition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcTreeNodeReferenceDefinition.AsObject {
    return {
      edgeTypeId: this.edgeTypeId,
      nodeTypeId: this.nodeTypeId,
      edgesLimit: this.edgesLimit,
      referenceRootId: this.referenceRootId,
      referenceRootLabel: this.referenceRootLabel,
      isCustomerReference: this.isCustomerReference
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcTreeNodeReferenceDefinition.AsProtobufJSON {
    return {
      edgeTypeId: this.edgeTypeId,
      nodeTypeId: this.nodeTypeId,
      edgesLimit: this.edgesLimit,
      referenceRootId: this.referenceRootId,
      referenceRootLabel: this.referenceRootLabel,
      isCustomerReference: this.isCustomerReference
    };
  }
}
export module GrpcTreeNodeReferenceDefinition {
  /**
   * Standard JavaScript object representation for GrpcTreeNodeReferenceDefinition
   */
  export interface AsObject {
    edgeTypeId?: string;
    nodeTypeId?: string;
    edgesLimit?: number;
    referenceRootId?: string;
    referenceRootLabel?: string;
    isCustomerReference?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcTreeNodeReferenceDefinition
   */
  export interface AsProtobufJSON {
    edgeTypeId?: string;
    nodeTypeId?: string;
    edgesLimit?: number;
    referenceRootId?: string;
    referenceRootLabel?: string;
    isCustomerReference?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcTreeNodeDefinition
 */
export class GrpcTreeNodeDefinition implements GrpcMessage {
  static id = 'xconf.GrpcTreeNodeDefinition';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcTreeNodeDefinition();
    GrpcTreeNodeDefinition.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcTreeNodeDefinition) {
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.treeNodeReferenceDefinitions =
      _instance.treeNodeReferenceDefinitions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcTreeNodeDefinition,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new GrpcTreeNodeReferenceDefinition();
          _reader.readMessage(
            messageInitializer3,
            GrpcTreeNodeReferenceDefinition.deserializeBinaryFromReader
          );
          (_instance.treeNodeReferenceDefinitions =
            _instance.treeNodeReferenceDefinitions || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcTreeNodeDefinition.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcTreeNodeDefinition,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeTypeId) {
      _writer.writeString(1, _instance.nodeTypeId);
    }
    if (
      _instance.treeNodeReferenceDefinitions &&
      _instance.treeNodeReferenceDefinitions.length
    ) {
      _writer.writeRepeatedMessage(
        3,
        _instance.treeNodeReferenceDefinitions as any,
        GrpcTreeNodeReferenceDefinition.serializeBinaryToWriter
      );
    }
  }

  private _nodeTypeId?: string;
  private _treeNodeReferenceDefinitions?: GrpcTreeNodeReferenceDefinition[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcTreeNodeDefinition to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcTreeNodeDefinition.AsObject>) {
    _value = _value || {};
    this.nodeTypeId = _value.nodeTypeId;
    this.treeNodeReferenceDefinitions = (
      _value.treeNodeReferenceDefinitions || []
    ).map(m => new GrpcTreeNodeReferenceDefinition(m));
    GrpcTreeNodeDefinition.refineValues(this);
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get treeNodeReferenceDefinitions():
    | GrpcTreeNodeReferenceDefinition[]
    | undefined {
    return this._treeNodeReferenceDefinitions;
  }
  set treeNodeReferenceDefinitions(
    value: GrpcTreeNodeReferenceDefinition[] | undefined
  ) {
    this._treeNodeReferenceDefinitions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcTreeNodeDefinition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcTreeNodeDefinition.AsObject {
    return {
      nodeTypeId: this.nodeTypeId,
      treeNodeReferenceDefinitions: (
        this.treeNodeReferenceDefinitions || []
      ).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcTreeNodeDefinition.AsProtobufJSON {
    return {
      nodeTypeId: this.nodeTypeId,
      treeNodeReferenceDefinitions: (
        this.treeNodeReferenceDefinitions || []
      ).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GrpcTreeNodeDefinition {
  /**
   * Standard JavaScript object representation for GrpcTreeNodeDefinition
   */
  export interface AsObject {
    nodeTypeId?: string;
    treeNodeReferenceDefinitions?: GrpcTreeNodeReferenceDefinition.AsObject[];
  }

  /**
   * Protobuf JSON representation for GrpcTreeNodeDefinition
   */
  export interface AsProtobufJSON {
    nodeTypeId?: string;
    treeNodeReferenceDefinitions?:
      | GrpcTreeNodeReferenceDefinition.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for xconf.GrpcDataSourceDefinition
 */
export class GrpcDataSourceDefinition implements GrpcMessage {
  static id = 'xconf.GrpcDataSourceDefinition';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDataSourceDefinition();
    GrpcDataSourceDefinition.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDataSourceDefinition) {
    _instance.id = _instance.id || '';
    _instance.rootNodeTypeIds = _instance.rootNodeTypeIds || [];
    _instance.treeNodeDefinitions = _instance.treeNodeDefinitions || [];
    _instance.systemDefined = _instance.systemDefined || false;
    _instance.customerInstance = _instance.customerInstance || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDataSourceDefinition,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          (_instance.rootNodeTypeIds = _instance.rootNodeTypeIds || []).push(
            _reader.readString()
          );
          break;
        case 3:
          const messageInitializer3 = new GrpcTreeNodeDefinition();
          _reader.readMessage(
            messageInitializer3,
            GrpcTreeNodeDefinition.deserializeBinaryFromReader
          );
          (_instance.treeNodeDefinitions =
            _instance.treeNodeDefinitions || []).push(messageInitializer3);
          break;
        case 4:
          _instance.systemDefined = _reader.readBool();
          break;
        case 5:
          _instance.customerInstance = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDataSourceDefinition.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDataSourceDefinition,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.rootNodeTypeIds && _instance.rootNodeTypeIds.length) {
      _writer.writeRepeatedString(2, _instance.rootNodeTypeIds);
    }
    if (_instance.treeNodeDefinitions && _instance.treeNodeDefinitions.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.treeNodeDefinitions as any,
        GrpcTreeNodeDefinition.serializeBinaryToWriter
      );
    }
    if (_instance.systemDefined) {
      _writer.writeBool(4, _instance.systemDefined);
    }
    if (_instance.customerInstance) {
      _writer.writeBool(5, _instance.customerInstance);
    }
  }

  private _id?: string;
  private _rootNodeTypeIds?: string[];
  private _treeNodeDefinitions?: GrpcTreeNodeDefinition[];
  private _systemDefined?: boolean;
  private _customerInstance?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDataSourceDefinition to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDataSourceDefinition.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.rootNodeTypeIds = (_value.rootNodeTypeIds || []).slice();
    this.treeNodeDefinitions = (_value.treeNodeDefinitions || []).map(
      m => new GrpcTreeNodeDefinition(m)
    );
    this.systemDefined = _value.systemDefined;
    this.customerInstance = _value.customerInstance;
    GrpcDataSourceDefinition.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get rootNodeTypeIds(): string[] | undefined {
    return this._rootNodeTypeIds;
  }
  set rootNodeTypeIds(value: string[] | undefined) {
    this._rootNodeTypeIds = value;
  }
  get treeNodeDefinitions(): GrpcTreeNodeDefinition[] | undefined {
    return this._treeNodeDefinitions;
  }
  set treeNodeDefinitions(value: GrpcTreeNodeDefinition[] | undefined) {
    this._treeNodeDefinitions = value;
  }
  get systemDefined(): boolean | undefined {
    return this._systemDefined;
  }
  set systemDefined(value: boolean | undefined) {
    this._systemDefined = value;
  }
  get customerInstance(): boolean | undefined {
    return this._customerInstance;
  }
  set customerInstance(value: boolean | undefined) {
    this._customerInstance = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDataSourceDefinition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDataSourceDefinition.AsObject {
    return {
      id: this.id,
      rootNodeTypeIds: (this.rootNodeTypeIds || []).slice(),
      treeNodeDefinitions: (this.treeNodeDefinitions || []).map(m =>
        m.toObject()
      ),
      systemDefined: this.systemDefined,
      customerInstance: this.customerInstance
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDataSourceDefinition.AsProtobufJSON {
    return {
      id: this.id,
      rootNodeTypeIds: (this.rootNodeTypeIds || []).slice(),
      treeNodeDefinitions: (this.treeNodeDefinitions || []).map(m =>
        m.toProtobufJSON(options)
      ),
      systemDefined: this.systemDefined,
      customerInstance: this.customerInstance
    };
  }
}
export module GrpcDataSourceDefinition {
  /**
   * Standard JavaScript object representation for GrpcDataSourceDefinition
   */
  export interface AsObject {
    id?: string;
    rootNodeTypeIds?: string[];
    treeNodeDefinitions?: GrpcTreeNodeDefinition.AsObject[];
    systemDefined?: boolean;
    customerInstance?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcDataSourceDefinition
   */
  export interface AsProtobufJSON {
    id?: string;
    rootNodeTypeIds?: string[];
    treeNodeDefinitions?: GrpcTreeNodeDefinition.AsProtobufJSON[] | null;
    systemDefined?: boolean;
    customerInstance?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcRootNodeInstance
 */
export class GrpcRootNodeInstance implements GrpcMessage {
  static id = 'xconf.GrpcRootNodeInstance';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcRootNodeInstance();
    GrpcRootNodeInstance.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcRootNodeInstance) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcRootNodeInstance,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcRootNodeInstance.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcRootNodeInstance,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
  }

  private _id?: string;
  private _label?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcRootNodeInstance to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcRootNodeInstance.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    GrpcRootNodeInstance.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcRootNodeInstance.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcRootNodeInstance.AsObject {
    return {
      id: this.id,
      label: this.label
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcRootNodeInstance.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label
    };
  }
}
export module GrpcRootNodeInstance {
  /**
   * Standard JavaScript object representation for GrpcRootNodeInstance
   */
  export interface AsObject {
    id?: string;
    label?: string;
  }

  /**
   * Protobuf JSON representation for GrpcRootNodeInstance
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
  }
}

/**
 * Message implementation for xconf.GrpcDataSourceInstance
 */
export class GrpcDataSourceInstance implements GrpcMessage {
  static id = 'xconf.GrpcDataSourceInstance';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDataSourceInstance();
    GrpcDataSourceInstance.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDataSourceInstance) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.dataSourceDefinitionId = _instance.dataSourceDefinitionId || '';
    _instance.rootNodeInstances = _instance.rootNodeInstances || [];
    _instance.parentId = _instance.parentId || '';
    _instance.parentLabel = _instance.parentLabel || '';
    _instance.systemDefined = _instance.systemDefined || false;
    _instance.customerInstance = _instance.customerInstance || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDataSourceInstance,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.dataSourceDefinitionId = _reader.readString();
          break;
        case 4:
          const messageInitializer4 = new GrpcRootNodeInstance();
          _reader.readMessage(
            messageInitializer4,
            GrpcRootNodeInstance.deserializeBinaryFromReader
          );
          (_instance.rootNodeInstances =
            _instance.rootNodeInstances || []).push(messageInitializer4);
          break;
        case 5:
          _instance.parentId = _reader.readString();
          break;
        case 6:
          _instance.parentLabel = _reader.readString();
          break;
        case 7:
          _instance.systemDefined = _reader.readBool();
          break;
        case 8:
          _instance.customerInstance = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDataSourceInstance.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDataSourceInstance,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.dataSourceDefinitionId) {
      _writer.writeString(3, _instance.dataSourceDefinitionId);
    }
    if (_instance.rootNodeInstances && _instance.rootNodeInstances.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.rootNodeInstances as any,
        GrpcRootNodeInstance.serializeBinaryToWriter
      );
    }
    if (_instance.parentId) {
      _writer.writeString(5, _instance.parentId);
    }
    if (_instance.parentLabel) {
      _writer.writeString(6, _instance.parentLabel);
    }
    if (_instance.systemDefined) {
      _writer.writeBool(7, _instance.systemDefined);
    }
    if (_instance.customerInstance) {
      _writer.writeBool(8, _instance.customerInstance);
    }
  }

  private _id?: string;
  private _name?: string;
  private _dataSourceDefinitionId?: string;
  private _rootNodeInstances?: GrpcRootNodeInstance[];
  private _parentId?: string;
  private _parentLabel?: string;
  private _systemDefined?: boolean;
  private _customerInstance?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDataSourceInstance to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDataSourceInstance.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.dataSourceDefinitionId = _value.dataSourceDefinitionId;
    this.rootNodeInstances = (_value.rootNodeInstances || []).map(
      m => new GrpcRootNodeInstance(m)
    );
    this.parentId = _value.parentId;
    this.parentLabel = _value.parentLabel;
    this.systemDefined = _value.systemDefined;
    this.customerInstance = _value.customerInstance;
    GrpcDataSourceInstance.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get dataSourceDefinitionId(): string | undefined {
    return this._dataSourceDefinitionId;
  }
  set dataSourceDefinitionId(value: string | undefined) {
    this._dataSourceDefinitionId = value;
  }
  get rootNodeInstances(): GrpcRootNodeInstance[] | undefined {
    return this._rootNodeInstances;
  }
  set rootNodeInstances(value: GrpcRootNodeInstance[] | undefined) {
    this._rootNodeInstances = value;
  }
  get parentId(): string | undefined {
    return this._parentId;
  }
  set parentId(value: string | undefined) {
    this._parentId = value;
  }
  get parentLabel(): string | undefined {
    return this._parentLabel;
  }
  set parentLabel(value: string | undefined) {
    this._parentLabel = value;
  }
  get systemDefined(): boolean | undefined {
    return this._systemDefined;
  }
  set systemDefined(value: boolean | undefined) {
    this._systemDefined = value;
  }
  get customerInstance(): boolean | undefined {
    return this._customerInstance;
  }
  set customerInstance(value: boolean | undefined) {
    this._customerInstance = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDataSourceInstance.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDataSourceInstance.AsObject {
    return {
      id: this.id,
      name: this.name,
      dataSourceDefinitionId: this.dataSourceDefinitionId,
      rootNodeInstances: (this.rootNodeInstances || []).map(m => m.toObject()),
      parentId: this.parentId,
      parentLabel: this.parentLabel,
      systemDefined: this.systemDefined,
      customerInstance: this.customerInstance
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDataSourceInstance.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      dataSourceDefinitionId: this.dataSourceDefinitionId,
      rootNodeInstances: (this.rootNodeInstances || []).map(m =>
        m.toProtobufJSON(options)
      ),
      parentId: this.parentId,
      parentLabel: this.parentLabel,
      systemDefined: this.systemDefined,
      customerInstance: this.customerInstance
    };
  }
}
export module GrpcDataSourceInstance {
  /**
   * Standard JavaScript object representation for GrpcDataSourceInstance
   */
  export interface AsObject {
    id?: string;
    name?: string;
    dataSourceDefinitionId?: string;
    rootNodeInstances?: GrpcRootNodeInstance.AsObject[];
    parentId?: string;
    parentLabel?: string;
    systemDefined?: boolean;
    customerInstance?: boolean;
  }

  /**
   * Protobuf JSON representation for GrpcDataSourceInstance
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    dataSourceDefinitionId?: string;
    rootNodeInstances?: GrpcRootNodeInstance.AsProtobufJSON[] | null;
    parentId?: string;
    parentLabel?: string;
    systemDefined?: boolean;
    customerInstance?: boolean;
  }
}

/**
 * Message implementation for xconf.SearchProperty
 */
export class SearchProperty implements GrpcMessage {
  static id = 'xconf.SearchProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SearchProperty();
    SearchProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SearchProperty) {
    _instance.typeName = _instance.typeName || '';
    _instance.key = _instance.key || '';
    _instance.value = _instance.value || '';
    _instance.operator = _instance.operator || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SearchProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.typeName = _reader.readString();
          break;
        case 2:
          _instance.key = _reader.readString();
          break;
        case 3:
          _instance.value = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    SearchProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SearchProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.typeName) {
      _writer.writeString(1, _instance.typeName);
    }
    if (_instance.key) {
      _writer.writeString(2, _instance.key);
    }
    if (_instance.value) {
      _writer.writeString(3, _instance.value);
    }
    if (_instance.operator) {
      _writer.writeEnum(4, _instance.operator);
    }
  }

  private _typeName?: string;
  private _key?: string;
  private _value?: string;
  private _operator?: SearchProperty.SearchPropertyOperator;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SearchProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<SearchProperty.AsObject>) {
    _value = _value || {};
    this.typeName = _value.typeName;
    this.key = _value.key;
    this.value = _value.value;
    this.operator = _value.operator;
    SearchProperty.refineValues(this);
  }
  get typeName(): string | undefined {
    return this._typeName;
  }
  set typeName(value: string | undefined) {
    this._typeName = value;
  }
  get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
  }
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
  }
  get operator(): SearchProperty.SearchPropertyOperator | undefined {
    return this._operator;
  }
  set operator(value: SearchProperty.SearchPropertyOperator | undefined) {
    this._operator = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SearchProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SearchProperty.AsObject {
    return {
      typeName: this.typeName,
      key: this.key,
      value: this.value,
      operator: this.operator
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SearchProperty.AsProtobufJSON {
    return {
      typeName: this.typeName,
      key: this.key,
      value: this.value,
      operator:
        SearchProperty.SearchPropertyOperator[
          this.operator === null || this.operator === undefined
            ? 0
            : this.operator
        ]
    };
  }
}
export module SearchProperty {
  /**
   * Standard JavaScript object representation for SearchProperty
   */
  export interface AsObject {
    typeName?: string;
    key?: string;
    value?: string;
    operator?: SearchProperty.SearchPropertyOperator;
  }

  /**
   * Protobuf JSON representation for SearchProperty
   */
  export interface AsProtobufJSON {
    typeName?: string;
    key?: string;
    value?: string;
    operator?: string;
  }
  export enum SearchPropertyOperator {
    Equals = 0,
    NotEquals = 1,
    LessThan = 2,
    LessOrEqualThan = 3,
    GreateThan = 4,
    GreateOrEqualThan = 5,
    Contains = 6,
    EndsWith = 7,
    In = 8,
    StartsWith = 9
  }
}

/**
 * Message implementation for xconf.SearchNodesRequest
 */
export class SearchNodesRequest implements GrpcMessage {
  static id = 'xconf.SearchNodesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SearchNodesRequest();
    SearchNodesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SearchNodesRequest) {
    _instance.rootId = _instance.rootId || '';
    _instance.rootLabel = _instance.rootLabel || '';
    _instance.label = _instance.label || '';
    _instance.maxHops = _instance.maxHops || 0;
    _instance.orderBy = _instance.orderBy || '';
    _instance.skip = _instance.skip || 0;
    _instance.limit = _instance.limit || 0;
    _instance.properties = _instance.properties || [];
    _instance.propertiesOperatorAnd = _instance.propertiesOperatorAnd || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SearchNodesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rootId = _reader.readString();
          break;
        case 2:
          _instance.rootLabel = _reader.readString();
          break;
        case 3:
          _instance.label = _reader.readString();
          break;
        case 4:
          _instance.maxHops = _reader.readInt32();
          break;
        case 5:
          _instance.orderBy = _reader.readString();
          break;
        case 6:
          _instance.skip = _reader.readInt32();
          break;
        case 7:
          _instance.limit = _reader.readInt32();
          break;
        case 8:
          const messageInitializer8 = new SearchProperty();
          _reader.readMessage(
            messageInitializer8,
            SearchProperty.deserializeBinaryFromReader
          );
          (_instance.properties = _instance.properties || []).push(
            messageInitializer8
          );
          break;
        case 9:
          _instance.propertiesOperatorAnd = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    SearchNodesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SearchNodesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.rootId) {
      _writer.writeString(1, _instance.rootId);
    }
    if (_instance.rootLabel) {
      _writer.writeString(2, _instance.rootLabel);
    }
    if (_instance.label) {
      _writer.writeString(3, _instance.label);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(4, _instance.maxHops);
    }
    if (_instance.orderBy) {
      _writer.writeString(5, _instance.orderBy);
    }
    if (_instance.skip) {
      _writer.writeInt32(6, _instance.skip);
    }
    if (_instance.limit) {
      _writer.writeInt32(7, _instance.limit);
    }
    if (_instance.properties && _instance.properties.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.properties as any,
        SearchProperty.serializeBinaryToWriter
      );
    }
    if (_instance.propertiesOperatorAnd) {
      _writer.writeBool(9, _instance.propertiesOperatorAnd);
    }
  }

  private _rootId?: string;
  private _rootLabel?: string;
  private _label?: string;
  private _maxHops?: number;
  private _orderBy?: string;
  private _skip?: number;
  private _limit?: number;
  private _properties?: SearchProperty[];
  private _propertiesOperatorAnd?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SearchNodesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SearchNodesRequest.AsObject>) {
    _value = _value || {};
    this.rootId = _value.rootId;
    this.rootLabel = _value.rootLabel;
    this.label = _value.label;
    this.maxHops = _value.maxHops;
    this.orderBy = _value.orderBy;
    this.skip = _value.skip;
    this.limit = _value.limit;
    this.properties = (_value.properties || []).map(m => new SearchProperty(m));
    this.propertiesOperatorAnd = _value.propertiesOperatorAnd;
    SearchNodesRequest.refineValues(this);
  }
  get rootId(): string | undefined {
    return this._rootId;
  }
  set rootId(value: string | undefined) {
    this._rootId = value;
  }
  get rootLabel(): string | undefined {
    return this._rootLabel;
  }
  set rootLabel(value: string | undefined) {
    this._rootLabel = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }
  get orderBy(): string | undefined {
    return this._orderBy;
  }
  set orderBy(value: string | undefined) {
    this._orderBy = value;
  }
  get skip(): number | undefined {
    return this._skip;
  }
  set skip(value: number | undefined) {
    this._skip = value;
  }
  get limit(): number | undefined {
    return this._limit;
  }
  set limit(value: number | undefined) {
    this._limit = value;
  }
  get properties(): SearchProperty[] | undefined {
    return this._properties;
  }
  set properties(value: SearchProperty[] | undefined) {
    this._properties = value;
  }
  get propertiesOperatorAnd(): boolean | undefined {
    return this._propertiesOperatorAnd;
  }
  set propertiesOperatorAnd(value: boolean | undefined) {
    this._propertiesOperatorAnd = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SearchNodesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SearchNodesRequest.AsObject {
    return {
      rootId: this.rootId,
      rootLabel: this.rootLabel,
      label: this.label,
      maxHops: this.maxHops,
      orderBy: this.orderBy,
      skip: this.skip,
      limit: this.limit,
      properties: (this.properties || []).map(m => m.toObject()),
      propertiesOperatorAnd: this.propertiesOperatorAnd
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SearchNodesRequest.AsProtobufJSON {
    return {
      rootId: this.rootId,
      rootLabel: this.rootLabel,
      label: this.label,
      maxHops: this.maxHops,
      orderBy: this.orderBy,
      skip: this.skip,
      limit: this.limit,
      properties: (this.properties || []).map(m => m.toProtobufJSON(options)),
      propertiesOperatorAnd: this.propertiesOperatorAnd
    };
  }
}
export module SearchNodesRequest {
  /**
   * Standard JavaScript object representation for SearchNodesRequest
   */
  export interface AsObject {
    rootId?: string;
    rootLabel?: string;
    label?: string;
    maxHops?: number;
    orderBy?: string;
    skip?: number;
    limit?: number;
    properties?: SearchProperty.AsObject[];
    propertiesOperatorAnd?: boolean;
  }

  /**
   * Protobuf JSON representation for SearchNodesRequest
   */
  export interface AsProtobufJSON {
    rootId?: string;
    rootLabel?: string;
    label?: string;
    maxHops?: number;
    orderBy?: string;
    skip?: number;
    limit?: number;
    properties?: SearchProperty.AsProtobufJSON[] | null;
    propertiesOperatorAnd?: boolean;
  }
}

/**
 * Message implementation for xconf.SearchNodesResponse
 */
export class SearchNodesResponse implements GrpcMessage {
  static id = 'xconf.SearchNodesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SearchNodesResponse();
    SearchNodesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SearchNodesResponse) {
    _instance.nodes = _instance.nodes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SearchNodesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            messageInitializer1,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          (_instance.nodes = _instance.nodes || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    SearchNodesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SearchNodesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.nodes && _instance.nodes.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.nodes as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
  }

  private _nodes?: xprojectorGrpcModels002.GrpcNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SearchNodesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SearchNodesResponse.AsObject>) {
    _value = _value || {};
    this.nodes = (_value.nodes || []).map(
      m => new xprojectorGrpcModels002.GrpcNode(m)
    );
    SearchNodesResponse.refineValues(this);
  }
  get nodes(): xprojectorGrpcModels002.GrpcNode[] | undefined {
    return this._nodes;
  }
  set nodes(value: xprojectorGrpcModels002.GrpcNode[] | undefined) {
    this._nodes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SearchNodesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SearchNodesResponse.AsObject {
    return {
      nodes: (this.nodes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SearchNodesResponse.AsProtobufJSON {
    return {
      nodes: (this.nodes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module SearchNodesResponse {
  /**
   * Standard JavaScript object representation for SearchNodesResponse
   */
  export interface AsObject {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for SearchNodesResponse
   */
  export interface AsProtobufJSON {
    nodes?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GetTreeRequest
 */
export class GetTreeRequest implements GrpcMessage {
  static id = 'xconf.GetTreeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTreeRequest();
    GetTreeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTreeRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.isCustomerSpecific = _instance.isCustomerSpecific || false;
    _instance.customerId = _instance.customerId || '';
    _instance.maxHops = _instance.maxHops || 0;
    _instance.edgeTypeId = _instance.edgeTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTreeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.isCustomerSpecific = _reader.readBool();
          break;
        case 4:
          _instance.customerId = _reader.readString();
          break;
        case 5:
          _instance.maxHops = _reader.readInt32();
          break;
        case 6:
          _instance.edgeTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetTreeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTreeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.isCustomerSpecific) {
      _writer.writeBool(3, _instance.isCustomerSpecific);
    }
    if (_instance.customerId) {
      _writer.writeString(4, _instance.customerId);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(5, _instance.maxHops);
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(6, _instance.edgeTypeId);
    }
  }

  private _id?: string;
  private _label?: string;
  private _isCustomerSpecific?: boolean;
  private _customerId?: string;
  private _maxHops?: number;
  private _edgeTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTreeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTreeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.isCustomerSpecific = _value.isCustomerSpecific;
    this.customerId = _value.customerId;
    this.maxHops = _value.maxHops;
    this.edgeTypeId = _value.edgeTypeId;
    GetTreeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get isCustomerSpecific(): boolean | undefined {
    return this._isCustomerSpecific;
  }
  set isCustomerSpecific(value: boolean | undefined) {
    this._isCustomerSpecific = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTreeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTreeRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      isCustomerSpecific: this.isCustomerSpecific,
      customerId: this.customerId,
      maxHops: this.maxHops,
      edgeTypeId: this.edgeTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTreeRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      isCustomerSpecific: this.isCustomerSpecific,
      customerId: this.customerId,
      maxHops: this.maxHops,
      edgeTypeId: this.edgeTypeId
    };
  }
}
export module GetTreeRequest {
  /**
   * Standard JavaScript object representation for GetTreeRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    isCustomerSpecific?: boolean;
    customerId?: string;
    maxHops?: number;
    edgeTypeId?: string;
  }

  /**
   * Protobuf JSON representation for GetTreeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    isCustomerSpecific?: boolean;
    customerId?: string;
    maxHops?: number;
    edgeTypeId?: string;
  }
}

/**
 * Message implementation for xconf.GetTreeResponse
 */
export class GetTreeResponse implements GrpcMessage {
  static id = 'xconf.GetTreeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTreeResponse();
    GetTreeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTreeResponse) {
    _instance.root = _instance.root || undefined;
    _instance.children = _instance.children || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTreeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.root = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.root,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new GrpcTreeNode();
          _reader.readMessage(
            messageInitializer2,
            GrpcTreeNode.deserializeBinaryFromReader
          );
          (_instance.children = _instance.children || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetTreeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTreeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.root) {
      _writer.writeMessage(
        1,
        _instance.root as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.children && _instance.children.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.children as any,
        GrpcTreeNode.serializeBinaryToWriter
      );
    }
  }

  private _root?: xprojectorGrpcModels002.GrpcNode;
  private _children?: GrpcTreeNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTreeResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTreeResponse.AsObject>) {
    _value = _value || {};
    this.root = _value.root
      ? new xprojectorGrpcModels002.GrpcNode(_value.root)
      : undefined;
    this.children = (_value.children || []).map(m => new GrpcTreeNode(m));
    GetTreeResponse.refineValues(this);
  }
  get root(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._root;
  }
  set root(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._root = value;
  }
  get children(): GrpcTreeNode[] | undefined {
    return this._children;
  }
  set children(value: GrpcTreeNode[] | undefined) {
    this._children = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTreeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTreeResponse.AsObject {
    return {
      root: this.root ? this.root.toObject() : undefined,
      children: (this.children || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTreeResponse.AsProtobufJSON {
    return {
      root: this.root ? this.root.toProtobufJSON(options) : null,
      children: (this.children || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetTreeResponse {
  /**
   * Standard JavaScript object representation for GetTreeResponse
   */
  export interface AsObject {
    root?: xprojectorGrpcModels002.GrpcNode.AsObject;
    children?: GrpcTreeNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetTreeResponse
   */
  export interface AsProtobufJSON {
    root?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    children?: GrpcTreeNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GrpcTreeNode
 */
export class GrpcTreeNode implements GrpcMessage {
  static id = 'xconf.GrpcTreeNode';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcTreeNode();
    GrpcTreeNode.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcTreeNode) {
    _instance.node = _instance.node || undefined;
    _instance.edgeTypeId = _instance.edgeTypeId || '';
    _instance.children = _instance.children || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcTreeNode,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new xprojectorGrpcModels002.GrpcNode();
          _reader.readMessage(
            _instance.node,
            xprojectorGrpcModels002.GrpcNode.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.edgeTypeId = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new GrpcTreeNode();
          _reader.readMessage(
            messageInitializer3,
            GrpcTreeNode.deserializeBinaryFromReader
          );
          (_instance.children = _instance.children || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcTreeNode.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcTreeNode,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        xprojectorGrpcModels002.GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.edgeTypeId) {
      _writer.writeString(2, _instance.edgeTypeId);
    }
    if (_instance.children && _instance.children.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.children as any,
        GrpcTreeNode.serializeBinaryToWriter
      );
    }
  }

  private _node?: xprojectorGrpcModels002.GrpcNode;
  private _edgeTypeId?: string;
  private _children?: GrpcTreeNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcTreeNode to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcTreeNode.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new xprojectorGrpcModels002.GrpcNode(_value.node)
      : undefined;
    this.edgeTypeId = _value.edgeTypeId;
    this.children = (_value.children || []).map(m => new GrpcTreeNode(m));
    GrpcTreeNode.refineValues(this);
  }
  get node(): xprojectorGrpcModels002.GrpcNode | undefined {
    return this._node;
  }
  set node(value: xprojectorGrpcModels002.GrpcNode | undefined) {
    this._node = value;
  }
  get edgeTypeId(): string | undefined {
    return this._edgeTypeId;
  }
  set edgeTypeId(value: string | undefined) {
    this._edgeTypeId = value;
  }
  get children(): GrpcTreeNode[] | undefined {
    return this._children;
  }
  set children(value: GrpcTreeNode[] | undefined) {
    this._children = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcTreeNode.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcTreeNode.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      edgeTypeId: this.edgeTypeId,
      children: (this.children || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcTreeNode.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      edgeTypeId: this.edgeTypeId,
      children: (this.children || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GrpcTreeNode {
  /**
   * Standard JavaScript object representation for GrpcTreeNode
   */
  export interface AsObject {
    node?: xprojectorGrpcModels002.GrpcNode.AsObject;
    edgeTypeId?: string;
    children?: GrpcTreeNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GrpcTreeNode
   */
  export interface AsProtobufJSON {
    node?: xprojectorGrpcModels002.GrpcNode.AsProtobufJSON | null;
    edgeTypeId?: string;
    children?: GrpcTreeNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GetShortestPathRequest
 */
export class GetShortestPathRequest implements GrpcMessage {
  static id = 'xconf.GetShortestPathRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetShortestPathRequest();
    GetShortestPathRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetShortestPathRequest) {
    _instance.sourceId = _instance.sourceId || '';
    _instance.sourceLabel = _instance.sourceLabel || '';
    _instance.endId = _instance.endId || '';
    _instance.endLabel = _instance.endLabel || '';
    _instance.maxHops = _instance.maxHops || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetShortestPathRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sourceId = _reader.readString();
          break;
        case 2:
          _instance.sourceLabel = _reader.readString();
          break;
        case 3:
          _instance.endId = _reader.readString();
          break;
        case 4:
          _instance.endLabel = _reader.readString();
          break;
        case 5:
          _instance.maxHops = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetShortestPathRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetShortestPathRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.sourceId) {
      _writer.writeString(1, _instance.sourceId);
    }
    if (_instance.sourceLabel) {
      _writer.writeString(2, _instance.sourceLabel);
    }
    if (_instance.endId) {
      _writer.writeString(3, _instance.endId);
    }
    if (_instance.endLabel) {
      _writer.writeString(4, _instance.endLabel);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(5, _instance.maxHops);
    }
  }

  private _sourceId?: string;
  private _sourceLabel?: string;
  private _endId?: string;
  private _endLabel?: string;
  private _maxHops?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetShortestPathRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetShortestPathRequest.AsObject>) {
    _value = _value || {};
    this.sourceId = _value.sourceId;
    this.sourceLabel = _value.sourceLabel;
    this.endId = _value.endId;
    this.endLabel = _value.endLabel;
    this.maxHops = _value.maxHops;
    GetShortestPathRequest.refineValues(this);
  }
  get sourceId(): string | undefined {
    return this._sourceId;
  }
  set sourceId(value: string | undefined) {
    this._sourceId = value;
  }
  get sourceLabel(): string | undefined {
    return this._sourceLabel;
  }
  set sourceLabel(value: string | undefined) {
    this._sourceLabel = value;
  }
  get endId(): string | undefined {
    return this._endId;
  }
  set endId(value: string | undefined) {
    this._endId = value;
  }
  get endLabel(): string | undefined {
    return this._endLabel;
  }
  set endLabel(value: string | undefined) {
    this._endLabel = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetShortestPathRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetShortestPathRequest.AsObject {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      maxHops: this.maxHops
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetShortestPathRequest.AsProtobufJSON {
    return {
      sourceId: this.sourceId,
      sourceLabel: this.sourceLabel,
      endId: this.endId,
      endLabel: this.endLabel,
      maxHops: this.maxHops
    };
  }
}
export module GetShortestPathRequest {
  /**
   * Standard JavaScript object representation for GetShortestPathRequest
   */
  export interface AsObject {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    maxHops?: number;
  }

  /**
   * Protobuf JSON representation for GetShortestPathRequest
   */
  export interface AsProtobufJSON {
    sourceId?: string;
    sourceLabel?: string;
    endId?: string;
    endLabel?: string;
    maxHops?: number;
  }
}

/**
 * Message implementation for xconf.GetShortestPathResponse
 */
export class GetShortestPathResponse implements GrpcMessage {
  static id = 'xconf.GetShortestPathResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetShortestPathResponse();
    GetShortestPathResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetShortestPathResponse) {
    _instance.path = _instance.path || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetShortestPathResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.path = _instance.path || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    GetShortestPathResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetShortestPathResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.path && _instance.path.length) {
      _writer.writeRepeatedString(1, _instance.path);
    }
  }

  private _path?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetShortestPathResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetShortestPathResponse.AsObject>) {
    _value = _value || {};
    this.path = (_value.path || []).slice();
    GetShortestPathResponse.refineValues(this);
  }
  get path(): string[] | undefined {
    return this._path;
  }
  set path(value: string[] | undefined) {
    this._path = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetShortestPathResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetShortestPathResponse.AsObject {
    return {
      path: (this.path || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetShortestPathResponse.AsProtobufJSON {
    return {
      path: (this.path || []).slice()
    };
  }
}
export module GetShortestPathResponse {
  /**
   * Standard JavaScript object representation for GetShortestPathResponse
   */
  export interface AsObject {
    path?: string[];
  }

  /**
   * Protobuf JSON representation for GetShortestPathResponse
   */
  export interface AsProtobufJSON {
    path?: string[];
  }
}

/**
 * Message implementation for xconf.ReadoutMomentaryValuesRequest
 */
export class ReadoutMomentaryValuesRequest implements GrpcMessage {
  static id = 'xconf.ReadoutMomentaryValuesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadoutMomentaryValuesRequest();
    ReadoutMomentaryValuesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadoutMomentaryValuesRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadoutMomentaryValuesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ReadoutMomentaryValuesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadoutMomentaryValuesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
  }

  private _id?: string;
  private _label?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadoutMomentaryValuesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ReadoutMomentaryValuesRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    ReadoutMomentaryValuesRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadoutMomentaryValuesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadoutMomentaryValuesRequest.AsObject {
    return {
      id: this.id,
      label: this.label
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadoutMomentaryValuesRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label
    };
  }
}
export module ReadoutMomentaryValuesRequest {
  /**
   * Standard JavaScript object representation for ReadoutMomentaryValuesRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
  }

  /**
   * Protobuf JSON representation for ReadoutMomentaryValuesRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
  }
}

/**
 * Message implementation for xconf.ReadoutValuesResponse
 */
export class ReadoutValuesResponse implements GrpcMessage {
  static id = 'xconf.ReadoutValuesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadoutValuesResponse();
    ReadoutValuesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadoutValuesResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.numericValues = _instance.numericValues || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadoutValuesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new xprojectorGrpcModels002.GrpcXDataNumericValue();
          _reader.readMessage(
            messageInitializer3,
            xprojectorGrpcModels002.GrpcXDataNumericValue
              .deserializeBinaryFromReader
          );
          (_instance.numericValues = _instance.numericValues || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ReadoutValuesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadoutValuesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.numericValues && _instance.numericValues.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.numericValues as any,
        xprojectorGrpcModels002.GrpcXDataNumericValue.serializeBinaryToWriter
      );
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _numericValues?: xprojectorGrpcModels002.GrpcXDataNumericValue[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadoutValuesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadoutValuesResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.numericValues = (_value.numericValues || []).map(
      m => new xprojectorGrpcModels002.GrpcXDataNumericValue(m)
    );
    ReadoutValuesResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get numericValues():
    | xprojectorGrpcModels002.GrpcXDataNumericValue[]
    | undefined {
    return this._numericValues;
  }
  set numericValues(
    value: xprojectorGrpcModels002.GrpcXDataNumericValue[] | undefined
  ) {
    this._numericValues = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadoutValuesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadoutValuesResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      numericValues: (this.numericValues || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadoutValuesResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      numericValues: (this.numericValues || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module ReadoutValuesResponse {
  /**
   * Standard JavaScript object representation for ReadoutValuesResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    numericValues?: xprojectorGrpcModels002.GrpcXDataNumericValue.AsObject[];
  }

  /**
   * Protobuf JSON representation for ReadoutValuesResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    numericValues?:
      | xprojectorGrpcModels002.GrpcXDataNumericValue.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for xconf.GrpcNodeCommand
 */
export class GrpcNodeCommand implements GrpcMessage {
  static id = 'xconf.GrpcNodeCommand';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcNodeCommand();
    GrpcNodeCommand.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcNodeCommand) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.confirmMessage = _instance.confirmMessage || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcNodeCommand,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.confirmMessage = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcNodeCommand.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcNodeCommand,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.confirmMessage) {
      _writer.writeString(3, _instance.confirmMessage);
    }
  }

  private _id?: string;
  private _name?: string;
  private _confirmMessage?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcNodeCommand to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcNodeCommand.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.confirmMessage = _value.confirmMessage;
    GrpcNodeCommand.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get confirmMessage(): string | undefined {
    return this._confirmMessage;
  }
  set confirmMessage(value: string | undefined) {
    this._confirmMessage = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcNodeCommand.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcNodeCommand.AsObject {
    return {
      id: this.id,
      name: this.name,
      confirmMessage: this.confirmMessage
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcNodeCommand.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      confirmMessage: this.confirmMessage
    };
  }
}
export module GrpcNodeCommand {
  /**
   * Standard JavaScript object representation for GrpcNodeCommand
   */
  export interface AsObject {
    id?: string;
    name?: string;
    confirmMessage?: string;
  }

  /**
   * Protobuf JSON representation for GrpcNodeCommand
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    confirmMessage?: string;
  }
}

/**
 * Message implementation for xconf.GetNodeCommandsRequest
 */
export class GetNodeCommandsRequest implements GrpcMessage {
  static id = 'xconf.GetNodeCommandsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeCommandsRequest();
    GetNodeCommandsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeCommandsRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeCommandsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeCommandsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeCommandsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
  }

  private _id?: string;
  private _label?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeCommandsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeCommandsRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    GetNodeCommandsRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeCommandsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeCommandsRequest.AsObject {
    return {
      id: this.id,
      label: this.label
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeCommandsRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label
    };
  }
}
export module GetNodeCommandsRequest {
  /**
   * Standard JavaScript object representation for GetNodeCommandsRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
  }

  /**
   * Protobuf JSON representation for GetNodeCommandsRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
  }
}

/**
 * Message implementation for xconf.GetNodeCommandsResponse
 */
export class GetNodeCommandsResponse implements GrpcMessage {
  static id = 'xconf.GetNodeCommandsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeCommandsResponse();
    GetNodeCommandsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeCommandsResponse) {
    _instance.commands = _instance.commands || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeCommandsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcNodeCommand();
          _reader.readMessage(
            messageInitializer1,
            GrpcNodeCommand.deserializeBinaryFromReader
          );
          (_instance.commands = _instance.commands || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeCommandsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeCommandsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.commands && _instance.commands.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.commands as any,
        GrpcNodeCommand.serializeBinaryToWriter
      );
    }
  }

  private _commands?: GrpcNodeCommand[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeCommandsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeCommandsResponse.AsObject>) {
    _value = _value || {};
    this.commands = (_value.commands || []).map(m => new GrpcNodeCommand(m));
    GetNodeCommandsResponse.refineValues(this);
  }
  get commands(): GrpcNodeCommand[] | undefined {
    return this._commands;
  }
  set commands(value: GrpcNodeCommand[] | undefined) {
    this._commands = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeCommandsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeCommandsResponse.AsObject {
    return {
      commands: (this.commands || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeCommandsResponse.AsProtobufJSON {
    return {
      commands: (this.commands || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetNodeCommandsResponse {
  /**
   * Standard JavaScript object representation for GetNodeCommandsResponse
   */
  export interface AsObject {
    commands?: GrpcNodeCommand.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetNodeCommandsResponse
   */
  export interface AsProtobufJSON {
    commands?: GrpcNodeCommand.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.ExecuteNodeCommandRequest
 */
export class ExecuteNodeCommandRequest implements GrpcMessage {
  static id = 'xconf.ExecuteNodeCommandRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExecuteNodeCommandRequest();
    ExecuteNodeCommandRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExecuteNodeCommandRequest) {
    _instance.id = _instance.id || '';
    _instance.label = _instance.label || '';
    _instance.commandId = _instance.commandId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExecuteNodeCommandRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.commandId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ExecuteNodeCommandRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExecuteNodeCommandRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.commandId) {
      _writer.writeString(3, _instance.commandId);
    }
  }

  private _id?: string;
  private _label?: string;
  private _commandId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExecuteNodeCommandRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExecuteNodeCommandRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.label = _value.label;
    this.commandId = _value.commandId;
    ExecuteNodeCommandRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get commandId(): string | undefined {
    return this._commandId;
  }
  set commandId(value: string | undefined) {
    this._commandId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExecuteNodeCommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExecuteNodeCommandRequest.AsObject {
    return {
      id: this.id,
      label: this.label,
      commandId: this.commandId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExecuteNodeCommandRequest.AsProtobufJSON {
    return {
      id: this.id,
      label: this.label,
      commandId: this.commandId
    };
  }
}
export module ExecuteNodeCommandRequest {
  /**
   * Standard JavaScript object representation for ExecuteNodeCommandRequest
   */
  export interface AsObject {
    id?: string;
    label?: string;
    commandId?: string;
  }

  /**
   * Protobuf JSON representation for ExecuteNodeCommandRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    label?: string;
    commandId?: string;
  }
}

/**
 * Message implementation for xconf.ExecuteNodeCommandResponse
 */
export class ExecuteNodeCommandResponse implements GrpcMessage {
  static id = 'xconf.ExecuteNodeCommandResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExecuteNodeCommandResponse();
    ExecuteNodeCommandResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExecuteNodeCommandResponse) {
    _instance.id = _instance.id || '';
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.data = _instance.data || '';
    _instance.datatype = _instance.datatype || '';
    _instance.nodeUpdated = _instance.nodeUpdated || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExecuteNodeCommandResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.ok = _reader.readBool();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        case 4:
          _instance.data = _reader.readString();
          break;
        case 5:
          _instance.datatype = _reader.readString();
          break;
        case 6:
          _instance.nodeUpdated = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ExecuteNodeCommandResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExecuteNodeCommandResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.ok) {
      _writer.writeBool(2, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
    if (_instance.data) {
      _writer.writeString(4, _instance.data);
    }
    if (_instance.datatype) {
      _writer.writeString(5, _instance.datatype);
    }
    if (_instance.nodeUpdated) {
      _writer.writeBool(6, _instance.nodeUpdated);
    }
  }

  private _id?: string;
  private _ok?: boolean;
  private _message?: string;
  private _data?: string;
  private _datatype?: string;
  private _nodeUpdated?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExecuteNodeCommandResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExecuteNodeCommandResponse.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.ok = _value.ok;
    this.message = _value.message;
    this.data = _value.data;
    this.datatype = _value.datatype;
    this.nodeUpdated = _value.nodeUpdated;
    ExecuteNodeCommandResponse.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get data(): string | undefined {
    return this._data;
  }
  set data(value: string | undefined) {
    this._data = value;
  }
  get datatype(): string | undefined {
    return this._datatype;
  }
  set datatype(value: string | undefined) {
    this._datatype = value;
  }
  get nodeUpdated(): boolean | undefined {
    return this._nodeUpdated;
  }
  set nodeUpdated(value: boolean | undefined) {
    this._nodeUpdated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExecuteNodeCommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExecuteNodeCommandResponse.AsObject {
    return {
      id: this.id,
      ok: this.ok,
      message: this.message,
      data: this.data,
      datatype: this.datatype,
      nodeUpdated: this.nodeUpdated
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExecuteNodeCommandResponse.AsProtobufJSON {
    return {
      id: this.id,
      ok: this.ok,
      message: this.message,
      data: this.data,
      datatype: this.datatype,
      nodeUpdated: this.nodeUpdated
    };
  }
}
export module ExecuteNodeCommandResponse {
  /**
   * Standard JavaScript object representation for ExecuteNodeCommandResponse
   */
  export interface AsObject {
    id?: string;
    ok?: boolean;
    message?: string;
    data?: string;
    datatype?: string;
    nodeUpdated?: boolean;
  }

  /**
   * Protobuf JSON representation for ExecuteNodeCommandResponse
   */
  export interface AsProtobufJSON {
    id?: string;
    ok?: boolean;
    message?: string;
    data?: string;
    datatype?: string;
    nodeUpdated?: boolean;
  }
}

/**
 * Message implementation for xconf.GrpcDataSourceInstanceNode
 */
export class GrpcDataSourceInstanceNode implements GrpcMessage {
  static id = 'xconf.GrpcDataSourceInstanceNode';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDataSourceInstanceNode();
    GrpcDataSourceInstanceNode.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDataSourceInstanceNode) {
    _instance.node = _instance.node || undefined;
    _instance.children = _instance.children || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDataSourceInstanceNode,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.node = new GrpcDataSourceInstance();
          _reader.readMessage(
            _instance.node,
            GrpcDataSourceInstance.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new GrpcDataSourceInstanceNode();
          _reader.readMessage(
            messageInitializer2,
            GrpcDataSourceInstanceNode.deserializeBinaryFromReader
          );
          (_instance.children = _instance.children || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDataSourceInstanceNode.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDataSourceInstanceNode,
    _writer: BinaryWriter
  ) {
    if (_instance.node) {
      _writer.writeMessage(
        1,
        _instance.node as any,
        GrpcDataSourceInstance.serializeBinaryToWriter
      );
    }
    if (_instance.children && _instance.children.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.children as any,
        GrpcDataSourceInstanceNode.serializeBinaryToWriter
      );
    }
  }

  private _node?: GrpcDataSourceInstance;
  private _children?: GrpcDataSourceInstanceNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDataSourceInstanceNode to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDataSourceInstanceNode.AsObject>) {
    _value = _value || {};
    this.node = _value.node
      ? new GrpcDataSourceInstance(_value.node)
      : undefined;
    this.children = (_value.children || []).map(
      m => new GrpcDataSourceInstanceNode(m)
    );
    GrpcDataSourceInstanceNode.refineValues(this);
  }
  get node(): GrpcDataSourceInstance | undefined {
    return this._node;
  }
  set node(value: GrpcDataSourceInstance | undefined) {
    this._node = value;
  }
  get children(): GrpcDataSourceInstanceNode[] | undefined {
    return this._children;
  }
  set children(value: GrpcDataSourceInstanceNode[] | undefined) {
    this._children = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDataSourceInstanceNode.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDataSourceInstanceNode.AsObject {
    return {
      node: this.node ? this.node.toObject() : undefined,
      children: (this.children || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDataSourceInstanceNode.AsProtobufJSON {
    return {
      node: this.node ? this.node.toProtobufJSON(options) : null,
      children: (this.children || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GrpcDataSourceInstanceNode {
  /**
   * Standard JavaScript object representation for GrpcDataSourceInstanceNode
   */
  export interface AsObject {
    node?: GrpcDataSourceInstance.AsObject;
    children?: GrpcDataSourceInstanceNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GrpcDataSourceInstanceNode
   */
  export interface AsProtobufJSON {
    node?: GrpcDataSourceInstance.AsProtobufJSON | null;
    children?: GrpcDataSourceInstanceNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceTreeRequest
 */
export class GetDataSourceInstanceTreeRequest implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceTreeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceTreeRequest();
    GetDataSourceInstanceTreeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceTreeRequest) {
    _instance.id = _instance.id || '';
    _instance.maxHops = _instance.maxHops || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceTreeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.maxHops = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceTreeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceTreeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.maxHops) {
      _writer.writeInt32(2, _instance.maxHops);
    }
  }

  private _id?: string;
  private _maxHops?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceTreeRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceTreeRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.maxHops = _value.maxHops;
    GetDataSourceInstanceTreeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get maxHops(): number | undefined {
    return this._maxHops;
  }
  set maxHops(value: number | undefined) {
    this._maxHops = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceTreeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceTreeRequest.AsObject {
    return {
      id: this.id,
      maxHops: this.maxHops
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceTreeRequest.AsProtobufJSON {
    return {
      id: this.id,
      maxHops: this.maxHops
    };
  }
}
export module GetDataSourceInstanceTreeRequest {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceTreeRequest
   */
  export interface AsObject {
    id?: string;
    maxHops?: number;
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceTreeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    maxHops?: number;
  }
}

/**
 * Message implementation for xconf.GetDataSourceInstanceTreeResponse
 */
export class GetDataSourceInstanceTreeResponse implements GrpcMessage {
  static id = 'xconf.GetDataSourceInstanceTreeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDataSourceInstanceTreeResponse();
    GetDataSourceInstanceTreeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDataSourceInstanceTreeResponse) {
    _instance.root = _instance.root || undefined;
    _instance.children = _instance.children || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDataSourceInstanceTreeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.root = new GrpcDataSourceInstance();
          _reader.readMessage(
            _instance.root,
            GrpcDataSourceInstance.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new GrpcDataSourceInstanceNode();
          _reader.readMessage(
            messageInitializer2,
            GrpcDataSourceInstanceNode.deserializeBinaryFromReader
          );
          (_instance.children = _instance.children || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDataSourceInstanceTreeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDataSourceInstanceTreeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.root) {
      _writer.writeMessage(
        1,
        _instance.root as any,
        GrpcDataSourceInstance.serializeBinaryToWriter
      );
    }
    if (_instance.children && _instance.children.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.children as any,
        GrpcDataSourceInstanceNode.serializeBinaryToWriter
      );
    }
  }

  private _root?: GrpcDataSourceInstance;
  private _children?: GrpcDataSourceInstanceNode[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDataSourceInstanceTreeResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetDataSourceInstanceTreeResponse.AsObject>
  ) {
    _value = _value || {};
    this.root = _value.root
      ? new GrpcDataSourceInstance(_value.root)
      : undefined;
    this.children = (_value.children || []).map(
      m => new GrpcDataSourceInstanceNode(m)
    );
    GetDataSourceInstanceTreeResponse.refineValues(this);
  }
  get root(): GrpcDataSourceInstance | undefined {
    return this._root;
  }
  set root(value: GrpcDataSourceInstance | undefined) {
    this._root = value;
  }
  get children(): GrpcDataSourceInstanceNode[] | undefined {
    return this._children;
  }
  set children(value: GrpcDataSourceInstanceNode[] | undefined) {
    this._children = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDataSourceInstanceTreeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDataSourceInstanceTreeResponse.AsObject {
    return {
      root: this.root ? this.root.toObject() : undefined,
      children: (this.children || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDataSourceInstanceTreeResponse.AsProtobufJSON {
    return {
      root: this.root ? this.root.toProtobufJSON(options) : null,
      children: (this.children || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetDataSourceInstanceTreeResponse {
  /**
   * Standard JavaScript object representation for GetDataSourceInstanceTreeResponse
   */
  export interface AsObject {
    root?: GrpcDataSourceInstance.AsObject;
    children?: GrpcDataSourceInstanceNode.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDataSourceInstanceTreeResponse
   */
  export interface AsProtobufJSON {
    root?: GrpcDataSourceInstance.AsProtobufJSON | null;
    children?: GrpcDataSourceInstanceNode.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xconf.GrpcNodeLogObject
 */
export class GrpcNodeLogObject implements GrpcMessage {
  static id = 'xconf.GrpcNodeLogObject';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcNodeLogObject();
    GrpcNodeLogObject.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcNodeLogObject) {
    _instance.id = _instance.id || '';
    _instance.eventType = _instance.eventType || 0;
    _instance.message = _instance.message || '';
    _instance.timestamp = _instance.timestamp || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcNodeLogObject,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.eventType = _reader.readEnum();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        case 4:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcNodeLogObject.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcNodeLogObject,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.eventType) {
      _writer.writeEnum(2, _instance.eventType);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        4,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _eventType?: GrpcNodeLogObject.GrpcEventType;
  private _message?: string;
  private _timestamp?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcNodeLogObject to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcNodeLogObject.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.eventType = _value.eventType;
    this.message = _value.message;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    GrpcNodeLogObject.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get eventType(): GrpcNodeLogObject.GrpcEventType | undefined {
    return this._eventType;
  }
  set eventType(value: GrpcNodeLogObject.GrpcEventType | undefined) {
    this._eventType = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcNodeLogObject.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcNodeLogObject.AsObject {
    return {
      id: this.id,
      eventType: this.eventType,
      message: this.message,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcNodeLogObject.AsProtobufJSON {
    return {
      id: this.id,
      eventType:
        GrpcNodeLogObject.GrpcEventType[
          this.eventType === null || this.eventType === undefined
            ? 0
            : this.eventType
        ],
      message: this.message,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null
    };
  }
}
export module GrpcNodeLogObject {
  /**
   * Standard JavaScript object representation for GrpcNodeLogObject
   */
  export interface AsObject {
    id?: string;
    eventType?: GrpcNodeLogObject.GrpcEventType;
    message?: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GrpcNodeLogObject
   */
  export interface AsProtobufJSON {
    id?: string;
    eventType?: string;
    message?: string;
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
  export enum GrpcEventType {
    Debug = 0,
    Information = 1,
    Warning = 2,
    Error = 3
  }
}

/**
 * Message implementation for xconf.GetNodeLogsRequest
 */
export class GetNodeLogsRequest implements GrpcMessage {
  static id = 'xconf.GetNodeLogsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeLogsRequest();
    GetNodeLogsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeLogsRequest) {
    _instance.nodeId = _instance.nodeId || '';
    _instance.label = _instance.label || '';
    _instance.readFromId = _instance.readFromId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeLogsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nodeId = _reader.readString();
          break;
        case 2:
          _instance.label = _reader.readString();
          break;
        case 3:
          _instance.readFromId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeLogsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeLogsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeId) {
      _writer.writeString(1, _instance.nodeId);
    }
    if (_instance.label) {
      _writer.writeString(2, _instance.label);
    }
    if (_instance.readFromId) {
      _writer.writeString(3, _instance.readFromId);
    }
  }

  private _nodeId?: string;
  private _label?: string;
  private _readFromId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeLogsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeLogsRequest.AsObject>) {
    _value = _value || {};
    this.nodeId = _value.nodeId;
    this.label = _value.label;
    this.readFromId = _value.readFromId;
    GetNodeLogsRequest.refineValues(this);
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get readFromId(): string | undefined {
    return this._readFromId;
  }
  set readFromId(value: string | undefined) {
    this._readFromId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeLogsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeLogsRequest.AsObject {
    return {
      nodeId: this.nodeId,
      label: this.label,
      readFromId: this.readFromId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeLogsRequest.AsProtobufJSON {
    return {
      nodeId: this.nodeId,
      label: this.label,
      readFromId: this.readFromId
    };
  }
}
export module GetNodeLogsRequest {
  /**
   * Standard JavaScript object representation for GetNodeLogsRequest
   */
  export interface AsObject {
    nodeId?: string;
    label?: string;
    readFromId?: string;
  }

  /**
   * Protobuf JSON representation for GetNodeLogsRequest
   */
  export interface AsProtobufJSON {
    nodeId?: string;
    label?: string;
    readFromId?: string;
  }
}

/**
 * Message implementation for xconf.GetNodeLogsResponse
 */
export class GetNodeLogsResponse implements GrpcMessage {
  static id = 'xconf.GetNodeLogsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetNodeLogsResponse();
    GetNodeLogsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetNodeLogsResponse) {
    _instance.logs = _instance.logs || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetNodeLogsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcNodeLogObject();
          _reader.readMessage(
            messageInitializer1,
            GrpcNodeLogObject.deserializeBinaryFromReader
          );
          (_instance.logs = _instance.logs || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetNodeLogsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetNodeLogsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.logs && _instance.logs.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.logs as any,
        GrpcNodeLogObject.serializeBinaryToWriter
      );
    }
  }

  private _logs?: GrpcNodeLogObject[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetNodeLogsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetNodeLogsResponse.AsObject>) {
    _value = _value || {};
    this.logs = (_value.logs || []).map(m => new GrpcNodeLogObject(m));
    GetNodeLogsResponse.refineValues(this);
  }
  get logs(): GrpcNodeLogObject[] | undefined {
    return this._logs;
  }
  set logs(value: GrpcNodeLogObject[] | undefined) {
    this._logs = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetNodeLogsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetNodeLogsResponse.AsObject {
    return {
      logs: (this.logs || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetNodeLogsResponse.AsProtobufJSON {
    return {
      logs: (this.logs || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetNodeLogsResponse {
  /**
   * Standard JavaScript object representation for GetNodeLogsResponse
   */
  export interface AsObject {
    logs?: GrpcNodeLogObject.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetNodeLogsResponse
   */
  export interface AsProtobufJSON {
    logs?: GrpcNodeLogObject.AsProtobufJSON[] | null;
  }
}
