import { Component, Input, OnInit } from '@angular/core';
import { BaseControllerConfig, MonthOfYear, OutputPeriod, OutputTimeType, XprojOutputTimeControllerConfig } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-time-controller-config',
  templateUrl: './xproj-output-time-controller-config.component.html',
  styleUrls: ['./xproj-output-time-controller-config.component.scss']
})
export class XprojOutputTimeControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.timeConfig = value as XprojOutputTimeControllerConfig;
    this.reload();
  }

  timeConfig : XprojOutputTimeControllerConfig;

  fromYearCurrentTime : boolean;
  fromMonthCurrentTime : boolean;
  fromDayCurrentTime : boolean;

  toYearCurrentTime : boolean;
  toMonthCurrentTime : boolean;
  toDayCurrentTime : boolean;

  OutputTimeType = OutputTimeType;
  OutputPeriod = OutputPeriod;
  MonthOfYear = MonthOfYear;

  constructor()
  { }

  reload()
  {
    this.fromYearCurrentTime = this.timeConfig.FromConfig.Year < 0;
    this.fromMonthCurrentTime = this.timeConfig.FromConfig.Month < 0;
    this.fromDayCurrentTime = this.timeConfig.FromConfig.Day < 0;

    this.toYearCurrentTime = this.timeConfig.ToConfig.Year < 0;
    this.toMonthCurrentTime = this.timeConfig.ToConfig.Month < 0;
    this.toDayCurrentTime = this.timeConfig.ToConfig.Day < 0;
  }

  async ngOnInit(): Promise<void> {
    
  }

  yearConfigChanged() {
    if (this.fromYearCurrentTime) {
      this.timeConfig.FromConfig.Year = -1;
    }
  }

  monthConfigChanged() {
    if (this.fromMonthCurrentTime) {
      this.timeConfig.FromConfig.Month = -1;
    }
  }

  dayConfigChanged() {
    if (this.fromDayCurrentTime) {
      this.timeConfig.FromConfig.Day = -1;
    }
  }

  yearConfigChangedTo() {
    if (this.toYearCurrentTime) {
      this.timeConfig.ToConfig.Year = -1;
    }
  }

  monthConfigChangedTo() {
    if (this.toMonthCurrentTime) {
      this.timeConfig.ToConfig.Month = -1;
    }
  }

  dayConfigChangedTo() {
    if (this.toDayCurrentTime) {
      this.timeConfig.ToConfig.Day = -1;
    }
  }
}
