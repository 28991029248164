import { Component, Input, OnInit } from '@angular/core';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorDashboardClient } from '@xprojectorcore/xprojector_backend/xprojector-dashboard-client';
import { XConfDashboardsService } from '../../services/xconf-dashboards.service';
import { Router } from '@angular/router';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

@Component({
  selector: 'app-xconf-dashboard-treeview',
  templateUrl: './xconf-dashboard-treeview.component.html',
  styleUrls: ['./xconf-dashboard-treeview.component.scss']
})
export class XConfDashboardTreeviewComponent implements OnInit {

  currentUserRights: UserRights;
  currentDashboardId: string;

  private dataSourceInstanceId : string = '_x_dashboards_datasource';
  dataSourceInstance: GrpcDataSourceInstance;

  constructor(
    private state: StateService,
    public dashboardsService: XConfDashboardsService,
    private xConfClient: XProjectorXConfClient,
    private dashboardClient: XProjectorDashboardClient,
    private router: Router
  ) { }

  async ngOnInit() {
    this.currentUserRights = this.state.userRightsValue;

    this.dataSourceInstance = await this.xConfClient.getDataSourceInstance(this.dataSourceInstanceId);

  }

  async onTreeNodeSelect(item: {treeNode : XconfTreeNode, nodeType : GrpcNodeType}) {
    let dashboardId = item.treeNode.node.propertyValues.find(v => v.key == 'dashboardid');
    if (dashboardId) {
      this.currentDashboardId = dashboardId.value;
      this.router.navigateByUrl('xconfdashboard/' + dashboardId.value );
    }
    else {
      this.router.navigateByUrl('xconfdashboard');
    }
  }

  async onTreeNodeSave(item: {treeNode : XconfTreeNode, nodeType : GrpcNodeType}) {
    let dashboardId = item.treeNode.node.propertyValues.find(v => v.key == 'dashboardid');
    if (dashboardId && this.currentDashboardId != dashboardId.value) {
      await this.dashboardClient.updateDashboardId(this.currentDashboardId, dashboardId.value);
      this.currentDashboardId = dashboardId.value;
    }
  }

}
