<clr-modal [(clrModalOpen)]="showRemoveProgramDialog">
    <h3 class="modal-title">Remove program</h3>
    <div class="modal-body">
        <p>Are you sure?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="showRemoveProgramDialog = false">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="showRemoveProgramDialog = false; doRemoveSelectedProgram()">Ok</button>
    </div>
</clr-modal>

<main class="content-area">
    <h1>Programs</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedProgram" [clrDgRowSelection]="true" [clrDgLoading]="loadingPrograms">

            <clr-dg-column [clrDgField]="'Program.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Program.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Program.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Program.xautogroup'"> XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Program.xgroup'"> XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let program of programs" [clrDgItem]="program">
                <clr-dg-cell class="cellhideoverflow"> {{program.Program.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{program.Program.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{program.Program.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{program.Program.xautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{program.Program.xgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Programs per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Programs
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addProgram()">
        <clr-icon shape="plus-circle "></clr-icon>
        New program
    </button>


    <div class="clr-row" *ngIf="selectedProgram">
        <div style="width: 600px;">
            <div class="card">
                <div class="card-header">
                    {{selectedProgram.Program.name}}
                </div>
                <div class="card-block">
                    <div class="card-title">
                        test {{selectedProgram.Program.description}}
                    </div>
                    <div class="card-text" *ngIf="selectedProgram.IsSaved">
                        <div class="clr-row" *ngFor="let item of selectedProgram.Program | keyvalue">
                            <div class="clr-col-4">
                                <span>{{item.key}}</span>
                            </div>
                            <div class="clr-col-8">
                                <xproj-dynclr-mini [title]="item.key" [data]="item.value"></xproj-dynclr-mini>
                            </div>
                        </div>

                    </div>
                    <div class="card-text" *ngIf="!selectedProgram.IsSaved">
                        <form clrForm>
                            <clr-input-container>
                                <label>Program ID</label>
                                <input clrInput type="text" length="30" name="programid" [(ngModel)]="selectedProgram.Program.xautoprogramid" required/>
                            </clr-input-container>
                            <clr-input-container>
                                <label>Name</label>
                                <input clrInput type="text" length="30" name="programname" [(ngModel)]="selectedProgram.Program.name" required />
                            </clr-input-container>
                            <clr-input-container>
                                <label>Description</label>
                                <input clrInput type="text" length="50" name="programdescription" [(ngModel)]="selectedProgram.Program.description" />
                            </clr-input-container>

                            <clr-select-container>
                                <label>Program Type</label>
                                <select clrSelect name="programtype" [(ngModel)]="selectedProgram.Program.type">
                                        <option [ngValue]="XAUTO_XAutoProgramType.LUA">LUA</option>
                                    </select>
                            </clr-select-container>
                        </form>

                    </div>

                </div>
                <div class="card-footer">
                    <div class="btn-group btn-primary">

                        <button *ngIf="!selectedProgram.IsSaved" (click)="saveSelectedProgram()" class=" btn btn-outline" [clrLoading]="savingProgram">Save</button>
                        <button *ngIf="selectedProgram.IsSaved" [routerLink]="[selectedProgram.Program.xautoprogramid]" class=" btn btn-outline ">Edit</button>
                        <button class="btn btn-outline " (click)="setSelectedProgramEnabled(!selectedProgram.Program.enabled)">
                  {{selectedProgram.enabled ? 'Disable' : 'Enable'}}</button>
                        <button class="btn btn-danger-outline " (click)="showRemoveProgramDialog = true">Remove</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>