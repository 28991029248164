import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardWorkspaceComponent } from './components/admin-dashboard-workspace/admin-dashboard-workspace.component';
import { FormsModule } from '@angular/forms';
//import { SharedModule } from '@shared/shared.module';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { AdminDashboardsRoutingModule } from './admin-dashboards-routing.module';
import { AdminDashboardTreeviewComponent } from './components/admin-dashboard-treeview/admin-dashboard-treeview.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { RouterModule } from '@angular/router';
import { AngularResizeEventModule } from 'angular-resize-event';

@NgModule({
  declarations: [
    AdminDashboardWorkspaceComponent,
    AdminDashboardTreeviewComponent,
    AdminDashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreSharedModule,
    AngularResizeEventModule,
    AdminDashboardsRoutingModule,
    //SharedModule
  ]
})
export class AdminDashboardsModule { }
