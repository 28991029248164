import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './components/customers/customers.component';

import { ClarityModule } from '@clr/angular';

import { SysAdminRoutingModule } from './sys-admin-routing.module';
import { SysAdminWorkspaceComponent } from './components/sys-admin-workspace/sys-admin-workspace.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { FormsModule } from '@angular/forms';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { SysAdminUserProfilesComponent } from './components/sys-admin-user-profiles/sys-admin-user-profiles.component';
import { SysAdminUserProfileComponent } from './components/sys-admin-user-profile/sys-admin-user-profile.component';

@NgModule({
  declarations: [
    CustomersComponent,
    SysAdminWorkspaceComponent,
    AddCustomerComponent,
    SysAdminUserProfilesComponent,
    SysAdminUserProfileComponent
  ],
  exports: [
    SysAdminUserProfilesComponent
  ],
  imports: [
    //ClarityModule,
    CommonModule,
    SysAdminRoutingModule,
    FormsModule,
    CoreSharedModule
  ]
})
export class SysAdminModule { }
