import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { BaseQuery, BaseQueryOutputColumnDescription, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { MapRenderType, MapWidgetConfig, MapWidgetQuery } from '../map-widget-config/xproj-map-widget-config-service';

import OLMap from 'ol/Map';
import Geometry from 'ol/geom/Geometry';
import Vector from 'ol/layer/Vector';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import TileLayer from 'ol/layer/Tile';
import { toLonLat } from 'ol/proj';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import { fromLonLat } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Layer from 'ol/layer/Layer';
import Source from 'ol/source/Source';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { defaults as defaultControls } from 'ol/control';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { doesNotReject, rejects } from 'assert';
import { GroupSelectionTypes, OutputDataType } from '../../widget-config-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

class QueryData {
  id: string;
  columnname: string;
  columnoutname: string;
  label: string;
  datatype: XDataType;
  data: any;
  projectionid: string;
  group: string[];
  clickable: boolean = false;
  hidden: boolean = false;
  webGlId : string;
}

@Component({
  selector: 'xproj-map-widget',
  templateUrl: './xproj-map-widget.component.html',
  styleUrls: ['./xproj-map-widget.component.scss']
})
export class XprojMapWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("popup", { read: ElementRef, static: false }) popup: ElementRef;
  @ViewChild("popupContent", { read: ElementRef, static: false }) popupContent: ElementRef;
  @ViewChild("popupCloser", { read: ElementRef, static: false }) popupCloser: ElementRef;


  widgetConfig: MapWidgetConfig;

  loadingQuery: boolean = false;
  loading: boolean = true;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private useRelativeTimestamp: boolean = true;
  private lastQueries: BaseQuery[] = [];

  private forcereload: boolean = false;
  mapData: {
    coordinates: {
      longitude: number,
      latitude: number
    }[],
    data: QueryData[];
  } = { coordinates: [], data: [] };

  markerValues: {
    id: string,
    outputId: string,
    clickable: boolean,
    hidden: boolean,
    value: any
  }[] = [];

  initilized = false;

  map: OLMap;
  vectorSource: VectorSource<Point> = new VectorSource<Point>();
  markerLayer: Vector<VectorSource<Geometry>> = null;
  webGLLayer: any = null;
  previousLayer: any = null;
  oldLiteralStyle: any;
  currBounds: any;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {
      super(logger, xprojClient, widgetService);
  }


  ngAfterViewInit(): void {


    // this.map.on('pointermove', function (event) {
    //   if (that.map.hasFeatureAtPixel(event.pixel) === true) {
    //     var coordinate = event.coordinate;

    //     this.logger.info('event', event);
    //     that.popup.nativeElement.innerHTML = '<b>Hello world!</b><br />I am a popup.';
    //     overlay.setPosition(coordinate);
    //   } else {
    //     overlay.setPosition(undefined);
    //     that.popupCloser.nativeElement.blur();
    //   }
    // });


    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      setTimeout(() => {
        this.updateMap(true);
      }, 500);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngOnInit() {
    this.widgetConfig = this.config as MapWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {
    let rasterSource = null;
    if (this.widgetConfig.GreyScale)
    {
      rasterSource = new Stamen({ layer: 'toner' });
    }
    else
    {
      rasterSource = new OSM();
    }

    this.map = new OLMap({
      target: 'map',
      layers: [
        new TileLayer({
          //source: new OSM(),
          source: rasterSource,
          // source: new XYZ({
          //   url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          // })
          //crossOrigin: "anonymous"
        })
      ],
      view: new View({
        center: [0, 0],
        zoom: 2
      }),
      controls: defaultControls()
    })

    var overlay = new Overlay({
      element: this.popup.nativeElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    let that = this;
    this.map.addOverlay(overlay);

    this.popupCloser.nativeElement.onclick = function () {
      overlay.setPosition(undefined);
      that.popupCloser.nativeElement.blur();
      return false;
    };


    this.map.on('singleclick', function (event) {
      if (that.map.hasFeatureAtPixel(event.pixel) === true) {
        var coordinate = event.coordinate;

        let features = that.map.getFeaturesAtPixel(event.pixel);
        //this.logger.info('feature', features);

        if (features?.length > 0) {
          //@ts-ignore
          let index = features[0].values_.index;

          if (index >= 0 && that.mapData.data.length > 0 && index < that.mapData.data[0].data.length) {
            that.markerValues = [];
            that.mapData.data.forEach(qd => {
              if (!qd.hidden) {
                that.markerValues.push({ id: (qd.label?.length > 0 ? qd.label : qd.columnname), value: qd.data[index], hidden: qd.hidden, clickable: qd.clickable,  outputId: qd.id });
              }
            })

          }
        }

        //that.popupContent.nativeElement.innerHTML = '<b>Hello world!</b><br />I am a popup.';
        overlay.setPosition(coordinate);
      } else {
        overlay.setPosition(undefined);
        that.popupCloser.nativeElement.blur();
      }
    });

    this.map.on('moveend', function (e) {
      that.currBounds = that.map.getView().calculateExtent(that.map.getSize());
      that.emitOutputParameters();
    });
  }

  async onRefresh() {

  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    await this.updateMap(!this.initilized);
  }

  async onReset() {

  }

  async onUpdateQuery() {
    await this.updateMap(true);
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let load: boolean = false;
    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        load = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQueries.forEach(query => {
          query.Query.targetprojectionid = e.master.projectionId;
          query.Query.targetgroup = e.master.group;
          load = true;
        })
      }

      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          load = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          load = true;
        }
      }
    }

    if (load) {
      await this.updateMap(true);
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.updateMap(true);
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  async emitOutputParameters() {
    let leftdown = toLonLat([this.currBounds[0], this.currBounds[1]]);
    let topright = toLonLat([this.currBounds[2], this.currBounds[3]]);
    //this.logger.info('currBounds', that.currBounds, leftdown, topright);

    let lat_min = new WidgetOutputChangeParameters();
    lat_min.widgetId = this.widgetConfig.Id;
    lat_min.outputParameterId = 'latitude_min';
    lat_min.datatype = OutputDataType.Number;
    lat_min.value = leftdown[1];

    let lat_max = new WidgetOutputChangeParameters();
    lat_max.widgetId = this.widgetConfig.Id;
    lat_max.outputParameterId = 'latitude_max';
    lat_max.datatype = OutputDataType.Number;
    lat_max.value = topright[1];

    let lon_min = new WidgetOutputChangeParameters();
    lon_min.widgetId = this.widgetConfig.Id;
    lon_min.outputParameterId = 'longitude_min';
    lon_min.datatype = OutputDataType.Number;
    lon_min.value = leftdown[0];

    let lon_max = new WidgetOutputChangeParameters();
    lon_max.widgetId = this.widgetConfig.Id;
    lon_max.outputParameterId = 'longitude_max';
    lon_max.datatype = OutputDataType.Number;
    lon_max.value = topright[0];

    this.widgetService.outputParametersChanged([lat_min, lat_max, lon_min, lon_max]);
  }

  async outputClicked($event) {
    //this.logger.info('outputClicked', $event);
    if (this.widgetConfig.OutputParameters.length > 0) {
      let output = this.widgetConfig.OutputParameters.find((out) => out.id == $event.outputId);
      if (output) {
        let outputChanged = new WidgetOutputChangeParameters();
        outputChanged.widgetId = this.widgetConfig.Id;
        outputChanged.outputParameterId = output.id;
        outputChanged.datatype = output.datatype;
        outputChanged.value = $event.value;

        this.widgetService.outputParameterChanged(outputChanged);
      }
    }
  }

  refreshLayer(layer) {
    if (this.previousLayer != layer) {
      this.map.addLayer(layer);
      if (this.previousLayer) {
        this.vectorSource.clear();
        this.map.removeLayer(this.previousLayer);
        this.previousLayer.dispose();
        this.previousLayer = null;
      }

      this.previousLayer = layer;
    }
  }

  async updateMap(updateCenterPoint: boolean = true) {
    if (!this.widgetheight) {
      this.setWidgetHeight(this.config.Height);
    }

    setTimeout(() => {
      this.map.updateSize();
    }, 200);

    await this.refreshQueryMap();
    //this.logger.info('mapData', this.mapData);

    let view = this.map.getView();

    //this.vectorSource = null;
    switch (this.widgetConfig.RenderType) {
      case MapRenderType.MARKER:
        this.updateMarkerMap(updateCenterPoint);
        break;
      case MapRenderType.WEBGL:
        this.updateWebGLMap(updateCenterPoint);
        break;

    }
    if (updateCenterPoint) {
      view.setZoom(this.mapData.coordinates.length == 0 ? this.widgetConfig.DefaultZoomNoPoints : this.widgetConfig.DefaultZoom);
    }
  }

  private updateMarkerMap(updateCenterPoint: boolean = true) {
    let view = this.map.getView();

    const markerStyle = new Style({
      image: new Icon(/** @type {olx.style.IconOptions} */({
        anchor: [0.5, 46],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        opacity: 0.75,
        src: this.widgetConfig.MarkerURL
      }))
    });

    if (this.markerLayer == null) {
      this.markerLayer = new VectorLayer({ source: this.vectorSource, style: markerStyle });
      this.refreshLayer(this.markerLayer);
    }

    this.vectorSource.clear();

    let hasCoordinates = false;
    if (this.mapData.coordinates.length > 0) {
      for (let i = 0; i < this.mapData.coordinates.length; i++) {
        let co = this.mapData.coordinates[i];
        if (co.latitude > 0 || co.longitude > 0) {
          hasCoordinates = true;
          this.SetMarker(co.longitude, co.latitude, i);
        }

      }
    }

    if (updateCenterPoint) {
      if (!hasCoordinates) {
        view.setCenter(fromLonLat([this.widgetConfig.CenterPointLongitudeNoPoints, this.widgetConfig.CenterPointLatitudeNoPoints]));
      }
      else if (this.widgetConfig.CenterPointLatitude < 0 && hasCoordinates) {
        let co = this.mapData.coordinates.find(co => co.latitude > 0 || co.longitude > 0);
        if (co) {
          view.setCenter(fromLonLat([co.longitude, co.latitude]));
        }
      }
      else if (this.widgetConfig.CenterPointLatitude > -1 && this.widgetConfig.CenterPointLongitude > -1) {
        view.setCenter(fromLonLat([this.widgetConfig.CenterPointLongitude, this.widgetConfig.CenterPointLatitude]));
      }
    }
  }

  private updateWebGLMap(updateCenterPoint: boolean = true) {
    let view = this.map.getView();

    try {
      //cast to any just for compiler errors
      let json: any = this.widgetConfig.WebGLStyle;
      json = json.replaceAll('\n', '');
      let newLiteralStyle = JSON.parse(json);
      if (JSON.stringify(newLiteralStyle) !== JSON.stringify(this.oldLiteralStyle)) {
        this.webGLLayer = new WebGLPointsLayer({
          source: this.vectorSource,
          style: newLiteralStyle,
          disableHitDetection: false,
          //hitVertexShader: {},
          //hitFragmentShader: {}
        });
        this.oldLiteralStyle = newLiteralStyle;
      }

      this.refreshLayer(this.webGLLayer);
      this.widgetConfig.webGLErrorMessage = '';
    } catch (e) {
      //TODO : Alert
      this.widgetConfig.webGLErrorMessage = e.message;
      this.logger.info(e.message);
      //setStyleStatus(e.message);  //TODO
    }

    let features = [];
    let hasCoordinates = false;
    if (this.mapData.coordinates.length > 0) {
      for (let i = 0; i < this.mapData.coordinates.length; i++) {
        let co = this.mapData.coordinates[i];

        if (isNaN(co.longitude) || isNaN(co.latitude) || (co.longitude <= 0 && co.latitude <= 0)) {
          // guard against bad data
          continue;
        }

        hasCoordinates = true;
        let featurevalues = {
          geometry: new Point(fromLonLat([co.longitude, co.latitude])),
          index: i
        };

        this.mapData.data.forEach(qd => {
          featurevalues[qd.webGlId] = qd.data[i];
        });

        features.push(new Feature(featurevalues));
      }
    }

    this.vectorSource.clear();
    this.vectorSource.addFeatures(features);

    if (updateCenterPoint) {
      if (!hasCoordinates) {
        view.setCenter(fromLonLat([this.widgetConfig.CenterPointLongitudeNoPoints, this.widgetConfig.CenterPointLatitudeNoPoints]));
      }
      else if (this.widgetConfig.CenterPointLatitude < 0 && this.mapData.coordinates.length > 0) {
        let co = this.mapData.coordinates.find(co => co.latitude > 0 && co.longitude > 0);
        if (co) {
          view.setCenter(fromLonLat([co.longitude, co.latitude]));
        }
      }
      else if (this.widgetConfig.CenterPointLatitude > -1 && this.widgetConfig.CenterPointLongitude > -1) {
        view.setCenter(fromLonLat([this.widgetConfig.CenterPointLongitude, this.widgetConfig.CenterPointLatitude]));
      }
    }
  }

  private SetMarker(longitude: number, latitude: number, index: number) {
    if (isNaN(longitude) || isNaN(latitude) || (longitude <= 0 && latitude <= 0)) {
      // guard against bad data
      return;
    }

    var marker = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude])),
      index: index
    });
    this.vectorSource.addFeature(marker);
  }

  getExportQueries(): BaseQuery[] {
    return this.lastQueries;
  }

  //TODO : Print webGL layer!?
  exportImage(callback: (href: any) => void) {
    this.map.once('rendercomplete', function () {
      var mapCanvas: any = document.createElement('canvas');
      var size = this.map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      var mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        this.map.getViewport().querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            if (transform) {
              var matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );

            }

            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      var image;
      try {
        image = mapCanvas ? mapCanvas.toDataURL('image/jpg', 0.8) : null;
        callback(image);
      } catch (e) {
        this.logger.info('error', e);
        return;
      }
    }.bind(this));
    this.map.renderSync();
  }

  async reQuery(getQuery: () => BaseQuery, configQuery: MapWidgetQuery, queryData: QueryData[]): Promise<BaseQuery> {

    let query: BaseQuery;
    let queryResult = await this.requestBaseQueryResult(() => getQuery(),
      (query) => {
      return this.xprojClient.RequestQueryBaseQuery(query, this.forcereload, 'mapwidget', this.config.Name);
    }, configQuery, configQuery.DataFilters, this.forcereload, 'mapwidget', this.config.Name);

    this.forcereload = false;


    if (queryResult.columns) {
      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      configQuery.ColumnConfigs.forEach(columnConfig => {
        let queryColumn: BaseQueryOutputColumnDescription;
        if (columnConfig.ColumnName.indexOf(':') < 0) {
          queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnOutName);
        }
        else {
          if (columnConfig.ColumnName.startsWith('script')) {
            queryColumn = queryResult.columns.find(col => 'script:' + col.columnoutname == columnConfig.ColumnName);
          }
          else {
            queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnName);
          }
        }

        if (queryColumn) {
          let data = [];

          if (queryColumn.datatype <= XDataType.Number) {
            if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
              data = numericaldata[queryColumn.indexintypedvector];
            }
            else {
              data = WidgetUtils.FormatNumbers(numericaldata[queryColumn.indexintypedvector], this.globalWidgetSettings.Decimals);
            }
          }
          if (queryColumn.datatype == XDataType.String) {
            data = stringdata[queryColumn.indexintypedvector];
          }
          if (queryColumn.datatype == XDataType.Timestamp) {
            data = timestampdata[queryColumn.indexintypedvector];
          }

          let colData = queryData.find(d => d.columnoutname == queryColumn.columnoutname);

          if (colData) {
            colData.datatype = queryColumn.datatype;
            colData.data = data;
          }
          else {
            colData = new QueryData();
            colData.columnname = queryColumn.columnoutname;
            colData.columnoutname = queryColumn.columnname;
            colData.webGlId = columnConfig.WebGLId.length > 0 ? columnConfig.WebGLId : queryColumn.columnoutname;
            colData.label = columnConfig?.Label?.length > 0 ? columnConfig.Label : queryColumn.columnoutname;
            if (columnConfig?.Unit?.length > 0) {
              colData.label = colData.label + ' (' + columnConfig.Unit + ')';
            }
            colData.data = data;
            colData.projectionid = configQuery.ProjectionId;
            colData.group = configQuery.Group;
            colData.datatype = queryColumn.datatype;
            colData.id = columnConfig?.Id;
            colData.clickable = columnConfig?.Clickable;
            colData.hidden = columnConfig?.Hidden;

            queryData.push(colData);
          }
        }
      });
    }

    for (let i = 0; i < queryResult.columns.length; i++) {
      let it = queryResult.columns[i];

      let coloutname = it.columnoutname;
      let colname = it.columnname;

      if (colname == configQuery.LongitudeColumnName || colname == configQuery.LatitudeColumnName) {
        let colData = new QueryData();
        colData.columnoutname = coloutname;
        colData.label = coloutname;
        colData.data = queryResult.datanumbers[it.indexintypedvector];
        colData.projectionid = configQuery.ProjectionId;
        colData.group = configQuery.Group;
        colData.datatype = it.datatype;
        colData.id = coloutname;
        colData.clickable = false;
        colData.hidden = false;

        queryData.push(colData);
      }
    }

    return query;
  }

  queryByConfig: Map<string, string> = new Map<string, string>();

  checkIfNeedRefresh(query: BaseQuery, configQuery: MapWidgetQuery): boolean {
    let result = true;
    let queryJson = JSON.stringify(query)
    result = this.queryByConfig[configQuery.Id] != queryJson;
    this.queryByConfig[configQuery.Id] = queryJson;
    //this.logger.info('checkIfNeedRefresh', result);
    return result;
  }

  async refreshQueryMap(showLoading: boolean = true) {
    if (!this.loadingQuery) {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }
      if (!this.inputParametersHasValue(true)) {
        this.initilized = false;
      }
      else {
        //this.mapData.coorinates = [];
        //this.mapData.data = [];
        try {
          this.loadingQuery = true;
          this.loading = showLoading;
          let queryData: QueryData[] = [];
          this.lastQueries = [];

          await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: MapWidgetQuery) => {

            //if (this.forcereload || this.checkIfNeedRefresh(query, configQuery)) {
              let query = await this.reQuery(() => this.getQuery(configQuery), configQuery, queryData);
              this.lastQueries.push(query);
              let lonCol;
              let latCol;
              let dataCols = [];

              queryData.forEach(qd => {
                let colConfig = configQuery.ColumnConfigs.find(c => c.ColumnOutName == qd.columnoutname);
                if (colConfig) {
                  dataCols.push(qd);
                }
                else {
                  let scriptName = 'script:' + qd.columnoutname;
                  colConfig = configQuery.ColumnConfigs.find(c => c.ColumnOutName == scriptName);
                  if (colConfig) {
                    dataCols.push(qd);
                  }
                  else if (qd.columnoutname == configQuery.LongitudeColumnName) {
                    lonCol = qd;
                  }
                  else if (qd.columnoutname == configQuery.LatitudeColumnName) {
                    latCol = qd;
                  }
                  else {
                    colConfig = configQuery.ColumnConfigs.find(c => c.ColumnName == qd.columnoutname);
                    if (colConfig) {
                      dataCols.push(qd);
                    }
                    else {
                      colConfig = configQuery.ColumnConfigs.find(c => c.ColumnName == scriptName);
                      if (colConfig) {
                        dataCols.push(qd);
                      }
                    }
                  }
                }
              });

              if (lonCol && latCol) {
                //TODO
                this.mapData.coordinates = [];
                this.mapData.data = [];

                for (let i = 0; i < lonCol.data.length; i++) {
                  this.mapData.coordinates.push({ longitude: lonCol.data[i], latitude: latCol.data[i] });
                }
                dataCols.forEach(col => this.mapData.data.push(col));
              }
            //}

          });
        }
        finally {
          this.loadingQuery = false;
          this.initilized = true;
          this.loading = false;
        }
      }
    }
  }

  getQuery(configQuery: MapWidgetQuery): BaseQuery {
    let query = configQuery.Query.Clone();

    query.columns = query.columns.filter(col => col.columnname.indexOf(':') < 0);

    // Aggregation input parameters
    let i = 0;
    query.columns.forEach(col => {
      if (i > 1) {
        let columnConfig = configQuery.ColumnConfigs[configQuery.UseGrouping ? i - 1 : i - 2];
        if (columnConfig.UseAggregationInputParameter) {
          col.columnaggregation = this.getParameterValue(columnConfig.AggregationInputParameterId, col.columnaggregation).value;
        }
      }
      i++;
    });

    //Transformation input parameters
    if (configQuery.UseGrouping && configQuery.UseTransformInputParameter) {
      query.grouping.columntransformation = this.getParameterValue(configQuery.TransformInputParameterId, configQuery.GroupingTransform).value;
    }

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(query,
      this.fromZoom ?? this.from,
      this.toZoom ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    return query;
  }
}
