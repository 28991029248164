<div>
  <xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"
    [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>

  <clr-accordion>
    <clr-accordion-panel>
      <clr-accordion-title>Graph options</clr-accordion-title>
      <clr-accordion-content>
        <form clrForm>
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.DisableZoom" name="disablezoom" />
              <label>Disable zoom</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>

          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.LegendShow" name="legendshow" />
              <label>Show legend</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>

          <clr-select-container>
            <label>Legend position</label>
            <select clrSelect name="legendposition" [(ngModel)]="widgetConfig.LegendPosition">
              <option [ngValue]="LegendPosition.LEFT"> Left </option>
              <option [ngValue]="LegendPosition.TOP"> Top </option>
              <option [ngValue]="LegendPosition.RIGHT"> Right </option>
              <option [ngValue]="LegendPosition.BOTTOM"> Bottom </option>
            </select>
          </clr-select-container>

          <clr-select-container>
            <label>Legend alignment</label>
            <select clrSelect name="legendalignment" [(ngModel)]="widgetConfig.LegendAlignment">
              <option [ngValue]="LegendAlignment.START"> Start </option>
              <option [ngValue]="LegendAlignment.CENTER"> Center </option>
              <option [ngValue]="LegendAlignment.END"> End </option>
            </select>
          </clr-select-container>

          <clr-input-container>
            <label>Animations (ms)</label>
            <input clrInput placeholder="" type="number" name="animationsms" min="0" max="100000"
              [(ngModel)]="widgetConfig.AnimationsMs" />
          </clr-input-container>

          <clr-input-container>
            <label>Colorscheme</label>
            <input clrInput placeholder="" type="text" name="colorscheme" [(ngModel)]="widgetConfig.ColorScheme" />
          </clr-input-container>

          <clr-input-container>
            <label>Animations (ms)</label>
            <input clrInput placeholder="" type="number" name="animationsms" min="0" max="100000"
              [(ngModel)]="widgetConfig.AnimationsMs" />
          </clr-input-container>

          <clr-select-container>
            <label>Interaction mode</label>
            <select clrSelect name="interactionmode" [(ngModel)]="widgetConfig.InteractionMode">
              <option [ngValue]="'point'"> Point </option>
              <option [ngValue]="'nearest'"> Nearest </option>
              <option [ngValue]="'index'"> Index </option>
              <option [ngValue]="'dataset'"> Dataset </option>
              <option [ngValue]="'X'"> X </option>
              <option [ngValue]="'y'"> Y </option>
            </select>
          </clr-select-container>

          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="widgetConfig.InteractionIntersect" name="interactionintersect" />
              <label>Interaction Intersect</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>

          <clr-select-container>
            <label>Interaction axis</label>
            <select clrSelect name="interactionaxis" [(ngModel)]="widgetConfig.InteractionAxis">
              <option [ngValue]="''"> Default </option>
              <option [ngValue]="'X'"> X </option>
              <option [ngValue]="'Y'"> Y </option>
              <option [ngValue]="'xy'"> XY </option>
              <option [ngValue]="'r'"> R </option>
            </select>
          </clr-select-container>

        </form>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>

  <clr-accordion>
    <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
      <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-spectrum-analyzer-widget-query-config [widgetQuery]="configQuery" [projections]="projections"
          [widgetConfig]="widgetConfig" [inputParameters]="widgetConfig.InputParameters" [widgets]="widgets"
          (onQueryRemoved)="onQueryRemoved($event)">
        </xproj-spectrum-analyzer-widget-query-config>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addConfigQuery();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add query
  </button>
</div>
