<div class="background">
  <div class="card centered">
    <div i18n='@@resetpassword_title' class="card-header">Forgot your password?</div>
    <div class="card-block">
      <div class="card-text">
        <form clrForm (ngSubmit)="submit()">
          <clr-input-container>
            <label i18n='@@resetpassword_label' class="clr-col-12" >Enter your email address</label>
            <input i18n-placeholder='@@resetpassword_email' type="text" class="clr-col-12" name="email" clrInput
              placeholder="Email" [(ngModel)]="email" />
          </clr-input-container>
        </form>
      </div>
    </div>
    <div class="card-footer">
      <button i18n='@@resetpassword_submit' type="submit" style="width: 100%;" class="btn btn-primary"
        (click)="submit()">Submit</button>
        <a i18n='@@resetpassword_goback' [routerLink]="['/login']" routerLinkActive="active" class="signup">Cancel & Go back</a>
    </div>
  </div>
</div>
