/*
för att rita på sidan i monaco använder man decorators, finns ett exempel här;
https://microsoft.github.io/monaco-editor/playground.html?source=v0.40.0#XQAAAAIJBAAAAAAAAABBqQkHQ5NjdMjwa-jY7SIQ9S7DNlzs5W-mwj0fe1ZCDRFc9ws9XQE0SJE1jc2VKxhaLFIw9vEWSxW3yscw4J9ALRiS2N9aGkduDPa_nphCLwTjKAmpfIQJUhRZ_qarljn44uHUpLnwpFNF9vNfG1Ntp_F0GcBzMJ1yXbVcHjlMfeIefPbGYivywbbnI4-8mj9ukD-yo9ZS8_PseIeZuy6M4jSt120UWqI_Sj2Lohwaog_FkBmZpuV-AxA2GDAE_090SXmZyoaJzEL7n6mKsHC4lQeTwpvmH7F4xmWuwYDfZ79d_fJFdJG8dZFPhDY7j9xCgHain6vViQg7W4gOr0LW8OjMj8Wl4OaYjLrkKYHgPWC-qkZlEELy0QtFlE52WTMwUBEyfXjaMK5M2d--L3lOvtviMvNOSiKeJFgr6zVdz8y9IBMv3xhO4IozZF98hMv4EF1YG6EQRZf6XKrUVYIQBl6KWQISlPsj6_S5FIy4JqgSYl49wm0aJfQv9-hgcKxBzg65xvkQfDZfiUHXWjMoq_1i6w3LfT_RhHGk8_yJ6bQFwdmP1udiYTvlW6jBJIxwwKSzV_e60cUI_hqAtF788bGfO48zmFDNqyqjAaXvZYdwcQrXdwCBdVFH2vkjJmK9f75Sjid2GGStoz7zOVsR9FW6WhEqKtbBkAoS_-S04SSf-c4EAcXwvP1iKHQYMUbPy-sh5dLrK1aIe__4TYLi
så på nåt sätt måste man komma åt createDecorationsCollection eller ha den wrappad från ngx-monaco..
*/

import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { environment } from 'src/environments/environment';
import {LuadebuggerService} from './luadebugger.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { sleep } from '@cds/core/internal';
import { LuaDebuggerState } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj-luadebugger',
  templateUrl: './luadebugger.component.html',
  styleUrls: ['./luadebugger.component.scss']
})
export class XProjLuadebuggerComponent implements OnInit {

  @Input() set SID (value : string)
  {
    this.userRequestSelectedInstanceID = value;
    this.attach()
  }

  public subscription : Subscription;


  userRequestSelectedInstanceID:string = "";
  selectedInstance : any = null;

  sizeOptions = [10, 20, 50, 100];
  //sizeOptionsBreakpoints = [10, 20, 50, 100];
  sidepanelsizepx = 400;

  ascSort = ClrDatagridSortOrder.ASC;

  // source = null; //"function blaha(a)\r\n    print(\"nu b\u00F6rjar jag\")\r\n    local blipp = 10\r\n    arne = 10\r\n    for i=0, 10, 1\r\n    do\r\n        a = a + i + blipp + arne\r\n    end\r\n\r\n    print(\"nu skall jag returnera:\", a)\r\n    return a\r\nend\r\n\r\nglobal_init = 10\r\n\r\nlocal x = blaha(global_init)\r\nlocal y = blaha(x)\r\n\r\nprint(x,y)\r\nprint(\"nu \u00E4r jag klar..\")";
  // sourcehash = 0;
  selectedWatch = null;
  loadingWatch = false;
  //watchvars= [];
  userRequestWatch: string = null;
  

  localvars = [];  
  selectedLocalVar = null;
  loadingVariablesLocal = false;
  loadingBreakpoints = false;
  selectedBreakpoint = null;
  userRequestBreakpointLineNumber : number = null;


  

  editorOptionsLua = 
  { 
    theme: environment.editortheme, 
    language: 'lua', 
    readOnly: true,
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    glyphMargin:true,
    minimap: { enabled: false },
    hover: {enabled: true, sticky : true, delay: 100}
  };
  

  Editor : monaco.editor.ICodeEditor = null;
  

  constructor( private route: ActivatedRoute, public luaDebugger : LuadebuggerService ) 
  {     
    let that = this;
    let hasUpdatedAfterBreaked = false;
    setInterval(() => {
      let runUpdate = false;

      // if(this.luaDebugger.state == LuaDebuggerState.Paused)
      // {
      //   // if(!hasUpdatedAfterBreaked)
      //   // {
      //   //   runUpdate = true;
      //   //   hasUpdatedAfterBreaked = true;          
      //   // }
      //   runUpdate = true;
      // }
      // // else
      // // {
      // //   hasUpdatedAfterBreaked = false;
      // //   runUpdate = true;
      // // }

      // if(runUpdate)
      // {
        that.updateDebugger();
      //}
    }, 1000);
  }


  selectedSIDChanged($what)
  {
    this.userRequestSelectedInstanceID = $what;
  }

  async onEditorMouseDown( e: monaco.editor.IEditorMouseEvent)
  {        
    console.log("breakpoint clicky click", e);
    if(e.target.type == monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN || 
      e.target.type == monaco.editor.MouseTargetType.GUTTER_LINE_NUMBERS ||
      e.target.type == monaco.editor.MouseTargetType.GUTTER_LINE_DECORATIONS)
      {        
        //let newBreakpoints = [];
        let existed = false;
        console.log("breakpoint Toggle breakpoint at line", e.target.range.startLineNumber);
        let bp = e.target.range.startLineNumber;
        for(let i = 0; i < this.luaDebugger.breakpoints.length; i++)
        {
          let itbp = this.luaDebugger.breakpoints[i];
          if(itbp == bp)
          {
            console.log("Existed");
            this.luaDebugger.breakpoints.splice(i, 1);
            existed = true;
            break;
          }
        }
        if(!existed)
        {
          console.log("breakpoint !Existed");
          this.luaDebugger.breakpoints.push(bp);
        }

        console.log("breakpoint setbreakpoints", this.luaDebugger.breakpoints);
        await this.luaDebugger.setBreakpoints(null);
        this.updateMarginGlyphs();        
      }
  }

  onInitEditor(editor : any)
  {
    console.log("editor:", editor);
    let ed = editor as monaco.editor.ICodeEditor;
    this.Editor = ed;    
    this.updateMarginGlyphs();
    //this.Editor.onMouseDown = this.onEditorMouseDown;
    this.Editor.onMouseDown ( this.onEditorMouseDown.bind(this));
  }

  oldDecs = [];

  updateMarginGlyphs()
  {        
    let newDecs = [];

    newDecs.length = 0;
    let foundActiveLine = false;

    for(let i of this.luaDebugger.breakpoints)
    {
      let lineclass = '';
      if( i == this.luaDebugger.activeline && this.luaDebugger.state == LuaDebuggerState.Paused )
        lineclass = "monacoHighlightLine";

      newDecs.push(
        {
          range: new monaco.Range(i, 1, i, 1),
          options: {
            isWholeLine: true,
            className: lineclass,
            linesDecorationsClassName: 'monacoGlyphBreakpoint'
          }
        }
      );
    }

    if(!foundActiveLine  && this.luaDebugger.activeline != null  && this.luaDebugger.state == LuaDebuggerState.Paused)
    {
      newDecs.push(
        {
          range: new monaco.Range(this.luaDebugger.activeline, 1, this.luaDebugger.activeline, 1),
          options: {
            isWholeLine: true,
            className: 'monacoHighlightLine',
            linesDecorationsClassName: ''
          }
        }
      );  
    }    

    this.oldDecs = this.Editor.deltaDecorations(this.oldDecs, newDecs);

    
  }

  public gettingSource = false;

  async updateDebugger()
  {    
    console.log("Update debugger()");
    let currenthash = this.luaDebugger.sourcehash;
    let oldLine = this.luaDebugger.activeline;
    let oldState = this.luaDebugger.state;
    await this.luaDebugger.VerifyStatus();
    if(this.luaDebugger.state == LuaDebuggerState.NotRunning)
      return;

    if(currenthash != this.luaDebugger.sourcehash || currenthash == 0)
    {
      this.gettingSource = true;
      while(! await this.luaDebugger.VerifySource())
      {
        console.log("Verify source not ready so waiting and will retry");
        await sleep(2000);
      }
      console.log("Verify done!");
      this.gettingSource = false;
    }

    // if( (this.luaDebugger.activeline != oldLine || this.luaDebugger.state != oldState) && this.luaDebugger.state == LuaDebuggerState.Paused)
    if(this.luaDebugger.state == LuaDebuggerState.Paused)
    {
      this.luaDebugger.getVariables();
    }

    this.updateMarginGlyphs();
  }

  ngOnInit(): void {
    this.luaDebugger.ReloadPotentialSIDs();
    let that = this;

    this.subscription = this.route.params.subscribe(params => {
      this.userRequestSelectedInstanceID = params['id'];      
      that.attach();
    })  
  }

  async addwatch()
  {
    try
    {
      this.luaDebugger.watches.push(this.userRequestWatch);
      this.userRequestWatch = null;
      await this.luaDebugger.setWatches(null);
      //await this.luaDebugger.getVariables();
    }
    catch(err)
    {
      console.log(err);
    }
  }

  async clearwatch()
  {
    await this.luaDebugger.setWatches([]);
    this.userRequestWatch = null;
  }

  async addbreakpoint()
  {
    for(let i of this.luaDebugger.breakpoints)
    {
      if(i == this.userRequestBreakpointLineNumber)
        return;
    }
    this.luaDebugger.breakpoints.push(this.userRequestBreakpointLineNumber);
    await this.luaDebugger.setBreakpoints(null);
    this.userRequestBreakpointLineNumber = null;
    this.updateMarginGlyphs();
  }

  async clearbreakpoints()
  {
    await this.luaDebugger.setBreakpoints([]);
    this.userRequestBreakpointLineNumber = null;
    this.updateMarginGlyphs();
  }

  async release()
  {
    await this.luaDebugger.release();
    await this.updateDebugger();
  }

  async attach()
  {    
    await this.luaDebugger.attach(this.userRequestSelectedInstanceID);        
    await this.updateDebugger();
  }

  async run()
  {
    await this.luaDebugger.run();
    await this.updateDebugger();
  }

  async break()
  {
  }

  async stepInto()
  {
    await this.luaDebugger.stepInto();    
    await this.updateDebugger();
  }

  async stepOver()
  {
    await this.luaDebugger.stepOver();
    await this.updateDebugger();
  }

}
