<clr-modal [(clrModalOpen)]="showXEdgeSelectMetersModal" [clrModalSize]="'xl'" *ngIf="showXEdgeSelectMetersModal"
  [clrModalClosable]="false">
  <h3 class="modal-title">Add XEdge meters</h3>
  <div class="modal-body xedge-select-meter-modal">
    <app-xedge-select-xauto-vars #selectXEdgeMeters [remoteNodeId]="xedgeRemoteNodeId"></app-xedge-select-xauto-vars>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="showXEdgeSelectMetersModal=false;">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="selectXEdgeMeters?.selectedXAutoVars?.length < 1"
      (click)="addSelectedXEdgeMeters()">Add selected meters ({{selectXEdgeMeters?.selectedXAutoVars?.length}})</button>
  </div>
</clr-modal>

<div class="clr-row">
  <div class="clr-col-8">
    <clr-combobox-container class="realestate-combo sensitive">
      <label>Select realestate:</label>
      <clr-combobox [(ngModel)]="selectedRealestate" name="realestateselect"
        (ngModelChange)="selectedRealestateChanged($event)" (clrOpenChange)="realestateSelectOpenChange($event)">
        <clr-options>
          <clr-option *clrOptionItems="let realestate of realestates; field:'name'" [clrValue]="realestate">
            {{realestate.name}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
  </div>
  <div class="clr-col-4">

  </div>
</div>

<clr-modal [(clrModalOpen)]="showGridEditModal">
  <h3 class="modal-title">Edit node</h3>
  <div class="modal-body">
    <app-edit-treenode #editGridNode *ngIf="showGridEditModal && currentGridEditNode" [node]="currentGridEditNode"
      [nodeTypes]="allNodeTypes"></app-edit-treenode>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeGridEditNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveGridEditNode()">Save</button>
  </div>
</clr-modal>

<clr-tabs #tabs>
  <clr-tab>
    <button clrTabLink>Overview</button>
    <ng-template [(clrIfActive)]="overviewActive">
      <clr-tab-content>
        <div class="customers-content-area">
          <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false"
            [systemDashboard]="true" [dashboardId]="'bms_realestate_overview'" [enableExport]="false"
            [dashboardTag]="'latest'" [responsiveWidth]="overviewResponsiveWidth"
            [dashboardOutputParameters]="dashboardOverviewOutputParameters"
            (onWidgetValueSelected)="onRealestateDashboardValueChanged($event)"></xproj-dashboard>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink (click)="bmsActive = true;">BMS</button>
    <div class="tab-area" *ngIf="bmsActive">
      <clr-tab-content>
        <div class="customers-content-area">
          <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEndBMS()" class="main-area"
            [gutterSize]="7">
            <as-split-area [order]="3">
              <app-configuration-datasource #bmsConfigurationDatasource
                [sensitiveNodes]="['_x_bms_realestate', '_x_bms_building', '_x_bms_buildingaddress']"
                *ngIf="bmsDataSourceInstance && selectedRealestate" [lazy]="false"
                [dataSourceInstance]="bmsDataSourceInstance" [rootNodes]="[selectedRealestateNode]" [expandRoot]="true"
                [customerId]="selectedBmsCustomer.customerId" [sortByName]="true" [sortPaddingZeros]="10"
                [dropDownItems]="dropDownItems" [highlightSelected]="true" (onTreeNodeSelect)="onBMSTreeNodeSelect($event)"
                [moveEnabled]="true" [searchParameterIds]="[{typeName : 'string', key : 'deveui'}]"
                [moveEnabledNodeTypeIds]="['_x_bms_building', '_x_bms_buildingaddress', '_x_bms_facility', '_x_bms_apartment']"
                [singletonReferences]="true">
              </app-configuration-datasource>
            </as-split-area>
            <as-split-area [(size)]="bmsRightPanelWidth" [order]="4">
              <app-rossaker-bms-data-collector-view #rossakerBmsBMSView>
              </app-rossaker-bms-data-collector-view>
            </as-split-area>
          </as-split>
        </div>
      </clr-tab-content>
    </div>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Grid</button>
    <ng-template [(clrIfActive)]="gridActive">
      <div class="grid-area" *ngIf="selectedCustomerData">
        <clr-tab-content>
          <clr-datagrid [(clrDgSelected)]="selectedMeterDatas" [clrDgLoading]="loadingMeterData">

            <clr-dg-action-bar>
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditApartmentsOrFacilities()"
                  [disabled]="selectedMeterDatas?.length != 1">
                  <clr-icon shape="pencil"></clr-icon> Edit apartment/facility{{selectedMeterDatas.length > 1 ?
                  's' : ''}} ({{selectedMeterDatas.length}})
                </button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditMeters()"
                  [disabled]="selectedMeterDatas?.length != 1">
                  <clr-icon shape="pencil"></clr-icon> Edit meter{{selectedMeterDatas.length > 1 ? 's' : ''}}
                  ({{selectedMeterDatas.length}})
                </button>
              </div>

              <clr-button-group class="btn-sm btn-secondary">
                <clr-button (click)="onGridEditViewInBMS();" [disabled]="selectedMeterDatas?.length != 1">View in
                  BMS
                  <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon>
                </clr-button>
                <clr-button (click)="onGridExport()">
                  <clr-icon shape="export"></clr-icon> Export
                </clr-button>
                <clr-button (click)="onGridRefresh()">
                  <clr-icon shape="refresh"></clr-icon> Refresh
                </clr-button>
                <clr-button [clrInMenu]="true" (click)="showEditBuildingAddresses()">
                  Edit building addresses...</clr-button>
              </clr-button-group>
            </clr-dg-action-bar>

            <clr-dg-column [clrDgField]="'seconadaryAddress'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Identifier</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'externalId'" [clrDgSortBy]="externalIdSort" [clrDgSortOrder]="ascSort">
              <ng-container *clrDgHideableColumn="{hidden: false}">External Id</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'svlantApartmentno'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Apart. No</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'floor'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Floor</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meterId'">
              <ng-container *clrDgHideableColumn="{hidden: true}">Meter Id</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'tag'" style="min-width: 12em;">
              <ng-container *clrDgHideableColumn="{hidden: false}">Tag</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'gw_serialnumber'">
              <ng-container *clrDgHideableColumn="{hidden: true}">GW Serial</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'manufacturer'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Vendor</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'value'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Value</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'timestamp'" style="min-width: 12em;">
              <ng-container *clrDgHideableColumn="{hidden: false}">Timestamp</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'unit'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Unit</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'state'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Meter state</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meterType'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Meter type</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meterSubtype'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Meter subtype</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'variable'">
              <ng-container *clrDgHideableColumn="{hidden: true}">Variable</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'svlantPropertyDesignation'" style="min-width: 15em;">
              <ng-container *clrDgHideableColumn="{hidden: false}">Prop. Designation</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'svLantBuildingNo'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Building No</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'street'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Street</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'housenumber'">
              <ng-container *clrDgHideableColumn="{hidden: false}">House No.</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'postalcode'">
              <ng-container *clrDgHideableColumn="{hidden: false}">Postal code</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'postalcode'">
              <ng-container *clrDgHideableColumn="{hidden: false}">City</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'district'">
              <ng-container *clrDgHideableColumn="{hidden: false}">District</ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let meterData of selectedCustomerData.meterDatas; let i = index"
              [clrDgItem]="meterData">
              <clr-dg-cell>{{meterData.seconadaryAddress}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.externalId}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.svlantApartmentno}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.floor}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.meterId}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.tag}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.gw_serialnumber}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.manufacturer}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.value?.toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell>{{formatByString(meterData.timestamp,'yyyy-MM-dd HH:mm')}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.unit}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.state}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.meterType}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.meterSubtype}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.variable}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.svlantPropertyDesignation}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.svLantBuildingNo}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.street}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.housenumber}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.postalcode}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.city}}</clr-dg-cell>
              <clr-dg-cell>{{meterData.district}}</clr-dg-cell>
            </clr-dg-row>

            <ng-template [(clrIfDetail)]="gridDetailState" let-dataIndex
              (clrIfDetailChange)="onGridDetailChange($event)">
              <!-- <ng-template clrIfDetail let-dataIndex (clrIfDetailChange)="onDetailChange($event)"> -->
              <clr-dg-detail>
                <clr-dg-detail-header>Meter data</clr-dg-detail-header>
                <clr-dg-detail-body>
                  <div class="customers-content-area">
                    <xproj-dashboard #dashboardGridDetail [editMode]="false" [showDashboardSettings]="false"
                      [systemDashboard]="true" [dashboardId]="'bms_meter'" [dashboardTag]="'latest'"
                      [enableExport]="false" [responsiveWidth]="gridResponsiveWidth"
                      [dashboardOutputParameters]="dashboardGridDetailOutputParameters"></xproj-dashboard>
                  </div>
                </clr-dg-detail-body>
              </clr-dg-detail>
            </ng-template>

            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="20">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Meters per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </clr-tab-content>
      </div>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Data collectors</button>
    <div class="tab-area">
      <clr-tab-content>
        <div class="customers-content-area">
          <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEndDataCollectors()" class="main-area"
            [gutterSize]="7">
            <as-split-area [order]="1">
              <app-configuration-datasource #dataCollectorsDatasourceConfig
                [sensitiveNodes]="['_x_datacollectors_group']" *ngIf="dataCollectorsDataSourceInstance"
                [dataSourceInstance]="dataCollectorsDataSourceInstance" [lazy]="false" [treeMaxHops]="10"
                [rootNodes]="[selectedRealestateDataCollectorsNode]" [sortByName]="true" [sortPaddingZeros]="10"
                [highlightSelected]="true" [customerId]="this.selectedBmsCustomer?.customerId"
                (onTreeNodeSelect)="onDataCollectorsTreeNodeSelect($event)" [moveEnabled]="true"
                [moveEnabledNodeTypeIds]="[]" [expandRoot]="true" [dropDownItems]="dropDownItemsDataCollectors">
              </app-configuration-datasource>
            </as-split-area>
            <as-split-area [(size)]="dataCollectorsRightPanelWidth" [order]="2">
              <app-rossaker-bms-data-collector-view #rossakerBmsDataCollectorView>
              </app-rossaker-bms-data-collector-view>
            </as-split-area>
          </as-split>

        </div>
      </clr-tab-content>
    </div>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Export / Import</button>
    <ng-template [(clrIfActive)]="exportImportActive">
      <clr-tab-content>
        <div class="customers-content-area">

          <div class="card">
            <div class="card-header">
              Device file
            </div>
            <div class="card-block">
              <div class="card-text">
                <button class="btn btn-outline" [clrLoading]="exportingState" (click)="exportMeters()">
                  <clr-icon shape="export"></clr-icon>
                  Export meter file
                </button>

                <form clrForm clrLayout="vertical" autocomplete="off">
                  <clr-input-container>
                    <label class="clr-col-12">Import meter file, select or drop file.</label>
                    <input clrInput class="clr-col-12" name="importmeterfile_file" #file type="file"
                      (change)="uploadMeterFile(file)" />
                  </clr-input-container>
                </form>

                <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                  [disabled]="!importMeterfile" (click)="importMeterFiles()">
                  <clr-icon shape="import"></clr-icon>
                  Import meter file
                </button>
              </div>
            </div>
          </div>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
