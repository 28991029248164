export class RossakerBmsMeter {
  id : number;
  customerId : string;
  apartmentId : number;
  facilityId : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;
  gatewayId : number;

  identifier : string;
  manufacturer : string;
  unit : string;
  datapointValueUnit : string;
  variable : string;
  index : number;
  state : RossakerBmsMeterState;
  model : string;
  typeId : string;
  meterType : string;
  meterSubtype : string;
  coeffiecent : number;
  tag : string;

  createdAt : Date;
  modifiedAt : Date;
}


export enum RossakerBmsMeterState {
  Inactive = 0,
  InactiveReplaced = 1,
  Test = 20,
  ActiveBilling = 40,
  ActiveExport = 41,
  ActiveNoBilling = 60
}
