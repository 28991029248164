import { Component, Inject, Input, OnInit } from '@angular/core';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../../widgets/xproj-widget-service';
import { OutputDataType, WidgetConfig } from '../../../../../../widgets/widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { BaseControllerConfig, XprojOutputToggleControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-toggle-controller',
  templateUrl: './xproj-output-toggle-controller.component.html',
  styleUrls: ['./xproj-output-toggle-controller.component.scss']
})
export class XprojOutputToggleControllerComponent extends XprojOutputControllerBase implements OnInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      if (!this.useApplyButton) {
        this.toggleChanged();
      }
    }
    this._initDone = value;
  }

  toggleConfig: XprojOutputToggleControllerConfig;

  selectedValue: boolean;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService
  ) {
    super();
  }


  ngOnInit(): void {
    this.toggleConfig = this.config as XprojOutputToggleControllerConfig;

    this.selectedValue = this.toggleConfig.DefaultValue;
  }

  ngAfterViewInit(): void {

  }

  toggleChanged($event?) {
    if (!this.useApplyButton) {
      this.widgetService.outputParameterChanged(this.getOutputChangeParameters()[0]);
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let output = new WidgetOutputChangeParameters();
    output.widgetId = this.widgetConfig.Id;
    output.outputParameterId = this.toggleConfig.Id;
    output.datatype = this.toggleConfig.DataType;
    switch (this.toggleConfig.DataType) {
      case OutputDataType.String:
        output.value = this.selectedValue ?  this.toggleConfig.OnConfig.ValueString : this.toggleConfig.OffConfig.ValueString;
        break;
      case OutputDataType.Timestamp:
        output.value = this.selectedValue ?  this.toggleConfig.OnConfig.ValueDate : this.toggleConfig.OffConfig.ValueDate;
        break;
      default:
        output.value = this.selectedValue ?  this.toggleConfig.OnConfig.ValueNumber : this.toggleConfig.OffConfig.ValueNumber;
        break;
    }

    return [output];
  }

}
