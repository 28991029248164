import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { map } from 'rxjs/operators';
import { XprojDashboardComponent, DashboardOutputChangeParameters, ArrayUtils } from 'xproj-lib';
import { ConfigurationDataSourceComponent, DropDownItem } from '../configuration-datasource/configuration-datasource.component';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { NGXLogger } from 'ngx-logger';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

@Component({
  selector: 'app-view-datasource-instance',
  templateUrl: './view-datasource-instance.component.html',
  styleUrls: ['./view-datasource-instance.component.scss']
})
export class ViewDataSourceInstanceComponent implements OnInit {

  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;
  @ViewChild("bmsConfigurationDatasource", { read: ConfigurationDataSourceComponent, static: false }) bmsConfigurationDatasource: ConfigurationDataSourceComponent;

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  @Input() viewId : string = '';
  @Input() customerId : string = '';
  @Input() dataSourceInstance: GrpcDataSourceInstance;
  @Input() highlightSelected : boolean = false;
  @Input() sortByName : boolean = false;
  @Input() sortPaddingZeros: number = 0;
  @Input() rootNodes: GrpcNode[] = [];
  @Input() dropDownItems : DropDownItem[] = [];
  @Input() moveEnabled: boolean = false;
  @Input() moveEnabledNodeTypeIds: string[] = [];
  @Input() expandRoot: boolean = false;
  @Input() selectedPath: string[] = [];
  @Input() useRouteId : boolean = true;
  @Input() lazy : boolean = true;
  @Input() treeMaxHops: number = 5;
  @Input() singletonReferences: boolean = false;
  @Input() deleteWtihChildrenEnabled: boolean = false;
  @Input() sensitiveNodes : string [] = [];
  @Input() showNodeTypes : boolean = false;
  @Input() eventLogdEnabled: boolean = true;


  @Output() onTreeNodeSelect = new EventEmitter<{treeNode: XconfTreeNode, nodeType: GrpcNodeType}>();

  dataSourceInstanceId: string;
  _dataSourceInstance: GrpcDataSourceInstance

  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardId: string;
  systemDashboard : boolean = false;

  showDetailPane: boolean = false;
  showSearchPane: boolean = false;
  datasourcePaneWidth: number = 200;

  responsiveWidth : number = 834;

  constructor(private route: ActivatedRoute,
    private xConfClient: XProjectorXConfClient,
    private logger: NGXLogger,
    private cdr: ChangeDetectorRef) {
    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (!this.dataSourceInstance) {
        this.dataSourceInstanceId = id;
        setTimeout(async () => {
          await this.reloadDataSourceInstance();
        });
      }
    });

  }

  async ngOnInit() {
    let lsdatasourcePaneWidth = Number.parseInt(localStorage.getItem("xprojector-datasourcePaneWidth-" + this.viewId) || this.datasourcePaneWidth.toString());
    if (lsdatasourcePaneWidth != this.datasourcePaneWidth && lsdatasourcePaneWidth > 200) {
      this.datasourcePaneWidth = lsdatasourcePaneWidth;
    }

    await this.reloadDataSourceInstance();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async reloadDataSourceInstance() {
    if (this.dataSourceInstance) {
      this._dataSourceInstance = this.dataSourceInstance;
      this.logger.info('_dataSourceInstance', this._dataSourceInstance);
    }
    else if (this.useRouteId && this.dataSourceInstanceId?.length > 0) {
      if (this.dataSourceInstanceId?.length > 0) {
        this._dataSourceInstance = await this.xConfClient.getDataSourceInstance(this.dataSourceInstanceId);
      }
      this.logger.info('dataSourceInstance', this._dataSourceInstance);
    }
  }

  async onTreeNodeSelected(item: {treeNode : XconfTreeNode, nodeType : GrpcNodeType}) {
    this.dashboardId = '';
    let outputs: DashboardOutputChangeParameters[] = [];

    if (item.treeNode) {
      item.treeNode.node.propertyValues.forEach(p => {
        if (p.valueType == GrpcNodeProperty.ValueTypes.DASHBOARD) {
          this.dashboardId = p.value;
          let prop = item.nodeType.dashboardProperties.find(prop => prop.id == p.key);
          if (prop) {
            this.systemDashboard = prop.systemDashboard;
          }
        } else {
          let out = new DashboardOutputChangeParameters();
          out.outputParameterName = p.key;
          out.value = p.value;
          outputs.push(out);
        }
      })

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = item.treeNode.id;
      outputs.push(out);
    }

    this.showSearchPane = false;
    this.showDetailPane = false;

    if (!item.treeNode.parent) {
      this.showSearchPane = true;
    }
    else if (this.dashboardId?.length > 0) {
      this.showDetailPane = true;
      this.dashboardOutputParameters = outputs;

      this.cdr.detectChanges();

      setTimeout(async () => {
        await this.xprojDashboard?.setDashboardOutputParameters(this.dashboardOutputParameters);
        await this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', this.systemDashboard);
      });
    }

    this.onTreeNodeSelect.next(item);
  }

  getPaneArea(order : number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async updateWidth() {
    await this.onSplitDragEnd(null);
  }

  async onSplitDragEnd($event) {
    let datasourcePaneArea = this.getPaneArea(1);

    if (datasourcePaneArea) {
      this.datasourcePaneWidth = datasourcePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-datasourcePaneWidth-" + this.viewId, this.datasourcePaneWidth.toString());
    }

    let dashboardPaneArea = this.getPaneArea(2);

    if (dashboardPaneArea) {
      this.responsiveWidth = dashboardPaneArea.elRef.nativeElement.clientWidth;
    }

    if (this.dashboardId?.length > 0) {
      await this.xprojDashboard?.refresh();
    }
  }

  async refresh() {
    await this.xprojDashboard?.refresh();
  }

  async onViewTreeNode(item: { nodeId : string, nodeTypeLabel : string }) {
    if ((!this.rootNodes || this.rootNodes.length == 0) && this._dataSourceInstance) {
      this.rootNodes = await this.xConfClient.getDataSourceInstanceRootNodes(this._dataSourceInstance.id);
    }

    if (this.rootNodes?.length > 0) {
      this.selectedPath = await this.xConfClient.getShortestPath(this.rootNodes[0].id, this.rootNodes[0].nodeTypeLabel, item.nodeId, item.nodeTypeLabel, 10);
      if (this.bmsConfigurationDatasource) {
        this.bmsConfigurationDatasource.selectedPath = this.selectedPath;
        this.bmsConfigurationDatasource.initDataSourceInstance(false, true);
      }
    }
  }

  async initDataSourceInstance(updateDataSourceDefinition: boolean = true, sendEvent: boolean = true) {
    if (this.bmsConfigurationDatasource) {
      this.bmsConfigurationDatasource.selectedPath = this.selectedPath;
      this.bmsConfigurationDatasource.initDataSourceInstance(updateDataSourceDefinition, sendEvent);
    }
  }

}
