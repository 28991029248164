<form clrForm>
    <clr-input-container>
        <label>Label</label>
        <input clrInput placeholder="" name="label" [(ngModel)]="aggregationConfig.Label" />
    </clr-input-container>

    <clr-select-container>
        <label>Default aggregation</label>
        <select clrSelect name="defaultAggregation" [(ngModel)]="aggregationConfig.DefaultAggregation">
      <option [ngValue]="Aggregation.NONE"> None </option>
      <option [ngValue]="Aggregation.COUNT"> Count </option>
      <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

      <option [ngValue]="Aggregation.SUM"> Sum </option>

      <option [ngValue]="Aggregation.MAX"> Max </option>
      <option [ngValue]="Aggregation.MIN"> Min </option>

      <option [ngValue]="Aggregation.FIRST"> First </option>
      <option [ngValue]="Aggregation.LAST"> Last </option>

      <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

      <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
      <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
      <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

      <option [ngValue]="Aggregation.MEDIAN"> Median </option>

      <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
      <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
      <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
      </option>

      <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
        arithmetic mean </option>
      <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
        mean </option>
      <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
        mean </option>
      <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
    </select>
    </clr-select-container>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.NONE" name="NONE" />
            <label>Show NONE</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.COUNT" name="COUNT" />
            <label>Show COUNT</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.SUM" name="SUM" />
            <label>Show SUM</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MIN" name="MIN" />
            <label>Show MIN</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MAX" name="MAX" />
            <label>Show MAX</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.FIRST" name="FIRST" />
            <label>Show FIRST</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.LAST" name="LAST" />
            <label>Show LAST</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.CHECKSUM" name="CHECKSUM" />
            <label>Show CHECKSUM</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.FORWARD_DIFF" name="FORWARD_DIFF" />
            <label>Show FORWARD_DIFF</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MEAN_ARITHMETIC" name="MEAN_ARITHMETIC" />
            <label>Show MEAN_ARITHMETIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MEAN_GEOMETRIC" name="MEAN_GEOMETRIC" />
            <label>Show MEAN_GEOMETRIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MEAN_HARMONIC" name="MEAN_HARMONIC" />
            <label>Show MEAN_HARMONIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MEDIAN" name="MEDIAN" />
            <label>Show MEDIAN</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.SD_SAMPLED_UNCORRECTED" name="SD_SAMPLED_UNCORRECTED" />
            <label>Show SD_SAMPLED_UNCORRECTED</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.SD_SAMPLED_CORRECTED" name="SD_SAMPLED_CORRECTED" />
            <label>Show SD_SAMPLED_CORRECTED</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.SD_SAMPLED_UNBIASED_APPROX_ND" name="SD_SAMPLED_UNBIASED_APPROX_ND" />
            <label>Show SD_SAMPLED_UNBIASED_APPROX_ND</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MAD_ORIGO_MEAN_ARITHMETIC" name="MAD_ORIGO_MEAN_ARITHMETIC" />
            <label>Show MAD_ORIGO_MEAN_ARITHMETIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MAD_ORIGO_MEAN_GEOMETRIC" name="MAD_ORIGO_MEAN_GEOMETRIC" />
            <label>Show MAD_ORIGO_MEAN_GEOMETRIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MAD_ORIGO_MEAN_HARMONIC" name="MAD_ORIGO_MEAN_HARMONIC" />
            <label>Show MAD_ORIGO_MEAN_HARMONIC</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.MAD_ORIGO_MEDIAN" name="MAD_ORIGO_MEDIAN" />
            <label>Show MAD_ORIGO_MEDIAN</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.VARIANCE_ND" name="VARIANCE_ND" />
            <label>Show VARIANCE_ND</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="aggregationConfig.S2_ND" name="S2_ND" />
            <label>Show S2_ND</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</form>