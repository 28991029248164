<div>
  <form clrForm>
    <clr-select-container>
      <label>Projection</label>
      <select clrSelect id="useprojectioninput" name="useprojectioninput"
        [(ngModel)]="queryData.useProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="!queryData.useProjectionInputParameter" name="projection"
        [(ngModel)]="queryData.projection" (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="queryData.useProjectionInputParameter" class="clr-select"
        name="UseProjectionInputParameter" [(ngModel)]="queryData.projectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input / Group</label>
      <select clrSelect id="usegroupinput" name="usegroupinput" [(ngModel)]="queryData.groupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
      </select>

      <select clrSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select"
        name="UseProjectionInputParameter" [(ngModel)]="queryData.groupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <xproj-group-selection #groupSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP"
      [projection]="queryData.projection" [selected]="widgetQuery.Query?.targetgroup"
      (groupSelected)="selectedProjectionGroupChange($event)">
    </xproj-group-selection>

    <clr-combobox-container *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
      <label>Group input selections</label>
      <clr-combobox [(ngModel)]="queryData.groupInputParameterIds" name="multiSelect" clrMulti="true" required>
        <ng-container *clrOptionSelected="let selected">
          {{selected}}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
            [clrValue]="input.id">
            {{input.id}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
  </form>

  <h5>Data</h5>
  <form clrForm>
    <clr-select-container>
      <label>X Axis</label>
      <select clrSelect name="xaxis" [(ngModel)]="queryData.xaxis">
        <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : true " [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>

      <select style="margin-left: 1em;" clrSelect id="usetransforminput" name="usetransforminput"
        [(ngModel)]="queryData.useTransformInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Aggregation </option>
      </select>

      <select style="margin-left: 1em;" *ngIf="!queryData.useTransformInputParameter" clrSelect
        name="xaxisgrouptransform" id="xaxisgrouptransform" [(ngModel)]="queryData.xaxistransform">
        <option [ngValue]="Transformation.NONE"> None </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
        <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="queryData.useTransformInputParameter" class="clr-select"
        name="UseTransformInputParameter" [(ngModel)]="queryData.transformInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Y Axis</label>
      <select clrSelect class="clr-select" name="yaxis" id="yaxis" [(ngModel)]="queryData.yAxesConfig.ColumnName">
        <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : false" [ngValue]="col.columnname">
          {{col.columnname}}</option>
        <option *ngFor="let col of queryData.preQueryColumnConfigs" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Y Label</label>
      <input clrInput type="text" placeholder="" name="ylabel" [(ngModel)]="queryData.yAxesConfig.Label" />
    </clr-input-container>

    <clr-select-container>
      <label>Chart type</label>
      <select clrSelect name="charttype" id="yaxis" [(ngModel)]="queryData.chartType">
        <option [ngValue]="SPCChartType.XBAR"> XBAR </option>
        <option [ngValue]="SPCChartType.SIGMA"> SIGMA </option>
        <option [ngValue]="SPCChartType.INDIVIDUALS"> INDIVIDUALS </option>
        <option [ngValue]="SPCChartType.P"> P</option>
        <option [ngValue]="SPCChartType.NP"> NP </option>
        <option [ngValue]="SPCChartType.RANGE"> RANGE </option>
        <option [ngValue]="SPCChartType.EWMA"> EWMA </option>
      </select>
    </clr-select-container>

    <clr-checkbox-container clrInline>
      <label>Nelson signals</label>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson1" required [(ngModel)]="queryData.nelsonsignals[0]" />
        <label>1</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson2" required [(ngModel)]="queryData.nelsonsignals[1]" />
        <label>2</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson3" required [(ngModel)]="queryData.nelsonsignals[2]" />
        <label>3</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson4" required [(ngModel)]="queryData.nelsonsignals[3]" />
        <label>4</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson5" required [(ngModel)]="queryData.nelsonsignals[4]" />
        <label>5</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson6" required [(ngModel)]="queryData.nelsonsignals[5]" />
        <label>6</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson7" required [(ngModel)]="queryData.nelsonsignals[6]" />
        <label>7</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="nelson8" required [(ngModel)]="queryData.nelsonsignals[7]" />
        <label>8</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <h6>Colors</h6>

    <clr-input-container>
      <label>Value Color</label>
      <input clrInput placeholder="" name="colorvalue" type="text" [(ngModel)]="queryData.colorValue" />
      <input clrInput placeholder="" name="colorvalue2" type="color" [(ngModel)]="queryData.colorValue"
        style="margin-left: 1em;" />
    </clr-input-container>

    <clr-input-container>
      <label>Limit Color</label>
      <input clrInput placeholder="" name="colorlimit" type="text" [(ngModel)]="queryData.colorLimit" />
      <input clrInput placeholder="" name="colorlimit2" type="color" [(ngModel)]="queryData.colorLimit"
        style="margin-left: 1em;" />
    </clr-input-container>

    <clr-input-container>
      <label>Nelson Color</label>
      <input clrInput placeholder="" name="colornelson" type="text" [(ngModel)]="queryData.colorNelson" />
      <input clrInput placeholder="" name="colornelson2" type="color" [(ngModel)]="queryData.colorNelson"
        style="margin-left: 1em;" />
    </clr-input-container>

    <h6>Distrubution</h6>
    <clr-input-container>
      <label>Mean</label>
      <input clrInput type="number" step="0.1" min="-999999" max="999999" placeholder="" name="ymean"
        [(ngModel)]="queryData.stableDistribution.Mean" />
    </clr-input-container>

    <clr-input-container>
      <label>StdDev</label>
      <input clrInput type="number" step="0.1" min="-999999" max="999999" placeholder="" name="ystddev"
        [(ngModel)]="queryData.stableDistribution.StdDev" />
    </clr-input-container>
  </form>

  <h5>Filters</h5>
  <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="queryData.projection?.projectionid"
    [columnDescriptions]="queryData.columns" [group]="queryData.selectedGroup" [datafilters]="widgetQuery.DataFilters"
    [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType" [inputParameters]="inputParameters"
    [widgets]="widgets">
  </xproj-projection-filter>

  <br />

  <clr-accordion>
    <clr-accordion-panel *ngFor="let scriptedcolumn of queryData.scriptedcolumnspostaggregation; index as i">
      <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn"
          (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <!-- <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button> -->
</div>
