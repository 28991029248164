import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { EditplcprogramService } from '../editplcprogram.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xproj-editplcprogram-programcode',
  templateUrl: './editplcprogram-programcode.component.html',
  styleUrls: ['./editplcprogram-programcode.component.scss']
})
export class EditplcprogramProgramcodeComponent implements OnInit {

  public codeObject = "";

  editorOptionsLua = {
    theme: environment.editortheme,
    language: 'lua',
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap: { enabled: true }
  };

  public subscription : Subscription;

  constructor(private route: ActivatedRoute, public Project : EditplcprogramService) { }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      this.codeObject = params['codeobject'];
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
