<h2>Create configuration backup</h2>

<div class="alert alert-warning" role="alert" *ngIf="showbackupwarning">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
      </div>
      <span class="alert-text">Backups of configuration <b>does not include</b> runtime-data or projection-data. It also
        discards projection configurations prefixed with (case insensitive) <i>xdb, xauto, xbot</i></span>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="showbackupwarning=false">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>

<textarea #logoutput *ngIf="create_backup_log!=''" clrTextarea [(ngModel)]="create_backup_log"
  style="width: 50rem; height: 20rem; font-family: monospace;"></textarea>
<button class="btn" (click)="generateBackup()">Create</button>

<h3 *ngIf="backup">Backup created</h3>
<button *ngIf="backup && !downloadUrl" class="btn" (click)="download()">Generate download</button>
<button *ngIf="backup && !savedtoblob" class="btn" (click)="savetoblob()">Save to xdb</button>
<a *ngIf="downloadUrl" [download]="backupFilename" [href]="downloadUrl">Download {{backupFilename}} ({{backupSize}}
  bytes)</a>

<h2>Restore configuration backup</h2>
<textarea #loginput *ngIf="restore_backup_log!=''" clrTextarea [(ngModel)]="restore_backup_log"
  style="width: 50rem; height: 20rem; font-family: monospace;"></textarea>
<p *ngIf="selectedRestorePoint">
  Selected restore point: {{selectedRestorePoint}} ({{this.restoredata.length}} bytes)
</p>

<div *ngIf="showrestorewarning && selectedRestorePoint" class="alert alert-danger" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
      </div>
      <span class="alert-text">Restoring a backup (restore point) will revert any modifications.</span>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="showrestorewarning=false">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>

<button [clrLoading]="restoringStatus" class="btn" (click)="restoreBackup()"
  [disabled]="!restoredata || (restoringStatus!=ClrLoadingState.DEFAULT&&restoringStatus!=ClrLoadingState.SUCCESS&&restoringStatus!=ClrLoadingState.ERROR)">Restore
  selected</button>

<h3>From XDB</h3>
<div style="padding:1em;" *ngIf="blobbackups.length > 0">
  Load restore point from XDB;
  <ul>
    <li *ngFor="let f of blobbackups">
      <a style="cursor: pointer;" (click)="restoreFromBlob(f)">{{f}}</a>
    </li>
  </ul>
</div>
<div style="padding:1em;" *ngIf="blobbackups.length == 0">
  No backups stored in XDB.
</div>
<h3>From file</h3>


<div class="dropzone">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
    Drop backup file here.
  </ngx-file-drop>
</div>


<!-- <button class="btn" (click)="loadRestoreFromDisk()">Load restore point from file</button> -->
