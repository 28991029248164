<div>
  <xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets"
    [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>
  <br />

  <clr-accordion>
    <clr-accordion-panel>
      <clr-accordion-title>Query</clr-accordion-title>
      <clr-accordion-content>
        <form clrForm>
          <clr-select-container>
            <label>Projection</label>
            <select clrSelect id="useprojectioninput" name="useprojectioninput"
              [(ngModel)]="editConfig.ConfigQuery.UseProjectionInputParameter">
              <option [ngValue]="true"> Input </option>
              <option [ngValue]="false"> Projection </option>
            </select>

            <select clrSelect style="margin-left: 1em;" *ngIf="!editConfig.ConfigQuery.UseProjectionInputParameter"
              name="projection" [(ngModel)]="projection" (ngModelChange)="selectedProjectionChange($event)">
              <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
            </select>

            <select clrSelect style="margin-left: 1em;" *ngIf="editConfig.ConfigQuery.UseProjectionInputParameter"
              class="clr-select" name="UseProjectionInputParameter"
              [(ngModel)]="editConfig.ConfigQuery.ProjectionInputParameterId">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </clr-select-container>

          <clr-select-container>
            <label>Input / Group</label>
            <select clrSelect id="usegroupinput" name="usegroupinput"
              [(ngModel)]="editConfig.ConfigQuery.GroupSelectionType">
              <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
              <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
              <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
            </select>

            <select clrSelect *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT"
              class="clr-select" name="GroupSelectionType" [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterId">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </clr-select-container>

          <xproj-group-selection #groupSelect
            *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP" [projection]="projection"
            [selected]="editConfig.ConfigQuery.Query?.targetgroup"
            (groupSelected)="selectedProjectionGroupChange($event)">
          </xproj-group-selection>

          <clr-combobox-container
            *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
            <label>Group input selections</label>
            <clr-combobox [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterIds" name="multiSelect" clrMulti="true"
              required>
              <ng-container *clrOptionSelected="let selected">
                {{selected}}
              </ng-container>
              <clr-options>
                <clr-option
                  *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
                  [clrValue]="input.id">
                  {{input.id}}
                </clr-option>
              </clr-options>
            </clr-combobox>
          </clr-combobox-container>
        </form>

        <h5>Data</h5>
        <form clrForm *ngIf="columns">

          <!-- <clr-input-container>
            <label>Max items</label>
            <input clrInput placeholder="" type="number" name="rows" min="1" max="100"
              [(ngModel)]="editConfig.ConfigQuery.MaxItems" />
          </clr-input-container> -->

          <clr-select-container>
            <label>Default sorting column</label>
            <select clrSelect id="sortingcolumn" name="sortingcolumn"
              [(ngModel)]="editConfig.ConfigQuery.defaultSortColumnName">
              <option [ngValue]=""> </option>
              <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
                {{col.ColumnName}}</option>
            </select>
          </clr-select-container>

          <clr-select-container>
            <label>Default sorting order</label>

            <select clrSelect id="sortingorder" name="sortingorder"
              [(ngModel)]="editConfig.ConfigQuery.defaultSortDescending">
              <option [ngValue]="false"> Ascending </option>
              <option [ngValue]="true"> Descending </option>
            </select>
          </clr-select-container>

          <clr-select-container>
            <label>Grouping</label>

            <select clrSelect id="usegrouping" name="usegrouping" [(ngModel)]="editConfig.ConfigQuery.UseGrouping">
              <option [ngValue]="true"> Group </option>
              <option [ngValue]="false">No grouping</option>
            </select>

          </clr-select-container>

          <clr-datagrid [(clrDgSingleSelected)]="selectedColumnConfig">
            <clr-dg-column>Column</clr-dg-column>
            <clr-dg-column *ngIf="editConfig.ConfigQuery.UseGrouping">Transform / Aggregation</clr-dg-column>
            <clr-dg-column>Label</clr-dg-column>
            <clr-dg-column>Unit</clr-dg-column>
            <clr-dg-column>Default</clr-dg-column>
            <clr-dg-column>Move</clr-dg-column>

            <clr-dg-row *ngFor="let column of editConfig.ConfigQuery.ColumnConfigs; let i = index" [clrDgItem]="column">
              <clr-dg-cell>
                <div class="clr-select-wrapper">
                  <select class="clr-select" name="tselectqueryData.column{{i}}" id="tselectcolumn_{{i}}"
                    [(ngModel)]="column.ColumnName" (ngModelChange)="onColumnSelect($event, i)">
                    <option *ngFor="let col of columns" [ngValue]="col.columnname">
                      {{col.columnname}}</option>
                    <option *ngFor="let col of preQueryColumnConfigs" [ngValue]="col.columnname">
                      {{col.columnname}}</option>
                    <option *ngFor="let col of editConfig?.ConfigQuery?.Scriptedcolumnspostaggregation"
                      [ngValue]="'script:' + col.columnoutname">
                      script:{{col.columnoutname}}</option>
                  </select>
                </div>
              </clr-dg-cell>
              <clr-dg-cell *ngIf="editConfig.ConfigQuery.UseGrouping">
                <div class="clr-select-wrapper">
                  <input *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0" class="clr-checkbox" type="checkbox"
                    placeholder="" name="TransformInput{{i}}"
                    [(ngModel)]="editConfig.ConfigQuery.UseTransformInputParameter" />

                  <select class="clr-select" style="width:13em;"
                    *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0 && !editConfig.ConfigQuery.UseTransformInputParameter"
                    id="xaxisgrouptransform" name="xaxisgrouptransform"
                    [(ngModel)]="editConfig.ConfigQuery.GroupingTransform">
                    <option [ngValue]="Transformation.NONE"> None </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
                    <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
                    <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
                    <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
                    <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
                    <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
                    </option>
                    <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
                    </option>
                    <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
                    <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
                    <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
                    <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
                  </select>

                  <select
                    *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0 && editConfig.ConfigQuery.UseTransformInputParameter"
                    class="clr-select" style="width:13em;" name="inputtransformparam"
                    [(ngModel)]="editConfig.ConfigQuery.TransformInputParameterId">
                    <option
                      *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
                      [ngValue]="input.id">
                      {{input.id}}</option>
                  </select>

                  <input *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0" class="clr-checkbox" type="checkbox"
                    placeholder="" name="AggregationInput{{i}}"
                    [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter" />

                  <select
                    *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0 && !editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter"
                    class="clr-select" style="width:13em;" name="tselectqueryData.columnagg{{i}}"
                    [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Transform">
                    <option [ngValue]="Aggregation.NONE"> None </option>
                    <option [ngValue]="Aggregation.COUNT"> Count </option>
                    <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

                    <option [ngValue]="Aggregation.SUM"> Sum </option>

                    <option [ngValue]="Aggregation.MAX"> Max </option>
                    <option [ngValue]="Aggregation.MIN"> Min </option>

                    <option [ngValue]="Aggregation.FIRST"> First </option>
                    <option [ngValue]="Aggregation.LAST"> Last </option>

                    <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

                    <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
                    <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
                    <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

                    <option [ngValue]="Aggregation.MEDIAN"> Median </option>

                    <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
                    <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
                    <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
                    </option>

                    <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                      arithmetic mean </option>
                    <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                      mean </option>
                    <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                      mean </option>
                    <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
                  </select>

                  <select
                    *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0 && editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter"
                    class="clr-select" style="width:13em;" name="inputparam"
                    [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].AggregationInputParameterId">
                    <option
                      *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                      [ngValue]="input.id">
                      {{input.id}}</option>
                  </select>
                </div>
              </clr-dg-cell>

              <clr-dg-cell>
                <input class="clr-input" placeholder="" name="tlabel{{i}}"
                  [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Label" />
              </clr-dg-cell>

              <clr-dg-cell>
                <input class="clr-input" placeholder="" name="unit{{i}}"
                  [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Unit" />
              </clr-dg-cell>

              <clr-dg-cell>
                <input class="clr-input" placeholder="" name="defaultvalue{{i}}"
                  [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].DefaultValue" />
              </clr-dg-cell>

              <clr-dg-cell>
                <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
                <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
                  style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
              </clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>


          <clr-dropdown [clrCloseMenuOnItemClick]="true">
            <button clrDropdownTrigger aria-label="More actions">
              <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
              <!-- <clr-icon shape="caret down"></clr-icon> -->
            </button>
            <clr-dropdown-menu *clrIfOpen>
              <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addAllColumns()">Add all columns</div>
              <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="removeAllColumns()">Remove all columns
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>

          <button type="button" class="btn btn-link" (click)="addColumn()">
            <clr-icon shape="plus-circle"></clr-icon>
            Add column
          </button>
          <button *ngIf="selectedColumnConfig" type="button" class="btn btn-link" (click)="removeSelectedColumn()">
            <clr-icon shape="minus-circle"></clr-icon>
            Remove selected column
          </button>

          <div *ngIf="selectedColumnConfig" style="max-width: 800px;">
            <div class="card">
              <div class="card-header">
                Editing column
              </div>

              <div class="card-block">
                <div class="card-title">
                  {{selectedColumnConfig.ColumnName}} ({{selectedColumnConfig.Label}})
                </div>
                <form clrForm>
                  <clr-input-container *ngIf="selectedColumnConfig.Datatype == XDataType.Timestamp">
                    <label class="clr-col-4">Format (empty = default)
                      <clr-signpost>
                        <clr-signpost-content *clrIfOpen  [clrPosition]="'top-right'">
                          <h3>Date formats</h3>
                          <p>fullDate,
                            fullDateWithWeekday,
                            normalDate,
                            normalDateWithWeekday,
                            shortDate,
                            year,
                            month,
                            monthShort,
                            monthAndYear,
                            monthAndDate,
                            weekday,
                            weekdayShort,
                            dayOfMonth,
                            hours12h,
                            hours24h,
                            minutes,
                            seconds,
                            fullTime,
                            fullTime12h,
                            fullTime24h,
                            fullDateTime,
                            fullDateTime12h,
                            fullDateTime24h,
                            keyboardDate,
                            keyboardDateTime,
                            keyboardDateTime12h,
                            keyboardDateTime24h</p>
                        </clr-signpost-content>
                      </clr-signpost>
                    </label>
                    <input clrInput class="clr-col-8" type="text" size="20" [(ngModel)]="selectedColumnConfig.DateFormat"
                      name="fromconfigformat" />
                  </clr-input-container>
                </form>

              </div>
            </div>
          </div>
        </form>

        <h5>Filters</h5>
        <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="projection?.projectionid"
          [columnDescriptions]="columns" [group]="editConfig.ConfigQuery.Query.targetgroup"
          [datafilters]="editConfig.ConfigQuery.DataFilters"
          [filterLogicalGroupType]="editConfig.ConfigQuery.FilterLogicalGroupType" [inputParameters]="inputParameters"
          [widgets]="widgets">
        </xproj-projection-filter>

        <br>

        <clr-accordion>
          <clr-accordion-panel
            *ngFor="let scriptedcolumn of editConfig.ConfigQuery.Scriptedcolumnspostaggregation; index as i">
            <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
            <clr-accordion-content>
              <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn"
                (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
        <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
          <clr-icon shape="plus-circle"></clr-icon>
          Add scripted column
        </button>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>

  <md-editor name="Content" [preRender]="preRenderFunc" [postRender]="postRenderFunc" [(ngModel)]="editConfig.Content"
    [height]="'400px'" [mode]="mode" [options]="options" [upload]="doUpload" (onEditorLoaded)="onEditorLoaded($event)"
    (onPreviewDomChanged)="onPreviewDomChanged($event)" maxlength="1000">
  </md-editor>
  <br>
  <form clrForm>
    <clr-input-container>
      <label>Margin</label>
      <input clrInput type="text" [(ngModel)]="editConfig.Margin" name="margin" size="35" />
    </clr-input-container>
    <clr-input-container>
      <label>Background color</label>
      <input clrInput placeholder="" name="backgroundcolor" type="text" [(ngModel)]="editConfig.BackgroundColor" />
      <input clrInput type="color" placeholder="" name="backgroundcolor2" [(ngModel)]="editConfig.BackgroundColor" />
    </clr-input-container>
    <clr-input-container>
      <label>Text color</label>
      <input clrInput type="text" placeholder="" name="textcolor" [(ngModel)]="editConfig.TextColor" />
      <input clrInput type="color" placeholder="" name="textcolor2" [(ngModel)]="editConfig.TextColor" />
    </clr-input-container>
    <button type="button" class="btn btn-secondary"
      (click)="editConfig.TextColor='#000000';editConfig.BackgroundColor='#FFFFFF'">
      Reset colors
    </button>
  </form>
</div>
