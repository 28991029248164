import { Component, OnInit } from '@angular/core';
import { GetDataSourceInstancesRequest, GrpcDataSourceDefinition, GrpcDataSourceInstance } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';

@Component({
  selector: 'app-edit-datasource-instances',
  templateUrl: './edit-datasource-instances.component.html',
  styleUrls: ['./edit-datasource-instances.component.scss']
})
export class EditDataSourceInstancesComponent implements OnInit {

  dataSourceInstances: GrpcDataSourceInstance[] = [];

  dataSourceDefinitions : GrpcDataSourceDefinition[] = [];

  selected: GrpcDataSourceInstance = null;

  constructor(private xConfClient: XProjectorXConfClient) {

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.dataSourceInstances = await this.xConfClient.getDataSourceInstances(new GetDataSourceInstancesRequest({maxHops: 10}));
    this.dataSourceDefinitions = await this.xConfClient.getDataSourceDefinitions();
  }

  async saveDataSourceInstance(DataSourceInstance: GrpcDataSourceInstance): Promise<boolean> {
    let result = await this.xConfClient.upsertDataSourceInstance(DataSourceInstance);
    return result.result;
  }

  addDataSourceInstance() {
    let DataSourceInstance: GrpcDataSourceInstance = new GrpcDataSourceInstance();
    this.dataSourceInstances.push(DataSourceInstance);
    this.selected = null;
    this.selected.dataSourceDefinitionId
  }

  async deleteSelected() {
    if (this.selected) {
      let result = await this.xConfClient.deleteDataSourceInstance(this.selected.id);
      if (result.result) {
        this.dataSourceInstances = this.dataSourceInstances.filter(n => n.id != this.selected.id);
        this.selected = null;
      }
    }
  }

  async saveSelected() {
    if (this.selected) {
      //TODO: id changed??
      await this.saveDataSourceInstance(this.selected);

      this.selected = null;
    }
  }
}
