<clr-select-container
  *ngIf="!stringOptionsProperty.hidden && stringOptionsProperty.optionsRootNodeId.length == 0 && !stringOptionsProperty.freetext">
  <label class="clr-col-12">{{stringOptionsProperty.header}}</label>
  <select clrSelect class="clr-col-12" name="value_{{id}}" [(ngModel)]="value"
    [disabled]="stringOptionsProperty.readOnly" (ngModelChange)="selectedValueChange($event)">
    <option *ngFor="let option of stringOptionsProperty.options" [ngValue]="option">
      {{option}}
    </option>
  </select>
  <clr-control-helper *ngIf="showHelp">{{stringOptionsProperty.tooltip}}</clr-control-helper>
</clr-select-container>

<clr-input-container
  *ngIf="!stringOptionsProperty.hidden && stringOptionsProperty.optionsRootNodeId.length == 0 && stringOptionsProperty.freetext">
  <label class="clr-col-12">{{stringOptionsProperty.header}}</label>
  <input clrInput class="clr-col-12" name="value_{{id}}" [(ngModel)]="value" [attr.list]="'list_' + id"
    [disabled]="stringOptionsProperty.readOnly" (ngModelChange)="selectedValueChange($event)" />
  <clr-control-helper *ngIf="showHelp">{{stringOptionsProperty.tooltip}}</clr-control-helper>
</clr-input-container>

<datalist id="list_{{id}}"
  *ngIf="stringOptionsProperty.optionsRootNodeId.length == 0 && stringOptionsProperty.freetext">
  <option *ngFor="let option of stringOptionsProperty.options" [value]="option">
  </option>
</datalist>

<clr-select-container
  *ngIf="!stringOptionsProperty.hidden && stringOptionsProperty.optionsRootNodeId.length > 0 && !stringOptionsProperty.freetext">
  <label class="clr-col-12">{{stringOptionsProperty.header}}</label>
  <select clrSelect class="clr-col-12" name="value_{{id}}" [(ngModel)]="value"
    [disabled]="stringOptionsProperty.readOnly" (ngModelChange)="selectedValueChange($event)">
    <option *ngFor="let optionNode of optionNodes" [ngValue]="optionNode.name">
      {{optionNode.name}}
    </option>
  </select>
  <clr-control-helper *ngIf="showHelp">{{stringOptionsProperty.tooltip}}</clr-control-helper>
</clr-select-container>

<clr-input-container
  *ngIf="!stringOptionsProperty.hidden && stringOptionsProperty.optionsRootNodeId.length > 0 && stringOptionsProperty.freetext">
  <label class="clr-col-12">{{stringOptionsProperty.header}}</label>
  <input clrInput class="clr-col-12" name="value_{{id}}" [(ngModel)]="value" [attr.list]="'nodelist_' + id"
    [disabled]="stringOptionsProperty.readOnly" (ngModelChange)="selectedValueChange($event)" />
  <clr-control-helper *ngIf="showHelp">{{stringOptionsProperty.tooltip}}</clr-control-helper>
</clr-input-container>

<datalist id="nodelist_{{id}}"
  *ngIf="stringOptionsProperty.optionsRootNodeId.length > 0 && stringOptionsProperty.freetext">
  <option *ngFor="let optionNode of optionNodes" [value]="optionNode.name">
  </option>
</datalist>
