<main class="content-area">
  <router-outlet></router-outlet>
</main>

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">
  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Customers

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['customers']" routerLinkActive="active">
        View customers
      </a>
      <a clrVerticalNavLink [routerLink]="['addcustomer']" routerLinkActive="active">
        Add new customer
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

</clr-vertical-nav>
