/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.credentials.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as xprojectorGrpcModels001 from './xprojector.grpc.models.pb';
import { GRPC_CREDENTIALS_CLIENT_SETTINGS } from './xprojector.credentials.pbconf';
/**
 * Service client implementation for credentials.Credentials
 */
@Injectable({ providedIn: 'root' })
export class CredentialsClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /credentials.Credentials/SetCredentialsFromTicket
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>>
     */
    setCredentialsFromTicket: (
      requestData: thisProto.SetCredentialsFromTicketRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels001.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/credentials.Credentials/SetCredentialsFromTicket',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetCredentialsFromTicketRequest,
        responseClass: xprojectorGrpcModels001.BasicResponse
      });
    },
    /**
     * Unary call: /credentials.Credentials/GetTicketInfo
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetTicketInfoResponse>>
     */
    getTicketInfo: (
      requestData: thisProto.GetTicketInfoRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetTicketInfoResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/credentials.Credentials/GetTicketInfo',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetTicketInfoRequest,
        responseClass: thisProto.GetTicketInfoResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_CREDENTIALS_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'credentials.Credentials',
      settings
    );
  }

  /**
   * Unary call @/credentials.Credentials/SetCredentialsFromTicket
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels001.BasicResponse>
   */
  setCredentialsFromTicket(
    requestData: thisProto.SetCredentialsFromTicketRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels001.BasicResponse> {
    return this.$raw
      .setCredentialsFromTicket(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/credentials.Credentials/GetTicketInfo
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetTicketInfoResponse>
   */
  getTicketInfo(
    requestData: thisProto.GetTicketInfoRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetTicketInfoResponse> {
    return this.$raw
      .getTicketInfo(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
