import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_PLCVariableNumericalMapXAutoVariable, XAUTO_XAutoProcessGraph, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';
import { DomSanitizer} from '@angular/platform-browser';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { XprojModalService } from '../../modals/xproj-modal-service.service';
import { XAutoService } from '../xauto.service';

export class SimpleSignal
{
  xautogroup : string = "";
  xgroup: string = "";
  xname : string = "";
  xautovarid: string = "";

  constructor()
  {
  }
}

export class ProcessGraphCommand {
  commandId : string;
  messageId : string;
  data : any;
}

export class ProcessGraphResult {
  commandId : string;
  messageId : string;
  data : any;
}

@Component({
  selector: 'xproj-editprocessgraphs',
  templateUrl: './editprocessgraphs.component.html',
  styleUrls: ['./editprocessgraphs.component.scss']
})
export class EditprocessgraphsComponent implements OnInit, OnDestroy {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;
  public loadingGraphs = false;
  savingGraph : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingGraph : ClrLoadingState = ClrLoadingState.DEFAULT;
  helloInterval : any;

  public graphs = [];
  public selectedGraph : XAUTO_XAutoProcessGraph = null;

  public previewSVG;

  constructor(private xprojClient: XProjectorClient,
    private modalService: XprojModalService,
    private xautoService : XAutoService,
    private sanitizer: DomSanitizer)
  {

  }

  ngOnInit(): void {
    this.loadGraphs();
  }

  ngOnDestroy(): void {
    if (this.helloInterval) {
      clearInterval(this.helloInterval);
    }

    window.removeEventListener(
      "message",
      this.WindowMessageListener,
      false
    );
  }

  async saveSelectedGraph() {
    this.savingGraph = ClrLoadingState.LOADING;

    try{
      await this.xprojClient.XAUTO_SaveProcessGraph(this.selectedGraph);
      this.savingGraph = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingGraph = ClrLoadingState.ERROR;
    }
  }

  async loadGraphs()
  {
    try {
      this.loadingGraphs = true;
      this.graphs = [];
      let lgraphs = await this.xprojClient.XAUTO_GetProcessGraphs(0,1000);
      for(let i = 0; i < lgraphs.length;i++)
      {
        this.graphs.push(lgraphs[i]);
      }
    }
    finally {
      this.loadingGraphs = false;
    }
  }

  addGraph()
  {
    this.selectedGraph = new XAUTO_XAutoProcessGraph();
    this.selectedGraph.xautoprocessgraphid = uuid.v4();
    this.graphs.push(this.selectedGraph);
  }

  async removeSelectedGraph()
  {
    if (this.selectedGraph) {
      let doRemove = await this.modalService.ShowConfirmModalAsync({ header: 'Remove graph', description: 'Remove selected process graph, are you sure?' });

      if (doRemove) {
        try{
          this.savingRemovingGraph = ClrLoadingState.LOADING;
          await this.xprojClient.XAUTO_RemoveProcessGraph(this.selectedGraph.xautoprocessgraphid);
          this.savingRemovingGraph = ClrLoadingState.SUCCESS;
          this.selectedGraph = null;
        }
        catch
        {
          this.savingRemovingGraph = ClrLoadingState.ERROR;
        }

        await this.loadGraphs();
      }
    }
  }

  getGraph(id : string) : XAUTO_XAutoProcessGraph {
    return this.graphs.find(x => x.xautoprocessgraphid == id);
  }

  async setGraphData(graphData : XAUTO_XAutoProcessGraph) : Promise<boolean> {
    let result : boolean = false;
    let graph = this.getGraph(graphData.xautoprocessgraphid);
    if (graph) {
      graph.graphxml = graphData.graphxml;
      graph.svg = graphData.svg;
      await this.xprojClient.XAUTO_SaveProcessGraph(graph);
      result = true;
    }

    return result;
  }

  windowEditGraph : Window = null;

  async reloadPreview()
  {
    this.previewSVG = this.sanitizer.bypassSecurityTrustHtml(this.selectedGraph.svg);
  }

  async editGraph()
  {
    this.logger.debug('editGraph', this.selectedGraph);

    let size = "width=" + (window.outerWidth-10).toString()
    + ", height=" + (window.outerHeight-50).toString()
    + ", left=" + (window.screenLeft?window.screenLeft+10:10).toString()
    + ", top=" + (window.screenTop?window.screenTop+50:50).toString()
    this.windowEditGraph = window.open("/processgrapheditor/examples/grapheditor/www/index.html?filename=" + this.selectedGraph.name,
    "xautoeditor",
    "resizable=yes, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, " + size);

    let wnd = this.windowEditGraph;
    let that = this;

    this.helloInterval = setInterval(() => {
      wnd.postMessage('hello:' + this.selectedGraph.xautoprocessgraphid);
    }, 2000);

    let intervalId = setInterval( (args:any)=>{
      if(wnd.closed)
      {
        that.windowEditGraph = null;
        this.reloadPreview();
        clearInterval(intervalId);
        if (that.helloInterval) {
          clearInterval(this.helloInterval);
        }
      }
    }, 100 )

    setTimeout(() => {
      wnd.postMessage('hello:' + this.selectedGraph.xautoprocessgraphid);
    }, 500);

    window.addEventListener(
      "message",
      this.WindowMessageListener.bind(this),
      false
    );
  }

  variableUpdated(xvariable : XAUTO_PLCVariableNumericalMapXAutoVariable) {
    if (this.selectedGraph && this.windowEditGraph) {
      this.windowEditGraph.postMessage('updatexvariables:' + this.selectedGraph.xautoprocessgraphid);
    }
  }

  async WindowMessageListener(event) {
    if (event.origin !== window.location.origin) return;

    var graphCommand = event.data as ProcessGraphCommand;

    let result = new ProcessGraphResult();
    result.commandId = graphCommand.commandId;
    result.messageId = graphCommand.messageId;

    if (graphCommand) {
      switch (graphCommand.commandId) {
        case 'getxvariables':
          this.logger.debug('getxvariables', graphCommand.data.id, this.graphs);
          let graph = this.graphs.find(x => x.xautoprocessgraphid == graphCommand.data.id);
          if (graph) {
            result.data = graph.numericalmapxautovariables;
          }
          else {
            result.data = [];
          }

          this.logger.debug('getxvariables', result.data);
          break;

        case 'getxautogroups':
          result.data = await this.xautoService.getXAutoGroups();
          this.logger.debug('getxautogroups', result.data);
          break;

        case 'getxgroups':
          result.data = await this.xautoService.getXGroups(graphCommand.data.xautogroup);
          this.logger.debug('getxgroups', result.data);
          break;

        case 'getxvariableids':
          result.data = await this.xautoService.getXAutoVariableIds(graphCommand.data.xautogroup, graphCommand.data.xgroup);
          this.logger.debug('getxvariableids', result.data);
          break;

        case 'getprocessgraph':
          result.data = await this.getGraph(graphCommand.data.id);
          this.logger.debug('getprocessgraph', result.data);
          break;

        case 'setprocessgraph':
          result.data = await this.setGraphData(graphCommand.data);
          this.logger.debug('setprocessgraph', result.data);
          break;

      }
    }

    event.source.postMessage(result);
  }

}
