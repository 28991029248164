import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Guid } from '../../../utils/guid-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, LuaQueryColumn, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';

@jsonObject
export class TextColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public Writeable : boolean = false;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
  public DefaultValue : string = '---';

  @jsonMember
  public DateFormat : string = '';
}

@jsonObject
export class TextWidgetQuery implements BaseQueryConfigInterface {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 10;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(TextColumnConfig)
  public ColumnConfigs : TextColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonArrayMember(LuaQueryColumn)
  public Scriptedcolumnspostaggregation : LuaQueryColumn[] = [];

}


@jsonObject
export class TextWidgetConfig extends WidgetConfig{

  @jsonMember
  public ConfigQuery : TextWidgetQuery = new TextWidgetQuery();

  @jsonMember
  public Content : string = '';

  @jsonMember
  public Html : string = '';

  @jsonMember
  public BackgroundColor : string = '#FFFFFF';

  @jsonMember
  public TextColor : string = '#000000';

  @jsonMember
  public Margin : string = '';

  public Clone() : TextWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, TextWidgetConfig), TextWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    if (this.ConfigQuery.Query && this.ConfigQuery.Query.targetprojectionid?.length > 0) {
      result.push({projectionId : this.ConfigQuery.Query.targetprojectionid, group : this.ConfigQuery.Query.targetgroup})
    }
    return result;
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;

  update(config: TextWidgetConfig) {
    super.update(config)

    this.ConfigQuery = config.ConfigQuery;
    this.Content = config.Content;
    this.Html = config.Html;
    this.BackgroundColor = config.BackgroundColor;
    this.TextColor = config.TextColor;
    this.Margin = config.Margin;
  }
}
