import { Component, OnInit } from '@angular/core';
import { BaseQuery, DFTQuery, DownSampleQuery, MultiseriesQuery, WidgetExportParameters, XprojAlertService, XprojDashboardComponent } from 'xproj-lib';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
