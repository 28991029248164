import { Component, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { ModifyCustomerRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.customeradmin.pb';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorCustomerAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-customeradmin-client';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit {

  customer : Customer;

  constructor(
    public state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private customerAdminClient: XProjectorCustomerAdminClient,
    private alertService : XprojAlertService
  ) { }

  async ngOnInit(): Promise<void> {
    this.customer = await this.customerUserClient.getActiveCustomer();
  }

  async saveCustomer() {
    let request = new ModifyCustomerRequest();
    request.defaultDashboardId = this.customer.defaultDashboardId;
    let result = await this.customerAdminClient.modifyCustomer(request);
    if (result.result) {
      this.alertService.success('Customer modified.');
    } else {
      this.alertService.error(result.message);
    }
  }
}
