import { EventEmitter, Injectable, Output } from '@angular/core';
import { TreeviewItem } from '@xprojectorcore/models/treeview-item';

@Injectable({
  providedIn: 'root'
})
export class XConfDashboardsService {

  public clipboardTreeviewItem : TreeviewItem = null;

}
