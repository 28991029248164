<!-- <clr-input-container *ngIf="!stringArrayProperty.hidden && !(stringArrayProperty.optionsRootNodeId?.length > 0)">
  <label class="clr-col-12">{{stringArrayProperty.header}}</label>
  <input clrInput style="width: 90%;" class="clr-col-12" placeholder="{{stringArrayProperty.defaultValue}}"
    name="value_{{id}}" [(ngModel)]="value" [disabled]="stringArrayProperty.readOnly" />
  <clr-control-helper>{{stringArrayProperty.tooltip}}</clr-control-helper>
</clr-input-container> -->

<clr-combobox-container *ngIf="!stringArrayProperty.hidden && stringArrayProperty.optionsRootNodeId?.length > 0">
  <label class="clr-col-12">{{stringArrayProperty.header}}</label>
  <clr-combobox [(ngModel)]="value" name="stringarrayselect" clrMulti="true"
    [disabled]="stringArrayProperty.readOnly">
    <ng-container *clrOptionSelected="let selected">
      {{selected}}
    </ng-container>
    <clr-options>
      <clr-option *clrOptionItems="let optionNode of optionNodes; field:'name'" [clrValue]="optionNode.name">
        {{optionNode.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
  <clr-control-helper>{{stringArrayProperty.tooltip}}</clr-control-helper>
</clr-combobox-container>
