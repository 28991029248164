import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';

export const GridOptions : GridsterConfig = {
  gridType: GridType.ScrollVertical,
  compactType: CompactType.CompactLeftAndUp,
  margin: 8,
  outerMargin: true,
  outerMarginTop: null,
  outerMarginRight: null,
  outerMarginBottom: null,
  outerMarginLeft: null,
  useTransformPositioning: true,
  mobileBreakpoint: 0,
  minCols: 12,
  maxCols: 12,
  minRows: 1,
  maxRows: 100,
  maxItemCols: 100,
  minItemCols: 1,
  maxItemRows: 100,
  minItemRows: 1,
  maxItemArea: 2500,
  minItemArea: 1,
  defaultItemCols: 1,
  defaultItemRows: 1,
  fixedColWidth: 105,
  fixedRowHeight: 105,
  keepFixedHeightInMobile: true,
  keepFixedWidthInMobile: false,
  scrollSensitivity: 10,
  scrollSpeed: 20,
  enableEmptyCellClick: false,
  enableEmptyCellContextMenu: false,
  enableEmptyCellDrop: true,
  enableEmptyCellDrag: false,
  enableOccupiedCellDrop: false,
  emptyCellDragMaxCols: 50,
  emptyCellDragMaxRows: 50,
  ignoreMarginInRow: false,
  draggable: {
    enabled: true,
    delayStart: 0
  },
  resizable: {
    delayStart: 0,
    enabled: true,
    handles: {
      s: true,
      e: true,
      n: false,
      w: true,
      se: true,
      ne: false,
      sw: true,
      nw: false
    }
  },
  swap: true,
  pushItems: true,
  disablePushOnDrag: false,
  disablePushOnResize: false,
  pushDirections: {north: false, east: true, south: true, west: true},
  pushResizeItems: false,
  displayGrid: DisplayGrid.OnDragAndResize,
  disableWindowResize: true,
  disableWarnings: false,
  scrollToNewItems: false,
}
