import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { lastValueFrom } from 'rxjs';
import { AddXEdgeXAutoMetersRequest, AddXEdgeXAutoMetersResponse, ScanXEdgeRequest, XEdgeXAutoVar } from './proto/xprojector.module.xedge.pb';
import { XEdgeClient } from './proto/xprojector.module.xedge.pbsc';


@Injectable({
  providedIn: 'root'
})
export class XProjectorXEdgeClient implements OnInit, OnDestroy {

  constructor(
    private xedgeClient: XEdgeClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async scanXEdge(customerId : string, remoteNodeId: string): Promise<XEdgeXAutoVar[]> {
    let request: ScanXEdgeRequest = new ScanXEdgeRequest({
      customerId: customerId,
      remoteNodeId: remoteNodeId });

    let result = await lastValueFrom(this.xedgeClient.scanXEdge(request, this.state.metadata));

    return result.xedgeXAutoInfos;
  }

  async addXEdgeXAutoMeters(customerId : string, remoteNodeId: string, xedgeXAutoVar : XEdgeXAutoVar[]): Promise<AddXEdgeXAutoMetersResponse> {
    let request: AddXEdgeXAutoMetersRequest = new AddXEdgeXAutoMetersRequest({
      customerId: customerId,
      remoteNodeId: remoteNodeId,
      xedgeXAutoInfos : xedgeXAutoVar
    });

    return await lastValueFrom(this.xedgeClient.addXEdgeXAutoMeters(request, this.state.metadata));
  }
}
