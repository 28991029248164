<div>
  <xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets" [gridSettings]="gridSettings"></xproj-widget-config>
  <br />
</div>
<br>

<clr-checkbox-container>
  <clr-checkbox-wrapper>
    <input type="checkbox" clrCheckbox name="UseApplyButton" [(ngModel)]="workingCopy.UseApplyButton" />
    <label>Show apply button</label>
  </clr-checkbox-wrapper>
</clr-checkbox-container>

<clr-input-container>
  <label>Apply button text</label>
  <input clrInput type="text" size="20" [(ngModel)]="workingCopy.ApplyButtonText"
    name="applybuttontext" />
</clr-input-container>

<br>

<h5>Controllers</h5>
<form clrForm *ngIf="workingCopy.Controllers">
  <clr-datagrid [(clrDgSingleSelected)]="selectedController">
    <clr-dg-column>Type</clr-dg-column>
    <clr-dg-column>Name</clr-dg-column>
    <clr-dg-column>Move</clr-dg-column>

    <clr-dg-row *ngFor="let controller of workingCopy.Controllers; let i = index" [clrDgItem]="controller">
      <clr-dg-cell>
        <input class="clr-input" placeholder="" name="TypeName{{i}}" [value]="controller.TypeName" disabled="true" />
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" placeholder="" name="Name{{i}}" [(ngModel)]="controller.Name" />
      </clr-dg-cell>

      <clr-dg-cell>
        <clr-icon (click)="moveControllerUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
        <clr-icon (click)="moveControllerDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
          style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>

  <clr-dropdown [clrCloseMenuOnItemClick]="true">
    <button clrDropdownTrigger aria-label="Add controller" type="button" class="btn btn-link">
      <clr-icon shape="plus-circle"></clr-icon>
      Add controller
    </button>
    <clr-dropdown-menu *clrIfOpen>
      <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addController('Aggregation')">Add Aggregation
        controller</div>
      <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="addController('Buttons')">Add Buttons
        controller</div>
      <div aria-label="Dropdown header Action 3" clrDropdownItem (click)="addController('Combobox')">Add Combobox
        controller</div>
      <div aria-label="Dropdown header Action 4" clrDropdownItem (click)="addController('Projection')">Add Projection
        controller</div>
      <div aria-label="Dropdown header Action 5" clrDropdownItem (click)="addController('RadioButtons')">Add Radio Buttons
        controller</div>
      <div aria-label="Dropdown header Action 6" clrDropdownItem (click)="addController('Range')">Add Range controller
      </div>
      <div aria-label="Dropdown header Action 7" clrDropdownItem (click)="addController('Time')">Add Time controller
      </div>
      <div aria-label="Dropdown header Action 8" clrDropdownItem (click)="addController('Toggle')">Add Toggle
        controller</div>
      <div aria-label="Dropdown header Action 9" clrDropdownItem (click)="addController('Transform')">Add Transform
        controller</div>
    </clr-dropdown-menu>
  </clr-dropdown>

  <button *ngIf="selectedController" type="button" class="btn btn-link" (click)="removeSelectedController()">
    <clr-icon shape="minus-circle"></clr-icon>
    Remove selected controller
  </button>

  <div *ngIf="selectedController" style="width: 100%;">
    <div class="card">
      <div class="card-header">
        Editing controller
      </div>

      <div class="card-block">
        <div class="card-title">
          {{selectedController.Name}}
        </div>
        <xproj-output-range-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputRangeControllerConfig'" [config]="selectedController">
        </xproj-output-range-controller-config>
        <xproj-output-button-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputButtonControllerConfig'" [config]="selectedController">
        </xproj-output-button-controller-config>
        <xproj-output-combobox-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputComboboxControllerConfig'" [config]="selectedController"
            [widgets]="widgets" [inputParameters]="widgetConfig.InputParameters">
        </xproj-output-combobox-controller-config>
        <xproj-output-aggregation-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputAggregationControllerConfig'"
          [config]="selectedController">
        </xproj-output-aggregation-controller-config>
        <xproj-output-transform-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputTransformControllerConfig'" [config]="selectedController">
        </xproj-output-transform-controller-config>
        <xproj-output-projection-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputProjectionControllerConfig'"
          [config]="selectedController">
        </xproj-output-projection-controller-config>
        <xproj-output-time-controller-config *ngIf="(selectedController | typeof) == 'XprojOutputTimeControllerConfig'"
          [config]="selectedController">
        </xproj-output-time-controller-config>
        <xproj-output-toggle-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputToggleControllerConfig'" [config]="selectedController">
        </xproj-output-toggle-controller-config>
        <xproj-output-radiobutton-controller-config
          *ngIf="(selectedController | typeof) == 'XprojOutputRadioButtonControllerConfig'"
          [config]="selectedController">
        </xproj-output-radiobutton-controller-config>
      </div>
    </div>
  </div>
</form>
