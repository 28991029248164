<main class="content-area">
    <h1>Opc UA Connectors</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedConnector" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

            <clr-dg-column [clrDgField]="'Driver.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxautogroup'"> Default XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxgroup'"> Default XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Collectd Connectors
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addConnector()">
        <clr-icon shape="plus-circle "></clr-icon>
        New Opc UA connector
    </button>
    <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove connector
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedConnector">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Connector configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Driver ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.xautodriverid" name="xautodriverid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.name" name="drivername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.description" name="driverdescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxautogroup" name="driverdefaultxautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxgroup" name="driverdefaultxgroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>URI</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Opcua.uri" name="driveruri" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Username</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Opcua.username" name="driverusername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Password</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Opcua.password" name="driverpassword" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="driverenabled" [(ngModel)]="selectedConnector.Driver.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>


                    <button class="btn" [clrLoading]="savingDriver" (click)="saveDriver()">Save</button>

                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Connector Variables</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedVariable" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

                        <clr-dg-column [clrDgField]="'enabled'"> Enabled </clr-dg-column>
                        <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
                        <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xautogroup'"> XAutoGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xgroup'"> XGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'defaultscriptname'"> Default scriptname </clr-dg-column>

                        <clr-dg-row *clrDgItems="let variable of selectedConnector.Variables" [clrDgItem]="variable">
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.enabled}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.name}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.description}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xautogroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xgroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.defaultscriptname}} </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[1]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Variables
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <button class="btn btn-link " (click)="addVariable()">
                    <clr-icon shape="plus-circle "></clr-icon>
                    New Opc UA variable
                </button>
                <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingVariable" (click)="removeVariable(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove variable
                </button>

                <form clrForm *ngIf="selectedVariable">
                    <clr-input-container>
                        <label>Variable ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautovariableid" name="variablexautovariableid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.name" name="variablename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.description" name="variabledescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautogroup" name="variablexautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xgroup" name="variablexgroup" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variablewritable" [(ngModel)]="selectedVariable.Variable.writable" enabled="false" />
                            <label>Writable</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-input-container>
                        <label>Sample interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.sampleintervalseconds" name="variablesampleinterval" size="35" />
                    </clr-input-container>

                    <clr-select-container>
                        <label>Node ID Type</label>
                        <select clrSelect name="drivernodeidtype" [(ngModel)]="selectedVariable.Opcua.nodeidtype">
                            <option [ngValue]="XAUTO_OPCUANODEIDTYPE.INT32">Numeric</option>
                            <option [ngValue]="XAUTO_OPCUANODEIDTYPE.STRING">String</option>
                            <option [ngValue]="XAUTO_OPCUANODEIDTYPE.GUID">GUID</option>
                            <option [ngValue]="XAUTO_OPCUANODEIDTYPE.BASE64">Binary</option>
                        </select>
                    </clr-select-container>


                    <clr-input-container *ngIf="selectedVariable.Opcua.nodeidtype == XAUTO_OPCUANODEIDTYPE.INT32">
                        <label>Node ID (Numeric)</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Opcua.nodeidint32" name="variablenodeidint32" size="35" />
                    </clr-input-container>

                    <clr-input-container *ngIf="selectedVariable.Opcua.nodeidtype == XAUTO_OPCUANODEIDTYPE.STRING">
                        <label>Node ID (String)</label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Opcua.nodeidstring" name="variablenodeidstring" size="35" />
                    </clr-input-container>

                    <clr-input-container *ngIf="selectedVariable.Opcua.nodeidtype == XAUTO_OPCUANODEIDTYPE.GUID">
                        <label>Node ID (GUID)</label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Opcua.nodeidguid" name="variablenodeidguid" size="35" />
                    </clr-input-container>

                    <clr-input-container *ngIf="selectedVariable.Opcua.nodeidtype == XAUTO_OPCUANODEIDTYPE.BASE64">
                        <label>Node ID (Binary) - Base64 Input</label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Opcua.nodeidbase64" name="variablenodeidbase64" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableenabled" [(ngModel)]="selectedVariable.Variable.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form> 

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main>