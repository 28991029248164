<form clrForm>
  <clr-input-container>
    <label>Label</label>
    <input clrInput placeholder="" name="label" type="string" [(ngModel)]="rangeConfig.Label" />
  </clr-input-container>
  <clr-input-container>
    <label>Min</label>
    <input clrInput placeholder="0" name="min" type="number" [(ngModel)]="rangeConfig.Min" />
  </clr-input-container>
  <clr-input-container>
    <label>Max</label>
    <input clrInput placeholder="0" name="max" type="number" [(ngModel)]="rangeConfig.Max" />
  </clr-input-container>
  <clr-input-container>
    <label>Step</label>
    <input clrInput placeholder="10" name="step" type="number" [(ngModel)]="rangeConfig.Step" />
  </clr-input-container>
  <clr-input-container>
    <label>Default value</label>
    <input clrInput placeholder="0" name="value" type="number" [(ngModel)]="rangeConfig.Value" />
  </clr-input-container>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="UseApplyButton" [(ngModel)]="rangeConfig.ShowPlay" />
      <label>Show play</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-input-container>
    <label>Play seconds per step</label>
    <input clrInput placeholder="0" name="playsecondsperstep" type="number" min="1" max="1000" [(ngModel)]="rangeConfig.PlaySecondsPerStep"
      [disabled]="!rangeConfig.ShowPlay"/>
  </clr-input-container>

</form>
