import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widgets/widget-config-service';
import { FilterComparator, XDataType, ProjectionColumnDescription, TextSearchQuery, RelativeTimestampOrigo, OffsetType } from '../../XProjector/xprojector-client-service';
import { XProjectorClient } from '../../XProjector/xprojector-client-service';
import { DataFilter } from './data-filter-service';


@Component({
  selector: 'xproj-data-filter',
  templateUrl: './xproj-data-filter.component.html',
  styleUrls: ['./xproj-data-filter.component.scss']
})
export class XprojDataFilterComponent implements OnInit, AfterViewInit {

  @Output() onDelete = new EventEmitter<DataFilter>();

  @Input() editMode: boolean = true;
  @Input() group : [];
  @Input()
  get columnDescriptions(): ProjectionColumnDescription[] { return this._columnDescriptions }
  set columnDescriptions(columnDescriptions : ProjectionColumnDescription[]) {
    this._columnDescriptions = columnDescriptions;
    if (this._columnDescriptions.length > 0) {
      let columnDescription = this._columnDescriptions.find(coldesc =>
        coldesc.columnname == this.datafilter.ColumnDescriptor.columnname);
        if (columnDescription) {
          this.datafilter.ColumnDescriptor = columnDescription;
        }
    }
  }

  @Input() datafilter: DataFilter;
  @Input() inputParameters : WidgetInputParameter[] = [];
  @Input() widgets : WidgetConfig[];

  private _columnDescriptions: ProjectionColumnDescription[] = [];
  comparatorKeys: any;
  existingStrings : string[] = [];
  loading : boolean = false;

  RelativeTimestampOrigo = RelativeTimestampOrigo;
  OffsetType = OffsetType;

  constructor(private xprojClient: XProjectorClient,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService) {
    this.comparatorKeys = this.enumSelector(FilterComparator);
    this.comparatorKeys = this.comparatorKeys.slice(this.comparatorKeys.length / 2);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  setEditMode(mode: boolean): void {
    this.editMode = mode;
  }

  public Displayname(): string {
    if (this.datafilter?.CustomDisplayname?.length > 0)
      return this.datafilter?.CustomDisplayname;

    if (this.datafilter?.ColumnDescriptor?.columnname.length > 0) {
      return this.truncate(this.datafilter?.ColumnDescriptor?.columnname + ': ' +
        (this.datafilter?.InputParameterId.length > 0 ?
          ('[' + this.datafilter.InputParameterId + ']') :
          (this.datafilter?.ColumnDescriptor.datatype == XDataType.Timestamp ? (this.datafilter.DateTypeRelative ? 'Relative' : this.datafilter?.DateValue) :
            this.datafilter?.ColumnDescriptor.datatype == XDataType.String ? this.datafilter?.Value : this.datafilter.NumericValue)
        ), 25);
    }

    return "Not set";
  }

  private enumSelector(definition) {
    return Object.keys(definition)
      .map(key => ({ value: definition[key], title: key }));
  }

  private truncate(str : string, n : number) {
    return (str.length > n) ? str.substr(0, n - 1) : str;
  };

  removeFilter(): void {
    this.onDelete?.next(this.datafilter);
  }

  async onSearchValueChange(searchValue: string)
  {
    if (this.loading || !searchValue || searchValue.length < 2) {
      return;
    }

    this.loading = true;
    try
    {
      this.existingStrings.length = 0;
      let query = new TextSearchQuery();
      query.columnname = this.datafilter.ColumnDescriptor?.columnname;
      query.targetprojectionid = this.columnDescriptions[0].projectionid;
      query.searchvalue = searchValue;
      if(this.group?.length > 0)
        query.targetgroup = this.group;
      else
        query.targetgroup = new Array<string>();


      let x = await this.xprojClient.RequestQueryTextsearch(query);
      for(let o of x.strings)
      {
        this.existingStrings.push(o);
      }
    }
    catch(err)
    {
      this.logger.error(err);
    }
    this.loading = false;
  }


}
