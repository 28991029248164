import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { Subject, Subscription } from 'rxjs';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { BaseQuery, BaseQueryOutputColumnDescription, ColumnFilteringRelativeTimestamp, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { GroupSelectionTypes } from '../../widget-config-service';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { TextWidgetConfig } from '../text-widget-config/text-widget-config-service';

export class QueryDataColumn {
  id: string;
  columnname: string;
  label: string;
  datatype: XDataType;
  projectionid: string;
  data: any[] = [];
  dateformat: string;
}

function HTMLNodeIterator() {
  //task:function,node:HTML Node,param:task input parameter
  this.iterate = function iterate(task, node, param) {
    for (let x = 0; x < node.childNodes.length; x++) {
      var childNode = node.childNodes[x];
      if (task(childNode, param)) {
        if (childNode.childNodes.length > 0) {
          this.iterate(task, childNode, param);
        }
      }
    }
  }
}

@Component({
  selector: 'xproj-text-widget',
  templateUrl: './xproj-text-widget.component.html',
  styleUrls: ['./xproj-text-widget.component.scss']
})
export class XprojTextWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('htmlContainer', { static: true }) public htmlContainer: ElementRef;

  widgetConfig: TextWidgetConfig;

  content: string = '';

  loadingQuery: boolean = false;
  loading: boolean = true;
  private forcereload: boolean = false;
  lastQueryJson: string = '';

  columns: QueryDataColumn[] = [];

  routerSubject: Subject<string> = new Subject<string>();

  options = {
    showPreviewPanel: false,
    showBorder: false,
    enablePreviewContentClick: true,
    resizable: false
  }


  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    public ngZone: NgZone,
    private dateHelper: DateHelper,
    public renderer2: Renderer2,
    public router: Router
  ) {
    super(logger, xprojClient, widgetService);
    //@ts-ignore
    window.routerSubject = this.routerSubject;
  }

  ngAfterViewInit(): void {
    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      this.loadDelayed();
    }
  }

  ngOnDestroy(): void {
    this.routerSubject.unsubscribe();
    super.ngOnDestroy();
  }


  async ngOnInit() {
    this.routerSubject.subscribe((url: string) => {
      // Programmatically navigate whenever a new url gets emitted.
      this.ngZone.run(() => {
        this.router.navigate([`/${url}`]);
        //this.router.navigateByUrl('/' + url);
      });
    });

    this.widgetConfig = this.config as TextWidgetConfig;

    await super.ngOnInit();
  }

  async onInit() {

  }

  async onRefresh() {
    this.loadDelayed();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    if (force || projectionIds?.findIndex(pid => this.widgetConfig.ConfigQuery.Query.targetprojectionid == pid) > -1) {
      this.forcereload = true;
      await this.load();
    }
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    // if (this.htmlContainer && this.htmlContainer.nativeElement) {
    //   //this.htmlContainer.nativeElement.style.height = this.widgetheight * 0.75 + 'px';
    //   this.htmlContainer.nativeElement.style.height = this.widgetheight + 'px';
    // }
    this.loadDelayed();
  }

  async onReset() {

  }

  async onUpdateQuery() {
    this.loadDelayed();
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {

  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    this.loadDelayed();
  }

  private setTextColor(node, color): boolean {
    if (node.nodeName != 'A' && node.nodeName != 'PRE' && node.nodeName != 'CODE') {
      if (node.style) {
        node.style.color = color;
      }
    }

    return !(node.nodeName == 'PRE' || node.nodeName == 'CODE');
  }

  private formatValue(value: any, dataType: XDataType, dateformat : string = null): any {
    let result = value;
    switch (dataType) {
      case XDataType.Float32:
      case XDataType.Float64:
        result = WidgetUtils.FormatNumber(value, this.globalWidgetSettings.Decimals);
        break;
      case XDataType.Timestamp:
        result = this.dateHelper.formatDate(value as Date, dateformat);
        break;
    }

    return result;
  }

  updateContent() {
    let contentCopy = this.widgetConfig.Content;
    this.widgetConfig.InputParameters.forEach(input => {
      //@ts-ignore
      contentCopy = contentCopy.replaceAll('{{' + input.id + '}}', this.getParameterValue(input.id, input.initValue ?? '').value);
    });

    if (this.columns.length > 0 && this.columns[0].data.length > 0) {
      this.columns.forEach(col => {
        //@ts-ignore
        contentCopy = contentCopy.replaceAll('{{' + col.label + '}}', this.formatValue(col.data[0], col.datatype, col.dateformat));
      });
    }
    else {
      this.widgetConfig.ConfigQuery.ColumnConfigs.forEach(columnConfig => {
        //@ts-ignore
        contentCopy = contentCopy.replaceAll('{{' + columnConfig.Label + '}}', columnConfig.DefaultValue);
      });
    }

    this.content = contentCopy;

    let htmlCopy = this.widgetConfig.Html;
    this.widgetConfig.InputParameters.forEach(input => {
      //@ts-ignore
      htmlCopy = htmlCopy.replaceAll('{{' + input.id + '}}', this.getParameterValue(input.id, input.initValue ?? '').value);
    });

    if (this.columns.length > 0 && this.columns[0].data.length > 0) {
      this.columns.forEach(col => {
        //@ts-ignore
        htmlCopy = htmlCopy.replaceAll('{{' + col.label + '}}', this.formatValue(col.data[0], col.datatype, col.dateformat));
      });
    }
    else {
      this.widgetConfig.ConfigQuery.ColumnConfigs.forEach(columnConfig => {
        //@ts-ignore
        htmlCopy = htmlCopy.replaceAll('{{' + columnConfig.Label + '}}', columnConfig.DefaultValue);
      });
    }

    if (this.htmlContainer && this.htmlContainer.nativeElement) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer2.setProperty(this.htmlContainer.nativeElement, 'innerHTML', htmlCopy);
      });
    }

    if (this.widgetConfig.TextColor != '#000000') {
      var htmlNodeIterator = new HTMLNodeIterator();
      htmlNodeIterator.iterate(this.setTextColor, this.htmlContainer.nativeElement, this.widgetConfig.TextColor);
    }

  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }

    if (this.htmlContainer && this.htmlContainer.nativeElement) {
      this.htmlContainer.nativeElement.style.height = this.widgetheight + 'px';
    }
  }

  checkIfNeedRefresh(query: BaseQuery): boolean {
    let result = true;
    let queryJson = JSON.stringify(query)
    result = this.lastQueryJson != queryJson;
    this.lastQueryJson = queryJson;

    return result;
  }

  async reQuery(queryData: QueryDataColumn[]) : Promise<BaseQuery> {
    try {
      let query: BaseQuery;
      let queryResult = await this.requestBaseQueryResult(() => this.getQuery(),
        (query) => {
        return this.xprojClient.RequestQueryBaseQuery(query, this.forcereload, 'textwidget', this.config.Name);
      }, this.widgetConfig.ConfigQuery, this.widgetConfig.ConfigQuery.DataFilters, this.forcereload, 'textwidget', this.config.Name);
      this.forcereload = false;

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      this.widgetConfig.ConfigQuery.ColumnConfigs.forEach(columnConfig => {
        let queryColumn : BaseQueryOutputColumnDescription;
        if (columnConfig.ColumnName.indexOf(':') < 0) {
          queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnOutName);
        }
        else {
          if (columnConfig.ColumnName.startsWith('script')) {
            queryColumn = queryResult.columns.find(col => 'script:' + col.columnoutname == columnConfig.ColumnName);
          }
          else {
            queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnName);
          }
        }

        if (queryColumn) {
          let data = [];

          if (queryColumn.datatype <= XDataType.Number) {
            if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
              data = numericaldata[queryColumn.indexintypedvector];
            }
            else {
              data = WidgetUtils.FormatNumbers(numericaldata[queryColumn.indexintypedvector], this.globalWidgetSettings.Decimals);
            }
          }
          if (queryColumn.datatype == XDataType.String) {
            data = stringdata[queryColumn.indexintypedvector];
          }
          if (queryColumn.datatype == XDataType.Timestamp) {
            data = timestampdata[queryColumn.indexintypedvector];
          }

          let colData = queryData.find(d => d.columnname == queryColumn.columnoutname);

          if (colData) {
            colData.datatype = queryColumn.datatype;
            colData.dateformat = columnConfig.DateFormat;
            colData.data = data;
          }
          else {
            colData = new QueryDataColumn();
            colData.columnname = queryColumn.columnoutname;
            colData.label = columnConfig.ColumnOutName.length > 0 ? columnConfig.ColumnOutName : queryColumn.columnoutname;
            colData.data = data;
            colData.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;
            colData.datatype = queryColumn.datatype;
            colData.id = columnConfig?.Id;
            colData.dateformat = columnConfig.DateFormat;

            queryData.push(colData);
          }
        }
      });

      return query;
    }
    catch (error) {
      this.logger.log(error);
    }
  }

  loadTimer: any;
  async loadDelayed() {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(async () => {
        this.loadTimer = undefined;
        await this.load();
        this.updateContent();
      }, 200);
    }
  }

  async load() {

    if (!this.loadingQuery) {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }
      if (!this.inputParametersHasValue(true)) {
        //TODO
      }
      else {
        try {
          this.loadingQuery = true;
          this.loading = true;

          //if (this.forcereload || this.checkIfNeedRefresh(query)) {
            await this.reQuery(this.columns);
          //}
        }
        finally {
          this.loadingQuery = false;
        }
      }
    }

  }

  getQuery(): BaseQuery {
    let query = this.widgetConfig.ConfigQuery.Query.Clone();

    query.columns = query.columns.filter(col => col.columnname.indexOf(':') < 0);
    //Projection input parameters
    if (this.widgetConfig.ConfigQuery.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(this.widgetConfig.ConfigQuery.ProjectionInputParameterId, this.widgetConfig.ConfigQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(this.widgetConfig.ConfigQuery.GroupInputParameterId, this.widgetConfig.ConfigQuery.Query.targetgroup).value;
    }
    else if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      this.widgetConfig.ConfigQuery.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    WidgetUtils.SetQueryFilterValues(query, this.widgetConfig.ConfigQuery.DataFilters, (i, d) => this.getParameterValue(i, d));

    query.seekoffset = 0;
    query.maxitems = 1; //this.widgetConfig.ConfigQuery.MaxItems;

    let scol = query.columns.find(c => c.columnname == this.widgetConfig.ConfigQuery.defaultSortColumnName);
    if (scol) {
      if (this.widgetConfig.ConfigQuery.UseGrouping) {
        query.sorting.columnname = scol.columnoutname;
      }
      else {
        query.sorting.columnname = scol.columnname;
      }
      query.sorting.descending = this.widgetConfig.ConfigQuery.defaultSortDescending;
    }

    return query;
  }
}
