<h2>Communications</h2>
Rossaker communications systems are software based integration to existing third-party commercial and opensource systems.
With our integrated communications systems the manual tasks in provisioning and roaming are removed.

<h3>3GPP</h3>
We offer APN-integration via Radius AAA and provisioning via API directly to telecom operators using the <em>Ericsson DCP</em> plattform.

<h3>LoRaWAN</h3>
We offer a fully managed LoRaWAN infrastructure both on the edge/on-premise and in the cloud.
The cloud-based LoRaWAN system is only available for managed IoT sensors and meters.<br>
Our LoRaWAN systems support;
<ul>
    <li>Roaming between operators</li>
    <li>XProjector dashboards for monitoring and reporting</li>
    <li>Provisioning</li>
</ul>

Our LoRaWAN systems are built around the opensource project <em>Chirpstack</em> and supports both <em>Chirpstack gateway bridge</em> and <em>Semtech UDP</em>.

<h3>Field bus protocols</h3>
We offer local field bus adapters to most field bus protocols on the market.

<h3>Automation - Integration Servers</h3>
Our Integration servers in XAutomation supports transparent proxies between field bus protocols and high-level communication brokers.<br>
Currently supported integration server endpoints include:
<ul>
    <li>MQTT</li>
    <li>Modbus</li>
    <li>OPC UA</li>
</ul>