import { RossakerEventsClient } from './proto/xprojector.modulerossakerbms.events.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { RossakerWebClient } from './proto/xprojector.module.rossaker.web.pbsc';
import { AddContactInfoRequest } from './proto/xprojector.module.rossaker.web.pb';


export class RossakerXProjectorWebContactInfo {
  firstname : string;
  surname : string;
  email : string;
  phone : string;
  company : string;
  country : string;
  worktitle : string;
  message : string;
  target : string;
}

@Injectable({
  providedIn: 'root'
})
export class RossakerXProjectorWebClient implements OnInit, OnDestroy {

  constructor(
    private rossakerWebClient: RossakerWebClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }
  
  async addContactInfo(contactInfo : RossakerXProjectorWebContactInfo) : Promise<boolean> {
    let request: AddContactInfoRequest = new AddContactInfoRequest({
      firstname : contactInfo.firstname,
      surname : contactInfo.surname,
      email : contactInfo.email,
      phone : contactInfo.phone,
      company : contactInfo.company,
      worktitle : contactInfo.worktitle,
      message : contactInfo.message,
      target : contactInfo.target
    });

    let result = await this.rossakerWebClient.addContactInfo(request, this.state.metadata).toPromise();

    return result?.ok ?? false;
  }
}
