<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->

    <div class="splash-container">
        <h1>About Rossaker AB</h1>
    </div>

    <div class="content" >
        <h2>About us</h2>
        <p>
            Rossaker is a dedicated engineering company founded in 2020 by veterans with decades of experience 
            in industrial system development and deployment.
        </p>

        <h2>Rossaker&apos;s reason for being </h2>
        <p>Rossaker&apos;s reason for being is to accelerate the value creation process through implementation of 
        Industrial IoT, big data and automation technology, empower people, make industry and our society sustainable.</p>

        <h2>Core technology </h2>
        Rossaker systems, products and solutions are built on its core big data technology, the foundation of efficient, cost effective, secure and scalable digitalisation.

        <h2>Innovation oriented partnership</h2>
        We build true partnerships with our customers, as we believe partnerships foster great innovation and sustainable growth.
        
<!-- 
        <h2>About us</h2>
        <p>
            Rossaker was founded 2020 by a team having developed and delivered control and telecom systems.<br>
            From day one we have strived on making robust solutions which are preactical and economical to deploy.<br>
            Today our software and cloud services are used in several b2b and b2c applications.
        </p>
        <h2>About our technology</h2>
        <p>
            All technology developed by Rossaker are designed to scale, be secure and compliant to regulation and 
            at the same time being cost effective. <br>
            <br>
            In essence this means taking no short cuts and on a technical level we accomplish it by upholding internal modular design 
            with a balanced use of open source technologies.
        </p> -->
        <br><a  routerLink="/contact">Talk to us about partnership.</a>

        <h2>Locations</h2>
        We are located in Gothenburg and Stockholm, Sweden.        

        <h3>Gothenburg</h3>
        <p>            
            Datavägen 14A<br>
            436 32 Askim<br>
            Sweden
        </p>          
        <div class="mapcontent" >
            <iframe allowtransparency="true" width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=11.88394546508789%2C57.566862376950475%2C12.030200958251955%2C57.71313440039107&amp;layer=mapnik&amp;marker=57.64009815027791%2C11.95710459999998" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=57.6401&amp;mlon=11.9571#map=13/57.6401/11.9571">View Larger Map</a></small>
        </div>

        <h3 style="margin-top: 2em;">Stockholm</h3>
        <p>
            Borgmästargatan 12<br>
            116 29 Stockholm<br>
            Sweden
        </p> 
        <div  class="mapcontent">
            <iframe  width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=18.068218231201175%2C59.296615551469735%2C18.10478210449219%2C59.3314820615028&amp;layer=mapnik&amp;marker=59.314051000036784%2C18.08649379999997" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=59.3141&amp;mlon=18.0865#map=15/59.3141/18.0865">View Larger Map</a></small>
        </div>

        <h3 style="margin-top: 2em;">Shipments</h3>
        <p>
            For shipping details please <a routerLink="/contact">contact us</a>.
        </p>

    </div>

</div>