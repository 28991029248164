<table class="table table-vertical" *ngIf="lorawanDeviceInfo">
  <caption style="margin-bottom: 1em;">
    Device info
  </caption>
  <tbody>
    <tr>
      <th class="left">DevEui</th>
      <td class="left">{{lorawanDeviceInfo.deveui}}</td>
    </tr>
    <tr>
      <th class="left">Gateway Count</th>
      <td class="left">{{lorawanDeviceInfo.gatewayCount}}</td>
    </tr>
    <tr>
      <th class="left">Gateway Id</th>
      <td class="left">{{lorawanDeviceInfo.gatewayIdentifier}}</td>
    </tr>
    <tr>
      <th class="left">Gateway Ids</th>
      <td class="left">{{lorawanDeviceInfo.gatewayIdentifiers}}</td>
    </tr>
    <tr>
      <th class="left">Latitude</th>
      <td class="left">{{lorawanDeviceInfo.latitude}}</td>
    </tr>
    <tr>
      <th class="left">Longitude</th>
      <td class="left">{{lorawanDeviceInfo.longitude}}</td>
    </tr>
        <tr>
      <th class="left">RSSI</th>
      <td class="left">{{lorawanDeviceInfo.rssi}}</td>
    </tr>
        <tr>
      <th class="left">Snr</th>
      <td class="left">{{lorawanDeviceInfo.snr?.toFixed(1)}}</td>
    </tr>
    <tr>
      <th class="left">Spf</th>
      <td class="left">{{lorawanDeviceInfo.spreadingfactor}}</td>
    </tr>
    <tr>
      <th class="left">Battery</th>
      <td class="left">{{lorawanDeviceInfo.batteryLevel == 255 ? '---' : (lorawanDeviceInfo.batteryLevel * 100/ 254).toFixed(0) + ' %'}}</td>
    </tr>
    <tr>
      <th class="left">Time</th>
      <td class="left">{{dateHelper.utils.format(lorawanDeviceInfo.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
  </tbody>
</table>

<p *ngIf="!lorawanDeviceInfo"> Device info not available.</p>
