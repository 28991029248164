

<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->

    <div class="splash-container">
        <div style="display: table;">
            <h1>Support</h1>
        </div>
    </div>



    <div class="content">

        <h2>Partners and registered customers</h2>
        Partners and registered customers may log in to our support system <a href="https://support.rossaker.com">here</a>.

        <h2>New or non-registered customers</h2>
        Please use our contact form <a routerLink="/contact">here</a>.
        


    </div>

</div>