import { Injectable, InjectionToken } from '@angular/core';

export const LOGGERSERVICE = new InjectionToken<XprojLoggerService>('LOGGERSERVICE');

@Injectable({
  providedIn: 'root'
})
export abstract class XprojLoggerService {

  abstract trace(message: any, ...additional: any[]): void;
  abstract debug(message: any, ...additional: any[]): void;
  abstract info(message: any, ...additional: any[]): void;
  abstract log(message: any, ...additional: any[]): void;
  abstract warn(message: any, ...additional: any[]): void;
  abstract error(message: any, ...additional: any[]): void;
  abstract fatal(message: any, ...additional: any[]): void;

}
