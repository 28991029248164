import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@xprojectorcore/services/state-service';
import { XprojModalService } from 'xproj-lib';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email : string = '';

  constructor(
    private state: StateService,
    private router: Router,
    private modalService: XprojModalService
  ) {

  }

  async ngOnInit() {
    await this.state.logout(false);
  }

  async submit() {
    if (this.email?.length > 0) {
      await this.state.resetPassword(this.email);

      let hedaer = $localize `:@@resetpassword_confirmheader:Forgot password`;
      let description = $localize `:@@resetpassword_confirmdescription:Reset password link sent to email address.`;
      this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
        this.router.navigate(['/login']);
      });
    }
  }
}
