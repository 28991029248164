import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { XprojAlertService, XprojModalService } from 'xproj-lib';
import { StateService } from '@xprojectorcore/services/state-service';
import { CustomerUser, UserDevice } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class UserProfileComponent implements OnInit {

  user : CustomerUser;
  userDevices : UserDevice[] = [];
  selectedUserDevice : UserDevice;
  showUserDevice : boolean = false;

  oldPassword : string = '';
  newPassword : string = '';

  constructor(
    public state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private alertService : XprojAlertService,
    private modalService: XprojModalService,
    private logger: NGXLogger
  ) { }

  async ngOnInit() {
    this.user = await this.customerUserClient.getActiveCustomerUser();
    this.userDevices = await this.customerUserClient.getCustomerUserDevices();
  }

  async saveCustomerUser() {
    let result = await this.customerUserClient.modifyCustomerUser(this.user);
    if (result.result) {
      this.alertService.success($localize `:@@userprofile_usermodified:User modified.`);
    } else {
      this.alertService.error(result.message);
    }
  }

  async setPassword() {
    try {
      let result = await this.customerUserClient.setPassword(this.oldPassword, this.newPassword);
      if (result.result) {
        this.alertService.success($localize `:@@userprofile_passwordupdated:Password updated.`);
      } else {
        this.alertService.error(result.message);
      }
    } catch (err) {
      this.logger.error('err', err);
      this.alertService.error(err);
    }
  }

  async addCustomerUserDevice() {
    let addUserDeviceResult = await this.modalService.ShowInputModalAsync({
      header: 'Add device',
      description: 'Input device name:',
      value: '',
      ok: 'Add device',
      cancel: 'Cancel'
    });
    if (addUserDeviceResult.result) {
      let result = await this.customerUserClient.addCustomerUserDevice(addUserDeviceResult.value);
      if (result) {
        this.userDevices.push(result);
      }
    }
  }

  async deleteCustomerUserDevice() {
    if (this.selectedUserDevice) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete device',
        description: 'Delete selected device, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        let result = await this.customerUserClient.deleteCustomerUserDevice(this.selectedUserDevice.id);
        if (result.result) {
          this.userDevices = this.userDevices.filter(userDevice => userDevice.id != this.selectedUserDevice.id);
          this.selectedUserDevice = null;
        }
      }
    }
  }

  async viewCustomerUserDevice() {
    if (this.selectedUserDevice) {
      this.showUserDevice = true;
    }
  }
}
