import { TypedJSON } from 'typedjson';
import { DashboardConfig, Resolutions, XprojDashboardService } from './xproj-dashboard-service';

export class LocalstorageDashboardService implements XprojDashboardService {

  loadDashboard(id: string, version : number = -1, tag : string = '', systemDashboard : boolean = false): Promise<DashboardConfig> {
    const serializer = new TypedJSON(DashboardConfig);

    let json = localStorage.getItem('dashboardconfig_' + id)

    if (json?.length > 0) {
      let result : DashboardConfig = serializer.parse(json);
      //let result : DashboardConfig = JSON.parse(json);
      return Promise.resolve(result);
    }

    let result = new DashboardConfig();
    result.Id = id;
    return Promise.resolve(result);
  }

  saveDashboard(dashboardConfig : DashboardConfig): Promise<boolean> {
    const serializer = new TypedJSON(DashboardConfig);
    localStorage.setItem('dashboardconfig_' + dashboardConfig.Id, serializer.stringify(dashboardConfig));
    //localStorage.setItem('dashboardconfig_' + dashboardConfig.Id, JSON.stringify(dashboardConfig));
    console.log('save', dashboardConfig);
    return Promise.resolve(true);
  }

}
