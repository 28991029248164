<form clrForm>
  <clr-input-container>
    <label>Label</label>
    <input clrInput placeholder="" name="label" [(ngModel)]="transformConfig.Label" />
  </clr-input-container>

  <clr-select-container>
    <label>Default transform</label>
    <select clrSelect name="defaultTransform" id="defaultTransform" [(ngModel)]="transformConfig.DefaultTransform">
      <option [ngValue]="Transformation.NONE"> None </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
      <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
      <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
      <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
      <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
      <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
      </option>
      <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
      </option>
      <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
      <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
      <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
      <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
    </select>
  </clr-select-container>

  <clr-datagrid>
    <clr-dg-column>Transform</clr-dg-column>
    <clr-dg-column>Show</clr-dg-column>
    <clr-dg-column>Label</clr-dg-column>

    <clr-dg-row>
      <clr-dg-cell>
        NONE
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.NONE.Show" name="NONE" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labelnone" [(ngModel)]="transformConfig.NONE.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_YEAR
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_YEAR.Show" name="TIMESTAMP_RESOLUTION_YEAR" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_year" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_YEAR.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_MONTH
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_MONTH.Show" name="TIMESTAMP_RESOLUTION_MONTH" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_month" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_MONTH.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_DAY
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_DAY.Show" name="TIMESTAMP_RESOLUTION_DAY" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_day" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_DAY.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_HOUR
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_HOUR.Show" name="TIMESTAMP_RESOLUTION_HOUR" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_hour" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_HOUR.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_MINUTE
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_MINUTE.Show" name="TIMESTAMP_RESOLUTION_MINUTE" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_minute" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_MINUTE.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_RESOLUTION_SECOND
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_SECOND.Show" name="TIMESTAMP_RESOLUTION_SECOND" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_resolution_second" [(ngModel)]="transformConfig.TIMESTAMP_RESOLUTION_SECOND.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_DAYOFYEAR
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_DAYOFYEAR.Show" name="TIMESTAMP_DAYOFYEAR" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_dayofyear" [(ngModel)]="transformConfig.TIMESTAMP_DAYOFYEAR.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_DAYOFMONTH
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_DAYOFMONTH.Show" name="TIMESTAMP_DAYOFMONTH" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_dayofmonth" [(ngModel)]="transformConfig.TIMESTAMP_DAYOFMONTH.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_DAYOFWEEK
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_DAYOFWEEK.Show" name="TIMESTAMP_DAYOFWEEK" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_dayofweek" [(ngModel)]="transformConfig.TIMESTAMP_DAYOFWEEK.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_SECONDS_OF_DAY
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_SECONDS_OF_DAY.Show" name="TIMESTAMP_SECONDS_OF_DAY" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_seconds_of_day" [(ngModel)]="transformConfig.TIMESTAMP_SECONDS_OF_DAY.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_MINUTES_OF_DAY
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_MINUTES_OF_DAY.Show" name="TIMESTAMP_MINUTES_OF_DAY" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_minutes_of_day" [(ngModel)]="transformConfig.TIMESTAMP_MINUTES_OF_DAY.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_HOURS
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_HOURS.Show" name="TIMESTAMP_HOURS" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_hours" [(ngModel)]="transformConfig.TIMESTAMP_HOURS.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_MINUTES
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_MINUTES.Show" name="TIMESTAMP_MINUTES" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_minutes" [(ngModel)]="transformConfig.TIMESTAMP_MINUTES.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_SECONDS
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_SECONDS.Show" name="TIMESTAMP_SECONDS" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_seconds" [(ngModel)]="transformConfig.TIMESTAMP_SECONDS.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        TIMESTAMP_HOURS
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.TIMESTAMP_HOURS.Show" name="TIMESTAMP_HOURS" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labeltimestamp_hours" [(ngModel)]="transformConfig.TIMESTAMP_HOURS.Label" />
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-row>
      <clr-dg-cell>
        FORWARD_DIFF
      </clr-dg-cell>

      <clr-dg-cell>
        <div class="clr-checkbox-wrapper">
          <input type="checkbox" clrCheckbox [(ngModel)]="transformConfig.FORWARD_DIFF.Show" name="FORWARD_DIFF" />
        </div>
      </clr-dg-cell>

      <clr-dg-cell>
        <input class="clr-input" style="width: 95%;" placeholder="" name="labelforward_diff" [(ngModel)]="transformConfig.FORWARD_DIFF.Label" />
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>

</form>
