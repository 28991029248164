import { Pipe, PipeTransform } from '@angular/core';
import { WidgetInputParameter } from '../widgets/widget-config-service';

@Pipe({
  name: 'xproj_filterinputparameters'
})
export class XprojFilterInputParametersPipe implements PipeTransform {

  transform(items: WidgetInputParameter[], datatypes: number[]): WidgetInputParameter[] {
    let result: WidgetInputParameter[] = [];
    items.forEach(input => {
      datatypes.forEach(datatype => {
        if (datatype < 0 ||
          (datatype == input.datatype) ||
          (datatype < 6 && input.datatype < 6)) {
            result.push(input);
        }
      });    });

    return result;
  }

}
