import { Component, OnInit } from '@angular/core';
import { XProjectorClient } from '../../XProjector/xprojector-client-service';
import { EditplcprogramService } from '../editplcprogram.service';

@Component({
  selector: 'xproj-editplcprogram-io',
  templateUrl: './editplcprogram-io.component.html',
  styleUrls: ['./editplcprogram-io.component.scss']
})
export class EditplcprogramIoComponent implements OnInit {

  constructor(public plcProgramService : EditplcprogramService) { }

  ngOnInit(): void {
  }

}
