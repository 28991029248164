
export class RossakerBmsInvoiceInfo {
  id : number;
  customerId : string;
  meterType : string;
  activeFrom : Date;
  createdAt : Date;
  modifiedAt : Date;
  deleted : boolean;
  deletedAt : Date;

  activeFromString : string;
}
