import { ChartWidgetConfig } from '../widgets/chart/chart-widget-config/xproj-chart-widget-config-service';
import { OutputDataType, WidgetConfig, WidgetOutputParameter } from '../widgets/widget-config-service';
import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember, jsonMapMember } from 'typedjson';
import { ParseError } from '@angular/compiler';
import { TableWidgetConfig } from '../widgets/table/table-widget-config/table-widget-config-service';
import { MasterWidgetConfig } from '../widgets/master/master-widget-config/master-widget-config-service';
import { PiechartWidgetConfig } from '../widgets/piechart/piechart-widget-config/piechart-widget-config-service';
import { LabelWidgetConfig } from '../widgets/label/label-widget-config/label-widget-config-service';
import { SpectrumAnalyzerConfig } from '../widgets/spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { CompactType, GridType } from 'angular-gridster2';
import { Injectable, InjectionToken } from '@angular/core';
import { TextWidgetConfig } from '../widgets/text/text-widget-config/text-widget-config-service';
import { MapWidgetConfig } from '../widgets/map/map-widget-config/xproj-map-widget-config-service';
import { SvgWidgetConfig } from '../widgets/svg/svg-widget-config/xproj-svg-widget-config-service';
import { OutputControllerWidgetConfig } from '../widgets/output/output-controller-widget-config/xproj-output-controller-widget-config-service';
import { inherits } from 'util';
import { ProjectionDataEditorWidgetConfig } from '../widgets/projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { ContainerWidgetConfig } from '../widgets/container/container-widget-config/container-widget-config-service';
import { Scripted3dWidgetConfig } from '../widgets/scripted3d/scripted3d-widget-config/xproj-scripted3d-widget-config-service';
import { SpcConfig } from '../widgets/spc/spc-widget-config/spc-config-service';
import { GlobalWidgetSettings } from '../models/global-widget-settings';
import { ScriptedParameters } from '../models/scripted-parameters';

export const DASHBOARDSERVICE = new InjectionToken<XprojDashboardService>('DASHBOARDSERVICE');

@Injectable({
  providedIn: 'root'
})
export abstract class XprojDashboardService {

  abstract loadDashboard(id : string, version : number, tag : string, systemDashboard : boolean) : Promise<DashboardConfig>;

  // todo: Sandbox dashboardConfig, ResolutionBasedDashboardConfig
  abstract saveDashboard(dashboardConfig : DashboardConfig) : Promise<boolean>;
}


export enum SideNavPosition {
  LEFT    = 0,
  RIGHT   = 1,
  TOP     = 2,
  BOTTOM  = 3,

}

@jsonObject
export class GridSettings {
  @jsonMember
  public BackgroundColor : string = 'transparent';

  @jsonMember
  public MinCols : number = 12;

  @jsonMember
  public MaxCols : number =  12;

  @jsonMember
  public MinRows : number =  1;

  @jsonMember
  public MaxRows : number =  100;

  @jsonMember
  public FixedColWidth : number =  105;

  @jsonMember
  public FixedRowHeight : number =  105;

  @jsonMember
  public ScrollSensitivity : number =  10;

  @jsonMember
  public ScrollSpeed : number =  20;

  @jsonMember
  public GridType : GridType = GridType.ScrollVertical;

  @jsonMember
  public CompactType : CompactType = CompactType.CompactLeftAndUp;

  @jsonMember
  public PushNorth : boolean = false;

  @jsonMember
  public PushSouth : boolean = false;

  @jsonMember
  public PushEast : boolean = true;

  @jsonMember
  public PushWest : boolean = true;

  @jsonMember
  public DisableWindowResize : boolean = true;

  @jsonMember
  public Margin : number =  8;

  @jsonMember
  public AllowMultiLayer : boolean = false;

  @jsonMember
  public DefaultLayerIndex : number =  1;

  @jsonMember
  public MaxLayerIndex : number =  2;

  @jsonMember
  public BaseLayerIndex : number =  1;

  @jsonMember
  public ShowSideNav : boolean = false;

  @jsonMember
  public SideNavWidth : number = 200;

  @jsonArrayMember(String)
  public SideNavWidgets : string[] = [];

  @jsonMember
  public SideNavPosition : SideNavPosition = SideNavPosition.RIGHT;

}

export enum DashboardEvent
{
  INIT                = 0,
  PARAMETERS_LOADED   = 10,
  DATA_CHANGED        = 20,
  DATA_RETRIEVED      = 30
}

@jsonObject
export class ScriptedParametersOnEvent extends ScriptedParameters
{
  @jsonMember
  public OnEvent: DashboardEvent = DashboardEvent.INIT;
}

@jsonObject
export class ScriptedParametersOnParameterChange extends ScriptedParameters
{
  lastran : Date = new Date(1970,1,1);
  heldBack : boolean = false;
  @jsonArrayMember(String)
  public OnParameterChanged: string[] = [];
}

@jsonObject
export class ResponsiveOverriderableWidgetConfig
{
  @jsonMember
  public OriginalId : string;

  @jsonMember
  public x : number;

	@jsonMember
  public y : number;

	@jsonMember
  public cols : number = 2;

	@jsonMember
	public rows : number = 1;
}

@jsonObject
export class ReponsiveiveOverridableWidgetConfigs
{
  @jsonMember
  public Resolution : Resolutions;

	@jsonArrayMember(ResponsiveOverriderableWidgetConfig)
  public OverrideConfigs : ResponsiveOverriderableWidgetConfig[] = [];
}

@jsonObject
export class DashboardTestValue
{
  @jsonMember
  public Name : string;

  @jsonMember
  public Value : string;

  @jsonMember
	public Datatype : OutputDataType;
}

@jsonObject
export class DashboardColorPaletteEntry
{
  @jsonMember
  public Name : string;

  @jsonMember
  public LuaScriptName : string;

  @jsonMember
  public DefaultColor: string;

  @jsonMember
  public Profile: string  ="";
}

@jsonObject({
  knownTypes: [
    ChartWidgetConfig,
    TableWidgetConfig,
    MasterWidgetConfig,
    PiechartWidgetConfig,
    LabelWidgetConfig,
    SpectrumAnalyzerConfig,
    SpcConfig,
    TextWidgetConfig,
    MapWidgetConfig,
    SvgWidgetConfig,
    OutputControllerWidgetConfig,
    ScriptedParametersOnEvent,
    ScriptedParametersOnParameterChange,
    ProjectionDataEditorWidgetConfig,
    ContainerWidgetConfig,
    Scripted3dWidgetConfig,
    ResponsiveOverriderableWidgetConfig,
    ReponsiveiveOverridableWidgetConfigs
  ]
})
export class DashboardConfig {

  @jsonMember
  public Id : string;

  @jsonMember
  public Description : string;

  @jsonMember
  public Tag : string;

  @jsonMember
  public Version : number = 1;

  @jsonArrayMember(DashboardTestValue)
  public TestValues : DashboardTestValue[] = [];

	@jsonArrayMember(WidgetConfig)
  public WidgetConfigs : WidgetConfig[] = [];

  @jsonMapMember( Number, ReponsiveiveOverridableWidgetConfigs )
  public ResponsiveWidgets :  Map<Number, ReponsiveiveOverridableWidgetConfigs> = new Map<Number, ReponsiveiveOverridableWidgetConfigs>();

  @jsonArrayMember(ScriptedParameters)
  public DynamicParameters : ScriptedParameters[] = [];

  @jsonMember
  public GlobalWidgetSettings : GlobalWidgetSettings = new GlobalWidgetSettings();

  @jsonMember
  public Grid : GridSettings = new GridSettings();

  @jsonArrayMember(WidgetOutputParameter)
  public OutputParameters : WidgetOutputParameter[] = [];

  @jsonMember
  public SubscriptionsEnabled : boolean = true;

  @jsonMember
  public UserCSS : string = "";

  @jsonMember
  public RefreshEnabled : boolean = false;

  @jsonMember
  public RefreshInterval : number = 10000;

  @jsonArrayMember(DashboardColorPaletteEntry)
  public ColorPalette : DashboardColorPaletteEntry[] = [];

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];

    this.WidgetConfigs.forEach(c => {
      c.GetSubscriprionData().forEach(subData => {
        if (!result.find(d => d == subData)) {
          result.push(subData);
        }
      });
    });

    return result;
  }

  update(config: DashboardConfig) {
    this.Description = config.Description;
    //this.
    this.GlobalWidgetSettings = config.GlobalWidgetSettings;
    this.WidgetConfigs = config.WidgetConfigs;
    this.Grid = config.Grid;
    this.OutputParameters = config.OutputParameters;
    this.ResponsiveWidgets = config.ResponsiveWidgets;
    this.TestValues = config.TestValues;
    this.DynamicParameters = config.DynamicParameters;
    this.SubscriptionsEnabled = config.SubscriptionsEnabled;
    this.UserCSS = config.UserCSS;
  }
}

@jsonObject({
  knownTypes: [
    ChartWidgetConfig,
    TableWidgetConfig,
    MasterWidgetConfig,
    PiechartWidgetConfig,
    LabelWidgetConfig,
    SpectrumAnalyzerConfig,
    SpcConfig,
    TextWidgetConfig,
    MapWidgetConfig,
    SvgWidgetConfig,
    OutputControllerWidgetConfig,
    ScriptedParametersOnEvent,
    ScriptedParametersOnParameterChange,
    ProjectionDataEditorWidgetConfig,
    ContainerWidgetConfig,
    Scripted3dWidgetConfig
  ]
})
export class CopiedWidget
{
  @jsonMember
  CopiedWidget : WidgetConfig = null;
}


export enum Resolutions
{
  Sandbox,
  Mobile,
  IPad,
  HD,
  QHD,
  UHD
}
