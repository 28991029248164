import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import DateFnsUtils from "@date-io/date-fns";
import { sv, enUS } from "date-fns/locale";

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  utils: DateFnsUtils;
  locale: Locale;

  constructor(
    @Inject(LOCALE_ID) public localeId: string) {
    switch (localeId) {
      case 'sv':
      case 'sv-SE':
        this.locale = sv;
        break;

      default:
        this.locale = enUS;
        break;
    }
    this.utils = new DateFnsUtils({ locale: this.locale });
  }

  format(date: Date, formatKey: any, defaultValue : string = ''): string {
    try {
      return this.utils.format(date, formatKey);
    }
    catch {
      return defaultValue;
    }
  }

  formatByString(date: Date, formatString : string, defaultValue : string = ''): string {
    try {
      return this.utils.formatByString(date, formatString);
    }
    catch {
      return defaultValue;
    }
  }

  formatDate(date: Date, dateformat: string): string {
    let realformat = this.utils.formats.keyboardDateTime;
    if (!dateformat || dateformat == "") {
      let now = new Date();
      if (now.getDate() == date.getDate()) {
        realformat = this.utils.formats.fullTime24h;
      }
      else {
        realformat = this.utils.formats.keyboardDateTime;
      }
    }
    else if (dateformat == "fullDate")
      realformat = this.utils.formats.fullDate;
    else if (dateformat == "normalDate")
      realformat = this.utils.formats.normalDate;
    else if (dateformat == "shortDate")
      realformat = this.utils.formats.shortDate;
    else if (dateformat == "monthAndDate")
      realformat = this.utils.formats.monthAndDate;
    else if (dateformat == "fullTime24h")
      realformat = this.utils.formats.fullTime24h;
    else if (dateformat == "fullDateTime")
      realformat = this.utils.formats.fullDateTime;
    else if (dateformat == "fullDateTime24h")
      realformat = this.utils.formats.fullDateTime24h;
    else if (dateformat == "keyboardDate")
      realformat = this.utils.formats.keyboardDate;
    else if (dateformat == "keyboardDateTime")
      realformat = this.utils.formats.keyboardDateTime;
    else if (dateformat == "keyboardDateTime24h")
      realformat = this.utils.formats.keyboardDateTime24h;

    return this.utils.formatByString(date, realformat);
  }
}
