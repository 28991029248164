<xproj-widget-config [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets" [gridSettings]="gridSettings"></xproj-widget-config>

<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Query</clr-accordion-title>
        <clr-accordion-content>
            <form clrForm>
                <clr-select-container>
                    <label>Projection</label>
                    <select clrSelect id="useprojectioninput" name="useprojectioninput" [(ngModel)]="editConfig.ConfigQuery.UseProjectionInputParameter">
            <option [ngValue]="true"> Input </option>
            <option [ngValue]="false"> Projection </option>
          </select>

                    <select clrSelect style="margin-left: 1em;" *ngIf="!editConfig.ConfigQuery.UseProjectionInputParameter" name="projection" [(ngModel)]="projection" (ngModelChange)="selectedProjectionChange($event)">
            <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
          </select>

                    <select clrSelect style="margin-left: 1em;" *ngIf="editConfig.ConfigQuery.UseProjectionInputParameter" class="clr-select" name="UseProjectionInputParameter" [(ngModel)]="editConfig.ConfigQuery.ProjectionInputParameterId">
            <option
              *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
              [ngValue]="input.id">
              {{input.id}}</option>
          </select>
                </clr-select-container>

                <clr-select-container>
                    <label>Input / Group</label>
                    <select clrSelect id="usegroupinput" name="usegroupinput" [(ngModel)]="editConfig.ConfigQuery.GroupSelectionType">
            <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
            <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
            <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
          </select>

                    <select clrSelect *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select" name="GroupSelectionType" [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterId">
            <option
              *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
              [ngValue]="input.id">
              {{input.id}}</option>
          </select>
                </clr-select-container>

                <xproj-group-selection #groupSelect *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP" [projection]="projection" [selected]="editConfig.ConfigQuery.Query?.targetgroup" (groupSelected)="selectedProjectionGroupChange($event)">
                </xproj-group-selection>

                <clr-combobox-container *ngIf="editConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
                    <label>Group input selections</label>
                    <clr-combobox [(ngModel)]="editConfig.ConfigQuery.GroupInputParameterIds" name="multiSelect" clrMulti="true" required>
                        <ng-container *clrOptionSelected="let selected">
                            {{selected}}
                        </ng-container>
                        <clr-options>
                            <clr-option *clrOptionItems="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'" [clrValue]="input.id">
                                {{input.id}}
                            </clr-option>
                        </clr-options>
                    </clr-combobox>
                </clr-combobox-container>
            </form>

            <h5>Data</h5>
            <form clrForm *ngIf="columns">

                <clr-input-container>
                    <label>Max items</label>
                    <input clrInput placeholder="" type="number" name="rows" min="1" max="100" [(ngModel)]="editConfig.ConfigQuery.MaxItems" />
                </clr-input-container>

                <clr-select-container>
                    <label>Default sorting column</label>
                    <select clrSelect id="sortingcolumn" name="sortingcolumn" [(ngModel)]="editConfig.ConfigQuery.defaultSortColumnName">
            <option [ngValue]=""> </option>
            <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
              {{col.ColumnName}}</option>
          </select>
                </clr-select-container>

                <clr-select-container>
                    <label>Default sorting order</label>

                    <select clrSelect id="sortingorder" name="sortingorder" [(ngModel)]="editConfig.ConfigQuery.defaultSortDescending">
            <option [ngValue]="false"> Ascending </option>
            <option [ngValue]="true"> Descending </option>
          </select>
                </clr-select-container>

                <clr-select-container>
                    <label>Grouping</label>

                    <select clrSelect id="usegrouping" name="usegrouping" [(ngModel)]="editConfig.ConfigQuery.UseGrouping">
            <option [ngValue]="true"> Group </option>
            <option [ngValue]="false">No grouping</option>
          </select>

                </clr-select-container>

                <clr-datagrid [(clrDgSingleSelected)]="selectedColumnConfig">
                    <clr-dg-column>Column</clr-dg-column>
                    <clr-dg-column *ngIf="editConfig.ConfigQuery.UseGrouping">Transform / Aggregation</clr-dg-column>
                    <clr-dg-column>Label</clr-dg-column>
                    <clr-dg-column>Unit</clr-dg-column>
                    <clr-dg-column>Move</clr-dg-column>

                    <clr-dg-row *ngFor="let column of editConfig.ConfigQuery.ColumnConfigs; let i = index" [clrDgItem]="column">
                        <clr-dg-cell>
                            <div class="clr-select-wrapper">
                                <select class="clr-select" name="tselectqueryData.column{{i}}" id="tselectcolumn_{{i}}" [(ngModel)]="column.ColumnName" (ngModelChange)="onColumnSelect($event, i)">
                  <option *ngFor="let col of columns" [ngValue]="col.columnname">
                    {{col.columnname}}</option>
                </select>
                            </div>
                        </clr-dg-cell>
                        <clr-dg-cell *ngIf="editConfig.ConfigQuery.UseGrouping">
                            <div class="clr-select-wrapper">
                                <input *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0" class="clr-checkbox" type="checkbox" placeholder="" name="TransformInput{{i}}" [(ngModel)]="editConfig.ConfigQuery.UseTransformInputParameter" />

                                <select class="clr-select" style="width:13em;" *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0 && !editConfig.ConfigQuery.UseTransformInputParameter" id="xaxisgrouptransform" name="xaxisgrouptransform" [(ngModel)]="editConfig.ConfigQuery.GroupingTransform">
                  <option [ngValue]="Transformation.NONE"> None </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
                  <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
                  <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
                  <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
                  <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
                  <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
                  </option>
                  <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
                  </option>
                  <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
                  <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
                  <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
                  <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
                </select>

                                <select *ngIf="editConfig.ConfigQuery.UseGrouping && i == 0 && editConfig.ConfigQuery.UseTransformInputParameter" class="clr-select" style="width:13em;" name="inputtransformparam" [(ngModel)]="editConfig.ConfigQuery.TransformInputParameterId">
                  <option
                    *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
                    [ngValue]="input.id">
                    {{input.id}}</option>
                </select>

                                <input *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0" class="clr-checkbox" type="checkbox" placeholder="" name="AggregationInput{{i}}" [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter" />

                                <select *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0 && !editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter" class="clr-select" style="width:13em;" name="tselectqueryData.columnagg{{i}}" [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Transform">
                  <option [ngValue]="Aggregation.NONE"> None </option>
                  <option [ngValue]="Aggregation.COUNT"> Count </option>
                  <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

                  <option [ngValue]="Aggregation.SUM"> Sum </option>

                  <option [ngValue]="Aggregation.MAX"> Max </option>
                  <option [ngValue]="Aggregation.MIN"> Min </option>

                  <option [ngValue]="Aggregation.FIRST"> First </option>
                  <option [ngValue]="Aggregation.LAST"> Last </option>

                  <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

                  <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
                  <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
                  <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

                  <option [ngValue]="Aggregation.MEDIAN"> Median </option>

                  <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
                  <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
                  <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
                  </option>

                  <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                    arithmetic mean </option>
                  <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                    mean </option>
                  <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                    mean </option>
                  <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
                </select>

                                <select *ngIf="editConfig.ConfigQuery.UseGrouping && i > 0 && editConfig.ConfigQuery.ColumnConfigs[i].UseAggregationInputParameter" class="clr-select" style="width:13em;" name="inputparam" [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].AggregationInputParameterId">
                  <option
                    *ngFor="let input of editConfig.InputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                    [ngValue]="input.id">
                    {{input.id}}</option>
                </select>
                            </div>
                        </clr-dg-cell>

                        <clr-dg-cell>
                            <input class="clr-input" placeholder="" name="tlabel{{i}}" [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Label" />
                        </clr-dg-cell>

                        <clr-dg-cell>
                            <input class="clr-input" placeholder="" name="unit{{i}}" [(ngModel)]="editConfig.ConfigQuery.ColumnConfigs[i].Unit" />
                        </clr-dg-cell>

                        <clr-dg-cell>
                            <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
                            <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow" [style.cursor]="'pointer'" style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
                        </clr-dg-cell>
                    </clr-dg-row>
                </clr-datagrid>


                <clr-dropdown [clrCloseMenuOnItemClick]="true">
                    <button clrDropdownTrigger aria-label="More actions">
            <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
            <!-- <clr-icon shape="caret down"></clr-icon> -->
          </button>
                    <clr-dropdown-menu *clrIfOpen>
                        <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addAllColumns()">Add all columns</div>
                        <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="removeAllColumns()">Remove all columns
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>

                <button type="button" class="btn btn-link" (click)="addColumn()">
          <clr-icon shape="plus-circle"></clr-icon>
          Add column
        </button>
                <button *ngIf="selectedColumnConfig" type="button" class="btn btn-link" (click)="removeSelectedColumn()">
          <clr-icon shape="minus-circle"></clr-icon>
          Remove selected column
        </button>
            </form>

            <h5>Filters</h5>
            <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="projection?.projectionid" [columnDescriptions]="columns" [group]="editConfig.ConfigQuery.Query.targetgroup" [datafilters]="editConfig.ConfigQuery.DataFilters" [filterLogicalGroupType]="editConfig.ConfigQuery.FilterLogicalGroupType"
                [inputParameters]="editConfig.InputParameters" [widgets]="widgets">
            </xproj-projection-filter>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
    <clr-accordion-panel>
        <clr-accordion-title>Layout</clr-accordion-title>
        <clr-accordion-content>
            <form clrForm>
                <clr-select-container>
                    <label>Layout type</label>
                    <select clrSelect id="layouttype" name="layouttype" [(ngModel)]="editConfig.Layout">
            <option [ngValue]="ContainerLayout.Cards"> Cards </option>
            <option [ngValue]="ContainerLayout.Tabs"> Tabs </option>
            <option [ngValue]="ContainerLayout.Accordion"> Accordion </option>
            <option [ngValue]="ContainerLayout.Carousel"> Carousel </option>
          </select>
                </clr-select-container>
                <clr-input-container *ngIf="editConfig.Layout == ContainerLayout.Cards || editConfig.Layout == ContainerLayout.Carousel">
                    <label>Columns</label>
                    <input clrInput placeholder="" type="number" name="layoutcolumns" min="1" max="12" [(ngModel)]="editConfig.LayoutColumns" />
                </clr-input-container>
                <clr-select-container *ngIf="editConfig.Layout == ContainerLayout.Tabs">
                    <label>Tabs Layout</label>
                    <select clrSelect id="tasbslayout" name="tasbslayout" [(ngModel)]="editConfig.TabsLayout">
            <option [ngValue]="ContainerTabsLayout.Horizontal"> Horizontal </option>
            <option [ngValue]="ContainerTabsLayout.Vertical"> Vertical </option>
          </select>
                </clr-select-container>
                <clr-select-container *ngIf="editConfig.Layout == ContainerLayout.Tabs || editConfig.Layout == ContainerLayout.Accordion">
                    <label>Tab name column</label>
                    <select clrSelect id="tabnamecolumn" name="tabnamecolumn" [(ngModel)]="editConfig.TabsNameColumn">
            <option [ngValue]=""> </option>
            <option *ngFor="let col of editConfig.ConfigQuery.ColumnConfigs" [ngValue]="col.ColumnName">
              {{col.ColumnName}}</option>
          </select>
                </clr-select-container>
            </form>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>

<h4>Widgets</h4>
<form clrForm>
    <clr-select-container>
        <label>Widget type</label>
        <select clrSelect id="widgettype" name="widgettype" [(ngModel)]="widgetTypeId">
      <option [ngValue]="'label'"> Label widget </option>
      <option [ngValue]="'text'"> Text widget </option>
      <option [ngValue]="'chart'"> Chart widget </option>
      <option [ngValue]="'table'"> Table widget </option>
      <option [ngValue]="'map'"> Map widget </option>
      <option [ngValue]="'piechart'"> Piechart widget </option>
      <option [ngValue]="'spectrum-analyzer'"> Spectrum Analyzer widget </option>
      <option [ngValue]="'spc'"> Spc widget </option>
      <option [ngValue]="'projection-dataeditor'"> Projection Dataeditor widget </option>
    </select>
    </clr-select-container>
    <button type="button" class="btn btn-link" (click)="addWidget()">
    <clr-icon shape="plus-circle"></clr-icon>
    Add widget
  </button>

</form>
<clr-accordion>
    <clr-accordion-panel *ngFor="let widget of editConfig.ContainerWidgets; index as i">
        <clr-accordion-title>{{widget.WidgetConfig.Title?.length > 0 ? widget.WidgetConfig.Title : widget.WidgetConfig.Id}}
        </clr-accordion-title>
        <clr-accordion-content>
            <xproj-text-widget-config *ngIf="widget.TypeId == 'text'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-text-widget-config>
            <xproj-label-widget-config *ngIf="widget.TypeId == 'label'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-label-widget-config>
            <xproj-chart-widget-config *ngIf="widget.TypeId == 'chart'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-chart-widget-config>
            <xproj-table-widget-config *ngIf="widget.TypeId == 'table'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-table-widget-config>
            <xproj-map-widget-config *ngIf="widget.TypeId == 'map'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-map-widget-config>
            <xproj-piechart-widget-config *ngIf="widget.TypeId == 'piechart'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-piechart-widget-config>
            <xproj-spectrum-analyzer-widget-config *ngIf="widget.TypeId == 'spectrum-analyzer'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-spectrum-analyzer-widget-config>
            <xproj-spc-widget-config *ngIf="widget.TypeId == 'spc'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-spc-widget-config>
            <xproj-svg-widget-config *ngIf="widget.TypeId == 'svg'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-svg-widget-config>
            <xproj-projection-dataeditor-widget-config *ngIf="widget.TypeId == 'projection-dataeditor'" id="WidgetConfig_{{i}}" [config]="widget.WidgetConfig" [widgets]="[editConfig]" [gridSettings]="gridSettings" [dashboardConfig]="dashboardConfig">
            </xproj-projection-dataeditor-widget-config>

            <button class="btn" (click)="saveWidget(widget)">Save</button>
            <button class="btn btn-danger-outline" (click)="removeWidget(i)">Remove widget</button>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>