/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.xconf.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import * as googleProtobuf001 from './google/protobuf/empty.pb';
import * as xprojectorGrpcModels002 from './xprojector.grpc.models.pb';
import { GRPC_XCONF_SERVICE_CLIENT_SETTINGS } from './xprojector.xconf.pbconf';
/**
 * Service client implementation for xconf.XConfService
 */
@Injectable({ providedIn: 'root' })
export class XConfServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /xconf.XConfService/GetNodeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GrpcNodeType>>
     */
    getNodeType: (
      requestData: thisProto.GetNodeTypeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GrpcNodeType>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetNodeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetNodeTypeRequest,
        responseClass: thisProto.GrpcNodeType
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetNodeTypes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetNodeTypesResponse>>
     */
    getNodeTypes: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetNodeTypesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetNodeTypes',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetNodeTypesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/UpsertNodeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    upsertNodeType: (
      requestData: thisProto.GrpcNodeType,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/UpsertNodeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.GrpcNodeType,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteNodeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteNodeType: (
      requestData: thisProto.DeleteNodeTypeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteNodeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteNodeTypeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetEdgeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GrpcEdgeType>>
     */
    getEdgeType: (
      requestData: thisProto.GetEdgeTypeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GrpcEdgeType>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetEdgeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEdgeTypeRequest,
        responseClass: thisProto.GrpcEdgeType
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetEdgeTypes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetEdgeTypesResponse>>
     */
    getEdgeTypes: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetEdgeTypesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetEdgeTypes',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetEdgeTypesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/UpsertEdgeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    upsertEdgeType: (
      requestData: thisProto.GrpcEdgeType,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/UpsertEdgeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.GrpcEdgeType,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteEdgeType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteEdgeType: (
      requestData: thisProto.DeleteEdgeTypeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteEdgeType',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteEdgeTypeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceDefinition
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GrpcDataSourceDefinition>>
     */
    getDataSourceDefinition: (
      requestData: thisProto.GetDataSourceDefinitionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GrpcDataSourceDefinition>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceDefinition',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceDefinitionRequest,
        responseClass: thisProto.GrpcDataSourceDefinition
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceDefinitions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDataSourceDefinitionsResponse>>
     */
    getDataSourceDefinitions: (
      requestData: googleProtobuf001.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDataSourceDefinitionsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceDefinitions',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf001.Empty,
        responseClass: thisProto.GetDataSourceDefinitionsResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/UpsertDataSourceDefinition
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    upsertDataSourceDefinition: (
      requestData: thisProto.GrpcDataSourceDefinition,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/UpsertDataSourceDefinition',
        requestData,
        requestMetadata,
        requestClass: thisProto.GrpcDataSourceDefinition,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteDataSourceDefinition
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteDataSourceDefinition: (
      requestData: thisProto.DeleteDataSourceDefinitionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteDataSourceDefinition',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteDataSourceDefinitionRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceInstance
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GrpcDataSourceInstance>>
     */
    getDataSourceInstance: (
      requestData: thisProto.GetDataSourceInstanceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GrpcDataSourceInstance>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceInstance',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceInstanceRequest,
        responseClass: thisProto.GrpcDataSourceInstance
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceInstances
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDataSourceInstancesResponse>>
     */
    getDataSourceInstances: (
      requestData: thisProto.GetDataSourceInstancesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDataSourceInstancesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceInstances',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceInstancesRequest,
        responseClass: thisProto.GetDataSourceInstancesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/UpsertDataSourceInstance
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    upsertDataSourceInstance: (
      requestData: thisProto.GrpcDataSourceInstance,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/UpsertDataSourceInstance',
        requestData,
        requestMetadata,
        requestClass: thisProto.GrpcDataSourceInstance,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteDataSourceInstance
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteDataSourceInstance: (
      requestData: thisProto.DeleteDataSourceInstanceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteDataSourceInstance',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteDataSourceInstanceRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceInstanceRootNodes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDataSourceInstanceRootNodesResponse>>
     */
    getDataSourceInstanceRootNodes: (
      requestData: thisProto.GetDataSourceInstanceRootNodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetDataSourceInstanceRootNodesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceInstanceRootNodes',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceInstanceRootNodesRequest,
        responseClass: thisProto.GetDataSourceInstanceRootNodesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/AddDataSourceInstanceRootNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    addDataSourceInstanceRootNode: (
      requestData: thisProto.AddDataSourceInstanceRootNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/AddDataSourceInstanceRootNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDataSourceInstanceRootNodeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceInstanceChildren
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDataSourceInstanceChildrenResponse>>
     */
    getDataSourceInstanceChildren: (
      requestData: thisProto.GetDataSourceInstanceChildrenRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetDataSourceInstanceChildrenResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceInstanceChildren',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceInstanceChildrenRequest,
        responseClass: thisProto.GetDataSourceInstanceChildrenResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetDataSourceInstanceTree
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDataSourceInstanceTreeResponse>>
     */
    getDataSourceInstanceTree: (
      requestData: thisProto.GetDataSourceInstanceTreeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDataSourceInstanceTreeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetDataSourceInstanceTree',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDataSourceInstanceTreeRequest,
        responseClass: thisProto.GetDataSourceInstanceTreeResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.GrpcNode>>
     */
    getNode: (
      requestData: thisProto.GetNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.GrpcNode>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetNodeRequest,
        responseClass: xprojectorGrpcModels002.GrpcNode
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetReferencedNodes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetReferencedNodesResponse>>
     */
    getReferencedNodes: (
      requestData: thisProto.GetReferencedNodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetReferencedNodesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetReferencedNodes',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetReferencedNodesRequest,
        responseClass: thisProto.GetReferencedNodesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetRefereringNodes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetReferingNodesResponse>>
     */
    getRefereringNodes: (
      requestData: thisProto.GetReferingNodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetReferingNodesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetRefereringNodes',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetReferingNodesRequest,
        responseClass: thisProto.GetReferingNodesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/CreateNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateNodeResponse>>
     */
    createNode: (
      requestData: xprojectorGrpcModels002.GrpcNode,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateNodeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/CreateNode',
        requestData,
        requestMetadata,
        requestClass: xprojectorGrpcModels002.GrpcNode,
        responseClass: thisProto.CreateNodeResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/CreateNodev2
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateNodeResponse>>
     */
    createNodev2: (
      requestData: thisProto.CreateNodev2Request,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateNodeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/CreateNodev2',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateNodev2Request,
        responseClass: thisProto.CreateNodeResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/CreateReferencedNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateReferencedNodeResponse>>
     */
    createReferencedNode: (
      requestData: thisProto.CreateReferencedNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateReferencedNodeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/CreateReferencedNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateReferencedNodeRequest,
        responseClass: thisProto.CreateReferencedNodeResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/UpdateNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    updateNode: (
      requestData: thisProto.UpdateNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/UpdateNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateNodeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteNode: (
      requestData: thisProto.DeleteNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteNodeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/CreateReference
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    createReference: (
      requestData: thisProto.CreateReferenceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/CreateReference',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateReferenceRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/DeleteReference
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    deleteReference: (
      requestData: thisProto.DeleteReferenceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/DeleteReference',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteReferenceRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/MoveNode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>>
     */
    moveNode: (
      requestData: thisProto.MoveNodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<xprojectorGrpcModels002.BasicResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/MoveNode',
        requestData,
        requestMetadata,
        requestClass: thisProto.MoveNodeRequest,
        responseClass: xprojectorGrpcModels002.BasicResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/SearchNodes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SearchNodesResponse>>
     */
    searchNodes: (
      requestData: thisProto.SearchNodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SearchNodesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/SearchNodes',
        requestData,
        requestMetadata,
        requestClass: thisProto.SearchNodesRequest,
        responseClass: thisProto.SearchNodesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetTree
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetTreeResponse>>
     */
    getTree: (
      requestData: thisProto.GetTreeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetTreeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetTree',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetTreeRequest,
        responseClass: thisProto.GetTreeResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetShortestPath
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetShortestPathResponse>>
     */
    getShortestPath: (
      requestData: thisProto.GetShortestPathRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetShortestPathResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetShortestPath',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetShortestPathRequest,
        responseClass: thisProto.GetShortestPathResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/ReadoutMomentaryValues
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReadoutValuesResponse>>
     */
    readoutMomentaryValues: (
      requestData: thisProto.ReadoutMomentaryValuesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReadoutValuesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/ReadoutMomentaryValues',
        requestData,
        requestMetadata,
        requestClass: thisProto.ReadoutMomentaryValuesRequest,
        responseClass: thisProto.ReadoutValuesResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetNodeCommands
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetNodeCommandsResponse>>
     */
    getNodeCommands: (
      requestData: thisProto.GetNodeCommandsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetNodeCommandsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetNodeCommands',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetNodeCommandsRequest,
        responseClass: thisProto.GetNodeCommandsResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/ExecuteNodeCommand
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ExecuteNodeCommandResponse>>
     */
    executeNodeCommand: (
      requestData: thisProto.ExecuteNodeCommandRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ExecuteNodeCommandResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/ExecuteNodeCommand',
        requestData,
        requestMetadata,
        requestClass: thisProto.ExecuteNodeCommandRequest,
        responseClass: thisProto.ExecuteNodeCommandResponse
      });
    },
    /**
     * Unary call: /xconf.XConfService/GetNodeLogs
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetNodeLogsResponse>>
     */
    getNodeLogs: (
      requestData: thisProto.GetNodeLogsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetNodeLogsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/xconf.XConfService/GetNodeLogs',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetNodeLogsRequest,
        responseClass: thisProto.GetNodeLogsResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_XCONF_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('xconf.XConfService', settings);
  }

  /**
   * Unary call @/xconf.XConfService/GetNodeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GrpcNodeType>
   */
  getNodeType(
    requestData: thisProto.GetNodeTypeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GrpcNodeType> {
    return this.$raw
      .getNodeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetNodeTypes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetNodeTypesResponse>
   */
  getNodeTypes(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetNodeTypesResponse> {
    return this.$raw
      .getNodeTypes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/UpsertNodeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  upsertNodeType(
    requestData: thisProto.GrpcNodeType,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .upsertNodeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteNodeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteNodeType(
    requestData: thisProto.DeleteNodeTypeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteNodeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetEdgeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GrpcEdgeType>
   */
  getEdgeType(
    requestData: thisProto.GetEdgeTypeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GrpcEdgeType> {
    return this.$raw
      .getEdgeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetEdgeTypes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetEdgeTypesResponse>
   */
  getEdgeTypes(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetEdgeTypesResponse> {
    return this.$raw
      .getEdgeTypes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/UpsertEdgeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  upsertEdgeType(
    requestData: thisProto.GrpcEdgeType,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .upsertEdgeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteEdgeType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteEdgeType(
    requestData: thisProto.DeleteEdgeTypeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteEdgeType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceDefinition
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GrpcDataSourceDefinition>
   */
  getDataSourceDefinition(
    requestData: thisProto.GetDataSourceDefinitionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GrpcDataSourceDefinition> {
    return this.$raw
      .getDataSourceDefinition(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceDefinitions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDataSourceDefinitionsResponse>
   */
  getDataSourceDefinitions(
    requestData: googleProtobuf001.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDataSourceDefinitionsResponse> {
    return this.$raw
      .getDataSourceDefinitions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/UpsertDataSourceDefinition
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  upsertDataSourceDefinition(
    requestData: thisProto.GrpcDataSourceDefinition,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .upsertDataSourceDefinition(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteDataSourceDefinition
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteDataSourceDefinition(
    requestData: thisProto.DeleteDataSourceDefinitionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteDataSourceDefinition(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceInstance
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GrpcDataSourceInstance>
   */
  getDataSourceInstance(
    requestData: thisProto.GetDataSourceInstanceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GrpcDataSourceInstance> {
    return this.$raw
      .getDataSourceInstance(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceInstances
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDataSourceInstancesResponse>
   */
  getDataSourceInstances(
    requestData: thisProto.GetDataSourceInstancesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDataSourceInstancesResponse> {
    return this.$raw
      .getDataSourceInstances(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/UpsertDataSourceInstance
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  upsertDataSourceInstance(
    requestData: thisProto.GrpcDataSourceInstance,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .upsertDataSourceInstance(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteDataSourceInstance
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteDataSourceInstance(
    requestData: thisProto.DeleteDataSourceInstanceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteDataSourceInstance(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceInstanceRootNodes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDataSourceInstanceRootNodesResponse>
   */
  getDataSourceInstanceRootNodes(
    requestData: thisProto.GetDataSourceInstanceRootNodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDataSourceInstanceRootNodesResponse> {
    return this.$raw
      .getDataSourceInstanceRootNodes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/AddDataSourceInstanceRootNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  addDataSourceInstanceRootNode(
    requestData: thisProto.AddDataSourceInstanceRootNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .addDataSourceInstanceRootNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceInstanceChildren
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDataSourceInstanceChildrenResponse>
   */
  getDataSourceInstanceChildren(
    requestData: thisProto.GetDataSourceInstanceChildrenRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDataSourceInstanceChildrenResponse> {
    return this.$raw
      .getDataSourceInstanceChildren(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetDataSourceInstanceTree
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDataSourceInstanceTreeResponse>
   */
  getDataSourceInstanceTree(
    requestData: thisProto.GetDataSourceInstanceTreeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDataSourceInstanceTreeResponse> {
    return this.$raw
      .getDataSourceInstanceTree(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.GrpcNode>
   */
  getNode(
    requestData: thisProto.GetNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.GrpcNode> {
    return this.$raw
      .getNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetReferencedNodes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetReferencedNodesResponse>
   */
  getReferencedNodes(
    requestData: thisProto.GetReferencedNodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetReferencedNodesResponse> {
    return this.$raw
      .getReferencedNodes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetRefereringNodes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetReferingNodesResponse>
   */
  getRefereringNodes(
    requestData: thisProto.GetReferingNodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetReferingNodesResponse> {
    return this.$raw
      .getRefereringNodes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/CreateNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateNodeResponse>
   */
  createNode(
    requestData: xprojectorGrpcModels002.GrpcNode,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateNodeResponse> {
    return this.$raw
      .createNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/CreateNodev2
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateNodeResponse>
   */
  createNodev2(
    requestData: thisProto.CreateNodev2Request,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateNodeResponse> {
    return this.$raw
      .createNodev2(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/CreateReferencedNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateReferencedNodeResponse>
   */
  createReferencedNode(
    requestData: thisProto.CreateReferencedNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateReferencedNodeResponse> {
    return this.$raw
      .createReferencedNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/UpdateNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  updateNode(
    requestData: thisProto.UpdateNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .updateNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteNode(
    requestData: thisProto.DeleteNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/CreateReference
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  createReference(
    requestData: thisProto.CreateReferenceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .createReference(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/DeleteReference
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  deleteReference(
    requestData: thisProto.DeleteReferenceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .deleteReference(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/MoveNode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<xprojectorGrpcModels002.BasicResponse>
   */
  moveNode(
    requestData: thisProto.MoveNodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<xprojectorGrpcModels002.BasicResponse> {
    return this.$raw
      .moveNode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/SearchNodes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SearchNodesResponse>
   */
  searchNodes(
    requestData: thisProto.SearchNodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SearchNodesResponse> {
    return this.$raw
      .searchNodes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetTree
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetTreeResponse>
   */
  getTree(
    requestData: thisProto.GetTreeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetTreeResponse> {
    return this.$raw
      .getTree(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetShortestPath
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetShortestPathResponse>
   */
  getShortestPath(
    requestData: thisProto.GetShortestPathRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetShortestPathResponse> {
    return this.$raw
      .getShortestPath(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/ReadoutMomentaryValues
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReadoutValuesResponse>
   */
  readoutMomentaryValues(
    requestData: thisProto.ReadoutMomentaryValuesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReadoutValuesResponse> {
    return this.$raw
      .readoutMomentaryValues(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetNodeCommands
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetNodeCommandsResponse>
   */
  getNodeCommands(
    requestData: thisProto.GetNodeCommandsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetNodeCommandsResponse> {
    return this.$raw
      .getNodeCommands(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/ExecuteNodeCommand
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ExecuteNodeCommandResponse>
   */
  executeNodeCommand(
    requestData: thisProto.ExecuteNodeCommandRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ExecuteNodeCommandResponse> {
    return this.$raw
      .executeNodeCommand(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/xconf.XConfService/GetNodeLogs
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetNodeLogsResponse>
   */
  getNodeLogs(
    requestData: thisProto.GetNodeLogsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetNodeLogsResponse> {
    return this.$raw
      .getNodeLogs(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
