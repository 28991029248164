import { XprojLoggerService } from "./xproj-logger-service";

export class XprojConsoleLoggerService implements XprojLoggerService {

  trace(message: any, ...additional: any[]): void {
    console.trace(message, additional);
  }

  debug(message: any, ...additional: any[]): void{
    console.debug(message, additional);
  }

  info(message: any, ...additional: any[]): void {
    console.info(message, additional);
  }

  log(message: any, ...additional: any[]): void {
    console.log(message, additional);
  }

  warn(message: any, ...additional: any[]): void {
    console.warn(message, additional);
  }

  error(message: any, ...additional: any[]): void {
    console.error(message, additional);
  }

  fatal(message: any, ...additional: any[]): void {
    console.error(message, additional);
  }

}
