import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xdb-internal-acl',
  templateUrl: './xdb-internal-acl.component.html',
  styleUrls: ['./xdb-internal-acl.component.css']
})
export class XdbInternalAclComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
