import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectBackendSettings, XPROJECTORBACKENDCLIENTSETTINGS } from '@xprojectorcore/xprojector_backend/xprojector-backend-settings-service';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { RossakerBmsCustomer } from '../models/rossaker-bms-customer';
import { RossakerBmsDataCollectorView } from '../models/rossaker-bms-data-collector-view';
import { RossakerBmsTrustee } from '../models/rossaker-bms-trustee';
import { RossakerBmsAdminService } from './rossaker-bms-admin-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RossakerStateService implements OnDestroy {

  customerId: string = '';
  trusteeId: string = '';

  private customerSubject: BehaviorSubject<{ customer: Customer, bmsCustomer: RossakerBmsCustomer }>;
  public customer$: Observable<{ customer: Customer, bmsCustomer: RossakerBmsCustomer }>;

  private trusteeSubject: BehaviorSubject<{ trustee: RossakerBmsTrustee, bmsCustomer: RossakerBmsCustomer }>;
  public trustee$: Observable<{ trustee: RossakerBmsTrustee, bmsCustomer: RossakerBmsCustomer }>;


  customers: Customer[] = [];
  trustees: RossakerBmsTrustee[] = [];
  bmsCustomers: RossakerBmsCustomer[] = [];

  private dataCollectorViews: RossakerBmsDataCollectorView[] = [];

  public constructor(
    private logger: NGXLogger,
    private sysAdminClient: XProjectorSysAdminClient,
    @Inject(XPROJECTORBACKENDCLIENTSETTINGS) private settings: XProjectBackendSettings,
    private state: StateService,
    private adminService: RossakerBmsAdminService) {

      this.state.currentLogLevel = (!environment.production && !environment.logoff) ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF;
      this.state.currentLogLevelServer = NgxLoggerLevel.OFF;

      let lastCustomerId = localStorage.getItem("xprojector-rossaker-customers-admin-lastcustomerid");
      if (lastCustomerId) {
        this.logger.info('last customerId', lastCustomerId);
        this.customerId = lastCustomerId;
      }

      let lastTrusteeId = localStorage.getItem("xprojector-rossaker-customers-admin-lasttrusteeid");
      if (lastTrusteeId) {
        this.logger.info('last trusteeId', lastTrusteeId);
        this.trusteeId = lastTrusteeId;
      }

      this.customerSubject = new BehaviorSubject<{ customer: Customer, bmsCustomer: RossakerBmsCustomer }>({ customer: null, bmsCustomer: null });
      this.customer$ = this.customerSubject.asObservable();

      this.trusteeSubject = new BehaviorSubject<{ trustee: RossakerBmsTrustee, bmsCustomer: RossakerBmsCustomer }>({ trustee: null, bmsCustomer: null });
      this.trustee$ = this.trusteeSubject.asObservable();

      this.updateCustomers();
  }

  ngOnDestroy(): void {

  }

  async updateCustomers() {
    if (!this.customers?.length) {
      let customers = await this.sysAdminClient.getCustomers();
      this.bmsCustomers = await this.adminService.getCustomers();

      this.customers = customers.filter(x => {
        let bmsCustomer = this.bmsCustomers.find(c => c.customerId == x.id);
        return bmsCustomer && !bmsCustomer.customerIsTrustee;
      });

      let trustees = customers.filter(x => {
        let bmsCustomer = this.bmsCustomers.find(c => c.customerId == x.id);
        return bmsCustomer && bmsCustomer.customerIsTrustee;
      });

      this.trustees = [];
      trustees.forEach(x => {
        let bmsCustomer = this.bmsCustomers.find(c => c.customerId == x.id);

        let trustee = new RossakerBmsTrustee();
        trustee.id = x.id;
        trustee.name = x.name;
        trustee.billingEnabled = bmsCustomer.billingEnabled;
        trustee.enabled = !bmsCustomer.disabled;
        if (trustee.enabled) {
          this.trustees.push(trustee);
        }
      });

      if (this.customers) {
        let customer = this.customers.find(c => c.id == this.customerId);
        let bmsCustomer = this.bmsCustomers.find(c => c.customerId == this.customerId);

        this.customerSubject.next({ customer: customer, bmsCustomer: bmsCustomer });
      }

      if (this.trustees) {
        let trustee = this.trustees.find(c => c.id == this.trusteeId);
        let bmsCustomer = this.bmsCustomers.find(c => c.customerId == this.customerId);

        this.trusteeSubject.next({ trustee: trustee, bmsCustomer: bmsCustomer });
      }

    }
  }

  public get customerValue(): { customer: Customer, bmsCustomer: RossakerBmsCustomer } {
    return this.customerSubject.value;
  }

  public get trsuteeValue(): { trustee: RossakerBmsTrustee, bmsCustomer: RossakerBmsCustomer } {
    return this.trusteeSubject.value;
  }

  async setCustomer(customerId: string) {
    if (!this.customers?.length) {
      await this.updateCustomers();
    }
    this.customerId = customerId;
    let customer = this.customers.find(c => c.id == customerId);
    let bmsCustomer = this.bmsCustomers.find(c => c.customerId == customerId);
    this.customerSubject.next({ customer: customer, bmsCustomer: bmsCustomer });

    if (customer) {
      localStorage.setItem("xprojector-rossaker-customers-admin-lastcustomerid", customer.id);
    }

  }

  async setTrustee(trusteeId: string) {
    if (!this.trustees?.length) {
      await this.updateCustomers();
    }
    this.trusteeId = trusteeId;
    let trustee = this.trustees.find(c => c.id == trusteeId);
    let bmsCustomer = this.bmsCustomers.find(c => c.customerId == trusteeId);
    this.trusteeSubject.next({ trustee: trustee, bmsCustomer: bmsCustomer });

    if (trustee) {
      localStorage.setItem("xprojector-rossaker-customers-admin-lasttrusteeid", trustee.id);
    }

  }

  addDataCollectorView(page: RossakerBmsDataCollectorView) {
    if (this.dataCollectorViews.findIndex(p => p.id == page.id)) {
      this.dataCollectorViews.push(page);
    }
  }

  getDataCollectoViews(): RossakerBmsDataCollectorView[] {
    return this.dataCollectorViews;
  }

  async geteExportWebportTagList(realestateId: string): Promise<string> {
    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }
    let url = this.settings.host + '/api/v1/webport/taglist/' + realestateId;
    return this.state.http.get<string>(url, httpOptions).toPromise();
  }

  async importDataFile(file: any, customerId: string): Promise<number> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
      formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<number>(this.settings.host + '/api/v1/bms/import/datafile', formData, httpOptions).toPromise();
  }

  async importFile(file: any, importId : string, importTypeId : string, nodeId :string, nodeLabel : string, customerId : string): Promise<any> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    formData.append('importid', importId);
    formData.append('importtypeid', importTypeId);
    formData.append('nodeid', nodeId);
    formData.append('nodelabel', nodeLabel);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<any>(this.settings.host + '/api/v1/bms/import/modulefile', formData, httpOptions).toPromise();
  }

  //TODO
  async importMasterDataFiles(overwrite: boolean, files: any[]): Promise<any> {
    let formData = new FormData();
    formData.append('overwrite', overwrite ? 'true' : 'false');
    try {
      files.forEach(file => {
        formData.append('file', file, 'dummy');
      });

    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<any>(this.settings.host + '/api/v1/bms/import/masterdatafile', formData, httpOptions).toPromise();
  }
}
