<div class="background">
  <div class="card centered" *ngIf="ticketInfo && ticketInfo.ok" >
    <div i18n='@@createcredentials_newusertitle' class="card-header" *ngIf="action == 'newuser'">
      Submit your name and password.
    </div>
    <div i18n='@@createcredentials_resetusertitle' class="card-header" *ngIf="action == 'resetuser'">
      Reset password.
    </div>
    <div class="card-block">
      <div class="card-text">
        <form clrForm (ngSubmit)="submit()">

            <clr-input-container *ngIf="action == 'newuser'">
              <label class="clr-sr-only">Firstname</label>
              <input i18n-placeholder='@@createcredentials_firstname' type="text" class="clr-col-12" name="firstname" clrInput placeholder="Firstname" [(ngModel)]="firstname" />
            </clr-input-container>
            <clr-input-container *ngIf="action == 'newuser'">
              <label class="clr-sr-only">Surname</label>
              <input i18n-placeholder='@@createcredentials_surname' type="text" class="clr-col-12" name="surname" clrInput placeholder="Surname" [(ngModel)]="surname" />
            </clr-input-container>
            <clr-password-container>
              <label class="clr-sr-only">New password</label>
              <input i18n-placeholder='@@createcredentials_createnewpassword' type="password" class="clr-col-12" name="password" clrPassword placeholder="Create new password"
                [(ngModel)]="password" />
            </clr-password-container>
            <clr-password-container>
              <label class="clr-sr-only">Confirm password</label>
              <input i18n-placeholder='@@createcredentials_confirmnewpassword' type="password" class="clr-col-12" name="password2" clrPassword placeholder="Confirm new password"
                [(ngModel)]="password2" />
            </clr-password-container>

            <div class="error" [class.active]="errorsubmit">
              {{errormessage}}
            </div>
        </form>
      </div>
    </div>
    <div class="card-footer">
      <button *ngIf="action == 'newuser'" i18n='@@createcredentials_submit' type="submit" style="width: 100%;" class="btn btn-primary" (click)="submit()">Submit</button>
      <button *ngIf="action == 'resetuser'" i18n='@@createcredentials_reset' type="submit" style="width: 100%;" class="btn btn-primary" (click)="submit()">Reset password</button>
    </div>
  </div>

  <div class="card centered" *ngIf="ticketInfo && ticketInfo.expired" >
    <div i18n='@@createcredentials_expiredticket_header' class="card-header">
      Expired ticket.
    </div>
    <div class="card-block">
      <div i18n='@@createcredentials_expiredticket_body' class="card-text">
        The provided ticket has expired.
      </div>
    </div>
    <div class="card-footer">
      <a i18n='@@resetpassword_goback' [routerLink]="['/login']" routerLinkActive="active" class="signup">Cancel & Go back</a>
    </div>
  </div>

  <div class="card centered" *ngIf="ticketInfo && ticketInfo.hasBeenUsed" >
    <div i18n='@@createcredentials_usedticket_header' class="card-header">
      Used ticket.
    </div>
    <div class="card-block">
      <div i18n='@@createcredentials_usedticket_body' class="card-text">
        The provided ticket has already been used.
      </div>
    </div>
    <div class="card-footer">
      <a i18n='@@resetpassword_goback' [routerLink]="['/login']" routerLinkActive="active" class="signup">Cancel & Go back</a>
    </div>
  </div>
</div>
