import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { MultiseriesLuaQueryColumn, TableButtonConfig, TableWidgetConfig, TableWidgetQuery } from './table-widget-config-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widget-config-service';
import { Subscription } from 'rxjs';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Aggregation, LuaQueryColumn, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { XprojTableWidgetQueryConfigComponent } from '../table-widget-query-config/xproj-table-widget-query-config.component';
import { WidgetConfigBase } from '../../widget-config-base';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xproj-table-widget-config',
  templateUrl: './xproj-table-widget-config.component.html',
  styleUrls: ['./xproj-table-widget-config.component.scss']
})
export class XprojTableWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  @ViewChildren(XprojTableWidgetQueryConfigComponent, { read: XprojTableWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojTableWidgetQueryConfigComponent>;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;

  widgetConfig: TableWidgetConfig;

  projections: Projection[] = [];

  rowsPerPageOptionsString : string;

  editorOptions = {
    theme: environment.editortheme,
    language: 'lua',
    lineNumbers: "off",
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap:{enabled: false}};

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
    this.updateInputParameters();
  }

  async ngOnInit() {
    this.widgetConfig = this.config as TableWidgetConfig;

    this.rowsPerPageOptionsString = this.widgetConfig.RowsPerPageOptions.join(',');

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.widgetConfig.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
    while (this.widgetConfig.MultiseriesConfig.JoinableColumns.length < this.widgetConfig.ConfigQueries.length) {
      this.widgetConfig.MultiseriesConfig.JoinableColumns.push('');
    }
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  addConfigQuery(): void {
    this.widgetConfig.ConfigQueries.push(new TableWidgetQuery());
    this.widgetConfig.MultiseriesConfig.JoinableColumns.push('');
  }

  onQueryRemoved(query: TableWidgetQuery) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => q == query);
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
    ArrayUtils.RemoveItemAt(this.widgetConfig.MultiseriesConfig.JoinableColumns, i);
  }

  addButtonConfig() {
    this.widgetConfig.ButtonConfigs.push(new TableButtonConfig());
  }

  removeButtonConfig(buttonConfig : TableButtonConfig) {
    this.widgetConfig.ButtonConfigs = this.widgetConfig.ButtonConfigs.filter(b => b != buttonConfig);
  }

  addScriptedColumnsPostAggregation() {
    this.widgetConfig.MultiseriesConfig.ScriptedColumnsPostJoin.push(new MultiseriesLuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.widgetConfig.MultiseriesConfig.ScriptedColumnsPostJoin = this.widgetConfig.MultiseriesConfig.ScriptedColumnsPostJoin.filter(q => q.Column != queryColumn);
  }


  async onSaveConfig() {
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    this.widgetConfig.OutputParameters = [];
    this.widgetConfig.ConfigQueries.forEach((configQuery) => {
      configQuery.ColumnConfigs.forEach((col) => {
        if (col.Clickable) {
          let output = new WidgetOutputParameter();
          output.id = col.Id;
          if (col.ClickableOutputColumnId?.length > 0) {
            let colOutputConfig = configQuery.ColumnConfigs.find(c => c.Id == col.ClickableOutputColumnId);
            output.datatype = (colOutputConfig.Transform != Aggregation.NONE
                && colOutputConfig.Transform != Aggregation.MIN
                && colOutputConfig.Transform != Aggregation.MAX
                && colOutputConfig.Transform != Aggregation.FIRST
                && colOutputConfig.Transform != Aggregation.LAST) ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[colOutputConfig.Datatype]);
            output.name = (col.Label?.length > 0 ? col.Label : col.ColumnName) + ' (' + (colOutputConfig.Label?.length > 0 ? colOutputConfig.Label : colOutputConfig.ColumnName) + ')';
          }
          else {
            output.datatype = col.Transform != Aggregation.NONE ? OutputDataType.Number : Object.values(OutputDataType).indexOf(XDataType[col.Datatype]);
            output.name = col.Label?.length > 0 ? col.Label : col.ColumnName;
          }


          this.widgetConfig.OutputParameters.push(output);
        }
      });
    });

    try {
      this.widgetConfig.RowsPerPageOptions = [];
      this.rowsPerPageOptionsString.split(',').forEach(s => this.widgetConfig.RowsPerPageOptions.push(+s));
    }
    catch {
      this.widgetConfig.RowsPerPageOptions = [10,20,50,100];
    }
  }

  async onWidgetConfigSaved($event) {
    this.updateInputParameters();
  }

  async updateInputParameters() {
    let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.updateInputParameters(inputParameters);
    });
  }

}
