import { DashboardClient } from './proto/xprojector.dashboard.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { Empty } from '@ngx-grpc/well-known-types';
import { DeleteDashboardRequest, LoadDashboardRequest, DashboardConfig as Dashboard, DashboardTree, LoadDashboardTreeRequest, DeleteDashboardTreeRequest, LoadDashboardsRequest, UpdateDashboardIdRequest } from './proto/xprojector.dashboard.pb';
import { DashboardConfig } from 'xproj-lib';
import { TypedJSON } from 'typedjson';
import { Treeview } from '@xprojectorcore/models/treeview';


@Injectable({
  providedIn: 'root'
})
export class XProjectorDashboardClient implements OnInit, OnDestroy {

  constructor(
    private dashboardClient: DashboardClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async getDashboard(dashboardId: string, systemDashboard: boolean = false): Promise<DashboardConfig> {
    let request: LoadDashboardRequest = new LoadDashboardRequest();
    request.id = dashboardId;
    request.systemDashboard = systemDashboard;
    let result = await this.dashboardClient.loadDashboard(request, this.state.metadata).toPromise();
    return TypedJSON.parse(result.json, DashboardConfig);
  }

  async loadDashboard(dashboardId: string, version: number = -1, tag : string = '', systemDashboard: boolean = false): Promise<DashboardConfig> {
    let request: LoadDashboardRequest = new LoadDashboardRequest();
    request.id = dashboardId;
    request.systemDashboard = systemDashboard;
    request.version = version;
    request.tag = tag;
    let result = await this.dashboardClient.loadDashboardXConf(request, this.state.metadata).toPromise();
    let config = TypedJSON.parse(result.json, DashboardConfig);
    config.Version = result.version;
    config.Description = result.description;
    config.Tag = result.tag;

    return config;
  }

  async saveDashboard(dashboard: DashboardConfig): Promise<boolean> {
    let request: Dashboard = new Dashboard();
    request.id = dashboard.Id;
    request.json = TypedJSON.stringify(dashboard, DashboardConfig);
    request.description = dashboard.Description;
    request.tag = dashboard.Tag;
    request.version = dashboard.Version;
    //let result = await this.dashboardClient.saveDashboard(request, this.state.metadata).toPromise();
    let result = await this.dashboardClient.saveDashboardXConf(request, this.state.metadata).toPromise();
    return result.result;
  }

  async updateDashboardId(oldId: string, newId : string): Promise<boolean> {
    let request: UpdateDashboardIdRequest = new UpdateDashboardIdRequest();
    request.oldId = oldId;
    request.newId = newId;

    let result = await this.dashboardClient.updateDashboardIdXConf(request, this.state.metadata).toPromise();
    return result.result;
  }

  async deleteDashboardOld(dashboardId: string): Promise<boolean> {
    let request: DeleteDashboardRequest = new DeleteDashboardRequest();
    request.id = dashboardId;
    let result = await this.dashboardClient.deleteDashboard(request, this.state.metadata).toPromise();
    return result.result;
  }

  async deleteDashboard(dashboardId: string, version: number): Promise<boolean> {
    let request: DeleteDashboardRequest = new DeleteDashboardRequest();
    request.id = dashboardId;
    request.version = version;
    let result = await this.dashboardClient.deleteDashboardXConf(request, this.state.metadata).toPromise();
    return result.result;
  }

  async getDashboardTree(dashboardTreeId: string): Promise<Treeview> {
    let request: LoadDashboardTreeRequest = new LoadDashboardTreeRequest();
    request.id = dashboardTreeId;
    let result = await this.dashboardClient.loadDashboardTree(request, this.state.metadata).toPromise();
    return TypedJSON.parse(result.json, Treeview);
  }

  async getDashboardTrees(): Promise<Treeview[]> {
    let result = await this.dashboardClient.loadDashboardTrees(new Empty(), this.state.metadata).toPromise();
    let treeviews: Treeview[] = [];
    result.trees.forEach((tree) => {
      treeviews.push(TypedJSON.parse(tree.json, Treeview));
    });

    return treeviews;
  }

  async saveDashboardTree(treeview: Treeview): Promise<boolean> {
    let request: DashboardTree = new DashboardTree();
    request.id = treeview.id;
    request.json = TypedJSON.stringify(treeview, Treeview);

    let result = await this.dashboardClient.saveDashboardTree(request, this.state.metadata).toPromise();
    return result.result;
  }

  async deleteDashboardTree(treeviewdId: string): Promise<boolean> {
    let request: DeleteDashboardTreeRequest = new DeleteDashboardTreeRequest();
    request.id = treeviewdId;
    let result = await this.dashboardClient.deleteDashboardTree(request, this.state.metadata).toPromise();
    return result.result;
  }

  async loadAllDashboardVersions(dashboardId: string, systemDashboard: boolean = false): Promise<DashboardConfig[]> {
    let request: LoadDashboardsRequest = new LoadDashboardsRequest();
    request.id = dashboardId;
    request.systemDashboard = systemDashboard;

    let dashboards = await this.dashboardClient.loadDashboardsXConf(request, this.state.metadata).toPromise();

    let result: DashboardConfig[] = [];

    dashboards.dashboardConfigs.forEach(d => {
      if (d.json.length > 0) {
        let config = TypedJSON.parse(d.json, DashboardConfig);
        config.Version = d.version;
        config.Description = d.description;
        config.Tag = d.tag;
        result.push(config);
      }

    });

    return result;
  }
}
