import { TypedJSON, jsonObject, jsonMember } from 'typedjson';
import { OutputDataType } from '../widgets/widget-config-service';

@jsonObject
export class DashboardOutputChangeParameters {

	@jsonMember
  public outputParameterName : string;

  @jsonMember
  public value : any

  @jsonMember
  public datatype : OutputDataType;

  public Clone() : DashboardOutputChangeParameters {
		return TypedJSON.parse(TypedJSON.stringify(this, DashboardOutputChangeParameters), DashboardOutputChangeParameters);
	}
}
