

<div class="content-area">

    <!-- <div style="background-image: url(https://rossaker.com/user/images/g5_helium/nikola-johnny-mirkovic-HNsytirZYQg-unsplash.jpg); height: 1020px; background-size: cover; resize: both;"> -->


        <div class="splash-container">
            <div class="splash-inner">
                <h1>Systems</h1>
                Explore our software systems that may be deployed anywhere <br>
                – in Cloud, on-premises environments or on constrained devices.                 
            </div>
        </div>

    <nav class="subnav">
        <ul class="nav">
            <li class="nav-item">
                <a [routerLink]="[{ outlets: {systemsoutlet: ['big-data']} }]" routerLinkActive="active" class="nav-link nav-text boldtext"> Big Data </a>                
            </li>
            <li class="nav-item">                
                <a [routerLink]="[{ outlets: {systemsoutlet: ['automation']} }]" routerLinkActive="active" 
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve' }" class="nav-link nav-text boldtext"> Automation </a>
            </li>
            <li class="nav-item">
                <a [routerLink]="[{ outlets: {systemsoutlet: ['communications']} }]" routerLinkActive="active" 
                [routerLinkActiveOptions]="{ exact: true, queryParams: 'preserve' }" class="nav-link nav-text boldtext"> Communications </a>
            </li>
        </ul>
    </nav> 



    <!-- <div class="content">
        <div>
            
        </div>   

        <div>
            

        </div>   
        <div>
            
        </div>   
        <div>
            <a id="Optimizations"><h2>Optimizations</h2></a>
        </div>   
    </div> -->
    <div class="content">
        <router-outlet name="systemsoutlet"></router-outlet>
    </div>

</div>