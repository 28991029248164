import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DashboardConfig, GridSettings } from '../dashboard/xproj-dashboard-service';
import { WidgetUtils } from '../utils/widget-utils-service';
import { ColumnFilteringRelativeTimestamp, XProjectorClient } from '../XProjector/xprojector-client-service';
import { WidgetConfig } from './widget-config-service';
import { takeUntil } from 'rxjs/operators';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from './xproj-widget-service';
import { GridsterItemComponentInterface } from 'angular-gridster2';

@Component({
  template: ''
})
export class WidgetConfigBase implements OnInit, OnDestroy, AfterViewInit {

  ngUnsubscribe = new Subject<void>();

  @Input() dashboardConfig : DashboardConfig;
  @Input() config: WidgetConfig;
  @Input() widgets : WidgetConfig[];
  @Input() gridSettings: GridSettings;

	@Output() onConfigChanged = new EventEmitter<WidgetConfig>();

  constructor(
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {

    this.widgetService.saveConfigSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ widgetId, callback }) => {
      if (widgetId == this.config?.Id) {
        await this.onSaveConfig();
        callback(this.config);
        this.onConfigChanged?.emit(this.config);
      }
    });
  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async onSaveConfig() {

  }
}
