import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_MbusType, XAUTO_XAutoDriver,    XAUTO_XAutoDriverMbus, XAUTO_XAutoVariable,  XAUTO_XAutoVariableMbus, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public Mbus : XAUTO_XAutoVariableMbus;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public Mbus : XAUTO_XAutoDriverMbus;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}

@Component({
  selector: 'xproj-editconnectors-mbus',
  templateUrl: './editconnectors-mbus.component.html',
  styleUrls: ['./editconnectors-mbus.component.scss']
})
export class EditconnectorsMbusComponent {
  XAUTO_MbusType = XAUTO_MbusType;

 
  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
  public loadingConnectors = false;  
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;
 
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;
  public selectedVariable : ViewVariable = null;
  

  constructor(private xprojClient: XProjectorClient) {
  }


  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {    
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {  
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.Mbus = new XAUTO_XAutoVariableMbus();
    this.selectedVariable.Mbus.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.Mbus.xautovariableid = this.selectedVariable.Variable.xautovariableid;
    // this.selectedVariable.Mbus.continuousramp = false;
    // this.selectedVariable.Mbus.controllerid = 0;
    // this.selectedVariable.Mbus.statusbyte = 176;
    // this.selectedVariable.Mbus.mirrorvaluetocontroller = false;


    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  async saveVariable()
  {
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable mbus");
      await this.xprojClient.XAUTO_SaveVariableMbus( this.selectedVariable.Mbus );
      console.log("save variable mbus done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;    
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;

  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;    

    try
    {
      console.log("Saving driver", this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      console.log("Saving mbus driver", this.selectedConnector.Mbus);
      await this.xprojClient.XAUTO_SaveDriverMbus(this.selectedConnector.Mbus);
      console.log("Saving done");
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();        
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New Mbus";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.MIDI;
    this.selectedConnector.Mbus = new XAUTO_XAutoDriverMbus();    
    this.selectedConnector.Mbus.tcpport = 10001;
    this.selectedConnector.Mbus.tcphost = "";
    this.selectedConnector.Mbus.serialdevice = "";
    this.selectedConnector.Mbus.type = XAUTO_MbusType.TCPIP_MASTER;
    this.selectedConnector.Mbus.serialbaud = 2400;
    this.selectedConnector.Mbus.xautodriverid = this.selectedConnector.Driver.xautodriverid;

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {    
    console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,1000, driver.Driver.xautodriverid);
    for( let j = 0; j < variables.length; j++)
    {
      let variable = variables[j];
      console.log("loading variable", variable.xautovariableid);
      let mbusvar = await this.xprojClient.XAUTO_GetVariableMbus(variable.xautovariableid);

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.Mbus = mbusvar;
      driver.Variables.push(newViewVar);
    }
    console.log("loading variables done");
  }

  async loadDrivers()
  {
    let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);    
    for(let i = 0; i < drivers.length; i++)
    {    
      let driver = drivers[i];
      if(driver.driver != XAUTO_DriverType.MIDI)
        continue;

      let mbusdriver = await this.xprojClient.XAUTO_GetDriverMbus(driver.xautodriverid);
      let viewdriver = new ViewDriver();
      viewdriver.Driver = driver;
      viewdriver.Mbus = mbusdriver;
      await this.loadVariables(viewdriver);
      this.connectors.push(viewdriver);
    }
  }

  async ngOnInit() {
    this.loadDrivers();
  }


}
