import { Pipe, PipeTransform } from '@angular/core';
import { BimBuildingaddress } from '../models/bim-buildingaddress';

@Pipe({
  name: 'filter_buildingaddress'
})
export class FilterBuildingAddressPipe implements PipeTransform {

  transform(buildingaddresses: BimBuildingaddress[], buildingaddress : BimBuildingaddress,  args?: any): any {
    return buildingaddresses.filter(x => x.id != buildingaddress.id);
  }

}
