import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { Projection, XProjectorClient } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputProjectionControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-output-projection-controller',
  templateUrl: './xproj-output-projection-controller.component.html',
  styleUrls: ['./xproj-output-projection-controller.component.scss']
})
export class XprojOutputProjectionControllerComponent extends XprojOutputControllerBase implements OnInit {

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  projectionConfig: XprojOutputProjectionControllerConfig;

  selectedProjection: Projection;
  selectedGroup: string[] = [];

  projections: Projection[] = [];

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService,
    public xprojClient: XProjectorClient
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.projectionConfig = this.config as XprojOutputProjectionControllerConfig;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    this.selectedProjection = this.projections.find(p => p.projectionid == this.projectionConfig.DefaultProjectionId);
    this.selectedGroup = this.projectionConfig.DefaultGroup;
  }

  ngAfterViewInit(): void {
    if (!this.useApplyButton) {
      setTimeout(() => {
        this.sendDefaultValues();
      }, 2000);
    }
  }

  selectedProjectionChange() {
    if (!this.useApplyButton) {
      if (this.selectedProjection) {
        let output = new WidgetOutputChangeParameters();
        output.widgetId = this.widgetConfig.Id;
        output.outputParameterId = this.projectionConfig.Id;
        output.datatype = OutputDataType.Projection;
        output.value = this.selectedProjection.projectionid;

        this.widgetService.outputParameterChanged(output);
      }
    }
  }

  groupSelected(group: string[]) {
    this.selectedGroup = group;

    if (!this.useApplyButton) {
      if (group && group.length > 0) {
        let output = new WidgetOutputChangeParameters();
        output.widgetId = this.widgetConfig.Id;
        output.outputParameterId = this.projectionConfig.Id + '_group';
        output.datatype = OutputDataType.Group;
        output.value = group.join('|');

        this.widgetService.outputParameterChanged(output);
      }
    }
  }

  sendDefaultValues() {
    if (this.projectionConfig.DefaultProjectionId) {
      let outputProjection = new WidgetOutputChangeParameters();
      let outputGroup;
      outputProjection.widgetId = this.widgetConfig.Id;
      outputProjection.outputParameterId = this.projectionConfig.Id;
      outputProjection.datatype = OutputDataType.Projection;
      outputProjection.value = this.selectedProjection.projectionid;

      if (this.projectionConfig.DefaultGroup && this.projectionConfig.DefaultGroup.length > 0) {
        outputGroup = new WidgetOutputChangeParameters();
        outputGroup.widgetId = this.widgetConfig.Id;
        outputGroup.outputParameterId = this.projectionConfig.Id + '_group';
        outputGroup.datatype = OutputDataType.Group;
        outputGroup.value = this.projectionConfig.DefaultGroup.join('|');
      }

      let outputs = [];
      outputs.push(outputProjection);
      if (outputGroup) {
        outputs.push(outputGroup);
      }

      this.widgetService.outputParametersChanged(outputs);
    }
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    let outputs = [];
    if (this.selectedProjection) {
      let output = new WidgetOutputChangeParameters();
      output.widgetId = this.widgetConfig.Id;
      output.outputParameterId = this.projectionConfig.Id;
      output.datatype = OutputDataType.Projection;
      output.value = this.selectedProjection.projectionid;

      outputs.push(output);
    }

    if (this.selectedGroup && this.selectedGroup.length > 0) {
      let output = new WidgetOutputChangeParameters();
      output.widgetId = this.widgetConfig.Id;
      output.outputParameterId = this.projectionConfig.Id + '_group';
      output.datatype = OutputDataType.Group;
      output.value = this.selectedGroup.join('|');

      outputs.push(output);
    }

    return outputs;
  }

}
