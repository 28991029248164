import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { BaseQueryConfigInterface, GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Aggregation, BaseQuery, ColumnSortingDescription, DownSampleQuery, FilterLogicalGroupType, LuaQueryColumn, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { ChartWidgetConfig, ChartWidgetQuery } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { XprojChartWidgetComponent } from '../../chart/chart-widget/xproj-chart-widget.component';
import { Guid } from '../../../utils/guid-service';
import { EditMode, EnumMember } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';

@jsonObject
export class TableColorThreshold {
  @jsonMember
  public MinValue : number;

  @jsonMember
  public MaxValue : number;

  @jsonMember
  public Color : string;
}

@jsonObject
export class ColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Hidden : boolean = false;

  @jsonMember
  public HideIfEmpty : boolean = false;

  @jsonMember
  public IsSensitive : boolean = false;

  @jsonMember
  public FilterEnabled : boolean = true;

  @jsonMember
  public SortEnabled : boolean = true;

  @jsonMember
  public SortStringAsNumber : boolean = false;

  @jsonMember
  public Clickable : boolean = false;

  @jsonMember
  public MonospacedFont : boolean = false;

  @jsonMember
  public ClickableOutputColumnId : string = '';

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
	public ColumnTransformation : Transformation = Transformation.NONE;

  @jsonMember
  public EditMode : EditMode = EditMode.String;

  @jsonArrayMember(EnumMember)
  public EnumMembers : EnumMember[] = [];

  @jsonArrayMember(String)
  public Tags : string[] = [];

  @jsonArrayMember(TableColorThreshold)
  public ColorThresholds : TableColorThreshold[] = [];

  @jsonMember
  public Url : boolean = false;

  @jsonMember
  public DateFormat: string = 'yyyy-MM-dd HH:mm';

  @jsonMember
  public Excluded : boolean = false;

}

@jsonObject
export class MultiseriesLuaQueryColumn
{
  @jsonMember
  public Column : LuaQueryColumn = new LuaQueryColumn();

  @jsonMember
  public Unit : string = '';
}

@jsonObject
export class MultiseriesQueryConfig
{
  @jsonMember
  public Enabled : boolean = false;

  @jsonMember
  public Sorting : ColumnSortingDescription = new ColumnSortingDescription();

  @jsonArrayMember(String)
  public JoinableColumns : string[] = [];

  @jsonArrayMember(MultiseriesLuaQueryColumn)
  public ScriptedColumnsPostJoin : MultiseriesLuaQueryColumn[] = [];

  // @jsonArrayMember(LuaQueryColumn)
  // public ScriptedColumnsPostJoin : LuaQueryColumn[] = [];

  // @jsonArrayMember(String)
  // public ScriptedColumnUnits : string[] = [];
}

@jsonObject
export class TableButtonConfig {
  @jsonMember
  public Name : string;

  @jsonMember
  public LuaScript : string;
}

@jsonObject
export class TableWidgetQuery implements BaseQueryConfigInterface {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(ColumnConfig)
  public ColumnConfigs : ColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];
}


@jsonObject
export class TableWidgetConfig extends WidgetConfig{

  @jsonArrayMember(TableWidgetQuery)
  public ConfigQueries : TableWidgetQuery[] = [];

  @jsonMember
  public MultiseriesConfig : MultiseriesQueryConfig = new MultiseriesQueryConfig();

  @jsonArrayMember(TableButtonConfig)
  public ButtonConfigs : TableButtonConfig[] = [];

  @jsonMember
  public AutoClickFirstRow: boolean = false;

  @jsonArrayMember(Number)
  public RowsPerPageOptions: number[] = [10, 20, 50, 100];

  @jsonMember
  public DefaultRowsPerPage: number = 20;

  @jsonMember
  public Compact: boolean = true;

  @jsonMember
  public EmptyTableText: string = 'No data';

  @jsonMember
  public ClientPagination: boolean = false;

  @jsonMember
  public ClientPaginationMaxItems: number = 10000;

  //public constructor();
  public constructor(chartConfig? : ChartWidgetConfig) {
    super();

    if (chartConfig) {
      chartConfig.ConfigQueries.forEach(cq => {
        let tableWidgetQuery = TypedJSON.parse(TypedJSON.stringify(cq, ChartWidgetQuery), TableWidgetQuery);

        tableWidgetQuery.ProjectionId = cq.Query.targetprojectionid;
        cq.Query.targetgroup.forEach(g => {
          tableWidgetQuery.Group.push(g);
        });

        let colConfig = new ColumnConfig();
        colConfig.ColumnName = 'x';
        colConfig.Label = cq.Xaxis

        tableWidgetQuery.ColumnConfigs.push(colConfig);

        cq.YAxesConfigs.forEach(yconfig => {
          let colConfig = new ColumnConfig();
          colConfig.ColumnName = yconfig.ColumnOutName;
          colConfig.Label = yconfig.Label;
          colConfig.Unit = yconfig.Unit;

          tableWidgetQuery.ColumnConfigs.push(colConfig);
        });

        tableWidgetQuery.Query.maxitems = tableWidgetQuery.MaxItems;

        this.ConfigQueries.push(tableWidgetQuery);
      });
    }
  }

  public Clone() : TableWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, TableWidgetConfig), TableWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = false;
}
