import { Pipe, PipeTransform } from '@angular/core';
import { ProjectionDataEditorWidgetConfig } from '../widgets/projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { XDataType, ProjectionColumnDescription } from '../XProjector/xprojector-client-service';

@Pipe({
  name: 'xproj_filtertimestampcolumns'
})
export class XprojFiltertimestampcolumnsPipe implements PipeTransform {

  transform(items:  Array<ProjectionColumnDescription>): Array<ProjectionColumnDescription> {
    let rVal = new Array<ProjectionColumnDescription>()
    for(let item of items)
    {
      switch(item.datatype)
      {
        case XDataType.Timestamp:
          rVal.push(item);
          break;

        default:
          break;
      }
    }
    return rVal;
  }

}
