import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationWorkspaceComponent } from './components/configuration-workspace/configuration-workspace.component';
import { EditEdgeTypesComponent } from './components/edit-edge-types/edit-edge-types.component';
import { EditNodeTypesComponent } from './components/edit-node-types/edit-node-types.component';
import { EditDataSourceDefinitionsComponent } from './components/edit-datasource-definitions/edit-datasource-definitions.component';
import { EditDataSourceInstancesComponent } from './components/edit-datasource-instances/edit-datasource-instances.component';
import { FileLibraryComponent } from './components/image-library/file-library.component';
import { ViewDataSourceInstanceComponent } from './components/view-datasource-instance/view-datasource-instance.component';
import { LogConfigComponent } from './components/log-config/log-config.component';

const routes: Routes = [
  {
    path: '', component: ConfigurationWorkspaceComponent,

    children: [
      {
        path: 'editnodetypes',
        component: EditNodeTypesComponent
      },
      {
        path: 'editedgetypes',
        component: EditEdgeTypesComponent
      },
      {
        path: 'editdatasourcedefinitions',
        component: EditDataSourceDefinitionsComponent
      },
      {
        path: 'editdatasourceinstances',
        component: EditDataSourceInstancesComponent
      },
      {
        path: 'viewdatasource/:id',
        component: ViewDataSourceInstanceComponent
      },
      {
        path: 'filelibrary',
        component: FileLibraryComponent
      },
      {
        path: 'logconfig',
        component: LogConfigComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class XconfRoutingModule { }
