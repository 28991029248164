import { TypedJSON, jsonObject, jsonMember } from 'typedjson';
import { OutputDataType } from '../widgets/widget-config-service';
import { DashboardOutputChangeParameters } from './dashboard-output-change-parameters';

@jsonObject
export abstract class ScriptedParameters
{
  //@jsonArrayMember(DashboardOutputChangeParameters)
  public Parameter: DashboardOutputChangeParameters[] = [];

  @jsonMember
  public Name : string  = "";

  @jsonMember
  public Description : string = "";

  @jsonMember
  public LuaScript : string = "";

  public GetString(name: string) : string
  {
    for(let o of this.Parameter)
    {
      if(o.outputParameterName != name)
        continue;

      return o.value;
    }
    return "";
  }
  public GetNumber(name: string) : number
  {
    for(let o of this.Parameter)
    {
      if(o.outputParameterName != name)
        continue;

      return o.value;
    }
    return 0;
  }

  public SetString(name: string, value: string) : void
  {
    //console.log("ScriptedParameters setstring-  name:" + name + " value:" + value);
    if(!this.Parameter)
      this.Parameter = [];

    name = this.Name + " - " + name;

    for(let o of this.Parameter)
    {
      if(o.outputParameterName != name)
        continue;

      o.datatype = OutputDataType.String;
      o.value = value;
      return;
    }

    //console.log("pushing new parameter");
    let o = new DashboardOutputChangeParameters();
    o.datatype = OutputDataType.String;
    o.outputParameterName = name;
    o.value = value;
    this.Parameter.push(o);
  }
}
