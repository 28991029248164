<div class="projection-dataeditor-widget" tabindex="1" [style.height.px]="widgetheight" *ngIf="tableData.initilized">

  <clr-datagrid #grid [class]="widgetConfig?.Compact ? 'table-datagrid datagrid-compact' : 'table-datagrid'" [(clrDgSelected)]="selected" (clrDgRefresh)="load($event)" [clrDgLoading]="loading"
    (clrDgSelectedChange)="selectedChanged($event)">

    <clr-dg-action-bar *ngIf="widgetConfig.AddRemoveEnabled || widgetConfig.ImportEnabled">
      <div class="btn-group" *ngIf="widgetConfig.AddRemoveEnabled">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onAddData()">
          <clr-icon shape="plus"></clr-icon> Add data
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="onDuplicateSelected()"
          [disabled]="selected.length != 1">
          <clr-icon shape="copy"></clr-icon> Duplicate
        </button>
        <button type="button" class="btn btn-sm btn-danger-outline" (click)="onDeleteSelectedData()"
          [disabled]="selected.length == 0">
          <clr-icon shape="trash"></clr-icon> Delete ({{selected.length}})
        </button>
      </div>

      <div class="btn-group" *ngIf="groupFilterEnabled">
        <button type="button" class="btn btn-sm btn-secondary" (click)="showFilter = !showFilter">
          Group
          <clr-icon *ngIf="!showFilter" shape="caret down"></clr-icon>
          <clr-icon *ngIf="showFilter" shape="caret up"></clr-icon>
        </button>
      </div>

      <input *ngIf="widgetConfig.ImportEnabled" id="fileinput_{{widgetConfig?.Id}}" type="file" class="btn btn-sm btn-secondary"
        [multiple]="false" accept="Excel/*,.xlsx" (change)="onImportData($event)" hidden />
      <label *ngIf="widgetConfig.ImportEnabled" class="btn btn-sm btn-secondary" for="fileinput_{{widgetConfig?.Id}}">
        <clr-icon shape="import"></clr-icon> Import data
      </label>

      <div class="btn-group">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onRefresh()">
          <clr-icon shape="refresh"></clr-icon> Refresh
        </button>
      </div>

      <div class="btn-group" *ngIf="widgetConfig.SetColumnValueOnDelete" >
        <button *ngIf="!showDeleted"  type="button" class="btn btn-sm btn-secondary" (click)="showDeleted=true; onRefresh()">
          <clr-icon shape="eye"></clr-icon> Show deleted
        </button>
        <button *ngIf="showDeleted"  type="button" class="btn btn-sm btn-secondary" (click)="showDeleted = false; onRefresh()">
          <clr-icon shape="eye-hide"></clr-icon> Hide deleted
        </button>
      </div>

      <br *ngIf="showFilter">

      <xproj-group-selection *ngIf="showFilter" class="groupSelect" #groupSelect [projectionId]="widgetConfig.ConfigQuery.ProjectionId"
        [selected]="selectedGroup" [showHeader]="false" (groupSelected)="selectedProjectionGroupChange($event)" [fixedGroup]="fixedGroup">
      </xproj-group-selection>
    </clr-dg-action-bar>

    <clr-dg-column [clrDgField]="item.columnname" *ngFor="let item of tableData.columns">
      <ng-container *clrDgHideableColumn="{hidden: item.hidden}">
        {{item.label}}
      </ng-container>
      <clr-dg-filter *ngIf="item.datatype == 7">
        <xproj-datagrid-string-filter [group]="item.group" [projectionid]="item.projectionid"
          [columnname]="item.columnname" [startWithFilter]="false"></xproj-datagrid-string-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-row *ngFor="let obj of tableData.data_col0 ; index as i" [clrDgItem]="i+1"
      [class.row-deleted]="tableData.data_col_deleted != undefined ? tableData.data_col_deleted[i] : false">
      <clr-dg-cell *ngFor="let item of tableData.columns"> {{ item | projectiondataedit : i}}
      </clr-dg-cell>
    </clr-dg-row>

    <ng-template [(clrIfDetail)]="detailState" let-dataIndex (clrIfDetailChange)="onDetailChange($event)">
      <!-- <ng-template clrIfDetail let-dataIndex (clrIfDetailChange)="onDetailChange($event)"> -->
      <clr-dg-detail>
        <clr-dg-detail-header>Edit properties</clr-dg-detail-header>
        <clr-dg-detail-body>
          <form clrForm clrLayout="vertical">
            <ng-template ngFor let-column [ngForOf]="tableData.columns" let-i="index" trackby="indexTracker">
              <clr-input-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.String">
                <label class="clr-col-12">{{column.label}}</label>
                <input style="width: 90%;" class="clr-col-12" clrInput type="text" [(ngModel)]="selectedValues[i]"
                  name="value_{{i}}" [disabled]="!column.writeable || column.useFixedValue" />
              </clr-input-container>

              <clr-input-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.Number">
                <label class="clr-col-12">{{column.label}}</label>
                <input style="width: 90%;" class="clr-col-12" clrInput type="number" [(ngModel)]="selectedValues[i]"
                  name="value_{{i}}" [disabled]="!column.writeable || column.useFixedValue" />
              </clr-input-container>

              <clr-input-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.Timestamp">
                <label class="clr-col-12">{{column.label}}</label>
                <input style="width: 90%;" class="clr-col-12" clrInput type="datetime-local" step="1" [(ngModel)]="selectedValues[i]"
                  name="value_{{i}}" [disabled]="!column.writeable || column.useFixedValue" />
              </clr-input-container>

              <clr-select-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.XDatatype">
                <label class="clr-col-12">{{column.label}}</label>
                <select clrSelect [(ngModel)]="selectedValues[i]" class="clr-col-12" name="value_{{i}}"
                  [disabled]="!column.writeable || column.useFixedValue">
                  <option [ngValue]="XDataType.Float32">Float32</option>
                  <option [ngValue]="XDataType.Float64">Float64</option>
                  <option [ngValue]="XDataType.UInt8">UInt8</option>
                  <option [ngValue]="XDataType.Int32">Int32</option>
                  <option [ngValue]="XDataType.Int64">Int64</option>
                  <option [ngValue]="XDataType.Timestamp">Timestamp</option>
                  <option [ngValue]="XDataType.String">String</option>
                </select>
              </clr-select-container>

              <clr-select-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.Enum">
                <label class="clr-col-12">{{column.label}}</label>
                <select clrSelect [(ngModel)]="selectedValues[i]" class="clr-col-12" name="enumvalue_{{i}}"
                  [disabled]="!column.writeable || column.useFixedValue">
                  <option *ngFor="let enumMember of column.enumMembers" [ngValue]="enumMember.value">{{enumMember.name}}</option>
                </select>
              </clr-select-container>

              <div *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.Flags" style="margin-top: 1em;">
                <label class="clr-col-12">{{column.label}}</label>
                <div class="btn-group" class="clr-col-12">
                  <div class="checkbox btn btn-sm" *ngFor="let enumMember of column.enumMembers; let flagIndex = index" >
                    <input type="checkbox" id="btn-flag-{{enumMember.name}}_{{i}}" [checked]="selectedValues[i][flagIndex]" (click)="onFlagChange(i, flagIndex)"/>
                    <label for="btn-flag-{{enumMember.name}}_{{i}}">{{enumMember.name}}</label>
                  </div>
                </div>
              </div>


              <clr-checkbox-container *ngIf="(!column.hidden || column.writeable) && column.editMode == EditMode.Boolean">
                <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox class="clr-col-12" [(ngModel)]="selectedValues[i]"
                    name="value_{{i}}" [disabled]="!column.writeable || column.useFixedValue" />
                  <label class="clr-col-12">{{column.label}}</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>

            </ng-template>
          </form>
          <br>
          <button class="btn btn-primary" (click)="onSaveData(dataIndex-1)" [clrLoading]="savingProperties">Save</button>
          <button *ngIf="widgetConfig.AddRemoveEnabled" class="btn" (click)="onDuplicateData(dataIndex-1)"
            [clrLoading]="savingProperties">Duplicate</button>
          <button *ngIf="widgetConfig.AddRemoveEnabled && !selectedIsDeleted" class="btn btn-danger-outline"
            (click)="onDeleteData(dataIndex, true)" [clrLoading]="savingProperties">Delete</button>
          <button *ngIf="widgetConfig.AddRemoveEnabled && selectedIsDeleted" class="btn btn-danger-outline"
            (click)="onRestoreData(dataIndex)" [clrLoading]="savingProperties">Restore</button>

          <button class="btn" (click)="onCloseDetail()">Close</button>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>

    <clr-dg-footer>
      <clr-dg-pagination #pagination id="pagination" [clrDgPageSize]="pageSize">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions" size="xs">Rows per page</clr-dg-page-size>
        <div class="pagination-list" *ngIf="currentPage > 1 || queryNrRows >= pageSize">
          <button type="button" class="pagination-first" [disabled]="currentPage <= 1" (click)="stepFirst()">
            <cds-icon shape="step-forward-2" direction="down"></cds-icon>
          </button>
          <button type="button" class="pagination-previous" [disabled]="currentPage <= 1" (click)="stepPrevious()">
            <cds-icon shape="angle" direction="left"></cds-icon>
          </button>
          <input #currentPageInput type="text" class="pagination-current clr-input"
            [size]="currentPage.toString().length" [value]="currentPage" (keydown.enter)="updateCurrentPage($event)"
            (blur)="updateCurrentPage($event)" />
          <ng-template #readOnly>
            <span>{{ currentPage }}</span>
          </ng-template>

          <button type="button" class="pagination-next" [disabled]="currentPage > 1 && queryNrRows < pageSize"
            (click)="stepNext()">
            <cds-icon shape="angle" direction="right"></cds-icon>
          </button>
        </div>
      </clr-dg-pagination>
    </clr-dg-footer>


  </clr-datagrid>

</div>
