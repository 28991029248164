import { Inject, Injectable } from "@angular/core";
import { CACHESETTINGS, CacheSettings } from "./models/cache-settings";
import { CacheValue } from "./models/cache-value";
import { LifeSpan } from "./models/life-span";
import { ReturnType } from "./models/return-type";

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private readonly memoryStorage: Map<string, CacheValue>;
  protected readonly lifeSpan: LifeSpan;

  public constructor(
    @Inject(CACHESETTINGS) public settings : CacheSettings) {
      this.lifeSpan = settings.lifeSpan;
      this.memoryStorage = new Map<string, CacheValue>();
  }

  static normalizeKey(key: string | number): string {
    if (CacheService.validateKey(key)) {
      throw new Error('Please provide a valid key to save in the CacheService');
    }

    return `${key}`;
  }

  private static validateKey(key: string | number): boolean {
    return !key || typeof key === 'boolean' || Number.isNaN(key as number);
  }

  private static validateValue(value: CacheValue): boolean {
    return value.lifeSpan.expiry && value.lifeSpan.expiry > Date.now();
  }

  private parseLifeSpan(lifeSpan: LifeSpan): LifeSpan {
    return {
      expiry: lifeSpan.expiry || (lifeSpan.TTL ? Date.now() + lifeSpan.TTL * 1000 : this.lifeSpan.expiry),
      TTL: lifeSpan.TTL || this.lifeSpan.TTL
    };
  }

  get keys(): Array<string> {
    return Array.from(this.memoryStorage.keys());
  }

  has(key: string | number): boolean {
    const normalized = CacheService.normalizeKey(key);

    return this._get(normalized) != undefined;
  }

  set(key: string, value: any, returnType: ReturnType = ReturnType.Scalar, lifeSpan?: LifeSpan): boolean {
    let normalized = CacheService.normalizeKey(key);

    this.memoryStorage.set(normalized, {
      data: value,
      returnType,
      lifeSpan: this.parseLifeSpan(lifeSpan ? lifeSpan : this.lifeSpan)
    });

    return true;
  }

  get(key: string | number): any {
    let normalized = CacheService.normalizeKey(key);

    return this._get(normalized);
  }

  private _get(normalized: string ): any {
    let cached = this.memoryStorage.get(normalized);

    if (cached && Object.entries(cached).length !== 0 && cached.constructor === Object) {
      if (CacheService.validateValue(cached)) {
        return cached.data;
      }

      this._remove(normalized);
    }

    return undefined;
  }

  remove(key: string | number, wild = false): void {
    const normalized = CacheService.normalizeKey(key);

    this._remove(normalized, wild);
  }

  private _remove(normalized: string, wild = false): void {

    this.memoryStorage.delete(normalized);

    if (wild) {
      for (const item of this.keys) {
        if (item.indexOf(normalized) === 0) {
          this.memoryStorage.delete(item);
        }
      }
    }
  }

  clear(): void {
    this.memoryStorage.clear();
  }
}
