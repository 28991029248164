/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for xprojector.grpc.models.BasicResponse
 */
export class BasicResponse implements GrpcMessage {
  static id = 'xprojector.grpc.models.BasicResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BasicResponse();
    BasicResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BasicResponse) {
    _instance.result = _instance.result || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BasicResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.result = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BasicResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BasicResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.result) {
      _writer.writeBool(1, _instance.result);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _result?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BasicResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BasicResponse.AsObject>) {
    _value = _value || {};
    this.result = _value.result;
    this.message = _value.message;
    BasicResponse.refineValues(this);
  }
  get result(): boolean | undefined {
    return this._result;
  }
  set result(value: boolean | undefined) {
    this._result = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BasicResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BasicResponse.AsObject {
    return {
      result: this.result,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BasicResponse.AsProtobufJSON {
    return {
      result: this.result,
      message: this.message
    };
  }
}
export module BasicResponse {
  /**
   * Standard JavaScript object representation for BasicResponse
   */
  export interface AsObject {
    result?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for BasicResponse
   */
  export interface AsProtobufJSON {
    result?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.Customer
 */
export class Customer implements GrpcMessage {
  static id = 'xprojector.grpc.models.Customer';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Customer();
    Customer.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Customer) {
    _instance.id = _instance.id || '';
    _instance.description = _instance.description || '';
    _instance.name = _instance.name || '';
    _instance.username = _instance.username || '';
    _instance.externalId = _instance.externalId || '';
    _instance.isEnabled = _instance.isEnabled || false;
    _instance.lastModified = _instance.lastModified || undefined;
    _instance.defaultDashboardId = _instance.defaultDashboardId || '';
    _instance.sandboxEnabled = _instance.sandboxEnabled || false;
    _instance.information = _instance.information || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Customer,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        case 3:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.username = _reader.readString();
          break;
        case 5:
          _instance.externalId = _reader.readString();
          break;
        case 6:
          _instance.isEnabled = _reader.readBool();
          break;
        case 7:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.defaultDashboardId = _reader.readString();
          break;
        case 9:
          _instance.sandboxEnabled = _reader.readBool();
          break;
        case 10:
          _instance.information = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Customer.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Customer, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
    if (_instance.name) {
      _writer.writeString(3, _instance.name);
    }
    if (_instance.username) {
      _writer.writeString(4, _instance.username);
    }
    if (_instance.externalId) {
      _writer.writeString(5, _instance.externalId);
    }
    if (_instance.isEnabled) {
      _writer.writeBool(6, _instance.isEnabled);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        7,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.defaultDashboardId) {
      _writer.writeString(8, _instance.defaultDashboardId);
    }
    if (_instance.sandboxEnabled) {
      _writer.writeBool(9, _instance.sandboxEnabled);
    }
    if (_instance.information) {
      _writer.writeString(10, _instance.information);
    }
  }

  private _id?: string;
  private _description?: string;
  private _name?: string;
  private _username?: string;
  private _externalId?: string;
  private _isEnabled?: boolean;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _defaultDashboardId?: string;
  private _sandboxEnabled?: boolean;
  private _information?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Customer to deeply clone from
   */
  constructor(_value?: RecursivePartial<Customer.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.description = _value.description;
    this.name = _value.name;
    this.username = _value.username;
    this.externalId = _value.externalId;
    this.isEnabled = _value.isEnabled;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.defaultDashboardId = _value.defaultDashboardId;
    this.sandboxEnabled = _value.sandboxEnabled;
    this.information = _value.information;
    Customer.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get username(): string | undefined {
    return this._username;
  }
  set username(value: string | undefined) {
    this._username = value;
  }
  get externalId(): string | undefined {
    return this._externalId;
  }
  set externalId(value: string | undefined) {
    this._externalId = value;
  }
  get isEnabled(): boolean | undefined {
    return this._isEnabled;
  }
  set isEnabled(value: boolean | undefined) {
    this._isEnabled = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get defaultDashboardId(): string | undefined {
    return this._defaultDashboardId;
  }
  set defaultDashboardId(value: string | undefined) {
    this._defaultDashboardId = value;
  }
  get sandboxEnabled(): boolean | undefined {
    return this._sandboxEnabled;
  }
  set sandboxEnabled(value: boolean | undefined) {
    this._sandboxEnabled = value;
  }
  get information(): string | undefined {
    return this._information;
  }
  set information(value: string | undefined) {
    this._information = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Customer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Customer.AsObject {
    return {
      id: this.id,
      description: this.description,
      name: this.name,
      username: this.username,
      externalId: this.externalId,
      isEnabled: this.isEnabled,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      defaultDashboardId: this.defaultDashboardId,
      sandboxEnabled: this.sandboxEnabled,
      information: this.information
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Customer.AsProtobufJSON {
    return {
      id: this.id,
      description: this.description,
      name: this.name,
      username: this.username,
      externalId: this.externalId,
      isEnabled: this.isEnabled,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      defaultDashboardId: this.defaultDashboardId,
      sandboxEnabled: this.sandboxEnabled,
      information: this.information
    };
  }
}
export module Customer {
  /**
   * Standard JavaScript object representation for Customer
   */
  export interface AsObject {
    id?: string;
    description?: string;
    name?: string;
    username?: string;
    externalId?: string;
    isEnabled?: boolean;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    defaultDashboardId?: string;
    sandboxEnabled?: boolean;
    information?: string;
  }

  /**
   * Protobuf JSON representation for Customer
   */
  export interface AsProtobufJSON {
    id?: string;
    description?: string;
    name?: string;
    username?: string;
    externalId?: string;
    isEnabled?: boolean;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    defaultDashboardId?: string;
    sandboxEnabled?: boolean;
    information?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.User
 */
export class User implements GrpcMessage {
  static id = 'xprojector.grpc.models.User';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new User();
    User.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: User) {
    _instance.id = _instance.id || '';
    _instance.firstname = _instance.firstname || '';
    _instance.surname = _instance.surname || '';
    _instance.email = _instance.email || '';
    _instance.lastModified = _instance.lastModified || undefined;
    _instance.defaultDashboardId = _instance.defaultDashboardId || '';
    _instance.apiKey = _instance.apiKey || '';
    _instance.isAdmin = _instance.isAdmin || false;
    _instance.phone = _instance.phone || '';
    _instance.gdprConsent = _instance.gdprConsent || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: User, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.firstname = _reader.readString();
          break;
        case 3:
          _instance.surname = _reader.readString();
          break;
        case 4:
          _instance.email = _reader.readString();
          break;
        case 5:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.defaultDashboardId = _reader.readString();
          break;
        case 7:
          _instance.apiKey = _reader.readString();
          break;
        case 8:
          _instance.isAdmin = _reader.readBool();
          break;
        case 9:
          _instance.phone = _reader.readString();
          break;
        case 10:
          _instance.gdprConsent = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    User.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: User, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.firstname) {
      _writer.writeString(2, _instance.firstname);
    }
    if (_instance.surname) {
      _writer.writeString(3, _instance.surname);
    }
    if (_instance.email) {
      _writer.writeString(4, _instance.email);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        5,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.defaultDashboardId) {
      _writer.writeString(6, _instance.defaultDashboardId);
    }
    if (_instance.apiKey) {
      _writer.writeString(7, _instance.apiKey);
    }
    if (_instance.isAdmin) {
      _writer.writeBool(8, _instance.isAdmin);
    }
    if (_instance.phone) {
      _writer.writeString(9, _instance.phone);
    }
    if (_instance.gdprConsent) {
      _writer.writeBool(10, _instance.gdprConsent);
    }
  }

  private _id?: string;
  private _firstname?: string;
  private _surname?: string;
  private _email?: string;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _defaultDashboardId?: string;
  private _apiKey?: string;
  private _isAdmin?: boolean;
  private _phone?: string;
  private _gdprConsent?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of User to deeply clone from
   */
  constructor(_value?: RecursivePartial<User.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.firstname = _value.firstname;
    this.surname = _value.surname;
    this.email = _value.email;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.defaultDashboardId = _value.defaultDashboardId;
    this.apiKey = _value.apiKey;
    this.isAdmin = _value.isAdmin;
    this.phone = _value.phone;
    this.gdprConsent = _value.gdprConsent;
    User.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get firstname(): string | undefined {
    return this._firstname;
  }
  set firstname(value: string | undefined) {
    this._firstname = value;
  }
  get surname(): string | undefined {
    return this._surname;
  }
  set surname(value: string | undefined) {
    this._surname = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get defaultDashboardId(): string | undefined {
    return this._defaultDashboardId;
  }
  set defaultDashboardId(value: string | undefined) {
    this._defaultDashboardId = value;
  }
  get apiKey(): string | undefined {
    return this._apiKey;
  }
  set apiKey(value: string | undefined) {
    this._apiKey = value;
  }
  get isAdmin(): boolean | undefined {
    return this._isAdmin;
  }
  set isAdmin(value: boolean | undefined) {
    this._isAdmin = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }
  get gdprConsent(): boolean | undefined {
    return this._gdprConsent;
  }
  set gdprConsent(value: boolean | undefined) {
    this._gdprConsent = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    User.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): User.AsObject {
    return {
      id: this.id,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      defaultDashboardId: this.defaultDashboardId,
      apiKey: this.apiKey,
      isAdmin: this.isAdmin,
      phone: this.phone,
      gdprConsent: this.gdprConsent
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): User.AsProtobufJSON {
    return {
      id: this.id,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      defaultDashboardId: this.defaultDashboardId,
      apiKey: this.apiKey,
      isAdmin: this.isAdmin,
      phone: this.phone,
      gdprConsent: this.gdprConsent
    };
  }
}
export module User {
  /**
   * Standard JavaScript object representation for User
   */
  export interface AsObject {
    id?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    defaultDashboardId?: string;
    apiKey?: string;
    isAdmin?: boolean;
    phone?: string;
    gdprConsent?: boolean;
  }

  /**
   * Protobuf JSON representation for User
   */
  export interface AsProtobufJSON {
    id?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    defaultDashboardId?: string;
    apiKey?: string;
    isAdmin?: boolean;
    phone?: string;
    gdprConsent?: boolean;
  }
}

/**
 * Message implementation for xprojector.grpc.models.UserDevice
 */
export class UserDevice implements GrpcMessage {
  static id = 'xprojector.grpc.models.UserDevice';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserDevice();
    UserDevice.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserDevice) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.apiKey = _instance.apiKey || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserDevice,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.apiKey = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserDevice.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UserDevice, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.apiKey) {
      _writer.writeString(3, _instance.apiKey);
    }
  }

  private _id?: string;
  private _name?: string;
  private _apiKey?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserDevice to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserDevice.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.apiKey = _value.apiKey;
    UserDevice.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get apiKey(): string | undefined {
    return this._apiKey;
  }
  set apiKey(value: string | undefined) {
    this._apiKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserDevice.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserDevice.AsObject {
    return {
      id: this.id,
      name: this.name,
      apiKey: this.apiKey
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserDevice.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      apiKey: this.apiKey
    };
  }
}
export module UserDevice {
  /**
   * Standard JavaScript object representation for UserDevice
   */
  export interface AsObject {
    id?: string;
    name?: string;
    apiKey?: string;
  }

  /**
   * Protobuf JSON representation for UserDevice
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    apiKey?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.CustomerUser
 */
export class CustomerUser implements GrpcMessage {
  static id = 'xprojector.grpc.models.CustomerUser';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerUser();
    CustomerUser.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerUser) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
    _instance.firstname = _instance.firstname || '';
    _instance.surname = _instance.surname || '';
    _instance.email = _instance.email || '';
    _instance.isAdmin = _instance.isAdmin || false;
    _instance.lastModified = _instance.lastModified || undefined;
    _instance.defaultDashboardId = _instance.defaultDashboardId || '';
    _instance.apiKey = _instance.apiKey || '';
    _instance.phone = _instance.phone || '';
    _instance.gdprConsent = _instance.gdprConsent || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerUser,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.firstname = _reader.readString();
          break;
        case 4:
          _instance.surname = _reader.readString();
          break;
        case 5:
          _instance.email = _reader.readString();
          break;
        case 6:
          _instance.isAdmin = _reader.readBool();
          break;
        case 7:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.defaultDashboardId = _reader.readString();
          break;
        case 9:
          _instance.apiKey = _reader.readString();
          break;
        case 10:
          _instance.phone = _reader.readString();
          break;
        case 11:
          _instance.gdprConsent = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerUser.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerUser,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.firstname) {
      _writer.writeString(3, _instance.firstname);
    }
    if (_instance.surname) {
      _writer.writeString(4, _instance.surname);
    }
    if (_instance.email) {
      _writer.writeString(5, _instance.email);
    }
    if (_instance.isAdmin) {
      _writer.writeBool(6, _instance.isAdmin);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        7,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.defaultDashboardId) {
      _writer.writeString(8, _instance.defaultDashboardId);
    }
    if (_instance.apiKey) {
      _writer.writeString(9, _instance.apiKey);
    }
    if (_instance.phone) {
      _writer.writeString(10, _instance.phone);
    }
    if (_instance.gdprConsent) {
      _writer.writeBool(11, _instance.gdprConsent);
    }
  }

  private _id?: string;
  private _customerId?: string;
  private _firstname?: string;
  private _surname?: string;
  private _email?: string;
  private _isAdmin?: boolean;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _defaultDashboardId?: string;
  private _apiKey?: string;
  private _phone?: string;
  private _gdprConsent?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerUser to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerUser.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.firstname = _value.firstname;
    this.surname = _value.surname;
    this.email = _value.email;
    this.isAdmin = _value.isAdmin;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.defaultDashboardId = _value.defaultDashboardId;
    this.apiKey = _value.apiKey;
    this.phone = _value.phone;
    this.gdprConsent = _value.gdprConsent;
    CustomerUser.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get firstname(): string | undefined {
    return this._firstname;
  }
  set firstname(value: string | undefined) {
    this._firstname = value;
  }
  get surname(): string | undefined {
    return this._surname;
  }
  set surname(value: string | undefined) {
    this._surname = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get isAdmin(): boolean | undefined {
    return this._isAdmin;
  }
  set isAdmin(value: boolean | undefined) {
    this._isAdmin = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get defaultDashboardId(): string | undefined {
    return this._defaultDashboardId;
  }
  set defaultDashboardId(value: string | undefined) {
    this._defaultDashboardId = value;
  }
  get apiKey(): string | undefined {
    return this._apiKey;
  }
  set apiKey(value: string | undefined) {
    this._apiKey = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }
  get gdprConsent(): boolean | undefined {
    return this._gdprConsent;
  }
  set gdprConsent(value: boolean | undefined) {
    this._gdprConsent = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerUser.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      isAdmin: this.isAdmin,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      defaultDashboardId: this.defaultDashboardId,
      apiKey: this.apiKey,
      phone: this.phone,
      gdprConsent: this.gdprConsent
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerUser.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      isAdmin: this.isAdmin,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      defaultDashboardId: this.defaultDashboardId,
      apiKey: this.apiKey,
      phone: this.phone,
      gdprConsent: this.gdprConsent
    };
  }
}
export module CustomerUser {
  /**
   * Standard JavaScript object representation for CustomerUser
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    isAdmin?: boolean;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    defaultDashboardId?: string;
    apiKey?: string;
    phone?: string;
    gdprConsent?: boolean;
  }

  /**
   * Protobuf JSON representation for CustomerUser
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    isAdmin?: boolean;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    defaultDashboardId?: string;
    apiKey?: string;
    phone?: string;
    gdprConsent?: boolean;
  }
}

/**
 * Message implementation for xprojector.grpc.models.Role
 */
export class Role implements GrpcMessage {
  static id = 'xprojector.grpc.models.Role';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Role();
    Role.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Role) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.lastModified = _instance.lastModified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Role, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Role.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Role, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        3,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _name?: string;
  private _lastModified?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Role to deeply clone from
   */
  constructor(_value?: RecursivePartial<Role.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    Role.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Role.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Role.AsObject {
    return {
      id: this.id,
      name: this.name,
      lastModified: this.lastModified ? this.lastModified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Role.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null
    };
  }
}
export module Role {
  /**
   * Standard JavaScript object representation for Role
   */
  export interface AsObject {
    id?: string;
    name?: string;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for Role
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    lastModified?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for xprojector.grpc.models.DataChunk
 */
export class DataChunk implements GrpcMessage {
  static id = 'xprojector.grpc.models.DataChunk';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DataChunk();
    DataChunk.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DataChunk) {
    _instance.data = _instance.data || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DataChunk,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.data = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    DataChunk.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DataChunk, _writer: BinaryWriter) {
    if (_instance.data && _instance.data.length) {
      _writer.writeBytes(1, _instance.data);
    }
  }

  private _data?: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DataChunk to deeply clone from
   */
  constructor(_value?: RecursivePartial<DataChunk.AsObject>) {
    _value = _value || {};
    this.data = _value.data;
    DataChunk.refineValues(this);
  }
  get data(): Uint8Array | undefined {
    return this._data;
  }
  set data(value: Uint8Array | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DataChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DataChunk.AsObject {
    return {
      data: this.data ? this.data.subarray(0) : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DataChunk.AsProtobufJSON {
    return {
      data: this.data ? uint8ArrayToBase64(this.data) : ''
    };
  }
}
export module DataChunk {
  /**
   * Standard JavaScript object representation for DataChunk
   */
  export interface AsObject {
    data?: Uint8Array;
  }

  /**
   * Protobuf JSON representation for DataChunk
   */
  export interface AsProtobufJSON {
    data?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcDashboardInfo
 */
export class GrpcDashboardInfo implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcDashboardInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcDashboardInfo();
    GrpcDashboardInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcDashboardInfo) {
    _instance.id = _instance.id || '';
    _instance.description = _instance.description || '';
    _instance.tag = _instance.tag || '';
    _instance.version = _instance.version || 0;
    _instance.labels = _instance.labels || [];
    _instance.systemDashboard = _instance.systemDashboard || false;
    _instance.name = _instance.name || '';
    _instance.sortOrder = _instance.sortOrder || 0;
    _instance.image = _instance.image || '';
    _instance.imageWidth = _instance.imageWidth || 0;
    _instance.imageHeight = _instance.imageHeight || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcDashboardInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        case 3:
          _instance.tag = _reader.readString();
          break;
        case 4:
          _instance.version = _reader.readInt32();
          break;
        case 5:
          (_instance.labels = _instance.labels || []).push(
            _reader.readString()
          );
          break;
        case 6:
          _instance.systemDashboard = _reader.readBool();
          break;
        case 7:
          _instance.name = _reader.readString();
          break;
        case 8:
          _instance.sortOrder = _reader.readInt32();
          break;
        case 9:
          _instance.image = _reader.readString();
          break;
        case 10:
          _instance.imageWidth = _reader.readInt32();
          break;
        case 11:
          _instance.imageHeight = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcDashboardInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcDashboardInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
    if (_instance.tag) {
      _writer.writeString(3, _instance.tag);
    }
    if (_instance.version) {
      _writer.writeInt32(4, _instance.version);
    }
    if (_instance.labels && _instance.labels.length) {
      _writer.writeRepeatedString(5, _instance.labels);
    }
    if (_instance.systemDashboard) {
      _writer.writeBool(6, _instance.systemDashboard);
    }
    if (_instance.name) {
      _writer.writeString(7, _instance.name);
    }
    if (_instance.sortOrder) {
      _writer.writeInt32(8, _instance.sortOrder);
    }
    if (_instance.image) {
      _writer.writeString(9, _instance.image);
    }
    if (_instance.imageWidth) {
      _writer.writeInt32(10, _instance.imageWidth);
    }
    if (_instance.imageHeight) {
      _writer.writeInt32(11, _instance.imageHeight);
    }
  }

  private _id?: string;
  private _description?: string;
  private _tag?: string;
  private _version?: number;
  private _labels?: string[];
  private _systemDashboard?: boolean;
  private _name?: string;
  private _sortOrder?: number;
  private _image?: string;
  private _imageWidth?: number;
  private _imageHeight?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcDashboardInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcDashboardInfo.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.description = _value.description;
    this.tag = _value.tag;
    this.version = _value.version;
    this.labels = (_value.labels || []).slice();
    this.systemDashboard = _value.systemDashboard;
    this.name = _value.name;
    this.sortOrder = _value.sortOrder;
    this.image = _value.image;
    this.imageWidth = _value.imageWidth;
    this.imageHeight = _value.imageHeight;
    GrpcDashboardInfo.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get tag(): string | undefined {
    return this._tag;
  }
  set tag(value: string | undefined) {
    this._tag = value;
  }
  get version(): number | undefined {
    return this._version;
  }
  set version(value: number | undefined) {
    this._version = value;
  }
  get labels(): string[] | undefined {
    return this._labels;
  }
  set labels(value: string[] | undefined) {
    this._labels = value;
  }
  get systemDashboard(): boolean | undefined {
    return this._systemDashboard;
  }
  set systemDashboard(value: boolean | undefined) {
    this._systemDashboard = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get sortOrder(): number | undefined {
    return this._sortOrder;
  }
  set sortOrder(value: number | undefined) {
    this._sortOrder = value;
  }
  get image(): string | undefined {
    return this._image;
  }
  set image(value: string | undefined) {
    this._image = value;
  }
  get imageWidth(): number | undefined {
    return this._imageWidth;
  }
  set imageWidth(value: number | undefined) {
    this._imageWidth = value;
  }
  get imageHeight(): number | undefined {
    return this._imageHeight;
  }
  set imageHeight(value: number | undefined) {
    this._imageHeight = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcDashboardInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcDashboardInfo.AsObject {
    return {
      id: this.id,
      description: this.description,
      tag: this.tag,
      version: this.version,
      labels: (this.labels || []).slice(),
      systemDashboard: this.systemDashboard,
      name: this.name,
      sortOrder: this.sortOrder,
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcDashboardInfo.AsProtobufJSON {
    return {
      id: this.id,
      description: this.description,
      tag: this.tag,
      version: this.version,
      labels: (this.labels || []).slice(),
      systemDashboard: this.systemDashboard,
      name: this.name,
      sortOrder: this.sortOrder,
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight
    };
  }
}
export module GrpcDashboardInfo {
  /**
   * Standard JavaScript object representation for GrpcDashboardInfo
   */
  export interface AsObject {
    id?: string;
    description?: string;
    tag?: string;
    version?: number;
    labels?: string[];
    systemDashboard?: boolean;
    name?: string;
    sortOrder?: number;
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
  }

  /**
   * Protobuf JSON representation for GrpcDashboardInfo
   */
  export interface AsProtobufJSON {
    id?: string;
    description?: string;
    tag?: string;
    version?: number;
    labels?: string[];
    systemDashboard?: boolean;
    name?: string;
    sortOrder?: number;
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcCustomerContentDataTreeNode
 */
export class GrpcCustomerContentDataTreeNode implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcCustomerContentDataTreeNode';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcCustomerContentDataTreeNode();
    GrpcCustomerContentDataTreeNode.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcCustomerContentDataTreeNode) {
    _instance.nodeId = _instance.nodeId || '';
    _instance.shape = _instance.shape || '';
    _instance.childNodes = _instance.childNodes || [];
    _instance.image = _instance.image || '';
    _instance.imageWidth = _instance.imageWidth || 0;
    _instance.imageHeight = _instance.imageHeight || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcCustomerContentDataTreeNode,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nodeId = _reader.readString();
          break;
        case 2:
          _instance.shape = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new GrpcCustomerContentDataTreeNode();
          _reader.readMessage(
            messageInitializer3,
            GrpcCustomerContentDataTreeNode.deserializeBinaryFromReader
          );
          (_instance.childNodes = _instance.childNodes || []).push(
            messageInitializer3
          );
          break;
        case 4:
          _instance.image = _reader.readString();
          break;
        case 5:
          _instance.imageWidth = _reader.readInt32();
          break;
        case 6:
          _instance.imageHeight = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcCustomerContentDataTreeNode.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcCustomerContentDataTreeNode,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeId) {
      _writer.writeString(1, _instance.nodeId);
    }
    if (_instance.shape) {
      _writer.writeString(2, _instance.shape);
    }
    if (_instance.childNodes && _instance.childNodes.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.childNodes as any,
        GrpcCustomerContentDataTreeNode.serializeBinaryToWriter
      );
    }
    if (_instance.image) {
      _writer.writeString(4, _instance.image);
    }
    if (_instance.imageWidth) {
      _writer.writeInt32(5, _instance.imageWidth);
    }
    if (_instance.imageHeight) {
      _writer.writeInt32(6, _instance.imageHeight);
    }
  }

  private _nodeId?: string;
  private _shape?: string;
  private _childNodes?: GrpcCustomerContentDataTreeNode[];
  private _image?: string;
  private _imageWidth?: number;
  private _imageHeight?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcCustomerContentDataTreeNode to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GrpcCustomerContentDataTreeNode.AsObject>
  ) {
    _value = _value || {};
    this.nodeId = _value.nodeId;
    this.shape = _value.shape;
    this.childNodes = (_value.childNodes || []).map(
      m => new GrpcCustomerContentDataTreeNode(m)
    );
    this.image = _value.image;
    this.imageWidth = _value.imageWidth;
    this.imageHeight = _value.imageHeight;
    GrpcCustomerContentDataTreeNode.refineValues(this);
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get shape(): string | undefined {
    return this._shape;
  }
  set shape(value: string | undefined) {
    this._shape = value;
  }
  get childNodes(): GrpcCustomerContentDataTreeNode[] | undefined {
    return this._childNodes;
  }
  set childNodes(value: GrpcCustomerContentDataTreeNode[] | undefined) {
    this._childNodes = value;
  }
  get image(): string | undefined {
    return this._image;
  }
  set image(value: string | undefined) {
    this._image = value;
  }
  get imageWidth(): number | undefined {
    return this._imageWidth;
  }
  set imageWidth(value: number | undefined) {
    this._imageWidth = value;
  }
  get imageHeight(): number | undefined {
    return this._imageHeight;
  }
  set imageHeight(value: number | undefined) {
    this._imageHeight = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcCustomerContentDataTreeNode.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcCustomerContentDataTreeNode.AsObject {
    return {
      nodeId: this.nodeId,
      shape: this.shape,
      childNodes: (this.childNodes || []).map(m => m.toObject()),
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcCustomerContentDataTreeNode.AsProtobufJSON {
    return {
      nodeId: this.nodeId,
      shape: this.shape,
      childNodes: (this.childNodes || []).map(m => m.toProtobufJSON(options)),
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight
    };
  }
}
export module GrpcCustomerContentDataTreeNode {
  /**
   * Standard JavaScript object representation for GrpcCustomerContentDataTreeNode
   */
  export interface AsObject {
    nodeId?: string;
    shape?: string;
    childNodes?: GrpcCustomerContentDataTreeNode.AsObject[];
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
  }

  /**
   * Protobuf JSON representation for GrpcCustomerContentDataTreeNode
   */
  export interface AsProtobufJSON {
    nodeId?: string;
    shape?: string;
    childNodes?: GrpcCustomerContentDataTreeNode.AsProtobufJSON[] | null;
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcCustomerContentDataPage
 */
export class GrpcCustomerContentDataPage implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcCustomerContentDataPage';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcCustomerContentDataPage();
    GrpcCustomerContentDataPage.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcCustomerContentDataPage) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.shape = _instance.shape || '';
    _instance.image = _instance.image || '';
    _instance.imageWidth = _instance.imageWidth || 0;
    _instance.imageHeight = _instance.imageHeight || 0;
    _instance.sortOrder = _instance.sortOrder || 0;
    _instance.labels = _instance.labels || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcCustomerContentDataPage,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.shape = _reader.readString();
          break;
        case 4:
          _instance.image = _reader.readString();
          break;
        case 5:
          _instance.imageWidth = _reader.readInt32();
          break;
        case 6:
          _instance.imageHeight = _reader.readInt32();
          break;
        case 7:
          _instance.sortOrder = _reader.readInt32();
          break;
        case 8:
          (_instance.labels = _instance.labels || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcCustomerContentDataPage.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcCustomerContentDataPage,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.shape) {
      _writer.writeString(3, _instance.shape);
    }
    if (_instance.image) {
      _writer.writeString(4, _instance.image);
    }
    if (_instance.imageWidth) {
      _writer.writeInt32(5, _instance.imageWidth);
    }
    if (_instance.imageHeight) {
      _writer.writeInt32(6, _instance.imageHeight);
    }
    if (_instance.sortOrder) {
      _writer.writeInt32(7, _instance.sortOrder);
    }
    if (_instance.labels && _instance.labels.length) {
      _writer.writeRepeatedString(8, _instance.labels);
    }
  }

  private _id?: string;
  private _name?: string;
  private _shape?: string;
  private _image?: string;
  private _imageWidth?: number;
  private _imageHeight?: number;
  private _sortOrder?: number;
  private _labels?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcCustomerContentDataPage to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcCustomerContentDataPage.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.shape = _value.shape;
    this.image = _value.image;
    this.imageWidth = _value.imageWidth;
    this.imageHeight = _value.imageHeight;
    this.sortOrder = _value.sortOrder;
    this.labels = (_value.labels || []).slice();
    GrpcCustomerContentDataPage.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get shape(): string | undefined {
    return this._shape;
  }
  set shape(value: string | undefined) {
    this._shape = value;
  }
  get image(): string | undefined {
    return this._image;
  }
  set image(value: string | undefined) {
    this._image = value;
  }
  get imageWidth(): number | undefined {
    return this._imageWidth;
  }
  set imageWidth(value: number | undefined) {
    this._imageWidth = value;
  }
  get imageHeight(): number | undefined {
    return this._imageHeight;
  }
  set imageHeight(value: number | undefined) {
    this._imageHeight = value;
  }
  get sortOrder(): number | undefined {
    return this._sortOrder;
  }
  set sortOrder(value: number | undefined) {
    this._sortOrder = value;
  }
  get labels(): string[] | undefined {
    return this._labels;
  }
  set labels(value: string[] | undefined) {
    this._labels = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcCustomerContentDataPage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcCustomerContentDataPage.AsObject {
    return {
      id: this.id,
      name: this.name,
      shape: this.shape,
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,
      sortOrder: this.sortOrder,
      labels: (this.labels || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcCustomerContentDataPage.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      shape: this.shape,
      image: this.image,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,
      sortOrder: this.sortOrder,
      labels: (this.labels || []).slice()
    };
  }
}
export module GrpcCustomerContentDataPage {
  /**
   * Standard JavaScript object representation for GrpcCustomerContentDataPage
   */
  export interface AsObject {
    id?: string;
    name?: string;
    shape?: string;
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
    sortOrder?: number;
    labels?: string[];
  }

  /**
   * Protobuf JSON representation for GrpcCustomerContentDataPage
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    shape?: string;
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
    sortOrder?: number;
    labels?: string[];
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcCustomerContentData
 */
export class GrpcCustomerContentData implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcCustomerContentData';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcCustomerContentData();
    GrpcCustomerContentData.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcCustomerContentData) {
    _instance.name = _instance.name || '';
    _instance.facilityNodes = _instance.facilityNodes || [];
    _instance.rootNodes = _instance.rootNodes || [];
    _instance.dashboardInfos = _instance.dashboardInfos || [];
    _instance.customerId = _instance.customerId || '';
    _instance.pages = _instance.pages || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcCustomerContentData,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new GrpcNode();
          _reader.readMessage(
            messageInitializer2,
            GrpcNode.deserializeBinaryFromReader
          );
          (_instance.facilityNodes = _instance.facilityNodes || []).push(
            messageInitializer2
          );
          break;
        case 3:
          const messageInitializer3 = new GrpcCustomerContentDataTreeNode();
          _reader.readMessage(
            messageInitializer3,
            GrpcCustomerContentDataTreeNode.deserializeBinaryFromReader
          );
          (_instance.rootNodes = _instance.rootNodes || []).push(
            messageInitializer3
          );
          break;
        case 4:
          const messageInitializer4 = new GrpcDashboardInfo();
          _reader.readMessage(
            messageInitializer4,
            GrpcDashboardInfo.deserializeBinaryFromReader
          );
          (_instance.dashboardInfos = _instance.dashboardInfos || []).push(
            messageInitializer4
          );
          break;
        case 5:
          _instance.customerId = _reader.readString();
          break;
        case 6:
          const messageInitializer6 = new GrpcCustomerContentDataPage();
          _reader.readMessage(
            messageInitializer6,
            GrpcCustomerContentDataPage.deserializeBinaryFromReader
          );
          (_instance.pages = _instance.pages || []).push(messageInitializer6);
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcCustomerContentData.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcCustomerContentData,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.facilityNodes && _instance.facilityNodes.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.facilityNodes as any,
        GrpcNode.serializeBinaryToWriter
      );
    }
    if (_instance.rootNodes && _instance.rootNodes.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.rootNodes as any,
        GrpcCustomerContentDataTreeNode.serializeBinaryToWriter
      );
    }
    if (_instance.dashboardInfos && _instance.dashboardInfos.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.dashboardInfos as any,
        GrpcDashboardInfo.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(5, _instance.customerId);
    }
    if (_instance.pages && _instance.pages.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.pages as any,
        GrpcCustomerContentDataPage.serializeBinaryToWriter
      );
    }
  }

  private _name?: string;
  private _facilityNodes?: GrpcNode[];
  private _rootNodes?: GrpcCustomerContentDataTreeNode[];
  private _dashboardInfos?: GrpcDashboardInfo[];
  private _customerId?: string;
  private _pages?: GrpcCustomerContentDataPage[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcCustomerContentData to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcCustomerContentData.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.facilityNodes = (_value.facilityNodes || []).map(m => new GrpcNode(m));
    this.rootNodes = (_value.rootNodes || []).map(
      m => new GrpcCustomerContentDataTreeNode(m)
    );
    this.dashboardInfos = (_value.dashboardInfos || []).map(
      m => new GrpcDashboardInfo(m)
    );
    this.customerId = _value.customerId;
    this.pages = (_value.pages || []).map(
      m => new GrpcCustomerContentDataPage(m)
    );
    GrpcCustomerContentData.refineValues(this);
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get facilityNodes(): GrpcNode[] | undefined {
    return this._facilityNodes;
  }
  set facilityNodes(value: GrpcNode[] | undefined) {
    this._facilityNodes = value;
  }
  get rootNodes(): GrpcCustomerContentDataTreeNode[] | undefined {
    return this._rootNodes;
  }
  set rootNodes(value: GrpcCustomerContentDataTreeNode[] | undefined) {
    this._rootNodes = value;
  }
  get dashboardInfos(): GrpcDashboardInfo[] | undefined {
    return this._dashboardInfos;
  }
  set dashboardInfos(value: GrpcDashboardInfo[] | undefined) {
    this._dashboardInfos = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get pages(): GrpcCustomerContentDataPage[] | undefined {
    return this._pages;
  }
  set pages(value: GrpcCustomerContentDataPage[] | undefined) {
    this._pages = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcCustomerContentData.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcCustomerContentData.AsObject {
    return {
      name: this.name,
      facilityNodes: (this.facilityNodes || []).map(m => m.toObject()),
      rootNodes: (this.rootNodes || []).map(m => m.toObject()),
      dashboardInfos: (this.dashboardInfos || []).map(m => m.toObject()),
      customerId: this.customerId,
      pages: (this.pages || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcCustomerContentData.AsProtobufJSON {
    return {
      name: this.name,
      facilityNodes: (this.facilityNodes || []).map(m =>
        m.toProtobufJSON(options)
      ),
      rootNodes: (this.rootNodes || []).map(m => m.toProtobufJSON(options)),
      dashboardInfos: (this.dashboardInfos || []).map(m =>
        m.toProtobufJSON(options)
      ),
      customerId: this.customerId,
      pages: (this.pages || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GrpcCustomerContentData {
  /**
   * Standard JavaScript object representation for GrpcCustomerContentData
   */
  export interface AsObject {
    name?: string;
    facilityNodes?: GrpcNode.AsObject[];
    rootNodes?: GrpcCustomerContentDataTreeNode.AsObject[];
    dashboardInfos?: GrpcDashboardInfo.AsObject[];
    customerId?: string;
    pages?: GrpcCustomerContentDataPage.AsObject[];
  }

  /**
   * Protobuf JSON representation for GrpcCustomerContentData
   */
  export interface AsProtobufJSON {
    name?: string;
    facilityNodes?: GrpcNode.AsProtobufJSON[] | null;
    rootNodes?: GrpcCustomerContentDataTreeNode.AsProtobufJSON[] | null;
    dashboardInfos?: GrpcDashboardInfo.AsProtobufJSON[] | null;
    customerId?: string;
    pages?: GrpcCustomerContentDataPage.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcNodeProperty
 */
export class GrpcNodeProperty implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcNodeProperty';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcNodeProperty();
    GrpcNodeProperty.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcNodeProperty) {
    _instance.valueType = _instance.valueType || 0;
    _instance.key = _instance.key || '';
    _instance.value = _instance.value || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcNodeProperty,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.valueType = _reader.readEnum();
          break;
        case 2:
          _instance.key = _reader.readString();
          break;
        case 3:
          _instance.value = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcNodeProperty.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcNodeProperty,
    _writer: BinaryWriter
  ) {
    if (_instance.valueType) {
      _writer.writeEnum(1, _instance.valueType);
    }
    if (_instance.key) {
      _writer.writeString(2, _instance.key);
    }
    if (_instance.value) {
      _writer.writeString(3, _instance.value);
    }
  }

  private _valueType?: GrpcNodeProperty.ValueTypes;
  private _key?: string;
  private _value?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcNodeProperty to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcNodeProperty.AsObject>) {
    _value = _value || {};
    this.valueType = _value.valueType;
    this.key = _value.key;
    this.value = _value.value;
    GrpcNodeProperty.refineValues(this);
  }
  get valueType(): GrpcNodeProperty.ValueTypes | undefined {
    return this._valueType;
  }
  set valueType(value: GrpcNodeProperty.ValueTypes | undefined) {
    this._valueType = value;
  }
  get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
  }
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcNodeProperty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcNodeProperty.AsObject {
    return {
      valueType: this.valueType,
      key: this.key,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcNodeProperty.AsProtobufJSON {
    return {
      valueType:
        GrpcNodeProperty.ValueTypes[
          this.valueType === null || this.valueType === undefined
            ? 0
            : this.valueType
        ],
      key: this.key,
      value: this.value
    };
  }
}
export module GrpcNodeProperty {
  /**
   * Standard JavaScript object representation for GrpcNodeProperty
   */
  export interface AsObject {
    valueType?: GrpcNodeProperty.ValueTypes;
    key?: string;
    value?: string;
  }

  /**
   * Protobuf JSON representation for GrpcNodeProperty
   */
  export interface AsProtobufJSON {
    valueType?: string;
    key?: string;
    value?: string;
  }
  export enum ValueTypes {
    BOOLEAN = 0,
    DOUBLE = 1,
    STRING = 2,
    DATETIME = 3,
    TIMESPAN = 4,
    STRINGOPTIONS = 5,
    DASHBOARD = 6,
    STRINGARRAY = 7
  }
}

/**
 * Message implementation for xprojector.grpc.models.KeyValuePair
 */
export class KeyValuePair implements GrpcMessage {
  static id = 'xprojector.grpc.models.KeyValuePair';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyValuePair();
    KeyValuePair.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyValuePair) {
    _instance.key = _instance.key || '';
    _instance.value = _instance.value || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyValuePair,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.key = _reader.readString();
          break;
        case 2:
          _instance.value = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    KeyValuePair.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: KeyValuePair,
    _writer: BinaryWriter
  ) {
    if (_instance.key) {
      _writer.writeString(1, _instance.key);
    }
    if (_instance.value) {
      _writer.writeString(2, _instance.value);
    }
  }

  private _key?: string;
  private _value?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyValuePair to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyValuePair.AsObject>) {
    _value = _value || {};
    this.key = _value.key;
    this.value = _value.value;
    KeyValuePair.refineValues(this);
  }
  get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
  }
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyValuePair.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyValuePair.AsObject {
    return {
      key: this.key,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyValuePair.AsProtobufJSON {
    return {
      key: this.key,
      value: this.value
    };
  }
}
export module KeyValuePair {
  /**
   * Standard JavaScript object representation for KeyValuePair
   */
  export interface AsObject {
    key?: string;
    value?: string;
  }

  /**
   * Protobuf JSON representation for KeyValuePair
   */
  export interface AsProtobufJSON {
    key?: string;
    value?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcNode
 */
export class GrpcNode implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcNode';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcNode();
    GrpcNode.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcNode) {
    _instance.id = _instance.id || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.nodeTypeLabel = _instance.nodeTypeLabel || '';
    _instance.propertyValues = _instance.propertyValues || [];
    _instance.metaData = _instance.metaData || [];
    _instance.tags = _instance.tags || [];
    _instance.name = _instance.name || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcNode,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeLabel = _reader.readString();
          break;
        case 4:
          const messageInitializer4 = new GrpcNodeProperty();
          _reader.readMessage(
            messageInitializer4,
            GrpcNodeProperty.deserializeBinaryFromReader
          );
          (_instance.propertyValues = _instance.propertyValues || []).push(
            messageInitializer4
          );
          break;
        case 5:
          const messageInitializer5 = new KeyValuePair();
          _reader.readMessage(
            messageInitializer5,
            KeyValuePair.deserializeBinaryFromReader
          );
          (_instance.metaData = _instance.metaData || []).push(
            messageInitializer5
          );
          break;
        case 6:
          (_instance.tags = _instance.tags || []).push(_reader.readString());
          break;
        case 7:
          _instance.name = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcNode.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GrpcNode, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(2, _instance.nodeTypeId);
    }
    if (_instance.nodeTypeLabel) {
      _writer.writeString(3, _instance.nodeTypeLabel);
    }
    if (_instance.propertyValues && _instance.propertyValues.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.propertyValues as any,
        GrpcNodeProperty.serializeBinaryToWriter
      );
    }
    if (_instance.metaData && _instance.metaData.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.metaData as any,
        KeyValuePair.serializeBinaryToWriter
      );
    }
    if (_instance.tags && _instance.tags.length) {
      _writer.writeRepeatedString(6, _instance.tags);
    }
    if (_instance.name) {
      _writer.writeString(7, _instance.name);
    }
  }

  private _id?: string;
  private _nodeTypeId?: string;
  private _nodeTypeLabel?: string;
  private _propertyValues?: GrpcNodeProperty[];
  private _metaData?: KeyValuePair[];
  private _tags?: string[];
  private _name?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcNode to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcNode.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.nodeTypeId = _value.nodeTypeId;
    this.nodeTypeLabel = _value.nodeTypeLabel;
    this.propertyValues = (_value.propertyValues || []).map(
      m => new GrpcNodeProperty(m)
    );
    this.metaData = (_value.metaData || []).map(m => new KeyValuePair(m));
    this.tags = (_value.tags || []).slice();
    this.name = _value.name;
    GrpcNode.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get nodeTypeLabel(): string | undefined {
    return this._nodeTypeLabel;
  }
  set nodeTypeLabel(value: string | undefined) {
    this._nodeTypeLabel = value;
  }
  get propertyValues(): GrpcNodeProperty[] | undefined {
    return this._propertyValues;
  }
  set propertyValues(value: GrpcNodeProperty[] | undefined) {
    this._propertyValues = value;
  }
  get metaData(): KeyValuePair[] | undefined {
    return this._metaData;
  }
  set metaData(value: KeyValuePair[] | undefined) {
    this._metaData = value;
  }
  get tags(): string[] | undefined {
    return this._tags;
  }
  set tags(value: string[] | undefined) {
    this._tags = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcNode.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcNode.AsObject {
    return {
      id: this.id,
      nodeTypeId: this.nodeTypeId,
      nodeTypeLabel: this.nodeTypeLabel,
      propertyValues: (this.propertyValues || []).map(m => m.toObject()),
      metaData: (this.metaData || []).map(m => m.toObject()),
      tags: (this.tags || []).slice(),
      name: this.name
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcNode.AsProtobufJSON {
    return {
      id: this.id,
      nodeTypeId: this.nodeTypeId,
      nodeTypeLabel: this.nodeTypeLabel,
      propertyValues: (this.propertyValues || []).map(m =>
        m.toProtobufJSON(options)
      ),
      metaData: (this.metaData || []).map(m => m.toProtobufJSON(options)),
      tags: (this.tags || []).slice(),
      name: this.name
    };
  }
}
export module GrpcNode {
  /**
   * Standard JavaScript object representation for GrpcNode
   */
  export interface AsObject {
    id?: string;
    nodeTypeId?: string;
    nodeTypeLabel?: string;
    propertyValues?: GrpcNodeProperty.AsObject[];
    metaData?: KeyValuePair.AsObject[];
    tags?: string[];
    name?: string;
  }

  /**
   * Protobuf JSON representation for GrpcNode
   */
  export interface AsProtobufJSON {
    id?: string;
    nodeTypeId?: string;
    nodeTypeLabel?: string;
    propertyValues?: GrpcNodeProperty.AsProtobufJSON[] | null;
    metaData?: KeyValuePair.AsProtobufJSON[] | null;
    tags?: string[];
    name?: string;
  }
}

/**
 * Message implementation for xprojector.grpc.models.GrpcXDataNumericValue
 */
export class GrpcXDataNumericValue implements GrpcMessage {
  static id = 'xprojector.grpc.models.GrpcXDataNumericValue';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcXDataNumericValue();
    GrpcXDataNumericValue.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcXDataNumericValue) {
    _instance.nodeId = _instance.nodeId || '';
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.status = _instance.status || 0;
    _instance.varId = _instance.varId || '';
    _instance.value = _instance.value || 0;
    _instance.unit = _instance.unit || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcXDataNumericValue,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nodeId = _reader.readString();
          break;
        case 2:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.status = _reader.readUint32();
          break;
        case 4:
          _instance.varId = _reader.readString();
          break;
        case 10:
          _instance.value = _reader.readDouble();
          break;
        case 11:
          _instance.unit = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcXDataNumericValue.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GrpcXDataNumericValue,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeId) {
      _writer.writeString(1, _instance.nodeId);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        2,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeUint32(3, _instance.status);
    }
    if (_instance.varId) {
      _writer.writeString(4, _instance.varId);
    }
    if (_instance.value) {
      _writer.writeDouble(10, _instance.value);
    }
    if (_instance.unit) {
      _writer.writeString(11, _instance.unit);
    }
  }

  private _nodeId?: string;
  private _timestamp?: googleProtobuf000.Timestamp;
  private _status?: number;
  private _varId?: string;
  private _value?: number;
  private _unit?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcXDataNumericValue to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcXDataNumericValue.AsObject>) {
    _value = _value || {};
    this.nodeId = _value.nodeId;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    this.status = _value.status;
    this.varId = _value.varId;
    this.value = _value.value;
    this.unit = _value.unit;
    GrpcXDataNumericValue.refineValues(this);
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }
  get status(): number | undefined {
    return this._status;
  }
  set status(value: number | undefined) {
    this._status = value;
  }
  get varId(): string | undefined {
    return this._varId;
  }
  set varId(value: string | undefined) {
    this._varId = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcXDataNumericValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcXDataNumericValue.AsObject {
    return {
      nodeId: this.nodeId,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      status: this.status,
      varId: this.varId,
      value: this.value,
      unit: this.unit
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcXDataNumericValue.AsProtobufJSON {
    return {
      nodeId: this.nodeId,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      status: this.status,
      varId: this.varId,
      value: this.value,
      unit: this.unit
    };
  }
}
export module GrpcXDataNumericValue {
  /**
   * Standard JavaScript object representation for GrpcXDataNumericValue
   */
  export interface AsObject {
    nodeId?: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
    status?: number;
    varId?: string;
    value?: number;
    unit?: string;
  }

  /**
   * Protobuf JSON representation for GrpcXDataNumericValue
   */
  export interface AsProtobufJSON {
    nodeId?: string;
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    status?: number;
    varId?: string;
    value?: number;
    unit?: string;
  }
}
