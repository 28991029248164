import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeof'
})
export class XprojTypeofPipe implements PipeTransform {

	transform(value): string {
        //console.log("Pipe works ", value.constructor.name);
        return value.constructor.name;
      }

}
