import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardOutputChangeParameters } from '../models/dashboard-output-change-parameters';

export class WidgetType {
  id : string;
  name : string;
}

@Injectable({
  providedIn: 'root'
})
export class XprojDashboardInteractService {

  public getWidgetTypesSubject: Subject<{dashboardId : string, callback: (widgetTypes : WidgetType[]) => void}> = new Subject();
  public addWidgetSubject: Subject<{dashboardId : string, widgetType : WidgetType, callback: (result : boolean) => void}> = new Subject();
  public showDashboardSettingsSubject: Subject<{dashboardId : string, showSettings : boolean, settingsIconVisible : boolean}> = new Subject();
  public widgetOutputChangeSubject: Subject<DashboardOutputChangeParameters[]> = new Subject();
  public dashboardRefresh: Subject<void> = new Subject();
  public dashboardReset: Subject<void> = new Subject();

  public GetWidgetTypes(dashboardId : string, callback: (widgetTypes : WidgetType[]) => void) {
		this.getWidgetTypesSubject.next({dashboardId: dashboardId, callback: callback});
	}

  public AddWidget(dashboardId : string, widgetType : WidgetType, callback: (result : boolean) => void) {
		this.addWidgetSubject.next({dashboardId, widgetType, callback});
  }

  public ShowDashboardSettings(dashboardId : string, showSettings : boolean, settingsIconVisible : boolean) {
    this.showDashboardSettingsSubject.next({dashboardId, showSettings, settingsIconVisible});
  }

  public DashboardParameterChanged(parameters : DashboardOutputChangeParameters) {
		this.widgetOutputChangeSubject.next([parameters]);
  }

  public DashboardParametersChanged(parameters : DashboardOutputChangeParameters[]) {
		this.widgetOutputChangeSubject.next(parameters);
  }

  public RefreshDashboard() {
		this.dashboardRefresh.next();
  }

  public ResetDashboard() {
		this.dashboardReset.next();
  }

}

