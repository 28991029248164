<div style="padding:4em; font-family: monospace;" *ngIf="luaDebugger.selectedInstanceID==''" >
Select instance

<div class="limit-height" style="max-width:1200px;">
    <clr-datagrid class="datagrid-compact" (clrDgSingleSelectedChange)="selectedSIDChanged($event)" [(clrDgSingleSelected)]="selectedInstance" [clrDgRowSelection]="true"
      [clrDgLoading]="luaDebugger.loadingPotentialSIDs">
      <clr-dg-column [clrDgField]="'projectionid'"> Instance ID </clr-dg-column>
      <!-- <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Description </clr-dg-column> -->

      <clr-dg-row *clrDgItems="let instance of luaDebugger.PotentialSIDs" [clrDgItem]="instance">
        <clr-dg-cell class="cellhideoverflow"> {{instance}} </clr-dg-cell>
        <!-- <clr-dg-cell class="cellhideoverflow"> {{instance.description}} </clr-dg-cell> -->
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
          <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Instances per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} instances
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

  <form clrForm>
    <clr-input-container>
      <label>Instance ID </label>
      <input clrInput type="text" [(ngModel)]="userRequestSelectedInstanceID" name="userRequestSelectedInstanceID"
        size="80" />
        
    </clr-input-container>
    <button class="btn btn-primary btn-sm" (click)="attach()">Attach</button>
    </form>
    
</div>


<!-- *ngIf="selectedInstanceID!=''" -->
<as-split *ngIf="luaDebugger.selectedInstanceID!=''" direction="horizontal" [unit]="'pixel'" [gutterSize]="7"  style="font-family: monospace;" >
    <as-split-area [order]="1">
        <div *ngIf="luaDebugger.selectedInstanceID!=''">
            Attached to: {{luaDebugger.selectedInstanceID}} ({{luaDebugger.stateMessage}})
            <button class="btn btn-sm" style="margin-left:3em;" (click)="release()">Release</button>
        </div>

        <div style="margin:1em;">
            <div class="btn-group btn-sm">
                <button class="btn" (click)="run()">Run</button>
                <button class="btn" (click)="break()" disabled>Break</button>
                <button class="btn" (click)="stepInto()">Step Into</button>
                <button class="btn" (click)="stepOver()">Step over</button>
            </div>
        </div>
        <!-- Försöker få in ACE här för breakpoints osv.. men kanske är fel väg att gå.. / stefan  -->
        <!-- <ace [config]="aceConfig" [mode]="'text'" [theme]="'github'" [(value)]="source" style="height: 75vh;"></ace> -->
        <!-- <ace style="height: 75vh;"></ace> -->
        <!-- <div class="ace" [ace]="aceConfig">text</div> -->

        
        <ngx-monaco-editor [hidden]="gettingSource"  name="codeeditor"  (onInit)="onInitEditor($event)"
            [options]="editorOptionsLua" [(ngModel)]="luaDebugger.source" style="height: 75vh;">
        </ngx-monaco-editor> 
        <div *ngIf="gettingSource"> Loading script .. </div>
    </as-split-area>

    <as-split-area [order]="2" [(size)]="sidepanelsizepx" style="margin-left: 5px;">
        <h2>Watch</h2>
        
        <clr-datagrid class="datagrid-compact" style="height: 50vh;" [(clrDgSingleSelected)]="selectedWatch" [clrDgRowSelection]="true"
        [clrDgLoading]="loadingWatch">
        <clr-dg-column [clrDgField]="'watchname'"> Exp </clr-dg-column>
        <clr-dg-column [clrDgField]="'watchvalue'" [clrDgSortOrder]="ascSort"> Value </clr-dg-column>
  
        <clr-dg-row *clrDgItems="let watchvar of luaDebugger.watchesValues" [clrDgItem]="watchvar">
          <clr-dg-cell class="cellhideoverflow"> {{watchvar.exp}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{watchvar.val}} </clr-dg-cell>
        </clr-dg-row>
  
        <clr-dg-footer>
          <div style="width: 14em;">
            <input type="string" min="0" class="clr-input" placeholder="Exp#" [(ngModel)]="userRequestWatch" name="userRequestWatch" size="5" style="width: 10em;" />
            <span class="clr-input-group-addon"><button class="btn  btn-sm" style="margin-left: 1em;" (click)="addwatch()" [disabled]="userRequestWatch == null">Add</button>    </span>
            <span class="clr-input-group-addon"><button class="btn  btn-sm" style="margin-left: 1em;" (click)="clearwatch()">Clear</button>    </span>
          </div>
    
          <!-- <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
            <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} variables
          </clr-dg-pagination> -->
        </clr-dg-footer>
      </clr-datagrid>

<!--
        <h2>Locals</h2>
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedLocalVar" [clrDgRowSelection]="true"
        [clrDgLoading]="loadingVariablesLocal">
        <clr-dg-column [clrDgField]="'localvarname'"> Name </clr-dg-column>
        <clr-dg-column [clrDgField]="'localvarval'" [clrDgSortOrder]="ascSort"> Value </clr-dg-column>
  
        <clr-dg-row *clrDgItems="let localvar of localvars" [clrDgItem]="localvar">
          <clr-dg-cell class="cellhideoverflow"> {{localvar.name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{localvar.value}} </clr-dg-cell>
        </clr-dg-row>
  
        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
            <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Local variables per page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} variables
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

      <h2>Globals</h2>
      <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedLocalVar" [clrDgRowSelection]="true"
      [clrDgLoading]="loadingVariablesLocal">
      <clr-dg-column [clrDgField]="'localvarname'"> Name </clr-dg-column>
      <clr-dg-column [clrDgField]="'localvarval'" [clrDgSortOrder]="ascSort"> Value </clr-dg-column>

      <clr-dg-row *clrDgItems="let localvar of localvars" [clrDgItem]="localvar">
        <clr-dg-cell class="cellhideoverflow"> {{localvar.name}} </clr-dg-cell>
        <clr-dg-cell class="cellhideoverflow"> {{localvar.value}} </clr-dg-cell>
      </clr-dg-row>
      

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
          <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Global variables per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} variables
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>      
-->
    <h2>Breakpoints</h2>
    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedBreakpoint" [clrDgRowSelection]="true"
    [clrDgLoading]="loadingBreakpoints">
    <!-- <clr-dg-column [clrDgField]="'breakpointsrc'"> Source </clr-dg-column> -->
    <clr-dg-column [clrDgField]="'localvarval'" [clrDgSortOrder]="ascSort"> Line </clr-dg-column>

    <clr-dg-row *clrDgItems="let brp of luaDebugger.breakpoints" [clrDgItem]="brp">
      <!-- <clr-dg-cell class="cellhideoverflow"> Script </clr-dg-cell> -->
      <clr-dg-cell class="cellhideoverflow"> {{brp}} </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <div style="width: 14em;">
        <input type="number" min="0" class="clr-input" placeholder="Line#" [(ngModel)]="userRequestBreakpointLineNumber" name="userRequestBreakpointLineNumber" size="5" style="width: 6em;" />          
        <span class="clr-input-group-addon"><button class="btn  btn-sm" style="margin-left: 1em;" (click)="addbreakpoint()" [disabled]="userRequestBreakpointLineNumber == null">Add</button>    </span>
        <span class="clr-input-group-addon"><button class="btn  btn-sm" style="margin-left: 1em;" (click)="clearbreakpoints()">Clear</button>    </span>
      </div>
      <!-- <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
        <clr-dg-page-size [clrPageSizeOptions]="sizeOptions"></clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} breakpoints
      </clr-dg-pagination> -->
    </clr-dg-footer>
  </clr-datagrid>      
    
  <!-- <div class="clr-form-control">    
    <div class="clr-control-container">
      <div class ="clr-input-wrapper">
        <div class="clr-input-group">
          also commented? <span class="clr-input-group-addon">Line #</span> end of also commented?
          <input type="number" class="clr-input" placeholder="Line#" [(ngModel)]="userRequestBreakpointLineNumber" name="userRequestBreakpointLineNumber" size="5" style="width: 7em;" />          
        </div>
        <span class="clr-input-group-addon"><button class="btn  btn-sm" style="margin-left: 1em;" (click)="addbreakpoint()">Add</button>    </span>        
      </div>
    </div>
  </div> -->

  </as-split-area>



</as-split>
