<main class="content-area">
    <router-outlet></router-outlet>
</main>

<!--
<nav class="sidenav">

    <section class="nav-group collapsible" *ngFor="let proj of Projections" style="padding:0;">
        <input id="{{proj['projectionid']}}" type="checkbox" checked>
        <label for="{{proj['projectionid']}}"> {{proj['name']}}</label>
        <ul class="nav-list">
            <li>
                <a [routerLink]="['edit-projection']" routerLinkActive="active" class="nav-link nav-text" [routerLinkActiveOptions]="{exact:true}">Basic configuration</a>
            </li>
            <li><a class="nav-link" route>Link 1</a></li>
            <li><a class="nav-link">Link 2</a></li>
        </ul>
    </section>
</nav>
-->

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">

    <!-- <div class="nav-divider"></div> -->



    <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
        Projections

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['projections']" routerLinkActive="active">
                Edit projections
            </a>
            <a clrVerticalNavLink [routerLink]="['projections','new']" routerLinkActive=" active ">
                Create projection
            </a>
        </clr-vertical-nav-group-children>

    </clr-vertical-nav-group>

    <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="library"></clr-icon>
        Scripting

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['page-not-found']" routerLinkActive="active">
                Global scripts
            </a>
            <a clrVerticalNavLink [routerLink]="['debugger']" routerLinkActive="active">
                Debugger
            </a>    
            <a clrVerticalNavLink [routerLink]="['scripthelp']" routerLinkActive=" active ">
                Help
            </a>
        </clr-vertical-nav-group-children>

    </clr-vertical-nav-group>

    <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="calendar"></clr-icon>
        Scheduler

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['page-not-found']" routerLinkActive="active">
                Global settings
            </a>
            <a clrVerticalNavLink [routerLink]="['page-not-found','new']" routerLinkActive=" active ">
                Projection settings
            </a>
        </clr-vertical-nav-group-children>

    </clr-vertical-nav-group>

    <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="storage"></clr-icon>
        Data sources

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['datasources']" routerLinkActive="active">
                Edit data sources
            </a>
            <a clrVerticalNavLink [routerLink]="['datasources','new']" routerLinkActive=" active ">
                Create data source
            </a>
        </clr-vertical-nav-group-children>

    </clr-vertical-nav-group>


    <clr-vertical-nav-group routerLinkActive="true">
        <clr-icon clrVerticalNavIcon shape="eye"></clr-icon>
        Inspect

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['databrowser']" routerLinkActive="active">
                Data browser
            </a>
        </clr-vertical-nav-group-children>

    </clr-vertical-nav-group>

    <!-- <clr-vertical-nav-group routerLinkActive="false">
      <clr-icon clrVerticalNavIcon shape="image-gallery"></clr-icon>
      Files

      <clr-vertical-nav-group-children>
        <a clrVerticalNavLink [routerLink]="['filelibrary']" routerLinkActive="active">
          File library
        </a>
      </clr-vertical-nav-group-children>

    </clr-vertical-nav-group> -->

</clr-vertical-nav>
