import { Component, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

@Component({
  selector: 'app-log-config',
  templateUrl: './log-config.component.html',
  styleUrls: ['./log-config.component.scss']
})
export class LogConfigComponent implements OnInit {

  NgxLoggerLevel = NgxLoggerLevel;

  constructor(private logger: NGXLogger,
    public state: StateService) { }

  ngOnInit(): void {
  }

  currentLogLevelChanged($event) {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.level = this.state.currentLogLevel;
    updatedConfig.serverLogLevel = this.state.currentLogLevelServer;
    this.logger.updateConfig(updatedConfig);
  }
}
