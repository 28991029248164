import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { BaseQuery, XDataType, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, ProjectionColumnDescription, FilterLogicalGroupType, ColumnFilteringRelativeTimestamp } from '../../XProjector/xprojector-client-service';
import { DataFilter } from '../data-filter/data-filter-service';
import { TypedJSON } from 'typedjson';
import { WidgetConfig, WidgetInputParameter } from '../../widgets/widget-config-service';
import { WidgetUtils } from '../../utils/widget-utils-service';


export class ColumnDescription {
  public columnname: string;
  public datatype: XDataType;
}

@Component({
  selector: 'xproj-projection-filter',
  templateUrl: './xproj-projection-filter.component.html',
  styleUrls: ['./xproj-projection-filter.component.scss']
})
export class XprojProjectionFilterComponent implements OnInit {

  @Input() projectionid: string;
  //@Input() columnDescriptions: ProjectionColumnDescription[] = [];
  @Input() group : [] = [];
  @Input() datafilters: DataFilter[] = [];
  @Input() filterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;
  @Input() inputParameters : WidgetInputParameter[] = [];
  @Input() widgets : WidgetConfig[];
  @Input()
  get columnDescriptions(): ProjectionColumnDescription[] { return this._columnDescriptions }
  set columnDescriptions(columnDescriptions : ProjectionColumnDescription[]) {
    //convert from literal object to class object
    this._columnDescriptions = [];
    columnDescriptions?.forEach(desc => {
      this._columnDescriptions.push(TypedJSON.parse(JSON.stringify(desc), ProjectionColumnDescription));
    });
  }

  private _columnDescriptions: ProjectionColumnDescription[] = [];
  logicalGroupTypeKeys : any;

  constructor() {
    this.logicalGroupTypeKeys = Object.keys(FilterLogicalGroupType)
      .map(key => ({ value: FilterLogicalGroupType[key], title: key }));
    this.logicalGroupTypeKeys = this.logicalGroupTypeKeys.slice(this.logicalGroupTypeKeys.length / 2);
  }

  ngOnInit(): void {
  }

  newDataFilter() {
    this.datafilters.push(new DataFilter())
  }

  public GetQuery(): BaseQuery {
    let filterId = 0;
    let result = new BaseQuery();

    if(this.group?.length > 0)
      result.targetgroup = this.group;
      else
      result.targetgroup = new Array<string>();

    result.filter.type = parseInt(this.filterLogicalGroupType.toString());
    this.datafilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        columnFiltering.queryfilterid = ++filterId;
        result.filter.filters.push(columnFiltering.queryfilterid);

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          result.numericalfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          result.stringfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          result.timestampfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          result.relativetimestampfilters.push(columnFiltering);
        }
      }
    });

    return result;
  }

  onDataFilterDelete(dataFilter : DataFilter) : void {
    this.datafilters =  this.datafilters.filter(f => f != dataFilter)
  }
}
