<h1>Edit PLC Program</h1>

<form clrForm>
    <clr-input-container>
        <label>Program ID </label>
        <input clrInput type="text" [(ngModel)]="Project.Program.xautoprogramid" name="ProgramID" disabled size="35" />
    </clr-input-container>

    <clr-input-container>
        <label>Name </label>
        <input clrInput type="text" [(ngModel)]="Project.Program.name" name="ProgramName" size="35" />
    </clr-input-container>

    <clr-input-container>
        <label>Description </label>
        <input clrInput type="text" [(ngModel)]="Project.Program.description" name="ProgramDescription" size="35" />
    </clr-input-container>

    <clr-toggle-container>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="true" name="programenabled" [(ngModel)]="Project.Program.enabled" />
            <label>Enabled</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>

</form>