import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { XprojAlert, XprojAlertService, XprojAlertType } from 'xproj-lib';
//import { Alert, AlertType } from '../models/alert';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() id = 'default-alert';

  alerts: XprojAlert[] = [];
  alertSubscription: any;
  routeSubscription: Subscription;

  constructor(
    private router: Router,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        this.handleAlert(alert);
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });


  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  handleAlert(alert : XprojAlert) {
    // clear alerts when an empty alert is received
    if (!alert.message) {
      // filter out alerts without 'keepAfterRouteChange' flag
      this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

      // remove 'keepAfterRouteChange' flag on the rest
      this.alerts.forEach(x => delete x.keepAfterRouteChange);
      return;
    }

    // add alert to array
    this.alerts.push(alert);

    // auto close alert if required
    if (alert.autoClose) {
      setTimeout(() => this.removeAlert(alert), 3000);
    }
  }

  removeAlert(alert: XprojAlert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) {
      return;
    }

    // remove alert
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  getAlertType(alert : XprojAlert) : string {
    switch (alert.type) {
      case XprojAlertType.Success : return 'success';
      case XprojAlertType.Info : return 'info';
      case XprojAlertType.Error : return 'danger';
      case XprojAlertType.Warning : return 'warning';
      default : return 'info';
    }
  }
}
