import { Component } from '@angular/core';

@Component({
  selector: 'xproj-editintegrationservers-modbus',
  templateUrl: './editintegrationservers-modbus.component.html',
  styleUrls: ['./editintegrationservers-modbus.component.scss']
})
export class EditintegrationserversModbusComponent {

}
