import { Component } from '@angular/core';

@Component({
  selector: 'xproj-xdb-settings',
  templateUrl: './xdb-settings.component.html',
  styleUrls: ['./xdb-settings.component.scss']
})
export class XdbSettingsComponent {

}
