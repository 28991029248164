
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { XProjectorClient } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj--listdatasources',
  templateUrl: './xproj-listdatasources.component.html',
  styleUrls: ['./xproj-listdatasources.component.scss'],

  host: {
    class:'content-container'
}
})

//encapsulation: ViewEncapsulation.None,

export class XProjListdatasourcesComponent implements OnInit {

  Datasources = [];
  loadingDatasources  = false;
  selectedDataSource : any = null;

  collapsed = false;

  constructor(private xprojClient: XProjectorClient)
  {

  }

  async selectedProjectionChange( event : any)
  {
    //await this.basequery( this.selectedProjection );
    //await this.queryColumns( this.selectedProjection["projectionid"] );
  }

  async Start()
  {
    this.loadingDatasources = true;
    let t = await this.xprojClient.RequestListConfigDatasources(0,100);
    this.loadingDatasources = false;
    for(let i = 0; i < t.length; i++)
    {
      this.Datasources.push(t[i]);
    }
  }

  async ngOnInit()
  {
    this.Datasources.length = 0;
    this.Start();
  }

}
