import 'reflect-metadata';
import { jsonMember, jsonArrayMember, TypedJSON, jsonObject } from 'typedjson';
import { WidgetConfig } from '../../widget-config-service';
import { MasterTimeSettings } from '../../xproj-widget-service';

@jsonObject
export class MasterWidgetConfig extends WidgetConfig {

  @jsonMember
	public ProjectionSelectEnabled: boolean = true;

	@jsonMember
	public ProjectionId : string;

  @jsonArrayMember(String)
  public SelectedGroup : string[] = [];

	@jsonMember
	public Time : MasterTimeSettings;

	public Clone(): MasterWidgetConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, MasterWidgetConfig), MasterWidgetConfig);
	}

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    return [];
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;

}
