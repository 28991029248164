export class ArrayUtils {

	public static async AsyncForEach(array, callback) {
    if (array) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }
	}

	public static GroupBy = (array, key) => {
		// Return the end result
		return array.reduce((result, currentValue) => {
		  // If an array already present for key, push it to the array. Else create an array and push the object
		  (result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		  );
		  // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		  return result;
		}, {}); // empty object is the initial value for result object
	  };

	public static RemoveItemAt(array, index) {
		array.splice(index, 1);
	}

	public static MoveItemUp(array, index)
	{
		if(index < 1)
			return;

		let t = array[index-1];
		array[index-1] = array[index];
		array[index] = t;
	}

	public static MoveItemDown(array, index)
	{
		if(index > array.length-2)
			return;

		let t = array[index+1];
		array[index+1] = array[index];
		array[index] = t;
	}
}
