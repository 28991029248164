export class RossakerBmsRealestate {
  id : number;
  customerId : string;
  name : string;

  svlantPropertyDesignation : string;
  description : string;
  latitude : number;
  longitude : number;

  createdAt : Date;
  modifiedAt : Date;
}
