import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RossakerBmsTrusteeAdminWorkspaceComponent } from './components/rossaker-bms-trustee-admin-workspace/rossaker-bms-trustee-admin-workspace.component';
import { RossakerBmsTrusteeAdminRoutingModule } from './rossaker-bms-trustee-admin-routing.module';
import { FormsModule } from '@angular/forms';
import { XconfModule } from '@xprojectorfeatures/xconf/xconf.module';
import { SharedModule } from '@shared/shared.module';
import { SysAdminModule } from '@xprojectorfeatures/sysadmin/sys-admin.module';
import { RossakerBmsTrusteeAdminComponent } from './components/rossaker-bms-trustee-admin/rossaker-bms-trustee-admin.component';



@NgModule({
  declarations: [
    RossakerBmsTrusteeAdminWorkspaceComponent,
    RossakerBmsTrusteeAdminComponent
  ],
  imports: [
    CommonModule,
    RossakerBmsTrusteeAdminRoutingModule,
    FormsModule,
    SharedModule,
    XconfModule,
    SysAdminModule
  ]
})
export class RossakerBmsTrusteeAdminModule { }
