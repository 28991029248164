import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Treeview } from '@xprojectorcore/models/treeview';
import { TreeNode } from '@xprojectorcore/models/tree-node';
import { TreeviewItem } from '@xprojectorcore/models/treeview-item';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { ADMINDASHBOARDSSERVICE, AdminDashboardsService } from '../../services/admin-dashboards.service';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, Guid, XprojDashboardInteractService, XprojMediaService, XprojModalService } from 'xproj-lib';

class Tree {
  treeview: Treeview;
  openDropDown: boolean = false;
}

@Component({
  selector: 'app-admin-dashboard-workspace',
  templateUrl: './admin-dashboard-workspace.component.html',
  styleUrls: ['./admin-dashboard-workspace.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class AdminDashboardWorkspaceComponent implements OnInit, OnDestroy {

  treeviews: Tree[] = [];

  showEdit: boolean = false;
  newname: string;
  selectedTree: Tree;

  clipboardItem: TreeviewItem;

  collapsed = false;
  openDropDown = false;

  _navCollapsed: boolean = false;
  get navCollapsed(): boolean {
    return this._navCollapsed;
  }
  set navCollapsed(collapsed) {
    if (collapsed != this._navCollapsed) {
      this.xprojDashboardInteractService.RefreshDashboard();
    }
    this._navCollapsed = collapsed;
  }

  isMobile: boolean = false;
  mediaService = new XprojMediaService();
  mediaSubscription: any;

  constructor(
    private logger: NGXLogger,
    private xprojDashboardInteractService: XprojDashboardInteractService,
    private router: Router,
    private modalService: XprojModalService,
    @Inject(ADMINDASHBOARDSSERVICE) public adminDashboardsService: AdminDashboardsService,
  ) {

    // this.mediaService.setQuery('(max-width: 762px)');
    // this.mediaSubscription = this.mediaService.match$.subscribe(async (isMobile) => {
    //   this.isMobile = isMobile;
    //     //TODO: better solution!!
    //   setTimeout(() => {
    //     this.navCollapsed = this.isMobile;
    //   }, 2000);

    // });

  }

  async ngOnInit() {
    let treeviews = await this.adminDashboardsService.getDashboardTrees();

    treeviews.forEach(t => {
      let tree = new Tree();
      tree.treeview = t;
      this.treeviews.push(tree);
    });

    if (this.treeviews.length == 0) {
      this.treeviews.push(this.newTreeView('System Dashboards'));
      await this.adminDashboardsService.saveDashboardTree(this.treeviews[0].treeview);
    }

    let href = this.router.url;
    //this.logger.log('route', href);
    if (href?.length > 11) {
      let dashboardId = this.getDaskboardIdFromUrl(this.router.url);
      //this.logger.log('dashboardid', dashboardId);
      this.initSelectedTreeNode(dashboardId);
    }

    this.router.events.subscribe(val => {
      //this.logger.log(val);
      //@ts-ignore
      if (val.navigationTrigger == 'imperative') {
        //@ts-ignore
        let dashboardId = this.getDaskboardIdFromUrl(val.url);
        //this.logger.log('dashboardid', dashboardId);
        this.initSelectedTreeNode(dashboardId);
      }
    });
  }

  ngOnDestroy(): void {
    this.mediaSubscription?.unsubscribe();
  }

  getDaskboardIdFromUrl(url: string) {
    let dashboardId = decodeURIComponent(url.substr(11));
    let i = dashboardId.indexOf('?');
    if (i > 0) {
      dashboardId = dashboardId.substring(0, i);
    }

    return dashboardId;
  }

  onRightClick($event, tree) {
    $event.stopPropagation();
    $event.preventDefault();
    if (tree) {
      // @ts-ignore
      tree.openDropDown = false;
      setTimeout(() => {
        // @ts-ignore
        tree.openDropDown = true;
      });
    }
  }

  selectItem(item: Tree) {
    this.selectedTree = item;
  }

  initSelectedTreeNode(dashboardId: string) {
    for (let tree in this.treeviews) {
      let result = this.setSelectedTreeNode(this.treeviews[tree].treeview, dashboardId);
      if (result) {
        this.treeviews[tree].treeview.selectedNode = result;
        this.selectItem(this.treeviews[tree]);
        break;
      }
    };
  }

  setSelectedTreeNode(node: TreeNode, dashboardId: string): TreeNode {
    let result: TreeNode;
    if (node.id == dashboardId) {
      this.logger.log('Found node', node);
      result = node;
    }
    else {
      for (let child in node.children) {
        result = this.setSelectedTreeNode(node.children[child], dashboardId);
        if (result) {
          break;
        }
      }
    }

    return result;
  }

  async addTreeview() {
    let newTreeview = this.newTreeView('New_' + this.treeviews.length);
    this.treeviews.push(newTreeview);
    await this.adminDashboardsService.saveDashboardTree(newTreeview.treeview);
  }

  async deleteTreeview(tree: Tree, deleteDashboards: boolean = false) {
    this.modalService.ShowConfirmModal({ header: 'Delete dashboard tree', description: 'Delete dashboard tree, are you sure?' }, async (result) => {
      if (result) {
        if (deleteDashboards) {
          await ArrayUtils.AsyncForEach(tree.treeview.children, async (child) => {
            await this.deleteDashboardRecursive(child);
          });
        }
        this.treeviews = this.treeviews.filter(t => t.treeview.id != tree.treeview.id);
        await this.adminDashboardsService.deleteDashboardTree(tree.treeview.id);
      }
    });
  }

  async deleteDashboardRecursive(item: TreeNode) {
    await ArrayUtils.AsyncForEach(item.children, async (child) => {
      await this.deleteDashboardRecursive(child);
    });

    await this.adminDashboardsService.deleteDashboard(item.id);
  }

  async addRoot(tree: Tree) {
    let root: TreeviewItem = new TreeviewItem();
    root.id = Guid.newGuid();
    root.name = 'Root_' + tree.treeview.children.length;
    tree.treeview.children.push(root);

    await this.adminDashboardsService.saveDashboardTree(tree.treeview);
  }

  async renameTreeview(item: Tree) {
    if (item) {
      this.selectedTree = item;
      this.newname = item.treeview.name;
      this.showEdit = true;
    }
  }

  async doUpdateTreeview() {
    if (this.selectedTree) {
      this.selectedTree.treeview.name = this.newname;
      await this.adminDashboardsService.saveDashboardTree(this.selectedTree.treeview);
    }
  }

  private newTreeView(name: string): Tree {
    let treeView = new Treeview();
    treeView.id = Guid.newGuid();
    treeView.name = name;

    let root: TreeviewItem = new TreeviewItem();
    root.id = Guid.newGuid();
    root.name = 'Root';
    treeView.children.push(root);

    let result = new Tree();
    result.treeview = treeView;

    return result;
  }
}
