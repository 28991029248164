import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDateTimeProperty, GrpcNodeType, GrpcStringArrayProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { ClrTabs } from '@clr/angular';
import { NGXLogger } from 'ngx-logger';
import { join } from 'path';
import { ArrayUtils, DateHelper, Projection, XProjectorClient } from 'xproj-lib';
import { XconfTreeNode } from '../../models/xconf-tree-node';

@Component({
  selector: 'app-edit-treenode',
  templateUrl: './edit-treenode.component.html',
  styleUrls: ['./edit-treenode.component.scss']
})
export class EditTreenodeComponent implements OnInit {

  @ViewChild(ClrTabs) private readonly tabs: ClrTabs;

  _node: GrpcNode;
  @Input()
  get node(): GrpcNode {
    return this._node;
  }
  set node(node: GrpcNode) {
    this._node = node;
    this.initProperties();
  }
  @Input() parent: XconfTreeNode;
  @Input() nodeTypes: GrpcNodeType[] = [];
  @Input() customerId: string;

  @Output() onSaveTreeNode = new EventEmitter<GrpcNode>();

  nodeType: GrpcNodeType;
  parameters: { value: GrpcNodeProperty, parameter: any }[] = [];
  parametersByCategory: Map<string, { value: GrpcNodeProperty, parameter: any }[]> = new Map<string, { value: GrpcNodeProperty, parameter: any }[]>();

  projections: Projection[];
  projection: Projection;

  constructor(public xprojClient: XProjectorClient,
    private dateHelper: DateHelper,
    private logger: NGXLogger) {

  }

  async ngOnInit() {
    await this.initProperties();
  }

  async initProperties() {
    if (this.node) {

      this.parametersByCategory = new Map<string, { value: GrpcNodeProperty, parameter: any }[]>();
      this.nodeType = this.nodeTypes?.find(n => n.id == this.node.nodeTypeId);

      let properties = [];

      let loadProjections: boolean = false;

      this.nodeType?.dateTimeProperties.forEach(p => properties.push(p));
      this.nodeType?.stringProperties.forEach(p => {
        properties.push(p);
        if (p.projection) {
          loadProjections = true;
        }
      });
      this.nodeType?.timeSpanProperties.forEach(p => properties.push(p));
      this.nodeType?.booleanProperties.forEach(p => properties.push(p));
      this.nodeType?.doubleProperties.forEach(p => properties.push(p));
      this.nodeType?.stringOptionsProperties.forEach(p => properties.push(p));
      this.nodeType?.dashboardProperties.forEach(p => properties.push(p));

      ArrayUtils.AsyncForEach(this.nodeType?.stringArrayProperties, async (p: GrpcStringArrayProperty) => {
        properties.push(p);
        if (p.projectionGroup) {
          if (p.projectionPropertyParent) {
            if (this.parent && this.parent.node) {
              let propValue = this.parent.node.propertyValues.find(v => v.key == p.projectionPropertyId);
              if (propValue) {
                this.projection = await this.xprojClient.RequestGetConfigProjection(propValue.value);
              }
            }
          } else {
            let propValue = this.node.propertyValues.find(v => v.key == p.projectionPropertyId);
            if (propValue) {
              this.projection = await this.xprojClient.RequestGetConfigProjection(propValue.value);
            }
          }
        }

      })

      properties.sort((a, b) => {
        return a.order - b.order;
      });

      this.parameters = [];
      properties.forEach(p => {
        let propValue = this.node.propertyValues.find(v => v.key == p.id);
        if (propValue) {
          propValue = new GrpcNodeProperty({ key: propValue.key, value: this.getValue(propValue.valueType, propValue.value, p), valueType: propValue.valueType });
        }
        else {
          propValue = new GrpcNodeProperty({ key: p.id, value: p.defaultValue, valueType: this.getValueType(p.typeName) });
          if (propValue.valueType == GrpcNodeProperty.ValueTypes.DATETIME) {
            let v: any;
            if (p.defaultValue > 0) {
              try {
                v = new Date(p.defaultValue * 1000);
              }
              catch {
                v = new Date();
              }
            }
            else {
              v = new Date();
            }

            let dateTimeProperty: GrpcDateTimeProperty = p;
            propValue.value = dateTimeProperty.dateOnly ? this.dateHelper.utils.formatByString(v, 'yyyy-MM-dd') : dateTimeProperty.timeOnly ? this.dateHelper.utils.formatByString(v, 'HH:mm:ss')
              : this.dateHelper.utils.formatByString(v, "yyyy-MM-dd'T'HH:mm:ss");
          }
          else if (propValue.valueType == GrpcNodeProperty.ValueTypes.STRINGARRAY) {
            let v: any = [''];
            propValue.value = v;
          }
        }

        if (!p.category || p.category.length == 0) {
          p.category = 'Misc';
        }

        if (this.nodeType.categories.findIndex(c => c == p.category) < 0) {
          this.nodeType.categories.push(p.category);
        }

        if (!this.parametersByCategory.has(p.category)) {
          this.parametersByCategory.set(p.category, []);
        }

        this.parametersByCategory.get(p.category).push({ value: propValue, parameter: p });
      });

      if (this.nodeType?.categories.length > 0) {
        this.selectCategory(this.nodeType.categories[0]);
        setTimeout(() => {
          this.tabs?.tabLinkDirectives[0].activate();
        });
      }

      if (loadProjections) {
        this.projections = await this.xprojClient.RequestListConfigProjections(0, 100);
      }

    }
  }

  selectCategory(category) {
    this.parameters = this.parametersByCategory.get(category);
  }

  savePropertyValues() {
    this.node.propertyValues = [];

    this.parametersByCategory.forEach((kvp) => {
      kvp.forEach(param => {
        param.value.value = this.getPropertyValue(param.value.valueType, param.value.value);
        this.node.propertyValues.push(param.value);
      });
    });

    // this.parameters.forEach(param => {
    //   param.value.value = this.getPropertyValue(param.value.valueType, param.value.value);
    //   this.treeNode.propertyValues.push(param.value);
    // });

    this.onSaveTreeNode?.emit(this.node);
  }

  close() {

  }

  getValueType(typename: string): GrpcNodeProperty.ValueTypes {
    let result = GrpcNodeProperty.ValueTypes.STRING;
    switch (typename) {
      case "string":
        result = GrpcNodeProperty.ValueTypes.STRING;
        break;
      case "stringarray":
        result = GrpcNodeProperty.ValueTypes.STRINGARRAY;
        break;
      case "boolean":
        result = GrpcNodeProperty.ValueTypes.BOOLEAN;
        break;
      case "double":
        result = GrpcNodeProperty.ValueTypes.DOUBLE;
        break;
      case "datetime":
        result = GrpcNodeProperty.ValueTypes.DATETIME;
        break;
      case "timespan":
        result = GrpcNodeProperty.ValueTypes.TIMESPAN;
        break;
      case "stringoptions":
        result = GrpcNodeProperty.ValueTypes.STRINGOPTIONS;
        break;
      case "dashboard":
        result = GrpcNodeProperty.ValueTypes.DASHBOARD;
        break;

    }

    return result;
  }

  getValue(valueType: GrpcNodeProperty.ValueTypes, stringValue: string, property: any): any {

    switch (valueType) {
      case GrpcNodeProperty.ValueTypes.STRING:
        return stringValue;

      case GrpcNodeProperty.ValueTypes.STRINGARRAY:
        return stringValue.split('|');
      //return stringValue;

      case GrpcNodeProperty.ValueTypes.DOUBLE:
        return parseFloat(stringValue);

      case GrpcNodeProperty.ValueTypes.BOOLEAN:
        return stringValue.toLowerCase() == 'true';

      case GrpcNodeProperty.ValueTypes.DATETIME:
        let date: Date;
        date = this.dateHelper.utils.parse(stringValue, "yyyy-MM-dd HH:mm:ss");
        if (!this.dateHelper.utils.isValid(date)) {
          date = this.dateHelper.utils.parse(stringValue, "yyyy-MM-dd HH:mm");
          if (!this.dateHelper.utils.isValid(date)) {
            date = this.dateHelper.utils.parse(stringValue, "yyyy-MM-dd");
            if (!this.dateHelper.utils.isValid(date)) {
              date = this.dateHelper.utils.parse(stringValue, "HH:mm:ss");
              if (!this.dateHelper.utils.isValid(date)) {
                date = this.dateHelper.utils.date(stringValue);
              }
            }
          }
        }

        let dateTimeProperty: GrpcDateTimeProperty = property;
        return dateTimeProperty.dateOnly ? this.dateHelper.utils.formatByString(date, 'yyyy-MM-dd') : dateTimeProperty.timeOnly ? this.dateHelper.utils.formatByString(date, 'HH:mm:ss')
          : this.dateHelper.utils.formatByString(date, "yyyy-MM-dd'T'HH:mm:ss");

      case GrpcNodeProperty.ValueTypes.STRINGOPTIONS:
        return stringValue;

      case GrpcNodeProperty.ValueTypes.DASHBOARD:
        return stringValue;
    }

    return stringValue;
  }

  getPropertyValue(valueType: GrpcNodeProperty.ValueTypes, value: any): any {

    switch (valueType) {
      case GrpcNodeProperty.ValueTypes.STRING:
        return value + '';

      case GrpcNodeProperty.ValueTypes.STRINGARRAY:
        try {
          let arr: string[] = value;
          return arr.join('|');
        }
        catch {
          return (value + '').replaceAll(',', '|');
        }

      case GrpcNodeProperty.ValueTypes.DOUBLE:
        return value + '';

      case GrpcNodeProperty.ValueTypes.BOOLEAN:
        return value == true ? "true" : "false";

      case GrpcNodeProperty.ValueTypes.DATETIME:
        let date: Date = value;

        date = this.dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm:ss");
        if (!this.dateHelper.utils.isValid(date)) {
          date = this.dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm");
          if (!this.dateHelper.utils.isValid(date)) {
            date = this.dateHelper.utils.parse(value, "yyyy-MM-dd");
            if (!this.dateHelper.utils.isValid(date)) {
              date = this.dateHelper.utils.parse(value, "HH:mm:ss");
              if (!this.dateHelper.utils.isValid(date)) {
                date = this.dateHelper.utils.date(value);
              }
            }
          }
        }
        return date.toUTCString();

      case GrpcNodeProperty.ValueTypes.STRINGOPTIONS:
        return value + '';

      case GrpcNodeProperty.ValueTypes.DASHBOARD:
        return value + '';

    }

    return value;
  }

  removestring(strings: string[], item: string) {
    strings = strings.filter(s => s != item);
  }

  selectedProjectionGroupChange($event, property: GrpcNodeProperty) {
    this.logger.info("selectedProjectionGroupChange", $event);
    property.value = $event;//.join('|');
  }
}
