import { Component, Input, OnInit } from '@angular/core';
import { Projection, XProjectorClient } from '../../../../../../XProjector/xprojector-client-service';
import { BaseControllerConfig, XprojOutputProjectionControllerConfig } from '../../../xproj-output-controller-widget-config-service';

@Component({
  selector: 'xproj-output-projection-controller-config',
  templateUrl: './xproj-output-projection-controller-config.component.html',
  styleUrls: ['./xproj-output-projection-controller-config.component.scss']
})
export class XprojOutputProjectionControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.projectionConfig = value as XprojOutputProjectionControllerConfig;
    this.reload();
  }

  projectionConfig : XprojOutputProjectionControllerConfig;

  selectedProjection: Projection;
  projections: Projection[] = [];

  constructor(public xprojClient: XProjectorClient)
  { }

  async reload() : Promise<void>
  {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.selectedProjection = this.projections.find(p => p.projectionid == this.projectionConfig.DefaultProjectionId);

  }
  async ngOnInit(): Promise<void> {

  }

  selectedProjectionChange() {
    if (this.selectedProjection) {
      this.projectionConfig.DefaultProjectionId = this.selectedProjection.projectionid;
    }
    else {
      this.projectionConfig.DefaultProjectionId = '';
    }
  }

  groupSelected(group : string[]) {
    this.projectionConfig.DefaultGroup = group;
  }
}
