import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LuaExportColumn, LuaQueryColumn, XDataType } from '../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj-edit-lua-query-column',
  templateUrl: './xproj-edit-lua-query-column.component.html',
  styleUrls: ['./xproj-edit-lua-query-column.component.scss']
})
export class XprojEditLuaQueryColumnComponent implements OnInit {

  @Input() luaQueryColumn : LuaQueryColumn;
  @Input() unit : string;

  @Output() onQueryColumnRemoved = new EventEmitter<LuaQueryColumn>();
  @Output() onUnitChange = new EventEmitter<string>();

  editorOptions = {
    theme: 'vs',
    language: 'lua',
    lineNumbers: "off",
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap:{enabled: false}};

  selectedLuaExportColumn : LuaExportColumn;

  XDataType = XDataType;

  constructor() { }

  ngOnInit(): void {
  }

  addLuaExportColumn(): void {
    if (this.luaQueryColumn) {
      this.luaQueryColumn.inputcolumns.push(new LuaExportColumn());
    }
  }

  deleteSelectedLuaExportColumn() : void {
    this.luaQueryColumn.inputcolumns = this.luaQueryColumn.inputcolumns.filter(col => col != this.selectedLuaExportColumn);
  }

  removeQueryColumn() {
    this.onQueryColumnRemoved?.emit(this.luaQueryColumn);
  }

  onUnitChanged($event) {
    this.onUnitChange?.emit(this.unit);
  }
}
