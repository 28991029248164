<form clrForm>
  <clr-input-container>
    <label>Nr Rooms</label>
    <input clrInput type="number" min="0" max="1000" step="1" placeholder="" name="NrRooms" [(ngModel)]="nrRooms" />
  </clr-input-container>

  <clr-toggle-container style="margin-top: 1em;">
    <label>Include hallway</label>
    <clr-toggle-wrapper>
      <input type="checkbox" clrToggle value="false" name="IncludeHallway" [(ngModel)]="includeHallway" />
    </clr-toggle-wrapper>
  </clr-toggle-container>


  <button [disabled]="(plan.rooms.length == nrRooms) && plan.hallways.length == (includeHallway?1:0)" type="button"
    class="btn btn-link" (click)="updatePlan();">
    Update plan
  </button>
  <button *ngIf="saveEnabled" type="button" class="btn btn-link" (click)="saveFloorplan();">
    Save plan
  </button>
  <button *ngIf="saveEnabled && showSaved" class="btn btn-link" [disabled]="true">Floorplan saved</button>
</form>


<div style="max-width:1200px;">
  <clr-datagrid class="datagrid-compact" [clrDgLoading]="loadingFloorPlan">

    <clr-dg-column [clrDgField]="'room.matchesSuffix'" [clrDgSortOrder]="ascSort"> Matches </clr-dg-column>
    <clr-dg-column [clrDgField]="'room.area.position_x'"> X </clr-dg-column>
    <clr-dg-column [clrDgField]="'room.area.position_y'"> Y </clr-dg-column>
    <clr-dg-column [clrDgField]="'room.area.size_x'"> Size X </clr-dg-column>
    <clr-dg-column [clrDgField]="'room.area.size_y'"> Size Y </clr-dg-column>

    <clr-dg-row *clrDgItems="let room of plan.rooms">

      <clr-dg-cell class="cellhideoverflow">
        <clr-input-container style="margin-top: 0px;">
          <input clrInput type="text" placeholder="" name="MatchesSuffix" [(ngModel)]="room.matchesSuffix" />
        </clr-input-container>
      </clr-dg-cell>

      <clr-dg-cell class="cellhideoverflow">
        <clr-input-container style="margin-top: 0px;">
          <input clrInput type="number" min="0" max="1000" step="1" placeholder="" name="roomposx"
            [(ngModel)]="room.area.position_x" />
        </clr-input-container>
      </clr-dg-cell>


      <clr-dg-cell class="cellhideoverflow">
        <clr-input-container style="margin-top: 0px;">
          <input clrInput type="number" min="0" max="1000" step="1" placeholder="" name="roomposy"
            [(ngModel)]="room.area.position_y" />
        </clr-input-container>
      </clr-dg-cell>


      <clr-dg-cell class="cellhideoverflow">
        <clr-input-container style="margin-top: 0px;">
          <input clrInput type="number" min="0" max="1000" step="1" placeholder="" name="roomsizex"
            [(ngModel)]="room.area.size_x" />
        </clr-input-container>
      </clr-dg-cell>

      <clr-dg-cell class="cellhideoverflow">
        <clr-input-container style="margin-top: 0px;">
          <input clrInput type="number" min="0" max="1000" step="1" placeholder="" name="roomsizey"
            [(ngModel)]="room.area.size_y" />
        </clr-input-container>
      </clr-dg-cell>


    </clr-dg-row>

  </clr-datagrid>
</div>
<!--
  <p>
    calcBoundingBoxMinX = {{calcBoundingBoxMinX()}}<br>
    calcBoundingBoxMinY = {{calcBoundingBoxMinY()}}<br>
    calcBoundingBoxMaxX = {{calcBoundingBoxMaxX()}}<br>
    calcBoundingBoxMaxY = {{calcBoundingBoxMaxY()}}<br>
  </p> -->

<!-- calcBoundingBoxMaxY()-calcBoundingBoxMinY()" -->
<div style="position: relative;" [style.marginTop.em]="-calcBoundingBoxMinY() + 1"
  [style.width.em]="calcBoundingBoxMaxX()-calcBoundingBoxMinX()"
  [style.height.em]="calcBoundingBoxMaxY()-calcBoundingBoxMinY()">
  <div *ngFor="let room of plan.rooms" [style.left.em]="room.area.position_x"
    [style.top.em]="-room.area.position_y-room.area.size_y" [style.width.em]="room.area.size_x"
    [style.height.em]="room.area.size_y" class="room">
    {{room.matchesSuffix}}

  </div>
</div>
