import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { encode, decode } from "@msgpack/msgpack";
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'xproj-xautomation-settings',
  templateUrl: './xautomation-settings.component.html',
  styleUrls: ['./xautomation-settings.component.scss']
})
export class XautomationSettingsComponent {
  constructor() {
  }


}
