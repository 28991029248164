import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GrpcEdgeType, GrpcNodeType, GrpcTreeNodeDefinition, GrpcTreeNodeReferenceDefinition, GrpcDataSourceDefinition } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';

@Component({
  selector: 'app-edit-datasource-definitions',
  templateUrl: './edit-datasource-definitions.component.html',
  styleUrls: ['./edit-datasource-definitions.component.scss']
})
export class EditDataSourceDefinitionsComponent implements OnInit, AfterViewInit {

  dataSourceDefinitions: GrpcDataSourceDefinition[] = [];

  selected: GrpcDataSourceDefinition = null;
  selectedTreenode: GrpcTreeNodeDefinition = null;

  nodeTypes: GrpcNodeType[] = [];
  edgeTypes: GrpcEdgeType[] = [];

  constructor(private xConfClient: XProjectorXConfClient) {

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.dataSourceDefinitions = await this.xConfClient.getDataSourceDefinitions();
    this.nodeTypes = await this.xConfClient.getNodeTypes();
    this.edgeTypes = await this.xConfClient.getEdgeTypes();

    let dummybms = new GrpcDataSourceDefinition();
    dummybms.id = "_x_bms";
    this.dataSourceDefinitions.push(dummybms);
  }

  async saveDataSourceDefinition(dataSourceDefinition: GrpcDataSourceDefinition): Promise<boolean> {
    let result = await this.xConfClient.upsertDataSourceDefinition(dataSourceDefinition);
    return result.result;
  }

  addDataSourceDefinition() {
    let dataSourceDefinition: GrpcDataSourceDefinition = new GrpcDataSourceDefinition();
    this.dataSourceDefinitions.push(dataSourceDefinition);
    this.selected = null;
  }

  async deleteSelected() {
    if (this.selected) {
      let result = await this.xConfClient.deleteDataSourceDefinition(this.selected.id);
      if (result.result) {
        this.dataSourceDefinitions = this.dataSourceDefinitions.filter(n => n.id != this.selected.id);
        this.selected = null;
      }
    }
  }

  async saveSelected() {
    if (this.selected) {
      //TODO: id changed??
      await this.saveDataSourceDefinition(this.selected);

      this.selected = null;
    }
  }

  addTreenode() {
    let treenode: GrpcTreeNodeDefinition = new GrpcTreeNodeDefinition();
    this.selected.treeNodeDefinitions.push(treenode);
    this.selectedTreenode = null;
  }

  deleteSelectedTreenode() {
    if (this.selected && this.selectedTreenode) {
      this.selected.treeNodeDefinitions = this.selected.treeNodeDefinitions.filter(n => n.nodeTypeId != this.selectedTreenode.nodeTypeId);
      this.selectedTreenode = null;
    }
  }

  addTreenodeReference() {
    if (this.selectedTreenode) {
      this.selectedTreenode.treeNodeReferenceDefinitions.push(new GrpcTreeNodeReferenceDefinition());
    }
  }

  visualize()
  {
    console.log("visualize of", this.selectedTreenode);
    console.log("visualize of", this.selected);
    return;
    // for(let rootnodet of this.selected.rootNodeTypeIds)
    // {
    //   console.log(rootnodet);
    // }

    for(let nodetype of this.nodeTypes)
    {
      console.log(nodetype);
    }

    for(let edgetype of this.edgeTypes)
    {
      console.log(edgetype);
    }
  }

}
